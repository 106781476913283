import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Form from './form';

import { fetchEachVehicle, fetchPackage } from '../../../../store/actions';

const VehicleForm = ({
  fetchEachVehicle,
  vehiclePreview,
  fetchPackage,
  packages,
  loading,
  message,
  vId,
}) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const userId = new URLSearchParams(search).get('user');

  useEffect(() => {
    if (id) {
      fetchEachVehicle(id);
    }
    fetchPackage();
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        {vehiclePreview ? (
          <Form
            loading={loading}
            defaultValue={vehiclePreview}
            message={message}
            packages={packages}
            vId={vId}
            fetchPackage={fetchPackage}
          />
        ) : (
          <Form
            loading={loading}
            message={message}
            defaultValue={null}
            userID={userId}
            packages={packages}
            vId={vId}
            fetchPackage={fetchPackage}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { vehiclePreview, loading, vehicleError, message, vId } = state.Vehicle;
  const { packages } = state.Packages;
  return { vehiclePreview, loading, vehicleError, message, vId, packages };
};

export default connect(mapStateToProps, { fetchEachVehicle, fetchPackage })(
  VehicleForm
);
