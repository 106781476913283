import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
} from 'reactstrap';
import classnames from 'classnames';
import Maintenance from '../../../../assets/images/maintenance-bg.png';
import ChangePassword from './ChangePassword';
import EditProfileCard from './EditProfile';
import { fetchAllUser } from '../../../../store/actions';
const Settings = (user, fetchAllUser, loading, message) => {
  const [activeTab, SetActiveTab] = useState('1');

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      SetActiveTab(tab);
    }
  };

  return (
    <div className='page-content'>
      <Container fluid>
        <Card>
          <CardBody>
            <div className='mb-4'></div>
            <Nav className='nav-tabs-custom mb-4'>
              <NavItem>
                <NavLink
                  onClick={() => {
                    toggleTab('1');
                  }}
                  className={classnames(
                    { active: activeTab === '1' },
                    'font-weight-bold p-3'
                  )}
                >
                  Edit Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => {
                    toggleTab('2');
                  }}
                  className={classnames(
                    { active: activeTab === '2' },
                    'font-weight-bold p-3'
                  )}
                >
                  Change Password
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => {
                    toggleTab('3');
                  }}
                  className={classnames(
                    { active: activeTab === '3' },
                    'p-3 font-weight-bold'
                  )}
                >
                  User Info
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId='1'>
                <div className='d-flex justify-content-around'>
                  <EditProfileCard user={user.user} history={user.history} />
                </div>
              </TabPane>
              <TabPane tabId='2'>
                <div className='d-flex justify-content-around'>
                  <ChangePassword />
                </div>
              </TabPane>
              <TabPane tabId='3'>
                <div className='text-center'>
                  <img
                    src={Maintenance}
                    className='avatar-lg mb-3'
                    alt='Maintenance'
                  />
                  <h4> Payment Under Maintenance </h4>
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user, loading, userError, message } = state.Account;
  return { user, loading, userError, message };
};

export default connect(mapStateToProps, {
  fetchAllUser,
})(Settings);
