export const FETCH_CASHOUT = 'FETCH_CASHOUT';
export const FETCH_CASHOUT_SUCCESSFUL = 'FETCH_CASHOUT_SUCCESSFUL';
export const FETCH_CASHOUT_API_FAILED = 'FETCH_CASHOUT_API_FAILED';

export const CREATE_CASHOUT = 'CREATE_CASHOUT';
export const CREATE_CASHOUT_SUCCESSFUL = 'CREATE_CASHOUT_SUCCESSFULLY';
export const CREATE_CASHOUT_FAILED = 'CREATE_CASHOUT_FAILED';

export const FETCH_EACH_CASHOUT = 'FETCH_EACH_CASHOUT';
export const FETCH_EACH_CASHOUT_SUCCESSFUL = 'FETCH_EACH_CASHOUT_SUCCESSFUL';
export const FETCH_EACH_CASHOUT_API_FAILED = 'FETCH_EACH_CASHOUT_API_FAILED';

export const DELETE_CASHOUT = 'DELETE_CASHOUT';
export const DELETE_CASHOUT_SUCCESSFUL = 'DELETE_CASHOUT_SUCCESSFULLY';
export const DELETE_CASHOUT_FAILED = 'DELETE_CASHOUT_FAILED';

export const UPDATE_CASHOUT_STATUS = 'UPDATE_CASHOUT_STATUS';
export const UPDATE_CASHOUT_STATUS_SUCCESSFUL =
  'UPDATE_CASHOUT_STATUS_SUCCESSFULLY';
export const UPDATE_CASHOUT_STATUS_FAILED = 'UPDATE_CASHOUT_STATUS_FAILED';

export const UPDATE_CASHOUT = 'UPDATE_CASHOUT';
export const UPDATE_CASHOUT_SUCCESSFUL = 'UPDATE_CASHOUT_SUCCESSFULLY';
export const UPDATE_CASHOUT_FAILED = 'UPDATE_CASHOUT_FAILED';

export const FETCH_FOR_CASHOUT_CSV = 'FETCH_FOR_CASHOUT_CSV';
export const FETCH_FOR_CASHOUT_CSV_ERROR = 'FETCH_FOR_CASHOUT_CSV_ERROR';
export const FETCH_FOR_CASHOUT_CSV_SUCCESSFULLY =
  'FETCH_FOR_CASHOUT_CSV_SUCCESSFULLY';

export const FETCH_CASHOUT_SUMMARY = 'FETCH_CASHOUT_SUMMARY';
export const FETCH_CASHOUT_SUMMARY_FAILED = 'FETCH_CASHOUT_SUMMARY_FAILED';
export const FETCH_CASHOUT_SUMMARY_SUCCESSFULLY =
  'FETCH_CASHOUT_SUMMARY_SUCCESSFULLY';
