import React, { useState, useEffect } from 'react';
import { Card, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import AlartError from "../../../../../components/Common/AlertCard"
import { getS3ImageUrlHandler } from '../../../../../utils/general';

import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import Button from 'reactstrap/lib/Button';

const FormData = ({
  closeEdit = null,
  defaultValue = null,
  updateHandler,
  loading,
}) => {
  const [selectedFiles, setFileUpload] = useState([]);
  const [imageError, setImageError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultType, setDefaultValue] = useState('Button');
  const [defaultStatus, setDefaultStatus] = useState('INACTIVE');

  const handleSubmit = async (event, values) => {
    setImageError(null);
    const packageData = {};
    packageData.appConfigItems = {
      name: values.name,
      picture: '',
      order: values.order,
      type: values.type,
      status: values.Status,
    };
    try {
      if (selectedFiles.length > 0) {
        try {
          let result = await getS3ImageUrlHandler(
            selectedFiles[0],
            'app_setting'
          );

          packageData.appConfigItems.picture = result?.data.result?.mediaUrl;
        } catch (error) {
          throw error;
        }
      }
      updateHandler(packageData, defaultValue);
      setFileUpload([]);
    } catch (error) {
      setImageError(`${error} - image upload error format`);
      setIsLoading(false);
    }
  };

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setFileUpload(files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  useEffect(() => {
    if (defaultValue) {
      setDefaultValue(defaultValue.type);
      setDefaultStatus(defaultValue.status);
    }
  }, []);

  return (
    <div>
      <div>
        <Col sm={6}>
          {imageError && (
            <AlartError error={imageError} />
          )}
        </Col>
        <div>
          <AvForm
            className="form-horizontal"
            onValidSubmit={handleSubmit}
            model={defaultValue ? defaultValue : {}}
          >
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <FormGroup className=" mb-4">
                      <Label htmlFor="provider">App Name</Label>
                      <AvField
                        name="name"
                        type="text"
                        className="form-control bg-light"
                        id="name"
                        placeholder="Enter App Name ..."
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className=" mb-4">
                      <Label htmlFor="provider">Order Number</Label>
                      <AvField
                        name="order"
                        min="0"
                        type="number"
                        className="form-control bg-light"
                        id="order"
                        placeholder="Enter Order Number ..."
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className=" mb-4">
                      <AvField
                        className="form-control bg-light"
                        type="select"
                        name="type"
                        label="Type"
                        value={defaultType}
                        required
                      >
                        <option>Button</option>
                        <option>Icon</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className=" mb-4">
                      <AvField
                        className="form-control bg-light"
                        type="select"
                        name="Status"
                        label="Status"
                        value={defaultStatus}
                        required
                      >
                        <option>ACTIVE</option>
                        <option>INACTIVE</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        handleAcceptedFiles(acceptedFiles)
                      }
                      accept="image/jpeg,image/jpd"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                            </div>
                            <h4>Drop/Upload Item Picture Here.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + '-file'}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
                <div
                  className={`${
                    closeEdit ? 'd-flex justify-content-between' : 'text-center'
                  }`}
                >
                  {closeEdit && (
                    <Button color="secondary" onClick={closeEdit}>
                      Back
                    </Button>
                  )}
                  <button className="btn btn-success px-4" type="submit">
                    {loading
                      ? 'Submitting...'
                      : closeEdit
                      ? 'Update'
                      : 'Add Item'}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </div>
      </div>
    </div>
  );
};

export default FormData;
