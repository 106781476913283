import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import RedeemHistoryTable from './redeemHistoryTable';
import GreySummaryCard from '../../../../components/Common/Summary/index';

const PromoCode = ({ history, match }) => {
  const breadcrumbItems = [
    { title: 'Promo Code', link: '#' },
    { title: 'Redeem History', link: '/promo_history' },
  ];

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='Redeem History'
            breadcrumbItems={breadcrumbItems}
          />
          <GreySummaryCard field='Rides Promotions' subModule='History'>
            <>
              <Row>
                <Col>
                  <RedeemHistoryTable history={history} match={match} />
                </Col>
              </Row>
            </>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PromoCode;
