import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetActivitiesService = (query) => {
  const http = new HttpService();
  let url = 'activities-log/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }

  return http.getData(url);
};

export const GetEachActivitiesService = (id) => {
  const http = new HttpService();
  const url = `activities-log/${id}`;
  return http.getData(url);
};

export const CreateActivitiesService = (payload) => {
  const http = new HttpService();
  const url = 'activities-log';

  return http.postData(payload, url);
};
export const UpdateActivitiesService = ({ payload, id }) => {
  const http = new HttpService();
  const url = `activities-log/${id}`;

  return http.putData(payload, url);
};

export const DeleteActivitiesService = (id) => {
  const http = new HttpService();
  const url = `activities-log/${id}`;
  return http.deleteData(url);
};
