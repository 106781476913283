import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Label,
  Row,
  Alert,
  Col,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';
import Loader from '../../../components/Common/Loading/index.js';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Moment from 'moment';
import moment from 'moment-timezone';
import FilterDropDown from '../../../components/Common/FilterDropDown';
import SimpleBar from 'simplebar-react';
import imgCar from '../../../assets/images/sample_car.png';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../utils/general';
import queryString from 'query-string';

let skip = 0;
let dataLimit = 10;

const BonusTable = ({
  bonusData,
  previewAction,
  fetchBonus,
  tableError,
  deleteBonus,
  statusHandler,
  message,
  history,
  dateFilter,
  fetchBonusCSVError,
  fetchBonusCSV,
  csvData,
  csv_loading,
  CheckAction,
  ReadCheck,
  accessControl
}) => {
  const [csv, setCsvData] = useState([]);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [data, setData] = useState(null);
  const [skipPage, setSkipPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState('');
  const [menu, setMenu] = useState(false);
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(bonusData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = { skip: pageNumber, take: dataLimit, status, search };
    fetchBonus(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take, status, search };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchBonus(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      // skip,
      status,
    };
    fetchBonus(queryParams);
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          status,
        };
        fetchBonus(queryParams);
      }, 2000);
    }
  }, [message]);
  const handleCSV = (e, values) => {
    fetchBonusCSV({
      take: values.endRow
        ? values.endRow - values.startRow + 1
        : bonusData?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
    });
  };
  useEffect(() => {
    if (csvData) {
      // SET CSV EXPORT DATA
      const csv = csvData?.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Bonus_Name: data.name ? data.name : '----',
          Value: data?.value ? `₦${data.value}` : 0,
          Rides: data?.requirements?.ride,
          Online: data?.requirements?.online,
          Acceptance: data?.requirements?.acceptance
            ? `${data.requirements?.acceptance * 100}%`
            : 0,
          Completion: data?.requirements?.completions
            ? `${data.requirements?.completions * 100}%`
            : 0,
          Status: data?.status,
        };

        return formData;
      });

      setCsvData(csv);
    }
  }, [csvData]);

  useEffect(() => {
    if (!showCSVModal) {
      fetchBonusCSVError();
    }
  }, [showCSVModal]);

  useEffect(() => {
    if (bonusData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(bonusData?.total / take));
    }
  }, [bonusData]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchBonus(parsedUrlParams);
  }, [fetchBonus]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      status,
      take,
      skip: pageNumber,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        take,
        skip,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.status) {
      setStatus(parsedUrlParams?.status);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };
  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    dateFilter({ start: values.startDate, end: values.endDate });
    setMenu(false);
    setStatus(values.vehicleStatus);
    let queryParams = {
      search,
      take,
      skip,
      status: values.vehicleStatus,
      start: values.startDate,
      end: values.endDate,
    };
    fetchBonus(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: '',
      end: '',
    });
    dateFilter({});
    let queryParams = {
      search,
      take,
      skip,
      status,
    };
    fetchBonus(queryParams);
  };

  let checking = ReadCheck(accessControl, `Bonus`, 'All');

  const tableDataHandler = () => {
    const rowData = bonusData?.result?.map((data) => {
      return {
        id: data.id.substring(0, 7),
        userType: data?.userType,
        accountName: (

          <>
            {checking ?

              <Link
                to='#'
                // onClick={() => previewAction(data.id)}
                className='text-dark font-weight-bold'
              >
                {data.name}
              </Link>

              :
              <div className='text-dark font-weight-bold'>
                {data?.name}
              </div>
            }</>
        ),
        ride: (
          <span>
            <span className='d-block'>
              <span>Ride-{data?.requirements?.ride}</span>
            </span>
            <span className='d-block'>
              <span className='badge badge-soft-success '>
                Online-{data?.requirements?.online}
              </span>
            </span>
          </span>
        ),
        acceptance: (
          <span>
            <span className='d-block'>
              <span>
                Acceptance-
                {data?.requirements?.acceptance
                  ? `${data.requirements?.acceptance * 100}%`
                  : 0}
              </span>
            </span>
            <span className='d-block'>
              <span className='badge badge-soft-warning '>
                Completion-
                {data?.requirements?.completions
                  ? `${data.requirements?.completions * 100}%`
                  : 0}
              </span>
            </span>
          </span>
        ),
        zone: (
          <div className='text-truncate' style={{ maxWidth: '300px' }}>
            {data?.zones?.length > 0
              ? data?.zones?.map((zone) => (
                <div key={zone.id}>
                  {' '}
                  <h1 className={`badge badge-soft-secondary font-size-12`}>
                    {' '}
                    {zone.name}
                  </h1>{' '}
                </div>
              ))
              : 'No zone restriction'}
          </div>
        ),
        value: <span> ₦{data.value} </span>,
        status: (
          <h1
            className={`badge badge-soft-${data.status.toLowerCase() === 'active'
              ? 'success'
              : data.status.toLowerCase() === 'inactive'
                ? 'danger'
                : data.status.toLowerCase() === 'sanction'
                  ? 'warning'
                  : 'dark'
              } font-size-12`}
          >
            {data.status ? data.status : '---'}
          </h1>
        ),
        action: (
          <div className='d-flex align-items-center'>
            <CheckAction field='Bonus' subModule='All' iconType='Edit'>
              <Link
                to={`/bonus_form?id=${data.id}`}
                className='mr-3 text-primary'
                id='edit1'
              >
                <i className='mdi mdi-pencil font-size-16'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='edit1'>
                Edit
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field='Bonus' subModule='All' iconType='Delete'>
              <Link
                to='#'
                onClick={() => deleteBonus(data.id)}
                className='mr-3 text-danger'
                id='delete1'
              >
                <i className='mdi mdi-trash-can font-size-16'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='delete1'>
                Delete
              </UncontrolledTooltip>
            </CheckAction>
          </div>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'Bonus Id',
          dataField: 'id',
        },
        {
          text: 'Bonus Name',
          dataField: 'accountName',
          sort: true,
        },
        {
          text: 'Value',
          dataField: 'value',
        },
        {
          text: 'Rides ratio',
          dataField: 'ride',
        },
        {
          text: 'Acceptance Ratio',
          dataField: 'acceptance',
        },

        {
          text: 'Status',
          dataField: 'status',
        },
        {
          dataField: 'zone',
          text: 'Zones',
        },

        {
          text: 'UserType',
          dataField: 'userType',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {tableError !== null ? (
                <Alert color='danger' className='text-center'>
                  {tableError}
                </Alert>
              ) : (
                <>
                  {!isLoading && data !== null ? (
                    <>
                      <div className='d-flex justify-content-end align-items-center mb-2'>
                        <div>
                          <Button
                            size='sm'
                            color='primary'
                            className='mt-1'
                            onClick={() => setShowCSVModal(true)}
                            disabled={bonusData?.total > 0 ? false : true}
                          >
                            <i className=' fas fa-cloud-download-alt mr-1' />
                            CSV
                          </Button>
                        </div>
                        <div>
                          <FilterDropDown
                            menu={menu}
                            toggleMenu={() => setMenu(!menu)}
                          >
                            <div>
                              <AvForm
                                className='form-horizontal'
                                onValidSubmit={handleDateFilter}
                              >
                                <SimpleBar
                                  style={{
                                    maxHeight: '250px',
                                  }}
                                >
                                  <Row className='px-3  pt-3 pb-0'>
                                    <Col md='12'>
                                      <FormGroup>
                                        <AvField
                                          name='startDate'
                                          type='date'
                                          className='form-control bg-light'
                                          id='startDate'
                                          value={date.start}
                                          helpMessage='Start Date'
                                          placeholder='Please choose the start date...'
                                          required
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md='12'>
                                      <FormGroup>
                                        <AvField
                                          name='endDate'
                                          type='date'
                                          className='form-control bg-light'
                                          id='endDate'
                                          value={date.end}
                                          helpMessage='End Date'
                                          placeholder='Please choose the end date...'
                                          required
                                        />
                                      </FormGroup>
                                    </Col>{' '}
                                    <Col md='12'>
                                      <FormGroup>
                                        <AvField
                                          type='select'
                                          value={status}
                                          name='vehicleStatus'
                                          helpMessage='status'
                                        >
                                          <option value=''>All</option>
                                          <option value='ACTIVE'>ACTIVE</option>
                                          <option value='INACTIVE'>
                                            INACTIVE
                                          </option>
                                          <option value='SANCTION'>
                                            SANCTION
                                          </option>
                                        </AvField>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </SimpleBar>

                                <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                                  <Link
                                    to='#'
                                    onClick={resetHandler}
                                    className='btn btn-sm  bg-light font-size-14'
                                  >
                                    <i className=' mdi mdi-refresh mr-1'>
                                      {' '}
                                      Reset
                                    </i>
                                  </Link>

                                  <button
                                    className='btn btn-success btn-sm mr-2'
                                    type='submit'
                                  >
                                    <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                    Send
                                  </button>
                                </div>
                              </AvForm>
                            </div>
                          </FilterDropDown>
                        </div>
                      </div>

                      <div className='d-flex justify-content-between mb-2'>
                        <div>
                          <Input
                            bsSize='sm'
                            id='take'
                            name='take'
                            type='select'
                            value={take}
                            onChange={handleLimitChange}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div>
                          <Input
                            bsSize='sm'
                            id='search'
                            name='search'
                            value={search}
                            onChange={SearchHandler}
                            placeholder='Search'
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField='id'
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses='table-responsive'
                        noDataIndication={'No Data to Display'}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        // breakLabel={'...'}
                        // marginPagesDisplayed={2}
                        // pageRangeDisplayed={3}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        forcePage={skipPage}
                        //Bootstrap styling
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* CSV DOWNLOAD MODAL */}
      <Modal
        isOpen={showCSVModal}
        toggle={() => setShowCSVModal(!showCSVModal)}
      >
        <ModalHeader toggle={() => setShowCSVModal(false)}>
          Generate CSV
        </ModalHeader>
        <ModalBody>
          <AvForm className='form-horizontal' onValidSubmit={handleCSV}>
            <Col>
              <FormGroup>
                <Label htmlFor='startRow'>Start (Row)</Label>
                <AvField
                  name='startRow'
                  type='number'
                  max={bonusData?.total - 1}
                  className='form-control'
                  id='startRow'
                  placeholder='Enter the row to start from'
                  errorMessage='value must be greater than 0 and less than total data'
                  min='1'
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor='endRow'>End (Row)</Label>
                <AvField
                  name='endRow'
                  type='number'
                  className='form-control'
                  min='1'
                  max={bonusData?.total}
                  id='endRow'
                  placeholder='Enter the row to end'
                  helpMessage={
                    bonusData?.total
                      ? `Total data is ${bonusData?.total}`
                      : null
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Button
              size='sm'
              color='primary'
              className='ml-3 waves-effect waves-light '
              type='submit'
            >
              {csv_loading ? 'Generating...' : 'Generate'}
            </Button>
            {csvData && (
              <CSVLink
                data={csv}
                filename={'bonus.csv'}
                className='btn-sm btn-success ml-2'
                target='_blank'
                onClick={() => setShowCSVModal(false)}
              >
                Download
              </CSVLink>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default BonusTable;
