import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import AlartError from "../../../../components/Common/AlertCard"
import AutoComplete from '../../../../components/Common/Autocomplete';

const AssignToCard = ({
  assignedId,
  loading,
  assignComplaint,
  message,
  complaintError,
}) => {
  const [user, setSelectedUser] = useState(null);

  const assignHandler = () => {
    const payload = { id: assignedId, assignTo: user.value };
    assignComplaint(payload);
  };

  return (
    <div>
      {message && (
        <Alert color="success" className="text-center">
          Complaint Assigned Successfully
        </Alert>
      )}

      {complaintError && (
        <AlartError error={complaintError} />
      )}
      <AutoComplete
        setUser={(e) => setSelectedUser(e)}
        userType={'ADMIN'}
        title="Select Admin"
      />
      <div className="text-center">
        <button
          className="btn btn-success px-4"
          disabled={user || loading ? false : true}
          onClick={assignHandler}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AssignToCard;
