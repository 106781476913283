import {
  IS_APP_CONFIG_AUTHORIZE,
  FETCH_APP_CONFIGS,
  FETCH_APP_CONFIGS_SUCCESSFUL,
  FETCH_APP_CONFIGS_API_FAILED,
  FETCH_EACH_APP_CONFIGS,
  FETCH_EACH_APP_CONFIGS_SUCCESSFUL,
  FETCH_EACH_APP_CONFIGS_API_FAILED,
  UPDATE_APP_CONFIGS,
  UPDATE_APP_CONFIGS_SUCCESSFUL,
  UPDATE_APP_CONFIGS_API_FAILED,
  UPDATE_APP_CONFIG_ITEM,
  UPDATE_APP_CONFIG_ITEM_SUCCESSFUL,
  UPDATE_APP_CONFIG_ITEM_API_FAILED,
  CREATE_APP_CONFIGS,
  CREATE_APP_CONFIGS_SUCCESSFUL,
  CREATE_APP_CONFIGS_API_FAILED,
  DELETE_APP_CONFIGS,
  DELETE_APP_CONFIGS_SUCCESSFUL,
  DELETE_APP_CONFIGS_API_FAILED,
  DELETE_APP_CONFIG_ITEM,
  DELETE_APP_CONFIG_ITEM_SUCCESSFUL,
  DELETE_APP_CONFIG_ITEM_API_FAILED,
  CREATE_ACCESS_CONTROL_FE,
  CREATE_ACCESS_CONTROL_FE_SUCCESSFUL,
  CREATE_ACCESS_CONTROL_FE_API_FAILED,
  UPDATE_ACCESS_CONTROL_FE,
  UPDATE_ACCESS_CONTROL_FE_SUCCESSFUL,
  UPDATE_ACCESS_CONTROL_FE_API_FAILED,
  LIST_ACCESS_CONTROL_FE,
  LIST_ACCESS_CONTROL_FE_SUCCESSFUL,
  LIST_ACCESS_CONTROL_FE_API_FAILED,
  LIST_EACH_ACCESS_CONTROL_FE,
  LIST_EACH_ACCESS_CONTROL_FE_SUCCESSFUL,
  LIST_EACH_ACCESS_CONTROL_FE_API_FAILED,
  DELETE_ACCESS_CONTROL_FE,
  DELETE_ACCESS_CONTROL_FE_SUCCESSFUL,
  DELETE_ACCESS_CONTROL_FE_API_FAILED,
} from './actionTypes';

export const fetchAppConfigsAuthorizeError = (error) => {
  return {
    type: IS_APP_CONFIG_AUTHORIZE,
    payload: error,
  };
};

export const createAppConfigs = (payload) => {
  return {
    type: CREATE_APP_CONFIGS,
    payload,
  };
};

export const createAppConfigsSuccessful = (payload) => {
  return {
    type: CREATE_APP_CONFIGS_SUCCESSFUL,
    payload: payload,
  };
};

export const createAppConfigsError = (error) => {
  return {
    type: CREATE_APP_CONFIGS_API_FAILED,
    payload: error,
  };
};

export const fetchAppConfigs = (payload) => {
  return {
    type: FETCH_APP_CONFIGS,
    payload,
  };
};

export const fetchAppConfigsSuccessful = (payload) => {
  return {
    type: FETCH_APP_CONFIGS_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchAppConfigsError = (error) => {
  return {
    type: FETCH_APP_CONFIGS_API_FAILED,
    payload: error,
  };
};

export const fetchEachAppConfigs = (id) => {
  return {
    type: FETCH_EACH_APP_CONFIGS,
    payload: id,
  };
};

export const fetchEachAppConfigsSuccessful = (payload) => {
  return {
    type: FETCH_EACH_APP_CONFIGS_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachAppConfigsError = (error) => {
  return {
    type: FETCH_EACH_APP_CONFIGS_API_FAILED,
    payload: error,
  };
};

export const updateAppConfigs = (payload, id) => {
  return {
    type: UPDATE_APP_CONFIGS,
    payload: { payload, id },
  };
};

export const updateAppConfigsSuccessful = (payload) => {
  return {
    type: UPDATE_APP_CONFIGS_SUCCESSFUL,
    payload: payload,
  };
};

export const updateAppConfigsError = (error) => {
  return {
    type: UPDATE_APP_CONFIGS_API_FAILED,
    payload: error,
  };
};

export const updateAppConfigItem = (payload, id) => {
  return {
    type: UPDATE_APP_CONFIG_ITEM,
    payload: { payload, id },
  };
};

export const updateAppConfigItemSuccessful = (payload) => {
  return {
    type: UPDATE_APP_CONFIG_ITEM_SUCCESSFUL,
    payload: payload,
  };
};

export const updateAppConfigItemError = (error) => {
  return {
    type: UPDATE_APP_CONFIG_ITEM_API_FAILED,
    payload: error,
  };
};

export const deleteAppConfigs = (id) => {
  return {
    type: DELETE_APP_CONFIGS,
    payload: id,
  };
};

export const deleteAppConfigsSuccessful = (payload) => {
  return {
    type: DELETE_APP_CONFIGS_SUCCESSFUL,
    payload: payload,
  };
};

export const deleteAppConfigsError = (error) => {
  return {
    type: DELETE_APP_CONFIGS_API_FAILED,
    payload: error,
  };
};

export const deleteAppConfigItem = (id) => {
  return {
    type: DELETE_APP_CONFIG_ITEM,
    payload: id,
  };
};

export const deleteAppConfigItemSuccessful = (payload) => {
  return {
    type: DELETE_APP_CONFIG_ITEM_SUCCESSFUL,
    payload: payload,
  };
};

export const deleteAppConfigItemError = (error) => {
  return {
    type: DELETE_APP_CONFIG_ITEM_API_FAILED,
    payload: error,
  };
};

export const createAccessControlFE = (payload) => {
  return {
    type: CREATE_ACCESS_CONTROL_FE,
    payload,
  };
};

export const createAccessControlFESuccessful = (payload) => {
  return {
    type: CREATE_ACCESS_CONTROL_FE_SUCCESSFUL,
    payload: payload,
  };
};

export const createAccessControlFEError = (error) => {
  return {
    type: CREATE_ACCESS_CONTROL_FE_API_FAILED,
    payload: error,
  };
};

export const listAccessControlFE = (payload) => {
  return {
    type: LIST_ACCESS_CONTROL_FE,
    payload,
  };
};

export const listAccessControlFESuccessful = (payload) => {
  return {
    type: LIST_ACCESS_CONTROL_FE_SUCCESSFUL,
    payload: payload,
  };
};

export const listAccessControlFEError = (error) => {
  return {
    type: LIST_ACCESS_CONTROL_FE_API_FAILED,
    payload: error,
  };
};

export const listEachAccessControlFE = (payload) => {
  return {
    type: LIST_EACH_ACCESS_CONTROL_FE,
    payload,
  };
};

export const listEachAccessControlFESuccessful = (payload) => {
  return {
    type: LIST_EACH_ACCESS_CONTROL_FE_SUCCESSFUL,
    payload: payload,
  };
};

export const listEachAccessControlFEError = (error) => {
  return {
    type: LIST_EACH_ACCESS_CONTROL_FE_API_FAILED,
    payload: error,
  };
};

export const updateAccessControlFE = (payload, id) => {
  return {
    type: UPDATE_ACCESS_CONTROL_FE,
    payload: { payload, id },
  };
};

export const updateAccessControlFESuccessful = (payload) => {
  return {
    type: UPDATE_ACCESS_CONTROL_FE_SUCCESSFUL,
    payload: payload,
  };
};

export const updateAccessControlFEError = (error) => {
  return {
    type: UPDATE_ACCESS_CONTROL_FE_API_FAILED,
    payload: error,
  };
};

export const deleteAccessControlFE = (payload) => {
  return {
    type: DELETE_ACCESS_CONTROL_FE,
    payload,
  };
};

export const deleteAccessControlFESuccessful = (payload) => {
  return {
    type: DELETE_ACCESS_CONTROL_FE_SUCCESSFUL,
    payload: payload,
  };
};

export const deleteAccessControlFEError = (error) => {
  return {
    type: DELETE_ACCESS_CONTROL_FE_API_FAILED,
    payload: error,
  };
};
