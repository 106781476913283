import React from 'react'
// import MetaTags from "react-meta-tags"

import Form from './form'

const BankForm = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Form />
      </div>
    </React.Fragment>
  )
}

export default BankForm
