import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  Input,
  NavLink,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import { withRouter } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { CSVLink } from 'react-csv';
import Moment from 'moment';
import moment from 'moment-timezone';
import TimerCountDown from './Components/TimerCountDown';
import Loader from '../../../../components/Common/Loading/index.js';
import { QueryReallignment } from '../../../../utils/general';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { ScrollToTop } from '../../../../utils/general';

let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const DriverTable = ({
  usersData,
  fetchDriverLogs,
  message,
  history,
  fetchDriverLogCSV,
  fetchDriverLogCSVError,
  log_csv,
  log_csv_loading,
}) => {
  const [data, setData] = useState(null);
  const [refreshTimer, setRefreshTimer] = useState(0);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [startTimer, setStartTimer] = useState(0);
  const [activeTab, SetActiveTab] = useState('1');
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [Status, setStatus] = useState('');

  const toggleTab = (tab) => {
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      status:
        tab === '2'
          ? 'AVAILABLE'
          : tab === '3'
          ? 'BUSY'
          : tab === '4'
          ? 'OFFLINE'
          : tab === '5'
          ? 'NONE'
          : '',
      search,
    };
    setStatus(
      `${
        tab === '2'
          ? 'AVAILABLE'
          : tab === '3'
          ? 'BUSY'
          : tab === '4'
          ? 'OFFLINE'
          : tab === '5'
          ? 'NONE'
          : ''
      }`
    );

    if (activeTab !== tab) {
      SetActiveTab(tab);
    }
    fetchDriverLogs(pageInfo);
  };

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(usersData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      status: Status,
      search,
    };
    fetchDriverLogs(pageInfo);
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }

    let pageInfo = { skip, take, status: Status, search };
    fetchDriverLogs(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      status: Status,
    };
    fetchDriverLogs(queryParams);
  };
  const handleCSV = (e, values) => {
    fetchDriverLogCSV({
      take: values.endRow
        ? values.endRow - values.startRow + 1
        : usersData?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
    });
  };

  const routeToMapHadler = (url) => {
    window.open(url, '_blank') ||
      window.location.replace('https://support.wwf.org.uk');
  };

  useEffect(() => {
    if (usersData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setPageCount(Math.ceil(usersData?.total / take));
    }
  }, [usersData]);
  useEffect(() => {
    if (log_csv) {
      // SET CSV EXPORT DATA
      const csvData = log_csv?.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Driver_Id: data?.driverId,
          Full_Name: `${data.firstName} - ${data.lastName}`,
          Display_Name: data.displayName ? data.displayName : '----',
          Source: data?.source,
          Email: data?.email ? data.email : '----',
          Address: data?.address ? data?.address : 'NA',
          Cordinates: data?.lat
            ? `lat: ${data.lat} - lng: ${data.lng}`
            : '----',
          Status: data?.status,
          Date: data?.createdAt?.substring(0, 10),
        };

        return formData;
      });

      setCsvData(csvData);
    }
  }, [log_csv]);

  useEffect(() => {
    if (!showCSVModal) {
      fetchDriverLogCSVError();
    }
  }, [showCSVModal]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          status: Status,
        };
        fetchDriverLogs(queryParams);
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    const interval = setInterval(() => {
      let time = startTimer + 1;
      setStartTimer((prevState) => ({
        ...prevState,
        startTimer: time,
      }));
      let queryParams = {
        search,
        take,
        skip,
        status: Status,
      };
      fetchDriverLogs(queryParams);
    }, 60000);
    return () => clearInterval(interval);
  }, [refreshTimer]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchDriverLogs(parsedUrlParams);
  }, [fetchDriverLogs]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      status: Status,
      take,
      skip,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        search,
        status: Status,
        take,
        skip,
      };
      return params;
    }
    // update State with url param if available
    if (parsedUrlParams?.Status === 'AVAILABLE') {
      SetActiveTab('2');
      setStatus(parsedUrlParams?.Status);
    } else if (parsedUrlParams?.Status === 'BUSY') {
      SetActiveTab('3');
      setStatus(parsedUrlParams?.Status);
    }
    if (parsedUrlParams?.Status === 'OFFLINE') {
      SetActiveTab('4');
      setStatus(parsedUrlParams?.Status);
    } else if (parsedUrlParams?.Status === 'NONE') {
      SetActiveTab('5');
      setStatus(parsedUrlParams?.Status);
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };
  const tableDataHandler = () => {
    const rowData = usersData?.result?.map((data) => {
      return {
        keyId: data.id,
        id: (
          <Link
            to={`/user/${data.driverId}`}
            className="mr-3 text-dark font-weight-bold"
          >
            {data.id.substring(0, 7)}
          </Link>
        ),
        location:
          data.country || data.state != null
            ? `${data?.country} , ${data?.state}`
            : '__',
        email: data.email ? data.email : '----',
        fullName: (
          <div className="text-capitalize">
            {data.firstName} {data.lastName}
          </div>
        ),
        map_location: data.lat && data.lng && (
          <Link
            to="#"
            // to={`https://www.google.com/maps/@${data.lat},${data.lng},17z`}
            onClick={() =>
              routeToMapHadler(
                `https://www.google.com/maps/@${data.lat},${data.lng},17z`
              )
            }
            className="mr-3 text-dark font-weight-bold"
          >
            <div className="d-flex align-items-center bg-white shadow-lg border rounded">
              <div>
                <i className=" ri-send-plane-fill font-size-18 mx-2 text-muted" />
              </div>
              <div>
                <div className=" d-block mb-1 font-size-12">
                  Lng:
                  <span className="ml-2 badge badge-soft-secondary text-wrap">
                    {data?.lng.toFixed(5)}
                  </span>
                </div>
                <div className=" d-block mb-1 text-wrap font-size-12">
                  Lat:
                  <span className=" ml-2 badge badge-soft-secondary">
                    {data?.lat.toFixed(5)}
                  </span>
                </div>
              </div>
            </div>
          </Link>
        ),
        address: data.address ? (
          <span>
            <span className=" d-block mb-1 text-wrap">
              <span className="badge badge-soft-secondary text-wrap">
                {data?.address.split(',')[0]},{data?.address.split(',')[1]}
              </span>
            </span>
          </span>
        ) : (
          'NA'
        ),
        driver: (
          <Link to={`/user/${data.id}`} className="text-dark font-weight-bold">
            <div className="d-flex align-items-center">
              <img
                src={data?.picture ? data?.picture : avatarImg}
                alt=""
                height="20"
                className="rounded-circle avatar-xs mr-2"
              />

              <div>
                <div className="text-capitalize">
                  {data.firstName} {data.lastName}
                </div>

                <span className="text-muted ml-2 badge badge-soft-secondary">
                  <i className=" fas fa-star text-warning mr-1" />
                  {data?.rating ? data?.rating?.toFixed(2) : 0}
                </span>
                {data.gender && (
                  <span
                    className={`text-white mr-2 badge badge-${
                      data.gender === 'MALE' ? 'primary' : 'danger'
                    }`}
                  >
                    <i
                      className={`ri-${
                        data.gender === 'MALE' ? 'men' : 'women'
                      }-line mr-1`}
                    />
                    {data.gender}
                  </span>
                )}
              </div>
            </div>
          </Link>
        ),
        date: (
          <div>
            {' '}
            <div>{Moment(data.createdAt).format('l')}</div>
            <div className="badge badge-soft-secondary font-size-10">
              {' '}
              {Moment(data.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
        // phone_no: data.phone ? data.phone : '----',
        // rating: data?.rating.toFixed(1) ? data?.rating.toFixed(1) : '---',
        // trip: data.trips,
        status: (
          <div
            className={`badge badge-soft-${
              data?.status === 'NONE'
                ? 'danger'
                : data?.status === 'BUSY'
                ? 'warning'
                : data?.status === 'AVAILABLE'
                ? 'success'
                : 'secondary'
            } font-size-12`}
          >
            {data?.status === 'OFFLINE'
              ? 'NOT AVAILABLE'
              : data?.status === 'NONE'
              ? 'OFFLINE'
              : data?.status}
          </div>
        ),
      };
    });
    return {
      columns: [
        // {
        //   text: 'Log Id',
        //   dataField: 'id',
        // },
        {
          text: 'Name',
          dataField: 'fullName',
        },

        {
          text: 'Email',
          dataField: 'email',
        },
        {
          text: 'Address',
          dataField: 'address',
        },
        {
          text: 'Map Location',
          dataField: 'map_location',
        },
        {
          text: 'Registered Date',
          dataField: 'date',
        },
        {
          text: 'Status',
          dataField: 'status',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <Nav tabs className="nav-tabs-custom">
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab('1');
                      }}
                      className={classnames(
                        { active: activeTab === '1' },
                        'font-weight-bold p-3'
                      )}
                    >
                      All Driver Logs
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab('2');
                      }}
                      className={classnames(
                        { active: activeTab === '2' },
                        'p-3 font-weight-bold'
                      )}
                    >
                      AVAILABLE
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab('3');
                      }}
                      className={classnames(
                        { active: activeTab === '3' },
                        ' p-3 font-weight-bold'
                      )}
                    >
                      ON TRIP
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab('4');
                      }}
                      className={classnames(
                        { active: activeTab === '4' },
                        ' p-3 font-weight-bold'
                      )}
                    >
                      NOT AVAILABLE
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab('5');
                      }}
                      className={classnames(
                        { active: activeTab === '5' },
                        ' p-3 font-weight-bold'
                      )}
                    >
                      OFFLINE
                    </NavLink>
                  </NavItem>
                </Nav>
                <div className="d-flex align-items-center">
                  <TimerCountDown startTimer={startTimer} />
                  <div className="">
                    <Button
                      size="sm"
                      color="primary"
                      className="mt-1"
                      onClick={() => setShowCSVModal(true)}
                      disabled={usersData?.total > 0 ? false : true}
                    >
                      <i className=" fas fa-cloud-download-alt mr-1" />
                      CSV
                    </Button>
                  </div>
                  <Link to={`/drivers-records`}>
                    <button size="sm" className="btn btn-sm btn-primary ml-2">
                      <i className="mdi mdi-database mr-1" />
                      All Records
                    </button>
                  </Link>
                </div>
              </div>
              {!isLoading && data !== null ? (
                <>
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <Input
                        bsSize="sm"
                        id="take"
                        name="take"
                        type="select"
                        onChange={handleLimitChange}
                      >
                        <option>{take}</option>
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                      </Input>
                    </div>

                    <div>
                      <Input
                        bsSize="sm"
                        id="search"
                        value={search}
                        name="search"
                        onChange={SearchHandler}
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField="keyId"
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses="table-responsive"
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    // breakLabel={'...'}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={3}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    //Bootstrap styling
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* CSV DOWNLOAD MODAL */}
      <Modal
        isOpen={showCSVModal}
        toggle={() => setShowCSVModal(!showCSVModal)}
      >
        <ModalHeader toggle={() => setShowCSVModal(false)}>
          Generate CSV
        </ModalHeader>
        <ModalBody>
          <AvForm className="form-horizontal" onValidSubmit={handleCSV}>
            <Col>
              <FormGroup>
                <Label htmlFor="startRow">Start (Row)</Label>
                <AvField
                  name="startRow"
                  type="number"
                  max={usersData?.total - 1}
                  className="form-control"
                  id="startRow"
                  placeholder="Enter the row to start from"
                  errorMessage="value must be greater than 0 and less than total data"
                  min="1"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="endRow">End (Row)</Label>
                <AvField
                  name="endRow"
                  type="number"
                  className="form-control"
                  min="1"
                  max={usersData?.total}
                  id="endRow"
                  placeholder="Enter the row to end"
                  helpMessage={
                    usersData?.total
                      ? `Total data is ${usersData?.total}`
                      : null
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Button
              size="sm"
              color="primary"
              className="ml-3 waves-effect waves-light "
              type="submit"
            >
              {log_csv_loading ? 'Generating...' : 'Generate'}
            </Button>
            {log_csv && (
              <CSVLink
                data={csvData}
                filename={'LatestDrivers_log_.csv'}
                className="btn-sm btn-success ml-2"
                target="_blank"
                onClick={() => setShowCSVModal(false)}
              >
                Download
              </CSVLink>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(DriverTable);
