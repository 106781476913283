import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Alert,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { withRouter, Link } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import GreySummaryCard from '../../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../../components/Common/Summary/actionCheck';
import SOSTable from './sosTable';
import SOSPreview from './previewSOS';
import {
  fetchSOS,
  updateSOS,
  updateSOSFailed,
} from '../../../../store/actions';

const SOS = ({
  all_sos,
  sosError,
  tableError,
  fetchSOS,
  loading,
  message,
  history,
  updateSOS,
  updateSOSFailed,
  accessControl
}) => {
  const [showModal, setShowModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [statusError, setStatusError] = useState(false);
  const breadcrumbItems = [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'SOS', link: '#' },
  ];

  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };

  const statusIdHandler = (id, e) => {
    setShowStatusModal(true);
    setPreviewId(id);
    setStatus(e);
  };
  useEffect(() => {
    // updateSOSFailed('');
    if (message) {
      setTimeout(() => {
        setShowStatusModal(false);
        // updateSOSFailed('');
      }, 2000);
    }
  }, [message, updateSOSFailed]);

  const handleStatusUpdate = (event, values) => {
    setStatusError(null);
    if (values.Status === 'undefined') {
      setStatusError('Please change the status');
      return;
    }

    updateSOS({ status: values.Status }, previewId);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='SOS' breadcrumbItems={breadcrumbItems} />

          <GreySummaryCard field='Emergency' subModule='SOS Info'>
            <>
              {message && (
                <Alert color='success' className='text-center'>
                  {message}
                </Alert>
              )}
              {sosError ? (
                <Alert color='danger' className='text-center'>
                  {sosError}
                </Alert>
              ) : (
                <Row>
                  <Col>
                    <SOSTable
                      sosData={all_sos}
                      tableError={tableError}
                      loading={loading}
                      fetchSOS={fetchSOS}
                      history={history}
                      message={message}
                      previewAction={previewHandler}
                      statusIdHandler={statusIdHandler}
                      CheckAction={CheckAction}
                      ReadCheck={ReadCheck}
                      accessControl={accessControl}
                    />
                  </Col>
                </Row>
              )}
              {/* SOS Preview Modal */}
              <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
                <ModalHeader toggle={() => setShowModal(false)}>
                  Preview SOS
                </ModalHeader>
                <ModalBody>
                  <SOSPreview previewId={previewId} />
                </ModalBody>
              </Modal>

              <Modal
                isOpen={showStatusModal}
                toggle={() => setShowStatusModal(!showStatusModal)}
              >
                <ModalHeader toggle={() => setShowStatusModal(false)}>
                  Change Status
                </ModalHeader>
                <ModalBody>
                  {message && (
                    <Alert color='success' className='text-center'>
                      {message}
                    </Alert>
                  )}
                  {statusError && (
                    <Alert className='text-center' color='danger'>
                      {statusError}
                    </Alert>
                  )}
                  {sosError && (
                    <Alert color='danger' className='text-center'>
                      {sosError}
                    </Alert>
                  )}
                  <div>
                    <AvForm
                      className='form-horizontal'
                      onValidSubmit={handleStatusUpdate}
                    >
                      <Row>
                        <Col md='9'>
                          <FormGroup className='mb-4'>
                            <AvField
                              className='form-control bg-light'
                              type='select'
                              name='Status'
                              label='Status'
                              value={status}
                              disabled={statusError ? true : false}
                            >
                              <option value={'undefined'}>Change Status</option>
                              <option value={'PENDING'}>Pending</option>
                              <option value={'REVIEW'}>Review</option>
                              <option value={'CLOSED'}>Closed</option>
                            </AvField>
                          </FormGroup>
                        </Col>
                      </Row>

                      <button className='btn btn-success mr-2' type='submit'>
                        {loading ? 'Submitting ...' : 'Submit'}
                      </button>
                      <Link to='#' onClick={() => setShowStatusModal(false)}>
                        <button className='btn btn-secondary'>Close</button>
                      </Link>
                    </AvForm>
                  </div>
                </ModalBody>
              </Modal>
            </>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { all_sos, loading, sosError, message, tableError } = state.Contact;
  return { accessControl, all_sos, tableError, loading, sosError, message };
};

export default connect(mapStateToProps, {
  fetchSOS,
  updateSOSFailed,
  updateSOS,
})(withRouter(SOS));
