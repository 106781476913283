export const FETCH_TOURS = "FETCH_TOURS";
export const FETCH_TOURS_SUCCESSFUL = "FETCH_TOURS_SUCCESSFUL";
export const FETCH_TOURS_API_FAILED = "FETCH_TOURS_API_FAILED";

export const FETCH_EACH_TOUR = "FETCH_EACH_TOUR";
export const FETCH_EACH_TOUR_SUCCESSFUL = "FETCH_EACH_TOUR_SUCCESSFUL";
export const FETCH_EACH_TOUR_API_FAILED = "FETCH_EACH_TOUR_API_FAILED";

export const DELETE_TOUR = "DELETE_TOUR";
export const DELETE_TOUR_SUCCESSFUL = "DELETE_TOUR_SUCCESSFULLY";
export const DELETE_TOUR_FAILED = "DELETE_TOUR_FAILED";

export const CREATE_TOUR = "CREATE_TOUR";
export const CREATE_TOUR_SUCCESSFUL = "CREATE_TOUR_SUCCESSFULLY";
export const CREATE_TOUR_FAILED = "CREATE_TOUR_FAILED";

export const UPDATE_TOUR = "UPDATE_TOUR";
export const UPDATE_TOUR_SUCCESSFUL = "UPDATE_TOUR_SUCCESSFULLY";
export const UPDATE_TOUR_FAILED = "FAILED";
