import {
  FETCH_COMPLAINT_SUMMARY,
  FETCH_COMPLAINT_SUMMARY_SUCCESSFULLY,
  FETCH_COMPLAINT_SUMMARY_FAILED,
  FETCH_COMPLAINT,
  FETCH_MY_COMPLAINT,
  FETCH_COMPLAINT_SUCCESSFUL,
  FETCH_COMPLAINT_API_FAILED,
  ASSIGN_COMPLAINT,
  ASSIGN_COMPLAINT_SUCCESSFUL,
  ASSIGN_COMPLAINT_API_FAILED,
  FETCH_EACH_COMPLAINT,
  FETCH_EACH_COMPLAINT_SUCCESSFUL,
  FETCH_EACH_COMPLAINT_API_FAILED,
  DELETE_COMPLAINT,
  DELETE_COMPLAINT_SUCCESSFUL,
  DELETE_COMPLAINT_FAILED,
  CREATE_COMPLAINT,
  CREATE_COMPLAINT_SUCCESSFUL,
  CREATE_COMPLAINT_FAILED,
  UPDATE_COMPLAINT,
  UPDATE_COMPLAINT_SUCCESSFUL,
  UPDATE_COMPLAINT_FAILED,
  FETCH_FOR_COMPLAINT_CSV,
  FETCH_FOR_MY_COMPLAINT_CSV,
  FETCH_FOR_COMPLAINT_CSV_SUCCESSFULLY,
  FETCH_FOR_COMPLAINT_CSV_ERROR,
} from './actionTypes';

export const fetchComplaint = (payload) => {
  return {
    type: FETCH_COMPLAINT,
    payload,
  };
};

export const fetchMyComplaint = (payload) => {
  return {
    type: FETCH_MY_COMPLAINT,
    payload,
  };
};

export const fetchComplaintSuccessful = (payload) => {
  return {
    type: FETCH_COMPLAINT_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchComplaintError = (error) => {
  return {
    type: FETCH_COMPLAINT_API_FAILED,
    payload: error,
  };
};

export const fetchEachComplaint = (id) => {
  return {
    type: FETCH_EACH_COMPLAINT,
    payload: id,
  };
};

export const fetchEachComplaintSuccessful = (payload) => {
  return {
    type: FETCH_EACH_COMPLAINT_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachComplaintError = (error) => {
  return {
    type: FETCH_EACH_COMPLAINT_API_FAILED,
    payload: error,
  };
};

export const deleteComplaint = (id) => {
  return {
    type: DELETE_COMPLAINT,
    payload: id,
  };
};

export const deleteComplaintSuccessful = (payload) => {
  return {
    type: DELETE_COMPLAINT_SUCCESSFUL,
    payload,
  };
};

export const deleteComplaintFailed = (error) => {
  return {
    type: DELETE_COMPLAINT_FAILED,
    payload: error,
  };
};

export const createComplaint = (payload) => {
  return {
    type: CREATE_COMPLAINT,
    payload,
  };
};

export const createComplaintSuccessful = (payload) => {
  return {
    type: CREATE_COMPLAINT_SUCCESSFUL,
    payload,
  };
};

export const createComplaintFailed = (error) => {
  return {
    type: CREATE_COMPLAINT_FAILED,
    payload: error,
  };
};

export const updateComplaint = (payload) => {
  return {
    type: UPDATE_COMPLAINT,
    payload,
  };
};

export const updateComplaintFailed = (error) => {
  return {
    type: UPDATE_COMPLAINT_FAILED,
    payload: error,
  };
};

export const updateComplaintSuccessful = (payload) => {
  return {
    type: UPDATE_COMPLAINT_SUCCESSFUL,
    payload,
  };
};

export const assignComplaint = (payload) => {
  return {
    type: ASSIGN_COMPLAINT,
    payload,
  };
};

export const assignComplaintSuccessful = (payload) => {
  return {
    type: ASSIGN_COMPLAINT_SUCCESSFUL,
    payload: payload,
  };
};

export const assignComplaintError = (error) => {
  return {
    type: ASSIGN_COMPLAINT_API_FAILED,
    payload: error,
  };
};

//SUMMARY ENTRIES
export const fetchComplaintSummary = (query) => {
  return {
    type: FETCH_COMPLAINT_SUMMARY,
    payload: query,
  };
};

export const fetchComplaintSummarySuccessfully = (payload) => {
  return {
    type: FETCH_COMPLAINT_SUMMARY_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchComplaintSummaryFailed = (error) => {
  return {
    type: FETCH_COMPLAINT_SUMMARY_FAILED,
    payload: error,
  };
};

export const fetchDataForComplaintsCSV = (payload) => {
  return {
    type: FETCH_FOR_COMPLAINT_CSV,
    payload,
  };
};

export const fetchDataForMyComplaintsCSV = (payload) => {
  return {
    type: FETCH_FOR_MY_COMPLAINT_CSV,
    payload,
  };
};

export const fetchForCSVSuccessfully = (payload) => {
  return {
    type: FETCH_FOR_COMPLAINT_CSV_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchForCSVError = (error) => {
  return {
    type: FETCH_FOR_COMPLAINT_CSV_ERROR,
    payload: error,
  };
};
