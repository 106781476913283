import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import HistoryTable from './historyTable';
import GreySummaryCard from '../../../../components/Common/Summary/index';

const Loyalty = ({ history }) => {
  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'Loyalty', link: '/loyalty_history' },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Loyalty History"
            breadcrumbItems={breadcrumbItems}
          />
          <GreySummaryCard field="Loyalty" subModule="Usage History">
            <>
              <Row>
                <Col>
                  <HistoryTable history={history} />
                </Col>
              </Row>
            </>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Loyalty;
