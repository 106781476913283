import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';


const Index = ({ children, accessControl, subModule, field, iconType }) => {
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    if (subModule) {
      if (
        accessControl?.result[0]?.data?.modules[field]?.[subModule]?.action?.length > 0
      ) {
        setPreview(accessControl?.result[0]?.data?.modules[field]?.[subModule]?.action);
      } else {
        setPreview(false);
      }
    } else {
      if (
        accessControl?.result[0]?.data?.modules[field]?.action?.length > 0
      ) {
        setPreview(accessControl?.result[0]?.data?.modules[field]?.action);
      } else {
        setPreview(false);
      }
    }
  }, [accessControl]);


  // console.log(preview)
  if (preview) {

    if (preview.includes(iconType)) {
      if (iconType === 'Create') {
        return null
      }
      return <div className='mx-1'>--</div>;
    }

    // if (!preview.includes('Read')) {

    //   return { children };
    // }
    else {
      return <div>{children}</div>;
    }
  }

  // return <em>--</em>
  return <div>{children}</div>;
};

export const ReadCheck = (accessControl, field, subModule) => {
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    if (subModule) {
      if (
        accessControl?.result[0]?.data?.modules[field]?.[subModule]?.action?.length > 0
      ) {
        setPreview(accessControl?.result[0]?.data?.modules[field]?.[subModule]?.action);
      } else {
        setPreview(false);
      }
    } else {
      if (
        accessControl?.result[0]?.data?.modules[field]?.action?.length > 0
      ) {
        setPreview(accessControl?.result[0]?.data?.modules[field]?.action);
      } else {
        setPreview(false);
      }
    }
  }, [accessControl]);


  // console.log(preview)
  if (preview && preview.includes('Read')) {
    return false

  }

  return true;
};
const mapStateToProps = (state) => {
  const { accessControl } = state.Account;

  return { accessControl };
};

export default connect(mapStateToProps)(Index);
