import React, { useState, useEffect } from 'react';
import {
  Card,
  Alert,
  CardBody,
  Row,
  FormGroup,
  Col,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';

import ReactPaginate from 'react-paginate';

import Loader from '../../../components/Common/Loading/index.js';
import Moment from 'moment';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import FilterDropDown from '../../../components/Common/FilterDropDown';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../utils/general';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import queryString from 'query-string';

let skip = 0;
let dataLimit = 10;

const BetaEmailsTable = ({
  emails,
  notificationError,
  fetchBetaEmails,
  history,
  message,
  fetchForCSVError,
  fetchDataForCSV,
  csv,
  csv_loading,
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [skipPage, setSkipPage] = useState(0);
  const [menu, setMenu] = useState(false);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'M').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });
  const [csvData, setCsvData] = useState([]);
  const [showCSVModal, setShowCSVModal] = useState(false);

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(emails?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip,
      take: dataLimit,
      start: date.start,
      end: date.end,
      search,
    };
    fetchBetaEmails(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take, search, start: date.start, end: date.end };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchBetaEmails(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      skip,
      start: date.start,
      end: date.end,
    };
    SetUrlWithParams();
    fetchBetaEmails(queryParams);
  };
  const handleCSV = (e, values) => {
    fetchDataForCSV({
      start: date.start,
      end: date.end,
      take: values.endRow ? values.endRow - values.startRow + 1 : emails?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
    });
  };
  useEffect(() => {
    if (emails) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(emails?.total / take));
    }
  }, [emails]);
  useEffect(() => {
    if (csv) {
      // SET CSV EXPORT DATA
      const csvData = csv?.result.map((data, i) => {
        let formData = {
          No: i + 1,
          Emails: data.email,
          Created_Date: data?.date?.substring(0, 10),
        };

        return formData;
      });

      setCsvData(csvData);
    }
  }, [csv]);

  useEffect(() => {
    if (!showCSVModal) {
      fetchForCSVError();
    }
  }, [showCSVModal]);
  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchBetaEmails(parsedUrlParams);
  }, []);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      start: date.start,
      end: date.end,
      skip: pageNumber,
      take,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        start: date.start,
        end: date.end,
        skip,
        take,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }

    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          start: date.start,
          end: date.end,
        };
        fetchBetaEmails(queryParams);
      }, 2000);
    }
  }, [fetchBetaEmails, message]);

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    setMenu(false);
    let queryParams = {
      search,
      take,
      skip,
      start: values.startDate,
      end: values.endDate,
    };
    fetchBetaEmails(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    });
    let queryParams = {
      search,
      take,
      skip,
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    };
    fetchBetaEmails(queryParams);
  };

  const tableDataHandler = () => {
    let rowData = emails?.result?.map((data, i) => {
      return {
        keyId: i,
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,
        // id: (
        //   <Link to={`#`} className='mr-3 text-dark font-weight-bold'>
        //     {data.id.substring(0, 7)}
        //   </Link>
        // ),
        email: data.email,
        date: (
          <div>
            {' '}
            <div>{moment(data.date).format('DD MMM, YYYY')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data.date).format('hh:mm A')}
            </div>
          </div>
        ),

        // date: (
        //   <div>
        //     <div>{Moment(data.createdAt).format('l')}</div>
        //     <div className='badge badge-soft-secondary font-size-10'>
        //       {' '}
        //       {Moment(data.createdAt).format('hh:mm A')}
        //     </div>
        //   </div>
        // ),
      };
    });

    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
        },

        {
          text: 'Email',
          dataField: 'email',
          sort: true,
        },
        {
          text: 'Date & Time',
          dataField: 'date',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {notificationError !== null ? (
                <Alert color='danger' className='text-center'>
                  {notificationError}
                </Alert>
              ) : (
                <>
                  <div className='d-flex justify-content-end align-items-center mb-2'>
                    <div>
                      <Button
                        size='sm'
                        color='primary'
                        className='mt-1'
                        onClick={() => setShowCSVModal(true)}
                        disabled={emails?.total > 0 ? false : true}
                      >
                        <i className=' fas fa-cloud-download-alt mr-1' />
                        CSV
                      </Button>
                    </div>
                    <div>
                      <FilterDropDown
                        menu={menu}
                        toggleMenu={() => setMenu(!menu)}
                      >
                        <div>
                          <AvForm
                            className='form-horizontal'
                            onValidSubmit={handleDateFilter}
                          >
                            <SimpleBar
                              style={{
                                maxHeight: '250px',
                              }}
                            >
                              <Row className='px-3  pt-3 pb-0'>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='startDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='startDate'
                                      value={date.start}
                                      helpMessage='Start Date'
                                      placeholder='Please choose the start date...'
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='endDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='endDate'
                                      value={date.end}
                                      helpMessage='End Date'
                                      placeholder='Please choose the end date...'
                                      required
                                    />
                                  </FormGroup>
                                </Col>{' '}
                              </Row>
                            </SimpleBar>

                            <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                              <Link
                                to='#'
                                onClick={resetHandler}
                                className='btn btn-sm  bg-light font-size-14'
                              >
                                <i className=' mdi mdi-refresh mr-1'> Reset</i>
                              </Link>

                              <button
                                className='btn btn-success btn-sm mr-2'
                                type='submit'
                              >
                                <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                Send
                              </button>
                            </div>
                          </AvForm>
                        </div>
                      </FilterDropDown>
                    </div>
                  </div>
                  {!isLoading && data !== null ? (
                    <>
                      <div className='d-flex justify-content-between mb-2'>
                        <div>
                          <Input
                            bsSize='sm'
                            id='take'
                            name='take'
                            type='select'
                            onChange={handleLimitChange}
                            value={take}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div>
                          <Input
                            bsSize='sm'
                            id='search'
                            value={search}
                            name='search'
                            onChange={SearchHandler}
                            placeholder='Search'
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField='keyId'
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses='table-responsive'
                        noDataIndication={'No Data to Display'}
                        defaultSorted={[{ dataField: 'index', order: 'desc' }]}
                        // sort={{ dataField: 'name', order: 'asc' }}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        // breakLabel={'...'}
                        // marginPagesDisplayed={2}
                        // pageRangeDisplayed={3}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        forcePage={skipPage}
                        //Bootstrap styling
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* CSV DOWNLOAD MODAL */}
      <Modal
        isOpen={showCSVModal}
        toggle={() => setShowCSVModal(!showCSVModal)}
      >
        <ModalHeader toggle={() => setShowCSVModal(false)}>
          Generate CSV
        </ModalHeader>
        <ModalBody>
          <AvForm className='form-horizontal' onValidSubmit={handleCSV}>
            <Col>
              <FormGroup>
                <Label htmlFor='startRow'>Start (Row)</Label>
                <AvField
                  name='startRow'
                  type='number'
                  max={emails?.total - 1}
                  className='form-control'
                  id='startRow'
                  placeholder='Enter the row to start from'
                  errorMessage='value must be greater than 0 and less than total data'
                  min='1'
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor='endRow'>End (Row)</Label>
                <AvField
                  name='endRow'
                  type='number'
                  className='form-control'
                  min='1'
                  max={emails?.total}
                  id='endRow'
                  placeholder='Enter the row to end'
                  helpMessage={
                    emails?.total ? `Total data is ${emails?.total}` : null
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Button
              size='sm'
              color='primary'
              className='ml-3 waves-effect waves-light '
              type='submit'
            >
              {csv_loading ? 'Generating...' : 'Generate'}
            </Button>
            {csv && (
              <CSVLink
                data={csvData}
                filename={'emails_data.csv'}
                className='btn-sm btn-success ml-2'
                target='_blank'
                onClick={() => setShowCSVModal(false)}
              >
                Download
              </CSVLink>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default BetaEmailsTable;
