/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { Alert, Row, Col, Table } from 'reactstrap';
import Loader from '../../../../components/Common/Loading/index.js';
import { fetchEachLicenseKey } from '../../../../store/actions.js';
import { Link } from 'react-router-dom';

const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const PreviewLicenseKey = ({
  licenseKey,
  previewId,
  loading,
  licenseKeyError,
  fetchEachLicenseKey,
}) => {
  useEffect(() => {
    fetchEachLicenseKey(previewId);
  }, [fetchEachLicenseKey, previewId]);

  return (
    <React.Fragment>
      <div className='mt-3'>
        {licenseKeyError && !loading ? (
          <Alert color='danger' className='text-center'>
            {licenseKeyError}
          </Alert>
        ) : (
          <>
            {licenseKey === null ? (
              <Loader />
            ) : (
              <Row>
                <Col sm={12}>
                  <div>
                    <Table hover>
                      <tbody>
                        <tr>
                          <td className='font-weight-bold'>
                            Name{' '}
                          </td>
                          <td className='text-uppercase font-size-16 font-weight-bold text-success'>
                            {licenseKey?.name}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>
                            Key{' '}
                          </td>
                          <td className='text-uppercase font-size-16 font-weight-bold text-success'>
                            {licenseKey?.key}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Description</td>
                          <td className='text-uppercase'>
                            {licenseKey?.description}
                          </td>
                        </tr>

                        <tr>
                          <td className='font-weight-bold'>Zones</td>
                          <td className='text-uppercase'>
                            {licenseKey?.zones?.length > 0
                              ? licenseKey?.zones?.map((zone) => (
                                <div key={zone.id}>
                                  {' '}
                                  <h1 className={`badge badge-soft-secondary font-size-12`}>
                                    {' '}
                                    {zone.name}
                                  </h1>{' '}
                                </div>
                              ))
                              : 'No zone restriction'}
                          </td>
                        </tr>

                        <tr>
                          <td className='font-weight-bold'>Created At</td>
                          <td className='text-uppercase'>
                            {Moment(licenseKey.createdAt).format('l')}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Updated At</td>
                          <td className='text-uppercase'>
                            {Moment(licenseKey.updatedAt).format('l')}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { licenseKey, loading, licenseKeyError } = state.LicenseKey;
  return { licenseKey, loading, licenseKeyError };
};

export default connect(mapStateToProps, {
  fetchEachLicenseKey,
})(PreviewLicenseKey);
