import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { TwitterPicker } from 'react-color';
import { Link } from 'react-router-dom';
import { ScrollToTop } from '../../../utils/general';
import AlartError from '../../../components/Common/AlertCard';
import { getS3ImageUrlHandler } from '../../../utils/general';

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from 'availity-reactstrap-validation';

// actions
import { updatePackage, updatePackageFailed } from '../../../store/actions';

const UpdatePackage = ({
  updatePackage,
  defaultData,
  updatePackageFailed,
  message,
  closeUpdate,
  loading,
  setIsUpdated,
  packageError,
}) => {
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [Status, setStatus] = useState('Active');
  const [generalError, setGeneralError] = useState(null);
  const [selectedFiles, setFileUpload] = useState([]);
  const [color, setColor] = useState('');

  const handleSubmit = async (event, values) => {
    setIsLoading(true);
    setGeneralError(null);
    const packageData = {
      ...values,
      status: values.Status,
    };
    if (!color) {
      setGeneralError('color code is required');
      setIsLoading(false);
      ScrollToTop();
      return;
    }
    try {
      if (selectedFiles.length > 0) {
        let result = await getS3ImageUrlHandler(selectedFiles[0], 'package');

        packageData.picture = result?.data.result?.mediaUrl;
        packageData.pictureThumb = result?.data?.result.mediaThumbUrl;
      }
      if (color) {
        packageData.colorCode = color;
      }
      packageData.id = defaultData.id;
      packageData.capacity = Number(values.capacity);
      packageData.distancePrice = Number(values.distancePrice);
      packageData.pricingType =
        values.pricingType === 'Base Price'
          ? 'BASE'
          : values.pricingType === 'Time Price'
            ? 'TIME'
            : values.pricingType === 'Distance Price'
              ? 'DISTANCE'
              : 'TIMEDISTANCE';
      packageData.vehicle = values.vehicle;
      packageData.basePrice = Number(values.basePrice);
      packageData.timePrice = Number(values.timePrice);
      packageData.minimumPrice = Number(values.minimumPrice);
      packageData.minimumPriceDistance = Number(values.minimumPriceDistance);
      packageData.description = description;
      packageData.isAcceptShareRide =
        values.isAcceptShareRide === 'true' ? true : false;
      packageData.key = values.key.toUpperCase();
      updatePackage(packageData);

      setIsLoading(true);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setFileUpload(files);
  };

  const colorChangeHanndler = (c) => {
    setColor(c.hex);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        updatePackageFailed('');
        setIsUpdated();
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    if (defaultData && defaultData.colorCode) {
      setColor(defaultData.colorCode);
    }
    if (defaultData && defaultData.description) {
      setDescription(defaultData.description);
    }
  }, [defaultData]);

  return (
    <div>
      <Card>
        <CardBody>
          {message && (
            <Alert color='success' className='text-center'>
              {message}
            </Alert>
          )}

          {generalError && (
            <Alert color='danger' className='text-center'>
              {generalError}
            </Alert>
          )}
          {packageError && <AlartError error={packageError} />}
          <div className='float-right'>
            <Link to='#' onClick={() => closeUpdate(false)}>
              <i className='fas fa-arrow-left mr-3' />
              Back
            </Link>
          </div>
          <div>
            <h5> Update Package </h5>
          </div>
          <div>
            <AvForm
              className='form-horizontal'
              onValidSubmit={handleSubmit}
              model={defaultData}
            >
              <Row>
                <Col md={6}>
                  <FormGroup className='mb-4'>
                    <Label htmlFor='name'>Name</Label>
                    <AvField
                      name='name'
                      type='text'
                      className='form-control bg-light'
                      id='name'
                      placeholder='Enter Package name ...'
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className=' mb-4'>
                    <Label htmlFor='provider'>Provider</Label>
                    <AvField
                      name='provider'
                      type='text'
                      className='form-control bg-light'
                      id='provider'
                      placeholder='Enter Provider Name ...'
                      required
                    />
                  </FormGroup>
                </Col>
                <Col sm={12}>
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      handleAcceptedFiles(acceptedFiles)
                    }
                    multiple={false}
                    accept='image/jpeg,image/jpd'
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className='dropzone'>
                        <div
                          className='dz-message needsclick mt-2'
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className='mb-3'>
                            <i className='display-4 text-muted ri-upload-cloud-2-line'></i>
                          </div>
                          <h4>Drop/Upload Item Picture Here.</h4>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div
                    className='dropzone-previews mt-3 d-flex justify-content-between mr-3'
                    id='file-previews'
                  >
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                          key={i + '-file'}
                        >
                          <div className='p-2'>
                            <Row className='align-items-center'>
                              <Col className='col-auto'>
                                <img
                                  data-dz-thumbnail=''
                                  height='80'
                                  className='avatar-sm rounded bg-light'
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to='#'
                                  className='text-muted font-weight-bold'
                                >
                                  {f.name}
                                </Link>
                                <p className='mb-0'>
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                    {selectedFiles.length === 0 && (
                      <Card className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'>
                        <div className='p-2'>
                          <Row className='align-items-center'>
                            <Col className='col-auto'>
                              <img
                                data-dz-thumbnail=''
                                height='80'
                                className='avatar-sm rounded bg-light'
                                alt='package'
                                src={defaultData.picture}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )}
                  </div>
                </Col>

                <Col md={3}>
                  <FormGroup className='mb-4'>
                    <Label htmlFor='basePrice'>Base Price (₦)</Label>
                    <AvField
                      name='basePrice'
                      type='text'
                      className='form-control bg-light'
                      id='basePrice'
                      placeholder='Enter Base Price ...'
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className=' mb-4'>
                    <Label htmlFor='distancePrice'> Distance Price (N/m)</Label>
                    <AvField
                      name='distancePrice'
                      type='number'
                      className='form-control bg-light'
                      id='distancePrice'
                      placeholder='Enter Distance price ...'
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className='mb-4'>
                    <Label htmlFor='timePrice'>Time Price (N/s)</Label>
                    <AvField
                      name='timePrice'
                      type='number'
                      min='0'
                      className='form-control bg-light'
                      id='timePrice'
                      placeholder='Enter Time Price ...'
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className='mb-4'>
                    <Label htmlFor='capacity'>Seat Capacity</Label>
                    <AvField
                      name='capacity'
                      type='number'
                      min='0'
                      max='10'
                      className='form-control bg-light'
                      id='capacity'
                      placeholder='Enter Seat Capacitor ...'
                      required
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className='mb-4'>
                    <Label htmlFor='minimumPrice'>Minimum Price (N)</Label>
                    <AvField
                      name='minimumPrice'
                      type='number'
                      min='0'
                      className='form-control bg-light'
                      id='minimumPrice'
                      placeholder='Enter minimum price ...'
                      required
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className='mb-4'>
                    <Label htmlFor='minimumPriceDistance'>
                      Minimum Distance (m)
                    </Label>
                    <AvField
                      name='minimumPriceDistance'
                      type='number'
                      min='0'
                      className='form-control bg-light'
                      id='minimumPriceDistance'
                      placeholder='Enter Minimum PriceDistance ...'
                      required
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className=' mb-4'>
                    <AvField
                      className='form-control bg-light'
                      type='select'
                      name='pricingType'
                      label='Pricing Type'
                      value={
                        defaultData.pricingType === 'BASE'
                          ? 'Base Price'
                          : defaultData.pricingType === 'TIME'
                            ? 'Time Price'
                            : defaultData.pricingType === 'DISTANCE'
                              ? 'Distance Price'
                              : 'Distance + Base + Time Price'
                      }
                    >
                      <option>Distance + Base + Time Price</option>
                      <option>Base Price</option>
                      <option>Time Price</option>
                      <option>Distance Price</option>
                    </AvField>
                  </FormGroup>
                </Col>
                <Col md={6} className='d-flex'>
                  <Col md={6}>
                    <FormGroup className=' mb-4'>
                      <AvField
                        className='form-control bg-light'
                        type='select'
                        name='Status'
                        label='Status'
                        value={defaultData ? defaultData.status : 'ACTIVE'}
                      >
                        <option>ACTIVE</option>
                        <option>INACTIVE</option>
                        <option>COMINGSOON</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className=' mb-4'>
                      <AvField
                        className='form-control bg-light'
                        type='select'
                        name='vehicle'
                        label='Vehicle Type'
                        value={defaultData ? defaultData.vehicle : ''}
                      >
                        <option value={''}>Select Vehicle Type</option>
                        <option value={'CAR'}>CAR</option>
                        <option value={'BYKE'}>BYKE</option>
                        <option value={'TRYKE'}>TRYKE</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Col>
                <Col md={6}>
                  <FormGroup className=' mb-4'>
                    <AvField
                      className='form-control bg-light'
                      type='select'
                      name='packageType'
                      label='Type'
                      value={defaultData ? defaultData.type : 'ECONOMY'}
                    >
                      <option>ECONOMY</option>
                      <option>PREMIUM</option>
                      <option>SPECIAL</option>
                    </AvField>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup className='mb-4'>
                    <Label htmlFor='key'>Key</Label>
                    <AvField
                      name='key'
                      maxLength='5'
                      type='text'
                      className='form-control bg-light'
                      id='key'
                      placeholder='Enter Unique Key ...'
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className='mb-4'>
                    <Label htmlFor='isAcceptShareRide'>
                      Should this Type Accept Share Ride ?
                    </Label>

                    <AvRadioGroup
                      inline
                      name='isAcceptShareRide'
                      value={defaultData.isAcceptShareRide ? 'true' : 'false'}
                      required
                    >
                      <AvRadio label='Yes' value={'true'} />
                      <AvRadio label='No' value={'false'} />
                    </AvRadioGroup>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <div
                      className='p-2 rounded border-secondary text-white'
                      style={{ background: color }}
                    >
                      {' '}
                      Ride Color
                    </div>
                    <TwitterPicker
                      onChange={(c) => colorChangeHanndler(c)}
                      color={color}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='description'> Description</Label>
                    <textarea
                      className='form-control'
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      id='description'
                      rows='5'
                    ></textarea>
                  </FormGroup>
                </Col>
              </Row>
              <div className='text-center'>
                <button className='btn btn-success mr-2 px-5' type='submit'>
                  {isLoading ? 'Submitting ...' : 'Save'}
                </button>
              </div>
            </AvForm>{' '}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, packageError } = state.Packages;
  return { message, loading, packageError };
};

export default connect(mapStateToProps, { updatePackage, updatePackageFailed })(
  UpdatePackage
);
