import React, { Component } from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { withRouter } from 'react-router-dom'

class ProfileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      username: this.props.user?.firstName,
      picture:
        'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
    }
    this.toggle = this.toggle.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }))
  }

  componentDidMount() {
    const { picture } = this.props.user
    if (picture) {
      this.setState({
        picture: picture,
      })
    }
  }

  onChangeHandler(routeTo) {
    this.props.history.push(routeTo)
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
          >
            <img
              className="rounded-circle header-profile-user mr-1"
              src={this.state.picture}
              alt="Header Avatar"
            />
            <span className="d-none d-xl-inline-block ml-1 text-transform">
              {this.state.username}
            </span>
            <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={() => this.onChangeHandler('/profile')}>
              <i className="ri-user-line align-middle mr-1"></i> Profile
            </DropdownItem>
            <DropdownItem
              className="d-block"
              onClick={() => this.onChangeHandler('/settings')}
            >
              <span className="badge badge-success float-right mt-1">11</span>
              <i className="ri-settings-2-line align-middle mr-1"></i> Settings
            </DropdownItem>
            <DropdownItem href="#">
              <i className="ri-lock-unlock-line align-middle mr-1"></i> Lock
              screen
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              className="text-danger"
              onClick={() => this.props.logoutHandler()}
            >
              <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{' '}
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}

export default withRouter(ProfileMenu)
