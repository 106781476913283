/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux'
import { Alert, Row, Col, Button } from 'reactstrap'
import Loader from '../../../components/Common/Loading/index.js'
import { fetchEachBank, updateBank } from '../../../store/actions'
import { Link } from 'react-router-dom'

const BankStatus = ({
  bankDetails,
  bankRecipientError,
  loading,
  updateBank,
  bankRecipient,
}) => {
  const [isLoading, setIsloading] = useState(true)

  useEffect(() => {
    setIsloading(true)
    if (bankRecipient) {
      setIsloading(false)
    }
  })
  const statusHandler = (state) => {
    let query = 'status'
    let data = {
      status: state,
      code: bankDetails.code,
      id: bankDetails.id,
      longcode: bankDetails.longcode,
      gateway: bankDetails.gateway,
      recipient: bankRecipient.recipient_code,
    }
    updateBank(data, query)
  }
  return (
    <React.Fragment>
      <div>
        <h4 className="card-title">BANK DETAILS</h4>
        <hr className="my-0 py-0" />
        <div className="d-flex mb-2">
          <div className="col-6 font-size-14"> Account Name: </div>
          <div className="col-6 font-size-14"> {bankDetails.accountName}</div>
        </div>
        <div className="d-flex  mb-2">
          <div className="col-6 font-size-14"> Account Number: </div>
          <div className="col-6 font-size-14"> {bankDetails.accountNumber}</div>
        </div>
        {bankRecipientError && (
          <div className="mt-4">
            <h4 className="card-title">RECIPIENT DETAILS</h4>

            <div className="card shadow-lg pt-2 bg-soft-secondary">
              <div className="d-flex">
                <p className="col-6 font-size-14 font-weight-bold">
                  {' '}
                  Recipient Name:{' '}
                </p>
                <p className="col-6 font-size-14">INVALID NAME</p>
              </div>
              <div className="d-flex">
                <p className="col-6 font-size-14 font-weight-bold">
                  Recipient Number:{' '}
                </p>
                <p className="col-6 font-size-14">INVALID NUMBER</p>
              </div>
            </div>
          </div>
        )}
        {bankRecipient === null && loading ? (
          <Loader />
        ) : (
          bankRecipient !== null && (
            <div className="mt-4">
              <h4 className="card-title">RECIPIENT DETAILS</h4>

              <div className="card shadow-lg pt-2 bg-soft-secondary">
                <div className="d-flex">
                  <p className="col-6 font-size-14 font-weight-bold">
                    {' '}
                    Recipient Name:{' '}
                  </p>
                  <p className="col-6 font-size-14">
                    {' '}
                    {bankRecipient?.details?.account_name}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="col-6 font-size-14 font-weight-bold">
                    Recipient Number:{' '}
                  </p>
                  <p className="col-6 font-size-14">
                    {' '}
                    {bankRecipient?.details.account_number}
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div>
                  {bankDetails.status === 'ACTIVE' ? (
                    <>
                      <Button
                        onClick={() => statusHandler('INACTIVE')}
                        color="danger"
                        size="sm"
                        disabled={loading}
                      >
                        INACTIVE
                      </Button>
                      <Button
                        onClick={() => statusHandler('SANCTION')}
                        color="warning"
                        className="ml-2"
                        disabled={loading}
                        size="sm"
                      >
                        SANCTION
                      </Button>
                    </>
                  ) : bankDetails.status === 'INACTIVE' ? (
                    <>
                      <Button
                        onClick={() => statusHandler('ACTIVE')}
                        color="success"
                        size="sm"
                        disabled={loading}
                      >
                        ACTIVATE
                      </Button>
                      <Button
                        onClick={() => statusHandler('SANCTION')}
                        color="warning"
                        className="ml-2"
                        disabled={loading}
                        size="sm"
                      >
                        SANCTION
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => statusHandler('ACTIVE')}
                        color="success"
                        size="sm"
                        disabled={loading}
                      >
                        ACTIVATE
                      </Button>
                      <Button
                        onClick={() => statusHandler('INACTIVE')}
                        className="ml-2"
                        color="danger"
                        size="sm"
                        disabled={loading}
                      >
                        INACTIVE
                      </Button>
                    </>
                  )}
                </div>
                <div>
                  {loading && (
                    <Button
                      color="success"
                      className="ml-2"
                      size="sm"
                      disabled={loading}
                    >
                      Loading ...
                    </Button>
                  )}
                </div>{' '}
              </div>
            </div>
          )
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { bankRecipientError, loading, bankRecipient } = state.Bank
  return { bankRecipientError, loading, bankRecipient }
}

export default connect(mapStateToProps, { updateBank })(BankStatus)
