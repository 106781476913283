import React from 'react'
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux'
import { Row, Col, Container, Card, CardBody } from 'reactstrap'
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import {
  UserInfo,
  UserSummary,
} from '../../../components/Common/ProfileComponents/index'
import { fetchAllUser } from '../../../store/actions'
const Profile = ({ user }) => {
  const breadcrumbItems = [
    { title: 'nRyde', link: '#' },
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Profile', link: '#' },
  ]
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Profile" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col sm={12}>
              <UserInfo isProfile={true} user={user} />
            </Col>
            <Col sm={12}>
              <UserSummary user={user} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { user, loading, userError, message } = state.Account
  return { user, loading, userError, message }
}

export default connect(mapStateToProps, {
  fetchAllUser,
})(Profile)
