export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
export const FETCH_ACTIVITIES_SUCCESSFUL = 'FETCH_ACTIVITIES_SUCCESSFUL';
export const FETCH_ACTIVITIES_API_FAILED = 'FETCH_ACTIVITIES_API_FAILED';

export const FETCH_EACH_ACTIVITIES = 'FETCH_EACH_ACTIVITIES';
export const FETCH_EACH_ACTIVITIES_SUCCESSFUL =
  'FETCH_EACH_ACTIVITIES_SUCCESSFUL';
export const FETCH_EACH_ACTIVITIES_API_FAILED =
  'FETCH_EACH_ACTIVITIES_API_FAILED';

export const DELETE_ACTIVITIES = 'DELETE_ACTIVITIES';
export const DELETE_ACTIVITIES_SUCCESSFUL = 'DELETE_ACTIVITIES_SUCCESSFULLY';
export const DELETE_ACTIVITIES_FAILED = 'DELETE_ACTIVITIES_FAILED';

export const CREATE_ACTIVITIES = 'CREATE_ACTIVITIES';
export const CREATE_ACTIVITIES_SUCCESSFUL = 'CREATE_ACTIVITIES_SUCCESSFULLY';
export const CREATE_ACTIVITIES_FAILED = 'CREATE_ACTIVITIES_FAILED';

export const UPDATE_ACTIVITIES = 'UPDATE_ACTIVITIES';
export const UPDATE_ACTIVITIES_SUCCESSFUL = 'UPDATE_ACTIVITIES_SUCCESSFULLY';
export const UPDATE_ACTIVITIES_FAILED = 'FAILED';
