import {
  FETCH_REVIEWS,
  FETCH_REVIEWS_SUCCESSFUL,
  FETCH_REVIEWS_API_FAILED,
  FETCH_DRIVER_REVIEWS,
  FETCH_DRIVER_REVIEWS_SUCCESSFUL,
  FETCH_DRIVER_REVIEWS_API_FAILED,
  FETCH_EACH_REVIEW,
  FETCH_EACH_REVIEW_SUCCESSFUL,
  FETCH_EACH_REVIEW_API_FAILED,
  FETCH_REVIEW_SUMMARY,
  FETCH_REVIEW_SUMMARY_SUCCESSFULLY,
  FETCH_REVIEW_SUMMARY_FAILED,
} from './actionTypes';

const initialState = {
  reviews: null,
  review: null,
  reviewsError: null,
  message: null,
  loading: false,
  reviewSummary: null,
  summaryError: null,
};

const Review = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REVIEWS:
    case FETCH_DRIVER_REVIEWS:
      state = {
        ...state,
        reviews: null,
        reviewsError: null,
        loading: true,
        message: null,
      };
      break;

    case FETCH_REVIEWS_SUCCESSFUL:
    case FETCH_DRIVER_REVIEWS_SUCCESSFUL:
      state = {
        ...state,
        reviews: action.payload,
        reviewsError: null,
        loading: false,
      };
      break;

    case FETCH_EACH_REVIEW:
      state = {
        ...state,
        review: null,
        reviewsError: null,
        loading: true,
      };
      break;

    case FETCH_EACH_REVIEW_SUCCESSFUL:
      state = {
        ...state,
        review: action.payload,
        reviewsError: null,
        loading: false,
      };
      break;

    case FETCH_REVIEWS_API_FAILED:
    case FETCH_DRIVER_REVIEWS_API_FAILED:
    case FETCH_EACH_REVIEW_API_FAILED:
      state = {
        ...state,
        reviews: null,
        loading: false,
        message: null,
        reviewsError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;
    case FETCH_REVIEW_SUMMARY:
      return {
        ...state,
        reviewSummary: null,
        summaryError: null,
        loading: true,
      };

    case FETCH_REVIEW_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        reviewSummary: action.payload,
        summaryError: null,
        loading: false,
      };

    case FETCH_REVIEW_SUMMARY_FAILED:
      return {
        ...state,
        reviewSummary: null,
        summaryError: action.payload,
        loading: false,
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Review;
