import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  CREATE_PACKAGE,
  FETCH_PACKAGE,
  FETCH_PACKAGE_VEHICLES,
  UPDATE_PACKAGE,
  FETCH_PACKAGE_SUMMARY,
  DELETE_PACKAGE,
  FETCH_EACH_PACKAGE,
  ADD_PACKAGE_TO_VEHICLE,
  DELETE_PACKAGE_FROM_VEHICLE,
  REMOVE_VEHICLE_FROM_PACKAGE,
} from './actionTypes';

import {
  addPackageToVehiclesSuccessful,
  deletePackageToVehiclesSuccessful,
  deletePackageToVehiclesFailed,
  addPackageToVehiclesFailed,
  fetchPackageSuccessful,
  fetchPackageFailed,
  createPackageSuccessful,
  updatePackageSuccessful,
  updatePackageFailed,
  createPackageFailed,
  fetchEachPackageSuccessful,
  fetchEachPackageFailed,
  deletePackageSuccessful,
  deletePackageFailed,
  fetchPackageSummaryFailed,
  fetchPackageSummarySuccessfully,
  fetchPackageVehiclesSuccessful,
  fetchPackageVehiclesFailed,
  removeVehicleFailed,
  removeVehicleSuccessful,
} from './actions';

import {
  CreatePackageService,
  ListPackageService,
  ListPackageVehicleService,
  FetchEachPackageService,
  fetchPackageSummaryService,
  UpdatePackageService,
  DeletePackageService,
  addPackageToVehiclesService,
  deletePackageFromVehiclesService,
  removeVehiclesFromPackageService,
} from '../../services/packageService';

function* fetchPackageHandler({ payload }) {
  try {
    const response = yield call(ListPackageService, payload);
    yield put(fetchPackageSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchPackageFailed(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchPackageFailed(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchPackageFailed(error?.response?.data?.message));
    }
  }
}

function* fetchPackageVehiclesHandler({ payload }) {
  try {
    const response = yield call(ListPackageVehicleService, payload);
    yield put(fetchPackageVehiclesSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchPackageVehiclesFailed(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchPackageVehiclesFailed(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchPackageVehiclesFailed(error?.response?.data?.message));
    }
  }
}

function* removeVehiclesHandler({ payload }) {
  try {
    const response = yield call(removeVehiclesFromPackageService, payload);
    yield put(removeVehicleSuccessful(response.data));
  } catch (error) {
    yield put(removeVehicleFailed(error?.response?.data?.message));
    console.log('error', error);
  }
}

function* addPackageToVehiclesHandler({ payload }) {
  try {
    const response = yield call(addPackageToVehiclesService, payload);
    yield put(addPackageToVehiclesSuccessful(response.data));
  } catch (error) {
    yield put(addPackageToVehiclesFailed(error?.response?.data?.message));
  }
}

//
function* deletePackageFromVehiclesHandler({ payload }) {
  try {
    const response = yield call(deletePackageFromVehiclesService, payload);
    yield put(deletePackageToVehiclesSuccessful(response.data));
  } catch (error) {
    yield put(deletePackageToVehiclesFailed(error?.response?.data?.message));
  }
}

function* createPackageHandler({ payload }) {
  try {
    const response = yield call(CreatePackageService, payload);
    yield put(createPackageSuccessful(response.data.result));
  } catch (error) {
    yield put(createPackageFailed(error?.response?.data?.error?.error.message));
  }
}

function* fetchEachPackageHandler({ payload }) {
  try {
    const response = yield call(FetchEachPackageService, payload);
    yield put(fetchEachPackageSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachPackageFailed(error?.response?.data?.error));
  }
}

function* updatePackageHandler({ payload }) {
  try {
    const response = yield call(UpdatePackageService, payload);
    yield put(updatePackageSuccessful(response.data.result));
  } catch (error) {
    yield put(updatePackageFailed(error?.response?.data?.error));
  }
}

function* deletePackageHandler({ payload }) {
  try {
    const response = yield call(DeletePackageService, payload);
    yield put(deletePackageSuccessful(response.data.result));
  } catch (error) {
    yield put(deletePackageFailed(error?.response?.data?.error));
  }
}

// BANK SUMMARY
function* fetchPackageSummaryHandler({ payload }) {
  try {
    const response = yield call(fetchPackageSummaryService, payload);
    yield put(fetchPackageSummarySuccessfully(response.data));
  } catch (error) {
    yield put(fetchPackageSummaryFailed(error.response.data));
  }
}

export function* watchCreatePackage() {
  yield takeEvery(CREATE_PACKAGE, createPackageHandler);
}
export function* watchFetchPackage() {
  yield takeEvery(FETCH_PACKAGE, fetchPackageHandler);
}
export function* watchFetchPackageVehicle() {
  yield takeEvery(FETCH_PACKAGE_VEHICLES, fetchPackageVehiclesHandler);
}
export function* watchAddPackageToVehicles() {
  yield takeEvery(ADD_PACKAGE_TO_VEHICLE, addPackageToVehiclesHandler);
}

export function* watchDeletePackageFromVehicles() {
  yield takeEvery(
    DELETE_PACKAGE_FROM_VEHICLE,
    deletePackageFromVehiclesHandler
  );
}
export function* watchRemoveVehicle() {
  yield takeEvery(REMOVE_VEHICLE_FROM_PACKAGE, removeVehiclesHandler);
}
export function* watchFetchEachPackage() {
  yield takeEvery(FETCH_EACH_PACKAGE, fetchEachPackageHandler);
}

export function* watchDeletePackage() {
  yield takeEvery(DELETE_PACKAGE, deletePackageHandler);
}

export function* watchUpdatePackage() {
  yield takeEvery(UPDATE_PACKAGE, updatePackageHandler);
}

export function* watchFetchPackageSummary() {
  yield takeEvery(FETCH_PACKAGE_SUMMARY, fetchPackageSummaryHandler);
}

function* PackageService() {
  yield all([
    fork(watchFetchPackage),
    fork(watchUpdatePackage),
    fork(watchDeletePackage),
    fork(watchAddPackageToVehicles),
    fork(watchDeletePackageFromVehicles),
    fork(watchFetchPackageSummary),
    fork(watchCreatePackage),
    fork(watchFetchEachPackage),
    fork(watchFetchPackageVehicle),
    fork(watchRemoveVehicle),
  ]);
}

export default PackageService;
