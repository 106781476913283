/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { Alert, Row, Col, Table } from 'reactstrap';
import Loader from '../../../components/Common/Loading/index.js';
import { fetchEachCashout } from '../../../store/actions';
import { Link } from 'react-router-dom';

const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const Cashout = ({
  cashoutPreview,
  previewId,
  loading,
  cashoutError,
  fetchEachCashout,
}) => {
  useEffect(() => {
    fetchEachCashout(previewId);
  }, [fetchEachCashout, previewId]);

  return (
    <React.Fragment>
      <div className='mt-3'>
        {cashoutError && !loading ? (
          <Alert color='danger' className='text-center'>
            {cashoutError}
          </Alert>
        ) : (
          <>
            {cashoutPreview === null ? (
              <Loader />
            ) : (
              <Row>
                <Col sm={12}>
                  <div>
                    <Link
                      to={`/user/${cashoutPreview?.user?.id}`}
                      className='mr-3 text-primary'
                    >
                      <Row className='mt-2 m-2 align-items-center border shadow rounded p-2'>
                        <img
                          className='rounded-circle avatar-md border shadow-lg p-2 ml-2'
                          width='100%'
                          alt='Profile'
                          src={
                            cashoutPreview?.user.picture
                              ? cashoutPreview?.user.picture
                              : avatarImg
                          }
                        />
                        <Col sm={8}>
                          {' '}
                          <h4 className='font-weight-bold font-size-12 mt-1 mb-1 text-capitalize'>
                            {cashoutPreview?.user.firstName}{' '}
                            {cashoutPreview?.user.lastName}
                            <span className='text-muted font-size-10'>
                              <span className='mx-2'> | </span>
                              <span className='mr-2'>
                                <i className=' fas fa-star text-warning mr-1' />
                                {cashoutPreview?.user.rating}
                              </span>
                              <span className='text-muted'>
                                ({cashoutPreview?.user.raters}{' '}
                                <i className='fas fa-user font-size-10 mr-1 text-muted' />
                                )
                              </span>
                            </span>
                          </h4>
                          <div className='mb-2'>
                            <span className='text-muted font-size-10 font-weight-bold'>
                              {cashoutPreview?.user.userType}
                            </span>
                          </div>
                          <div className='d-flex justify-content-between'>
                            {cashoutPreview?.user.loyalty?.tier && (
                              <h5 className='font-weight-bold badge badge-soft-secondary rounded'>
                                <i className='ri-vip-crown-2-line mr-1 text-warning' />
                                {cashoutPreview?.user.loyalty?.tier}
                              </h5>
                            )}

                            {/* <span>
                              <span
                                className={`badge badge-soft-${
                                  cashoutPreview.status.toLowerCase() ===
                                  'active'
                                    ? 'success'
                                    : cashoutPreview.status.toLowerCase() ===
                                      'inactive'
                                    ? 'danger'
                                    : cashoutPreview.status.toLowerCase() ===
                                      'sanction'
                                    ? 'warning'
                                    : 'dark'
                                } font-size-12`}
                              >
                                {cashoutPreview.status && cashoutPreview.status}
                              </span> 
                            </span>*/}
                          </div>
                        </Col>
                      </Row>
                    </Link>
                    <Table hover>
                      <tbody>
                        <tr>
                          <td className='font-weight-bold'>Amount </td>
                          <td className='text-uppercase font-size-16 font-weight-bold text-danger'>
                            {cashoutPreview?.amount}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Fee</td>
                          <td className='text-uppercase'>
                            {cashoutPreview?.fee}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Type</td>
                          <td className='text-uppercase'>
                            {cashoutPreview?.type}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Status</td>
                          <td>
                            <span
                              className={`badge badge-soft-${
                                cashoutPreview.status.toLowerCase() ===
                                'completed'
                                  ? 'success'
                                  : cashoutPreview.status.toLowerCase() ===
                                    'cancelled'
                                  ? 'danger'
                                  : cashoutPreview.status.toLowerCase() ===
                                    'Processing'
                                  ? 'warning'
                                  : cashoutPreview.status.toLowerCase() ===
                                    'pending'
                                  ? 'secondary'
                                  : 'danger'
                              } font-size-12`}
                            >
                              {cashoutPreview?.status}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Created At</td>
                          <td className='text-uppercase'>
                            {Moment(cashoutPreview.createdAt).format('lll')}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <div>
                      <h4 className='card-title text-center'>Bank Details</h4>
                      <Table hover>
                        <tbody>
                          <tr>
                            <td className='font-weight-bold'>Account Name</td>
                            <td className='text-uppercase'>
                              {cashoutPreview?.bank?.accountName}
                            </td>
                          </tr>
                          <tr>
                            <td className='font-weight-bold'>Bank</td>
                            <td className='text-uppercase'>
                              {cashoutPreview?.bank?.bankName}
                            </td>
                          </tr>
                          <tr>
                            <td className='font-weight-bold'>Account Number</td>
                            <td>{cashoutPreview?.bank?.accountNumber}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    {cashoutPreview?.status === 'COMPLETED' && (
                      <>
                        <h4 className='card-title text-center'>
                          Payment Reference
                        </h4>
                        <Table hover>
                          <tbody>
                            <tr>
                              <td className='font-weight-bold'>
                                TransactionId
                              </td>
                              <td className='text-uppercase'>
                                {cashoutPreview?.transactionId
                                  ? cashoutPreview?.transactionId
                                  : 'Nill'}
                              </td>
                            </tr>

                            <tr>
                              <td className='font-weight-bold'>
                                TransactionCode
                              </td>
                              <td className='text-uppercase'>
                                {cashoutPreview?.transactionCode
                                  ? cashoutPreview?.transactionCode
                                  : 'Nill'}
                              </td>
                            </tr>

                            <tr>
                              <td className='font-weight-bold'>
                                Transaction Time
                              </td>
                              <td className='text-uppercase'>
                                {cashoutPreview?.transactionTime
                                  ? cashoutPreview?.transactionTime
                                  : 'Nill'}
                              </td>
                            </tr>

                            <tr>
                              <td className='font-weight-bold'>
                                Transaction url
                              </td>
                              <td className='text-uppercase'>
                                {cashoutPreview?.involveUrl
                                  ? cashoutPreview?.involveUrl
                                  : 'Nill'}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { cashoutPreview, loading, cashoutError } = state.Cashout;
  const { user, userError } = state.Users;
  return { cashoutPreview, loading, cashoutError, user, userError };
};

export default connect(mapStateToProps, { fetchEachCashout })(Cashout);
