import HttpService from "./HttpService";
import { QueryReallignment } from "../utils/general";

export const GetTourService = (query) => {
  const http = new HttpService();
  let url = "tour/all";

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const GetEachTourService = (id) => {
  const http = new HttpService();
  const url = `tour/${id}`;
  return http.getData(url);
};

export const CreateTourService = (payload) => {
  const http = new HttpService();
  const url = "tour";
  return http.postData(payload, url);
};
export const UpdateTourService = (payload) => {
  const http = new HttpService();
  const url = `tour/${payload.id}`;
  return http.putData(payload, url);
};

export const DeleteTourService = (id) => {
  const http = new HttpService();
  const url = `tour/${id}`;
  return http.deleteData(url);
};
