import React, { Component } from 'react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import data from '../../components/Common/modules/data';

import { connect } from 'react-redux';
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from '../../store/actions';

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSideBar: true,
    };
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu('#side-menu');

    var matchingMenuItem = null;
    var ul = document.getElementById('side-menu');
    var items = ul.getElementsByTagName('a');
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        // const parent3 = parent2.parentElement;

        // if (parent3) {
        //   parent3.classList.add('mm-active');
        //   parent3.childNodes[0].classList.add('mm-active');

        //   let currentElement = parent3;
        //   while (currentElement) {
        //     if (currentElement.classList.contains('sub-menu')) {
        //       currentElement.classList.add('mm-show');
        //       const parent4 = currentElement.parentElement;
        //       if (parent4) {
        //         parent4.classList.add('mm-active');
        //       }
        //     }
        //     currentElement = currentElement.parentElement;
        //   }
        // }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {this.props.user?.userType === 'ADMIN' &&
              this.props.accessControl?.result && (
                <>
                  {this.props.accessControl?.result.length > 0 ? (
                    <>
                      {data?.modules.map((val) => (
                        <li key={val.name}>
                          {val?.subModule?.length > 0 ? (
                            <>
                              {' '}
                              <Link to="" className="has-arrow waves-effect">
                                <i className={val.icon}></i>
                                <span>{val.name}</span>
                              </Link>
                              <ul className="sub-menu" aria-expanded="false">
                                {val?.subModule?.map(
                                  (subMap) =>
                                    this?.props?.accessControl?.result[0]?.data
                                      ?.modules[val.name][subMap.name] &&
                                    this?.props?.accessControl?.result[0]?.data
                                      ?.modules[val.name][subMap.name]
                                      ?.subscribe !== 'NOT_ALLOWED' && (
                                      <li key={subMap?.name}>
                                        <Link to={subMap?.route}>
                                          {subMap?.name}
                                        </Link>
                                      </li>
                                    )
                                )}
                              </ul>
                            </>
                          ) : (
                            <>
                              {this.props.accessControl?.result[0]?.data
                                ?.modules[val.name]?.subscribe !==
                                'NOT_ALLOWED' ? (
                                <Link to={val.route} className="waves-effect">
                                  <i className={val.icon}></i>
                                  <span>{val.name}</span>
                                </Link>
                              ) : null}
                            </>
                          )}
                        </li>
                      ))}
                    </>
                  ) : (
                    <>
                      {data?.modules.map((val) => (
                        <li key={val.name}>
                          {val?.subModule?.length > 0 ? (
                            <>
                              {' '}
                              <Link to="" className="has-arrow waves-effect">
                                <i className={val.icon}></i>
                                <span>{val.name}</span>
                              </Link>
                              <ul className="sub-menu" aria-expanded="false">
                                {val.subModule.map((subMap) => (
                                  <li key={subMap?.name}>
                                    <Link to={subMap?.route}>
                                      {subMap?.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </>
                          ) : (
                            <>
                              <Link to={val.route} className="waves-effect">
                                <i className={val.icon}></i>
                                <span>{val.name}</span>
                              </Link>
                            </>
                          )}
                        </li>
                      ))}
                    </>
                  )}
                </>
              )}

            {this.props.user?.userType !== 'ADMIN' && (
              <>
                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="mdi mdi-view-dashboard"></i>
                    <span>Dashboard</span>
                  </Link>
                </li>
                {/* Trip Request */}
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ri-road-map-line"></i>
                    <span>Rides</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/my_rides">My Rides</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-location-arrow"></i>
                    <span>Notification</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/notification">My Notification</Link>
                    </li>
                  </ul>
                </li>
                {/* Complaints */}
                <li>
                  <Link to="/complains" className=" waves-effect">
                    <i className="ri-file-list-3-fill"></i>
                    <span>Complaints</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, accessControl } = state.Account;
  return {
    ...state.Layout,
    user,
    accessControl,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(SidebarContent)
);
