import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  UncontrolledTooltip,
} from "reactstrap";
import AlartError from "../../../../components/Common/AlertCard";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import CustomSelect from "../../../../components/Common/MoreDropdown/CustomSelect";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import moment from "moment";

// actions
import {
  createBonus,
  createBonusFailed,
  updateBonus,
  updateBonusFailed,
} from "../../../../store/actions";

const Form = ({
  createBonus,
  createBonusFailed,
  updateBonus,
  updateBonusFailed,
  message,
  loading,
  history,
  bonusError,
  defaultValue,
  zone,
  fetchZone,
}) => {
  const [description, setDescription] = useState("");
  const [descError, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [zoneOption, SetZoneOption] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const handleSubmit = async (event, values) => {
    setIsLoading(true);
    if (!description) {
      setError(true);
      setIsLoading(false);
      return;
    }
    setError(false);
    let zoneIds = [];
    selected.forEach((data) => {
      zoneIds.push(data.value);
    });
    const bonusData = {};
    bonusData.name = values.name;
    bonusData.value = Number(values.value);
    bonusData.description = description;
    bonusData.start = moment(values.start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    bonusData.expiry = moment(values.expiry).format(
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    );
    bonusData.type = values.type;
    bonusData.userType = values.userType;
    bonusData.isHighlight = values.isHighlight;
    bonusData.priority = Number(values.priority);
    bonusData.requirements = {
      ride: Number(values.ride),
      acceptance: Number(values.acceptance / 100),
      completions: Number(values.completions / 100),
      online: Number(values.online),
      start: `${
        startTime ? values.start?.substring(0, 10) + "T" + startTime : ""
      }`,
      end: `${endTime ? values.expiry?.substring(0, 10) + "T" + endTime : ""}`,
    };
    bonusData.zones = zoneIds;
    if (defaultValue) {
      bonusData.status = values.Status;
      updateBonus(bonusData, defaultValue.id);
      return;
    }
    createBonus(bonusData);
    // console.log(bonusData);
    setIsLoading(false);
  };

  useEffect(() => {
    createBonusFailed("");
    updateBonusFailed("");
  }, []);

  useEffect(() => {
    if (defaultValue) {
      if (defaultValue?.requirements?.start) {
        setStartTime(defaultValue?.requirements?.start?.substring(11, 16));
        setEndTime(defaultValue?.requirements?.end?.substring(11, 16));
      }
      setDescription(defaultValue.description);
      if (defaultValue?.zones?.length > 0) {
        const zonArr = [];
        if (defaultValue.zones) {
          defaultValue.zones.forEach((element) => {
            const zonObj = {};
            zonObj.value = element.id;
            zonObj.label = element.name;
            zonArr.push(zonObj);
          });
        }
        setSelected(zonArr);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        createBonusFailed("");
        updateBonusFailed("");
        history.goBack();
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    if (zone) {
      const zoneArr = [];
      zone.result.forEach((element) => {
        const zoneObj = {};
        zoneObj.value = element.id;
        zoneObj.label = element.name;
        zoneArr.push(zoneObj);
      });
      SetZoneOption(zoneArr);
    }
  }, [zone]);

  const handleFetchMoreZones = () => {
    fetchZone({ take: zoneOption.length + 10, skip: 0 });
  };
  return (
    <Card>
      <CardBody>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        {descError && (
          <Alert className="text-center" color="danger">
            Description can't be empty
          </Alert>
        )}
        {bonusError && <AlartError error={bonusError} />}
        <div className="float-right">
          <Link to="#" onClick={() => history.goBack()}>
            <i className="fas fa-arrow-left mr-3" />
            Back
          </Link>
        </div>
        <div>
          <h5> {defaultValue ? "Update" : "Create"} Bonus </h5>
        </div>
        <div>
          <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup className="mb-4">
                  <Label htmlFor="name">Name</Label>
                  <AvField
                    name="name"
                    type="text"
                    className="form-control bg-light"
                    id="name"
                    value={defaultValue ? defaultValue.name : ""}
                    placeholder="Enter Bonus Name ..."
                    required
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-4">
                  <Label htmlFor="value">Value</Label>
                  <AvField
                    name="value"
                    type="number"
                    min="0"
                    className="form-control bg-light"
                    id="value"
                    value={defaultValue ? defaultValue.value : ""}
                    placeholder="Enter Bonus Value ..."
                    required
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-4">
                  <Label htmlFor="value">Rides</Label>
                  <AvField
                    name="ride"
                    type="number"
                    min="0"
                    className="form-control bg-light"
                    id="ride"
                    value={defaultValue ? defaultValue.requirements?.ride : ""}
                    placeholder="Enter ride value ..."
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className=" mb-4">
                  <div className="d-flex align-items-center mb-1">
                    <Label className="m-0" htmlFor="priority">
                      Priority
                    </Label>
                    <i
                      className="mdi mdi-alert-circle-outline ml-2 font-size-16"
                      id="priorityTooltip"
                    ></i>
                    <UncontrolledTooltip
                      placement="top"
                      target="priorityTooltip"
                    >
                      Order of bonus! 1 is highest and 9 is lowest
                    </UncontrolledTooltip>
                  </div>
                  <AvField
                    name="priority"
                    type="number"
                    min="1"
                    max="9"
                    value={defaultValue ? defaultValue.priority : ""}
                    className="form-control bg-light"
                    id="priority"
                    placeholder="Enter bonus priority ..."
                    helpMessage={"Must be within 1-9"}
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className=" mb-4">
                  <Label htmlFor="point"> Acceptance</Label>
                  <AvField
                    name="acceptance"
                    type="number"
                    min="0"
                    max="100"
                    value={
                      defaultValue
                        ? defaultValue.requirements?.acceptance * 100
                        : ""
                    }
                    className="form-control bg-light"
                    id="acceptance"
                    placeholder="Enter bonus acceptance ..."
                    helpMessage={"Must be within 0-100 percent"}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-4">
                  <Label htmlFor="completions">Completions</Label>
                  <AvField
                    name="completions"
                    type="number"
                    min="0"
                    max="100"
                    className="form-control bg-light"
                    id="percent"
                    value={
                      defaultValue
                        ? defaultValue.requirements?.completions * 100
                        : ""
                    }
                    placeholder="Enter Completion Value ..."
                    helpMessage={"Must be within 0-100 percent"}
                  />
                </FormGroup>
              </Col>

              <Col md={defaultValue ? 6 : 12}>
                <FormGroup className="mb-4">
                  <Label htmlFor="percent">Online</Label>
                  <AvField
                    name="online"
                    type="number"
                    min="0"
                    className="form-control bg-light"
                    id="online"
                    value={
                      defaultValue ? defaultValue.requirements?.online : ""
                    }
                    placeholder="Enter Online Value ..."
                  />
                </FormGroup>
              </Col>
              {defaultValue ? (
                <Col md={6}>
                  <FormGroup className=" mb-4">
                    <AvField
                      className="form-control bg-light"
                      type="select"
                      name="Status"
                      label="Status"
                      value={defaultValue ? defaultValue.status : "INACTIVE"}
                    >
                      <option value={"ACTIVE"}>Active</option>
                      <option value={"INACTIVE"}>Inactive</option>
                      <option value={"COMINGSOON"}>Coming Soon</option>
                    </AvField>
                  </FormGroup>
                </Col>
              ) : null}
              <Col md={6}>
                <FormGroup>
                  <Label htmlFor="zone">Zones</Label>
                  <div className="CustomSelect">
                    <CustomSelect
                      options={zoneOption}
                      selected={selected}
                      onChange={setSelected}
                      fetchMore={
                        zoneOption.length !== zone?.total
                          ? handleFetchMoreZones
                          : null
                      }
                      labelledBy="Select zones ..."
                    ></CustomSelect>
                  </div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <Row>
                  <Col>
                    <FormGroup className=" mb-4">
                      <div className="d-flex align-items-center mb-2">
                        <Label htmlFor="UserType" className="m-0">
                          UserType
                        </Label>
                        <i
                          className="mdi mdi-alert-circle-outline ml-4 font-size-16"
                          id="UserType"
                        ></i>
                        <UncontrolledTooltip placement="top" target="UserType">
                          User Group
                        </UncontrolledTooltip>
                      </div>
                      <AvField
                        className="form-control bg-light"
                        type="select"
                        name="userType"
                        value={defaultValue ? defaultValue.userType : "USER"}
                      >
                        <option>USER</option>
                        <option>RIDER</option>
                        <option>DRIVER</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mb-4">
                      <div className="d-flex align-items-center mb-2">
                        <Label htmlFor="isHighlight" className="m-0">
                          Should be Advertised ?
                        </Label>
                        <i
                          className="mdi mdi-alert-circle-outline ml-2 font-size-16"
                          id="highlightTooltip"
                        ></i>
                        <UncontrolledTooltip
                          placement="top"
                          target="highlightTooltip"
                        >
                          Advertise bonus on home page!
                        </UncontrolledTooltip>
                      </div>
                      <AvRadioGroup
                        inline
                        name="isHighlight"
                        value={defaultValue ? defaultValue.isHighlight : ""}
                      >
                        <AvRadio label="Yes" value={true} />
                        <AvRadio label="No" value={false} />
                      </AvRadioGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>

              <Col md={6}>
                <FormGroup className=" mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <Label htmlFor="start" className="m-0">
                      Start Date
                    </Label>
                    <i
                      className="mdi mdi-alert-circle-outline ml-2 font-size-16"
                      id="startDateTooltip"
                    ></i>
                    <UncontrolledTooltip
                      placement="top"
                      target="startDateTooltip"
                    >
                      Bonus Visible Start date and time
                    </UncontrolledTooltip>
                  </div>
                  <AvField
                    className="form-control bg-light"
                    type="datetime-local"
                    name="start"
                    value={
                      defaultValue
                        ? `${defaultValue.start.split(":")[0]}:${
                            defaultValue.start.split(":")[1]
                          }`
                        : ""
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className=" mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <Label htmlFor="expiry" className="m-0">
                      End Date
                    </Label>
                    <i
                      className="mdi mdi-alert-circle-outline ml-2 font-size-16"
                      id="endDateTooltip"
                    ></i>
                    <UncontrolledTooltip
                      placement="top"
                      target="endDateTooltip"
                    >
                      Bonus Visible End date and time
                    </UncontrolledTooltip>
                  </div>
                  <AvField
                    className="form-control bg-light"
                    type="datetime-local"
                    name="expiry"
                    value={
                      defaultValue
                        ? `${defaultValue.expiry.split(":")[0]}:${
                            defaultValue.expiry.split(":")[1]
                          }`
                        : ""
                    }
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-4 d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <Label htmlFor="startTime" className="m-0">
                      Start Time
                    </Label>
                    <i
                      className="mdi mdi-alert-circle-outline ml-2 font-size-16"
                      id="startTimeTooltip"
                    ></i>
                    <UncontrolledTooltip
                      placement="top"
                      target="startTimeTooltip"
                    >
                      Bonus Redeemable Start Time
                    </UncontrolledTooltip>
                  </div>
                  <TimePicker onChange={setStartTime} value={startTime} />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-4 d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <Label htmlFor="endTime" className="m-0">
                      End Time
                    </Label>
                    <i
                      className="mdi mdi-alert-circle-outline ml-2 font-size-16"
                      id="endTimeTooltip"
                    ></i>
                    <UncontrolledTooltip
                      placement="top"
                      target="endTimeTooltip"
                    >
                      Bonus Redeemable End Time
                    </UncontrolledTooltip>
                  </div>

                  <TimePicker onChange={setEndTime} value={endTime} />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className=" mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <Label htmlFor="type" className="m-0">
                      Type
                    </Label>
                    <i
                      className="mdi mdi-alert-circle-outline ml-4 font-size-16"
                      id="typeTooltip"
                    ></i>
                    <UncontrolledTooltip placement="top" target="typeTooltip">
                      Bonus Grouping
                    </UncontrolledTooltip>
                  </div>
                  <AvField
                    className="form-control bg-light"
                    type="select"
                    name="type"
                    value={defaultValue ? defaultValue.type : "DAILY"}
                  >
                    <option>DAILY</option>
                    <option>WEEKLY</option>
                    <option>REFERRAL</option>
                    <option>ONLINE</option>
                    <option>RATING</option>
                    <option>SIGNUP</option>
                    <option>TRIP</option>
                  </AvField>
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label htmlFor="description"> Description</Label>
                  <textarea
                    className="form-control"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    id="description"
                    rows="4"
                  ></textarea>
                </FormGroup>
              </Col>
            </Row>
            {defaultValue ? (
              <button className="btn btn-success mr-2" type="submit">
                {loading ? "Submitting ..." : "Update"}
              </button>
            ) : (
              <button className="btn btn-success mr-2" type="submit">
                {isLoading ? "Submitting ..." : "Save"}
              </button>
            )}

            <Link to="#" onClick={() => history.goBack()}>
              <button className="btn btn-secondary">Cancel</button>
            </Link>
          </AvForm>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, bonusError } = state.Bonus;
  return { message, loading, bonusError };
};

export default connect(mapStateToProps, {
  createBonus,
  updateBonus,
  createBonusFailed,
  updateBonusFailed,
})(withRouter(Form));
