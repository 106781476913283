import React, { Component } from 'react';
import AlartError from '../../../components/Common/AlertCard';
import {
  Row,
  Col,
  Button,
  Alert,
  Card,
  CardBody,
  Container,
  Label,
  FormGroup,
} from 'reactstrap';
// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import queryString from 'query-string';

import Recaptcha from 'react-google-invisible-recaptcha';

// actions
import {
  activateAccount,
  apiError,
  createNewPasswordError,
  generateOTP,
  generateOTPFailed,
} from '../../../store/actions';

// import images
import logosmdark from '../../../assets/images/Iryde-Logo.png';

class ActivateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: null,
      messageSent: false,
      activationId: null,
      otp: null,
      disableButton: false,
    };
    this.activateHandler = this.activateHandler.bind(this);
  }

  activateHandler(event, values) {
    console.log('hello');

    this.recaptcha.execute();
    const activationKey = this.props.match.params.id;
    this.setState({
      ...this.state,
      activationId: activationKey,
      otp: values.otp,
    });
  }

  onResolved = () => {
    this.setState({ messageSent: true });
    let payload = {
      otp: this.state.otp,
      id: this.state.activationId,
      // recaptchaToken: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    };
    this.props.activateAccount(payload, this.props.history);
  };

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    this.props.apiError('');
    this.props.createNewPasswordError('');
    document.body.classList.add('auth-body-bg');
    this.setState({ ...this.state, userEmail: params?.email });
    if (this.props.otp) {
      setTimeout(() => {
        this.props.generateOTPFailed('');
      }, 2000);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activate_message !== this.props.activate_message) {
      setTimeout(() => {
        this.props.history.push('/login');
      }, 2000);
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('auth-body-bg');
  }
  handleGenerateOTP = () => {
    alert('get otp');
    let params = queryString.parse(this.props.location.search);
    this.setState({ ...this.state, disableButton: true });
    this.props.generateOTP({ email: params?.email, type: 'USER_REGISTER' });
    setTimeout(() => {
      this.setState({ ...this.state, disableButton: false });
      this.props.generateOTPFailed('');
    }, 300000);
  };
  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div>

        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={12}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={4}>
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={this.activateHandler}
                        >
                          <Card className="shadow-lg">
                            <CardBody>
                              {this.props.activate_message ? (
                                <>
                                  <div className="text-center">
                                    <Link to="/" className="logo">
                                      <img
                                        src={logosmdark}
                                        height="50"
                                        alt="logo"
                                      />
                                    </Link>
                                  </div>
                                  <Alert
                                    color="success"
                                    className="text-center"
                                  >
                                    {this.props.activate_message}
                                  </Alert>
                                </>
                              ) : (
                                <>
                                  <div>
                                    <div className="text-center">
                                      <div>
                                        <Link to="/" className="logo">
                                          <img
                                            src={logosmdark}
                                            height="50"
                                            alt="logo"
                                          />
                                        </Link>
                                      </div>
                                      {this.props.otpError && (
                                        <Alert
                                          color="danger"
                                          className="text-center"
                                        >
                                          {this.props.otpError}
                                        </Alert>
                                      )}
                                      {this.props.otpMessage && (
                                        <Alert
                                          color="secondary"
                                          className="text-center"
                                        >
                                          {this.props.otpMessage}
                                        </Alert>
                                      )}
                                      {this.props.activationError && (
                                        <AlartError
                                          error={this.props.activationError}
                                        />
                                      )}

                                      <h4 className="font-size-18 mt-4">
                                        Welcome Back !
                                      </h4>
                                      <h4>
                                        {this.state.userEmail &&
                                          this.state.userEmail}
                                      </h4>
                                      <p className="text-muted">
                                        Activate your account to continue to
                                        nRyde.
                                      </p>
                                    </div>
                                    <div className="p-2 mt-2">
                                      <div className="d-flex align-items-center w-100">
                                        <Col md={9} className="pr-0">
                                          <FormGroup className="auth-form-group-custom mb-0">
                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                            <Label htmlFor="userotp">OTP</Label>
                                            <AvField
                                              name="otp"
                                              value={this.state.otp}
                                              type="number"
                                              className="form-control mb-0"
                                              required
                                              id="userotp"
                                              errorMessage="please request and enter OTP sent to you"
                                              placeholder="Enter OTP"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={4} className="pl-1 mb-3">
                                          <Button
                                            color="primary"
                                            onClick={this.handleGenerateOTP}
                                            disabled={this.state.disableButton}
                                          >
                                            {this.props.loadingOTP
                                              ? 'Sending'
                                              : 'Request OTP'}
                                          </Button>
                                        </Col>
                                      </div>
                                      {this.props.otp && (
                                        <p className="text-center font-size-12 text-primary">
                                          OTP sent at:{' '}
                                          {this.props.otp?.time
                                            ?.split(',')
                                            ?.pop()}
                                          , Request again after 5 minutes
                                        </p>
                                      )}

                                      <div className="mt-2 text-center">
                                        <Button
                                          color="primary"
                                          className="w-md waves-effect waves-light"
                                          type="submit"
                                        >
                                          {this.props.loading
                                            ? 'Activating...'
                                            : 'Activate Now!'}
                                        </Button>
                                      </div>
                                    </div>

                                    <div className="mt-2 text-center">
                                      <p>
                                        Don't have an account ?{' '}
                                        <Link
                                          to="/register"
                                          className="font-weight-medium text-primary"
                                        >
                                          {' '}
                                          Register{' '}
                                        </Link>{' '}
                                      </p>
                                      <p>© 2022 nRyde.</p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </CardBody>
                          </Card>
                        </AvForm>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Recaptcha
              ref={(ref) => (this.recaptcha = ref)}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onResolved={this.onResolved}
            />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    otp,
    otpError,
    otpMessage,
    loadingOTP,
    activationError,
    activate_message,
    loading,
  } = state.Account;
  return {
    otp,
    otpError,
    otpMessage,
    loadingOTP,
    activationError,
    activate_message,
    loading,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    activateAccount,
    apiError,
    createNewPasswordError,
    generateOTP,
    generateOTPFailed,
  })(ActivateAccount)
);
