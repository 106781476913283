import React from 'react';
import { Card, CardBody, Media } from 'reactstrap';
import { Link } from 'react-router-dom';

const AppSettingCard = ({ config, previewId }) => {
  return (
    <Link to={`/app_setting/${config.id}`}>
      <Card className="pb-0 mb-0 rounded shadow-lg mb-2">
        <CardBody>
          <Media>
            <Media body className="overflow-hidden">
              <div>
                <span
                  className={`badge badge-soft-${
                    config.status === 'INACTIVE' ? 'danger' : 'success'
                  } font-size-11 mr-1 float-right text-lowercase`}
                >
                  {config.status}
                </span>
                <p className="text-truncate font-size-14 mb-2">
                  {config.module}
                </p>
              </div>
              <h4 className="mb-0">
                {config.appConfigItems.length}{' '}
                <span className="font-size-14 text-muted">Item/s</span>
              </h4>
            </Media>
            <div className="text-primary">
              <i className={config.module + ' font-size-24'}></i>
            </div>
          </Media>
        </CardBody>

        <CardBody className="border-top bg-light text-dark">
          <div className="text-truncate">
            <span className="text-capitalize">
              {config.description ? config.description : 'No description'}
            </span>
          </div>
        </CardBody>
      </Card>
    </Link>
  );
};

export default AppSettingCard;
