import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledTooltip,
  Button,
} from 'reactstrap';
import FilterDropDown from '../../../../components/Common/FilterDropDown';
import SimpleBar from 'simplebar-react';
import { CSVLink } from 'react-csv';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import imgCar from '../../../../assets/images/sample_car.png';
import ReactPaginate from 'react-paginate';
import Loader from '../../../../components/Common/Loading/index.js';
import Moment from 'moment';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../../utils/general';
import queryString from 'query-string';
// import AssignVehiclePackage from './components/assignedPackage';
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';
let skip = 0;
let dataLimit = 10;

const PackageVehicleTable = ({
  pkgVehicleData,
  fetchPackageVehicles,
  packagePreview,
  fetchPackageVehiclesFailed,
  message,
  history,
  dateFilter,
  packageId
}) => {
  const [data, setData] = useState(null);
  const [skipPage, setSkipPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [date, setDate] = useState({
    start: '',
    end: '',
  });
  const [status, setStatus] = useState('');
  const [menu, setMenu] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(pkgVehicleData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let query = {
      skip: pageNumber, take: dataLimit, search, start: date.start,
      end: date.end,
    };
    fetchPackageVehicles(packageId, query);
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let query = {
      skip, take, search, start: date.start,
      end: date.en
    };
    setSkipPage(Math.ceil(pageNumber / take));
    fetchPackageVehicles(packageId, query);
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let query = {
      search,
      take,
      skip,
      status,
      start: date.start,
      end: date.end
    };
    fetchPackageVehicles(packageId, query);
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    dateFilter({ start: values.startDate, end: values.endDate });
    setMenu(false);
    setStatus(values.vehicleStatus);
    let query = {
      search,
      take,
      skip,
      packages: values.packages,
      status: values.vehicleStatus,
      start: values.startDate,
      end: values.endDate,
    };
    fetchPackageVehicles(packageId, query);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: '',
      end: '',
    });
    dateFilter({});
    let queryParams = {
      search,
      take,
      skip,
      status,
    };
    fetchPackageVehicles(packageId, queryParams);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let query = {
          search,
          take,
          skip,
          status,
          start: date.start,
          end: date.end,
        };
        fetchPackageVehicles(packageId, query);
      }, 1000);
    }
  }, [message, fetchPackageVehicles]);

  useEffect(() => {
    if (pkgVehicleData) {
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(pkgVehicleData?.total / take));
    }
  }, [pkgVehicleData]);

  useEffect(() => {
    let query = {
      skip,
      take: dataLimit,
      start: date.start,
      end: date.end,
      search,
      status
    };
    fetchPackageVehicles(packageId, query);
  }, [fetchPackageVehicles]);


  const tableDataHandler = () => {
    const rowData = pkgVehicleData?.result?.map((data, i) => {
      return {
        idKey: data.id,
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,

        id: data.id.substring(0, 7),
        make: (
          <div className='d-flex align-items-center justify-content-center'>
            <Link
              to={`/vehicle/${data.id}`}
              // onClick={() => previewAction(data.id)}
              className="text-dark font-weight-bold"
            >
              <Row className="align-items-center">
                <Col className="mb-2 px-0 py-0 text-center" sm={3}>
                  <img
                    className="rounded avatar-sm border shadow-xs p-2"
                    alt="Profile"
                    src={data.imageFront ? data?.imageFront : imgCar}
                  />
                </Col>
                <Col sm={9}>
                  <span className="mt-1 mb-1">
                    <h4 className="font-weight-bold font-size-12 text-sm-left text-center mb-0">
                      {data?.make}{' '}
                      <span>
                        <span
                          className={`font-size-10 badge badge-${data.color.toLowerCase() === 'red'
                            ? 'danger'
                            : data.color.toLowerCase() === 'blue'
                              ? 'primary'
                              : data.color.toLowerCase() === 'black'
                                ? 'dark text-white'
                                : data.color.toLowerCase() === 'gold'
                                  ? 'warning'
                                  : data.color.toLowerCase() === 'green'
                                    ? 'success'
                                    : data.color.toLowerCase() === 'grey'
                                      ? 'secondary text-white'
                                      : 'soft-secondary text-dark'
                            } text-white rounded text-capitalize`}
                        >
                          {' '}
                          {data?.color}{' '}
                        </span>
                      </span>
                    </h4>
                  </span>
                  <span className="text-muted mb-0 font-size-11 text-sm-left text-center">
                    Model: {data?.model}
                  </span>
                  <p className="text-muted text-sm-left text-center font-size-11 mb-0">
                    {data?.plateNumber} | {data?.year}
                  </p>
                </Col>
              </Row>
            </Link>
          </div>
        ),
        model: <div className='text-center'>{data.model}</div>,
        plateNumber: <div className='text-center'>{data.plateNumber}</div>,
        year: <div className='text-center'>{data.year}</div>,
        date: (
          <div className='text-center'>
            {' '}
            <div>{Moment(data.createdAt).format('DD/MM/YYYY')}</div>
            <div className="badge badge-soft-secondary font-size-10">
              {' '}
              {Moment(data.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
        driver: (
          <div className='d-flex align-items-center justify-content-center'>
            <Link
              to={`/user/${data?.user?.id}`}
              className="text-dark font-weight-bold"
            >
              <div className="d-flex align-items-center">
                <img
                  src={data?.user?.picture ? data?.user?.picture : avatarImg}
                  alt=""
                  height="20"
                  className="rounded-circle avatar-xs mr-2"
                />

                <div>
                  <div className="text-capitalize">
                    {data?.user?.firstName} {data?.user?.lastName}
                  </div>

                  <span className="text-muted ml-2 badge badge-soft-secondary">
                    <i className=" fas fa-star text-warning mr-1" />
                    {data?.user?.rating ? data?.user?.rating?.toFixed(2) : 0}
                  </span>
                </div>
              </div>
            </Link>
          </div>
        ),
        seats: <div className='text-center'>{data.seats}</div>,
        status: (
          <div className='d-flex align-items-center justify-content-center'>
            <h1
              className={`badge badge-soft-${data.status.toLowerCase() === 'approved'
                ? 'success'
                : data.status.toLowerCase() === 'cancelled'
                  ? 'danger'
                  : data.status.toLowerCase() === 'rejected'
                    ? 'danger'
                    : data.status.toLowerCase() === 'pending'
                      ? 'warning'
                      : data.status.toLowerCase() === 'review'
                        ? 'dark'
                        : ''
                } font-size-10`}
            >
              {data.status ? data.status : '---'}
            </h1>
          </div>
        )

      };
    });
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
          headerAlign: 'center',
          headerStyle: { width: '70px' },
        },

        {
          text: 'Vehicle Brand',
          dataField: 'make',
          sort: true,
          headerAlign: 'center',
        },

        {
          text: 'Capacity',
          dataField: 'seats',
          headerAlign: 'center',
          headerStyle: { width: '100px' },
        },

        {
          text: 'Driver',
          dataField: 'driver',
          headerAlign: 'center',
        },

        {
          text: 'Status',
          dataField: 'status',
          headerAlign: 'center',
        },
        {
          text: 'CreatedAt',
          dataField: 'date',
          headerAlign: 'center',
        },

      ],
      rows: rowData,
    };
  };


  return (
    <React.Fragment>
      <Row>
        {packagePreview && (
          <Col lg={12}>
            <Card>
              <CardBody>
                <div>
                  <Link
                    to='#'
                    onClick={() => history.goBack()}
                    className='text-dark float-right'
                  >
                    <h6>Back</h6>
                  </Link>
                  <Row className='mt-2 align-items-center responsive-profile-container'>
                    <Col
                      className='pl-3 mb-2 px-0 py-0 responsive-profile'
                      sm={12}
                    >
                      <div className='align-items-center d-flex responsive-profile-text'>
                        <div className='ml-3 mr-1 d-flex position-relative'>
                          <img
                            className=' img-thumbnail p-2'
                            alt='Car'
                            src={packagePreview?.picture ? packagePreview?.picture : imgCar}
                            onClick={() => {
                              // setShowModal(true);
                            }}
                            style={
                              packagePreview?.picture
                                ? {
                                  width: '16rem',
                                  // height: '13rem',
                                  cursor: 'pointer',
                                  border: 'none',
                                  background: 'none'
                                }
                                : {
                                  width: '13rem',
                                  height: '13rem',
                                  pointerEvents: 'none',
                                }
                            }
                          />

                        </div>
                        <div className='mt-2 ml-2'>
                          <div className='d-flex align-items-center'>



                            <h2 className='font-weight-bold mb-2 text-capitalize'>
                              {packagePreview?.name}
                            </h2>
                            <div className='badge badge-soft-secondary ml-2'>

                              {packagePreview?.key}
                            </div>
                          </div>
                          <div className='mb-2'>
                            <h3
                              className='font-size-18 text-justify w-auto'
                              style={{ color: 'grey', }}
                            >
                              {packagePreview?.description}
                            </h3>
                          </div>
                          <div>
                            <Button size='sm' color='success'>
                              <Link to={`/add_vehicles?id=${packageId}`} className='text-white'>
                                <i className="mdi mdi-plus-thick font-size-14 mr-2"></i>
                                Add Vehicles
                              </Link>
                            </Button>

                            <Button size='sm' color='danger' className='ml-2'>
                              <Link to={`/remove_vehicles?id=${packageId}`} className='text-white'>
                                <i className="mdi mdi-window-minimize font-size-14 mr-2"></i>
                                Remove Vehicles
                              </Link>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>


                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
        <Col lg={12}>
          <Card>
            <CardBody className="pt-3">
              {!isLoading && data !== null ? (
                <>
                  <div className="d-flex justify-content-end mb-2">

                    <div className="d-flex align-items-center">
                      <div>
                        <FilterDropDown
                          menu={menu}
                          toggleMenu={() => setMenu(!menu)}
                        >
                          <div>
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={handleDateFilter}
                            >
                              <SimpleBar
                                style={{
                                  maxHeight: '250px',
                                }}
                              >
                                <Row className="px-3  pt-3 pb-0">
                                  <Col md="12">
                                    <FormGroup>
                                      <AvField
                                        name="startDate"
                                        type="date"
                                        className="form-control bg-light"
                                        id="startDate"
                                        value={date.start}
                                        helpMessage="Start Date"
                                        placeholder="Please choose the start date..."
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="12">
                                    <FormGroup>
                                      <AvField
                                        name="endDate"
                                        type="date"
                                        className="form-control bg-light"
                                        id="endDate"
                                        value={date.end}
                                        helpMessage="End Date"
                                        placeholder="Please choose the end date..."
                                        required
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md="12">
                                    <FormGroup>
                                      <AvField
                                        type="select"
                                        value={status}
                                        name="vehicleStatus"
                                        helpMessage="status"
                                      >
                                        <option value="">All</option>
                                        <option value="APPROVED">
                                          APPROVED
                                        </option>
                                        <option value="REJECTED">
                                          REJECTED
                                        </option>
                                        <option value="PENDING">PENDING</option>
                                        <option value="CANCELLED">
                                          CANCELLED
                                        </option>
                                        <option value="REVIEW">REVIEW</option>
                                      </AvField>
                                    </FormGroup>
                                  </Col>
                                  <Col md="12">
                                    <FormGroup>
                                      <AvField
                                        name="expiryDate"
                                        type="date"
                                        className="form-control bg-light"
                                        id="expiryDate"
                                        value={''}
                                        helpMessage="Inspection Expiry Date"
                                        placeholder="Please choose the inspection expiry date..."
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </SimpleBar>

                              <div className="p-2 d-flex justify-content-between align-items-center bg-light">
                                <Link
                                  to="#"
                                  onClick={resetHandler}
                                  className="btn btn-sm  bg-light font-size-14"
                                >
                                  <i className=" mdi mdi-refresh mr-1">
                                    {' '}
                                    Reset
                                  </i>
                                </Link>

                                <button
                                  className="btn btn-success btn-sm mr-2"
                                  type="submit"
                                >
                                  <i className="mdi mdi-arrow-right-circle mr-1"></i>
                                  Send
                                </button>
                              </div>
                            </AvForm>
                          </div>
                        </FilterDropDown>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <Input
                        bsSize="sm"
                        id="take"
                        name="take"
                        type="select"
                        onChange={handleLimitChange}
                        value={take}
                      >
                        <option>5</option>
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>
                    <div>
                      <Input
                        bsSize="sm"
                        id="search"
                        value={search}
                        name="search"
                        onChange={SearchHandler}
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField="idKey"
                    data={data.rows}
                    columns={data.columns}
                    // selectRow={selectRow}
                    striped
                    hover
                    wrapperClasses="table-responsive"
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={skipPage}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>

      </Row>
    </React.Fragment>
  );
};

export default PackageVehicleTable;
