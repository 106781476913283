import {
  CREATE_USER,
  CREATE_USER_SUCCESSFUL,
  CREATE_USER_FAILED,
  FETCH_USER_ROLE,
  FETCH_USER_ROLE_SUCCESSFULLY,
  FETCH_USER_ROLE_FAILED,
  FETCH_USER_EXPORT,
  FETCH_USER_EXPORT_SUCCESSFULLY,
  FETCH_USER_EXPORT_FAILED,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_SUCCESSFULLY,
  UPDATE_USER_ROLE_FAILED,
  FETCH_BADGE_SUMMARY,
  FETCH_BADGE_SUMMARY_SUCCESSFULLY,
  FETCH_BADGE_SUMMARY_FAILED,
  FETCH_USER_SUMMARY,
  FETCH_USER_SUMMARY_SUCCESSFULLY,
  FETCH_USER_SUMMARY_FAILED,
  FETCH_ACCOUNT_SUMMARY,
  FETCH_ACCOUNT_SUMMARY_SUCCESSFULLY,
  FETCH_ACCOUNT_SUMMARY_FAILED,
  FETCH_ACC_SUMMARY_CSV,
  FETCH_ACC_SUMMARY_CSV_SUCCESSFULLY,
  FETCH_ACC_SUMMARY_CSV_FAILED,
  DRIVER_LOGS_SUMMARY,
  DRIVER_LOGS_SUMMARY_SUCCESSFULLY,
  DRIVER_LOGS_SUMMARY_FAILED,
  FREEZE_ACCOUNT,
  FREEZE_ACCOUNT_SUCCESSFULLY,
  FREEZE_ACCOUNT_FAILED,
  FETCH_ALL_USER,
  FETCH_ALL_USER_SUCCESSFULLY,
  FETCH_USER_ERROR,
  APPROVE_USER_ACCOUNT,
  APPROVE_USER_ACCOUNT_SUCCESSFULLY,
  APPROVE_USER_ACCOUNT_ERROR,
  FETCH_FOR_USER_CSV,
  FETCH_FOR_USER_CSV_SUCCESSFULLY,
  FETCH_FOR_USER_CSV_ERROR,
  FETCH_FOR_DRIVER_LOG_CSV,
  FETCH_FOR_DRIVER_LOG_CSV_SUCCESSFULLY,
  FETCH_FOR_DRIVER_LOG_CSV_ERROR,
  FETCH_FOR_DRIVER_LOGS_CSV,
  FETCH_FOR_DRIVER_LOGS_CSV_SUCCESSFULLY,
  FETCH_FOR_DRIVER_LOGS_CSV_ERROR,
  INVITE_DRIVER,
  INVITE_DRIVER_FAILED,
  INVITE_DRIVER_SUCCESSFULLY,
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_ERROR,
  FETCH_USER_DETAILS_SUCCESSFULLY,
  FETCH_USER_NO_LOADING,
  FETCH_USER_NO_LOADING_ERROR,
  FETCH_USER_NO_LOADING_SUCCESSFULLY,
  DELETE_USER,
  DELETE_USER_SUCCESSFUL,
  DELETE_USER_FAILED,
  ACTIVATE_USER_PROFILE,
  ACTIVATE_USER_PROFILE_SUCCESSFUL,
  ACTIVATE_USER_PROFILE_API_FAILED,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESSFUL,
  UPDATE_USER_PROFILE_API_FAILED,
  FETCH_DRIVER_LOGS,
  FETCH_DRIVER_LOGS_SUCCESSFULLY,
  FETCH_DRIVER_LOGS_FAILED,
  FETCH_ALL_DRIVER_LOGS,
  FETCH_ALL_DRIVER_LOGS_SUCCESSFULLY,
  FETCH_ALL_DRIVER_LOGS_FAILED,
  CLEAR_USER,
  IS_USER_AUTHORIZE,
  FETCH_DRIVER_ONLINE_LOGS,
  FETCH_DRIVER_ONLINE_LOGS_SUCCESSFULLY,
  FETCH_DRIVER_ONLINE_LOGS_FAILED,
  FETCH_DRIVER_HOURS_LOGS,
  FETCH_DRIVER_HOURS_LOGS_SUCCESSFULLY,
  FETCH_DRIVER_HOURS_LOGS_FAILED,
  FETCH_HOURS_CSV,
  FETCH_HOURS_CSV_SUCCESSFULLY,
  FETCH_HOURS_CSV_ERROR,
} from './actionTypes'

export const fetchUserAuthorizeError = (error) => {
  return {
    type: IS_USER_AUTHORIZE,
    payload: error,
  }
}

export const createUser = (payload) => {
  return {
    type: CREATE_USER,
    payload,
  }
}

export const createUserSuccessful = (payload) => {
  return {
    type: CREATE_USER_SUCCESSFUL,
    payload: payload,
  }
}

export const createUserFailed = (error) => {
  return {
    type: CREATE_USER_FAILED,
    payload: error,
  }
}

export const fetchAllUser = (userType) => {
  return {
    type: FETCH_ALL_USER,
    payload: userType,
  }
}

export const fetchAllUserSuccessfully = (payload) => {
  return {
    type: FETCH_ALL_USER_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchUserError = (error) => {
  return {
    type: FETCH_USER_ERROR,
    payload: error,
  }
}

// approveUserAccount
export const approveUserAccount = (data, id) => {
  return {
    type: APPROVE_USER_ACCOUNT,
    payload: {data, id},
  }
}

export const approveUserAccountSuccessfully = (payload) => {
  return {
    type: APPROVE_USER_ACCOUNT_SUCCESSFULLY,
    payload: payload,
  }
}

export const approveUserAccountError = (error) => {
  return {
    type: APPROVE_USER_ACCOUNT_ERROR,
    payload: error,
  }
}

export const fetchUsersForCSV = (userType) => {
  return {
    type: FETCH_FOR_USER_CSV,
    payload: userType,
  }
}

export const fetchForCSVSuccessfully = (payload) => {
  return {
    type: FETCH_FOR_USER_CSV_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchForCSVError = (error) => {
  return {
    type: FETCH_FOR_USER_CSV_ERROR,
    payload: error,
  }
}

export const fetchDriverLogCSV = (query) => {
  return {
    type: FETCH_FOR_DRIVER_LOG_CSV,
    payload: { query },
  }
}

export const fetchDriverLogCSVSuccessfully = (payload) => {
  return {
    type: FETCH_FOR_DRIVER_LOG_CSV_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchDriverLogCSVError = (error) => {
  return {
    type: FETCH_FOR_DRIVER_LOG_CSV_ERROR,
    payload: error,
  }
}

export const fetchDriverLogsCSV = (query) => {
  return {
    type: FETCH_FOR_DRIVER_LOGS_CSV,
    payload: { query },
  }
}

export const fetchDriverLogsCSVSuccessfully = (payload) => {
  return {
    type: FETCH_FOR_DRIVER_LOGS_CSV_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchDriverLogsCSVError = (error) => {
  return {
    type: FETCH_FOR_DRIVER_LOGS_CSV_ERROR,
    payload: error,
  }
}

export const inviteDriver = (payload) => {
  return {
    type: INVITE_DRIVER,
    payload: payload,
  }
}

export const inviteDriverSuccessfully = (payload) => {
  return {
    type: INVITE_DRIVER_SUCCESSFULLY,
    payload: payload,
  }
}

export const inviteDriverFailed = (error) => {
  return {
    type: INVITE_DRIVER_FAILED,
    payload: error,
  }
}

export const fetchClearUser = () => {
  return {
    type: CLEAR_USER,
  }
}

// User details
export const fetchUserDetails = (id) => {
  return {
    type: FETCH_USER_DETAILS,
    payload: { id },
  }
}

export const fetchUserDetailsSuccessfully = (payload) => {
  return {
    type: FETCH_USER_DETAILS_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchUserDetailsError = (error) => {
  return {
    type: FETCH_USER_DETAILS_ERROR,
    payload: error,
  }
}

export const fetchUser = (id) => {
  return {
    type: FETCH_USER_NO_LOADING,
    payload: { id },
  }
}

export const fetchUserSuccessfully = (payload) => {
  return {
    type: FETCH_USER_NO_LOADING_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchUserFailed = (error) => {
  return {
    type: FETCH_USER_NO_LOADING_ERROR,
    payload: error,
  }
}

// FREEZE ACCOUNT
export const freezeAccount = (id, payload) => {
  return {
    type: FREEZE_ACCOUNT,
    payload: { id, payload },
  }
}

export const freezeAccountSuccessfully = (payload) => {
  return {
    type: FREEZE_ACCOUNT_SUCCESSFULLY,
    payload: payload,
  }
}

export const freezeAccountFailed = (error) => {
  return {
    type: FREEZE_ACCOUNT_FAILED,
    payload: error,
  }
}

//ACCOUNT ENTRIES
export const fetchAccountEntries = (id, query) => {
  return {
    type: FETCH_ACCOUNT_SUMMARY,
    payload: { id, query },
  }
}

export const fetchAccountEntriesSuccessfully = (payload) => {
  return {
    type: FETCH_ACCOUNT_SUMMARY_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchAccountEntriesFailed = (error) => {
  return {
    type: FETCH_ACCOUNT_SUMMARY_FAILED,
    payload: error,
  }
}

export const fetchAccountCSV = (id, query) => {
  return {
    type: FETCH_ACC_SUMMARY_CSV,
    payload: { id, query },
  }
}

export const fetchAccountCSVSuccessfully = (payload) => {
  return {
    type: FETCH_ACC_SUMMARY_CSV_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchAccountCSVFailed = (error) => {
  return {
    type: FETCH_ACC_SUMMARY_CSV_FAILED,
    payload: error,
  }
}

//SUMMARY ENTRIES
export const fetchUserSummary = (query) => {
  return {
    type: FETCH_USER_SUMMARY,
    payload: query,
  }
}

export const fetchUserSummarySuccessfully = (payload) => {
  return {
    type: FETCH_USER_SUMMARY_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchUserSummaryFailed = (error) => {
  return {
    type: FETCH_USER_SUMMARY_FAILED,
    payload: error,
  }
}

//SUMMARY ENTRIES
export const fetchBadgeSummary = (query) => {
  return {
    type: FETCH_BADGE_SUMMARY,
    payload: query,
  }
}

export const fetchBadgeSummarySuccessfully = (payload) => {
  return {
    type: FETCH_BADGE_SUMMARY_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchBadgeSummaryFailed = (error) => {
  return {
    type: FETCH_BADGE_SUMMARY_FAILED,
    payload: error,
  }
}

export const deleteUser = (payload) => {
  return {
    type: DELETE_USER,
    payload,
  }
}

export const deleteUserSuccessful = (payload) => {
  return {
    type: DELETE_USER_SUCCESSFUL,
    payload,
  }
}

export const deleteUserFailed = (error) => {
  return {
    type: DELETE_USER_FAILED,
    payload: error,
  }
}

// UPDATE PROFILE
export const updateUserProfile = ( id, userType) => {
  return {
    type: UPDATE_USER_PROFILE,
    payload: { id, userType },
  };
}

export const updateUserProfileSuccessful = (payload) => {
  return {
    type: UPDATE_USER_PROFILE_SUCCESSFUL,
    payload: payload,
  }
}

export const updateUserProfileError = (error) => {
  return {
    type: UPDATE_USER_PROFILE_API_FAILED,
    payload: error,
  }
}

// UPDATE PROFILE
export const activateUserProfile = (payload) => {
  return {
    type: ACTIVATE_USER_PROFILE,
    payload: { payload },
  }
}

export const activateUserProfileSuccessful = (payload) => {
  return {
    type: ACTIVATE_USER_PROFILE_SUCCESSFUL,
    payload: payload,
  }
}

export const activateUserProfileError = (error) => {
  return {
    type: ACTIVATE_USER_PROFILE_API_FAILED,
    payload: error,
  }
}

//DRIVER LOGS
export const fetchDriverLogs = (query) => {
  return {
    type: FETCH_DRIVER_LOGS,
    payload: { query },
  }
}

export const fetchDriverLogsSuccessfully = (payload) => {
  return {
    type: FETCH_DRIVER_LOGS_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchDriverLogsFailed = (error) => {
  return {
    type: FETCH_DRIVER_LOGS_FAILED,
    payload: error,
  }
}

export const fetchAllDriverLogs = (query) => {
  return {
    type: FETCH_ALL_DRIVER_LOGS,
    payload: { query },
  }
}

export const fetchAllDriverLogsSuccessfully = (payload) => {
  return {
    type: FETCH_ALL_DRIVER_LOGS_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchAllDriverLogsFailed = (error) => {
  return {
    type: FETCH_ALL_DRIVER_LOGS_FAILED,
    payload: error,
  }
}
export const driverLogsSummary = (query) => {
  return {
    type: DRIVER_LOGS_SUMMARY,
    payload: query,
  }
}

export const driverLogsSummarySuccessfully = (payload) => {
  return {
    type: DRIVER_LOGS_SUMMARY_SUCCESSFULLY,
    payload: payload,
  }
}

export const driverLogsSummaryFailed = (error) => {
  return {
    type: DRIVER_LOGS_SUMMARY_FAILED,
    payload: error,
  }
}

export const fetchDriverOnlineLogs = (query) => {
  return {
    type: FETCH_DRIVER_ONLINE_LOGS,
    payload: { query },
  }
}

export const fetchDriverOnlineLogsSuccessfully = (payload) => {
  return {
    type: FETCH_DRIVER_ONLINE_LOGS_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchDriverOnlineLogsFailed = (error) => {
  return {
    type: FETCH_DRIVER_ONLINE_LOGS_FAILED,
    payload: error,
  }
}

export const fetchDriverHoursLogs = (query) => {
  return {
    type: FETCH_DRIVER_HOURS_LOGS,
    payload: { query },
  }
}

export const fetchDriverHoursLogsSuccessfully = (payload) => {
  return {
    type: FETCH_DRIVER_HOURS_LOGS_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchDriverHoursLogsFailed = (error) => {
  return {
    type: FETCH_DRIVER_HOURS_LOGS_FAILED,
    payload: error,
  }
}

export const fetchHoursCSV = (payload) => {
  return {
    type: FETCH_HOURS_CSV,
    payload,
  }
}

export const fetchHoursCSVSuccessfully = (payload) => {
  return {
    type: FETCH_HOURS_CSV_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchHoursCSVError = (error) => {
  return {
    type: FETCH_HOURS_CSV_ERROR,
    payload: error,
  }
}

export const fetchUserRoles = (userType) => {
  return {
    type: FETCH_USER_ROLE,
    payload: userType,
  }
}

export const fetchUserRolesSuccessfully = (payload) => {
  return {
    type: FETCH_USER_ROLE_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchUserRolesFailed = (error) => {
  return {
    type: FETCH_USER_ROLE_FAILED,
    payload: error,
  }
}

export const fetchUserExport = (userType) => {
  return {
    type: FETCH_USER_EXPORT,
    payload: userType,
  }
}

export const fetchUserExportSuccessfully = (payload) => {
  return {
    type: FETCH_USER_EXPORT_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchUserExportFailed = (error) => {
  return {
    type: FETCH_USER_EXPORT_FAILED,
    payload: error,
  }
}

export const updateUserRolePackages = (id, data) => {
  return {
    type: UPDATE_USER_ROLE,
    payload: { id, data },
  }
}

export const updateUserRolePackagesSuccessfully = (payload) => {
  return {
    type: UPDATE_USER_ROLE_SUCCESSFULLY,
    payload: payload,
  }
}

export const updateUserRolePackagesFailed = (error) => {
  return {
    type: UPDATE_USER_ROLE_FAILED,
    payload: error,
  }
}
