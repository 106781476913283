import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

// Login Redux States
import {
  UPDATE_USER_ROLE,
  FETCH_ALL_USER,
  APPROVE_USER_ACCOUNT,
  FETCH_USER_EXPORT,
  FETCH_FOR_USER_CSV,
  FETCH_ACCOUNT_SUMMARY,
  FETCH_USER_ROLE,
  FETCH_ACC_SUMMARY_CSV,
  FETCH_DRIVER_LOGS,
  FETCH_ALL_DRIVER_LOGS,
  FETCH_DRIVER_ONLINE_LOGS,
  FETCH_DRIVER_HOURS_LOGS,
  FETCH_USER_SUMMARY,
  FETCH_BADGE_SUMMARY,
  DRIVER_LOGS_SUMMARY,
  FETCH_USER_DETAILS,
  CREATE_USER,
  FETCH_USER_NO_LOADING,
  UPDATE_USER_PROFILE,
  ACTIVATE_USER_PROFILE,
  FREEZE_ACCOUNT,
  DELETE_USER,
  INVITE_DRIVER,
  FETCH_FOR_DRIVER_LOG_CSV,
  FETCH_FOR_DRIVER_LOGS_CSV,
  FETCH_HOURS_CSV,
} from './actionTypes'

import {
  updateUserRolePackagesSuccessfully,
  updateUserRolePackagesFailed,
  fetchUserRolesSuccessfully,
  fetchUserRolesFailed,
  fetchUserError,
  fetchAllUserSuccessfully,
  inviteDriverSuccessfully,
  inviteDriverFailed,
  fetchForCSVSuccessfully,
  fetchForCSVError,
  fetchDriverLogsSuccessfully,
  fetchDriverLogsFailed,
  fetchAllDriverLogsSuccessfully,
  fetchAllDriverLogsFailed,
  fetchDriverLogCSVSuccessfully,
  fetchDriverLogCSVError,
  fetchDriverLogsCSVSuccessfully,
  fetchDriverLogsCSVError,
  fetchDriverHoursLogsSuccessfully,
  fetchDriverHoursLogsFailed,
  fetchDriverOnlineLogsSuccessfully,
  fetchDriverOnlineLogsFailed,
  fetchUserDetailsSuccessfully,
  fetchUserDetailsError,
  fetchUserSuccessfully,
  fetchUserFailed,
  fetchUserAuthorizeError,
  createUserSuccessful,
  createUserFailed,
  freezeAccount,
  updateUserProfileSuccessful,
  updateUserProfileError,
  activateUserProfileSuccessful,
  activateUserProfileError,
  fetchUserSummaryFailed,
  fetchBadgeSummaryFailed,
  fetchUserSummarySuccessfully,
  fetchBadgeSummarySuccessfully,
  driverLogsSummarySuccessfully,
  driverLogsSummaryFailed,
  freezeAccountSuccessfully,
  fetchAccountEntriesSuccessfully,
  fetchAccountEntriesFailed,
  fetchAccountCSVSuccessfully,
  fetchAccountCSVFailed,
  freezeAccountFailed,
  deleteUserFailed,
  deleteUserSuccessful,
  fetchHoursCSVSuccessfully,
  fetchHoursCSVError,
  fetchUserExportSuccessfully,
  fetchUserExportFailed,
  approveUserAccountSuccessfully,
  approveUserAccountError,
} from './actions'

import {
  fetchAllUserService,
  fetchUserDetailsService,
  freezeAccountService,
  fetchUserSummaryService,
  fetchBadgeSummaryService,
  fetchDriverLogsServices,
  AllDriverLogsServices,
  updateUserProfileServices,
  activateUserProfileServices,
  fetchAccountEntriesServices,
  DeleteUserService,
  openDriverAppService,
  driverLogsSummaryService,
  fetchDriverOnlineLogsServices,
  fetchDriverHoursLogsServices,
  updateUserRoleService,
  fetchUserRoleService,
  CreateUserService,
  fetchUserExportService,
  approveUserAccountService,
} from '../../services/userServices'

function* fetchUserRoleHandler({ payload }) {
  try {
    const response = yield call(fetchUserRoleService, payload)
    yield put(fetchUserRolesSuccessfully(response.data))
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchUserRolesFailed(
          'Access to this page is restricted to authorized users only',
        ),
      )
      return
    } else if (error.response.status === 429) {
      yield put(
        fetchUserRolesFailed(
          'Too much request, please refresh and try again after few seconds',
        ),
      )
    } else {
      yield put(fetchUserRolesFailed(error?.response?.data?.message))
    }
  }
}

function* updateUserRoleHandler({ payload }) {
  try {
    const response = yield call(updateUserRoleService, payload)
    yield put(updateUserRolePackagesSuccessfully(response.data))
    console.log(response.data)
  } catch (error) {
    yield put(updateUserRolePackagesFailed(error?.response?.data?.message))
  }
}

function* fetchUserExportHandler({ payload }) {
  try {
    const response = yield call(fetchUserExportService, payload)
    yield put(fetchUserExportSuccessfully(response.data))
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchUserExportFailed(
          'Access to this page is restrictedto authorized users only',
        ),
      )
      return
    } else if (error.response.status === 429) {
      yield put(
        fetchUserExportFailed(
          'Too much request, please refresh and try again after few seconds',
        ),
      )
    } else {
      yield put(fetchUserExportFailed(error?.response?.data?.message))
    }
  }
}
function* fetchUserHandler({ payload }) {
  try {
    const response = yield call(fetchAllUserService, payload)
    yield put(fetchAllUserSuccessfully(response.data))
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchUserAuthorizeError(
          'Access to this page is restricted to authorized users only',
        ),
      )
      return
    } else if (error.response.status === 429) {
      yield put(
        fetchUserError(
          'Too much request, please refresh and try again after few seconds',
        ),
      )
    } else {
      yield put(fetchUserError(error?.response?.data?.message))
    }
  }
}

function* fetchUsersForCSVHandler({ payload }) {
  try {
    const response = yield call(fetchAllUserService, payload)
    yield put(fetchForCSVSuccessfully(response.data))
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchUserAuthorizeError(
          'Access to this page is restricted to authorized users only',
        ),
      )
      return
    } else if (error.response.status === 429) {
      yield put(
        fetchForCSVError(
          'Too much request, please refresh and try again after few seconds',
        ),
      )
    } else {
      yield put(fetchForCSVError(error?.response?.data?.message))
    }
  }
}

function* approveUserAccountHandler({ payload }) {
  try {
    const response = yield call(approveUserAccountService, payload)
    yield put(approveUserAccountSuccessfully(response.data))
  } catch (error) {
    yield put(approveUserAccountError(error?.response?.data?.message))
  }
}

function* inviteDriverHandler({ payload }) {
  try {
    const response = yield call(openDriverAppService, payload)
    console.log(response)
    yield put(inviteDriverSuccessfully(response?.data))
  } catch (error) {
    console.log(error)
    yield put(inviteDriverFailed(error?.response?.data?.message))
  }
}

function* createUserHandler({ payload }) {
  try {
    const response = yield call(CreateUserService, payload)
    yield put(createUserSuccessful(response.data.result))
  } catch (error) {
    yield put(createUserFailed(error?.response?.data?.message))
  }
}

function* fetchUserDetails({ payload: { id } }) {
  try {
    const response = yield call(fetchUserDetailsService, id)
    yield put(fetchUserDetailsSuccessfully(response.data.result))
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchUserDetailsError(
          'Access to this page is restricted to authorized users only',
        ),
      )
      return
    } else if (error.response.status === 429) {
      yield put(
        fetchUserDetailsError(
          'Too much request, please refresh and try again after few seconds',
        ),
      )
    } else {
      yield put(fetchUserDetailsError('user with the id does not found'))
    }
  }
}

function* fetchUser({ payload: { id } }) {
  try {
    const response = yield call(fetchUserDetailsService, id)
    yield put(fetchUserSuccessfully(response.data.result))
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchUserFailed(
          'Access to this page is restricted to authorized users only',
        ),
      )
      return
    } else if (error.response.status === 429) {
      yield put(
        fetchUserFailed(
          'Too much request, please refresh and try again after few seconds',
        ),
      )
    } else {
      yield put(fetchUserFailed('user with the id does not found'))
    }
  }
}

function* freezeAccountHandler({ payload }) {
  try {
    const response = yield call(freezeAccountService, payload)
    yield put(freezeAccountSuccessfully(response))
  } catch (error) {
    yield put(freezeAccountFailed(error?.response?.data?.message))
  }
}

// Account Entries
function* fetchAccountEntriesHandler({ payload }) {
  try {
    const response = yield call(fetchAccountEntriesServices, payload)
    yield put(fetchAccountEntriesSuccessfully(response.data))
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchAccountEntriesFailed(
          'Access to this page is restricted to authorized users only',
        ),
      )
      return
    } else if (error.response.status === 429) {
      yield put(
        fetchAccountEntriesFailed(
          'Too much request, please refresh and try again after few seconds',
        ),
      )
    } else {
      yield put(fetchAccountEntriesFailed(error?.response?.data?.message))
    }
  }
}

function* fetchAccountCSVHandler({ payload }) {
  try {
    const response = yield call(fetchAccountEntriesServices, payload)
    yield put(fetchAccountCSVSuccessfully(response.data))
  } catch (error) {
    yield put(fetchAccountCSVFailed(error?.response?.data?.message))
  }
}

// USER SUMMARY
function* fetchUserSummaryHandler({ payload }) {
  try {
    const response = yield call(fetchUserSummaryService, payload)
    yield put(fetchUserSummarySuccessfully(response.data))
  } catch (error) {
    yield put(fetchUserSummaryFailed(error?.response?.data?.message))
  }
}

// Badge SUMMARY
function* fetchBadgeSummaryHandler({ payload }) {
  try {
    const response = yield call(fetchBadgeSummaryService, payload)
    yield put(fetchBadgeSummarySuccessfully(response.data))
  } catch (error) {
    yield put(fetchBadgeSummaryFailed(error?.response?.data?.message))
  }
}

function* driverLogsSummaryHandler({ payload }) {
  try {
    const response = yield call(driverLogsSummaryService, payload)
    yield put(driverLogsSummarySuccessfully(response.data))
  } catch (error) {
    yield put(driverLogsSummaryFailed(error?.response?.data?.message))
  }
}

function* deleteUserHandler({ payload }) {
  console.log(payload)
  try {
    const response = yield call(DeleteUserService, payload)
    yield put(deleteUserSuccessful(response.data.message))
  } catch (error) {
    yield put(deleteUserFailed(error?.response?.data?.message))
  }
}

function* UpdateUserProfileHandler({ payload }) {
  try {
    const response = yield call(updateUserProfileServices, payload)
    yield put(updateUserProfileSuccessful(response.data.result))
  } catch (error) {
    // console.log(error?.response?.data?.message);
    yield put(updateUserProfileError(error?.response?.data?.message))
  }
}

function* ActivateUserProfileHandler({ payload }) {
  try {
    const response = yield call(activateUserProfileServices, payload)
    yield put(activateUserProfileSuccessful(response.data.result))
  } catch (error) {
    // console.log(error?.response?.data?.message);
    yield put(activateUserProfileError(error?.response?.data?.message))
  }
}

function* fetchDriverLogsHandler({ payload }) {
  try {
    const response = yield call(fetchDriverLogsServices, payload)
    yield put(fetchDriverLogsSuccessfully(response.data))
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchDriverLogsFailed(
          'Access to this page is restricted to authorized users only',
        ),
      )
      return
    } else if (error.response.status === 429) {
      yield put(
        fetchDriverLogsFailed(
          'Too much request, please refresh and try again after few seconds',
        ),
      )
    } else {
      yield put(fetchDriverLogsFailed(error?.response?.data?.message))
    }
  }
}

function* fetchDriverLogCSVHandler({ payload }) {
  try {
    const response = yield call(fetchDriverLogsServices, payload)
    yield put(fetchDriverLogCSVSuccessfully(response.data))
  } catch (error) {
    console.log(error)
    yield put(fetchDriverLogCSVError())
  }
}

function* fetchAllDriverLogsHandler({ payload }) {
  try {
    const response = yield call(AllDriverLogsServices, payload)
    yield put(fetchAllDriverLogsSuccessfully(response.data))
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchAllDriverLogsFailed(
          'Access to this page is restricted to authorized users only',
        ),
      )
      return
    } else if (error.response.status === 429) {
      yield put(
        fetchAllDriverLogsFailed(
          'Too much request, please refresh and try again after few seconds',
        ),
      )
    } else {
      yield put(fetchAllDriverLogsFailed(error?.response?.data?.message))
    }
  }
}

function* fetchAllDriverLogsCSVHandler({ payload }) {
  try {
    const response = yield call(AllDriverLogsServices, payload)
    yield put(fetchDriverLogsCSVSuccessfully(response.data))
  } catch (error) {
    yield put(fetchDriverLogsCSVError(error?.response?.data?.message))
  }
}

function* fetchDriverOnlineLogsHandler({ payload }) {
  try {
    const response = yield call(fetchDriverOnlineLogsServices, payload)
    yield put(fetchDriverOnlineLogsSuccessfully(response.data))
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchDriverOnlineLogsFailed(
          'Access to this page is restricted to authorized users only',
        ),
      )
      return
    } else if (error.response.status === 429) {
      yield put(
        fetchDriverOnlineLogsFailed(
          'Too much request, please refresh and try again after few seconds',
        ),
      )
    } else {
      yield put(fetchDriverOnlineLogsFailed(error?.response?.data?.message))
    }
  }
}
function* fetchDriverHoursLogsHandler({ payload }) {
  try {
    const response = yield call(fetchDriverHoursLogsServices, payload)
    yield put(fetchDriverHoursLogsSuccessfully(response.data))
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchDriverHoursLogsFailed(
          'Access to this page is restricted to authorized users only',
        ),
      )
      return
    } else if (error.response.status === 429) {
      yield put(
        fetchDriverHoursLogsFailed(
          'Too much request, please refresh and try again after few seconds',
        ),
      )
    } else {
      yield put(fetchDriverHoursLogsFailed(error?.response?.data?.message))
    }
  }
}

function* fetchHoursCSVHandler({ payload }) {
  try {
    const response = yield call(fetchDriverHoursLogsServices, payload)
    yield put(fetchHoursCSVSuccessfully(response.data))
  } catch (error) {
    yield put(fetchHoursCSVError(error?.response.data?.message))
  }
}

export function* watchUserRoleHandler() {
  yield takeEvery(FETCH_USER_ROLE, fetchUserRoleHandler)
}
export function* watchUserExportHandler() {
  yield takeEvery(FETCH_USER_EXPORT, fetchUserExportHandler)
}
export function* watchUpdateUserRoleHandler() {
  yield takeEvery(UPDATE_USER_ROLE, updateUserRoleHandler)
}
export function* watchDriverLogs() {
  yield takeEvery(FETCH_DRIVER_LOGS, fetchDriverLogsHandler)
}
export function* watchDriverOnlineLogs() {
  yield takeEvery(FETCH_DRIVER_ONLINE_LOGS, fetchDriverOnlineLogsHandler)
}
export function* watchDriverHoursLogs() {
  yield takeEvery(FETCH_DRIVER_HOURS_LOGS, fetchDriverHoursLogsHandler)
}

export function* watchApproveUserAccount() {
  yield takeEvery(APPROVE_USER_ACCOUNT, approveUserAccountHandler)
}

export function* watchHoursCSV() {
  yield takeEvery(FETCH_HOURS_CSV, fetchHoursCSVHandler)
}

export function* watchDriverLogCSV() {
  yield takeEvery(FETCH_FOR_DRIVER_LOG_CSV, fetchDriverLogCSVHandler)
}

export function* watchAllDriverLogs() {
  yield takeEvery(FETCH_ALL_DRIVER_LOGS, fetchAllDriverLogsHandler)
}

export function* watchAllDriverLogsCSV() {
  yield takeEvery(FETCH_FOR_DRIVER_LOGS_CSV, fetchAllDriverLogsCSVHandler)
}

export function* watchALLUser() {
  yield takeEvery(FETCH_ALL_USER, fetchUserHandler)
}
export function* watchCreateUser() {
  yield takeEvery(CREATE_USER, createUserHandler)
}

export function* watchUsersForCSV() {
  yield takeEvery(FETCH_FOR_USER_CSV, fetchUsersForCSVHandler)
}

export function* watchInviteDriver() {
  yield takeEvery(INVITE_DRIVER, inviteDriverHandler)
}

export function* watchFreezeAccount() {
  yield takeEvery(FREEZE_ACCOUNT, freezeAccountHandler)
}

export function* watchUpdateUserProfile() {
  yield takeEvery(UPDATE_USER_PROFILE, UpdateUserProfileHandler)
}

export function* watchActivateUserProfile() {
  yield takeEvery(ACTIVATE_USER_PROFILE, ActivateUserProfileHandler)
}

export function* watchFetchUserSummary() {
  yield takeEvery(FETCH_USER_SUMMARY, fetchUserSummaryHandler)
}

export function* watchFetchBadgeSummary() {
  yield takeEvery(FETCH_BADGE_SUMMARY, fetchBadgeSummaryHandler)
}

export function* watchDriverLogsSummary() {
  yield takeEvery(DRIVER_LOGS_SUMMARY, driverLogsSummaryHandler)
}

export function* watchFetchUserDetails() {
  yield takeEvery(FETCH_USER_DETAILS, fetchUserDetails)
}

export function* watchFetchUser() {
  yield takeEvery(FETCH_USER_NO_LOADING, fetchUser)
}

export function* watchFetchAccountEntries() {
  yield takeEvery(FETCH_ACCOUNT_SUMMARY, fetchAccountEntriesHandler)
}

export function* watchFetchAccountCSV() {
  yield takeEvery(FETCH_ACC_SUMMARY_CSV, fetchAccountCSVHandler)
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUserHandler)
}

function* userSaga() {
  yield all([
    fork(watchALLUser),
    fork(watchUsersForCSV),
    fork(watchInviteDriver),
    fork(watchUpdateUserProfile),
    fork(watchActivateUserProfile),
    fork(watchFetchUserDetails),
    fork(watchApproveUserAccount),
    fork(watchFetchUser),
    fork(watchFreezeAccount),
    fork(watchDriverLogs),
    fork(watchDriverOnlineLogs),
    fork(watchDriverHoursLogs),
    fork(watchHoursCSV),
    fork(watchDriverLogCSV),
    fork(watchAllDriverLogsCSV),
    fork(watchAllDriverLogs),
    fork(watchUserRoleHandler),
    fork(watchUpdateUserRoleHandler),
    fork(watchCreateUser),
    fork(watchFetchUserSummary),
    fork(watchFetchBadgeSummary),
    fork(watchDriverLogsSummary),
    fork(watchDeleteUser),
    fork(watchFetchAccountEntries),
    fork(watchFetchAccountCSV),
    fork(watchUserExportHandler),
  ])
}

export default userSaga
