import {
  FETCH_ROUTES,
  FETCH_ROUTES_SUCCESSFUL,
  FETCH_ROUTES_API_FAILED,
  FETCH_EACH_ROUTE,
  FETCH_EACH_ROUTE_SUCCESSFUL,
  FETCH_EACH_ROUTE_API_FAILED,
  DELETE_ROUTE,
  DELETE_ROUTE_SUCCESSFUL,
  DELETE_ROUTE_FAILED,
  CREATE_ROUTE,
  CREATE_ROUTE_SUCCESSFUL,
  CREATE_ROUTE_FAILED,
  UPDATE_ROUTE,
  UPDATE_ROUTE_SUCCESSFUL,
  UPDATE_ROUTE_FAILED,
} from './actionTypes';

const initialState = {
  routes: null,
  routeError: null,
  tableError: null,
  route: null,
  isAuthorize: null,
  message: null,
  loading: false,
};

const Route = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROUTES:
    case UPDATE_ROUTE:
    case DELETE_ROUTE:
      state = {
        ...state,
        routes: null,
        tableError: null,
        routeError: null,
        loading: true,
        message: null,
        isAuthorize: null,
      };
      break;
    case FETCH_ROUTES_SUCCESSFUL:
      state = {
        ...state,
        routes: action.payload,
        tableError: null,
        routeError: null,
        loading: false,
      };
      break;

    case DELETE_ROUTE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        routeError: null,
        message: 'Route deleted successfully',
      };
      break;

    case UPDATE_ROUTE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        routeError: null,
        message: 'Route Updated Successfully',
      };
      break;

    case FETCH_EACH_ROUTE:
      state = {
        ...state,
        loading: true,
        routes: null,
        routeError: null,
        message: null,
        isAuthorize: null,
      };
      break;

    case FETCH_EACH_ROUTE_SUCCESSFUL:
      state = {
        ...state,
        route: action.payload,
        loading: false,
        routeError: null,
        message: null,
      };
      break;

    case CREATE_ROUTE:
      state = {
        ...state,
        loading: true,
        routeError: null,
        message: null,
      };
      break;

    case CREATE_ROUTE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        routeError: null,
        message: `Route  created successfully`,
      };
      break;

    case FETCH_ROUTES_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case CREATE_ROUTE_FAILED:
    case DELETE_ROUTE_FAILED:
    case FETCH_EACH_ROUTE_API_FAILED:
      state = {
        ...state,
        routes: null,
        route: null,
        loading: false,
        message: null,
        routeError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_ROUTE_FAILED:
      state = {
        ...state,
        loading: false,
        routeError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Route;
