import {
  CREATE_CASHOUT,
  CREATE_CASHOUT_SUCCESSFUL,
  CREATE_CASHOUT_FAILED,
  FETCH_CASHOUT,
  FETCH_CASHOUT_SUCCESSFUL,
  FETCH_CASHOUT_API_FAILED,
  FETCH_EACH_CASHOUT,
  FETCH_EACH_CASHOUT_SUCCESSFUL,
  FETCH_EACH_CASHOUT_API_FAILED,
  DELETE_CASHOUT,
  DELETE_CASHOUT_SUCCESSFUL,
  DELETE_CASHOUT_FAILED,
  UPDATE_CASHOUT,
  UPDATE_CASHOUT_SUCCESSFUL,
  UPDATE_CASHOUT_FAILED,
  UPDATE_CASHOUT_STATUS,
  UPDATE_CASHOUT_STATUS_SUCCESSFUL,
  UPDATE_CASHOUT_STATUS_FAILED,
  FETCH_FOR_CASHOUT_CSV,
  FETCH_FOR_CASHOUT_CSV_SUCCESSFULLY,
  FETCH_FOR_CASHOUT_CSV_ERROR,
  FETCH_CASHOUT_SUMMARY,
  FETCH_CASHOUT_SUMMARY_SUCCESSFULLY,
  FETCH_CASHOUT_SUMMARY_FAILED,
} from './actionTypes';

const initialState = {
  cashout: null,
  cashoutError: null,
  tableError: null,
  cashoutPreview: null,
  message: null,
  loading: false,
  csvData: null,
  csv_loading: false,
};

const cashout = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CASHOUT:
    case UPDATE_CASHOUT_STATUS:
    case DELETE_CASHOUT:
      state = {
        ...state,
        tableError: null,
        cashout: null,
        cashoutError: null,
        tableError: null,
        cashoutRecipient: null,
        loading: true,
        message: null,
      };
      break;

    case UPDATE_CASHOUT:
      state = {
        ...state,
        tableError: null,
        cashoutPreview: null,
        cashoutError: null,
        loading: true,
        message: null,
      };
      break;

    case FETCH_CASHOUT_SUCCESSFUL:
      state = {
        ...state,
        tableError: null,
        cashout: action.payload,
        cashoutError: null,
        loading: false,
      };
      break;

    case DELETE_CASHOUT_SUCCESSFUL:
      state = {
        ...state,
        tableError: null,
        loading: false,
        cashoutError: null,
        message: 'cashout details deleted successfully',
      };
      break;
    case UPDATE_CASHOUT_SUCCESSFUL:
    case UPDATE_CASHOUT_STATUS_SUCCESSFUL:
      state = {
        ...state,
        tableError: null,
        loading: false,
        cashoutError: null,
        message: 'cashout details updated Successfully',
      };
      break;

    case FETCH_EACH_CASHOUT:
      state = {
        ...state,
        loading: true,
        tableError: null,
        cashoutError: null,
        message: null,
      };
      break;
    case FETCH_EACH_CASHOUT_SUCCESSFUL:
      state = {
        ...state,
        cashoutPreview: action.payload,
        loading: false,
        cashoutError: null,
        message: null,
      };
      break;
    case CREATE_CASHOUT:
      state = {
        ...state,
        loading: true,
        cashoutError: null,
        message: null,
      };
      break;

    case CREATE_CASHOUT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        cashoutError: null,
        message: `₦${action.payload.amount} cashout was requested successfully`,
      };
      break;
    case FETCH_CASHOUT_API_FAILED:
      state = {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case DELETE_CASHOUT_FAILED:
    case CREATE_CASHOUT_FAILED:
    case FETCH_EACH_CASHOUT_API_FAILED:
      state = {
        ...state,
        cashout: null,
        cashoutPreview: null,
        loading: false,
        message: null,
        cashoutError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_CASHOUT_FAILED:
    case UPDATE_CASHOUT_STATUS_FAILED:
      state = {
        ...state,
        loading: false,
        cashoutError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;

    case FETCH_FOR_CASHOUT_CSV:
      state = {
        ...state,
        csvData: null,
        csv_loading: false,
      };
      break;

    case FETCH_FOR_CASHOUT_CSV_SUCCESSFULLY:
      state = {
        ...state,
        csvData: action.payload,
        csv_loading: false,
      };
      break;

    case FETCH_FOR_CASHOUT_CSV_ERROR:
      state = {
        ...state,
        csvData: null,
        csv_loading: false,
      };
      break;

    case FETCH_CASHOUT_SUMMARY:
      return {
        ...state,
        cashoutSummary: null,
        summaryError: null,
        loading: true,
      };

    case FETCH_CASHOUT_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        cashoutSummary: action.payload,
        summaryError: null,
        loading: false,
      };

    case FETCH_CASHOUT_SUMMARY_FAILED:
      return {
        ...state,
        cashoutSummary: null,
        summaryError: action.payload,
        loading: false,
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default cashout;
