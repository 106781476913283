import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const ListPackageService = (query) => {
  const http = new HttpService();
  let url = 'package/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const ListPackageVehicleService = ({ packageId, query }) => {
  const http = new HttpService();
  let url = `package/${packageId}/vehicles`;
  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const CreatePackageService = (payload) => {
  const http = new HttpService();
  const url = 'package';
  return http.postData(payload, url);
};

export const FetchEachPackageService = (query) => {
  const http = new HttpService();
  const url = `package/${query}`;
  return http.getData(url);
};

export const DeletePackageService = (id) => {
  const http = new HttpService();
  const url = `package/${id}`;
  return http.deleteData(url);
};

export const UpdatePackageService = (payload) => {
  const http = new HttpService();
  let url = `package/${payload.id}`;
  if (payload.type) {
    url = `package/${payload.id}/zone`;
  }
  return http.putData(payload, url);
};

export const fetchPackageSummaryService = (query) => {
  // Payload with query
  const http = new HttpService();
  let url = 'summary/package';
  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const addPackageToVehiclesService = (payload) => {
  const http = new HttpService();
  let url = `package/${payload.id}/vehicle`;
  return http.putData(payload, url);
};

export const deletePackageFromVehiclesService = (payload) => {
  console.log(payload);
  // const http = new HttpService();
  // let url = `package/${payload.id}/vehicle`;
  // return http.deleteDataWithData(payload, url);
};

export const removeVehiclesFromPackageService = (payload) => {
  const http = new HttpService();
  const url = `package/${payload.id}/vehicle`;
  return http.deleteDataWithData(payload.data, url);
};
