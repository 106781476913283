import {
  FETCH_CASHOUT_ANALYTIC,
  FETCH_CASHOUT_ANALYTIC_SUCCESSFUL,
  FETCH_CASHOUT_ANALYTIC_API_FAILED,
  FETCH_EACH_CASHOUT_ANALYTIC,
  FETCH_EACH_CASHOUT_ANALYTIC_SUCCESSFUL,
  FETCH_EACH_CASHOUT_ANALYTIC_API_FAILED,
} from './actionTypes';
export const FetchEachCashoutAnalytics = (id) => {
  return {
    type: FETCH_EACH_CASHOUT_ANALYTIC,
    payload: id,
  };
};

export const FetchEachCashoutAnalyticsSuccessful = (payload) => {
  return {
    type: FETCH_EACH_CASHOUT_ANALYTIC_SUCCESSFUL,
    payload: payload,
  };
};

export const FetchEachCashoutAnalyticsError = (error) => {
  return {
    type: FETCH_EACH_CASHOUT_ANALYTIC_API_FAILED,
    payload: error,
  };
};

export const FetchCashoutAnalytics = (payload) => {
  return {
    type: FETCH_CASHOUT_ANALYTIC,
    payload,
  };
};

export const FetchCashoutAnalyticsSuccessful = (payload) => {
  return {
    type: FETCH_CASHOUT_ANALYTIC_SUCCESSFUL,
    payload: payload,
  };
};

export const FetchCashoutAnalyticsError = (error) => {
  return {
    type: FETCH_CASHOUT_ANALYTIC_API_FAILED,
    payload: error,
  };
};
