import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledTooltip,
  Button,
} from 'reactstrap';
import FilterDropDown from '../../../components/Common/FilterDropDown';
import SimpleBar from 'simplebar-react';
import { CSVLink } from 'react-csv';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import imgCar from '../../../assets/images/sample_car.png';
import ReactPaginate from 'react-paginate';
import Loader from '../../../components/Common/Loading/index.js';
import Moment from 'moment';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../utils/general';
import queryString from 'query-string';
import AssignVehiclePackage from './components/assignedPackage';
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';
let skip = 0;
let dataLimit = 10;

const VehicleTable = ({
  vehicleData,
  previewAction,
  fetchPackageFailed,
  addPackageHandler,
  packages,
  fetchVehicle,
  deleteVehicle,
  handleStatusUpdate,
  packageMsg,
  message,
  history,
  dateFilter,
  CheckAction,
  ReadCheck,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [AddVehicleModal, setAddVehicleModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [skipPage, setSkipPage] = useState(0);
  const [pkg, setPackage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });
  const [status, setStatus] = useState('');
  const [menu, setMenu] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(vehicleData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = { skip: pageNumber, take: dataLimit, search };
    fetchVehicle(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take, search };
    setSkipPage(Math.ceil(pageNumber / take));
    fetchVehicle(pageInfo);
    ScrollToTop();
    SetUrlWithParams();
  };
  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      // skip,
      status,
    };
    fetchVehicle(queryParams);
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    dateFilter({ start: values.startDate, end: values.endDate });
    setMenu(false);
    setStatus(values.vehicleStatus);
    let queryParams = {
      search,
      take,
      skip,
      packages: values.packages,
      status: values.vehicleStatus,
      start: values.startDate,
      end: values.endDate,
    };
    fetchVehicle(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: '',
      end: '',
    });
    dateFilter({});
    setPackage('');
    let queryParams = {
      search,
      take,
      skip,
      status,
    };
    fetchVehicle(queryParams);
  };

  useEffect(() => {
    if (message || packageMsg) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          status,
        };
        fetchVehicle(queryParams);

        fetchPackageFailed('');
      }, 1000);
    }
  }, [message, fetchVehicle, packageMsg]);

  useEffect(() => {
    if (vehicleData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(vehicleData?.total / take));
      // SET CSV EXPORT DATA
      const csvData = vehicleData.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Driver_Name: `${data?.user?.firstName} - ${data?.user?.lastName} `,
          Make: data?.make ? data.make : '----',
          Model: data?.model ? data.model : '----',
          Description: data?.description ? data.description : '----',
          Color: data?.color ? data.color : '----',
          Year: data?.year ? data.year : '----',
          Seats: data?.seats ? data.seats : '----',
          Plate_Number: data?.plateNumber ? data.plateNumber : '----',
          Status: data?.status,
          Image_Front: data?.imageFront ? data.imageFront : '----',
          Image_Back: data?.imageBack ? data.imageBack : '----',
          Image_Left: data?.imageLeft ? data.imageLeft : '----',
          Image_Right: data?.imageRight ? data.imageRight : '----',
          Craeted_Date: data?.createdAt?.substring(0, 10),
        };

        return formData;
      });
      setCsvData(csvData);
    }
  }, [vehicleData]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchVehicle(parsedUrlParams);
  }, [fetchVehicle]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      status,
      take,
      skip: pageNumber,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        take,
        skip,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.status) {
      setStatus(parsedUrlParams?.status);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };
  let accessChecking = ReadCheck(accessControl, `Vehicles`);
  const tableDataHandler = () => {
    const rowData = vehicleData?.result?.map((data, i) => {
      return {
        idKey: data.id,
        index: <div className="text-truncate">{skip > 0 ? i + 1 + pageNumber : i + 1}</div>,

        id: data.id.substring(0, 7),
        make: (
          <>
            {accessChecking ?
              <Link
                to={`/vehicle/${data.id}`}
                // onClick={() => previewAction(data.id)}
                className="text-dark font-weight-bold align-items-center d-flex flex-column"
              >
                <Row className="align-items-center">
                  <Col className="mb-2 px-0 py-0 text-center" sm={3}>
                    <img
                      className="rounded avatar-sm border shadow-xs p-2"
                      alt="Profile"
                      src={data.imageFront ? data?.imageFront : imgCar}
                    />
                  </Col>
                  <Col sm={9}>
                    <span className="mt-1 mb-1">
                      <h4 className="font-weight-bold font-size-12 text-sm-left text-center mb-0">
                        {data?.make}{' '}
                        <span>
                          <span
                            className={`font-size-10 badge badge-${data.color.toLowerCase() === 'red'
                              ? 'danger'
                              : data.color.toLowerCase() === 'blue'
                                ? 'primary'
                                : data.color.toLowerCase() === 'black'
                                  ? 'dark text-white'
                                  : data.color.toLowerCase() === 'gold'
                                    ? 'warning'
                                    : data.color.toLowerCase() === 'green'
                                      ? 'success'
                                      : data.color.toLowerCase() === 'grey'
                                        ? 'secondary text-white'
                                        : 'soft-secondary text-dark'
                              } text-white rounded text-capitalize`}
                          >
                            {' '}
                            {data?.color}{' '}
                          </span>
                        </span>
                      </h4>
                    </span>
                    <div className='d-flex flex-column'>
                      <span className="text-muted mb-0 font-size-11 text-sm-left text-center">
                        Model: {data?.model}
                      </span>
                      <span className="text-muted mb-0 font-size-11 text-sm-left text-center">
                        Capacity: {data.seats}
                      </span>
                    </div>
                    <p className="text-muted text-sm-left text-center font-size-11 mb-0">
                      {data?.plateNumber} | {data?.year}
                    </p>
                  </Col>
                </Row>
                <h1
                  className={`mt-2 badge badge-soft-${data.status.toLowerCase() === 'approved'
                    ? 'success'
                    : data.status.toLowerCase() === 'cancelled'
                      ? 'danger'
                      : data.status.toLowerCase() === 'rejected'
                        ? 'danger'
                        : data.status.toLowerCase() === 'pending'
                          ? 'warning'
                          : data.status.toLowerCase() === 'review'
                            ? 'dark'
                            : ''
                    } font-size-12`}
                >
                  {data.status ? data.status : '---'}
                </h1>
              </Link>

              :
              <div className='text-dark font-weight-bold align-items-center d-flex flex-column'>
                <Row className="align-items-center">
                  <Col className="mb-2 px-0 py-0 text-center" sm={3}>
                    <img
                      className="rounded avatar-sm border shadow-xs p-2"
                      alt="Profile"
                      src={data.imageFront ? data?.imageFront : imgCar}
                    />
                  </Col>
                  <Col sm={9}>
                    <span className="mt-1 mb-1">
                      <h4 className="font-weight-bold font-size-12 text-sm-left text-center mb-0">
                        {data?.make}{' '}
                        <span>
                          <span
                            className={`font-size-10 badge badge-${data.color.toLowerCase() === 'red'
                              ? 'danger'
                              : data.color.toLowerCase() === 'blue'
                                ? 'primary'
                                : data.color.toLowerCase() === 'black'
                                  ? 'dark text-white'
                                  : data.color.toLowerCase() === 'gold'
                                    ? 'warning'
                                    : data.color.toLowerCase() === 'green'
                                      ? 'success'
                                      : data.color.toLowerCase() === 'grey'
                                        ? 'secondary text-white'
                                        : 'soft-secondary text-dark'
                              } text-white rounded text-capitalize`}
                          >
                            {' '}
                            {data?.color}{' '}
                          </span>
                        </span>
                      </h4>
                    </span>
                    <div className='d-flex flex-column'>
                      <span className="text-muted mb-0 font-size-11 text-sm-left text-center">
                        Model: {data?.model}
                      </span>
                      <span className="text-muted mb-0 font-size-11 text-sm-left text-center">
                        Capacity: {data.seats}
                      </span>
                    </div>
                    <p className="text-muted text-sm-left text-center font-size-11 mb-0">
                      {data?.plateNumber} | {data?.year}
                    </p>
                  </Col>
                </Row>
                <h1
                  className={`mt-2 badge badge-soft-${data.status.toLowerCase() === 'approved'
                    ? 'success'
                    : data.status.toLowerCase() === 'cancelled'
                      ? 'danger'
                      : data.status.toLowerCase() === 'rejected'
                        ? 'danger'
                        : data.status.toLowerCase() === 'pending'
                          ? 'warning'
                          : data.status.toLowerCase() === 'review'
                            ? 'dark'
                            : ''
                    } font-size-12`}
                >
                  {data.status ? data.status : '---'}
                </h1>
              </div>
            }
          </>
        ),
        packages: (
          <div>
            {data.packages.length > 0
              ? data.packages.map((pkg) => (
                <div key={pkg.id}>
                  <span
                    className={`badge text-white font-size-12 text-capitalize`}
                    style={{ background: pkg?.colorCode }}
                  >
                    {' '}
                    {pkg.name}
                  </span>
                </div>
              ))
              : 'No package'}
          </div>
        ),
        inspection: 'CSoon',
        model: data.model,
        plateNumber: data.plateNumber,
        year: data.year,
        date: (
          <div>
            {' '}
            <div>{Moment(data.createdAt).format('DD/MM/YYYY')}</div>
            <div className="badge badge-soft-secondary font-size-10">
              {' '}
              {Moment(data.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
        driver: (
          <>
            {accessChecking ?
              <Link
                to={`/user/${data?.user?.id}`}
                className="text-dark font-weight-bold text-truncate"
              >
                <div className="d-flex align-items-center text-truncate">
                  <img
                    src={data?.user?.picture ? data?.user?.picture : avatarImg}
                    alt=""
                    height="20"
                    className="rounded-circle avatar-xs mr-2"
                  />

                  <div>
                    <div className="text-capitalize">
                      {data?.user?.firstName} {data?.user?.lastName}
                    </div>

                    <span className="text-muted ml-2 badge badge-soft-secondary">
                      <i className=" fas fa-star text-warning mr-1" />
                      {data?.user?.rating ? data?.user?.rating?.toFixed(2) : 0}
                    </span>
                  </div>
                </div>
              </Link>
              :
              <div className='text-dark font-weight-bold text-truncate'>
                <div className="d-flex align-items-center text-truncate">
                  <img
                    src={data?.user?.picture ? data?.user?.picture : avatarImg}
                    alt=""
                    height="20"
                    className="rounded-circle avatar-xs mr-2"
                  />

                  <div>
                    <div className="text-capitalize">
                      {data?.user?.firstName} {data?.user?.lastName}
                    </div>

                    <span className="text-muted ml-2 badge badge-soft-secondary">
                      <i className=" fas fa-star text-warning mr-1" />
                      {data?.user?.rating ? data?.user?.rating?.toFixed(2) : 0}
                    </span>
                  </div>
                </div>
              </div>
            }
          </>
        ),
        seats: data.seats,
        status: (
          <h1
            className={`badge badge-soft-${data.status.toLowerCase() === 'approved'
              ? 'success'
              : data.status.toLowerCase() === 'cancelled'
                ? 'danger'
                : data.status.toLowerCase() === 'rejected'
                  ? 'danger'
                  : data.status.toLowerCase() === 'pending'
                    ? 'warning'
                    : data.status.toLowerCase() === 'review'
                      ? 'dark'
                      : ''
              } font-size-12`}
          >
            {data.status ? data.status : '---'}
          </h1>
        ),
        action: (
          <div className='d-flex align-items-center'>
            <CheckAction field="Vehicles" iconType='Edit'>

              <Link
                to={`/vehicle_form?id=${data.id}`}
                className="mr-3 text-primary"
                id="edit1"
              >
                <i className="mdi mdi-pencil font-size-16"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="edit1">
                Edit
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field="Vehicles" iconType='Delete'>
              <Link
                to="#"
                onClick={() => deleteVehicle(data.id)}
                className="mr-3 text-primary"
                id="delete1"
              >
                <i className="mdi mdi-trash-can font-size-16"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="delete1">
                Delete
              </UncontrolledTooltip>
              <Link
                to="#"
                className="mr-3 text-success"
                id="approve"
                style={
                  data.status === 'APPROVED'
                    ? { pointerEvents: 'none', opacity: '0.5' }
                    : null
                }
                onClick={() => handleStatusUpdate(data, 'APPROVED')}
              >
                <i className="fas fa-check font-size-16 text-success rounded-circle mb-2"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="approve">
                Approve
              </UncontrolledTooltip>
              <Link
                to="#"
                className="text-danger"
                id="reject"
                style={
                  data.status === 'REJECTED'
                    ? { pointerEvents: 'none', opacity: '0.5' }
                    : null
                }
                onClick={() => handleStatusUpdate(data, 'REJECTED')}
              >
                <i className="fas fa-times mr-3 font-size-16 text-danger rounded-circle"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="reject">
                Reject
              </UncontrolledTooltip>
              <Link
                to="#"
                className="text-success"
                id="add-package"
                style={
                  data.status === 'REVIEW'
                    ? { pointerEvents: 'none', opacity: '0.5' }
                    : null
                }
                onClick={() => addPackageHandler(data)}
              >
                <i
                  className="fas fa-file-medical
 font-size-16 text-success rounded-circle"
                ></i>
              </Link>
              <UncontrolledTooltip placement="top" target="add-package">
                Add Package
              </UncontrolledTooltip>

            </CheckAction>
          </div>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
          headerStyle: { width: '70px' }
        },
        {
          text: 'ID',
          dataField: 'id',
          headerStyle: { width: '85px' }
        },
        {
          text: 'Vehicle',
          dataField: 'make',
          sort: true,
          headerStyle: { width: '180px' }
        },
        {
          text: 'Packages',
          dataField: 'packages',
        },
        // {
        //   text: 'Capacity',
        //   dataField: 'seats',
        // },
        // {
        //   text: 'Year',
        //   dataField: 'year',
        // },
        {
          text: 'Driver',
          dataField: 'driver',
          headerStyle: { width: '150px' }
        },
        {
          text: 'Inspection',
          dataField: 'inspection',
        },
        // {
        //   text: 'Status',
        //   dataField: 'status',
        // },
        {
          text: 'CreatedAt',
          dataField: 'date',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  const handleRowSelect = (value, isSelectedis) => {
    if (isSelectedis) {
      setSelectedVehicle([...selectedVehicle, value.idKey]);
    } else {
      let clonedData = selectedVehicle.filter((data) => data !== value.idKey);
      console.log('clonedData', clonedData);
      setSelectedVehicle([...clonedData]);
    }
  };

  const handleSelectAll = (isSelectedis, value) => {
    let clonedArr = selectedVehicle;

    if (isSelectedis) {
      value.forEach((data) => {
        if (!clonedArr.includes(data.idKey)) {
          clonedArr.push(data.idKey);
        }
      });
    } else {
      value.forEach((data) => {
        clonedArr = clonedArr.filter((item) => item !== data.idKey);
      });
    }

    setSelectedVehicle([...clonedArr]);
  };

  const selectRow = {
    mode: 'checkbox', // multi select
    clickToSelect: true,
    onSelectAll: handleSelectAll,
    onSelect: handleRowSelect,
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-3">
              {!isLoading && data !== null ? (
                <>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex align-items-center">
                      <Button
                        color="primary"
                        className=" mt-1"
                        size="sm"
                        onClick={() => setAddVehicleModal(!AddVehicleModal)}
                      >
                        Package Update
                        <span>
                          <i
                            className="mdi mdi-alert-circle-outline ml-2 font-size-16"
                            id="packageTooltip"
                          ></i>
                          <UncontrolledTooltip
                            placement="top"
                            target="packageTooltip"
                          >
                            You can add or remove package from vehicle
                          </UncontrolledTooltip>
                        </span>
                      </Button>{' '}
                      {/* <p className="text-muted font-size-12">
                        to{' '}
                        <span className="pl-1 border border-1 rounded bg-success shadow text-white font-weight-bolder">
                          {selectedVehicle.length > 0
                            ? selectedVehicle.length
                            : 'all'}{' '}
                        </span>
                        Vehicles
                      </p> */}
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="">
                        <CSVLink
                          data={csvData}
                          filename={'All_vehicles_data.csv'}
                          className="btn btn-primary btn-sm  mt-1"
                          target="_blank"
                        >
                          <i className=" fas fa-cloud-download-alt mr-1" />
                          CSV
                        </CSVLink>
                      </div>
                      <div>
                        <FilterDropDown
                          menu={menu}
                          toggleMenu={() => setMenu(!menu)}
                        >
                          <div>
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={handleDateFilter}
                            >
                              <SimpleBar
                                style={{
                                  maxHeight: '250px',
                                }}
                              >
                                <Row className="px-3  pt-3 pb-0">
                                  <Col md="12">
                                    <FormGroup>
                                      <AvField
                                        name="startDate"
                                        type="date"
                                        className="form-control bg-light"
                                        id="startDate"
                                        value={date.start}
                                        helpMessage="Start Date"
                                        placeholder="Please choose the start date..."
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="12">
                                    <FormGroup>
                                      <AvField
                                        name="endDate"
                                        type="date"
                                        className="form-control bg-light"
                                        id="endDate"
                                        value={date.end}
                                        helpMessage="End Date"
                                        placeholder="Please choose the end date..."
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  {packages !== null && (
                                    <Col md="12">
                                      <FormGroup>
                                        <AvField
                                          type="select"
                                          value={pkg}
                                          name="packages"
                                          helpMessage="packages"
                                        >
                                          <option value="">All</option>
                                          {packages.result.map((pkg) => (
                                            <option key={pkg.id} value={pkg.id}>
                                              {pkg.name}
                                            </option>
                                          ))}
                                        </AvField>
                                      </FormGroup>
                                    </Col>
                                  )}
                                  <Col md="12">
                                    <FormGroup>
                                      <AvField
                                        type="select"
                                        value={status}
                                        name="vehicleStatus"
                                        helpMessage="status"
                                      >
                                        <option value="">All</option>
                                        <option value="APPROVED">
                                          APPROVED
                                        </option>
                                        <option value="REJECTED">
                                          REJECTED
                                        </option>
                                        <option value="PENDING">PENDING</option>
                                        <option value="CANCELLED">
                                          CANCELLED
                                        </option>
                                        <option value="REVIEW">REVIEW</option>
                                      </AvField>
                                    </FormGroup>
                                  </Col>
                                  <Col md="12">
                                    <FormGroup>
                                      <AvField
                                        name="expiryDate"
                                        type="date"
                                        className="form-control bg-light"
                                        id="expiryDate"
                                        value={''}
                                        helpMessage="Inspection Expiry Date"
                                        placeholder="Please choose the inspection expiry date..."
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </SimpleBar>

                              <div className="p-2 d-flex justify-content-between align-items-center bg-light">
                                <Link
                                  to="#"
                                  onClick={resetHandler}
                                  className="btn btn-sm  bg-light font-size-14"
                                >
                                  <i className=" mdi mdi-refresh mr-1">
                                    {' '}
                                    Reset
                                  </i>
                                </Link>

                                <button
                                  className="btn btn-success btn-sm mr-2"
                                  type="submit"
                                >
                                  <i className="mdi mdi-arrow-right-circle mr-1"></i>
                                  Send
                                </button>
                              </div>
                            </AvForm>
                          </div>
                        </FilterDropDown>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <Input
                        bsSize="sm"
                        id="take"
                        name="take"
                        type="select"
                        onChange={handleLimitChange}
                        value={take}
                      >
                        <option>5</option>
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>
                    <div>
                      <Input
                        bsSize="sm"
                        id="search"
                        value={search}
                        name="search"
                        onChange={SearchHandler}
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField="idKey"
                    data={data.rows}
                    columns={data.columns}
                    selectRow={selectRow}
                    striped
                    hover
                    wrapperClasses="table-responsive"
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={skipPage}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
        <Modal
          isOpen={AddVehicleModal}
          toggle={() => setAddVehicleModal(!AddVehicleModal)}
        >
          <ModalHeader toggle={() => setAddVehicleModal(false)}>
            Add package to vehicles{' '}
          </ModalHeader>
          <ModalBody>
            <AssignVehiclePackage
              selectedVehicle={selectedVehicle}
              setAddVehicleModal={setAddVehicleModal}
            />
          </ModalBody>
        </Modal>
      </Row>
    </React.Fragment>
  );
};

export default VehicleTable;
