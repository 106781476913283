import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import MiniWidgets from './Components/MiniWidgets';
import RewardTable from './rewardTable';
import PreviewReward from './previewReward';
import GreySummaryCard from '../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../components/Common/Summary/actionCheck';

import { fetchReward, deleteReward } from '../../../store/actions';

const Reward = ({
  reward,
  fetchReward,
  deleteReward,
  tableError,
  loading,
  message,
  history,
  accessControl
}) => {
  const [showModal, setShowModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [dateFilter, setDate] = useState({});
  const breadcrumbItems = [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Rewards', link: '#' },
  ];

  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };
  const historyViewer = (e) => {
    setHistoryModal(e);
  };

  const deleteRewardModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteReward(deleteId);
    setDeleteId(null);
  };
  const date = (e) => {
    setDate(e);
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Rewards' breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field='Rewards' subModule='Riders Rewards'>
            <CheckAction
              field='Rewards'
              subModule='Riders Rewards'
              iconType={'Create'}
            >
              <div className='mb-2 text-right'>
                <Link to='/reward_form'>
                  <Button color='success' size='sm'>
                    <i className='mdi mdi-plus-circle-outline font-size-18'></i>{' '}
                    Create Reward
                  </Button>
                </Link>
              </div>
            </CheckAction>
            {message && (
              <Alert color='success' className='text-center'>
                {message}
              </Alert>
            )}
            <Row>
              <Col>
                <Row>
                  <MiniWidgets date={dateFilter} />
                </Row>
                <RewardTable
                  rewardData={reward}
                  dateFilter={date}
                  loading
                  deleteReward={deleteRewardModal}
                  fetchReward={fetchReward}
                  previewAction={previewHandler}
                  history={history}
                  message={message}
                  tableError={tableError}
                  CheckAction={CheckAction}
                  ReadCheck={ReadCheck}
                  accessControl={accessControl}
                />
              </Col>
            </Row>
          </GreySummaryCard>
        </Container>
        <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
          <ModalHeader toggle={() => setShowModal(false)}>
            Preview Reward
          </ModalHeader>
          <ModalBody>
            <PreviewReward
              previewId={previewId}
              historyViewer={historyViewer}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={showDeleteModal}
          toggle={() => setShowDeleteModal(!showDeleteModal)}
        >
          <ModalBody>
            <h4 className='card-title mb-4 text-center'>
              {' '}
              Are you sure you want to delete this Reward
            </h4>
            <button
              onClick={() => deleteHandler()}
              className='btn btn-outline-danger btn-sm w-100'
            >
              Click to Delete
            </button>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { reward, loading, rewardError, message, tableError } = state.Reward;
  return { accessControl, reward, loading, rewardError, message, tableError };
};

export default connect(mapStateToProps, { fetchReward, deleteReward })(Reward);
