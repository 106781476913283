export const FETCH_MY_COMPLAINT = 'FETCH_MY_COMPLAINT';
export const FETCH_COMPLAINT = 'FETCH_COMPLAINT';
export const FETCH_COMPLAINT_SUCCESSFUL = 'FETCH_COMPLAINT_SUCCESSFUL';
export const FETCH_COMPLAINT_API_FAILED = 'FETCH_COMPLAINT_API_FAILED';

// COMPLAINT Summary
export const FETCH_COMPLAINT_SUMMARY = 'FETCH_COMPLAINT_SUMMARY';
export const FETCH_COMPLAINT_SUMMARY_FAILED = 'FETCH_COMPLAINT_SUMMARY_FAILED';
export const FETCH_COMPLAINT_SUMMARY_SUCCESSFULLY =
  'FETCH_COMPLAINT_SUMMARY_SUCCESSFULLY';

export const ASSIGN_COMPLAINT = 'ASSIGN_COMPLAINT';
export const ASSIGN_COMPLAINT_SUCCESSFUL = 'ASSIGN_COMPLAINT_SUCCESSFUL';
export const ASSIGN_COMPLAINT_API_FAILED = 'ASSIGN_COMPLAINT_API_FAILED';

export const FETCH_EACH_COMPLAINT = 'FETCH_EACH_COMPLAINT';
export const FETCH_EACH_COMPLAINT_SUCCESSFUL =
  'FETCH_EACH_COMPLAINT_SUCCESSFUL';
export const FETCH_EACH_COMPLAINT_API_FAILED =
  'FETCH_EACH_COMPLAINT_API_FAILED';

export const DELETE_COMPLAINT = 'DELETE_COMPLAINT';
export const DELETE_COMPLAINT_SUCCESSFUL = 'DELETE_COMPLAINT_SUCCESSFUL';
export const DELETE_COMPLAINT_FAILED = 'DELETE_COMPLAINT_FAILED';

export const CREATE_COMPLAINT = 'CREATE_COMPLAINT';
export const CREATE_COMPLAINT_SUCCESSFUL = 'CREATE_COMPLAINT_SUCCESSFUL';
export const CREATE_COMPLAINT_FAILED = 'CREATE_COMPLAINT_FAILED';

export const UPDATE_COMPLAINT = 'UPDATE_COMPLAINT';
export const UPDATE_COMPLAINT_SUCCESSFUL = 'UPDATE_COMPLAINT_SUCCESSFUL';
export const UPDATE_COMPLAINT_FAILED = 'UPDATE_COMPLAINT_FAILED';

export const FETCH_FOR_COMPLAINT_CSV = 'FETCH_FOR_COMPLAINT_CSV';
export const FETCH_FOR_MY_COMPLAINT_CSV = 'FETCH_FOR_MY_COMPLAINT_CSV';
export const FETCH_FOR_COMPLAINT_CSV_ERROR = 'FETCH_FOR_COMPLAINT_CSV_ERROR';
export const FETCH_FOR_COMPLAINT_CSV_SUCCESSFULLY =
  'FETCH_FOR_COMPLAINT_CSV_SUCCESSFULLY';
