import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  CHECK_LOGIN,
  MY_ACCESS_CONTROL,
  LOGOUT_USER,
  LOAD_USER,
  REGISTER_USER,
  UPDATE_ACCOUNT_WALLET,
  ACTIVATE_USER,
  EMAIL_SUBSCRIPTION,
  FORGET_USER,
  CREATE_NEW_PASSWORD,
  UPDATE_PROFILE,
  GOOGLE_LOGIN,
  GENERATE_OTP,
  RE_ACTIVATE_USER,
  PASSWORD_SETTING,
  USER_LOCATION,
} from './actionTypes';
import {
  apiError,
  authError,
  apiActivationError,
  generateOTPSuccessful,
  generateOTPFailed,
  loginUserSuccessful,
  logoutUserSuccess,
  updateAccountWalletSuccessfully,
  updateAccountWalletFailed,
  loadUserSuccessful,
  registerUserSuccessful,
  registerUserFailed,
  emailSubscriptionSuccess,
  emailSubscriptionFailed,
  activateAccountSuccess,
  activateAccountFailed,
  forgetUserSuccessful,
  userForgetPasswordError,
  createNewPasswordError,
  createNewPasswordSuccessful,
  updateProfileSuccessful,
  updateProfileError,
  passwordSettingError,
  passwordSettingSuccessful,
  resendActivateAccountSuccess,
  resendActivateAccountFailed,
  userLocationSuccessfully,
  userLocationError,
  myAccessControlSuccessfully,
  myAccessControlFailed,
} from './actions';

import {
  LoginService,
  loadUserServer,
  RegisterService,
  ActivateServices,
  ReActivateServices,
  updateAccountWalletService,
  EmailSubscriptionServices,
  ForgetPasswordServices,
  createNewPasswordServices,
  updateProfileServices,
  passwordSettingServices,
  myAccessControlService,
  OTPService,
  getUserLocationService,
} from '../../services/authServices';

function* loadUserHandler() {
  try {
    const response = yield call(loadUserServer);
    localStorage.setItem('authUser', JSON.stringify(response.data.result));
    yield call(myAccessControlHandler);
    yield put(loadUserSuccessful(response.data.result));
  } catch (error) {
    console.log(error);
    yield put(authError(error?.response?.data?.message));
  }
}
function* generateOTPHandler({ payload }) {
  try {
    const response = yield call(OTPService, payload);
    yield put(generateOTPSuccessful(response.data));
  } catch (error) {
    yield put(generateOTPFailed(error?.response?.data?.message));
  }
}
//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(LoginService, {
      email: user.username,
      password: user.password,
    });
    localStorage.setItem('authTokens', JSON.stringify(response.data.result));
    document.cookie = JSON.stringify(response.data.result.accessToken);
    yield put(loginUserSuccessful(response.data.result));
    yield call(loadUserHandler);
    history.push('/dashboard');
  } catch (error) {
    yield put(apiError(error?.response?.data?.message));
  }
}

function* logoutUser(errorFound) {
  try {
    localStorage.removeItem('authTokens');
    localStorage.removeItem('authUser');
    yield put(logoutUserSuccess());
  } catch (error) {
    if (errorFound) {
      yield put(apiError(errorFound));
      return;
    }
    yield put(apiError(error));
  }
}

function* registerUser({ payload: { userInfo } }) {
  try {
    const response = yield call(RegisterService, userInfo);

    yield put(registerUserSuccessful(response));
  } catch (error) {
    yield put(registerUserFailed(error.response.data?.message));
  }
}

function* activateUser({ payload: { id, history } }) {
  try {
    const response = yield call(ActivateServices, id);
    yield put(activateAccountSuccess('Account is now active please login!'));
  } catch (error) {
    console.log(error);
    yield put(activateAccountFailed(error.response.data?.message));
  }
}

function* reActivateUser({ payload: { email } }) {
  try {
    const response = yield call(ReActivateServices, email);
    yield put(
      resendActivateAccountSuccess('Check your mail for activation code!')
    );
  } catch (error) {
    yield put(resendActivateAccountFailed(error.response.data?.message));
  }
}

function* emailSubscription({ payload }) {
  try {
    const response = yield call(EmailSubscriptionServices, payload);
    yield put(
      emailSubscriptionSuccess('Email unsubscription is now active please!')
    );
  } catch (error) {
    yield put(emailSubscriptionFailed(error.response.data?.message));
  }
}

function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(ForgetPasswordServices, {
      email: user.useremail,
    });
    if (response) {
      yield put(
        forgetUserSuccessful(
          'Reset link is sent to your mailbox, check there first'
        )
      );
    }
  } catch (error) {
    yield put(userForgetPasswordError(error?.response?.data?.message));
  }
}

// create new password
function* CreateNewPassword({ payload: { user, history } }) {
  try {
    const response = yield call(createNewPasswordServices, user);
    if (response) {
      yield put(
        createNewPasswordSuccessful(
          'Password successfully change, Please login with your new credentials'
        )
      );
    }
  } catch (error) {
    yield put(createNewPasswordError(error?.response?.data?.error?.error));
  }
}

// create new password
function* passwordSetting({ payload }) {
  try {
    const response = yield call(passwordSettingServices, payload);
    if (response) {
      yield put(passwordSettingSuccessful('Password successfully change'));
    }
  } catch (error) {
    yield put(passwordSettingError(error?.response?.data?.error?.error));
  }
}

// create new password
function* UpdateProfileHandler({ payload }) {
  try {
    const response = yield call(updateProfileServices, payload);
    yield put(updateProfileSuccessful(response.data.result));
  } catch (error) {
    yield put(updateProfileError(error?.response?.data?.message));
  }
}

function* fetchGoogleHandler({ payload: { token, history } }) {
  try {
    console.log(token);
    // const response = yield call(googleLoginService);
    localStorage.setItem('authTokens', JSON.stringify(token));
    // yield put(loginUserSuccessful(response.data.token));
    yield call(loadUserHandler);
    history.push('/dashboard');
  } catch (error) {
    yield put(apiError(error?.response?.data?.error?.error));
  }
}

// UPDATE WALLET
function* updateAccountWalletHandler({ payload }) {
  try {
    const response = yield call(updateAccountWalletService, payload);
    yield put(updateAccountWalletSuccessfully(response.data));
  } catch (error) {
    yield put(updateAccountWalletFailed(error?.response?.data?.message));
  }
}

function* userLocationHandler({ payload }) {
  try {
    const response = yield call(getUserLocationService, payload);
    yield put(userLocationSuccessfully(response.data));
  } catch (error) {
    yield put(userLocationError(error?.response?.data?.message));
  }
}

function* myAccessControlHandler() {
  try {
    const response = yield call(myAccessControlService);
    yield put(myAccessControlSuccessfully(response.data));
    console.log('response');
    console.log(response);
  } catch (error) {
    // yield put(myAccessControlFailed());
    let accessError = error?.response?.data?.message;
    yield call(logoutUser, accessError);
  }
}

export function* watchUpdateAccountWallet() {
  yield takeEvery(UPDATE_ACCOUNT_WALLET, updateAccountWalletHandler);
}

export function* watchUserLogin() {
  yield takeEvery(CHECK_LOGIN, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchLoadUser() {
  yield takeEvery(LOAD_USER, loadUserHandler);
}

export function* watchGenerateOTP() {
  yield takeEvery(GENERATE_OTP, generateOTPHandler);
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

export function* watchAccountActivation() {
  yield takeEvery(ACTIVATE_USER, activateUser);
}
export function* watchAccountReActivation() {
  yield takeEvery(RE_ACTIVATE_USER, reActivateUser);
}

export function* watchEmailSubscriptionActivation() {
  yield takeEvery(EMAIL_SUBSCRIPTION, emailSubscription);
}

export function* watchUpdateProfile() {
  yield takeEvery(UPDATE_PROFILE, UpdateProfileHandler);
}
export function* watchUserForget() {
  yield takeEvery(FORGET_USER, forgetUser);
}

export function* watchCreateNewForget() {
  yield takeEvery(CREATE_NEW_PASSWORD, CreateNewPassword);
}

export function* watchGoogleLogin() {
  yield takeEvery(GOOGLE_LOGIN, fetchGoogleHandler);
}
export function* watchPasswordSetting() {
  yield takeEvery(PASSWORD_SETTING, passwordSetting);
}

export function* watchUserLocation() {
  yield takeEvery(USER_LOCATION, userLocationHandler);
}

export function* watchMyAccessControl() {
  yield takeEvery(MY_ACCESS_CONTROL, myAccessControlHandler);
}

function* loginSaga() {
  yield all([
    fork(watchUpdateAccountWallet),
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchUpdateProfile),
    fork(watchLoadUser),
    fork(watchUserRegister),
    fork(watchAccountActivation),
    fork(watchUserForget),
    fork(watchCreateNewForget),
    fork(watchGoogleLogin),
    fork(watchPasswordSetting),
    fork(watchAccountReActivation),
    fork(watchEmailSubscriptionActivation),
    fork(watchGenerateOTP),
    fork(watchUserLocation),
    fork(watchMyAccessControl),
  ]);
}

export default loginSaga;
