import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import Form from './form';

import { fetchEachLoyaltyTier } from '../../../../../store/actions';

const LoyaltyTierForm = ({
  fetchEachLoyaltyTier,
  loyaltyTierPreview,
  loading,
  message,
}) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  useEffect(() => {
    if (id) {
      fetchEachLoyaltyTier(id);
    }
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        {loyaltyTierPreview ? (
          <Form
            loading={loading}
            defaultValue={loyaltyTierPreview}
            message={message}
          />
        ) : (
          <Form loading={loading} message={message} defaultValue={null} />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { loyaltyTierPreview, loading, loyaltyTierError, message } =
    state.LoyaltyTier;
  return { loyaltyTierPreview, loading, loyaltyTierError, message };
};

export default connect(mapStateToProps, { fetchEachLoyaltyTier })(
  LoyaltyTierForm
);
