import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Col, Card, CardBody, Media } from 'reactstrap';
import { connect } from 'react-redux';
import { fetchDashStat } from '../../../store/actions';

const MiniWidgets = ({
  dashStat,
  fetchDashStat,
  start,
  end,
  loading,
  user,
}) => {
  useEffect(() => {
    if (user.userType === 'ADMIN') {
      fetchDashStat({ data: 'user', start, end });
    }
  }, [end, fetchDashStat, start]);
  const reports = [
    {
      icon: 'ri-team-line',
      title: 'Users',
      total: `${dashStat?.allUsers ? dashStat?.allUsers : '0'}`,
      value: `${dashStat?.registeredUsers ? dashStat?.registeredUsers : '0'}`,
      active: `${dashStat?.activeUsers ? dashStat?.activeUsers : '0'}`,
      rate: `${
        dashStat?.previousRegisteredUsers
          ? dashStat?.previousRegisteredUsers
          : '0'
      }%`,
      desc: 'Previous Registered Users',
    },

    {
      icon: 'ri-user-line',
      title: 'Captains',
      value: `${
        dashStat?.registeredDrivers ? dashStat?.registeredDrivers : '0'
      }`,
      total: `${dashStat?.allDrivers ? dashStat?.allDrivers : '0'}`,
      active: `${dashStat?.activeDrivers ? dashStat?.activeDrivers : '0'}`,
      rate: `${
        dashStat?.previousRegisteredDrivers
          ? dashStat?.previousRegisteredDrivers
          : '0'
      }%`,
      desc: 'Previous Registered Captains',
    },

    {
      icon: 'ri-group-line',
      title: 'Riders',
      value: `${dashStat?.registeredRiders ? dashStat?.registeredRiders : '0'}`,
      total: `${dashStat?.allRiders ? dashStat?.allRiders : '0'}`,
      active: `${dashStat?.activeRiders ? dashStat?.activeRiders : '0'}`,
      rate: `${
        dashStat?.previousRegisteredRiders
          ? dashStat?.previousRegisteredRiders
          : '0'
      }%`,
      desc: 'Previous Registered Riders',
    },
    {
      icon: 'ri-user-2-line',
      title: 'Admins',
      value: `${dashStat?.registeredAdmin ? dashStat?.registeredAdmin : '0'}`,
      total: `${dashStat?.allAdmin ? dashStat?.allAdmin : '0'}`,
      active: `${dashStat?.activeAdmin ? dashStat?.activeAdmin : '0'}`,
      rate: `${
        dashStat?.previousRegisteredAdmin
          ? dashStat?.previousRegisteredAdmin
          : '0'
      }%`,
      desc: 'Previous Registered Admin',
    },
    {
      icon: 'ri-car-line',
      title: 'Vehicles',
      value: `${
        dashStat?.registeredVehicles ? dashStat?.registeredVehicles : '0'
      }`,
      total: `${dashStat?.allVehicles ? dashStat?.allVehicles : '0'}`,
      active: `${dashStat?.activeVehicles ? dashStat?.activeVehicles : '0'}`,
      rate: `${
        dashStat?.previousRegisteredVehicles
          ? dashStat?.previousRegisteredVehicles
          : '0'
      }%`,
      desc: 'Previous Registered Vehicles',
    },
  ];
  return (
    <React.Fragment>
      {reports.map((report, key) => (
        <Col
          key={key}
          // className={`${key !== 0 || key !== 4 ? 'pr-1' : ''} px-0`}
          style={{ flex: '0 0 20%', maxWidth: '20%' }}
        >
          <Card>
            <CardBody className='pb-0'>
              <Media>
                <Media body className='overflow-hidden'>
                  <div className='text-truncate font-size-14 mb-2 d-flex justify-content-between align-items-center'>
                    <p>
                      {report.title}{' '}
                      <span className='font-size-12 text-secondary font-weight-light'>
                        (Active)
                      </span>
                    </p>

                    <span className='text-primary'>
                      <i className={report.icon + ' font-size-24'}></i>
                    </span>
                  </div>
                  <h4 className='mt-2'>
                    {loading ? (
                      <Skeleton duration={2} width='20%' />
                    ) : (
                      <>
                        {report.total}
                        <span className='font-size-12 ml-1 text-secondary font-weight-light'>
                          ({report.active})
                        </span>
                      </>
                    )}
                  </h4>
                </Media>
              </Media>
            </CardBody>
            <CardBody className='border-top py-1'>
              <div
                className='text-truncate font-size-12'
                style={{ whiteSpace: 'normal' }}
              >
                <span className='badge badge-soft-primary font-size-11 mr-1'>
                  <i className='mdi mdi-menu-up'> </i>
                  {report.value}
                </span>
                <span className='text-muted'>{report.desc.slice(9)}</span>
              </div>
            </CardBody>
            <CardBody className='border-top py-1'>
              <div
                className='text-truncate font-size-12'
                style={{ whiteSpace: 'normal' }}
              >
                <span className='badge badge-soft-success font-size-11 mr-1'>
                  <i className='mdi mdi-menu-up'> </i>
                  {parseInt(report.rate) === 0 || parseInt(report.value) === 0
                    ? 0
                    : (
                        (parseInt(report.value) / parseInt(report.rate)) *
                        100
                      ).toFixed(1)}
                  %
                </span>
                <span className='text-muted ml-2 '>{report.desc}</span>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { dashStat, loading, dashStatError } = state.Dashboard;
  return { dashStat, loading, dashStatError };
};

export default connect(mapStateToProps, { fetchDashStat })(MiniWidgets);
