/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import Moment from 'moment';
import { Alert, Row, Col, Table } from 'reactstrap';
import Loader from '../../../../components/Common/Loading/index.js';
import { fetchEachDocument } from '../../../../store/actions';
import { Document, Page, pdfjs } from 'react-pdf';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const DocPreview = ({
  documentPreview,
  previewId,
  loading,
  documentError,
  fetchEachDocument,
  docIsImg,
  docIsPdf,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [rotation, setRotation] = useState(0);

  const RotateImageHandler = () => {
    if (rotation === 180) {
      setRotation(0);
      return;
    }
    setRotation(180);
  };

  useEffect(() => {
    fetchEachDocument(previewId);
  }, [fetchEachDocument, previewId]);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <React.Fragment>
      <div className="mt-3">
        {documentError && !loading && (
          <Alert color="danger" className="text-center">
            {documentError}
          </Alert>
        )}
        {documentPreview && loading ? (
          <Loader />
        ) : (
          <Row>
            <Col sm={12}>
              <center>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="mb-2">{documentPreview?.description}</div>
                  {!documentPreview?.isOptional ? (
                    <h1
                      className={`badge badge-soft-success font-size-10 ml-3`}
                    >
                      Required
                    </h1>
                  ) : null}
                </div>
                <div className="d-flex justify-content-center mb-1">
                  <div className="mr-3 d-flex">
                    <span className="mr-2">Key:</span>
                    <h6>{documentPreview?.key}</h6>
                  </div>
                  <div className="mr-3 d-flex">
                    <span className="mr-2">Expiry Date:</span>
                    <h6>
                      {documentPreview?.expiry
                        ? Moment(documentPreview.expiry).format('YYYY-MM-DD')
                        : '---'}
                    </h6>
                  </div>
                </div>
                {documentPreview?.comment && (
                  <div className="mb-3">
                    <h6 className="font-size-12 font-weight-bold">
                      Driver Comment{' '}
                    </h6>
                    <div>{documentPreview?.comment}</div>
                  </div>
                )}
                {documentPreview?.status === 'REJECTED' &&
                documentPreview?.data ? (
                  <div className="mb-3">
                    <h6 className="font-size-12 font-weight-bold">
                      Rejection Reason{' '}
                    </h6>
                    <div>{documentPreview?.data}</div>
                  </div>
                ) : null}
                {docIsImg ? (
                  <>
                    <button
                      className="btn btn-success btn-sm mb-1"
                      onClick={RotateImageHandler}
                    >
                      <i className="ri-clockwise-line font-weight-bold mr-1" />
                      Rotate{' '}
                    </button>{' '}
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          style={{ transform: `rotate(${rotation}deg)` }}
                          data-dz-thumbnail=""
                          height={600}
                          className="rounded bg-light w-100"
                          alt="name"
                          src={
                            documentPreview?.documentUrl
                              ? documentPreview?.documentUrl
                              : documentPreview?.documentBackUrl
                          }
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </>
                ) : docIsPdf ? (
                  <Document
                    file={
                      documentPreview?.documentUrl
                        ? documentPreview?.documentUrl
                        : documentPreview?.documentBackUrl
                    }
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={console.error}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        height={800}
                        key={`pages_${index + 1}`}
                        pageNumber={index + 1}
                      />
                    ))}
                  </Document>
                ) : (
                  <i>Document not uploaded yet</i>
                )}
              </center>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { documentPreview, loading, documentError } = state.Document;
  return { documentPreview, loading, documentError };
};

export default connect(mapStateToProps, { fetchEachDocument })(DocPreview);
