import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  FormGroup,
  Col,
  Input,
  Alert,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Label,
} from 'reactstrap';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import { CSVLink } from 'react-csv';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactPaginate from 'react-paginate';
import Moment from 'moment';
import Loader from '../../../components/Common/Loading/index.js';
import SimpleBar from 'simplebar-react';
import FilterDropDown from '../../../components/Common/FilterDropDown';
import { Link } from 'react-router-dom';
import { ScrollToTop } from '../../../utils/general';

let skip = 0;
let dataLimit = 10;

const CashoutTable = ({
  cashoutData,
  previewAction,
  fetchCashout,
  user,
  statusUpdateHandler,
  statusIdHandler,
  message,
  tableError,
  handleDateFilter,
  resetHandler,
  date,
  type,
  toggle,
  status,
  fetchCashoutCSVError,
  fetchCashoutCSV,
  csvData,
  csv_loading,
}) => {
  const [data, setData] = useState(null);
  const [csv, setCsvData] = useState([]);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [menu, setMenu] = useState(toggle);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(cashoutData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      status,
      search,
      start: date.start,
      end: date.end,
      user: user.id,
    };
    fetchCashout(pageInfo);
  };
  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = {
      skip,
      take,
      status,
      search,
      start: date.start,
      end: date.end,
      user: user.id,
    };
    fetchCashout(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      user: user.id,
      status,
      start: date.start,
      end: date.end,
    };
    fetchCashout(queryParams);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          status,
          start: date.start,
          end: date.end,
          user: user.id,
        };
        fetchCashout(queryParams);
      }, 2000);
    }
  }, [message]);

  const handleCSV = (e, values) => {
    fetchCashoutCSV({
      take: values.endRow
        ? values.endRow - values.startRow + 1
        : cashoutData?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
      user: user.id,
      start: date.start,
      end: date.end,
    });
  };

  useEffect(() => {
    if (toggle) {
      setMenu(toggle);
    }
    if (cashoutData) {
      setData(tableDataHandler());
      setIsLoading(false);
      setPageCount(Math.ceil(cashoutData?.total / take));
    }
  }, [toggle, cashoutData]);

  useEffect(() => {
    if (csvData) {
      // SET CSV EXPORT DATA
      const csv = csvData.result.map((data) => {
        let id = data.id.substring(0, 7);
        let formData = {
          id: id,
          userName: data.user?.firstName + ' ' + data.user?.lastName,
          UserType: data?.user?.userType,
          fee: data.fee,
          amount: data.amount,
          type: data.type,
          Bank: data?.bank.bankName,
          AccountNumber: `A-${data?.bank?.accountNumber}`,
          Status: data?.status,
          createdAt: data?.createdAt?.substring(0, 10),
        };

        return formData;
      });

      setCsvData(csv);
    }
  }, [csvData]);
  useEffect(() => {
    if (!showCSVModal) {
      fetchCashoutCSVError();
    }
  }, [showCSVModal]);

  useEffect(() => {
    let queryParams = {
      search,
      take,
      skip,
      status,
      start: date.start,
      end: date.end,
      user: user?.id,
    };
    if (user?.userType === 'DRIVER') {
      fetchCashout(queryParams);
    }
  }, [fetchCashout]);

  const tableDataHandler = () => {
    const rowData = cashoutData?.result?.map((data) => {
      return {
        keyId: data.id,
        amount: (
          <Link
            to='#'
            onClick={() => previewAction(data.id)}
            className='text-dark font-weight-bold'
          >
            <div>
              <p className='mb-0'>{data.amount}</p>
              <span className='badge badge-soft-success rounded'>
                {data.fee}
              </span>
            </div>
            <div
              className={`badge badge-soft-${
                data.type.toLowerCase() === 'instant'
                  ? 'success'
                  : data.type.toLowerCase() === 'scheduled'
                  ? 'warning'
                  : data.type.toLowerCase() === 'bonus'
                  ? 'primary'
                  : 'dark'
              } font-size-12 mt-2`}
            >
              {data.type}
            </div>
          </Link>
        ),

        date: (
          <Link to={`/user/${data.user.id}`} className='mr-3 text-dark'>
            <div>
              {' '}
              <div>{Moment(data.createdAt).format('D/M/YYYY')}</div>
              <div className='badge badge-soft-secondary font-size-10'>
                {' '}
                {Moment(data.createdAt).format('hh:mm A')}
              </div>
            </div>
          </Link>
        ),

        accountNumber: (
          <>
            <div>{data?.bank?.accountNumber}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {data?.bank.bankName}
            </div>
            <h1
              className={`badge badge-soft-${
                data.status.toLowerCase() === 'completed'
                  ? 'success'
                  : data.status.toLowerCase() === 'pending'
                  ? 'secondary'
                  : data.status.toLowerCase() === 'processing'
                  ? 'warning'
                  : data.status.toLowerCase() === 'cancelled'
                  ? 'danger'
                  : 'dark'
              } font-size-12 mt-3`}
            >
              {data.status ? data.status : '---'}
            </h1>
          </>
        ),

        action: (
          <>
            <Link
              to='#'
              className='mr-3 text-success'
              id='confirm'
              onClick={() => statusUpdateHandler(data.id)}
              style={
                data.status === 'CANCELLED' ||
                data.status === 'COMPLETED' ||
                data.status === 'PROCESSING'
                  ? { pointerEvents: 'none', opacity: '0.5' }
                  : null
              }
            >
              <i className='fas fa-check font-size-12'></i>
            </Link>
            <UncontrolledTooltip placement='top' target='confirm'>
              Confirm
            </UncontrolledTooltip>
            <Link
              to='#'
              className='text-danger'
              id='cancel'
              onClick={() => statusIdHandler(data.id)}
              style={
                data.status === 'CANCELLED' || data.status === 'COMPLETED'
                  ? { pointerEvents: 'none', opacity: '0.5' }
                  : null
              }
            >
              <i className='fas fa-times pr-2 font-size-16 '></i>
            </Link>
            <UncontrolledTooltip placement='top' target='cancel'>
              Cancel
            </UncontrolledTooltip>
          </>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'Account Number',
          dataField: 'accountNumber',
          headerAlign: 'center',
          headerStyle: { width: '130px' },
        },
        {
          text: 'Amount/Fees',
          dataField: 'amount',
          classes: 'text-center',
          headerStyle: { width: '130px' },
        },
        {
          text: 'Date',
          dataField: 'date',
          headerAlign: 'center',
          classes: 'text-center',
        },
        {
          text: 'Action',
          dataField: 'action',
          headerAlign: 'center',
          // headerStyle: { width: '100px' },
          classes: 'text-center',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody
              className={`pt-0`}
              style={{
                height: `${user?.userType !== 'DRIVER' ? '300px' : ''}`,
              }}
            >
              {/* <div className='d-flex justify-content-end align-items-center mb-2'>
                <div>
                  <Button
                    size='sm'
                    color='primary'
                    className='mt-1'
                    onClick={() => setShowCSVModal(true)}
                    disabled={cashoutData?.total > 0 ? false : true}
                  >
                    <i className=' fas fa-cloud-download-alt mr-1' />
                    CSV
                  </Button>
                </div>
                <div>
                  <FilterDropDown menu={menu} toggleMenu={() => setMenu(!menu)}>
                    <div>
                      <AvForm
                        className='form-horizontal'
                        onValidSubmit={handleDateFilter}
                      >
                        <SimpleBar
                          style={{
                            maxHeight: '250px',
                          }}
                        >
                          <Row className='px-3  pt-3 pb-0'>
                            <Col md='12'>
                              <FormGroup>
                                <AvField
                                  name='startDate'
                                  type='date'
                                  className='form-control bg-light'
                                  id='startDate'
                                  value={date.start}
                                  helpMessage='Start Date'
                                  placeholder='Please choose the start date...'
                                />
                              </FormGroup>
                            </Col>
                            <Col md='12'>
                              <FormGroup>
                                <AvField
                                  name='endDate'
                                  type='date'
                                  className='form-control bg-light'
                                  id='endDate'
                                  value={date.end}
                                  helpMessage='End Date'
                                  placeholder='Please choose the end date...'
                                />
                              </FormGroup>
                            </Col>
                            <Col md='12'>
                              <FormGroup>
                                <AvField
                                  type='select'
                                  value={status}
                                  helpMessage='Status'
                                  name='Status'
                                >
                                  <option value=''>All</option>
                                  <option value='COMPLETED'>COMPLETED</option>
                                  <option value='PROCESSING'>PROCESSING</option>
                                  <option value='REJECTED'>REJECTED</option>
                                  <option value='CANCELLED'>CANCELLED</option>
                                  <option value='PENDING'>PENDING</option>
                                </AvField>
                              </FormGroup>
                            </Col>
                          </Row>

                          <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                            <Link
                              to='#'
                              onClick={resetHandler}
                              className='btn btn-sm  bg-light font-size-14'
                            >
                              <i className=' mdi mdi-refresh mr-1'> Reset</i>
                            </Link>

                            <button
                              className='btn btn-success btn-sm mr-2'
                              type='submit'
                            >
                              <i className='mdi mdi-arrow-right-circle mr-1'></i>
                              Send
                            </button>
                          </div>
                        </SimpleBar>
                      </AvForm>
                    </div>
                  </FilterDropDown>
                </div>
              </div> */}
              {message && (
                <Alert color='success' className='text-center'>
                  {message}
                </Alert>
              )}
              {tableError && (
                <Alert color='danger' className='text-center'>
                  {tableError}
                </Alert>
              )}

              <div className='d-flex justify-content-between mb-2 align-items-center'>
                {user?.userType === 'DRIVER' && (
                  <div>
                    <Input
                      bsSize='sm'
                      id='take'
                      name='take'
                      type='select'
                      onChange={handleLimitChange}
                    >
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                    </Input>
                  </div>
                )}
                <div className='d-flex justify-content-end align-items-center'>
                  {user?.userType === 'DRIVER' && (
                    <div>
                      <Button
                        size='sm'
                        color='primary'
                        className='mt-1'
                        onClick={() => setShowCSVModal(true)}
                        disabled={cashoutData?.total > 0 ? false : true}
                      >
                        <i className=' fas fa-cloud-download-alt mr-1' />
                        CSV
                      </Button>
                    </div>
                  )}
                  <div>
                    <FilterDropDown
                      menu={menu}
                      toggleMenu={() => setMenu(!menu)}
                    >
                      <div>
                        <AvForm
                          className='form-horizontal'
                          onValidSubmit={handleDateFilter}
                        >
                          <SimpleBar
                            style={{
                              maxHeight: '360px',
                            }}
                          >
                            <Row className='px-3  pt-3 pb-0'>
                              <Col md='12'>
                                <FormGroup>
                                  <AvField
                                    name='startDate'
                                    type='date'
                                    className='form-control bg-light'
                                    id='startDate'
                                    value={date.start}
                                    helpMessage='Start Date'
                                    placeholder='Please choose the start date...'
                                  />
                                </FormGroup>
                              </Col>
                              <Col md='12'>
                                <FormGroup>
                                  <AvField
                                    name='endDate'
                                    type='date'
                                    className='form-control bg-light'
                                    id='endDate'
                                    value={date.end}
                                    helpMessage='End Date'
                                    placeholder='Please choose the end date...'
                                  />
                                </FormGroup>
                              </Col>
                              {user?.userType === 'DRIVER' && (
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      type='select'
                                      value={status}
                                      helpMessage='Status'
                                      name='Status'
                                    >
                                      <option value=''>All</option>
                                      <option value='COMPLETED'>
                                        COMPLETED
                                      </option>
                                      <option value='PROCESSING'>
                                        PROCESSING
                                      </option>
                                      <option value='REJECTED'>REJECTED</option>
                                      <option value='CANCELLED'>
                                        CANCELLED
                                      </option>
                                      <option value='PENDING'>PENDING</option>
                                    </AvField>
                                  </FormGroup>
                                </Col>
                              )}
                              <Col md='12'>
                                <FormGroup>
                                  <AvField
                                    type='select'
                                    value={type}
                                    helpMessage='Type'
                                    name='Type'
                                  >
                                    <option value=''>All</option>
                                    <option value='CREDIT'>Credit</option>
                                    <option value='DEBIT'>Debit</option>
                                  </AvField>
                                </FormGroup>
                              </Col>
                            </Row>

                            <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                              <Link
                                to='#'
                                onClick={resetHandler}
                                className='btn btn-sm  bg-light font-size-14'
                              >
                                <i className=' mdi mdi-refresh mr-1'> Reset</i>
                              </Link>

                              <button
                                className='btn btn-success btn-sm mr-2'
                                type='submit'
                              >
                                <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                Send
                              </button>
                            </div>
                          </SimpleBar>
                        </AvForm>
                      </div>
                    </FilterDropDown>
                  </div>
                </div>
                {/* <div>
                      <Input
                        bsSize='sm'
                        id='search'
                        name='search'
                        onChange={SearchHandler}
                        placeholder='Search'
                      />
                    </div> */}
              </div>
              {user?.userType === 'DRIVER' && (
                <>
                  {!isLoading && data !== null ? (
                    <>
                      <BootstrapTable
                        keyField='keyId'
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses='table-responsive'
                        noDataIndication={'No Data to Display'}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* CSV DOWNLOAD MODAL */}
      <Modal
        isOpen={showCSVModal}
        toggle={() => setShowCSVModal(!showCSVModal)}
      >
        <ModalHeader toggle={() => setShowCSVModal(false)}>
          Generate CSV
        </ModalHeader>
        <ModalBody>
          <AvForm className='form-horizontal' onValidSubmit={handleCSV}>
            <Col>
              <FormGroup>
                <Label htmlFor='startRow'>Start (Row)</Label>
                <AvField
                  name='startRow'
                  type='number'
                  max={cashoutData?.total - 1}
                  className='form-control'
                  id='startRow'
                  placeholder='Enter the row to start from'
                  errorMessage='value must be greater than 0 and less than total data'
                  min='1'
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor='endRow'>End (Row)</Label>
                <AvField
                  name='endRow'
                  type='number'
                  className='form-control'
                  min='1'
                  max={cashoutData?.total}
                  id='endRow'
                  placeholder='Enter the row to end'
                  helpMessage={
                    cashoutData?.total
                      ? `Total data is ${cashoutData?.total}`
                      : null
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Button
              size='sm'
              color='primary'
              className='ml-3 waves-effect waves-light '
              type='submit'
            >
              {csv_loading ? 'Generating...' : 'Generate'}
            </Button>
            {csvData && (
              <CSVLink
                data={csv}
                filename={'driver_cashout_detail.csv'}
                className='btn-sm btn-success ml-2'
                target='_blank'
                onClick={() => setShowCSVModal(false)}
              >
                Download
              </CSVLink>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CashoutTable;
