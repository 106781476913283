import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  FormGroup,
  Label,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import MiniWidgets from '../Components/MiniWidget';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import LoyaltyTable from './ridersLoyaltyTable';
import GreySummaryCard from '../../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../../components/Common/Summary/actionCheck';
import {
  fetchAllUser,
  updateUserPoint,
  updateUserPointFailed,
} from '../../../../store/actions';

const Loyalty = ({
  users,
  userError,
  tableError,
  fetchAllUser,
  history,
  updateUserPoint,
  updateUserPointFailed,
  loyaltyError,
  pointError,
  loading,
  message,
  isAuthorize,
  accessControl
}) => {
  const [showModal, setShowModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [showPointModal, setShowPointModal] = useState(false);
  const [action, setAction] = useState(null);

  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'Loyalty', link: '/riders_loyalty' },
  ];

  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };
  const pointHandler = (id, e) => {
    setShowPointModal(true);
    setPreviewId(id);
    setAction(e);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        updateUserPointFailed('');

        setShowPointModal(false);
      }, 2000);
    }
  }, [message, updateUserPointFailed]);

  useEffect(() => {
    updateUserPointFailed('');
  }, []);

  const handlePointUpdate = (event, values) => {
    const loyaltyData = {
      ...values,
    };
    loyaltyData.id = previewId;
    loyaltyData.source = 'ADMIN';
    loyaltyData.currency = 'NGN';
    loyaltyData.action = action;

    if (action === 'credit') {
      loyaltyData.increaseByPoint = Number(values.point);
    } else {
      loyaltyData.decreaseByPoint = Number(values.point);
    }
    updateUserPoint(loyaltyData);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='Loyalty Users'
            breadcrumbItems={breadcrumbItems}
          />
          <GreySummaryCard field='Loyalty' subModule='Rider Loyalties'>
            <>
              {message && (
                <Alert color='success' className='text-center'>
                  {message}
                </Alert>
              )}
              {loyaltyError && (
                <Alert color='danger' className='text-center'>
                  {loyaltyError}
                </Alert>
              )}
              <Row>
                <Col>
                  <Row>
                    <MiniWidgets />
                  </Row>
                  <LoyaltyTable
                    loyaltyData={users}
                    tableError={tableError}
                    isAuthorize={isAuthorize}
                    loading
                    fetchAllUser={fetchAllUser}
                    previewAction={previewHandler}
                    pointHandler={pointHandler}
                    history={history}
                    message={message}
                    CheckAction={CheckAction}
                    ReadCheck={ReadCheck}
                    accessControl={accessControl}
                  />
                </Col>
              </Row>

              <Modal
                isOpen={showPointModal}
                toggle={() => setShowPointModal(!showPointModal)}
              >
                <ModalHeader toggle={() => setShowPointModal(false)}>
                  User Point Update
                </ModalHeader>
                <ModalBody>
                  {message && (
                    <Alert color='success' className='text-center'>
                      {message}
                    </Alert>
                  )}
                  {pointError && (
                    <Alert color='danger' className='text-center'>
                      {pointError}
                    </Alert>
                  )}
                  <div>
                    <AvForm
                      className='form-horizontal'
                      onValidSubmit={handlePointUpdate}
                    >
                      <Row>
                        <Col md='9'>
                          <FormGroup className='mb-4'>
                            <Label htmlFor='point'>
                              Number of points to{' '}
                              {action === 'credit' ? 'credit' : 'debit'}{' '}
                            </Label>
                            <AvField
                              name='point'
                              type='number'
                              min='0'
                              className='form-control bg-light'
                              id='point'
                              value={''}
                              placeholder='Enter number of points...'
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <button className='btn btn-success mr-2' type='submit'>
                        {loading ? 'Submitting ...' : 'Submit'}
                      </button>
                      <Link to='#' onClick={() => setShowPointModal(false)}>
                        <button className='btn btn-secondary'>Close</button>
                      </Link>
                    </AvForm>
                  </div>
                </ModalBody>
              </Modal>
            </>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { loyaltyError, pointError, message } = state.Loyalty;
  const { isAuthorize, users, loading, userError, tableError } = state.Users;
  return {
    users,
    userError,
    loading,
    loyaltyError,
    pointError,
    message,
    tableError,
    isAuthorize,
    accessControl
  };
};

export default connect(mapStateToProps, {
  fetchAllUser,
  updateUserPoint,
  updateUserPointFailed,
})(Loyalty);
