import React, { useState, useEffect } from "react";
import {
  Card,
  Alert,
  CardBody,
  Row,
  FormGroup,
  Col,
  Input,
  UncontrolledTooltip,
  Modal,
  ModalBody,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";

import ReactPaginate from "react-paginate";

import Loader from "../../../components/Common/Loading/index.js";
import Moment from "moment";
import moment from "moment-timezone";

import FilterDropDown from "../../../components/Common/FilterDropDown/index.js";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { QueryReallignment, ScrollToTop } from "../../../utils/general.js";
import { AvForm, AvField } from "availity-reactstrap-validation";
import queryString from "query-string";

let skip = 0;
let dataLimit = 10;

const BusStopTable = ({
  busStopData,
  updateBusStop,
  previewAction,
  tableError,
  fetchBusStops,
  deleteBusStop,
  history,
  message,
  CheckAction,
  accessControl,
  ReadCheck,
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [skipPage, setSkipPage] = useState(0);
  const [menu, setMenu] = useState(false);
  const [pageCount, setPageCount] = useState("");
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState("");
  const [date, setDate] = useState({});
  const [showImage, setShowImage] = useState(false);
  const [imageSRC, setImage] = useState("");

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(busStopData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = { skip, take: dataLimit, search };
    fetchBusStops(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data?.selected;
    setPageNumber(parseInt(data?.selected));
    if (skip > 0) {
      skip += "0";
      setPageNumber(parseInt(data?.selected + "0"));
    }
    let pageInfo = { skip, take, search };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchBusStops(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      skip,
    };
    SetUrlWithParams();
    fetchBusStops(queryParams);
  };
  useEffect(() => {
    if (busStopData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(busStopData?.total / take));
    }
  }, [busStopData]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchBusStops(parsedUrlParams);
  }, [fetchBusStops]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,

      skip: pageNumber,
      take,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        // start: date.start,
        // end: date.end,
        skip,
        take,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }

    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
        };
        fetchBusStops(queryParams);
      }, 2000);
    }
  }, [fetchBusStops, message]);

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    setMenu(false);
    let queryParams = {
      search,
      take,
      skip,
      status: values.vehicleStatus,
      start: values.startDate,
      end: values.endDate,
    };
    fetchBusStops(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: Moment().subtract(1, "w").format("YYYY-MM-DD"),
      end: moment.utc().tz("Africa/Lagos").format("YYYY-MM-DD"),
    });
    let queryParams = {
      search,
      take,
      skip,
      start: Moment().subtract(1, "w").format("YYYY-MM-DD"),
      end: moment.utc().tz("Africa/Lagos").format("YYYY-MM-DD"),
    };
    fetchBusStops(queryParams);
  };

  let accessChecking = ReadCheck(accessControl, "Bus", "Bus Stop");
  const tableDataHandler = () => {
    let rowData = busStopData?.result?.map((data, i) => {
      return {
        keyId: data?.id,
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,

        name: (
          <>
            {accessChecking ? (
              <Link
                to={`#`}
                onClick={() => previewAction(data?.id)}
                className="mr-3 text-dark font-weight-bold d-flex flex-column align-items-start"
              >
                {data?.name}
                {data?.tag && (
                  <div className="mt-2">
                    <span className="badge badge-success rounded">
                      {data?.tag}
                    </span>
                  </div>
                )}
              </Link>
            ) : (
              <div className="mr-3 text-dark font-weight-bold d-flex flex-column align-items-start">
                {data?.name}
                {data?.tag && (
                  <div className="mt-2">
                    <span className="badge badge-success rounded">
                      {data?.tag}
                    </span>
                  </div>
                )}
              </div>
            )}
          </>
        ),

        map_location: data?.lat && data?.lng && (
          <div className="mr-3 text-dark font-weight-bold">
            <div className="d-flex align-items-center bg-white shadow-lg border rounded">
              <div>
                <div className=" d-block mb-1 font-size-12">
                  Lng:
                  <span className="ml-2 badge badge-soft-secondary text-wrap">
                    {data?.lng}
                  </span>
                </div>
                <div className=" d-block mb-1 text-wrap font-size-12">
                  Lat:
                  <span className=" ml-2 badge badge-soft-secondary">
                    {data?.lat}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ),
        image: (
          <Col className="mb-2 px-0 py-0 text-center">
            {data?.image ? (
              <img
                className="rounded avatar-md border cursor-pointer"
                alt="Bus Stop Img"
                src={data?.image}
                onClick={() => {
                  setImage(data?.image);
                  setShowImage(true);
                }}
              />
            ) : (
              <em>---No Image provided---</em>
            )}
          </Col>
        ),
        address: (
          <div className="text-truncate" style={{ whiteSpace: "normal" }}>{`${
            data?.address ? data?.address : ""
          }
              ${data?.state ? "," + data?.state : ""}
              ${data?.country ? "," + data?.country : ""}`}</div>
        ),

        date: (
          <div>
            <div>{Moment(data?.createdAt)?.format("l")}</div>
            <div className="badge badge-soft-secondary font-size-10">
              {" "}
              {Moment(data?.createdAt)?.format("hh:mm A")}
            </div>
          </div>
        ),
        action: (
          <div className="d-flex align-items-center">
            <CheckAction field="Bus" subModule="Bus Stop" iconType="Edit">
              <Link
                to={`/busStop_form?id=${data?.id}`}
                className="mr-3 text-primary"
                id="edit1"
              >
                <i className="mdi mdi-pencil font-size-18"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="edit1">
                Edit
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field="Bus" subModule="Bus Stop" iconType="Delete">
              <Link
                to="#"
                onClick={() => deleteBusStop(data?.id)}
                className="text-danger mr-3"
                id="delete1"
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="delete1">
                Delete
              </UncontrolledTooltip>
            </CheckAction>
          </div>
        ),
      };
    });

    return {
      columns: [
        {
          text: "Name",
          dataField: "name",
        },
        {
          text: "Location",
          dataField: "map_location",
          sort: true,
          headerAlign: "center",
          // headerStyle: { width: '200px' },
        },

        {
          text: "Address",
          dataField: "address",
          headerAlign: "center",
          headerStyle: { width: "200px" },
        },
        {
          text: "Image",
          dataField: "image",
          headerAlign: "center",
        },
        {
          text: "Created Date",
          dataField: "date",
        },

        {
          text: "Action",
          dataField: "action",
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-3">
              {tableError !== null ? (
                <Alert color="danger" className="text-center">
                  {tableError}
                </Alert>
              ) : (
                <>
                  <div className="d-flex justify-content-end mb-2">
                    <div>
                      <FilterDropDown
                        menu={menu}
                        toggleMenu={() => setMenu(!menu)}
                      >
                        <div>
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={handleDateFilter}
                          >
                            <SimpleBar
                              style={{
                                maxHeight: "250px",
                              }}
                            >
                              <Row className="px-3  pt-3 pb-0">
                                <Col md="12">
                                  <FormGroup>
                                    <AvField
                                      name="startDate"
                                      type="date"
                                      className="form-control bg-light"
                                      id="startDate"
                                      value={date.start}
                                      helpMessage="Start Date"
                                      placeholder="Please choose the start date..."
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <FormGroup>
                                    <AvField
                                      name="endDate"
                                      type="date"
                                      className="form-control bg-light"
                                      id="endDate"
                                      value={date.end}
                                      helpMessage="End Date"
                                      placeholder="Please choose the end date..."
                                      required
                                    />
                                  </FormGroup>
                                </Col>{" "}
                              </Row>
                            </SimpleBar>

                            <div className="p-2 d-flex justify-content-between align-items-center bg-light">
                              <Link
                                to="#"
                                onClick={resetHandler}
                                className="btn btn-sm  bg-light font-size-14"
                              >
                                <i className=" mdi mdi-refresh mr-1"> Reset</i>
                              </Link>

                              <button
                                className="btn btn-success btn-sm mr-2"
                                type="submit"
                              >
                                <i className="mdi mdi-arrow-right-circle mr-1"></i>
                                Send
                              </button>
                            </div>
                          </AvForm>
                        </div>
                      </FilterDropDown>
                    </div>
                  </div>
                  {!isLoading && data !== null ? (
                    <>
                      <div className="d-flex justify-content-between mb-2">
                        <div>
                          <Input
                            bsSize="sm"
                            id="take"
                            name="take"
                            type="select"
                            onChange={handleLimitChange}
                            value={take}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div>
                          <Input
                            bsSize="sm"
                            id="search"
                            value={search}
                            name="search"
                            onChange={SearchHandler}
                            placeholder="Search"
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField="keyId"
                        data={data?.rows}
                        columns={data?.columns}
                        striped
                        hover
                        wrapperClasses="table-responsive"
                        noDataIndication={"No Data to Display"}
                        defaultSorted={[{ dataField: "index", order: "desc" }]}
                        // sort={{ dataField: 'name', order: 'asc' }}
                      />
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        // breakLabel={'...'}
                        // marginPagesDisplayed={2}
                        // pageRangeDisplayed={3}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        forcePage={skipPage}
                        //Bootstrap styling
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={showImage}
        toggle={() => {
          setShowImage(!showImage);
          setImage(null);
        }}
        size="lg"
        style={{ left: "5%" }}
        centered={true}
      >
        <ModalBody className="p-0 m-2" style={{ height: "70vh" }}>
          <img
            src={imageSRC}
            alt="Bus Stop img"
            style={{ width: "-webkit-fill-available", height: "100%" }}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default BusStopTable;
