import {
  FETCH_RIDE_SUMMARY,
  FETCH_RIDE_SUMMARY_SUCCESSFULLY,
  FETCH_RIDE_SUMMARY_FAILED,
  FETCH_RIDE_PRICE,
  FETCH_RIDE_PRICE_SUCCESSFULLY,
  FETCH_RIDE_PRICE_FAILED,
  FETCH_RIDES,
  FETCH_RIDES_SUCCESSFUL,
  FETCH_RIDES_API_FAILED,
  FETCH_FOR_CSV,
  FETCH_FOR_CSV_SUCCESSFULLY,
  FETCH_FOR_CSV_ERROR,
  FETCH_EACH_RIDE,
  FETCH_EACH_RIDE_SUCCESSFUL,
  FETCH_EACH_RIDE_API_FAILED,
  RIDE_STATUS_UPDATE,
  RIDE_STATUS_UPDATE_SUCCESSFUL,
  RIDE_STATUS_UPDATE_FAILED,
  IS_RIDE_AUTHORIZE,
  DELETE_RIDE,
  DELETE_RIDE_SUCCESSFUL,
  DELETE_RIDE_FAILED,
  FETCH_MY_RIDE,
  FETCH_MY_RIDE_SUCCESSFUL,
  FETCH_MY_RIDE_API_FAILED,
  CREATE_RIDE,
  CREATE_RIDE_SUCCESSFUL,
  CREATE_RIDE_FAILED,
  UPDATE_RIDE,
  UPDATE_RIDE_SUCCESSFUL,
  UPDATE_RIDE_FAILED,
  BROADCAST_RIDE,
  BROADCAST_RIDE_SUCCESSFUL,
  BROADCAST_RIDE_FAILED,
  RIDE_PRICE,
  RIDE_PRICE_SUCCESSFUL,
  RIDE_PRICE_FAILED,
  FETCH_PLACES,
  FETCH_PLACES_SUCCESSFUL,
  FETCH_PLACES_FAILED,
  FETCH_RIDE_LOG,
  FETCH_RIDE_LOG_SUCCESSFUL,
  FETCH_RIDE_LOG_API_FAILED,
  FETCH_RIDE_DECLINE_LOG,
  FETCH_RIDE_DECLINE_LOG_SUCCESSFUL,
  FETCH_RIDE_DECLINE_LOG_API_FAILED,
  CONNECT,
  CONNECT_SUCCESSFUL,
  CONNECT_FAILED,
} from './actionTypes';

export const fetchRideAuthorizeError = (error) => {
  return {
    type: IS_RIDE_AUTHORIZE,
    payload: error,
  };
};

export const fetchRides = (payload) => {
  return {
    type: FETCH_RIDES,
    payload,
  };
};

export const fetchRidesSuccessful = (payload) => {
  return {
    type: FETCH_RIDES_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchRidesError = (error) => {
  return {
    type: FETCH_RIDES_API_FAILED,
    payload: error,
  };
};

export const fetchRideLog = (payload) => {
  return {
    type: FETCH_RIDE_LOG,
    payload,
  };
};

export const fetchRideLogSuccessful = (payload) => {
  return {
    type: FETCH_RIDE_LOG_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchRideLogError = (error) => {
  return {
    type: FETCH_RIDE_LOG_API_FAILED,
    payload: error,
  };
};

export const fetchRideDeclineLog = ({ id, query }) => {
  return {
    type: FETCH_RIDE_DECLINE_LOG,
    payload: { id, query },
  };
};

export const fetchRideDeclineLogSuccessful = (payload) => {
  return {
    type: FETCH_RIDE_DECLINE_LOG_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchRideDeclineLogError = (error) => {
  return {
    type: FETCH_RIDE_DECLINE_LOG_API_FAILED,
    payload: error,
  };
};

export const fetchDataForCSV = (payload) => {
  return {
    type: FETCH_FOR_CSV,
    payload,
  };
};

export const fetchForCSVSuccessfully = (payload) => {
  return {
    type: FETCH_FOR_CSV_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchForCSVError = (error) => {
  return {
    type: FETCH_FOR_CSV_ERROR,
    payload: error,
  };
};

export const fetchPlaces = (payload) => {
  return {
    type: FETCH_PLACES,
    payload,
  };
};

export const fetchPlacesSuccessful = (payload) => {
  return {
    type: FETCH_PLACES_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchPlacesFailed = (error) => {
  return {
    type: FETCH_PLACES_FAILED,
    payload: error,
  };
};

export const fetchEachRide = (id) => {
  return {
    type: FETCH_EACH_RIDE,
    payload: id,
  };
};

export const fetchEachRideSuccessful = (payload) => {
  return {
    type: FETCH_EACH_RIDE_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachRideError = (error) => {
  return {
    type: FETCH_EACH_RIDE_API_FAILED,
    payload: error,
  };
};

export const fetchMyRide = (payload) => {
  return {
    type: FETCH_MY_RIDE,
    payload,
  };
};

export const fetchMyRideSuccessful = (payload) => {
  return {
    type: FETCH_MY_RIDE_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchMyRideError = (error) => {
  return {
    type: FETCH_MY_RIDE_API_FAILED,
    payload: error,
  };
};

export const rideStatus = (payload) => {
  return {
    type: RIDE_STATUS_UPDATE,
    payload,
  };
};
export const rideStatusFailed = (error) => {
  return {
    type: RIDE_STATUS_UPDATE_FAILED,
    payload: error,
  };
};

export const rideStatusSuccessful = (payload) => {
  return {
    type: RIDE_STATUS_UPDATE_SUCCESSFUL,
    payload,
  };
};

export const deleteRide = (id) => {
  return {
    type: DELETE_RIDE,
    payload: id,
  };
};

export const deleteRideSuccessful = (payload) => {
  return {
    type: DELETE_RIDE_SUCCESSFUL,
    payload,
  };
};

export const deleteRideFailed = (error) => {
  return {
    type: DELETE_RIDE_FAILED,
    payload: error,
  };
};

export const createRide = (payload) => {
  return {
    type: CREATE_RIDE,
    payload,
  };
};

export const createRideSuccessful = (payload) => {
  return {
    type: CREATE_RIDE_SUCCESSFUL,
    payload,
  };
};

export const createRideFailed = (error) => {
  return {
    type: CREATE_RIDE_FAILED,
    payload: error,
  };
};

export const updateRide = (payload) => {
  return {
    type: UPDATE_RIDE,
    payload,
  };
};

export const updateRideFailed = (error) => {
  return {
    type: UPDATE_RIDE_FAILED,
    payload: error,
  };
};

export const updateRideSuccessful = (payload) => {
  return {
    type: UPDATE_RIDE_SUCCESSFUL,
    payload,
  };
};

export const RidePrice = (payload) => {
  return {
    type: RIDE_PRICE,
    payload,
  };
};

export const RidePriceSuccessful = (payload) => {
  return {
    type: RIDE_PRICE_SUCCESSFUL,
    payload,
  };
};

export const RidePriceFailed = (error) => {
  return {
    type: RIDE_PRICE_FAILED,
    payload: error,
  };
};

//SUMMARY ENTRIES
export const fetchRidePrice = (payload) => {
  return {
    type: FETCH_RIDE_PRICE,
    payload,
  };
};

export const fetchRidePriceSuccessfully = (payload) => {
  return {
    type: FETCH_RIDE_PRICE_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchRidePriceFailed = (error) => {
  return {
    type: FETCH_RIDE_PRICE_FAILED,
    payload: error,
  };
};

//SUMMARY ENTRIES
export const fetchRideSummary = (query) => {
  return {
    type: FETCH_RIDE_SUMMARY,
    payload: query,
  };
};

export const fetchRideSummarySuccessfully = (payload) => {
  return {
    type: FETCH_RIDE_SUMMARY_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchRideSummaryFailed = (error) => {
  return {
    type: FETCH_RIDE_SUMMARY_FAILED,
    payload: error,
  };
};

export const broadCastRide = (payload) => {
  return {
    type: BROADCAST_RIDE,
    payload,
  };
};

export const broadCastRideFailed = (error) => {
  return {
    type: BROADCAST_RIDE_FAILED,
    payload: error,
  };
};

export const broadCastRideSuccessful = (payload) => {
  return {
    type: BROADCAST_RIDE_SUCCESSFUL,
    payload,
  };
};

export const connectDriver = (payload) => {
  return {
    type: CONNECT,
    payload: { payload },
  };
};

export const connectDriverFailed = (error) => {
  return {
    type: CONNECT_FAILED,
    payload: error,
  };
};

export const connectDriverSuccessful = (payload) => {
  return {
    type: CONNECT_SUCCESSFUL,
    payload,
  };
};
