import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import DocTable from "./DocTable";
import DocPreview from "../../../pages/IRides/Users/Document/previewDoc";
import queryString from "query-string";
import { QueryReallignment } from "../../../utils/general";
import HistoryCard from "./HistoryCard";
import Overview from "./Overview";
import PaymentHistory from "./PaymentHistory";
import AccountApprover from "./AccountApprover";
import Activities from "./Activities";
import UserBonus from "./UserBonus";
import UserVehicle from "./UserVehicle";
import BusVehicle from "./busVehicle";
import UserDriverLogs from "./UserDriverLogs";
import Logs from "./Logs";

const UserReportCard = ({
  user,
  bank,
  document,
  isLoading,
  history,
  handleVehicleApproval,
  updateVehicleFailed,
  updateVehiclePackages,
  message,
  vehicleError,
  user_location,
  loadingLocation,
  user_locationError,
}) => {
  const [activeTab, SetActiveTab] = useState(1);
  const [previewId, setPreviewId] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [docIsPdf, setDocIsPdf] = useState(false);
  const [docIsImg, setDocIsImg] = useState(false);

  const [docName, setDocName] = useState(null);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      SetActiveTab(tab);
      // history.push({
      //   pathname: history.location.pathname,
      //   search: `?tab=${tab}`,
      // });
    }
  };

  useEffect(() => {
    const queryParam = queryString.parse(history.location.search);
    if (queryParam?.tab) {
      SetActiveTab(queryParam.tab);
    } else {
      SetActiveTab("1");
    }
  }, []);
  const previewHandler = (name, id, isImg, isDoc) => {
    setShowPreviewModal(true);
    setPreviewId(id);
    setDocName(name);
    setDocIsImg(isImg);
    setDocIsPdf(isDoc);
  };
  return (
    <div>
      <Card>
        <CardBody>
          <Nav tabs className="nav-tabs-custom mb-4">
            <NavItem>
              <NavLink
                onClick={() => {
                  toggleTab("1");
                }}
                className={classnames(
                  { active: activeTab === "1" },
                  "font-weight-bold p-3"
                )}
              >
                Overview
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={() => {
                  toggleTab("2");
                }}
                className={classnames(
                  { active: activeTab === "2" },
                  "font-weight-bold p-3"
                )}
              >
                Ride History
              </NavLink>
            </NavItem>
            {user?.userType === "DRIVER" && (
              <>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      toggleTab("3");
                    }}
                    className={classnames(
                      { active: activeTab === "3" },
                      "p-3 font-weight-bold"
                    )}
                  >
                    Documents
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      toggleTab("4");
                    }}
                    className={classnames(
                      { active: activeTab === "4" },
                      " p-3 font-weight-bold"
                    )}
                  >
                    Vehicle
                  </NavLink>
                </NavItem>
              </>
            )}
            {user?.userType === "INSPECTOR" && (
              <>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      toggleTab("3");
                    }}
                    className={classnames(
                      { active: activeTab === "3" },
                      "p-3 font-weight-bold"
                    )}
                  >
                    Documents
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      toggleTab("4");
                    }}
                    className={classnames(
                      { active: activeTab === "4" },
                      " p-3 font-weight-bold"
                    )}
                  >
                    Vehicle
                  </NavLink>
                </NavItem>
              </>
            )}
            <NavItem>
              <NavLink
                onClick={() => {
                  toggleTab("5");
                }}
                className={classnames(
                  { active: activeTab === "5" },
                  " p-3 font-weight-bold"
                )}
              >
                Payment
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={() => {
                  toggleTab("6");
                }}
                className={classnames(
                  { active: activeTab === "6" },
                  "font-weight-bold p-3"
                )}
              >
                Account Approver
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            {activeTab == 1 && (
              <TabPane tabId="1">
                <Overview
                  bank={bank}
                  userInfo={user}
                  document={document}
                  user_location={user_location}
                  loadingLocation={loadingLocation}
                  user_locationError={user_locationError}
                />
              </TabPane>
            )}
            {activeTab == 2 && (
              <TabPane tabId="2">
                <HistoryCard user={user} user_location={user_location} />
              </TabPane>
            )}
            {user?.userType === "DRIVER" && (
              <>
                {activeTab == 3 && (
                  <TabPane tabId="3">
                    <DocTable
                      previewAction={previewHandler}
                      document={document}
                      userInfo={user}
                      isLoading={isLoading}
                    />
                  </TabPane>
                )}
                {activeTab == 4 && (
                  <TabPane tabId="4">
                    {user?.packagePreference !== "BUS" ? (
                      <UserVehicle
                        user={user}
                        handleVehicleApproval={handleVehicleApproval}
                        updateVehicleFailed={updateVehicleFailed}
                        updateVehiclePackages={updateVehiclePackages}
                        message={message}
                        vehicleError={vehicleError}
                      />
                    ) : (
                      <BusVehicle
                        user={user}
                        handleVehicleApproval={handleVehicleApproval}
                        updateVehicleFailed={updateVehicleFailed}
                        updateVehiclePackages={updateVehiclePackages}
                        message={message}
                        vehicleError={vehicleError}
                      />
                    )}
                  </TabPane>
                )}
              </>
            )}

            {user?.userType === "INSPECTOR" && (
              <>
                {activeTab == 3 && (
                  <TabPane tabId="3">
                    <DocTable
                      previewAction={previewHandler}
                      document={document}
                      userInfo={user}
                      isLoading={isLoading}
                    />
                  </TabPane>
                )}
                {activeTab == 4 && (
                  <TabPane tabId="4">
                    <UserVehicle
                      user={user}
                      handleVehicleApproval={handleVehicleApproval}
                      updateVehicleFailed={updateVehicleFailed}
                      updateVehiclePackages={updateVehiclePackages}
                      message={message}
                      vehicleError={vehicleError}
                    />
                  </TabPane>
                )}
              </>
            )}
            {activeTab == 5 && (
              <TabPane tabId="5">
                <PaymentHistory
                  previewAction={previewHandler}
                  document={document}
                  userInfo={user}
                  isLoading={isLoading}
                />
              </TabPane>
            )}
            {activeTab == 6 && (
              <TabPane tabId="6">
                <AccountApprover
                  userInfo={user}
                  user_location={user_location}
                />
              </TabPane>
            )}
          </TabContent>
        </CardBody>
      </Card>
      <Modal
        size={`${docIsPdf ? "lg" : "md"}`}
        isOpen={showPreviewModal}
        toggle={() => setShowPreviewModal(!showPreviewModal)}
      >
        <ModalHeader toggle={() => setShowPreviewModal(false)}>
          {docName ? docName : "Preview Document"}
        </ModalHeader>
        <ModalBody>
          <DocPreview
            previewId={previewId}
            docIsPdf={docIsPdf}
            docIsImg={docIsImg}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default withRouter(UserReportCard);
