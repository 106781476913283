/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { Alert, Row, Col, Table } from 'reactstrap';
import Loader from '../../../components/Common/Loading/index.js';
import car4 from '../../../assets/images/cars_type/Suv_car.png';
import { fetchEachPackage } from '../../../store/actions';

const ServiceType = ({
  packagePreview,
  previewId,
  loading,
  packageError,
  fetchEachPackage,
}) => {
  useEffect(() => {
    fetchEachPackage(previewId);
  }, [fetchEachPackage, previewId]);

  return (
    <React.Fragment>
      <div className='mt-3'>
        {packageError && !loading && (
          <Alert color='danger' className='text-center'>
            {packageError}
          </Alert>
        )}
        {packagePreview && loading ? (
          <Loader />
        ) : (
          <Row>
            <Col sm={12} className='text-center'>
              <div
                className=' my-0 mx-auto rounded w-auto p-2 shadow'
                style={{ maxWidth: 'fit-content', background: '#F1F8E9' }}
              >
                <img
                  src={packagePreview?.picture ? packagePreview.picture : car4}
                  alt='suv'
                  className='avatar-sm'
                />
              </div>
              {packagePreview?.status.toLowerCase() == 'active' ? (
                <span className='badge badge-success'>
                  {packagePreview?.status}
                </span>
              ) : packagePreview?.status.toLowerCase() == 'inactive' ? (
                <span className='badge badge-danger text-capitalize'>
                  {packagePreview?.status}
                </span>
              ) : (
                <span className='badge badge-warning text-capitalize'>
                  {packagePreview?.status}
                </span>
              )}
              <p>{packagePreview?.description}</p>
            </Col>
            <Col sm={12}>
              <div>
                <Table hover>
                  <tbody>
                    <tr>
                      <td className='font-weight-bold'>Name</td>
                      <td>{packagePreview?.name}</td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Package Type</td>
                      <td>{packagePreview?.packageType}</td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Provider Name</td>
                      <td>{packagePreview?.provider}</td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Package key</td>
                      <td>{packagePreview?.key}</td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Pricing Type</td>
                      <td>{packagePreview?.pricingType}</td>
                    </tr>

                    <tr>
                      <td className='font-weight-bold'>Minimum Price </td>
                      <td>{packagePreview?.minimumPrice} (N)</td>
                    </tr>

                    <tr>
                      <td className='font-weight-bold'>
                        Minimum PriceDistance
                      </td>
                      <td>{packagePreview?.minimumPriceDistance} (m)</td>
                    </tr>

                    <tr>
                      <td className='font-weight-bold'>Base Price </td>
                      <td>{packagePreview?.basePrice} (₦)</td>
                    </tr>

                    <tr>
                      <td className='font-weight-bold'>Distance Price </td>
                      <td>{packagePreview?.distancePrice} (N/m)</td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Capacity</td>
                      <td>{packagePreview?.capacity}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { packagePreview, loading, packageError } = state.Packages;
  return { packagePreview, loading, packageError };
};

export default connect(mapStateToProps, { fetchEachPackage })(ServiceType);
