/* eslint-disable eqeqeq */
import React, { useEffect } from 'react'
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux'
import { Alert, Row, Col, Table } from 'reactstrap'
import Loader from '../../../components/Common/Loading/index.js'
import { fetchEachBonus, fetchUserDetails } from '../../../store/actions'

const Bonus = ({
  bonusPreview,
  previewId,
  loading,
  bonusError,
  fetchEachBonus,
  fetchUserDetails,
  user,
}) => {
  useEffect(() => {
    fetchEachBonus(previewId)
  }, [fetchEachBonus, previewId])

  useEffect(() => {
    if (bonusPreview) {
      fetchUserDetails(bonusPreview?.userId)
    }
  }, [bonusPreview, fetchUserDetails])

  return (
    <React.Fragment>
      <div className="mt-3">
        {bonusError && !loading && (
          <Alert color="danger" className="text-center">
            {bonusError?.error}
          </Alert>
        )}
        {bonusPreview && loading ? (
          <Loader />
        ) : (
          <Row>
            <Col sm={12}>
              <div>
                {bonusPreview?.note ? (
                  <center className="mb-4">{bonusPreview.note}</center>
                ) : null}
                <Table hover>
                  {/* <tbody>
                    <tr>
                      <td className="font-weight-bold">Account Name</td>
                      <td className="text-uppercase">
                        {bonusPreview?.accountName}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Bonus</td>
                      <td className="text-uppercase">
                        {bonusPreview?.bonusName}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Account Number</td>
                      <td>{bonusPreview?.accountNumber}</td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Account Owner</td>
                      <td>
                        {' '}
                        {user
                          ? `${user?.firstName} ${user?.lastName}`
                          : 'User not found'}
                      </td>
                    </tr>
                  </tbody> */}
                </Table>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { bonusPreview, loading, bonusError } = state.Bonus
  const { user, userError } = state.Users
  return { bonusPreview, loading, bonusError, user, userError }
}

export default connect(mapStateToProps, { fetchEachBonus, fetchUserDetails })(
  Bonus,
)
