import React, { useState } from "react";
import { ChannelList, Channel, ChannelSettings } from "@sendbird/uikit-react/";

function CustomizedApp({}) {
  const [currentChannel, setCurrentChannel] = useState(null);
  const currentChannelUrl = currentChannel ? currentChannel.url : "";
  const [showSettings, setShowSettings] = useState(false);

  let channelChatDiv = document.getElementsByClassName("channel-chat")[0];
  console.log(currentChannel, currentChannelUrl);
  const renderSettingsBar = () => {
    channelChatDiv.style.width = "52%";
    channelChatDiv.style.cssFloat = "left";
  };
  const hideSettingBar = () => {
    channelChatDiv.style.width = "75%";
    channelChatDiv.style.cssFloat = "right";
  };
  return (
    <div className="channel-wrap">
      <div className=" channel-list">
        <ChannelList
          onChannelSelect={(channel) => {
            setCurrentChannel(channel);
          }}
        />
      </div>

      <div className="Channel-chat">
        <Channel
          channelUrl={currentChannelUrl}
          onChatHeaderActionClick={() => {
            setShowSettings(!showSettings);
            renderSettingsBar();
          }}
        />
      </div>
      {showSettings && (
        <div className="channel-settings">
          <ChannelSettings
            channelUrl={currentChannelUrl}
            onCloseClick={() => {
              setShowSettings(false);
              hideSettingBar();
            }}
          />
        </div>
      )}
    </div>
  );
}

export default CustomizedApp;
