export const FETCH_ZONE = 'FETCH_ZONE';
export const FETCH_ZONE_SUCCESSFUL = 'FETCH_ZONE_SUCCESSFUL';
export const FETCH_ZONE_API_FAILED = 'FETCH_ZONE_API_FAILED';

export const FETCH_EACH_ZONE = 'FETCH_EACH_ZONE';
export const FETCH_EACH_ZONE_SUCCESSFUL = 'FETCH_EACH_ZONE_SUCCESSFUL';
export const FETCH_EACH_ZONE_API_FAILED = 'FETCH_EACH_ZONE_API_FAILED';

export const DELETE_ZONE = 'DELETE_ZONE';
export const DELETE_ZONE_SUCCESSFUL = 'DELETE_ZONE_SUCCESSFULLY';
export const DELETE_ZONE_FAILED = 'DELETE_ZONE_FAILED';

export const CREATE_ZONE = 'CREATE_ZONE';
export const CREATE_ZONE_SUCCESSFUL = 'CREATE_ZONE_SUCCESSFULLY';
export const CREATE_ZONE_FAILED = 'CREATE_ZONE_FAILED';

export const UPDATE_ZONE = 'UPDATE_ZONE';
export const UPDATE_ZONE_SUCCESSFUL = 'UPDATE_ZONE_SUCCESSFULLY';
export const UPDATE_ZONE_FAILED = 'FAILED';
