import React, { useState, useEffect, useRef } from 'react';
const Stopwatch = ({ startTimer }) => {
  const [newTime, setNewTime] = useState(0);
  const Timer = useRef(null);
  useEffect(() => {
    let delayed = 60;
    let interval = setInterval(() => {
      delayed--;
      setNewTime(delayed);
      Timer.current = delayed;
    }, 1000);
    return () => clearInterval(interval);
  }, [startTimer]);
  return (
    <div className='d-flex align-items-center bg-light text-secondary rounded px-2 py-0 '>
      <i
        className=' ri-refresh-line
 font-size-18 text-secondary  mr-2'
      ></i>{' '}
      in
      <span className='font-size-13 ml-1'>{newTime}</span>
    </div>
  );
};
export default Stopwatch;
