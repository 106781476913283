import axios from 'axios'
import store from '../store/index';
import { setNetworkError } from '../store/actions';

if (navigator.geolocation) {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      let { latitude, longitude } = position.coords;
      if (!localStorage.getItem('lat')) {
        localStorage.setItem('lat', latitude)
        localStorage.setItem('lng', longitude)
      }
    });
}
const checkAuthTokens = () => {
  let authTokens = localStorage.getItem('authTokens')
    ? JSON.parse(localStorage.getItem('authTokens'))
    : null

  return authTokens
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // headers: { Authorization: `Bearer ${checkAuthTokens()?.accessToken}` },
})

axiosInstance.interceptors.request.use(async (req) => {
  if (checkAuthTokens()) {
    req.headers.Authorization = `Bearer ${checkAuthTokens()?.accessToken}`
  }
  req.headers.requester = req.baseURL
  req.headers['nryde-source'] = `${localStorage.getItem(
    'headerType',
  )}-dashboard`
  if (localStorage.getItem('lat')) {
    req.headers['nryde-lng'] = JSON.parse(localStorage.getItem('lng'));
    req.headers['nryde-lat'] = JSON.parse(localStorage.getItem('lat'));
  }


  if (localStorage.getItem('IP')) {
    // req.headers['Ip-Address'] = JSON.parse(localStorage.getItem('IP'));
  }
  if (!localStorage.getItem('IP')) {
    const getIP = await axios.get('https://geolocation-db.com/json/')
    localStorage.setItem('IP', JSON.stringify(getIP.data.IPv4))
    // req.headers['Ip-Address'] = JSON.parse(localStorage.getItem('IP'));
  }

  return req
})

axiosInstance.interceptors.response.use(
  (res) => {
    return res
  },
  async (error) => {
    const originalConfig = error.config
    if (error.response) {
      if (error.response.status === 401) {
        try {
          const rs = await refreshToken()
          if (rs) {
            const { result } = rs.data
            localStorage.setItem('authTokens', JSON.stringify(result))
            axiosInstance.defaults.headers.Authorization = `Bearer ${result?.accessToken}`
            return axiosInstance(originalConfig)
          }
        } catch (_error) {
          window.location.href = '/login'
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data)
          }
          return Promise.reject(_error)
        }
      }
    }
    if (error.code === "ERR_NETWORK") {
      store.dispatch(setNetworkError(error.code, error.message));
    }
    return Promise.reject(error)
  },
)

async function refreshToken() {
  if (localStorage.getItem('authTokens')) {
    const result = await axiosInstance.post('/auth/refreshToken', {
      refreshToken: JSON.parse(localStorage.getItem('authTokens')).refreshToken,
    })
    return result
  } else {
    return null
  }
}

export default axiosInstance
