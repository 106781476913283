import {
  IS_VEHICLE_AUTHORIZE,
  FETCH_VEHICLE_SUMMARY,
  FETCH_VEHICLE_SUMMARY_SUCCESSFULLY,
  FETCH_VEHICLE_SUMMARY_FAILED,
  FETCH_VEHICLE,
  FETCH_VEHICLE_SUCCESSFUL,
  FETCH_VEHICLE_API_FAILED,
  FETCH_EACH_VEHICLE,
  FETCH_EACH_VEHICLE_SUCCESSFUL,
  FETCH_EACH_VEHICLE_API_FAILED,
  FETCH_MY_VEHICLE,
  FETCH_MY_VEHICLE_SUCCESSFUL,
  FETCH_MY_VEHICLE_API_FAILED,
  DELETE_VEHICLE,
  DELETE_VEHICLE_SUCCESSFUL,
  DELETE_VEHICLE_FAILED,
  CREATE_VEHICLE,
  CREATE_VEHICLE_SUCCESSFUL,
  CREATE_VEHICLE_FAILED,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_SUCCESSFUL,
  UPDATE_VEHICLE_FAILED,
  UPDATE_VEHICLE_WITH_PACKAGE,
  UPDATE_VEHICLE_WITH_PACKAGE_SUCCESSFULLY,
  UPDATE_VEHICLE_WITH_PACKAGE_FAILED,
} from './actionTypes';

const initialState = {
  vehicle: null,
  vehicleError: null,
  vehiclePreview: null,
  vehicleSummary: null,
  summaryError: null,
  isAuthorize: null,
  message: null,
  loading: false,
  vId: null,
};

const Vehicle = (state = initialState, action) => {
  switch (action.type) {
    case IS_VEHICLE_AUTHORIZE:
      state = {
        ...state,
        isAuthorize: action.payload,
      };
      break;

    case FETCH_VEHICLE:
    case UPDATE_VEHICLE:
    case UPDATE_VEHICLE_WITH_PACKAGE:
    case DELETE_VEHICLE:
      state = {
        ...state,
        // vehicle: null,
        vehicleError: null,
        loading: true,
        isAuthorize: null,
        message: null,
      };
      break;
    case FETCH_VEHICLE_SUCCESSFUL:
    case FETCH_MY_VEHICLE_SUCCESSFUL:
      state = {
        ...state,
        vehicle: action.payload,
        vehicleError: null,
        loading: false,
      };
      break;

    case DELETE_VEHICLE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        vehicleError: null,
        message: 'Vehicle deleted successfully',
      };
      break;
    case UPDATE_VEHICLE_SUCCESSFUL:
    case UPDATE_VEHICLE_WITH_PACKAGE_SUCCESSFULLY:
      state = {
        ...state,
        loading: false,
        vehicleError: null,
        message: 'Vehicle Updated Successfully',
      };
      break;

    case FETCH_VEHICLE_SUMMARY:
      return {
        ...state,
        vehicleSummary: null,
        summaryError: null,
        loading: true,
      };

    case FETCH_VEHICLE_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        vehicleSummary: action.payload,
        summaryError: null,
        loading: false,
      };

    case FETCH_VEHICLE_SUMMARY_FAILED:
      return {
        ...state,
        vehicleSummary: null,
        summaryError: action.payload,
        loading: false,
      };

    case FETCH_EACH_VEHICLE:
      state = {
        ...state,
        loading: true,
        vehicle: null,
        vehiclePreview: null,
        vehicleError: null,
        message: null,
        vId: null,
      };
      break;
    case FETCH_MY_VEHICLE:
      state = {
        ...state,
        vehicleError: null,
        loading: true,
        message: null,
        vehicle: null,
        vId: null,
      };
      break;
    case FETCH_EACH_VEHICLE_SUCCESSFUL:
      state = {
        ...state,
        vehiclePreview: action.payload,
        loading: false,
        vehicleError: null,
        message: null,
      };
      break;

    case CREATE_VEHICLE:
      state = {
        ...state,
        loading: true,
        vehicleError: null,
        message: null,
      };
      break;

    case CREATE_VEHICLE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        vehicleError: null,
        message: `${action.payload.make}  created successfully`,
        vId: action.payload.id,
      };
      break;

    case CREATE_VEHICLE_FAILED:
    case FETCH_VEHICLE_API_FAILED:
    case DELETE_VEHICLE_FAILED:
    case FETCH_EACH_VEHICLE_API_FAILED:
    case FETCH_MY_VEHICLE_API_FAILED:
      state = {
        ...state,
        vehicle: null,
        vehiclePreview: null,
        loading: false,
        message: null,
        vehicleError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_VEHICLE_FAILED:
    case UPDATE_VEHICLE_WITH_PACKAGE_FAILED:
      state = {
        ...state,
        loading: false,
        vehicleError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Vehicle;
