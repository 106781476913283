/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { Alert, Row, Col, Table } from 'reactstrap';
import Loader from '../../../components/Common/Loading/index.js';
import { fetchEachNotification } from '../../../store/actions';

const Notification = ({
  notificationPreview,
  previewId,
  loading,
  notificationError,
  fetchEachNotification,
  user,
}) => {
  useEffect(() => {
    fetchEachNotification(previewId);
  }, []);

  return (
    <React.Fragment>
      <div className='mt-3'>
        {notificationError && !loading && (
          <Alert color='danger' className='text-center'>
            {notificationError}
          </Alert>
        )}
        {loading ? (
          <Loader />
        ) : (
          <Row>
            <Col sm={12}>
              <div>
                <center className='mb-3'>
                  <h4 className='card-title'>{notificationPreview?.title}</h4>

                  <p>{notificationPreview?.text}</p>
                  {user && user.userType === 'ADMIN' && (
                    <>
                      Has Seen:
                      <span
                        className={`badge badge-soft-${
                          notificationPreview?.hasSeen ? 'success' : 'danger'
                        } font-size-12`}
                      >
                        {notificationPreview?.hasSeen ? 'Yes' : 'No'}
                      </span>
                    </>
                  )}
                </center>
                <Table hover>
                  <tbody>
                    <tr>
                      <td className='font-weight-bold'>Notification Type</td>
                      <td className='text-uppercase'>
                        {notificationPreview?.type}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div className='text-center'>
                  {notificationPreview?.actionUrl && (
                    <button className='btn btn-outline-primary btn-sm rounded'>
                      <a href={notificationPreview?.actionUrl} target='_blank'>
                        Action
                      </a>
                    </button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { notificationPreview, loading, notificationError } =
    state.Notifications;
  const { user } = state.Account;
  return { notificationPreview, loading, notificationError, user };
};

export default connect(mapStateToProps, { fetchEachNotification })(
  Notification
);
