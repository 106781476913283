import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Col, Card, CardBody, Media } from 'reactstrap';
import { connect } from 'react-redux';
import { fetchUserSummary } from '../../../../../../store/actions';

const MiniWidgets = ({
  summaryError,
  summaryCount,
  fetchUserSummary,
  start,
  userType,
  end,
  loading,
  date,
  defaultIcon,
  vehicle
}) => {
  // useEffect(() => {
  //   if (userType) {
  //     fetchUserSummary({ userType });
  //   } else {
  //     fetchUserSummary();
  //   }
  // }, [userType]);

  useEffect(() => {
    if (date.end) {
      if (userType) {
        fetchUserSummary({ userType, vehicle, start: date.start, end: date.end });
      } else {
        fetchUserSummary(date);
      }
    } else {
      if (userType) {
        fetchUserSummary({ userType, vehicle });
      } else {
        fetchUserSummary();
      }
    }
  }, [userType, date]);

  const reports = [
    {
      icon: defaultIcon ? defaultIcon : 'fas fa-users',
      title: 'Registered Captains',
      value: `${summaryCount?.result.total}`,
      rate: `${summaryCount?.previousRegisteredDrivers
        ? summaryCount?.previousRegisteredDrivers
        : '0'
        }%`,
      color: 'primary',
      desc: 'From previous period',
    },
    {
      icon: 'ri-police-car-fill',
      title: 'ACTIVE (Inactive)',
      value: summaryCount?.result.active,
      more: summaryCount?.result.pending,
      rate: `${summaryCount?.previousActiveDrivers
        ? summaryCount?.previousActiveDrivers
        : '0'
        }%`,
      color: 'success',
      desc: 'From previous period',
    },
    {
      icon: 'ri-police-car-fill',
      title: 'Approved Captains',
      value: `${summaryCount?.result?.approvedDriver
        ? summaryCount?.result?.approvedDriver
        : '0'
        }`,
      rate: `${summaryCount?.previousActiveUsers
        ? summaryCount?.previousActiveUsers
        : '0'
        }%`,
      color: 'success',
      desc: 'From previous period',
    },
  ];

  return (
    <React.Fragment>
      {summaryError === null &&
        reports.map((report, key) => (
          <Col key={key} md={3}>
            <Card>
              <CardBody>
                <Media>
                  <Media body className="overflow-hidden">
                    <span
                      className={`text-${report.color} badge badge-soft-${report.color} rounded`}
                    >
                      <i className={report.icon + ' font-size-16'}></i>
                    </span>
                    <p className="text-truncate font-size-14 mt-4 mb-2 ">
                      {report.title}
                    </p>
                    <h2 className="mt-0 mb-0 font-size-">
                      {summaryCount === null ? (
                        <Skeleton duration={2} width="20%" />
                      ) : (
                        report.value.toLocaleString()
                      )}
                      {report?.more && report?.more !== '0' ? (
                        <span className="font-size-15 text-danger">
                          ({report.more})
                        </span>
                      ) : null}
                    </h2>
                  </Media>
                </Media>
              </CardBody>

              {/* <CardBody className="border-top py-3">
              <div className="text-truncate">
                <span className="badge badge-soft-success font-size-11 mr-1">
                  <i className="mdi mdi-menu-up"> </i> {report.rate}
                </span>
                <span className="text-muted ml-2">{report.desc}</span>
              </div>
            </CardBody> */}
            </Card>
          </Col>
        ))}
      <Col md={3}>
        <Card className="d-flex flex-row">
          <CardBody className="pr-1">
            <Media>
              <Media body className="overflow-hidden">
                <span
                  className={`text-warning badge badge-soft-warning rounded`}
                >
                  <i className="ri-star-line  font-size-16"></i>
                </span>
                <p className="text-truncate font-size-14 mt-4 mb-2 ">RATINGS</p>
                <h2 className="mt-0 mb-0 font-size-">
                  {summaryCount === null ? (
                    <Skeleton duration={2} width="20%" />
                  ) : (
                    summaryCount.result.rating.toFixed(2)
                  )}
                </h2>
                {summaryCount?.isCache && summaryCount?.time && (
                  <p
                    className="font-size-12 text-primary"
                    style={{ position: 'absolute', right: '11px' }}
                  >
                    Updated at: {summaryCount?.time?.split(',')?.pop()}
                  </p>
                )}
              </Media>
            </Media>
          </CardBody>
          <CardBody className="p-1 pr-3">
            {summaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">
                  {summaryCount?.result?.ratingsCount?.zero_rating}
                </h6>
                -{' '}
                <span className="text-muted font-size-12">
                  (<i className=" ri-star-s-fill text-warning font-size-10" />{' '}
                  0)
                </span>
              </div>
            )}

            {summaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">
                  {summaryCount?.result?.ratingsCount?.one_rating}
                </h6>
                -{' '}
                <span className="text-muted font-size-12">
                  (<i className=" ri-star-s-fill text-warning font-size-10" />{' '}
                  1)
                </span>
              </div>
            )}

            {summaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">
                  {summaryCount?.result?.ratingsCount?.two_rating}
                </h6>
                -{' '}
                <span className="text-muted font-size-12">
                  (<i className=" ri-star-s-fill text-warning font-size-10" />{' '}
                  2)
                </span>
              </div>
            )}

            {summaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">
                  {summaryCount?.result?.ratingsCount?.three_rating}
                </h6>
                -{' '}
                <span className="text-muted font-size-12">
                  (<i className=" ri-star-s-fill text-warning font-size-10" />{' '}
                  3)
                </span>
              </div>
            )}

            {summaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">
                  {summaryCount?.result?.ratingsCount?.four_rating}
                </h6>
                -{' '}
                <span className="text-muted font-size-12">
                  (<i className=" ri-star-s-fill text-warning font-size-10" />{' '}
                  4)
                </span>
              </div>
            )}

            {summaryCount === null ? (
              <Skeleton duration={2} width="100%" />
            ) : (
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0">
                  {summaryCount?.result?.ratingsCount?.five_rating}
                </h6>
                -{' '}
                <span className="text-muted font-size-12">
                  (<i className=" ri-star-s-fill text-warning font-size-10" />{' '}
                  5)
                </span>
              </div>
            )}
          </CardBody>

          {/* <CardBody className="border-top py-3">
              <div className="text-truncate">
                <span className="badge badge-soft-success font-size-11 mr-1">
                  <i className="mdi mdi-menu-up"> </i> {report.rate}
                </span>
                <span className="text-muted ml-2">{report.desc}</span>
              </div>
            </CardBody> */}
        </Card>
      </Col>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { summaryError, summaryCount } = state.Users;
  return { summaryError, summaryCount };
};

export default connect(mapStateToProps, { fetchUserSummary })(MiniWidgets);
