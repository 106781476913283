import {
  FETCH_PROMOCODE,
  FETCH_PROMOCODE_SUCCESSFUL,
  FETCH_PROMOCODE_API_FAILED,
  FETCH_PROMOHISTORY,
  FETCH_PROMOHISTORY_SUCCESSFUL,
  FETCH_PROMOHISTORY_API_FAILED,
  FETCH_PROMOREDEEMHISTORY,
  FETCH_PROMOREDEEMHISTORY_SUCCESSFUL,
  FETCH_PROMOREDEEMHISTORY_API_FAILED,
  FETCH_EACH_PROMOCODE,
  FETCH_EACH_PROMOCODE_SUCCESSFUL,
  FETCH_EACH_PROMOCODE_API_FAILED,
  DELETE_PROMOCODE,
  DELETE_PROMOCODE_SUCCESSFUL,
  DELETE_PROMOCODE_FAILED,
  CREATE_PROMOCODE,
  CREATE_PROMOCODE_SUCCESSFUL,
  CREATE_PROMOCODE_FAILED,
  UPDATE_PROMOCODE,
  UPDATE_PROMOCODE_SUCCESSFUL,
  UPDATE_PROMOCODE_FAILED,
  FETCH_PROMOCODE_SUMMARY,
  FETCH_PROMOCODE_SUMMARY_SUCCESSFULLY,
  FETCH_PROMOCODE_SUMMARY_FAILED,
} from './actionTypes'

const initialState = {
  promoCode: null,
  promoHistory: null,
  promoCodeError: null,
  tableError: null,
  tableError: null,
  promoCodeSummary: null,
  summaryError: null,
  promoCodePreview: null,
  message: null,
  loading: false,
}

const PromoCode = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROMOCODE:
    case FETCH_PROMOHISTORY:
    case FETCH_PROMOREDEEMHISTORY:
    case UPDATE_PROMOCODE:
    case DELETE_PROMOCODE:
      state = {
        ...state,
        promoCode: null,
        promoCodeError: null,
        promoHistory: null,
        tableError: null,
        tableError: null,
        promoHistoryError: null,
        loading: true,
        message: null,
      }
      break
    case FETCH_PROMOCODE_SUCCESSFUL:
      state = {
        ...state,
        promoCode: action.payload,
        promoCodeError: null,
        tableError: null,
        tableError: null,
        loading: false,
      }
      break

    case FETCH_PROMOHISTORY_SUCCESSFUL:
    case FETCH_PROMOREDEEMHISTORY_SUCCESSFUL:
      state = {
        ...state,
        promoHistory: action.payload,
        promoCodeError: null,
        tableError: null,
        tableError: null,
        loading: false,
        promoHistoryError: null,
      }
      break

    case DELETE_PROMOCODE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        promoCodeError: null,
        message: 'Promo Code deleted successfully',
      }
      break
    case UPDATE_PROMOCODE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        promoCodeError: null,
        message: 'Promo Code Updated Successfully',
      }
      break

    case FETCH_PROMOCODE_SUMMARY:
      return {
        ...state,
        promoCodeSummary: null,
        summaryError: null,
        loading: true,
      }

    case FETCH_PROMOCODE_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        promoCodeSummary: action.payload,
        summaryError: null,
        loading: false,
      }

    case FETCH_PROMOCODE_SUMMARY_FAILED:
      return {
        ...state,
        promoCodeSummary: null,
        summaryError: action.payload,
        loading: false,
      }

    case FETCH_EACH_PROMOCODE:
      state = {
        ...state,
        loading: true,
        promoCode: null,
        promoCodeError: null,
        message: null,
      }
      break

    case FETCH_EACH_PROMOCODE_SUCCESSFUL:
      state = {
        ...state,
        promoCodePreview: action.payload,
        loading: false,
        promoCodeError: null,
        message: null,
      }
      break

    case CREATE_PROMOCODE:
      state = {
        ...state,
        loading: true,
        promoCodeError: null,
        message: null,
      }
      break

    case CREATE_PROMOCODE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        promoCodeError: null,
        message: `${action.payload.name}  created successfully`,
      }
      break

    case FETCH_PROMOCODE_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      }

    case CREATE_PROMOCODE_FAILED:
    case DELETE_PROMOCODE_FAILED:
    case FETCH_EACH_PROMOCODE_API_FAILED:
      state = {
        ...state,
        promoCode: null,
        promoHistory: null,
        promoCodePreview: null,
        loading: false,
        message: null,
        promoCodeError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      }
      break

    case FETCH_PROMOHISTORY_API_FAILED:
    case FETCH_PROMOREDEEMHISTORY_API_FAILED:
      state = {
        ...state,
        promoHistory: null,
        loading: false,
        message: null,
        promoHistoryError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      }
      break

    case UPDATE_PROMOCODE_FAILED:
      state = {
        ...state,
        loading: false,
        promoCodeError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default PromoCode
