export const IS_BANK_AUTHORIZE = 'IS_BANK_AUTHORIZE';

export const FETCH_BANK = 'FETCH_BANK';
export const FETCH_BANK_SUCCESSFUL = 'FETCH_BANK_SUCCESSFUL';
export const FETCH_BANK_API_FAILED = 'FETCH_BANK_API_FAILED';

// BANK Summary
export const FETCH_BANK_SUMMARY = 'FETCH_BANK_SUMMARY';
export const FETCH_BANK_SUMMARY_FAILED = 'FETCH_BANK_SUMMARY_FAILED';
export const FETCH_BANK_SUMMARY_SUCCESSFULLY =
  'FETCH_BANK_SUMMARY_SUCCESSFULLY';

export const FETCH_BANK_LIST = 'FETCH_BANK_LIST';
export const FETCH_BANK_LIST_SUCCESSFUL = 'FETCH_BANK_LIST_SUCCESSFUL';
export const FETCH_BANK_LIST_API_FAILED = 'FETCH_BANK_LIST_API_FAILED';

export const FETCH_EACH_BANK = 'FETCH_EACH_BANK';
export const FETCH_EACH_BANK_SUCCESSFUL = 'FETCH_EACH_BANK_SUCCESSFUL';
export const FETCH_EACH_BANK_API_FAILED = 'FETCH_EACH_BANK_API_FAILED';

export const DELETE_BANK = 'DELETE_BANK';
export const DELETE_BANK_SUCCESSFUL = 'DELETE_BANK_SUCCESSFULLY';
export const DELETE_BANK_FAILED = 'DELETE_BANK_FAILED';

export const CREATE_BANK = 'CREATE_BANK';
export const CREATE_BANK_SUCCESSFUL = 'CREATE_BANK_SUCCESSFULLY';
export const CREATE_BANK_FAILED = 'CREATE_BANK_FAILED';

export const UPDATE_BANK = 'UPDATE_BANK';
export const UPDATE_BANK_SUCCESSFUL = 'UPDATE_BANK_SUCCESSFULLY';
export const UPDATE_BANK_FAILED = 'FAILED';

export const GET_BANK_RECIPIENT = 'GET_BANK_RECIPIENT';
export const GET_BANK_RECIPIENT_SUCCESSFUL = 'GET_BANK_RECIPIENT_SUCCESSFULLY';
export const GET_BANK_RECIPIENT_FAILED = 'GET_BANK_RECIPIENT_FAILED';

export const FETCH_FOR_BANK_CSV = 'FETCH_FOR_BANK_CSV';
export const FETCH_FOR_BANK_CSV_ERROR = 'FETCH_FOR_BANK_CSV_ERROR';
export const FETCH_FOR_BANK_CSV_SUCCESSFULLY =
  'FETCH_FOR_BANK_CSV_SUCCESSFULLY';
