import {
  FETCH_DASH_STAT,
  FETCH_DASH_STAT_SUCCESSFUL,
  FETCH_DASH_STAT_FAILED,
  FETCH_DASH_EARNING,
  FETCH_DASH_EARNING_SUCCESSFUL,
  FETCH_DASH_EARNING_FAILED,
  FETCH_DASH_REVENUE,
  FETCH_DASH_REVENUE_SUCCESSFUL,
  FETCH_DASH_REVENUE_FAILED,
  FETCH_DASH_TRIP,
  FETCH_DASH_TRIP_SUCCESSFUL,
  FETCH_DASH_TRIP_FAILED,
  FETCH_DASH_RATING,
  FETCH_DASH_RATING_SUCCESSFUL,
  FETCH_DASH_RATING_FAILED,
} from './actionTypes'

export const fetchDashStat = (payload) => {
  return {
    type: FETCH_DASH_STAT,
    payload,
  }
}

export const fetchDashStatSuccessful = (payload) => {
  return {
    type: FETCH_DASH_STAT_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchDashStatError = (error) => {
  return {
    type: FETCH_DASH_STAT_FAILED,
    payload: error,
  }
}

export const fetchDashEarning = (payload) => {
  return {
    type: FETCH_DASH_EARNING,
    payload,
  }
}

export const fetchDashEarningSuccessful = (payload) => {
  return {
    type: FETCH_DASH_EARNING_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchDashEarningError = (error) => {
  return {
    type: FETCH_DASH_EARNING_FAILED,
    payload: error,
  }
}

export const fetchDashRevenue = (payload) => {
  return {
    type: FETCH_DASH_REVENUE,
    payload,
  }
}

export const fetchDashRevenueSuccessful = (payload) => {
  return {
    type: FETCH_DASH_REVENUE_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchDashRevenueError = (error) => {
  return {
    type: FETCH_DASH_REVENUE_FAILED,
    payload: error,
  }
}

export const fetchDashTrip = (payload) => {
  return {
    type: FETCH_DASH_TRIP,
    payload,
  }
}

export const fetchDashTripSuccessful = (payload) => {
  return {
    type: FETCH_DASH_TRIP_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchDashTripError = (error) => {
  return {
    type: FETCH_DASH_TRIP_FAILED,
    payload: error,
  }
}

export const fetchDashRating = (payload) => {
  return {
    type: FETCH_DASH_RATING,
    payload,
  }
}

export const fetchDashRatingSuccessful = (payload) => {
  return {
    type: FETCH_DASH_RATING_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchDashRatingError = (error) => {
  return {
    type: FETCH_DASH_RATING_FAILED,
    payload: error,
  }
}
