import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  FETCH_DASH_STAT,
  FETCH_DASH_EARNING,
  FETCH_DASH_REVENUE,
  FETCH_DASH_TRIP,
  FETCH_DASH_RATING,
} from './actionTypes';
import {
  fetchDashStatSuccessful,
  fetchDashStatError,
  fetchDashEarningSuccessful,
  fetchDashEarningError,
  fetchDashRevenueSuccessful,
  fetchDashRevenueError,
  fetchDashTripSuccessful,
  fetchDashTripError,
  fetchDashRatingSuccessful,
  fetchDashRatingError,
} from './actions';

import {
  fetchDashStatService,
  fetchDashEarningService,
  fetchDashRevenueService,
} from '../../services/dashboardServices';

function* fetchDashStatHandler({ payload }) {
  try {
    const response = yield call(fetchDashStatService, payload);
    yield put(fetchDashStatSuccessful(response.data?.result));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchDashStatError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchDashStatError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchDashStatError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchDashEarningHandler({ payload }) {
  try {
    const response = yield call(fetchDashEarningService, payload);
    yield put(fetchDashEarningSuccessful(response.data?.result));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchDashEarningError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchDashEarningError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchDashEarningError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchDashRevenueHandler({ payload }) {
  try {
    const response = yield call(fetchDashRevenueService, payload);
    yield put(fetchDashRevenueSuccessful(response.data?.result));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchDashRevenueError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchDashRevenueError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchDashRevenueError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchDashTripHandler({ payload }) {
  try {
    const response = yield call(fetchDashStatService, payload);
    yield put(fetchDashTripSuccessful(response.data?.result));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchDashTripError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchDashTripError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchDashTripError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchDashRatingHandler({ payload }) {
  try {
    const response = yield call(fetchDashStatService, payload);
    yield put(fetchDashRatingSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchDashRatingError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchDashRatingError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchDashRatingError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

export function* watchFetchDashStat() {
  yield takeEvery(FETCH_DASH_STAT, fetchDashStatHandler);
}

export function* watchFetchDashEarning() {
  yield takeEvery(FETCH_DASH_EARNING, fetchDashEarningHandler);
}

export function* watchFetchDashRevenue() {
  yield takeEvery(FETCH_DASH_REVENUE, fetchDashRevenueHandler);
}

export function* watchFetchDashTrip() {
  yield takeEvery(FETCH_DASH_TRIP, fetchDashTripHandler);
}

export function* watchFetchDashRating() {
  yield takeEvery(FETCH_DASH_RATING, fetchDashRatingHandler);
}

function* dashboardSaga() {
  yield all([fork(watchFetchDashStat)]);
  yield all([fork(watchFetchDashEarning)]);
  yield all([fork(watchFetchDashRevenue)]);
  yield all([fork(watchFetchDashTrip)]);
  yield all([fork(watchFetchDashRating)]);
}

export default dashboardSaga;
