import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  Container,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Moment from 'moment';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import CheckAction from '../../../components/Common/Summary/actionCheck';
import {
  fetchTemplate,
  fetchEachTemplate,
  createTemplate,
  createTemplateFailed,
  updateTemplate,
  deleteTemplate,
} from '../../../store/actions';
import CreateNotification from './createNotification';

const Notification = ({
  createTemplate,
  createTemplateFailed,
  fetchTemplate,
  fetchEachTemplate,
  updateTemplate,
  deleteTemplate,
  template,
  templates,
  templateLoading,
  templateMessage,
  templateError,
}) => {
  const [take, setTake] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [Error, setError] = useState(false);
  const [data, setData] = useState({ title: '', message: '' });
  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'Push Notification', link: '#' },
  ];
  useEffect(() => {
    setMessage('');
    if (template) {
      setMessage(template.messageBody);
    }
  }, [template]);

  useEffect(() => {
    fetchTemplate();
  }, []);

  const handleSubmit = (_event, values) => {
    setError(false);
    if (!message) {
      setError('The message is required');
      return;
    }
    const data = {
      title: values.title,
      messageBody: message,
    };

    if (template) {
      updateTemplate(data, template.id);
      return;
    }
    createTemplate(data);
  };

  const handleViewMore = (e) => {
    setTake(take + 10);
    fetchTemplate({ take: take + 10 });
  };
  const handleEdit = (e) => {
    fetchEachTemplate(e);
    setShowModal(true);
  };

  useEffect(() => {
    if (templateError || templateMessage) {
      if (
        templateError !==
        'Access to this page is restricted to authorized users only'
      ) {
        setTimeout(() => {
          createTemplateFailed('');
          setShowModal(false);
          fetchTemplate();
        }, 2000);
      }
    }
  }, [templateError, templateMessage]);
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Notification' breadcrumbItems={breadcrumbItems} />
          <Row>
            <CreateNotification data={data} />

            <Col md={6}>
              {templateMessage && (
                <Alert color='success' className='text-center'>
                  {templateMessage}
                </Alert>
              )}
              {templateError ? (
                <Alert className='text-center' color='danger'>
                  {templateError}
                </Alert>
              ) : (
                <Card>
                  <CardHeader className='bg-light'>
                    <CheckAction
                      field='Custom Notification'
                      subModule='Push Notification'
                      iconType={'Create'}
                    >
                      <Button
                        size='sm'
                        color='success'
                        className='float-right'
                        onClick={() => setShowModal(true)}
                      >
                        New Template
                      </Button>
                    </CheckAction>
                  </CardHeader>
                  <CardBody className='py-1'>
                    {templates &&
                      templates?.result !== '' &&
                      templates?.count ? (
                      <SimpleBar style={{ maxHeight: '440px' }}>
                        <Row>
                          <Col>
                            {templates?.result?.map((temp) => (
                              <div
                                className='border rounded p-2 px-4 mb-3'
                                style={{
                                  background: '#ebf3f1',
                                  cursor: 'pointer',
                                }}
                                key={temp.id}
                                onClick={() =>
                                  setData({
                                    title: temp?.title,
                                    message: temp?.messageBody,
                                  })
                                }
                              >
                                <div className='d-flex justify-content-between align-items-center'>
                                  <h4 className='card-title text-muted'>
                                    {temp?.title}
                                  </h4>
                                  <div>
                                    <CheckAction
                                      field='Custom Notification'
                                      subModule='Push Notification'
                                      iconType={'Edit'}
                                    >

                                      <Link
                                        to={`#`}
                                        className='mr-3 text-primary'
                                        id='edit1'
                                        onClick={() => handleEdit(temp.id)}
                                      >
                                        <i className='mdi mdi-pencil font-size-16'></i>
                                      </Link>
                                      <UncontrolledTooltip
                                        placement='top'
                                        target='edit1'
                                      >
                                        Edit
                                      </UncontrolledTooltip>
                                    </CheckAction>
                                    <CheckAction
                                      field='Custom Notification'
                                      subModule='Push Notification'
                                      iconType={'Delete'}
                                    >
                                      <Link
                                        to='#'
                                        onClick={() => deleteTemplate(temp.id)}
                                        className='text-danger'
                                        id='delete1'
                                      >
                                        <i className='mdi mdi-trash-can font-size-16'></i>
                                      </Link>
                                      <UncontrolledTooltip
                                        placement='top'
                                        target='delete1'
                                      >
                                        Delete
                                      </UncontrolledTooltip>

                                    </CheckAction>
                                  </div>
                                </div>

                                <hr className='my-2' />
                                <div>
                                  <p className='text-muted mb-1'>
                                    {temp?.messageBody}{' '}
                                  </p>
                                  <span className='badge badge-soft-secondary font-size-10'>
                                    {Moment(temp?.createdAt).format(
                                      'MMMM Do YYYY, h:mm:ss a'
                                    )}
                                  </span>
                                </div>
                              </div>
                            ))}
                            {templateLoading ? (
                              <Table
                                hover
                                className='mt-3 border rounded mb-0 table-centered table-nowrap'
                                style={{ background: '#ebf3f1' }}
                              >
                                <tbody>
                                  {[1]?.map((data, key) => {
                                    return (
                                      <tr key={key}>
                                        <td style={{ width: '90%' }}>
                                          <h5 className='font-size-14 mb-2'>
                                            <Skeleton
                                              duration={2}
                                              width='80%'
                                            />
                                          </h5>
                                          <span className='text-muted mb-2'>
                                            <Skeleton
                                              duration={2}
                                              width='50%'
                                            />
                                          </span>
                                          <span className='text-muted mb-2'>
                                            <Skeleton
                                              duration={2}
                                              width='70%'
                                            />
                                          </span>
                                          <hr className='my-2' />
                                          <h5 className='font-size-14  mb-2'>
                                            <Skeleton
                                              duration={2}
                                              width='80%'
                                            />
                                          </h5>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            ) : (
                              templates?.count > 10 && (
                                <Button
                                  color='primary'
                                  size='sm'
                                  className='w-100 my-2'
                                  onClick={handleViewMore}
                                >
                                  {' '}
                                  View More
                                </Button>
                              )
                            )}
                          </Col>
                        </Row>
                      </SimpleBar>
                    ) : (
                      <p className='text-center font-weight-bold font-size-16'>
                        No templates created
                      </p>
                    )}
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
        <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
          <ModalHeader toggle={() => setShowModal(false)}>
            {template ? 'Update' : 'New'} Template
          </ModalHeader>
          {Error && (
            <Alert color='danger' className='text-center'>
              {Error}
            </Alert>
          )}
          {templateMessage && (
            <Alert color='success' className='text-center'>
              {templateMessage}
            </Alert>
          )}
          {templateError && (
            <Alert className='text-center' color='danger'>
              {templateError}
            </Alert>
          )}
          <ModalBody>
            <AvForm className='form-horizontal' onValidSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <FormGroup className=' mb-4'>
                    <Label htmlFor='provider'>Push Title</Label>
                    <AvField
                      name='title'
                      type='text'
                      className='form-control bg-light'
                      id='title'
                      placeholder='Push Title ...'
                      value={template ? template?.title : ''}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label htmlFor='description'> Message</Label>
                    <textarea
                      className='form-control'
                      onChange={(e) => setMessage(e.target.value)}
                      id='message'
                      placeholder='Enter Message'
                      rows='5'
                      value={message}
                      required
                    ></textarea>
                  </FormGroup>
                </Col>
              </Row>
              <button className='btn btn-success mr-2 btn-sm' type='submit'>
                {templateLoading ? 'Submitting ...' : 'Submit'}
              </button>
              <Link to='#' onClick={() => setShowModal(false)}>
                <button className='btn btn-secondary btn-sm'>Close</button>
              </Link>
            </AvForm>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    template,
    templates,
    templateLoading,
    templateMessage,
    templateError,
  } = state.Notifications;
  return {
    template,
    templates,
    templateLoading,
    templateMessage,
    templateError,
  };
};

export default connect(mapStateToProps, {
  fetchTemplate,
  fetchEachTemplate,
  createTemplate,
  createTemplateFailed,
  updateTemplate,
  deleteTemplate,
})(Notification);
