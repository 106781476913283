import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Form from './form';

import { fetchEachApp_version } from '../../../../../store/actions';

const App_versionForm = ({
  fetchEachApp_version,
  app_version,
  loading,
  message,
}) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  useEffect(() => {
    if (id) {
      fetchEachApp_version(id);
    }
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Form
          loading={loading}
          defaultValue={app_version ? app_version : null}
          message={message}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { app_version, loading, app_versionError, message } = state.App_version;
  return { app_version, loading, app_versionError, message };
};

export default connect(mapStateToProps, { fetchEachApp_version })(
  App_versionForm
);
