/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Row, Col, Table } from 'reactstrap';
import Loader from '../../../components/Common/Loading/index.js';
import { fetchEachReward } from '../../../store/actions';
import Moment from 'moment';

const Reward = ({
  rewardPreview,
  previewId,
  loading,
  rewardError,
  fetchEachReward,
  historyViewer,
}) => {
  useEffect(() => {
    fetchEachReward(previewId);
  }, [fetchEachReward, previewId]);

  return (
    <React.Fragment>
      <div className='mt-1'>
        {rewardError && !loading && (
          <Alert color='danger' className='text-center'>
            {rewardError}
          </Alert>
        )}
        {!rewardPreview && loading ? (
          <Loader />
        ) : (
          <Row>
            <Col sm={12} className='text-center'>
              <div className='d-flex justify-content-between pb-2'>
                <span
                  className={`badge badge-soft-${
                    new Date(rewardPreview?.expiry) <= new Date()
                      ? 'danger'
                      : 'success'
                  }`}
                >
                  {Moment(rewardPreview?.expiry).format('lll')}
                </span>
                <span
                  className={`badge badge-soft-${
                    rewardPreview?.status.toLowerCase() === 'active'
                      ? 'success'
                      : rewardPreview?.status.toLowerCase() === 'inactive'
                      ? 'danger'
                      : 'warning'
                  }`}
                >
                  {rewardPreview?.status === 'COMINGSOON'
                    ? 'COMING SOON'
                    : rewardPreview?.status}
                </span>
              </div>
              {rewardPreview?.imageUrl && (
                <div
                  className=' my-0 mx-auto rounded w-auto p-2 shadow'
                  style={{ maxWidth: 'fit-content', background: '#F1F8E9' }}
                >
                  <img
                    src={rewardPreview?.imageUrl}
                    alt='package'
                    className='avatar-sm'
                  />
                </div>
              )}
              <div>
                <h4 className='card-title'> {rewardPreview?.code} </h4>
                <div className='text-muted'>{rewardPreview?.description}</div>
              </div>
            </Col>
            <Col sm={12}>
              <div>
                <Table hover>
                  <tbody>
                    <tr>
                      <td className='font-weight-bold'>Name</td>
                      <td className='text-uppercase'>{rewardPreview?.name}</td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Category</td>
                      <td>{rewardPreview?.category}</td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Value</td>
                      <td> {rewardPreview?.value}</td>
                    </tr>
                    {rewardPreview?.point && (
                      <tr>
                        <td className='font-weight-bold'>Point</td>
                        <td> {rewardPreview?.point}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <div className='text-center'>
                <h4 className='card-title'> Packages: </h4>
                {rewardPreview?.packages && rewardPreview?.packages.length !== 0
                  ? rewardPreview?.packages.map((data) => (
                      <span
                        key={data?.id}
                        className='badge badge-secondary rounded mr-2 mb-1'
                      >
                        {' '}
                        #{data?.name}
                      </span>
                    ))
                  : 'No Packages'}

                <Link to={`/reward_history/${previewId}`}>
                  <button className='btn btn-primary mt-4 w-100 btn-sm'>
                    Show Redeem History
                  </button>
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { rewardPreview, loading, rewardError } = state.Reward;
  return { rewardPreview, loading, rewardError };
};

export default connect(mapStateToProps, { fetchEachReward })(Reward);
