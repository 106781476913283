export const IS_LOYALTYTIER_AUTHORIZE = 'IS_LOYALTYTIER_AUTHORIZE';
// LOYALTYTIER Summary
export const FETCH_LOYALTYTIER_SUMMARY = 'FETCH_LOYALTYTIER_SUMMARY';
export const FETCH_LOYALTYTIER_SUMMARY_FAILED =
  'FETCH_LOYALTYTIER_SUMMARY_FAILED';
export const FETCH_LOYALTYTIER_SUMMARY_SUCCESSFULLY =
  'FETCH_LOYALTYTIER_SUMMARY_SUCCESSFULLY';

export const FETCH_LOYALTYTIER = 'FETCH_LOYALTYTIER';
export const FETCH_LOYALTYTIER_SUCCESSFUL = 'FETCH_LOYALTYTIER_SUCCESSFUL';
export const FETCH_LOYALTYTIER_API_FAILED = 'FETCH_LOYALTYTIER_API_FAILED';

export const FETCH_EACH_LOYALTYTIER = 'FETCH_EACH_LOYALTYTIER';
export const FETCH_EACH_LOYALTYTIER_SUCCESSFUL =
  'FETCH_EACH_LOYALTYTIER_SUCCESSFUL';
export const FETCH_EACH_LOYALTYTIER_API_FAILED =
  'FETCH_EACH_LOYALTYTIER_API_FAILED';

export const FETCH_MY_LOYALTYTIER = 'FETCH_MY_LOYALTYTIER';
export const FETCH_MY_LOYALTYTIER_SUCCESSFUL =
  'FETCH_MY_LOYALTYTIER_SUCCESSFUL';
export const FETCH_MY_LOYALTYTIER_API_FAILED =
  'FETCH_MY_LOYALTYTIER_API_FAILED';

export const DELETE_LOYALTYTIER = 'DELETE_LOYALTYTIER';
export const DELETE_LOYALTYTIER_SUCCESSFUL = 'DELETE_LOYALTYTIER_SUCCESSFULLY';
export const DELETE_LOYALTYTIER_FAILED = 'DELETE_LOYALTYTIER_FAILED';

export const CREATE_LOYALTYTIER = 'CREATE_LOYALTYTIER';
export const CREATE_LOYALTYTIER_SUCCESSFUL = 'CREATE_LOYALTYTIER_SUCCESSFULLY';
export const CREATE_LOYALTYTIER_FAILED = 'CREATE_LOYALTYTIER_FAILED';

export const UPDATE_LOYALTYTIER = 'UPDATE_LOYALTYTIER';
export const UPDATE_LOYALTYTIER_SUCCESSFUL = 'UPDATE_LOYALTYTIER_SUCCESSFULLY';
export const UPDATE_LOYALTYTIER_FAILED = 'FAILED';
