import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { MultiSelect } from "react-multi-select-component";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  createBusStop,
  createBusStopFailed,
  updateBusStop,
  updateBusStopFailed,
} from "../../../../store/actions";
import { getS3ImageUrlHandler } from "../../../../utils/general";

const apiKey = process.env.REACT_APP_GOOGLE_KEY;

function Form({
  createBusStop,
  createBusStopFailed,
  updateBusStop,
  updateBusStopFailed,
  message,
  history,
  busStopError,
  defaultValue,
  loading,
}) {
  const [errorMessage, setError] = useState("");
  const [description, setDescription] = useState(" ");
  const [selectedImage, setImageUpload] = useState([]);
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [center, setCenter] = useState({ lat: 9.072264, lng: 7.491302 });
  const [markerPosition, setMarkerPosition] = useState(center);
  const [position, setPosition] = useState({});
  const [selected, setSelected] = useState([]);
  const [keywordOption, SetKeywordOption] = useState([""]);

  useEffect(() => {
    initializeFormValues();
    if (defaultValue) {
      setDescription(defaultValue.description);
      setImage(defaultValue.image);
      const keywordsArr = [];
      if (defaultValue.keywords) {
        defaultValue.keywords.forEach((element) => {
          const pkgObj = {};
          pkgObj.value = element;
          pkgObj.label = element;
          keywordsArr.push(pkgObj);
        });
      }
      setSelected(keywordsArr);
      SetKeywordOption(keywordsArr.concat([{ value: "", label: "" }]));
      setPosition({
        address: defaultValue.address,
        country: defaultValue.country,
        state: defaultValue.state,
        lat: Number(defaultValue.lat),
        lng: Number(defaultValue.lng),
      });
      setMarkerPosition({
        lat: Number(defaultValue.lat),
        lng: Number(defaultValue.lng),
      });
      setCenter({
        lat: Number(defaultValue.lat),
        lng: Number(defaultValue.lng),
      });
    }
    if (message) {
      setTimeout(() => {
        createBusStopFailed("");
        createBusStopFailed("");
        history.goBack();
      }, 2000);
    }
  }, [defaultValue, message]);

  useEffect(() => {
    createBusStopFailed("");
  }, []);

  const initializeFormValues = () => {
    setDescription("");
    setImage("");
    setPosition({});
    SetKeywordOption([]);
  };

  const handleMarkerDrag = (e) => {
    const { latLng } = e;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setMarkerPosition({ lat: Number(lat), lng: Number(lng) });
    fetchAddressFromCoordinates(Number(lat), Number(lng));
  };

  const fetchAddressFromCoordinates = async (lat, lng, add = true) => {
    try {
      const response = await fetch(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=en`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (!position.address) {
        setPosition({
          address: data.locality,
          country: data.countryName,
          state: data.principalSubdivision,
          lat,
          lng,
        });
      } else {
        setPosition({
          ...position,
          country: data.countryName,
          state: data.principalSubdivision,
          lat,
          lng,
        });
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };
  const handleMarkerPosition = () => {
    if (position.lat && position.lng) {
      fetchAddressFromCoordinates(
        Number(position.lat),
        Number(position.lng),
        "false"
      );
      setMarkerPosition({
        lat: Number(position.lat),
        lng: Number(position.lng),
      });
      setCenter({
        lat: Number(position.lat) + 0.00001,
        lng: Number(position.lng) + 0.00001,
      });

      return;
    }
    setError("Location coordinate (lat and lng) is required");
    return;
  };

  const handleSubmit = async (event, values) => {
    let imageResult;
    const busStopData = { ...values };

    if (selectedImage.length !== 0) {
      imageResult = await getS3ImageUrlHandler(selectedImage[0], "busStop");
      busStopData.image = imageResult?.data.result?.mediaUrl;
      busStopData.imageThumb = imageResult?.data?.result.mediaThumbUrl;
    }
    if (selected.length > 0) {
      busStopData.keywords = [];
      busStopData.keywords = selected.map((val) => val.value);
    }
    busStopData.description = description;
    busStopData.address = position.address;
    busStopData.state = position.state;
    busStopData.country = position.country;
    busStopData.lat = `${position.lat}`;
    busStopData.lng = `${position.lng}`;
    busStopData.tag = values.tag;
    busStopData.name = values.name;

    if (defaultValue) {
      busStopData.id = defaultValue.id;
      updateBusStop(busStopData);
    } else {
      createBusStop(busStopData);
    }
  };

  const imageHandler = (e) => {
    const files = e.target.files;
    if (files) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
      setImageUpload(files);
      setImageName(files[0].name);
    }
  };
  useEffect(() => {
    if (markerPosition !== center) {
      if (defaultValue) {
        fetchAddressFromCoordinates(
          markerPosition.lat,
          markerPosition.lng,
          "false"
        );
      } else {
        fetchAddressFromCoordinates(markerPosition.lat, markerPosition.lng);
      }
    } else {
      setPosition({
        address: "",
        country: "",
        state: "",
        lat: "",
        lng: "",
      });
    }
  }, [markerPosition]);

  return (
    <Card className="col-md-12">
      <CardBody>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        {busStopError && (
          <Alert color="danger" className="text-center">
            {busStopError}
          </Alert>
        )}
        {errorMessage && (
          <Alert color="danger" className="text-center">
            {errorMessage}
          </Alert>
        )}

        <div className="float-right">
          <Link to="#" onClick={() => history.goBack()}>
            <i className="fas fa-arrow-left mr-3" />
            Back
          </Link>
        </div>

        <div className="mb-3">
          <h6>{defaultValue ? "Update" : "Create"} Bus Stop</h6>
        </div>

        <div className="col-md-12 pl-0">
          <Row>
            <Col md={6}>
              <AvForm
                className="form-horizontal"
                onValidSubmit={handleSubmit}
                model={defaultValue ? defaultValue : {}}
              >
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="name">Name</Label>
                      <AvField
                        name="name"
                        type="text"
                        className="form-control bg-light"
                        id="name"
                        value={defaultValue ? defaultValue.name : ""}
                        required
                        placeholder="Enter Bus Stop Name ..."
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="tag">Tag</Label>
                      <AvField
                        name="tag"
                        type="text"
                        className="form-control bg-light"
                        id="tag"
                        value={defaultValue ? defaultValue.tag : ""}
                        required
                        placeholder="Enter Bus Stop Tag ..."
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="country">Country</Label>
                          <AvField
                            name="country"
                            type="text"
                            className="form-control bg-light"
                            id="country"
                            value={
                              markerPosition !== center ? position.country : ""
                            }
                            onChange={(e) =>
                              setPosition({
                                ...position,
                                country: e.target.value,
                              })
                            }
                            required
                            placeholder="Enter the Country ..."
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="state">State</Label>
                          <AvField
                            name="state"
                            type="text"
                            className="form-control bg-light"
                            id="state"
                            value={
                              markerPosition !== center ? position.state : ""
                            }
                            onChange={(e) =>
                              setPosition({
                                ...position,
                                state: e.target.value,
                              })
                            }
                            required
                            placeholder="Enter the state ..."
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="lat">Latitude</Label>
                          <AvField
                            name="lat"
                            type="text"
                            className="form-control bg-light"
                            id="lat"
                            value={
                              markerPosition !== center
                                ? markerPosition.lat
                                : position.lat
                            }
                            onChange={(e) =>
                              setPosition((prevPosition) => ({
                                ...prevPosition,
                                lat: e.target.value,
                              }))
                            }
                            required
                            placeholder="Enter the latitude ..."
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="lng">Longitude</Label>
                          <AvField
                            name="lng"
                            type="text"
                            className="form-control bg-light"
                            id="lng"
                            value={
                              markerPosition !== center
                                ? markerPosition.lng
                                : position.lng
                            }
                            onChange={(e) =>
                              setPosition({ ...position, lng: e.target.value })
                            }
                            required
                            placeholder="Enter the lng ..."
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="address">Address</Label>
                          <AvField
                            name="address"
                            type="text"
                            className="form-control bg-light"
                            id="address"
                            value={
                              markerPosition !== center ? position.address : ""
                            }
                            onChange={(e) =>
                              setPosition({
                                ...position,
                                address: e.target.value,
                              })
                            }
                            required
                            placeholder="Enter Bus Stop Address ..."
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="">
                          <Label htmlFor="keywords"> Keywords</Label>
                          <MultiSelect
                            options={selected ? keywordOption : []}
                            value={selected}
                            onChange={setSelected}
                            labelledBy="Selected"
                            isCreatable={true}
                            hasSelectAll={false}
                            overrideStrings={{
                              selectSomeItems:
                                "Enter the Keywords of this Tier...",
                              create: "Click to add",
                              search: "Input keywords here",
                            }}
                            isMulti
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup className=" mb-4">
                      <Label>Image</Label>
                      <div className={image ? "d-flex" : null}>
                        <div>
                          <div className="mb-3 input-group">
                            <div className="custom-file">
                              <input
                                type="file"
                                accept="image/*"
                                name="image-upload"
                                className="custom-file-input"
                                id="image"
                                aria-describedby="image"
                                onChange={(e) => imageHandler(e)}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="image"
                              >
                                Choose file ...
                              </label>
                            </div>
                          </div>
                          <span className="font-italic">{imageName}</span>
                        </div>
                        {image ? (
                          <img
                            data-dz-thumbnail=""
                            height="100"
                            className="rounded bg-light ml-3"
                            style={{ width: "40%" }}
                            alt={imageName}
                            src={image}
                          />
                        ) : null}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label htmlFor="description">Description</Label>
                      <textarea
                        className="form-control"
                        onChange={(e) => setDescription(e.target.value)}
                        id="description"
                        value={description}
                        rows="5"
                        // ref={descriptionRef}
                      ></textarea>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="d-flex justify-content-between alihn-items-center">
                  <div>
                    <button className="btn btn-success mr-2" type="submit">
                      {loading
                        ? "Submitting ..."
                        : `${defaultValue ? "Update" : "Create"}`}
                    </button>
                    <Link to="#" onClick={() => history.goBack()}>
                      <button className="btn btn-secondary">Cancel</button>
                    </Link>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-info btn-sm"
                      onClick={() => handleMarkerPosition()}
                    >
                      Show on Map
                    </button>
                  </div>
                </div>
              </AvForm>
            </Col>
            <Col md={6} className="mb-5 mt-3">
              <LoadScript googleMapsApiKey={apiKey}>
                <GoogleMap
                  mapContainerStyle={{ height: "100%" }}
                  center={center}
                  zoom={15}
                >
                  <Marker
                    position={markerPosition}
                    draggable={true}
                    onDragEnd={handleMarkerDrag}
                  />
                </GoogleMap>
              </LoadScript>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
}

const mapStateToProps = (state) => {
  const { message, loading, busStopError } = state.BusStop;
  return { message, loading, busStopError };
};

export default connect(mapStateToProps, {
  createBusStop,
  createBusStopFailed,
  updateBusStop,
  updateBusStopFailed,
})(withRouter(Form));
