import {
  FETCH_REVIEWS,
  FETCH_REVIEWS_SUCCESSFUL,
  FETCH_REVIEWS_API_FAILED,
  FETCH_DRIVER_REVIEWS,
  FETCH_DRIVER_REVIEWS_SUCCESSFUL,
  FETCH_DRIVER_REVIEWS_API_FAILED,
  FETCH_EACH_REVIEW,
  FETCH_EACH_REVIEW_SUCCESSFUL,
  FETCH_EACH_REVIEW_API_FAILED,
  FETCH_REVIEW_SUMMARY,
  FETCH_REVIEW_SUMMARY_SUCCESSFULLY,
  FETCH_REVIEW_SUMMARY_FAILED,
} from './actionTypes';

export const fetchReviews = (payload) => {
  return {
    type: FETCH_REVIEWS,
    payload,
  };
};

export const fetchReviewsSuccessful = (payload) => {
  return {
    type: FETCH_REVIEWS_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchReviewsError = (error) => {
  return {
    type: FETCH_REVIEWS_API_FAILED,
    payload: error,
  };
};

export const fetchDriverReviews = (payload) => {
  return {
    type: FETCH_DRIVER_REVIEWS,
    payload,
  };
};

export const fetchDriverReviewsSuccessful = (payload) => {
  return {
    type: FETCH_DRIVER_REVIEWS_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchDriverReviewsError = (error) => {
  return {
    type: FETCH_DRIVER_REVIEWS_API_FAILED,
    payload: error,
  };
};

export const fetchEachReview = (id) => {
  return {
    type: FETCH_EACH_REVIEW,
    payload: id,
  };
};

export const fetchEachReviewSuccessful = (payload) => {
  return {
    type: FETCH_EACH_REVIEW_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachReviewError = (error) => {
  return {
    type: FETCH_EACH_REVIEW_API_FAILED,
    payload: error,
  };
};

//SUMMARY ENTRIES
export const fetchReviewSummary = (query) => {
  return {
    type: FETCH_REVIEW_SUMMARY,
    payload: query,
  };
};

export const fetchReviewSummarySuccessfully = (payload) => {
  return {
    type: FETCH_REVIEW_SUMMARY_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchReviewSummaryFailed = (error) => {
  return {
    type: FETCH_REVIEW_SUMMARY_FAILED,
    payload: error,
  };
};
