import React, { Component } from 'react';
import AlartError from '../../../../components/Common/AlertCard';
import {
  Row,
  Col,
  Button,
  Alert,
  Card,
  CardBody,
  Container,
  Label,
  FormGroup,
} from 'reactstrap';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import Recaptcha from 'react-google-invisible-recaptcha';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from '../../../../store/actions';

import RegistrationMessage from './RegistrationMessage';

// Redux
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import images
import logosmdark from '../../../../assets/images/nrydeLogo.png';

// const REGEXP = /[\+]\d{3}[\(]\d{2}[\)]\d{4}[\-]\d{4}/;
//eslint-disable-next-line
const REGEXP = /[\+]\d{3}\d{2}\d{4}\d{4}/;
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      phone: '',
      notification: { title: '', body: '' },
      dob: '',
      messageSent: false,
      formValue: null,
      loading: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event, values) {
    this.recaptcha.execute();
    const cloneData = { ...values };
    cloneData.userType = 'ADMIN';
    cloneData.lat = this.props.geoLocation.lat;
    cloneData.lng = this.props.geoLocation.lng;
    this.setState({
      ...this.state,
      formValue: cloneData,
    });
  }

  componentDidMount() {
    this.props.registerUserFailed('');
    this.props.apiError('');
    document.body.classList.add('auth-body-bg');
    // const location = Location();
    let params = queryString.parse(this.props.location.search);
    let newParams = {};
    if (params.email || params.firstName || params.lastName) {
      if (params.email) {
        newParams.email = params.email;
      }
      if (params.firstName) {
        newParams.firstName = params.firstName;
      }
      if (params.lastName) {
        newParams.lastName = params.lastName;
      }
      this.setState({
        ...this.state,
        ...newParams,
        loading: false,
      });
    } else {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  }

  onResolved = () => {
    this.setState({ messageSent: true });
    this.props.registerUser(this.state.formValue);
  };

  render() {
    if (this.props.isRegistered) {
      return <RegistrationMessage />;
    }
    return (
      <React.Fragment>
        <div>
          {!this.state.loading && (
            <Container fluid className="p-0 mt-1">
              <Row className="no-gutters">
                <Col lg={12}>
                  <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                    <div className="w-100">
                      <Row className="justify-content-center">
                        <Col lg={6}>
                          <Card className="shadow-lg">
                            <CardBody>
                              <div>
                                <div className="text-center">
                                  <div>
                                    <Link to="#" className="logo">
                                      <img
                                        src={logosmdark}
                                        height="50"
                                        alt="logo"
                                      />
                                    </Link>
                                  </div>

                                  <h4 className="font-size-18 mt-4">
                                    Register account
                                  </h4>
                                  <p className="text-muted">
                                    Get your free nRyde account now.
                                  </p>
                                </div>

                                {/* {this.props.user && (
                            <Alert color="success">
                              Registration Done Successfully.
                            </Alert>
                          )} */}

                                {this.props.registrationError && (
                                  <AlartError
                                    error={this.props.registrationError}
                                  />
                                )}
                                {this.props.locationErr && (
                                  <Alert className='text-center' color='danger'>
                                    {this.props.locationErr}
                                  </Alert>
                                )}
                                <div className="p-2 mt-3">
                                  <AvForm
                                    onValidSubmit={this.handleSubmit}
                                    className="form-horizontal"
                                  >
                                    <Row>
                                      <Col md={6}>
                                        <FormGroup className="auth-form-group-custom mb-4">
                                          <i className="ri-user-2-line auti-custom-input-icon"></i>
                                          <Label htmlFor="firstName">
                                            First Name
                                          </Label>
                                          <AvField
                                            name="firstName"
                                            value={this.state.firstName}
                                            type="text"
                                            className="form-control"
                                            id="firstName"
                                            placeholder="Enter first Name"
                                            required
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={6}>
                                        <FormGroup className="auth-form-group-custom mb-4">
                                          <i className="ri-user-2-line auti-custom-input-icon"></i>
                                          <Label htmlFor="lastName">
                                            Last Name
                                          </Label>
                                          <AvField
                                            name="lastName"
                                            value={this.state.lastName}
                                            type="text"
                                            className="form-control"
                                            id="lastName"
                                            placeholder="Enter Last Name"
                                            required
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                        <FormGroup className="auth-form-group-custom mb-4">
                                          <i className="ri-mail-line auti-custom-input-icon"></i>
                                          <Label htmlFor="useremail">
                                            Email
                                          </Label>
                                          <AvField
                                            name="email"
                                            value={this.state.email}
                                            validate={{
                                              email: true,
                                              required: true,
                                            }}
                                            type="email"
                                            className="form-control"
                                            id="useremail"
                                            placeholder="Enter email"
                                            required
                                          />
                                        </FormGroup>
                                      </Col>

                                      <Col md={6}>
                                        <FormGroup className="auth-form-group-custom mb-4">
                                          <i className="ri-contacts-book-2-line auti-custom-input-icon"></i>
                                          <Label htmlFor="dob">
                                            Date Of Birth
                                          </Label>
                                          <AvField
                                            name="dob"
                                            value={this.state.dob}
                                            type="date"
                                            className="form-control"
                                            id="dob"
                                            placeholder="Enter dob"
                                            required
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={6}>
                                        <FormGroup className="auth-form-group-custom mb-4">
                                          <i className="ri-contacts-book-2-line auti-custom-input-icon"></i>
                                          <Label htmlFor="phone">
                                            Phone Number
                                          </Label>
                                          <AvField
                                            name="phone"
                                            value={this.state.phone}
                                            type="tel"
                                            errorMessage="Please key in a correct phone number"
                                            validate={{
                                              tel: { pattern: REGEXP },
                                            }}
                                            className="form-control"
                                            id="phone"
                                            placeholder="Enter your phone no ..."
                                            required
                                          // helpMessage='Phone number should start with the country code "+2348012345678"'
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={6}>
                                        <FormGroup className="auth-form-group-custom mb-4">
                                          <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                          <Label htmlFor="userpassword">
                                            Password
                                          </Label>
                                          <AvField
                                            name="password"
                                            value={this.state.password}
                                            type="password"
                                            className="form-control"
                                            id="userpassword"
                                            placeholder="Enter password"
                                            required
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={6}>
                                        <FormGroup className="auth-form-group-custom mb-4">
                                          <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                          <Label htmlFor="confirmPassword">
                                            Confirm Password
                                          </Label>
                                          <AvField
                                            name="confirmPassword"
                                            errorMessage="password not the same"
                                            validate={{
                                              match: { value: 'password' },
                                            }}
                                            type="password"
                                            className="form-control"
                                            id="confirmPassword"
                                            placeholder="Confirm password"
                                            required
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>

                                    <div className="text-center">
                                      <Button
                                        color="primary"
                                        className="w-md waves-effect waves-light"
                                        type="submit"
                                      >
                                        {this.props.loading
                                          ? 'Loading ...'
                                          : 'Register'}
                                      </Button>
                                    </div>

                                    <div className="mt-4 text-center">
                                      <p className="mb-0">
                                        By registering you agree to the nRyde{' '}
                                        <Link to="#" className="text-primary">
                                          Terms of Use
                                        </Link>
                                      </p>
                                    </div>
                                  </AvForm>
                                </div>

                                <div className="mt-5 text-center">
                                  <p>
                                    Already have an account ?{' '}
                                    <Link
                                      to="/login"
                                      className="font-weight-medium text-primary"
                                    >
                                      {' '}
                                      Login
                                    </Link>{' '}
                                  </p>
                                  <p>© 2020 nRyde</p>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
              <Recaptcha
                ref={(ref) => (this.recaptcha = ref)}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onResolved={this.onResolved}
              />
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, registrationError, loading, isRegistered } = state.Account;
  return { user, registrationError, loading, isRegistered };
};

export default withRouter(
  connect(mapStateToProps, {
    registerUser,
    apiError,
    registerUserFailed,
  })(Register)
);
