import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  CREATE_PUSH_NOTIFICATION,
  FETCH_PUSH_NOTIFICATION,
  UPDATE_PUSH_NOTIFICATION,
  DELETE_PUSH_NOTIFICATION,
  CREATE_NOTIFICATION,
  FETCH_NOTIFICATION,
  FETCH_MY_NOTIFICATION,
  FETCH_EACH_NOTIFICATION,
  UPDATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  FPN_SUB,
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
  FETCH_TEMPLATE,
  FETCH_EACH_TEMPLATE,
  UPDATE_TEMPLATE,
  FETCH_BETA_EMAILS,
  FETCH_EMAILS_FOR_CSV,
  FETCH_NOTIFICATION_LOG,
  FETCH_EACH_NOTIFICATION_LOG,
} from './actionTypes';

import {
  fetchPushNotificationSuccessful,
  fetchPushNotificationFailed,
  createPushNotificationSuccessful,
  updatePushNotificationSuccessful,
  updatePushNotificationFailed,
  createPushNotificationFailed,
  deletePushNotificationSuccessful,
  deletePushNotificationFailed,
  fetchNotificationSuccessful,
  fetchNotificationFailed,
  fetchMyNotificationSuccessful,
  fetchMyNotificationFailed,
  fetchEachNotificationSuccessful,
  fetchEachNotificationFailed,
  createNotificationSuccessful,
  updateNotificationSuccessful,
  updateNotificationFailed,
  createNotificationFailed,
  deleteNotificationSuccessful,
  deleteNotificationFailed,
  fpnSubSuccessful,
  fpnSubFailed,
  fetchTemplateSuccessful,
  fetchTemplateFailed,
  fetchEachTemplateSuccessful,
  fetchEachTemplateFailed,
  createTemplateSuccessful,
  createTemplateFailed,
  updateTemplateSuccessful,
  updateTemplateFailed,
  deleteTemplateSuccessful,
  deleteTemplateFailed,
  fetchBetaEmailsSuccessful,
  fetchBetaEmailsFailed,
  fetchEmailsForCSVSuccessfully,
  fetchEmailsForCSVError,
  fetchNotificationLogSuccessful,
  fetchNotificationLogFailed,
  fetchEachNotificationLogSuccessful,
  fetchEachNotificationLogFailed,
} from './actions';

import {
  CreatePushNotificationService,
  FetchPushNotificationService,
  UpdatePushNotificationService,
  DeletePushNotificationService,
  CreateNotificationService,
  FetchNotificationService,
  FetchMyNotificationService,
  FetchEachNotificationService,
  UpdateNotificationService,
  DeleteNotificationService,
  FPNSubService,
  CreateTemplateService,
  GetTemplateService,
  GetEachTemplateService,
  UpdateTemplateService,
  DeleteTemplateService,
  GetBetaEmailsService,
  FetchNotificationLogService,
  FetchEachNotificationLogService,
} from '../../services/notificationService';

function* fetchPushNotificationHandler({ payload }) {
  try {
    const response = yield call(FetchPushNotificationService, payload);
    yield put(fetchPushNotificationSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchPushNotificationFailed(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchPushNotificationFailed(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchPushNotificationFailed(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* createPushNotificationHandler({ payload }) {
  try {
    const response = yield call(CreatePushNotificationService, payload);
    yield put(createPushNotificationSuccessful(response.data.result));
  } catch (error) {
    yield put(
      createPushNotificationFailed(
        error?.response?.data?.message.constructor === String
          ? error?.response?.data?.message
          : 'Error in processing data'
      )
    );
  }
}

function* updatePushNotificationHandler({ payload }) {
  try {
    const response = yield call(UpdatePushNotificationService, payload);
    yield put(updatePushNotificationSuccessful(response.data.result));
  } catch (error) {
    yield put(
      updatePushNotificationFailed(
        error?.response?.data?.message.constructor === String
          ? error?.response?.data?.message
          : 'Error in processing data'
      )
    );
  }
}

function* deletePushNotificationHandler({ payload }) {
  try {
    const response = yield call(DeletePushNotificationService, payload);
    yield put(deletePushNotificationSuccessful(response.data.result));
  } catch (error) {
    yield put(
      deletePushNotificationFailed(
        error?.response?.data?.message.constructor === String
          ? error?.response?.data?.message
          : 'Error in processing data'
      )
    );
  }
}

function* fetchNotificationHandler({ payload }) {
  try {
    const response = yield call(FetchNotificationService, payload);
    yield put(fetchNotificationSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchNotificationFailed(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchNotificationFailed(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchNotificationFailed(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchMyNotificationHandler({ payload }) {
  try {
    const response = yield call(FetchMyNotificationService, payload);
    yield put(fetchMyNotificationSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchMyNotificationFailed(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchMyNotificationFailed(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchMyNotificationFailed(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEachNotificationHandler({ payload }) {
  try {
    const response = yield call(FetchEachNotificationService, payload);
    yield put(fetchEachNotificationSuccessful(response.data.result));
  } catch (error) {
    yield put(
      fetchEachNotificationFailed(
        error?.response?.data?.message.constructor === String
          ? error?.response?.data?.message
          : 'Error in processing data'
      )
    );
  }
}

function* createNotificationHandler({ payload }) {
  try {
    const response = yield call(CreateNotificationService, payload);
    yield put(createNotificationSuccessful(response.data.result));
  } catch (error) {
    yield put(
      createNotificationFailed(
        error?.response?.data?.message.constructor === String
          ? error?.response?.data?.message
          : 'Error in processing data'
      )
    );
  }
}

function* updateNotificationHandler({ payload }) {
  try {
    const response = yield call(UpdateNotificationService, payload);
    yield put(updateNotificationSuccessful(response.data.result));
  } catch (error) {
    yield put(
      updateNotificationFailed(
        error?.response?.data?.message.constructor === String
          ? error?.response?.data?.message
          : 'Error in processing data'
      )
    );
  }
}

function* deleteNotificationHandler({ payload }) {
  try {
    const response = yield call(DeleteNotificationService, payload);
    yield put(deleteNotificationSuccessful(response.data.result));
  } catch (error) {
    yield put(
      deleteNotificationFailed(
        error?.response?.data?.message.constructor === String
          ? error?.response?.data?.message
          : 'Error in processing data'
      )
    );
  }
}
function* fpnHandler({ payload }) {
  try {
    const response = yield call(FPNSubService, payload);
    yield put(fpnSubSuccessful(response.data.result));
  } catch (error) {
    yield put(
      fpnSubFailed(
        error?.response?.data?.message.constructor === String
          ? error?.response?.data?.message
          : 'Error in processing data'
      )
    );
  }
}

function* fetchTemplateHandler({ payload }) {
  try {
    const response = yield call(GetTemplateService, payload);
    yield put(fetchTemplateSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchTemplateFailed(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchTemplateFailed(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchTemplateFailed(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEachTemplateHandler({ payload }) {
  try {
    const response = yield call(GetEachTemplateService, payload);
    yield put(fetchEachTemplateSuccessful(response.data.result));
  } catch (error) {
    yield put(
      fetchEachTemplateFailed(
        error?.response?.data?.message.constructor === String
          ? error?.response?.data?.message
          : 'Error in processing data'
      )
    );
  }
}

function* createTemplateHandler({ payload }) {
  try {
    const response = yield call(CreateTemplateService, payload);
    yield put(createTemplateSuccessful(response.data.result));
  } catch (error) {
    yield put(
      createTemplateFailed(
        error?.response?.data?.message.constructor === String
          ? error?.response?.data?.message
          : 'Error in processing data'
      )
    );
  }
}

function* updateTemplateHandler({ payload }) {
  try {
    const response = yield call(UpdateTemplateService, payload);
    yield put(updateTemplateSuccessful(response.data.result));
  } catch (error) {
    yield put(
      updateTemplateFailed(
        error?.response?.data?.message.constructor === String
          ? error?.response?.data?.message
          : 'Error in processing data'
      )
    );
  }
}

function* deleteTemplateHandler({ payload }) {
  try {
    const response = yield call(DeleteTemplateService, payload);
    yield put(deleteTemplateSuccessful(response.data.result));
  } catch (error) {
    yield put(
      deleteTemplateFailed(
        error?.response?.data?.message.constructor === String
          ? error?.response?.data?.message
          : 'Error in processing data'
      )
    );
  }
}

function* fetchBetaEmailsHandler({ payload }) {
  try {
    const response = yield call(GetBetaEmailsService, payload);
    yield put(fetchBetaEmailsSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchBetaEmailsFailed(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchBetaEmailsFailed(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchBetaEmailsFailed(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEmailsForCSVHandler({ payload }) {
  try {
    const response = yield call(GetBetaEmailsService, payload);
    yield put(fetchEmailsForCSVSuccessfully(response.data));
  } catch (error) {
    yield put(fetchEmailsForCSVError(error?.response.data?.message));
  }
}

function* fetchNotificationLogHandler({ payload }) {
  try {
    const response = yield call(FetchNotificationLogService, payload);
    yield put(fetchNotificationLogSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchNotificationLogFailed(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchNotificationLogFailed(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchNotificationLogFailed(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEachNotificationLogHandler({ payload }) {
  try {
    const response = yield call(FetchEachNotificationLogService, payload);
    yield put(fetchEachNotificationLogSuccessful(response.data.result));
  } catch (error) {
    yield put(
      fetchEachNotificationLogFailed(
        error?.response?.data?.message.constructor === String
          ? error?.response?.data?.message
          : 'Error in processing data'
      )
    );
  }
}

export function* watchCreatePushNotification() {
  yield takeEvery(CREATE_PUSH_NOTIFICATION, createPushNotificationHandler);
}
export function* watchFetchPushNotification() {
  yield takeEvery(FETCH_PUSH_NOTIFICATION, fetchPushNotificationHandler);
}

export function* watchDeletePushNotification() {
  yield takeEvery(DELETE_PUSH_NOTIFICATION, deletePushNotificationHandler);
}

export function* watchUpdatePushNotification() {
  yield takeEvery(UPDATE_PUSH_NOTIFICATION, updatePushNotificationHandler);
}

// Notification

export function* watchCreateNotification() {
  yield takeEvery(CREATE_NOTIFICATION, createNotificationHandler);
}
export function* watchFetchNotification() {
  yield takeEvery(FETCH_NOTIFICATION, fetchNotificationHandler);
}
export function* watchForCSV() {
  yield takeEvery(FETCH_EMAILS_FOR_CSV, fetchEmailsForCSVHandler);
}
export function* watchFetchMyNotification() {
  yield takeEvery(FETCH_MY_NOTIFICATION, fetchMyNotificationHandler);
}

export function* watchFetchEachNotification() {
  yield takeEvery(FETCH_EACH_NOTIFICATION, fetchEachNotificationHandler);
}

export function* watchDeleteNotification() {
  yield takeEvery(DELETE_NOTIFICATION, deleteNotificationHandler);
}

export function* watchUpdateNotification() {
  yield takeEvery(UPDATE_NOTIFICATION, updateNotificationHandler);
}

export function* watchFPN() {
  yield takeEvery(FPN_SUB, fpnHandler);
}

export function* watchCreateTemplate() {
  yield takeEvery(CREATE_TEMPLATE, createTemplateHandler);
}
export function* watchFetchTemplate() {
  yield takeEvery(FETCH_TEMPLATE, fetchTemplateHandler);
}

export function* watchFetchEachTemplate() {
  yield takeEvery(FETCH_EACH_TEMPLATE, fetchEachTemplateHandler);
}

export function* watchFetchNotificationLog() {
  yield takeEvery(FETCH_NOTIFICATION_LOG, fetchNotificationLogHandler);
}

export function* watchFetchEachNotificationLog() {
  yield takeEvery(FETCH_EACH_NOTIFICATION_LOG, fetchEachNotificationLogHandler);
}

export function* watchDeleteTemplate() {
  yield takeEvery(DELETE_TEMPLATE, deleteTemplateHandler);
}

export function* watchUpdateTemplate() {
  yield takeEvery(UPDATE_TEMPLATE, updateTemplateHandler);
}

export function* watchFetchBetaEmails() {
  yield takeEvery(FETCH_BETA_EMAILS, fetchBetaEmailsHandler);
}

function* PushNotificationService() {
  yield all([
    fork(watchFetchPushNotification),
    fork(watchUpdatePushNotification),
    fork(watchDeletePushNotification),
    fork(watchCreatePushNotification),

    fork(watchFetchNotification),
    fork(watchFetchMyNotification),
    fork(watchFetchEachNotification),
    fork(watchUpdateNotification),
    fork(watchDeleteNotification),
    fork(watchCreateNotification),

    fork(watchFPN),

    fork(watchFetchTemplate),
    fork(watchFetchEachTemplate),
    fork(watchUpdateTemplate),
    fork(watchDeleteTemplate),
    fork(watchCreateTemplate),

    fork(watchFetchBetaEmails),
    fork(watchForCSV),

    fork(watchFetchNotificationLog),
    fork(watchFetchEachNotificationLog),
  ]);
}

export default PushNotificationService;
