import {
  FETCH_PAYMENT_SUMMARY,
  FETCH_PAYMENT_SUMMARY_SUCCESSFULLY,
  FETCH_PAYMENT_SUMMARY_FAILED,
  FETCH_PAYMENT,
  FETCH_PAYMENT_SUCCESSFUL,
  FETCH_PAYMENT_API_FAILED,
  FETCH_PAYMENT_LIST,
  FETCH_PAYMENT_LIST_SUCCESSFUL,
  FETCH_PAYMENT_LIST_API_FAILED,
  FETCH_EACH_PAYMENT,
  FETCH_EACH_PAYMENT_SUCCESSFUL,
  FETCH_EACH_PAYMENT_API_FAILED,
  DELETE_PAYMENT,
  DELETE_PAYMENT_SUCCESSFUL,
  DELETE_PAYMENT_FAILED,
  CREATE_PAYMENT,
  CREATE_PAYMENT_SUCCESSFUL,
  CREATE_PAYMENT_FAILED,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_SUCCESSFUL,
  UPDATE_PAYMENT_FAILED,
  GET_PAYMENT_RECIPIENT,
  GET_PAYMENT_RECIPIENT_SUCCESSFUL,
  GET_PAYMENT_RECIPIENT_FAILED,
  FETCH_FOR_PAYMENT_CSV,
  FETCH_FOR_PAYMENT_CSV_SUCCESSFULLY,
  FETCH_FOR_PAYMENT_CSV_ERROR,
} from './actionTypes';

export const fetchPaymentList = (payload) => {
  return {
    type: FETCH_PAYMENT_LIST,
    payload,
  };
};

export const fetchPaymentListSuccessful = (payload) => {
  return {
    type: FETCH_PAYMENT_LIST_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchPaymentListError = (error) => {
  return {
    type: FETCH_PAYMENT_LIST_API_FAILED,
    payload: error,
  };
};

export const fetchPayment = (payload) => {
  return {
    type: FETCH_PAYMENT,
    payload,
  };
};

export const fetchPaymentSuccessful = (payload) => {
  return {
    type: FETCH_PAYMENT_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchPaymentError = (error) => {
  return {
    type: FETCH_PAYMENT_API_FAILED,
    payload: error,
  };
};

export const fetchEachPayment = (id) => {
  return {
    type: FETCH_EACH_PAYMENT,
    payload: id,
  };
};

export const fetchEachPaymentSuccessful = (payload) => {
  return {
    type: FETCH_EACH_PAYMENT_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachPaymentError = (error) => {
  return {
    type: FETCH_EACH_PAYMENT_API_FAILED,
    payload: error,
  };
};

export const deletePayment = (id) => {
  return {
    type: DELETE_PAYMENT,
    payload: id,
  };
};

export const deletePaymentSuccessful = (payload) => {
  return {
    type: DELETE_PAYMENT_SUCCESSFUL,
    payload,
  };
};

export const deletePaymentFailed = (error) => {
  return {
    type: DELETE_PAYMENT_FAILED,
    payload: error,
  };
};

export const createPayment = (payload) => {
  return {
    type: CREATE_PAYMENT,
    payload,
  };
};

export const createPaymentSuccessful = (payload) => {
  return {
    type: CREATE_PAYMENT_SUCCESSFUL,
    payload,
  };
};

export const createPaymentFailed = (error) => {
  return {
    type: CREATE_PAYMENT_FAILED,
    payload: error,
  };
};

export const updatePayment = (payload, query) => {
  return {
    type: UPDATE_PAYMENT,
    payload: { data: payload, query: query },
  };
};

export const updatePaymentFailed = (error) => {
  return {
    type: UPDATE_PAYMENT_FAILED,
    payload: error,
  };
};

export const updatePaymentSuccessful = (payload) => {
  return {
    type: UPDATE_PAYMENT_SUCCESSFUL,
    payload,
  };
};

// getRecipient
export const getRecipient = (payload) => {
  return {
    type: GET_PAYMENT_RECIPIENT,
    payload,
  };
};

export const getRecipientFailed = (error) => {
  return {
    type: GET_PAYMENT_RECIPIENT_FAILED,
    payload: error,
  };
};

export const getRecipientSuccessful = (payload) => {
  return {
    type: GET_PAYMENT_RECIPIENT_SUCCESSFUL,
    payload,
  };
};

//SUMMARY ENTRIES
export const fetchPaymentSummary = (query) => {
  return {
    type: FETCH_PAYMENT_SUMMARY,
    payload: query,
  };
};

export const fetchPaymentSummarySuccessfully = (payload) => {
  return {
    type: FETCH_PAYMENT_SUMMARY_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchPaymentSummaryFailed = (error) => {
  return {
    type: FETCH_PAYMENT_SUMMARY_FAILED,
    payload: error,
  };
};
export const fetchPaymentCSV = (payload) => {
  return {
    type: FETCH_FOR_PAYMENT_CSV,
    payload,
  };
};

export const fetchPaymentCSVSuccessfully = (payload) => {
  return {
    type: FETCH_FOR_PAYMENT_CSV_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchPaymentCSVError = (error) => {
  return {
    type: FETCH_FOR_PAYMENT_CSV_ERROR,
    payload: error,
  };
};
