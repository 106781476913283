import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import GreySummaryCard from '../../../components/Common/Summary/index';

import ActivitiesTable from './activitiesTable';
import PreviewActivities from '../../../components/Common/ProfileComponents/previewActivity';

import {
  fetchActivities,
  deleteActivities,
  updateActivities,
  updateActivitiesFailed,
} from '../../../store/actions';

const Activities = ({
  activities,
  fetchActivities,
  deleteActivities,
  updateActivities,
  updateActivitiesFailed,
  activitiesError,
  loading,
  message,
  tableError,
  history,
  accessControl
}) => {
  const [showModal, setShowModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [activitiesDetails, setActivitiesDetails] = useState(null);
  const [statusModal, setStatusModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [dateFilter, setDate] = useState({});
  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'Activities Log', link: '/activities_log' },
  ];

  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        updateActivitiesFailed('');
      }, 2000);
    }
  }, [message, updateActivitiesFailed]);

  useEffect(() => {
    updateActivitiesFailed('');
  }, []);

  const deleteActivitiesModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteActivities(deleteId);
    setDeleteId(null);
  };
  const date = (e) => {
    setDate(e);
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='Activities Log'
            breadcrumbItems={breadcrumbItems}
          />
          <GreySummaryCard field='Reports' subModule='Activities Log'>
            <>
              <div className='mb-2 text-right'></div>
              {message && (
                <Alert color='success' className='text-center'>
                  {message}
                </Alert>
              )}
              {activitiesError && (
                <Alert color='danger' className='text-center'>
                  {activitiesError}
                </Alert>
              )}
              {tableError !== null ? (
                <Alert color='danger' className='text-center'>
                  {tableError}
                </Alert>
              ) : (
                <Row>
                  <Col>
                    <ActivitiesTable
                      activitiesData={activities}
                      dateFilter={date}
                      loading
                      deleteActivities={deleteActivitiesModal}
                      fetchActivities={fetchActivities}
                      previewAction={previewHandler}
                      message={message}
                      history={history}
                      accessControl={accessControl}
                    />
                  </Col>
                </Row>
              )}
              <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
                <ModalHeader toggle={() => setShowModal(false)}>
                  Preview Activities
                </ModalHeader>
                <ModalBody>
                  <PreviewActivities previewId={previewId} />
                </ModalBody>
              </Modal>

              <Modal
                isOpen={showDeleteModal}
                toggle={() => setShowDeleteModal(!showDeleteModal)}
              >
                <ModalBody>
                  <h4 className='card-title mb-4 text-center'>
                    {' '}
                    Are you sure you want to delete this activities
                  </h4>
                  <button
                    onClick={() => deleteHandler()}
                    className='btn btn-outline-danger btn-sm w-100'
                  >
                    Click to Delete
                  </button>
                </ModalBody>
              </Modal>
            </>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { activities, loading, activitiesError, tableError, message } =
    state.Activities;
  return { accessControl, activities, loading, activitiesError, tableError, message };
};

export default connect(mapStateToProps, {
  fetchActivities,
  deleteActivities,
  updateActivities,
  updateActivitiesFailed,
})(Activities);
