import React from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { inviteDriver, logoutUser } from '../../../store/actions';
import logosmdark from '../../../assets/images/nrydeLogo.png';
import { Row, Col, Button, Container } from 'reactstrap';

const DriverInvite = ({ inviteDriver, loading }) => {
  return (
    // <div>driverInvite</div>
    <div>
      <Container fluid className='p-0'>
        <Row
          className='no-gutters align-items-center'
          style={{ height: '100vh' }}
        >
          <Col lg={12}>
            <div className='text-center'>
              <div>
                <Link to='/' className='logo'>
                  <img src={logosmdark} height='50' alt='logo' />
                </Link>
              </div>

              <h4 className='font-size-18 mt-4'>Welcome!</h4>
              <p className='text-muted'>
                Click the button to open the mobile app
              </p>
            </div>
            <div className='p-2 mt-2'>
              <div className='mt-2 text-center'>
                <Button
                  color='primary'
                  className='w-md waves-effect waves-light'
                  onClick={() => inviteDriver()}
                >
                  {loading ? 'Opening...' : 'Open App!'}
                </Button>
              </div>
            </div>

            <div className='mt-2 text-center'>
              <p>© 2022 nRyde.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { users, loading, userError } = state.Users;
  return {
    users,
    loading,
    userError,
  };
};

export default connect(mapStateToProps, {
  inviteDriver,
})(withRouter(DriverInvite));
