import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Alert,
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import GreySummaryCard from '../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../components/Common/Summary/actionCheck';
import RoleTable from './rolesTable';
import { fetchRoles, deleteRole } from '../../../store/actions';

const Roles = ({
  roles,
  tableError,
  roleError,
  fetchRoles,
  deleteRole,
  loading,
  message,
  history,
  user,
  accessControl
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const breadcrumbItems = [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Roles', link: '#' },
  ];

  const deleteRoleModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };
  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteRole(deleteId);
    setDeleteId(null);
  };
  let isSuperAdmin = user?.userRoles?.find(
    (usr) => usr.includes('SUPERADMIN')
  )
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Role' breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field='Configurations' subModule='Access Control'>
            <>

              {!roleError && !tableError ? (
                <div className='mb-4 d-flex justify-content-end align-items-center '>
                  <CheckAction
                    field='Configurations'
                    subModule='Access Control'
                    iconType={'Create'}
                  >
                    <Link to={`/role_form`}>
                      <Button color='success' className='mr-2' size='sm'>
                        {/* <i className='mdi mdi-plus-circle-outline font-size-18'></i>{' '} */}
                        Create Role
                      </Button>
                    </Link>
                  </CheckAction>
                  <CheckAction
                    field='Configurations'
                    subModule='Access Control'
                    iconType={'Edit'}
                  >
                    <Link to='/assign_role'>
                      <Button color='primary' className='mr-2' size='sm'>
                        {/* <i className='mdi mdi-plus-circle-outline font-size-18'></i>{' '} */}
                        Assign Role
                      </Button>
                    </Link>
                    <Link to={`/assign_role?function=unassign`}>
                      <Button color='danger' className='mr-2' size='sm'>
                        {/* <i className='mdi mdi-plus-circle-outline font-size-18'></i>{' '} */}
                        Unassign Role
                      </Button>
                    </Link>
                  </CheckAction>

                  {isSuperAdmin &&
                    <CheckAction
                      field='Configurations'
                      subModule='Access Control'
                      iconType={'Create'}
                    >
                      <Link to={`/create_user`}>
                        <Button color='info' className='mr-2' size='sm'>
                          Create User
                        </Button>
                      </Link>
                    </CheckAction>
                  }

                </div>
              ) : null}

              {message && (
                <Alert color='success' className='text-center'>
                  {message}
                </Alert>
              )}
              {tableError && (
                <Alert className='text-center' color='danger'>
                  {tableError}
                </Alert>
              )}
              {roleError && (
                <Alert className='text-center' color='danger'>
                  {roleError}
                </Alert>
              )}
              <Row>
                <Col>
                  <RoleTable
                    roleData={roles}
                    tableError={tableError}
                    loading={loading}
                    deleteRole={deleteRoleModal}
                    fetchRoles={fetchRoles}
                    history={history}
                    message={message}
                    CheckAction={CheckAction}
                    ReadCheck={ReadCheck}
                    accessControl={accessControl}
                    user={user}
                  />
                </Col>
              </Row>

              {/* Delete Confirmation Modal */}
              <Modal
                isOpen={showDeleteModal}
                toggle={() => setShowDeleteModal(!showDeleteModal)}
              >
                <ModalBody>
                  <h4 className='card-title mb-4 text-center'>
                    {' '}
                    Are you sure you want to delete this role
                  </h4>
                  <button
                    onClick={() => deleteHandler()}
                    className='btn btn-outline-danger btn-sm w-100'
                  >
                    Click to Delete
                  </button>
                </ModalBody>
              </Modal>
            </>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { privileges, roles, role, loading, roleError, message, tableError } =
    state.Role;
  const { user, accessControl } = state.Account;
  return { accessControl, user, privileges, roles, role, tableError, loading, roleError, message };
};

export default connect(mapStateToProps, {
  fetchRoles,
  deleteRole,
})(withRouter(Roles));
