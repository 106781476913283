export const FETCH_INVITATION = 'FETCH_INVITATION';
export const FETCH_INVITATION_SUCCESSFUL = 'FETCH_INVITATION_SUCCESSFUL';
export const FETCH_INVITATION_API_FAILED = 'FETCH_INVITATION_API_FAILED';

export const FETCH_USERS_INVITATION = 'FETCH_USERS_INVITATION';
export const FETCH_USERS_INVITATION_SUCCESSFUL =
  'FETCH_USERS_INVITATION_SUCCESSFUL';
export const FETCH_USERS_INVITATION_API_FAILED =
  'FETCH_USERS_INVITATION_API_FAILED';

export const FETCH_EACH_INVITATION = 'FETCH_EACH_INVITATION';
export const FETCH_EACH_INVITATION_SUCCESSFUL =
  'FETCH_EACH_INVITATION_SUCCESSFUL';
export const FETCH_EACH_INVITATION_API_FAILED =
  'FETCH_EACH_INVITATION_API_FAILED';

export const DELETE_INVITATION = 'DELETE_INVITATION';
export const DELETE_INVITATION_SUCCESSFUL = 'DELETE_INVITATION_SUCCESSFULLY';
export const DELETE_INVITATION_FAILED = 'DELETE_INVITATION_FAILED';

export const CREATE_INVITATION = 'CREATE_INVITATION';
export const CREATE_INVITATION_SUCCESSFUL = 'CREATE_INVITATION_SUCCESSFULLY';
export const CREATE_INVITATION_FAILED = 'CREATE_INVITATION_FAILED';

export const UPDATE_INVITATION = 'UPDATE_INVITATION';
export const UPDATE_INVITATION_SUCCESSFUL = 'UPDATE_INVITATION_SUCCESSFULLY';
export const UPDATE_INVITATION_FAILED = 'FAILED';

export const FETCH_INVITATION_DETAILS = 'FETCH_INVITATION_DETAILS';
export const FETCH_INVITATION_DETAILS_FAILED =
  'FETCH_INVITATION_DETAILS_FAILED';
export const FETCH_INVITATION_DETAILS_SUCCESSFULLY =
  'FETCH_INVITATION_DETAILS_SUCCESSFULLY';
// INVITATION Summary
export const FETCH_INVITATION_SUMMARY = 'FETCH_INVITATION_SUMMARY';
export const FETCH_INVITATION_SUMMARY_FAILED =
  'FETCH_INVITATION_SUMMARY_FAILED';
export const FETCH_INVITATION_SUMMARY_SUCCESSFULLY =
  'FETCH_INVITATION_SUMMARY_SUCCESSFULLY';

export const FETCH_FOR_INVITATION_CSV = 'FETCH_FOR_INVITATION_CSV';
export const FETCH_FOR_INVITATION_CSV_ERROR = 'FETCH_FOR_INVITATION_CSV_ERROR';
export const FETCH_FOR_INVITATION_CSV_SUCCESSFULLY =
  'FETCH_FOR_INVITATION_CSV_SUCCESSFULLY';
