import React from 'react';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import rideLogSVG from '../../../assets/images/markers_icon/ridelog.svg';

function Map({
  driverLocalion,
}) {







  return (
    <>

      <GoogleMap
        defaultZoom={12}
        defaultCenter={driverLocalion}
      // defaultCenter={{ lat: 41.8507300, lng: -87.6512600 }}
      // onClick={(e) => handleLocation(e)}
      // defaultOptions={{ styles: mapStyles }}
      >
        <Marker
          position={driverLocalion}
          icon={{
            url: rideLogSVG,
            scaledSize: new window.google.maps.Size(25, 25),
          }}
        />


        {/* <DirectionsRenderer
            directions={directions}
            hideRouteList={true}
            draggable={false}
            suppressMarkers={true}
          /> */}


      </GoogleMap>

    </>
  );
}

const MapWrapped = withScriptjs(withGoogleMap(Map));

export default function App({

  driverLocalion,
}) {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
        loadingElement={<div style={{ height: `100%`, borderRadius: '20px' }} />}
        containerElement={<div style={{ height: `100%`, borderRadius: '20px' }} />}
        mapElement={<div style={{ height: `100%`, borderRadius: '20px' }} />}

        driverLocalion={driverLocalion}

      />
    </div>
  );
}
