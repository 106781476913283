import React, { useState, useEffect } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import {
  Col,
  Row,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Card,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import VehiclePackage from "../../../pages/IRides/Vehicle/components/VehiclePackage";
import Moment from "moment";

const UserVehicleCard = ({
  updateVehiclePackages,
  updateVehicleFailed,
  message,
  vehicleError,
  user,
  handleVehicleApproval,
}) => {
  const [statusModal, setStatusModal] = useState(false);
  const [res, setRes] = useState("");

  const vehicleInfo =
    user?.vehicles &&
    user?.vehicles.find((vehicle) => vehicle.isDeleted === false);

  const images = [
    { url: vehicleInfo?.imageFront },
    vehicleInfo?.imageLeft ? { url: vehicleInfo?.imageLeft } : null,
    vehicleInfo?.imageBack ? { url: vehicleInfo?.imageBack } : null,
    vehicleInfo?.imageRight ? { url: vehicleInfo?.imageRight } : null,
  ];

  useEffect(() => {
    if (message) {
      setRes(message);
      setTimeout(() => {
        setStatusModal(false);
        updateVehicleFailed("");
      }, 2000);
    }
  }, [message]);

  if (vehicleInfo) {
    return (
      <div>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        {vehicleError && (
          <Alert color="danger" className="text-center">
            {vehicleError}
          </Alert>
        )}
        <Row style={{ justifyContent: "space-evenly" }}>
          <Col sm={3} className="rounded p-2">
            <div>
              <h4 className="text-capitalize font-size-12 card-title mb-2 mt-2">
                Seat Capacity
              </h4>
              <Card className="bg-light rounded">
                <CardBody className="p-2">
                  <div className="mb-4">
                    <h4 className="text-capitalize font-size-10 card-title mb-2 mt-2">
                      Vehicle Model
                    </h4>
                    <h3 className="text-capitalize  card-title mb-2 mt-2">
                      Toyota Bus (Coaster)
                    </h3>
                  </div>
                  <div>
                    <h4 className="text-capitalize font-size-10 card-title mb-2 mt-2">
                      Capacity
                    </h4>
                    <h3 className="text-capitalize font-size-15 card-title mb-2 mt-2">
                      60
                    </h3>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div>
              <h4 className="text-capitalize font-size-12 card-title mb-2 mt-2">
                Driver Preferred Route
              </h4>
              <Card className="bg-light rounded">
                <CardBody>Route Tab</CardBody>
              </Card>
            </div>
            <div>
              <h4 className="text-capitalize font-size-12 card-title mb-2 mt-2">
                Assign Trip Route
              </h4>
              <Card className="bg-light rounded">
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <ul className="list-unstyled activity-wid mb-0">
                        <li className="activity-list pb-2">
                          <div
                            className="activity-icon d-flex align-items-center"
                            style={{ marginTop: "2px" }}
                          >
                            <div
                              className={`rounded-circle badge badge-soft-success font-size-10 align-self-end`}
                            >
                              <i
                                className="fas fa-circle"
                                style={{ marginTop: "1px", fontSize: "9px" }}
                              ></i>
                            </div>
                          </div>
                          <div>
                            <p className="mb-1 font-size-12 text-muted">
                              Address 1
                            </p>
                          </div>
                        </li>
                        <li className="activity-list pb-2">
                          <div className="activity-icon">
                            <i className="ri-map-pin-line text-success" />
                          </div>
                          <div>
                            <p className="mb-1 font-size-12 text-muted">
                              Address 1
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div>
                      {" "}
                      <Button
                        color={"success"}
                        outline
                        size="sm"
                        className="px-3"
                      >
                        Assign Route
                      </Button>{" "}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <ul className="list-unstyled activity-wid mb-0">
                        <li className="activity-list pb-2">
                          <div
                            className="activity-icon d-flex align-items-center"
                            style={{ marginTop: "2px" }}
                          >
                            <div
                              className={`rounded-circle badge badge-soft-success font-size-10 align-self-end`}
                            >
                              <i
                                className="fas fa-circle"
                                style={{ marginTop: "1px", fontSize: "9px" }}
                              ></i>
                            </div>
                          </div>
                          <div>
                            <p className="mb-1 font-size-12 text-muted">
                              Address 1
                            </p>
                          </div>
                        </li>
                        <li className="activity-list pb-2">
                          <div className="activity-icon">
                            <i className="ri-map-pin-line text-success" />
                          </div>
                          <div>
                            <p className="mb-1 font-size-12 text-muted">
                              Address 1
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div>
                      {" "}
                      <Button
                        color={"success"}
                        outline
                        size="sm"
                        className="px-3"
                      >
                        Assign Route
                      </Button>{" "}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <ul className="list-unstyled activity-wid mb-0">
                        <li className="activity-list pb-2">
                          <div
                            className="activity-icon d-flex align-items-center"
                            style={{ marginTop: "2px" }}
                          >
                            <div
                              className={`rounded-circle badge badge-soft-success font-size-10 align-self-end`}
                            >
                              <i
                                className="fas fa-circle"
                                style={{ marginTop: "1px", fontSize: "9px" }}
                              ></i>
                            </div>
                          </div>
                          <div>
                            <p className="mb-1 font-size-12 text-muted">
                              Address 1
                            </p>
                          </div>
                        </li>
                        <li className="activity-list pb-2">
                          <div className="activity-icon">
                            <i className="ri-map-pin-line text-success" />
                          </div>
                          <div>
                            <p className="mb-1 font-size-12 text-muted">
                              Address 1
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div>
                      {" "}
                      <Button
                        color={"success"}
                        outline
                        size="sm"
                        className="px-3"
                      >
                        Assign Route
                      </Button>{" "}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <ul className="list-unstyled activity-wid mb-0">
                        <li className="activity-list pb-2">
                          <div
                            className="activity-icon d-flex align-items-center"
                            style={{ marginTop: "2px" }}
                          >
                            <div
                              className={`rounded-circle badge badge-soft-success font-size-10 align-self-end`}
                            >
                              <i
                                className="fas fa-circle"
                                style={{ marginTop: "1px", fontSize: "9px" }}
                              ></i>
                            </div>
                          </div>
                          <div>
                            <p className="mb-1 font-size-12 text-muted">
                              Address 1
                            </p>
                          </div>
                        </li>
                        <li className="activity-list pb-2">
                          <div className="activity-icon">
                            <i className="ri-map-pin-line text-success" />
                          </div>
                          <div>
                            <p className="mb-1 font-size-12 text-muted">
                              Address 1
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div>
                      {" "}
                      <Button
                        color={"success"}
                        outline
                        size="sm"
                        className="px-3"
                      >
                        Assign Route
                      </Button>{" "}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col sm={4} className="rounded p-2">
            <div>
              <h4 className="text-capitalize font-size-12 card-title mb-2 mt-2">
                Vehicle Details
              </h4>
              <Card className="bg-light rounded">
                <CardBody className="p-2">
                  <div className="mb-2">
                    <Table hover>
                      <tbody>
                        <tr className="border-top-0">
                          <td className="text-muted text-left col-6">
                            Vehicle Brand
                          </td>
                          <td className="font-weight-bold text-left col-6">
                            {vehicleInfo?.brand}
                          </td>
                        </tr>

                        <tr>
                          <td className="text-muted text-left col-6">
                            Vehicle Year
                          </td>
                          <td className="font-weight-bold text-left col-6">
                            {vehicleInfo?.year}
                          </td>
                        </tr>

                        <tr>
                          <td className="text-muted text-left col-6">
                            Vehicle Model
                          </td>
                          <td className="font-weight-bold text-left col-6">
                            {vehicleInfo?.model}
                          </td>
                        </tr>

                        <tr>
                          <td className="text-muted text-left col-6">
                            Vehicle color
                          </td>
                          <td className="font-weight-bold text-left col-6">
                            {vehicleInfo?.color}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-muted text-left col-6">
                            Vehicle Status
                          </td>
                          <td className="font-weight-bold text-left col-6">
                            <span
                              className={`badge badge-soft-${
                                vehicleInfo?.status.toLowerCase() === "approved"
                                  ? "success"
                                  : vehicleInfo?.status.toLowerCase() ===
                                    "cancelled"
                                  ? "danger"
                                  : vehicleInfo?.status.toLowerCase() ===
                                    "rejected"
                                  ? "danger"
                                  : vehicleInfo?.status.toLowerCase() ===
                                    "pending"
                                  ? "warning"
                                  : vehicleInfo?.status.toLowerCase() ===
                                    "review"
                                  ? "dark"
                                  : ""
                              } font-size-9 ml-2 p-1`}
                            >
                              {vehicleInfo?.status}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-muted text-left col-6">
                            Plate Number
                          </td>
                          <td className="font-weight-bold text-left col-6">
                            {vehicleInfo?.plateNumber}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-muted text-left col-6">
                            Created At
                          </td>
                          <td className="font-weight-bold text-left col-6">
                            {Moment(vehicleInfo?.createdAt).format("l")}
                          </td>
                        </tr>

                        <tr>
                          <td className="text-muted text-left col-6">
                            Package
                          </td>
                          <td className="font-weight-bold text-left col-6">
                            {vehicleInfo?.package}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div>
              <h4 className="text-capitalize font-size-12 card-title mb-2 mt-2">
                Image
              </h4>
              <Card className="bg-light rounded">
                <CardBody>Waiting for api ...</CardBody>
              </Card>
            </div>
          </Col>
          <Col md={5} className="rounded p-2">
            <div>
              <h4 className="text-capitalize font-size-12 card-title mb-2 mt-2">
                Bus Type
              </h4>
              <Card className="bg-light rounded">
                <CardBody>Waiting api and Miss Yu</CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        {/* Vehicle package Update Modal */}
        <Modal isOpen={statusModal} toggle={() => setStatusModal(!statusModal)}>
          <ModalHeader toggle={() => setStatusModal(false)}>
            Update Vehicle Package
          </ModalHeader>
          <ModalBody>
            {message && (
              <Alert color="success" className="text-center">
                {message}
              </Alert>
            )}
            {vehicleError && (
              <Alert color="danger" className="text-center">
                {vehicleError}
              </Alert>
            )}
            <div className="CustomSelect">
              <VehiclePackage
                vehicleDetails={vehicleInfo}
                updateVehiclePackages={updateVehiclePackages}
                message={res}
                closeModal={setStatusModal}
                updateVehicleFailed={updateVehicleFailed}
              />
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  } else {
    return <div>This driver has no registered vehicle</div>;
  }
};

export default UserVehicleCard;
