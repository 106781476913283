import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Alert,
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import GreySummaryCard from '../../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../../components/Common/Summary/actionCheck';
import RoleTable from './table';
import {
  listAccessControlFE,
  deleteAccessControlFE,
} from '../../../../store/actions';

const Roles = ({
  accessControlFE,
  accessControl,
  accessControlFEError,
  listAccessControlFE,
  deleteAccessControlFE,
  loading,
  message,
  history,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const breadcrumbItems = [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Access Control FE', link: '#' },
  ];

  const deleteAccessControlFEModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };
  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteAccessControlFE(deleteId);
    setDeleteId(null);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Role' breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field='Configurations' subModule='Access Control FE'>
            <>
              {!accessControlFEError && (
                <div className='mb-4 d-flex justify-content-end align-items-center '>
                  <CheckAction
                    field='Configurations'
                    subModule='Access Control FE'
                    iconType='Create'
                  >
                    <Link to={`/access_control_form`}>
                      <Button color='success' className='mr-2' size='sm'>
                        Create Control
                      </Button>
                    </Link>
                  </CheckAction>
                </div>
              )}

              {message && (
                <Alert color='success' className='text-center'>
                  {message}
                </Alert>
              )}
              {accessControlFEError && (
                <Alert className='text-center' color='danger'>
                  {accessControlFEError}
                </Alert>
              )}
              <Row>
                <Col>
                  <RoleTable
                    roleData={accessControlFE}
                    loading={loading}
                    deleteRole={deleteAccessControlFEModal}
                    fetchRoles={listAccessControlFE}
                    history={history}
                    message={message}
                    CheckAction={CheckAction}
                    accessControl={accessControl}
                    ReadCheck={ReadCheck}
                  />
                </Col>
              </Row>

              {/* Delete Confirmation Modal */}
              <Modal
                isOpen={showDeleteModal}
                toggle={() => setShowDeleteModal(!showDeleteModal)}
              >
                <ModalBody>
                  <h4 className='card-title mb-4 text-center'>
                    {' '}
                    Are you sure you want to delete this Access
                  </h4>
                  <button
                    onClick={() => deleteHandler()}
                    className='btn btn-outline-danger btn-sm w-100'
                  >
                    Click to Delete
                  </button>
                </ModalBody>
              </Modal>
            </>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const {
    accessControlFE,
    loading,
    accessControlFEError,
    message,
    tableError,
  } = state.AppConfigs;
  return {
    accessControl,
    accessControlFE,
    tableError,
    loading,
    accessControlFEError,
    message,
  };
};

export default connect(mapStateToProps, {
  listAccessControlFE,
  deleteAccessControlFE,
})(withRouter(Roles));
