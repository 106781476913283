import HttpService from './HttpService';

export const CreateAppConfigsService = (payload) => {
  const http = new HttpService();
  const url = 'appConfig';
  return http.postData(payload, url);
};

export const GetAppConfigsService = (payload) => {
  const http = new HttpService();
  let url = 'appConfig';
  if (payload) {
    url = `${url}?search=${payload}`;
  }
  return http.getData(url);
};

export const GetEachAppConfigsService = (id) => {
  const http = new HttpService();
  const url = `appConfig/${id}`;
  return http.getData(url);
};

export const UpdateAppConfigsService = ({ payload, id }) => {
  const http = new HttpService();
  const url = `appConfig/${id}`;
  return http.putData(payload, url);
};

export const UpdateAppConfigItemService = ({ payload, id }) => {
  const http = new HttpService();
  const url = `appConfig/item/${id}`;
  return http.putData(payload.appConfigItems, url);
};

export const DeleteAppConfigsService = (id) => {
  const http = new HttpService();
  const url = `appConfig/${id}`;
  return http.deleteData(url);
};

export const DeleteAppConfigItemService = (id) => {
  const http = new HttpService();
  const url = `appConfig/item/${id}`;
  return http.deleteData(url);
};

// listAccessControlFEService,
// createAccessControlFEService,
export const createAccessControlFEService = (payload) => {
  const http = new HttpService();
  const url = `appConfig/acl`;
  console.log('url', url);
  return http.postData(payload, url);
};
// updateAccessControlFEService,
export const updateAccessControlFEService = ({ payload, id }) => {
  console.log('payload-service', payload);
  console.log(id);
  const http = new HttpService();
  const url = `appConfig/acl/${id}`;
  return http.putData(payload, url);
};
// deleteAccessControlFEService,
export const deleteAccessControlFEService = (id) => {
  const http = new HttpService();
  const url = `appConfig/acl/${id}`;
  return http.deleteData(url);
};

export const getEachAccessControlFEService = (id) => {
  const http = new HttpService();
  let url = `appConfig/acl/${id}`;
  return http.getData(url);
};

export const listAccessControlFEService = (payload) => {
  const http = new HttpService();
  let url = 'appConfig/acl/all';
  if (payload) {
    url = `${url}?search=${payload}`;
  }
  return http.getData(url);
};
