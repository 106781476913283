import React, { Component } from 'react'
import AlartError from '../../../components/Common/AlertCard'
import {
  Row,
  Col,
  Button,
  Alert,
  Card,
  CardBody,
  FormGroup,
  Label,
  Container,
} from 'reactstrap'

// Redux
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

// actions
import {
  resendActivateAccount,
  apiActivationError,
} from '../../../store/actions'

// import images
import logosmdark from '../../../assets/images/nrydeLogo.png'
import { AvForm, AvField } from 'availity-reactstrap-validation'

class ResendActivation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userEmail: null,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(event, values) {
    this.props.resendActivateAccount(values.email)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activate_message !== this.props.activate_message) {
      setTimeout(() => {
        this.props.history.push('/login')
      }, 2000)
    }
  }

  componentDidMount() {
    this.props.apiActivationError('')
  }

  componentWillUnmount() {
    document.body.classList.remove('auth-body-bg')
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div>

        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={12}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={4}>
                        <Card className="shadow-lg">
                          <CardBody>
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={this.handleSubmit}
                            >
                              {this.props.activate_message ? (
                                <>
                                  <div className="text-center">
                                    <Link to="/" className="logo">
                                      <img
                                        src={logosmdark}
                                        height="50"
                                        alt="logo"
                                      />
                                    </Link>
                                  </div>
                                  <Alert
                                    color="success"
                                    className="text-center"
                                  >
                                    {this.props.activate_message}
                                  </Alert>
                                </>
                              ) : (
                                <>
                                  <div>
                                    <div className="text-center">
                                      <div>
                                        <Link to="/" className="logo">
                                          <img
                                            src={logosmdark}
                                            height="50"
                                            alt="logo"
                                          />
                                        </Link>
                                      </div>
                                      {this.props.activationError && (
                                        <AlartError
                                          error={this.props.activationError}
                                        />
                                      )}

                                      <h4 className="font-size-18 mt-4">
                                        Re_activate Account!
                                      </h4>
                                      <h4 className="text-center"></h4>
                                      <p className="text-muted">
                                        Enter your email below to get another
                                        activation code
                                      </p>
                                    </div>

                                    <div>
                                      <Row>
                                        <Col md={12}>
                                          <FormGroup className=" mb-4">
                                            <AvField
                                              name="email"
                                              type="email"
                                              className="form-control bg-light"
                                              id="email"
                                              placeholder="Enter Email ..."
                                              required
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                      <div className=" text-center">
                                        <Button
                                          color="primary"
                                          className="w-md waves-effect waves-light"
                                        >
                                          {this.props.loading
                                            ? 'Sending...'
                                            : 'Send Now!'}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className="mt-2 text-center">
                                <p>© 2022 nRyde.</p>
                              </div>
                            </AvForm>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { activationError, activate_message, loading } = state.Account
  return { activationError, activate_message, loading }
}

export default withRouter(
  connect(mapStateToProps, {
    resendActivateAccount,
    apiActivationError,
  })(ResendActivation),
)
