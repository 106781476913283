import {
  FETCH_ZONE,
  FETCH_ZONE_SUCCESSFUL,
  FETCH_ZONE_API_FAILED,
  FETCH_EACH_ZONE,
  FETCH_EACH_ZONE_SUCCESSFUL,
  FETCH_EACH_ZONE_API_FAILED,
  DELETE_ZONE,
  DELETE_ZONE_SUCCESSFUL,
  DELETE_ZONE_FAILED,
  CREATE_ZONE,
  CREATE_ZONE_SUCCESSFUL,
  CREATE_ZONE_FAILED,
  UPDATE_ZONE,
  UPDATE_ZONE_SUCCESSFUL,
  UPDATE_ZONE_FAILED,
} from './actionTypes';

export const fetchZone = (payload) => {
  return {
    type: FETCH_ZONE,
    payload,
  };
};

export const fetchZoneSuccessful = (payload) => {
  return {
    type: FETCH_ZONE_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchZoneError = (error) => {
  return {
    type: FETCH_ZONE_API_FAILED,
    payload: error,
  };
};

export const fetchEachZone = (id) => {
  return {
    type: FETCH_EACH_ZONE,
    payload: id,
  };
};

export const fetchEachZoneSuccessful = (payload) => {
  return {
    type: FETCH_EACH_ZONE_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachZoneError = (error) => {
  return {
    type: FETCH_EACH_ZONE_API_FAILED,
    payload: error,
  };
};

export const deleteZone = (id) => {
  return {
    type: DELETE_ZONE,
    payload: id,
  };
};

export const deleteZoneSuccessful = (payload) => {
  return {
    type: DELETE_ZONE_SUCCESSFUL,
    payload,
  };
};

export const deleteZoneFailed = (error) => {
  return {
    type: DELETE_ZONE_FAILED,
    payload: error,
  };
};

export const createZone = (payload) => {
  return {
    type: CREATE_ZONE,
    payload,
  };
};

export const createZoneSuccessful = (payload) => {
  return {
    type: CREATE_ZONE_SUCCESSFUL,
    payload,
  };
};

export const createZoneFailed = (error) => {
  return {
    type: CREATE_ZONE_FAILED,
    payload: error,
  };
};

export const updateZone = (payload) => {
  return {
    type: UPDATE_ZONE,
    payload,
  };
};

export const updateZoneFailed = (error) => {
  return {
    type: UPDATE_ZONE_FAILED,
    payload: error,
  };
};

export const updateZoneSuccessful = (payload) => {
  return {
    type: UPDATE_ZONE_SUCCESSFUL,
    payload,
  };
};
