
import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetBusStopService = (query) => {
  const http = new HttpService();
  let url = 'bus-stop/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const GetEachBusStopService = (id) => {
  const http = new HttpService();
  const url = `bus-stop/${id}`;
  return http.getData(url);
};

export const CreateBusStopService = (payload) => {
  const http = new HttpService();
  const url = 'bus-stop';
  return http.postData(payload, url);
};
export const UpdateBusStopService = (payload) => {
  const http = new HttpService();
  const url = `bus-stop/${payload.id}`;
  return http.putData(payload, url);
};

export const DeleteBusStopService = (id) => {
  const http = new HttpService();
  const url = `bus-stop/${id}`;
  return http.deleteData(url);
};
