import {
  FETCH_REWARD,
  FETCH_REWARD_SUCCESSFUL,
  FETCH_REWARD_API_FAILED,
  FETCH_REWARD_HISTORY,
  FETCH_REWARD_HISTORY_SUCCESSFUL,
  FETCH_REWARD_HISTORY_API_FAILED,
  FETCH_REWARD_REDEEM_HISTORY,
  FETCH_REWARD_REDEEM_HISTORY_SUCCESSFUL,
  FETCH_REWARD_REDEEM_HISTORY_API_FAILED,
  FETCH_EACH_REWARD,
  FETCH_EACH_REWARD_SUCCESSFUL,
  FETCH_EACH_REWARD_API_FAILED,
  DELETE_REWARD,
  DELETE_REWARD_SUCCESSFUL,
  DELETE_REWARD_FAILED,
  CREATE_REWARD,
  CREATE_REWARD_SUCCESSFUL,
  CREATE_REWARD_FAILED,
  UPDATE_REWARD,
  UPDATE_REWARD_SUCCESSFUL,
  UPDATE_REWARD_FAILED,
  FETCH_REWARD_SUMMARY,
  FETCH_REWARD_SUMMARY_SUCCESSFULLY,
  FETCH_REWARD_SUMMARY_FAILED,
} from './actionTypes'

export const fetchReward = (payload) => {
  return {
    type: FETCH_REWARD,
    payload,
  }
}

export const fetchRewardHistory = (payload) => {
  return {
    type: FETCH_REWARD_HISTORY,
    payload,
  }
}

export const fetchRewardRedeemHistory = (payload) => {
  return {
    type: FETCH_REWARD_REDEEM_HISTORY,
    payload,
  }
}

export const fetchRewardSuccessful = (payload) => {
  return {
    type: FETCH_REWARD_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchRewardHistorySuccessful = (payload) => {
  return {
    type: FETCH_REWARD_HISTORY_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchRewardRedeemHistorySuccessful = (payload) => {
  return {
    type: FETCH_REWARD_REDEEM_HISTORY_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchRewardError = (error) => {
  return {
    type: FETCH_REWARD_API_FAILED,
    payload: error,
  }
}

export const fetchRewardHistoryError = (error) => {
  return {
    type: FETCH_REWARD_HISTORY_API_FAILED,
    payload: error,
  }
}

export const fetchRewardRedeemHistoryError = (error) => {
  return {
    type: FETCH_REWARD_REDEEM_HISTORY_API_FAILED,
    payload: error,
  }
}

export const fetchEachReward = (id) => {
  return {
    type: FETCH_EACH_REWARD,
    payload: id,
  }
}

export const fetchEachRewardSuccessful = (payload) => {
  return {
    type: FETCH_EACH_REWARD_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchEachRewardError = (error) => {
  return {
    type: FETCH_EACH_REWARD_API_FAILED,
    payload: error,
  }
}

export const deleteReward = (id) => {
  return {
    type: DELETE_REWARD,
    payload: id,
  }
}

export const deleteRewardSuccessful = (payload) => {
  return {
    type: DELETE_REWARD_SUCCESSFUL,
    payload,
  }
}

export const deleteRewardFailed = (error) => {
  return {
    type: DELETE_REWARD_FAILED,
    payload: error,
  }
}

export const createReward = (payload) => {
  return {
    type: CREATE_REWARD,
    payload,
  }
}

export const createRewardSuccessful = (payload) => {
  return {
    type: CREATE_REWARD_SUCCESSFUL,
    payload,
  }
}

export const createRewardFailed = (error) => {
  return {
    type: CREATE_REWARD_FAILED,
    payload: error,
  }
}

export const updateReward = (payload, id) => {
  return {
    type: UPDATE_REWARD,
    payload: { payload, id },
  }
}

export const updateRewardFailed = (error) => {
  return {
    type: UPDATE_REWARD_FAILED,
    payload: error,
  }
}

export const updateRewardSuccessful = (payload) => {
  return {
    type: UPDATE_REWARD_SUCCESSFUL,
    payload,
  }
}
//SUMMARY ENTRIES
export const fetchRewardSummary = (query) => {
  return {
    type: FETCH_REWARD_SUMMARY,
    payload: query,
  }
}

export const fetchRewardSummarySuccessfully = (payload) => {
  return {
    type: FETCH_REWARD_SUMMARY_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchRewardSummaryFailed = (error) => {
  return {
    type: FETCH_REWARD_SUMMARY_FAILED,
    payload: error,
  }
}
