import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import data from './data.json';
import Form from './form';
import UpdateForm from './updateFrom/index';

import {
  updateAccessControlFE,
  createAccessControlFE,
  listEachAccessControlFE,
  createAccessControlFEError,
} from '../../../../../store/actions';

const RoleForm = ({
  eachAccessControlFE,
  updateAccessControlFE,
  createAccessControlFE,
  listEachAccessControlFE,
  createAccessControlFEError,
}) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  useEffect(() => {
    if (id) {
      listEachAccessControlFE(id);
    }
  }, [id, listEachAccessControlFE]);

  return (
    <React.Fragment>
      <div className="page-content">
        {id && eachAccessControlFE && (
          <>
            <UpdateForm
              updateData={eachAccessControlFE[0]}
              data={data}
              createHandler={updateAccessControlFE}
              createAccessControlFEError={createAccessControlFEError}
            />
          </>
        )}
        {!id && (
          <Form
            data={data}
            createHandler={createAccessControlFE}
            createAccessControlFEError={createAccessControlFEError}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { eachAccessControlFE } = state.AppConfigs;
  return { eachAccessControlFE };
};

export default connect(mapStateToProps, {
  updateAccessControlFE,
  createAccessControlFE,
  listEachAccessControlFE,
  createAccessControlFEError,
})(RoleForm);
