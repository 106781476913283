import React from 'react';
import { Alert, FormGroup, Row, Col } from 'reactstrap';
import AlartError from "../../../../components/Common/AlertCard"
import {
  AvForm,
  AvField,
} from 'availity-reactstrap-validation';

const UpdateComplaint = ({
  updateId,
  loading,
  updateComplaint,
  message,
  complaintError,
}) => {
  const handleSubmit = (e, values) => {
    const payload = {
      status: values.Status,
      note: values.note,
      id: updateId,
    };
    updateComplaint(payload);
  };

  return (
    <div>
      {message && (
        <Alert color="success" className="text-center">
          Complaint Updated Successfully
        </Alert>
      )}

      {complaintError && (
        <AlartError error={complaintError} />
      )}

      <div>
        <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
          <Row>
            <Col sm={12}>
              <FormGroup className=" mb-4">
                <AvField
                  className="form-control bg-light"
                  type="select"
                  name="Status"
                  value="DISPUTED"
                  label="Status"
                  required
                >
                  <option>DISPUTED</option>
                  <option>PENDING</option>
                  <option>REVIEW</option>
                </AvField>
              </FormGroup>
            </Col>
            <Col sm={12}>
              <FormGroup className="mb-4">
                <AvField
                  name="note"
                  type="text"
                  className="form-control bg-light"
                  label="Note"
                  id="note"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="text-center">
            <button className="btn btn-success px-2" type="submit">
              Update
            </button>
          </div>
        </AvForm>
      </div>
    </div>
  );
};

export default UpdateComplaint;
