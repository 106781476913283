import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import AutoComplete from "../../../../../components/Common/Autocomplete";
import { AvForm, AvField } from "availity-reactstrap-validation";
import CustomSelect from "../../../../../components/Common/MoreDropdown/CustomSelect";
// actions
import {
  createLicenseKey,
  createLicenseKeyFailed,
  updateLicenseKey,
  updateLicenseKeyFailed,
} from "../../../../../store/actions";
const Form = ({
  createLicenseKey,
  createLicenseKeyFailed,
  updateLicenseKey,
  updateLicenseKeyFailed,
  message,
  history,
  licenseKeyError,
  defaultValue,
  loading,
  zone,
  fetchZone,
}) => {
  const [errorMessage, setError] = useState("");
  const [description, setDescription] = useState(" ");
  const [selected, setSelected] = useState([]);
  const [zoneOption, SetZoneOption] = useState([]);
  const handleSubmit = async (event, values) => {
    if (!description) {
      setError("Please key in the description");
      return;
    }
    let zoneIds = [];
    selected.forEach((data) => {
      zoneIds.push(data.value);
    });
    const licenseKeyData = {
      ...values,
    };
    licenseKeyData.description = description;
    licenseKeyData.zones = zoneIds;
    // console.log(licenseKeyData);
    if (defaultValue) {
      licenseKeyData.id = defaultValue.id;
      updateLicenseKey(licenseKeyData);
      return;
    }
    createLicenseKey(licenseKeyData);
  };

  useEffect(() => {
    setDescription("");
    setSelected([]);
    if (defaultValue) {
      setDescription(defaultValue.description);
      if (defaultValue?.zones?.length > 0) {
        const zonArr = [];
        if (defaultValue.zones) {
          defaultValue.zones.forEach((element) => {
            const zonObj = {};
            zonObj.value = element.id;
            zonObj.label = element.name;
            zonArr.push(zonObj);
          });
        }
        setSelected(zonArr);
      }
    }
    if (message) {
      setTimeout(() => {
        createLicenseKeyFailed("");
        createLicenseKeyFailed("");
        history.goBack();
      }, 2000);
    }
  }, [defaultValue, message]);

  useEffect(() => {
    createLicenseKeyFailed("");
  }, []);
  useEffect(() => {
    if (zone) {
      const zoneArr = [];
      zone.result.forEach((element) => {
        const zoneObj = {};
        zoneObj.value = element.id;
        zoneObj.label = element.name;
        zoneArr.push(zoneObj);
      });
      SetZoneOption(zoneArr);
    }
  }, [zone]);

  const handleFetchMoreZones = () => {
    fetchZone({ take: zoneOption.length + 10, skip: 0 });
  };
  return (
    <Card className="col-md-6">
      <CardBody>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        {licenseKeyError && (
          <Alert color="danger" className="text-center">
            {licenseKeyError}
          </Alert>
        )}
        {errorMessage && (
          <Alert color="danger" className="text-center">
            {errorMessage}
          </Alert>
        )}
        <div className="float-right">
          <Link to="#" onClick={() => history.goBack()}>
            <i className="fas fa-arrow-left mr-3" />
            Back
          </Link>
        </div>
        <div className="mb-3">
          <h6> {defaultValue ? "Update" : "Create"} License Key </h6>
        </div>
        <div className="col-md-12">
          <AvForm
            className="form-horizontal"
            onValidSubmit={handleSubmit}
            model={defaultValue ? defaultValue : {}}
          >
            <Row>
              <Col>
                <FormGroup className="mb-4">
                  <Label htmlFor="name">Name</Label>
                  <AvField
                    name="name"
                    type="text"
                    className="form-control bg-light"
                    id="name"
                    value={defaultValue ? defaultValue.name : ""}
                    required
                    placeholder="Enter License Key Name ..."
                  />
                </FormGroup>

                <FormGroup className=" mb-4">
                  <Label htmlFor="zone">Zones</Label>
                  <div className="CustomSelect">
                    <CustomSelect
                      options={zoneOption}
                      selected={selected}
                      onChange={setSelected}
                      fetchMore={
                        zoneOption.length !== zone?.total
                          ? handleFetchMoreZones
                          : null
                      }
                      labelledBy="Select zones ..."
                    ></CustomSelect>
                  </div>
                  <div className="font-size-12">
                    Click <Link to="/zone_form">here</Link> to create a new zone
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="description"> Description</Label>
                  <textarea
                    className="form-control"
                    onChange={(e) => setDescription(e.target.value)}
                    id="description"
                    value={description}
                    rows="5"
                    // ref={descriptionRef}
                  ></textarea>
                </FormGroup>
              </Col>
            </Row>

            <button className="btn btn-success mr-2" type="submit">
              {loading
                ? "Submitting ..."
                : `${defaultValue ? "Update" : "Create"}`}
            </button>
            <Link to="#" onClick={() => history.goBack()}>
              <button className="btn btn-secondary">Cancel</button>
            </Link>
          </AvForm>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, licenseKeyError } = state.LicenseKey;
  return { message, loading, licenseKeyError };
};

export default connect(mapStateToProps, {
  createLicenseKey,
  createLicenseKeyFailed,
  updateLicenseKey,
  updateLicenseKeyFailed,
})(withRouter(Form));
