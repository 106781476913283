import AxiosInstance from './axiosInstance';
import Axios from 'axios';

const ScrollToTop = () => {
  return window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const getS3ImageUrlHandler = async (file, requestType) => {
  try {
    let result;
    if (file.path) {
      result = await AxiosInstance.get(
        `/cdn/getSignedUrl?module=${requestType}&name=${file.path}&mimeType=${file.type}`
      );
    } else {
      result = await AxiosInstance.get(
        `/cdn/getSignedUrl?module=${requestType}&name=${file.name}&mimeType=${file.type}`
      );
    }
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', `${file.type}`);

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: file,
      redirect: 'follow',
    };
    const res = await fetch(result.data.result.uploadUrl, requestOptions);
    if (res.status !== 200) {
      throw res.statusText;
    } else {
      return result;
    }
  } catch (error) {
    throw error;
  }
};

const getS3DocUrlHandler = async (file, requestType) => {
  let result = await AxiosInstance.get(
    `/cdn/getSignedUrl?module=${requestType}&name=${file.path}&mimeType=${file.type}`
  );
  var myHeaders = new Headers();

  myHeaders.append('Content-Type', file.type);

  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: file,
    redirect: 'follow',
  };
  const res = await fetch(result.data.result.uploadUrl, requestOptions);
  if (res.status !== 200) {
    throw res.statusText;
  } else {
    return result;
  }
};

const QueryReallignment = (queryData) => {
  if (queryData) {
    let query = '';
    for (const [key, value] of Object.entries(queryData)) {
      // console.log(value)
      if (
        value === null ||
        (typeof value === 'string' && value.trim() === '')
      ) {
      } else {
        let result = `${key}=${value}&`;
        query = result + query;
      }
    }
    let result = query.slice(0, -1);
    return result;
  }
};

export {
  ScrollToTop,
  getS3ImageUrlHandler,
  getS3DocUrlHandler,
  QueryReallignment,
};
