import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { ModalHeader, Container, ModalBody, Modal, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import GreySummaryCard from '../../../components/Common/Summary/index';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import {
  fetchBetaEmails,
  fetchEmailsForCSV,
  fetchEmailsForCSVError,
} from '../../../store/actions';

import BetaEmailsTable from './table';

const breadcrumbItems = [
  { title: 'Dashboard', link: '#' },
  { title: 'Beta Test Emails', link: '#' },
];

const Notification = ({
  fetchBetaEmails,
  emails,
  notificationError,
  loading,
  message,
  history,
  fetchEmailsForCSV,
  fetchEmailsForCSVError,
  csv,
  csv_loading,
}) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Beta Test Emails"
            breadcrumbItems={breadcrumbItems}
          />
          <GreySummaryCard field="Reports" subModule="Beta Test Email">
            <div>
              <>
                <BetaEmailsTable
                  emails={emails}
                  fetchBetaEmails={fetchBetaEmails}
                  loading={loading}
                  notificationError={notificationError}
                  message={message}
                  history={history}
                  fetchDataForCSV={fetchEmailsForCSV}
                  fetchForCSVError={fetchEmailsForCSVError}
                  csv={csv}
                  csv_loading={csv_loading}
                />
              </>
            </div>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    csv,
    csv_loading,
    emails,
    loading,
    notificationError,
    message,
    isAuthorize,
  } = state.Notifications;
  return {
    emails,
    loading,
    notificationError,
    message,
    isAuthorize,
    csv,
    csv_loading,
  };
};

export default connect(mapStateToProps, {
  fetchEmailsForCSV,
  fetchEmailsForCSVError,
  fetchBetaEmails,
})(Notification);
