import {
  FETCH_BONUS_LIST,
  FETCH_BONUS_LIST_SUCCESSFUL,
  FETCH_BONUS_LIST_API_FAILED,
  FETCH_USER_BONUS,
  FETCH_USER_BONUS_SUCCESSFULLY,
  FETCH_USER_BONUS_FAILED,
  FETCH_BONUS_LOG,
  FETCH_BONUS_LOG_SUCCESSFUL,
  FETCH_BONUS_LOG_API_FAILED,
  FETCH_BONUS_LOG_SUMMARY,
  FETCH_BONUS_LOG_SUMMARY_SUCCESSFUL,
  FETCH_BONUS_LOG_SUMMARY_API_FAILED,
  FETCH_EACH_BONUS_LOG,
  FETCH_EACH_BONUS_LOG_SUCCESSFUL,
  FETCH_EACH_BONUS_LOG_API_FAILED,
  FETCH_EACH_USER_BONUS,
  FETCH_EACH_USER_BONUS_SUCCESSFULLY,
  FETCH_EACH_USER_BONUS_FAILED,
  FETCH_BONUS,
  FETCH_BONUS_SUCCESSFUL,
  FETCH_BONUS_API_FAILED,
  FETCH_EACH_BONUS,
  FETCH_EACH_BONUS_SUCCESSFUL,
  FETCH_EACH_BONUS_API_FAILED,
  DELETE_BONUS,
  DELETE_BONUS_SUCCESSFUL,
  DELETE_BONUS_FAILED,
  CREATE_BONUS,
  CREATE_BONUS_SUCCESSFUL,
  CREATE_BONUS_FAILED,
  UPDATE_BONUS,
  UPDATE_BONUS_SUCCESSFUL,
  UPDATE_BONUS_FAILED,
  FETCH_BONUS_SUMMARY,
  FETCH_BONUS_SUMMARY_SUCCESSFULLY,
  FETCH_BONUS_SUMMARY_FAILED,
  FETCH_BONUS_CSV,
  FETCH_BONUS_CSV_SUCCESSFULLY,
  FETCH_BONUS_CSV_ERROR,
  FETCH_BONUS_LOG_CSV,
  FETCH_BONUS_LOG_CSV_SUCCESSFULLY,
  FETCH_BONUS_LOG_CSV_ERROR,
} from './actionTypes';

const initialState = {
  bonusList: null,
  bonusLog: null,
  bonusLogSummary: null,
  bonusLogPreview: null,
  userBonus: null,
  eachUserBonus: null,
  bonusError: null,
  bonus: null,
  tableError: null,
  bonusError: null,
  bonusPreview: null,
  isAuthorize: null,
  bonusSummary: null,
  summaryError: null,
  message: null,
  loading: false,
  csvData: null,
  csv_loading: false,
  logCSVData: null,
};

const Bonus = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BONUS:
    case FETCH_BONUS_LOG:
    case FETCH_USER_BONUS:
    case FETCH_BONUS_LIST:
    case UPDATE_BONUS:
    case DELETE_BONUS:
      state = {
        ...state,
        bonus: null,
        userBonus: null,
        bonusLog: null,
        bonusError: null,
        tableError: null,
        bonusError: null,
        loading: true,
        isAuthorize: null,
        message: null,
      };
      break;
    case FETCH_EACH_BONUS_LOG:
      state = {
        ...state,
        bonusLogPreview: null,
        bonusError: null,
        tableError: null,
        bonusError: null,
        loading: true,
        message: null,
      };
      break;

    case FETCH_EACH_USER_BONUS:
      state = {
        ...state,
        eachUserBonus: null,
        bonusError: null,
        loading: true,
        message: null,
      };
      break;

    case FETCH_EACH_USER_BONUS_SUCCESSFULLY:
      state = {
        ...state,
        eachUserBonus: action.payload,
        bonusError: null,
        loading: false,
        message: null,
      };
      break;

    case FETCH_EACH_USER_BONUS_FAILED:
      state = {
        ...state,
        eachUserBonus: null,
        bonusError: action.payload,
        loading: true,
        message: null,
      };
      break;

    case FETCH_USER_BONUS_SUCCESSFULLY:
      state = {
        ...state,
        userBonus: action.payload,
        tableError: null,
        bonusError: null,
        loading: false,
        message: null,
      };
      break;

    case FETCH_BONUS_SUCCESSFUL:
      state = {
        ...state,
        bonus: action.payload,
        tableError: null,
        bonusError: null,
        loading: false,
      };
      break;
    case FETCH_EACH_BONUS_LOG_SUCCESSFUL:
      state = {
        ...state,
        bonusLogPreview: action.payload,
        tableError: null,
        bonusError: null,
        loading: false,
      };
      break;

    case FETCH_BONUS_LIST_SUCCESSFUL:
      state = {
        ...state,
        bonusList: action.payload,
        tableError: null,
        bonusError: null,
        loading: false,
      };
      break;

    case FETCH_BONUS_LOG_SUCCESSFUL:
      state = {
        ...state,
        bonusLog: action.payload,
        tableError: null,
        bonusError: null,
        loading: false,
      };
      break;

    case DELETE_BONUS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        bonusError: null,
        message: 'Bonus details deleted successfully',
      };
      break;

    case FETCH_USER_BONUS_FAILED:
      state = {
        ...state,
        loading: false,
        userBonus: null,
        bonusError: action.payload,
        // message: 'Bonus details deleted successfully',
      };
      break;
    case UPDATE_BONUS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        bonusError: null,
        message: 'Bonus details updated Successfully',
      };
      break;

    case FETCH_BONUS_SUMMARY:
    case FETCH_BONUS_LOG_SUMMARY:
      return {
        ...state,
        bonusSummary: null,
        bonusLogSummary: null,
        summaryError: null,
        loading: true,
      };

    case FETCH_BONUS_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        bonusSummary: action.payload,
        summaryError: null,
        loading: false,
      };

    case FETCH_BONUS_SUMMARY_FAILED:
    case FETCH_BONUS_LOG_SUMMARY_API_FAILED:
      return {
        ...state,
        bonusSummary: null,
        bonusLogSummary: null,
        summaryError: action.payload,
        loading: false,
      };

    case FETCH_BONUS_LOG_SUMMARY_SUCCESSFUL:
      return {
        ...state,
        bonusLogSummary: action.payload,
        summaryError: null,
        loading: false,
      };
    case FETCH_EACH_BONUS:
      state = {
        ...state,
        loading: true,
        bonus: null,
        bonusError: null,
        message: null,
      };
      break;
    case FETCH_EACH_BONUS_SUCCESSFUL:
      state = {
        ...state,
        bonusPreview: action.payload,
        loading: false,
        bonusError: null,
        message: null,
      };
      break;

    case CREATE_BONUS:
      state = {
        ...state,
        loading: true,
        bonusError: null,
        message: null,
      };
      break;

    case CREATE_BONUS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        bonusError: null,
        message: `${action.payload.name} details added successfully for ${action.payload.accountName}`,
      };
      break;

    case FETCH_BONUS_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case CREATE_BONUS_FAILED:
    case FETCH_BONUS_LIST_API_FAILED:
    case FETCH_BONUS_LOG_API_FAILED:
    case FETCH_EACH_BONUS_LOG_API_FAILED:
    case DELETE_BONUS_FAILED:
    case FETCH_EACH_BONUS_API_FAILED:
      state = {
        ...state,
        bonus: null,
        bonusLog: null,
        bonusLogPreview: null,
        bonusPreview: null,
        loading: false,
        message: null,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_BONUS_FAILED:
      state = {
        ...state,
        loading: false,
        bonusError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;
    case FETCH_BONUS_CSV:
      state = {
        ...state,
        csvData: null,
        csv_loading: false,
      };
      break;

    case FETCH_BONUS_CSV_SUCCESSFULLY:
      state = {
        ...state,
        csvData: action.payload,
        csv_loading: false,
      };
      break;

    case FETCH_BONUS_CSV_ERROR:
      state = {
        ...state,
        csvData: null,
        csv_loading: false,
      };
      break;

    case FETCH_BONUS_LOG_CSV:
      state = {
        ...state,
        logCSVData: null,
        csv_loading: false,
      };
      break;

    case FETCH_BONUS_LOG_CSV_SUCCESSFULLY:
      state = {
        ...state,
        logCSVData: action.payload,
        csv_loading: false,
      };
      break;

    case FETCH_BONUS_LOG_CSV_ERROR:
      state = {
        ...state,
        logCSVData: null,
        csv_loading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Bonus;
