import React, { useState, useEffect } from 'react';
import { Label, Input, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import {
  fetchAllUser,
  fetchUserDetails,
  fetchUserError,
  fetchClearUser,
} from '../../../store/actions';
import Select from 'react-select';
const Debounced_Val = 1000;

const Autocomplete = ({
  title = 'Select user',
  user,
  users,
  fetchAllUser,
  userType,
  setUser,
  defaultUserId,
  fetchUserDetails,
  fetchUserError,
  fetchClearUser,
}) => {
  const [searchUser, setUserSearch] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [userData, setUserData] = useState([]);

  const onChangeHandler = (e) => {
    setSelectedUser(e);
    setUser(e);
  };

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      if (searchUser.length !== 0) {
        let searchInfo = { search: searchUser };
        if (userType) {
          searchInfo = { userType: userType, search: searchUser };
        }
        fetchAllUser(searchInfo);
        return;
      }
      // setUserData([]);
    }, Debounced_Val);

    return () => {
      clearTimeout(timeoutRef);
    };
  }, [searchUser]);

  useEffect(() => {
    if (users) {
      const usersOption = users?.result?.map((data) => {
        return {
          label: `${data.firstName} ${data.lastName} - (${data.userType})`,
          value: data.id,
        };
      });

      setUserData(usersOption);
    }
    if (defaultUserId) {
      fetchUserDetails(defaultUserId);
    }
  }, [users, defaultUserId]);

  // useEffect(() => {
  //   fetchClearUser('');
  // }, []);

  return (
    <FormGroup>
      {/* Input here  */}
      <Label className="text-capitalize"> {title} </Label>
      <Select
        name="userId"
        // defaultValue={selectedUser}
        value={selectedUser}
        // isLoading={isLoading}
        options={userData}
        onInputChange={(e) => setUserSearch(e)}
        onChange={onChangeHandler}
        required={true}
        placeholder={`Please type the owner's name`}
      />
    </FormGroup>
  );
};

const mapStateToProps = (state) => {
  const { user, users, loading, userError } = state.Users;
  return { user, users, loading, userError };
};

export default connect(mapStateToProps, {
  fetchAllUser,
  fetchUserDetails,
  fetchUserError,
  fetchClearUser,
})(Autocomplete);
