import React from 'react';
import moment from 'moment';

const DateComponent = ({ date }) => {
    const currentDate = moment().startOf('day');
    const formattedDate = moment(date).startOf('day');

    let formattedString;

    if (formattedDate.isSame(currentDate, 'day')) {
        formattedString = 'Today';
    } else if (formattedDate.isSame(currentDate.clone().subtract(1, 'day'), 'day')) {
        formattedString = 'Yesterday';
    } else if (formattedDate.isSame(currentDate.clone().subtract(2, 'day'), 'day')) {
        formattedString = '2 days ago';
    } else if (formattedDate.isAfter(currentDate.clone().subtract(1, 'week'), 'day')) {
        formattedString = 'A few days ago';
    } else if (formattedDate.isAfter(currentDate.clone().subtract(1, 'month'), 'day')) {
        const weeks = currentDate.diff(formattedDate, 'weeks');
        formattedString = `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
    } else if (formattedDate.isAfter(currentDate.clone().subtract(1, 'year'), 'day')) {
        const months = currentDate.diff(formattedDate, 'months');
        formattedString = `${months} month${months !== 1 ? 's' : ''} ago`;
    } else {
        formattedString = 'Some time back';
    }

    return <span>{formattedString}</span>;
};
export const TimeComponent = ({ date }) => {
    const currentTime = moment();
    const formattedTime = moment(date);

    let formattedString;

    // Check if the time difference is less than a minute
    if (currentTime.diff(formattedTime, 'seconds') < 60) {
        formattedString = 'a few seconds ago';
    }
    // Check if the time difference is less than an hour
    else if (currentTime.diff(formattedTime, 'minutes') < 60) {
        const minutes = currentTime.diff(formattedTime, 'minutes');
        formattedString = `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    }
    // Check if the time difference is less than 24 hours
    else if (currentTime.diff(formattedTime, 'hours') < 24) {
        const hours = currentTime.diff(formattedTime, 'hours');
        formattedString = `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    }
    // Display the full date
    else {
        formattedString = moment(date).format('hh:mm A');
        // { Moment(data?.createdAt).format('hh:mm A') }
    }

    return <span>{formattedString}</span>;
};

export default DateComponent;
