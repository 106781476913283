import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  UncontrolledTooltip,
} from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import AutoComplete from "../../../../components/Common/Autocomplete";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { fetchPackage } from "../../../../store/actions";
import { CustomSelect } from "react-multi-select-component";
import Select from "react-select";
// actions
import {
  unassignRole,
  assignRole,
  assignRoleFailed,
  fetchUserDetails,
  fetchUserDetailsError,
  fetchPackageFailed,
  addPackageToVehicles,
  deletePackageToVehicles,
} from "../../../../store/actions";
// import { addFileToFormData } from 'stream-chat/dist/types/utils';
const Form = ({
  unassignRole,
  packages,
  assignRole,
  isUnassign,
  assignRoleFailed,
  fetchPackageFailed,
  message,
  history,
  generalError,
  selectedVehicle,
  loading,
  setAddVehicleModal,
  roles,
  deletePackageToVehicles,
  fetchPackage,
  fetchUserDetails,
  fetchUserDetailsError,
  addPackageToVehicles,
  user,
}) => {
  const [errorMessage, setError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [packageId, setPackage] = useState("");
  const [packageOption, SetPackageOption] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selected, setSelected] = useState("");
  const [roleOption, SetRoleOption] = useState([]);
  const handleSubmit = async (event, values) => {
    setLoading(true);
    setError("");
    if (!selected) {
      setError(`Please choose a package to attached to vehicle`);
      setLoading(false);
      return;
    }

    let formData = { id: selected.value };

    if (selectedVehicle.length > 0) {
      formData.vehicles = selectedVehicle;
    }
    if (values.applyToALlVehicle === "true") {
      formData.all = true;
    }
    if (values.applyToALlVehicle === "false") {
      formData.all = false;
    }

    addPackageToVehicles(formData);
    setLoading(false);
  };

  const deletePackageHandler = () => {
    setError(null);
    if (!selected) {
      setError(`Please choose a package to remove from vehicle`);
      return;
    }

    let formData = { id: selected.value };
    if (selectedVehicle.length > 0) {
      formData.vehicles = selectedVehicle;
    }
    deletePackageToVehicles(formData);
  };
  useEffect(() => {
    fetchPackageFailed("");
    console.log("hekooooooooooo");
    if (!packages) {
      fetchPackage();
    }
  }, [packages]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setAddVehicleModal(false);
      }, 2000);
    }

    if (roles) {
      const roleArr = [];
      roles.result.forEach((element) => {
        const roleObj = {};
        roleObj.value = element.id;
        roleObj.label = element.name;
        roleArr.push(roleObj);
      });
      SetRoleOption(roleArr);
    }
  }, [assignRoleFailed, message, roles, user]);

  useEffect(() => {
    if (packages) {
      const packageArr = [];
      packages.result.forEach((element) => {
        const packageObj = {};
        packageObj.value = element.id;
        packageObj.label = element.name;
        packageArr.push(packageObj);
      });
      SetPackageOption(packageArr);
    }
  }, [packages]);

  useEffect(() => {
    if (selectedUser) {
      fetchUserDetails(selectedUser.value);
    }
  }, [selectedUser]);

  return (
    <div>
      {" "}
      <Card>
        <CardBody>
          {message && (
            <Alert color="success" className="text-center">
              {message}
            </Alert>
          )}
          {generalError && (
            <Alert color="danger" className="text-center">
              {/* {generalError} */}
            </Alert>
          )}
          {errorMessage && (
            <Alert color="danger" className="text-center">
              {errorMessage}
            </Alert>
          )}
          <div className="col-md-12">
            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col>
                  {selectedVehicle.length > 0 ? (
                    <p>
                      {" "}
                      You are about to add the selected{" "}
                      <span className="pl-1 border border-1 rounded bg-success shadow text-white font-weight-bolder">
                        {selectedVehicle.length}{" "}
                      </span>
                      a vehicles package !
                    </p>
                  ) : (
                    <FormGroup className="mb-4">
                      <Label
                        className="font-size-14"
                        htmlFor="isAcceptShareRide"
                      >
                        APPLY PACKAGE TO ALL VEHICLES ?
                      </Label>
                      <AvRadioGroup
                        inline
                        name="applyToALlVehicle"
                        value="true"
                        required
                      >
                        <AvRadio label="Yes" value={"true"} />
                        <AvRadio label="No" value={"false"} />
                      </AvRadioGroup>
                    </FormGroup>
                  )}

                  <FormGroup>
                    {/* Input here  */}
                    <div className="d-flex align-items-center mb-1">
                      <Label htmlFor="role" className="m-0">
                        Package
                      </Label>
                      <i
                        className="mdi mdi-alert-circle-outline ml-2 font-size-16"
                        id="priorityTooltip"
                      ></i>
                      <UncontrolledTooltip
                        placement="top"
                        target="priorityTooltip"
                      >
                        Select package to attached to vehicles
                      </UncontrolledTooltip>
                    </div>
                    <Select
                      name="packageType"
                      value={selected}
                      options={packageOption}
                      onInputChange={(e) => setPackage(e)}
                      onChange={setSelected}
                      required={true}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <button
                disabled={loading}
                className="btn btn-success mr-2"
                type="submit"
              >
                {loading ? "Submitting ..." : "Assign"}
              </button>
              {/* <Link to="#" onClick={() => deletePackageHandler()}>
                <button className="btn btn-danger">Unassign Package</button>
              </Link> */}
            </AvForm>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { packages, message, generalError, loading } = state.Packages;
  const { user } = state.Users;
  return { user, message, loading, generalError, packages };
};

export default connect(mapStateToProps, {
  unassignRole,
  assignRole,
  fetchPackage,
  assignRoleFailed,
  fetchUserDetails,
  fetchPackageFailed,
  addPackageToVehicles,
  fetchUserDetailsError,
  deletePackageToVehicles,
})(withRouter(Form));
