import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import { withRouter } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Moment from 'moment';
import Loader from '../Loading/index.js';
import { Link } from 'react-router-dom';
import { ScrollToTop } from '../../../utils/general';
import { connect } from 'react-redux';
import { fetchDriverLogs } from '../../../store/actions';

let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const DriverTable = ({
  driverLogs,
  loading,
  userError,
  approveMessage,
  approveError,
  user,
  fetchDriverLogs,
  message,
  history,
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [userInfo, setUserInfo] = useState(user.id);
  const [isActive, setActive] = useState('');

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(driverLogs?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      status: isActive,
      search,
    };
    fetchDriverLogs(pageInfo);
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }

    let pageInfo = { skip, take, status: isActive, search };
    fetchDriverLogs(pageInfo);
    ScrollToTop();
  };

  const routeToMapHadler = (url) => {
    window.open(url, '_blank') ||
      window.location.replace('https://support.wwf.org.uk');
  };

  useEffect(() => {
    if (driverLogs) {
      setData(tableDataHandler());
      setIsLoading(false);
      setPageCount(Math.ceil(driverLogs?.total / take));
    }
  }, [driverLogs]);

  useEffect(() => {
    // let parsedUrlParams = updateStateWithUrlHandler()
    if (user) {
      let infoParams = { id: user.id };
      fetchDriverLogs(infoParams);
    }
  }, [user]);

  const tableDataHandler = () => {
    const rowData = driverLogs?.result?.map((data) => {
      return {
        keyId: data.id,
        id: (
          <Link
            to={`/user/${data.driverId}`}
            className='mr-3 text-dark font-weight-bold'
          >
            {data.id.substring(0, 7)}
          </Link>
        ),
        location:
          data.country || data.state != null
            ? `${data?.country} , ${data?.state}`
            : '__',
        email: data.email ? data.email : '----',
        fullName: (
          <div className='text-capitalize'>
            {data?.firstName} {data?.lastName}
          </div>
        ),
        map_location: data.lat && data.lng && (
          <Link
            to='#'
            onClick={() =>
              routeToMapHadler(
                `https://www.google.com/maps/@${data.lat},${data.lng},17z`
              )
            }
            className='mr-3 text-dark font-weight-bold'
          >
            <div className='d-flex align-items-center bg-white shadow-lg border rounded'>
              <div>
                <i className=' ri-send-plane-fill font-size-18 mx-2 text-muted' />
              </div>
              <div>
                <div className=' d-block mb-1 font-size-12'>
                  Lng:
                  <span className='ml-2 badge badge-soft-secondary text-wrap'>
                    {data?.lng.toFixed(5)}
                  </span>
                </div>
                <div className=' d-block mb-1 text-wrap font-size-12'>
                  Lat:
                  <span className=' ml-2 badge badge-soft-secondary'>
                    {data?.lat.toFixed(5)}
                  </span>
                </div>
              </div>
            </div>
          </Link>
        ),
        address: data.address && data.address && (
          <span>
            <span className=' d-block mb-1 text-wrap'>
              <span className='badge badge-soft-secondary text-wrap'>
                {data?.address.split(',')[0]},{data?.address.split(',')[1]}
              </span>
            </span>
          </span>
        ),
        driver: (
          <Link to={`/user/${data.id}`} className='text-dark font-weight-bold'>
            <div className='d-flex align-items-center'>
              <img
                src={data?.picture ? data?.picture : avatarImg}
                alt=''
                height='20'
                className='rounded-circle avatar-xs mr-2'
              />

              <div>
                <div className='text-capitalize'>
                  {data.firstName} {data.lastName}
                </div>

                <span className='text-muted ml-2 badge badge-soft-secondary'>
                  <i className=' fas fa-star text-warning mr-1' />
                  {data?.rating ? data?.rating?.toFixed(2) : 0}
                </span>
                {data.gender && (
                  <span
                    className={`text-white mr-2 badge badge-${
                      data.gender === 'MALE' ? 'primary' : 'danger'
                    }`}
                  >
                    <i
                      className={`ri-${
                        data.gender === 'MALE' ? 'men' : 'women'
                      }-line mr-1`}
                    />
                    {data.gender}
                  </span>
                )}
              </div>
            </div>
          </Link>
        ),
        date: (
          <div>
            {' '}
            <div>{Moment(data.createdAt).format('l')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
        status: (
          <div
            className={`badge badge-soft-${
              data?.status === 'NONE'
                ? 'danger'
                : data?.status === 'BUSY'
                ? 'warning'
                : data?.status === 'AVAILABLE'
                ? 'success'
                : 'secondary'
            } font-size-12`}
          >
            {data?.status === 'OFFLINE'
              ? 'NOT AVAILABLE'
              : data?.status === 'NONE'
              ? 'OFFLINE'
              : data?.status}
          </div>
        ),
      };
    });
    return {
      columns: [
        // {
        //   text: 'Log Id',
        //   dataField: 'id',
        // },
        // {
        //   text: 'Name',
        //   dataField: 'fullName',
        // },

        {
          text: 'Email',
          dataField: 'email',
        },
        {
          text: 'Address',
          dataField: 'address',
        },
        {
          text: 'Map Location',
          dataField: 'map_location',
        },
        {
          text: 'Registered Date',
          dataField: 'date',
        },
        {
          text: 'Status',
          dataField: 'status',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-0'>
              {/* <div className="d-flex align-items-center justify-content-end mb-4">
                {' '}
                <div className="d-flex align-items-center">
                  <TimerCountDown startTimer={startTimer} />
                </div>
              </div> */}
              {!isLoading && data !== null ? (
                <>
                  {/* <div className="d-flex justify-content-between mb-2">
                    <div>
                      <Input
                        bsSize="sm"
                        id="take"
                        name="take"
                        type="select"
                        onChange={handleLimitChange}
                      >
                        <option>{take}</option>
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>

                    <div>
                      <Input
                        bsSize="sm"
                        id="search"
                        value={search}
                        name="search"
                        onChange={SearchHandler}
                        placeholder="Search"
                      />
                    </div>
                  </div> */}
                  <BootstrapTable
                    keyField='keyId'
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses='table-responsive'
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const {
    driverLogs,
    loading,
    userError,
    message,
    approveMessage,
    approveError,
  } = state.Users;
  return {
    driverLogs,
    loading,
    userError,
    message,
    approveMessage,
    approveError,
  };
};

export default connect(mapStatetoProps, {
  fetchDriverLogs,
})(withRouter(DriverTable));
