import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
// import MiniWidgets from './Components/MiniWidgets.js';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import LoyaltyTierTable from './loyaltyTierTable';
import PreviewLoyaltyTier from './previewLoyaltyTier';
import CheckAction, { ReadCheck } from '../../../../components/Common/Summary/actionCheck';
import GreySummaryCard from '../../../../components/Common/Summary/index';
import { fetchLoyaltyTier, deleteLoyaltyTier } from '../../../../store/actions';

const LoyaltyTier = ({
  loyaltyTier,
  fetchLoyaltyTier,
  deleteLoyaltyTier,
  tableError,
  loading,
  message,
  history,
  loyaltyTierError,
  isAuthorize,
  accessControl
}) => {
  const [showModal, setShowModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const breadcrumbItems = [
    { title: 'Loyalty', link: '/loyalty_users' },
    { title: 'Loyalty Tier', link: '#' },
  ];

  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };

  const deleteLoyaltyTierModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteLoyaltyTier(deleteId);
    setDeleteId(null);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Loyalty Tier' breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field='Loyalty' subModule='Loyalty Tier'>
            {!isAuthorize && (
              <div className='mb-2 text-right'>
                <CheckAction
                  field='Loyalty'
                  subModule='Loyalty Tier'
                  iconType={'Create'}
                >
                  <Link to='/loyaltyTier_Form'>
                    <Button color='success'>
                      <i className='mdi mdi-plus-circle-outline font-size-18'></i>{' '}
                      Add Loyalty Tier
                    </Button>
                  </Link>
                </CheckAction>
              </div>
            )}
            {message && (
              <Alert color='success' className='text-center'>
                {message}
              </Alert>
            )}
            <Row>
              <Col>
                <Row>{/* <MiniWidgets /> */}</Row>
                <LoyaltyTierTable
                  loyaltyTierData={loyaltyTier}
                  loading
                  deleteLoyaltyTier={deleteLoyaltyTierModal}
                  fetchLoyaltyTier={fetchLoyaltyTier}
                  previewAction={previewHandler}
                  isAuthorize={isAuthorize}
                  history={history}
                  message={message}
                  CheckAction={CheckAction}
                  ReadCheck={ReadCheck}
                  accessControl={accessControl}
                />
              </Col>
            </Row>
          </GreySummaryCard>
        </Container>
        <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
          <ModalHeader toggle={() => setShowModal(false)}>
            Preview Loyalty Tier
          </ModalHeader>
          <ModalBody>
            <PreviewLoyaltyTier previewId={previewId} />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={showDeleteModal}
          toggle={() => setShowDeleteModal(!showDeleteModal)}
        >
          <ModalBody>
            <h4 className='card-title mb-4 text-center'>
              {' '}
              Are you sure you want to delete this Loyalty Tier
            </h4>
            <button
              onClick={() => deleteHandler()}
              className='btn btn-outline-danger btn-sm w-100'
            >
              Click to Delete
            </button>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const {
    loyaltyTier,
    loading,
    loyaltyTierError,
    isAuthorize,
    message,
    tableError,
  } = state.LoyaltyTier;
  return {
    accessControl,
    loyaltyTier,
    loading,
    loyaltyTierError,
    isAuthorize,
    message,
    tableError,
  };
};

export default connect(mapStateToProps, {
  fetchLoyaltyTier,
  deleteLoyaltyTier,
})(LoyaltyTier);
