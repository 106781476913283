import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Card, CardBody } from 'reactstrap';

const TableLoader = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody className="pb-0">
          <h4>
            <Skeleton duration={10} width="100%" />
          </h4>
        </CardBody>
        <CardBody className="border-top py-1">
          <p>
            <Skeleton duration={10} count={20} width="100%" />
          </p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default TableLoader;
