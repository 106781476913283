import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Input,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactPaginate from 'react-paginate';
import Loader from '../../../../components/Common/Loading/index.js';
import { CSVLink } from 'react-csv';
import { QueryReallignment } from '../../../../utils/general';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Moment from 'moment';
import { Link } from 'react-router-dom';
import { ScrollToTop } from '../../../../utils/general';

let skip = 0;
let dataLimit = 10;

const TripsTable = ({
  rides,
  fetchMyRide,
  previewHandler,
  statusIdHandler,
  history,
  deleteRide,
  user,
  fetchForCSVError,
  fetchDataForCSV,
  csv,
  csv_loading,
  CheckAction,
  ReadCheck,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [pageCount, setPageCount] = useState('');
  const [search, setSearch] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [status, setStatus] = useState('');

  const handleLimitChange = async (e) => {
    let pageInfo;
    setPageCount(Math.ceil(rides?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    pageInfo = { skip: pageNumber, take: dataLimit, status, search };
    fetchMyRide(pageInfo);
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    let pageInfo;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }

    pageInfo = { skip, take, status, search };
    fetchMyRide(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      // skip,
      status,
    };
    fetchMyRide(queryParams);
  };
  const GetRideByStatus = (status) => {
    setStatus(status);
    let queryParams = {
      search,
      take,
      skip,
      status,
    };
    fetchMyRide(queryParams);
  };
  const handleCSV = (e, values) => {
    fetchDataForCSV({
      user,
      take: values.endRow ? values.endRow - values.startRow + 1 : rides?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
    });
  };
  useEffect(() => {
    if (rides) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setPageCount(Math.ceil(rides?.total / take));
    }
  }, [rides]);
  useEffect(() => {
    if (csv) {
      // SET CSV EXPORT DATA
      const csvData = csv?.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Driver: data?.driver
            ? `${data?.driver?.firstName} - ${data?.driver?.lastName}`
            : 'Not Assigned',
          Rider: `${data?.rider?.firstName} - ${data?.rider?.lastName}`,
          pickUp: data?.pickUp?.address,
          dropOff: data?.dropOff?.address,
          Payment_Method: data?.paymentMethod ? data.paymentMethod : '----',
          ridePackage: data?.ridePackage ? data.ridePackage.name : '----',
          'Distance(m)': data?.duration ? data.duration : '----',
          'Duration(s)': data?.duration ? data.duration : '----',
          'DurationInTraffic(s)': data?.durationInTraffic
            ? data.durationInTraffic
            : '----',
          'TotalAmount(₦)': data?.totalAmount ? data.totalAmount : '----',
          ArrivalTime: data?.arrivalTime
            ? data?.arrivalTime?.substring(11, 18)
            : '----',
          StartTime: data?.startTime
            ? data?.startTime?.substring(11, 18)
            : '----',
          EndTime: data?.endTime ? data?.endTime?.substring(11, 18) : '----',
          'BasePrice(₦)': data?.bill ? data?.bill.basePrice : '----',
          'DistancePrice(₦)': data?.bill ? data?.bill.distancePrice : '----',
          'TimePrice(₦)': data?.bill ? data?.bill.timePrice : '----',
          'ServiceCharge(₦)': data?.bill ? data?.bill.serviceCharge : '----',
          'Discount(₦)': data?.bill ? data?.bill.discount : '----',
          'SurgePrice(₦)': data?.bill ? data?.bill.surgePrice : '----',
          'WaitPrice(₦)': data?.bill ? data?.bill.waitPrice : '----',
          'Tax(₦)': data?.bill ? data?.bill.tax : '----',
          'Toll(₦)': data?.bill ? data?.bill.toll : '----',
          Status: data?.status,
          Cancelled_By:
            data?.cancellation?.reasonKey === 'SYSTEM_REQUEST'
              ? 'SYSTEM'
              : data?.cancellation?.requester,
          Created_Date: data?.createdAt?.substring(0, 10),
        };

        return formData;
      });

      setCsvData(csvData);
    }
  }, [csv]);

  useEffect(() => {
    if (!showCSVModal) {
      fetchForCSVError();
    }
  }, [showCSVModal]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchMyRide(parsedUrlParams);
  }, [fetchMyRide]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      status,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.status) {
      setStatus(parsedUrlParams?.status);
    }
    return parsedUrlParams;
  };

  let accessChecking = ReadCheck(accessControl, `Rides`, 'My Rides');
  const tableDataHandler = () => {
    const rowData = rides?.result?.map((data) => {
      return {
        id: data.id,
        date: (
          <div>
            <div>{Moment(data.createdAt).format('D/M/YYYY')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
        driverName:
          data.driver !== null ? (
            <div className='text-capitalize'>
              {data?.driver.firstName} {data?.driver.lastName}
            </div>
          ) : (
            'Not Assigned'
          ),
        userName: (
          <div className='text-capitalize'>
            {data?.rider.firstName} {data?.rider.lastName}
          </div>
        ),
        status: (
          <div className='d-flex flex-column align-items-start'>
            <h1
              className={`badge badge-soft-${data.status === 'PROCESSING'
                ? 'warning'
                : data.status === 'ACCEPTED'
                  ? 'success'
                  : data.status === 'CANCELLED'
                    ? 'danger'
                    : data.status === 'COMPLETED'
                      ? 'primary'
                      : 'dark'
                } font-size-12`}
            >
              {data.status}
            </h1>
            {data.status === 'CANCELLED' && (
              <p
                className={`badge badge-soft-secondary  font-size-10 m-0`}
                style={{
                  paddingTop: '4px',
                  background: `${data?.cancellation?.reasonKey === 'SYSTEM_REQUEST'
                    ? '#6c757d2e'
                    : data?.cancellation?.requester === 'RIDER'
                      ? '#f18357'
                      : data?.cancellation?.requester === 'ADMIN'
                        ? '#007bff'
                        : '#00a859'
                    }`,
                  color: `${data?.cancellation?.reasonKey !== 'SYSTEM_REQUEST'
                    ? 'white'
                    : 'black'
                    }`,
                }}
              >
                {data?.cancellation?.reasonKey === 'SYSTEM_REQUEST'
                  ? 'SYSTEM'
                  : data?.cancellation?.requester}
              </p>
            )}
          </div>
        ),
        paymentMethod:
          data.paymentMethod === 'ACCOUNT' ? (
            <span>
              <span className='d-block'>
                <i className='fas fa-wallet' /> NPAY{' '}
                {data.redemption && '/ PROMO'}
              </span>
              <div>
                <span className='badge badge-soft-success'>
                  ₦{data.totalAmount?.toLocaleString('en-US')}
                </span>
                {data.redemption && (
                  <>
                    -
                    <span className='badge badge-soft-danger'>
                      ₦{data.bill?.discount?.toLocaleString('en-US')}
                    </span>
                  </>
                )}
              </div>
            </span>
          ) : data.paymentMethod === 'CASH' ? (
            <span>
              <span className='d-block'>
                <i className='fas fa-money-bill-wave' /> {data.paymentMethod}
                {data.redemption && '/ PROMO'}
              </span>
              <div>
                <span className='badge badge-soft-success'>
                  ₦{data.totalAmount?.toLocaleString('en-US')}
                </span>
                {data.redemption && (
                  <>
                    -
                    <span className='badge badge-soft-danger'>
                      ₦{data.bill?.discount?.toLocaleString('en-US')}
                    </span>
                  </>
                )}
              </div>
            </span>
          ) : (
            <span>
              <span className='d-block'>
                <i className='fas fa-wallet' />
                {data.paymentMethod} {data.redemption && '/ PROMO'}
              </span>
              <div>
                <span className='badge badge-soft-success'>
                  ₦{data.totalAmount?.toLocaleString('en-US')}
                </span>
                {data.redemption && (
                  <>
                    -
                    <span className='badge badge-soft-danger'>
                      ₦{data.bill?.discount?.toLocaleString('en-US')}
                    </span>
                  </>
                )}
              </div>
            </span>
          ),
        packageType: <span> {data.ridePackage.name} </span>,
        action: (
          <div className='d-flex align-items-center'>
            <CheckAction field='Rides' subModule='My Rides' iconType='Read'>
              <Link
                to={`/ride/${data.id}?dId=${data.id}`}
                className='mr-3 text-dark'
                id='edit'
              >
                <i className='mdi mdi-eye p-2 font-size-16 mb-2'></i>
              </Link>
              <UncontrolledTooltip
                placement='top'
                className='p-0 bg-light'
                target='edit'
              >
                Preview
              </UncontrolledTooltip>
            </CheckAction>
            {user.userType === 'DRIVER' && (
              <>
                <CheckAction field='Rides' subModule='My Rides' iconType='Edit'>
                  <Link
                    to='#'
                    onClick={() => statusIdHandler(data.id, data.status)}
                    className='text-primary mr-3'
                    id='preview'
                    style={
                      data.status === 'CANCELLED' || data.status === 'COMPLETED'
                        ? { pointerEvents: 'none', opacity: '0.5' }
                        : null
                    }
                  >
                    <i className=' fas fa-exchange-alt font-size-16'></i>
                  </Link>
                  <UncontrolledTooltip placement='top' target='preview'>
                    Change Status
                  </UncontrolledTooltip>
                </CheckAction>
                <CheckAction field='Rides' subModule='My Rides' iconType='Edit'>
                  <Link
                    to={`/ride_form?id=${data.id}`}
                    className='mr-3 text-primary'
                    id='edit1'
                    style={
                      data.status === 'CANCELLED' || data.status === 'COMPLETED'
                        ? { pointerEvents: 'none', opacity: '0.5' }
                        : null
                    }
                  >
                    <i className='mdi mdi-pencil font-size-16'></i>
                  </Link>
                  <UncontrolledTooltip placement='top' target='edit1'>
                    Edit
                  </UncontrolledTooltip>
                  <Link
                    to='#'
                    className='mr-3 text-danger'
                    id='cancelled'
                    style={
                      data.status === 'CANCELLED' || data.status === 'COMPLETED'
                        ? { pointerEvents: 'none', opacity: '0.5' }
                        : null
                    }
                    onClick={() => previewHandler(data.id)}
                  >
                    <i className='fas fa-times p-2 font-size-16 mt-2'></i>
                  </Link>
                  <UncontrolledTooltip placement='top' target='cancelled'>
                    Cancel Ride
                  </UncontrolledTooltip>
                </CheckAction>
              </>
            )}
            {user.userType === 'RIDER' && (
              <>
                <CheckAction field='Rides' subModule='My Rides' iconType='Edit'>
                  <Link
                    to='#'
                    className='mr-3 text-danger'
                    id='cancelled'
                    style={
                      data.status === 'PROCESSING' ||
                        data.status === 'ACCEPTED' ||
                        data.status === 'APPROACHING'
                        ? null
                        : { pointerEvents: 'none', opacity: '0.5' }
                    }
                    onClick={() => previewHandler(data.id)}
                  >
                    <i className='fas fa-times p-2 font-size-16 mt-2'></i>
                  </Link>
                  <UncontrolledTooltip placement='top' target='cancelled'>
                    Cancel Ride
                  </UncontrolledTooltip>
                </CheckAction>
                {data.status === 'CANCELLED' || data.status === 'COMPLETED' ? (
                  <>
                    <CheckAction field='Rides' subModule='My Rides' iconType='Edit'>
                      <Link
                        to={`/ride_form?rebooking=${data.id}`}
                        // onClick={() => deleteRide(data.id)}
                        className='text-primary'
                        id='rebook'
                      >
                        <i className='mdi mdi-autorenew font-size-16'></i>
                      </Link>
                      <UncontrolledTooltip placement='top' target='rebook'>
                        Rebook Ride
                      </UncontrolledTooltip>
                    </CheckAction>
                  </>
                ) : null}
              </>
            )}

            {/* <Link
              to='#'
              onClick={() => deleteRide(data.id)}
              className='text-danger'
              id='delete1'
            >
              <i className='mdi mdi-trash-can font-size-16'></i>
            </Link>
            <UncontrolledTooltip placement='top' target='delete1'>
              Delete
            </UncontrolledTooltip> */}
          </div>
        ),
      };
    });
    return {
      columns: [
        // {
        //   text: 'Trip ID',
        //   dataField: 'id',
        // },
        {
          text: 'Rider',
          dataField: 'userName',
        },
        {
          text: 'Driver',
          dataField: 'driverName',
        },
        {
          text: 'Payment Method',
          dataField: 'paymentMethod',
        },
        {
          text: 'Date',
          dataField: 'date',
        },
        {
          text: 'Package Type',
          dataField: 'packageType',
        },
        {
          text: 'Trip Status',
          dataField: 'status',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <div>
                <div className='float-right'>
                  <select
                    className='custom-select custom-select-sm'
                    onChange={(e) => {
                      GetRideByStatus(e.target.value);
                    }}
                  >
                    <option value=''>All Trips</option>
                    <option value='PROCESSING'>Processing</option>
                    <option value='ACCEPTED'>Accepted</option>
                    <option value='APPROACHING'>Approaching</option>
                    <option value='ARRIVED'>Arrived</option>
                    <option value='INROUTE'>Inroute</option>
                    <option value='CANCELLED'>Cancelled</option>
                    <option value='COMPLETED'>Completed</option>
                  </select>
                </div>
                <h4> Rides History </h4>
              </div>
              {!isLoading && data !== null ? (
                <>
                  <div className='d-flex justify-content-end  align-items-center mb-2'>
                    <div className=''>
                      <Button
                        size='sm'
                        color='primary'
                        className='mt-1'
                        onClick={() => setShowCSVModal(true)}
                        disabled={rides?.total > 0 ? false : true}
                      >
                        <i className=' fas fa-cloud-download-alt mr-1' />
                        CSV
                      </Button>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between mb-2'>
                    <div>
                      <Input
                        bsSize='sm'
                        id='take'
                        name='take'
                        type='select'
                        onChange={handleLimitChange}
                      >
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>
                    <div>
                      <Input
                        bsSize='sm'
                        id='search'
                        value={search}
                        name='search'
                        onChange={SearchHandler}
                        placeholder='Search'
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField='id'
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses='table-responsive'
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    // breakLabel={'...'}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={3}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    //Bootstrap styling
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* CSV DOWNLOAD MODAL */}
      <Modal
        isOpen={showCSVModal}
        toggle={() => setShowCSVModal(!showCSVModal)}
      >
        <ModalHeader toggle={() => setShowCSVModal(false)}>
          Generate CSV
        </ModalHeader>
        <ModalBody>
          <AvForm className='form-horizontal' onValidSubmit={handleCSV}>
            <Col>
              <FormGroup>
                <Label htmlFor='startRow'>Start (Row)</Label>
                <AvField
                  name='startRow'
                  type='number'
                  max={rides?.total - 1}
                  className='form-control'
                  id='startRow'
                  placeholder='Enter the row to start from'
                  errorMessage='value must be greater than 0 and less than total data'
                  min='1'
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor='endRow'>End (Row)</Label>
                <AvField
                  name='endRow'
                  type='number'
                  className='form-control'
                  min='1'
                  max={rides?.total}
                  id='endRow'
                  placeholder='Enter the row to end'
                  helpMessage={
                    rides?.total ? `Total data is ${rides?.total}` : null
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Button
              size='sm'
              color='primary'
              className='ml-3 waves-effect waves-light '
              type='submit'
            >
              {csv_loading ? 'Generating...' : 'Generate'}
            </Button>
            {csv && (
              <CSVLink
                data={csvData}
                filename={'rides_data.csv'}
                className='btn-sm btn-success ml-2'
                target='_blank'
                onClick={() => setShowCSVModal(false)}
              >
                Download
              </CSVLink>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(TripsTable);
