/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import {
  Alert, Row, Col, Table, Modal,
  ModalBody
} from 'reactstrap';
import Loader from '../../../components/Common/Loading/index.js';
import { fetchEachBusStop } from '../../../store/actions.js';
import { Link } from 'react-router-dom';

const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const PreviewBusStop = ({
  busStop,
  previewId,
  loading,
  busStopError,
  fetchEachBusStop,
}) => {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    fetchEachBusStop(previewId);
  }, [fetchEachBusStop, previewId]);

  return (
    <React.Fragment>
      <div className='mt-3'>
        {busStopError && !loading ? (
          <Alert color='danger' className='text-center'>
            {busStopError}
          </Alert>
        ) : (
          <>
            {busStop === null ? (
              <Loader />
            ) : (
              <Row>
                <Col sm={12} className='text-center'>
                  {busStop?.image &&
                    <div
                      className=' my-0 mx-auto rounded w-auto'
                      style={{ maxWidth: 'fit-content', background: '#F1F8E9' }}
                    >
                      <img
                        src={busStop?.image}
                        alt='suv'
                        className='avatar-md cursor-pointer'
                        onClick={() => {
                          setShowImage(true);
                        }}
                      />
                    </div>
                  }
                  {busStop?.tag &&
                    <span className='badge badge-success text-capitalize'>
                      {busStop?.tag}
                    </span>
                  }
                  <p className="font-size-12">{busStop?.description}</p>
                </Col>
                <Col sm={12}>
                  <div>
                    <Table hover>
                      <tbody>
                        <tr>
                          <td className='font-weight-bold'>
                            Name{' '}
                          </td>
                          <td className='text-uppercase font-size-16 font-weight-bold text-success'>
                            {busStop?.name}
                          </td>
                        </tr>


                        <tr>
                          <td className='font-weight-bold'>Address</td>
                          <td className='text-uppercase text-truncate' style={{
                            whiteSpace: 'normal',
                            width: '75%'
                          }}>
                            {`${(busStop?.address ? busStop?.address : null)},
              ${(busStop?.state ? busStop?.state : null)},
              ${(busStop?.country ? busStop?.country : null)
                              }`}
                          </td>
                        </tr>

                        <tr>
                          <td className='font-weight-bold'>Created At</td>
                          <td className='text-uppercase'>
                            {Moment(busStop.createdAt).format('l')}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Updated At</td>
                          <td className='text-uppercase'>
                            {Moment(busStop.updatedAt).format('l')}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
      <Modal
        isOpen={showImage}
        toggle={() => setShowImage(!showImage)}
        size='lg'
        style={{ left: '5%' }}
        centered={true}
      >
        <ModalBody className='p-0 m-2' style={{ height: '70vh' }}>
          <img
            src={busStop?.image}
            alt='Bus Stop img'
            style={{ width: '-webkit-fill-available', height: '100%' }}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { busStop, loading, busStopError } = state.BusStop;
  return { busStop, loading, busStopError };
};

export default connect(mapStateToProps, {
  fetchEachBusStop,
})(PreviewBusStop);
