import React, { useEffect } from 'react';
import { Card, Alert, Row, Col, FormGroup, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// actions
import {
  passwordSetting,
  passwordSettingError,
} from '../../../../store/actions';

import { AvForm, AvField } from 'availity-reactstrap-validation';

const PasswordSetting = ({
  passwordSetting,
  loading,
  forgetError,
  passwordSettingError,
  message,
  history,
}) => {
  const handleSubmit = (event, values) => {
    const payload = {
      oldPassword: values.password,
      password: values.newPassword,
    };
    passwordSetting(payload);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        passwordSettingError('');
        history.push('/settings');
      }, [3000]);
    }
  }, [message]);

  return (
    <div className='col-md-6'>
      {message ? (
        <Alert color='success' className='mb-4'>
          {message}
        </Alert>
      ) : null}

      {forgetError && (
        <Alert color='danger' className='text-center'>
          {forgetError}
        </Alert>
      )}

      <AvForm className='form-horizontal' onValidSubmit={handleSubmit}>
        <Row>
          <Col md={12}>
            <FormGroup className='mb-4'>
              <Label htmlFor='userpassword'> Old Password</Label>
              <AvField
                name='password'
                type='password'
                className='form-control '
                id='userpassword'
                placeholder='Old Password'
                required
              />
            </FormGroup>
          </Col>
          <hr className='my-2 bg-secondary text-secondary' />
          <Col md={12}>
            <FormGroup className='mb-4'>
              <Label htmlFor='newPassword'>New Password</Label>
              <AvField
                name='newPassword'
                errorMessage='password is required'
                // value={this.state.password}
                // validate={{
                //   match: { value: 'password' },
                // }}
                type='password'
                className='form-control'
                id='newPassword'
                placeholder='Enter New password'
                required
              />
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup className='mb-4'>
              <Label htmlFor='confirmPassword'>Confirm Password</Label>
              <AvField
                name='confirmPassword'
                errorMessage='password not the same'
                // value={this.state.password}
                validate={{
                  match: { value: 'newPassword' },
                }}
                type='password'
                className='form-control'
                id='confirmPassword'
                placeholder='New password'
                required
              />
            </FormGroup>
          </Col>
        </Row>

        <button className='btn btn-success mr-2' type='submit'>
          {loading ? 'Loading...' : 'Save'}
        </button>
      </AvForm>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, forgetError } = state.Account;
  return { message, loading, forgetError };
};

export default withRouter(
  connect(mapStateToProps, { passwordSetting, passwordSettingError })(
    PasswordSetting
  )
);
