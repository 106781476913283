import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
} from 'reactstrap';
import { useLocation, Link } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import Form from './form';

import {
  fetchEachDocument,
  deleteDocument,
} from '../../../../../store/actions';

const DocumentForm = ({
  fetchEachDocument,
  documentPreview,
  deleteDocument,
  loading,
  message,
}) => {
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [showImgModal, setShowImgModal] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [docName, setDocName] = useState('');
  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'User', link: '#' },
    { title: 'Document', link: '#' },
  ];

  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const docId = new URLSearchParams(search).get('docId');

  useEffect(() => {
    if (id) {
      fetchEachDocument(id);
    }
    if (docId) {
      fetchEachDocument(docId);
    }
  }, []);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const updatePdfModalDisplay = (e) => {
    setShowPdfModal(e);
  };
  const updateImgModalDisplay = (e) => {
    setShowImgModal(e);
  };
  const updateDocName = (e) => {
    setDocName(e);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <React.Fragment>
      <div className='page-content'>
        <Form
          loading={loading}
          defaultValue={documentPreview}
          message={message}
          deleteDocument={deleteDocument}
          updatePdfModalDisplay={updatePdfModalDisplay}
          updateImgModalDisplay={updateImgModalDisplay}
          updateDocName={updateDocName}
          docId={docId}
        />
      </div>
      <Modal
        size='lg'
        isOpen={showPdfModal}
        toggle={() => setShowPdfModal(!showPdfModal)}
      >
        <ModalHeader toggle={() => setShowPdfModal(false)}>
          {docName}
        </ModalHeader>
        <ModalBody>
          <center>
            <Document
              file={documentPreview?.documentUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={console.error}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  height={800}
                  key={`pages_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          </center>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showImgModal}
        toggle={() => setShowImgModal(!showImgModal)}
        size='lg'
      >
        <ModalHeader toggle={() => setShowImgModal(false)}>
          {docName}
        </ModalHeader>
        <ModalBody>
          <center>
            <img
              data-dz-thumbnail=''
              height={600}
              className='rounded bg-light w-100'
              alt='name'
              src={documentPreview?.documentUrl}
            />
          </center>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { documentPreview, loading, documentError, message } = state.Document;
  return { documentPreview, loading, documentError, message };
};

export default connect(mapStateToProps, { fetchEachDocument, deleteDocument })(
  DocumentForm
);
