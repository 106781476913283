import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import Form from './form';

const InvitationForm = ({}) => {
  return (
    <React.Fragment>
      <div className='page-content'>
        <Form />
      </div>
    </React.Fragment>
  );
};

export default InvitationForm;
