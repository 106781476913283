import React from 'react';
import { Alert } from 'reactstrap';

const AlertError = ({ error }) => {
  return (
    <Alert color="danger" className="text-center">
      {typeof(error) === "object" ? 'Error in processing data' : error}
    </Alert>
  );
};

export default AlertError;