import {
  FETCH_CASHOUT_ANALYTIC,
  FETCH_CASHOUT_ANALYTIC_SUCCESSFUL,
  FETCH_CASHOUT_ANALYTIC_API_FAILED,
  FETCH_EACH_CASHOUT_ANALYTIC,
  FETCH_EACH_CASHOUT_ANALYTIC_SUCCESSFUL,
  FETCH_EACH_CASHOUT_ANALYTIC_API_FAILED,
} from './actionTypes';

const initialState = {
  cashoutAnalytics: null,
  cashoutAnalyticsError: null,
  tableError: null,
  cashoutAnalyticsPreview: null,
  message: null,
  loading: false,
};

const cashout = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CASHOUT_ANALYTIC:
      state = {
        ...state,
        cashoutAnalytics: null,
        cashoutAnalyticsError: null,
        tableError: null,
        cashoutRecipient: null,
        loading: true,
        message: null,
      };
      break;

    case FETCH_CASHOUT_ANALYTIC_SUCCESSFUL:
      state = {
        ...state,
        tableError: null,
        cashoutAnalytics: action.payload,
        cashoutAnalyticsError: null,
        loading: false,
      };
      break;

    case FETCH_EACH_CASHOUT_ANALYTIC:
      state = {
        ...state,
        loading: true,
        tableError: null,
        cashoutAnalyticsError: null,
        message: null,
      };
      break;
    case FETCH_EACH_CASHOUT_ANALYTIC_SUCCESSFUL:
      state = {
        ...state,
        cashoutAnalyticsPreview: action.payload,
        loading: false,
        cashoutAnalyticsError: null,
        message: null,
      };
      break;

    case FETCH_CASHOUT_ANALYTIC_API_FAILED:
      state = {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case FETCH_EACH_CASHOUT_ANALYTIC_API_FAILED:
      return {
        ...state,
        cashoutAnalyticsPreview: null,
        cashoutAnalyticsError: action.payload,
        loading: false,
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default cashout;
