import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  FormGroup,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import FilterDropDown from '../../../../components/Common/FilterDropDown';
import SimpleBar from 'simplebar-react';
import ReactPaginate from 'react-paginate';
import queryString from 'query-string';
import Loader from '../../../../components/Common/Loading/index.js';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../../utils/general';
import Moment from 'moment';
import moment from 'moment-timezone';
import { fetchLoyalty } from '../../../../store/actions';
import CheckAction, { ReadCheck } from '../../../../components/Common/Summary/actionCheck';

let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const LoyaltyTable = ({
  history,
  loyalty,
  tableError,
  fetchLoyalty,
  pointHandler,
  message,
  loyaltyError,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [menu, setMenu] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });
  const [tier, setTier] = useState('');

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(loyalty?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      search,
      start: date.start,
      end: date.end,
      tier,
    };
    fetchLoyalty(pageInfo);
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = {
      skip,
      take,
      search,
      start: date.start,
      end: date.end,
      tier,
    };
    fetchLoyalty(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      // skip,
      start: date.start,
      end: date.end,
    };
    fetchLoyalty(queryParams);
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    setMenu(false);
    setStatus(values.loyaltyStatus);
    let queryParams = {
      search,
      take,
      skip,

      type: values.loyaltyStatus,
      start: values.startDate,
      end: values.endDate,
    };
    fetchLoyalty(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    });
    let queryParams = {
      search,
      take,
      skip,
      type: '',
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    };
    fetchLoyalty(queryParams);
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          start: date.start,
          end: date.end,
          tier,
        };
        fetchLoyalty(queryParams);
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    if (loyalty) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setPageCount(Math.ceil(loyalty?.total / take));
    }
  }, [loyalty]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchLoyalty(parsedUrlParams);
  }, []);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      start: date.start,
      end: date.end,
    };
    let result = QueryReallignment(queryParams);

    history.push({
      pathname: history?.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history?.location?.search);

    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    return parsedUrlParams;
  };
  let accessChecking = ReadCheck(accessControl, `Loyalty`, 'Usage History');
  const tableDataHandler = () => {
    const rowData = loyalty?.result?.map((data) => {
      return {
        id: data.id.substring(0, 7),
        loyaltyUserName: (

          <>
            {accessChecking ?

              <Link
                to={`/loyalty_preview?id=${data.loyaltyUser}`}
                className='text-dark font-weight-bold'
              >
                {' '}
                {data.loyaltyUserName ? data.loyaltyUserName : '___'}
              </Link>

              :
              <div className='text-dark font-weight-bold'>
                {data.loyaltyUserName ? data.loyaltyUserName : '___'}
              </div>
            }
          </>
        ),
        point: (
          <span className='text-capitalize'>
            {data.point}
            <span className='d-block'>
              <span
                className={`badge badge-soft-${data.type.toLowerCase() === 'credit' ? 'success' : 'danger'
                  } rounded`}
              >
                {' '}
                {data.type}{' '}
              </span>
            </span>
          </span>
        ),
        pointsAfter: data.pointsAfter,
        createdAt: (
          <div>
            <div>{Moment(data.createdAt).format('l')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {Moment(data.createdAt).format('LT')}
            </div>
          </div>
        ),
        tierStartDate: (
          <div>
            {' '}
            <div>{Moment(data?.loyalty?.tierStartDate).format('l')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data?.loyalty?.tierStartDate).format('LT')}
            </div>
          </div>
        ),
        tier: data?.loyalty?.tier,
        sourceUserName: data.sourceUserName ? data.sourceUserName : '___',
        action: (

          <CheckAction
            field='Loyalty'
            subModule='Usage History'
            iconType={'Read'}
          >
            <div className='d-flex'>
              <Link
                to={`/loyalty_preview?id=${data.loyaltyUser}`}
                className='mr-3 text-dark'
                id='edit'
              >
                <i className='mdi mdi-eye p-2 font-size-16 mb-2'></i>
              </Link>
              <UncontrolledTooltip
                placement='top'
                className='p-0 bg-light'
                target='edit'
              >
                Preview
              </UncontrolledTooltip>
            </div>
          </CheckAction>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'Loyalty Id',
          dataField: 'id',
        },
        {
          text: 'Loyalty User',
          dataField: 'loyaltyUserName',
          sort: true,
        },
        {
          text: 'Point Earned',
          dataField: 'point',
          sort: true,
        },
        {
          text: 'Earned On',
          dataField: 'createdAt',
          classes: 'text-capitalize',
        },
        {
          text: 'Point Balance',
          dataField: 'pointsAfter',
        },
        {
          text: 'Tier Start Date',
          dataField: 'tierStartDate',
        },
        {
          text: 'Current Tier',
          dataField: 'tier',
        },
        {
          text: 'Source User',
          dataField: 'sourceUserName',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {/* <div>
                <h4> Loyalty History </h4>
              </div> */}
              {message && (
                <Alert color='success' className='text-center'>
                  {message}
                </Alert>
              )}
              {tableError !== null ? (
                <Alert color='danger' className='text-center'>
                  {tableError}
                </Alert>
              ) : (
                <>
                  {!isLoading && data !== null ? (
                    <>
                      <div className='d-flex justify-content-end mb-2'>
                        <FilterDropDown
                          menu={menu}
                          toggleMenu={() => setMenu(!menu)}
                        >
                          <div>
                            <AvForm
                              className='form-horizontal'
                              onValidSubmit={handleDateFilter}
                            >
                              <SimpleBar
                                style={{
                                  maxHeight: '250px',
                                }}
                              >
                                <Row className='px-3  pt-3 pb-0'>
                                  <Col md='12'>
                                    <FormGroup>
                                      <AvField
                                        name='startDate'
                                        type='date'
                                        className='form-control bg-light'
                                        id='startDate'
                                        value={date.start}
                                        helpMessage='Start Date'
                                        placeholder='Please choose the start date...'
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md='12'>
                                    <FormGroup>
                                      <AvField
                                        name='endDate'
                                        type='date'
                                        className='form-control bg-light'
                                        id='endDate'
                                        value={date.end}
                                        helpMessage='End Date'
                                        placeholder='Please choose the end date...'
                                        required
                                      />
                                    </FormGroup>
                                  </Col>{' '}
                                  <Col md='12'>
                                    <FormGroup>
                                      <AvField
                                        type='select'
                                        value={status}
                                        name='loyaltyStatus'
                                        helpMessage='status'
                                      >
                                        <option value=''>All</option>
                                        <option value='DEBIT'>DEBIT</option>
                                        <option value='CREDIT'>CREDIT</option>
                                      </AvField>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </SimpleBar>

                              <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                                <Link
                                  to='#'
                                  onClick={resetHandler}
                                  className='btn btn-sm  bg-light font-size-14'
                                >
                                  <i className=' mdi mdi-refresh mr-1'>
                                    {' '}
                                    Reset
                                  </i>
                                </Link>

                                <button
                                  className='btn btn-success btn-sm mr-2'
                                  type='submit'
                                >
                                  <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                  Send
                                </button>
                              </div>
                            </AvForm>
                          </div>
                        </FilterDropDown>
                      </div>

                      <div className='d-flex justify-content-between mb-2'>
                        <div>
                          <Input
                            bsSize='sm'
                            id='take'
                            name='take'
                            type='select'
                            onChange={handleLimitChange}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div className='d-flex'>
                          <Input
                            bsSize='sm'
                            id='search'
                            value={search}
                            name='search'
                            onChange={SearchHandler}
                            placeholder='Search'
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField='id'
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses='table-responsive'
                        noDataIndication={'No Data to Display'}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                      <Modal
                        isOpen={showFilterModal}
                        toggle={() => setShowFilterModal(!showFilterModal)}
                      >
                        <ModalHeader toggle={() => setShowFilterModal(false)}>
                          Filter Data By Date
                        </ModalHeader>
                        <ModalBody>
                          <div>
                            <AvForm
                              className='form-horizontal'
                              onValidSubmit={handleDateFilter}
                            >
                              <Row>
                                <Col md='6'>
                                  <FormGroup className='mb-4'>
                                    <AvField
                                      name='startDate'
                                      type='date'
                                      label='Start Date'
                                      className='form-control bg-light'
                                      id='startDate'
                                      value={''}
                                      placeholder='Please choose the start date...'
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md='6'>
                                  <FormGroup className='mb-4'>
                                    <AvField
                                      name='endDate'
                                      type='date'
                                      label='End Date'
                                      className='form-control bg-light'
                                      id='endDate'
                                      value={''}
                                      placeholder='Please choose the end date...'
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>

                              <button
                                className='btn btn-success mr-2'
                                type='submit'
                              >
                                {'Submit'}
                              </button>
                              <Link
                                to='#'
                                onClick={() => setShowFilterModal(false)}
                              >
                                <button className='btn btn-secondary'>
                                  Close
                                </button>
                              </Link>
                            </AvForm>
                          </div>
                        </ModalBody>
                      </Modal>
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { loyalty, loading, loyaltyError, message, tableError } = state.Loyalty;
  return { accessControl, loyalty, loading, loyaltyError, message, tableError };
};

export default connect(mapStateToProps, {
  fetchLoyalty,
})(LoyaltyTable);
