import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from "reactstrap";
import AlartError from "../../../../components/Common/AlertCard";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { Link, withRouter } from "react-router-dom";
import CustomSelect from "../../../../components/Common/MoreDropdown/CustomSelect";
import { getS3ImageUrlHandler } from "../../../../utils/general";
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import {
  createPromoCode,
  createPromoCodeFailed,
  updatePromoCode,
  updatePromoCodeFailed,
  fetchPackage,
} from "../../../../store/actions";

const Form = ({
  fetchPackage,
  packages,
  createPromoCode,
  createPromoCodeFailed,
  updatePromoCode,
  updatePromoCodeFailed,
  message,
  loading,
  history,
  promoCodeError,
  defaultValue,
}) => {
  const [description, setDescription] = useState("");
  const [descError, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setFileUpload] = useState([]);
  const [selected, setSelected] = useState([]);
  const [packageOption, SetPackageOption] = useState([]);

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setFileUpload(files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleSubmit = async (event, values) => {
    setIsLoading(true);
    if (!description) {
      setError(true);
      setIsLoading(false);
      return;
    }
    setError(false);

    const promoCodeData = {
      ...values,
    };
    let result;
    if (selectedFiles.length !== 0) {
      result = await getS3ImageUrlHandler(selectedFiles[0], "promoCode");
    }
    promoCodeData.imageUrl = result?.data.result?.mediaUrl;
    promoCodeData.imageThumbUrl = result?.data?.result.mediaThumbUrl;
    promoCodeData.category = values.category;
    promoCodeData.name = values.name;
    promoCodeData.total = Number(values.total);
    promoCodeData.value = Number(values.value);
    promoCodeData.percent = Number(values.percent);
    promoCodeData.packageOffer = Number(values.packageOffer);
    promoCodeData.point = Number(values.point);
    promoCodeData.expiry = values.expiry;
    promoCodeData.startDate = values.startDate;
    promoCodeData.description = description;

    if (selected.length > 0) {
      promoCodeData.packages = [];
      promoCodeData.packages = selected.map((val) => val.value);
    }

    if (defaultValue) {
      if (selectedFiles.length === 0) {
        promoCodeData.imageUrl = defaultValue?.imageUrl;
        promoCodeData.imageThumbUrl = defaultValue?.imageThumbUrl;
      }
      promoCodeData.status = values.Status;
      updatePromoCode(promoCodeData, defaultValue.id);
      return;
    }
    createPromoCode(promoCodeData);
    setIsLoading(false);
  };

  useEffect(() => {
    createPromoCodeFailed("");
    updatePromoCodeFailed("");
    fetchPackage();
  }, []);

  useEffect(() => {
    if (packages) {
      const packageArr = [];
      packages.result.forEach((element) => {
        const packageObj = {};
        packageObj.value = element.id;
        packageObj.label = element.name;
        packageArr.push(packageObj);
      });
      SetPackageOption(packageArr);
    }

    if (defaultValue) {
      const pkgArr = [];
      if (defaultValue.packages) {
        defaultValue.packages.forEach((element) => {
          const pkgObj = {};
          pkgObj.value = element.id;
          pkgObj.label = element.name;
          pkgArr.push(pkgObj);
        });
      }
      setSelected(pkgArr);
      setDescription(defaultValue.description);
    }

    if (message) {
      setTimeout(() => {
        createPromoCodeFailed("");
        updatePromoCodeFailed("");
        history.goBack();
      }, 2000);
    }
  }, [packages, defaultValue, message]);
  const handleFetchMore = () => {
    fetchPackage({ take: packageOption.length + 10, skip: 0 });
  };
  return (
    <Card>
      <CardBody>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        {descError && (
          <Alert className="text-center" color="danger">
            Description can't be empty
          </Alert>
        )}
        {promoCodeError && <AlartError error={promoCodeError} />}
        <div className="float-right">
          <Link to="#" onClick={() => history.goBack()}>
            <i className="fas fa-arrow-left mr-3" />
            Back
          </Link>
        </div>
        <div>
          <h5> {defaultValue ? "Update" : "Create"} Promo Code </h5>
        </div>
        <div>
          <AvForm
            className="form-horizontal"
            onValidSubmit={handleSubmit}
            // model={defaultValue ? defaultValue : false}
          >
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="name">Name</Label>
                      <AvField
                        name="name"
                        type="text"
                        className="form-control bg-light"
                        id="name"
                        value={defaultValue ? defaultValue.name : ""}
                        placeholder="Enter Promo Code Name ..."
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    {" "}
                    <FormGroup className="mb-4">
                      <AvField
                        name="category"
                        type="select"
                        className="form-control bg-light"
                        label="Category"
                        value={defaultValue ? defaultValue.category : "RIDE"}
                      >
                        <option>RIDE</option>
                        <option>DISPATCH</option>
                        <option>FOOD</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label htmlFor="packages"> Packages</Label>
                  <div className="CustomSelect">
                    <CustomSelect
                      options={packageOption}
                      selected={selected}
                      onChange={setSelected}
                      fetchMore={
                        packageOption.length !== packages?.total
                          ? handleFetchMore
                          : null
                      }
                      labelledBy="Select packages ..."
                    ></CustomSelect>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="value">Value</Label>
                      <AvField
                        name="value"
                        type="number"
                        min="0"
                        className="form-control bg-light"
                        id="value"
                        value={defaultValue ? defaultValue.value : ""}
                        placeholder="Enter Promo Code Value ..."
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className=" mb-4">
                      <Label htmlFor="point"> Point</Label>
                      <AvField
                        name="point"
                        type="number"
                        min="0"
                        value={
                          defaultValue && defaultValue?.point
                            ? defaultValue?.point
                            : ""
                        }
                        className="form-control bg-light"
                        id="point"
                        placeholder="Enter Promo Code Point ..."
                      />
                    </FormGroup>
                  </Col>

                  <Col md={12}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="percent">Percentage</Label>
                      <AvField
                        name="percent"
                        type="number"
                        min="0"
                        className="form-control bg-light"
                        id="percent"
                        value={defaultValue ? defaultValue.percent : ""}
                        placeholder="Enter Percentage Value ..."
                        max="100"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className=" mb-4">
                      <Label htmlFor="total">Total</Label>
                      <AvField
                        name="total"
                        type="number"
                        min="0"
                        value={defaultValue ? defaultValue.total : ""}
                        className="form-control bg-light"
                        id="total"
                        placeholder="Enter Total Number ..."
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className=" mb-4">
                      <Label htmlFor="packageOffer">Package Offer</Label>
                      <AvField
                        name="packageOffer"
                        type="number"
                        min="0"
                        value={defaultValue ? defaultValue.packageOffer : "1"}
                        className="form-control bg-light"
                        id="total"
                        placeholder="Enter Package Offer ..."
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className=" mb-4">
                      <AvField
                        className="form-control bg-light"
                        type="datetime-local"
                        name="startDate"
                        label="Start Date"
                        value={
                          defaultValue
                            ? `${defaultValue.startDate.split(":")[0]}:${
                                defaultValue.startDate.split(":")[1]
                              }`
                            : ""
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className=" mb-4">
                      <AvField
                        className="form-control bg-light"
                        type="datetime-local"
                        name="expiry"
                        label="End Date"
                        value={
                          defaultValue
                            ? `${defaultValue.expiry.split(":")[0]}:${
                                defaultValue.expiry.split(":")[1]
                              }`
                            : ""
                        }
                        // min={new Date().toISOString()}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <div>
                  <FormGroup>
                    <Label htmlFor="description"> Description</Label>
                    <textarea
                      className="form-control"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      id="description"
                      rows="4"
                    ></textarea>
                  </FormGroup>
                </div>
                <div>
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      handleAcceptedFiles(acceptedFiles)
                    }
                    multiple={false}
                    accept="image/jpeg, image/png, image/jpg"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        style={{
                          minHeight: "100px",
                          border: "2px dashed #ced4da",
                          background: "#fff",
                          borderRadius: "6px",
                        }}
                      >
                        <div
                          className="dz-message needsclick mt-2"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-2">
                            <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                  {selectedFiles.length === 0 && defaultValue?.imageUrl ? (
                    <>
                      <img
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt="promo_img"
                        src={defaultValue.imageUrl}
                      />
                    </>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              {defaultValue ? (
                <Col md={12}>
                  <FormGroup className=" mb-4">
                    <AvField
                      className="form-control bg-light"
                      type="select"
                      name="Status"
                      label="Status"
                      value={defaultValue ? defaultValue.status : "INACTIVE"}
                    >
                      <option value={"ACTIVE"}>Active</option>
                      <option value={"INACTIVE"}>Inactive</option>
                      <option value={"COMINGSOON"}>Coming Soon</option>
                    </AvField>
                  </FormGroup>
                </Col>
              ) : null}
            </Row>
            {defaultValue ? (
              <button className="btn btn-success mr-2" type="submit">
                {loading ? "Submitting ..." : "Update"}
              </button>
            ) : (
              <button className="btn btn-success mr-2" type="submit">
                {isLoading ? "Submitting ..." : "Save"}
              </button>
            )}

            <Link to="#" onClick={() => history.goBack()}>
              <button className="btn btn-secondary">Cancel</button>
            </Link>
          </AvForm>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, promoCodeError } = state.PromoCode;
  const { packages } = state.Packages;
  return { message, loading, promoCodeError, packages };
};

export default connect(mapStateToProps, {
  createPromoCode,
  updatePromoCode,
  createPromoCodeFailed,
  updatePromoCodeFailed,
  fetchPackage,
})(withRouter(Form));
