import React, { useEffect, useState } from 'react'
// import MetaTags from "react-meta-tags"
import { ModalHeader, Container, ModalBody, Modal, Alert } from 'reactstrap'
import { connect } from 'react-redux'
import PreviewNotification from '../PreviewNotification'

//Import Breadcrumb
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import CheckAction, {
  ReadCheck,
} from '../../../../components/Common/Summary/actionCheck'
import GreySummaryCard from '../../../../components/Common/Summary/index'

import {
  fetchNotification,
  updateNotification,
  updateNotificationFailed,
} from '../../../../store/actions'

import NotificationTable from './table'

const breadcrumbItems = [
  { title: 'Dashboard', link: '#' },
  { title: 'Notification', link: '#' },
  { title: 'Schedule', link: '#' },
]

const Notification = ({
  fetchNotification,
  updateNotification,
  updateNotificationFailed,
  notifications,
  notificationError,
  loading,
  history,
  message,
  accessControl,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [previewId, setPreviewId] = useState(null)
  const previewHandler = (id) => {
    setShowModal(true)
    setPreviewId(id)
  }

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        updateNotificationFailed('')
      }, 2000)
    }
  }, [message])
  const updateHandler = (value) => {
    let data = {
      ...value,
      stopAt: new Date(),
    }
    updateNotification(data, value.id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <GreySummaryCard
            field="Custom Notification"
            subModule="Schedule Notifications"
          >
            <Breadcrumbs
              title="Schedule Notifications"
              breadcrumbItems={breadcrumbItems}
            />
            <div>
              <>
                {message && (
                  <Alert color="success" className="text-center">
                    {message}
                  </Alert>
                )}
                {notificationError ? (
                  <Alert color="danger" className="text-center">
                    {notificationError}
                  </Alert>
                ) : (
                  <NotificationTable
                    notifications={notifications}
                    fetchNotification={fetchNotification}
                    updateHandler={updateHandler}
                    message={message}
                    history={history}
                    loading={loading}
                    previewAction={previewHandler}
                    CheckAction={CheckAction}
                    ReadCheck={ReadCheck}
                    accessControl={accessControl}
                  />
                )}
                <Modal
                  isOpen={showModal}
                  toggle={() => setShowModal(!showModal)}
                >
                  <ModalHeader toggle={() => setShowModal(false)}>
                    Preview Notification
                  </ModalHeader>
                  <ModalBody>
                    <PreviewNotification previewId={previewId} />
                  </ModalBody>
                </Modal>
              </>
            </div>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { accessControl } = state.Account
  const {
    notifications,
    loading,
    notificationError,
    message,
    isAuthorize,
  } = state.Notifications
  return {
    notifications,
    loading,
    notificationError,
    message,
    isAuthorize,
    accessControl,
  }
}

export default connect(mapStateToProps, {
  fetchNotification,
  updateNotification,
  updateNotificationFailed,
})(Notification)
