import React, { useEffect, useState, useCallback } from "react";
import {
  Switch,
  BrowserRouter as Router,
  HashRouter,
  Route,
} from "react-router-dom";
import { connect } from "react-redux";
import { getToken, onMessageListener } from "./firebase";
import { Toast, ToastBody, ToastHeader } from "reactstrap";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
import AppRoutePublic from "./routes/routePublic";
import Pages404 from "./pages/IRides/Utility/Error404";
// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./theme.scss";
import Loader from "./components/Common/Loading/index";
import Location from "./components/Common/Location";
import socket, { Socket } from "./services/socketService";
import {
  fpnSub,
  loadUser,
  fetchMyNotification,
  rideStatus,
  setNetworkError,
} from "./store/actions";

const App = ({
  auth,
  layout,
  loadUser,
  fetchMyNotification,
  fpnSub,
  rideStatus,
  rides,
  setNetworkError,
  networkError,
}) => {
  const [show, setShow] = useState(true);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [messageData, setMessageData] = useState({
    id: null,
    topic: null,
    url: "/",
  });
  const [isTokenFound, setTokenFound] = useState(false);
  const [token, setToken] = useState(false);
  const [driverStatus, setRes] = useState("");
  const [authToken, setAuthToken] = useState([]);
  const [userType, setUser] = useState("");
  const location = Location();

  useEffect(() => {
    setUser("");
    const authToken = JSON.parse(localStorage.getItem("authTokens"));
    if (authToken) {
      setAuthToken(authToken);
    }
    if (auth && auth?.user?.userType && authToken?.accessToken) {
      setUser(auth?.user?.userType);
    }
  }, [auth]);

  useEffect(() => {
    setRes("");
    socket.on("chat", (response) => {
      console.log("Response from chat ", response); //
    });
    // socket.on('connect', () => {
    //   console.log('Connected ids ', socket.id); //
    // });

    if (userType === "DRIVER" && authToken) {
      socket.emit("driver:connect", {
        token: authToken.accessToken,
        body: {},
      });
      socket.on("driver:connect::response", (response) => {
        setRes(response?.result?.status);
        console.log(response); //
      });
    }
  }, [userType, authToken.accessToken]);

  useEffect(() => {
    if (userType === "ADMIN" && authToken) {
      Socket("admin:connect", {
        token: authToken.accessToken,
        body: {
          status: "AVAILABLE",
          fcmToken: token,
        },
      });
      socket.on("admin:sos-updated", (response) => {
        console.log("Response for sos socket", response);
        // console.log(response); //
      });
    }
  }, [userType, authToken.accessToken]);

  useEffect(() => {
    if (userType === "DRIVER" || userType === "RIDER") {
      if (location && location.loaded) {
        // console.log(userType, 'status: ' + driverStatus);
        if (location.err) {
          setNotification({
            title:
              location.error.message === "User denied Geolocation"
                ? "Location Permission"
                : "Error with location",
            body: "Please turn on location and relaod the page",
          });
          return;
        } else {
          if (
            userType === "DRIVER" &&
            driverStatus === "AVAILABLE" &&
            authToken
          ) {
            Socket("driver:online", {
              token: authToken.accessToken,
              body: {
                status: "AVAILABLE",
              },
            });
            Socket("driver:log", {
              token: authToken.accessToken,
              body: {
                status: "AVAILABLE",
                lat: location.coordinates.lat,
                lng: location.coordinates.lng,
              },
            });
          }
          if (userType === "RIDER") {
            Socket("rider:connect", {
              token: authToken.accessToken,
              body: {
                lat: location.coordinates.lat,
                lng: location.coordinates.lng,
                fcmToken: token,
              },
            });

            socket.on("rider:ride-request", (response) => {
              setShow(true);
              setNotification({
                title: `Ride to ${response.ride.dropOff.name}`,
                body: `Ride Request to ${response.message}.     "~SKT"`,
              });
              setMessageData({
                id: response.ride.id,
                topic: `Ride to ${response.ride.dropOff.name}`,
              });
              // console.log('Response from ride request', response);
            });
          }
        }
      } else {
        setNotification({
          title: "Error with location",
          body: "Location data not available yet",
        });
      }
    }
  }, [driverStatus, location, location.err, location.loaded]);

  useEffect(() => {
    if (userType === "DRIVER" || userType === "RIDER") {
      const interval = setInterval(() => {
        if (location.loaded) {
          if (location.err) {
            setNotification({
              title:
                location.error.message === "User denied Geolocation"
                  ? "Location Permission"
                  : "Error with location",
              body: "Please turn on location and relaod the page",
            });
            return;
          } else {
            if (userType === "DRIVER" && driverStatus === "AVAILABLE") {
              Socket("driver:online", {
                token: authToken.accessToken,
                body: {
                  status: "AVAILABLE",
                },
              });
              Socket("driver:log", {
                token: authToken.accessToken,
                body: {
                  status: "AVAILABLE",
                  lat: location.coordinates.lat,
                  lng: location.coordinates.lng,
                },
              });
            }
            if (userType === "RIDER") {
              Socket("rider:log", {
                token: authToken.accessToken,
                body: {
                  lat: location.coordinates.lat,
                  lng: location.coordinates.lng,
                },
              });
            }
          }
        } else {
          setNotification({
            title: "Error with location",
            body: "Location data not available yet",
          });
        }
      }, 300000);

      return () => clearInterval(interval);
    }
  }, [userType, driverStatus, location.loaded]);

  useEffect(() => {
    if (auth && auth?.user?.userType === "DRIVER") {
      socket.on("driver:ride-request", (response) => {
        setShow(true);
        setNotification({
          title: "New Ride Request",
          body: `Ride Request to ${response.ride.dropOff.name}.     "~SKT"`,
        });
        setMessageData({
          id: response.ride.id,
          topic: `New Ride Request`,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (auth && auth?.user?.userType === "DRIVER") {
      socket.on("driver:ride-cancel", (response) => {
        setShow(true);
        setNotification({
          title: "Ride Cancellation",
          body: `Ride to ${response.dropOff.name} has been cancelled     "~SKT"`,
        });
        setMessageData({
          id: response.id,
          topic: `Ride Cancellation`,
        });
      });
    }
  }, []);

  useEffect(() => {
    loadUser();
    fetchMyNotification();
  }, [loadUser, fetchMyNotification]);

  useEffect(() => {
    getToken(setTokenFound, setToken);
    if (token && auth.isAuthenticated) {
      fpnSub({ fcmToken: token });
    }
  }, [auth.isAuthenticated, token]);

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  useEffect(() => {
    setTimeout(() => {
      if (isTokenFound === true) {
        console.log(" Notification permission enabled 👍🏻 ");
      }
      if (!isTokenFound) {
        console.log(" Need notification permission ❗️ ");
      }
      if (isTokenFound && isTokenFound !== true) {
        console.log(isTokenFound);
        setShow(true);
        setNotification({
          title: "Notification Denied",
          body: "Please Enable Notification Access",
        });
      }
    }, 5000);
    if (rides.acceptMessage) {
      setNotification({
        title: "ACCEPTED",
        body: rides.acceptMessage,
      });
      return;
    }
    if (networkError?.networkError) {
      setShow(true);
      setNotification({
        title: "Network Error",
        body: "There is a problem with your connection. Please check and try again.",
      });
      setMessageData({
        // id: networkError.networkError.code,
        id: null,
        topic: networkError.networkError.message,
      });
    }
  }, [isTokenFound, rides.acceptMessage, networkError]);

  onMessageListener()
    .then((payload) => {
      console.log(payload);
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      setMessageData({
        id: null,
        topic: null,
      });
      if (
        payload.data?.topic === "ride-request" ||
        payload.data?.topic === "ride-cancelled"
      ) {
        setMessageData({
          id: payload.data.id,
          topic: payload.data.topic,
          url: `/ride/${payload.data.id}`,
        });
      }
      if (payload.data?.topic === "user-create") {
        setMessageData({
          id: payload.data.id,
          topic: payload.data.topic,
          url: `/user/${payload.data.id}`,
        });
      }
      if (
        payload.data?.topic === "document-create" ||
        payload.data?.topic === "document-review"
      ) {
        setMessageData({
          id: payload.data.id,
          topic: payload.data.topic,
          url: `/user/${payload.data.id}?tab=3`,
        });
      }
      if (payload.data?.topic === "vehicle-create") {
        setMessageData({
          id: payload.data.id,
          topic: payload.data.topic,
          url: `/vehicle/${payload.data.id}`,
        });
      }
    })
    .catch((err) => console.log("failed: ", err));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggle = useCallback(() => setShow(!show));

  useEffect(() => {
    if (show && !messageData.id) {
      const hideToast = setTimeout(() => toggle(), 10000);
      return () => clearInterval(hideToast);
    }
    if (show && messageData.topic) {
      const hideToast = setTimeout(() => {
        toggle();
      }, 50000);
      return () => clearInterval(hideToast);
    }
  }, [show, toggle, messageData.id, messageData.topic]);

  const handleRideAcceptance = (event) => {
    if (location.loaded) {
      if (location.err) {
        setNotification({
          title:
            location.error.message === "User denied Geolocation"
              ? "Location Permission"
              : "Error with location",
          body: "Please turn on location and relaod the page",
        });
        return;
      } else {
        let rideData = {
          id: messageData.id,
          lat: location.coordinates.lat,
          lng: location.coordinates.lng,
          status: "accept",
        };

        rideStatus(rideData);
      }
    } else {
      setNotification({
        title: "Error with location",
        body: "Location data not available yet",
      });
    }
  };

  return (
    <React.Fragment>
      <>
        {notification.title || notification.body ? (
          <Toast
            isOpen={show}
            style={{
              position: "absolute",
              top: 20,
              right: 20,
              minWidth: 200,
              zIndex: 9999,
            }}
          >
            <ToastHeader toggle={toggle}>
              <img
                src={`${messageData.topic === "Network Error"
                  ? "networkError.png"
                  : "/nrydeIcon.png"
                  }`}
                className="mr-3"
                style={{ width: "20px" }}
                alt=""
              />
              <strong
                className="font-weight-bold text-uppercase"
                style={{
                  color: `${messageData.topic === "Network Error" ? "red" : "#312f2f"
                    }`,
                  marginRight: "20px",
                  fontSize: `${messageData.topic === "Network Error" ? "15px" : "14px"
                    }`,
                }}
              >
                {notification.title}
              </strong>
            </ToastHeader>

            <ToastBody className="d-flex flex-column text-dark">
              <a
                href={`${messageData.url ? messageData.url : ""}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-dark"
                style={
                  messageData.url
                    ? { cursor: "pointer" }
                    : { pointerEvents: "none" }
                }
              >
                {notification.body}
              </a>
              {messageData.topic &&
                notification.title === "New Ride Request" ? (
                <button
                  onClick={() => handleRideAcceptance()}
                  className="btn btn-success btn-sm mt-3"
                >
                  Accept Ride
                </button>
              ) : null}
            </ToastBody>
          </Toast>
        ) : null}
        {auth.isAuthenticated !== null && !auth.mainLoader ? (
          <>
            <Router>
              <Switch>
                {publicRoutes.map((route, idx) => (
                  <AppRoutePublic
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    isAuthenticated={auth.isAuthenticated}
                    key={idx}
                  />
                ))}

                {authProtectedRoutes.map((route, idx) => (
                  <AppRoute
                    exact
                    path={route.path}
                    accessFields={auth?.accessControl?.result}
                    accessAuth={auth.accessAuth}
                    layout={getLayout()}
                    component={route.component}
                    account={auth}
                    role={route.role}
                    field={route.field}
                    key={idx}
                  />
                ))}
              </Switch>
            </Router>
          </>
        ) : (
          <div className="vh-100">
            <Loader />
          </div>
        )}
      </>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    auth: state.Account,
    notification: state.Notification,
    rides: state.Rides,
    networkError: state.NetworkChecker,
  };
};

export default connect(mapStateToProps, {
  loadUser,
  fetchMyNotification,
  fpnSub,
  rideStatus,
  setNetworkError,
})(App);
