import React, { useState, useEffect } from 'react'
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from 'reactstrap'
//Dropzone

import { AvForm, AvField } from 'availity-reactstrap-validation'
// actions
import { connect } from 'react-redux'
import {
  createPushNotification,
  createNotificationFailed,
  createNotification,
} from '../../../../store/actions'

const CreateNotification = ({
  createPushNotification,
  createNotificationFailed,
  message,
  notificationError,
  data,
  userId,
}) => {
  const [text, setMessage] = useState('')
  const [descError, setError] = useState('')

  const handleSubmit = (event, values) => {
    setError('')
    if (!text) {
      setError('Message can not be empty')
      return
    }
    const packageData = {
      ...values,
      message: text,
    }
    createPushNotification(packageData, userId)
    return
  }

  useEffect(() => {
    setMessage('')
    if (data) {
      setMessage(data.message)
    }
    if (message || notificationError) {
      setTimeout(() => {
        createNotificationFailed('')
      }, 2000)
    }
  }, [data, notificationError, message])

  return (
    <Col md={12}>
      {descError && (
        <Alert className="text-center" color="danger">
          {descError}
        </Alert>
      )}
      {message && (
        <Alert className="text-center" color="success">
          {message}
        </Alert>
      )}
      {notificationError && (
        <Alert className="text-center" color="danger">
          {notificationError}
        </Alert>
      )}
      <Card>
        <CardBody>
          <div className="mt-3">
            <div>
              <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <FormGroup className=" mb-4">
                      <Label htmlFor="provider">Push Title</Label>
                      <AvField
                        name="title"
                        type="text"
                        className="form-control bg-light"
                        id="title"
                        placeholder="Push Title ..."
                        // value={data ? data?.title : ''}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label htmlFor="description"> Message</Label>
                      <textarea
                        className="form-control"
                        onChange={(e) => setMessage(e.target.value)}
                        id="message"
                        placeholder="Enter Message"
                        rows="5"
                        required
                        value={text}
                      ></textarea>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-center">
                  <button className="btn btn-success px-4" type="submit">
                    Send
                  </button>
                </div>
              </AvForm>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}
const mapStateToProps = (state) => {
  const { loading, notificationError, message } = state.Notifications
  const { user } = state.Account
  return { user, loading, notificationError, message }
}

export default connect(mapStateToProps, {
  createPushNotification,
  createNotificationFailed,
  createNotification,
})(CreateNotification)
