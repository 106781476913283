import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import MiniWidgets from './Components/MiniWidgets.js';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import ReferralTable from './referralTable';
import UsersInvitationsTable from './usersInvitationTable.js';
import GreySummaryCard from '../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../components/Common/Summary/actionCheck';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Moment from 'moment';
import moment from 'moment-timezone';

import {
  fetchInvitation,
  deleteInvitation,
  updateInvitation,
  updateInvitationFailed,
  fetchReferralCSV,
  fetchReferralCSVError,
  fetchUsersInvitation,
} from '../../../store/actions';

const Invitation = ({
  invitations,
  fetchInvitation,
  fetchUsersInvitation,
  users_invitations,
  updateInvitation,
  updateInvitationFailed,
  deleteInvitation,
  tableError,
  loading,
  message,
  history,
  fetchReferralCSVError,
  fetchReferralCSV,
  referralCSV,
  csv_loading,
  accessControl
}) => {
  const [previewId, setPreviewId] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [check, setCheck] = useState('');
  const [dateFilter, setDate] = useState({
    start: '',
    end: '',
  });
  const breadcrumbItems = [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Referral', link: '#' },
  ];

  const statusIdHandler = (id, e) => {
    setShowStatusModal(true);
    setPreviewId(id);
    setStatus(e);
  };

  const deleteInvitationModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteInvitation(deleteId);
    setDeleteId(null);
  };
  const date = (e) => {
    setDate(e);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        updateInvitationFailed('');
        setShowStatusModal(false);
      }, 2000);
    }
  }, [message, updateInvitationFailed]);

  const handleStatusUpdate = (event, values) => {
    updateInvitation({ status: values.Status }, previewId);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Referral' breadcrumbItems={breadcrumbItems} />

          <GreySummaryCard field='Referral'>
            {tableError === null && (
              <div className='mb-2 text-right'>
                <CheckAction field='Referral' iconType={'Create'}>
                  <Link to='/referral_form'>
                    <Button color='success' size='sm'>
                      <i className='mdi mdi-plus-circle-outline font-size-18'></i>{' '}
                      Add Referral
                    </Button>
                  </Link>
                </CheckAction>
              </div>
            )}
            {message && (
              <Alert color='success' className='text-center'>
                {message}
              </Alert>
            )}
            <Row>
              <Col>
                <Row
                  className='styled-scroll mb-5'
                  style={{ flexWrap: 'nowrap', overflowX: 'auto' }}
                >
                  <MiniWidgets date={dateFilter} />
                </Row>
                {tableError === null && (
                  <BootstrapSwitchButton
                    checked={check}
                    onlabel='Individual View'
                    offlabel='Table View'
                    onstyle='primary'
                    offstyle='primary'
                    onChange={(e) => setCheck(e)}
                    width={140}
                    // eslint-disable-next-line react/style-prop-object
                    style={'ml-3 position-absolute mt-3 z-index-999'}
                  />
                )}
                {check ? (
                  <UsersInvitationsTable
                    users_invitations={users_invitations}
                    tableError={tableError}
                    // dateFilter={date}
                    loading
                    deleteInvitation={deleteInvitationModal}
                    fetchUsersInvitation={fetchUsersInvitation}
                    statusIdHandler={statusIdHandler}
                    history={history}
                    message={message}
                    fetchReferralCSVError={fetchReferralCSVError}
                    fetchReferralCSV={fetchReferralCSV}
                    csvData={referralCSV}
                    csv_loading={csv_loading}
                    CheckAction={CheckAction}
                    ReadCheck={ReadCheck}
                    accessControl={accessControl}
                  />
                ) : (
                  <ReferralTable
                    invitationData={invitations}
                    tableError={tableError}
                    dateFilter={date}
                    loading
                    deleteInvitation={deleteInvitationModal}
                    fetchInvitation={fetchInvitation}
                    statusIdHandler={statusIdHandler}
                    history={history}
                    message={message}
                    fetchReferralCSVError={fetchReferralCSVError}
                    fetchReferralCSV={fetchReferralCSV}
                    csvData={referralCSV}
                    csv_loading={csv_loading}
                    CheckAction={CheckAction}
                    ReadCheck={ReadCheck}
                    accessControl={accessControl}
                  />
                )}
              </Col>
            </Row>
          </GreySummaryCard>
        </Container>

        <Modal
          isOpen={showDeleteModal}
          toggle={() => setShowDeleteModal(!showDeleteModal)}
        >
          <ModalBody>
            <h4 className='card-title mb-4 text-center'>
              {' '}
              Are you sure you want to delete this Referral
            </h4>
            <button
              onClick={() => deleteHandler()}
              className='btn btn-outline-danger btn-sm w-100'
            >
              Click to Delete
            </button>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={showStatusModal}
          toggle={() => setShowStatusModal(!showStatusModal)}
        >
          <ModalHeader toggle={() => setShowStatusModal(false)}>
            Change Status
          </ModalHeader>
          <ModalBody>
            {message && (
              <Alert color='success' className='text-center'>
                {message}
              </Alert>
            )}

            <div>
              <AvForm
                className='form-horizontal'
                onValidSubmit={handleStatusUpdate}
              >
                <Row>
                  <Col md='9'>
                    <FormGroup className='mb-4'>
                      <AvField
                        className='form-control bg-light'
                        type='select'
                        name='Status'
                        label='Status'
                        value={status}
                      >
                        <option value={'PENDING'}>Pending</option>
                        <option value={'ACCEPTED'}>Accepted</option>
                        <option value={'JOINED'}>Joined</option>
                        <option value={'COMPLETED'}>Completed</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>

                <button className='btn btn-success mr-2' type='submit'>
                  {loading ? 'Submitting ...' : 'Submit'}
                </button>
                <Link to='#' onClick={() => setShowStatusModal(false)}>
                  <button className='btn btn-secondary'>Close</button>
                </Link>
              </AvForm>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const {
    referralCSV,
    csv_loading,
    invitations,
    loading,
    invitationError,
    message,
    tableError,
    users_invitations,
  } = state.Referral;
  return {
    referralCSV,
    csv_loading,
    invitations,
    loading,
    invitationError,
    message,
    tableError,
    users_invitations,
    accessControl
  };
};

export default connect(mapStateToProps, {
  fetchInvitation,
  fetchUsersInvitation,
  deleteInvitation,
  updateInvitation,
  updateInvitationFailed,
  fetchReferralCSV,
  fetchReferralCSVError,
})(Invitation);
