import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import GoogleMapApp from '../MyRides/GoogleMap';
import AlartError from '../../../../components/Common/AlertCard';
import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from 'availity-reactstrap-validation';

// actions
import {
  createRide,
  createRideFailed,
  updateRide,
  updateRideFailed,
  fetchRidePrice,
  fetchRidePriceFailed,
  fetchPlaces,
} from '../../../../store/actions';

const Form = ({
  createRide,
  createRideFailed,
  updateRide,
  ridePrice,
  updateRideFailed,
  message,
  history,
  ridesError,
  defaultValue,
  packages,
  rideId,
  rebooking,
  fetchRidePrice,
  fetchRidePriceFailed,
  fetchPlaces,
  placesLoading,
  places,
  placesError
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newPrice, setPrice] = useState(0);

  const [selectedPackage, setPackage] = useState(null);
  const [rideDetails, setRideDetails] = useState(null);
  const [rideLocation, setRideLocation] = useState(null);

  const handleSubmit = (event, values) => {
    if (rideLocation === null) {
      return;
    }
    setIsLoading(true);
    const rideData = {};
    rideData.ridePackage = values.ridePackage;
    // check if its default input value and replace with it id

    let packageId = selectedPackage ? selectedPackage : packages?.result[0].id;
    if (defaultValue && defaultValue?.ridePackage.name === values.ridePackage) {
      rideData.ridePackage = defaultValue.ridePackage.id;
    }
    rideData.paymentMethod = values.paymentMethod;
    rideData.isAcceptShareRide =
      values.isAcceptShareRide === 'true' ? true : false;
    rideData.pickUp = {
      ...rideLocation.pickUp,
    };
    rideData.dropOff = {
      ...rideLocation.dropOff,
    };
    rideData.ridePackage = packageId;
    if (defaultValue) {
      rideData.id = defaultValue.id;
      updateRide(rideData);
    } else {
      createRide(rideData);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        createRideFailed('');
        updateRideFailed('');
        history.goBack();
      }, 1000);
    }
  }, [message]);

  useEffect(() => {
    if (defaultValue) {
      const locationInfo = {
        pickUp: defaultValue?.pickUp,
        dropOff: defaultValue?.dropOff,
        distance: defaultValue?.distance,
        time: defaultValue?.durationInTraffic,
      };
      setRideLocation(locationInfo);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (rideLocation) {
      let payload = {
        pickUp: {
          lat: rideLocation?.pickUp?.lat,
          lng: rideLocation?.pickUp?.lng,
        },
        dropOff: {
          lat: rideLocation?.dropOff?.lat,
          lng: rideLocation?.dropOff?.lng,
        },
      };
      fetchRidePrice(payload);
    }
  }, [rideLocation, fetchRidePrice]);

  useEffect(() => {
    if (ridePrice) {
      setRideDetails(ridePrice.result);
      setPackage(ridePrice.result[0].packageId);
      getPrice(ridePrice.result[0].packageId);
    }
  }, [ridePrice]);

  useEffect(() => {
    getPrice(selectedPackage);
  }, [selectedPackage]);

  useEffect(() => {
    fetchRidePriceFailed('');
  }, []);

  const getPrice = (id) => {
    let result = rideDetails?.find((data) => data.packageId === id);
    setPrice(result?.price);
  };

  return (
    <Card>
      <CardBody>
        {message && (
          <Alert color='success' className='text-center'>
            {message}
          </Alert>
        )}
        {ridesError && <AlartError error={ridesError} />}

        <div className='float-right'>
          <Link to='#' onClick={() => history.goBack()}>
            <i className='fas fa-arrow-left mr-3' />
            Back
          </Link>
        </div>
        <div>
          <h5> {defaultValue ? 'Update' : 'Create'} Ride </h5>
        </div>
        <div>
          <Row>
            <Col md={8}>
              <div className='mt-3 mb-5'>
                <GoogleMapApp
                  setLocation={(data) => setRideLocation(data)}
                  rideLocation={rideLocation}
                  rebooking={rebooking}
                  fetchPlaces={fetchPlaces}
                  placesError={placesError}
                  places={places}
                  placesLoading={placesLoading}
                />
              </div>
            </Col>
            <Col md={4}>
              {rideDetails && (
                <AvForm
                  className='form-horizontal'
                  onValidSubmit={handleSubmit}
                >
                  <Row className='d-flex  justify-content-between'>
                    <Col sm={12}>
                      <FormGroup className=' mb-2'>
                        <Label htmlFor='ridePackage'> Ride Packages</Label>

                        <select
                          className='form-control custom-select'
                          required
                          value={selectedPackage}
                          onChange={(e) => setPackage(e.target.value)}
                          title='Country'
                        >
                          {rideDetails.map((data) => (
                            <option
                              key={data.packageId}
                              value={`${data.packageId}`}
                            >
                              {data.packageName}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm={12}>
                      <FormGroup className=' mb-4'>
                        <AvField
                          className='form-control bg-light'
                          type='select'
                          name='paymentMethod'
                          label='Mode of Payment'
                          value={
                            defaultValue ? defaultValue.paymentMethod : 'CASH'
                          }
                        >
                          <option>CASH</option>
                          {/* <option disabled>CARD</option> */}
                          <option>NPAY</option>
                        </AvField>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div
                    className='bg-light rounded pt-3 p-2'
                    style={{ minHeight: '300px' }}
                  >
                    <h2 className='text-center mb-4'> Ride Information</h2>
                    {rideLocation ? (
                      <>
                        {' '}
                        <div className='d-flex mb-2'>
                          <h5 className='mb-1 font-size-14 text-muted'>
                            <i className='ri-map-pin-2-fill text-success mr-2' />
                            {rideLocation !== null &&
                              rideLocation.pickUp.address}{' '}
                          </h5>
                        </div>
                        <div className='d-flex mb-2'>
                          <h5 className='mb-1 font-size-14 text-muted'>
                            <i className='ri-map-pin-3-line text-danger mr-2' />
                            {rideLocation !== null &&
                              rideLocation.dropOff.address}
                          </h5>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                          <h4 className='card-title mb-3 col-md-6 px-0'>
                            Total Distance :
                            <span className='text-muted'>
                              {rideLocation !== null &&
                                rideLocation?.distance?.toLocaleString()}
                            </span>
                          </h4>
                          <h4 className='card-title mb-2 col-md-6 px-0'>
                            Total Time :
                            <span className='text-muted'>
                              {rideLocation !== null && rideLocation.time}
                            </span>
                          </h4>
                        </div>
                        <div>
                          <h4 className='card-title mb-2 px-0'>
                            Estimated Price :
                            <span className='text-muted'>
                              {newPrice && parseInt(newPrice)?.toLocaleString()}
                            </span>
                          </h4>
                        </div>
                      </>
                    ) : (
                      'Searching...'
                    )}
                    {rideLocation !== null && (
                      <>
                        <div className='p-2 bg-white  rounded'>
                          <FormGroup>
                            <Label htmlFor='isAcceptShareRide'>
                              Is Schedule ?
                            </Label>
                            <AvRadioGroup
                              inline
                              name='isAcceptShareRide'
                              value={
                                defaultValue
                                  ? defaultValue?.isSchedule === false
                                    ? 'false'
                                    : 'true'
                                  : 'false'
                              }
                              required
                            >
                              <AvRadio label='Yes' value={'true'} />
                              <AvRadio label='False' value={'false'} />
                            </AvRadioGroup>
                          </FormGroup>
                        </div>
                        <div className='text-center mt-2'>
                          {!ridesError && (
                            <button
                              className='btn btn-success mr-2'
                              type='submit'
                            >
                              {isLoading ? 'Submitting ...' : 'Book ride'}
                            </button>
                          )}

                          <Link to='#' onClick={() => history.goBack()}>
                            <button className='btn btn-secondary'>
                              Cancel
                            </button>
                          </Link>
                        </div>
                      </>
                    )}
                  </div>
                </AvForm>
              )}
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { places, placesLoading, placesError, message, loading, ridesError, ridePrice } =
    state.Rides;
  return { places, placesLoading, placesError, message, loading, ridesError, ridePrice };
};

export default connect(mapStateToProps, {
  createRide,
  updateRide,
  createRideFailed,
  updateRideFailed,
  fetchRidePriceFailed,
  fetchRidePrice,
  fetchPlaces,
})(withRouter(Form));
