import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetRoleService = (query) => {
  const http = new HttpService();
  let url = 'role/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};
export const GetPrivilegesService = (id) => {
  const http = new HttpService();
  const url = `privilege/all`;
  return http.getData(url);
};
export const GetEachRoleService = (id) => {
  const http = new HttpService();
  const url = `role/${id}`;
  return http.getData(url);
};

export const CreateRoleService = (payload) => {
  const http = new HttpService();
  const url = 'role';
  return http.postData(payload, url);
};
export const UpdateRoleService = (payload) => {
  const http = new HttpService();
  const url = `role/${payload.id}`;
  return http.putData(payload, url);
};

export const DeleteRoleService = (id) => {
  const http = new HttpService();
  const url = `role/${id}`;
  return http.deleteData(url);
};

export const AssignRoleService = (payload) => {
  const http = new HttpService();
  const url = `user/${payload.id}/role`;
  return http.putData(payload.data, url);
};

export const UnassignRoleService = (payload) => {
  const http = new HttpService();
  const url = `user/${payload.id}/role`;
  return http.deleteDataWithData(payload.data, url);
};
