import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col, Input, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SimpleBar from 'simplebar-react';
import BootstrapTable from 'react-bootstrap-table-next';
import FilterDropDown from '../FilterDropDown';
import { withRouter } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Moment from 'moment';
import Loader from '../Loading/index.js';
import { Link } from 'react-router-dom';
import { ScrollToTop } from '../../../utils/general';

let skip = 0;
let dataLimit = 10;
const LogsTable = ({
  logs,
  fetchDriverOnlineLogs,
  handleDateFilter,
  resetHandler,
  status,
  date,
  toggle,
  driverId,
}) => {
  const [data, setData] = useState(null);
  // const [refreshTimer, setRefreshTimer] = useState(0);
  const [menu, setMenu] = useState(toggle);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(logs?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      status,
      search,
      driverId,
      start: date.start,
      end: date.end,
    };
    fetchDriverOnlineLogs(pageInfo);
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }

    let pageInfo = {
      skip,
      take,
      status,
      search,
      driverId,
      start: date.start,
      end: date.end,
    };
    fetchDriverOnlineLogs(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      status,
      driverId,
      start: date.start,
      end: date.end,
    };
    fetchDriverOnlineLogs(queryParams);
  };
  useEffect(() => {
    if (toggle) {
      setMenu(toggle);
    }
    if (logs) {
      setData(tableDataHandler());
      setIsLoading(false);
      setPageCount(Math.ceil(logs?.total / take));
    }
  }, [toggle, logs]);

  const tableDataHandler = () => {
    const rowData = logs?.result?.map((data) => {
      return {
        keyId: data.id,
        id: (
          <Link
            to={`/user/${data.driverId}`}
            className='mr-3 text-dark font-weight-bold'
          >
            {data.id.substring(0, 7)}
          </Link>
        ),

        fullName: (
          <div className='text-capitalize'>
            {data.firstName} {data.lastName}
          </div>
        ),
        time: data?.epoch ? (
          <div className='badge badge-soft-secondary font-size-12 text-center'>
            {new Date(data?.epoch * 1000).toISOString().substr(14, 5)}
          </div>
        ) : (
          '----'
        ),
        date: (
          <div>
            {' '}
            <div>{Moment(data.createdAt).format('l')}</div>
            {/* <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data.createdAt).format('hh:mm A')}
            </div> */}
          </div>
        ),

        status: (
          <div
            className={`badge badge-soft-${
              data?.status === 'NONE'
                ? 'danger'
                : data?.status === 'BUSY'
                ? 'warning'
                : data?.status === 'AVAILABLE'
                ? 'success'
                : 'secondary'
            } font-size-12`}
          >
            {data?.status === 'OFFLINE'
              ? 'NOT AVAILABLE'
              : data?.status === 'NONE'
              ? 'OFFLINE'
              : data?.status}
          </div>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'Name',
          dataField: 'fullName',
        },
        {
          text: 'Time Spent',
          dataField: 'time',
          classes: 'text-center',
          headerStyle: { textAlign: 'center' },
        },
        {
          text: 'Date',
          dataField: 'date',
        },
        {
          text: 'Status',
          dataField: 'status',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-0'>
              {!isLoading && data !== null ? (
                <>
                  <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div>
                      <Input
                        bsSize='sm'
                        id='take'
                        name='take'
                        type='select'
                        onChange={handleLimitChange}
                        value={take}
                      >
                        <option>{take}</option>
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>

                    <div className='d-flex align-items-center'>
                      <div>
                        <FilterDropDown
                          menu={menu}
                          toggleMenu={() => setMenu(!menu)}
                        >
                          <div>
                            <AvForm
                              className='form-horizontal'
                              onValidSubmit={handleDateFilter}
                            >
                              <SimpleBar
                                style={{
                                  maxHeight: '250px',
                                }}
                              >
                                <Row className='px-3  pt-3 pb-0'>
                                  <Col md='12'>
                                    <FormGroup>
                                      <AvField
                                        name='startDate'
                                        type='date'
                                        className='form-control bg-light'
                                        id='startDate'
                                        value={date.start}
                                        helpMessage='Start Date'
                                        placeholder='Please choose the start date...'
                                        // required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md='12'>
                                    <FormGroup>
                                      <AvField
                                        name='endDate'
                                        type='date'
                                        className='form-control bg-light'
                                        id='endDate'
                                        value={date.end}
                                        helpMessage='End Date'
                                        placeholder='Please choose the end date...'
                                        // required
                                      />
                                    </FormGroup>
                                  </Col>{' '}
                                  <Col md='12'>
                                    <FormGroup>
                                      <AvField
                                        type='select'
                                        value={status}
                                        name='Status'
                                        helpMessage='Status'
                                      >
                                        <option value=''>All</option>
                                        <option value='AVAILABLE'>
                                          AVAILABLE
                                        </option>
                                        <option value='OFFLINE'>OFFLINE</option>
                                        <option value='BUSY'>BUSY</option>
                                      </AvField>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </SimpleBar>

                              <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                                <Link
                                  to='#'
                                  onClick={resetHandler}
                                  className='btn btn-sm  bg-light font-size-14'
                                >
                                  <i className=' mdi mdi-refresh mr-1'>
                                    {' '}
                                    Reset
                                  </i>
                                </Link>

                                <button
                                  className='btn btn-success btn-sm mr-2'
                                  type='submit'
                                >
                                  <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                  Send
                                </button>
                              </div>
                            </AvForm>
                          </div>
                        </FilterDropDown>
                      </div>
                      <Input
                        bsSize='sm'
                        id='search'
                        value={search}
                        name='search'
                        onChange={SearchHandler}
                        placeholder='Search'
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField='keyId'
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses='table-responsive logsTable'
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    // breakLabel={'...'}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={3}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    //Bootstrap styling
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(LogsTable);
