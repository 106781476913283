import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Row,
  Col,
  Container,
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import { withRouter, Link } from 'react-router-dom'
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import CheckAction, {
  ReadCheck,
} from '../../../../components/Common/Summary/actionCheck'
import APP_VERSIONTABLE from './appVersionsTable'
import PreviewAppverion from './previewAppVersion'
import GreySummaryCard from '../../../../components/Common/Summary/index'
import {
  fetchApp_versions,
  deleteApp_version,
  updateApp_version,
  updateApp_versionFailed,
} from '../../../../store/actions'

const App_versions = ({
  app_versions,
  tableError,
  fetchApp_versions,
  deleteApp_version,
  loading,
  message,
  history,
  accessControl,
  updateApp_version,
  updateApp_versionFailed,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [previewId, setPreviewId] = useState(null)
  const [deleteId, setDeleteId] = useState(null)
  const breadcrumbItems = [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'App Versions', link: '#' },
  ]

  const deleteApp_versionModal = (id) => {
    setShowDeleteModal(true)
    setDeleteId(id)
  }
  const deleteHandler = () => {
    setShowDeleteModal(false)
    deleteApp_version(deleteId)
    setDeleteId(null)
  }
  const previewHandler = (id) => {
    setShowModal(true)
    setPreviewId(id)
  }

  useEffect(() => {
    updateApp_versionFailed('')
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="App Version" breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field="Configurations" subModule="App Version">
            {message && (
              <Alert color="success" className="text-center">
                {message}
              </Alert>
            )}
            {tableError ? (
              <Alert color="danger" className="text-center">
                {tableError}
              </Alert>
            ) : (
              <>
                <CheckAction
                  field="Configurations"
                  subModule="App Version"
                  iconType={'Create'}
                >
                  <div className="mb-2 d-flex justify-content-end align-items-center ">
                    <Link to="/app_version_form">
                      <Button color="success" className="mr-1" size="sm">
                        <i className="mdi mdi-plus-circle-outline font-size-16"></i>{' '}
                        Create Version
                      </Button>
                    </Link>
                  </div>
                </CheckAction>
                <Row>
                  <Col>
                    <APP_VERSIONTABLE
                      app_versionData={app_versions}
                      tableError={tableError}
                      loading={loading}
                      deleteApp_version={deleteApp_versionModal}
                      fetchApp_versions={fetchApp_versions}
                      history={history}
                      message={message}
                      previewAction={previewHandler}
                      CheckAction={CheckAction}
                      accessControl={accessControl}
                      ReadCheck={ReadCheck}
                      updateApp_version={updateApp_version}
                    />
                  </Col>
                </Row>

                {/* Delete Confirmation Modal */}
                <Modal
                  isOpen={showDeleteModal}
                  toggle={() => setShowDeleteModal(!showDeleteModal)}
                >
                  <ModalBody>
                    <h4 className="card-title mb-4 text-center">
                      {' '}
                      Are you sure you want to delete this app_version
                    </h4>
                    <button
                      onClick={() => deleteHandler()}
                      className="btn btn-outline-danger btn-sm w-100"
                    >
                      Click to Delete
                    </button>
                  </ModalBody>
                </Modal>

                {/* App_version Preview Modal */}
                <Modal
                  isOpen={showModal}
                  toggle={() => setShowModal(!showModal)}
                >
                  <ModalHeader toggle={() => setShowModal(false)}>
                    Preview App_version
                  </ModalHeader>
                  <ModalBody>
                    <PreviewAppverion previewId={previewId} />
                  </ModalBody>
                </Modal>
              </>
            )}
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { accessControl } = state.Account
  const {
    app_versions,
    loading,
    app_versionError,
    message,
    tableError,
  } = state.App_version
  return {
    accessControl,
    app_versions,
    tableError,
    loading,
    app_versionError,
    message,
  }
}

export default connect(mapStateToProps, {
  fetchApp_versions,
  deleteApp_version,
  updateApp_version,
  updateApp_versionFailed,
})(withRouter(App_versions))
