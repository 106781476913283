import {
  FETCH_LOYALTY,
  FETCH_LOYALTY_SUCCESSFUL,
  FETCH_LOYALTY_API_FAILED,
  FETCH_MY_LOYALTY,
  FETCH_MY_LOYALTY_SUCCESSFUL,
  FETCH_MY_LOYALTY_API_FAILED,
  UPDATE_USER_POINT,
  UPDATE_USER_POINT_SUCCESSFUL,
  UPDATE_USER_POINT_FAILED,
  FETCH_LOYALTY_SUMMARY,
  FETCH_LOYALTY_SUMMARY_SUCCESSFULLY,
  FETCH_LOYALTY_SUMMARY_FAILED,
} from './actionTypes'

export const fetchLoyalty = (payload) => {
  return {
    type: FETCH_LOYALTY,
    payload,
  }
}

export const fetchLoyaltySuccessful = (payload) => {
  return {
    type: FETCH_LOYALTY_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchLoyaltyError = (error) => {
  return {
    type: FETCH_LOYALTY_API_FAILED,
    payload: error,
  }
}

export const fetchMyLoyalty = (id) => {
  return {
    type: FETCH_MY_LOYALTY,
    payload: id,
  }
}

export const fetchMyLoyaltySuccessful = (payload) => {
  return {
    type: FETCH_MY_LOYALTY_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchMyLoyaltyError = (error) => {
  return {
    type: FETCH_MY_LOYALTY_API_FAILED,
    payload: error,
  }
}

export const updateUserPoint = (payload) => {
  return {
    type: UPDATE_USER_POINT,
    payload,
  }
}

export const updateUserPointFailed = (error) => {
  return {
    type: UPDATE_USER_POINT_FAILED,
    payload: error,
  }
}

export const updateUserPointSuccessful = (payload) => {
  return {
    type: UPDATE_USER_POINT_SUCCESSFUL,
    payload,
  }
}

//SUMMARY ENTRIES
export const fetchLoyaltySummary = (query) => {
  return {
    type: FETCH_LOYALTY_SUMMARY,
    payload: query,
  }
}

export const fetchLoyaltySummarySuccessfully = (payload) => {
  return {
    type: FETCH_LOYALTY_SUMMARY_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchLoyaltySummaryFailed = (error) => {
  return {
    type: FETCH_LOYALTY_SUMMARY_FAILED,
    payload: error,
  }
}
