import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  UncontrolledTooltip,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from 'reactstrap';
import Moment from 'moment';
import moment from 'moment-timezone';
import SimpleBar from 'simplebar-react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import Loader from '../../../components/Common/Loading/index.js';
import FilterDropDown from '../../../components/Common/FilterDropDown';
import CheckAction from '../../../components/Common/Summary/actionCheck';
import ReactPaginate from 'react-paginate';
import AlertError from '../../../components/Common/AlertCard';
import { ScrollToTop } from '../../../utils/general';

const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';
let skip = 0;
let dataLimit = 10;

const DriverTable = ({
  isAdmin = true,
  loading,
  csvModal,
  complaintError,
  user,
  complaint,
  fetchComplaint,
  previewAction,
  updateAction,
  assignedHandler,
  columns,
  dateFilter,
  subModule,
  field,
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [menu, setMenu] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [take, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');
  const [device, setDevice] = useState('');
  const [priority, setPriority] = useState('');
  const [status, setStatus] = useState('');
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });

  useEffect(() => {
    if (complaint) {
      setData(tableDataHandler());
      setIsLoading(false);
      setPageCount(Math.ceil(complaint?.total / take));
    }
  }, [complaint]);

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take, search, type, status };
    fetchComplaint(pageInfo);
    ScrollToTop();
  };
  console.log(subModule,
    field)
  const resetHandler = () => {
    setMenu(false);
    setStatus('');
    setType('');
    setDevice('');
    setPriority('');
    setSearch('');
    setDate({
      start: '',
      end: '',
    });
    dateFilter({});

    fetchComplaint();
  };

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(complaint.result?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      search,
      skip: pageNumber,
      take: dataLimit,
      type,
      status,
    };
    fetchComplaint(pageInfo);
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    dateFilter({ start: values.startDate, end: values.endDate });
    setStatus(values.Status);
    setType(values.userType);
    setDevice(values.device);
    setPriority(values.priority);
    setMenu(false);
    let queryParams = {
      search,
      take,
      skip,
      status: values.Status,
      type: values.userType,
      priority: values.priority,
      device: values.device,
      start: values.startDate,
      end: values.endDate,
    };
    fetchComplaint(queryParams);
  };

  const GetComplaintByStatus = (e, data) => {
    setType(data.userType);
    setDevice(data.device);
    setPriority(data.priority);
    setStatus(data.Status);
    let queryParams = {
      search,
      skip,
      type: data.userType,
      priority: data.priority,
      device: data.device,
      status: data.Status,
      take,
    };
    fetchComplaint(queryParams);

    setShowFilterModal(false);
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
    };
    // queryParams.skip = skip
    queryParams.take = take;
    fetchComplaint(queryParams);
  };

  const tableDataHandler = () => {
    const rowData = complaint?.result.map((data) => {
      const newData = {
        id: data.id,
        complaintType: (
          <div>
            <div> {data?.type}</div>

            <span
              className={`badge badge-soft-${data?.device === 'WEBSITE'
                ? 'primary'
                : data?.device === 'RIDER'
                  ? 'success'
                  : 'warning'
                }`}
            >
              {data.device}
            </span>
          </div>
        ),
        reporter: (
          <div>
            <div className='text-capitalize'>
              {data?.reporter &&
                `${data?.reporter?.firstName} ${data?.reporter?.lastName}`}
            </div>
            <div className='d-flex align-items-center'>
              <div className='mr-2'>
                <img
                  src={
                    data?.reporter?.picture
                      ? data?.reporter?.picture
                      : avatarImg
                  }
                  alt=''
                  height='20'
                  className='rounded-circle avatar-xs'
                />
              </div>
              <div>
                <span className='d-block'>
                  <span
                    className={`badge badge-${data?.reportee?.userType === 'RIDER'
                      ? 'primary'
                      : data?.reportee?.userType === 'DRIVER'
                        ? 'secondary'
                        : data?.reportee?.userType === 'ADMIN'
                          ? 'success'
                          : 'warning'
                      }`}
                  >
                    {data?.reporter?.userType}
                  </span>
                </span>
              </div>
            </div>
          </div>
        ),

        reportee: (
          <div>
            <div className='text-capitalize'>
              {data?.reportee &&
                `${data?.reportee?.firstName} ${data?.reportee?.lastName}`}
            </div>
            <div className='d-flex align-items-center'>
              <div className='mr-2'>
                <img
                  src={
                    data?.reportee?.picture
                      ? data?.reportee?.picture
                      : avatarImg
                  }
                  alt=''
                  height='20'
                  className='rounded-circle avatar-xs'
                />
              </div>
              <div>
                <span className='d-block'>
                  <span
                    className={`badge badge-${data?.reportee?.userType === 'RIDER'
                      ? 'primary'
                      : data?.reportee?.userType === 'DRIVER'
                        ? 'secondary'
                        : data?.reportee?.userType === 'ADMIN'
                          ? 'success'
                          : 'warning'
                      }`}
                  >
                    {data?.reportee?.userType}
                  </span>
                </span>
              </div>
            </div>
          </div>
        ),
        assignee: (
          <span className='text-capitalize'>
            {data?.assignee?.firstName}-{data?.assignee?.lastName}
            <span className='d-block'>
              <span
                className={`badge badge-${data?.assignee?.userType === 'RIDER'
                  ? 'primary'
                  : data?.assignee?.userType === 'DRIVER'
                    ? 'secondary'
                    : data?.assignee?.userType === 'ADMIN'
                      ? 'success'
                      : 'warning'
                  }`}
              >
                {data?.assignee?.userType}
              </span>
            </span>
          </span>
        ),

        complaintTitle: 'User Complaint',
        description: (
          <div>
            <div className='text-truncate' style={{ maxWidth: '200px' }}>
              {data.title}
            </div>
            <span
              className={`badge badge-${data?.priority === 'LOW'
                ? 'primary'
                : data?.priority === 'HIGH'
                  ? 'danger'
                  : 'warning'
                }`}
            >
              {data.priority}
            </span>
          </div>
        ),
        date: (
          <div>
            {' '}
            <div>{Moment(data.createdAt).format('l')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
        status: (
          <span
            className={`badge badge-soft-${data.status === 'PENDING'
              ? 'secondary'
              : data.status === 'REVIEW'
                ? 'warning'
                : data.status === 'DISPUTED'
                  ? 'success'
                  : data.status === 'COMPLETED'
                    ? 'primary'
                    : 'dark'
              } font-size-12`}
          >
            {data.status}
          </span>
        ),
        assign_by: (
          <div>
            {data.assignor ? (
              <div>
                <div className='text-capitalize'>
                  {data?.assignor &&
                    `${data?.assignor?.firstName} ${data?.assignor?.lastName}`}
                </div>
                <div className='d-flex align-items-center'>
                  <div className='mr-2'>
                    <img
                      src={
                        data?.assignor?.picture
                          ? data?.assignor?.picture
                          : avatarImg
                      }
                      alt=''
                      height='20'
                      className='rounded-circle avatar-xs'
                    />
                  </div>
                  <div>
                    <span className='d-block'>
                      <span className='badge badge-soft-secondary rounded'>
                        {data?.assignor?.userType}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              '---'
            )}
          </div>
        ),
        assign_to: (
          <div>
            {data.assignee ? (
              <div>
                <div className='text-capitalize'>
                  {data?.assignee &&
                    `${data?.assignee?.firstName} ${data?.assignee?.lastName}`}
                </div>
                <div className='d-flex align-items-center'>
                  <div className='mr-2'>
                    <img
                      src={
                        data?.assignee?.picture
                          ? data?.assignee?.picture
                          : avatarImg
                      }
                      alt=''
                      height='20'
                      className='rounded-circle avatar-xs'
                    />
                  </div>
                  <div>
                    <span className='d-block'>
                      <span className='badge badge-soft-secondary rounded'>
                        {data?.assignee?.userType}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <CheckAction field={field} subModule={subModule} iconType={'Edit'}>
                <button
                  className='btn btn-sm btn-success rounded font-size-10'
                  style={{ width: '100px' }}
                  onClick={() => assignedHandler(data.id)}
                >
                  Assign-To
                </button>
              </CheckAction>
            )}
          </div>
        ),
        action: (
          <div className='d-flex align-items-center'>
            <CheckAction field={field} subModule={subModule} iconType={'Read'}>
              <Link
                to='#'
                onClick={() => previewAction(data.id)}
                className='mr-3 text-secondary'
                id='preview'
              >
                <i className='mdi mdi-eye font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='bottom' target='preview'>
                Preview
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field={field} subModule={subModule} iconType={'Edit'}>
              <Link
                to='#'
                onClick={() => updateAction(data.id)}
                className='mr-3 text-primary'
                id='Edit'
              >
                <i className='mdi mdi-pencil font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='Edit'>
                Edit
              </UncontrolledTooltip>
            </CheckAction>
          </div>
        ),
      };
      return newData;
    });
    return {
      columns,
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              {complaintError ? (
                <AlertError error={complaintError} />
              ) : (
                <>
                  {!isLoading && data !== null ? (
                    <>
                      <div className='d-flex justify-content-between align-items-center'>
                        {user.userType === 'ADMIN' && (
                          <div>
                            <h5 className='text-uppercase'>
                              {' '}
                              {!type ? 'All' : type}
                              {type && "'s"} Complaints{' '}
                            </h5>
                          </div>
                        )}
                        <div className='d-flex'>
                          <div className=''>
                            <Button
                              size='sm'
                              color='primary'
                              className='mt-1'
                              onClick={() => csvModal(true)}
                              disabled={complaint?.total > 0 ? false : true}
                            >
                              <i className=' fas fa-cloud-download-alt mr-1' />
                              CSV
                            </Button>
                          </div>
                          <FilterDropDown
                            menu={menu}
                            toggleMenu={() => setMenu(!menu)}
                          >
                            <div>
                              <AvForm
                                className='form-horizontal'
                                onValidSubmit={handleDateFilter}
                              >
                                <SimpleBar
                                  style={{
                                    maxHeight: '250px',
                                  }}
                                >
                                  <Row className='px-3  pt-3 pb-0'>
                                    <Col md='12'>
                                      <FormGroup>
                                        <AvField
                                          name='startDate'
                                          type='date'
                                          className='form-control bg-light'
                                          id='startDate'
                                          value={date.start}
                                          helpMessage='Start Date'
                                          placeholder='Please choose the start date...'
                                          required
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md='12'>
                                      <FormGroup>
                                        <AvField
                                          name='endDate'
                                          type='date'
                                          className='form-control bg-light'
                                          id='endDate'
                                          value={date.end}
                                          helpMessage='End Date'
                                          placeholder='Please choose the end date...'
                                          required
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md='12'>
                                      <FormGroup className='mb-4'>
                                        <AvField
                                          name='userType'
                                          type='select'
                                          value={type}
                                          helpMessage='User Type'
                                          className='form-control bg-light'
                                          id='userType'
                                        >
                                          <option value=''>All</option>
                                          <option value='RIDER'>Rider</option>
                                          <option value='DRIVER'>Driver</option>
                                        </AvField>
                                      </FormGroup>
                                    </Col>
                                    <Col md='12'>
                                      <FormGroup className='mb-4'>
                                        <AvField
                                          name='device'
                                          type='select'
                                          value={device}
                                          helpMessage='Device'
                                          className='form-control bg-light'
                                          id='device'
                                        >
                                          <option value=''>All</option>
                                          <option value='DRIVER'>DRIVER</option>
                                          <option value='WEBSITE'>
                                            WEBSITE
                                          </option>
                                          <option value='RIDER'>RIDER</option>
                                        </AvField>
                                      </FormGroup>
                                    </Col>
                                    <Col md='12'>
                                      <FormGroup className='mb-4'>
                                        <AvField
                                          name='priority'
                                          type='select'
                                          value={priority}
                                          helpMessage='Priority'
                                          className='form-control bg-light'
                                          id='priority'
                                        >
                                          <option value=''>All</option>
                                          <option value='HIGH'>HIGH</option>
                                          <option value='LOW'>LOW</option>
                                          <option value='MEDIUM'>MEDIUM</option>
                                        </AvField>
                                      </FormGroup>
                                    </Col>
                                    <Col md='12'>
                                      <FormGroup>
                                        <AvField
                                          type='select'
                                          value={status}
                                          helpMessage='Status'
                                          name='Status'
                                        >
                                          <option value=''>All</option>
                                          <option value='DISPUTED'>
                                            DISPUTED
                                          </option>
                                          <option value='REVIEW'>REVIEW</option>
                                          <option value='PENDING'>
                                            PENDING
                                          </option>
                                        </AvField>
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                                    <Link
                                      to='#'
                                      onClick={resetHandler}
                                      className='btn btn-sm  bg-light font-size-14'
                                    >
                                      <i className=' mdi mdi-refresh mr-1'>
                                        {' '}
                                        Reset
                                      </i>
                                    </Link>

                                    <button
                                      className='btn btn-success btn-sm mr-2'
                                      type='submit'
                                    >
                                      <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                      Send
                                    </button>
                                  </div>
                                </SimpleBar>
                              </AvForm>
                            </div>
                          </FilterDropDown>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between mb-2 align-items-center'>
                        <div>
                          <Input
                            bsSize='sm'
                            id='take'
                            value={take}
                            name='take'
                            type='select'
                            onChange={handleLimitChange}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div>
                          <Input
                            bsSize='sm'
                            id='search'
                            value={search}
                            name='search'
                            onChange={SearchHandler}
                            placeholder='Search'
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField='id'
                        data={data.rows}
                        columns={data.columns}
                        loading={isLoading}
                        // overlay={overlayFactory()}
                        striped
                        hover
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                      <Modal
                        isOpen={showFilterModal}
                        toggle={() => setShowFilterModal(!showFilterModal)}
                      >
                        <ModalHeader toggle={() => setShowFilterModal(false)}>
                          Filter Data
                        </ModalHeader>
                        <ModalBody>
                          <div>
                            <AvForm
                              className='form-horizontal'
                              onValidSubmit={GetComplaintByStatus}
                            >
                              <Row>
                                <Col md='6'>
                                  <FormGroup className='mb-4'>
                                    <AvField
                                      name='userType'
                                      type='select'
                                      label='User Type'
                                      className='form-control bg-light'
                                      id='userType'
                                    >
                                      <option value=''>All</option>
                                      <option value='RIDER'>Rider</option>
                                      <option value='DRIVER'>Driver</option>
                                    </AvField>
                                  </FormGroup>
                                </Col>
                                <Col md='6'>
                                  <FormGroup className='mb-4'>
                                    <AvField
                                      name='Status'
                                      type='select'
                                      label='Status'
                                      className='form-control bg-light'
                                      id='Status'
                                      value={''}
                                    >
                                      <option value=''>All</option>
                                      <option value='DISPUTED'>DISPUTED</option>
                                      <option value='REVIEW'>REVIEW</option>
                                      <option value='PENDING'>PENDING</option>
                                    </AvField>
                                  </FormGroup>
                                </Col>
                              </Row>

                              <button
                                className='btn btn-success mr-2'
                                type='submit'
                              >
                                {'Submit'}
                              </button>
                              <Link
                                to='#'
                                onClick={() => setShowFilterModal(false)}
                              >
                                <button className='btn btn-secondary'>
                                  Close
                                </button>
                              </Link>
                            </AvForm>
                          </div>
                        </ModalBody>
                      </Modal>
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { user } = state.Account;
  return { user };
};

export default connect(mapStateToProps)(DriverTable);
