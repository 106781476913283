import {
  FETCH_INVITATION,
  FETCH_INVITATION_SUCCESSFUL,
  FETCH_INVITATION_API_FAILED,
  FETCH_USERS_INVITATION,
  FETCH_USERS_INVITATION_SUCCESSFUL,
  FETCH_USERS_INVITATION_API_FAILED,
  FETCH_EACH_INVITATION,
  FETCH_EACH_INVITATION_SUCCESSFUL,
  FETCH_EACH_INVITATION_API_FAILED,
  DELETE_INVITATION,
  DELETE_INVITATION_SUCCESSFUL,
  DELETE_INVITATION_FAILED,
  CREATE_INVITATION,
  CREATE_INVITATION_SUCCESSFUL,
  CREATE_INVITATION_FAILED,
  UPDATE_INVITATION,
  UPDATE_INVITATION_SUCCESSFUL,
  UPDATE_INVITATION_FAILED,
  FETCH_INVITATION_DETAILS,
  FETCH_INVITATION_DETAILS_SUCCESSFULLY,
  FETCH_INVITATION_DETAILS_FAILED,
  FETCH_INVITATION_SUMMARY,
  FETCH_INVITATION_SUMMARY_SUCCESSFULLY,
  FETCH_INVITATION_SUMMARY_FAILED,
  FETCH_FOR_INVITATION_CSV,
  FETCH_FOR_INVITATION_CSV_SUCCESSFULLY,
  FETCH_FOR_INVITATION_CSV_ERROR,
} from './actionTypes';

export const fetchInvitation = (payload) => {
  return {
    type: FETCH_INVITATION,
    payload,
  };
};

export const fetchInvitationSuccessful = (payload) => {
  return {
    type: FETCH_INVITATION_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchInvitationError = (error) => {
  return {
    type: FETCH_INVITATION_API_FAILED,
    payload: error,
  };
};

export const fetchUsersInvitation = (payload) => {
  return {
    type: FETCH_USERS_INVITATION,
    payload,
  };
};

export const fetchUsersInvitationSuccessful = (payload) => {
  return {
    type: FETCH_USERS_INVITATION_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchUsersInvitationError = (error) => {
  return {
    type: FETCH_USERS_INVITATION_API_FAILED,
    payload: error,
  };
};

export const fetchEachInvitation = (user, query) => {
  return {
    type: FETCH_EACH_INVITATION,
    payload: { user, query },
  };
};

export const fetchEachInvitationSuccessful = (payload) => {
  return {
    type: FETCH_EACH_INVITATION_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachInvitationError = (error) => {
  return {
    type: FETCH_EACH_INVITATION_API_FAILED,
    payload: error,
  };
};

export const deleteInvitation = (id) => {
  return {
    type: DELETE_INVITATION,
    payload: id,
  };
};

export const deleteInvitationSuccessful = (payload) => {
  return {
    type: DELETE_INVITATION_SUCCESSFUL,
    payload,
  };
};

export const deleteInvitationFailed = (error) => {
  return {
    type: DELETE_INVITATION_FAILED,
    payload: error,
  };
};

export const createInvitation = (payload) => {
  return {
    type: CREATE_INVITATION,
    payload,
  };
};

export const createInvitationSuccessful = (payload) => {
  return {
    type: CREATE_INVITATION_SUCCESSFUL,
    payload,
  };
};

export const createInvitationFailed = (error) => {
  return {
    type: CREATE_INVITATION_FAILED,
    payload: error,
  };
};

export const updateInvitation = (payload, id) => {
  return {
    type: UPDATE_INVITATION,
    payload: { payload, id },
  };
};

export const updateInvitationFailed = (error) => {
  return {
    type: UPDATE_INVITATION_FAILED,
    payload: error,
  };
};

export const updateInvitationSuccessful = (payload) => {
  return {
    type: UPDATE_INVITATION_SUCCESSFUL,
    payload,
  };
};

export const fetchInvitationDetails = (query) => {
  return {
    type: FETCH_INVITATION_DETAILS,
    payload: query,
  };
};

export const fetchInvitationDetailsSuccessfully = (payload) => {
  return {
    type: FETCH_INVITATION_DETAILS_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchInvitationDetailsFailed = (error) => {
  return {
    type: FETCH_INVITATION_DETAILS_FAILED,
    payload: error,
  };
};

//SUMMARY ENTRIES
export const fetchInvitationSummary = (query) => {
  return {
    type: FETCH_INVITATION_SUMMARY,
    payload: query,
  };
};

export const fetchInvitationSummarySuccessfully = (payload) => {
  return {
    type: FETCH_INVITATION_SUMMARY_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchInvitationSummaryFailed = (error) => {
  return {
    type: FETCH_INVITATION_SUMMARY_FAILED,
    payload: error,
  };
};

export const fetchReferralCSV = (userType) => {
  return {
    type: FETCH_FOR_INVITATION_CSV,
    payload: userType,
  };
};

export const fetchReferralCSVSuccessfully = (payload) => {
  return {
    type: FETCH_FOR_INVITATION_CSV_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchReferralCSVError = (error) => {
  return {
    type: FETCH_FOR_INVITATION_CSV_ERROR,
    payload: error,
  };
};
