import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Form from './Form';

import { fetchEachNotification } from '../../../../store/actions';

const NotificationForm = ({
  fetchEachNotification,
  notification,
  loading,
  message,
}) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  useEffect(() => {
    if (id) {
      fetchEachNotification(id);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Form loading={loading} defaultValue={notification} message={message} />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { notification, loading, message } = state.Notifications;
  return { loading, notification, message };
};

export default connect(mapStateToProps, { fetchEachNotification })(
  NotificationForm
);
