import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  FETCH_REVIEWS,
  FETCH_DRIVER_REVIEWS,
  FETCH_EACH_REVIEW,
  FETCH_REVIEW_SUMMARY,
} from './actionTypes';
import {
  fetchReviewsSuccessful,
  fetchReviewsError,
  fetchEachReviewSuccessful,
  fetchEachReviewError,
  fetchReviewSummaryFailed,
  fetchReviewSummarySuccessfully,
  fetchDriverReviews,
  fetchDriverReviewsSuccessful,
} from './actions';

import {
  GetReviewsService,
  GetEachReviewService,
  GetReviewSummaryService,
  GetDriverReviewsService,
} from '../../services/reviewServices';

function* fetchReviewsHandler({ payload }) {
  try {
    const response = yield call(GetReviewsService, payload);
    yield put(fetchReviewsSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchReviewsError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchReviewsError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchReviewsError(error?.response?.data?.message));
    }
  }
}
function* fetchDriverReviewsHandler({ payload }) {
  try {
    const response = yield call(GetDriverReviewsService, payload);
    yield put(fetchDriverReviewsSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchDriverReviews(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchDriverReviews(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchDriverReviews(error?.response?.data?.message));
    }
  }
}

function* fetchEachReviewHandler({ payload }) {
  try {
    const response = yield call(GetEachReviewService, payload);
    yield put(fetchEachReviewSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachReviewError(error?.response?.data?.message));
  }
}
function* fetchReviewSummaryHandler({ payload }) {
  try {
    const response = yield call(GetReviewSummaryService, payload);
    yield put(fetchReviewSummarySuccessfully(response.data));
  } catch (error) {
    yield put(fetchReviewSummaryFailed(error.response.data));
  }
}
export function* watchFetchReviews() {
  yield takeEvery(FETCH_REVIEWS, fetchReviewsHandler);
}
export function* watchDriverFetchReviews() {
  yield takeEvery(FETCH_DRIVER_REVIEWS, fetchDriverReviewsHandler);
}

export function* watchFetchEachReview() {
  yield takeEvery(FETCH_EACH_REVIEW, fetchEachReviewHandler);
}
export function* watchFetchReviewSummary() {
  yield takeEvery(FETCH_REVIEW_SUMMARY, fetchReviewSummaryHandler);
}

function* reviewSaga() {
  yield all([
    fork(watchFetchReviews),
    fork(watchDriverFetchReviews),
    fork(watchFetchEachReview),
    fork(watchFetchReviewSummary),
  ]);
}

export default reviewSaga;
