import {
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_SUCCESSFUL,
  FETCH_ACTIVITIES_API_FAILED,
  FETCH_EACH_ACTIVITIES,
  FETCH_EACH_ACTIVITIES_SUCCESSFUL,
  FETCH_EACH_ACTIVITIES_API_FAILED,
  DELETE_ACTIVITIES,
  DELETE_ACTIVITIES_SUCCESSFUL,
  DELETE_ACTIVITIES_FAILED,
  CREATE_ACTIVITIES,
  CREATE_ACTIVITIES_SUCCESSFUL,
  CREATE_ACTIVITIES_FAILED,
  UPDATE_ACTIVITIES,
  UPDATE_ACTIVITIES_SUCCESSFUL,
  UPDATE_ACTIVITIES_FAILED,
} from './actionTypes';

const initialState = {
  activities: null,
  activitiesError: null,
  tableError: null,
  activitiesPreview: null,
  message: null,
  loading: false,
};

const Activities = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVITIES:
    case UPDATE_ACTIVITIES:
    case DELETE_ACTIVITIES:
      state = {
        ...state,
        activities: null,
        activitiesError: null,
        tableError: null,
        loading: true,
        message: null,
      };
      break;
    case FETCH_ACTIVITIES_SUCCESSFUL:
      state = {
        ...state,
        activities: action.payload,
        tableError: null,
        activitiesError: null,
        loading: false,
      };
      break;

    case DELETE_ACTIVITIES_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        activitiesError: null,
        message: 'Activity deleted successfully',
      };
      break;
    case UPDATE_ACTIVITIES_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        activitiesError: null,
        message: 'Activity Updated Successfully',
      };
      break;

    case FETCH_EACH_ACTIVITIES:
      state = {
        ...state,
        loading: true,
        activitiesError: null,
        message: null,
      };
      break;

    case FETCH_EACH_ACTIVITIES_SUCCESSFUL:
      state = {
        ...state,
        activitiesPreview: action.payload,
        loading: false,
        activitiesError: null,
        message: null,
      };
      break;

    case CREATE_ACTIVITIES:
      state = {
        ...state,
        loading: true,
        activitiesError: null,
        message: null,
      };
      break;

    case CREATE_ACTIVITIES_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        activitiesError: null,
        message: `${action.payload.name}  created successfully`,
      };
      break;

    case FETCH_ACTIVITIES_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case CREATE_ACTIVITIES_FAILED:
    case DELETE_ACTIVITIES_FAILED:
    case FETCH_EACH_ACTIVITIES_API_FAILED:
      state = {
        ...state,
        activities: null,
        activitiesPreview: null,
        loading: false,
        message: null,
        activitiesError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_ACTIVITIES_FAILED:
      state = {
        ...state,
        loading: false,
        activitiesError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Activities;
