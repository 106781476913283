import React, { useState, useEffect } from 'react';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
  DirectionsRenderer,
} from 'react-google-maps';
import * as parkData from './data.json';
import Moment from 'moment';
import socket, { Socket } from '../../../services/socketService';
import startPoint from '../../../assets/images/markers_icon/location.png';
import rideLogSVG from '../../../assets/images/markers_icon/ridelog.svg';

function Map({
  startLocation,
  endLocation,
  driverLocalion,
  locationPermission,
  rideLog,
}) {
  const [selectedPark, setSelectedPark] = useState(null);
  const [directions, setDirection] = useState(null);
  const [markerPosition, setMarker] = useState({
    lat: startLocation.lat,
    lng: startLocation.lng,
  });
  const [showInfoWindow, setShowInfoWindow] = useState({
    processing: false,
    approaching: false,
    arrived: false,
    inroute: false,
    completed: false,
  });

  useEffect(() => {
    DirectionHandler();
    const listener = (e) => {
      if (e.key === 'Escape') {
        setSelectedPark(null);
      }
    };
    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, []);

  const DirectionHandler = () => {
    const DirectionsService = new window.google.maps.DirectionsService();

    DirectionsService.route(
      {
        origin: new window.google.maps.LatLng(
          startLocation.lat,
          startLocation.lng
        ),
        destination: new window.google.maps.LatLng(
          endLocation.lat,
          endLocation.lng
        ),
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirection(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  const handleLocation = (e) => {
    if (locationPermission) {
      Socket('driver:log', {
        token: JSON.parse(localStorage.getItem('authTokens')).accessToken,
        body: {
          lat: e.latLng?.lat(),
          lng: e.latLng?.lng(),
        },
      });
      setMarker({ lat: e.latLng?.lat(), lng: e.latLng?.lng() });
    }
  };

  let processing = rideLog?.find((log) => log.type === 'PROCESSING');
  let approaching = rideLog?.find((log) => log.type === 'APPROACHING');
  let arrived = rideLog?.find((log) => log.type === 'ARRIVED');
  let inroute = rideLog?.find((log) => log.type === 'INROUTE');
  let completed = rideLog?.find((log) => log.type === 'COMPLETED');

  return (
    <>
      {directions !== null && (
        <GoogleMap
          defaultZoom={9}
          defaultCenter={{ lat: 9.0666664, lng: 7.4833314 }}
          // defaultCenter={{ lat: 41.8507300, lng: -87.6512600 }}
          onClick={(e) => handleLocation(e)}
          // defaultOptions={{ styles: mapStyles }}
        >
          <Marker
            position={driverLocalion}
            icon={{
              url: startPoint,
              scaledSize: new window.google.maps.Size(25, 25),
            }}
          />
          {locationPermission && (
            <Marker
              position={markerPosition}
              icon={{
                url: startPoint,
                scaledSize: new window.google.maps.Size(25, 25),
              }}
            />
          )}

          <DirectionsRenderer
            directions={directions}
            hideRouteList={true}
            draggable={false}
            suppressMarkers={true}
          />

          {/* Markers for Ride Logs */}
          <>
            {processing && (
              <Marker
                position={{
                  lat: processing?.location.lat,
                  lng: processing?.location.lng,
                }}
                icon={{
                  url: rideLogSVG,
                  scaledSize: new window.google.maps.Size(35, 35),
                }}
                title={processing?.type}
                onMouseOver={() =>
                  setShowInfoWindow({ ...showInfoWindow, processing: true })
                }
                onMouseOut={() =>
                  setShowInfoWindow({ ...showInfoWindow, processing: false })
                }
              >
                {showInfoWindow.processing && (
                  <InfoWindow>
                    <div>
                      <h6>{processing?.type}</h6>
                      <div>
                        Time
                        <br />
                        <div className='badge badge-soft-secondary font-size-10'>
                          {Moment(processing.createdAt).format('hh:mm A')}
                        </div>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            )}
            {approaching && (
              <Marker
                position={{
                  lat: approaching?.location.lat,
                  lng: approaching?.location.lng,
                }}
                icon={{
                  url: rideLogSVG,
                  scaledSize: new window.google.maps.Size(35, 35),
                }}
                title={approaching?.type}
                onMouseOver={() =>
                  setShowInfoWindow({ ...showInfoWindow, approaching: true })
                }
                onMouseOut={() =>
                  setShowInfoWindow({ ...showInfoWindow, approaching: false })
                }
              >
                {showInfoWindow.approaching && (
                  <InfoWindow>
                    <div>
                      <h6>{approaching?.type}</h6>
                      <div>
                        Time
                        <br />
                        <div className='badge badge-soft-secondary font-size-10'>
                          {Moment(approaching.createdAt).format('hh:mm A')}
                        </div>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            )}
            {arrived && (
              <Marker
                position={{
                  lat: arrived?.location.lat,
                  lng: arrived?.location.lng,
                }}
                icon={{
                  url: rideLogSVG,
                  scaledSize: new window.google.maps.Size(35, 35),
                }}
                title={arrived?.type}
                onMouseOver={() =>
                  setShowInfoWindow({ ...showInfoWindow, arrived: true })
                }
                onMouseOut={() =>
                  setShowInfoWindow({ ...showInfoWindow, arrived: false })
                }
              >
                {showInfoWindow.arrived && (
                  <InfoWindow>
                    <div>
                      <h6>{arrived?.type}</h6>
                      <div>
                        Time
                        <br />
                        <div className='badge badge-soft-secondary font-size-10'>
                          {Moment(arrived.createdAt).format('hh:mm A')}
                        </div>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            )}
            {inroute && (
              <Marker
                position={{
                  lat: inroute?.location.lat,
                  lng: inroute?.location.lng,
                }}
                icon={{
                  url: rideLogSVG,
                  scaledSize: new window.google.maps.Size(35, 35),
                }}
                title={inroute?.type}
                onMouseOver={() =>
                  setShowInfoWindow({ ...showInfoWindow, inroute: true })
                }
                onMouseOut={() =>
                  setShowInfoWindow({ ...showInfoWindow, inroute: false })
                }
              >
                {showInfoWindow.inroute && (
                  <InfoWindow>
                    <div>
                      <h6>{inroute?.type}</h6>
                      <div>
                        Time
                        <br />
                        <div className='badge badge-soft-secondary font-size-10'>
                          {Moment(inroute.createdAt).format('hh:mm A')}
                        </div>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            )}
            {completed && (
              <Marker
                position={{
                  lat: completed?.location.lat,
                  lng: completed?.location.lng,
                }}
                icon={{
                  url: rideLogSVG,
                  scaledSize: new window.google.maps.Size(35, 35),
                }}
                title={completed?.type}
                onMouseOver={() =>
                  setShowInfoWindow({ ...showInfoWindow, completed: true })
                }
                onMouseOut={() =>
                  setShowInfoWindow({ ...showInfoWindow, completed: false })
                }
              >
                {showInfoWindow.completed && (
                  <InfoWindow>
                    <div>
                      <h6>{completed?.type}</h6>
                      <div>
                        Time
                        <br />
                        <div className='badge badge-soft-secondary font-size-10'>
                          {Moment(completed.createdAt).format('hh:mm A')}
                        </div>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            )}
          </>
        </GoogleMap>
      )}
    </>
  );
}

const MapWrapped = withScriptjs(withGoogleMap(Map));

export default function App({
  startLocation,
  endLocation,
  driverLocalion,
  locationPermission,
  rideLog,
}) {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        startLocation={startLocation}
        endLocation={endLocation}
        driverLocalion={driverLocalion}
        locationPermission={locationPermission}
        rideLog={rideLog}
      />
    </div>
  );
}
