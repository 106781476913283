import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Col, Row, Card, CardBody, Media } from 'reactstrap';
import AccessTable from './table';

const MiniWidgets = () => {
  return (
    <React.Fragment>
      <Row>
        {[1, 2, 3, 4].map((report) => (
          <Col key={report}>
            <Card>
              <CardBody className="pb-0">
                <Media>
                  <Media body className="overflow-hidden">
                    <div>
                      <Skeleton duration={10} />
                    </div>
                  </Media>
                </Media>
              </CardBody>
              <CardBody className="border-top py-1">
                <h2>
                  <Skeleton duration={10} height={50} />
                </h2>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      <Row>
        <Col>
          <AccessTable />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MiniWidgets;
