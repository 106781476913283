import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_BUS_STOPS,
  FETCH_EACH_BUS_STOP,
  CREATE_BUS_STOP,
  DELETE_BUS_STOP,
  UPDATE_BUS_STOP,
} from './actionTypes';
import {
  fetchBusStopsSuccessful,
  fetchBusStopsError,
  fetchEachBusStopSuccessful,
  fetchEachBusStopError,
  deleteBusStopSuccessful,
  deleteBusStopFailed,
  createBusStopSuccessful,
  createBusStopFailed,
  updateBusStopSuccessful,
  updateBusStopFailed,
} from './actions';

import {
  GetBusStopService,
  GetEachBusStopService,
  DeleteBusStopService,
  CreateBusStopService,
  UpdateBusStopService,
} from '../../services/busStopService';

function* fetchBusStopsHandler({ payload }) {
  try {
    const response = yield call(GetBusStopService, payload);
    yield put(fetchBusStopsSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchBusStopsError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchBusStopsError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchBusStopsError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEachBusStopHandler({ payload }) {
  try {
    const response = yield call(GetEachBusStopService, payload);
    yield put(fetchEachBusStopSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachBusStopError(error?.response?.data.error?.message));
  }
}

function* deleteBusStopHandler({ payload }) {
  try {
    const response = yield call(DeleteBusStopService, payload);
    yield put(deleteBusStopSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteBusStopFailed(error?.response?.data?.message));
  }
}

function* createBusStopHandler({ payload }) {
  try {
    const response = yield call(CreateBusStopService, payload);
    yield put(createBusStopSuccessful(response.data.result));
  } catch (error) {
    yield put(createBusStopFailed(error?.response?.data?.message));
  }
}

function* updateBusStopHandler({ payload }) {
  try {
    const response = yield call(UpdateBusStopService, payload);
    yield put(updateBusStopSuccessful(response.data.result));
  } catch (error) {
    yield put(updateBusStopFailed(error?.response?.data?.message));
  }
}

// BUS_STOP SUMMARY

export function* watchFetchBusStop() {
  yield takeEvery(FETCH_BUS_STOPS, fetchBusStopsHandler);
}

export function* watchFetchEachBusStop() {
  yield takeEvery(FETCH_EACH_BUS_STOP, fetchEachBusStopHandler);
}

export function* watchDeleteBusStop() {
  yield takeEvery(DELETE_BUS_STOP, deleteBusStopHandler);
}
export function* watchCreateBusStop() {
  yield takeEvery(CREATE_BUS_STOP, createBusStopHandler);
}
export function* watchUpdateBusStop() {
  yield takeEvery(UPDATE_BUS_STOP, updateBusStopHandler);
}

function* busStopSaga() {
  yield all([
    fork(watchFetchBusStop),
    fork(watchFetchEachBusStop),
    fork(watchDeleteBusStop),
    fork(watchCreateBusStop),
    fork(watchUpdateBusStop),
  ]);
}

export default busStopSaga;
