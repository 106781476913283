import React from 'react'
import GTBank from '../../../assets/images/banks/gtbank.png'
import Access from '../../../assets/images/banks/access_bank.png'
import Fidelity from '../../../assets/images/banks/fidelity_bank.jpeg'
import FirstBank from '../../../assets/images/banks/first_bank.png'
import Stanbic from '../../../assets/images/banks/stanbic.jpeg'
import Sterling from '../../../assets/images/banks/sterlin_bank.png'
import UBA from '../../../assets/images/banks/uba_bank.jpeg'
import Union from '../../../assets/images/banks/union_bank.png'
import Zenith from '../../../assets/images/banks/zenith_bank.png'
import Bank_logo from '../../../assets/images/banks/bank_logo.png'

const BankLogo = ({ bank }) => {
  return (
    <div>
      {(() => {
        switch (bank.toLowerCase()) {
          case 'access bank':
            return (
              <img
                src={Access}
                alt=""
                height="20"
                className="rounded-circle avatar-xs"
              />
            )
            break
          case 'guaranty trust bank':
            return (
              <img
                src={GTBank}
                alt=""
                height="20"
                className="rounded-circle avatar-xs"
              />
            )
            break
          case 'first bank of nigeria':
            return (
              <img
                src={FirstBank}
                alt=""
                height="20"
                className="rounded-circle avatar-xs"
              />
            )
            break
          case 'fidelity bank':
            return (
              <img
                src={Fidelity}
                alt=""
                height="20"
                className="rounded-circle avatar-xs"
              />
            )
            break
          case 'stanbic ibtc bank':
            return (
              <img
                src={Stanbic}
                alt=""
                height="20"
                className="rounded-circle avatar-xs"
              />
            )
            break
          case 'sterling bank':
            return (
              <img
                src={Sterling}
                alt=""
                height="20"
                className="rounded-circle avatar-xs"
              />
            )
            break
          case 'united bank for africa':
            return (
              <img
                src={UBA}
                alt=""
                height="20"
                className="rounded-circle avatar-xs"
              />
            )
            break
          case 'union bank of nigeria':
            return (
              <img
                src={Union}
                alt=""
                height="20"
                className="rounded-circle avatar-xs"
              />
            )
            break
          case 'zenith bank':
            return (
              <img
                src={Zenith}
                alt=""
                height="20"
                className="rounded-circle avatar-xs"
              />
            )
            break
          default:
            return (
              <img
                src={Bank_logo}
                alt=""
                height="20"
                className="rounded-circle avatar-xs"
              />
            )
        }
      })()}
    </div>
  )
}

export default BankLogo
