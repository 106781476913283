import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { getS3DocUrlHandler } from '../../../../utils/general';
import { Link, withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import {
  updateDocument,
  updateDocumentFailed,
  fetchDocument,
} from '../../../../store/actions';

const UploadDoc = ({
  updateDocument,
  updateDocumentFailed,
  fetchDocument,
  updateDocUploadModalDisplay,
  previewId,
  isProfile,
  message,
  uploadStatus,
  loading,
  history,
  documentError,
}) => {
  const [docError, setDocError] = useState(false);
  const [selectedFiles, setFileUpload] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event, values) => {
    setIsLoading(true);
    setDocError(false);

    if (selectedFiles.length > 0) {
      try {
        let result = await getS3DocUrlHandler(selectedFiles[0], 'document');
        const documentData = {
          ...values,
        };
        documentData.id = previewId;

        documentData.documentUrl = result?.data.result?.mediaUrl;
        documentData.documentThumbUrl = result?.data?.result.mediaThumbUrl;
        if (uploadStatus) {
          documentData.status = uploadStatus;
        }
        updateDocument(documentData);
        setIsLoading(false);
      } catch (error) {
        alert('error in document upload');

        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setDocError('Please choose a document to upload');
    }
  };

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setFileUpload(files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        updateDocUploadModalDisplay(false);
        updateDocumentFailed('');
      }, 2000);
      if (isProfile && message) {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  }, [message]);

  useEffect(() => {
    updateDocumentFailed('');
  }, []);

  return (
    <div>
      {message && (
        <Alert color='success' className='text-center'>
          {message}
        </Alert>
      )}
      {documentError && (
        <Alert color='danger' className='text-center'>
          {documentError}
        </Alert>
      )}
      {docError && (
        <Alert color='danger' className='text-center'>
          {docError}
        </Alert>
      )}
      <div>
        <AvForm className='form-horizontal' onValidSubmit={handleSubmit}>
          <Row>
            <Col>
              <Dropzone
                onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
                multiple={false}
                accept='application/pdf, 
                  
                  image/png, 
                  image/jpeg, 
                  image/jpg,'
              >
                {({ getRootProps, getInputProps }) => (
                  <div className='dropzone'>
                    <div
                      className='dz-message needsclick mt-2'
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className='mb-3'>
                        <i className='display-4 text-muted ri-upload-cloud-2-line'></i>
                      </div>
                      <h4>Drop or click here to upload document.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className='dropzone-previews mt-3' id='file-previews'>
                {selectedFiles.map((f, i) => {
                  if (
                    f.type === 'image/png' ||
                    f.type === 'image/jpeg' ||
                    f.type === 'image/jpg'
                  ) {
                    return (
                      <Card
                        className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                        key={i + '-file'}
                      >
                        <div className='p-2'>
                          <Row className='align-items-center'>
                            <Col className='col-auto'>
                              <img
                                data-dz-thumbnail=''
                                height='100'
                                className='rounded bg-light'
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                            <Col>
                              <Link
                                to='#'
                                className='text-muted font-weight-bold'
                              >
                                {f.name}
                              </Link>
                              <p className='mb-0'>
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    );
                  } else {
                    return (
                      <div className='p-2' key={i + '-file'}>
                        <Row className='align-items-center'>
                          <Col>
                            <AvField
                              className='text-muted font-weight-bold form-control bg-light'
                              value={f.name}
                              name='documentUrl'
                            />
                            <p className='mb-0'>
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    );
                  }
                })}
              </div>
            </Col>
          </Row>
          <button className='btn btn-success mr-2' type='submit'>
            {isLoading ? 'Uploading ...' : 'Upload'}
          </button>

          <Link to='#' onClick={() => updateDocUploadModalDisplay(false)}>
            <button className='btn btn-secondary'>Cancel</button>
          </Link>
        </AvForm>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, documentError } = state.Document;
  return { message, loading, documentError };
};

export default connect(mapStateToProps, {
  updateDocument,
  updateDocumentFailed,
})(withRouter(UploadDoc));
