import React from 'react';
import { Row, Col, Table } from 'reactstrap';
import Moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import UserReportCard from './UserReportCard';

const Overview = ({
  userInfo,
  bank,
  document,
  match,
  driverLogs,
  user_location,
}) => {
  const docInfo = document?.result?.find((doc) => doc.key === 'DDL');

  return (
    <div>
      <Row>
        <Col sm={6} className='border rounded p-2'>
          <div className='d-flex justify-content-between'>
            <h4 className='text-capitalize card-title mb-4'>
              {' '}
              {userInfo.userType} Details{' '}
            </h4>
            {userInfo?.userType === 'DRIVER' && (
              <span>
                Online-Status:{' '}
                <span
                  className={`badge badge-soft-${
                    driverLogs?.result[0]?.status === 'OFFLINE'
                      ? 'danger'
                      : driverLogs?.result[0]?.status === 'BUSY'
                      ? 'warning'
                      : driverLogs?.result[0]?.status === 'AVAILABLE'
                      ? 'success'
                      : 'secondary'
                  } font-size-12`}
                >
                  {driverLogs?.result[0]?.status
                    ? driverLogs?.result[0]?.status
                    : 'No data'}
                </span>
              </span>
            )}
          </div>
          <Table hover>
            <tbody>
              <tr>
                <td className='text-muted text-right col-6'>First Name</td>
                <td className='font-weight-bold text-left col-6 text-capitalize'>
                  {userInfo?.firstName}
                </td>
              </tr>
              <tr>
                <td className='text-muted text-right col-6'> Last Name</td>
                <td className='font-weight-bold text-left col-6 text-capitalize'>
                  {userInfo?.lastName}
                </td>
              </tr>
              <tr>
                <td className='text-muted text-right col-6'>Phone Number</td>
                <td className='font-weight-bold text-left col-6'>
                  {userInfo?.phone}
                </td>
              </tr>

              <tr>
                <td className='text-muted text-right col-6'>Device</td>
                <td className='font-weight-bold text-left col-6'>
                  {userInfo?.device}
                </td>
              </tr>
              <tr>
                <td className='text-muted text-right col-6'>Email Address</td>
                <td className='font-weight-bold text-lef col-6t'>
                  {userInfo?.email}
                </td>
              </tr>
              <tr>
                <td className='text-muted text-right col-6'> Date of Birth</td>
                <td className='font-weight-bold text-lef col-6t'>
                  {Moment(userInfo?.dob).format('DD/MM/YYYY')}
                </td>
              </tr>

              <tr>
                <td className='text-muted text-right col-6'>Gender</td>
                <td className='font-weight-bold text-lef col-6t'>
                  {userInfo?.gender ? userInfo?.gender : '---'}
                </td>
              </tr>
              <tr>
                <td className='text-muted text-right col-6'>
                  Residential Address
                </td>
                <td className='font-weight-bold text-left col-6'>
                  {userInfo?.address ? userInfo?.address : '-'}
                  {userInfo?.state ? `,${userInfo?.state}` : '-'}
                  {userInfo?.country ? `,${userInfo?.country}` : '-'}
                </td>
              </tr>

              <tr>
                <td className='text-muted text-right col-6'>State of Origin</td>
                <td className='font-weight-bold text-left col-6'>
                  {userInfo?.state ? userInfo?.state : '---'}
                </td>
              </tr>
              <tr>
                <td className='text-muted text-right col-6'>
                  {' '}
                  Date of Registration
                </td>
                <td className='font-weight-bold text-left col-6'>
                  {Moment(userInfo?.createdAt).format('DD/MM/YYYY')}
                </td>
              </tr>
              {userInfo?.isActive && (
                <tr>
                  <td className='text-muted text-right col-6'>
                    {' '}
                    Regsitration Location
                  </td>
                  <td className='font-weight-bold text-left col-6'>
                    {user_location?.result?.address ? (
                      <>
                        {user_location?.result?.address}

                        <div className='mt-3'>
                          <div className='badge badge-soft-secondary font-size-10'>
                            {Moment(user_location?.result?.updatedAt).format(
                              'DD/MM/YYYY'
                            )}{' '}
                            {Moment(user_location?.result?.updatedAt).format(
                              'LT'
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      '----'
                    )}
                  </td>
                </tr>
              )}
              {userInfo?.userType === 'DRIVER' && (
                <>
                  <tr>
                    <td className='text-muted text-right col-6'>
                      {' '}
                      License Expiry Date
                    </td>
                    <td className='font-weight-bold text-left col-6'>
                      {docInfo
                        ? Moment(docInfo?.expiry).format('DD/MM/YYYY')
                        : '---'}
                    </td>
                  </tr>
                  <tr>
                    <td className='text-muted text-right col-6'>
                      Online Status
                    </td>
                    <td className='font-weight-bold text-left col-6'>
                      <span
                        className={`badge text-danger badge-soft-${
                          driverLogs?.result[0]?.status === 'OFFLINE'
                            ? 'danger'
                            : driverLogs?.result[0]?.status === 'BUSY'
                            ? 'warning'
                            : driverLogs?.result[0]?.status === 'AVAILABLE'
                            ? 'success'
                            : 'secondary'
                        } font-size-12`}
                      >
                        {driverLogs?.result[0]?.status
                          ? driverLogs?.result[0]?.status
                          : 'No data'}
                      </span>
                    </td>
                  </tr>
                </>
              )}
              {bank && bank.result.length !== 0 && (
                <tr>
                  <td className='text-muted text-right col-6'> Bank Details</td>
                  <td className='font-weight-bold text-left col-6'>
                    <div>
                      {' '}
                      <h5 className='card-title font-size-12'>
                        {bank.result[0]?.accountName}
                      </h5>
                      <p className='mb-0 text-muted'>
                        {bank.result[0]?.accountNumber}
                      </p>
                      <p className='mb-0 text-muted'>
                        {bank.result[0]?.bankName}
                      </p>
                      <span
                        className={`badge badge-soft-${
                          bank.result[0]?.status.toLowerCase() === 'active'
                            ? 'success'
                            : bank.result[0]?.status.toLowerCase() ===
                              'inactive'
                            ? 'danger'
                            : bank.result[0]?.status.toLowerCase() ===
                              'sanction'
                            ? 'warning'
                            : 'dark'
                        } font-size-10`}
                      >
                        {bank.result[0]?.status}
                      </span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <Col sm={6}>
          <UserReportCard user={userInfo} />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { driverLogs } = state.Users;
  return { driverLogs };
};

export default connect(mapStateToProps, {})(withRouter(Overview));
