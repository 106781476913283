export const Options = [
  {
    value: 'I couldn’t get a driver on time',
    label: 'The rider couldn’t get a driver on time',
  },
  {
    value: 'I am just testing the app',
    label: 'I am just testing the app',
  },
  {
    value: 'Drivers kept cancelling my request',
    label: 'Drivers kept cancelling the request request',
  },
  { value: 'An emergency came up', label: 'An emergency came up' },
  { value: 'Driver asked to cancel', label: 'Driver asked to cancel' },
  { value: 'Driver is faraway', label: 'Driver is faraway' },
  { value: 'Driver not moving', label: 'Driver not moving' },
  { value: 'Driver is unreachable', label: 'Driver is unreachable' },
  { value: 'Payment method', label: 'Payment method' },
  {
    value: 'Driver is no where to be found',
    label: 'Driver is no where to be found',
  },
  {
    value: 'The driver is not who is displayed in my app.',
    label: 'The driver is not who is displayed in my app.',
  },
  {
    value: 'Vehicle is not the same as displayed in my app',
    label: 'Vehicle is not the same as displayed in my app',
  },
  {
    value: 'Vehicle condition is in bad shape',
    label: 'Vehicle condition is in bad shape',
  },
];
