import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Card, CardBody, Row, Col, Input, Alert } from 'reactstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import ReactPaginate from 'react-paginate'
import queryString from 'query-string'
import Loader from '../../../../components/Common/Loading/index.js'
import { Link } from 'react-router-dom'
import { QueryReallignment, ScrollToTop } from '../../../../utils/general'
import Moment from 'moment'
import moment from 'moment-timezone'
import { fetchRewardHistory } from '../../../../store/actions'

const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
let skip = 0
let dataLimit = 10

const RewardHistoryTable = ({
  history,
  rewardHistory,
  fetchRewardHistory,
  message,
  rewardHistoryError,
}) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [pageCount, setPageCount] = useState('')
  const [take, setLimit] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)
  const [search, setSearch] = useState('')
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  })
  const [status, setStatus] = useState('')

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(rewardHistory?.total / e.target.value))
    setLimit(parseInt(e.target.value))
    dataLimit = parseInt(e.target.value)
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      search,
      // start: date.start,
      // end: date.end,
    }
    fetchRewardHistory(pageInfo)
  }

  const handlePageClick = async (data) => {
    skip = data.selected
    setPageNumber(parseInt(data.selected))
    if (skip > 0) {
      skip += '0'
      setPageNumber(parseInt(data.selected + '0'))
    }
    let pageInfo = {
      skip,
      take,
      search,
    }
    fetchRewardHistory(pageInfo)
    ScrollToTop()
  }

  const SearchHandler = (event) => {
    const search = event.target.value
    setSearch(search)
    let queryParams = {
      search,
      take,
      // skip,
      start: date.start,
      end: date.end,
    }
    fetchRewardHistory(queryParams)
  }
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          // start: date.start,
          // end: date.end,
        }
        fetchRewardHistory(queryParams)
      }, 2000)
    }
  }, [message])

  useEffect(() => {
    if (rewardHistory) {
      SetUrlWithParams()
      setData(tableDataHandler())
      setIsLoading(false)
      setPageCount(Math.ceil(rewardHistory?.total / take))
    }
  }, [rewardHistory])

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler()
    fetchRewardHistory(parsedUrlParams)
  }, [])

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      // start: date.start,
      // end: date.end,
    }
    let result = QueryReallignment(queryParams)

    history.push({
      pathname: history?.location.pathname,
      search: result,
    })
  }

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history?.location?.search)

    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search)
    }
    return parsedUrlParams
  }

  const GetPromoByStatus = (status) => {
    setStatus(status)
    let queryParams = {
      search,
      take,
      skip,
      status,
      // start: date.start,
      // end: date.end,
    }
    fetchRewardHistory(queryParams)
  }
  const tableDataHandler = () => {
    const rowData = rewardHistory?.result?.map((data, i) => {
      return {
        id: data.id,
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,

        name: (
          <Link
            to="#"
            // onClick={() => previewAction(data.id)}
            className="text-dark font-weight-bold"
          >
            <div className="d-flex align-items-center">
              <img
                src={data?.reward.imageUrl ? data?.reward.imageUrl : avatarImg}
                alt=""
                height="20"
                className="rounded avatar-sm mr-2"
              />
              <span className="text-capitalize">
                {data.reward.name}
                <span className="d-block">
                  <span className="badge badge-soft-secondary rounded">
                    {' '}
                    {data.reward.category}{' '}
                  </span>
                </span>
              </span>
            </div>
          </Link>
        ),
        value: (
          <span>
            <span className="d-block">
              ₦{data.value.toLocaleString()}
              {data.point && (
                <div>
                  <span className="badge badge-warning rounded">
                    {data.point}
                  </span>
                </div>
              )}
            </span>
          </span>
        ),
        user: (
          <Link
            to={`/user/${data.user.id}`}
            className="text-dark font-weight-bold"
          >
            <div className="d-flex align-items-center">
              <img
                src={data?.user?.picture ? data?.user.picture : avatarImg}
                alt=""
                height="20"
                className="rounded-circle avatar-xs mr-2"
              />

              <div>
                <div className="text-capitalize">
                  {data.user.firstName} {data.user.lastName}
                </div>

                <span className="text-muted ml-2 badge badge-soft-secondary">
                  {data?.user.userType}
                </span>
              </div>
            </div>
          </Link>
        ),
        date: (
          <div>
            {' '}
            <div>{Moment(data.createdAt).format('l')}</div>
            <div className="badge badge-soft-secondary font-size-10">
              {' '}
              {Moment(data.createdAt).format('LT')}
            </div>
          </div>
        ),
        status: (
          <h1
            className={`badge badge-soft-${
              data.status.toLowerCase() === 'active'
                ? 'success'
                : data.status.toLowerCase() === 'inactive'
                ? 'danger'
                : 'warning'
            }`}
          >
            {data.status === 'COMINGSOON' ? 'COMING SOON' : data.status}
          </h1>
        ),
      }
    })
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a
            }
            return a - b
          },
        },
        {
          text: 'Reward',
          dataField: 'name',
        },
        {
          text: 'User',
          dataField: 'user',
          sort: true,
        },
        {
          text: 'Value - Point',
          dataField: 'value',
          sort: true,
        },
        {
          text: 'Created',
          dataField: 'date',
        },
        {
          text: 'Status',
          dataField: 'status',
        },
      ],
      rows: rowData,
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-3">
              {message && (
                <Alert color="success" className="text-center">
                  {message}
                </Alert>
              )}
              {rewardHistoryError ? (
                <Alert color="danger" className="text-center">
                  {rewardHistoryError}
                </Alert>
              ) : !isLoading && data !== null ? (
                <>
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <Input
                        bsSize="sm"
                        id="take"
                        name="take"
                        type="select"
                        onChange={handleLimitChange}
                      >
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>
                    <div>
                      <Input
                        bsSize="sm"
                        id="search"
                        name="search"
                        onChange={SearchHandler}
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField="id"
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses="table-responsive"
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { rewardHistory, loading, rewardHistoryError, message } = state.Reward
  return { rewardHistory, loading, rewardHistoryError, message }
}

export default connect(mapStateToProps, {
  fetchRewardHistory,
})(RewardHistoryTable)
