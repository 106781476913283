import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import CashoutTable from './cashoutTable';
import PreviewCashout from './previewCashout';
import UpdateModal from './UpdateModal';
import MiniWidgets from './miniWidgets';
import GreySummaryCard from '../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../components/Common/Summary/actionCheck';

import {
  fetchCashout,
  deleteCashout,
  updateCashout,
  updateCashoutStatus,
  updateCashoutFailed,
  generateOTP,
  generateOTPFailed,
  fetchCashoutCSV,
  fetchCashoutCSVError,
} from '../../../store/actions';

const Cashout = ({
  cashout,
  fetchCashout,
  deleteCashout,
  generateOTP,
  generateOTPFailed,
  accessControl,
  otp,
  loadingOTP,
  otpError,
  otpMessage,
  user,
  tableError,
  updateCashoutStatus,
  updateCashoutFailed,
  cashoutError,
  loading,
  message,
  history,
  fetchCashoutCSV,
  fetchCashoutCSVError,
  csvData,
  csv_loading,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [dateFilter, setDate] = useState({});
  const [showCancelModal, setCancelModal] = useState(false);
  const [showApproveModal, setApproveModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [cancellationReason, setReasonForm] = useState('');
  const [Error, setError] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [disableButton, setDisable] = useState(false);
  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'Account', link: '#' },
    { title: 'Cashout', link: '/cashout' },
  ];

  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };

  const updateHandler = (data) => {
    setShowUpdateModal(true);
    setUpdateData(data);
  };

  const handleGenerateOTP = () => {
    setDisable(true);
    generateOTP({ email: user.email, type: 'CASHOUT_APPROVE' });
    setTimeout(() => {
      setDisable(false);
    }, 15000);
  };

  const ApprovalHandler = (id) => {
    generateOTPFailed('');
    setApproveModal(true);
    setPreviewId(id);
  };

  const statusIdHandler = (id) => {
    setCancelModal(true);
    setPreviewId(id);
  };
  const handleCancellation = (_event) => {
    setError(false);
    if (!cancellationReason) {
      setError(true);
      return;
    }
    let data = {
      id: previewId,
      event: 'cancel',
      status: 'CANCELLED',
      message: cancellationReason,
    };

    updateCashoutStatus(data);
  };
  const handleApproval = (_event, value) => {
    setError(false);
    let data = {
      id: previewId,
      event: 'confirm',
      status: 'COMPLETED',
      code: value.code,
    };
    updateCashoutStatus(data);
  };

  useEffect(() => {
    if (message || cashoutError) {
      setTimeout(() => {
        updateCashoutFailed('');
        setShowUpdateModal(false);
        setApproveModal(false);
        setCancelModal(false);
        fetchCashout();
      }, 3000);
    }
    if (otp) {
      setTimeout(() => {
        generateOTPFailed('');
      }, 2000);
    }
  }, [otp, message, cashoutError, updateCashoutFailed, fetchCashout]);

  useEffect(() => {
    updateCashoutFailed('');
  }, []);

  const deleteCashoutModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteCashout(deleteId);
    setDeleteId(null);
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Cashout' breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field='Finance' subModule='Cashout'>
            <>
              <CheckAction field='Finance' subModule='Cashout' iconType={'Create'}>
                <div className='mb-2 text-right'>
                  <Link to='/cashout_form'>
                    <Button color='success' size='sm'>
                      <i className='mdi mdi-plus-circle-outline font-size-18'></i>{' '}
                      Add Cashout
                    </Button>
                  </Link>
                </div>
              </CheckAction>
              {message && (
                <Alert color='success' className='text-center'>
                  {message}
                </Alert>
              )}
              {tableError && (
                <Alert color='danger' className='text-center'>
                  {tableError}
                </Alert>
              )}
              <Row>
                <Col>
                  <Row>
                    <MiniWidgets date={dateFilter} />
                  </Row>

                  <CashoutTable
                    cashoutData={cashout}
                    loading
                    deleteCashout={deleteCashoutModal}
                    fetchCashout={fetchCashout}
                    updateHandler={updateHandler}
                    statusUpdateHandler={ApprovalHandler}
                    statusIdHandler={statusIdHandler}
                    previewAction={previewHandler}
                    tableError={tableError}
                    message={message}
                    history={history}
                    fetchCashoutCSV={fetchCashoutCSV}
                    fetchCashoutCSVError={fetchCashoutCSVError}
                    csvData={csvData}
                    csv_loading={csv_loading}
                    CheckAction={CheckAction}
                    ReadCheck={ReadCheck}
                    accessControl={accessControl}
                  />
                </Col>
              </Row>
              <Modal
                isOpen={showCancelModal}
                toggle={() => setCancelModal(!showCancelModal)}
              >
                <ModalHeader toggle={() => setCancelModal(false)}>
                  Reject Cash Out
                </ModalHeader>
                <ModalBody>
                  {message && (
                    <Alert color='success' className='text-center'>
                      {message}
                    </Alert>
                  )}
                  {Error && (
                    <Alert className='text-center' color='danger'>
                      Please kindly state the reason for rejection
                    </Alert>
                  )}
                  <div>
                    <AvForm
                      className='form-horizontal'
                      onValidSubmit={handleCancellation}
                    >
                      <Row>
                        <Col md='9'>
                          <FormGroup className='mb-4'>
                            <Label htmlFor='cancellationReason'>
                              Reason For Rejection
                            </Label>
                            <textarea
                              className='form-control'
                              onChange={(e) => setReasonForm(e.target.value)}
                              value={cancellationReason}
                              id='cancellationReason'
                              rows='3'
                            ></textarea>
                          </FormGroup>
                        </Col>
                      </Row>

                      <button className='btn btn-success mr-2' type='submit'>
                        {loading ? 'Submitting ...' : 'Submit'}
                      </button>
                      <Link to='#' onClick={() => setCancelModal(false)}>
                        <button className='btn btn-secondary'>Close</button>
                      </Link>
                    </AvForm>
                  </div>
                </ModalBody>
              </Modal>

              {/* Approve Cashout OTP */}
              <Modal
                isOpen={showApproveModal}
                toggle={() => setApproveModal(!showApproveModal)}
              >
                <ModalHeader toggle={() => setApproveModal(false)}>
                  Approve Cashout
                </ModalHeader>
                <ModalBody>
                  {message && (
                    <Alert color='success' className='text-center'>
                      {message}
                    </Alert>
                  )}
                  {Error && (
                    <Alert className='text-center' color='danger'>
                      Please kindly input the OTP sent to your email
                    </Alert>
                  )}
                  {cashoutError && (
                    <Alert color='danger' className='text-center'>
                      {cashoutError}
                    </Alert>
                  )}
                  {otpError && (
                    <Alert color='danger' className='text-center'>
                      {otpError}
                    </Alert>
                  )}
                  {otpMessage && (
                    <Alert color='secondary' className='text-center'>
                      {otpMessage}
                    </Alert>
                  )}
                  <div>
                    <AvForm
                      className='form-horizontal'
                      onValidSubmit={handleApproval}
                    >
                      <Row>
                        <div className='d-flex align-items-center w-100 mb-2'>
                          <Col md={9} className='pr-0'>
                            <FormGroup className=' mb-4'>
                              <Label htmlFor='code'>OTP</Label>

                              <AvField
                                name='code'
                                type='number'
                                className='form-control bg-light'
                                required
                                errorMessage='please enter OTP sent to you'
                                placeholder='Enter otp'
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4} className='pl-1 mt-1'>
                            <Button
                              color='primary'
                              onClick={handleGenerateOTP}
                              disabled={disableButton}
                            >
                              {loadingOTP ? 'Sending' : 'Generate OTP'}
                            </Button>
                          </Col>
                        </div>
                        {otp && (
                          <p
                            className='font-size-12 text-primary'
                            style={{
                              position: 'absolute',
                              left: '3%',
                              bottom: '30%',
                            }}
                          >
                            OTP sent at: {otp?.time?.split(',')?.pop()}
                          </p>
                        )}
                      </Row>

                      <button className='btn btn-success mr-2' type='submit'>
                        {loading ? 'Submitting ...' : 'Submit'}
                      </button>
                      <Link to='#' onClick={() => setApproveModal(false)}>
                        <button className='btn btn-secondary'>Close</button>
                      </Link>
                    </AvForm>
                  </div>
                </ModalBody>
              </Modal>

              {/* Cashout Preview Modal */}
              <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
                <ModalHeader toggle={() => setShowModal(false)}>
                  Preview Cashout
                </ModalHeader>
                <ModalBody>
                  <PreviewCashout previewId={previewId} />
                </ModalBody>
              </Modal>

              {/* Cashout Preview Modal */}
              <Modal
                isOpen={showUpdateModal}
                toggle={() => setShowUpdateModal(!showUpdateModal)}
              >
                <ModalHeader toggle={() => setShowUpdateModal(false)}>
                  Update Cashout
                </ModalHeader>
                <ModalBody>
                  <UpdateModal data={updateData} />
                </ModalBody>
              </Modal>

              {/* Delete Confirmation modal */}
              <Modal
                isOpen={showDeleteModal}
                toggle={() => setShowDeleteModal(!showDeleteModal)}
              >
                <ModalBody>
                  <h4 className='card-title mb-4 text-center'>
                    {' '}
                    Are you sure you want to delete this cashout details
                  </h4>
                  <button
                    onClick={() => deleteHandler()}
                    className='btn btn-outline-danger btn-sm w-100'
                  >
                    Click to Delete
                  </button>
                </ModalBody>
              </Modal>
            </>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    csvData,
    csv_loading,
    cashout,
    loading,
    tableError,
    cashoutError,
    message,
  } = state.Cashout;
  const { user, otp, loadingOTP, otpError, otpMessage, accessControl } =
    state.Account;
  return {
    csvData,
    csv_loading,
    user,
    accessControl,
    otp,
    loadingOTP,
    otpError,
    otpMessage,
    cashout,
    loading,
    tableError,
    cashoutError,
    message,
  };
};

export default connect(mapStateToProps, {
  fetchCashout,
  deleteCashout,
  updateCashout,
  updateCashoutStatus,
  updateCashoutFailed,
  generateOTP,
  generateOTPFailed,
  fetchCashoutCSV,
  fetchCashoutCSVError,
})(Cashout);
