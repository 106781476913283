import React from 'react';
import { Card, Row, Col, FormGroup, Label } from 'reactstrap';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import Button from 'reactstrap/lib/Button';

const Firebase = () => {
  return (
    <div>
      <div>
        <div>
          <AvForm className='form-horizontal' model={null}>
            <Row>
              <Col>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>Firebase Database Url</Label>
                  <AvField
                    name='url'
                    type='text'
                    className='form-control bg-light'
                    id='url'
                    placeholder='Paste Database Url ...'
                    required
                  />
                </FormGroup>

                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>Firebase Api Key </Label>
                  <AvField
                    name='apiKey'
                    type='text'
                    className='form-control bg-light'
                    id='apiKey'
                    placeholder='Paste API Key ...'
                    required
                  />
                </FormGroup>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>Firebase Auth Domain</Label>
                  <AvField
                    name='commission'
                    type='text'
                    className='form-control bg-light'
                    id='commission'
                    placeholder='Enter Auth Domain ...'
                    required
                  />
                </FormGroup>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>Firebase Project Id </Label>
                  <AvField
                    name='firebaseId'
                    type='text'
                    className='form-control bg-light'
                    id='firebaseId'
                    placeholder='Enter Firebase Project Id ...'
                    required
                  />
                </FormGroup>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>Firebase Storage Bucket</Label>
                  <AvField
                    name='storageBucket'
                    type='text'
                    className='form-control bg-light'
                    id='storageBucket'
                    placeholder='Enter Firebase Storage Bucket ...'
                    required
                  />
                </FormGroup>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>Firebase Messaging Sender Id</Label>
                  <AvField
                    name='messageId'
                    type='text'
                    className='form-control bg-light'
                    id='messageId'
                    placeholder='Enter Firebase Messaging Sender Id ...'
                    required
                  />
                </FormGroup>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>Firebase App Id</Label>
                  <AvField
                    name='appId'
                    type='text'
                    className='form-control bg-light'
                    id='appId'
                    placeholder='Enter Firebase App Id ...'
                    required
                  />
                </FormGroup>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>Firebase Measurement Id</Label>
                  <AvField
                    name='measureId'
                    type='text'
                    className='form-control bg-light'
                    id='measureId'
                    placeholder='Enter Firebase Measurement Id ...'
                    required
                  />
                </FormGroup>
                <div className='text-right'>
                  <Button className='btn btn-success' size='lg'>
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </div>
      </div>
    </div>
  );
};

export default Firebase;
