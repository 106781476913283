import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
} from 'reactstrap';
import GreySummaryCard from '../../../../components/Common/Summary/index';

import MiniWidgets from './Components/MiniWidgets.js';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import BonnusLogTable from './table';
import PreviewBonusLog from './Components/previewBonusLog';

import {
  fetchBonusLog,
  fetchEachBonusLog,
  fetchBonusLogSummary,
  fetchBonusLogCSV,
  fetchBonusLogCSVError,
} from '../../../../store/actions';

const BonusLog = ({
  bonusLog,
  fetchBonusLog,
  fetchEachBonusLog,
  fetchBonusLogSummary,
  tableError,
  history,
  loading,
  fetchBonusLogCSV,
  fetchBonusLogCSVError,
  logCSVData,
  csv_loading,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [dateFilter, setDate] = useState({});
  const breadcrumbItems = [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Bonus Log', link: '#' },
  ];

  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };
  const statusIdHandler = (id, e) => {
    setShowStatusModal(true);
    setPreviewId(id);
    setStatus(e);
  };

  const fetchEachBonusLogModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const deleteHandler = () => {
    setShowDeleteModal(false);
    fetchEachBonusLog(deleteId);
    setDeleteId(null);
  };
  const date = (e) => {
    setDate(e);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Bonus Log" breadcrumbItems={breadcrumbItems} />

          <GreySummaryCard field="Bonus" subModule="Bonus Logs">
            <Row>
              <Col>
                <Row
                  className="styled-scroll mb-5 col-sm-12"
                  style={{ flexWrap: 'nowrap', overflowX: 'auto' }}
                >
                  <MiniWidgets date={dateFilter} />
                </Row>
                <BonnusLogTable
                  bonusLogData={bonusLog}
                  tableError={tableError}
                  dateFilter={date}
                  loading
                  history={history}
                  // fetchEachBonusLog={fetchEachBonusLogModal}
                  fetchBonusLog={fetchBonusLog}
                  statusIdHandler={statusIdHandler}
                  previewAction={previewHandler}
                  fetchBonusLogCSV={fetchBonusLogCSV}
                  fetchBonusLogCSVError={fetchBonusLogCSVError}
                  csvData={logCSVData}
                  csv_loading={csv_loading}
                />
              </Col>
            </Row>
          </GreySummaryCard>
        </Container>
        <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
          <ModalHeader toggle={() => setShowModal(false)}>
            Bonus Preview
          </ModalHeader>
          <ModalBody>
            <PreviewBonusLog previewId={previewId} />
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { logCSVData, csv_loading, bonusLog, loading, tableError } =
    state.Bonus;
  return { logCSVData, csv_loading, bonusLog, loading, tableError };
};

export default connect(mapStateToProps, {
  fetchBonusLog,
  fetchEachBonusLog,
  fetchBonusLogSummary,
  fetchBonusLogCSV,
  fetchBonusLogCSVError,
})(BonusLog);
