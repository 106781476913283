import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_LOYALTY,
  FETCH_MY_LOYALTY,
  FETCH_LOYALTY_SUMMARY,
  UPDATE_USER_POINT,
} from './actionTypes';
import {
  fetchLoyaltySuccessful,
  fetchLoyaltyError,
  fetchMyLoyaltySuccessful,
  fetchMyLoyaltyError,
  updateUserPointSuccessful,
  updateUserPointFailed,
  fetchLoyaltySummaryFailed,
  fetchLoyaltySummarySuccessfully,
} from './actions';

import {
  GetLoyaltyService,
  GetMyLoyaltyService,
  UpdateUserPointService,
  fetchLoyaltySummaryService,
} from '../../services/loyaltyService';

function* fetchLoyaltyHandler({ payload }) {
  try {
    const response = yield call(GetLoyaltyService, payload);
    yield put(fetchLoyaltySuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchLoyaltyError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchLoyaltyError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchLoyaltyError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchMyLoyaltyHandler({ payload }) {
  try {
    const response = yield call(GetMyLoyaltyService, payload);
    yield put(fetchMyLoyaltySuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchMyLoyaltyError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchMyLoyaltyError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchMyLoyaltyError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* updateUserPointHandler({ payload }) {
  try {
    const response = yield call(UpdateUserPointService, payload);
    yield put(updateUserPointSuccessful(response.data.result));
  } catch (error) {
    yield put(updateUserPointFailed(error?.response?.data?.message));
  }
}
function* fetchLoyaltySummaryHandler({ payload }) {
  try {
    const response = yield call(fetchLoyaltySummaryService, payload);
    yield put(fetchLoyaltySummarySuccessfully(response.data));
  } catch (error) {
    yield put(fetchLoyaltySummaryFailed(error.response.data));
  }
}

export function* watchFetchLoyaltySummary() {
  yield takeEvery(FETCH_LOYALTY_SUMMARY, fetchLoyaltySummaryHandler);
}

export function* watchFetchLoyalty() {
  yield takeEvery(FETCH_LOYALTY, fetchLoyaltyHandler);
}

export function* watchFetchMyLoyalty() {
  yield takeEvery(FETCH_MY_LOYALTY, fetchMyLoyaltyHandler);
}

export function* watchUpdateUserPoint() {
  yield takeEvery(UPDATE_USER_POINT, updateUserPointHandler);
}

function* loyaltySaga() {
  yield all([
    fork(watchFetchLoyalty),
    fork(watchFetchLoyaltySummary),
    fork(watchFetchMyLoyalty),
    fork(watchUpdateUserPoint),
  ]);
}

export default loyaltySaga;
