import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Container } from 'reactstrap';
import Moment from 'moment';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LogsTable from './LogsTable';
// import Breadcrumbs from '../Breadcrumb';
import {
  fetchDriverOnlineLogs,
  fetchDriverHoursLogs,
} from '../../../store/actions';

const Logs = ({
  user,
  fetchDriverOnlineLogs,
  fetchDriverHoursLogs,
  driverHoursLogs,
  driverHoursLogsError,
  driverOnlineLogs,
  driverOnlineLogsError,
  driverLogs,
  loading,
  history,
}) => {
  const [date, setDate] = useState({
    start: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });
  const [status, setStatus] = useState('');
  const [menu, setMenu] = useState(null);
  useEffect(() => {
    if (user) {
      fetchDriverOnlineLogs({
        driverId: user?.id,
        start: date.start,
        end: date.end,
      });
      fetchDriverHoursLogs({
        driverId: user?.id,
        start: date.start,
        end: date.end,
      });
    }
  }, []);

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    setStatus(values.Status);
    setMenu(false);
    let query = {
      start: values.startDate,
      end: values.endDate,
      status: values.Status,
      driverId: user?.id,
    };
    fetchDriverOnlineLogs(query);
    fetchDriverHoursLogs(query);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    });
    setStatus('');
    let query = {
      start: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
      driverId: user?.id,
    };
    fetchDriverOnlineLogs(query);
    fetchDriverHoursLogs(query);
  };
  return (
    <div>
      <Row>
        <Col sm={4} className='border rounded p-2'>
          <div className='d-flex justify-content-between mt-5 pt-3'>
            <h4 className='text-capitalize card-title mb-4'> Driver Hours </h4>
            <span className='font-size-12'>
              Current Status:{' '}
              <h6
                className={`badge badge-soft-${
                  driverLogs?.result[0]?.status === 'OFFLINE'
                    ? 'danger'
                    : driverLogs?.result[0]?.status === 'BUSY'
                    ? 'warning'
                    : driverLogs?.result[0]?.status === 'AVAILABLE'
                    ? 'success'
                    : 'secondary'
                } font-size-10`}
              >
                {driverLogs?.result[0]?.status
                  ? driverLogs?.result[0]?.status
                  : 'No data'}
              </h6>
            </span>
          </div>
          <Table hover>
            <tbody>
              <tr>
                <td className='text-muted text-left col-6'>Total Records</td>
                <td className='font-weight-bold text-left col-6 text-capitalize'>
                  {driverHoursLogs?.result?.records}
                </td>
              </tr>
              <tr>
                <td className='text-muted text-left col-6'>Minutes</td>
                <td className='font-weight-bold text-left col-6 text-capitalize'>
                  {driverHoursLogs?.result?.minutes}
                </td>
              </tr>
              <tr>
                <td className='text-muted text-left col-6'>Hours</td>
                <td className='font-weight-bold text-left col-6'>
                  {driverHoursLogs?.result?.hours}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col sm={8}>
          <div className=''>
            <Container fluid>
              <Row>
                <Col>
                  <LogsTable
                    logs={driverOnlineLogs}
                    loading={loading}
                    fetchDriverOnlineLogs={fetchDriverOnlineLogs}
                    message={driverOnlineLogsError}
                    handleDateFilter={handleDateFilter}
                    resetHandler={resetHandler}
                    date={date}
                    status={status}
                    toggle={menu}
                    driverId={user?.id}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    loading,
    driverLogs,
    driverHoursLogs,
    driverHoursLogsError,
    driverOnlineLogs,
    driverOnlineLogsError,
  } = state.Users;
  return {
    loading,
    driverLogs,
    driverHoursLogs,
    driverHoursLogsError,
    driverOnlineLogs,
    driverOnlineLogsError,
  };
};

export default connect(mapStateToProps, {
  fetchDriverOnlineLogs,
  fetchDriverHoursLogs,
})(withRouter(Logs));
