/* eslint-disable eqeqeq */
import React, { useEffect } from 'react'
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Alert, Row, Col, Table } from 'reactstrap'
import Loader from '../../../../../components/Common/Loading/index.js'
import { fetchEachBonusLog } from '../../../../../store/actions'
import Moment from 'moment'

const PreviewBonusLog = ({
  bonusLogPreview,
  previewId,
  loading,
  bonusError,
  fetchEachBonusLog,
}) => {
  console.log(previewId)
  useEffect(() => {
    fetchEachBonusLog(previewId)
  }, [fetchEachBonusLog, previewId])

  return (
    <React.Fragment>
      <div className="mt-1">
        {bonusError && !loading && (
          <Alert color="danger" className="text-center">
            {bonusError}
          </Alert>
        )}
        {!bonusLogPreview && loading ? (
          <Loader />
        ) : (
          <Row>
            <Col sm={12} className="text-center">
              <div className="d-flex justify-content-between pb-2"></div>
              {bonusLogPreview?.result?.user && (
                <div
                  className=" my-0 mx-auto rounded w-auto p-2 shadow"
                  style={{ maxWidth: 'fit-content', background: '#F1F8E9' }}
                >
                  <img
                    src={bonusLogPreview?.result?.user.picture}
                    alt="tier-img"
                    className="avatar-sm"
                  />
                </div>
              )}
              <div>
                <h4 className="card-title">
                  {`${bonusLogPreview?.result?.user?.firstName} - ${bonusLogPreview?.result?.user?.lastName}`}
                </h4>
                <span className="badge badge-secondary">
                  {bonusLogPreview?.result?.user?.userType}
                </span>
              </div>
            </Col>
            <Col sm={12}>
              <div>
                <Table hover>
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Name</td>
                      <td className="text-uppercase">
                        {bonusLogPreview?.result?.name}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Source</td>
                      <td>{bonusLogPreview?.result?.source}</td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Value</td>
                      <td>₦{bonusLogPreview?.result?.value}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Created Date</td>
                      <td>
                        {' '}
                        {Moment(bonusLogPreview?.result?.createdAt).format(
                          'lll',
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="">
                <h4 className="card-title text-center"> Requirements </h4>
                {/* <Table hover>
                  {bonusLogPreview?.userType === 'RIDER' ? (
                    <tbody>
                      <tr>
                        <td className="font-weight-bold">Minimum Points</td>
                        <td className="text-uppercase text-left">
                          {bonusLogPreview?.requirement?.minimumPoint > 999 &&
                          bonusLogPreview?.requirement?.minimumPoint < 999999
                            ? `${
                                bonusLogPreview?.requirement?.minimumPoint /
                                1000
                              } K`
                            : bonusLogPreview?.requirement?.minimumPoint >
                              999999
                            ? `${
                                bonusLogPreview?.requirement?.minimumPoint /
                                1000000
                              } M`
                            : bonusLogPreview?.requirement?.minimumPoint}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Maximum Points</td>
                        <td className="text-uppercase text-left">
                          {bonusLogPreview?.requirement?.maximumPoint > 999 &&
                          bonusLogPreview?.requirement?.maximumPoint < 999999
                            ? `${(
                                bonusLogPreview?.requirement?.maximumPoint /
                                1000
                              ).toFixed(1)} K`
                            : bonusLogPreview?.requirement?.maximumPoint >
                              999999
                            ? `${(
                                bonusLogPreview?.requirement?.maximumPoint /
                                1000000
                              ).toFixed(1)} M`
                            : bonusLogPreview?.requirement?.maximumPoint}
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="font-weight-bold">Trips</td>
                        <td>{bonusLogPreview?.requirement?.trip}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Rating</td>
                        <td>{bonusLogPreview?.requirement?.rating}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Vehicle Year</td>
                        <td>{bonusLogPreview?.requirement?.vehicleYear}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Cancellation</td>
                        <td>{bonusLogPreview?.requirement?.cancellation}</td>
                      </tr>
                    </tbody>
                  )}</Table> */}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { bonusLogPreview, loading, bonusError } = state.Bonus
  return { bonusLogPreview, loading, bonusError }
}

export default connect(mapStateToProps, { fetchEachBonusLog })(PreviewBonusLog)
