import React, { useState, useEffect } from 'react';
import AlartError from '../../../components/Common/AlertCard';
import {
  Row,
  Col,
  Button,
  Alert,
  Card,
  CardBody,
  Container,
  Label,
  FormGroup,
} from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useLocation } from 'react-router-dom'

// action
import {
  createUser,
  createUserFailed,
  fetchRoles,
  generateOTP,
  generateOTPFailed,
  fetchLicenseKeys
} from '../../../store/actions';

// Redux
import { connect } from 'react-redux';

const REGEXP = /[\+]\d{3}\d{2}\d{4}\d{4}/;
const CreateUser = ({
  loading,
  createUser,
  createUserFailed,
  userError,
  message,
  history,
  fetchRoles,
  roles,
  generateOTP,
  generateOTPFailed,
  otp,
  loadingOTP,
  otpError,
  otpMessage,
  user,
  fetchLicenseKeys,
  licenseKeys
}) => {
  const [errorMessage, setError] = useState('');
  const [disableButton, setDisable] = useState(false);
  const [timer, setTimer] = useState(0);

  const search = useLocation().search
  const usertype = new URLSearchParams(search).get('userType')

  const handleSubmit = async (event, values) => {
    setError('');
    if (values.password !== values.confirmPassword) {
      setError('Password do not match');
    }
    if (values.role === 'loading') {
      setError(`Please select a role`);
      return;
    }
    if (values.userType === 'loading') {
      setError(`Please select the user type`);
      return;
    }
    if (values.code === '') {
      setError(`Please generate and enter OTP sent to you`);
      return;
    }
    if (values.license === 'loading') {
      setError(`Please select a License Name`);
      return;
    }

    const userData = {
      ...values,
    };


    if (usertype) {
      createUser({ ...userData, userType: usertype, roles: [userData.roles] });
      // console.log({ ...userData, userType: usertype, roles: [userData.roles] });
      return
    }
    createUser({ ...userData, roles: [userData.roles] });
    // console.log({ ...userData, roles: [userData.roles] });
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        createUserFailed('');
        history.goBack();
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    fetchRoles();
    fetchLicenseKeys()
  }, []);


  useEffect(() => {
    createUserFailed('');
  }, []);

  const handleGenerateOTP = () => {
    setDisable(true);
    generateOTP({ email: user.email, type: 'CREATE_USER' });
    setTimeout(() => {
      setDisable(false);
    }, 30000);
  };
  let isSuperAdmin = user?.userRoles?.find(
    (usr) => usr.includes('SUPERADMIN')
  )

  return (
    <React.Fragment>
      <div className='page-content'>
        <Card>
          <CardBody>
            {message && (
              <Alert color='success' className='text-center'>
                {message}
              </Alert>
            )}
            {userError && (
              <Alert color='danger' className='text-center'>
                {userError}
              </Alert>
            )}
            {errorMessage && (
              <Alert color='danger' className='text-center'>
                {errorMessage}
              </Alert>
            )}

            <div className='float-right'>
              <Link to='#' onClick={() => history.goBack()}>
                <i className='fas fa-arrow-left mr-3' />
                Back
              </Link>
            </div>
            <div className='mb-3'>
              <h6> Create User </h6>
            </div>
            <Col md={12}>
              <AvForm onValidSubmit={handleSubmit} className='form-horizontal'>
                <Row>
                  <Col md={6}>
                    <FormGroup className=' mb-4'>
                      <Label htmlFor='firstName'>First Name</Label>
                      <AvField
                        name='firstName'
                        value={''}
                        type='text'
                        className='form-control'
                        id='firstName'
                        placeholder='Enter first Name'
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className=' mb-4'>
                      <Label htmlFor='lastName'>Last Name</Label>
                      <AvField
                        name='lastName'
                        value={''}
                        type='text'
                        className='form-control'
                        id='lastName'
                        placeholder='Enter Last Name'
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup className=' mb-4'>
                      <Label htmlFor='useremail'>Email</Label>
                      <AvField
                        name='email'
                        value={''}
                        validate={{
                          email: true,
                          required: true,
                        }}
                        type='email'
                        className='form-control'
                        id='useremail'
                        placeholder='Enter email'
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup className=' mb-4'>
                      <Label htmlFor='phone'>Phone Number</Label>
                      <AvField
                        name='phone'
                        value={''}
                        type='text'
                        errorMessage='Please enter a valid phone number (country code + 10 digits)'
                        validate={{
                          tel: { pattern: REGEXP },
                        }}
                        className='form-control'
                        id='phone'
                        placeholder="Enter User's Phone Number ..."
                        required
                        helpMessage='Phone number should start with the country code "+2348012345678"'
                      />
                    </FormGroup>
                  </Col>
                  {!usertype &&
                    <Col md={6}>
                      <FormGroup className=' mb-4'>
                        <Label htmlFor='UserType'>User Type</Label>

                        <AvField
                          className='form-control bg-light'
                          type='select'
                          name='userType'
                          value={usertype ? usertype : 'loading'}
                          required
                        >
                          <option value={'loading'}>Select the User Type</option>
                          <option>ADMIN</option>
                          <option>RIDER</option>
                          <option>DRIVER</option>
                          <option>INSPECTOR</option>
                        </AvField>
                      </FormGroup>
                    </Col>
                  }
                  <Col md={6}>
                    <FormGroup className='mb-4'>
                      <Label htmlFor='roles'> Roles</Label>
                      <AvField
                        type='select'
                        name='roles'
                        value={'loading'}
                        required
                      >
                        {!roles?.result ? (
                          <option value={'loading'} default>
                            {' '}
                            Loading ...
                          </option>
                        ) : (
                          <>
                            <option value={'loading'}>Select the Role</option>

                            {roles?.result?.map((role) => (
                              <option key={role.id} value={role.id}>
                                {role.name}
                              </option>
                            ))}
                          </>
                        )}
                      </AvField>
                    </FormGroup>
                  </Col>
                  {isSuperAdmin &&
                    <Col md={6}>
                      <FormGroup className='mb-4'>
                        <Label htmlFor='license'> License Key</Label>
                        <AvField
                          type='select'
                          name='license'
                          value={'loading'}
                          required
                        >
                          {!licenseKeys?.result ? (
                            <option value={'loading'} default>
                              {' '}
                              Loading ...
                            </option>
                          ) : (
                            <>
                              <option value={'loading'}>Select the License Name</option>

                              {licenseKeys?.result?.map((licenseKey) => (
                                <option key={licenseKey.key} value={licenseKey.key}>
                                  {licenseKey.name}
                                </option>
                              ))}
                            </>
                          )}
                        </AvField>
                      </FormGroup>
                    </Col>
                  }
                  <Col md={6}>
                    <FormGroup className=' mb-4'>
                      <Label htmlFor='userpassword'>Password</Label>
                      <AvField
                        name='password'
                        value={''}
                        type='password'
                        className='form-control'
                        id='userpassword'
                        placeholder='Enter password'
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className=' mb-4'>
                      <Label htmlFor='confirmPassword'>Confirm Password</Label>
                      <AvField
                        name='confirmPassword'
                        errorMessage='password not the same'
                        validate={{
                          match: { value: 'password' },
                        }}
                        type='password'
                        className='form-control'
                        id='confirmPassword'
                        placeholder='Confirm password'
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup className=' mb-4'>
                      <Label htmlFor='dob'>Date Of Birth</Label>
                      <AvField
                        name='dob'
                        value={''}
                        type='date'
                        className='form-control'
                        id='dob'
                        placeholder='Enter dob'
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6} className='position-relative'>
                    <div className='d-flex align-items-center w-100 mb-2'>
                      <Col md={9} className='p-0'>
                        <FormGroup className=' mb-4'>
                          <Label htmlFor='code'>OTP</Label>

                          <AvField
                            name='code'
                            type='number'
                            className='form-control bg-light'
                            errorMessage='please enter OTP sent to you'
                            placeholder='Enter otp'
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4} className='pl-1 mt-1'>
                        <Button
                          color='info'
                          onClick={handleGenerateOTP}
                          style={{ cursor: `${disableButton ? 'not-allowed' : 'pointer'}` }}
                          disabled={disableButton}
                          className='font-size-10'
                        >
                          {loadingOTP ? 'Sending' : 'Generate OTP'}
                        </Button>
                      </Col>
                    </div>
                    {otp && (
                      <p
                        className='font-size-12 text-primary'
                        style={{
                          position: 'absolute',
                          left: '3%',
                          marginTop: '-30px',
                        }}
                      >
                        OTP sent at: {otp?.time?.split(',')?.pop()}
                      </p>
                    )}
                  </Col>
                </Row>

                <Button
                  color='primary'
                  className='w-md waves-effect waves-light'
                  type='submit'
                >
                  {loading ? 'Submitting ...' : 'Create'}
                </Button>
                {/* <Link to='#' onClick={() => history.goBack()}>
                    <button className='btn btn-secondary'>Cancel</button>
                  </Link> */}
              </AvForm>
            </Col>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { user, otp, loadingOTP, otpError, otpMessage } = state.Account;
  const { loading, userError, message } = state.Users;
  const { licenseKeys, licenseKeyError } =
    state.LicenseKey;
  const { roles } = state.Role;
  return {
    roles,
    user,
    loading,
    message,
    userError,
    otp,
    loadingOTP,
    otpError,
    otpMessage,
    licenseKeys,
    licenseKeyError
  };
};

export default connect(mapStateToProps, {
  createUser,
  createUserFailed,
  generateOTP,
  generateOTPFailed,
  fetchRoles,
  fetchLicenseKeys
})(withRouter(CreateUser));
