import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_CONTACTS,
  FETCH_SOS,
  FETCH_EACH_CONTACT,
  FETCH_EACH_SOS,
  CREATE_CONTACT,
  DELETE_CONTACT,
  UPDATE_CONTACT,
  UPDATE_SOS,
} from './actionTypes';
import {
  fetchContactsSuccessful,
  fetchContactsError,
  fetchSOSSuccessful,
  fetchSOSError,
  fetchEachContactSuccessful,
  fetchEachContactError,
  fetchEachSOSSuccessful,
  fetchEachSOSError,
  deleteContactSuccessful,
  deleteContactFailed,
  createContactSuccessful,
  createContactFailed,
  updateContactSuccessful,
  updateContactFailed,
  updateSOSSuccessful,
  updateSOSFailed,
} from './actions';

import {
  GetContactService,
  GetEachContactService,
  DeleteContactService,
  CreateContactService,
  UpdateContactService,
  GetSOSService,
  GetEachSOSService,
  UpdateSOSService,
} from '../../services/contactService';

function* fetchContactsHandler({ payload }) {
  try {
    const response = yield call(GetContactService, payload);
    yield put(fetchContactsSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchContactsError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchContactsError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchContactsError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchSOSHandler({ payload }) {
  try {
    const response = yield call(GetSOSService, payload);
    yield put(fetchSOSSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchSOSError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchSOSError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchSOSError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEachContactHandler({ payload }) {
  try {
    const response = yield call(GetEachContactService, payload);
    yield put(fetchEachContactSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachContactError(error?.response?.data.error?.message));
  }
}

function* fetchEachSOSHandler({ payload }) {
  try {
    const response = yield call(GetEachSOSService, payload);
    yield put(fetchEachSOSSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachSOSError(error?.response?.data.error?.message));
  }
}

function* deleteContactHandler({ payload }) {
  try {
    const response = yield call(DeleteContactService, payload);
    yield put(deleteContactSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteContactFailed(error?.response?.data?.message));
  }
}

function* createContactHandler({ payload }) {
  try {
    const response = yield call(CreateContactService, payload);
    yield put(createContactSuccessful(response.data.result));
  } catch (error) {
    yield put(createContactFailed(error?.response?.data?.message));
  }
}

function* updateContactHandler({ payload }) {
  try {
    const response = yield call(UpdateContactService, payload);
    yield put(updateContactSuccessful(response.data.result));
  } catch (error) {
    yield put(updateContactFailed(error?.response?.data?.message));
  }
}

function* updateSOSHandler({ payload }) {
  try {
    const response = yield call(UpdateSOSService, payload);
    yield put(updateSOSSuccessful(response.data.result));
  } catch (error) {
    yield put(updateSOSFailed(error?.response?.data?.message));
  }
}

// CONTACT SUMMARY

export function* watchFetchContact() {
  yield takeEvery(FETCH_CONTACTS, fetchContactsHandler);
}
export function* watchFetchSOS() {
  yield takeEvery(FETCH_SOS, fetchSOSHandler);
}
export function* watchFetchEachContact() {
  yield takeEvery(FETCH_EACH_CONTACT, fetchEachContactHandler);
}
export function* watchFetchEachSOS() {
  yield takeEvery(FETCH_EACH_SOS, fetchEachSOSHandler);
}
export function* watchDeleteContact() {
  yield takeEvery(DELETE_CONTACT, deleteContactHandler);
}
export function* watchCreateContact() {
  yield takeEvery(CREATE_CONTACT, createContactHandler);
}
export function* watchUpdateContact() {
  yield takeEvery(UPDATE_CONTACT, updateContactHandler);
}
export function* watchUpdateSOS() {
  yield takeEvery(UPDATE_SOS, updateSOSHandler);
}

function* contactSaga() {
  yield all([
    fork(watchFetchContact),
    fork(watchFetchEachContact),
    fork(watchDeleteContact),
    fork(watchCreateContact),
    fork(watchUpdateContact),
    fork(watchFetchSOS),
    fork(watchFetchEachSOS),
    fork(watchUpdateSOS),
  ]);
}

export default contactSaga;
