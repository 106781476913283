import React, { useEffect } from 'react'
import {
  Row,
  Col,
  Alert,
  Button,
  Container,
  FormGroup,
  Label,
  Card,
  CardBody,
} from 'reactstrap'
import AlartError from '../../../components/Common/AlertCard'

// Redux
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import QueryString from 'query-string'

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'

// action
import {
  createNewPassword,
  createNewPasswordError,
} from '../../../store/actions'

// import images
import logosmdark from '../../../assets/images/nrydeLogo.png'

const ForgetPasswordPage = ({
  message,
  forgetError,
  loading,
  createNewPassword,
  createNewPasswordError,
  history,
  location,
}) => {
  function handleValidSubmit(event, values) {
    const search = location?.search
    const { resetHash } = QueryString.parse(search)
    let resetForm = { ...values }
    if (resetHash) {
      resetForm['resetHash'] = resetHash
    }

    createNewPassword(resetForm, history)
  }

  useEffect(() => {
    createNewPasswordError('')
    if (message) {
      setTimeout(() => {
        history.push('/login')
      }, [3000])
    }
  }, [message])

  useEffect(() => {
    createNewPasswordError('')
  }, [])

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/">
          <i className="mdi mdi-home-variant h2 text-white"></i>
        </Link>
      </div>
      <div>
        <Container fluid className="p-0">
          <Row className="no-gutters">
            <Col lg={12}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={4}>
                      <Card className="shadow-lg">
                        <CardBody>
                          <div>
                            <div className="text-center">
                              <div>
                                <Link to="/" className="logo">
                                  <img
                                    src={logosmdark}
                                    height="50"
                                    alt="logo"
                                  />
                                </Link>
                              </div>

                              <h4 className="font-size-18 mt-4">
                                Create New Password
                              </h4>
                              <p className="text-muted">
                                Reset your password to nRyde.
                              </p>
                            </div>

                            <div className="p-2 mt-5">
                              {forgetError && forgetError ? (
                                <AlartError error={forgetError.error} />
                              ) : null}
                              {message ? (
                                <Alert color="success" className="mb-4">
                                  {message}
                                </Alert>
                              ) : null}
                              <AvForm
                                className="form-horizontal"
                                onValidSubmit={handleValidSubmit}
                              >
                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-mail-line auti-custom-input-icon"></i>
                                  <Label htmlFor="useremail">Email</Label>
                                  <AvField
                                    name="email"
                                    // value={this.state.username}
                                    type="email"
                                    validate={{ email: true, required: true }}
                                    className="form-control"
                                    id="useremail"
                                    placeholder="Enter email"
                                  />
                                </FormGroup>
                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="userpassword">Password</Label>
                                  <AvField
                                    name="password"
                                    // value={this.state.password}
                                    type="password"
                                    className="form-control"
                                    id="userpassword"
                                    placeholder="Enter New Password"
                                  />
                                </FormGroup>
                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                  <Label htmlFor="confirmPassword">
                                    Confirm Password
                                  </Label>
                                  <AvField
                                    name="confirmPassword"
                                    errorMessage="password not the same"
                                    // value={this.state.password}
                                    validate={{
                                      match: { value: 'password' },
                                    }}
                                    type="password"
                                    className="form-control"
                                    id="confirmPassword"
                                    placeholder="New password"
                                    required
                                  />
                                </FormGroup>

                                <div className="mt-4 text-center">
                                  <Button
                                    color="primary"
                                    className="w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    {loading ? 'Loading...' : 'Reset'}
                                  </Button>
                                </div>
                              </AvForm>
                            </div>

                            <div className="mt-5 text-center">
                              <p>
                                Don't have an account ?{' '}
                                <Link
                                  to="/login"
                                  className="font-weight-medium text-primary"
                                >
                                  {' '}
                                  Log in{' '}
                                </Link>{' '}
                              </p>
                              <p>© 2022 nRyde.</p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            {/* <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { message, forgetError, loading } = state.Account
  return { message, forgetError, loading }
}

export default withRouter(
  connect(mapStateToProps, { createNewPassword, createNewPasswordError })(
    ForgetPasswordPage,
  ),
)
