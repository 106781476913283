export const ADD_PACKAGE_TO_VEHICLE = 'ADD_PACKAGE_TO_VEHICLE';
export const ADD_PACKAGE_TO_VEHICLE_SUCCESSFUL =
  'ADD_PACKAGE_TO_VEHICLE_SUCCESSFULLY';
export const ADD_PACKAGE_TO_VEHICLE_FAILED = 'ADD_PACKAGE_TO_VEHICLE';

export const DELETE_PACKAGE_FROM_VEHICLE = 'DELETE_PACKAGE_FROM_VEHICLE';
export const DELETE_PACKAGE_FROM_VEHICLE_SUCCESSFUL =
  'DELETE_PACKAGE_FROM_VEHICLE_SUCCESSFULLY';
export const DELETE_PACKAGE_FROM_VEHICLE_FAILED = 'DELETE_PACKAGE_FROM_VEHICLE';

export const FETCH_PACKAGE = 'FETCH_PACKAGE';
export const FETCH_PACKAGE_SUCCESSFUL = 'FETCH_PACKAGE_SUCCESSFULLY';
export const FETCH_PACKAGE_FAILED = 'FAILED';

export const FETCH_PACKAGE_VEHICLES = 'FETCH_PACKAGE_VEHICLES';
export const FETCH_PACKAGE_VEHICLES_SUCCESSFUL =
  'FETCH_PACKAGE_VEHICLES_SUCCESSFULLY';
export const FETCH_PACKAGE_VEHICLES_FAILED = 'FAILED';

// Package Summary
export const FETCH_PACKAGE_SUMMARY = 'FETCH_PACKAGE_SUMMARY';
export const FETCH_PACKAGE_SUMMARY_FAILED = 'FETCH_PACKAGE_SUMMARY_FAILED';
export const FETCH_PACKAGE_SUMMARY_SUCCESSFULLY =
  'FETCH_PACKAGE_SUMMARY_SUCCESSFULLY';

export const UPDATE_PACKAGE = 'UPDATE_PACKAGE';
export const UPDATE_PACKAGE_SUCCESSFUL = 'UPDATE_PACKAGE_SUCCESSFULLY';
export const UPDATE_PACKAGE_FAILED = 'FAILED';

export const CREATE_PACKAGE = 'CREATE_PACKAGE';
export const CREATE_PACKAGE_SUCCESSFUL = 'CREATE_PACKAGE_SUCCESSFULLY';
export const CREATE_PACKAGE_FAILED = 'CREATE_PACKAGE_FAILED';

export const FETCH_EACH_PACKAGE = 'FETCH_EACH_PACKAGE';
export const FETCH_EACH_PACKAGE_SUCCESSFUL = 'FETCH_EACH_PACKAGE_SUCCESSFULLY';
export const FETCH_EACH_PACKAGE_FAILED = 'FETCH_EACH_PACKAGE_FAILED';

export const DELETE_PACKAGE = 'DELETE_PACKAGE';
export const DELETE_PACKAGE_SUCCESSFUL = 'DELETE_PACKAGE_SUCCESSFULLY';
export const DELETE_PACKAGE_FAILED = 'DELETE_PACKAGE_FAILED';

export const REMOVE_VEHICLE_FROM_PACKAGE = 'REMOVE_VEHICLE_FROM_PACKAGE';
export const REMOVE_VEHICLE_FROM_PACKAGE_SUCCESSFUL =
  'REMOVE_VEHICLE_FROM_PACKAGE_SUCCESSFULLY';
export const REMOVE_VEHICLE_FROM_PACKAGE_FAILED =
  'REMOVE_VEHICLE_FROM_PACKAGE_FAILED';
