import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Alert,
  FormGroup,
  Col,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import ReactPaginate from 'react-paginate';
import Loader from '../../../../components/Common/Loading/index.js';
import FilterDropDown from '../../../../components/Common/FilterDropDown';
import SimpleBar from 'simplebar-react';
import Moment from 'moment';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../../utils/general';
import queryString from 'query-string';

let skip = 0;
let dataLimit = 10;

const LoyaltyTierTable = ({
  loyaltyTierData,
  tableError,
  previewAction,
  fetchLoyaltyTier,
  deleteLoyaltyTier,
  history,
  message,
  isAuthorize,
  CheckAction,
  ReadCheck,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [skipPage, setSkipPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [status, setStatus] = useState('');
  const [type, setUser] = useState('');
  const [search, setSearch] = useState('');
  const [menu, setMenu] = useState(false);
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(loyaltyTierData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = { skip: pageNumber, take: dataLimit, status, search };
    fetchLoyaltyTier(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take, status, search };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchLoyaltyTier(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      skip,
      status,
      userType: type,
    };
    fetchLoyaltyTier(queryParams);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          status,
          userType: type,
        };
        fetchLoyaltyTier(queryParams);
      }, 2000);
    }
  }, [fetchLoyaltyTier, message]);

  useEffect(() => {
    if (loyaltyTierData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(loyaltyTierData?.total / take));
    }
  }, [loyaltyTierData]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchLoyaltyTier(parsedUrlParams);
  }, [fetchLoyaltyTier]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      status,
      take,
      userType: type,
      skip: pageNumber,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        take,
        skip,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.status) {
      setStatus(parsedUrlParams?.status);
    }
    if (parsedUrlParams?.type) {
      setUser(parsedUrlParams?.type);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    setMenu(false);
    setStatus(values.vehicleStatus);
    setUser(values.type);
    let queryParams = {
      search,
      take,
      skip,
      status: values.vehicleStatus,
      // start: values.startDate,
      // end: values.endDate,
      userType: values.type,
    };
    fetchLoyaltyTier(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    });
    setUser('');
    let queryParams = {
      search,
      take,
      skip,
      status: '',
      userType: '',
      // start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      // end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    };
    fetchLoyaltyTier(queryParams);
  };

  let accessChecking = ReadCheck(accessControl, `Loyalty`, 'Loyalty Tier');
  const tableDataHandler = () => {
    const rowData = loyaltyTierData?.result?.map((data) => {
      return {
        id: data.id,
        name: (

          <>
            {accessChecking ?

              <Link
                to='#'
                onClick={() => previewAction(data.id)}
                className='text-dark font-weight-bold'
              >
                <span className='text-capitalize'>
                  {data.name}
                  <span className='d-block'>
                    <span
                      className='badge text-white rounded'
                      style={{
                        background: `${!data.requirement?.rating ? '#f18357' : '#00a859'
                          }`,
                      }}
                    >
                      {' '}
                      {data.requirement?.rating ? 'DRIVER' : 'RIDER'}{' '}
                    </span>
                  </span>
                </span>
              </Link>

              :
              <div className='text-dark font-weight-bold'>
                <span className='text-capitalize'>
                  {data.name}
                  <span className='d-block'>
                    <span
                      className='badge text-white rounded'
                      style={{
                        background: `${!data.requirement?.rating ? '#f18357' : '#00a859'
                          }`,
                      }}
                    >
                      {' '}
                      {data.requirement?.rating ? 'DRIVER' : 'RIDER'}{' '}
                    </span>
                  </span>
                </span>
              </div>
            }
          </>
        ),
        image: (
          <Row className='align-items-center justify-content-center'>
            {data?.image ? (
              <Col className='mb-2 px-0 py-0 text-center mr-3' sm={3}>
                <img
                  className='rounded avatar-sm border shadow-xs p-2'
                  alt='Tier-Img'
                  src={data?.image}
                />
              </Col>
            ) : null}
            <Col sm={9}>
              <span className='mt-1 mb-1'>
                <h4 className='font-weight-bold font-size-12 text-center mb-0'>
                  <span>
                    <span
                      className={`font-size-10 badge text-white rounded text-capitalize`}
                      style={{ background: `${data?.colorCode}` }}
                    >
                      {' '}
                      {data?.colorCode}{' '}
                    </span>
                  </span>
                </h4>
              </span>
            </Col>
          </Row>
        ),
        requirement: (
          <>
            {data.requirement?.rating ? (
              <>
                <span className='d-block'>
                  Trips : {data.requirement?.trip}
                </span>
                <span className='d-flex align-items-center'>
                  Rating : {data.requirement?.rating}
                </span>

                <span className='d-block'>
                  Vehicle Year : {data.requirement?.vehicleYear}
                </span>
                <span className='d-block'>
                  Cancellation : {`${data.requirement?.cancellation * 100}%`}
                </span>
              </>
            ) : (
              <>
                <span className='d-block'>
                  Minimum Points :{' '}
                  {data.requirement?.minimumPoint > 999 &&
                    data.requirement?.minimumPoint < 999999
                    ? `${(data.requirement?.minimumPoint / 1000).toFixed(1)} K`
                    : data.requirement?.minimumPoint > 999999
                      ? `${(data.requirement?.minimumPoint / 1000000).toFixed(
                        1
                      )} M`
                      : data.requirement?.minimumPoint}
                </span>
                <span className='d-block'>
                  Maximum Points :{' '}
                  {data.requirement?.maximumPoint > 999 &&
                    data.requirement?.maximumPoint < 999999
                    ? `${(data.requirement?.maximumPoint / 1000).toFixed(1)} K`
                    : data.requirement?.maximumPoint > 999999
                      ? `${(data.requirement?.maximumPoint / 1000000).toFixed(
                        1
                      )} M`
                      : data.requirement?.maximumPoint}
                </span>
              </>
            )}
          </>
        ),

        status: (
          <h1
            className={`badge badge-soft-${data.status.toLowerCase() === 'active'
              ? 'success'
              : data.status.toLowerCase() === 'inactive'
                ? 'danger'
                : 'warning'
              }`}
          >
            {data.status === 'COMINGSOON' ? 'COMING SOON' : data.status}
          </h1>
        ),
        action: (
          <div className='d-flex align-items-center'>
            <CheckAction field='Loyalty' subModule='Loyalty Tier' iconType='Edit'>
              <Link
                to={`/loyaltyTier_Form?id=${data.id}`}
                className='mr-3 text-primary'
                id='edit1'
              >
                <i className='mdi mdi-pencil font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='edit1'>
                Edit
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field='Loyalty' subModule='Loyalty Tier' iconType='Delete'>
              <Link
                to='#'
                onClick={() => deleteLoyaltyTier(data.id)}
                className='text-danger'
                id='delete1'
              >
                <i className='mdi mdi-trash-can font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='delete1'>
                Delete
              </UncontrolledTooltip>
            </CheckAction>
          </div>
        ),
      };
    });
    return {
      columns: [
        {
          dataField: 'sl.no',
          text: 'S.No',
          formatter: (cell, row, rowIndex) => {
            return rowIndex + 1;
          },
          sort: true,
        },
        {
          text: 'Name',
          dataField: 'name',
        },
        {
          text: 'Image - Color Code',
          dataField: 'image',
          sort: true,
        },
        {
          text: 'Requirements',
          dataField: 'requirement',
          sort: true,
        },
        {
          text: 'Status',
          dataField: 'status',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {isAuthorize ? (
                <Alert color='danger' className='text-center'>
                  {isAuthorize}
                </Alert>
              ) : (
                <>
                  <div className='d-flex justify-content-end mb-2'>
                    <FilterDropDown
                      menu={menu}
                      toggleMenu={() => setMenu(!menu)}
                    >
                      <div>
                        <AvForm
                          className='form-horizontal'
                          onValidSubmit={handleDateFilter}
                        >
                          <SimpleBar
                            style={{
                              maxHeight: '250px',
                            }}
                          >
                            <Row className='px-3  pt-3 pb-0'>
                              {/* <Col md='12'>
                              <FormGroup>
                                <AvField
                                  name='startDate'
                                  type='date'
                                  className='form-control bg-light'
                                  id='startDate'
                                  value={date.start}
                                  helpMessage='Start Date'
                                  placeholder='Please choose the start date...'
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md='12'>
                              <FormGroup>
                                <AvField
                                  name='endDate'
                                  type='date'
                                  className='form-control bg-light'
                                  id='endDate'
                                  value={date.end}
                                  helpMessage='End Date'
                                  placeholder='Please choose the end date...'
                                  required
                                />
                              </FormGroup>
                            </Col>{' '} */}
                              <Col md='12'>
                                <FormGroup>
                                  <AvField
                                    type='select'
                                    value={type}
                                    name='type'
                                    helpMessage='User Type'
                                  >
                                    <option value=''>All</option>
                                    <option value='RIDER'>RIDER</option>
                                    <option value='DRIVER'>DRIVER</option>
                                  </AvField>
                                </FormGroup>
                              </Col>
                              <Col md='12'>
                                <FormGroup>
                                  <AvField
                                    type='select'
                                    value={status}
                                    name='vehicleStatus'
                                    helpMessage='status'
                                  >
                                    <option value=''>All</option>
                                    <option value='ACTIVE'>ACTIVE</option>
                                    <option value='INACTIVE'>INACTIVE</option>
                                  </AvField>
                                </FormGroup>
                              </Col>
                            </Row>
                          </SimpleBar>

                          <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                            <Link
                              to='#'
                              onClick={resetHandler}
                              className='btn btn-sm  bg-light font-size-14'
                            >
                              <i className=' mdi mdi-refresh mr-1'> Reset</i>
                            </Link>

                            <button
                              className='btn btn-success btn-sm mr-2'
                              type='submit'
                            >
                              <i className='mdi mdi-arrow-right-circle mr-1'></i>
                              Send
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </FilterDropDown>
                  </div>
                  {!isLoading && data !== null ? (
                    <>
                      <div className='d-flex justify-content-between mb-2'>
                        <div>
                          <Input
                            bsSize='sm'
                            id='take'
                            name='take'
                            type='select'
                            onChange={handleLimitChange}
                            value={take}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div>
                          <Input
                            bsSize='sm'
                            id='search'
                            value={search}
                            name='search'
                            onChange={SearchHandler}
                            placeholder='Search'
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField='id'
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses='table-responsive'
                        noDataIndication={'No Data to Display'}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        // breakLabel={'...'}
                        // marginPagesDisplayed={2}
                        // pageRangeDisplayed={3}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        forcePage={skipPage}
                        //Bootstrap styling
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default LoyaltyTierTable;
