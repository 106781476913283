export const IS_VEHICLE_AUTHORIZE = 'IS_VEHICLE_AUTHORIZE'
// VEHICLE Summary
export const FETCH_VEHICLE_SUMMARY = 'FETCH_VEHICLE_SUMMARY'
export const FETCH_VEHICLE_SUMMARY_FAILED = 'FETCH_VEHICLE_SUMMARY_FAILED'
export const FETCH_VEHICLE_SUMMARY_SUCCESSFULLY =
  'FETCH_VEHICLE_SUMMARY_SUCCESSFULLY'

export const FETCH_VEHICLE = 'FETCH_VEHICLE'
export const FETCH_VEHICLE_SUCCESSFUL = 'FETCH_VEHICLE_SUCCESSFUL'
export const FETCH_VEHICLE_API_FAILED = 'FETCH_VEHICLE_API_FAILED'

export const FETCH_EACH_VEHICLE = 'FETCH_EACH_VEHICLE'
export const FETCH_EACH_VEHICLE_SUCCESSFUL = 'FETCH_EACH_VEHICLE_SUCCESSFUL'
export const FETCH_EACH_VEHICLE_API_FAILED = 'FETCH_EACH_VEHICLE_API_FAILED'

export const FETCH_MY_VEHICLE = 'FETCH_MY_VEHICLE'
export const FETCH_MY_VEHICLE_SUCCESSFUL = 'FETCH_MY_VEHICLE_SUCCESSFUL'
export const FETCH_MY_VEHICLE_API_FAILED = 'FETCH_MY_VEHICLE_API_FAILED'

export const DELETE_VEHICLE = 'DELETE_VEHICLE'
export const DELETE_VEHICLE_SUCCESSFUL = 'DELETE_VEHICLE_SUCCESSFULLY'
export const DELETE_VEHICLE_FAILED = 'DELETE_VEHICLE_FAILED'

export const CREATE_VEHICLE = 'CREATE_VEHICLE'
export const CREATE_VEHICLE_SUCCESSFUL = 'CREATE_VEHICLE_SUCCESSFULLY'
export const CREATE_VEHICLE_FAILED = 'CREATE_VEHICLE_FAILED'

export const UPDATE_VEHICLE = 'UPDATE_VEHICLE'
export const UPDATE_VEHICLE_SUCCESSFUL = 'UPDATE_VEHICLE_SUCCESSFULLY'
export const UPDATE_VEHICLE_FAILED = 'FAILED'

export const UPDATE_VEHICLE_WITH_PACKAGE = 'UPDATE_VEHICLE_WITH_PACKAGE'
export const UPDATE_VEHICLE_WITH_PACKAGE_FAILED =
  'UPDATE_VEHICLE_WITH_PACKAGE_FAILED'
export const UPDATE_VEHICLE_WITH_PACKAGE_SUCCESSFULLY =
  'UPDATE_VEHICLE_WITH_PACKAGE_SUCCESSFULLY'
