export const CHECK_LOGIN = 'check_login';
export const LOGIN_USER_SUCCESSFUL = 'login_user_successfull';
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const API_ERROR = 'api_failed';
export const AUTH_ERROR = 'auth_error';
export const API_ACTIVATION_ERROR = 'API_ACTIVATION_ERROR';

export const MY_ACCESS_CONTROL = 'MY_ACCESS_CONTROL';
export const MY_ACCESS_CONTROL_SUCCESSFUL = 'MY_ACCESS_CONTROL_SUCCESSFUL';
export const MY_ACCESS_CONTROL_FAILED = 'MY_ACCESS_CONTROL_FAILED';

export const REGISTER_USER = 'register_user';
export const REGISTER_USER_SUCCESSFUL = 'register_user_successful';
export const REGISTER_USER_FAILED = 'register_user_failed';

// Load User
export const LOAD_USER = 'load_user';
export const LOAD_USER_ERROR = 'load_user_error';
export const LOAD_USER_SUCCESSFUL = 'load_user_successful';

// Generate OTP
export const GENERATE_OTP = 'GENERATE_OTP';
export const GENERATE_OTP_SUCCESS = 'GENERATE_OTP_SUCCESS';
export const GENERATE_OTP_FAILED = 'GENERATE_OTP_FAILED';

// Activate User
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAILED = 'ACTIVATE_USER_FAILED';

// Activate User
export const RE_ACTIVATE_USER = 'RE_ACTIVATE_USER';
export const RE_ACTIVATE_USER_SUCCESS = 'RE_ACTIVATE_USER_SUCCESS';
export const RE_ACTIVATE_USER_FAILED = 'RE_ACTIVATE_USER_FAILED';

export const UPDATE_ACCOUNT_WALLET = 'UPDATE_ACCOUNT_WALLET';
export const UPDATE_ACCOUNT_WALLET_SUCCESSFULLY =
  'UPDATE_ACCOUNT_WALLET_SUCCESSFULLY';
export const UPDATE_ACCOUNT_WALLET_FAILED = 'UPDATE_ACCOUNT_WALLET_FAILED';

// email subscription
export const EMAIL_SUBSCRIPTION = 'EMAIL_SUBSCRIPTION';
export const EMAIL_SUBSCRIPTION_SUCCESS = 'EMAIL_SUBSCRIPTION_SUCCESS';
export const EMAIL_SUBSCRIPTION_FAILED = 'EMAIL_SUBSCRIPTION_FAILED';

// forget password
export const FORGET_USER = 'forget_password';
export const FORGET_USER_SUCCESSFUL = 'forget_user_successfull';

export const FORGET_PASSWORD_API_FAILED = 'FORGET_PASSWORD_API_FAILED';

// Create new password
export const CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD';
export const CREATE_NEW_PASSWORD_SUCCESSFUL = 'CREATE_NEW_PASSWORD_SUCCESSFUL';

export const CREATE_NEW_PASSWORD_API_FAILED = 'CREATE_NEW_PASSWORD_API_FAILED';

// password setting
export const PASSWORD_SETTING = 'PASSWORD_SETTING';
export const PASSWORD_SETTING_SUCCESSFUL = 'PASSWORD_SETTING_SUCCESSFUL';

export const PASSWORD_SETTING_API_FAILED = 'PASSWORD_SETTING_API_FAILED';

// Update Profile
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESSFUL = 'UPDATE_PROFILE_SUCCESSFUL';
export const UPDATE_PROFILE_API_FAILED = 'UPDATE_PROFILE_API_FAILED';

export const USER_LOCATION = 'USER_LOCATION';
export const USER_LOCATION_ERROR = 'USER_LOCATION_ERROR';
export const USER_LOCATION_SUCCESSFULLY = 'USER_LOCATION_SUCCESSFULLY';
