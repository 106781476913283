import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Col, Card, CardBody, Media } from 'reactstrap';
import { connect } from 'react-redux';
import { fetchCashoutSummary } from '../../../store/actions';

const MiniWidgets = ({
  summaryError,
  cashoutSummary,
  fetchCashoutSummary,
  start,
  end,
  loading,
  defaultIcon,
  date,
}) => {
  useEffect(() => {
    if (date.end) {
      fetchCashoutSummary(date);
    } else {
      fetchCashoutSummary();
    }
  }, [date, fetchCashoutSummary]);

  const reports = [
    {
      icon: defaultIcon ? defaultIcon : 'ri-bank-line',
      title: 'Total Amount',
      color: 'primary',
      value: `₦${cashoutSummary?.result?.requestAmount.overall.toLocaleString(
        'en-US'
      )}`,
      more: [
        {
          Completed: `₦${cashoutSummary?.result?.requestAmount.completed.toLocaleString(
            'en-US'
          )}`,
        },
        {
          Pending: `₦${cashoutSummary?.result?.requestAmount?.pending.toLocaleString(
            'en-US'
          )}`,
        },
        {
          Processing: `₦${cashoutSummary?.result?.requestAmount?.processing.toLocaleString(
            'en-US'
          )}`,
        },
        {
          Cancelled: `₦${cashoutSummary?.result?.requestAmount?.cancelled.toLocaleString(
            'en-US'
          )}`,
        },
      ],
    },
    {
      icon: 'fas fa-donate',
      title: 'Completed Status',
      color: 'secondary',
      value: `${cashoutSummary?.result?.requestStatus.completed}`,
      more: [
        {
          Pending: `${cashoutSummary?.result?.requestStatus.pending}`,
        },
      ],
    },
    {
      icon: 'fas fa-car  ',
      title: 'Total Request',
      color: 'warning',
      value: `${cashoutSummary?.result?.cashoutRequest?.numberofRequest}`,
      more: [
        {
          Driver: `${cashoutSummary?.result?.cashoutRequest?.numberofDrivers}`,
        },
      ],
    },
    {
      icon: defaultIcon ? defaultIcon : 'ri-bank-line',
      title: 'Monday',
      color: 'primary',
      value: `${cashoutSummary?.result?.weeklyStatus.Monday.toLocaleString(
        'en-US'
      )}`,
      more: [
        {
          Tuesday: `${cashoutSummary?.result?.weeklyStatus.Tuesday.toLocaleString(
            'en-US'
          )}`,
        },
        {
          Wednesday: `${cashoutSummary?.result?.weeklyStatus?.Wednesday.toLocaleString(
            'en-US'
          )}`,
        },
        {
          Thursday: `${cashoutSummary?.result?.weeklyStatus?.Thursday.toLocaleString(
            'en-US'
          )}`,
        },
        {
          Friday: `${cashoutSummary?.result?.weeklyStatus?.Friday.toLocaleString(
            'en-US'
          )}`,
        },
        {
          Saturday: `${cashoutSummary?.result?.weeklyStatus?.Saturday.toLocaleString(
            'en-US'
          )}`,
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      {summaryError === null &&
        reports.map((report, key) => (
          <Col key={key} md={4} lg={3}>
            <Card className="d-flex flex-row">
              <CardBody>
                <Media>
                  <Media body className="overflow-hidden">
                    <span
                      className={`text-${report.color} badge badge-soft-${report.color} rounded`}
                    >
                      <i className={report.icon + ' font-size-16'}></i>
                    </span>
                    <p className="text-truncate font-size-14 mt-4 mb-2 ">
                      {report.title}
                    </p>
                    <h2 className="mt-0 mb-0 font-size-12">
                      {cashoutSummary === null ? (
                        <Skeleton duration={2} width="20%" />
                      ) : (
                        report.value
                      )}
                    </h2>

                    {cashoutSummary?.isCache && report?.updatedTime && (
                      <p
                        className="font-size-12 text-primary"
                        style={{ position: 'absolute', right: '11px' }}
                      >
                        Updated at: {report?.updatedTime?.split(',')?.pop()}
                      </p>
                    )}
                  </Media>
                </Media>
              </CardBody>

              {report?.more?.length > 0 && (
                <CardBody
                  className="p-1 pr-3 py-4 d-flex flex-column"
                  style={{ justifyContent: 'space-evenly' }}
                >
                  {report?.more.map((item, index) => (
                    <div key={index}>
                      {cashoutSummary === null ? (
                        <Skeleton duration={2} width="100%" />
                      ) : (
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="text-muted font-size-12">
                            {Object.keys(item)[0]}
                          </span>
                          <h2 className="mt-0 mb-0 font-size-12">
                            {Object.values(item)[0]}
                          </h2>
                        </div>
                      )}
                    </div>
                  ))}
                </CardBody>
              )}
            </Card>
          </Col>
        ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { summaryError, cashoutSummary } = state.Cashout;
  return { summaryError, cashoutSummary };
};

export default connect(mapStateToProps, { fetchCashoutSummary })(MiniWidgets);
