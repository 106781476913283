import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import Moment from 'moment';
import { fetchActivities } from '../../../store/actions';
import SimpleBar from 'simplebar-react';
import PreviewActivity from './previewActivity';

const Activities = ({ fetchActivities, activities, user, loading }) => {
  const [take, setTake] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);

  useEffect(() => {
    fetchActivities({ userId: user.id });
  }, []);

  const handleViewMore = (e) => {
    setTake(take + 10);
    fetchActivities({ userId: user.id, take: take + 10 });
  };

  return (
    <React.Fragment>
      {activities &&
      activities?.result != '' &&
      activities?.message !== 'No Activities' &&
      user.isActive ? (
        <SimpleBar style={{ maxHeight: '400px' }}>
          {/* <div>
            {' '}
            <Link to={`/activities?user=${user.id}`} className='mr-4 my-4'>
              More
              <i className='fas fa-arrow-right ml-2' />
            </Link>{' '}
          </div> */}
          <Row>
            <Col sm={7} md={5}>
              {activities?.result?.map((activity, key) => (
                <div
                  className='border rounded p-2 px-4 mb-3'
                  style={{ background: '#ebf3f1', cursor: 'pointer' }}
                  key={activity._id}
                  onClick={() => (
                    setPreviewId(activity._id), setShowModal(true)
                  )}
                >
                  <div className='d-flex justify-content-between align-items-center'>
                    <h4 className='card-title text-muted text-capitalize'>
                      {activity?.firstName} {activity?.lastName}
                    </h4>

                    <span>
                      <h6
                        className={`badge badge-soft-${
                          activity.responseStatusCode === '200' ||
                          activity.responseStatusCode === '201'
                            ? 'success'
                            : 'danger'
                        } ml-2`}
                      >
                        {activity.responseStatusCode === '200' ||
                        activity.responseStatusCode === '201'
                          ? 'success'
                          : 'failed'}{' '}
                      </h6>
                    </span>
                  </div>

                  <div>
                    <div className='d-flex align-items-baseline mb-1'>
                      <div className='w-100 d-flex align-items-center justify-content-between pr-1'>
                        <h5 className='font-weight-bold font-size-13'>
                          <>
                            {activity?.module}
                            <span className='text-muted ml-2 badge badge-soft-secondary'>
                              {activity?.nrydeSource}
                            </span>
                          </>

                          {/* <span>
                              <i className=' ri-checkbox-blank-circle-fill font-size-10 mr-1 ml-3 text-muted' />
                              <span className='text-success '>
                                {activity?.url.includes('?')
                                  ? activity?.url.substr(
                                      0,
                                      activity?.url.indexOf('?')
                                    )
                                  : activity?.url}
                              </span>
                            </span> */}
                        </h5>
                        <h6 className='font-size-11'>
                          {key + 1} / {activities?.total}
                        </h6>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <p className='text-muted font-size-10 mb-1'>
                        {Moment(activity?.createdAt).format(
                          'MMMM Do YYYY, h:mm:ss a'
                        )}
                      </p>
                    </div>
                  </div>
                  <hr className='my-2' />
                  <div>
                    <ul className='list-unstyled activity-wid mb-0'>
                      <li className='activity-list pb-2 pl-1'>
                        <div className='activity-icon'>
                          <i className='fas fa-globe-americas text-primary' />
                        </div>
                        <div>
                          <h5 className='font-weight-bold font-size-13 d-flex justify-content-between align-items-center'>
                            <span
                              className=' '
                              style={{
                                whiteSpace: ' pre-wrap',
                                width: '80%',
                              }}
                            >
                              {activity?.host}
                              {activity?.url.includes('?')
                                ? activity?.url.substr(
                                    0,
                                    activity?.url.indexOf('?')
                                  )
                                : activity?.url}
                            </span>
                            <span className={`badge badge-soft-dark`}>
                              {activity.method}{' '}
                            </span>
                          </h5>
                        </div>
                      </li>
                      {activity.response && !activity.response.success && (
                        <li className='activity-list pb-2 pl-1'>
                          <div className='activity-icon'>
                            <i className='fas fa-envelope-square text-warning' />
                          </div>
                          <div>
                            <p className='mb-1 font-size-12 text-muted'>
                              {activity.response?.error?.message
                                ? activity.response?.error?.message
                                : activity.response?.message}
                            </p>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              ))}
              {loading ? (
                <Table
                  hover
                  className='mt-3 border rounded mb-0 table-centered table-nowrap'
                  style={{ background: '#ebf3f1' }}
                >
                  <tbody>
                    {[1]?.map((data, key) => {
                      return (
                        <tr key={key}>
                          <td style={{ width: '90%' }}>
                            <h5 className='font-size-14 mb-2'>
                              <Skeleton duration={2} width='80%' />
                            </h5>
                            <span className='text-muted mb-2'>
                              <Skeleton duration={2} width='50%' />
                            </span>
                            <span className='text-muted mb-2'>
                              <Skeleton duration={2} width='70%' />
                            </span>
                            <hr className='my-2' />
                            <h5 className='font-size-14  mb-2'>
                              <Skeleton duration={2} width='80%' />
                            </h5>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <Button
                  color='primary'
                  size='sm'
                  className='w-100 my-2'
                  onClick={handleViewMore}
                >
                  {' '}
                  View More
                </Button>
              )}
            </Col>
          </Row>
          <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
            <ModalHeader toggle={() => setShowModal(false)}>
              Preview Activity
            </ModalHeader>
            <ModalBody>
              <PreviewActivity previewId={previewId} />
            </ModalBody>
          </Modal>
        </SimpleBar>
      ) : (
        'There is no recent activities for this user'
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { activities, loading, activitiesError, message } = state.Activities;
  return { activities, loading, activitiesError, message };
};

export default connect(mapStateToProps, {
  fetchActivities,
})(Activities);
