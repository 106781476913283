import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Alert,
  FormGroup,
  Col,
  UncontrolledTooltip,
  Input,
} from 'reactstrap';
import Moment from 'moment';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import { QueryReallignment } from '../../../utils/general';
import car4 from '../../../assets/images/cars_type/Suv_car.png';
import FilterDropDown from '../../../components/Common/FilterDropDown';
import SimpleBar from 'simplebar-react';
import BootstrapTable from 'react-bootstrap-table-next';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import ReactPaginate from 'react-paginate';
import Loader from '../../../components/Common/Loading/index.js';
import { Link } from 'react-router-dom';
import { ScrollToTop } from '../../../utils/general';
import queryString from 'query-string';

let skip = 0;
let dataLimit = 10;

const PackageTable = ({
  packageData,
  zone,
  previewPackageAction,
  addZoneHandler,
  deletePackage,
  tableError,
  fetchPackage,
  history,
  message,
  setIsUpdatedHandler,
  dateFilter,
  CheckAction,
  ReadCheck,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [skipPage, setSkipPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState('');
  const [menu, setMenu] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });
  let currentPage = 0;
  let dataLimit = 10;

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(packageData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);

    let pageInfo = { skip: pageNumber, take: dataLimit };
    fetchPackage(pageInfo);
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take, search };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchPackage(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
    };
    // queryParams.skip = skip
    queryParams.take = take;
    fetchPackage(queryParams);
    SetUrlWithParams();
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    dateFilter({ start: values.startDate, end: values.endDate });
    setMenu(false);
    setStatus(values.vehicleStatus);
    let queryParams = {
      search,
      take,
      skip,
      status: values.vehicleStatus,
      start: values.startDate,
      end: values.endDate,
    };
    fetchPackage(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: '',
      end: '',
    });
    dateFilter({});
    let queryParams = {
      search,
      take,
      skip,
      status,
    };
    fetchPackage(queryParams);
  };

  const SetUrlWithParams = () => {
    let queryParams = {
      skip,
      search,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        // start: date.start,
        // end: date.end,
        skip,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    return parsedUrlParams;
  };

  useEffect(() => {
    if (packageData) {
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(packageData?.total / take));
      // SET CSV EXPORT DATA
      const csvData = packageData.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Name: data?.name,
          Key: data?.key,
          Description: data?.description,
          Provider: data?.provider,
          Capacity: data?.capacity,
          Priority: data?.priority ? data?.priority : '----',
          BasePrice: data?.basePrice,
          DistancePrice: data?.distancePrice,
          TimePrice: data?.timePrice,
          PricingType: data?.pricingType,
          PackageType: data?.packageType,
          Picture: data?.picture ? data?.picture : '----',
          isAcceptShareRide: data?.isAcceptShareRide,
          MinimumPrice: data?.minimumPrice,
          MinimumPriceDistance: data?.minimumPriceDistance,
          ColorCode: data?.colorCode,
          Status: data?.status,
          Created_Date: data?.createdAt?.substring(0, 10),
        };

        return formData;
      });
      setCsvData(csvData);
    }
  }, [packageData]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    console.log(parsedUrlParams);
    fetchPackage(parsedUrlParams);
  }, [fetchPackage]);

  let accessChecking = ReadCheck(accessControl, `Ride Package`);
  const tableDataHandler = () => {
    const rowData = packageData?.result?.map((data) => {
      return {
        id: data.id,
        serviceName: (

          <>
            {accessChecking ?

              <Link
                to='#'
                onClick={() => previewPackageAction(data.id)}
                className='text-dark font-weight-bold text-uppercase d-flex flex-column align-items-start'
              >
                <div>{data.name}</div>
                <span
                  className='text-white badge mt-2'
                  style={{ background: data.colorCode }}
                >
                  {data.provider}
                </span>

                <span
                  className=''
                >
                  {data.key}
                </span>
              </Link>

              :
              <div className='text-dark font-weight-bold text-uppercase d-flex flex-column align-items-start'>
                <div>{data.name}</div>
                <span
                  className='text-white badge mt-2'
                  style={{ background: data.colorCode }}
                >
                  {data.provider}
                </span>

                <span
                  className=''
                >
                  {data.key}
                </span>
              </div>
            }
          </>
        ),
        zone: (
          <div className='text-truncate' style={{ maxWidth: '300px' }}>
            {data?.zones?.length > 0
              ? data?.zones?.map((zone) => (
                <div key={zone.id}>
                  {' '}
                  <h1 className={`badge badge-soft-secondary font-size-12`}>
                    {' '}
                    {zone.name}
                  </h1>{' '}
                </div>
              ))
              : 'No zone restriction'}
          </div>
        ),
        key: data.key,
        // providerName: data.provider,
        capacity: data.capacity,
        basePrice: `₦${data.basePrice}`,
        baseDistance: data.distancePrice,
        timePrice: `₦${data.timePrice}`,
        priceCalculator: data.pricingType,
        status: (
          <>
            <span
              className={`badge badge-soft-${data?.status === 'ACTIVE'
                ? 'success'
                : data?.status === 'INACTIVE'
                  ? 'danger'
                  : 'warning'
                } font-size-12`}
            >
              {data?.status}
            </span>
          </>
        ),
        serviceImage: (
          <div className='d-flex flex-column align-items-center'>
            <img
              src={data.picture ? data.picture : car4}
              alt='suv'
              className='avatar-sm'
            />
            <h1 className={`mt-3 badge badge-soft-${data?.status === 'ACTIVE'
              ? 'success'
              : data?.status === 'INACTIVE'
                ? 'danger'
                : 'warning'
              } font-size-10`}
            >
              {data?.status}
            </h1>
          </div>
        ),
        action: (
          <div className='d-flex align-items-center'>
            <CheckAction field='Ride Package' iconType='Edit'>
              <Link
                to='#'
                onClick={() => setIsUpdatedHandler(true, data)}
                className='mr-3 text-primary'
                id='edit1'
              >
                <i className='mdi mdi-pencil font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='edit1'>
                Edit
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field='Ride Package' iconType='Delete'>
              <Link
                to='#'
                onClick={() => deletePackage(data.id)}
                className='text-danger mr-3'
                id='delete1'
              >
                <i className='mdi mdi-trash-can font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='delete1'>
                Delete
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field='Ride Package' iconType='Edit'>
              <Link
                to='#'
                className='text-success mr-3'
                id='addZone'
                onClick={() => addZoneHandler(data)}
              >
                <i
                  className='mdi mdi-map-marker-multiple-outline
 font-size-16 text-success rounded-circle'
                ></i>
              </Link>
              <UncontrolledTooltip placement='top' target='addZone'>
                Add Zone
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field='Ride Package' iconType='Read'>
              <Link
                to={`/package_vehicles?id=${data.id}`}
                className='text-info'
                id='vehiclesView'
              >
                <i
                  className='ri-car-line font-size-18'
                ></i>
              </Link>
              <UncontrolledTooltip placement='top' target='vehiclesView'>
                View Vehicles
              </UncontrolledTooltip>
            </CheckAction>
          </div>
        ),
      };
    });
    return {
      columns: [
        // {
        //   dataField: 'id',
        //   text: 'Order ID',
        // },
        {
          dataField: 'serviceName',
          text: 'Service Name',
        },
        // {
        //   dataField: 'key',
        //   text: 'Unique Key',
        // },
        // {
        //   dataField: 'capacity',
        //   text: 'Capacity',
        // },
        {
          dataField: 'basePrice',
          text: 'Base Price (₦)',
          headerStyle: { width: '120px' },
        },
        {
          dataField: 'baseDistance',
          text: 'Distance Price (N/m)',
        },
        {
          dataField: 'timePrice',
          text: 'Time Price (N/s)',
          headerStyle: { width: '130px' },
        },
        {
          dataField: 'zone',
          text: 'Zones',
        },
        // {
        //   text: 'Status',
        //   dataField: 'status',
        // },
        {
          dataField: 'serviceImage',
          text: 'Service Image',
        },
        {
          dataField: 'action',
          text: 'Action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              {tableError !== null ? (
                <Alert color='danger' className='text-center'>
                  {tableError}
                </Alert>
              ) : (
                <>
                  {!isLoading && data !== null ? (
                    <>
                      <div className='d-flex justify-content-end  align-items-center mb-2'>
                        <div className=''>
                          <CSVLink
                            data={csvData}
                            filename={'packages_data.csv'}
                            className='btn btn-primary btn-sm  mt-1'
                            target='_blank'
                          >
                            <i className=' fas fa-cloud-download-alt mr-1' />
                            CSV
                          </CSVLink>
                        </div>
                        <div>
                          <FilterDropDown
                            menu={menu}
                            toggleMenu={() => setMenu(!menu)}
                          >
                            <div>
                              <AvForm
                                className='form-horizontal'
                                onValidSubmit={handleDateFilter}
                              >
                                <SimpleBar
                                  style={{
                                    maxHeight: '250px',
                                  }}
                                >
                                  <Row className='px-3  pt-3 pb-0'>
                                    <Col md='12'>
                                      <FormGroup>
                                        <AvField
                                          name='startDate'
                                          type='date'
                                          className='form-control bg-light'
                                          id='startDate'
                                          value={date.start}
                                          helpMessage='Start Date'
                                          placeholder='Please choose the start date...'
                                          required
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md='12'>
                                      <FormGroup>
                                        <AvField
                                          name='endDate'
                                          type='date'
                                          className='form-control bg-light'
                                          id='endDate'
                                          value={date.end}
                                          helpMessage='End Date'
                                          placeholder='Please choose the end date...'
                                          required
                                        />
                                      </FormGroup>
                                    </Col>{' '}
                                    <Col md='12'>
                                      <FormGroup>
                                        <AvField
                                          type='select'
                                          value={status}
                                          name='vehicleStatus'
                                          helpMessage='status'
                                        >
                                          <option value=''>All</option>
                                          <option value='ACTIVE'>ACTIVE</option>
                                          <option value='INACTIVE'>
                                            INACTIVE
                                          </option>
                                          <option value='SANCTION'>
                                            SANCTION
                                          </option>
                                        </AvField>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </SimpleBar>

                                <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                                  <Link
                                    to='#'
                                    onClick={resetHandler}
                                    className='btn btn-sm  bg-light font-size-14'
                                  >
                                    <i className=' mdi mdi-refresh mr-1'>
                                      {' '}
                                      Reset
                                    </i>
                                  </Link>

                                  <button
                                    className='btn btn-success btn-sm mr-2'
                                    type='submit'
                                  >
                                    <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                    Send
                                  </button>
                                </div>
                              </AvForm>
                            </div>
                          </FilterDropDown>
                        </div>
                      </div>

                      <div className='d-flex justify-content-between mb-2'>
                        <div>
                          <Input
                            bsSize='sm'
                            id='limit'
                            name='limit'
                            type='select'
                            onChange={handleLimitChange}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div>
                          <Input
                            bsSize='sm'
                            id='search'
                            name='search'
                            onChange={SearchHandler}
                            placeholder='Search'
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField='id'
                        data={data.rows}
                        columns={data.columns}
                        loading={isLoading}
                        // overlay={overlayFactory()}
                        striped
                        hover
                        wrapperClasses='table-responsive'
                        noDataIndication={'No Data to Display'}
                      />
                      <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        // breakLabel={'...'}
                        // marginPagesDisplayed={2}
                        // pageRangeDisplayed={3}

                        forcePage={skipPage}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PackageTable;
