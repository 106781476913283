import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Form from './form';

import { fetchEachBank } from '../../../../store/actions';

const BankForm = ({ fetchEachBank, bankPreview, loading, message }) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const userId = new URLSearchParams(search).get('user');

  useEffect(() => {
    if (id) {
      fetchEachBank(id);
    }
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        {bankPreview ? (
          <Form
            loading={loading}
            defaultValue={bankPreview}
            message={message}
          />
        ) : (
          <Form
            loading={loading}
            message={message}
            defaultValue={null}
            userID={userId}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { bankPreview, loading, bankError, message } = state.Bank;
  return { bankPreview, loading, bankError, message };
};

export default connect(mapStateToProps, { fetchEachBank })(BankForm);
