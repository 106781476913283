import React, { useState } from 'react';
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import classnames from 'classnames';

//Import Breadcrumb
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import Loader from '../../../../components/Common/Loading/index.js';
import TripSettings from './TripSettings';
import WalletSettings from './WalletSettings';
import General from './GeneralSettings';
import Installation from './Installation';
import Referral from './Referral';
import MapSettings from './MapSettings';
import Firebase from './FirebaseSettings';

const SystemSetting = ({ loading }) => {
  const [activeTab, setActiveTab] = useState(1);

  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'System Setting', link: '#' },
  ];

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='System Setting'
            breadcrumbItems={breadcrumbItems}
          />

          <Card>
            <CardBody>
              {loading ? (
                <Loader />
              ) : (
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        <div id=''>
                          <Nav tabs>
                            <NavItem key={1}>
                              <NavLink
                                className={classnames(
                                  {
                                    active: activeTab === '1',
                                  },
                                  activeTab === 1 ? 'active' : null
                                )}
                                onClick={() => {
                                  setActiveTab(1);
                                }}
                              >
                                Trip Settings
                              </NavLink>
                            </NavItem>
                            <NavItem key={2}>
                              <NavLink
                                className={classnames(
                                  {
                                    active: activeTab === '2',
                                  },
                                  activeTab === 2 ? 'active' : null
                                )}
                                onClick={() => {
                                  setActiveTab(2);
                                }}
                              >
                                Wallet Settings
                              </NavLink>
                            </NavItem>
                            <NavItem key={3}>
                              <NavLink
                                className={classnames(
                                  {
                                    active: activeTab === '3',
                                  },
                                  activeTab === 3 ? 'active' : null
                                )}
                                onClick={() => {
                                  setActiveTab(3);
                                }}
                              >
                                General
                              </NavLink>
                            </NavItem>
                            <NavItem key={4}>
                              <NavLink
                                className={classnames(
                                  {
                                    active: activeTab === '4',
                                  },
                                  activeTab === 4 ? 'active' : null
                                )}
                                onClick={() => {
                                  setActiveTab(4);
                                }}
                              >
                                Installation
                              </NavLink>
                            </NavItem>
                            <NavItem key={5}>
                              <NavLink
                                className={classnames(
                                  {
                                    active: activeTab === '5',
                                  },
                                  activeTab === 5 ? 'active' : null
                                )}
                                onClick={() => {
                                  setActiveTab(5);
                                }}
                              >
                                Referral
                              </NavLink>
                            </NavItem>
                            <NavItem key={6}>
                              <NavLink
                                className={classnames(
                                  {
                                    active: activeTab === '6',
                                  },
                                  activeTab === 6 ? 'active' : null
                                )}
                                onClick={() => {
                                  setActiveTab(6);
                                }}
                              >
                                Map Settings
                              </NavLink>
                            </NavItem>
                            <NavItem key={7}>
                              <NavLink
                                className={classnames(
                                  {
                                    active: activeTab === '7',
                                  },
                                  activeTab === 7 ? 'active' : null
                                )}
                                onClick={() => {
                                  setActiveTab(7);
                                }}
                              >
                                Firebase Settings
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent activeTab={activeTab} className='p-3'>
                            <TabPane tabId={1}>
                              <TripSettings />
                            </TabPane>

                            <TabPane tabId={2}>
                              <WalletSettings />
                            </TabPane>

                            <TabPane tabId={3}>
                              <General />
                            </TabPane>

                            <TabPane tabId={4}>
                              <Installation />
                            </TabPane>

                            <TabPane tabId={5}>
                              <Referral />
                            </TabPane>

                            <TabPane tabId={6}>
                              <MapSettings />
                            </TabPane>

                            <TabPane tabId={7}>
                              <Firebase />
                            </TabPane>
                          </TabContent>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SystemSetting;
