import React, { useState, useEffect } from 'react';
import {
  Card,
  Alert,
  CardBody,
  Row,
  FormGroup,
  Col,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';

import ReactPaginate from 'react-paginate';

import Loader from '../../../../components/Common/Loading/index.js';
import Moment from 'moment';
import moment from 'moment-timezone';

import FilterDropDown from '../../../../components/Common/FilterDropDown';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../../utils/general';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import queryString from 'query-string';

let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const RoleTable = ({
  roleData,
  updateRole,
  previewAction,
  tableError,
  fetchRoles,
  deleteRole,
  history,
  message,
  privileges,
  CheckAction,
  ReadCheck,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [skipPage, setSkipPage] = useState(0);
  const [menu, setMenu] = useState(false);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState({});

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(roleData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = { skip, take: dataLimit, search };
    fetchRoles(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data?.selected;
    setPageNumber(parseInt(data?.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data?.selected + '0'));
    }
    let pageInfo = { skip, take, search };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchRoles(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      skip,
    };
    SetUrlWithParams();
    fetchRoles(queryParams);
  };
  useEffect(() => {
    if (roleData) {
      console.log(roleData);
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(roleData?.total / take));
    }
  }, [roleData]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchRoles(parsedUrlParams);
  }, [fetchRoles]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,

      skip: pageNumber,
      take,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  // console.log(roleData);

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        // start: date.start,
        // end: date.end,
        skip,
        take,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }

    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
        };
        fetchRoles(queryParams);
      }, 2000);
    }
  }, [fetchRoles, message]);

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    setMenu(false);
    let queryParams = {
      search,
      take,
      skip,
      status: values.vehicleStatus,
      start: values.startDate,
      end: values.endDate,
    };
    fetchRoles(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    });
    let queryParams = {
      search,
      take,
      skip,
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    };
    fetchRoles(queryParams);
  };
  let accessChecking = ReadCheck(accessControl, `Configurations`, 'Access Control FE');

  const tableDataHandler = () => {
    let rowData = roleData?.map((data, i) => {
      return {
        keyId: data?.id,
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,
        id: (
          <div className="mr-3 text-dark font-weight-bold">
            {data?.id.substring(0, 7)}
          </div>
        ),
        name: <p className="text-capitalize">{data?.name}</p>,
        user: !data.role ? (

          <>
            {accessChecking ?

              <Link
                to={`/user/${data?.user?.id}`}
                className="text-dark font-weight-bold"
              >
                <div className="d-flex flex-column align-items-center">

                  <div className="text-capitalize">
                    {data?.user?.firstName} {data?.user?.lastName}
                  </div>

                  <span className="text-white badge badge-danger">
                    User Base Acl
                  </span>
                </div>

              </Link>

              :
              <div className='text-dark font-weight-bold'>
                <div className="d-flex flex-column align-items-center">

                  <div className="text-capitalize">
                    {data?.user?.firstName} {data?.user?.lastName}
                  </div>

                  <span className="text-white badge badge-danger">
                    User Base Acl
                  </span>
                </div>
              </div>

            }</>
        ) : (
          <div className="d-flex align-items-center">
            <div>
              <div className="text-capitalize">{data?.role.name}</div>

              <span className="text-white badge badge-success">
                Role Base Acl
              </span>
            </div>
          </div>
        ),

        connected: (
          <div className="text-upper">{data?.connected ? 'TRUE' : 'FALSE'}</div>
        ),
        description: data?.description,
        privileges: (
          <div
            className="text-truncate "
            style={{
              maxWidth: '300px',
              maxHeight: '200px',
              overflowY: 'scroll',
            }}
          >
            {data?.privileges?.length > 0
              ? data?.privileges?.map((privileges) => (
                <div key={privileges.id}>
                  {' '}
                  <h1 className={`badge badge-soft-secondary font-size-12`}>
                    {' '}
                    {privileges.module} - {privileges.method}
                  </h1>{' '}
                </div>
              ))
              : 'No privileges restriction'}
          </div>
        ),

        date: (
          <div>
            <div>{Moment(data?.createdAt).format('l')}</div>
            <div className="badge badge-soft-secondary font-size-10">
              {' '}
              {Moment(data?.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
        action: (
          <div className='d-flex align-items-center'>
            <CheckAction field="Configurations" subModule="Access Control FE" iconType='Edit'>
              <Link
                to={`/access_control_form?id=${data?.id}`}
                className="mr-3 text-primary"
                id="edit1"
              >
                <i className="mdi mdi-pencil font-size-18"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="edit1">
                Edit
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field="Configurations" subModule="Access Control FE" iconType='Delete'>
              <Link
                to="#"
                onClick={() => deleteRole(data?.id)}
                className="text-danger mr-3"
                id="delete1"
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="delete1">
                Delete
              </UncontrolledTooltip>
            </CheckAction>
          </div>
        ),
      };
    });

    return {
      columns: [
        {
          text: 'User Id',
          dataField: 'id',
        },
        {
          text: 'Role Name',
          dataField: 'name',
          sort: true,
        },

        {
          text: 'Assigned To:',
          dataField: 'user',
          sort: true,
        },
        {
          text: 'Description',
          dataField: 'description',
        },
        {
          text: 'Created Date',
          dataField: 'date',
        },

        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-3">
              <>
                {!isLoading && data !== null ? (
                  <>
                    <div className="d-flex justify-content-between mb-2">
                      <div>
                        <Input
                          bsSize="sm"
                          id="take"
                          name="take"
                          type="select"
                          onChange={handleLimitChange}
                          value={take}
                        >
                          <option>10</option>
                          <option>25</option>
                          <option>50</option>
                        </Input>
                      </div>
                      <div>
                        <Input
                          bsSize="sm"
                          id="search"
                          value={search}
                          name="search"
                          onChange={SearchHandler}
                          placeholder="Search"
                        />
                      </div>
                    </div>
                    <BootstrapTable
                      keyField="keyId"
                      data={data?.rows}
                      columns={data?.columns}
                      striped
                      hover
                      wrapperClasses="table-responsive"
                      noDataIndication={'No Data to Display'}
                      defaultSorted={[{ dataField: 'index', order: 'desc' }]}
                    />
                    <ReactPaginate
                      previousLabel={'Previous'}
                      nextLabel={'Next'}
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      forcePage={skipPage}
                      containerClassName={'pagination justify-content-center'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      activeClassName={'active'}
                    />
                  </>
                ) : (
                  <Loader loading={isLoading} />
                )}
              </>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default RoleTable;
