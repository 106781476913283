import React, { useEffect, useState } from 'react'
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux'
import { Row, Col, Container, Alert } from 'reactstrap'
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import MiniWidgets from '../Components/MiniWidget'
import LoyaltyDetails from './loyaltyDetail'

import {
  fetchLoyalty,
  updateUserPoint,
  updateUserPointFailed,
} from '../../../../store/actions'

const Loyalty = ({
  loyalty,
  tableError,
  fetchLoyalty,
  history,
  loyaltyError,
  loading,
  message,
}) => {
  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'Loyalty', link: '/loyalty_history' },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Loyalty Preview"
            breadcrumbItems={breadcrumbItems}
          />

          <>
            {message && (
              <Alert color="success" className="text-center">
                {message}
              </Alert>
            )}
            {loyaltyError && (
              <Alert color="danger" className="text-center">
                {loyaltyError}
              </Alert>
            )}
            <Row>
              <Col>
                <Row>
                  <MiniWidgets />
                </Row>
                <LoyaltyDetails
                  tableError={tableError}
                  loyalty={loyalty}
                  loading
                  fetchLoyalty={fetchLoyalty}
                  history={history}
                />
              </Col>
            </Row>
          </>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const {
    loyalty,
    loading,
    loyaltyError,
    pointError,
    message,
    tableError,
  } = state.Loyalty
  return { loyalty, loading, loyaltyError, pointError, message, tableError }
}

export default connect(mapStateToProps, {
  fetchLoyalty,
  updateUserPoint,
  updateUserPointFailed,
})(Loyalty)
