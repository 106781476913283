import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Form from './form';

import { fetchEachRole, fetchPrivileges } from '../../../../store/actions';

const RoleForm = ({
  fetchEachRole,
  role,
  fetchPrivileges,
  privileges,
  loading,
  message,
}) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  useEffect(() => {
    if (id) {
      fetchEachRole(id);
    }
    fetchPrivileges();
  }, [id]);

  return (
    <React.Fragment>
      <div className='page-content' style={{ overflow: 'auto' }}>
        <Form role={role} message={message} privileges={privileges} />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { role, privileges, loading, roleError, message } = state.Role;
  return { role, privileges, loading, roleError, message };
};

export default connect(mapStateToProps, { fetchEachRole, fetchPrivileges })(
  RoleForm
);
