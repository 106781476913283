import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  UncontrolledTooltip,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import CustomSelect from "../../../../../components/Common/MoreDropdown/CustomSelect";
import AutoComplete from "../../../../../components/Common/Autocomplete";
import data from "../../../../../components/Common/modules/data.json";
import { ScrollToTop } from "../../../../../utils/general";
import {
  unassignRole,
  assignRole,
  assignRoleFailed,
  fetchUserDetails,
  fetchUserDetailsError,
  fetchRoles,
} from "../../../../../store/actions";
const Form = ({
  message,
  updateValue,
  createHandler,
  history,
  accessControlFEError,
  createAccessControlFEError,
  loading,
  role,
  fetchRoles,
  roles,
}) => {
  const [isLoading, setLoading] = useState(false);

  const [check, setCheck] = useState("");
  const [defaultValues, setDefaultValues] = useState(null);
  const [description, setDescription] = useState("");
  const [roleOption, SetRoleOption] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [errorMessage, setError] = useState("");
  useEffect(() => {
    if (updateValue) {
      let result = {
        ...updateValue.data.modules,
        firstName: updateValue.user.firstName,
        lastName: updateValue.user.lastName,
        userId: updateValue.user.id,
      };
      if (updateValue?.role) {
        result.roleId = updateValue.role.id;
      }

      result.name = updateValue.name;
      setDescription(updateValue.description);
      setDefaultValues(result);
    } else {
      setDefaultValues({});
    }
  }, [updateValue]);

  useEffect(() => {
    if (accessControlFEError) {
      setTimeout(() => {
        createAccessControlFEError("");
      }, 3000);
    }
    if (message) {
        setTimeout(() => {
        createAccessControlFEError("");
          history.push("/access_control_fe");
        }, 3000);
    }
  }, [message, accessControlFEError]);

  useEffect(() => {
    if (roles) {
      const roleArr = [];
      roles.result.forEach((element) => {
        const roleObj = {};
        roleObj.value = element.id;
        roleObj.label = element.name;
        roleArr.push(roleObj);
      });
      SetRoleOption(roleArr);
    }
  }, [roles]);

  useEffect(() => {
    assignRoleFailed("");
    fetchRoles();
    fetchUserDetailsError("");
    setSelected([]);
  }, []);


  const handleFormSubmit = (_event, value) => {
    setLoading(true);
    if (!updateValue) {
      if (!selectedUser && !selected) {
        setError(`Please atleast a user or a role to assign acl to!`);
        ScrollToTop();
        setLoading(false);
        return;
      }
    }

    let userId = updateValue?.userId;
    if (selectedUser) {
      userId = selectedUser.value;
    }
    let formData = {
      userId: userId,
      name: value.name,
      description: description,
      data: {
        modules: value,
      },
    };

    if (selected) {
      formData.roleId = selected.value;
    }
    if (updateValue) {
      // Update Module
      if (updateValue.roleId) {
        formData.roleId = updateValue.roleId;
      }
      createHandler(formData, updateValue?.id);
    } else {
      createHandler(formData);
    }
    ScrollToTop();
    setLoading(false);
  };

  return (
    <Card className="col-md-12 bg-light">
      <CardBody>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        {accessControlFEError && (
          <Alert color="danger" className="text-center">
            {accessControlFEError}
          </Alert>
        )}

        {errorMessage && (
          <Alert color="danger" className="text-center">
            {errorMessage}
          </Alert>
        )}

        <div className="float-right">
          <Link to="#" onClick={() => history.goBack()}>
            <i className="fas fa-arrow-left mr-3" />
            Back
          </Link>
        </div>
        <div className="mb-3">
          <h5 className="text-capitalize">
            {updateValue ? (
              defaultValues !== null ? (
                <>Update {defaultValues?.name} Role</>
              ) : (
                "Loading ..."
              )
            ) : (
              "Create Role"
            )}
          </h5>
        </div>
        {defaultValues !== null && (
          <div>
            <AvForm
              className="form-horizontal"
              onValidSubmit={handleFormSubmit}
              model={defaultValues}
            >
              <div
                style={{
                  height: "500px",
                  overflow: "auto",
                  width: "fit-content",
                }}
              >
                <Row className="mb-3">
                  <Col sm={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="name">Role Name </Label>
                      <AvField
                        name="name"
                        type="text"
                        className="form-control bg-light"
                        required
                        errorMessage="please enter name of the role"
                        placeholder="Enter Role Name"
                        value={`${role ? role?.name : ""}`}
                      />
                    </FormGroup>
                    {!updateValue && (
                      <>
                        <BootstrapSwitchButton
                          checked={check}
                          onlabel="User"
                          offlabel="Role"
                          onstyle="danger"
                          offstyle="success"
                          onChange={(e) => setCheck(e)}
                          width={100}
                          // size="sm"
                          style={"mb-2"}
                          // eslint-disable-next-line react/style-prop-object
                        />
                        {check ? (
                          <>
                            {" "}
                            <FormGroup>
                              <div className="d-flex align-items-center mb-1">
                                <Label htmlFor="role" className="m-0">
                                  Role
                                </Label>
                                <i
                                  className="mdi mdi-alert-circle-outline ml-2 font-size-16"
                                  id="priorityTooltip"
                                ></i>
                                <UncontrolledTooltip
                                  placement="top"
                                  target="priorityTooltip"
                                >
                                  Roles selected in the box are assign
                                </UncontrolledTooltip>
                              </div>

                              <div className="CustomSelect">
                                <Select
                                  name="packageType"
                                  value={selected}
                                  options={roleOption}
                                  // onInputChange={(e) => setPackage(e)}
                                  onChange={setSelected}
                                  required={true}
                                  // placeholder={`Please type the owner's name`}
                                />
                              </div>
                            </FormGroup>
                          </>
                        ) : (
                          <>
                            <FormGroup className="mb-2">
                              <AutoComplete
                                setUser={(e) => setSelectedUser(e)}
                              />
                            </FormGroup>
                          </>
                        )}

                        <FormGroup className="mb-4">
                          <Label htmlFor="description"> Description</Label>
                          <textarea
                            className="form-control"
                            onChange={(e) => setDescription(e.target.value)}
                            value={description}
                            id="description"
                            rows="4"
                          ></textarea>
                        </FormGroup>
                      </>
                    )}
                  </Col>
                </Row>
                <Card
                  className="bg-success text-white"
                  style={{
                    // background: '#ebf3f1',
                    position: "sticky",
                    top: "0",
                    zIndex: "99",
                  }}
                >
                  <CardBody>
                    <Row>
                      <Col xs={3}>
                        <Label className="font-size-12">Module List </Label>
                      </Col>
                      <Col xs={4}>
                        <Row>
                          <Col>
                            <Label className="font-size-12">Visible </Label>
                          </Col>
                          <Col>
                            <Label className="font-size-12">Hidden </Label>
                          </Col>
                          <Col>
                            <Label className="font-size-12">Grey </Label>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={4}>
                        <Row>
                          <Col>
                            <Label className="font-size-12">No_Create </Label>
                          </Col>
                          <Col>
                            <Label className="font-size-12">No_Update </Label>
                          </Col>
                          <Col>
                            <Label className="font-size-12">No_Delete </Label>
                          </Col>
                          <Col>
                            <Label className="font-size-12">No_Preview </Label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Row className="mb-3">
                  {data.modules.map((val) => (
                    <Col xs={12} key={val.name}>
                      <div key={val.name}>
                        <Card>
                          <CardBody className="pb-0">
                            {val?.subModule ? (
                              <div>
                                <Label className="font-size-20 mb-">
                                  {val.name}
                                </Label>
                                {val?.subModule?.map((sub) => (
                                  <Col xs={12} key={sub.name}>
                                    <Row>
                                      <Col xs={3}>
                                        <Label className="text-secondary">
                                          {sub.name}
                                        </Label>
                                      </Col>
                                      <Col xs={4}>
                                        <AvRadioGroup
                                          inline
                                          name={`${val.name}.${sub.name}.subscribe`}
                                        >
                                          <Row>
                                            <Col xs={4}>
                                              <AvRadio value="ALLOWED" />
                                            </Col>
                                            <Col xs={4}>
                                              <AvRadio value="NOT_ALLOWED" />
                                            </Col>
                                            <Col xs={4}>
                                              <AvRadio value="GREY" />
                                            </Col>
                                          </Row>
                                        </AvRadioGroup>
                                      </Col>

                                      <Col xs={4}>
                                        <AvCheckboxGroup
                                          inline
                                          name={`${val.name}.${sub.name}.action`}
                                        >
                                          <Row>
                                            <Col xs={3}>
                                              <AvCheckbox value="Create" />
                                            </Col>

                                            <Col xs={3}>
                                              <AvCheckbox value="Edit" />
                                            </Col>

                                            <Col xs={3}>
                                              <AvCheckbox value="Delete" />
                                            </Col>
                                            <Col xs={3}>
                                              <AvCheckbox value="Read" />
                                            </Col>
                                          </Row>
                                        </AvCheckboxGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                ))}
                              </div>
                            ) : (
                              <Col xs={12}>
                                <Row>
                                  <Col xs={3}>
                                    <Label className="font-size-20 mb-">
                                      {val.name}
                                    </Label>
                                  </Col>

                                  <Col xs={4}>
                                    <AvRadioGroup
                                      inline
                                      name={`${val.name}.subscribe`}
                                    >
                                      <Row>
                                        <Col xs={4}>
                                          <AvRadio value="ALLOWED" />
                                        </Col>
                                        <Col xs={4}>
                                          <AvRadio value="NOT_ALLOWED" />
                                        </Col>
                                        <Col xs={4}>
                                          <AvRadio value="GREY" />
                                        </Col>
                                      </Row>
                                    </AvRadioGroup>
                                  </Col>

                                  <Col xs={4}>
                                    <AvCheckboxGroup
                                      inline
                                      name={`${val.name}.action`}
                                    >
                                      <Row>
                                        <Col xs={3}>
                                          <AvCheckbox value="Create" />
                                        </Col>

                                        <Col xs={3}>
                                          <AvCheckbox value="Edit" />
                                        </Col>

                                        <Col xs={3}>
                                          <AvCheckbox value="Delete" />
                                        </Col>
                                        <Col xs={3}>
                                          <AvCheckbox value="Read" />
                                        </Col>
                                      </Row>
                                    </AvCheckboxGroup>
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
              <div className="pt-2">
                <button
                  className="btn btn-success mr-2"
                  type="submit"
                  disabled={loading}
                >
                  {defaultValues ? "Update" : "Create"}
                </button>
                <Link to="#" onClick={() => history.goBack()}>
                  <button className="btn btn-secondary">Cancel</button>
                </Link>
              </div>
            </AvForm>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, accessControlFEError } = state.AppConfigs;
  const { roles } = state.Role;
  return { message, loading, accessControlFEError, roles };
};

export default connect(mapStateToProps, {
  unassignRole,
  assignRole,
  assignRoleFailed,
  fetchUserDetails,
  fetchUserDetailsError,
  fetchRoles,
})(withRouter(Form));
