import {
  FETCH_DOCTYPE,
  FETCH_DOCTYPE_SUCCESSFUL,
  FETCH_DOCTYPE_API_FAILED,
  FETCH_EACH_DOCTYPE,
  FETCH_EACH_DOCTYPE_SUCCESSFUL,
  FETCH_EACH_DOCTYPE_API_FAILED,
  DELETE_DOCTYPE,
  DELETE_DOCTYPE_SUCCESSFUL,
  DELETE_DOCTYPE_FAILED,
  CREATE_DOCTYPE,
  CREATE_DOCTYPE_SUCCESSFUL,
  CREATE_DOCTYPE_FAILED,
  UPDATE_DOCTYPE,
  UPDATE_DOCTYPE_SUCCESSFUL,
  UPDATE_DOCTYPE_FAILED,
  INITIALIZE_DOCTYPE,
  INITIALIZE_DOCTYPE_SUCCESSFUL,
  INITIALIZE_DOCTYPE_FAILED,
  IS_DOCTYPE_AUTHORIZE,
} from './actionTypes';

const initialState = {
  loading: false,
  docType: null,
  docTypeError: null,
  isAuthorize: null,
  docTypePreview: null,
  message: null,
  initDoc: null,
  initDocError: null,
};

const DocType = (state = initialState, action) => {
  switch (action.type) {
    case IS_DOCTYPE_AUTHORIZE:
      state = {
        ...state,
        isAuthorize: action.payload,
      };
      break;

    case UPDATE_DOCTYPE:
    case DELETE_DOCTYPE:
      state = {
        ...state,
        docType: null,
        docTypeError: null,
        loading: true,
        message: null,
      };
      break;

    case INITIALIZE_DOCTYPE:
      state = {
        ...state,
        initDoc: null,
        initDocError: null,
      };
      break;

    case FETCH_DOCTYPE:
      state = {
        ...state,
        docType: null,
        isAuthorize: null,
        loading: true,
      };
      break;

    case FETCH_DOCTYPE_SUCCESSFUL:
      state = {
        ...state,
        docType: action.payload,
        docTypeError: null,
        loading: false,
      };
      break;

    case INITIALIZE_DOCTYPE_SUCCESSFUL:
      state = {
        ...state,
        initDoc: action.payload,
        initDocError: null,
      };
      break;

    case INITIALIZE_DOCTYPE_FAILED:
      state = {
        ...state,
        initDoc: null,
        initDocError: action.payload.constructor === String
          ? action.payload
          : 'Error in processing data',
      };
      break;


    case DELETE_DOCTYPE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        docTypeError: null,
        message: 'Document Type deleted successfully',
      };
      break;

    case UPDATE_DOCTYPE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        docTypeError: null,
        message: 'Document Type Updated Successfully',
      };
      break;

    case FETCH_EACH_DOCTYPE:
      state = {
        ...state,
        loading: true,
        docType: null,
        docTypeError: null,
        message: null,
      };
      break;

    case FETCH_EACH_DOCTYPE_SUCCESSFUL:
      state = {
        ...state,
        docTypePreview: action.payload,
        loading: false,
        docTypeError: null,
        message: null,
      };
      break;

    case CREATE_DOCTYPE:
      state = {
        ...state,
        loading: true,
        docTypeError: null,
        message: null,
        docTypeId: null
      };
      break;

    case CREATE_DOCTYPE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        docTypeError: null,
        message: `${action.payload.name}  created successfully`,
        docTypeId: action.payload.id,
      };
      break;

    case CREATE_DOCTYPE_FAILED:
    case FETCH_DOCTYPE_API_FAILED:
    case DELETE_DOCTYPE_FAILED:
    case FETCH_EACH_DOCTYPE_API_FAILED:
      state = {
        ...state,
        docType: null,
        docTypePreview: null,
        loading: false,
        message: null,
        docTypeError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_DOCTYPE_FAILED:
      state = {
        ...state,
        loading: false,
        docTypeError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DocType;
