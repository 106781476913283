import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  CardBody,
  Row,
  Input,
  FormGroup,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Button,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useLocation } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';
import TimerCountDown from './Components/TimerCountDown';
import FilterDropDown from '../../../components/Common/FilterDropDown';
import CheckAction from '../../../components/Common/Summary/actionCheck';
import RefreshDropDown from '../../../components/Common/FilterDropDown/RefreshDropDown';
import SimpleBar from 'simplebar-react';
import Loader from '../../../components/Common/Loading/index.js';
import { QueryReallignment } from '../../../utils/general';
import queryString from 'query-string';
import Moment from 'moment';
import moment from 'moment-timezone';
import ProcessTimer from './Components/ProcessingTimer';
import { Link, withRouter } from 'react-router-dom';
import { ScrollToTop } from '../../../utils/general';

const time = new Date();
let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const TripsTable = ({
  rides,
  fetchRides,
  previewHandler,
  broadCastRide,
  history,
  deleteRide,
  dateFilter,
  fetchForCSVError,
  fetchDataForCSV,
  csv,
  csv_loading,
  ReadCheck,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [menu, setMenu] = useState(false);
  const [refreshTimer, setRefreshTimer] = useState(0);
  const [refreshMenu, setRefreshMenu] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [showCSVModal, setShowCSVModal] = useState(false);
  // const [user, setUser] = useState('');
  const [refreshTitle, setRefreshTitle] = useState(null);
  const [skipPage, setSkipPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [search, setSearch] = useState('');
  const [take, setLimit] = useState(10);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [status, setStatus] = useState('');
  const [startTimer, setStartTimer] = useState(0);

  const [date, setDate] = useState({
    start: '',
    end: '',
  });
  const url = useLocation().search;
  const user = new URLSearchParams(url).get('user');

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(rides?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      status,
      user,
      search,
      paymentMethod,
      start: date.start,
      end: date.end,
    };
    fetchRides(pageInfo);
    SetUrlWithParams();
  };

  const refreshHandler = (e) => {
    setRefreshTimer(e.target.value);
    setRefreshMenu(false);
    // console.log(e.target.value)
    if (e.target.value === '0') {
      setRefreshTitle(null);
    } else {
      setRefreshTitle(Number(e.target.value));
    }
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    let pageInfo;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }

    pageInfo = {
      skip,
      take,
      user,
      status,
      search,
      paymentMethod,
      start: date.start,
      end: date.end,
    };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchRides(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let pageInfo = {
      search,
      take,
      skip,
      status,
      start: date.start,
      end: date.end,
      user,
      paymentMethod,
    };
    fetchRides(pageInfo);
    SetUrlWithParams();
  };
  const handleCSV = (e, values) => {
    fetchDataForCSV({
      user,
      take: values.endRow ? values.endRow - values.startRow + 1 : rides?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
    });
  };
  const handleDateFilter = (e, values) => {
    dateFilter({ start: values.startDate, end: values.endDate });
    setDate({ start: values.startDate, end: values.endDate });
    setMenu(false);
    if (values.paymentMethod === 'ALL') {
      setPaymentMethod('');
    } else {
      setPaymentMethod(values.paymentMethod);
    }

    if (values.rideStatus !== 'All Trips') {
      setStatus(values.rideStatus);
    } else {
      setStatus('');
    }
    let query = {
      search,
      take,
      user,
      skip,
      status: values.rideStatus === 'All Trips' ? '' : values.rideStatus,
      paymentMethod: values.paymentMethod === 'ALL' ? '' : values.paymentMethod,
      start: values.startDate,
      end: values.endDate,
    };

    SetUrlWithParams();
    fetchRides(query);
  };
  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    });
    dateFilter({});
    setStatus('');
    setPaymentMethod('');
    let queryParams = {
      search,
      take,
      skip,
      status: '',
      paymentMethod: '',
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    };
    fetchRides(queryParams);
  };
  useEffect(() => {
    if (rides) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(rides?.total / take));
    }
  }, [rides]);
  useEffect(() => {
    if (csv) {
      // SET CSV EXPORT DATA
      const csvData = csv?.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Driver: data?.driver
            ? `${data?.driver?.firstName} - ${data?.driver?.lastName}`
            : 'Not Assigned',
          Rider: `${data?.rider?.firstName} - ${data?.rider?.lastName}`,
          pickUp: data?.pickUp?.address,
          dropOff: data?.dropOff?.address,
          Payment_Method: data?.paymentMethod ? data.paymentMethod : '----',
          ridePackage: data?.ridePackage ? data.ridePackage.name : '----',
          'Distance(m)': data?.duration ? data.duration : '----',
          'Duration(s)': data?.duration ? data.duration : '----',
          'DurationInTraffic(s)': data?.durationInTraffic
            ? data.durationInTraffic
            : '----',
          'TotalAmount(₦)': data?.totalAmount ? data.totalAmount : '----',
          ArrivalTime: data?.arrivalTime
            ? data?.arrivalTime?.substring(11, 18)
            : '----',
          StartTime: data?.startTime
            ? data?.startTime?.substring(11, 18)
            : '----',
          EndTime: data?.endTime ? data?.endTime?.substring(11, 18) : '----',
          'BasePrice(₦)': data?.bill ? data?.bill.basePrice : '----',
          'DistancePrice(₦)': data?.bill ? data?.bill.distancePrice : '----',
          'TimePrice(₦)': data?.bill ? data?.bill.timePrice : '----',
          'ServiceCharge(₦)': data?.bill ? data?.bill.serviceCharge : '----',
          'Discount(₦)': data?.bill ? data?.bill.discount : '----',
          'SurgePrice(₦)': data?.bill ? data?.bill.surgePrice : '----',
          'WaitPrice(₦)': data?.bill ? data?.bill.waitPrice : '----',
          'Tax(₦)': data?.bill ? data?.bill.tax : '----',
          'Toll(₦)': data?.bill ? data?.bill.toll : '----',
          Status: data?.status,
          Cancelled_By:
            data?.cancellation?.reasonKey === 'SYSTEM_REQUEST'
              ? 'SYSTEM'
              : data?.cancellation?.requester,
          Created_Date: data?.createdAt?.substring(0, 10),
        };

        return formData;
      });

      setCsvData(csvData);
    }
  }, [csv]);

  useEffect(() => {
    if (!showCSVModal) {
      fetchForCSVError();
    }
  }, [showCSVModal]);

  useEffect(() => {
    let query = {
      search: search,
      take: take,
      user: user,
      skip: pageNumber,
      status: status,
      paymentMethod: paymentMethod,
      start: date.start,
      end: date.end,
    };
    let interval = setInterval(() => {
      let time = startTimer + 1;
      setStartTimer((prevState) => ({
        ...prevState,
        startTimer: time,
      }));

      fetchRides(query);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const queryParam = queryString.parse(history.location.search);

    let parsedUrlParams = updateStateWithUrlHandler();
    if (queryParam['user']) {
      console.log(queryParam['user']);
      parsedUrlParams.user = queryParam['user'];
    }
    fetchRides(parsedUrlParams);
  }, [fetchRides]);

  const SetUrlWithParams = () => {
    let queryParams = {
      take,
      skip: pageNumber,
      search,
      user,
      status: status,
      paymentMethod: paymentMethod,
      start: date.start,
      end: date.end,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        start: date.start,
        end: date.end,
        skip,
        take,
        status,
        paymentMethod,
        user,
        search,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.paymentMethod) {
      setPaymentMethod(parsedUrlParams?.paymentMethod);
    }
    if (parsedUrlParams?.user) {
      // setUser(parsedUrlParams?.user);
    }
    if (parsedUrlParams?.status) {
      setStatus(parsedUrlParams?.status);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    if (parsedUrlParams?.start) {
      setDate({
        start: parsedUrlParams.start,
        end: parsedUrlParams.end,
      });
    }
    return parsedUrlParams;
  };

  let accessChecking = ReadCheck(accessControl, `Rides`, 'History');
  const tableDataHandler = () => {
    const rowData = rides?.result?.map((data, i) => {
      return {
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,

        keyId: data.id,
        id: (
          <>
            {accessChecking ?
              <Link
                to={`/ride/${data.id}`}
                className='mr-3 text-dark font-weight-bold'
              >
                {data.id.substring(0, 7)}
              </Link>

              :
              <div className='mr-3 text-dark font-weight-bold'>
                {data?.id?.substring(0, 7)}
              </div>
            }
          </>
        ),
        date: (
          <div>
            {' '}
            <div>{Moment(data.createdAt).format('D/M/YYYY')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data.createdAt).format('hh:mm A')}
            </div>
            {data.status === 'PROCESSING' && (
              <ProcessTimer rideCreateTime={data.createdAt} />
            )}
          </div>
        ),
        driverName:
          data.driver !== null ? (
            <div>
              {data.status === 'COMPLETED' &&
                (data?.tripDetails?.distance / data?.distance) * 100 < 30 ? (
                <>
                  <div className='text-capitalize text-danger font-weight-bold'>
                    {data.driver.firstName} {data.driver.lastName}{' '}
                    <i className='fa fa-ban'></i>
                  </div>
                  <div className='d-flex align-items-center'>
                    <div className='mr-2'>
                      <img
                        src={
                          data.driver?.picture
                            ? data.driver?.picture
                            : avatarImg
                        }
                        alt=''
                        height='20'
                        className='rounded-circle avatar-xs'
                      />
                    </div>
                    <div>
                      <span className='text-muted ml-2 badge badge-soft-secondary'>
                        <i className=' fas fa-star text-warning mr-1' />
                        {data?.driver?.rating.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='text-capitalize'>
                    {data.driver.firstName} {data.driver.lastName}{' '}
                  </div>
                  <div className='d-flex align-items-center'>
                    <div className='mr-2'>
                      <img
                        src={
                          data.driver?.picture
                            ? data.driver?.picture
                            : avatarImg
                        }
                        alt=''
                        height='20'
                        className='rounded-circle avatar-xs'
                      />
                    </div>
                    <div>
                      <span className='text-muted ml-2 badge badge-soft-secondary'>
                        <i className=' fas fa-star text-warning mr-1' />
                        {data?.driver?.rating.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            'Not Assigned'
          ),
        userName: (
          <div className='mr-3 text-dark'>
            <div className='text-capitalize'>
              {data.rider.firstName} {data.rider.lastName}
            </div>
            <div className='d-flex align-items-center'>
              <div className='mr-2'>
                <img
                  src={data.rider?.picture ? data.rider?.picture : avatarImg}
                  alt=''
                  height='20'
                  className='rounded-circle avatar-xs'
                />
              </div>
              <div>
                <span className='text-muted ml-2 badge badge-soft-secondary'>
                  <i className=' fas fa-star text-warning mr-1' />
                  {data?.rider?.rating.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        ),
        status: (
          <div className='d-flex flex-column align-items-start'>
            <h1
              className={`badge badge-soft-${data.status === 'PROCESSING'
                ? 'warning'
                : data.status === 'ACCEPTED'
                  ? 'success'
                  : data.status === 'CANCELLED'
                    ? 'danger'
                    : data.status === 'COMPLETED'
                      ? 'primary'
                      : 'dark'
                } font-size-12`}
            >
              {data.status}
            </h1>
            {data.status === 'CANCELLED' && (
              <p
                className='badge badge-soft-secondary font-size-10 m-0'
                style={{
                  paddingTop: '4px',
                  background: `${data?.cancellation?.reasonKey === 'SYSTEM_REQUEST'
                    ? '#6c757d2e'
                    : data?.cancellation?.requester === 'RIDER'
                      ? '#f18357'
                      : data?.cancellation?.requester === 'ADMIN'
                        ? '#007bff'
                        : '#00a859'
                    }`,
                  color: `${data?.cancellation?.reasonKey !== 'SYSTEM_REQUEST'
                    ? 'white'
                    : 'black'
                    }`,
                }}
              >
                {data?.cancellation?.reasonKey === 'SYSTEM_REQUEST'
                  ? 'SYSTEM'
                  : data?.cancellation?.requester}
              </p>
            )}
          </div>
        ),
        paymentMethod:
          data.paymentMethod === 'ACCOUNT' ? (
            <span>
              <span className='d-block'>
                <i className='fas fa-wallet' /> NPAY{' '}
                {data.redemption && '/ PROMO'}
              </span>
              <div>
                <span className='badge badge-soft-success'>
                  ₦{data.totalAmount?.toLocaleString('en-US')}
                </span>
                {data.redemption && (
                  <>
                    -
                    <span className='badge badge-soft-danger'>
                      ₦{data.redemption?.value?.toLocaleString('en-US')}
                    </span>
                  </>
                )}
              </div>
            </span>
          ) : data.paymentMethod === 'CASH' ? (
            <span>
              <span className='d-block'>
                <i className='fas fa-money-bill-wave' /> {data.paymentMethod}
                {data.redemption && '/ PROMO'}
              </span>
              <div>
                <span className='badge badge-soft-success'>
                  ₦{data.totalAmount?.toLocaleString('en-US')}
                </span>
                {data.redemption && (
                  <>
                    -
                    <span className='badge badge-soft-danger'>
                      ₦{data.redemption?.value?.toLocaleString('en-US')}
                    </span>
                  </>
                )}
              </div>
            </span>
          ) : (
            <span>
              <span className='d-block'>
                <i className='fas fa-wallet' />
                {data.paymentMethod} {data.redemption && '/ PROMO'}
              </span>
              <div>
                <span className='badge badge-soft-success'>
                  ₦{data.totalAmount?.toLocaleString('en-US')}
                </span>
                {data.redemption && (
                  <>
                    -
                    <span className='badge badge-soft-danger'>
                      ₦{data.redemption?.value?.toLocaleString('en-US')}
                    </span>
                  </>
                )}
              </div>
            </span>
          ),
        packageType: <span> {data.ridePackage.name} </span>,
        action: (
          <div className='d-flex align-items-center'>
            <CheckAction field='Rides' subModule='History' iconType='Edit'>
              <Link
                to={`/ride_form?rideId=${data.id}`}
                className='mr-3 text-primary'
                id='edit1'
                style={
                  data.status === 'CANCELLED' || data.status === 'COMPLETED'
                    ? { display: 'none' }
                    : null
                }
              >
                <i className='mdi mdi-pencil font-size-16'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='edit1'>
                Edit
              </UncontrolledTooltip>
            </CheckAction>
            {data.status === 'PROCESSING' && (
              <CheckAction field='Rides' subModule='History' iconType='Edit'>
                <Link
                  to='#'
                  onClick={() => broadCastRide(data.id)}
                  className='mr-3 text-primary'
                  id='broadcast'
                >
                  <i className='ri-broadcast-fill font-size-16'></i>
                </Link>
                <UncontrolledTooltip placement='top' target='broadcast'>
                  Broadcast to Drivers
                </UncontrolledTooltip>
              </CheckAction>
            )}
            <>
              <CheckAction field='Rides' subModule='History' iconType='Edit'>
                <Link
                  to='#'
                  className='mr-3 text-danger'
                  id='cancelled'
                  style={data.status === 'CANCELLED' ? { display: 'none' } : null}
                  onClick={() => previewHandler(data.id)}
                >
                  <i className='fas fa-times p-2 font-size-16 mt-2'></i>
                </Link>
                <UncontrolledTooltip placement='top' target='cancelled'>
                  Cancel Ride
                </UncontrolledTooltip>
              </CheckAction>
            </>
            <CheckAction field='Rides' subModule='History' iconType='Delete'>
              <Link
                to='#'
                onClick={() => deleteRide(data.id)}
                className='mr-3 text-danger'
                id='delete1'
              >
                <i className='mdi mdi-trash-can font-size-16'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='delete1'>
                Delete
              </UncontrolledTooltip>
            </CheckAction>
            {data.status === 'CANCELLED' || data.status === 'COMPLETED' ? (
              <>
                <CheckAction field='Rides' subModule='History' iconType='Edit'>
                  <Link
                    to={`/ride_form?rebooking=${data.id}`}
                    // onClick={() => deleteRide(data.id)}
                    className='text-primary'
                    id='rebook'
                  >
                    <i className='mdi mdi-autorenew font-size-16'></i>
                  </Link>
                  <UncontrolledTooltip placement='top' target='rebook'>
                    Rebook Ride
                  </UncontrolledTooltip>
                </CheckAction>
              </>
            ) : null}
          </div>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
        },
        {
          text: 'Ride Id',
          dataField: 'id',
          sort: true,
        },
        {
          text: 'Rider',
          dataField: 'userName',
        },
        {
          text: 'Captains',
          dataField: 'driverName',
        },
        {
          text: 'Payment',
          dataField: 'paymentMethod',
        },
        {
          text: 'Date',
          dataField: 'date',
        },
        {
          text: 'Package Type',
          dataField: 'packageType',
        },
        {
          text: 'Trip Status',
          dataField: 'status',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  // if (rides?.result[1].createdAt) {
  //   let newDate = new Date().getTime()
  //   let createdATDate = new Date(rides?.result[0].createdAt)
  //   let result = newDate - createdATDate
  //   console.log(Math.floor((result / 3600000) % 60))
  //   console.log(Math.floor((result / 60000) % 60))
  //   console.log(Math.floor((result / 1000) % 60))
  // }
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <div>
                <div className='d-flex justify-content-between align-items-top'>
                  <h4> Rides History </h4>
                  <div className='d-flex align-items-center'>
                    <TimerCountDown startTimer={startTimer} />
                    {/* <RefreshDropDown
                      menu={refreshMenu}
                      title={refreshTitle}
                      toggleMenu={() => setRefreshMenu(!refreshMenu)}
                    >
                      <div className="p-4">
                        <Input
                          bsSize="sm"
                          id="refresh"
                          name="refresh"
                          type="select"
                          value={refreshTimer}
                          onChange={refreshHandler}
                        >
                          <option value={0}>0 min</option>
                          <option value={1}>1 min</option>
                          <option value={2}>2 mins</option>
                          <option value={5}>5 mins</option>
                        </Input>
                      </div>
                    </RefreshDropDown> */}
                    <div className=''>
                      <Button
                        size='sm'
                        color='primary'
                        className='mt-1'
                        onClick={() => setShowCSVModal(true)}
                        disabled={rides?.total > 0 ? false : true}
                      >
                        <i className=' fas fa-cloud-download-alt mr-1' />
                        CSV
                      </Button>
                    </div>
                    <FilterDropDown
                      menu={menu}
                      toggleMenu={() => setMenu(!menu)}
                    >
                      <div>
                        <AvForm
                          className='form-horizontal'
                          onValidSubmit={handleDateFilter}
                        >
                          <SimpleBar
                            style={{
                              maxHeight: '250px',
                            }}
                          >
                            <Row className='px-3  pt-3 pb-0'>
                              <Col md='12'>
                                <FormGroup>
                                  <AvField
                                    name='startDate'
                                    type='date'
                                    className='form-control bg-light'
                                    id='startDate'
                                    value={date.start}
                                    helpMessage='Start Date'
                                    placeholder='Please choose the start date...'
                                  />
                                </FormGroup>
                              </Col>
                              <Col md='12'>
                                <FormGroup>
                                  <AvField
                                    name='endDate'
                                    type='date'
                                    className='form-control bg-light'
                                    id='endDate'
                                    value={date.end}
                                    helpMessage='End Date'
                                    placeholder='Please choose the end date...'
                                  />
                                </FormGroup>
                              </Col>
                              <Col md='12'>
                                <FormGroup>
                                  <AvField
                                    type='select'
                                    value={paymentMethod}
                                    name='paymentMethod'
                                    helpMessage='Payment Method'
                                  >
                                    <option>ALL</option>
                                    <option>CASH</option>
                                    <option>CARD</option>
                                    <option>NPAY</option>
                                    <option>ACCOUNT</option>
                                  </AvField>
                                </FormGroup>
                              </Col>

                              <Col md='12'>
                                <FormGroup>
                                  <AvField
                                    type='select'
                                    value={status}
                                    name='rideStatus'
                                    helpMessage='status'
                                  >
                                    <option>All Trips</option>
                                    <option value='PROCESSING'>
                                      PROCESSING
                                    </option>
                                    <option value='ACCEPTED'>ACCEPTED</option>
                                    <option value='APPROACHING'>
                                      APPROACHING
                                    </option>
                                    <option value='ARRIVED'>ARRIVED</option>
                                    <option value='INROUTE'>INROUTE</option>
                                    <option value='CANCELLED'>CANCELLED</option>
                                    <option value='COMPLETED'>COMPLETED</option>
                                  </AvField>
                                </FormGroup>
                              </Col>
                            </Row>
                          </SimpleBar>

                          <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                            <Link
                              to='#'
                              onClick={resetHandler}
                              className='btn btn-sm  bg-light font-size-14'
                            >
                              <i className=' mdi mdi-refresh mr-1'> Reset</i>
                            </Link>

                            <button
                              className='btn btn-success btn-sm mr-2'
                              type='submit'
                            >
                              <i className='mdi mdi-arrow-right-circle mr-1'></i>
                              Send
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </FilterDropDown>
                  </div>
                </div>
              </div>
              {!isLoading && data !== null ? (
                <>
                  <div className='d-flex justify-content-between mb-2'>
                    <div>
                      <Input
                        bsSize='sm'
                        id='take'
                        name='take'
                        type='select'
                        onChange={handleLimitChange}
                      >
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>
                    <div>
                      <Input
                        bsSize='sm'
                        id='search'
                        value={search}
                        name='search'
                        onChange={SearchHandler}
                        placeholder='Search'
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField='keyId'
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses='table-responsive'
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    forcePage={skipPage}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    //Bootstrap styling
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* CSV DOWNLOAD MODAL */}
      <Modal
        isOpen={showCSVModal}
        toggle={() => setShowCSVModal(!showCSVModal)}
      >
        <ModalHeader toggle={() => setShowCSVModal(false)}>
          Generate CSV
        </ModalHeader>
        <ModalBody>
          <AvForm className='form-horizontal' onValidSubmit={handleCSV}>
            <Col>
              <FormGroup>
                <Label htmlFor='startRow'>Start (Row)</Label>
                <AvField
                  name='startRow'
                  type='number'
                  max={rides?.total - 1}
                  className='form-control'
                  id='startRow'
                  placeholder='Enter the row to start from'
                  errorMessage='value must be greater than 0 and less than total data'
                  min='1'
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor='endRow'>End (Row)</Label>
                <AvField
                  name='endRow'
                  type='number'
                  className='form-control'
                  min='1'
                  max={rides?.total}
                  id='endRow'
                  placeholder='Enter the row to end'
                  helpMessage={
                    rides?.total ? `Total data is ${rides?.total}` : null
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Button
              size='sm'
              color='primary'
              className='ml-3 waves-effect waves-light '
              type='submit'
            >
              {csv_loading ? 'Generating...' : 'Generate'}
            </Button>
            {csv && (
              <CSVLink
                data={csvData}
                filename={'rides_data.csv'}
                className='btn-sm btn-success ml-2'
                target='_blank'
                onClick={() => setShowCSVModal(false)}
              >
                Download
              </CSVLink>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(TripsTable);
