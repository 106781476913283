import {
  FETCH_PROMOCODE,
  FETCH_PROMOCODE_SUCCESSFUL,
  FETCH_PROMOCODE_API_FAILED,
  FETCH_PROMOHISTORY,
  FETCH_PROMOHISTORY_SUCCESSFUL,
  FETCH_PROMOHISTORY_API_FAILED,
  FETCH_PROMOREDEEMHISTORY,
  FETCH_PROMOREDEEMHISTORY_SUCCESSFUL,
  FETCH_PROMOREDEEMHISTORY_API_FAILED,
  FETCH_EACH_PROMOCODE,
  FETCH_EACH_PROMOCODE_SUCCESSFUL,
  FETCH_EACH_PROMOCODE_API_FAILED,
  DELETE_PROMOCODE,
  DELETE_PROMOCODE_SUCCESSFUL,
  DELETE_PROMOCODE_FAILED,
  CREATE_PROMOCODE,
  CREATE_PROMOCODE_SUCCESSFUL,
  CREATE_PROMOCODE_FAILED,
  UPDATE_PROMOCODE,
  UPDATE_PROMOCODE_SUCCESSFUL,
  UPDATE_PROMOCODE_FAILED,
  FETCH_PROMOCODE_SUMMARY,
  FETCH_PROMOCODE_SUMMARY_SUCCESSFULLY,
  FETCH_PROMOCODE_SUMMARY_FAILED,
} from './actionTypes'

export const fetchPromoCode = (payload) => {
  return {
    type: FETCH_PROMOCODE,
    payload,
  }
}

export const fetchPromoHistory = (payload) => {
  return {
    type: FETCH_PROMOHISTORY,
    payload,
  }
}

export const fetchPromoRedeemHistory = (payload) => {
  return {
    type: FETCH_PROMOREDEEMHISTORY,
    payload,
  }
}

export const fetchPromoCodeSuccessful = (payload) => {
  return {
    type: FETCH_PROMOCODE_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchPromoHistorySuccessful = (payload) => {
  return {
    type: FETCH_PROMOHISTORY_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchPromoRedeemHistorySuccessful = (payload) => {
  return {
    type: FETCH_PROMOREDEEMHISTORY_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchPromoCodeError = (error) => {
  return {
    type: FETCH_PROMOCODE_API_FAILED,
    payload: error,
  }
}

export const fetchPromoHistoryError = (error) => {
  return {
    type: FETCH_PROMOHISTORY_API_FAILED,
    payload: error,
  }
}

export const fetchPromoRedeemHistoryError = (error) => {
  return {
    type: FETCH_PROMOREDEEMHISTORY_API_FAILED,
    payload: error,
  }
}

export const fetchEachPromoCode = (id) => {
  return {
    type: FETCH_EACH_PROMOCODE,
    payload: id,
  }
}

export const fetchEachPromoCodeSuccessful = (payload) => {
  return {
    type: FETCH_EACH_PROMOCODE_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchEachPromoCodeError = (error) => {
  return {
    type: FETCH_EACH_PROMOCODE_API_FAILED,
    payload: error,
  }
}

export const deletePromoCode = (id) => {
  return {
    type: DELETE_PROMOCODE,
    payload: id,
  }
}

export const deletePromoCodeSuccessful = (payload) => {
  return {
    type: DELETE_PROMOCODE_SUCCESSFUL,
    payload,
  }
}

export const deletePromoCodeFailed = (error) => {
  return {
    type: DELETE_PROMOCODE_FAILED,
    payload: error,
  }
}

export const createPromoCode = (payload) => {
  return {
    type: CREATE_PROMOCODE,
    payload,
  }
}

export const createPromoCodeSuccessful = (payload) => {
  return {
    type: CREATE_PROMOCODE_SUCCESSFUL,
    payload,
  }
}

export const createPromoCodeFailed = (error) => {
  return {
    type: CREATE_PROMOCODE_FAILED,
    payload: error,
  }
}

export const updatePromoCode = (payload, id) => {
  return {
    type: UPDATE_PROMOCODE,
    payload: { payload, id },
  }
}

export const updatePromoCodeFailed = (error) => {
  return {
    type: UPDATE_PROMOCODE_FAILED,
    payload: error,
  }
}

export const updatePromoCodeSuccessful = (payload) => {
  return {
    type: UPDATE_PROMOCODE_SUCCESSFUL,
    payload,
  }
}

//SUMMARY ENTRIES
export const fetchPromoCodeSummary = (query) => {
  return {
    type: FETCH_PROMOCODE_SUMMARY,
    payload: query,
  }
}

export const fetchPromoCodeSummarySuccessfully = (payload) => {
  return {
    type: FETCH_PROMOCODE_SUMMARY_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchPromoCodeSummaryFailed = (error) => {
  return {
    type: FETCH_PROMOCODE_SUMMARY_FAILED,
    payload: error,
  }
}
