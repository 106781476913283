/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Alert,
  FormGroup,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactPaginate from 'react-paginate';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import FilterDropDown from '../../../../components/Common/FilterDropDown';
import SimpleBar from 'simplebar-react';
import { useLocation, Link } from 'react-router-dom';
import Loader from '../../../../components/Common/Loading/index.js';
import { ScrollToTop } from '../../../../utils/general';
import Moment from 'moment';
import moment from 'moment-timezone';

let skip = 0;
let dataLimit = 10;

const LoyaltyDetails = ({
  tableError,
  loyalty,
  loading,
  loyaltyError,
  fetchLoyalty,
  history,
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState('');
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [status, setStatus] = useState('');
  const [menu, setMenu] = useState(false);
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });
  const getUrl = useLocation().search;
  const id = new URLSearchParams(getUrl).get('id');

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(loyalty?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      user,
      skip: pageNumber,
      take: dataLimit,
      search,
      start: date.start,
      end: date.end,
    };
    fetchLoyalty(pageInfo);
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = {
      user,
      skip,
      take,
      search,
      start: date.start,
      end: date.end,
    };
    fetchLoyalty(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      user,
      search,
      take,
      // skip,
      start: date.start,
      end: date.end,
    };
    fetchLoyalty(queryParams);
  };

  useEffect(() => {
    if (id) {
      setUser(id);
    }
    if (loyalty) {
      setData(tableDataHandler());
      setIsLoading(false);
      setPageCount(Math.ceil(loyalty?.total / take));
    }
  }, [loyalty, id]);

  useEffect(() => {
    let queryParams = {
      user: id,
      search,
      take,
      skip,
      start: date.start,
      end: date.end,
    };
    fetchLoyalty(queryParams);
  }, []);

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    let queryParams = {
      user,
      search,
      take,
      skip,
      type: values.status,
      start: values.startDate,
      end: values.endDate,
    };
    fetchLoyalty(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    });
    let queryParams = {
      search,
      take,
      skip,
      status,
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    };
    fetchLoyalty(queryParams);
  };

  const tableDataHandler = () => {
    const rowData = loyalty?.result?.map((data) => {
      return {
        id: data.id,
        point: (
          <span className='text-capitalize'>
            {data.point}
            <span className='d-block'>
              <span
                className={`badge badge-soft-${
                  data.type.toLowerCase() === 'credit' ? 'success' : 'danger'
                } rounded`}
              >
                {' '}
                {data.type}{' '}
              </span>
            </span>
          </span>
        ),
        pointsAfter: data.pointsAfter,
        createdAt: Moment(data.createdAt).format('l'),
        tierStartDate: Moment(data?.loyalty?.tierStartDate).format('l'),
        tier: data?.loyalty?.tier,
        sourceUserName: data.sourceUserName ? data.sourceUserName : '___',
      };
    });
    return {
      columns: [
        {
          text: 'Point Earned',
          dataField: 'point',
          sort: true,
        },
        {
          text: 'Earned On',
          dataField: 'createdAt',
          classes: 'text-capitalize',
        },
        {
          text: 'Balance',
          dataField: 'pointsAfter',
        },
        {
          text: 'Tier Start Date',
          dataField: 'tierStartDate',
        },
        {
          text: 'Current Tier',
          dataField: 'tier',
        },
        {
          text: 'Source User',
          dataField: 'sourceUserName',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          <Card>
            <CardBody className='pt-3'>
              <div className='mb-3'>
                <div className='float-right'>
                  <button
                    size='sm'
                    className='btn btn-sm btn-primary'
                    onClick={() => history.goBack()}
                  >
                    <i className='fas fa-arrow-left mr-3' />
                    Back
                  </button>
                </div>
                <h4>
                  {loyalty?.result[0]?.loyaltyUserName
                    ? `${loyalty?.result[0]?.loyaltyUserName}'s`
                    : 'Loyalty'}{' '}
                  History{' '}
                </h4>
              </div>
              {tableError !== null ? (
                <Alert color='danger' className='text-center'>
                  {tableError}
                </Alert>
              ) : (
                <>
                  {!isLoading && data !== null ? (
                    <>
                      <div className='d-flex justify-content-end mb-2'>
                        <FilterDropDown
                          menu={menu}
                          toggleMenu={() => setMenu(!menu)}
                        >
                          <div>
                            <AvForm
                              className='form-horizontal'
                              onValidSubmit={handleDateFilter}
                            >
                              <SimpleBar
                                style={{
                                  maxHeight: '250px',
                                }}
                              >
                                <Row className='px-3  pt-3 pb-0'>
                                  <Col md='12'>
                                    <FormGroup>
                                      <AvField
                                        name='startDate'
                                        type='date'
                                        className='form-control bg-light'
                                        id='startDate'
                                        value={date.start}
                                        helpMessage='Start Date'
                                        placeholder='Please choose the start date...'
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md='12'>
                                    <FormGroup>
                                      <AvField
                                        name='endDate'
                                        type='date'
                                        className='form-control bg-light'
                                        id='endDate'
                                        value={date.end}
                                        helpMessage='End Date'
                                        placeholder='Please choose the end date...'
                                        required
                                      />
                                    </FormGroup>
                                  </Col>{' '}
                                  <Col md='12'>
                                    <FormGroup>
                                      <AvField
                                        type='select'
                                        value={status}
                                        name='vehicleStatus'
                                        helpMessage='status'
                                      >
                                        <option value=''>All</option>
                                        <option value='CREDIT'>CREDIT</option>
                                        <option value='DEBIT'>DEBIT</option>
                                      </AvField>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </SimpleBar>

                              <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                                <Link
                                  to='#'
                                  onClick={resetHandler}
                                  className='btn btn-sm  bg-light font-size-14'
                                >
                                  <i className=' mdi mdi-refresh mr-1'>
                                    {' '}
                                    Reset
                                  </i>
                                </Link>

                                <button
                                  className='btn btn-success btn-sm mr-2'
                                  type='submit'
                                >
                                  <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                  Send
                                </button>
                              </div>
                            </AvForm>
                          </div>
                        </FilterDropDown>
                      </div>
                      <div className='d-flex justify-content-between my-2'>
                        <div>
                          <Input
                            bsSize='sm'
                            id='take'
                            name='take'
                            type='select'
                            onChange={handleLimitChange}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div className='d-flex'>
                          <Link
                            to={`#`}
                            onClick={() => setShowFilterModal(true)}
                            className='mr-3 text-dark'
                            id='filter'
                          >
                            <i className='mdi mdi-filter-variant font-size-22'></i>
                          </Link>
                          <UncontrolledTooltip placement='top' target='filter'>
                            Filter By Date
                          </UncontrolledTooltip>
                          <Input
                            bsSize='sm'
                            id='search'
                            value={search}
                            name='search'
                            onChange={SearchHandler}
                            placeholder='Search'
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField='id'
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses='table-responsive'
                        noDataIndication={'No Data to Display'}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />{' '}
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default LoyaltyDetails;
