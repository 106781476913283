import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  FormGroup,
  Label,
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import socket, { Socket } from '../../../../services/socketService';
import { getToken } from '../../../../firebase';

//Import Breadcrumb
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import CheckAction, { ReadCheck } from '../../../../components/Common/Summary/actionCheck';
import Location from '../../../../components/Common/Location';
import TripsTable from './Tripstable';

//Import Action to copy breadcrumb items from local state to redux state
import {
  fetchMyRide,
  fetchDataForCSV,
  fetchForCSVError,
  rideStatus,
  rideStatusFailed,
  deleteRide,
  connectDriver,
} from '../../../../store/actions';

const Ride = ({
  connectDriver,
  connectRes,
  connectErr,
  rides,
  fetchMyRide,
  fetchDataForCSV,
  fetchForCSVError,
  csv,
  csv_loading,
  loading,
  rideStatus,
  rideStatusFailed,
  ridesError,
  message,
  isAuthorize,
  deleteRide,
  user,
  accessControl
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [status, setStatus] = useState(null);
  const [Error, setError] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [reasonForm, setReasonForm] = useState('');
  const location = Location();
  const [tokenFound, setTokenFound] = useState(false);
  const [fcmToken, setToken] = useState('');
  const [check, setCheck] = useState({ autocheck: false, check: '' });
  const [authToken, setAuthToken] = useState([]);
  const [driverStatus, setRes] = useState('');
  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'Rides', link: '#' },
  ];

  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };

  const statusIdHandler = (id, e) => {
    setShowStatusModal(true);
    setPreviewId(id);
    setStatus(e);
  };

  getToken(setTokenFound, setToken);

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem('authTokens'));
    if (authToken) {
      setAuthToken(authToken);
    }
  }, []);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        rideStatusFailed('');
        setShowModal(false);
        setShowStatusModal(false);
        setShowAcceptModal(false);
        fetchMyRide();
      }, 2000);
    }
  }, [message, rideStatusFailed, setShowAcceptModal, fetchMyRide]);

  useEffect(() => {
    const vehicle = user?.vehicles?.find(
      (vehicle) => vehicle.isDeleted === false
    );
    if (user.userType === 'DRIVER' && !vehicle) {
      setStatusError('You do not have a vehicle ');
    }
    if (user.userType === 'DRIVER' && vehicle?.status !== 'APPROVED') {
      setStatusError('Your vehicle is yet to be approved ');
    }
    rideStatusFailed('');
  }, []);

  const handleCancellation = (_event) => {
    setError(false);
    if (!reasonForm) {
      setError(true);
      return;
    }
    const rideData = { id: previewId, reason: reasonForm, status: 'cancel' };
    rideStatus(rideData);
  };

  const handleStatusUpdate = (event, values) => {
    setStatusError(null);
    const Status =
      values.Status === 'APPROACHING'
        ? 'approaching'
        : values.Status === 'ACCEPTED'
          ? 'accept'
          : values.Status === 'INROUTE'
            ? 'start'
            : values.Status === 'ARRIVED'
              ? 'arrived'
              : values.Status === 'COMPLETED'
                ? 'end'
                : values.Status;
    if (Status === 'undefined') {
      setStatusError('Please change the status');
      return;
    }
    if (!previewId && values.accept === '') {
      setStatusError('Please key in the ride id');
      return;
    }
    if (location.loaded) {
      if (location.err) {
        setStatusError(
          location.error.message === 'User denied Geolocation'
            ? 'Please turn on location and relaod the page'
            : 'Location error'
        );
        return;
      } else {
        let rideData;
        if (previewId) {
          rideData = {
            id: previewId,
            lat: location.coordinates.lat,
            lng: location.coordinates.lng,
            status: Status,
          };
        } else {
          rideData = {
            id: values.accept,
            lat: location.coordinates.lat,
            lng: location.coordinates.lng,
            status: 'accept',
          };
        }

        rideStatus(rideData);
      }
    } else {
      setStatusError('Location data not available yet.');
    }
  };

  useEffect(() => {
    if (user?.userType === 'DRIVER') {
      socket.emit('driver:connect', {
        token: authToken.accessToken,
        body: {},
      });
      socket.on('driver:connect::response', (response) => {
        setRes(response?.result?.status);
      });
      if (driverStatus === 'AVAILABLE') {
        setCheck({ ...check, autocheck: true });
      }
    }
  }, [authToken.accessToken, driverStatus, socket]);

  useEffect(() => {
    if (user?.userType === 'DRIVER') {
      if (tokenFound && check.check === true) {
        if (location.loaded) {
          if (location.err) {
            setStatusError(
              location.error.message === 'User denied Geolocation'
                ? 'Status Not Updated: Please turn on location and relaod the page'
                : 'Status Not Updated: Location error'
            );
            return;
          } else {
            Socket('driver:connect', {
              token: authToken.accessToken,
              body: {
                status: 'AVAILABLE',
                lat: location.coordinates.lat,
                lng: location.coordinates.lng,
                fcmToken,
                radius: 5000,
              },
            });
            connectDriver({
              status: 'AVAILABLE',
              lat: location.coordinates.lat,
              lng: location.coordinates.lng,
              fcmToken,
              radius: 5000,
            });
          }
        } else {
          setStatusError('Location data not available yet.');
        }
      }
      if (tokenFound && check.check === false) {
        if (location.loaded) {
          if (location.err) {
            setStatusError(
              location.error.message === 'User denied Geolocation'
                ? 'Status Not Updated: Please turn on location and relaod the page'
                : 'Status Not Updated: Location error'
            );
            return;
          } else {
            Socket('driver:connect', {
              token: authToken.accessToken,
              body: {
                status: 'OFFLINE',
                lat: location.coordinates.lat,
                lng: location.coordinates.lng,
                fcmToken,
                radius: 5000,
              },
            });
            connectDriver({
              status: 'OFFLINE',
              lat: location.coordinates.lat,
              lng: location.coordinates.lng,
              fcmToken,
              radius: 5000,
            });
          }
        } else {
          setStatusError('Location data not available yet.');
        }
      }
    }
  }, [check, fcmToken, location.loaded, tokenFound]);

  useEffect(() => {
    socket.on('driver:ride-cancel', (response) => {
      if (response) {
        fetchMyRide();
      }
    });
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Rides' breadcrumbItems={breadcrumbItems} />

          <>


            <div className='mb-1 text-right'>
              <div className='d-flex align-items-center'>
                <CheckAction field='Rides' subModule='History' iconType={'Create'}>
                  {user && user.userType === 'RIDER' && (
                    <Link to='/ride_form'>
                      <Button color='success' className='ml-3'>
                        <i className='mdi mdi-plus-circle-outline font-size-14 mr-2'></i>
                        Create Ride
                      </Button>
                    </Link>
                  )}
                </CheckAction>

                {user && user.userType === 'DRIVER' && (
                  <div className='d-flex justify-content-end mb-3'>
                    <CheckAction field='Rides' subModule='History' iconType={'Edit'}>
                      <BootstrapSwitchButton
                        checked={check.autocheck || check.check}
                        onlabel='Available'
                        offlabel='Not Available'
                        onstyle='success'
                        offstyle='danger'
                        onChange={(e) => setCheck({ autocheck: false, check: e })}
                        width={check === true ? 100 : 130}
                        // eslint-disable-next-line react/style-prop-object
                        style={'mr-3'}
                      />


                      <Button
                        color='success'
                        onClick={() => setShowAcceptModal(true)}
                      >
                        Accept ride
                      </Button>
                    </CheckAction>
                  </div>
                )}
              </div>
            </div>

            {message && (
              <Alert color='success' className='text-center'>
                {message}
              </Alert>
            )}
            {ridesError && (
              <Alert color='danger' className='text-center'>
                {ridesError}
              </Alert>
            )}
            {statusError && (
              <Alert color='danger' className='text-center'>
                {statusError}
              </Alert>
            )}
            <Row>
              <Col>
                <TripsTable
                  rides={rides}
                  loading={loading}
                  fetchMyRide={fetchMyRide}
                  previewHandler={previewHandler}
                  fetchDataForCSV={fetchDataForCSV}
                  fetchForCSVError={fetchForCSVError}
                  csv={csv}
                  csv_loading={csv_loading}
                  statusIdHandler={statusIdHandler}
                  deleteRide={deleteRide}
                  user={user}
                  connectDriver={connectDriver}
                  connectRes={connectRes}
                  connectErr={connectErr}
                  CheckAction={CheckAction}
                  accessControl={accessControl}
                  ReadCheck={ReadCheck}
                />
              </Col>
            </Row>
            <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
              <ModalHeader toggle={() => setShowModal(false)}>
                Cancel Ride
              </ModalHeader>
              <ModalBody>
                {message && (
                  <Alert color='success' className='text-center'>
                    {message}
                  </Alert>
                )}
                {Error && (
                  <Alert className='text-center' color='danger'>
                    Please kindly state the reason for cancellation
                  </Alert>
                )}
                <div>
                  <AvForm
                    className='form-horizontal'
                    onValidSubmit={handleCancellation}
                  >
                    <Row>
                      <Col md='9'>
                        <FormGroup className='mb-4'>
                          <Label htmlFor='reasonForm'>
                            Reason For Cancellation
                          </Label>
                          <textarea
                            className='form-control'
                            onChange={(e) => setReasonForm(e.target.value)}
                            value={reasonForm}
                            id='reasonForm'
                            rows='3'
                          ></textarea>
                        </FormGroup>
                      </Col>
                    </Row>

                    <button className='btn btn-success mr-2' type='submit'>
                      {loading ? 'Submitting ...' : 'Submit'}
                    </button>
                    <Link to='#' onClick={() => setShowModal(false)}>
                      <button className='btn btn-secondary'>Close</button>
                    </Link>
                  </AvForm>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={showStatusModal}
              toggle={() => setShowStatusModal(!showStatusModal)}
            >
              <ModalHeader toggle={() => setShowStatusModal(false)}>
                Change Status
              </ModalHeader>
              <ModalBody>
                {message && (
                  <Alert color='success' className='text-center'>
                    {message}
                  </Alert>
                )}
                {statusError && (
                  <Alert className='text-center' color='danger'>
                    {statusError}
                  </Alert>
                )}
                {ridesError && (
                  <Alert color='danger' className='text-center'>
                    {ridesError}
                  </Alert>
                )}
                <div>
                  <AvForm
                    className='form-horizontal'
                    onValidSubmit={handleStatusUpdate}
                  >
                    <Row>
                      <Col md='9'>
                        <FormGroup className='mb-4'>
                          <AvField
                            className='form-control bg-light'
                            type='select'
                            name='Status'
                            label='Status'
                            value={
                              status === 'PROCESSING' || status === 'CANCELLED'
                                ? 'undefined'
                                : status
                            }
                            disabled={statusError ? true : false}
                          >
                            <option value={'undefined'}>Change Status</option>
                            <option value={'ACCEPTED'}>Accept</option>
                            <option value={'APPROACHING'}>Approaching</option>
                            <option value={'ARRIVED'}>Arrived</option>
                            <option value={'INROUTE'}>Start Trip</option>
                            <option value={'COMPLETED'}>End Trip</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>

                    <button className='btn btn-success mr-2' type='submit'>
                      {loading ? 'Submitting ...' : 'Submit'}
                    </button>
                    <Link to='#' onClick={() => setShowStatusModal(false)}>
                      <button className='btn btn-secondary'>Close</button>
                    </Link>
                  </AvForm>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={showAcceptModal}
              toggle={() => setShowAcceptModal(!showAcceptModal)}
            >
              <ModalHeader toggle={() => setShowAcceptModal(false)}>
                Accept Ride
              </ModalHeader>
              <ModalBody>
                {message && (
                  <Alert color='success' className='text-center'>
                    {message}
                  </Alert>
                )}
                {statusError && (
                  <Alert className='text-center' color='danger'>
                    {statusError}
                  </Alert>
                )}
                {ridesError && (
                  <Alert color='danger' className='text-center'>
                    {ridesError}
                  </Alert>
                )}
                <div>
                  <AvForm
                    className='form-horizontal'
                    onValidSubmit={handleStatusUpdate}
                  >
                    <Row>
                      <Col md='9'>
                        <FormGroup className='mb-4'>
                          <AvField
                            className='form-control bg-light'
                            type='text'
                            name='accept'
                            label='Please paste the ride ID'
                            value=''
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <button className='btn btn-success mr-2' type='submit'>
                      {loading ? 'Submitting ...' : 'Submit'}
                    </button>
                    <Link to='#' onClick={() => setShowAcceptModal(false)}>
                      <button className='btn btn-secondary'>Close</button>
                    </Link>
                  </AvForm>
                </div>
              </ModalBody>
            </Modal>
          </>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    connectRes,
    connectErr,
    rides,
    csv,
    csv_loading,
    loading,
    ridesError,
    message,
    isAuthorize,
  } = state.Rides;
  const { user, userError, accessControl } = state.Account;
  return {
    accessControl,
    csv,
    csv_loading,
    user,
    rides,
    loading,
    ridesError,
    message,
    isAuthorize,
    connectRes,
    connectErr,
  };
};

export default connect(mapStateToProps, {
  fetchMyRide,
  rideStatus,
  rideStatusFailed,
  deleteRide,
  fetchDataForCSV,
  fetchForCSVError,
  connectDriver,
})(Ride);
