import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  FETCH_APP_CONFIGS,
  FETCH_EACH_APP_CONFIGS,
  CREATE_APP_CONFIGS,
  UPDATE_APP_CONFIGS,
  UPDATE_APP_CONFIG_ITEM,
  DELETE_APP_CONFIGS,
  DELETE_APP_CONFIG_ITEM,
  CREATE_ACCESS_CONTROL_FE,
  UPDATE_ACCESS_CONTROL_FE,
  LIST_ACCESS_CONTROL_FE,
  DELETE_ACCESS_CONTROL_FE,
  LIST_EACH_ACCESS_CONTROL_FE,
} from './actionTypes';
import {
  fetchAppConfigsSuccessful,
  fetchAppConfigsError,
  fetchEachAppConfigsSuccessful,
  fetchEachAppConfigsError,
  createAppConfigsSuccessful,
  createAppConfigsError,
  updateAppConfigsSuccessful,
  updateAppConfigsError,
  updateAppConfigItemSuccessful,
  updateAppConfigItemError,
  deleteAppConfigsSuccessful,
  deleteAppConfigsError,
  deleteAppConfigItemSuccessful,
  deleteAppConfigItemError,
  fetchAppConfigsAuthorizeError,
  listAccessControlFESuccessful,
  listAccessControlFEError,
  listEachAccessControlFEError,
  listEachAccessControlFESuccessful,
  updateAccessControlFESuccessful,
  updateAccessControlFEError,
  deleteAccessControlFESuccessful,
  deleteAccessControlFEError,
  createAccessControlFESuccessful,
  createAccessControlFEError,
} from './actions';

import {
  CreateAppConfigsService,
  GetAppConfigsService,
  GetEachAppConfigsService,
  UpdateAppConfigsService,
  UpdateAppConfigItemService,
  DeleteAppConfigsService,
  DeleteAppConfigItemService,
  getEachAccessControlFEService,
  listAccessControlFEService,
  createAccessControlFEService,
  updateAccessControlFEService,
  deleteAccessControlFEService,
} from '../../services/appConfigService';

function* fetchAppConfigHandler({ payload }) {
  try {
    const response = yield call(GetAppConfigsService, payload);
    yield put(fetchAppConfigsSuccessful(response.data.result));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchAppConfigsAuthorizeError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    }
    yield put(fetchAppConfigsError(error?.response?.data?.message));
  }
}

function* fetchEachAppConfigHandler({ payload }) {
  try {
    const response = yield call(GetEachAppConfigsService, payload);
    yield put(fetchEachAppConfigsSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachAppConfigsError(error?.response?.data?.message));
  }
}

function* createAppConfig({ payload }) {
  try {
    const response = yield call(CreateAppConfigsService, payload);
    yield put(createAppConfigsSuccessful(response.data.result));
  } catch (error) {
    yield put(createAppConfigsError(error?.response?.data?.message));
  }
}

function* updateAppConfig({ payload }) {
  try {
    const response = yield call(UpdateAppConfigsService, payload);
    yield put(updateAppConfigsSuccessful(response.data.result));
  } catch (error) {
    yield put(updateAppConfigsError(error?.response?.data?.message));
  }
}

function* updateAppConfigItem({ payload }) {
  try {
    const response = yield call(UpdateAppConfigItemService, payload);
    yield put(updateAppConfigItemSuccessful(response.data.result));
  } catch (error) {
    yield put(updateAppConfigItemError(error?.response?.data?.message));
  }
}

function* deleteAppConfig({ payload }) {
  try {
    const response = yield call(DeleteAppConfigsService, payload);
    yield put(deleteAppConfigsSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteAppConfigsError(error?.response?.data?.message));
  }
}

function* deleteAppConfigItem({ payload }) {
  try {
    const response = yield call(DeleteAppConfigItemService, payload);
    yield put(deleteAppConfigItemSuccessful(payload));
  } catch (error) {
    yield put(deleteAppConfigItemError(error?.response?.data?.message));
  }
}

function* fetchAccessControlFEHandler({ payload }) {
  try {
    const response = yield call(listAccessControlFEService, payload);
    yield put(listAccessControlFESuccessful(response.data.result));
  } catch (error) {
    yield put(listAccessControlFEError(error?.response?.data?.message));
  }
}

function* fetchEachAccessControlFEHandler({ payload }) {
  try {
    const response = yield call(getEachAccessControlFEService, payload);
    yield put(listEachAccessControlFESuccessful(response.data.result));
  } catch (error) {
    yield put(listEachAccessControlFEError(error?.response?.data?.message));
  }
}

function* createAccessControlFEHandler({ payload }) {
  try {
    const response = yield call(createAccessControlFEService, payload);
    yield put(createAccessControlFESuccessful(response.data.result));
  } catch (error) {
    yield put(createAccessControlFEError(error?.response?.data?.message));
  }
}

function* updateAccessControlFEHandler({ payload }) {
  try {
    const response = yield call(updateAccessControlFEService, payload);
    yield put(updateAccessControlFESuccessful(response.data.result));
  } catch (error) {
    yield put(updateAccessControlFEError(error?.response?.data?.message));
  }
}

function* deleteAccessControlFEHandler({ payload }) {
  try {
    const response = yield call(deleteAccessControlFEService, payload);
    yield put(deleteAccessControlFESuccessful(response.data.result));
  } catch (error) {
    yield put(deleteAccessControlFEError(error?.response?.data?.message));
  }
}
export function* watchCreateAccessControlFE() {
  yield takeEvery(CREATE_ACCESS_CONTROL_FE, createAccessControlFEHandler);
}
export function* watchUpdateAccessControlFE() {
  yield takeEvery(UPDATE_ACCESS_CONTROL_FE, updateAccessControlFEHandler);
}
export function* watchDeleteAccessControlFE() {
  yield takeEvery(DELETE_ACCESS_CONTROL_FE, deleteAccessControlFEHandler);
}
export function* watchListAccessControlFE() {
  yield takeEvery(LIST_ACCESS_CONTROL_FE, fetchAccessControlFEHandler);
}

export function* watchListEachAccessControlFE() {
  yield takeEvery(LIST_EACH_ACCESS_CONTROL_FE, fetchEachAccessControlFEHandler);
}

export function* watchFetchAppConfigs() {
  yield takeEvery(FETCH_APP_CONFIGS, fetchAppConfigHandler);
}

export function* watchFetchEachAppConfig() {
  yield takeEvery(FETCH_EACH_APP_CONFIGS, fetchEachAppConfigHandler);
}

export function* watchCreateAppConfig() {
  yield takeEvery(CREATE_APP_CONFIGS, createAppConfig);
}

export function* watchUpdateAppConfig() {
  yield takeEvery(UPDATE_APP_CONFIGS, updateAppConfig);
}

export function* watchUpdateAppConfigItem() {
  yield takeEvery(UPDATE_APP_CONFIG_ITEM, updateAppConfigItem);
}

export function* watchDeleteAppConfig() {
  yield takeEvery(DELETE_APP_CONFIGS, deleteAppConfig);
}

export function* watchDeleteAppConfigItem() {
  yield takeEvery(DELETE_APP_CONFIG_ITEM, deleteAppConfigItem);
}

function* appConfigSaga() {
  yield all([
    fork(watchFetchAppConfigs),
    fork(watchFetchEachAppConfig),
    fork(watchCreateAppConfig),
    fork(watchUpdateAppConfig),
    fork(watchUpdateAppConfigItem),
    fork(watchDeleteAppConfig),
    fork(watchDeleteAppConfigItem),
    fork(watchListAccessControlFE),
    fork(watchCreateAccessControlFE),
    fork(watchDeleteAccessControlFE),
    fork(watchUpdateAccessControlFE),
    fork(watchListEachAccessControlFE),
  ]);
}

export default appConfigSaga;
