import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { AvForm, AvField } from 'availity-reactstrap-validation';

import AutoComplete from '../../../../components/Common/Autocomplete';

// actions
import {
  createNotification,
  createNotificationFailed,
  updateNotification,
  updateNotificationFailed,
  fetchUserDetails,
} from '../../../../store/actions';

const Form = ({
  createNotification,
  createNotificationFailed,
  updateNotification,
  updateNotificationFailed,
  message,
  loading,
  history,
  users,
  user,
  notificationError,
  defaultValue,
  userID,
}) => {
  const [description, setDescription] = useState('');
  const [selectedUser, setUser] = useState(null);
  const [errorMessage, setError] = useState('');

  const handleSubmit = (event, values) => {
    setError('');
    if (!description) {
      setError('Notification description can not be empty');
      return;
    }
    if (!selectedUser && !defaultValue) {
      setError('User can not be empty');
      return;
    }
    const notificationData = {
      ...values,
    };
    notificationData.title = values.title;
    notificationData.type = values.type;
    if (values.actionUrl && values.actionUrl !== '') {
      notificationData.actionUrl = values.actionUrl.trim();
    }
    notificationData.text = description;
    if (!defaultValue) {
      notificationData.userId = selectedUser.value;
    } else {
      notificationData.userId = defaultValue.userId;
    }
    if (defaultValue) {
      updateNotification(notificationData, defaultValue.id);
      return;
    }
    createNotification(notificationData);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        createNotificationFailed('');
        updateNotificationFailed('');
        history.push('/notification');
      }, 1000);
    }
  }, [message]);

  useEffect(() => {
    createNotificationFailed('');
    updateNotificationFailed('');
  }, []);

  useEffect(() => {
    setDescription('');
    if (defaultValue) {
      setDescription(defaultValue.text);
    }
  }, [defaultValue]);

  return (
    <div>
      <Card>
        <CardBody>
          {message && (
            <Alert color='success' className='text-center'>
              {message}
            </Alert>
          )}
          {notificationError && (
            <Alert color='danger' className='text-center'>
              {notificationError}
            </Alert>
          )}
          {errorMessage && (
            <Alert color='danger' className='text-center'>
              {errorMessage}
            </Alert>
          )}
          <div className='float-right'>
            <Link to='#' onClick={() => history.push('/notification')}>
              <i className='fas fa-arrow-left mr-3' />
              Back
            </Link>
          </div>
          <div>
            <h5> {defaultValue ? 'Update' : 'Create'} Notification </h5>
          </div>
          <div>
            <Row>
              <Col sm={6}>
                <AvForm
                  className='form-horizontal'
                  onValidSubmit={handleSubmit}
                  model={defaultValue}
                >
                  <Row>
                    <Col xs={12}>
                      <FormGroup className='mb-4'>
                        <Label htmlFor='title'>Title Name</Label>
                        <AvField
                          name='title'
                          type='text'
                          className='form-control bg-light'
                          id='title'
                          value={defaultValue ? defaultValue.title : ' '}
                          required
                          placeholder='Enter Title ...'
                        />
                      </FormGroup>
                    </Col>
                    {!defaultValue && (
                      <Col md={12}>
                        <AutoComplete
                          setUser={(e) => setUser(e)}
                          defaultUserId={defaultValue?.userId}
                        />
                      </Col>
                    )}
                    <Col sm={12}>
                      <FormGroup className=' mb-4'>
                        <AvField
                          className='form-control bg-light'
                          type='select'
                          name='type'
                          label='Type'
                          value={defaultValue ? defaultValue.type : 'RIDE'}
                        >
                          <option value={'RIDE'}>Ride</option>
                          <option value={'USER'}>User</option>
                          <option value={'PAYMENT'}>Payment</option>
                          <option value={'OTHERS'}>Others</option>
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col sm={12}>
                      <Label htmlFor='userId'> Action Url </Label>

                      <FormGroup className=' mb-4'>
                        <AvField
                          name='actionUrl'
                          type='url'
                          className='form-control bg-light'
                          id='actionUrl'
                          value={
                            defaultValue?.actionUrl
                              ? defaultValue.actionUrl
                              : ' '
                          }
                          // required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroup className=' mb-4'>
                        <Label htmlFor='description'> Description</Label>
                        <textarea
                          className='form-control'
                          onChange={(e) => setDescription(e.target.value)}
                          id='description'
                          actionUrl
                          value={description}
                          rows='5'
                        ></textarea>
                      </FormGroup>
                    </Col>
                  </Row>
                  <button className='btn btn-success mr-2' type='submit'>
                    {loading
                      ? 'Submitting ...'
                      : `${defaultValue ? 'Update' : 'Create'}`}
                  </button>
                  <Link to='#' onClick={() => history.push('/notification')}>
                    <button className='btn btn-secondary'>Cancel</button>
                  </Link>
                </AvForm>
              </Col>
            </Row>{' '}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, notificationError } = state.Notifications;
  return { message, loading, notificationError };
};

export default connect(mapStateToProps, {
  createNotification,
  updateNotification,
  createNotificationFailed,
  updateNotificationFailed,

  fetchUserDetails,
})(withRouter(Form));
