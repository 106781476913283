import React, { useState, useEffect } from 'react'
import AlartError from '../../../../components/Common/AlertCard'
import { connect } from 'react-redux'
import { Row, Col, Container, Alert } from 'reactstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
//Import Breadcrumb
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import MiniWidgets from './Components/MiniWidget'
import MapView from './Components/MapView'
import DriverTable from './table'
import GreySummaryCard from '../../../../components/Common/Summary/index'

import {
  fetchDriverLogCSV,
  fetchDriverLogCSVError,
  fetchDriverLogs,
  updateUserProfile,
} from '../../../../store/actions'

const Dashboard = ({
  driverLogs,
  fetchDriverLogs,
  fetchDriverLogCSV,
  fetchDriverLogCSVError,
  log_csv,
  log_csv_loading,
  loading,
  userError,
  isAuthorize,
  message,
  approveMessage,
  approveError,
}) => {
  const [check, setCheck] = useState(true)
  const breadcrumbItems = [
    { title: 'Captain', link: '#' },
    { title: 'Dashboard', link: '#' },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <GreySummaryCard field="Users" subModule="Driver Logs">
            <Breadcrumbs title="Captains" breadcrumbItems={breadcrumbItems} />
            {userError && (
              <Alert color="danger" className="text-center">
                {userError}
              </Alert>
            )}
            {message && (
              <Alert color="success" className="text-center">
                {message}
              </Alert>
            )}
            {isAuthorize ? (
              <AlartError error={isAuthorize} />
            ) : (
              <>
                <Row>
                  <Col sm={12}>
                    <Row>
                      <MiniWidgets
                        userType={'driverLogs'}
                        defaultIcon="fas fa-id-card"
                      />{' '}
                    </Row>
                  </Col>
                  <Col>
                    <div>
                      <div className="mb-1 d-flex ">
                        <BootstrapSwitchButton
                          checked={check}
                          onlabel="Table View"
                          offlabel="Map View"
                          onstyle="primary"
                          offstyle="primary"
                          onChange={(e) => setCheck(!check)}
                          width={120}
                          style={'ml-3'}
                        />
                      </div>
                    </div>
                    {check ? (
                      <DriverTable
                        usersData={driverLogs}
                        loading={loading}
                        fetchDriverLogs={fetchDriverLogs}
                        message={message}
                        fetchDriverLogCSV={fetchDriverLogCSV}
                        fetchDriverLogCSVError={fetchDriverLogCSVError}
                        log_csv={log_csv}
                        log_csv_loading={log_csv_loading}
                        approveMessage={approveMessage}
                        approveError={approveError}
                      />
                    ) : (
                      <div
                        className="bg-light w-100"
                        style={{ height: '500px' }}
                      >
                        <MapView isMarkerShown driverlogData={driverLogs} />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = (state) => {
  const {
    log_csv,
    log_csv_loading,
    driverLogs,
    loading,
    userError,
    isAuthorize,
    message,
    approveMessage,
    approveError,
  } = state.Users
  return {
    log_csv,
    log_csv_loading,
    driverLogs,
    loading,
    userError,
    isAuthorize,
    message,
    approveMessage,
    approveError,
  }
}

export default connect(mapStatetoProps, {
  fetchDriverLogs,
  updateUserProfile,
  fetchDriverLogCSV,
  fetchDriverLogCSVError,
})(Dashboard)
