import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetCashoutAnalyticService = (query) => {
  const http = new HttpService();
  let url = 'dashboard/driver/analytics';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }

  return http.getData(url);
};

export const GetEachCashoutAnalyticService = (payload) => {
  console.log(payload);
  const http = new HttpService();
  let url = `dashboard/driver/analytics/${payload.id}`;
  let queryParams = QueryReallignment(payload.date);
  url = `${url}?${queryParams}`;
  return http.getData(url);
};
