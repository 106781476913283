import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { fetchDashTrip } from '../../../store/actions';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import './dashboard.scss';

const SalesAnalytics = ({ dashTrips, fetchDashTrip, start, end, user }) => {
  const [category, setCat] = useState('All');
  // console.log(dashTrips);
  useEffect(() => {
    if (user.userType === 'ADMIN') {
      fetchDashTrip({ data: 'trip', start, end });
    }
  }, [end, fetchDashTrip, start]);

  const series = [
    dashTrips?.completed,
    dashTrips?.ongoing,
    dashTrips?.cancelled,
  ];
  const options = {
    labels: ['Completed Trips', 'Ongoing Trips', 'Cancelled Trips'],
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
          labels: {
            show: true,
            name: {
              fontWeight: 600,
              show: false,
            },
            value: {
              fontWeight: 600,
            },
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: ['#1cbb8c', '#eeb902', '#FC4B6C'],
  };
  const completedPercent = (
    (dashTrips?.completed / dashTrips?.trip) *
    100
  ).toFixed(1);
  const ongoingPercent = ((dashTrips?.ongoing / dashTrips?.trip) * 100).toFixed(
    1
  );

  const cancelledPercent = (
    (dashTrips?.cancelled / dashTrips?.trip) *
    100
  ).toFixed(1);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className='float-right'>
            <select
              className='custom-select custom-select-sm'
              onChange={(e) => setCat(e.target.value)}
            >
              <option defaultValue>All</option>
              <option value='Ride'>Ride</option>
              <option value='Dispatch'>Dispatch</option>
              <option value='Food'>Food</option>
            </select>
          </div>
          <h4 className='card-title mb-4'>
            Trip Analysis{' '}
            <span className='text-muted'>
              ({dashTrips?.trip ? dashTrips?.trip : '0'}){' '}
            </span>
          </h4>

          <div id='donut-chart' className='apex-charts'>
            {dashTrips?.trip ? (
              <ReactApexChart
                options={options}
                series={series}
                type='donut'
                height='160'
              />
            ) : (
              <center>No Trips to display</center>
            )}
          </div>

          <Row>
            <Col xs={4}>
              <div className='text-center mt-4'>
                <p className='mb-2 text-truncate'>
                  <i className='mdi mdi-circle text-success font-size-10 mr-1'></i>{' '}
                  Completed
                </p>
                {dashTrips?.trip && dashTrips?.completed ? (
                  <>
                    <h5>{completedPercent} %</h5>
                  </>
                ) : (
                  <>
                    <h5>0 %</h5>
                  </>
                )}
                <span className='text-success font-weight-bolder'>
                  {dashTrips?.completed} Trips
                </span>
              </div>
            </Col>
            <Col xs={4}>
              <div className='text-center mt-4'>
                <p className='mb-2 text-truncate'>
                  <i className='mdi mdi-circle text-warning font-size-10 mr-1'></i>{' '}
                  Ongoing
                </p>
                {dashTrips?.trip && dashTrips?.ongoing ? (
                  <h5>{ongoingPercent} %</h5>
                ) : (
                  <h5>0 %</h5>
                )}
                <span className='text-warning font-weight-bolder'>
                  {dashTrips?.ongoing} Trips
                </span>
              </div>
            </Col>
            <Col xs={4}>
              <div className='text-center mt-4'>
                <p className='mb-2 text-truncate'>
                  <i className='mdi mdi-circle text-danger font-size-10 mr-1'></i>{' '}
                  Cancelled
                </p>
                {dashTrips?.trip && dashTrips?.cancelled ? (
                  <h5>{cancelledPercent} %</h5>
                ) : (
                  <h5>0 %</h5>
                )}
                <span className='text-danger font-weight-bolder'>
                  {dashTrips?.cancelled} Trips
                </span>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { dashTrips, loading, dashStatError } = state.Dashboard;
  return { dashTrips, loading, dashStatError };
};

export default connect(mapStateToProps, { fetchDashTrip })(SalesAnalytics);
