import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Alert,
  Label,
  Button,
  FormGroup,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import Moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { pdfjs } from 'react-pdf';
import { AvForm } from 'availity-reactstrap-validation';
import UploadDoc from '../../../pages/IRides/Users/Document/UploadDocument';
import Loader from '../Loading/index.js';
import pdfPlaceholder from '../../../assets/images/pdfPlaceholder.svg';
import {
  fetchDocument,
  deleteDocument,
  fetchDocumentError,
  updateDocument,
} from '../../../store/actions';

import { Link } from 'react-router-dom';

const DocumentTable = ({
  userInfo,
  document,
  previewAction,
  updateDocument,
  fetchDocument,
  deleteDocument,
  documentError,
  message,
  loading,
  match,
  isLoading,
}) => {
  const [showDocUploadModal, setShowDocUploadModal] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [cancellationReason, setReason] = useState('');
  const [key, setKey] = useState('');
  const [Error, setError] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [isProfile] = useState(true);

  useEffect(() => {
    if (message || documentError) {
      setShowComment(false);
      setReason(null);
      setTimeout(() => {
        // fetchDocumentError('');
        let payload = { user: userInfo?.id };
        if (match.params.id) {
          payload.user = match.params.id;
        }
        fetchDocument(payload);
      }, 2000);
    }
  }, [message, documentError]);

  // useEffect(() => {
  //   let payload = { user: user?.id };
  //   if (match.params.id) {
  //     payload.user = match.params.id;
  //   }
  // fetchDocument(payload);
  //   setIsLoading(false);
  // }, []);

  const updateId = (e) => {
    setPreviewId(e);
  };
  const updateDocUploadModalDisplay = (e) => {
    setShowDocUploadModal(e);
  };

  const handleDocView = async (documentUrl, documentId) => {
    let docName, docExtension;
    if (documentUrl) {
      var getDocNameFromUrl = documentUrl.split('/');
      docName = getDocNameFromUrl[getDocNameFromUrl.length - 1];

      var getDocExtensionFromUrl = documentUrl.split('.');
      docExtension = getDocExtensionFromUrl[getDocExtensionFromUrl.length - 1];

      if (docExtension === 'pdf') {
        previewAction(docName, documentId, false, true);
      } else {
        previewAction(docName, documentId, true, false);
      }
    } else {
      previewAction(null, documentId, false, false);
    }
  };

  const handleDocUpload = (e) => {
    updateDocUploadModalDisplay(true);
    updateId(e);
  };

  const handleStatusUpdate = (data, status) => {
    let formData = { ...data, status };
    updateDocument(formData);
  };

  const handleCancellation = (_event) => {
    setError(false);
    if (!cancellationReason) {
      setError('Reason for rejection needs to be stated');
      return;
    }
    let formData = {
      id: previewId,
      status: 'REJECTED',
      data: cancellationReason,
    };

    updateDocument(formData);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          {message && (
            <Alert color='success' className='text-center'>
              {message}
            </Alert>
          )}
          {documentError && (
            <Alert color='danger' className='text-center'>
              {documentError}
            </Alert>
          )}
          {Error && (
            <Alert className='text-center' color='danger'>
              Please kindly state the reason for rejection
            </Alert>
          )}
          {!isLoading ? (
            document?.count ? (
              <Row
                className={`align-items-${
                  showComment ? 'start' : 'center'
                } justify-content-around`}
              >
                {document?.result?.map((data, index) => (
                  <div>
                    {data.documentNumber && (
                      <h6 className="font-weight-bold font-size-12">
                        ID: {data.documentNumber}
                      </h6>
                    )}

                    <Card
                      className='shadow-lg rounded mr-2'
                      style={{ width: '260px' }}
                    >
                      <CardBody className='p-3'>
                        {data?.documentUrl || data?.documentBackUrl ? (
                          data?.documentUrl?.split('.')?.pop() === 'pdf' ||
                          data?.documentBackUrl?.split('.')?.pop() === 'pdf' ? (
                            <div
                              className='w-100 d-flex justify-content-center align-items-center badge badge-soft-secondary'
                              style={{ height: '150px' }}
                            >
                              <img
                                src={pdfPlaceholder}
                                alt='pdfPlaceholder'
                                style={{
                                  height: '50px',
                                  width: '50px',
                                }}
                              />
                            </div>
                          ) : (
                            <img
                              alt='name'
                              src={
                                data?.documentUrl
                                  ? data?.documentUrl
                                  : data?.documentBackUrl
                              }
                              style={{
                                height: '150px',
                                width: '100%',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                handleDocView(
                                  data.documentUrl
                                    ? data.documentUrl
                                    : data.documentBackUrl,
                                  data.id
                                )
                              }
                            />
                          )
                        ) : (
                          <div
                            className='w-100 d-flex justify-content-center align-items-center badge badge-soft-secondary'
                            style={{ height: '150px' }}
                          >
                            <img
                              src={pdfPlaceholder}
                              alt='pdfPlaceholder'
                              style={{
                                height: '50px',
                                width: '30px',
                                opacity: '0.3',
                              }}
                            />
                          </div>
                        )}
                        <div className='mt-2'>
                          <h6 className='font-weight-bold font-size-12'>
                            {data.name} - {data.key}{' '}
                            <span className={'text-danger'}>
                              {!data.isOptional ? '*' : ''}
                            </span>{' '}
                          </h6>
                          {data?.expiry && (
                            <h6 className='font-weight-bold font-size-12'>
                              Expiry Date -{' '}
                              {Moment(data?.expiry).format('YYYY-MM-DD')}
                            </h6>
                          )}
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                          <h1
                            className={`badge badge-soft-${
                              data.status.toLowerCase() === 'accepted'
                                ? 'success'
                                : data.status.toLowerCase() === 'rejected'
                                ? 'danger'
                                : data.status.toLowerCase() === 'pending'
                                ? 'warning'
                                : data.status.toLowerCase() === 'flagged'
                                ? 'dark'
                                : data.status.toLowerCase() === 'review'
                                ? 'secondary'
                                : ''
                            } font-size-10`}
                          >
                            {data.status ? data.status : '---'}
                          </h1>
                          <div>
                            <Link
                              to={`/document_form?docId=${data.id}`}
                              className='mr-3 text-primary'
                              id='edit1'
                            >
                              <i className='mdi mdi-pencil font-size-14'></i>
                            </Link>
                            <UncontrolledTooltip placement='top' target='edit1'>
                              Edit
                            </UncontrolledTooltip>
                            <Link
                              to='#'
                              onClick={() => deleteDocument(data.id)}
                              className='text-danger mr-3'
                              id='delete1'
                            >
                              <i className='mdi mdi-trash-can font-size-14'></i>
                            </Link>
                            <UncontrolledTooltip
                              placement='top'
                              target='delete1'
                            >
                              Delete
                            </UncontrolledTooltip>
                            <Link
                              to='#'
                              className='text-dark mr-3'
                              id='docUpload'
                              onClick={() => handleDocUpload(data.id)}
                            >
                              <i className='fas fa-upload font-size-14'></i>
                            </Link>
                            <UncontrolledTooltip
                              placement='top'
                              target='docUpload'
                            >
                              Upload
                            </UncontrolledTooltip>
                            {data.status.toLowerCase() !== 'accepted' && (
                              <>
                                <Link
                                  to='#'
                                  className='mr-3 text-success'
                                  id='approve'
                                  style={
                                    data.status.toLowerCase() === 'pending'
                                      ? { display: 'none' }
                                      : { display: 'inline-flex' }
                                  }
                                  onClick={() =>
                                    handleStatusUpdate(data, 'ACCEPTED')
                                  }
                                >
                                  <i className='fas fa-check font-size-14 text-success mb-2'></i>
                                </Link>
                                <UncontrolledTooltip
                                  placement='top'
                                  target='approve'
                                >
                                  Approve
                                </UncontrolledTooltip>
                              </>
                            )}{' '}
                            {data.status.toLowerCase() !== 'rejected' && (
                              <>
                                <Link
                                  to='#'
                                  className='mr-3 text-danger'
                                  id='reject'
                                  style={
                                    data.status === 'PENDING'
                                      ? { display: 'none' }
                                      : null
                                  }
                                  onClick={() => {
                                    setShowComment(true);
                                    setKey(index);
                                    setPreviewId(data.id);
                                  }}
                                >
                                  <i className='fas fa-times font-size-14 text-danger'></i>
                                </Link>
                                <UncontrolledTooltip
                                  placement='top'
                                  target='reject'
                                >
                                  Reject
                                </UncontrolledTooltip>
                              </>
                            )}
                            {data.status !== 'PENDING' && (
                              <>
                                <Link
                                  to='#'
                                  onClick={() =>
                                    handleDocView(
                                      data.documentUrl
                                        ? data.documentUrl
                                        : data.documentBackUrl,
                                      data.id
                                    )
                                  }
                                  className='text-dark font-weight-bold'
                                  id='docPreview'
                                >
                                  <i className='fas fa-eye font-size-14'></i>
                                </Link>
                                <UncontrolledTooltip
                                  placement='top'
                                  target='docPreview'
                                >
                                  Preview
                                </UncontrolledTooltip>
                              </>
                            )}
                          </div>
                        </div>
                        {showComment && key === index ? (
                          <div>
                            <AvForm
                              className='form-horizontal'
                              onValidSubmit={handleCancellation}
                            >
                              <Row>
                                <Col md='12'>
                                  <FormGroup className='mb-4'>
                                    <Label
                                      htmlFor='cancellationReason'
                                      className='font-size-12'
                                    >
                                      Reason For Rejection
                                    </Label>
                                    <textarea
                                      className='form-control'
                                      onChange={(e) =>
                                        setReason(e.target.value)
                                      }
                                      value={cancellationReason}
                                      id='cancellationReason'
                                      rows='3'
                                    ></textarea>
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Button
                                className='btn btn-success mr-2'
                                type='submit'
                                size='sm'
                              >
                                {loading ? 'Submitting ...' : 'Submit'}
                              </Button>
                              <Link
                                to='#'
                                onClick={() => {
                                  setShowComment(false);
                                  setReason('');
                                }}
                              >
                                <Button className='btn btn-secondary' size='sm'>
                                  Discard
                                </Button>
                              </Link>
                            </AvForm>
                          </div>
                        ) : null}
                      </CardBody>
                    </Card>
                  </div>
                ))}
              </Row>
            ) : (
              <div>This driver has no documents</div>
            )
          ) : (
            <Loader />
          )}

          <Modal
            isOpen={showDocUploadModal}
            toggle={() => setShowDocUploadModal(!showDocUploadModal)}
          >
            <ModalHeader toggle={() => setShowDocUploadModal(false)}>
              Upload Document
            </ModalHeader>
            <ModalBody>
              <UploadDoc
                previewId={previewId}
                uploadStatus='ACCEPTED'
                updateDocUploadModalDisplay={updateDocUploadModalDisplay}
                isProfile={isProfile}
              />
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { document, loading, documentError, message } = state.Document;
  const { user } = state.Account;
  return { user, document, loading, documentError, message };
};

export default connect(mapStateToProps, {
  fetchDocument,
  deleteDocument,
  updateDocument,
  fetchDocumentError,
})(withRouter(DocumentTable));
