import React, { useEffect, useState } from "react";
// import MetaTags from "react-meta-tags"
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import Loader from "../../../../components/Common/Loading/index.js";
import {
  UserInfo,
  UserSummary,
} from "../../../../components/Common/ProfileComponents/index";
import { Socket } from "../../../../services/socketService";
import { withRouter, useHistory } from "react-router-dom";

import {
  fetchUserDetails,
  fetchUser,
  deleteVehicle,
  updateVehicle,
  updateVehicleFailed,
  updateUserProfileError,
  fetchBank,
  fetchDriverLogs,
  updateVehiclePackages,
  fetchDocument,
  userLocation,
  activateAccountFailed,
  userLocationError,
} from "../../../../store/actions";
import PushNotification from "./PushNotification";

const UserDetails = ({
  match,
  user,
  bank,
  fetchUserDetails,
  activate_message,
  fetchUser,
  updateVehicle,
  fetchDriverLogs,
  updateVehicleFailed,
  updateVehiclePackages,
  updateUserProfileError,
  fetchDocument,
  document,
  vehicleError,
  fetchBank,
  activationError,
  activateAccountFailed,
  mainLoader,
  loading,
  userError,
  message,
  userLocation,
  userLocationError,
  user_location,
  loadingLocation,
  user_locationError,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const breadcrumbItems = [
    { title: "User", link: "#" },
    { title: "Dashboard", link: "#" },
  ];
  let history = useHistory();
  useEffect(() => {
    let payload = { user: user?.id };
    if (match.params.id) {
      fetchUserDetails(match.params.id);
      payload.user = match.params.id;
    }
    fetchDocument(payload);

    setIsLoading(false);
  }, [fetchDocument, fetchUserDetails, match.params.id]);

  useEffect(() => {
    if (user) {
      let data = { user: user.id };
      fetchBank(data);
      userLocation({ userId: user?.id });
    }
  }, [fetchBank, user]);

  useEffect(() => {
    if (activate_message) {
      setTimeout(() => {
        fetchUser(match.params.id);
        activateAccountFailed("");
      }, 2000);
    }
  }, [activate_message]);

  useEffect(() => {
    if (message || vehicleError) {
      setTimeout(() => {
        updateVehicleFailed("");
        updateUserProfileError("");
        fetchUser(match.params.id);
      }, 2000);
    }
  }, [message, vehicleError]);

  useEffect(() => {
    if (activationError) {
      setTimeout(() => {
        activateAccountFailed("");
      }, 2000);
    }
  }, [activationError]);

  if (userError && !loading) {
    return (
      <div className="page-content text-center">
        <Alert color="danger">{userError}</Alert>
      </div>
    );
  }
  const handleVehicleApproval = (id, status) => {
    updateVehicle({ id, status });
  };

  const goBackHandler = () => {
    console.log(history);
    history.push("/users");
  };
  const chatRouterHandler = (id) => {
    if (user.userType === "DRIVER") {
      Socket("chat", {
        token: JSON.parse(localStorage.getItem("authTokens")).accessToken,
        driver: id,
      });
    }

    if (user.userType === "RIDER") {
      Socket("chat", {
        token: JSON.parse(localStorage.getItem("authTokens")).accessToken,
        rider: id,
      });
    }
    let url = `${process.env.REACT_APP_STREAM_CHAT_URL}/${id}`;
    // let url = `http://localhost:3001/${id}`;

    window.open(url, "_blank") ||
      window.location.replace("https://support.wwf.org.uk");
  };

  const whatsAppHandler = (userPhone) => {
    let modifyNumber = userPhone.replace("+", "");
    let url = `https://wa.me/${modifyNumber}`;

    window.open(url, "_blank") ||
      window.location.replace("https://support.wwf.org.uk");
  };

  const sinchCallHandler = () => {
    // let url = `${process.env.REACT_APP_SINCH_CALL_URL}`;
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="User-Details" breadcrumbItems={breadcrumbItems} />

          {activationError && (
            <Alert color="danger" className="text-center">
              {activationError}
            </Alert>
          )}
          {activate_message && (
            <Alert color="success" className="text-center">
              {activate_message}
            </Alert>
          )}

          {user && !mainLoader ? (
            <Row>
              <Col sm="12">
                <Row className=" justify-content-between">
                  <Col>
                    <Button size="sm" color="primary" onClick={goBackHandler}>
                      <i className="fas fa-arrow-left mr-3" />
                      Back
                    </Button>
                  </Col>
                  <Col className="mr-3 mb-1 mb-2 text-right w-100">
                    <button
                      className="btn btn-sm btn-light border-success border ml-2"
                      onClick={() => setShowModal(true)}
                    >
                      <i className="ri-mail-send-line mr-2" /> Push
                    </button>
                    <Link className="text-dark" to={`/chat?id=${user?.id}`}>
                      <button
                        // onClick={() => chatRouterHandler(user?.id)}
                        className="btn btn-sm btn-light border-secondary border ml-2"
                      >
                        <i className="ri-message-3-line mr-2" /> Chat
                      </button>
                    </Link>
                    <button
                      onClick={() => sinchCallHandler(user?.id)}
                      className="btn btn-sm btn-light border-secondary border ml-2"
                    >
                      <i className="ri-phone-line mr-1" /> Call
                    </button>
                    <button
                      onClick={() => whatsAppHandler(user?.phone)}
                      className="btn btn-sm btn-light border-success border ml-2"
                    >
                      <i className="ri-message-3-line mr-1" /> Whatsapp
                    </button>
                  </Col>
                </Row>
              </Col>
              <Col sm={12}>
                <UserInfo user={user} />
              </Col>
              <Col sm={12}>
                <UserSummary
                  document={document}
                  isLoading={isLoading}
                  bank={bank}
                  user={user}
                  handleVehicleApproval={handleVehicleApproval}
                  updateVehicleFailed={updateVehicleFailed}
                  updateVehiclePackages={updateVehiclePackages}
                  message={message}
                  vehicleError={vehicleError}
                  user_location={user_location}
                  loadingLocation={loadingLocation}
                  user_locationError={user_locationError}
                />
              </Col>
            </Row>
          ) : (
            <Card>
              <CardBody>
                <Loader loading={loading} />
              </CardBody>
            </Card>
          )}
        </Container>

        <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
          <ModalHeader toggle={() => setShowModal(false)}>
            Send Notification
          </ModalHeader>

          <ModalBody>
            <PushNotification userId={user?.id} />
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { user, loading, userError, mainLoader } = state.Users;
  const { message, vehicleError } = state.Vehicle;
  const { bank } = state.Bank;
  const { document, documentError } = state.Document;
  const {
    user_location,
    loadingLocation,
    user_locationError,
    activationError,
    activate_message,
  } = state.Account;
  return {
    document,
    documentError,
    activate_message,
    user,
    loading,
    userError,
    message,
    vehicleError,
    bank,
    activationError,
    mainLoader,
    user_location,
    loadingLocation,
    user_locationError,
  };
};

export default connect(mapStateToProps, {
  fetchUserDetails,
  fetchUser,
  deleteVehicle,
  updateVehicle,
  fetchDriverLogs,
  fetchDocument,
  updateVehicleFailed,
  activateAccountFailed,
  updateUserProfileError,
  fetchBank,
  updateVehiclePackages,
  userLocation,
  userLocationError,
})(withRouter(UserDetails));
