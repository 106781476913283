import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AccessSummary from '../AccessView/summary';

const Index = ({ children, accessControl, subModule, field }) => {
  const [preview, setPreview] = useState();
  useEffect(() => {
    if (subModule) {
      if (
        accessControl?.result[0]?.data?.modules[field][subModule]?.subscribe ===
        'GREY'
      ) {
        setPreview(true);
      } else {
        setPreview(false);
      }
    } else {
      if (accessControl?.result[0]?.data?.modules[field].subscribe === 'GREY') {
        setPreview(true);
      } else {
        setPreview(false);
      }
    }
  }, [accessControl]);

  if (preview) return <AccessSummary />;

  return <div>{children}</div>;
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;

  return { accessControl };
};

export default connect(mapStateToProps)(Index);
