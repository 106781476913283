import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
  Row,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Button,
  FormGroup,
  Col,
  Alert,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import MiniWidgets from '../Components/MiniWidget';
import {
  fetchComplaint,
  assignComplaint,
  updateComplaint,
  assignComplaintSuccessful,
  fetchDataForComplaintsCSV,
  fetchForCSVError,
} from '../../../../store/actions';
import GreySummaryCard from '../../../../components/Common/Summary/index';
import { GeneralTableHeader } from '../data';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { CSVLink } from 'react-csv';
//Import Breadcrumb
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import GeneralTable from '../table';
import PreviewComplaint from '../Components/previewComplaint';
import ComplainUpdate from '../Components/ComplainUpdate';
import AssignToCard from '../Components/AssignToCard';

const breadcrumbItems = [
  { title: 'Dashboard', link: '#' },
  { title: 'Title', link: '#' },
];

const Complaint = ({
  loading,
  fetchDataForComplaintsCSV,
  fetchForCSVError,
  csv,
  csv_loading,
  history,
  complaint,
  message,
  complaintError,
  assignComplaint,
  fetchComplaint,
  updateComplaint,
  assignComplaintSuccessful,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [assignToModal, setAssignToModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [complaintId, setComplaintId] = useState(null);
  const [dateFilter, setDate] = useState({});
  const [showCSVModal, setCSVModal] = useState(false);

  useEffect(() => {
    const queryParam = queryString.parse(history.location.search);
    if (queryParam['reporterId']) {
      let params = { reporterId: queryParam['reporterId'] };
      fetchComplaint(params);
    } else {
      fetchComplaint();
    }
  }, []);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setAssignToModal(false);
        setUpdateModal(false);
        setComplaintId(null);
        fetchComplaint();
        assignComplaintSuccessful('');
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    // Updating Url with previewId
    if (complaintId) {
      history.push({
        pathname: history.location.pathname,
        search: `previewId=${complaintId}`,
      });
    }
  }, [complaintId]);
  const handleCSV = (e, values) => {
    fetchDataForComplaintsCSV({
      take: values.endRow
        ? values.endRow - values.startRow + 1
        : complaint?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
    });
  };
  useEffect(() => {
    if (csv) {
      // SET CSV EXPORT DATA
      const csvData = csv?.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Description: data?.title ? data.title : '----',
          Note: data?.note ? data.note : '----',
          Type: data?.type ? data.type : '----',
          Priority: data?.priority ? data.priority : '----',
          Device: data?.device ? data.device : '----',
          Location: data?.location ? data.location : '----',
          ImageUrl: data?.imageUrl ? data.imageUrl : '----',
          Status: data?.status ? data.status : '----',
          Reporter: data?.reporter
            ? `${data?.reporter?.firstName} - ${data?.reporter?.lastName}`
            : '----',
          Reportee: data?.reportee
            ? `${data?.reportee?.firstName} - ${data?.reportee?.lastName}`
            : '---',
          Assignee: data?.assignee
            ? `${data?.assignee?.firstName} - ${data?.assignee?.lastName}`
            : '----',
          Assignor: data?.assignor
            ? `${data?.assignor?.firstName} - ${data?.assignor?.lastName}`
            : '---',
          Created_Date: data?.createdAt?.substring(0, 10),
        };

        return formData;
      });

      setCsvData(csvData);
    }
  }, [csv]);

  useEffect(() => {
    if (!showCSVModal) {
      fetchForCSVError();
    }
  }, [showCSVModal]);

  useEffect(() => {
    // Show modal if previewId present  on first Visit
    const parsedUrlParams = queryString.parse(history.location.search);
    let id = parsedUrlParams?.previewId;
    if (id) {
      previewHandler(id);
    }
  }, []);

  const previewHandler = (id) => {
    setShowModal(true);
    setComplaintId(id);
  };

  const closePreview = () => {
    setShowModal(false);
    setComplaintId('');
    // Clear Browser Url
    history.push({
      pathname: history.location.pathname,
    });
  };

  const updateHandler = (id) => {
    setUpdateModal(true);
    setComplaintId(id);
  };

  const assignedHandler = (id) => {
    setAssignToModal(true);
    setComplaintId(id);
  };
  const date = (e) => {
    setDate(e);
  };
  const toggleCSVModal = (e) => {
    setCSVModal(e);
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='All Complaints'
            breadcrumbItems={breadcrumbItems}
          />
          <GreySummaryCard field='Complaints' subModule='General Complaint'>
            <Row>
              <MiniWidgets date={dateFilter} />
            </Row>
            {complaintError !== null ? (
              <Alert color='danger' className='text-center'>
                {complaintError}
              </Alert>
            ) : (
              <GeneralTable
                complaint={complaint}
                dateFilter={date}
                csvModal={toggleCSVModal}
                loading={loading}
                field={'Complaints'}
                subModule={'General Complaint'}
                fetchComplaint={fetchComplaint}
                previewAction={previewHandler}
                updateAction={updateHandler}
                assignedHandler={assignedHandler}
                columns={GeneralTableHeader}
                fetchDataForComplaintsCSV={fetchDataForComplaintsCSV}
                fetchForCSVError={fetchForCSVError}
                csv={csv}
                csv_loading={csv_loading}
              />
            )}
          </GreySummaryCard>
        </Container>
        {/* Preview Modal */}
        <Modal isOpen={showModal} toggle={() => closePreview()}>
          <ModalHeader toggle={() => closePreview()}>
            Preview Complaint
          </ModalHeader>
          <ModalBody>
            <PreviewComplaint previewId={complaintId} />
          </ModalBody>
        </Modal>

        {/* Assign to Modal */}
        <Modal
          isOpen={assignToModal}
          toggle={() => setAssignToModal(!assignToModal)}
        >
          <ModalHeader toggle={() => setAssignToModal(false)}>
            Assign To
          </ModalHeader>
          <ModalBody>
            <AssignToCard
              assignedId={complaintId}
              assignComplaint={assignComplaint}
              loading={loading}
              message={message}
              complaintError={complaintError}
            />
          </ModalBody>
        </Modal>

        {/* Update to Modal */}
        <Modal isOpen={updateModal} toggle={() => setUpdateModal(!updateModal)}>
          <ModalHeader toggle={() => setUpdateModal(false)}>
            Update Complaint
          </ModalHeader>
          <ModalBody>
            <ComplainUpdate
              updateId={complaintId}
              updateComplaint={updateComplaint}
              loading={loading}
              message={message}
              complaintError={complaintError}
            />
          </ModalBody>
        </Modal>
        {/* CSV DOWNLOAD MODAL */}
        <Modal isOpen={showCSVModal} toggle={() => setCSVModal(!showCSVModal)}>
          <ModalHeader toggle={() => setCSVModal(false)}>
            Generate CSV
          </ModalHeader>
          <ModalBody>
            <AvForm className='form-horizontal' onValidSubmit={handleCSV}>
              <Col>
                <FormGroup>
                  <Label htmlFor='startRow'>Start (Row)</Label>
                  <AvField
                    name='startRow'
                    type='number'
                    max={complaint?.total - 1}
                    className='form-control'
                    id='startRow'
                    placeholder='Enter the row to start from'
                    errorMessage='value must be greater than 0 and less than total data'
                    min='1'
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor='endRow'>End (Row)</Label>
                  <AvField
                    name='endRow'
                    type='number'
                    className='form-control'
                    min='1'
                    max={complaint?.total}
                    id='endRow'
                    placeholder='Enter the row to end'
                    helpMessage={
                      complaint?.total
                        ? `Total data is ${complaint?.total}`
                        : null
                    }
                    required
                  />
                </FormGroup>
              </Col>
              <Button
                size='sm'
                color='primary'
                className='ml-3 waves-effect waves-light '
                type='submit'
              >
                {csv_loading ? 'Generating...' : 'Generate'}
              </Button>
              {csv && (
                <CSVLink
                  data={csvData}
                  filename={'general_complaints.csv'}
                  className='btn-sm btn-success ml-2'
                  target='_blank'
                  onClick={() => setCSVModal(false)}
                >
                  Download
                </CSVLink>
              )}
            </AvForm>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { csv, csv_loading, loading, complaint, message, complaintError } =
    state.Complaint;
  return { csv, csv_loading, loading, complaint, message, complaintError };
};

export default connect(mapStateToProps, {
  fetchComplaint,
  assignComplaint,
  updateComplaint,
  assignComplaintSuccessful,
  fetchDataForComplaintsCSV,
  fetchForCSVError,
})(withRouter(Complaint));
