import {
  FETCH_ZONE,
  FETCH_ZONE_SUCCESSFUL,
  FETCH_ZONE_API_FAILED,
  FETCH_EACH_ZONE,
  FETCH_EACH_ZONE_SUCCESSFUL,
  FETCH_EACH_ZONE_API_FAILED,
  DELETE_ZONE,
  DELETE_ZONE_SUCCESSFUL,
  DELETE_ZONE_FAILED,
  CREATE_ZONE,
  CREATE_ZONE_SUCCESSFUL,
  CREATE_ZONE_FAILED,
  UPDATE_ZONE,
  UPDATE_ZONE_SUCCESSFUL,
  UPDATE_ZONE_FAILED,
} from './actionTypes';

const initialState = {
  zone: null,
  zoneError: null,
  tableError: null,
  zonePreview: null,
  isAuthorize: null,
  message: null,
  loading: false,
};

const Zone = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ZONE:
    case UPDATE_ZONE:
    case DELETE_ZONE:
      state = {
        ...state,
        zone: null,
        tableError: null,
        zoneError: null,
        loading: true,
        message: null,
        isAuthorize: null,
      };
      break;
    case FETCH_ZONE_SUCCESSFUL:
      state = {
        ...state,
        zone: action.payload,
        tableError: null,
        zoneError: null,
        loading: false,
      };
      break;

    case DELETE_ZONE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        zoneError: null,
        message: 'Zone deleted successfully',
      };
      break;
    case UPDATE_ZONE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        zoneError: null,
        message: 'Zone Updated Successfully',
      };
      break;

    case FETCH_EACH_ZONE:
      state = {
        ...state,
        loading: true,
        zone: null,
        zoneError: null,
        message: null,
        isAuthorize: null,
      };
      break;

    case FETCH_EACH_ZONE_SUCCESSFUL:
      state = {
        ...state,
        zonePreview: action.payload,
        loading: false,
        zoneError: null,
        message: null,
      };
      break;

    case CREATE_ZONE:
      state = {
        ...state,
        loading: true,
        zoneError: null,
        message: null,
      };
      break;

    case CREATE_ZONE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        zoneError: null,
        message: `${action.payload.name}  created successfully`,
      };
      break;

    case FETCH_ZONE_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case CREATE_ZONE_FAILED:
    case DELETE_ZONE_FAILED:
    case FETCH_EACH_ZONE_API_FAILED:
      state = {
        ...state,
        zone: null,
        zonePreview: null,
        loading: false,
        message: null,
        zoneError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_ZONE_FAILED:
      state = {
        ...state,
        loading: false,
        zoneError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Zone;
