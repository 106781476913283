import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Form from './form';

import { fetchEachLicenseKey, fetchZone } from '../../../../../store/actions';

const LicenseKeyForm = ({
  fetchEachLicenseKey,
  fetchZone,
  licenseKey,
  loading,
  message,
  zone
}) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  useEffect(() => {
    if (id) {
      fetchEachLicenseKey(id);
    }
  }, []);

  useEffect(() => {
    fetchZone();
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Form
          loading={loading}
          defaultValue={licenseKey ? licenseKey : null}
          message={message}
          zone={zone}
          fetchZone={fetchZone}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { licenseKey, loading, licenseKeyError, message } = state.LicenseKey;
  const { zone } = state.Zone;
  return { zone, licenseKey, loading, licenseKeyError, message };
};

export default connect(mapStateToProps, { fetchZone, fetchEachLicenseKey })(
  LicenseKeyForm
);
