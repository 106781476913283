export const FETCH_DASH_STAT = 'FETCH_DASH_STAT'
export const FETCH_DASH_STAT_SUCCESSFUL = 'FETCH_DASH_STAT_SUCCESSFUL'
export const FETCH_DASH_STAT_FAILED = 'FETCH_DASH_STAT_API_FAILED'

export const FETCH_DASH_EARNING = 'FETCH_DASH_EARNING'
export const FETCH_DASH_EARNING_SUCCESSFUL = 'FETCH_DASH_EARNING_SUCCESSFUL'
export const FETCH_DASH_EARNING_FAILED = 'FETCH_DASH_EARNING_API_FAILED'

export const FETCH_DASH_REVENUE = 'FETCH_DASH_REVENUE'
export const FETCH_DASH_REVENUE_SUCCESSFUL = 'FETCH_DASH_REVENUE_SUCCESSFUL'
export const FETCH_DASH_REVENUE_FAILED = 'FETCH_DASH_REVENUE_API_FAILED'

export const FETCH_DASH_TRIP = 'FETCH_DASH_TRIP'
export const FETCH_DASH_TRIP_SUCCESSFUL = 'FETCH_DASH_TRIP_SUCCESSFUL'
export const FETCH_DASH_TRIP_FAILED = 'FETCH_DASH_TRIP_API_FAILED'

export const FETCH_DASH_RATING = 'FETCH_DASH_RATING'
export const FETCH_DASH_RATING_SUCCESSFUL = 'FETCH_DASH_RATING_SUCCESSFUL'
export const FETCH_DASH_RATING_FAILED = 'FETCH_DASH_RATING_API_FAILED'
