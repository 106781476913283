import {
  FETCH_BANK_SUMMARY,
  FETCH_BANK_SUMMARY_SUCCESSFULLY,
  FETCH_BANK_SUMMARY_FAILED,
  FETCH_BANK,
  FETCH_BANK_SUCCESSFUL,
  FETCH_BANK_API_FAILED,
  FETCH_BANK_LIST,
  FETCH_BANK_LIST_SUCCESSFUL,
  FETCH_BANK_LIST_API_FAILED,
  FETCH_EACH_BANK,
  FETCH_EACH_BANK_SUCCESSFUL,
  FETCH_EACH_BANK_API_FAILED,
  DELETE_BANK,
  DELETE_BANK_SUCCESSFUL,
  DELETE_BANK_FAILED,
  CREATE_BANK,
  CREATE_BANK_SUCCESSFUL,
  CREATE_BANK_FAILED,
  UPDATE_BANK,
  UPDATE_BANK_SUCCESSFUL,
  UPDATE_BANK_FAILED,
  GET_BANK_RECIPIENT,
  GET_BANK_RECIPIENT_SUCCESSFUL,
  GET_BANK_RECIPIENT_FAILED,
  IS_BANK_AUTHORIZE,
  FETCH_FOR_BANK_CSV,
  FETCH_FOR_BANK_CSV_SUCCESSFULLY,
  FETCH_FOR_BANK_CSV_ERROR,
} from './actionTypes';

export const fetchBankAuthorizeError = (error) => {
  return {
    type: IS_BANK_AUTHORIZE,
    payload: error,
  };
};

export const fetchBankList = (payload) => {
  return {
    type: FETCH_BANK_LIST,
    payload,
  };
};

export const fetchBankListSuccessful = (payload) => {
  return {
    type: FETCH_BANK_LIST_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchBankListError = (error) => {
  return {
    type: FETCH_BANK_LIST_API_FAILED,
    payload: error,
  };
};

export const fetchBank = (payload) => {
  return {
    type: FETCH_BANK,
    payload,
  };
};

export const fetchBankSuccessful = (payload) => {
  return {
    type: FETCH_BANK_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchBankError = (error) => {
  return {
    type: FETCH_BANK_API_FAILED,
    payload: error,
  };
};

export const fetchEachBank = (id) => {
  return {
    type: FETCH_EACH_BANK,
    payload: id,
  };
};

export const fetchEachBankSuccessful = (payload) => {
  return {
    type: FETCH_EACH_BANK_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachBankError = (error) => {
  return {
    type: FETCH_EACH_BANK_API_FAILED,
    payload: error,
  };
};

export const deleteBank = (id) => {
  return {
    type: DELETE_BANK,
    payload: id,
  };
};

export const deleteBankSuccessful = (payload) => {
  return {
    type: DELETE_BANK_SUCCESSFUL,
    payload,
  };
};

export const deleteBankFailed = (error) => {
  return {
    type: DELETE_BANK_FAILED,
    payload: error,
  };
};

export const createBank = (payload) => {
  return {
    type: CREATE_BANK,
    payload,
  };
};

export const createBankSuccessful = (payload) => {
  return {
    type: CREATE_BANK_SUCCESSFUL,
    payload,
  };
};

export const createBankFailed = (error) => {
  return {
    type: CREATE_BANK_FAILED,
    payload: error,
  };
};

export const updateBank = (payload, query) => {
  return {
    type: UPDATE_BANK,
    payload: { data: payload, query: query },
  };
};

export const updateBankFailed = (error) => {
  return {
    type: UPDATE_BANK_FAILED,
    payload: error,
  };
};

export const updateBankSuccessful = (payload) => {
  return {
    type: UPDATE_BANK_SUCCESSFUL,
    payload,
  };
};

// getRecipient
export const getRecipient = (payload) => {
  return {
    type: GET_BANK_RECIPIENT,
    payload,
  };
};

export const getRecipientFailed = (error) => {
  return {
    type: GET_BANK_RECIPIENT_FAILED,
    payload: error,
  };
};

export const getRecipientSuccessful = (payload) => {
  return {
    type: GET_BANK_RECIPIENT_SUCCESSFUL,
    payload,
  };
};

//SUMMARY ENTRIES
export const fetchBankSummary = (query) => {
  return {
    type: FETCH_BANK_SUMMARY,
    payload: query,
  };
};

export const fetchBankSummarySuccessfully = (payload) => {
  return {
    type: FETCH_BANK_SUMMARY_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchBankSummaryFailed = (error) => {
  return {
    type: FETCH_BANK_SUMMARY_FAILED,
    payload: error,
  };
};

export const fetchBankCSV = (payload) => {
  return {
    type: FETCH_FOR_BANK_CSV,
    payload,
  };
};

export const fetchBankCSVSuccessfully = (payload) => {
  return {
    type: FETCH_FOR_BANK_CSV_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchBankCSVError = (error) => {
  return {
    type: FETCH_FOR_BANK_CSV_ERROR,
    payload: error,
  };
};
