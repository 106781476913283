import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_COMPLAINT,
  FETCH_MY_COMPLAINT,
  ASSIGN_COMPLAINT,
  FETCH_COMPLAINT_SUMMARY,
  FETCH_EACH_COMPLAINT,
  CREATE_COMPLAINT,
  DELETE_COMPLAINT,
  UPDATE_COMPLAINT,
  FETCH_FOR_COMPLAINT_CSV,
  FETCH_FOR_MY_COMPLAINT_CSV,
} from './actionTypes';
import {
  fetchComplaintSuccessful,
  fetchComplaintError,
  fetchComplaintSummaryFailed,
  fetchComplaintSummarySuccessfully,
  assignComplaintSuccessful,
  assignComplaintError,
  fetchEachComplaintSuccessful,
  fetchEachComplaintError,
  deleteComplaintSuccessful,
  deleteComplaintFailed,
  createComplaintSuccessful,
  createComplaintFailed,
  updateComplaintSuccessful,
  fetchComplaintAuthorizeError,
  fetchForCSVSuccessfully,
  fetchForCSVError,
  updateComplaintFailed,
} from './actions';

import {
  GetComplaintService,
  GetMyComplaintService,
  GetEachComplaintService,
  DeleteComplaintService,
  fetchComplaintSummaryService,
  CreateComplaintService,
  UpdateComplaintService,
  AssignComplaintService,
} from '../../services/complaintService';

function* fetchMyComplaintHandler({ payload }) {
  try {
    const response = yield call(GetMyComplaintService, payload);
    yield put(fetchComplaintSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchComplaintError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchComplaintError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchComplaintError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchComplaintHandler({ payload }) {
  try {
    const response = yield call(GetComplaintService, payload);
    yield put(fetchComplaintSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchComplaintError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchComplaintError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchComplaintError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchForComplaintsCSVHandler({ payload }) {
  try {
    const response = yield call(GetComplaintService, payload);
    yield put(fetchForCSVSuccessfully(response.data));
  } catch (error) {
    yield put(fetchForCSVError(error?.response.data?.message));
  }
}

function* fetchForMyComplaintsCSVHandler({ payload }) {
  try {
    const response = yield call(GetMyComplaintService, payload);
    yield put(fetchForCSVSuccessfully(response.data));
  } catch (error) {
    yield put(fetchForCSVError(error?.response?.data?.message));
  }
}

function* fetchEachComplaintHandler({ payload }) {
  try {
    const response = yield call(GetEachComplaintService, payload);
    yield put(fetchEachComplaintSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachComplaintError(error?.response?.data?.message));
  }
}

function* deleteComplaintHandler({ payload }) {
  try {
    const response = yield call(DeleteComplaintService, payload);
    yield put(deleteComplaintSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteComplaintFailed(error?.response?.data?.error));
  }
}

function* createComplaintHandler({ payload }) {
  try {
    const response = yield call(CreateComplaintService, payload);
    yield put(createComplaintSuccessful(response.data.result));
  } catch (error) {
    yield put(createComplaintFailed(error?.response?.data?.message));
  }
}

function* updateComplaintHandler({ payload }) {
  try {
    const response = yield call(UpdateComplaintService, payload);
    yield put(updateComplaintSuccessful(response.data.result));
  } catch (error) {
    yield put(updateComplaintFailed(error?.response?.data?.message));
  }
}

function* assignComplaintHandler({ payload }) {
  try {
    const response = yield call(AssignComplaintService, payload);
    yield put(assignComplaintSuccessful(response.data));
  } catch (error) {
    yield put(assignComplaintError(error?.response?.data?.message));
  }
}

// Complaint SUMMARY
function* fetchComplaintSummaryHandler({ payload }) {
  try {
    const response = yield call(fetchComplaintSummaryService, payload);
    yield put(fetchComplaintSummarySuccessfully(response.data));
  } catch (error) {
    yield put(fetchComplaintSummaryFailed(error.response.data));
  }
}

export function* watchFetchComplaint() {
  yield takeEvery(FETCH_COMPLAINT, fetchComplaintHandler);
}
export function* watchForCSV() {
  yield takeEvery(FETCH_FOR_COMPLAINT_CSV, fetchForComplaintsCSVHandler);
}
export function* watchForMyComplaintsCSV() {
  yield takeEvery(FETCH_FOR_MY_COMPLAINT_CSV, fetchForMyComplaintsCSVHandler);
}
export function* watchFetchMyComplaint() {
  yield takeEvery(FETCH_MY_COMPLAINT, fetchMyComplaintHandler);
}

export function* watchFetchEachComplaint() {
  yield takeEvery(FETCH_EACH_COMPLAINT, fetchEachComplaintHandler);
}
export function* watchDeleteComplaint() {
  yield takeEvery(DELETE_COMPLAINT, deleteComplaintHandler);
}
export function* watchCreateComplaint() {
  yield takeEvery(CREATE_COMPLAINT, createComplaintHandler);
}
export function* watchUpdateComplaint() {
  yield takeEvery(UPDATE_COMPLAINT, updateComplaintHandler);
}

export function* watchAssignComplaint() {
  yield takeEvery(ASSIGN_COMPLAINT, assignComplaintHandler);
}

export function* watchFetchComplaintSummary() {
  yield takeEvery(FETCH_COMPLAINT_SUMMARY, fetchComplaintSummaryHandler);
}
function* ComplaintSaga() {
  yield all([
    fork(watchFetchComplaint),
    fork(watchForCSV),
    fork(watchForMyComplaintsCSV),
    fork(watchFetchEachComplaint),
    fork(watchDeleteComplaint),
    fork(watchCreateComplaint),
    fork(watchFetchComplaintSummary),
    fork(watchUpdateComplaint),
    fork(watchAssignComplaint),
    fork(watchFetchMyComplaint),
  ]);
}

export default ComplaintSaga;
