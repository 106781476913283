export const FETCH_REWARD = 'FETCH_REWARD'
export const FETCH_REWARD_SUCCESSFUL = 'FETCH_REWARD_SUCCESSFUL'
export const FETCH_REWARD_API_FAILED = 'FETCH_REWARD_API_FAILED'

export const FETCH_REWARD_HISTORY = 'FETCH_REWARD_HISTORY'
export const FETCH_REWARD_HISTORY_SUCCESSFUL = 'FETCH_REWARD_HISTORY_SUCCESSFUL'
export const FETCH_REWARD_HISTORY_API_FAILED = 'FETCH_REWARD_HISTORY_API_FAILED'

export const FETCH_REWARD_REDEEM_HISTORY = 'FETCH_REWARD_REDEEM_HISTORY'
export const FETCH_REWARD_REDEEM_HISTORY_SUCCESSFUL =
  'FETCH_REWARD_REDEEM_HISTORY_SUCCESSFUL'
export const FETCH_REWARD_REDEEM_HISTORY_API_FAILED =
  'FETCH_REWARD_REDEEM_HISTORY_API_FAILED'

export const FETCH_EACH_REWARD = 'FETCH_EACH_REWARD'
export const FETCH_EACH_REWARD_SUCCESSFUL = 'FETCH_EACH_REWARD_SUCCESSFUL'
export const FETCH_EACH_REWARD_API_FAILED = 'FETCH_EACH_REWARD_API_FAILED'

export const DELETE_REWARD = 'DELETE_REWARD'
export const DELETE_REWARD_SUCCESSFUL = 'DELETE_REWARD_SUCCESSFULLY'
export const DELETE_REWARD_FAILED = 'DELETE_REWARD_FAILED'

export const CREATE_REWARD = 'CREATE_REWARD'
export const CREATE_REWARD_SUCCESSFUL = 'CREATE_REWARD_SUCCESSFULLY'
export const CREATE_REWARD_FAILED = 'CREATE_REWARD_FAILED'

export const UPDATE_REWARD = 'UPDATE_REWARD'
export const UPDATE_REWARD_SUCCESSFUL = 'UPDATE_REWARD_SUCCESSFULLY'
export const UPDATE_REWARD_FAILED = 'FAILED'

// REWARD Summary
export const FETCH_REWARD_SUMMARY = 'FETCH_REWARD_SUMMARY'
export const FETCH_REWARD_SUMMARY_FAILED = 'FETCH_REWARD_SUMMARY_FAILED'
export const FETCH_REWARD_SUMMARY_SUCCESSFULLY =
  'FETCH_REWARD_SUMMARY_SUCCESSFULLY'
