import { all } from "redux-saga/effects";

//public
import accountSaga from "./auth/saga";
import LayoutSaga from "./layout/saga";
import userSaga from "./users/saga";
import Packages from "./packages/saga";
import AppConfigs from "./appConfigs/saga";
import Activities from "./activities/saga";
import Rides from "./rides/saga";
import PromoCode from "./promoCode/saga";
import DocType from "./docType/saga";
import Document from "./document/saga";
import Vehicle from "./vehicle/saga";
import Complaint from "./complaint/saga";
import Bank from "./bank/saga";
import Payment from "./payment/saga";
import Cashout from "./cashout/saga";
import Bonus from "./bonus/saga";
import Notifications from "./notifications/saga";
import Review from "./review/saga";
import Reward from "./reward/saga";
import Loyalty from "./loyalty/saga";
import LoyaltyTier from "./loyaltyTier/saga";
import Dashboard from "./dashboard/saga";
import Zone from "./zone/saga";
import Roles from "./roles/saga";
import Contact from "./contact/saga";
import App_version from "./appVersion/saga";
import LicenseKey from "./licenseKey/saga";
import Invitation from "./invitation/saga";
import AnalyticCashout from "./analytics/cashout/saga";
import NetworkCheckerSaga from "./networkChecker/saga";
import BusStop from "./busStop/saga";
import Route from "./route/saga";
import Tour from "./tour/saga";

export default function* rootSaga() {
  yield all([
    //public
    accountSaga(),
    LayoutSaga(),
    Reward(),
    userSaga(),
    Packages(),
    AppConfigs(),
    Rides(),
    PromoCode(),
    DocType(),
    Document(),
    Vehicle(),
    Complaint(),
    Bank(),
    Payment(),
    Cashout(),
    Bonus(),
    Notifications(),
    Review(),
    Loyalty(),
    Dashboard(),
    LoyaltyTier(),
    Zone(),
    Roles(),
    Activities(),
    Contact(),
    Invitation(),
    AnalyticCashout(),
    App_version(),
    LicenseKey(),
    NetworkCheckerSaga(),
    BusStop(),
    Route(),
    Tour(),
  ]);
}
