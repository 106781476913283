import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Label, FormGroup, Button } from "reactstrap";
import { CustomSelect } from "react-multi-select-component";
import { fetchUserRoles, updateUserRolePackages } from "../../../store/actions";
const AssignedRole = ({
  vehicleDetails,
  userRoles,
  userId,
  message,
  closeModal,
  fetchUserRoles,
  setActivateModalRole,
  updateUserRoleFailed,
  updateUserRolePackages,
}) => {
  const [selected, setSelected] = useState([]);
  const [packageOption, SetPackageOption] = useState([]);

  useEffect(() => {
    fetchUserRoles();
  }, []);

  const updateUserRole = () => {
    let ambassadorRole = userRoles?.result?.find(
      (data) => data.name === "AMBASSADOR"
    );

    ambassadorRole = [{ id: ambassadorRole.id }];
    updateUserRolePackages(userId, ambassadorRole);
  };

  useEffect(() => {
    // if (userRoles) {
    //   const packageArr = [];
    //   userRoles.result.forEach((element) => {
    //     const packageObj = {};
    //     packageObj.value = element.id;
    //     packageObj.label = element.name;
    //     packageArr.push(packageObj);
    //   });
    //   SetPackageOption(packageArr);
    // }

    if (message) {
      setTimeout(() => {
        // updateUserRoleFailed('');
        closeModal(false);
      }, 2000);
    }
  }, [userRoles, message]);

  return (
    <div>
      <div md={6}>
        {/* <FormGroup>
          <Label htmlFor="packages"> Packages</Label>
          <CustomSelect
            options={packageOption}
            value={selected}
            onChange={setSelected}
            labelledBy="Packages"
          />
        </FormGroup> */}
        <span className="mb-2">
          {" "}
          Are you sure you want to assign{" "}
          <span className="font-weight-bold text-danger">Ambassador</span> to
          user?{" "}
        </span>
        <div>
          <Button
            color="success"
            size="sm"
            className="mr-1"
            onClick={updateUserRole}
          >
            Assign Ambassador
          </Button>
          <Button
            color="secondary"
            size="sm"
            onClick={() => setActivateModalRole(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userRoles, message, loading } = state.Users;
  return { loading, message, userRoles };
};

export default connect(mapStateToProps, {
  fetchUserRoles,
  updateUserRolePackages,
})(withRouter(AssignedRole));
