import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Form from './form';

import { fetchEachBusStop } from '../../../../store/actions';

const BusStopForm = ({
  fetchEachBusStop,
  busStop,
  loading,
  message,
}) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  useEffect(() => {
    if (id) {
      fetchEachBusStop(id);
    }
  }, []);



  return (
    <React.Fragment>
      <div className='page-content'>
        <Form
          loading={loading}
          defaultValue={busStop ? busStop : null}
          message={message}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { busStop, loading, busStopError, message } = state.BusStop;
  return { busStop, loading, busStopError, message };
};

export default connect(mapStateToProps, { fetchEachBusStop })(
  BusStopForm
);
