// @flow
import {
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_SUCCESSFULLY,
  UPDATE_USER_ROLE_FAILED,
  FETCH_USER_ROLE,
  FETCH_USER_ROLE_SUCCESSFULLY,
  FETCH_USER_ROLE_FAILED,
  FETCH_USER_EXPORT,
  FETCH_USER_EXPORT_SUCCESSFULLY,
  FETCH_USER_EXPORT_FAILED,
  FETCH_ACCOUNT_SUMMARY,
  FETCH_ACCOUNT_SUMMARY_SUCCESSFULLY,
  FETCH_ACCOUNT_SUMMARY_FAILED,
  FETCH_ACC_SUMMARY_CSV,
  FETCH_ACC_SUMMARY_CSV_SUCCESSFULLY,
  FETCH_ACC_SUMMARY_CSV_FAILED,
  FETCH_USER_SUMMARY,
  FETCH_USER_SUMMARY_SUCCESSFULLY,
  FETCH_USER_SUMMARY_FAILED,
  FETCH_BADGE_SUMMARY,
  FETCH_BADGE_SUMMARY_SUCCESSFULLY,
  FETCH_BADGE_SUMMARY_FAILED,
  DRIVER_LOGS_SUMMARY,
  DRIVER_LOGS_SUMMARY_SUCCESSFULLY,
  DRIVER_LOGS_SUMMARY_FAILED,
  FETCH_ALL_USER,
  FETCH_ALL_USER_SUCCESSFULLY,
  CREATE_USER,
  CREATE_USER_SUCCESSFUL,
  CREATE_USER_FAILED,
  INVITE_DRIVER,
  INVITE_DRIVER_FAILED,
  INVITE_DRIVER_SUCCESSFULLY,
  FETCH_USER_ERROR,
  FETCH_FOR_USER_CSV,
  FETCH_FOR_USER_CSV_SUCCESSFULLY,
  FETCH_FOR_USER_CSV_ERROR,
  FETCH_FOR_DRIVER_LOG_CSV,
  FETCH_FOR_DRIVER_LOG_CSV_SUCCESSFULLY,
  FETCH_FOR_DRIVER_LOG_CSV_ERROR,
  FETCH_FOR_DRIVER_LOGS_CSV,
  FETCH_FOR_DRIVER_LOGS_CSV_SUCCESSFULLY,
  FETCH_FOR_DRIVER_LOGS_CSV_ERROR,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESSFUL,
  UPDATE_USER_PROFILE_API_FAILED,
  ACTIVATE_USER_PROFILE,
  ACTIVATE_USER_PROFILE_SUCCESSFUL,
  ACTIVATE_USER_PROFILE_API_FAILED,
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_SUCCESSFULLY,
  FETCH_USER_DETAILS_ERROR,
  FETCH_USER_NO_LOADING,
  FETCH_USER_NO_LOADING_SUCCESSFULLY,
  FETCH_USER_NO_LOADING_ERROR,
  IS_USER_AUTHORIZE,
  FREEZE_ACCOUNT,
  FREEZE_ACCOUNT_SUCCESSFULLY,
  FREEZE_ACCOUNT_FAILED,
  FETCH_DRIVER_LOGS,
  FETCH_DRIVER_LOGS_SUCCESSFULLY,
  FETCH_DRIVER_LOGS_FAILED,
  FETCH_ALL_DRIVER_LOGS,
  FETCH_ALL_DRIVER_LOGS_SUCCESSFULLY,
  FETCH_ALL_DRIVER_LOGS_FAILED,
  DELETE_USER,
  DELETE_USER_SUCCESSFUL,
  DELETE_USER_FAILED,
  APPROVE_USER_ACCOUNT,
  APPROVE_USER_ACCOUNT_SUCCESSFULLY,
  APPROVE_USER_ACCOUNT_ERROR,
  CLEAR_USER,
  FETCH_DRIVER_ONLINE_LOGS,
  FETCH_DRIVER_ONLINE_LOGS_SUCCESSFULLY,
  FETCH_DRIVER_ONLINE_LOGS_FAILED,
  FETCH_DRIVER_HOURS_LOGS,
  FETCH_DRIVER_HOURS_LOGS_SUCCESSFULLY,
  FETCH_DRIVER_HOURS_LOGS_FAILED,
  FETCH_HOURS_CSV,
  FETCH_HOURS_CSV_SUCCESSFULLY,
  FETCH_HOURS_CSV_ERROR,
} from "./actionTypes";

const INIT_STATE = {
  loading: false,
  users: null,
  csvData: null,
  csv_loading: false,
  driverLogs: null,
  driverLogsSummaryCounts: null,
  driverLogsSummaryError: null,
  summaryCount: null,
  summaryError: null,
  badgeSummaryCount: null,
  badgeSummaryError: null,
  userError: null,
  tableError: null,
  freezeMessage: null,
  isAuthorize: null,
  forgetError: null,
  user: null,
  errorMessage: null,
  mainLoader: null,
  exportMessage: null,
  exportError: null,
  exportFile: null,
  userRoles: null,
  accountEntries: null,
  accountEntriesError: null,
  message: null,
  approveMessage: null,
  approveError: null,
  driverHoursLogs: null,
  driverHoursLogsError: null,
  driverOnlineLogs: null,
  driverOnlineLogsError: null,
  log_csv: null,
  log_csv_loading: false,
  all_logs_csv: null,
  all_logs_csv_loading: false,
  hour_csv: null,
  hour_csv_loading: false,
  acc_csv: null,
  userDetails: null,
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case IS_USER_AUTHORIZE:
      return {
        ...state,
        isAuthorize: action.payload,
      };

    case FETCH_USER_ROLE:
      return {
        ...state,
        userRoles: null,
        // loading: true,
        message: null,
      };

    case FETCH_USER_ROLE_SUCCESSFULLY:
      return {
        ...state,
        userRoles: action.payload,
        // loading: false,
        message: null,
      };

    case FETCH_USER_ROLE_FAILED:
      return {
        ...state,
        userRoles: null,
        // loading: false,
        message: action.payload,
      };

    case APPROVE_USER_ACCOUNT:
      return {
        ...state,
        errorMessage: null,
        // loading: true,
        message: null,
      };

    case APPROVE_USER_ACCOUNT_SUCCESSFULLY:
      return {
        ...state,
        userRoles: action.payload,
        errorMessage: null,
        // loading: false,
        message: "Document approver successfully",
      };

    case APPROVE_USER_ACCOUNT_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        // loading: false,
        message: null,
      };

    case UPDATE_USER_ROLE:
      return {
        ...state,
        user: null,
        // loading: true,
        message: null,
      };

    case UPDATE_USER_ROLE_SUCCESSFULLY:
      return {
        ...state,
        user: action.payload.result,
        userError: null,
        // loading: false,
        message: "User role updated successfully",
      };

    case UPDATE_USER_ROLE_FAILED:
      return {
        ...state,
        // userRoles: null,
        // loading: false,
        userError: action.payload,
      };

    case FREEZE_ACCOUNT:
      return {
        ...state,
        freezeMessage: null,
        loading: true,
      };
    case CLEAR_USER:
      return {
        ...state,
        users: null,
        // loading: true,
      };
    case FETCH_ACCOUNT_SUMMARY:
      return {
        ...state,
        // accountEntries: null,
        accountEntriesError: null,
        // loading: true,
      };

    case FETCH_USER_SUMMARY:
      return {
        ...state,
        summaryCount: null,
        summaryError: null,
        loading: true,
      };

    case FETCH_BADGE_SUMMARY:
      return {
        ...state,
        badgeSummaryCount: null,
        badgeSummaryError: null,
        loading: true,
      };

    case FETCH_BADGE_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        badgeSummaryCount: action.payload,
        badgeSummaryError: null,
        loading: false,
      };

    case FETCH_BADGE_SUMMARY_FAILED:
      return {
        ...state,
        badgeSummaryCount: null,
        badgeSummaryError: action.payload,
        loading: false,
      };

    case FETCH_USER_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        summaryCount: action.payload,
        summaryError: null,
        loading: false,
      };

    case FETCH_USER_SUMMARY_FAILED:
      return {
        ...state,
        summaryCount: null,
        summaryError: action.payload,
        loading: false,
      };

    case DRIVER_LOGS_SUMMARY:
      return {
        ...state,
        driverLogsSummaryCounts: null,
        driverLogsSummaryError: null,
        loading: true,
      };

    case DRIVER_LOGS_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        driverLogsSummaryCounts: action.payload,
        driverLogsSummaryError: null,
        loading: false,
      };

    case DRIVER_LOGS_SUMMARY_FAILED:
      return {
        ...state,
        driverLogsSummaryCounts: null,
        driverLogsSummaryError: action.payload,
        loading: false,
      };

    case UPDATE_USER_PROFILE:
    case ACTIVATE_USER_PROFILE:
      return {
        ...state,
        loading: true,
        message: null,
        approveError: null,
        approveMessage: null,
      };

    case UPDATE_USER_PROFILE_SUCCESSFUL:
    case ACTIVATE_USER_PROFILE_SUCCESSFUL:
      return {
        ...state,
        user: action.payload,
        loading: false,
        message: "Profile Update Successfully",
        approveMessage: "Driver approved successfully",
      };

    case UPDATE_USER_PROFILE_API_FAILED:
    case ACTIVATE_USER_PROFILE_API_FAILED:
      return {
        ...state,
        loading: false,
        message: null,
        forgetError: action.payload,
        approveError: action.payload,
      };

    case FETCH_ACCOUNT_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        accountEntries: action.payload,
        loading: false,
        accountEntriesError: null,
      };

    case FETCH_ACCOUNT_SUMMARY_FAILED:
      return {
        ...state,
        accountEntriesError: action.payload,
        // loading: false,
        accountEntries: null,
      };

    case FREEZE_ACCOUNT_SUCCESSFULLY:
      let freezeUser = action.payload.data.result;
      let clonedUsers = state.users;
      let result = clonedUsers.result.map((obj) =>
        freezeUser.id === obj.id ? freezeUser : obj
      );
      clonedUsers.result = result;
      return {
        ...state,
        users: clonedUsers,
        freezeMessage: action.payload,
        loading: false,
      };

    case FETCH_ALL_USER:
    case FETCH_ALL_DRIVER_LOGS:
    case INVITE_DRIVER:
    case DELETE_USER:
    case CREATE_USER:
      return {
        ...state,
        tableError: null,
        users: null,
        driverLogs: null,
        loading: true,
        userError: null,
        isAuthorize: null,
        message: null,
        approveMessage: null,
        approveError: null,
        exportFile: null,
      };

    case FETCH_USER_EXPORT:
      return {
        ...state,
        exportMessage: null,
        exportError: null,
      };

    case CREATE_USER_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        userError: null,
        message: `User created successfully`,
      };

    case FETCH_DRIVER_LOGS:
      return {
        ...state,
        driverLogs: null,
        // loading: true,
        userError: null,
        isAuthorize: null,
        message: null,
        approveMessage: null,
        approveError: null,
      };

    case FETCH_DRIVER_ONLINE_LOGS:
      return {
        ...state,
        driverOnlineLogs: null,
        driverOnlineLogsError: null,
        message: null,
      };

    case FETCH_DRIVER_HOURS_LOGS:
      return {
        ...state,
        driverHoursLogs: null,
        driverHoursLogsError: null,
        message: null,
      };

    case FETCH_FOR_USER_CSV:
      return {
        ...state,
        csvData: null,
        csv_loading: false,
      };

    case FETCH_ACC_SUMMARY_CSV:
      return {
        ...state,
        acc_csv: null,
        csv_loading: false,
      };

    case FETCH_USER_DETAILS:
      return {
        ...state,
        mainLoader: true,
        // user: null,
        tableError: null,
        loading: true,
        isAuthorize: null,
      };

    case FETCH_USER_NO_LOADING:
      return {
        ...state,
        // user: null,
        tableError: null,
        isAuthorize: null,
      };

    case FETCH_USER_DETAILS_SUCCESSFULLY:
    case FETCH_USER_NO_LOADING_SUCCESSFULLY:
      return {
        ...state,
        mainLoader: false,
        user: action.payload,
        userDetails: action.payload,
        loading: false,
      };

    case FETCH_ALL_USER_SUCCESSFULLY:
    case INVITE_DRIVER_SUCCESSFULLY:
      return {
        ...state,
        users: action.payload,
        tableError: null,
        loading: false,
        userError: null,
      };

    case FETCH_USER_EXPORT_SUCCESSFULLY:
      return {
        ...state,
        exportMessage: "Exported Successfully",
        exportError: null,
        exportFile: action.payload,
      };
    case FETCH_USER_EXPORT_FAILED:
      return {
        ...state,
        exportFile: null,
        exportMessage: null,
        exportError:
          action.payload?.constructor === String
            ? action.payload
            : "Error in processing data",
      };
    case FETCH_FOR_USER_CSV_SUCCESSFULLY:
      return {
        ...state,
        csvData: action.payload,
        csv_loading: false,
      };

    case FETCH_ACC_SUMMARY_CSV_SUCCESSFULLY:
      return {
        ...state,
        acc_csv: action.payload,
        csv_loading: false,
      };

    case FETCH_FOR_DRIVER_LOG_CSV:
      return {
        ...state,
        log_csv: null,
        log_csv_loading: false,
      };
    case FETCH_FOR_DRIVER_LOG_CSV_SUCCESSFULLY:
      return {
        ...state,
        log_csv: action.payload,
        log_csv_loading: false,
      };
    case FETCH_FOR_DRIVER_LOG_CSV_ERROR:
      return {
        ...state,
        driverLogs: null,
        log_csv: null,
        log_csv_loading: false,
      };

    case FETCH_FOR_DRIVER_LOGS_CSV:
      return {
        ...state,
        all_logs_csv: null,
        all_logs_csv_loading: false,
      };
    case FETCH_FOR_DRIVER_LOGS_CSV_SUCCESSFULLY:
      return {
        ...state,
        all_logs_csv: action.payload,
        all_logs_csv_loading: false,
      };
    case FETCH_FOR_DRIVER_LOGS_CSV_ERROR:
      return {
        ...state,
        all_logs_csv: null,
        all_logs_csv_loading: false,
      };

    case FETCH_ALL_DRIVER_LOGS_SUCCESSFULLY:
    case FETCH_DRIVER_LOGS_SUCCESSFULLY:
      return {
        ...state,
        // users: action.payload,
        tableError: null,
        driverLogs: action.payload,
        loading: false,
        userError: null,
      };

    case FETCH_DRIVER_ONLINE_LOGS_SUCCESSFULLY:
      return {
        ...state,
        tableError: null,
        driverOnlineLogs: action.payload,
        loading: false,
        driverOnlineLogsError: null,
      };
    case FETCH_DRIVER_HOURS_LOGS_SUCCESSFULLY:
      return {
        ...state,
        tableError: null,
        driverHoursLogs: action.payload,
        loading: false,
        driverHoursLogsError: null,
      };

    case DELETE_USER_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        userError: null,
        message: action.payload,
      };

    case FETCH_ACC_SUMMARY_CSV_FAILED:
    case FETCH_FOR_USER_CSV_ERROR:
      return {
        ...state,
        csvData: null,
        acc_csv: null,
        csv_loading: false,
      };
    case FETCH_USER_ERROR:
    case FETCH_DRIVER_LOGS_FAILED:
    case FETCH_ALL_DRIVER_LOGS_FAILED:
    case INVITE_DRIVER_FAILED:
      return {
        ...state,
        driverLogs: null,
        tableError:
          action.payload?.constructor === String
            ? action.payload
            : "Error in processing data",
      };
    case FETCH_DRIVER_ONLINE_LOGS_FAILED:
      return {
        ...state,
        driverOnlineLogs: null,
        driverOnlineLogsError:
          action.payload?.constructor === String
            ? action.payload
            : "Error in processing data",
      };

    case FETCH_DRIVER_HOURS_LOGS_FAILED:
      return {
        ...state,
        driverHoursLogs: null,
        driverHoursLogsError:
          action.payload?.constructor === String
            ? action.payload
            : "Error in processing data",
      };

    case FREEZE_ACCOUNT_FAILED:
    case FETCH_USER_DETAILS_ERROR:
    case FETCH_USER_NO_LOADING_ERROR:
    case DELETE_USER_FAILED:
    case CREATE_USER_FAILED:
      return {
        ...state,

        mainLoader: false,
        users: null,
        loading: false,
        user: null,
        userError: action.payload,
        freezeMessage: null,
        message: null,
        userDetails: null,
      };
    case FETCH_HOURS_CSV:
      return {
        ...state,
        hour_csv: null,
        hour_csv_loading: true,
      };

    case FETCH_HOURS_CSV_SUCCESSFULLY:
      return {
        ...state,

        hour_csv: action.payload,
        hour_csv_loading: false,
      };

    case FETCH_HOURS_CSV_ERROR:
      return {
        ...state,
        hour_csv: null,
        hour_csv_loading: false,
      };

    default:
      return state;
  }
};

export default User;
