import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetCashoutService = (query) => {
  const http = new HttpService();
  let url = 'cashout/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }

  return http.getData(url);
};

export const GetEachCashoutService = (id) => {
  const http = new HttpService();
  const url = `cashout/${id}`;
  return http.getData(url);
};
export const GetMyCashoutService = () => {
  const http = new HttpService();
  const url = `cashout`;
  return http.getData(url);
};

export const UpdateCashoutService = (data) => {
  const http = new HttpService();
  let url = `cashout/${data.id}`;
  return http.putData(data, url);
};

export const UpdateCashoutStatusService = (data) => {
  const http = new HttpService();
  let url = `cashout/${data.payload.id}/${data.payload.event}`;
  return http.putData(data.payload, url);
};

export const DeleteCashoutService = (id) => {
  const http = new HttpService();
  const url = `cashout/${id}`;
  return http.deleteData(url);
};

export const CreateCashoutService = (payload) => {
  const http = new HttpService();
  const url = 'cashout';
  return http.postData(payload, url);
};

export const fetchCashoutSummaryService = (query) => {
  // Payload with query
  const http = new HttpService();
  let url = 'summary/cashout';
  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};
