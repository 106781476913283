export const IS_DOCTYPE_AUTHORIZE = 'IS_DOCTYPE_AUTHORIZE';

export const FETCH_DOCTYPE = 'FETCH_DOCTYPE';
export const FETCH_DOCTYPE_SUCCESSFUL = 'FETCH_DOCTYPE_SUCCESSFUL';
export const FETCH_DOCTYPE_API_FAILED = 'FETCH_DOCTYPE_API_FAILED';

export const FETCH_EACH_DOCTYPE = 'FETCH_EACH_DOCTYPE';
export const FETCH_EACH_DOCTYPE_SUCCESSFUL = 'FETCH_EACH_DOCTYPE_SUCCESSFUL';
export const FETCH_EACH_DOCTYPE_API_FAILED = 'FETCH_EACH_DOCTYPE_API_FAILED';

export const DELETE_DOCTYPE = 'DELETE_DOCTYPE';
export const DELETE_DOCTYPE_SUCCESSFUL = 'DELETE_DOCTYPE_SUCCESSFULLY';
export const DELETE_DOCTYPE_FAILED = 'DELETE_DOCTYPE_FAILED';

export const CREATE_DOCTYPE = 'CREATE_DOCTYPE';
export const CREATE_DOCTYPE_SUCCESSFUL = 'CREATE_DOCTYPE_SUCCESSFULLY';
export const CREATE_DOCTYPE_FAILED = 'CREATE_DOCTYPE_FAILED';

export const UPDATE_DOCTYPE = 'UPDATE_DOCTYPE';
export const UPDATE_DOCTYPE_SUCCESSFUL = 'UPDATE_DOCTYPE_SUCCESSFULLY';
export const UPDATE_DOCTYPE_FAILED = 'FAILED';

export const INITIALIZE_DOCTYPE = 'INITIALIZE_DOCTYPE';
export const INITIALIZE_DOCTYPE_SUCCESSFUL = 'INITIALIZE_DOCTYPE_SUCCESSFULLY';
export const INITIALIZE_DOCTYPE_FAILED = 'FAILED';
