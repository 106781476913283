import HttpService from './HttpService'
import { QueryReallignment } from '../utils/general'

export const GetBankService = (query) => {
  const http = new HttpService()
  let url = 'bank/all'

  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }

  return http.getData(url)
}

export const GetBankListService = (query) => {
  const http = new HttpService()
  let url = 'bank/list'

  return http.getData(url)
}

export const GetBankRecipientService = (id) => {
  const http = new HttpService()
  let url = `/bank/${id}/recipient`
  return http.getData(url)
}

export const GetEachBankService = (id) => {
  const http = new HttpService()
  const url = `bank/${id}`
  return http.getData(url)
}
export const GetMyBankService = () => {
  const http = new HttpService()
  const url = `bank`
  return http.getData(url)
}
export const CreateBankService = (payload) => {
  const http = new HttpService()
  const url = 'bank'
  return http.postData(payload, url)
}
export const UpdateBankService = ({ data, query }) => {
  const http = new HttpService()
  let url = `bank/${data.id}${data.review ? `/review` : ''}`
  if (query) {
    url = `bank/${data.id}/review`
  }
  return http.putData(data, url)
}

export const DeleteBankService = (id) => {
  const http = new HttpService()
  const url = `bank/${id}`
  return http.deleteData(url)
}

export const fetchBankSummaryService = (query) => {
  // Payload with query
  const http = new HttpService()
  let url = 'summary/bank'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}
