import React, { useState, useEffect } from 'react';
import { fetctUserBonus } from '../../../store/actions';
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Modal,
  Alert,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
} from 'reactstrap';
import FilterDropDown from '../../../components/Common/FilterDropDown';
import { QueryReallignment, ScrollToTop } from '../../../utils/general';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SimpleBar from 'simplebar-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PreviewUserBonus from './PreviewUserBonus';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactPaginate from 'react-paginate';
import Loader from '../Loading/index.js';
import Moment from 'moment';
import moment from 'moment-timezone';

let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const UserBonus = ({
  user,
  fetctUserBonus,
  userBonus,
  eachUserBonus,
  loading,
  bonusError,
  message,
  user_location
}) => {
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [PreviewId, setPreviewId] = useState(null);
  const [menu, setMenu] = useState(false);
  const [refreshTimer, setRefreshTimer] = useState(0);
  const [startTimer, setStartTimer] = useState(0);
  const [activeTab, SetActiveTab] = useState('1');
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [userInfo, setUserInfo] = useState(user.id);
  const [isActive, setActive] = useState('');

  const setPreviewHandler = (id) => {
    setPreviewId(id);
    setShowModal(true);
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }

    let pageInfo = { skip, take };
    fetctUserBonus({ userId: user.id }, pageInfo);
    ScrollToTop();
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate });
    setMenu(false);
    let pageInfo = {
      take,
      skip,
      // status: values.vehicleStatus,
      start: values.startDate,
      end: values.startDate,
      // end: values.endDate,
    };
    console.log(values.startDate);

    fetctUserBonus({ userId: user.id }, pageInfo);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: '',
    });
    let pageInfo = {
      take,
      skip,
    };
    fetctUserBonus({ userId: user.id }, pageInfo);
  };

  useEffect(() => {
    if (userBonus) {
      // SetUrlWithParams()
      setData(tableDataHandler());
      setIsLoading(false);
      setPageCount(Math.ceil(userBonus?.user?.total / take));
    }
  }, [userBonus]);

  const tableDataHandler = () => {
    const rowData = userBonus?.user?.result?.map((data, i) => {
      return {
        keyId: data.id,
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,

        id: data.id.substring(0, 7),
        accountName: (
          <Link to="#" className="text-dark font-weight-bold">
            {data.name}
          </Link>
        ),
        ride: (
          <span>
            <span className="d-block">
              <span>Ride-{data?.requirements?.ride}</span>
            </span>
            <span className="d-block">
              <span className="badge badge-soft-success ">
                Online-{data?.requirements?.online}
              </span>
            </span>
          </span>
        ),
        acceptance: (
          <span>
            <span className="d-block">
              <span>
                Acceptance-
                {data?.requirements?.acceptance
                  ? `${data.requirements?.acceptance * 100}%`
                  : 0}
              </span>
            </span>
            <span className="d-block">
              <span className="badge badge-soft-warning ">
                Completion-
                {data?.requirements?.completions
                  ? `${data.requirements?.completions * 100}%`
                  : 0}
              </span>
            </span>
          </span>
        ),
        createdAt: Moment(data.updatedAt).format('DD/MM/YYYY'),
        value: <span> ₦{data.value} </span>,
        status: (
          <h1
            className={`badge badge-soft-${data.status.toLowerCase() === 'active'
              ? 'success'
              : data.status.toLowerCase() === 'inactive'
                ? 'danger'
                : data.status.toLowerCase() === 'sanction'
                  ? 'warning'
                  : 'dark'
              } font-size-12`}
          >
            {data.status ? data.status : '---'}
          </h1>
        ),
        action: (
          <>
            <Link
              to="#"
              onClick={() => setPreviewHandler(data.id)}
              className="mr-3 text-secondary"
              id="preview"
            >
              <i className="mdi mdi-eye font-size-18"></i>
            </Link>
            <UncontrolledTooltip placement="bottom" target="preview">
              Preview
            </UncontrolledTooltip>
          </>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
        },
        {
          text: 'Bonus Id',
          dataField: 'id',
        },
        {
          text: 'Bonus Name',
          dataField: 'accountName',
          sort: true,
        },
        {
          text: 'Value',
          dataField: 'value',
        },
        {
          text: 'Rides/Online',
          dataField: 'ride',
        },
        {
          text: 'Acceptance/ Completion',
          dataField: 'acceptance',
        },
        {
          text: 'CreateAt',
          dataField: 'createdAt',
        },
        {
          text: 'Status',
          dataField: 'status',
        },

        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  useEffect(() => {
    if (user_location) {
      fetctUserBonus({ userId: user.id, lat: user_location?.result?.lat, lng: user_location?.result?.lng });
    }
  }, [user_location]);
  return (
    <Card className="mb-2">
      <CardBody className="pt-0">
        {bonusError && (
          <Alert color="danger" className="text-center">
            {bonusError}
          </Alert>
        )}
        {bonusError === null &&
          (!isLoading && data !== null ? (
            <>
              <div className="d-flex justify-content-end  align-items-center mb-2">
                <div>
                  <FilterDropDown menu={menu} toggleMenu={() => setMenu(!menu)}>
                    <div>
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={handleDateFilter}
                      >
                        <SimpleBar
                          style={{
                            maxHeight: '250px',
                          }}
                        >
                          <Row className="px-3  pt-3 pb-0">
                            <Col md="12">
                              <FormGroup>
                                <AvField
                                  name="startDate"
                                  type="date"
                                  className="form-control bg-light"
                                  id="startDate"
                                  value={date.start}
                                  helpMessage="Start Date"
                                  placeholder="Please choose the start date..."
                                  required
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </SimpleBar>

                        <div className="p-2 d-flex justify-content-between align-items-center bg-light">
                          <Link
                            to="#"
                            onClick={resetHandler}
                            className="btn btn-sm  bg-light font-size-14"
                          >
                            <i className=" mdi mdi-refresh mr-1"> Reset</i>
                          </Link>

                          <button
                            className="btn btn-success btn-sm mr-2"
                            type="submit"
                          >
                            <i className="mdi mdi-arrow-right-circle mr-1"></i>
                            Send
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </FilterDropDown>
                </div>
              </div>

              <BootstrapTable
                keyField="keyId"
                data={data.rows}
                columns={data.columns}
                striped
                hover
                wrapperClasses="table-responsive"
                noDataIndication={'No Data to Display'}
              />
              <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={'pagination justify-content-center'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </>
          ) : (
            <Loader loading={isLoading} />
          ))}
        <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
          <ModalHeader toggle={() => setShowModal(false)}>
            Bonus Preview
          </ModalHeader>
          <ModalBody>
            <PreviewUserBonus previewId={PreviewId} user={user} />
          </ModalBody>
        </Modal>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { eachUserBonus, userBonus, loading, bonusError, message } =
    state.Bonus;
  return { userBonus, loading, bonusError, message, eachUserBonus };
};

export default connect(mapStateToProps, {
  fetctUserBonus,
})(UserBonus);
