import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  Table,
  Input,
} from 'reactstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
// actions
import {
  createRole,
  updateRole,
  updateRoleFailed,
} from '../../../../store/actions';

let uniqueModule;
const Form = ({
  createRole,
  updateRole,
  updateRoleFailed,
  message,
  history,
  roleError,
  loading,
  role,
  privileges,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [descError, setError] = useState(false);
  const [modulesId, setModulesId] = useState([]);

  const handleCheck = (id) => {
    setModulesId((prevIds) => [...prevIds, { id }]);
  };

  const handleUncheck = (id) => {
    setModulesId((prevIds) => prevIds.filter((item) => item.id !== id));
  };

  const handleFormSubmit = (_event, value) => {
    setLoading(true);
    if (!description) {
      setError('Please key in the description');
      return;
    }

    let data = {
      name: value.name,
      description: description,
      privileges: modulesId,
    };

    if (role) {
      updateRole({ ...data, id: role.id });
      setLoading(false);
      return;
    }
    createRole(data);
    setLoading(false);
  };
  useEffect(() => {
    if (privileges) {
      uniqueModule = [...new Set(privileges.result.map((priv) => priv.module))];
    }
  }, [privileges]);

  useEffect(() => {
    if (role?.privileges?.length > 0) {
      role.privileges.forEach((e) => {
        setModulesId((prevIds) => [...prevIds, { id: e.id }]);
      });
    }
  }, [role]);

  useEffect(() => {
    setDescription('');

    if (role?.description) {
      setDescription(role?.description);
    }
    if (message || roleError) {
      setTimeout(() => {
        updateRoleFailed('');
        history.goBack();
      }, 3000);
    }
  }, [message, role, roleError, updateRoleFailed]);

  return (
    <Card className='col-md-9'>
      <CardBody>
        {message && (
          <Alert color='success' className='text-center'>
            {message}
          </Alert>
        )}
        {roleError && (
          <Alert color='danger' className='text-center'>
            {roleError}
          </Alert>
        )}

        {descError && (
          <Alert color='danger' className='text-center'>
            {descError}
          </Alert>
        )}
        <div className='float-right'>
          <Link to='#' onClick={() => history.goBack()}>
            <i className='fas fa-arrow-left mr-3' />
            Back
          </Link>
        </div>
        <div className='mb-3'>
          <h6> {role ? 'Update' : 'Create'} Role </h6>
        </div>
        <div className='col-md-12'>
          <AvForm className='form-horizontal' onValidSubmit={handleFormSubmit}>
            <Row className='mb-3'>
              <Col>
                <FormGroup className='mb-4'>
                  <Label htmlFor='name'>Role Name</Label>
                  <AvField
                    name='name'
                    type='text'
                    className='form-control bg-light'
                    required
                    errorMessage='please enter name of the role'
                    placeholder='Enter Role Name'
                    value={`${role ? role?.name : ''}`}
                  />
                </FormGroup>
                <FormGroup className='mb-4'>
                  <Label htmlFor='description'> Description</Label>
                  <textarea
                    className='form-control'
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    id='description'
                    rows='4'
                  ></textarea>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor='privileges'> Privileges</Label>
                  <div>
                    <Table
                      hover
                      className='mt-3 border d-block rounded mb-0 table-centered table-nowrap'
                      style={{
                        height: '250px',
                        overflow: 'auto',
                        width: 'fit-content',
                      }}
                    >
                      <thead
                        style={{
                          background: '#ebf3f1',
                          position: 'sticky',
                          top: '0',
                          zIndex: '99',
                        }}
                      >
                        <tr>
                          <th>Module List</th>
                          <th>Read</th>
                          <th>List</th>
                          <th>Create</th>
                          <th>Update</th>
                          <th>Delete</th>
                          <th>Special</th>
                        </tr>
                      </thead>
                      <tbody>
                        {uniqueModule?.map((element, key) => {
                          const priviledges = privileges?.result.filter(
                            (priv) => priv.module === element
                          );
                          const methods = [
                            'READ',
                            'LIST',
                            'CREATE',
                            'UPDATE',
                            'DELETE',
                            'ALL',
                          ];

                          return (
                            <tr key={element}>
                              <td>{element}</td>
                              {methods.map((method) => {
                                const privilege = priviledges?.find(
                                  (priv) => priv.method === method
                                );
                                const roleData = modulesId?.filter(
                                  (roleData) => roleData?.id === privilege?.id
                                );

                                return privilege ? (
                                  <td
                                    className='text-center'
                                    key={`${element}-${method}`}
                                  >
                                    <Input
                                      type='checkbox'
                                      name={method}
                                      value={`${privilege.module}${privilege.method}`}
                                      className='position-relative ml-0'
                                      style={{
                                        transform: 'scale(1.5)',
                                        filter: ' hue-rotate(240deg)',
                                      }}
                                      checked={roleData[0]?.id}
                                      onChange={(e) =>
                                        e.target.checked
                                          ? handleCheck(privilege.id)
                                          : handleUncheck(privilege.id)
                                      }
                                    />
                                  </td>
                                ) : (
                                  <td
                                    key={`${element}-${method}`}
                                    className='text-center'
                                  >
                                    {' '}
                                    -{' '}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <button className='btn btn-success mr-2' type='submit'>
              {isLoading ? 'Submitting ...' : `${role ? 'Update' : 'Create'}`}
            </button>
            <Link to='#' onClick={() => history.goBack()}>
              <button className='btn btn-secondary'>Cancel</button>
            </Link>
          </AvForm>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, roleError } = state.Role;
  return { message, loading, roleError };
};

export default connect(mapStateToProps, {
  createRole,
  updateRole,
  updateRoleFailed,
})(withRouter(Form));
