import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { Container, Card, CardBody, Col, Row, Alert } from 'reactstrap';
import GreySummaryCard from '../../../components/Common/Summary/index';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import CheckAction, { ReadCheck } from '../../../components/Common/Summary/actionCheck';
import MiniWidgets from './Components/MiniWidgets';
//Import Action to copy breadcrumb items from local state to redux state
import ReviewTable from './reviewTable';
import { fetchReviews } from '../../../store/actions';

const UserReview = ({
  message,
  history,
  fetchReviews,
  loading,
  reviews,
  reviewsError,
  accessControl
}) => {
  const [dateFilter, setDate] = useState({});
  const breadcrumbItems = [
    { title: 'Rider Review', link: '#' },
    { title: 'Dashboard', link: '#' },
  ];
  let skip = 0;
  let dataLimit = 10;
  const date = (e) => {
    setDate(e);
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Rider Review' breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col>
              <GreySummaryCard
                field='Rating & Review'
                subModule='Rider Reviews'
              >
                <Col sm={12}>
                  <Row>
                    <MiniWidgets userType={'RIDER'} date={dateFilter} />
                  </Row>
                </Col>
                {message && (
                  <Alert color='success' className='text-center'>
                    {message}
                  </Alert>
                )}
                {reviewsError ? (
                  <Alert className='text-center' color='danger'>
                    {reviewsError}
                  </Alert>
                ) : (
                  <ReviewTable
                    reviewsData={reviews}
                    loading={loading}
                    fetchReviews={fetchReviews}
                    reviewer={''}
                    userType={'RIDER'}
                    message={message}
                    history={history}
                    skip={skip}
                    dataLimit={dataLimit}
                    reviewsError={reviewsError}
                    CheckAction={CheckAction}
                    ReadCheck={ReadCheck}
                    accessControl={accessControl}
                    subModule={'Rider Review'}
                  />
                )}
              </GreySummaryCard>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { reviews, loading, reviewsError, message } = state.Review;
  return { accessControl, reviews, loading, reviewsError, message };
};

export default connect(mapStateToProps, { fetchReviews })(UserReview);
