import React from 'react'
import { Row, Col, Container } from 'reactstrap'

const Footer = ({ user }) => {

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={4}>{new Date().getFullYear()} © nRyde.</Col>
            <Col sm={4}>
              <div className="text-center font-size-13 d-none d-sm-block">
                License :

                <span
                  className='ml-2 badge badge-soft-dark font-weight-bold'
                >{user.licenseName}</span>
              </div>
            </Col>
            <Col sm={4}>
              <div className="text-sm-right d-none d-sm-block">
                Version : {process.env.REACT_APP_ENV} {process.env.REACT_APP_SITE_VERSION}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
