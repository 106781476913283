import {
  FETCH_BUS_STOPS,
  FETCH_BUS_STOPS_SUCCESSFUL,
  FETCH_BUS_STOPS_API_FAILED,
  FETCH_EACH_BUS_STOP,
  FETCH_EACH_BUS_STOP_SUCCESSFUL,
  FETCH_EACH_BUS_STOP_API_FAILED,
  DELETE_BUS_STOP,
  DELETE_BUS_STOP_SUCCESSFUL,
  DELETE_BUS_STOP_FAILED,
  CREATE_BUS_STOP,
  CREATE_BUS_STOP_SUCCESSFUL,
  CREATE_BUS_STOP_FAILED,
  UPDATE_BUS_STOP,
  UPDATE_BUS_STOP_SUCCESSFUL,
  UPDATE_BUS_STOP_FAILED,
} from './actionTypes';

const initialState = {
  busStops: null,
  busStopError: null,
  tableError: null,
  busStop: null,
  isAuthorize: null,
  message: null,
  loading: false,
};

const BusStop = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUS_STOPS:
    case UPDATE_BUS_STOP:
    case DELETE_BUS_STOP:
      state = {
        ...state,
        busStops: null,
        tableError: null,
        busStopError: null,
        loading: true,
        message: null,
        isAuthorize: null,
      };
      break;
    case FETCH_BUS_STOPS_SUCCESSFUL:
      state = {
        ...state,
        busStops: action.payload,
        tableError: null,
        busStopError: null,
        loading: false,
      };
      break;

    case DELETE_BUS_STOP_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        busStopError: null,
        message: 'BusStop deleted successfully',
      };
      break;

    case UPDATE_BUS_STOP_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        busStopError: null,
        message: 'BusStop Updated Successfully',
      };
      break;

    case FETCH_EACH_BUS_STOP:
      state = {
        ...state,
        loading: true,
        busStops: null,
        busStopError: null,
        message: null,
        isAuthorize: null,
      };
      break;

    case FETCH_EACH_BUS_STOP_SUCCESSFUL:
      state = {
        ...state,
        busStop: action.payload,
        loading: false,
        busStopError: null,
        message: null,
      };
      break;

    case CREATE_BUS_STOP:
      state = {
        ...state,
        loading: true,
        busStopError: null,
        message: null,
      };
      break;

    case CREATE_BUS_STOP_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        busStopError: null,
        message: `BusStop  created successfully`,
      };
      break;

    case FETCH_BUS_STOPS_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case CREATE_BUS_STOP_FAILED:
    case DELETE_BUS_STOP_FAILED:
    case FETCH_EACH_BUS_STOP_API_FAILED:
      state = {
        ...state,
        busStops: null,
        busStop: null,
        loading: false,
        message: null,
        busStopError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_BUS_STOP_FAILED:
      state = {
        ...state,
        loading: false,
        busStopError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default BusStop;
