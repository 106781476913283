import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { Row, Col, Container, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Table from './table';
import MiniWidgets from './components/MiniWidgets.js';
import GreySummaryCard from '../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../components/Common/Summary/actionCheck';

import {
  fetchPayment,
  updatePayment,
  updatePaymentFailed,
  getRecipient,
  fetchPaymentCSV,
  fetchPaymentCSVError,
} from '../../../store/actions';

const Payment = ({
  payment,
  fetchPayment,
  updatePaymentFailed,
  tableError,
  getRecipient,
  accessControl,
  isAuthorize,
  loading,
  message,
  history,
  fetchPaymentCSV,
  fetchPaymentCSVError,
  csvData,
  csv_loading,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [dateFilter, setDate] = useState({});
  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'Account', link: '#' },
    { title: 'Payment', link: '/payment' },
  ];

  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        updatePaymentFailed('');
        setStatusModal('');
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    updatePaymentFailed('');
  }, []);

  const showStatusModal = (info) => {
    setBankDetails(info);
    setStatusModal(true);
    getRecipient(info.id);
  };

  const date = (e) => {
    setDate(e);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Payment" breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field="Finance" subModule="Payments">
            {isAuthorize ? (
              <Alert color="danger" className="text-center">
                {isAuthorize}
              </Alert>
            ) : (
              <>
                {message && (
                  <Alert color="success" className="text-center">
                    {message}
                  </Alert>
                )}
                {tableError && (
                  <Alert color="danger" className="text-center">
                    {tableError}
                  </Alert>
                )}
                <Row>
                  <Col>
                    <Col sm={12}>
                      <Row>
                        <MiniWidgets date={dateFilter} />
                      </Row>
                    </Col>
                    <Table
                      paymentData={payment}
                      dateFilter={date}
                      loading
                      fetchPayment={fetchPayment}
                      previewAction={previewHandler}
                      statusHandler={showStatusModal}
                      message={message}
                      history={history}
                      fetchPaymentCSV={fetchPaymentCSV}
                      fetchPaymentCSVError={fetchPaymentCSVError}
                      csvData={csvData}
                      csv_loading={csv_loading}
                      tableError={tableError}
                      CheckAction={CheckAction}
                      ReadCheck={ReadCheck}
                      accessControl={accessControl}
                    />
                  </Col>
                </Row>
              </>
            )}
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const {
    csvData,
    csv_loading,
    payment,
    loading,
    tableError,
    message,
    isAuthorize,
  } = state.Payment;
  return {
    csvData,
    csv_loading,
    accessControl,
    payment,
    loading,
    tableError,
    message,
    isAuthorize,
  };
};

export default connect(mapStateToProps, {
  fetchPayment,
  getRecipient,
  updatePayment,
  updatePaymentFailed,
  fetchPaymentCSV,
  fetchPaymentCSVError,
})(Payment);
