import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import Form from './form';

import { fetchEachPromoCode } from '../../../../store/actions';

const PromoCodeForm = ({
  fetchEachPromoCode,
  promoCodePreview,
  loading,
  message,
}) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  useEffect(() => {
    if (id) {
      fetchEachPromoCode(id);
    }
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        {promoCodePreview ? (
          <Form
            loading={loading}
            defaultValue={promoCodePreview}
            message={message}
          />
        ) : (
          <Form loading={loading} message={message} defaultValue={null} />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { promoCodePreview, loading, promoCodeError, message } =
    state.PromoCode;
  return { promoCodePreview, loading, promoCodeError, message };
};

export default connect(mapStateToProps, { fetchEachPromoCode })(PromoCodeForm);
