/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { Alert, Row, Col, Table } from 'reactstrap';
import Loader from '../../../../components/Common/Loading/index.js';
import { fetchEachApp_version } from '../../../../store/actions.js';
import { Link } from 'react-router-dom';

const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const PreviewAppverion = ({
  app_version,
  previewId,
  loading,
  app_versionError,
  fetchEachApp_version,
}) => {
  useEffect(() => {
    fetchEachApp_version(previewId);
  }, [fetchEachApp_version, previewId]);

  return (
    <React.Fragment>
      <div className='mt-3'>
        {app_versionError && !loading ? (
          <Alert color='danger' className='text-center'>
            {app_versionError}
          </Alert>
        ) : (
          <>
            {app_version === null ? (
              <Loader />
            ) : (
              <Row>
                <Col sm={12}>
                  <div>
                    <Table hover>
                      <tbody>
                        <tr>
                          <td className='font-weight-bold'>
                            App_version Name{' '}
                          </td>
                          <td className='text-uppercase font-size-16 font-weight-bold text-success'>
                            {app_version?.name}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Description</td>
                          <td className='text-uppercase'>
                            {app_version?.description}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Type</td>
                          <td className='text-uppercase'>
                            {app_version?.type}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Version</td>
                          <td className='text-uppercase'>
                            {app_version?.version}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Build</td>
                          <td className='text-uppercase'>
                            {app_version?.build}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Expiry</td>
                          <td className='text-uppercase'>
                            {app_version?.isExpired ? 'Yes' : 'No'}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Created At</td>
                          <td className='text-uppercase'>
                            {Moment(app_version.createdAt).format('l')}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { app_version, loading, app_versionError } = state.App_version;
  return { app_version, loading, app_versionError };
};

export default connect(mapStateToProps, {
  fetchEachApp_version,
})(PreviewAppverion);
