// Load User
export const CLEAR_USER = 'CLEAR_USER'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCESSFUL = 'CREATE_USER_SUCCESSFULLY'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'

export const FETCH_ALL_USER = 'FETCH_ALL_USER'
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR'
export const FETCH_ALL_USER_SUCCESSFULLY = 'FETCH_ALL_USER_SUCCESSFULLY'

export const FETCH_USER_EXPORT = 'FETCH_USER_EXPORT'
export const FETCH_USER_EXPORT_FAILED = 'FETCH_USER_EXPORT_FAILED'
export const FETCH_USER_EXPORT_SUCCESSFULLY = 'FETCH_USER_EXPORT_SUCCESSFULLY'

export const FETCH_USER_ROLE = 'FETCH_USER_ROLE'
export const FETCH_USER_ROLE_SUCCESSFULLY = 'FETCH_USER_ROLE_SUCCESSFULLY'
export const FETCH_USER_ROLE_FAILED = 'FETCH_USER_ROLE_FAILED'

export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE'
export const UPDATE_USER_ROLE_SUCCESSFULLY = 'UPDATE_USER_ROLE_SUCCESSFULLY'
export const UPDATE_USER_ROLE_FAILED = 'UPDATE_USER_ROLE_FAILED'

export const FETCH_FOR_USER_CSV = 'FETCH_FOR_USER_CSV'
export const FETCH_FOR_USER_CSV_ERROR = 'FETCH_FOR_USER_CSV_ERROR'
export const FETCH_FOR_USER_CSV_SUCCESSFULLY = 'FETCH_FOR_USER_CSV_SUCCESSFULLY'

export const FETCH_FOR_DRIVER_LOG_CSV = 'FETCH_FOR_DRIVER_LOG_CSV'
export const FETCH_FOR_DRIVER_LOG_CSV_ERROR = 'FETCH_FOR_DRIVER_LOG_CSV_ERROR'
export const FETCH_FOR_DRIVER_LOG_CSV_SUCCESSFULLY =
  'FETCH_FOR_DRIVER_LOG_CSV_SUCCESSFULLY'

export const FETCH_FOR_DRIVER_LOGS_CSV = 'FETCH_FOR_DRIVER_LOGS_CSV'
export const FETCH_FOR_DRIVER_LOGS_CSV_ERROR = 'FETCH_FOR_DRIVER_LOGS_CSV_ERROR'
export const FETCH_FOR_DRIVER_LOGS_CSV_SUCCESSFULLY =
  'FETCH_FOR_DRIVER_LOGS_CSV_SUCCESSFULLY'

export const INVITE_DRIVER = 'INVITE_DRIVER'
export const INVITE_DRIVER_FAILED = 'INVITE_DRIVER_FAILED'
export const INVITE_DRIVER_SUCCESSFULLY = 'INVITE_DRIVER_SUCCESSFULLY'

// ACCOUNT ENTITIES
export const FETCH_ACCOUNT_SUMMARY = 'FETCH_ACCOUNT_SUMMARY'
export const FETCH_ACCOUNT_SUMMARY_FAILED = 'FETCH_ACCOUNT_SUMMARY_FAILED'
export const FETCH_ACCOUNT_SUMMARY_SUCCESSFULLY =
  'FETCH_ACCOUNT_SUMMARY_SUCCESSFULLY'

export const FETCH_ACC_SUMMARY_CSV = 'FETCH_ACC_SUMMARY_CSV'
export const FETCH_ACC_SUMMARY_CSV_FAILED = 'FETCH_ACC_SUMMARY_CSV_FAILED'
export const FETCH_ACC_SUMMARY_CSV_SUCCESSFULLY =
  'FETCH_ACC_SUMMARY_CSV_SUCCESSFULLY'

// ACCOUNT Summary
export const FETCH_USER_SUMMARY = 'FETCH_USER_SUMMARY'
export const FETCH_USER_SUMMARY_FAILED = 'FETCH_USER_SUMMARY_FAILED'
export const FETCH_USER_SUMMARY_SUCCESSFULLY = 'FETCH_USER_SUMMARY_SUCCESSFULLY'

// Badge Summary
export const FETCH_BADGE_SUMMARY = 'FETCH_BADGE_SUMMARY'
export const FETCH_BADGE_SUMMARY_FAILED = 'FETCH_BADGE_SUMMARY_FAILED'
export const FETCH_BADGE_SUMMARY_SUCCESSFULLY = 'FETCH_BADGE_SUMMARY_SUCCESSFULLY'

// User Details
export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS'
export const FETCH_USER_DETAILS_ERROR = 'FETCH_USER_DETAILS_ERROR'
export const FETCH_USER_DETAILS_SUCCESSFULLY = 'FETCH_USER_DETAILS_SUCCESSFULLY'

export const FETCH_USER_NO_LOADING = 'FETCH_USER_NO_LOADING'
export const FETCH_USER_NO_LOADING_ERROR = 'FETCH_USER_NO_LOADING_ERROR'
export const FETCH_USER_NO_LOADING_SUCCESSFULLY =
  'FETCH_USER_NO_LOADING_SUCCESSFULLY'

export const IS_USER_AUTHORIZE = 'IS_USER_AUTHORIZE'

export const FREEZE_ACCOUNT = 'FREEZE_ACCOUNT'
export const FREEZE_ACCOUNT_SUCCESSFULLY = 'FREEZE_ACCOUNT_SUCCESSFULLY'
export const FREEZE_ACCOUNT_FAILED = 'FREEZE_ACCOUNT_FAILED'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESSFUL = 'DELETE_USER_SUCCESSFULLY'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'

// Update Profile

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const UPDATE_USER_PROFILE_SUCCESSFUL = 'UPDATE_USER_PROFILE_SUCCESSFUL'
export const UPDATE_USER_PROFILE_API_FAILED = 'UPDATE_USER_PROFILE_API_FAILED'

export const ACTIVATE_USER_PROFILE = 'ACTIVATE_USER_PROFILE'
export const ACTIVATE_USER_PROFILE_SUCCESSFUL =
  'ACTIVATE_USER_PROFILE_SUCCESSFUL'
export const ACTIVATE_USER_PROFILE_API_FAILED =
  'ACTIVATE_USER_PROFILE_API_FAILED'

// DRIVER LOGS
export const FETCH_DRIVER_LOGS = 'FETCH_DRIVER_LOGS'
export const FETCH_DRIVER_LOGS_FAILED = 'FETCH_DRIVER_LOGS_FAILED'
export const FETCH_DRIVER_LOGS_SUCCESSFULLY = 'FETCH_DRIVER_LOGS_SUCCESSFULLY'

export const FETCH_ALL_DRIVER_LOGS = 'FETCH_ALL_DRIVER_LOGS'
export const FETCH_ALL_DRIVER_LOGS_FAILED = 'FETCH_ALL_DRIVER_LOGS_FAILED'
export const FETCH_ALL_DRIVER_LOGS_SUCCESSFULLY =
  'FETCH_ALL_DRIVER_LOGS_SUCCESSFULLY'

export const DRIVER_LOGS_SUMMARY = 'DRIVER_LOGS_SUMMARY'
export const DRIVER_LOGS_SUMMARY_FAILED = 'DRIVER_LOGS_SUMMARY_FAILED'
export const DRIVER_LOGS_SUMMARY_SUCCESSFULLY =
  'DRIVER_LOGS_SUMMARY_SUCCESSFULLY'

export const FETCH_DRIVER_ONLINE_LOGS = 'FETCH_DRIVER_ONLINE_LOGS'
export const FETCH_DRIVER_ONLINE_LOGS_FAILED = 'FETCH_DRIVER_ONLINE_LOGS_FAILED'
export const FETCH_DRIVER_ONLINE_LOGS_SUCCESSFULLY =
  'FETCH_DRIVER_ONLINE_LOGS_SUCCESSFULLY'

export const FETCH_DRIVER_HOURS_LOGS = 'FETCH_DRIVER_HOURS_LOGS'
export const FETCH_DRIVER_HOURS_LOGS_FAILED = 'FETCH_DRIVER_HOURS_LOGS_FAILED'
export const FETCH_DRIVER_HOURS_LOGS_SUCCESSFULLY =
  'FETCH_DRIVER_HOURS_LOGS_SUCCESSFULLY'

export const FETCH_HOURS_CSV = 'FETCH_HOURS_CSV'
export const FETCH_HOURS_CSV_ERROR = 'FETCH_HOURS_CSV_ERROR'
export const FETCH_HOURS_CSV_SUCCESSFULLY = 'FETCH_HOURS_CSV_SUCCESSFULLY'

export const APPROVE_USER_ACCOUNT = 'APPROVE_USER_ACCOUNT'
export const APPROVE_USER_ACCOUNT_ERROR = 'APPROVE_USER_ACCOUNT_ERROR'
export const APPROVE_USER_ACCOUNT_SUCCESSFULLY =
  'APPROVE_USER_ACCOUNT_SUCCESSFULLY'
