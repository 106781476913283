import {
  FETCH_PACKAGE_SUMMARY,
  FETCH_PACKAGE_SUMMARY_SUCCESSFULLY,
  FETCH_PACKAGE_SUMMARY_FAILED,
  CREATE_PACKAGE,
  CREATE_PACKAGE_SUCCESSFUL,
  CREATE_PACKAGE_FAILED,
  DELETE_PACKAGE,
  DELETE_PACKAGE_SUCCESSFUL,
  DELETE_PACKAGE_FAILED,
  FETCH_PACKAGE,
  FETCH_PACKAGE_SUCCESSFUL,
  FETCH_PACKAGE_FAILED,
  FETCH_PACKAGE_VEHICLES,
  FETCH_PACKAGE_VEHICLES_SUCCESSFUL,
  FETCH_PACKAGE_VEHICLES_FAILED,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_SUCCESSFUL,
  UPDATE_PACKAGE_FAILED,
  FETCH_EACH_PACKAGE,
  FETCH_EACH_PACKAGE_SUCCESSFUL,
  FETCH_EACH_PACKAGE_FAILED,
  ADD_PACKAGE_TO_VEHICLE,
  ADD_PACKAGE_TO_VEHICLE_SUCCESSFUL,
  ADD_PACKAGE_TO_VEHICLE_FAILED,
  // DELETE_PACKAGE_FROM_VEHICLE
  DELETE_PACKAGE_FROM_VEHICLE,
  DELETE_PACKAGE_FROM_VEHICLE_SUCCESSFUL,
  DELETE_PACKAGE_FROM_VEHICLE_FAILED,
  REMOVE_VEHICLE_FROM_PACKAGE,
  REMOVE_VEHICLE_FROM_PACKAGE_SUCCESSFUL,
  REMOVE_VEHICLE_FROM_PACKAGE_FAILED,
} from './actionTypes';

export const fetchPackage = (query) => {
  return {
    type: FETCH_PACKAGE,
    payload: query,
  };
};

export const fetchPackageSuccessful = (payload) => {
  return {
    type: FETCH_PACKAGE_SUCCESSFUL,
    payload,
  };
};

export const fetchPackageFailed = (error) => {
  return {
    type: FETCH_PACKAGE_FAILED,
    payload: error,
  };
};

export const fetchPackageVehicles = (packageId, query) => {
  return {
    type: FETCH_PACKAGE_VEHICLES,
    payload: { packageId, query },
  };
};

export const fetchPackageVehiclesSuccessful = (payload) => {
  return {
    type: FETCH_PACKAGE_VEHICLES_SUCCESSFUL,
    payload,
  };
};

export const fetchPackageVehiclesFailed = (error) => {
  return {
    type: FETCH_PACKAGE_VEHICLES_FAILED,
    payload: error,
  };
};

export const addPackageToVehicles = (query) => {
  return {
    type: ADD_PACKAGE_TO_VEHICLE,
    payload: query,
  };
};

export const addPackageToVehiclesSuccessful = (payload) => {
  return {
    type: ADD_PACKAGE_TO_VEHICLE_SUCCESSFUL,
    payload,
  };
};

export const addPackageToVehiclesFailed = (error) => {
  return {
    type: ADD_PACKAGE_TO_VEHICLE_FAILED,
    payload: error,
  };
};

// deletePackageToVehicles;
export const deletePackageToVehicles = (query) => {
  return {
    type: DELETE_PACKAGE_FROM_VEHICLE,
    payload: query,
  };
};

export const deletePackageToVehiclesSuccessful = (payload) => {
  return {
    type: DELETE_PACKAGE_FROM_VEHICLE_SUCCESSFUL,
    payload,
  };
};

export const deletePackageToVehiclesFailed = (error) => {
  return {
    type: DELETE_PACKAGE_FROM_VEHICLE_FAILED,
    payload: error,
  };
};

export const deletePackage = (id) => {
  return {
    type: DELETE_PACKAGE,
    payload: id,
  };
};

export const deletePackageSuccessful = (payload) => {
  return {
    type: DELETE_PACKAGE_SUCCESSFUL,
    payload,
  };
};

export const deletePackageFailed = (error) => {
  return {
    type: DELETE_PACKAGE_FAILED,
    payload: error,
  };
};

export const fetchEachPackage = (queryId) => {
  return {
    type: FETCH_EACH_PACKAGE,
    payload: queryId,
  };
};

export const fetchEachPackageSuccessful = (payload) => {
  return {
    type: FETCH_EACH_PACKAGE_SUCCESSFUL,
    payload,
  };
};

export const fetchEachPackageFailed = (error) => {
  return {
    type: FETCH_EACH_PACKAGE_FAILED,
    payload: error,
  };
};

export const updatePackage = (payload) => {
  return {
    type: UPDATE_PACKAGE,
    payload,
  };
};

export const updatePackageFailed = (error) => {
  return {
    type: UPDATE_PACKAGE_FAILED,
    payload: error,
  };
};

export const updatePackageSuccessful = (payload) => {
  return {
    type: UPDATE_PACKAGE_SUCCESSFUL,
    payload,
  };
};

export const createPackage = (payload) => {
  return {
    type: CREATE_PACKAGE,
    payload,
  };
};

export const createPackageSuccessful = (payload) => {
  return {
    type: CREATE_PACKAGE_SUCCESSFUL,
    payload,
  };
};

export const createPackageFailed = (error) => {
  return {
    type: CREATE_PACKAGE_FAILED,
    payload: error,
  };
};

//SUMMARY ENTRIES
export const fetchPackageSummary = (query) => {
  return {
    type: FETCH_PACKAGE_SUMMARY,
    payload: query,
  };
};

export const fetchPackageSummarySuccessfully = (payload) => {
  return {
    type: FETCH_PACKAGE_SUMMARY_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchPackageSummaryFailed = (error) => {
  return {
    type: FETCH_PACKAGE_SUMMARY_FAILED,
    payload: error,
  };
};

export const removeVehicle = (id, data) => {
  return {
    type: REMOVE_VEHICLE_FROM_PACKAGE,
    payload: { id, data },
  };
};

export const removeVehicleFailed = (error) => {
  return {
    type: REMOVE_VEHICLE_FROM_PACKAGE_FAILED,
    payload: error,
  };
};

export const removeVehicleSuccessful = (payload) => {
  return {
    type: REMOVE_VEHICLE_FROM_PACKAGE_SUCCESSFUL,
    payload,
  };
};
