import React, { useEffect, useState } from 'react';
import AlartError from '../../../../components/Common/AlertCard';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Input,
  Card,
  CardBody,
  Container,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import Loader from '../../../../components/Common/Loading/index.js';
import GreySummaryCard from '../../../../components/Common/Summary/index';
import CheckAction from '../../../../components/Common/Summary/actionCheck';

import AppSettingCard from './AppSettingCard';

import { createAppConfigs, fetchAppConfigs } from '../../../../store/actions';

const AppSetting = ({
  fetchAppConfigs,
  appConfigs,
  appConfig,
  loading,
  isAuthorize,
}) => {
  const [showPreview, setShowPreview] = useState(null);
  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'App Setting', link: '#' },
  ];

  const SearchHandler = (event) => {
    const searchValue = event.target.value;
    if (searchValue !== '') {
      fetchAppConfigs(searchValue);
    } else {
      fetchAppConfigs();
    }
  };

  useEffect(() => {
    fetchAppConfigs();
  }, [appConfig, fetchAppConfigs]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='App Setting' breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field='Configurations' subModule='App Settings'>
            {isAuthorize ? (
              <AlartError error={isAuthorize} />
            ) : (
              <>
                <div className='mb-2 text-right'>
                  <CheckAction
                    field='Configurations'
                    subModule='App Settings'
                    isButton={true}
                  >
                    <Link to='/create_app_setting'>
                      <Button color='success'>
                        <i className=' fas git fa-plus mr-2' /> Create Settings
                      </Button>
                    </Link>
                  </CheckAction>
                </div>
                <Card>
                  <CardBody>
                    <div className='mb-2 col-sm-3 px-0 col-6'>
                      <Input
                        bsSize='sm'
                        id='search'
                        name='limit'
                        onChange={SearchHandler}
                        placeholder='Search'
                      />{' '}
                    </div>
                    {loading ? (
                      <Loader />
                    ) : (
                      <Row>
                        {appConfigs !== null &&
                          appConfigs.map((config) => (
                            <Col sm='3' key={config.id}>
                              <AppSettingCard
                                config={config}
                                previewId={(id) => setShowPreview(id)}
                              />
                            </Col>
                          ))}
                      </Row>
                    )}

                    <span className='badge badge-soft-primary font-size-11 font-weight-bold mb-3'>
                      This Module is in Progress
                    </span>
                  </CardBody>
                </Card>
              </>
            )}
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { appConfigs, appConfig, loading, appConfigError, isAuthorize } =
    state.AppConfigs;
  return { appConfigs, appConfig, loading, appConfigError, isAuthorize };
};

export default connect(mapStateToProps, { createAppConfigs, fetchAppConfigs })(
  AppSetting
);
