import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';
import AlartError from '../../../../components/Common/AlertCard';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import {
  createInvitation,
  createInvitationFailed,
} from '../../../../store/actions';

const Form = ({
  createInvitation,
  createInvitationFailed,
  message,
  loading,
  history,
  invitationError,
}) => {
  const [isLoading, setIsLoading] = useState(loading);
  const [inputs, setInput] = useState(1);
  //eslint-disable-next-line
  const REGEXP = /[\+]\d{3}\d{2}\d{4}\d{4}/;

  const handleSubmit = async (event, values) => {
    setIsLoading(true);
    const invitationData = {};
    let invitations = [];
    for (let i = 0; i < inputs; i++) {
      invitations[i] = {
        firstName: values['firstName' + i],
        lastName: values['lastName' + i],
        phone: values['phone' + i],
        userType: values['userType' + i],
      };
    }
    invitationData.invitations = invitations;
    invitationData.sendSMS = true;

    createInvitation(invitationData);
    // console.log(invitationData);
    setIsLoading(false);
  };

  useEffect(() => {
    createInvitationFailed('');
  }, []);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        createInvitationFailed('');
        history.goBack();
      }, 2000);
    }
  }, [message]);
  const renderInputs = () => {
    return [
      Array(Math.ceil(inputs))
        .fill(0)
        .map((star, key) => (
          <div key={key}>
            <Row>
              <Col md={6}>
                <FormGroup className='mb-4'>
                  <Label htmlFor={`firstName${key}`}>First Name</Label>
                  <AvField
                    name={`firstName${key}`}
                    type='text'
                    className='form-control bg-light'
                    id={`firstName${key}`}
                    value={''}
                    placeholder='Enter Referee First Name ...'
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className='mb-4'>
                  <Label htmlFor={`lastName${key}`}>Last Name</Label>
                  <AvField
                    name={`lastName${key}`}
                    type='text'
                    className='form-control bg-light'
                    id={`lastName${key}`}
                    value={''}
                    placeholder='Enter Referee Last Name ...'
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                {' '}
                <FormGroup className='mb-1'>
                  <AvField
                    name={`userType${key}`}
                    type='select'
                    className='form-control bg-light'
                    label='User Type'
                    value={'RIDER'}
                  >
                    <option>RIDER</option>
                    <option>DRIVER</option>
                  </AvField>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className='mb-1'>
                  <Label htmlFor={`phone${key}`}>Contact Number</Label>
                  <AvField
                    name={`phone${key}`}
                    type='text'
                    className='form-control bg-light'
                    id={`phone${key}`}
                    placeholder='Enter Referee Phone Number ...'
                    required
                    validate={{
                      tel: { pattern: REGEXP },
                    }}
                    errorMessage='Please enter a valid phone number (country code + 10 digits)'
                    helpMessage='Country code + 10 digits'
                    value={''}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr />
          </div>
        )),
    ];
  };
  return (
    <Col md={6}>
      <Card>
        <CardBody>
          {message && (
            <Alert color='success' className='text-center'>
              {message}
            </Alert>
          )}

          {invitationError && <AlartError error={invitationError} />}
          <div className='float-right'>
            <Link to='#' onClick={() => history.goBack()}>
              <i className='fas fa-arrow-left mr-3' />
              Back
            </Link>
          </div>
          <div>
            <h5> Create Invitation </h5>
          </div>
          <div>
            <AvForm
              className='form-horizontal pt-4'
              onValidSubmit={handleSubmit}
              // model={defaultValue ? defaultValue : false}
            >
              {renderInputs()}
              <Col className='col-md-2 d-flex align-items-end mb-4 float-right mt-3'>
                <Button
                  size='sm'
                  color='success'
                  onClick={() => setInput(inputs + 1)}
                  className='mr-2'
                  id='add'
                >
                  <i className='mdi mdi-plus-thick font-size-14'></i>{' '}
                </Button>
                <UncontrolledTooltip placement='top' target='add'>
                  Add more invites
                </UncontrolledTooltip>
                {inputs > 1 && (
                  <>
                    <Button
                      size='sm'
                      color='danger'
                      onClick={() => setInput(inputs - 1)}
                      id='remove'
                    >
                      <i className='mdi mdi-window-minimize font-size-14'></i>{' '}
                    </Button>
                    <UncontrolledTooltip placement='top' target='remove'>
                      Remove last invite
                    </UncontrolledTooltip>
                  </>
                )}
              </Col>
              <button className='btn btn-success mr-2 mt-3' type='submit'>
                {isLoading ? 'Submitting ...' : 'Invite'}
              </button>

              <Link to='#' onClick={() => history.goBack()}>
                <button className='btn btn-secondary mt-3'>Cancel</button>
              </Link>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, invitationError } = state.Referral;
  return { message, loading, invitationError };
};

export default connect(mapStateToProps, {
  createInvitation,
  createInvitationFailed,
})(withRouter(Form));
