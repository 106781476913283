import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_VEHICLE,
  FETCH_EACH_VEHICLE,
  FETCH_MY_VEHICLE,
  FETCH_VEHICLE_SUMMARY,
  CREATE_VEHICLE,
  DELETE_VEHICLE,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_WITH_PACKAGE,
} from './actionTypes';
import {
  fetchVehicleSuccessful,
  fetchVehicleError,
  fetchEachVehicleSuccessful,
  fetchEachVehicleError,
  fetchVehicleSummaryFailed,
  fetchVehicleSummarySuccessfully,
  fetchMyVehicleSuccessful,
  fetchMyVehicleError,
  deleteVehicleSuccessful,
  deleteVehicleFailed,
  createVehicleSuccessful,
  createVehicleFailed,
  updateVehicleSuccessful,
  updateVehiclePackagesSuccessfully,
  updateVehiclePackagesFailed,
  fetchVehicleAuthorizeError,
  updateVehicleFailed,
} from './actions';

import {
  GetVehicleService,
  GetEachVehicleService,
  DeleteVehicleService,
  CreateVehicleService,
  fetchVehicleSummaryService,
  UpdateVehicleService,
  updateVehicleWithPackageService,
  GetMyVehicleService,
} from '../../services/vehicleService';

function* fetchVehicleHandler({ payload }) {
  try {
    const response = yield call(GetVehicleService, payload);
    yield put(fetchVehicleSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchVehicleAuthorizeError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    }
    yield put(fetchVehicleError(error?.response?.data?.message));
  }
}

function* fetchMyVehicleHandler({ payload }) {
  try {
    const response = yield call(GetMyVehicleService, payload);
    yield put(fetchMyVehicleSuccessful(response.data));
  } catch (error) {
    yield put(fetchMyVehicleError(error?.response?.data?.message));
  }
}

function* fetchEachVehicleHandler({ payload }) {
  try {
    const response = yield call(GetEachVehicleService, payload);
    yield put(fetchEachVehicleSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachVehicleError(error?.response?.data?.message));
  }
}

function* deleteVehicleHandler({ payload }) {
  try {
    const response = yield call(DeleteVehicleService, payload);
    yield put(deleteVehicleSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteVehicleFailed(error?.response?.data?.error));
  }
}

function* createVehicleHandler({ payload }) {
  try {
    const response = yield call(CreateVehicleService, payload);
    yield put(createVehicleSuccessful(response.data.result));
  } catch (error) {
    yield put(createVehicleFailed(error?.response?.data?.message));
  }
}

function* updateVehicleHandler({ payload }) {
  try {
    const response = yield call(UpdateVehicleService, payload);
    yield put(updateVehicleSuccessful(response.data.result));
  } catch (error) {
    yield put(updateVehicleFailed(error?.response?.data?.message));
  }
}

// VEHICLE SUMMARY
function* fetchVehicleSummaryHandler({ payload }) {
  try {
    const response = yield call(fetchVehicleSummaryService, payload);
    yield put(fetchVehicleSummarySuccessfully(response.data));
  } catch (error) {
    yield put(fetchVehicleSummaryFailed(error.response.data));
  }
}

// VEHICLE UPDATE WITH PACKAGES
function* updateVehicleWithPackageHandler({ payload }) {
  try {
    const response = yield call(updateVehicleWithPackageService, payload);
    yield put(updateVehiclePackagesSuccessfully(response.data));
  } catch (error) {
    yield put(updateVehiclePackagesFailed(error.response.data));
  }
}

export function* watchUpdateVehicleWithPackages() {
  yield takeEvery(UPDATE_VEHICLE_WITH_PACKAGE, updateVehicleWithPackageHandler);
}

export function* watchFetchVehicle() {
  yield takeEvery(FETCH_VEHICLE, fetchVehicleHandler);
}
export function* watchFetchMyVehicle() {
  yield takeEvery(FETCH_MY_VEHICLE, fetchMyVehicleHandler);
}
export function* watchFetchEachVehicle() {
  yield takeEvery(FETCH_EACH_VEHICLE, fetchEachVehicleHandler);
}
export function* watchDeleteVehicle() {
  yield takeEvery(DELETE_VEHICLE, deleteVehicleHandler);
}
export function* watchCreateVehicle() {
  yield takeEvery(CREATE_VEHICLE, createVehicleHandler);
}
export function* watchUpdateVehicle() {
  yield takeEvery(UPDATE_VEHICLE, updateVehicleHandler);
}
export function* watchFetchVehicleSummary() {
  yield takeEvery(FETCH_VEHICLE_SUMMARY, fetchVehicleSummaryHandler);
}

function* vehicleSaga() {
  yield all([
    fork(watchUpdateVehicleWithPackages),
    fork(watchFetchVehicle),
    fork(watchFetchVehicleSummary),
    fork(watchFetchEachVehicle),
    fork(watchFetchMyVehicle),
    fork(watchDeleteVehicle),
    fork(watchCreateVehicle),
    fork(watchUpdateVehicle),
  ]);
}

export default vehicleSaga;
