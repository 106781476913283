import {
  FETCH_TOURS,
  FETCH_TOURS_SUCCESSFUL,
  FETCH_TOURS_API_FAILED,
  FETCH_EACH_TOUR,
  FETCH_EACH_TOUR_SUCCESSFUL,
  FETCH_EACH_TOUR_API_FAILED,
  DELETE_TOUR,
  DELETE_TOUR_SUCCESSFUL,
  DELETE_TOUR_FAILED,
  CREATE_TOUR,
  CREATE_TOUR_SUCCESSFUL,
  CREATE_TOUR_FAILED,
  UPDATE_TOUR,
  UPDATE_TOUR_SUCCESSFUL,
  UPDATE_TOUR_FAILED,
} from "./actionTypes";

export const fetchTours = (payload) => {
  return {
    type: FETCH_TOURS,
    payload,
  };
};

export const fetchToursSuccessful = (payload) => {
  return {
    type: FETCH_TOURS_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchToursError = (error) => {
  return {
    type: FETCH_TOURS_API_FAILED,
    payload: error,
  };
};

export const fetchEachTour = (id) => {
  return {
    type: FETCH_EACH_TOUR,
    payload: id,
  };
};

export const fetchEachTourSuccessful = (payload) => {
  return {
    type: FETCH_EACH_TOUR_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachTourError = (error) => {
  return {
    type: FETCH_EACH_TOUR_API_FAILED,
    payload: error,
  };
};

export const deleteTour = (id) => {
  return {
    type: DELETE_TOUR,
    payload: id,
  };
};

export const deleteTourSuccessful = (payload) => {
  return {
    type: DELETE_TOUR_SUCCESSFUL,
    payload,
  };
};

export const deleteTourFailed = (error) => {
  return {
    type: DELETE_TOUR_FAILED,
    payload: error,
  };
};

export const createTour = (payload) => {
  return {
    type: CREATE_TOUR,
    payload,
  };
};

export const createTourSuccessful = (payload) => {
  return {
    type: CREATE_TOUR_SUCCESSFUL,
    payload,
  };
};

export const createTourFailed = (error) => {
  return {
    type: CREATE_TOUR_FAILED,
    payload: error,
  };
};

export const updateTour = (payload) => {
  return {
    type: UPDATE_TOUR,
    payload,
  };
};

export const updateTourFailed = (error) => {
  return {
    type: UPDATE_TOUR_FAILED,
    payload: error,
  };
};

export const updateTourSuccessful = (payload) => {
  return {
    type: UPDATE_TOUR_SUCCESSFUL,
    payload,
  };
};
