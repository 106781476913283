export const FETCH_BONUS = 'FETCH_BONUS'
export const FETCH_BONUS_SUCCESSFUL = 'FETCH_BONUS_SUCCESSFUL'
export const FETCH_BONUS_API_FAILED = 'FETCH_BONUS_API_FAILED'

// BONUS Summary
export const FETCH_BONUS_SUMMARY = 'FETCH_BONUS_SUMMARY'
export const FETCH_BONUS_SUMMARY_FAILED = 'FETCH_BONUS_SUMMARY_FAILED'
export const FETCH_BONUS_SUMMARY_SUCCESSFULLY =
  'FETCH_BONUS_SUMMARY_SUCCESSFULLY'

export const FETCH_USER_BONUS = 'FETCH_USER_BONUS'
export const FETCH_USER_BONUS_FAILED = 'FETCH_USER_BONUS_FAILED'
export const FETCH_USER_BONUS_SUCCESSFULLY = 'FETCH_USER_BONUS_SUCCESSFULLY'

export const FETCH_EACH_USER_BONUS = 'FETCH_EACH_USER_BONUS'
export const FETCH_EACH_USER_BONUS_FAILED = 'FETCH_EACH_USER_BONUS_FAILED'
export const FETCH_EACH_USER_BONUS_SUCCESSFULLY =
  'FETCH_EACH_USER_BONUS_SUCCESSFULLY'

export const FETCH_BONUS_LIST = 'FETCH_BONUS_LIST'
export const FETCH_BONUS_LIST_SUCCESSFUL = 'FETCH_BONUS_LIST_SUCCESSFUL'
export const FETCH_BONUS_LIST_API_FAILED = 'FETCH_BONUS_LIST_API_FAILED'

export const FETCH_BONUS_LOG = 'FETCH_BONUS_LOG'
export const FETCH_BONUS_LOG_SUCCESSFUL = 'FETCH_BONUS_LOG_SUCCESSFUL'
export const FETCH_BONUS_LOG_API_FAILED = 'FETCH_BONUS_LOG_API_FAILED'

export const FETCH_BONUS_LOG_SUMMARY = 'FETCH_BONUS_LOG_SUMMARY'
export const FETCH_BONUS_LOG_SUMMARY_SUCCESSFUL =
  'FETCH_BONUS_LOG_SUMMARY_SUCCESSFUL'
export const FETCH_BONUS_LOG_SUMMARY_API_FAILED =
  'FETCH_BONUS_LOG_SUMMARY_API_FAILED'

export const FETCH_EACH_BONUS_LOG = 'FETCH_EACH_BONUS_LOG'
export const FETCH_EACH_BONUS_LOG_SUCCESSFUL = 'FETCH_EACH_BONUS_LOG_SUCCESSFUL'
export const FETCH_EACH_BONUS_LOG_API_FAILED = 'FETCH_EACH_BONUS_LOG_API_FAILED'

export const FETCH_EACH_BONUS = 'FETCH_EACH_BONUS'
export const FETCH_EACH_BONUS_SUCCESSFUL = 'FETCH_EACH_BONUS_SUCCESSFUL'
export const FETCH_EACH_BONUS_API_FAILED = 'FETCH_EACH_BONUS_API_FAILED'

export const DELETE_BONUS = 'DELETE_BONUS'
export const DELETE_BONUS_SUCCESSFUL = 'DELETE_BONUS_SUCCESSFULLY'
export const DELETE_BONUS_FAILED = 'DELETE_BONUS_FAILED'

export const CREATE_BONUS = 'CREATE_BONUS'
export const CREATE_BONUS_SUCCESSFUL = 'CREATE_BONUS_SUCCESSFULLY'
export const CREATE_BONUS_FAILED = 'CREATE_BONUS_FAILED'

export const UPDATE_BONUS = 'UPDATE_BONUS'
export const UPDATE_BONUS_SUCCESSFUL = 'UPDATE_BONUS_SUCCESSFULLY'
export const UPDATE_BONUS_FAILED = 'UPDATE_BONUS_FAILED'

export const FETCH_BONUS_CSV = 'FETCH_BONUS_CSV'
export const FETCH_BONUS_CSV_ERROR = 'FETCH_BONUS_CSV_ERROR'
export const FETCH_BONUS_CSV_SUCCESSFULLY = 'FETCH_BONUS_CSV_SUCCESSFULLY'

export const FETCH_BONUS_LOG_CSV = 'FETCH_BONUS_LOG_CSV'
export const FETCH_BONUS_LOG_CSV_ERROR = 'FETCH_BONUS_LOG_CSV_ERROR'
export const FETCH_BONUS_LOG_CSV_SUCCESSFULLY =
  'FETCH_BONUS_LOG_CSV_SUCCESSFULLY'
