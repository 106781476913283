import {
  IS_LOYALTYTIER_AUTHORIZE,
  FETCH_LOYALTYTIER_SUMMARY,
  FETCH_LOYALTYTIER_SUMMARY_SUCCESSFULLY,
  FETCH_LOYALTYTIER_SUMMARY_FAILED,
  FETCH_LOYALTYTIER,
  FETCH_LOYALTYTIER_SUCCESSFUL,
  FETCH_LOYALTYTIER_API_FAILED,
  FETCH_EACH_LOYALTYTIER,
  FETCH_EACH_LOYALTYTIER_SUCCESSFUL,
  FETCH_EACH_LOYALTYTIER_API_FAILED,
  FETCH_MY_LOYALTYTIER,
  FETCH_MY_LOYALTYTIER_SUCCESSFUL,
  FETCH_MY_LOYALTYTIER_API_FAILED,
  DELETE_LOYALTYTIER,
  DELETE_LOYALTYTIER_SUCCESSFUL,
  DELETE_LOYALTYTIER_FAILED,
  CREATE_LOYALTYTIER,
  CREATE_LOYALTYTIER_SUCCESSFUL,
  CREATE_LOYALTYTIER_FAILED,
  UPDATE_LOYALTYTIER,
  UPDATE_LOYALTYTIER_SUCCESSFUL,
  UPDATE_LOYALTYTIER_FAILED,
} from './actionTypes';

const initialState = {
  loyaltyTier: null,
  loyaltyTierError: null,
  loyaltyTierPreview: null,
  loyaltyTierSummary: null,
  summaryError: null,
  isAuthorize: null,
  message: null,
  loading: false,
};

const LoyaltyTier = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOYALTYTIER_AUTHORIZE:
      state = {
        ...state,
        isAuthorize: action.payload,
      };
      break;

    case FETCH_LOYALTYTIER:
    case UPDATE_LOYALTYTIER:
    case DELETE_LOYALTYTIER:
      state = {
        ...state,
        loyaltyTier: null,
        loyaltyTierError: null,
        loading: true,
        isAuthorize: null,
        message: null,
      };
      break;
    case FETCH_LOYALTYTIER_SUCCESSFUL:
    case FETCH_MY_LOYALTYTIER_SUCCESSFUL:
      state = {
        ...state,
        loyaltyTier: action.payload,
        loyaltyTierError: null,
        loading: false,
      };
      break;

    case DELETE_LOYALTYTIER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        loyaltyTierError: null,
        message: 'LoyaltyTier deleted successfully',
      };
      break;
    case UPDATE_LOYALTYTIER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        loyaltyTierError: null,
        message: 'LoyaltyTier Updated Successfully',
      };
      break;

    case FETCH_LOYALTYTIER_SUMMARY:
      return {
        ...state,
        loyaltyTierSummary: null,
        summaryError: null,
        loading: true,
      };

    case FETCH_LOYALTYTIER_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        loyaltyTierSummary: action.payload,
        summaryError: null,
        loading: false,
      };

    case FETCH_LOYALTYTIER_SUMMARY_FAILED:
      return {
        ...state,
        loyaltyTierSummary: null,
        summaryError: action.payload,
        loading: false,
      };

    case FETCH_EACH_LOYALTYTIER:
      state = {
        ...state,
        loading: true,
        loyaltyTier: null,
        loyaltyTierPreview: null,
        loyaltyTierError: null,
        message: null,
      };
      break;
    case FETCH_MY_LOYALTYTIER:
      state = {
        ...state,
        loyaltyTierError: null,
        loading: true,
        message: null,
        loyaltyTier: null,
      };
      break;
    case FETCH_EACH_LOYALTYTIER_SUCCESSFUL:
      state = {
        ...state,
        loyaltyTierPreview: action.payload,
        loading: false,
        loyaltyTierError: null,
        message: null,
      };
      break;

    case CREATE_LOYALTYTIER:
      state = {
        ...state,
        loading: true,
        loyaltyTierError: null,
        message: null,
      };
      break;

    case CREATE_LOYALTYTIER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        loyaltyTierError: null,
        message: `${action.payload.make}  created successfully`,
      };
      break;

    case CREATE_LOYALTYTIER_FAILED:
    case FETCH_LOYALTYTIER_API_FAILED:
    case DELETE_LOYALTYTIER_FAILED:
    case FETCH_EACH_LOYALTYTIER_API_FAILED:
    case FETCH_MY_LOYALTYTIER_API_FAILED:
      state = {
        ...state,
        loyaltyTier: null,
        loyaltyTierPreview: null,
        loading: false,
        message: null,
        loyaltyTierError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_LOYALTYTIER_FAILED:
      state = {
        ...state,
        loading: false,
        loyaltyTierError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LoyaltyTier;
