import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Alert,
  FormGroup,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import ReactPaginate from 'react-paginate';
import FilterDropDown from '../../../components/Common/FilterDropDown';
import SimpleBar from 'simplebar-react';
import Loader from '../../../components/Common/Loading/index.js';

import Moment from 'moment';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../utils/general';
import queryString from 'query-string';

const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

let skip = 0;
let dataLimit = 10;

const RewardTable = ({
  rewardData,
  previewAction,
  fetchReward,
  tableError,
  deleteReward,
  history,
  message,
  dateFilter,
  CheckAction,
  accessControl,
  ReadCheck
}) => {
  const [data, setData] = useState(null);
  const [skipPage, setSkipPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [menu, setMenu] = useState(false);
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(rewardData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = { skip: pageNumber, take: dataLimit, status, search };
    fetchReward(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take, status, search };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchReward(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      // skip,
      status,
    };
    fetchReward(queryParams);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          status,
        };
        fetchReward(queryParams);
      }, 2000);
    }
  }, [fetchReward, message]);

  useEffect(() => {
    if (rewardData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(rewardData?.total / take));
    }
  }, [rewardData]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchReward(parsedUrlParams);
  }, [fetchReward]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      status,
      take,
      skip: pageNumber,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        take,
        skip,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.status) {
      setStatus(parsedUrlParams?.status);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    dateFilter({ start: values.startDate, end: values.endDate });
    setMenu(false);
    setStatus(values.rewardStatus);
    let queryParams = {
      search,
      take,
      skip,
      status: values.rewardStatus,
      start: values.startDate,
      end: values.endDate,
    };
    fetchReward(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: '',
      end: '',
    });
    dateFilter({});
    let queryParams = {
      search,
      take,
      skip,
      status,
    };
    fetchReward(queryParams);
  };

  let accessChecking = ReadCheck(accessControl, `Rewards`, 'Riders Rewards');
  const tableDataHandler = () => {
    const rowData = rewardData?.result?.map((data, i) => {
      return {
        keyId: data.id,
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,

        id: (
          <Link to='#' className='mr-3 text-dark font-weight-bold'>
            {data.id.substring(0, 7)}
          </Link>
        ),
        name: (
          <>
            {accessChecking ?
              <Link
                to='#'
                onClick={() => previewAction(data.id)}
                className='text-dark font-weight-bold'
              >
                <div className='d-flex align-items-center'>
                  <img
                    src={data?.imageUrl ? data?.imageUrl : avatarImg}
                    alt=''
                    height='20'
                    className='rounded avatar-sm mr-2'
                  />
                  <span className='text-capitalize'>
                    {data.name}
                    <span className='d-block'>
                      <span className='badge badge-soft-secondary rounded'>
                        {' '}
                        {data.category}{' '}
                      </span>
                    </span>
                  </span>
                </div>
              </Link>
              :
              <div className='text-dark font-weight-bold'>
                <div className='d-flex align-items-center'>
                  <img
                    src={data?.imageUrl ? data?.imageUrl : avatarImg}
                    alt=''
                    height='20'
                    className='rounded avatar-sm mr-2'
                  />
                  <span className='text-capitalize'>
                    {data.name}
                    <span className='d-block'>
                      <span className='badge badge-soft-secondary rounded'>
                        {' '}
                        {data.category}{' '}
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            }
          </>
        ),
        value: (
          <span>
            <span className='d-block'>
              ₦{data.value.toLocaleString()}
              {data.point && (
                <div>
                  <span className='badge badge-warning rounded'>
                    {data.point}
                  </span>
                </div>
              )}
            </span>
          </span>
        ),
        balance: (
          <>
            <span className='d-block'>Total : {data.totalAtStart}</span>
            <span className='d-flex align-items-center'>
              Balance : {data.total}
              <span className='badge badge-primary rounded ml-2'>
                {data.redeems}
              </span>
            </span>
          </>
        ),
        date: (
          <div>
            {' '}
            <div>{Moment(data.createdAt).format('l')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
        status: (
          <h1
            className={`badge badge-soft-${data.status.toLowerCase() === 'active'
              ? 'success'
              : data.status.toLowerCase() === 'inactive'
                ? 'danger'
                : 'warning'
              }`}
          >
            {data.status === 'COMINGSOON' ? 'COMING SOON' : data.status}
          </h1>
        ),
        action: (
          <div className='d-flex align-items-center'>
            <CheckAction field='Rewards' subModule='Riders Rewards' iconType='Edit'>
              <Link
                to={`/reward_form?id=${data.id}`}
                className='mr-3 text-primary'
                id='edit1'
              >
                <i className='mdi mdi-pencil font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='edit1'>
                Edit
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field='Rewards' subModule='Riders Rewards' iconType='Delete'>
              <Link
                to='#'
                onClick={() => deleteReward(data.id)}
                className='text-danger'
                id='delete1'
              >
                <i className='mdi mdi-trash-can font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='delete1'>
                Delete
              </UncontrolledTooltip>
            </CheckAction>
          </div>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
        },
        {
          text: 'Reward Id',
          dataField: 'id',
        },
        {
          text: 'Name',
          dataField: 'name',
        },
        {
          text: 'Values - Point - %',
          dataField: 'value',
          sort: true,
        },
        {
          text: 'Created At',
          dataField: 'date',
        },
        {
          text: 'Status',
          dataField: 'status',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {tableError !== null ? (
                <Alert color='danger' className='text-center'>
                  {tableError}
                </Alert>
              ) : (
                <>
                  <div className='d-flex justify-content-between align-items-top'>
                    <h4> Rewards History </h4>
                    <div className='d-flex align-items-center'>
                      <FilterDropDown
                        menu={menu}
                        toggleMenu={() => setMenu(!menu)}
                      >
                        <div>
                          <AvForm
                            className='form-horizontal'
                            onValidSubmit={handleDateFilter}
                          >
                            <SimpleBar
                              style={{
                                maxHeight: '250px',
                              }}
                            >
                              <Row className='px-3  pt-3 pb-0'>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='startDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='startDate'
                                      value={date.start}
                                      helpMessage='Start Date'
                                      placeholder='Please choose the start date...'
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='endDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='endDate'
                                      value={date.end}
                                      helpMessage='End Date'
                                      placeholder='Please choose the end date...'
                                      required
                                    />
                                  </FormGroup>
                                </Col>

                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      type='select'
                                      value={status}
                                      name='rewardStatus'
                                      helpMessage='status'
                                    >
                                      <option value=''>All</option>
                                      <option value='ACTIVE'>ACTIVE</option>
                                      <option value='INACTIVE'>INACTIVE</option>
                                      <option value='COMINGSOON'>
                                        COMING SOON
                                      </option>
                                    </AvField>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </SimpleBar>

                            <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                              <Link
                                to='#'
                                onClick={resetHandler}
                                className='btn btn-sm  bg-light font-size-14'
                              >
                                <i className=' mdi mdi-refresh mr-1'> Reset</i>
                              </Link>

                              <button
                                className='btn btn-success btn-sm mr-2'
                                type='submit'
                              >
                                <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                Send
                              </button>
                            </div>
                          </AvForm>
                        </div>
                      </FilterDropDown>
                    </div>
                  </div>
                  {!isLoading && data !== null ? (
                    <>
                      <div className='d-flex justify-content-between mb-2'>
                        <div>
                          <Input
                            bsSize='sm'
                            id='take'
                            name='take'
                            type='select'
                            onChange={handleLimitChange}
                            value={take}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div>
                          <Input
                            bsSize='sm'
                            id='search'
                            name='search'
                            value={search}
                            onChange={SearchHandler}
                            placeholder='Search'
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField='keyId'
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses='table-responsive'
                        noDataIndication={'No Data to Display'}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        // breakLabel={'...'}
                        // marginPagesDisplayed={2}
                        // pageRangeDisplayed={3}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        forcePage={skipPage}
                        //Bootstrap styling
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default RewardTable;
