import config from "./routeConfig";
import Dashboard from "../pages/IRides/Dashboard";

import IconMaterialdesign from "../pages/Icons/MaterialDesign";
import Iconion from "../pages/Icons/RemixIcons";
import IconFontawesome from "../pages/Icons/FontAwesome";

import PagesBlank from "../pages/IRides/Utility/StarterPage";
import Pages404 from "../pages/IRides/Utility/Error404";

// UberApp
import Users from "../pages/IRides/Users";
import UserDetails from "../pages/IRides/Users/UserDetails";
import Admin from "../pages/IRides/Users/AdminDetails";
import Inspection from "../pages/IRides/Users/inspectors/index";

import Riders from "../pages/IRides/Users/RidersDetails";
import Drivers from "../pages/IRides/Users/Captains";
import CarDrivers from "../pages/IRides/Users/Captains/Car";
import BusDrivers from "../pages/IRides/Users/Captains/Bus";
import TrykeRiders from "../pages/IRides/Users/Captains/Tryke";
import BykeRiders from "../pages/IRides/Users/Captains/Byke";
import DriverLogs from "../pages/IRides/Users/DriverLogs";
import AllDriverLogs from "../pages/IRides/Users/DriverLogs/AllDriverLogs/index.js";
import DriverHours from "../pages/IRides/Users/DriverHours/index";

import Document from "../pages/IRides/Users/Document";
import UpdateDocument from "../pages/IRides/Users/Document/DocumentForm";

import Rides from "../pages/IRides/Rides";
import RidesMonitory from "../pages/IRides/Utility/CommingSoon";
import MyRides from "../pages/IRides/Rides/MyRides";
import TripDetails from "../pages/IRides/Rides/TripDetails";
import CreateRide from "../pages/IRides/Rides/RideForm";

import AssignedComplains from "../pages/IRides/Complaints/AssignedComplains";
import GeneralComplaint from "../pages/IRides/Complaints/GeneralComplaint";
import MyComplaint from "../pages/IRides/Complaints/MyComplaint";

import CashOutAnalytic from "../pages/IRides/Analytics/Cashout";

import CreateComplaint from "../pages/IRides/Complaints/GeneralComplaint/createTitle";

import UserReview from "../pages/IRides/Review/userReview";
import ReviewDetails from "../pages/IRides/Review/reviewDetails";
import DriverReview from "../pages/IRides/Review/driverReview";

import AppSetting from "../pages/IRides/Configuration/AppSetting";
import CreateAppSetting from "../pages/IRides/Configuration/AppSetting/createAppForm";
import AppSettingPreview from "../pages/IRides/Configuration/AppSetting/PreviewAppSetting";
import SystemSetting from "../pages/IRides/Configuration/SystemSetting";
import DocumentType from "../pages/IRides/Configuration/DocumentType";
import AccessControl_FE from "../pages/IRides/Configuration/accessControl_FE";
import CreateAcccess from "../pages/IRides/Configuration/accessControl_FE/form/index";
import CreateDocumentType from "../pages/IRides/Configuration/DocumentType/DocumentTypeForm";
import AppVersion from "../pages/IRides/Configuration/AppVersion";
import AppVersionForm from "../pages/IRides/Configuration/AppVersion/AppVersionForm/index";

import LicenseKey from "../pages/IRides/Configuration/LicenseKey";
import LicenseKeyForm from "../pages/IRides/Configuration/LicenseKey/LicenseKeyForm/index";

import BusStop from "../pages/IRides/BusStop";
import BusStopForm from "../pages/IRides/BusStop/BusStopForm/index";

import Route from "../pages/IRides/Route";
import RouteForm from "../pages/IRides/Route/RouteForm/index";

import Tour from "../pages/IRides/Tour";
import TourForm from "../pages/IRides/Tour/TourForm/index";
import PreviewTour from "../pages/IRides/Tour/preview";

import Bank from "../pages/IRides/Bank";
import Payment from "../pages/IRides/Payment";
import CreateBank from "../pages/IRides/Bank/BankForm";

import Cashout from "../pages/IRides/Cashout/index";
import CreateCashout from "../pages/IRides/Cashout/form/index";

import Bonus from "../pages/IRides/Bonus";
import CreateBonus from "../pages/IRides/Bonus/BonusForm";
import RiderBonus from "../pages/IRides/Utility/CommingSoon";
import BonusLog from "../pages/IRides/Bonus/BonusLog";
import Vehicle from "../pages/IRides/Vehicle";
import VehiclePreview from "../pages/IRides/Vehicle/previewVehicle";
import CreateVehicle from "../pages/IRides/Vehicle/VehicleForm";

import Notification from "../pages/IRides/Notification";
import CreateNotification from "../pages/IRides/Notification/NotificationForms/index";
import CreatePushNotification from "../pages/IRides/FPNotification/index";
import Announcements from "../pages/IRides/Notification/Announcements/index";
import Schedule from "../pages/IRides/Notification/ScheduleNotification/index";
import NotificationLog from "../pages/IRides/Notification/NotificationLog/index";

import Profile from "../pages/IRides/Profile";
import Settings from "../pages/IRides/Profile/Settings";

import ServiceType from "../pages/IRides/PackageType";
import CreateService from "../pages/IRides/PackageType/CreatePackage";
import PackageVehicles from "../pages/IRides/PackageType/PackageVehicles/index";
import AddVehicles from "../pages/IRides/PackageType/PackageVehicles/addVehicles";
import RemoveVehicles from "../pages/IRides/PackageType/PackageVehicles/removeVehicles";

import PromoCode from "../pages/IRides/PromoCode";
import CreatePromoCode from "../pages/IRides/PromoCode/PromoCodeForm";
import PromoHistory from "../pages/IRides/PromoCode/History";
import RedeemHistory from "../pages/IRides/PromoCode/RedeemHistory/index";

import Reward from "../pages/IRides/Reward";
import CreateReward from "../pages/IRides/Reward/RewardForm";
import RewardHistory from "../pages/IRides/Reward/RedeemHistory/index";
import RedeemRewardHistory from "../pages/IRides/Reward/RedeemHistory/index";
import DriverReward from "../pages/IRides/Utility/CommingSoon";

import LoyaltyHistory from "../pages/IRides/Loyalty/History";
import LoyaltyPreview from "../pages/IRides/Loyalty/LoyaltyDetails";
import RidersLoyalty from "../pages/IRides/Loyalty/LoyaltyUsers/ridersLoyalty";
import DriversLoyalty from "../pages/IRides/Loyalty/LoyaltyUsers/driversLoyalty";

import LoyaltyTierForm from "../pages/IRides/Loyalty/LoyaltyTier/LoyaltyTierForm";
import LoyaltyTierPreview from "../pages/IRides/Loyalty/LoyaltyTier/previewLoyaltyTier";
import LoyaltyTier from "../pages/IRides/Loyalty/LoyaltyTier";

import Referral from "../pages/IRides/Referral";
import ReferralForm from "../pages/IRides/Referral/ReferralForm";
import ReferralPreview from "../pages/IRides/Referral/PreviewReferral/index";

import Zones from "../pages/IRides/Zones";
import ZoneForm from "../pages/IRides/Zones/ZoneForm/index";

import Contacts from "../pages/IRides/Emergency/Contact";
import ContactForm from "../pages/IRides/Emergency/Contact/ContactForm/index";

import SOS from "../pages/IRides/Emergency/SOS";

import BetaEmail from "../pages/IRides/Report";
import Activities from "../pages/IRides/Activities";

import Roles from "../pages/IRides/Roles";
import AssignRole from "../pages/IRides/Roles/AssignRole/index";
import RoleForm from "../pages/IRides/Roles/RoleForm/index";
import CreateUser from "../pages/IRides/Roles/createUser.js";

// Authentication related pages
import Login from "../pages/IRides/Authentication/Login";
import Logout from "../pages/IRides/Authentication/Logout";
import Register from "../pages/IRides/Authentication/Registration";
import ActivateAccount from "../pages/IRides/Authentication/ActivateAccount";
import UnsubscribeEmail from "../pages/IRides/Authentication/Unsubscribe";
import DriverInvite from "../pages/IRides/Authentication/driverInvite";
import ResendActivation from "../pages/IRides/Authentication/ResendActivation";
import ForgetPwd from "../pages/IRides/Authentication/ForgetPassword";
import CreateNewPassword from "../pages/IRides/Authentication/setNewPassword";

import Chat from "../pages/IRides/Chat/chat";
import CommingSoon from "../pages/IRides/Utility/CommingSoon";

const authProtectedRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    role: ["ADMIN", "DRIVER", "RIDER"],
    field: {
      module: config.DASHBOARD,
    },
  },
  {
    path: "/users",
    component: Users,
    role: ["ADMIN"],
    field: {
      module: config.DASHBOARD,
      subModule: config.USERS,
    },
  },
  // {
  //   path: '/privileges',
  //   component: PagesBlank,
  //   role: ['ADMIN'],
  //   field: [config.PRIVILEGES],
  // },
  // {
  //   path: '/translations',
  //   component: PagesBlank,
  //   role: ['ADMIN'],
  //   field: [config.TRANSLATION],
  // },
  {
    path: "/car_make",
    component: PagesBlank,
    role: ["ADMIN"],
    // field: [config.CAR_MAKE],
  },
  {
    path: "/car_model",
    component: PagesBlank,
    role: ["ADMIN"],
    // field: [config.CAR_MODEL],
  },

  {
    path: "/package_type",
    component: ServiceType,
    role: ["ADMIN"],
    field: {
      module: config.RIDE_PACKAGE,
    },
  },

  {
    path: "/create_service",
    component: CreateService,
    role: ["ADMIN"],
    // field: [config.CREATE_SERVICE],
  },
  {
    path: "/package_vehicles",
    component: PackageVehicles,
    role: ["ADMIN"],
    field: [config.PACKAGE_TYPE],
  },
  {
    path: "/add_vehicles",
    component: AddVehicles,
    role: ["ADMIN"],
    field: [config.PACKAGE_TYPE],
  },
  {
    path: "/remove_vehicles",
    component: RemoveVehicles,
    role: ["ADMIN"],
    field: [config.PACKAGE_TYPE],
  },
  {
    path: "/needed_document",
    component: PagesBlank,
    role: ["ADMIN"],
    // field: [config.NEEDED_DOCUMENT],
  },

  {
    path: "/service_locations",
    component: PagesBlank,
    role: ["ADMIN"],
    // field: [config.SERVICE_LOCATION],
  },

  {
    path: "/manage_owners",
    component: PagesBlank,
    role: ["ADMIN"],
    // field: [config.MANAGE_OWNER],
  },

  {
    path: "/manage_fleet",
    component: PagesBlank,
    role: ["ADMIN"],
    // field: [config.MANAGE_FLEET]
  },

  {
    path: "/admins",
    component: Admin,
    role: ["ADMIN"],
    field: {
      module: config.USERS,
      subModule: config.ADMINS,
    },
  },
  {
    path: "/inspectors",
    component: Inspection,
    role: ["ADMIN"],
    field: {
      module: config.USERS,
      subModule: config.INSPECTORS,
    },
  },
  {
    path: "/driver_review",
    component: DriverReview,
    role: ["ADMIN"],
    field: {
      module: config.RATING_REVIEW,
      subModule: config.DRIVER_REVIEW,
    },
  },
  {
    path: "/cashout_analytic",
    component: CashOutAnalytic,
    role: ["ADMIN"],
    field: {
      module: config.ANALYTICS,
      subModule: config.CASHOUT,
    },
  },

  {
    path: "/user_review",
    component: UserReview,
    role: ["ADMIN"],
    field: {
      module: config.RATING_REVIEW,
      subModule: config.RIDER_REVIEWS,
    },
  },

  {
    path: "/review_details",
    component: ReviewDetails,
    role: ["ADMIN"],
  },

  {
    path: "/rides",
    component: Rides,
    role: ["ADMIN", "DRIVER", "RIDER"],
    field: {
      module: config.RIDES,
      subModule: config.HISTORY,
    },
  },
  {
    path: "/rides_monitory",
    component: RidesMonitory,
    role: ["ADMIN"],
    field: {
      module: config.RIDES,
      subModule: config.MONITORING,
    },
  },
  {
    path: "/fleet",
    component: CommingSoon,
    role: ["ADMIN"],
    field: {
      module: config.INSPECTIONS,
      subModule: config.FLEETS,
    },
  },
  {
    path: "/agents",
    component: CommingSoon,
    role: ["ADMIN"],
    field: {
      module: config.INSPECTIONS,
      subModule: config.AGENTS,
    },
  },

  {
    path: "/my_rides",
    component: MyRides,
    role: ["ADMIN", "DRIVER", "RIDER"],
    // field: [config.MY_RIDES],
  },

  {
    path: "/chat",
    component: Chat,
    role: ["ADMIN"],
    field: [config.CHAT],
  },

  {
    path: "/ride/:id",
    component: TripDetails,
    role: ["ADMIN", "DRIVER", "RIDER"],
  },
  {
    path: "/access_control_form",
    component: CreateAcccess,
    role: ["ADMIN", "DRIVER", "RIDER"],
    field: {
      module: config.CONFIGURATION,
      subModule: config.ACCESS_CONTROL,
    },
  },
  {
    path: "/ride_form",
    component: CreateRide,
    role: ["ADMIN", "DRIVER", "RIDER"],
    field: {
      module: config.RIDES,
      subModule: config.HISTORY,
    },
  },
  {
    path: "/trip_form",
    component: CreateRide,
    role: ["ADMIN"],
  },
  {
    path: "/riders",
    component: Riders,
    role: ["ADMIN", "DRIVERS"],
    field: {
      module: config.USERS,
      subModule: config.RIDERS,
    },
  },
  {
    path: "/drivers",
    component: Drivers,
    role: ["ADMIN"],
    // field: [config.DRIVERS],
  },
  {
    path: "/car-drivers",
    component: CarDrivers,
    role: ["ADMIN"],
    // field: [config.DRIVERS],
  },
  {
    path: "/bus-drivers",
    component: BusDrivers,
    role: ["ADMIN"],
    // field: [config.DRIVERS],
  },
  {
    path: "/tryke_riders",
    component: TrykeRiders,
    role: ["ADMIN"],
    // field: [config.TRYKE_RIDERS],
  },
  {
    path: "/byke_riders",
    component: BykeRiders,
    role: ["ADMIN"],
    // field: [config.BYKE_RIDERS],
  },

  {
    path: "/drivers-log",
    component: DriverLogs,
    role: ["ADMIN"],
    field: {
      module: config.USERS,
      subModule: config.DRIVER_LOGS,
    },
  },
  {
    path: "/drivers-records",
    component: AllDriverLogs,
    role: ["ADMIN"],
    // field: [config.DRIVERS_RECORDS],
  },
  {
    path: "/drivers-hours",
    component: DriverHours,
    role: ["ADMIN"],
    field: {
      module: config.USERS,
      subModule: config.DRIVER_HOURS,
    },
  },

  {
    path: "/vehicles",
    component: Vehicle,
    role: ["ADMIN"],
    field: {
      module: config.VEHICLES,
    },
  },
  {
    path: "/vehicle/:id",
    component: VehiclePreview,
    role: ["ADMIN"],
    field: {
      module: config.VEHICLES,
    },
  },
  {
    path: "/vehicle_form",
    component: CreateVehicle,
    role: ["ADMIN"],
    field: {
      module: config.VEHICLES,
    },
  },
  {
    path: "/banks",
    component: Bank,
    role: ["ADMIN"],
    field: {
      module: config.FINANCES,
      subModule: config.BANK_DETAIL,
    },
  },
  {
    path: "/payment",
    component: Payment,
    role: ["ADMIN"],
    field: {
      module: config.FINANCES,
      subModule: config.PAYMENTS,
    },
  },
  {
    path: "/bank_form",
    component: CreateBank,
    role: ["ADMIN"],
    field: {
      module: config.FINANCES,
      subModule: config.BANK_DETAIL,
    },
  },
  {
    path: "/cashout",
    component: Cashout,
    role: ["ADMIN"],
    field: {
      module: config.FINANCES,
      subModule: config.CASHOUT,
    },
  },
  {
    path: "/cashout_form",
    component: CreateCashout,
    role: ["ADMIN"],
    field: {
      module: config.FINANCES,
      subModule: config.CASHOUT,
    },
  },
  {
    path: "/bonus",
    component: Bonus,
    role: ["ADMIN"],
    field: {
      module: config.BONUS,
      subModule: config.ALL,
    },
  },
  {
    path: "/bonus_form",
    component: CreateBonus,
    role: ["ADMIN"],
    field: {
      module: config.BONUS,
      subModule: config.ALL,
    },
  },
  {
    path: "/bonus-log",
    component: BonusLog,
    role: ["ADMIN"],
    field: {
      module: config.BONUS,
      subModule: config.BONUS_LOGS,
    },
  },
  {
    path: "/riders-bonus",
    component: RiderBonus,
    role: ["ADMIN"],
    field: {
      module: config.BONUS,
      subModule: config.RIDER_BONUS_HISTORY,
    },
  },
  {
    path: "/documents",
    component: Document,
    role: ["ADMIN"],
    field: {
      module: config.DOCUMENTS,
    },
  },
  {
    path: "/document_form",
    component: UpdateDocument,
    role: ["ADMIN"],
    field: {
      module: config.DOCUMENTS,
    },
  },
  {
    path: "/promo_code",
    component: PromoCode,
    role: ["ADMIN"],
    field: {
      module: config.RIDES_PROMOTION,
      subModule: config.PROMO_CODE,
    },
  },
  {
    path: "/promo_code_form",
    component: CreatePromoCode,
    role: ["ADMIN"],
    field: {
      module: config.RIDES_PROMOTION,
      subModule: config.PROMO_CODE,
    },
  },
  {
    path: "/promo_history",
    component: PromoHistory,
    role: ["ADMIN"],
    field: {
      module: config.RIDES_PROMOTION,
      subModule: config.HISTORY,
    },
  },
  { path: "/redeem_history/:id", component: RedeemHistory, role: ["ADMIN"] },
  {
    path: "/referral",
    component: Referral,
    role: ["ADMIN"],
    field: {
      module: config.REFERRAL,
    },
  },
  {
    path: "/referral_form",
    component: ReferralForm,
    role: ["ADMIN"],
    field: {
      module: config.REFERRAL,
    },
  },
  {
    path: "/user_referral",
    component: ReferralPreview,
    role: ["ADMIN"],
    // field: [config.USER_REFERRAL],
  },
  // Reward
  {
    path: "/reward",
    component: Reward,
    role: ["ADMIN"],
    field: {
      module: config.REWARDS,
      subModule: config.RIDERS_REWARDS,
    },
  },
  {
    path: "/reward_form",
    component: CreateReward,
    role: ["ADMIN"],
    // field: [config.REWARD_FORM],
  },
  {
    path: "/reward_history",
    component: RewardHistory,
    role: ["ADMIN"],
    field: {
      module: config.REWARDS,
      subModule: config.REWARD_LOGS,
    },
  },
  {
    path: "/drivers-rewards",
    component: DriverReward,
    role: ["ADMIN"],
    field: {
      module: config.REWARDS,
      subModule: config.DRIVER_REWARDS,
    },
  },
  {
    path: "/reward_history/:id",
    component: RedeemRewardHistory,
    role: ["ADMIN"],
  },

  {
    path: "/cancellation_rides",
    component: PagesBlank,
    role: ["ADMIN"],
  },

  {
    path: "/vehicle_types",
    component: PagesBlank,
    role: ["ADMIN"],
    // field: [config.VEHICLE_TYPES],
  },
  {
    path: "/driver_ratings",
    component: PagesBlank,
    role: ["ADMIN"],
  },
  {
    path: "/withdrawal_requests",
    component: PagesBlank,
    role: ["ADMIN"],
  },
  {
    path: "/users",
    component: PagesBlank,
    role: ["ADMIN"],
    field: {
      module: config.USERS,
      subModule: config.USERS,
    },
  },
  {
    path: "/user/:id",
    component: UserDetails,
    role: ["ADMIN"],
    field: {
      module: config.USERS,
      subModule: config.USERS,
    },
  },
  {
    path: "/profile",
    component: Profile,
    role: ["ADMIN", "DRIVER", "RIDER"],
    field: [config.PROFILE],
  },
  {
    path: "/settings",
    component: Settings,
    role: ["ADMIN", "DRIVER", "RIDER"],
    // field: [config.SETTINGS],
  },
  {
    path: "/assigned_complains",
    component: AssignedComplains,
    role: ["ADMIN"],
    field: {
      module: config.COMPLAINTS,
      subModule: config.MY_COMPLAINTS,
    },
  },
  {
    path: "/general_complain",
    component: GeneralComplaint,
    role: ["ADMIN"],
    field: {
      module: config.COMPLAINTS,
      subModule: config.GENERAL_COMPLAINTS,
    },
  },
  {
    path: "/complains",
    component: MyComplaint,
    role: ["DRIVER", "RIDER"],
  },
  {
    path: "/create_title",
    component: CreateComplaint,
    role: ["ADMIN"],
    field: {
      module: config.COMPLAINTS,
      subModule: config.MY_COMPLAINTS,
    },
  },
  {
    path: "/emergency_nember",
    component: PagesBlank,
    role: ["ADMIN"],
  },
  {
    path: "/push_notification",
    component: CreatePushNotification,
    role: ["ADMIN"],
    field: {
      module: config.CUSTOM_NOTIFICATION,
      subModule: config.PUSH_NOTIFICATION,
    },
  },
  {
    path: "/create_notification",
    component: CreateNotification,
    role: ["ADMIN"],
  },
  {
    path: "/notification",
    component: Notification,
    role: ["ADMIN", "DRIVER", "RIDER"],
    field: {
      module: config.CUSTOM_NOTIFICATION,
      subModule: config.GENERAL_NOTIFICATION,
    },
  },
  {
    path: "/announcements",
    component: Announcements,
    role: ["ADMIN"],
    field: {
      module: config.CUSTOM_NOTIFICATION,
      subModule: config.ANNOUCEMENTS,
    },
  },
  {
    path: "/schedule",
    component: Schedule,
    role: ["ADMIN"],
    field: {
      module: config.CUSTOM_NOTIFICATION,
      subModule: config.SCHEDULE_NOTIFICATION,
    },
  },
  {
    path: "/notification_log",
    component: NotificationLog,
    role: ["ADMIN"],
    field: {
      module: config.CUSTOM_NOTIFICATION,
      subModule: config.NOTIFICATION_LOG,
    },
  },
  {
    path: "/loyalty_history",
    component: LoyaltyHistory,
    role: ["ADMIN"],
    field: {
      module: config.LOYALTY,
      subModule: config.USAGE_HISTORY,
    },
  },
  {
    path: "/loyalty_preview",
    component: LoyaltyPreview,
    role: ["ADMIN"],
    field: {
      module: config.LOYALTY,
      subModule: config.USAGE_HISTORY,
    },
  },
  {
    path: "/riders_loyalty",
    component: RidersLoyalty,
    role: ["ADMIN"],
    field: {
      module: config.LOYALTY,
      subModule: config.RIDER_LOYALTIES,
    },
  },
  {
    path: "/drivers_loyalty",
    component: DriversLoyalty,
    role: ["ADMIN"],
    field: {
      module: config.LOYALTY,
      subModule: config.DRIVER_LOYALTIES,
    },
  },

  {
    path: "/loyaltyTier_Form",
    component: LoyaltyTierForm,
    role: ["ADMIN"],
  },
  {
    path: "/loyaltyTier_preview",
    component: LoyaltyTierPreview,
    role: ["ADMIN"],
  },
  {
    path: "/loyaltyTier",
    component: LoyaltyTier,
    role: ["ADMIN"],
    field: {
      module: config.LOYALTY,
      subModule: config.LOYALTY_TIER,
    },
  },

  {
    path: "/zones",
    component: Zones,
    role: ["ADMIN"],
    field: [config.ZONES],
  },
  {
    path: "/zone_form",
    component: ZoneForm,
    role: ["ADMIN"],
    field: {
      module: config.ZONES,
    },
  },
  {
    path: "/contacts",
    component: Contacts,
    role: ["ADMIN"],
    field: {
      module: config.EMERGENCY,
      subModule: config.EMERGENCY_CONTACT,
    },
  },
  {
    path: "/contact_form",
    component: ContactForm,
    role: ["ADMIN"],
    field: {
      module: config.EMERGENCY,
      subModule: config.EMERGENCY_CONTACT,
    },
  },
  {
    path: "/sos",
    component: SOS,
    role: ["ADMIN"],
    field: {
      module: config.EMERGENCY,
      subModule: config.SOS_INFO,
    },
  },
  {
    path: "/app_settings",
    component: AppSetting,
    role: ["ADMIN"],
    field: {
      module: config.CONFIGURATION,
      subModule: config.APP_SETTING,
    },
  },
  {
    path: "/create_app_setting",
    component: CreateAppSetting,
    role: ["ADMIN"],
    field: {
      module: config.CONFIGURATION,
      subModule: config.APP_SETTING,
    },
  },
  {
    path: "/system_settings",
    component: SystemSetting,
    role: ["ADMIN"],
  },
  {
    path: "/document_type",
    component: DocumentType,
    role: ["ADMIN"],
    field: {
      module: config.CONFIGURATION,
      subModule: config.DOCUMENT_TYPE,
    },
  },
  {
    path: "/access_control_fe",
    component: AccessControl_FE,
    role: ["ADMIN"],
    field: {
      module: config.CONFIGURATION,
      subModule: config.ACCESS_CONTROL_FE,
    },
  },

  {
    path: "/document_type_form",
    component: CreateDocumentType,
    role: ["ADMIN"],
    field: {
      module: config.CONFIGURATION,
      subModule: config.DOCUMENT_TYPE,
    },
  },
  {
    path: "/MY_ACCESS_CONTROL",
    component: Roles,
    role: ["ADMIN"],
    field: {
      module: config.CONFIGURATION,
      subModule: config.ACCESS_CONTROL,
    },
  },
  {
    path: "/role_form",
    component: RoleForm,
    role: ["ADMIN"],
    field: {
      module: config.CONFIGURATION,
      subModule: config.ACCESS_CONTROL,
    },
  },

  {
    path: "/create_user",
    component: CreateUser,
    role: ["ADMIN"],
    field: {
      module: config.CONFIGURATION,
      subModule: config.ACCESS_CONTROL,
    },
  },

  {
    path: "/access_control",
    component: Roles,
    role: ["ADMIN"],
    field: {
      module: config.CONFIGURATION,
      subModule: config.ACCESS_CONTROL,
    },
  },
  { path: "/assign_role", component: AssignRole, role: ["ADMIN"] },
  {
    path: "/app_setting/:id",
    component: AppSettingPreview,
    role: ["ADMIN"],
    field: {
      module: config.CONFIGURATION,
      subModule: config.APP_SETTING,
    },
  },
  {
    path: "/app_version",
    component: AppVersion,
    role: ["ADMIN"],
    field: {
      module: config.CONFIGURATION,
      subModule: config.APP_VERSION,
    },
  },
  {
    path: "/app_version_form",
    component: AppVersionForm,
    role: ["ADMIN"],
    field: {
      module: config.CONFIGURATION,
      subModule: config.APP_VERSION,
    },
  },
  {
    path: "/licenseKey",
    component: LicenseKey,
    role: ["ADMIN"],
    field: {
      module: config.CONFIGURATION,
      subModule: config.LICENSE,
    },
  },
  {
    path: "/licenseKey_form",
    component: LicenseKeyForm,
    role: ["ADMIN"],
    field: {
      module: config.CONFIGURATION,
      subModule: config.LICENSE,
    },
  },
  {
    path: "/busStop",
    component: BusStop,
    role: ["ADMIN"],
    field: {
      module: config.BUS_STOPS,
    },
  },
  {
    path: "/busStop_form",
    component: BusStopForm,
    role: ["ADMIN"],
    field: {
      module: config.BUS_STOPS,
    },
  },
  {
    path: "/route",
    component: Route,
    role: ["ADMIN"],
    field: {
      module: config.ROUTE,
    },
  },
  {
    path: "/route_form",
    component: RouteForm,
    role: ["ADMIN"],
    field: {
      module: config.ROUTE,
    },
  },
  {
    path: "/tour",
    component: Tour,
    role: ["ADMIN"],
    field: {
      module: config.TOUR,
    },
  },
  {
    path: "/tour_form",
    component: TourForm,
    role: ["ADMIN"],
    field: {
      module: config.TOUR,
    },
  },
  {
    path: "/preview_tour",
    component: PreviewTour,
    role: ["ADMIN"],
    field: {
      module: config.TOUR,
    },
  },
  {
    path: "/dispatchers",
    component: PagesBlank,
    role: ["ADMIN"],
  },

  {
    path: "/activities_log",
    component: Activities,
    role: ["ADMIN"],
    field: {
      module: config.REPORTS,
      subModule: config.ACTIVITIES_LOG,
    },
  },
  {
    path: "/driver_report",
    component: PagesBlank,
    role: ["ADMIN"],
    field: {
      module: config.REPORTS,
      subModule: config.DRIVER_REPORT,
    },
  },
  {
    path: "/beta_emails",
    component: BetaEmail,
    role: ["ADMIN"],
    field: [config.BETA_EMAILS],
  },
  {
    path: "/404",
    component: Pages404,
    role: ["ADMIN", "DRIVER", "RIDER"],
    field: [config.PAGE_NOT_FOUND],
  },
  {
    path: "/feedback",
    component: PagesBlank,
    role: ["ADMIN"],
    field: {
      module: config.FEEDBACK,
    },
  },

  {
    path: "/icons-materialdesign",

    component: IconMaterialdesign,
    role: ["ADMIN", "RIDER", "DRIVER"],
  },
  {
    path: "/icons-ion",
    component: Iconion,
    role: ["ADMIN", "RIDER", "DRIVER"],
  },
  {
    path: "/icons-fontawesome",
    component: IconFontawesome,
    role: ["ADMIN", "RIDER", "DRIVER"],
  },

  {
    path: "/",
    component: Dashboard,
    role: ["ADMIN", "DRIVER", "RIDER"],
    field: [config.DASHBOARD],
  },
  {
    path: "*",
    component: Pages404,
    role: ["ADMIN", "DRIVER", "RIDER"],
  },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/password-reset", component: CreateNewPassword },
  { path: "/register", component: Register },
  { path: "/activate/:id", component: ActivateAccount },
  { path: "/unsubscribe", component: UnsubscribeEmail },
  { path: "/driver-login", component: DriverInvite },
  { path: "/driver-activate", component: DriverInvite },
  { path: "/resend-activation", component: ResendActivation },
  // { path: '/not-found', component: PagesBlank },
];

export { authProtectedRoutes, publicRoutes };
