export const FETCH_ROUTES = 'FETCH_ROUTES';
export const FETCH_ROUTES_SUCCESSFUL = 'FETCH_ROUTES_SUCCESSFUL';
export const FETCH_ROUTES_API_FAILED = 'FETCH_ROUTES_API_FAILED';

export const FETCH_EACH_ROUTE = 'FETCH_EACH_ROUTE';
export const FETCH_EACH_ROUTE_SUCCESSFUL =
  'FETCH_EACH_ROUTE_SUCCESSFUL';
export const FETCH_EACH_ROUTE_API_FAILED =
  'FETCH_EACH_ROUTE_API_FAILED';

export const DELETE_ROUTE = 'DELETE_ROUTE';
export const DELETE_ROUTE_SUCCESSFUL = 'DELETE_ROUTE_SUCCESSFULLY';
export const DELETE_ROUTE_FAILED = 'DELETE_ROUTE_FAILED';

export const CREATE_ROUTE = 'CREATE_ROUTE';
export const CREATE_ROUTE_SUCCESSFUL = 'CREATE_ROUTE_SUCCESSFULLY';
export const CREATE_ROUTE_FAILED = 'CREATE_ROUTE_FAILED';

export const UPDATE_ROUTE = 'UPDATE_ROUTE';
export const UPDATE_ROUTE_SUCCESSFUL = 'UPDATE_ROUTE_SUCCESSFULLY';
export const UPDATE_ROUTE_FAILED = 'FAILED';
