import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_REWARD,
  FETCH_REWARD_HISTORY,
  FETCH_REWARD_SUMMARY,
  FETCH_REWARD_REDEEM_HISTORY,
  FETCH_EACH_REWARD,
  CREATE_REWARD,
  DELETE_REWARD,
  UPDATE_REWARD,
} from './actionTypes';
import {
  fetchRewardSuccessful,
  fetchRewardError,
  fetchRewardHistorySuccessful,
  fetchRewardHistoryError,
  fetchRewardRedeemHistorySuccessful,
  fetchRewardRedeemHistoryError,
  fetchEachRewardSuccessful,
  fetchEachRewardError,
  deleteRewardSuccessful,
  deleteRewardFailed,
  createRewardSuccessful,
  createRewardFailed,
  updateRewardSuccessful,
  updateRewardFailed,
  fetchRewardSummaryFailed,
  fetchRewardSummarySuccessfully,
} from './actions';

import {
  GetRewardService,
  GetEachRewardService,
  DeleteRewardService,
  CreateRewardService,
  UpdateRewardService,
  fetchRewardSummaryService,
  GetRewardHistoryService,
  GetRewardRedeemHistoryService,
} from '../../services/rewardCodeService';

function* fetchRewardHandler({ payload }) {
  try {
    const response = yield call(GetRewardService, payload);
    yield put(fetchRewardSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchRewardError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchRewardError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchRewardError(error?.response?.data?.message));
    }
  }
}

function* fetchRewardHistoryHandler({ payload }) {
  try {
    const response = yield call(GetRewardHistoryService, payload);
    yield put(fetchRewardHistorySuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchRewardHistoryError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchRewardHistoryError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchRewardHistoryError(error?.response?.data?.message));
    }
  }
}

function* fetchRewardRedeemHistoryHandler({ payload }) {
  try {
    const response = yield call(GetRewardRedeemHistoryService, payload);
    yield put(fetchRewardRedeemHistorySuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchRewardRedeemHistoryError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchRewardRedeemHistoryError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchRewardRedeemHistoryError(error?.response?.data?.message));
    }
  }
}

function* fetchEachRewardCodeHandler({ payload }) {
  try {
    const response = yield call(GetEachRewardService, payload);
    yield put(fetchEachRewardSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachRewardError(error?.response?.data?.message));
  }
}
function* deleteRewardHandler({ payload }) {
  try {
    const response = yield call(DeleteRewardService, payload);
    yield put(deleteRewardSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteRewardFailed(error?.response?.data?.error));
  }
}

function* createRewardHandler({ payload }) {
  try {
    const response = yield call(CreateRewardService, payload);
    yield put(createRewardSuccessful(response.data.result));
  } catch (error) {
    yield put(createRewardFailed(error?.response?.data?.error.error.message));
  }
}

function* updateRewardHandler({ payload }) {
  try {
    const response = yield call(UpdateRewardService, payload);
    yield put(updateRewardSuccessful(response.data.result));
  } catch (error) {
    yield put(updateRewardFailed(error?.response?.data?.error));
  }
}

function* fetchRewardSummaryHandler({ payload }) {
  try {
    const response = yield call(fetchRewardSummaryService, payload);
    yield put(fetchRewardSummarySuccessfully(response.data));
  } catch (error) {
    yield put(fetchRewardSummaryFailed(error.response.data));
  }
}

export function* watchFetchRewardSummary() {
  yield takeEvery(FETCH_REWARD_SUMMARY, fetchRewardSummaryHandler);
}

export function* watchFetchReward() {
  yield takeEvery(FETCH_REWARD, fetchRewardHandler);
}
export function* watchFetchRewardHistory() {
  yield takeEvery(FETCH_REWARD_HISTORY, fetchRewardHistoryHandler);
}
export function* watchFetchRewardRedeemHistory() {
  yield takeEvery(FETCH_REWARD_REDEEM_HISTORY, fetchRewardRedeemHistoryHandler);
}

export function* watchFetchEachReward() {
  yield takeEvery(FETCH_EACH_REWARD, fetchEachRewardCodeHandler);
}
export function* watchDeleteReward() {
  yield takeEvery(DELETE_REWARD, deleteRewardHandler);
}
export function* watchCreateReward() {
  yield takeEvery(CREATE_REWARD, createRewardHandler);
}
export function* watchUpdateReward() {
  yield takeEvery(UPDATE_REWARD, updateRewardHandler);
}

function* rewardCodeSaga() {
  yield all([
    fork(watchFetchReward),
    fork(watchFetchRewardHistory),
    fork(watchFetchEachReward),
    fork(watchFetchRewardSummary),
    fork(watchDeleteReward),
    fork(watchCreateReward),
    fork(watchUpdateReward),
    fork(watchFetchRewardRedeemHistory),
  ]);
}

export default rewardCodeSaga;
