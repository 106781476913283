import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_BANK,
  FETCH_BANK_LIST,
  FETCH_EACH_BANK,
  FETCH_BANK_SUMMARY,
  CREATE_BANK,
  GET_BANK_RECIPIENT,
  DELETE_BANK,
  UPDATE_BANK,
  FETCH_FOR_BANK_CSV,
} from './actionTypes';
import {
  fetchBankListSuccessful,
  fetchBankListError,
  fetchBankSuccessful,
  fetchBankError,
  fetchBankSummaryFailed,
  fetchBankSummarySuccessfully,
  fetchEachBankSuccessful,
  fetchEachBankError,
  deleteBankSuccessful,
  deleteBankFailed,
  getRecipientFailed,
  getRecipientSuccessful,
  createBankSuccessful,
  createBankFailed,
  updateBankSuccessful,
  fetchBankAuthorizeError,
  updateBankFailed,
  fetchBankCSVSuccessfully,
  fetchBankCSVError,
} from './actions';

import {
  GetBankService,
  GetBankListService,
  GetEachBankService,
  DeleteBankService,
  CreateBankService,
  fetchBankSummaryService,
  UpdateBankService,
  GetBankRecipientService,
} from '../../services/bankService';

function* fetchBankListHandler({ payload }) {
  try {
    const response = yield call(GetBankListService, payload);
    yield put(fetchBankListSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchBankListError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchBankListError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchBankListError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchBankHandler({ payload }) {
  try {
    const response = yield call(GetBankService, payload);
    yield put(fetchBankSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchBankAuthorizeError('You are not authorize to view this page')
      );
      return;
    }
    yield put(fetchBankError(error?.response?.data?.message));
  }
}

function* fetchEachBankHandler({ payload }) {
  try {
    const response = yield call(GetEachBankService, payload);
    yield put(fetchEachBankSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachBankError(error?.response?.data?.message));
  }
}

function* deleteBankHandler({ payload }) {
  try {
    const response = yield call(DeleteBankService, payload);
    yield put(deleteBankSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteBankFailed(error?.response?.data?.error));
  }
}

function* createBankHandler({ payload }) {
  try {
    const response = yield call(CreateBankService, payload);
    yield put(createBankSuccessful(response.data.result));
  } catch (error) {
    yield put(createBankFailed(error?.response?.data?.message));
  }
}

function* updateBankHandler({ payload }) {
  try {
    const response = yield call(UpdateBankService, payload);
    yield put(updateBankSuccessful(response.data.result));
  } catch (error) {
    yield put(updateBankFailed(error?.response?.data?.message));
  }
}

function* getRecipientHandler({ payload }) {
  try {
    const response = yield call(GetBankRecipientService, payload);
    yield put(getRecipientSuccessful(response.data.result));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        getRecipientFailed(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        getRecipientFailed(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        getRecipientFailed(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

// BANK SUMMARY
function* fetchBankSummaryHandler({ payload }) {
  try {
    const response = yield call(fetchBankSummaryService, payload);
    yield put(fetchBankSummarySuccessfully(response.data));
  } catch (error) {
    yield put(fetchBankSummaryFailed(error.response.data));
  }
}

function* fetchBankCSVHandler({ payload }) {
  try {
    const response = yield call(GetBankService, payload);
    yield put(fetchBankCSVSuccessfully(response.data));
  } catch (error) {
    yield put(fetchBankCSVError(error?.response?.data?.message));
  }
}

export function* watchFetchBankList() {
  yield takeEvery(FETCH_BANK_LIST, fetchBankListHandler);
}

export function* watchFetchBank() {
  yield takeEvery(FETCH_BANK, fetchBankHandler);
}

export function* watchFetchEachBank() {
  yield takeEvery(FETCH_EACH_BANK, fetchEachBankHandler);
}
export function* watchDeleteBank() {
  yield takeEvery(DELETE_BANK, deleteBankHandler);
}
export function* watchCreateBank() {
  yield takeEvery(CREATE_BANK, createBankHandler);
}
export function* watchUpdateBank() {
  yield takeEvery(UPDATE_BANK, updateBankHandler);
}
export function* watchGetBankRecipient() {
  yield takeEvery(GET_BANK_RECIPIENT, getRecipientHandler);
}
export function* watchFetchBankSummary() {
  yield takeEvery(FETCH_BANK_SUMMARY, fetchBankSummaryHandler);
}
export function* watchBankCSV() {
  yield takeEvery(FETCH_FOR_BANK_CSV, fetchBankCSVHandler);
}

function* bankSaga() {
  yield all([
    fork(watchFetchBankList),
    fork(watchFetchBank),
    fork(watchFetchEachBank),
    fork(watchDeleteBank),
    fork(watchFetchBankSummary),
    fork(watchCreateBank),
    fork(watchUpdateBank),
    fork(watchGetBankRecipient),
    fork(watchBankCSV),
  ]);
}

export default bankSaga;
