import React, { useEffect, useState } from 'react';
import AlartError from '../../../../components/Common/AlertCard';
import { connect } from 'react-redux';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';
import Moment from 'moment';
import { withRouter, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
//Import Breadcrumb
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import Loader from '../../../../components/Common/Loading/index.js';
import MapDirection from '../../../../components/Common/Map/index';
import {
  fetchEachRide,
  fetchDriverLogs,
  fetchRideLog,
} from '../../../../store/actions';

const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const TripDetails = ({
  match,
  ride,
  fetchEachRide,
  fetchDriverLogs,
  fetchRideLog,
  loading,
  ridesError,
  driverLogs,
  rideLog,
  user,
}) => {
  const [locationPermission, setSendLocation] = React.useState(false);
  const [newLocation, setNewLocation] = useState({
    lng: 3.35217,
    lat: 6.57654,
  });
  const breadcrumbItems = [
    { title: 'Trip', link: '#' },
    { title: 'Dashboard', link: '#' },
  ];
  let history = useHistory();
  useEffect(() => {
    if (match.params.id) {
      fetchEachRide(match.params.id);
    }
  }, []);

  useEffect(() => {
    if (
      ride?.status === 'APPROACHING' ||
      ride?.status === 'ACCEPTED' ||
      ride?.status === 'ARRIVED' ||
      ride?.status === 'INROUTE'
    ) {
      fetchDriverLogs({ id: ride.driver.id });
    }
    if (ride) {
      fetchRideLog({ ride: ride.id });
    }
  }, [ride]);

  useEffect(() => {
    let data = {};
    if (driverLogs) {
      data.lat = driverLogs?.result[0]?.lat;
      data.lng = driverLogs?.result[0]?.lng;
    }
    setNewLocation(data);
  }, [driverLogs]);

  if (ridesError && !loading) {
    return (
      <div className='page-content text-center'>
        <AlartError error={ridesError} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='Trips-Details'
            breadcrumbItems={breadcrumbItems}
          />
          <div className='mb-1 mb-2 text-right'>
            <button
              onClick={() => history.goBack()}
              className='btn btn-sm btn-primary'
            >
              <i className='fas fa-arrow-left mr-3' />
              Back
            </button>
          </div>
          <Card>
            <CardBody>
              {ride !== null ? (
                <>
                  <Row className='d-flex justify-content-end align-items-center '>
                    <div className='mb-1 mb-2'>
                      <span className='text-muted'>Created : </span>
                      <span className='font-weight-bold card-title mr-1'>
                        {Moment(ride.createdAt).format('l')}
                      </span>
                      <span className='badge badge-soft-secondary font-size-10'>
                        {' '}
                        {Moment(ride.createdAt).format('LT')}
                      </span>
                    </div>
                  </Row>

                  <div>
                    <Row>
                      <Col md={5}>
                        <h5 className='font-size-2  py-1 rounded card-title'>
                          RIDER
                        </h5>
                        <div>
                          <Card className='border'>
                            <CardBody>
                              <Link to={`/user/${ride.rider.id}`}>
                                <div className='d-flex align-items-center my-2 text-dark'>
                                  <img
                                    src={
                                      ride.rider?.picture
                                        ? ride.rider?.picture
                                        : avatarImg
                                    }
                                    alt=''
                                    height='20'
                                    className='rounded-circle avatar-md mr-2'
                                  />

                                  <div className='col-10'>
                                    <div className='text-capitalize'>
                                      {ride.rider.firstName}{' '}
                                      {ride.rider.lastName}
                                    </div>
                                    <span className='d-flex justify-content-between'>
                                      <span>
                                        <span className='text-muted badge badge-soft-secondary'>
                                          <i className=' fas fa-star text-warning mr-1' />
                                          {ride.rider?.rating.toFixed(2)}
                                        </span>
                                      </span>
                                      <span>
                                        <span className='badge badge-soft-success rounded  rounded px-2 '>
                                          {ride?.ridePackage.name}
                                        </span>
                                      </span>
                                    </span>

                                    {/* <span className='badge badge-soft-primary rounded px-2 '>
                                      view profile
                                    </span> */}
                                    <div className='font-size-14'>
                                      <h6 className='badge badge-soft-secondary rounded px-2 mr-2'>
                                        Bookings (
                                        {ride?.rider.trips +
                                          ride?.rider.cancellations +
                                          ride?.rider.ongoing}
                                        )
                                      </h6>
                                      <h6 className='badge badge-soft-success rounded px-2'>
                                        Completed ({ride?.rider.trips})
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </CardBody>
                          </Card>
                        </div>
                        <hr />
                        {ride.tripDetails && (
                          <>
                            <div className='d-flex mb-2'>
                              <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                                Actual Distance:
                              </h5>
                              <h5 className='mb-1 font-size-14'>
                                {ride.tripDetails.distance / 1000}Km
                              </h5>
                            </div>
                            <div className='d-flex mb-2'>
                              <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                                Actual Duration:
                              </h5>
                              <h5 className='mb-1 font-size-14'>
                                {(ride.tripDetails.duration / 60).toFixed(0)}m,{' '}
                                {(ride.tripDetails.duration % 60).toFixed(0)}s
                              </h5>
                            </div>
                          </>
                        )}
                        <div className='d-flex mb-2'>
                          <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                            Estimation Distance:
                          </h5>
                          <h5 className='mb-1 font-size-14'>
                            {' '}
                            {(ride?.distance / 1000).toLocaleString()}Km
                          </h5>
                        </div>
                        <div className='d-flex mb-2'>
                          <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                            Estimation Duration:
                          </h5>
                          <h5 className='mb-1 font-size-14'>
                            {' '}
                            {Math.floor(ride.duration / 60)}m ,{' '}
                            {ride.duration % 60}s
                          </h5>
                        </div>
                        <div className='d-flex mb-2'>
                          <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                            Ride Start Time:
                          </h5>
                          <h5 className='mb-1 font-size-14'>
                            {ride.startTime
                              ? Moment(ride.startTime).format('LT')
                              : 'Pending'}
                          </h5>
                        </div>
                        <div className='d-flex mb-2'>
                          <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                            Ride End Time:
                          </h5>
                          <h5 className='mb-1 font-size-14'>
                            {ride.startTime
                              ? Moment(ride.endTime).format('LT')
                              : 'Pending'}
                          </h5>
                        </div>
                        <hr />
                        <div className='d-flex mb-2'>
                          <h5 className='mb-1 font-size-14 text-muted'>
                            <i className='ri-map-pin-2-fill text-success mr-2' />
                            {ride.pickUp?.address}
                          </h5>
                        </div>
                        <div className='d-flex mb-2'>
                          <h5 className='mb-1 font-size-14 text-muted'>
                            <i className='ri-map-pin-3-line text-danger mr-2' />
                            {ride.dropOff?.address}
                          </h5>
                        </div>
                        <hr />
                        <div className='d-flex mb-2'>
                          <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                            Base Price:
                          </h5>
                          <h5 className='mb-1 font-size-14'>
                            ₦{ride.ridePackage.basePrice}
                          </h5>
                        </div>

                        {ride?.bill && (
                          <>
                            <div className='d-flex mb-2'>
                              <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                                Distance Price:
                              </h5>
                              <h5 className='mb-1 font-size-14'>
                                ₦
                                {ride?.bill?.distancePrice?.toLocaleString(
                                  'en-US'
                                )}
                              </h5>
                            </div>
                            <div className='d-flex mb-2'>
                              <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                                Time Price:
                              </h5>
                              <h5 className='mb-1 font-size-14'>
                                ₦
                                {ride?.bill?.timePrice?.toLocaleString('en-US')}
                              </h5>
                            </div>
                          </>
                        )}
                        {ride?.bill?.toll > 0 && (
                          <div className='d-flex mb-2'>
                            <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                              Toll Price:
                            </h5>
                            <h5 className='mb-1 font-size-14'>
                              ₦{ride?.bill?.toll?.toLocaleString('en-US')}
                            </h5>
                          </div>
                        )}
                        <div className='d-flex mb-2'>
                          <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                            Total Charged:
                          </h5>
                          <h5 className='mb-1 font-size-14'>
                            ₦{ride?.totalAmount?.toLocaleString('en-US')}
                          </h5>
                        </div>
                        {/* {ride.tripDetails && (
                          <div className='d-flex mb-2'>
                            <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                              Actual Cost:
                            </h5>
                            <h5 className="mb-1 text-success font-size-14">
                              ₦
                              {parseInt(
                                ride?.tripDetails?.tripActualCost,
                              ).toLocaleString('en-US')}
                            </h5>
                          </div>
                        )} */}
                        {ride?.bill?.discount > 0 && (
                          <div className='d-flex mb-2'>
                            <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                              Discount:
                            </h5>
                            <h5 className='mb-1 text-danger font-size-14'>
                              ₦
                              {parseInt(ride?.bill.discount).toLocaleString(
                                'en-US'
                              )}
                            </h5>
                          </div>
                        )}
                        {ride.paymentMethod && (
                          <div className='d-flex mb-2'>
                            <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                              Payment
                              <i className='text-warning fas fa-wallet ml-1' />{' '}
                              :
                            </h5>
                            <h5 className='mb-1 rounded font-size-14 rounded'>
                              <span>
                                <span
                                  className={`font-weight-bold text-white bg-${
                                    ride.paymentMethod === 'CARD'
                                      ? 'warning'
                                      : ride.paymentMethod === 'CASH'
                                      ? 'success'
                                      : 'primary'
                                  } px-2 rounded`}
                                >
                                  {ride.paymentMethod === 'ACCOUNT'
                                    ? 'NPAY'
                                    : ride.paymentMethod}
                                </span>
                              </span>
                            </h5>
                          </div>
                        )}
                        {ride.redemption && (
                          <>
                            <hr />
                            <div className='d-flex mb-2'>
                              <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                                Promo Used:
                              </h5>
                              <h5 className='mb-1 font-size-14'>
                                {ride?.redemption?.promoCode?.name}
                              </h5>
                            </div>
                            {/* <div className='d-flex mb-2'>
                              <h5 className='col-6 font-size-14 text-capitalize text-muted'>
                                Promo Details:
                              </h5>
                              <h5 className='mb-1 font-size-14'>
                                ₦
                                {parseInt(
                                  ride?.redemption?.promoCode?.value
                                ).toLocaleString('en-US')}{' '}
                                /{' '}
                                <span className='text-success'>
                                  {ride?.redemption?.promoCode?.totalAtStart}
                                </span>
                                /
                                <span className='text-danger'>
                                  {ride?.redemption?.promoCode?.total}
                                </span>
                              </h5>
                            </div> */}
                          </>
                        )}
                        {ride.cancellation && (
                          <>
                            <hr />
                            <h5 className='font-size-14 text-center text-danger'>
                              Cancellation Reason
                              <span
                                className='badge rounded ml-2'
                                style={{
                                  paddingTop: '4px',

                                  background: `${
                                    ride?.cancellation?.reasonKey ===
                                    'SYSTEM_REQUEST'
                                      ? '#6c757d2e'
                                      : ride?.cancellation?.requester ===
                                        'RIDER'
                                      ? '#f18357'
                                      : ride?.cancellation?.requester ===
                                        'ADMIN'
                                      ? '#007bff'
                                      : '#00a859'
                                  }`,
                                  color: `${
                                    ride?.cancellation?.reasonKey !==
                                    'SYSTEM_REQUEST'
                                      ? 'white'
                                      : 'black'
                                  }`,
                                }}
                              >
                                {ride?.cancellation?.reasonKey ===
                                'SYSTEM_REQUEST'
                                  ? 'SYSTEM'
                                  : ride?.cancellation?.requester}
                              </span>
                            </h5>
                            <hr />
                            <h5 className='mb-1 text-center'>
                              {ride.cancellation.reason}
                            </h5>
                          </>
                        )}
                      </Col>
                      <Col md={7}>
                        <div className='position-relative'>
                          <div
                            className='position-absolute w-100'
                            style={{ zIndex: '100', top: '10px' }}
                          >
                            <div className='d-flex justify-content-end pr-5'>
                              <div className='bg-white col-7 mr-5 rounded p-2'>
                                <h5 className='font-size-2  py-1 rounded card-title d-flex justify-content-between'>
                                  <span>CAPTAIN</span>
                                  <span>
                                    {' '}
                                    NR-{ride?.driver?.id.substring(0, 7)}{' '}
                                  </span>
                                </h5>
                                {ride.driver ? (
                                  <div>
                                    <Link to={`/user/${ride.driver.id}`}>
                                      <div className='d-flex align-items-center my-2'>
                                        <img
                                          src={
                                            ride.driver?.picture
                                              ? ride.driver?.picture
                                              : avatarImg
                                          }
                                          alt=''
                                          height='20'
                                          className='rounded-circle avatar-sm mr-2'
                                        />

                                        <div className='col-10'>
                                          <div className='text-capitalize'>
                                            {ride.driver.firstName}{' '}
                                            {ride.driver.lastName}
                                            <span>
                                              <span className='text-muted font-size-12 badge badge-soft-secondary ml-2'>
                                                <i className=' fas fa-star text-warning mr-1' />
                                                {ride.driver?.rating.toFixed(2)}
                                              </span>
                                            </span>
                                          </div>
                                          <span
                                            className={`font-size-10 badge badge-${
                                              ride.status === 'PROCESSING'
                                                ? 'warning'
                                                : ride.status === 'ACCEPTED'
                                                ? 'success'
                                                : ride.status === 'CANCELLED'
                                                ? 'danger'
                                                : ride.status === 'COMPLETED'
                                                ? 'primary'
                                                : 'dark'
                                            }`}
                                          >
                                            {ride.status}
                                          </span>
                                          <span className='d-flex justify-content-between text-warning'>
                                            <span>
                                              <span className='text-warning font-size-12'>
                                                {ride.driver?.trips}
                                                <span> Trips </span>
                                              </span>
                                            </span>
                                            <span className='badge badge-soft-primary rounded px-2 '>
                                              view profile
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                ) : (
                                  <span>
                                    <p className='text-center'>
                                      {' '}
                                      No Captain Found
                                    </p>
                                    <span className='mt-2 text-center w-100 d-flex justify-content-around'>
                                      <span className='badge badge-danger'>
                                        {' '}
                                        Trip Cancelled{' '}
                                      </span>
                                    </span>
                                  </span>
                                )}{' '}
                              </div>
                            </div>
                          </div>
                          {ride.status !== 'COMPLETED' &&
                          ride.status !== 'CANCELLED' &&
                          user.userType !== 'ADMIN' ? (
                            <div
                              className='position-absolute w-100'
                              style={{
                                zIndex: '100',
                                top: '70px',
                                left: '30px',
                              }}
                            >
                              <h6>Send Location</h6>
                              <BootstrapSwitchButton
                                checked={locationPermission}
                                size='sm'
                                onChange={(e) => setSendLocation(e)}
                                onlabel='ON'
                                offlabel='OFF'
                                onstyle='success'
                                offstyle='danger'
                                width={100}
                                // eslint-disable-next-line react/style-prop-object
                                style={'mr-3'}
                              />
                            </div>
                          ) : null}
                        </div>
                        <div className='bg-light w-100 h-100'>
                          {ride?.status === 'COMPLETED' ||
                          ride?.status === 'CANCELLED' ? (
                            <MapDirection
                              startLocation={ride.pickUp}
                              endLocation={ride.dropOff}
                              locationPermission={locationPermission}
                              rideLog={rideLog?.result}
                            />
                          ) : null}

                          {ride?.status === 'APPROACHING' ||
                          ride?.status === 'ACCEPTED' ||
                          ride?.status === 'ARRIVED' ||
                          ride?.status === 'INROUTE'
                            ? newLocation && (
                                <MapDirection
                                  driverLocalion={newLocation}
                                  startLocation={ride.pickUp}
                                  endLocation={ride.dropOff}
                                  locationPermission={locationPermission}
                                />
                              )
                            : null}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <Loader />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { rideLog, ride, loading, ridesError } = state.Rides;
  const { driverLogs } = state.Users;
  const { user } = state.Account;
  return { rideLog, user, ride, loading, ridesError, driverLogs };
};

export default connect(mapStateToProps, {
  fetchRideLog,
  fetchEachRide,
  fetchDriverLogs,
})(withRouter(TripDetails));
