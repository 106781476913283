export const IS_APP_CONFIG_AUTHORIZE = 'IS_APP_CONFIG_AUTHORIZE';

export const FETCH_APP_CONFIGS = 'FETCH_APP_CONFIGS';
export const FETCH_APP_CONFIGS_SUCCESSFUL = 'FETCH_APP_CONFIGS_SUCCESSFUL';
export const FETCH_APP_CONFIGS_API_FAILED = 'FETCH_APP_CONFIGS_API_FAILED';

export const FETCH_EACH_APP_CONFIGS = 'FETCH_EACH_APP_CONFIGS';
export const FETCH_EACH_APP_CONFIGS_SUCCESSFUL =
  'FETCH_EACH_APP_CONFIGS_SUCCESSFUL';
export const FETCH_EACH_APP_CONFIGS_API_FAILED =
  'FETCH_EACH_APP_CONFIGS_API_FAILED';

export const UPDATE_APP_CONFIGS = 'UPDATE_APP_CONFIGS';
export const UPDATE_APP_CONFIGS_SUCCESSFUL = 'UPDATE_APP_CONFIGS_SUCCESSFUL';
export const UPDATE_APP_CONFIGS_API_FAILED = 'UPDATE_APP_CONFIGS_API_FAILED';

export const UPDATE_APP_CONFIG_ITEM = 'UPDATE_APP_CONFIG_ITEM';
export const UPDATE_APP_CONFIG_ITEM_SUCCESSFUL =
  'UPDATE_APP_CONFIG_ITEM_SUCCESSFUL';
export const UPDATE_APP_CONFIG_ITEM_API_FAILED =
  'UPDATE_APP_CONFIG_ITEM_API_FAILED';

export const DELETE_APP_CONFIGS = 'DELETE_APP_CONFIGS';
export const DELETE_APP_CONFIGS_SUCCESSFUL = 'DELETE_APP_CONFIGS_SUCCESSFUL';
export const DELETE_APP_CONFIGS_API_FAILED = 'DELETE_APP_CONFIGS_API_FAILED';

export const DELETE_APP_CONFIG_ITEM = 'DELETE_APP_CONFIG_ITEM';
export const DELETE_APP_CONFIG_ITEM_SUCCESSFUL =
  'DELETE_APP_CONFIG_ITEM_SUCCESSFUL';
export const DELETE_APP_CONFIG_ITEM_API_FAILED =
  'DELETE_APP_CONFIG_ITEM_API_FAILED';

export const CREATE_APP_CONFIGS = 'CREATE_APP_CONFIGS';
export const CREATE_APP_CONFIGS_SUCCESSFUL = 'CREATE_APP_CONFIGS_SUCCESSFUL';
export const CREATE_APP_CONFIGS_API_FAILED = 'CREATE_APP_CONFIGS_API_FAILED';

export const LIST_ACCESS_CONTROL_FE = 'LIST_ACCESS_CONTROL_FE';
export const LIST_ACCESS_CONTROL_FE_SUCCESSFUL =
  'LIST_ACCESS_CONTROL_FE_SUCCESSFUL';
export const LIST_ACCESS_CONTROL_FE_API_FAILED =
  'LIST_ACCESS_CONTROL_FE_API_FAILED';

export const LIST_EACH_ACCESS_CONTROL_FE = 'LIST_EACH_ACCESS_CONTROL_FE';
export const LIST_EACH_ACCESS_CONTROL_FE_SUCCESSFUL =
  'LIST_EACH_ACCESS_CONTROL_FE_SUCCESSFUL';
export const LIST_EACH_ACCESS_CONTROL_FE_API_FAILED =
  'LIST_EACH_ACCESS_CONTROL_FE_API_FAILED';

export const CREATE_ACCESS_CONTROL_FE = 'CREATE_ACCESS_CONTROL_FE';
export const CREATE_ACCESS_CONTROL_FE_SUCCESSFUL =
  'CREATE_ACCESS_CONTROL_FE_SUCCESSFUL';
export const CREATE_ACCESS_CONTROL_FE_API_FAILED =
  'CREATE_ACCESS_CONTROL_FE_API_FAILED';

export const UPDATE_ACCESS_CONTROL_FE = 'UPDATE_ACCESS_CONTROL_FE';
export const UPDATE_ACCESS_CONTROL_FE_SUCCESSFUL =
  'UPDATE_ACCESS_CONTROL_FE_SUCCESSFUL';
export const UPDATE_ACCESS_CONTROL_FE_API_FAILED =
  'UPDATE_ACCESS_CONTROL_FE_API_FAILED';

export const DELETE_ACCESS_CONTROL_FE = 'DELETE_ACCESS_CONTROL_FE';
export const DELETE_ACCESS_CONTROL_FE_SUCCESSFUL =
  'DELETE_ACCESS_CONTROL_FE_SUCCESSFUL';
export const DELETE_ACCESS_CONTROL_FE_API_FAILED =
  'DELETE_ACCESS_CONTROL_FE_API_FAILED';
