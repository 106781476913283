import React, { Component } from 'react'

import { Row, Col, Button, Card, CardBody, Container } from 'reactstrap'

import { Link } from 'react-router-dom'

// import images
import logosmdark from '../../../../assets/images/IrydeLogo.svg'

class ActivateAccount extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    document.body.classList.add('auth-body-bg')
  }

  componentWillUnmount() {
    document.body.classList.remove('auth-body-bg')
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={12}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={4}>
                        <Card className="shadow-lg">
                          <CardBody>
                            <div>
                              <div className="text-center">
                                <div>
                                  <Link to="/" className="logo">
                                    <img
                                      src={logosmdark}
                                      height="50"
                                      alt="logo"
                                    />
                                  </Link>
                                </div>

                                <h4 className="font-size-18 mt-4">
                                  Thank You!
                                </h4>
                                <p className="text-muted">
                                  Check Your Mail to Continue nRyde.
                                </p>
                              </div>
                              <div className="p-2 mt-2">
                                <div className="mt-2 text-center">
                                  <Link to="login">
                                    <Button
                                      color="primary"
                                      className="w-md waves-effect waves-light"
                                    >
                                      Visit Our Login
                                    </Button>
                                  </Link>
                                </div>
                              </div>

                              <div className="mt-2 text-center">
                                {/* <p>
                                  Don't have an account ?{' '}
                                  <Link
                                    to="/Login"
                                    className="font-weight-medium text-primary"
                                  >
                                    {' '}
                                    Register{' '}
                                  </Link>{' '}
                                </p> */}
                                <p>© 2022 nRyde.</p>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ActivateAccount
