import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_LOYALTYTIER,
  FETCH_EACH_LOYALTYTIER,
  FETCH_MY_LOYALTYTIER,
  FETCH_LOYALTYTIER_SUMMARY,
  CREATE_LOYALTYTIER,
  DELETE_LOYALTYTIER,
  UPDATE_LOYALTYTIER,
} from './actionTypes';
import {
  fetchLoyaltyTierSuccessful,
  fetchLoyaltyTierError,
  fetchEachLoyaltyTierSuccessful,
  fetchEachLoyaltyTierError,
  fetchLoyaltyTierSummaryFailed,
  fetchLoyaltyTierSummarySuccessfully,
  fetchMyLoyaltyTierSuccessful,
  fetchMyLoyaltyTierError,
  deleteLoyaltyTierSuccessful,
  deleteLoyaltyTierFailed,
  createLoyaltyTierSuccessful,
  createLoyaltyTierFailed,
  updateLoyaltyTierSuccessful,
  fetchLoyaltyTierAuthorizeError,
  updateLoyaltyTierFailed,
} from './actions';

import {
  GetLoyaltyTierService,
  GetEachLoyaltyTierService,
  DeleteLoyaltyTierService,
  CreateLoyaltyTierService,
  fetchLoyaltyTierSummaryService,
  UpdateLoyaltyTierService,
  GetMyLoyaltyTierService,
} from '../../services/loyaltyTierService';

function* fetchLoyaltyTierHandler({ payload }) {
  try {
    const response = yield call(GetLoyaltyTierService, payload);
    yield put(fetchLoyaltyTierSuccessful(response.data));
  } catch (error) {
    console.log(error.responses);
    if (error.response.status === 403) {
      yield put(
        fetchLoyaltyTierAuthorizeError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    }
    yield put(fetchLoyaltyTierError(error?.response?.data?.message));
  }
}

function* fetchMyLoyaltyTierHandler({ payload }) {
  try {
    const response = yield call(GetMyLoyaltyTierService, payload);
    yield put(fetchMyLoyaltyTierSuccessful(response.data));
  } catch (error) {
    console.log(error.responses);
    if (error.response.status === 403) {
      yield put(
        fetchMyLoyaltyTierError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchMyLoyaltyTierError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchMyLoyaltyTierError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEachLoyaltyTierHandler({ payload }) {
  try {
    const response = yield call(GetEachLoyaltyTierService, payload);
    yield put(fetchEachLoyaltyTierSuccessful(response.data.result));
  } catch (error) {
    console.log(error.responses);
    yield put(fetchEachLoyaltyTierError(error?.response?.data?.message));
  }
}

function* deleteLoyaltyTierHandler({ payload }) {
  try {
    const response = yield call(DeleteLoyaltyTierService, payload);
    yield put(deleteLoyaltyTierSuccessful(response.data.result));
  } catch (error) {
    console.log(error?.response);
    yield put(deleteLoyaltyTierFailed(error?.response?.data?.error));
  }
}

function* createLoyaltyTierHandler({ payload }) {
  try {
    const response = yield call(CreateLoyaltyTierService, payload);
    yield put(createLoyaltyTierSuccessful(response.data.result));
    console.log(response);
  } catch (error) {
    console.log(error?.response);
    yield put(createLoyaltyTierFailed(error?.response?.data?.message));
  }
}

function* updateLoyaltyTierHandler({ payload }) {
  try {
    const response = yield call(UpdateLoyaltyTierService, payload);
    yield put(updateLoyaltyTierSuccessful(response.data.result));
  } catch (error) {
    console.log(error?.response);
    yield put(updateLoyaltyTierFailed(error?.response?.data?.message));
  }
}

// LOYALTYTIER SUMMARY
function* fetchLoyaltyTierSummaryHandler({ payload }) {
  try {
    const response = yield call(fetchLoyaltyTierSummaryService, payload);
    yield put(fetchLoyaltyTierSummarySuccessfully(response.data));
  } catch (error) {
    yield put(fetchLoyaltyTierSummaryFailed(error.response.data));
  }
}

export function* watchFetchLoyaltyTier() {
  yield takeEvery(FETCH_LOYALTYTIER, fetchLoyaltyTierHandler);
}
export function* watchFetchMyLoyaltyTier() {
  yield takeEvery(FETCH_MY_LOYALTYTIER, fetchMyLoyaltyTierHandler);
}
export function* watchFetchEachLoyaltyTier() {
  yield takeEvery(FETCH_EACH_LOYALTYTIER, fetchEachLoyaltyTierHandler);
}
export function* watchDeleteLoyaltyTier() {
  yield takeEvery(DELETE_LOYALTYTIER, deleteLoyaltyTierHandler);
}
export function* watchCreateLoyaltyTier() {
  yield takeEvery(CREATE_LOYALTYTIER, createLoyaltyTierHandler);
}
export function* watchUpdateLoyaltyTier() {
  yield takeEvery(UPDATE_LOYALTYTIER, updateLoyaltyTierHandler);
}
export function* watchFetchLoyaltyTierSummary() {
  yield takeEvery(FETCH_LOYALTYTIER_SUMMARY, fetchLoyaltyTierSummaryHandler);
}

function* loyaltyTierSaga() {
  yield all([
    fork(watchFetchLoyaltyTier),
    fork(watchFetchLoyaltyTierSummary),
    fork(watchFetchEachLoyaltyTier),
    fork(watchFetchMyLoyaltyTier),
    fork(watchDeleteLoyaltyTier),
    fork(watchCreateLoyaltyTier),
    fork(watchUpdateLoyaltyTier),
  ]);
}

export default loyaltyTierSaga;
