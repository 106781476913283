import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Card, CardBody, Button } from 'reactstrap';
import { fetchDashStat } from '../../../store/actions';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

const UserReportCard = ({ user, dashStat, fetchDashStat, start, end }) => {
  let userInfo = user?.id;
  useEffect(() => {
    fetchDashStat({ data: 'user', user: userInfo });
  }, [fetchDashStat, userInfo]);
  return (
    <div>
      <Card className='border'>
        <CardBody>
          <div className='mb-4'>
            <h5> Trips </h5>
          </div>

          <Col md={12}>
            <div className='d-flex justify-content-end mb-2' style={{ marginTop: '-40px' }}>
              <Link
                to={`/rides?user=${user.id}`}
                className='mr-2 font-size-11 text-success'
              >
                See more trips
                {/* <i className='fas fa-arrow-right ml-2' /> */}
              </Link>
            </div>
            <Row className='mt-5'>
              <Col xs={4}>
                <h4 className='text-center mb-2 text-secondary'>
                  {/* <i className='fas fa-times mr-2 p-2 font-size-12 text-danger  bg-soft-danger rounded-circle' /> */}
                  {dashStat?.cancelledTrip ? dashStat?.cancelledTrip : '0'}
                </h4>
                <p className='font-size-12 text-muted text-center' style={{ textWrap: 'nowrap' }}>
                  {' '}
                  Cancelled - Trips{' '}
                </p>
              </Col>

              <Col xs={4}>
                <h4 className='text-center mb-2 text-warning'>
                  {/* <i className='fas fa-car font-size-12 text-warning mr-2 p-2 bg-soft-warning rounded-circle' /> */}
                  {dashStat?.ongoingTrip ? dashStat?.ongoingTrip : '0'}
                </h4>
                <p className='font-size-12 text-muted text-center' style={{ textWrap: 'nowrap' }}>
                  {' '}
                  Ongoing - Trip{' '}
                </p>
              </Col>
              <Col xs={4}>
                <h4 className='text-center mb-2 text-success'>
                  {/* <i className='fas fa-check mr-2 p-2 font-size-12 text-success bg-soft-success rounded-circle' /> */}
                  {dashStat?.completedTrip ? dashStat?.completedTrip : '0'}
                </h4>
                <p className='font-size-12 text-muted text-center' style={{ textWrap: 'nowrap' }}>
                  {' '}
                  Completed - Trips{' '}
                </p>
              </Col>
            </Row>

          </Col>
        </CardBody>
      </Card>
      <Card className='border'>
        <CardBody>
          <div className='mb-4'>
            <h5> Complaints </h5>
          </div>

          <Col md={12}>
            <div className='d-flex justify-content-end mb-2' style={{ marginTop: '-40px' }}>
              <Link
                to={`/general_complain?reporterId=${user.id}`}
                className='mr-2 font-size-11 text-success'
              >
                See more complaints
                {/* <i className='fas fa-arrow-right ml-2' /> */}
              </Link>
            </div>
            <Row className='mt-5'>
              <Col xs={4}>
                <h4 className='text-center mb-2 text-secondary'>
                  {/* <i className='ri-article-line mr-2 p-2 font-size-12 text-secondary bg-soft-secondary rounded-circle font-weight-bold' /> */}
                  {dashStat?.complaint ? dashStat?.complaint : '0'}
                </h4>
                <p className='font-size-12 text-muted text-center' style={{ textWrap: 'nowrap' }}>
                  {' '}
                  Complaints
                </p>
              </Col>
              <Col xs={4}>
                <h4 className='text-center mb-2 text-warning'>
                  {/* <i className='ri-todo-line mr-2 p-2 font-size-12 text-warning bg-soft-warning rounded-circle font-weight-bold' /> */}
                  {dashStat?.ongoingComplaint
                    ? dashStat?.ongoingComplaint
                    : '0'}
                </h4>
                <p className='font-size-12 text-muted text-center' style={{ textWrap: 'nowrap' }}>
                  {' '}
                  Ongoing - Complaints
                </p>
              </Col>
              <Col xs={4}>
                <h4 className='text-center mb-2 text-success'>
                  {/* <i className='ri-task-line mr-2 p-2 font-size-12 text-success bg-soft-success rounded-circle font-weight-bold' /> */}
                  {dashStat?.resolvedComplaint
                    ? dashStat?.resolvedComplaint
                    : '0'}
                </h4>
                <p className='font-size-12 text-muted text-center' style={{ textWrap: 'nowrap' }}>
                  {' '}
                  Resolved - Complaints
                </p>
              </Col>
            </Row>{' '}

          </Col>
        </CardBody>
      </Card>
      {user.userType !== 'ADMIN' && (
        <Card className='border'>
          <CardBody>
            <div className='mb-4'>
              <h5> Loyalty Tier History </h5>
            </div>

            <Col md={12}>
              <div className='align-items-center d-flex'>
                <Col md={4} className='d-flex align-items-center'>
                  <h6
                    className='badge badge-secondary font-size-14 d-flex align-items-center px-3 text-capitalize m-0'
                    style={{ borderRadius: '30px', background: '#4AB880' }}
                  >
                    <i className='text-warning ri-vip-crown-2-line mr-1' />
                    Bronze
                  </h6>
                </Col>

                <div>
                  <p className='font-weight-bold font-size-14 m-0'>
                    Started on{' '}
                    {moment(user?.loyalty?.tierStartDate).format(
                      'DD MMM, YYYY'
                    )}
                  </p>
                </div>
              </div>
              <hr />
              <div className='d-flex  mt-3'>
                <Col md={4} className='d-flex align-items-start'>
                  <h6
                    className='badge badge-secondary font-size-14 d-flex align-items-center px-3 text-capitalize m-0'
                    style={{ borderRadius: '30px', background: '#47CBCB' }}
                  >
                    <i className='text-warning ri-vip-crown-2-line mr-1' />
                    Silver
                  </h6>
                </Col>

                <div>
                  <p className='font-weight-bold font-size-14 m-0'>
                    {user.userType === 'DRIVER' ? (
                      user.trips < 100 ? (
                        <>
                          Needs to complete 100 Trips to achieve a silver badge
                          <span className='text-success ml-1'>
                            Currently completed {user.trips} rides
                          </span>
                        </>
                      ) : (
                        <>
                          Started on{' '}
                          {moment(user?.loyalty?.tierStartDate).format(
                            'DD MMM, YYYY'
                          )}
                        </>
                      )
                    ) : user?.loyalty?.accumulatedPoint < 499999 ? (
                      <>
                        Needs to accumulate 500k points to achieve a silver
                        badge
                        <span className='text-success ml-1'>
                          Current accumulated points is{' '}
                          {user?.loyalty?.accumulatedPoint}
                        </span>
                      </>
                    ) : (
                      <>
                        Started on{' '}
                        {moment(user?.loyalty?.tierStartDate).format(
                          'DD MMM, YYYY'
                        )}
                      </>
                    )}
                  </p>
                </div>
              </div>
              <hr />
              <div className='d-flex  mt-3'>
                <Col md={4} className='d-flex align-items-start'>
                  <h6
                    className='badge badge-secondary font-size-14 d-flex align-items-center px-3 text-capitalize m-0'
                    style={{ borderRadius: '30px', background: '#F8BC06' }}
                  >
                    <i className='text-light ri-vip-crown-2-line mr-1' />
                    Gold
                  </h6>
                </Col>

                <div>
                  <p className='font-weight-bold font-size-14 m-0'>
                    {user.userType === 'DRIVER' ? (
                      user.trips < 250 ? (
                        <>
                          Needs to complete 250 Trips to achieve a gold badge
                          <span className='text-success ml-1'>
                            Currently completed {user.trips} rides
                          </span>
                        </>
                      ) : (
                        <>
                          Started on{' '}
                          {moment(user?.loyalty?.tierStartDate).format(
                            'DD MMM, YYYY'
                          )}
                        </>
                      )
                    ) : user?.loyalty?.accumulatedPoint < 1500000 ? (
                      <>
                        Needs to accumulate 1.5m points to achieve a gold badge
                        <span className='text-success ml-1'>
                          Current accumulated points is{' '}
                          {user?.loyalty?.accumulatedPoint}
                        </span>
                      </>
                    ) : (
                      <>
                        Started on{' '}
                        {moment(user?.loyalty?.tierStartDate).format(
                          'DD MMM, YYYY'
                        )}
                      </>
                    )}
                  </p>
                </div>
              </div>
              <hr />
              <div className='d-flex  mt-3'>
                <Col md={4} className='d-flex align-items-start'>
                  <h6
                    className='badge badge-secondary font-size-14 d-flex align-items-center px-2 text-capitalize m-0'
                    style={{ borderRadius: '30px', background: '#7722C3' }}
                  >
                    <i className='text-warning ri-vip-crown-2-line mr-1' />
                    Platinum
                  </h6>
                </Col>

                <div>
                  <p className='font-weight-bold font-size-14 m-0'>
                    {user.userType === 'DRIVER' ? (
                      user.trips < 400 ? (
                        <>
                          Needs to complete 400 Trips to achieve a platinum
                          badge
                          <span className='text-success ml-1'>
                            Currently completed {user.trips} rides
                          </span>
                        </>
                      ) : (
                        <>
                          Started on{' '}
                          {moment(user?.loyalty?.tierStartDate).format(
                            'DD MMM, YYYY'
                          )}
                        </>
                      )
                    ) : user?.loyalty?.accumulatedPoint < 3000000 ? (
                      <>
                        Needs to accumulate 3m points to achieve a platinum
                        badge
                        <span className='text-success ml-1'>
                          Current accumulated points is{' '}
                          {user?.loyalty?.accumulatedPoint}
                        </span>
                      </>
                    ) : (
                      <>
                        Started on{' '}
                        {moment(user?.loyalty?.tierStartDate).format(
                          'DD MMM, YYYY'
                        )}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </Col>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { dashStat, loading, dashStatError } = state.Dashboard;
  return { dashStat, loading, dashStatError };
};

export default connect(mapStateToProps, { fetchDashStat })(UserReportCard);
