import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  Button,
} from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { EditControl } from "react-leaflet-draw";
import { MapContainer, TileLayer, FeatureGroup, Polygon } from "react-leaflet";
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import {
  createZone,
  createZoneFailed,
  updateZone,
  updateZoneFailed,
} from "../../../../store/actions";
const Form = ({
  createZone,
  createZoneFailed,
  updateZone,
  updateZoneFailed,
  message,
  history,
  zoneError,
  defaultValue,
  loading,
  newPolygonCord,
  cordinates,
  mapCordinates,
}) => {
  const [description, setDescription] = useState("");
  const [errorMessage, setError] = useState("");
  const [poly, setPoly] = useState("");
  const [edit, setEdit] = useState(null);
  const [mapPolygon, setMapPoly] = useState("");
  const [inputs, setInput] = useState(2);
  const [center, setCenter] = useState(
    defaultValue?.polygon?.coordinates[0]
      ? {
          lat: defaultValue?.polygon?.coordinates[0][0][1],
          lng: defaultValue?.polygon?.coordinates[0][0][0],
        }
      : { lat: 9.072264, lng: 7.491302 }
  );
  const swapArray = (array) => {
    const newArray = [];
    for (const subArray of array) {
      const swappedSubArray = [subArray[1], subArray[0]]; // Swapping values
      newArray.push(swappedSubArray);
    }
    return newArray;
  };
  const handleSubmit = async (event, values) => {
    if (!description) {
      setError("Zone description can not be empty");
      return;
    }
    const zoneData = {
      ...values,
    };
    let newPolygon = [];
    for (let i = 0; i < inputs; i++) {
      newPolygon[i] = [Number(values["lat" + i]), Number(values["lng" + i])];
    }

    zoneData.name = values.name;
    zoneData.description = description;
    zoneData.country = "NIGERIA";
    // if (poly !== '') {
    //   zoneData.polygon = poly;
    // }
    // else {

    // }
    zoneData.polygon = newPolygon;
    if (defaultValue) {
      zoneData.id = defaultValue.id;
      zoneData.status = values.Status;
      updateZone(zoneData);

      return;
    }

    createZone(zoneData);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        createZoneFailed("");
        updateZoneFailed("");
        history.goBack();
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    createZoneFailed("");
    updateZoneFailed("");
  }, []);

  useEffect(() => {
    setPoly("");
    setMapPoly("");
    setDescription("");

    if (newPolygonCord) {
      console.log(newPolygonCord);
      setMapPoly(JSON.parse(newPolygonCord));
      setPoly(swapArray(JSON.parse(newPolygonCord)));
      setInput(JSON.parse(newPolygonCord)?.length);
    }
    if (cordinates) {
      console.log(cordinates);
      setPoly(JSON.parse(cordinates));
      setInput(JSON.parse(cordinates)?.length);
    }
    if (mapCordinates) {
      console.log("mapCordinates: ", mapCordinates);
      if (mapCordinates.includes("cord=")) {
        let cord = mapCordinates.replace("cord=", "");
        setMapPoly(JSON.parse(cord));
        setPoly(swapArray(JSON.parse(cord)));
        setInput(JSON.parse(cord)?.length);
      } else {
        setMapPoly(JSON.parse(mapCordinates));
        setPoly(swapArray(JSON.parse(mapCordinates)));
        setInput(JSON.parse(mapCordinates)?.length);
      }
    }
    if (defaultValue) {
      setDescription(defaultValue.description);
      if (defaultValue?.polygon?.coordinates[0]) {
        setCenter({
          lat: defaultValue?.polygon?.coordinates[0][0][1],
          lng: defaultValue?.polygon?.coordinates[0][0][0],
        });
      }
    }
  }, [cordinates, defaultValue, mapCordinates, newPolygonCord]);
  const renderInputs = () => {
    return [
      Array(Math.ceil(inputs))
        .fill(0)
        .map((star, key) => (
          <Row key={key}>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor={`lat${key}`}>Latitude</Label>
                <AvField
                  name={`lat${key}`}
                  type="number"
                  className="form-control bg-light"
                  id={`lat${key}`}
                  value={
                    poly ? poly[key][1] : mapPolygon ? mapPolygon[key][0] : ""
                  }
                  placeholder="Enter the latitude ..."
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              {" "}
              <FormGroup className="mb-4">
                <Label htmlFor={`lng${key}`}>Longitude</Label>
                <AvField
                  name={`lng${key}`}
                  type="number"
                  className="form-control bg-light"
                  id={`lng${key}`}
                  value={
                    poly ? poly[key][0] : mapPolygon ? mapPolygon[key][1] : ""
                  }
                  placeholder="Enter the longitude ..."
                  required
                />
              </FormGroup>
            </Col>
          </Row>
        )),
    ];
  };
  const mapRef = useRef();
  const _onCreate = (e) => {
    const { layerType, layer } = e;
    if (layerType === "polygon") {
      let cord = JSON.stringify(layer.toGeoJSON().geometry.coordinates[0]);
      let newCord = swapArray(JSON.parse(cord));
      setPoly(newCord);
      setInput(newCord?.length);
    }
  };

  const _onEdited = (e) => {
    const {
      layers: { _layers },
    } = e;
    if (!Object.values(_layers)[0]) {
      return;
    }
    let cord = JSON.stringify(
      Object.values(_layers)[0].toGeoJSON().geometry.coordinates[0]
    );
    Object.values(_layers).map(({ options }) => {
      let newCord = swapArray(JSON.parse(cord));
      setPoly(newCord);
      setInput(newCord?.length);
    });
  };

  useEffect(() => {
    setEdit(false);
    if (poly !== "" || mapPolygon !== "") {
      setEdit(true);
    }
  }, [mapPolygon, poly]);

  return (
    <Card>
      <CardBody>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        {zoneError && (
          <Alert color="danger" className="text-center">
            {zoneError}
          </Alert>
        )}
        {errorMessage && (
          <Alert color="danger" className="text-center">
            {errorMessage}
          </Alert>
        )}
        <div className="float-right">
          <Link to="#" onClick={() => history.goBack()}>
            <i className="fas fa-arrow-left mr-3" />
            Back
          </Link>
        </div>
        <div>
          <h5> {defaultValue ? "Update" : "Create"} Zone </h5>
        </div>
        <div className="col-md-12">
          <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
            <Row>
              <Col className="col-md-6">
                <FormGroup className="mb-4">
                  <Label htmlFor="name">Zone Name</Label>
                  <AvField
                    name="name"
                    type="text"
                    className="form-control bg-light"
                    id="name"
                    value={defaultValue ? defaultValue.name : ""}
                    required
                    placeholder="Enter Zone Name ..."
                  />
                </FormGroup>
                {defaultValue && (
                  <FormGroup className="mb-4">
                    <AvField
                      className="form-control bg-light"
                      type="select"
                      name="Status"
                      label="Status"
                      value={defaultValue ? defaultValue.status : ""}
                    >
                      <option value={"PENDING"}>Pending</option>
                      <option value={"ACTIVE"}>Active</option>
                      <option value={"INACTIVE"}>Inactive</option>
                      <option value={"FREEZE"}>Freeze</option>
                    </AvField>
                  </FormGroup>
                )}

                <FormGroup className=" mb-4">
                  <Label htmlFor="description"> Description</Label>
                  <textarea
                    className="form-control"
                    onChange={(e) => setDescription(e.target.value)}
                    id="description"
                    value={description}
                    rows="5"
                  ></textarea>
                </FormGroup>
              </Col>
              <Col className="col-md-6">
                {/* {!cordinates && ( */}
                <div>
                  <h6 className="mb-3 ml-2">Polygon Coordinates</h6>
                  <Col
                    className="col-md-10"
                    style={{ maxHeight: "270px", overflowY: "scroll" }}
                  >
                    {renderInputs()}
                  </Col>
                  <Col className="col-md-2 d-flex align-items-end mb-4">
                    <Button
                      size="sm"
                      color="success"
                      onClick={() =>
                        poly
                          ? (setInput(inputs + 1),
                            setPoly((poly) => [...poly, ["", ""]]))
                          : mapPolygon
                          ? (setInput(inputs + 1),
                            setMapPoly((mapPolygon) => [...mapPolygon, []]))
                          : setInput(inputs + 1)
                      }
                      className="mr-2"
                    >
                      <i className="mdi mdi-plus-thick font-size-18"></i>{" "}
                    </Button>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() =>
                        poly
                          ? (setInput(inputs - 1),
                            setPoly([...poly.slice(0, -1)]))
                          : mapPolygon
                          ? (setInput(inputs - 1),
                            setMapPoly([...mapPolygon.slice(0, -1)]))
                          : setInput(inputs - 1)
                      }
                    >
                      <i className="mdi mdi-window-minimize font-size-18"></i>{" "}
                    </Button>
                  </Col>
                </div>
                {/* )} */}
              </Col>
            </Row>
            <Row>
              <Col className="py-3">
                {defaultValue && (
                  <div className="col text-center">
                    <div
                      className={edit ? "remove-create col" : "remove-edit col"}
                    >
                      <MapContainer
                        center={
                          defaultValue?.polygon?.coordinates[0]
                            ? {
                                lat: defaultValue?.polygon
                                  ?.coordinates[0][0][1],
                                lng: defaultValue?.polygon
                                  ?.coordinates[0][0][0],
                              }
                            : center
                        }
                        zoom={8}
                        refs={mapRef}
                        // scrollWheelZoom={false}
                        style={{ height: "600px", width: "100%" }}
                      >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <FeatureGroup>
                          {poly !== "" ? (
                            <Polygon
                              pathOptions={{ color: "#3388ff" }}
                              id={defaultValue?.id}
                              positions={poly}
                              draggable={true}
                              edit={true}
                              enable={true}
                              drawing={true}
                              key={defaultValue?.id}
                            />
                          ) : null}

                          <EditControl
                            position="topright"
                            onCreated={_onCreate}
                            onEdited={_onEdited}
                          />
                        </FeatureGroup>
                      </MapContainer>
                    </div>
                  </div>
                )}
                {!defaultValue && (
                  <div className="col text-center">
                    <div
                      className={edit ? "remove-create col" : "remove-edit col"}
                    >
                      <MapContainer
                        center={
                          defaultValue?.polygon?.coordinates[0]
                            ? {
                                lat: defaultValue?.polygon
                                  ?.coordinates[0][0][1],
                                lng: defaultValue?.polygon
                                  ?.coordinates[0][0][0],
                              }
                            : center
                        }
                        zoom={5}
                        refs={mapRef}
                        // scrollWheelZoom={false}
                        style={{ height: "600px", width: "100%" }}
                      >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <FeatureGroup>
                          {poly !== "" ? (
                            <Polygon
                              pathOptions={{ color: "#3388ff" }}
                              id={defaultValue?.id}
                              positions={poly}
                              draggable={true}
                              edit={true}
                              enable={true}
                              drawing={true}
                              key={defaultValue?.id}
                            />
                          ) : null}

                          <EditControl
                            position="topright"
                            onCreated={_onCreate}
                            onEdited={_onEdited}
                          />
                        </FeatureGroup>
                      </MapContainer>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <button className="btn btn-success mr-2" type="submit">
              {loading
                ? "Submitting ..."
                : `${defaultValue ? "Update" : "Create"}`}
            </button>
            <Link to="#" onClick={() => history.goBack()}>
              <button className="btn btn-secondary">Cancel</button>
            </Link>
          </AvForm>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, zoneError } = state.Zone;
  return { message, loading, zoneError };
};

export default connect(mapStateToProps, {
  createZone,
  updateZone,
  createZoneFailed,
  updateZoneFailed,
})(withRouter(Form));
