import {
  FETCH_DOCTYPE,
  FETCH_DOCTYPE_SUCCESSFUL,
  FETCH_DOCTYPE_API_FAILED,
  FETCH_EACH_DOCTYPE,
  FETCH_EACH_DOCTYPE_SUCCESSFUL,
  FETCH_EACH_DOCTYPE_API_FAILED,
  DELETE_DOCTYPE,
  DELETE_DOCTYPE_SUCCESSFUL,
  DELETE_DOCTYPE_FAILED,
  CREATE_DOCTYPE,
  CREATE_DOCTYPE_SUCCESSFUL,
  CREATE_DOCTYPE_FAILED,
  UPDATE_DOCTYPE,
  UPDATE_DOCTYPE_SUCCESSFUL,
  UPDATE_DOCTYPE_FAILED,
  INITIALIZE_DOCTYPE,
  INITIALIZE_DOCTYPE_SUCCESSFUL,
  INITIALIZE_DOCTYPE_FAILED,
  IS_DOCTYPE_AUTHORIZE,
} from './actionTypes';

export const fetchDocTypeAuthorizeError = (error) => {
  return {
    type: IS_DOCTYPE_AUTHORIZE,
    payload: error,
  };
};

export const fetchDocType = (payload) => {
  return {
    type: FETCH_DOCTYPE,
    payload,
  };
};

export const fetchDocTypeSuccessful = (payload) => {
  return {
    type: FETCH_DOCTYPE_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchDocTypeError = (error) => {
  return {
    type: FETCH_DOCTYPE_API_FAILED,
    payload: error,
  };
};

export const fetchEachDocType = (id) => {
  return {
    type: FETCH_EACH_DOCTYPE,
    payload: id,
  };
};

export const fetchEachDocTypeSuccessful = (payload) => {
  return {
    type: FETCH_EACH_DOCTYPE_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachDocTypeError = (error) => {
  return {
    type: FETCH_EACH_DOCTYPE_API_FAILED,
    payload: error,
  };
};

export const deleteDocType = (id) => {
  return {
    type: DELETE_DOCTYPE,
    payload: id,
  };
};

export const deleteDocTypeSuccessful = (payload) => {
  return {
    type: DELETE_DOCTYPE_SUCCESSFUL,
    payload,
  };
};

export const deleteDocTypeFailed = (error) => {
  return {
    type: DELETE_DOCTYPE_FAILED,
    payload: error,
  };
};

export const createDocType = (payload) => {
  return {
    type: CREATE_DOCTYPE,
    payload,
  };
};

export const createDocTypeSuccessful = (payload) => {
  return {
    type: CREATE_DOCTYPE_SUCCESSFUL,
    payload,
  };
};

export const createDocTypeFailed = (error) => {
  return {
    type: CREATE_DOCTYPE_FAILED,
    payload: error,
  };
};

export const updateDocType = (payload) => {
  return {
    type: UPDATE_DOCTYPE,
    payload,
  };
};

export const updateDocTypeFailed = (error) => {
  return {
    type: UPDATE_DOCTYPE_FAILED,
    payload: error,
  };
};

export const updateDocTypeSuccessful = (payload) => {
  return {
    type: UPDATE_DOCTYPE_SUCCESSFUL,
    payload,
  };
};

export const initDocType = (payload) => {
  return {
    type: INITIALIZE_DOCTYPE,
    payload,
  };
};

export const initDocTypeFailed = (error) => {
  return {
    type: INITIALIZE_DOCTYPE_FAILED,
    payload: error,
  };
};

export const initDocTypeSuccessful = (payload) => {
  return {
    type: INITIALIZE_DOCTYPE_SUCCESSFUL,
    payload,
  };
};
