import React, { useRef, useState, useEffect } from "react";
import { Col, Button, ButtonGroup, Row, Alert } from "reactstrap";
import Location from "../../../../components/Common/Location";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import Select from "react-select";
const libraries = ["places"];

const GoogleMapApp = ({
  setLocation,
  rideLocation,
  rebooking,
  fetchPlaces,
  places,
  placesLoading,
  placesError,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries,
  });
  const [searchPlaces, setSearchPlaces] = useState("");
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  const [selectedDestination, setSelectedDestination] = useState(null);

  const location = Location();
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [center, setCenter] = useState({ lat: 9.072264, lng: 7.491302 });
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [name, setName] = useState({ pickUp: null, dropOff: null });
  const [showMap, setShowMap] = useState(true);
  const [locationError, setLocationError] = useState(false);
  /** @type React.MutableRefObject<HTMLInputElement> */

  async function calculateRoute() {
    if (!selectedOrigin || !selectedDestination) {
      return;
    }
    console.log(selectedOrigin);

    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: selectedOrigin.value,
      destination: selectedDestination.value,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
    setName({
      pickUp: results.routes[0].legs[0].start_address,
      dropOff: results.routes[0].legs[0].end_address,
    });

    let origin = await GetLocation(selectedOrigin.value);
    let destination = await GetLocation(selectedDestination.value);
    const locationParams = {
      pickUp: {
        ...origin,
        address: selectedOrigin.value,
        name: selectedOrigin.value.split(",")[0],
      },
      dropOff: {
        ...destination,
        address: selectedDestination.value,
        name: selectedDestination.value.split(",")[0],
      },
      distance: results.routes[0].legs[0].distance.text,
      time: results.routes[0].legs[0].duration.text,
    };
    setLocation(locationParams);
  }

  async function GetLocation(Address) {
    // eslint-disable-next-line no-undef
    let geocoder = new google.maps.Geocoder();
    let address = Address;
    let location = {};
    await geocoder.geocode({ address: address }, function (results, status) {
      // eslint-disable-next-line no-undef
      if (status == google.maps.GeocoderStatus.OK) {
        location.lat = results[0].geometry.location.lat();
        location.lng = results[0].geometry.location.lng();
      } else {
        alert("Request failed.");
      }
    });
    return location;
  }

  function clearRoute() {
    setDirectionsResponse(null);
    setDistance("");
    setDuration("");
    setSelectedDestination("");
    setSelectedOrigin("");
    setLocation(null);
  }

  const direction = async (pickup, dropoff) => {
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: pickup,
      destination: dropoff,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    setShowMap(true);
  };

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem("country"));
    const region = JSON.parse(localStorage.getItem("region"));
    if (country) {
      setCountry(country);
    } else {
      localStorage.setItem("country", JSON.stringify(country));
    }
    if (region) {
      setRegion(region);
    } else {
      localStorage.setItem("region", JSON.stringify(region));
    }
  }, [country, region]);

  useEffect(() => {
    if (rideLocation && rebooking) {
      setShowMap(false);
      direction(rideLocation.pickUp.address, rideLocation.dropOff.address);
      setSelectedOrigin({
        label: rideLocation.pickUp.address,
        value: rideLocation.pickUp.address,
      });
      setSelectedDestination({
        label: rideLocation.dropOff.address,
        value: rideLocation.dropOff.address,
      });
    }
  }, [rebooking, rideLocation]);

  const handleCurrentaddress = (inputField) => {
    setLocationError(null);
    if (location.loaded) {
      if (location.err) {
        setLocationError(
          location.error.message === "User denied Geolocation"
            ? "Please turn on location and relaod the page"
            : "Location error"
        );
        return;
      } else {
        // eslint-disable-next-line no-undef
        var latlng = new google.maps.LatLng(
          location.coordinates.lat,
          location.coordinates.lng
        );
        // eslint-disable-next-line no-undef
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ latLng: latlng }, (results, status) => {
          // eslint-disable-next-line no-undef
          if (status !== google.maps.GeocoderStatus.OK) {
            setLocationError(status);
          }
          // This is checking to see if the Geoeode Status is OK before proceeding
          // eslint-disable-next-line no-undef
          if (status == google.maps.GeocoderStatus.OK) {
            if (inputField === "origin") {
              setCenter({
                lat: location.coordinates.lat,
                lng: location.coordinates.lng,
              });

              setSelectedOrigin({
                label: `${results[0].formatted_address}`,
                value: `${results[0].formatted_address}`,
              });
            }
            if (inputField === "destination") {
              setSelectedDestination({
                label: `${results[0].formatted_address}`,
                value: `${results[0].formatted_address}`,
              });
            }
          }
        });
      }
    } else {
      setLocationError("Location data not available yet.");
    }
  };

  const handleSetCountry = (shortName) => {
    setCountry(shortName);
    localStorage.setItem("country", JSON.stringify(shortName));
  };

  const handleSetRegion = (value) => {
    setCountry(value);
    localStorage.setItem("region", JSON.stringify(value));
  };

  const placeData = places?.result?.map((data) => {
    return {
      label: data.formattedAddress,
      value: data.formattedAddress,
    };
  });

  useEffect(() => {
    if (location.loaded) {
      if (location.err) {
        setLocationError(
          location.error.message === "User denied Geolocation"
            ? "Please turn on location and relaod the page"
            : "Location error"
        );
        return;
      } else {
        const timeoutRef = setTimeout(() => {
          if (searchPlaces.length !== 0) {
            let searchInfo = {
              lat: location.coordinates.lat,
              lng: location.coordinates.lng,
              input: searchPlaces,
              state: region,
              country: country,
            };

            fetchPlaces(searchInfo);
            // console.log(searchInfo);
            return;
          }
          // setUserList([]);
        }, 500);

        return () => {
          clearTimeout(timeoutRef);
        };
      }
    }
  }, [searchPlaces]);

  if (!isLoaded || !showMap) {
    return <p>Loading ... </p>;
  }
  return (
    <div
      className="position-relative align-items-center w-100"
      style={{ minHeight: "500px" }}
    >
      <div className="d-flex align-items-center w-80 mb-2">
        <label className="pr-3">Country:</label>
        <CountryDropdown
          defaultOptionLabel="Choose your country..."
          value={country}
          // valueType='short'
          onChange={(val) => handleSetCountry(val)}
          classes="form-control mr-4"
          priorityOptions={["NG", "KE", "CA", "MY", "VN"]}
        />
        <RegionDropdown
          defaultOptionLabel="Choose your state or region..."
          country={country}
          value={region}
          onChange={(val) => handleSetRegion(val)}
          classes="form-control"
        />
      </div>
      {placesError && (
        <Alert className="text-center" color="danger">
          {placesError}
        </Alert>
      )}

      {locationError && (
        <Alert className="text-center" color="danger">
          {locationError}
        </Alert>
      )}
      <div className="position-absolute left-0 top-0 h-100 w-100">
        {/* Google Map Box */}
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={{
            zoomControl: true,
            streetViewControl: true,
            mapTypeControl: false,
            fullscreenControl: true,
          }}
          onLoad={(map) => setMap(map)}
        >
          <Marker position={center} />
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </GoogleMap>
      </div>
      <div
        className="px-4 pt-4 border rounded-lg m-4 mb-2 z-index-3 position-absolute bg-white"
        style={{ width: "88%" }}
      >
        <Row className="justify-content-around align-items-start">
          <Col className="col-md-4">
            <Select
              name="origin"
              value={selectedOrigin}
              isLoading={placesLoading}
              options={placeData}
              // menuIsOpen={placeData && !selectedOrigin}
              onInputChange={(e) => setSearchPlaces(e)}
              onChange={(e) => setSelectedOrigin(e)}
              required={true}
              placeholder={`Origin`}
              classNamePrefix="places-select"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#ebf3f1",
                  primary: "#00a857",
                },
              })}
            />
            <p
              onClick={() => handleCurrentaddress("origin")}
              className="font-size-12 mt-1"
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-map-pin mr-1 text-primary"></i>
              Set to my current location
            </p>
          </Col>
          <Col className="col-md-4">
            <Select
              name="destination"
              value={selectedDestination}
              isLoading={placesLoading}
              options={placeData}
              onInputChange={(e) => setSearchPlaces(e)}
              onChange={(e) => setSelectedDestination(e)}
              required={true}
              placeholder={`Destination`}
              classNamePrefix="places-select"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#ebf3f1",
                  primary: "#00a857",
                },
              })}
            />
            <p
              onClick={() => handleCurrentaddress("destination")}
              className="font-size-12 mt-1"
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-map-pin mr-1 text-primary"></i>
              Set to my current location
            </p>
          </Col>

          <ButtonGroup>
            <Button color="primary" type="submit" onClick={calculateRoute}>
              Route
            </Button>
            <span
              style={{ cursor: "pointer" }}
              className="align-items-center p-2 bg-light border-left border-white"
              onClick={clearRoute}
            >
              <i className="fas fa-undo" />
            </span>
            <span
              style={{ cursor: "pointer" }}
              className="align-items-center p-2 bg-light border-left border-white"
              onClick={() => {
                map.panTo(center);
                map.setZoom(15);
              }}
            >
              <i className="fas fa-arrows-alt" />
            </span>
          </ButtonGroup>
        </Row>
        {/* <Row className="justify-content-between">
          <p>Distance: {distance} </p>
          <p>Duration: {duration} </p>
        </Row> */}
      </div>
    </div>
  );
};

export default GoogleMapApp;
