import React from 'react';
import SimpleImageSlider from 'react-simple-image-slider';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';

const VehicleImages = ({ vehicleInfo }) => {
  const [showModal, setShowModal] = React.useState(false);
  const images = [
    { url: vehicleInfo?.imageFront },
    vehicleInfo?.imageLeft ? { url: vehicleInfo?.imageLeft } : null,
    vehicleInfo?.imageBack ? { url: vehicleInfo?.imageBack } : null,
    vehicleInfo?.imageRight ? { url: vehicleInfo?.imageRight } : null,
  ];
  return (
    <div>
      <Row>
        {vehicleInfo?.imageFront && (
          <Col sm={3}>
            <div className='border rounded p-2 d-flex align-items-center'>
              <img
                className='rounded avatar-lg border p-2  '
                alt='Profile'
                src={vehicleInfo?.imageFront}
                onClick={() => {
                  setShowModal(true);
                }}
                style={{ cursor: 'pointer' }}
              />{' '}
              <h4 className='text-center card-title ml-4'> Front View </h4>
            </div>
          </Col>
        )}
        {vehicleInfo?.imageLeft && (
          <Col sm={3}>
            <div className='border rounded p-2   d-flex align-items-center'>
              <img
                className='rounded avatar-lg border p-2'
                alt='Profile'
                src={vehicleInfo?.imageLeft}
                onClick={() => {
                  setShowModal(true);
                }}
                style={{ cursor: 'pointer' }}
              />{' '}
              <h4 className='text-center card-title'> Left View </h4>
            </div>
          </Col>
        )}
        {vehicleInfo?.imageBack && (
          <Col sm={3}>
            <div className='border rounded p-2  d-flex align-items-center'>
              <img
                className='rounded avatar-lg border p-2 '
                alt='Profile'
                src={vehicleInfo?.imageBack}
                onClick={() => {
                  setShowModal(true);
                }}
                style={{ cursor: 'pointer' }}
              />{' '}
              <h4 className='text-center card-title'> Back View </h4>
            </div>
          </Col>
        )}
        {vehicleInfo?.imageRight && (
          <Col sm={3}>
            <div className='border rounded p-2 d-flex align-items-center'>
              <img
                className='rounded avatar-lg border p-2  '
                alt='Profile'
                src={vehicleInfo?.imageRight}
                onClick={() => {
                  setShowModal(true);
                }}
                style={{ cursor: 'pointer' }}
              />{' '}
              <h4 className='text-center card-title'> Right View </h4>
            </div>
          </Col>
        )}
      </Row>

      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        size='lg'
        style={{ left: '5%' }}
        centered={true}
      >
        {/* <ModalHeader toggle={() => setShowModal(false)}>
        </ModalHeader> */}
        <ModalBody className='p-0 m-2'>
          <SimpleImageSlider
            width={'-webkit-fill-available'}
            height={'80vh'}
            images={images}
            showBullets={true}
            showNavs={true}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default VehicleImages;
