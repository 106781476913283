export const IS_DOCUMENT_AUTHORIZE = 'IS_DOCUMENT_AUTHORIZE'

export const FETCH_DOCUMENT = 'FETCH_DOCUMENT'
export const FETCH_DOCUMENT_SUCCESSFUL = 'FETCH_DOCUMENT_SUCCESSFUL'
export const FETCH_DOCUMENT_API_FAILED = 'FETCH_DOCUMENT_API_FAILED'

export const FETCH_EACH_DOCUMENT = 'FETCH_EACH_DOCUMENT'
export const FETCH_EACH_DOCUMENT_SUCCESSFUL = 'FETCH_EACH_DOCUMENT_SUCCESSFUL'
export const FETCH_EACH_DOCUMENT_API_FAILED = 'FETCH_EACH_DOCUMENT_API_FAILED'

export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'
export const DELETE_DOCUMENT_SUCCESSFUL = 'DELETE_DOCUMENT_SUCCESSFULLY'
export const DELETE_DOCUMENT_FAILED = 'DELETE_DOCUMENT_FAILED'

export const CREATE_DOCUMENT = 'CREATE_DOCUMENT'
export const CREATE_DOCUMENT_SUCCESSFUL = 'CREATE_DOCUMENT_SUCCESSFULLY'
export const CREATE_DOCUMENT_FAILED = 'CREATE_DOCUMENT_FAILED'

export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT'
export const UPDATE_DOCUMENT_SUCCESSFUL = 'UPDATE_DOCUMENT_SUCCESSFULLY'
export const UPDATE_DOCUMENT_FAILED = 'FAILED'

// Document Summary
export const FETCH_DOCUMENT_SUMMARY = 'FETCH_DOCUMENT_SUMMARY'
export const FETCH_DOCUMENT_SUMMARY_FAILED = 'FETCH_DOCUMENT_SUMMARY_FAILED'
export const FETCH_DOCUMENT_SUMMARY_SUCCESSFULLY =
  'FETCH_DOCUMENT_SUMMARY_SUCCESSFULLY'
