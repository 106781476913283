import React, { useState, useEffect } from 'react';
import AlartError from '../../../../../components/Common/AlertCard';
import { connect } from 'react-redux';
import { Row, Col, Container, Alert } from 'reactstrap';
import Moment from 'moment';
import moment from 'moment-timezone';
//Import Breadcrumb
import Breadcrumbs from '../../../../../components/Common/Breadcrumb';
import MiniWidgets from './Components/MiniWidget';
import DriverTable from './table';
import CheckAction, { ReadCheck } from '../../../../../components/Common/Summary/actionCheck';

//Import Action to copy breadcrumb items from local state to redux state
import {
  fetchDriverLogsCSV,
  fetchDriverLogsCSVError,
  fetchAllDriverLogs,
} from '../../../../../store/actions';

const Dashboard = ({
  driverLogs,
  fetchAllDriverLogs,
  fetchDriverLogsCSV,
  fetchDriverLogsCSVError,
  all_logs_csv,
  all_logs_csv_loading,
  loading,
  userError,
  isAuthorize,
  message,
  approveMessage,
  approveError,
  history,
  accessControl
}) => {
  const [deleteData, setDeleteData] = useState(null);
  const [dateFilter, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });
  const breadcrumbItems = [
    { title: 'Captain', link: '#' },
    { title: 'Dashboard', link: '#' },
  ];
  const date = (e) => {
    setDate(e);
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='Captains Records'
            breadcrumbItems={breadcrumbItems}
          />
          {userError && (
            <Alert color='danger' className='text-center'>
              {userError}
            </Alert>
          )}
          {message && (
            <Alert color='success' className='text-center'>
              {message}
            </Alert>
          )}
          {isAuthorize ? (
            <AlartError error={isAuthorize} />
          ) : (
            <>
              <Row>
                <Col sm={12}>
                  {' '}
                  <Row>
                    <MiniWidgets
                      defaultIcon='fas fa-id-card'
                      date={dateFilter}
                    />
                  </Row>
                </Col>
                <Col>
                  <DriverTable
                    usersData={driverLogs}
                    dateFilter={date}
                    loading={loading}
                    fetchAllDriverLogs={fetchAllDriverLogs}
                    message={message}
                    approveMessage={approveMessage}
                    fetchDriverLogsCSV={fetchDriverLogsCSV}
                    fetchDriverLogsCSVError={fetchDriverLogsCSVError}
                    logs_csv={all_logs_csv}
                    csv_loading={all_logs_csv_loading}
                    approveError={approveError}
                    history={history}
                    accessControl={accessControl}
                    ReadCheck={ReadCheck}
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const {
    all_logs_csv,
    all_logs_csv_loading,
    driverLogs,
    loading,
    userError,
    isAuthorize,
    message,
    approveMessage,
    approveError,
  } = state.Users;
  const { accessControl } = state.Account;
  return {
    all_logs_csv,
    all_logs_csv_loading,
    driverLogs,
    loading,
    userError,
    isAuthorize,
    message,
    approveMessage,
    approveError,
    accessControl
  };
};

export default connect(mapStatetoProps, {
  fetchAllDriverLogs,
  fetchDriverLogsCSV,
  fetchDriverLogsCSVError,
})(Dashboard);
