import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import BankTable from './bankTable';
import MiniWidgets from './Components/MiniWidgets.js';
import PreviewBank from './previewBank';
import BankStatus from './bankStatus';
import AccessSummary from '../../../components/Common/AccessView/summary';
import GreySummaryCard from '../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../components/Common/Summary/actionCheck';

import {
  fetchBank,
  deleteBank,
  updateBank,
  updateBankFailed,
  getRecipient,
  fetchBankCSV,
  fetchBankCSVError,
} from '../../../store/actions';

const Bank = ({
  bank,
  fetchBank,
  deleteBank,
  updateBankFailed,
  tableError,
  getRecipient,
  isAuthorize,
  message,
  history,
  fetchBankCSV,
  fetchBankCSVError,
  csvData,
  csv_loading,
  accessControl
}) => {
  const [showModal, setShowModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [dateFilter, setDate] = useState({});
  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'Account', link: '#' },
    { title: 'Bank', link: '/banks' },
  ];

  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        updateBankFailed('');
        setStatusModal('');
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    updateBankFailed('');
  }, []);

  const showStatusModal = (info) => {
    setBankDetails(info);
    setStatusModal(true);
    getRecipient(info.id);
  };

  const deleteBankModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteBank(deleteId);
    setDeleteId(null);
  };
  const date = (e) => {
    setDate(e);
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Bank Details' breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field='Finance' subModule='Bank Detail'>
            {isAuthorize ? (
              <Alert color='danger' className='text-center'>
                {isAuthorize}
              </Alert>
            ) : (
              <>
                <CheckAction
                  field='Finance'
                  subModule='Bank Detail'
                  iconType='Create'
                >
                  <div className='mb-2 text-right'>
                    <Link to='/bank_form'>
                      <Button color='success' size='sm'>
                        <i className='mdi mdi-plus-circle-outline font-size-18'></i>{' '}
                        Add Bank
                      </Button>
                    </Link>
                  </div>
                </CheckAction>
                {message && (
                  <Alert color='success' className='text-center'>
                    {message}
                  </Alert>
                )}
                {tableError && (
                  <Alert color='danger' className='text-center'>
                    {tableError}
                  </Alert>
                )}
                <Row>
                  <Col>
                    {' '}
                    <Row>
                      <MiniWidgets date={dateFilter} />
                    </Row>
                    <BankTable
                      bankData={bank}
                      dateFilter={date}
                      loading
                      deleteBank={deleteBankModal}
                      fetchBank={fetchBank}
                      previewAction={previewHandler}
                      statusHandler={showStatusModal}
                      message={message}
                      history={history}
                      fetchBankCSV={fetchBankCSV}
                      fetchBankCSVError={fetchBankCSVError}
                      csvData={csvData}
                      csv_loading={csv_loading}
                      CheckAction={CheckAction}
                      ReadCheck={ReadCheck}
                      accessControl={accessControl}
                    />
                  </Col>
                </Row>

                {/* Bank Preview Modal */}
                <Modal
                  isOpen={showModal}
                  toggle={() => setShowModal(!showModal)}
                >
                  <ModalHeader toggle={() => setShowModal(false)}>
                    Preview Bank
                  </ModalHeader>
                  <ModalBody>
                    <PreviewBank previewId={previewId} />
                  </ModalBody>
                </Modal>

                {/* Status Update Modal */}
                <Modal
                  isOpen={statusModal}
                  toggle={() => setStatusModal(!statusModal)}
                >
                  <ModalHeader toggle={() => setStatusModal(false)}>
                    Update Bank Status
                  </ModalHeader>
                  <ModalBody>
                    <BankStatus bankDetails={bankDetails} />
                  </ModalBody>
                </Modal>

                {/* Delete Confirmation modal */}
                <Modal
                  isOpen={showDeleteModal}
                  toggle={() => setShowDeleteModal(!showDeleteModal)}
                >
                  <ModalBody>
                    <h4 className='card-title mb-4 text-center'>
                      {' '}
                      Are you sure you want to delete this bank details
                    </h4>
                    <button
                      onClick={() => deleteHandler()}
                      className='btn btn-outline-danger btn-sm w-100'
                    >
                      Click to Delete
                    </button>
                  </ModalBody>
                </Modal>
              </>
            )}
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const {
    csvData,
    csv_loading,
    bank,
    loading,
    tableError,
    message,
    isAuthorize,
  } = state.Bank;
  return {
    csvData,
    csv_loading,
    bank,
    loading,
    tableError,
    message,
    accessControl,
    isAuthorize,
  };
};

export default connect(mapStateToProps, {
  fetchBank,
  getRecipient,
  deleteBank,
  updateBank,
  updateBankFailed,
  fetchBankCSV,
  fetchBankCSVError,
})(Bank);
