import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  Container,
} from 'reactstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
// actions
import { connect } from 'react-redux';
import {
  createPushNotification,
  createNotificationFailed,
  createNotification,
} from '../../../store/actions';
import CheckAction from '../../../components/Common/Summary/actionCheck';
import AutoComplete from '../../../components/Common/Autocomplete';

const CreateNotification = ({
  createPushNotification,
  createNotificationFailed,
  createNotification,
  message,
  notificationError,
  data,
  user,
}) => {
  const [text, setMessage] = useState('');
  const [descError, setError] = useState('');
  const [isToDevice, setIsToDevice] = useState(true);
  const [selectedUser, setUser] = useState(null);
  const [check, setCheck] = useState(false);
  const [occurrenceValue, setOccur] = useState('None');

  const handleSubmit = (event, values) => {
    setError('');
    if (!text) {
      setError('Message can not be empty');
      return;
    }
    if (isToDevice) {
      if (!selectedUser) {
        setError('User can not be empty');
        return;
      }
      const packageData = {
        ...values,
        message: text,
      };
      let userId = selectedUser.value;
      createPushNotification(packageData, userId);
      return;
    }
    if (!isToDevice && check) {
      const packageData = {
        ...values,
        userId: user.id,
        receiverType: values.topic,
        type: 'SCHEDULED',
        text,
      };
      createNotification(packageData);
      // console.log(packageData);
      return;
    }
    if (!isToDevice && !check) {
      const packageData = {
        ...values,
        message: text,
      };
      createPushNotification(packageData);
      return;
    }

    // console.log(packageData);
  };

  useEffect(() => {
    setMessage('');
    if (data) {
      setMessage(data.message);
    }
    if (message || notificationError) {
      setTimeout(() => {
        createNotificationFailed('');
      }, 2000);
    }
  }, [data, notificationError, message]);

  return (
    <Col md={6}>
      {descError && (
        <Alert className='text-center' color='danger'>
          {descError}
        </Alert>
      )}
      {message && (
        <Alert className='text-center' color='success'>
          {message}
        </Alert>
      )}
      {notificationError && (
        <Alert className='text-center' color='danger'>
          {notificationError}
        </Alert>
      )}
      <Card>
        <CardBody>
          <div className='text-center'>
            <div>
              <h5> Create Notification</h5>
            </div>
            <div className='py-2 rounded border shadow'>
              <span>
                <Button
                  color={isToDevice ? 'primary' : 'white'}
                  size='sm'
                  className='mx-1 bt-2'
                  onClick={() => setIsToDevice(true)}
                >
                  To A User
                </Button>
                <Button
                  color={!isToDevice ? 'primary' : 'white'}
                  size='sm'
                  className='mr-1 bt-2'
                  onClick={() => setIsToDevice(false)}
                >
                  Broadcast
                </Button>
              </span>
            </div>
            {!isToDevice && (
              // <BootstrapSwitchButton
              //   checked={check}
              //   onlabel='Send Later'
              //   offlabel='Send Now'
              //   onstyle='primary'
              //   offstyle='primary'
              //   onChange={(e) => setCheck(e)}
              //   width={120}
              //   // size='small'
              //   // eslint-disable-next-line react/style-prop-object
              //   style={'mt-3'}
              // />
              <div className='mt-3'>
                <span>
                  <Button
                    color={!check ? 'primary' : 'white'}
                    size='sm'
                    className='mx-1 bt-2'
                    onClick={() => setCheck(false)}
                  >
                    Send Now
                  </Button>
                  <Button
                    color={check ? 'primary' : 'white'}
                    size='sm'
                    className='mr-1 bt-2'
                    onClick={() => setCheck(true)}
                  >
                    Send Later
                  </Button>
                </span>
              </div>
            )}
          </div>
          <div className='mt-3'>
            <div>
              <AvForm className='form-horizontal' onValidSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    {!isToDevice ? (
                      <>
                        <FormGroup className=' mb-4 mr-2'>
                          <AvField
                            className='form-control bg-light'
                            type='select'
                            name='topic'
                            value='Driver'
                            label='Topic'
                            required
                          >
                            <option value='DRIVER'>Driver</option>
                            <option value='RIDER'>Rider</option>
                            <option value='ADMIN'>Admin</option>
                          </AvField>
                        </FormGroup>
                      </>
                    ) : (
                      <AutoComplete setUser={(e) => setUser(e)} />
                    )}
                  </Col>
                  <Col md={12}>
                    <FormGroup className=' mb-4'>
                      <Label htmlFor='provider'>Push Title</Label>
                      <AvField
                        name='title'
                        type='text'
                        className='form-control bg-light'
                        id='title'
                        placeholder='Push Title ...'
                        value={data ? data?.title : ''}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label htmlFor='description'> Message</Label>
                      <textarea
                        className='form-control'
                        onChange={(e) => setMessage(e.target.value)}
                        id='message'
                        placeholder='Enter Message'
                        rows='5'
                        required
                        value={text}
                      ></textarea>
                    </FormGroup>
                  </Col>
                  {!isToDevice && check ? (
                    <>
                      <Col md={6}>
                        <FormGroup className=' mb-4 mr-2'>
                          <AvField
                            className='form-control bg-light'
                            type='select'
                            name='occurrence'
                            label='Occurrence'
                            onChange={(e) => setOccur(e.target.value)}
                          >
                            <option value='NONE'>Set Occurrence</option>
                            <option value='ONCE'>Once</option>
                            <option value='DAILY'>Daily</option>
                            <option value='WEEKLY'>Weekly</option>
                          </AvField>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className=' mb-4'>
                          <AvField
                            className='form-control bg-light'
                            type='datetime-local'
                            name='sendAt'
                            label='Send At'
                          />
                        </FormGroup>
                      </Col>
                      {occurrenceValue === 'DAILY' ||
                        occurrenceValue === 'WEEKLY' ? (
                        <>
                          <Col md={6}>
                            <FormGroup className=' mb-4'>
                              <AvField
                                className='form-control bg-light'
                                type='datetime-local'
                                name='stopAt'
                                label='Stop At'
                              />
                            </FormGroup>
                          </Col>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </Row>
                <CheckAction
                  field='Custom Notification'
                  subModule='Push Notification'
                  iconType={'Create'}
                >
                  <div className='text-center'>
                    <button className='btn btn-success px-4' type='submit'>
                      Send
                    </button>
                  </div>
                </CheckAction>
              </AvForm>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
const mapStateToProps = (state) => {
  const { loading, notificationError, message } = state.Notifications;
  const { user } = state.Account;
  return { user, loading, notificationError, message };
};

export default connect(mapStateToProps, {
  createPushNotification,
  createNotificationFailed,
  createNotification,
})(CreateNotification);
