import {
  FETCH_BONUS,
  FETCH_BONUS_SUCCESSFUL,
  FETCH_BONUS_API_FAILED,
  FETCH_BONUS_LIST,
  FETCH_BONUS_LIST_SUCCESSFUL,
  FETCH_BONUS_LIST_API_FAILED,
  FETCH_USER_BONUS,
  FETCH_USER_BONUS_SUCCESSFULLY,
  FETCH_USER_BONUS_FAILED,
  FETCH_EACH_USER_BONUS,
  FETCH_EACH_USER_BONUS_SUCCESSFULLY,
  FETCH_EACH_USER_BONUS_FAILED,
  FETCH_BONUS_LOG,
  FETCH_BONUS_LOG_SUCCESSFUL,
  FETCH_BONUS_LOG_API_FAILED,
  FETCH_BONUS_LOG_SUMMARY,
  FETCH_BONUS_LOG_SUMMARY_SUCCESSFUL,
  FETCH_BONUS_LOG_SUMMARY_API_FAILED,
  FETCH_EACH_BONUS_LOG,
  FETCH_EACH_BONUS_LOG_SUCCESSFUL,
  FETCH_EACH_BONUS_LOG_API_FAILED,
  FETCH_EACH_BONUS,
  FETCH_EACH_BONUS_SUCCESSFUL,
  FETCH_EACH_BONUS_API_FAILED,
  DELETE_BONUS,
  DELETE_BONUS_SUCCESSFUL,
  DELETE_BONUS_FAILED,
  CREATE_BONUS,
  CREATE_BONUS_SUCCESSFUL,
  CREATE_BONUS_FAILED,
  UPDATE_BONUS,
  UPDATE_BONUS_SUCCESSFUL,
  UPDATE_BONUS_FAILED,
  FETCH_BONUS_SUMMARY,
  FETCH_BONUS_SUMMARY_SUCCESSFULLY,
  FETCH_BONUS_SUMMARY_FAILED,
  FETCH_BONUS_CSV,
  FETCH_BONUS_CSV_SUCCESSFULLY,
  FETCH_BONUS_CSV_ERROR,
  FETCH_BONUS_LOG_CSV,
  FETCH_BONUS_LOG_CSV_SUCCESSFULLY,
  FETCH_BONUS_LOG_CSV_ERROR,
} from './actionTypes'

export const fetchBonusList = (payload) => {
  return {
    type: FETCH_BONUS_LIST,
    payload,
  }
}

export const fetchBonusListSuccessful = (payload) => {
  return {
    type: FETCH_BONUS_LIST_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchBonusListError = (error) => {
  return {
    type: FETCH_BONUS_LIST_API_FAILED,
    payload: error,
  }
}

export const fetctUserBonus = (pageInfo) => {
  console.log(pageInfo)
  return {
    type: FETCH_USER_BONUS,
    payload: pageInfo,
  }
}

export const fetctUserBonusSuccessful = (payload) => {
  return {
    type: FETCH_USER_BONUS_SUCCESSFULLY,
    payload
  }
}

export const fetctUserBonusError = (error) => {
  return {
    type: FETCH_USER_BONUS_FAILED,
    payload: error,
  }
}

export const fetchEachUserBonus = (payload) => {
  return {
    type: FETCH_EACH_USER_BONUS,
    payload,
  }
}

export const fetchEachUserBonusSuccessful = (payload) => {
  return {
    type: FETCH_EACH_USER_BONUS_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchEachUserBonusError = (error) => {
  return {
    type: FETCH_EACH_USER_BONUS_FAILED,
    payload: error,
  }
}
export const fetchBonusLog = (payload) => {
  return {
    type: FETCH_BONUS_LOG,
    payload,
  }
}

export const fetchBonusLogSuccessful = (payload) => {
  return {
    type: FETCH_BONUS_LOG_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchBonusLogError = (error) => {
  return {
    type: FETCH_BONUS_LOG_API_FAILED,
    payload: error,
  }
}

export const fetchBonusLogSummary = (payload) => {
  return {
    type: FETCH_BONUS_LOG_SUMMARY,
    payload,
  }
}

export const fetchBonusLogSummarySuccessful = (payload) => {
  return {
    type: FETCH_BONUS_LOG_SUMMARY_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchBonusLogSummaryError = (error) => {
  return {
    type: FETCH_BONUS_LOG_SUMMARY_API_FAILED,
    payload: error,
  }
}

export const fetchEachBonusLog = (payload) => {
  return {
    type: FETCH_EACH_BONUS_LOG,
    payload,
  }
}

export const fetchEachBonusLogSuccessful = (payload) => {
  return {
    type: FETCH_EACH_BONUS_LOG_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchEachBonusLogError = (error) => {
  return {
    type: FETCH_EACH_BONUS_LOG_API_FAILED,
    payload: error,
  }
}

export const fetchBonus = (payload) => {
  return {
    type: FETCH_BONUS,
    payload,
  }
}

export const fetchBonusSuccessful = (payload) => {
  return {
    type: FETCH_BONUS_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchBonusError = (error) => {
  return {
    type: FETCH_BONUS_API_FAILED,
    payload: error,
  }
}

export const fetchEachBonus = (id) => {
  return {
    type: FETCH_EACH_BONUS,
    payload: id,
  }
}

export const fetchEachBonusSuccessful = (payload) => {
  return {
    type: FETCH_EACH_BONUS_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchEachBonusError = (error) => {
  return {
    type: FETCH_EACH_BONUS_API_FAILED,
    payload: error,
  }
}

export const deleteBonus = (id) => {
  return {
    type: DELETE_BONUS,
    payload: id,
  }
}

export const deleteBonusSuccessful = (payload) => {
  return {
    type: DELETE_BONUS_SUCCESSFUL,
    payload,
  }
}

export const deleteBonusFailed = (error) => {
  return {
    type: DELETE_BONUS_FAILED,
    payload: error,
  }
}

export const createBonus = (payload) => {
  return {
    type: CREATE_BONUS,
    payload,
  }
}

export const createBonusSuccessful = (payload) => {
  return {
    type: CREATE_BONUS_SUCCESSFUL,
    payload,
  }
}

export const createBonusFailed = (error) => {
  return {
    type: CREATE_BONUS_FAILED,
    payload: error,
  }
}

export const updateBonus = (data, id) => {
  return {
    type: UPDATE_BONUS,
    payload: { data, id },
  }
}

export const updateBonusFailed = (error) => {
  return {
    type: UPDATE_BONUS_FAILED,
    payload: error,
  }
}

export const updateBonusSuccessful = (payload) => {
  return {
    type: UPDATE_BONUS_SUCCESSFUL,
    payload,
  }
}
//SUMMARY ENTRIES
export const fetchBonusSummary = (query) => {
  return {
    type: FETCH_BONUS_SUMMARY,
    payload: query,
  }
}

export const fetchBonusSummarySuccessfully = (payload) => {
  return {
    type: FETCH_BONUS_SUMMARY_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchBonusSummaryFailed = (error) => {
  return {
    type: FETCH_BONUS_SUMMARY_FAILED,
    payload: error,
  }
}

export const fetchBonusCSV = (payload) => {
  return {
    type: FETCH_BONUS_CSV,
    payload,
  }
}

export const fetchBonusCSVSuccessfully = (payload) => {
  return {
    type: FETCH_BONUS_CSV_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchBonusCSVError = (error) => {
  return {
    type: FETCH_BONUS_CSV_ERROR,
    payload: error,
  }
}

export const fetchBonusLogCSV = (payload) => {
  return {
    type: FETCH_BONUS_LOG_CSV,
    payload,
  }
}

export const fetchBonusLogCSVSuccessfully = (payload) => {
  return {
    type: FETCH_BONUS_LOG_CSV_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchBonusLogCSVError = (error) => {
  return {
    type: FETCH_BONUS_LOG_CSV_ERROR,
    payload: error,
  }
}
