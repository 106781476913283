import {
  FETCH_INVITATION,
  FETCH_INVITATION_SUCCESSFUL,
  FETCH_INVITATION_API_FAILED,
  FETCH_USERS_INVITATION,
  FETCH_USERS_INVITATION_SUCCESSFUL,
  FETCH_USERS_INVITATION_API_FAILED,
  FETCH_EACH_INVITATION,
  FETCH_EACH_INVITATION_SUCCESSFUL,
  FETCH_EACH_INVITATION_API_FAILED,
  DELETE_INVITATION,
  DELETE_INVITATION_SUCCESSFUL,
  DELETE_INVITATION_FAILED,
  CREATE_INVITATION,
  CREATE_INVITATION_SUCCESSFUL,
  CREATE_INVITATION_FAILED,
  UPDATE_INVITATION,
  UPDATE_INVITATION_SUCCESSFUL,
  UPDATE_INVITATION_FAILED,
  FETCH_INVITATION_DETAILS,
  FETCH_INVITATION_DETAILS_SUCCESSFULLY,
  FETCH_INVITATION_DETAILS_FAILED,
  FETCH_INVITATION_SUMMARY,
  FETCH_INVITATION_SUMMARY_SUCCESSFULLY,
  FETCH_INVITATION_SUMMARY_FAILED,
  FETCH_FOR_INVITATION_CSV,
  FETCH_FOR_INVITATION_CSV_SUCCESSFULLY,
  FETCH_FOR_INVITATION_CSV_ERROR,
} from './actionTypes';

const initialState = {
  invitations: null,
  invitationError: null,
  tableError: null,
  invitationSummary: null,
  summaryError: null,
  invitationDetails: null,
  detailsError: null,
  invitation: null,
  message: null,
  loading: false,
  referralCSV: null,
  csv_loading: false,

  users_invitations: null,
  users_invitationError: null,
};

const Invitation = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVITATION:
    case UPDATE_INVITATION:
    case DELETE_INVITATION:
      state = {
        ...state,
        invitations: null,
        invitationError: null,
        tableError: null,
        loading: true,
        message: null,
      };
      break;

    case FETCH_USERS_INVITATION:
      state = {
        ...state,
        users_invitations: null,
        users_invitationError: null,
        tableError: null,
        loading: true,
        message: null,
      };
      break;

    case FETCH_INVITATION_SUCCESSFUL:
      state = {
        ...state,
        invitations: action.payload,
        invitationError: null,
        tableError: null,
        loading: false,
      };
      break;

    case FETCH_USERS_INVITATION_SUCCESSFUL:
      state = {
        ...state,
        users_invitations: action.payload,
        users_invitationError: null,
        tableError: null,
        loading: false,
      };
      break;

    case DELETE_INVITATION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        invitationError: null,
        message: 'Invitation deleted successfully',
      };
      break;
    case UPDATE_INVITATION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        invitationError: null,
        message: 'Invitation Updated Successfully',
      };
      break;

    case FETCH_INVITATION_SUMMARY:
      return {
        ...state,
        invitationSummary: null,
        summaryError: null,
        loading: true,
      };
    case FETCH_INVITATION_DETAILS:
      return {
        ...state,
        invitationDetails: null,
        detailsError: null,
      };

    case FETCH_INVITATION_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        invitationSummary: action.payload,
        summaryError: null,
        loading: false,
      };

    case FETCH_INVITATION_DETAILS_SUCCESSFULLY:
      return {
        ...state,
        invitationDetails: action.payload,
        detailsError: null,
      };

    case FETCH_INVITATION_SUMMARY_FAILED:
      return {
        ...state,
        invitationSummary: null,
        summaryError: action.payload,
        loading: false,
      };

    case FETCH_INVITATION_DETAILS_FAILED:
      return {
        ...state,
        invitationDetails: null,
        detailsError: action.payload,
      };

    case FETCH_EACH_INVITATION:
      state = {
        ...state,
        loading: true,
        invitation: null,
        invitationError: null,
        message: null,
      };
      break;

    case FETCH_EACH_INVITATION_SUCCESSFUL:
      state = {
        ...state,
        invitation: action.payload,
        loading: false,
        invitationError: null,
        message: null,
      };
      break;

    case CREATE_INVITATION:
      state = {
        ...state,
        loading: true,
        invitationError: null,
        message: null,
      };
      break;

    case CREATE_INVITATION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        invitationError: null,
        message: `Invitation  created successfully`,
      };
      break;

    case FETCH_INVITATION_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case FETCH_USERS_INVITATION_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case CREATE_INVITATION_FAILED:
    case DELETE_INVITATION_FAILED:
    case FETCH_EACH_INVITATION_API_FAILED:
      state = {
        ...state,
        invitations: null,
        invitation: null,
        loading: false,
        message: null,
        invitationError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_INVITATION_FAILED:
      state = {
        ...state,
        loading: false,
        invitationError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;

    case FETCH_FOR_INVITATION_CSV:
      return {
        ...state,
        referralCSV: null,
        csv_loading: true,
      };
    case FETCH_FOR_INVITATION_CSV_SUCCESSFULLY:
      return {
        ...state,
        referralCSV: action.payload,
        csv_loading: false,
      };
    case FETCH_FOR_INVITATION_CSV_ERROR:
      return {
        ...state,
        referralCSV: null,
        csv_loading: false,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Invitation;
