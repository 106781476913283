export const FETCH_ROLE = 'FETCH_ROLE';
export const FETCH_ROLE_SUCCESSFUL = 'FETCH_ROLE_SUCCESSFUL';
export const FETCH_ROLE_API_FAILED = 'FETCH_ROLE_API_FAILED';

export const FETCH_PRIVILEGES = 'FETCH_PRIVILEGES';
export const FETCH_PRIVILEGES_SUCCESSFUL = 'FETCH_PRIVILEGES_SUCCESSFUL';
export const FETCH_PRIVILEGES_API_FAILED = 'FETCH_PRIVILEGES_API_FAILED';

export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESSFUL = 'DELETE_ROLE_SUCCESSFULLY';
export const DELETE_ROLE_FAILED = 'DELETE_ROLE_FAILED';

export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATE_ROLE_SUCCESSFUL = 'CREATE_ROLE_SUCCESSFULLY';
export const CREATE_ROLE_FAILED = 'CREATE_ROLE_FAILED';

export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESSFUL = 'UPDATE_ROLE_SUCCESSFULLY';
export const UPDATE_ROLE_FAILED = 'FAILED';

export const FETCH_EACH_ROLE = 'FETCH_EACH_ROLE';
export const FETCH_EACH_ROLE_SUCCESSFUL = 'FETCH_EACH_ROLE_SUCCESSFUL';
export const FETCH_EACH_ROLE_API_FAILED = 'FETCH_EACH_ROLE_API_FAILED';

export const ASSIGN_ROLE = 'ASSIGN_ROLE';
export const ASSIGN_ROLE_SUCCESSFUL = 'ASSIGN_ROLE_SUCCESSFULLY';
export const ASSIGN_ROLE_FAILED = 'FAILED';

export const UNASSIGN_ROLE = 'UNASSIGN_ROLE';
export const UNASSIGN_ROLE_SUCCESSFUL = 'UNASSIGN_ROLE_SUCCESSFULLY';
export const UNASSIGN_ROLE_FAILED = 'UNASSIGN_ROLE_FAILED';
