import {
  CREATE_CASHOUT,
  CREATE_CASHOUT_SUCCESSFUL,
  CREATE_CASHOUT_FAILED,
  FETCH_CASHOUT,
  FETCH_CASHOUT_SUCCESSFUL,
  FETCH_CASHOUT_API_FAILED,
  FETCH_EACH_CASHOUT,
  FETCH_EACH_CASHOUT_SUCCESSFUL,
  FETCH_EACH_CASHOUT_API_FAILED,
  DELETE_CASHOUT,
  DELETE_CASHOUT_SUCCESSFUL,
  DELETE_CASHOUT_FAILED,
  UPDATE_CASHOUT,
  UPDATE_CASHOUT_SUCCESSFUL,
  UPDATE_CASHOUT_FAILED,
  UPDATE_CASHOUT_STATUS,
  UPDATE_CASHOUT_STATUS_SUCCESSFUL,
  UPDATE_CASHOUT_STATUS_FAILED,
  FETCH_FOR_CASHOUT_CSV,
  FETCH_FOR_CASHOUT_CSV_SUCCESSFULLY,
  FETCH_FOR_CASHOUT_CSV_ERROR,
  FETCH_CASHOUT_SUMMARY,
  FETCH_CASHOUT_SUMMARY_SUCCESSFULLY,
  FETCH_CASHOUT_SUMMARY_FAILED,
} from './actionTypes';

export const fetchCashout = (payload) => {
  return {
    type: FETCH_CASHOUT,
    payload,
  };
};

export const fetchCashoutSuccessful = (payload) => {
  return {
    type: FETCH_CASHOUT_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchCashoutError = (error) => {
  return {
    type: FETCH_CASHOUT_API_FAILED,
    payload: error,
  };
};

export const fetchEachCashout = (id) => {
  return {
    type: FETCH_EACH_CASHOUT,
    payload: id,
  };
};

export const fetchEachCashoutSuccessful = (payload) => {
  return {
    type: FETCH_EACH_CASHOUT_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachCashoutError = (error) => {
  return {
    type: FETCH_EACH_CASHOUT_API_FAILED,
    payload: error,
  };
};

export const deleteCashout = (id) => {
  return {
    type: DELETE_CASHOUT,
    payload: id,
  };
};

export const deleteCashoutSuccessful = (payload) => {
  return {
    type: DELETE_CASHOUT_SUCCESSFUL,
    payload,
  };
};

export const deleteCashoutFailed = (error) => {
  return {
    type: DELETE_CASHOUT_FAILED,
    payload: error,
  };
};

export const updateCashoutStatus = (payload, id) => {
  return {
    type: UPDATE_CASHOUT_STATUS,
    payload: { payload, id },
  };
};

export const updateCashoutStatusSuccessful = (payload) => {
  return {
    type: UPDATE_CASHOUT_STATUS_SUCCESSFUL,
    payload,
  };
};

export const updateCashoutStatusFailed = (error) => {
  return {
    type: UPDATE_CASHOUT_STATUS_FAILED,
    payload: error,
  };
};

export const updateCashout = (payload) => {
  return {
    type: UPDATE_CASHOUT,
    payload,
  };
};

export const updateCashoutFailed = (error) => {
  return {
    type: UPDATE_CASHOUT_FAILED,
    payload: error,
  };
};

export const updateCashoutSuccessful = (payload) => {
  return {
    type: UPDATE_CASHOUT_SUCCESSFUL,
    payload,
  };
};

export const createCashout = (payload) => {
  return {
    type: CREATE_CASHOUT,
    payload,
  };
};

export const createCashoutSuccessful = (payload) => {
  return {
    type: CREATE_CASHOUT_SUCCESSFUL,
    payload,
  };
};

export const createCashoutFailed = (error) => {
  return {
    type: CREATE_CASHOUT_FAILED,
    payload: error,
  };
};

export const fetchCashoutCSV = (payload) => {
  return {
    type: FETCH_FOR_CASHOUT_CSV,
    payload,
  };
};

export const fetchCashoutCSVSuccessfully = (payload) => {
  return {
    type: FETCH_FOR_CASHOUT_CSV_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchCashoutCSVError = (error) => {
  return {
    type: FETCH_FOR_CASHOUT_CSV_ERROR,
    payload: error,
  };
};

//SUMMARY ENTRIES
export const fetchCashoutSummary = (query) => {
  return {
    type: FETCH_CASHOUT_SUMMARY,
    payload: query,
  };
};

export const fetchCashoutSummarySuccessfully = (payload) => {
  return {
    type: FETCH_CASHOUT_SUMMARY_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchCashoutSummaryFailed = (error) => {
  return {
    type: FETCH_CASHOUT_SUMMARY_FAILED,
    payload: error,
  };
};
