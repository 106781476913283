import React, { useEffect } from "react";
// import MetaTags from "react-meta-tags"
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import Form from "./form";

import {
  fetchEachTour,
  fetchBusStops,
  fetchRoutes,
  fetchPackage,
} from "../../../../store/actions";

const TourForm = ({
  fetchEachTour,
  fetchBusStops,
  fetchRoutes,
  fetchPackage,
  packages,
  routes,
  busStops,
  busStopError,
  tour,
  loading,
  message,
}) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  useEffect(() => {
    if (id) {
      fetchEachTour(id);
    }
    fetchBusStops();
    fetchPackage();
    fetchRoutes();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Form
          loading={loading}
          defaultValue={tour ? tour : null}
          message={message}
          busStops={busStops}
          packages={packages}
          routes={routes}
          busStopError={busStopError}
          fetchBusStops={fetchBusStops}
          fetchRoutes={fetchRoutes}
          fetchPackage={fetchPackage}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { tour, loading, tourError, message } = state.Tour;
  const { busStops, busStopError } = state.BusStop;
  const { packages } = state.Packages;
  const { routes } = state.Route;
  return {
    packages,
    routes,
    busStops,
    busStopError,
    tour,
    loading,
    tourError,
    message,
  };
};

export default connect(mapStateToProps, {
  fetchBusStops,
  fetchEachTour,
  fetchRoutes,
  fetchPackage,
})(TourForm);
