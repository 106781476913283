import React, { useState, useEffect } from 'react';
import { Card, Row, Col, FormGroup, Label } from 'reactstrap';

import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import Button from 'reactstrap/lib/Button';

const General = () => {
  const [selectedFiles, setFileUpload] = useState([]);
  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setFileUpload(files);
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  return (
    <div>
      <div>
        <div>
          <AvForm
            className="form-horizontal"
            // onValidSubmit={handleSubmit}
            model={null}
          >
            <Row>
              <Col>
                <Row>
                  <Col md={6}>
                    <Label>Logo </Label>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        handleAcceptedFiles(acceptedFiles)
                      }
                      accept="image/jpeg,image/jpd"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                            </div>
                            <h4>Drop/Upload Item Picture Here.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + '-file'}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </Col>
                  <Col md={6}>
                    <Label>Favicon </Label>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        handleAcceptedFiles(acceptedFiles)
                      }
                      accept="image/jpeg,image/jpd"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                            </div>
                            <h4>Drop/Upload Item Picture Here.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + '-file'}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
                <FormGroup className=" mb-4">
                  <Label htmlFor="provider">App Name </Label>
                  <AvField
                    name="name"
                    type="text"
                    className="form-control bg-light"
                    id="name"
                    placeholder="Enter App Name ..."
                    required
                  />
                </FormGroup>

                <FormGroup className=" mb-4">
                  <Label htmlFor="provider">Head Office Number</Label>
                  <AvField
                    name="phone"
                    type="text"
                    className="form-control bg-light"
                    id="phone"
                    required
                  />
                </FormGroup>
                <FormGroup className=" mb-4">
                  <Label htmlFor="provider">Help Email Address</Label>
                  <AvField
                    name="email"
                    type="email"
                    className="form-control bg-light"
                    id="commission"
                    placeholder="Enter Email address ..."
                    required
                  />
                </FormGroup>

                <div className="text-right">
                  <Button className="btn btn-success" size="lg">
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </div>
      </div>
    </div>
  );
};

export default General;
