import HttpService from './HttpService'
import { QueryReallignment } from '../utils/general'

export const GetPromoCodeService = (query) => {
  const http = new HttpService()
  let url = 'promoCode/all'

  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }

  return http.getData(url)
}

export const GetEachPromoCodeService = (id) => {
  const http = new HttpService()
  const url = `promoCode/${id}`
  return http.getData(url)
}

export const CreatePromoCodeService = (payload) => {
  const http = new HttpService()
  const url = 'promoCode'

  return http.postData(payload, url)
}
export const UpdatePromoCodeService = ({ payload, id }) => {
  const http = new HttpService()
  const url = `promoCode/${id}`

  return http.putData(payload, url)
}

export const DeletePromoCodeService = (id) => {
  const http = new HttpService()
  const url = `promoCode/${id}`
  return http.deleteData(url)
}

export const GetPromoHistoryService = (query) => {
  const http = new HttpService()
  let url = `promoCode/redeem`
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}

export const GetPromoRedeemHistoryService = (query) => {
  const http = new HttpService()
  let url
  if (query) {
    url = `promoCode/${query.promoId}/redeem`
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }

  return http.getData(url)
}

export const fetchPromoCodeSummaryService = (query) => {
  // Payload with query
  const http = new HttpService()
  let url = 'summary/promoCode'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}
