import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Table } from 'reactstrap';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
//Simple bar
import SimpleBar from 'simplebar-react';
import { fetchMyComplaint } from '../../../store/actions';
import Moment from 'moment';

const RecentlyComplaints = ({ complaint, fetchMyComplaint, loading }) => {
  const [type, setType] = useState('All');
  useEffect(() => {
    fetchMyComplaint({ take: 5, skip: 0 });

    if (type === 'All') {
      fetchMyComplaint({ take: 5, skip: 0 });
    } else {
      fetchMyComplaint({ take: 5, skip: 0, type });
    }
  }, [fetchMyComplaint, type]);

  const avatarImg =
    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

  return (
    <React.Fragment>
      <Col lg={4}>
        <Card>
          <CardBody>
            <div className='float-right'>
              <select
                className='custom-select custom-select-sm'
                onChange={(e) => setType(e.target.value)}
              >
                <option defaultValue>All</option>
                <option value='RIDER'>Rider</option>
                <option value='DRIVER'>Driver</option>
              </select>
            </div>

            <Link to={`/general_complain`}>
              <h4 className='card-title mb-4'>
                Assigned Complaints{' '}
                <span className='text-muted'>({type}) </span>
              </h4>
            </Link>
            <SimpleBar style={{ maxHeight: '330px' }}>
              {loading ? (
                <Table hover className=' mb-0 table-centered table-nowrap'>
                  <tbody>
                    {[1, 2, 3, 4, 5]?.map((data, key) => {
                      return (
                        <tr key={key}>
                          <td style={{ width: '10%' }}>
                            <div className='avatar-xs'>
                              <div className='avatar-title rounded-circle bg-light'>
                                <img
                                  src={avatarImg}
                                  alt=''
                                  height='20'
                                  className='rounded-circle avatar-xs'
                                />
                              </div>
                            </div>
                          </td>

                          <td style={{ width: '90%' }}>
                            <h5 className='font-size-14 mb-0'>
                              <Skeleton duration={2} width='75%' />
                            </h5>

                            <span className='text-muted'>
                              <Skeleton duration={2} width='100%' />
                            </span>
                          </td>
                          <td>
                            <div id='spak-chart1'></div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <ul className='list-unstyled activity-wid'>
                  {complaint && complaint?.count
                    ? complaint?.result?.map((data, key) => {
                        return (
                          <li className='activity-list pb-3' key={key}>
                            <div className='activity-icon avatar-xs'>
                              <span className='avatar-title bg-soft-primary text-primary rounded-circle'>
                                <img
                                  src={
                                    data?.reporter?.picture
                                      ? data?.reporter?.picture
                                      : avatarImg
                                  }
                                  alt=''
                                  height='20'
                                  className='rounded-circle avatar-xs'
                                />
                              </span>
                            </div>
                            <Link to={`\general_complain?previewId=${data.id}`}>
                              <div>
                                <div className='d-flex justify-content-between align-items-start'>
                                  <h5 className='text-truncate font-size-13'>
                                    {data?.title}

                                    <small className='text-muted ml-2'>
                                      {data?.reporter?.type}
                                    </small>
                                  </h5>
                                  <h1
                                    className={`badge badge-soft-${
                                      data.status === 'PENDING'
                                        ? 'warning'
                                        : data.status === 'RESOLVED'
                                        ? 'success'
                                        : data.status === 'DISPUTED'
                                        ? 'danger'
                                        : data.status === 'REVIEW'
                                        ? 'primary'
                                        : 'dark'
                                    } font-size-11 mr-1`}
                                  >
                                    {data.status}
                                  </h1>
                                </div>

                                <div className='d-flex justify-content-between align-items-start text-capitalize'>
                                  <p className=' text-muted mb-0 text-capitalize'>
                                    {data?.reporter?.firstName}{' '}
                                    {data?.reporter?.lastName}
                                  </p>
                                  <p className=' text-muted mb-0'>
                                    {Moment(data.createdAt).format('D/M/YYYY')}
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        );
                      })
                    : ' No complaints to display'}
                </ul>
              )}
            </SimpleBar>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { complaint, loading, complaintError } = state.Complaint;
  return { complaint, loading, complaintError };
};

export default connect(mapStateToProps, { fetchMyComplaint })(
  RecentlyComplaints
);
