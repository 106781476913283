import io from 'socket.io-client';

const socket = io.connect(process.env.REACT_APP_BASE_URL);

const Socket = (emitName, data) => {
  // console.log(`socket.emit(${emitName}, ${JSON.stringify(data)})`);

  socket.emit(emitName, data, (response) => {
    console.log(response);
  });

  // socket.onAny((emitName, data) => {
  //   console.log('Got here');
  // });

  socket.on(emitName + '::response', (response) => {
    console.log(response); //
  });
  // socket.on('connect_error', (err) => {
  //   console.log(`connect_error due to ${err.message}`);
  // });
};

export default socket;
export { Socket };
