import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Container,
  Alert,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter, Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CheckAction, {
  ReadCheck,
} from "../../../components/Common/Summary/actionCheck";
import GreySummaryCard from "../../../components/Common/Summary/index";
import TourTABLE from "./table";
import PreviewTour from "./preview";
import {
  fetchTours,
  deleteTour,
  updateTour,
  updateTourFailed,
} from "../../../store/actions";

const Tours = ({
  tours,
  tableError,
  fetchTours,
  deleteTour,
  loading,
  message,
  history,
  accessControl,
  updateTour,
  updateTourFailed,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [data, setData] = useState(null);
  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Tour", link: "#" },
  ];

  const deleteTourModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };
  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteTour(deleteId);
    setDeleteId(null);
  };
  const previewHandler = (id, data = null) => {
    if (!data) setShowModal(true);
    setPreviewId(id);
    if (data) {
      setData(data);
      setShowUpdateModal(true);
    }
  };
  useEffect(() => {
    updateTourFailed("");
  }, []);

  const handleUpdate = (_event, value) => {
    let data = {
      id: previewId,
      availableSeats: value.availableSeats,
    };
    updateTour(data);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <GreySummaryCard field="Rides" subModule="Tour">
            <Breadcrumbs title="Tour" breadcrumbItems={breadcrumbItems} />
            {message && (
              <Alert color="success" className="text-center">
                {message}
              </Alert>
            )}
            {tableError ? (
              <Alert color="danger" className="text-center">
                {tableError}
              </Alert>
            ) : (
              <>
                <CheckAction field="Rides" subModule="Tour" iconType={"Create"}>
                  <div className="mb-2 d-flex justify-content-end align-items-center ">
                    <Link to="/tour_form">
                      <Button
                        color="success"
                        className="mr-1 font-size-11"
                        size="sm"
                      >
                        <i className="mdi mdi-plus-circle-outline font-size-12"></i>{" "}
                        Create Tour
                      </Button>
                    </Link>
                  </div>
                </CheckAction>
                <Row>
                  <Col>
                    <TourTABLE
                      tourData={tours}
                      tableError={tableError}
                      loading={loading}
                      deleteTour={deleteTourModal}
                      fetchTours={fetchTours}
                      history={history}
                      message={message}
                      previewAction={previewHandler}
                      updateTourFailed={updateTourFailed}
                      CheckAction={CheckAction}
                      ReadCheck={ReadCheck}
                      accessControl={accessControl}
                      setShowUpdateModal={setShowUpdateModal}
                    />
                  </Col>
                </Row>

                {/* Delete Confirmation Modal */}
                <Modal
                  isOpen={showDeleteModal}
                  toggle={() => setShowDeleteModal(!showDeleteModal)}
                >
                  <ModalBody>
                    <h4 className="card-title mb-4 text-center">
                      {" "}
                      Are you sure you want to delete this Tour
                    </h4>
                    <button
                      onClick={() => deleteHandler()}
                      className="btn btn-outline-danger btn-sm w-100"
                    >
                      Click to Delete
                    </button>
                  </ModalBody>
                </Modal>

                {/* Tour Preview Modal */}
                <Modal
                  isOpen={showModal}
                  toggle={() => setShowModal(!showModal)}
                >
                  <ModalHeader toggle={() => setShowModal(false)}>
                    Preview Tour
                  </ModalHeader>
                  <ModalBody>
                    <PreviewTour previewId={previewId} />
                  </ModalBody>
                </Modal>

                <Modal
                  isOpen={showUpdateModal}
                  toggle={() => setShowUpdateModal(!showUpdateModal)}
                >
                  <ModalHeader toggle={() => setShowUpdateModal(false)}>
                    Update Tour
                  </ModalHeader>
                  <ModalBody>
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleUpdate}
                    >
                      <Row>
                        <Col md={6}>
                          <FormGroup className="mb-4">
                            <Label htmlFor="availableSeats">
                              Available Seats
                            </Label>
                            <AvField
                              className="form-control bg-light"
                              type="number"
                              min="1"
                              name="availableSeats"
                              value={data ? data.availableSeats : ""}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <button className="btn btn-success mr-2" type="submit">
                        {loading ? "Submitting ..." : "Submit"}
                      </button>
                      <Link to="#" onClick={() => setShowUpdateModal(false)}>
                        <button className="btn btn-secondary">Close</button>
                      </Link>
                    </AvForm>
                  </ModalBody>
                </Modal>
              </>
            )}
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { tours, loading, tourError, message, tableError } = state.Tour;
  return { accessControl, tours, tableError, loading, tourError, message };
};

export default connect(mapStateToProps, {
  fetchTours,
  deleteTour,
  updateTour,
  updateTourFailed,
})(withRouter(Tours));
