import React, { useState } from 'react';
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from 'reactstrap';

import { Link } from 'react-router-dom';

import {
  AvForm,
  AvField,
} from 'availity-reactstrap-validation';

const CreateTitle = ({}) => {
  const [description, setDescription] = useState(null);
  const handleSubmit = (event, values) => {};

  return (
    <div className='page-content'>
      <Card>
        <CardBody>
          <div className='float-right'>
            <Link to='/general_complain'>
              <i className='fas fa-arrow-left mr-3' />
              Back
            </Link>
          </div>
          <div>
            <h5> Create Title </h5>
          </div>
          <div>
            <AvForm className='form-horizontal' onValidSubmit={handleSubmit}>
              <Row>
                <Col md={4}>
                  <FormGroup className=' mb-4'>
                    <AvField
                      className='form-control bg-light'
                      type='select'
                      name='userType'
                      label='User Type'
                      required
                    >
                      <option>User</option>
                      <option>Driver</option>
                    </AvField>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className=' mb-4'>
                    <AvField
                      className='form-control bg-light'
                      type='select'
                      name='complaintTitle'
                      label='Complaint Title'
                      required
                    >
                      <option>Request Help</option>
                      <option>General</option>
                    </AvField>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className=' mb-4'>
                    <Label htmlFor='provider'>Complaint Title</Label>
                    <AvField
                      name='compliantTitle'
                      type='text'
                      className='form-control bg-light'
                      id='compliantTitle'
                      placeholder='Enter Complaint Title ...'
                      required
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor='description'> Description</Label>
                    <textarea
                      className='form-control'
                      onChange={(e) => setDescription(e.target.value)}
                      id='description'
                      rows='5'
                    ></textarea>
                  </FormGroup>
                </Col>
              </Row>

              <button className='btn btn-success mr-2' type='submit'>
                Save
              </button>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default CreateTitle;
