import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { useLocation } from 'react-router-dom';

import Form from './form';

import { fetchEachDocType, deleteDocType } from '../../../../../store/actions';

const DocumentTypeForm = ({
  fetchEachDocType,
  docTypePreview,
  deleteDocType,
  loading,
  message,
}) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  useEffect(() => {
    if (id) {
      fetchEachDocType(id);
    }
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        {docTypePreview ? (
          <Form
            loading={loading}
            defaultValue={docTypePreview}
            message={message}
            deleteDocType={deleteDocType}
          />
        ) : (
          <Form loading={loading} message={message} defaultValue={null} />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { docTypePreview, loading, docTypeError, message } = state.DocType;
  return { docTypePreview, loading, docTypeError, message };
};

export default connect(mapStateToProps, { fetchEachDocType, deleteDocType })(
  DocumentTypeForm
);
