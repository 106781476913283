import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Document from './Document';
import VehicleSummary from './VehicleSummary';
import VehicleImages from './vehicleImages';

const VehicleInfo = ({ vehicleInfo }) => {
  const [activeTab, SetActiveTab] = useState('1');
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      SetActiveTab(tab);
    }
  };

  return (
    <div>
      <div className='d-flex mb-4 justify-content-between align-items-center'>
        <Nav tabs className='nav-tabs-custom'>
          <NavItem>
            <NavLink
              onClick={() => {
                toggleTab('1');
              }}
              className={classnames(
                { active: activeTab === '1' },
                'font-weight-bold p-3'
              )}
            >
              Overview
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={() => {
                toggleTab('2');
              }}
              className={classnames(
                { active: activeTab === '2' },
                'font-weight-bold p-3'
              )}
            >
              Images
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={() => {
                toggleTab('3');
              }}
              className={classnames(
                { active: activeTab === '3' },
                'p-3 font-weight-bold'
              )}
            >
              Documents
            </NavLink>
          </NavItem>
        </Nav>

        <Link
          to={`/vehicle_form?id=${vehicleInfo.id}`}
          className='mr-3 text-primary'
          id='edit1'
        >
          <Button size='sm' color='primary' className=' mr-3'>
            <i className='mdi mdi-pencil font-size-12 mr-2'></i>
            Edit
          </Button>
        </Link>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId='1'>
          <VehicleSummary vehicleInfo={vehicleInfo} />
        </TabPane>
        <TabPane tabId='2'>
          <VehicleImages vehicleInfo={vehicleInfo} />
        </TabPane>
        <TabPane tabId='3'>
          <Document userId={vehicleInfo?.user?.id} />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default VehicleInfo;
