import HttpService from './HttpService'
import { QueryReallignment } from '../utils/general'

export const GetDocumentService = (query) => {
  const http = new HttpService()
  let url = 'document/all'

  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}

export const GetEachDocumentService = (id) => {
  const http = new HttpService()
  const url = `document/${id}`
  return http.getData(url)
}

export const CreateDocumentService = (payload) => {
  const http = new HttpService()
  const url = 'document'

  return http.postData(payload, url)
}
export const UpdateDocumentService = (payload) => {
  const http = new HttpService()
  const url = `document/${payload.id}`

  return http.putData(payload, url)
}

export const DeleteDocumentService = (id) => {
  const http = new HttpService()
  const url = `document/${id}`
  return http.deleteData(url)
}

export const fetchDocumentSummaryService = (query) => {
  // Payload with query
  const http = new HttpService()
  let url = 'summary/document'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}
