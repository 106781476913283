import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Col, Card, CardBody, Media } from 'reactstrap';
import { connect } from 'react-redux';
import { fetchComplaintSummary } from '../../../../store/actions';

const MiniWidgets = ({
  summaryError,
  complaintSummary,
  fetchComplaintSummary,
  start,
  end,
  loading,
  defaultIcon,
  date,
}) => {
  useEffect(() => {
    if (date.end) {
      fetchComplaintSummary(date);
    } else {
      fetchComplaintSummary();
    }
  }, [date, fetchComplaintSummary]);

  const reports = [
    {
      icon: defaultIcon ? defaultIcon : 'fas fa-users',
      title: 'TOTAL',
      value: `${complaintSummary?.result?.total}`,
      rate: `${
        complaintSummary?.previousRegisteredDrivers
          ? complaintSummary?.previousRegisteredDrivers
          : '0'
      }%`,
      color: 'primary',
      desc: 'From previous period',
    },
    {
      icon: 'ri-police-car-fill',
      title: 'RESOLVED',
      value: complaintSummary?.result?.resolved,
      rate: `${
        complaintSummary?.previousActiveDrivers
          ? complaintSummary?.previousActiveDrivers
          : '0'
      }%`,
      color: 'success',
      desc: 'From previous period',
    },
    {
      icon: 'fas fa-ban  ',
      title: 'REVIEW',
      value: `${complaintSummary?.result?.review}`,
      rate: `${
        complaintSummary?.previousRegisteredUsers
          ? complaintSummary?.previousRegisteredUsers
          : '0'
      }%`,
      color: 'secondary',
      desc: 'From previous period',
    },
    {
      icon: 'fas fa-exclamation-triangle',
      title: 'DISPUTED',
      value: `${complaintSummary?.result?.disputed}`,
      rate: `${
        complaintSummary?.previousActiveUsers
          ? complaintSummary?.previousActiveUsers
          : '0'
      }%`,
      color: 'danger',
      desc: 'From previous period',
      updatedTime: `${complaintSummary?.time}`,
    },
  ];

  return (
    <React.Fragment>
      {summaryError === null &&
        reports.map((report, key) => (
          <Col key={key} md={3}>
            <Card>
              <CardBody>
                <Media>
                  <Media body className='overflow-hidden'>
                    <span
                      className={`text-${report.color} badge badge-soft-${report.color} rounded`}
                    >
                      <i className={report.icon + ' font-size-16'}></i>
                    </span>
                    <p className='text-truncate font-size-14 mt-4 mb-2 '>
                      {report.title}
                    </p>
                    <h2 className='mt-0 mb-0 font-size-'>
                      {complaintSummary === null ? (
                        <Skeleton duration={2} width='20%' />
                      ) : (
                        report.value.toLocaleString()
                      )}
                    </h2>
                    {complaintSummary?.isCache && report?.updatedTime && (
                      <p
                        className='font-size-12 text-primary'
                        style={{ position: 'absolute', right: '11px' }}
                      >
                        Updated at: {report?.updatedTime?.split(',')?.pop()}
                      </p>
                    )}
                  </Media>
                </Media>
              </CardBody>

              {/* <CardBody className="border-top py-3">
              <div className="text-truncate">
                <span className="badge badge-soft-success font-size-11 mr-1">
                  <i className="mdi mdi-menu-up"> </i> {report.rate}
                </span>
                <span className="text-muted ml-2">{report.desc}</span>
              </div>
            </CardBody> */}
            </Card>
          </Col>
        ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { summaryError, complaintSummary } = state.Complaint;
  return { summaryError, complaintSummary };
};

export default connect(mapStateToProps, { fetchComplaintSummary })(MiniWidgets);
