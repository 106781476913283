import React, { useEffect } from "react";
// import MetaTags from "react-meta-tags"
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import Form from "./form";

import { fetchEachRoute, fetchBusStops } from "../../../../store/actions";

const RouteForm = ({
  fetchEachRoute,
  fetchBusStops,
  busStops,
  busStopError,
  route,
  loading,
  message,
}) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  useEffect(() => {
    if (id) {
      fetchEachRoute(id);
    }
    fetchBusStops();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Form
          loading={loading}
          defaultValue={route ? route : null}
          message={message}
          busStops={busStops}
          busStopError={busStopError}
          fetchBusStops={fetchBusStops}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { route, loading, routeError, message } = state.Route;
  const { busStops, busStopError } = state.BusStop;
  return { busStops, busStopError, route, loading, routeError, message };
};

export default connect(mapStateToProps, { fetchBusStops, fetchEachRoute })(
  RouteForm
);
