import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  FormGroup,
  Col,
  Input,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Label,
} from 'reactstrap';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import { CSVLink } from 'react-csv';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactPaginate from 'react-paginate';
import Moment from 'moment';
import Loader from '../../../components/Common/Loading/index.js';
import SimpleBar from 'simplebar-react';
import FilterDropDown from '../../../components/Common/FilterDropDown';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../utils/general';
import queryString from 'query-string';
import BankLogo from '../../../components/Common/BankLogo/index';

let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const CashoutTable = ({
  cashoutData,
  updateHandler,
  previewAction,
  fetchCashout,
  deleteCashout,
  statusUpdateHandler,
  statusIdHandler,
  message,
  history,
  fetchCashoutCSVError,
  fetchCashoutCSV,
  csvData,
  csv_loading,
  tableError,
  CheckAction,
  ReadCheck,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [csv, setCsvData] = useState([]);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [menu, setMenu] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState('');
  const [date, setDate] = useState({
    start: '',
    end: '',
  });

  const analyticHandler = (id) => {
    alert(id);
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    setStatus(values.Status);
    setMenu(false);
    let queryParams = {
      search,
      // take,
      // skip,
      status: values.Status,
      start: values.startDate,
      end: values.endDate,
    };
    fetchCashout(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setStatus('');
    setSearch('');
    setDate({
      start: '',
      end: '',
    });
    let queryParams = {};
    fetchCashout(queryParams);
  };

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(cashoutData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      status,
      search,
      start: date.start,
      end: date.end,
    };
    fetchCashout(pageInfo);
  };
  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = {
      skip,
      take,
      status,
      search,
      start: date.start,
      end: date.end,
    };
    fetchCashout(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      // skip,
      status,
      start: date.start,
      end: date.end,
    };
    fetchCashout(queryParams);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          status,
          start: date.start,
          end: date.end,
        };
        fetchCashout(queryParams);
      }, 2000);
    }
  }, [message]);

  const handleCSV = (e, values) => {
    fetchCashoutCSV({
      take: values.endRow
        ? values.endRow - values.startRow + 1
        : cashoutData?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
    });
  };

  useEffect(() => {
    if (cashoutData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setPageCount(Math.ceil(cashoutData?.total / take));
    }
  }, [cashoutData]);

  useEffect(() => {
    if (csvData) {
      // SET CSV EXPORT DATA
      const csv = csvData.result.map((data) => {
        let id = data.id.substring(0, 7);
        let formData = {
          id: id,
          userName: data.user?.firstName + ' ' + data.user?.lastName,
          UserType: data?.user?.userType,
          fee: data.fee,
          amount: data.amount,
          type: data.type,
          Bank: data?.bank.bankName,
          AccountNumber: `A-${data?.bank?.accountNumber}`,
          Status: data?.status,
          createdAt: data?.createdAt?.substring(0, 10),
        };

        return formData;
      });

      setCsvData(csv);
    }
  }, [csvData]);
  useEffect(() => {
    if (!showCSVModal) {
      fetchCashoutCSVError();
    }
  }, [showCSVModal]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchCashout(parsedUrlParams);
  }, [fetchCashout]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      status,
      start: date.start,
      end: date.end,
      skip,
      take,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        start: date.start,
        end: date.end,
        skip,
        take,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.status) {
      setStatus(parsedUrlParams?.status);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };

  let checking = ReadCheck(accessControl, `Finance`, 'Cashout');


  const tableDataHandler = () => {
    const rowData = cashoutData?.result?.map((data, i) => {
      return {
        keyId: data.id,
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,

        id: (

          <>
            {checking ?

              <Link
                to='#'
                onClick={() => previewAction(data.id)}
                className='text-dark font-weight-bold'
              >
                {data.id.substring(0, 7)}
              </Link>

              :
              <div className='text-dark font-weight-bold'>
                {data?.id?.substring(0, 7)}
              </div>
            }</>
        ),
        amount: (
          <div className='d-flex flex-column align-items-center'>
            <div className='d-flex align-items-center'>
              <span className='mb-0'>{`${data.amount} -`}</span>
              <span className='badge badge-soft-danger rounded' style={{ marginLeft: '5px' }}>{`${data.fee}`}</span>
            </div>
            <span
              className={`mt-2 badge badge-soft-${data.type.toLowerCase() === 'instant'
                ? 'success'
                : data.type.toLowerCase() === 'scheduled'
                  ? 'warning'
                  : data.type.toLowerCase() === 'bonus'
                    ? 'primary'
                    : 'dark'
                } font-size-12`}
            >
              {data.type}
            </span>
          </div>
        ),
        type: (
          <span
            className={`badge badge-soft-${data.type.toLowerCase() === 'instant'
              ? 'success'
              : data.type.toLowerCase() === 'scheduled'
                ? 'warning'
                : data.type.toLowerCase() === 'bonus'
                  ? 'primary'
                  : 'dark'
              } font-size-12`}
          >
            {data.type}
          </span>
        ),

        userName: (
          <div
            className='text-truncate'
            style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}
          >

            {checking ?

              <Link to={`/user/${data.user.id}`} className='mr-3 text-dark'>
                <div className='text-capitalize'>
                  {data.user.firstName} {data.user.lastName}
                </div>
                <div className='d-flex align-items-center'>
                  <div className='mr-2'>
                    <img
                      src={data.user?.picture ? data.user?.picture : avatarImg}
                      alt=''
                      height='20'
                      className='rounded-circle avatar-xs'
                    />
                  </div>
                  <div>
                    <span
                      className={`badge badge-${data?.user?.userType === 'RIDER'
                        ? 'primary'
                        : data?.user?.userType === 'DRIVER'
                          ? 'secondary'
                          : data?.user?.userType === 'ADMIN'
                            ? 'success'
                            : 'warning'
                        }`}
                    >
                      {data?.user?.userType}
                    </span>
                  </div>
                </div>
              </Link>

              :
              <div className='text-dark font-weight-bold'>
                <div className='text-capitalize'>
                  {data.user.firstName} {data.user.lastName}
                </div>
                <div className='d-flex align-items-center'>
                  <div className='mr-2'>
                    <img
                      src={data.user?.picture ? data.user?.picture : avatarImg}
                      alt=''
                      height='20'
                      className='rounded-circle avatar-xs'
                    />
                  </div>
                  <div>
                    <span
                      className={`badge badge-${data?.user?.userType === 'RIDER'
                        ? 'primary'
                        : data?.user?.userType === 'DRIVER'
                          ? 'secondary'
                          : data?.user?.userType === 'ADMIN'
                            ? 'success'
                            : 'warning'
                        }`}
                    >
                      {data?.user?.userType}
                    </span>
                  </div>
                </div>
              </div>
            }
          </div>
        ),

        bankLogo: (
          <div className='d-flex flex-column align-items-center'>
            <div
              className='d-flex align-items-center text-truncate'
              style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}
            >
              <div className='mr-2'>
                <BankLogo bank={data?.bank.bankName} />
              </div>
              <div>
                <div>{data?.bank.bankName}</div>
              </div>
            </div>
            <div className='badge badge-soft-secondary font-size-12 mt-2'>
              {' '}
              {data?.bank.accountNumber}
            </div>
          </div>
        ),
        bankName: data?.bank.bankName,
        accountNumber: data?.bank?.accountNumber,
        createdAt: <div>
          {' '}
          <div>{Moment(data.createdAt).format('D MMM, YYYY')}</div>
          <div className="badge badge-soft-secondary font-size-10">
            {' '}
            {Moment(data?.createdAt).add(1, 'hours').format('hh:mm A')}
          </div>
        </div>,
        status: (
          <h1
            className={`badge badge-soft-${data.status.toLowerCase() === 'completed'
              ? 'success'
              : data.status.toLowerCase() === 'pending'
                ? 'secondary'
                : data.status.toLowerCase() === 'processing'
                  ? 'warning'
                  : data.status.toLowerCase() === 'cancelled'
                    ? 'danger'
                    : 'dark'
              } font-size-12`}
          >
            {data.status ? data.status : '---'}
          </h1>
        ),
        action: (
          <div className='d-flex align-items-center'>
            <CheckAction field='Finance' subModule='Cashout' iconType='Edit'>
              <Link
                to='#'
                onClick={() => updateHandler(data)}
                className='mr-3 text-primary'
                id='edit1'
                style={
                  data.status === 'CANCELLED' || data.status === 'COMPLETED'
                    ? { pointerEvents: 'none', opacity: '0.5' }
                    : null
                }
              >
                <i className='mdi mdi-pencil font-size-16'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='edit1'>
                Edit
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field='Finance' subModule='Cashout' iconType='Edit'>
              <Link
                to='#'
                className='mr-3 text-success'
                id='confirm'
                onClick={() => statusUpdateHandler(data.id)}
                style={
                  data.status === 'CANCELLED' ||
                    data.status === 'COMPLETED' ||
                    data.status === 'PROCESSING'
                    ? { pointerEvents: 'none', opacity: '0.5' }
                    : null
                }
              >
                <i className='fas fa-check font-size-12'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='confirm'>
                Confirm
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field='Finance' subModule='Cashout' iconType='Delete'>
              <Link
                to='#'
                className='mr-3 text-danger'
                id='cancel'
                onClick={() => statusIdHandler(data.id)}
                style={
                  data.status === 'CANCELLED' || data.status === 'COMPLETED'
                    ? { pointerEvents: 'none', opacity: '0.5' }
                    : null
                }
              >
                <i className='fas fa-times pr-2 font-size-16 '></i>
              </Link>
              <UncontrolledTooltip placement='top' target='cancel'>
                Cancel
              </UncontrolledTooltip>
            </CheckAction>
            {/* <Link
              to="#"
              className="mr-3 text-secondary"
              id="Analysis"
              onClick={() => analyticHandler(data.id)}
            >
              <i className="ri-line-chart-fill pr-2 font-size-16 "></i>
            </Link>
            <UncontrolledTooltip placement="top" target="Analysis">
              Analysis
            </UncontrolledTooltip> */}
          </div>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
          headerStyle: { width: '70px' }
        },
        {
          text: 'Cashout Id',
          dataField: 'id',
        },

        {
          text: 'Owner',
          dataField: 'userName',
          headerAlign: 'center',
          headerStyle: { width: '150px' },
        },
        {
          text: 'Amount/Fees/Type',
          dataField: 'amount',
        },
        // {
        //   text: 'Type',
        //   dataField: 'type',
        // },

        {
          text: 'Bank',
          dataField: 'bankLogo',
          headerAlign: 'center',
          headerStyle: { width: '150px' },
        },
        // {
        //   text: 'Account Number',
        //   dataField: 'accountNumber',
        // },
        {
          text: 'Created At',
          dataField: 'createdAt',
        },
        {
          text: 'Status',
          dataField: 'status',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {tableError === null ? (
                <>
                  <div className='d-flex justify-content-end align-items-center mb-2'>
                    <div>
                      <Button
                        size='sm'
                        color='primary'
                        className='mt-1'
                        onClick={() => setShowCSVModal(true)}
                        disabled={cashoutData?.total > 0 ? false : true}
                      >
                        <i className=' fas fa-cloud-download-alt mr-1' />
                        CSV
                      </Button>
                    </div>
                    <div>
                      <FilterDropDown
                        menu={menu}
                        toggleMenu={() => setMenu(!menu)}
                      >
                        <div>
                          <AvForm
                            className='form-horizontal'
                            onValidSubmit={handleDateFilter}
                          >
                            <SimpleBar
                              style={{
                                maxHeight: '250px',
                              }}
                            >
                              <Row className='px-3  pt-3 pb-0'>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='startDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='startDate'
                                      value={date.start}
                                      helpMessage='Start Date'
                                      placeholder='Please choose the start date...'
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='endDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='endDate'
                                      value={date.end}
                                      helpMessage='End Date'
                                      placeholder='Please choose the end date...'
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      type='select'
                                      value={status}
                                      helpMessage='Status'
                                      name='Status'
                                    >
                                      <option value=''>All</option>
                                      <option value='COMPLETED'>
                                        COMPLETED
                                      </option>
                                      <option value='PROCESSING'>
                                        PROCESSING
                                      </option>
                                      <option value='REJECTED'>REJECTED</option>
                                      <option value='CANCELLED'>
                                        CANCELLED
                                      </option>
                                      <option value='PENDING'>PENDING</option>
                                    </AvField>
                                  </FormGroup>
                                </Col>
                              </Row>

                              <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                                <Link
                                  to='#'
                                  onClick={resetHandler}
                                  className='btn btn-sm  bg-light font-size-14'
                                >
                                  <i className=' mdi mdi-refresh mr-1'>
                                    {' '}
                                    Reset
                                  </i>
                                </Link>

                                <button
                                  className='btn btn-success btn-sm mr-2'
                                  type='submit'
                                >
                                  <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                  Send
                                </button>
                              </div>
                            </SimpleBar>
                          </AvForm>
                        </div>
                      </FilterDropDown>
                    </div>
                  </div>
                  {!isLoading && data !== null ? (
                    <>
                      <div className='d-flex justify-content-between mb-2'>
                        <div>
                          <Input
                            bsSize='sm'
                            id='take'
                            name='take'
                            type='select'
                            onChange={handleLimitChange}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div>
                          <Input
                            bsSize='sm'
                            id='search'
                            name='search'
                            onChange={SearchHandler}
                            placeholder='Search'
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField='keyId'
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses='table-responsive'
                        noDataIndication={'No Data to Display'}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              ) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* CSV DOWNLOAD MODAL */}
      <Modal
        isOpen={showCSVModal}
        toggle={() => setShowCSVModal(!showCSVModal)}
      >
        <ModalHeader toggle={() => setShowCSVModal(false)}>
          Generate CSV
        </ModalHeader>
        <ModalBody>
          <AvForm className='form-horizontal' onValidSubmit={handleCSV}>
            <Col>
              <FormGroup>
                <Label htmlFor='startRow'>Start (Row)</Label>
                <AvField
                  name='startRow'
                  type='number'
                  max={cashoutData?.total - 1}
                  className='form-control'
                  id='startRow'
                  placeholder='Enter the row to start from'
                  errorMessage='value must be greater than 0 and less than total data'
                  min='1'
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor='endRow'>End (Row)</Label>
                <AvField
                  name='endRow'
                  type='number'
                  className='form-control'
                  min='1'
                  max={cashoutData?.total}
                  id='endRow'
                  placeholder='Enter the row to end'
                  helpMessage={
                    cashoutData?.total
                      ? `Total data is ${cashoutData?.total}`
                      : null
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Button
              size='sm'
              color='primary'
              className='ml-3 waves-effect waves-light '
              type='submit'
            >
              {csv_loading ? 'Generating...' : 'Generate'}
            </Button>
            {csvData && (
              <CSVLink
                data={csv}
                filename={'driver_cashout_detail.csv'}
                className='btn-sm btn-success ml-2'
                target='_blank'
                onClick={() => setShowCSVModal(false)}
              >
                Download
              </CSVLink>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CashoutTable;
