import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetContactService = (query) => {
  const http = new HttpService();
  let url = 'contact/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const GetSOSService = (query) => {
  const http = new HttpService();
  let url = 'connect/sos/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const GetEachContactService = (id) => {
  const http = new HttpService();
  const url = `contact/${id}`;
  return http.getData(url);
};

export const GetEachSOSService = (id) => {
  const http = new HttpService();
  const url = `connect/sos/${id}`;
  return http.getData(url);
};

export const CreateContactService = (payload) => {
  const http = new HttpService();
  const url = 'contact';
  return http.postData(payload, url);
};
export const UpdateContactService = (payload) => {
  const http = new HttpService();
  const url = `contact/${payload.id}`;
  return http.putData(payload, url);
};

export const UpdateSOSService = ({ payload, id }) => {
  const http = new HttpService();
  const url = `connect/sos/${id}`;
  return http.putData(payload, url);
};

export const DeleteContactService = (id) => {
  const http = new HttpService();
  const url = `contact/${id}`;
  return http.deleteData(url);
};
