import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetPaymentService = (query) => {
  const http = new HttpService();
  let url = 'account/entry/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }

  return http.getData(url);
};

export const GetPaymentListService = (query) => {
  const http = new HttpService();
  let url = 'account/list';

  return http.getData(url);
};

export const GetPaymentRecipientService = (id) => {
  const http = new HttpService();
  let url = `/account/${id}/recipient`;
  return http.getData(url);
};

export const GetEachPaymentService = (id) => {
  const http = new HttpService();
  const url = `account/${id}`;
  return http.getData(url);
};
export const GetMyPaymentService = () => {
  const http = new HttpService();
  const url = `account`;
  return http.getData(url);
};
export const CreatePaymentService = (payload) => {
  const http = new HttpService();
  const url = 'account';
  return http.postData(payload, url);
};
export const UpdatePaymentService = ({ data, query }) => {
  const http = new HttpService();
  let url = `account/${data.id}${data.review ? `/review` : ''}`;
  if (query) {
    url = `account/${data.id}/review`;
  }
  return http.putData(data, url);
};

export const DeletePaymentService = (id) => {
  const http = new HttpService();
  const url = `account/${id}`;
  return http.deleteData(url);
};

export const fetchPaymentSummaryService = (query) => {
  // Payload with query
  const http = new HttpService();
  let url = 'summary/account';
  if (query) {
    if (query.user) {
      url = 'account/summary';
    }
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};
