import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  Container,
} from 'reactstrap';
import { getS3ImageUrlHandler } from '../../../../utils/general';

//Dropzone
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';

import { Link, withRouter } from 'react-router-dom';

import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import {
  createAppConfigs,
  createAppConfigsError,
} from '../../../../store/actions';

const CreateAppSetting = ({
  message,
  loading,
  history,
  packageError,
  createAppConfigs,
}) => {
  const [selectedFiles, setFileUpload] = useState([]);
  const [imageError, setImageError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [defaultData, setDefaultData] = useState({
    module: '',
    description: '',
    appConfigItems: {
      name: '',
      picture: '',
      order: 0,
      type: 'BUTTON',
    },
  });

  const handleSubmit = async (event, values) => {
    setImageError(null);
    const packageData = {
      ...values,
      appConfigItems: [values.appConfigItems],
      description,
    };
    try {
      if (selectedFiles.length > 0) {
        try {
          let result = await getS3ImageUrlHandler(
            selectedFiles[0],
            'app_setting'
          );

          packageData.appConfigItems[0].picture = result?.data.result?.mediaUrl;
        } catch (error) {
          throw error;
        }
      }
      createAppConfigs(packageData);
    } catch (error) {
      setImageError(`${error} - image upload error format`);
      setIsLoading(false);
    }
  };

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setFileUpload(files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        history.push('/app_settings');
      }, [2000]);
    }
  }, [message]);

  return (
    <div className='page-content'>
      <Container fluid>
        <div className='mb-2'>
          <Link to='/app_settings'>
            <button className='btn btn-light btn-sm'>
              <i className='fas fa-arrow-left mr-2' /> Back
            </button>
          </Link>
        </div>
        <Card>
          <CardBody>
            <div>
              {message && (
                <Alert color='success' className='text-center'>
                  {message}
                </Alert>
              )}
              {packageError && (
                <Alert color='danger' className='text-center'>
                  {packageError}
                </Alert>
              )}

              {imageError && (
                <Alert color='danger' className='text-center'>
                  {imageError}
                </Alert>
              )}

              <div>
                <AvForm
                  className='form-horizontal'
                  onValidSubmit={handleSubmit}
                  model={defaultData}
                >
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col className='mb-2' xs={12}>
                          <h5> Configuration Setting</h5>
                        </Col>
                        <Col md={12}>
                          <FormGroup className='mb-4'>
                            <Label htmlFor='name'>Module Name</Label>
                            <AvField
                              name='module'
                              type='text'
                              className='form-control bg-light'
                              id='module'
                              placeholder='Enter Module Name ...'
                              required
                            />
                          </FormGroup>
                        </Col>

                        <Col md={12}>
                          <FormGroup>
                            <Label htmlFor='description'> Description</Label>
                            <textarea
                              className='form-control'
                              onChange={(e) => setDescription(e.target.value)}
                              id='description'
                              rows='10'
                            ></textarea>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col xs={12} className='mb-2'>
                          <h5> Items </h5>
                        </Col>
                        <Col md={12}>
                          <FormGroup className=' mb-4'>
                            <Label htmlFor='provider'>App Name</Label>
                            <AvField
                              name='appConfigItems.name'
                              type='text'
                              className='form-control bg-light'
                              id='name'
                              placeholder='Enter App Name ...'
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className=' mb-4'>
                            <Label htmlFor='provider'>Order Number</Label>
                            <AvField
                              name='appConfigItems.order'
                              min='0'
                              type='number'
                              className='form-control bg-light'
                              id='order'
                              placeholder='Enter Order Number ...'
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className=' mb-4'>
                            <AvField
                              className='form-control bg-light'
                              type='select'
                              name='appConfigItems.type'
                              label='Type'
                            >
                              <option>Button</option>
                              <option>Icon</option>
                            </AvField>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              handleAcceptedFiles(acceptedFiles)
                            }
                            multiple={false}
                            accept='image/jpeg,image/jpd'
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className='dropzone'>
                                <div
                                  className='dz-message needsclick mt-2'
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className='mb-3'>
                                    <i className='display-4 text-muted ri-upload-cloud-2-line'></i>
                                  </div>
                                  <h5>Drop/Upload Item Picture Here.</h5>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className='dropzone-previews mt-3'
                            id='file-previews'
                          >
                            {selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                                  key={i + '-file'}
                                >
                                  <div className='p-2'>
                                    <Row className='align-items-center'>
                                      <Col className='col-auto'>
                                        <img
                                          data-dz-thumbnail=''
                                          height='80'
                                          className='avatar-sm rounded bg-light'
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to='#'
                                          className='text-muted font-weight-bold'
                                        >
                                          {f.name}
                                        </Link>
                                        <p className='mb-0'>
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              );
                            })}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className='text-center mt-2'>
                    <button className='btn btn-success px-4' type='submit'>
                      {isLoading ? 'Submitting ...' : 'Create'}
                    </button>
                  </div>
                </AvForm>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, packageError } = state.AppConfigs;
  return { message, loading, packageError };
};

export default connect(mapStateToProps, {
  createAppConfigs,
  createAppConfigsError,
})(withRouter(CreateAppSetting));
