import {
  FETCH_DOCUMENT_SUMMARY,
  FETCH_DOCUMENT_SUMMARY_SUCCESSFULLY,
  FETCH_DOCUMENT_SUMMARY_FAILED,
  FETCH_DOCUMENT,
  FETCH_DOCUMENT_SUCCESSFUL,
  FETCH_DOCUMENT_API_FAILED,
  FETCH_EACH_DOCUMENT,
  FETCH_EACH_DOCUMENT_SUCCESSFUL,
  FETCH_EACH_DOCUMENT_API_FAILED,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_SUCCESSFUL,
  DELETE_DOCUMENT_FAILED,
  CREATE_DOCUMENT,
  CREATE_DOCUMENT_SUCCESSFUL,
  CREATE_DOCUMENT_FAILED,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_SUCCESSFUL,
  UPDATE_DOCUMENT_FAILED,
  IS_DOCUMENT_AUTHORIZE,
} from './actionTypes'

export const fetchDocumentAuthorizeError = (error) => {
  return {
    type: IS_DOCUMENT_AUTHORIZE,
    payload: error,
  }
}

export const fetchDocument = (payload) => {
  return {
    type: FETCH_DOCUMENT,
    payload,
  }
}

export const fetchDocumentSuccessful = (payload) => {
  return {
    type: FETCH_DOCUMENT_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchDocumentError = (error) => {
  return {
    type: FETCH_DOCUMENT_API_FAILED,
    payload: error,
  }
}

export const fetchEachDocument = (id) => {
  return {
    type: FETCH_EACH_DOCUMENT,
    payload: id,
  }
}

export const fetchEachDocumentSuccessful = (payload) => {
  return {
    type: FETCH_EACH_DOCUMENT_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchEachDocumentError = (error) => {
  return {
    type: FETCH_EACH_DOCUMENT_API_FAILED,
    payload: error,
  }
}

export const deleteDocument = (id) => {
  return {
    type: DELETE_DOCUMENT,
    payload: id,
  }
}

export const deleteDocumentSuccessful = (payload) => {
  return {
    type: DELETE_DOCUMENT_SUCCESSFUL,
    payload,
  }
}

export const deleteDocumentFailed = (error) => {
  return {
    type: DELETE_DOCUMENT_FAILED,
    payload: error,
  }
}

export const createDocument = (payload) => {
  return {
    type: CREATE_DOCUMENT,
    payload,
  }
}

export const createDocumentSuccessful = (payload) => {
  return {
    type: CREATE_DOCUMENT_SUCCESSFUL,
    payload,
  }
}

export const createDocumentFailed = (error) => {
  return {
    type: CREATE_DOCUMENT_FAILED,
    payload: error,
  }
}

export const updateDocument = (payload) => {
  return {
    type: UPDATE_DOCUMENT,
    payload,
  }
}

export const updateDocumentFailed = (error) => {
  return {
    type: UPDATE_DOCUMENT_FAILED,
    payload: error,
  }
}

export const updateDocumentSuccessful = (payload) => {
  return {
    type: UPDATE_DOCUMENT_SUCCESSFUL,
    payload,
  }
}

//SUMMARY ENTRIES
export const fetchDocumentSummary = (query) => {
  return {
    type: FETCH_DOCUMENT_SUMMARY,
    payload: query,
  }
}

export const fetchDocumentSummarySuccessfully = (payload) => {
  return {
    type: FETCH_DOCUMENT_SUMMARY_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchDocumentSummaryFailed = (error) => {
  return {
    type: FETCH_DOCUMENT_SUMMARY_FAILED,
    payload: error,
  }
}
