import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';

import { useLocation } from 'react-router-dom';

import Form from './form';

import {
  fetchPackage,
  fetchEachRide,
  deleteRide,
} from '../../../../store/actions';

const RideForm = ({
  fetchPackage,
  fetchEachRide,
  ride,
  packages,
  deleteRide,
  loading,
  message,
  tripId,
}) => {
  const url = useLocation().search;
  const rideId = new URLSearchParams(url).get('rideId');
  const rebooking = new URLSearchParams(url).get('rebooking');

  useEffect(() => {
    if (rideId) {
      fetchEachRide(rideId);
    }
    if (rebooking) {
      fetchEachRide(rebooking);
    }
  }, []);

  useEffect(() => {
    fetchPackage();
  }, [fetchPackage]);
  return (
    <React.Fragment>
      <div className='page-content'>
        <Form
          loading={loading}
          defaultValue={ride ? ride : null}
          message={message}
          deleteRide={deleteRide}
          fetchPackage={fetchPackage}
          packages={packages}
          rideId={rideId}
          rebooking={rebooking ? true : false}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { ride, loading, ridesError, message, tripId } = state.Rides;
  const { packages } = state.Packages;
  return { packages, ride, loading, ridesError, message, tripId };
};

export default connect(mapStateToProps, {
  fetchPackage,
  fetchEachRide,
  deleteRide,
})(RideForm);
