import React, { useEffect } from 'react';
import PreviewTable from './previewTable';
import { Alert } from 'reactstrap';
const PreviewCashout = ({
  FetchEachCashoutAnalytics,
  previewId,
  date,
  cashoutAnalyticsError,
  cashoutAnalyticsPreview,
  loading,
}) => {
  useEffect(() => {
    FetchEachCashoutAnalytics(previewId);
  }, [FetchEachCashoutAnalytics]);

  return (
    <div>
      {cashoutAnalyticsError && (
        <Alert color="danger" className="text-center">
          {cashoutAnalyticsError}
        </Alert>
      )}
      <PreviewTable
        cashoutAnalyticsPreview={cashoutAnalyticsPreview}
        FetchEachCashoutAnalytics={FetchEachCashoutAnalytics}
        loading={loading}
        cashoutAnalyticsError={cashoutAnalyticsError}
        previewId={previewId}
      />
    </div>
  );
};

export default PreviewCashout;
