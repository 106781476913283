import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Moment from 'moment';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import {
  Row,
  FormGroup,
  Col,
  Table,
  Label,
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
//Simple bar
import SimpleBar from 'simplebar-react';
import PreviewCashout from '../../../pages/IRides/Cashout/previewCashout';
import {
  fetchAccountEntries,
  fetchCashout,
  updateCashoutStatus,
  updateCashoutFailed,
  generateOTP,
  generateOTPFailed,
  fetchCashoutCSV,
  fetchCashoutCSVError,
  fetchPaymentSummary,
  fetchAccountCSV,
  fetchAccountCSVFailed,
  fetctUserBonus,
} from '../../../store/actions';
import CashoutTable from './cashoutTable';

const PaymentHistory = ({
  accountEntries,
  loading,
  user,
  fetchAccountEntries,
  fetchPaymentSummary,
  paymentSummary,
  cashout,
  fetchCashout,
  fetchAccountCSV,
  fetchAccountCSVFailed,
  acc_csv,
  generateOTP,
  generateOTPFailed,
  otp,
  loadingOTP,
  otpError,
  otpMessage,
  tableError,
  updateCashoutStatus,
  updateCashoutFailed,
  cashoutError,
  message,
  history,
  fetchCashoutCSV,
  fetchCashoutCSVError,
  csvData,
  csv_loading,
  user_location,
  fetctUserBonus,
  bonusError,
  userBonus,
}) => {

  const [date, setDate] = useState({
    start: Moment().subtract(1, 'M').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });
  const [csv, setCsvData] = useState([]);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setCancelModal] = useState(false);
  const [showApproveModal, setApproveModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [cancellationReason, setReasonForm] = useState('');
  const [Error, setError] = useState(false);
  const [disableButton, setDisable] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (user) {
      let query = {
        startDate: date.start,
        endDate: date.end,
      };
      fetchAccountEntries(user.id, query);

      if (user_location) {
        fetctUserBonus({ userId: user.id, lat: user_location?.result?.lat, lng: user_location?.result?.lng });
      }
      if (user?.userType === 'DRIVER') {
        fetchCashout({ user: user.id });
      }
      setLoading(false);
    }
  }, [fetchAccountEntries, user, user_location]);



  const handleGenerateOTP = () => {
    setDisable(true);
    generateOTP({ email: user.email, type: 'CASHOUT_APPROVE' });
    setTimeout(() => {
      setDisable(false);
    }, 15000);
  };


  const handleCancellation = (_event) => {
    setError(false);
    if (!cancellationReason) {
      setError(true);
      return;
    }
    let data = {
      id: previewId,
      event: 'cancel',
      status: 'CANCELLED',
      message: cancellationReason,
    };

    updateCashoutStatus(data);
  };
  const handleApproval = (_event, value) => {
    setError(false);
    let data = {
      id: previewId,
      event: 'confirm',
      status: 'COMPLETED',
      code: value.code,
    };
    updateCashoutStatus(data);
  };

  useEffect(() => {
    if (message || cashoutError) {
      setTimeout(() => {
        updateCashoutFailed('');
        setApproveModal(false);
        setCancelModal(false);
        fetchCashout();
      }, 3000);
    }
    if (otp) {
      setTimeout(() => {
        generateOTPFailed('');
      }, 2000);
    }
  }, [otp, message, cashoutError, updateCashoutFailed, fetchCashout]);

  useEffect(() => {
    updateCashoutFailed('');
    if (user?.userType === 'DRIVER') {
      if (date.end) {
        fetchPaymentSummary({ ...date, user: user.id });
      } else {
        fetchPaymentSummary({ user: user.id });
      }
    }
  }, [date, fetchPaymentSummary]);

  const handleCSV = (e, values) => {
    fetchAccountCSV(user.id, {
      take: values.endRow
        ? values.endRow - values.startRow + 1
        : accountEntries?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
      startDate: date.start,
      endDate: date.end,
    });
  };

  useEffect(() => {
    if (acc_csv) {
      // SET CSV EXPORT DATA
      const csv = acc_csv.result.map((data) => {
        let id = data.id.substring(0, 7);
        let formData = {
          id: id,
          Type: data?.type,
          Classification: data?.classification,
          Source: data?.source,

          Amount: data?.amount ? data?.amount : '0',
          Balance_Before: `₦${data?.balanceBefore}`,
          Balance_After: `₦${data?.balanceAfter}`,
          createdAt: data?.createdAt?.substring(0, 10),
        };

        return formData;
      });

      setCsvData(csv);
    }
  }, [acc_csv]);
  useEffect(() => {
    if (!showCSVModal) {
      fetchAccountCSVFailed();
    }
  }, [showCSVModal]);
  return (
    <React.Fragment>
      <Row>
        <Col sm={4} md={4}>
          <div className="border rounded mb-2" >
            <div className='p-3 d-flex justify-content-between align-items-center' style={{ borderBottom: '2px dotted lightgrey' }}>
              <h6 className='font-weight-bold'> Recent Payments </h6>
              <Link to={`#`} onClick={() => setShowCSVModal(true)} className="mr-2 font-size-11 font-weight-bold text-success">
                Export CSV
              </Link>{' '}
            </div>
            {accountEntries && accountEntries.result.length > 0 ?
              <>
                {accountEntries.result.map((entry) => (
                  <div className='px-3 pt-3 pb-1 d-flex align-items-center justify-content-between' style={{ borderBottom: '2px dotted lightgrey' }} key={entry?.id}>
                    <div>
                      <div className='d-flex align-items-center'>
                        <h6 className='font-weight-bold font-size-18 text-dark'>
                          ₦ {entry?.amount?.toLocaleString()}
                        </h6>
                        <span className='ml-1 text-muted font-size-10'>{entry?.source}</span>
                      </div>
                      <h6 className='font-weight-bold text-success' style={{ fontSize: '9px' }}>{entry?.classification}</h6>
                    </div>
                    <h6 className={`badge badge-soft-${entry?.type === 'CREDIT'
                      ? 'success'
                      : entry?.type === 'DEBIT'
                        ? 'danger'
                        : 'warning'
                      } font-size-10`}>
                      {entry?.type}
                    </h6>
                    <div className='d-flex flex-column align-items-end font-size-12'>
                      <span>
                        {Moment(entry.createdAt).format('D MMM, YYYY')}</span>
                      <small className='ml-2 text-muted'>
                        {Moment(entry.createdAt).format('LT')}
                      </small>

                    </div>
                  </div>))}
              </>
              :
              <p className='font-weight-bold p-3'>No data for the specified date range</p>
            }
          </div>
        </Col>
        <Col sm={8}>
          {user?.userType === 'DRIVER' && (
            <Col className="mb-4">
              <div className="border rounded mb-2" >
                <div className='p-3 d-flex justify-content-between align-items-center' style={{ borderBottom: '2px dotted lightgrey' }}>
                  <h6 className='font-weight-bold'> Payment Summaries</h6>

                </div>
                <Row className='mt-5'>
                  <Col xs={4}>
                    <h3 className='text-center mb-2 text-success'>
                      ₦ {paymentSummary?.result?.earnings?.toLocaleString('en-US')}
                    </h3>
                    <p className='font-size-12 text-muted text-center' style={{ textWrap: 'nowrap' }}>
                      {' '}
                      Total Inflow{' '}
                    </p>
                  </Col>
                  <Col xs={4}>
                    <h3 className='text-center mb-2 text-warning'>
                      ₦ {paymentSummary?.result?.expense?.toLocaleString('en-US')}
                    </h3>
                    <p className='font-size-12 text-muted text-center' style={{ textWrap: 'nowrap' }}>
                      {' '}
                      Total Outflow{' '}
                    </p>
                  </Col>
                  <Col xs={4}>
                    <h3 className='text-center mb-2 text-success'>
                      ₦ {paymentSummary?.result?.netEarnings?.toLocaleString('en-US')}
                    </h3>
                    <p className='font-size-12 text-muted text-center' style={{ textWrap: 'nowrap' }}>
                      {' '}
                      Net Earnings{' '}
                    </p>
                  </Col>


                </Row>
              </div>
            </Col>)}
          <Col className="mb-4">
            <div className="border rounded mb-2" >
              <div className='p-3 d-flex justify-content-between align-items-center' style={{ borderBottom: '2px dotted lightgrey' }}>
                <h6 className='font-weight-bold'> Recent Bonuses</h6>
                <Link to={`/bonus`} className="mr-2 font-size-11 font-weight-bold text-success">
                  See full bonus details
                </Link>{' '}
              </div>
              <div className='p-2'>
                <Table
                  hover
                  className='mt-3 border rounded mb-0 table-centered  p-2 font-size-12'
                >
                  <thead
                    style={{
                      background: 'lightgrey',
                      position: 'sticky',
                      top: '0',
                      zIndex: '99',
                    }}
                  >
                    <tr>
                      <th className='py-1'>Bonus Type</th>
                      <th className='py-1'>Amount</th>
                      <th className='py-1'>Date/Time</th>
                      <th className='py-1 text-center' style={{ width: '40px' }}>Acceptance Rate(%)</th>
                      <th className='py-1 text-center' style={{ width: '40px' }}>Completion Rate(%)</th>
                      <th className='py-1 text-center'>Online Hours</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bonusError === null &&
                      userBonus !== null ? (
                      <>
                        {userBonus?.result?.map((data, key) => {
                          return (
                            <tr key={key}>
                              <td>{data?.type ? (
                                data?.type
                              ) : (
                                '----'
                              )}</td>
                              <td><span> ₦ {data.value} </span></td>
                              <td><div>{Moment(data.createdAt).format('D MMM, YYYY')}</div></td>
                              <td className='text-center'><span>
                                {data?.requirements?.acceptance
                                  ? `${data.requirements?.acceptance * 100}%`
                                  : 0}
                              </span></td>
                              <td className='text-center'><span>
                                {data?.requirements?.completions
                                  ? `${data.requirements?.completions * 100}%`
                                  : 0}
                              </span></td>
                              <td className='text-center'><span>
                                {data?.requirements?.online
                                  ? data.requirements?.online
                                  : 0}
                              </span></td>
                            </tr>
                          )
                        })}
                      </>)
                      : bonusError ?
                        <tr>
                          <td colSpan={6} className='text-center'><p className="p-3 font-weight-bold">{bonusError}</p></td>
                        </tr>

                        :
                        <tr>
                          <td colSpan={6} className='text-center'><p className="p-3 font-weight-bold">No bonus records</p></td>
                        </tr>}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
          <Col>
            <div className="border rounded mb-2" >
              <div className='p-3 d-flex justify-content-between align-items-center' style={{ borderBottom: '2px dotted lightgrey' }}>
                <h6 className='font-weight-bold'> Cashouts</h6>
                <Link to={`/cashout`} className="mr-2 font-size-11 font-weight-bold text-success">
                  See more Cashouts
                </Link>{' '}
              </div>
              <div className='p-2'>
                <Table
                  hover
                  className='mt-3 border rounded mb-0 table-centered  p-2 font-size-12'

                >
                  <thead
                    style={{
                      background: 'lightgrey',
                      position: 'sticky',
                      top: '0',
                      zIndex: '99',
                    }}
                  >
                    <tr>
                      <th className='py-1'>Amount</th>
                      <th className='py-1'>Date/Time</th>
                      <th className='py-1'>Status</th>
                      <th className='py-1'>Account</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cashoutError === null &&
                      cashout !== null ? (
                      <>
                        {cashout?.result?.map((data, key) => {
                          return (
                            <tr key={key}>
                              <td>{data?.amount ? (
                                <h1 className='font-weight-bold font-size-16'>₦ {data.amount}</h1>
                              ) : (
                                '----'
                              )}</td>
                              <td><div>{Moment(data.createdAt).format('D MMM, YYYY')}</div></td>
                              <td>
                                <h1
                                  className={`badge badge-soft-${data.status.toLowerCase() === 'completed'
                                    ? 'success'
                                    : data.status.toLowerCase() === 'pending'
                                      ? 'secondary'
                                      : data.status.toLowerCase() === 'processing'
                                        ? 'warning'
                                        : data.status.toLowerCase() === 'cancelled'
                                          ? 'danger'
                                          : 'dark'
                                    } font-size-10`}
                                >
                                  {data.status ? data.status : '---'}
                                </h1>
                              </td>
                              <td>
                                <div
                                  className={`text-${data.type.toLowerCase() === 'instant'
                                    ? 'success'
                                    : data.type.toLowerCase() === 'scheduled'
                                      ? 'warning'
                                      : data.type.toLowerCase() === 'bonus'
                                        ? 'primary'
                                        : 'dark'
                                    } font-size-12 font-weight-bold`}
                                >
                                  {data.type}
                                </div>
                              </td>

                            </tr>
                          )
                        })}
                      </>)
                      : cashoutError ?
                        <tr>
                          <td colSpan={4} className='text-center'><p className="p-3 font-weight-bold">{cashoutError}</p></td>
                        </tr>

                        :
                        <tr>
                          <td colSpan={4} className='text-center'><p className="p-3 font-weight-bold">No cashout records</p></td>
                        </tr>}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Col>
      </Row>
      <Modal
        isOpen={showCancelModal}
        toggle={() => setCancelModal(!showCancelModal)}
      >
        <ModalHeader toggle={() => setCancelModal(false)}>
          Reject Cash Out
        </ModalHeader>
        <ModalBody>
          {message && (
            <Alert color='success' className='text-center'>
              {message}
            </Alert>
          )}
          {Error && (
            <Alert className='text-center' color='danger'>
              Please kindly state the reason for rejection
            </Alert>
          )}
          <div>
            <AvForm
              className='form-horizontal'
              onValidSubmit={handleCancellation}
            >
              <Row>
                <Col md='9'>
                  <FormGroup className='mb-4'>
                    <Label htmlFor='cancellationReason'>
                      Reason For Rejection
                    </Label>
                    <textarea
                      className='form-control'
                      onChange={(e) => setReasonForm(e.target.value)}
                      value={cancellationReason}
                      id='cancellationReason'
                      rows='3'
                    ></textarea>
                  </FormGroup>
                </Col>
              </Row>

              <button className='btn btn-success mr-2' type='submit'>
                {loading ? 'Submitting ...' : 'Submit'}
              </button>
              <Link to='#' onClick={() => setCancelModal(false)}>
                <button className='btn btn-secondary'>Close</button>
              </Link>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>

      {/* Approve Cashout OTP */}
      <Modal
        isOpen={showApproveModal}
        toggle={() => setApproveModal(!showApproveModal)}
      >
        <ModalHeader toggle={() => setApproveModal(false)}>
          Approve Cashout
        </ModalHeader>
        <ModalBody>
          {message && (
            <Alert color='success' className='text-center'>
              {message}
            </Alert>
          )}
          {Error && (
            <Alert className='text-center' color='danger'>
              Please kindly input the OTP sent to your email
            </Alert>
          )}
          {cashoutError && (
            <Alert color='danger' className='text-center'>
              {cashoutError}
            </Alert>
          )}
          {otpError && (
            <Alert color='danger' className='text-center'>
              {otpError}
            </Alert>
          )}
          {otpMessage && (
            <Alert color='secondary' className='text-center'>
              {otpMessage}
            </Alert>
          )}
          <div>
            <AvForm className='form-horizontal' onValidSubmit={handleApproval}>
              <Row>
                <div className='d-flex align-items-center w-100 mb-2'>
                  <Col md={9} className='pr-0'>
                    <FormGroup className=' mb-4'>
                      <Label htmlFor='code'>OTP</Label>

                      <AvField
                        name='code'
                        type='number'
                        className='form-control bg-light'
                        required
                        errorMessage='please enter OTP sent to you'
                        placeholder='Enter otp'
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} className='pl-1 mt-1'>
                    <Button
                      color='primary'
                      onClick={handleGenerateOTP}
                      disabled={disableButton}
                    >
                      {loadingOTP ? 'Sending' : 'Generate OTP'}
                    </Button>
                  </Col>
                </div>
                {otp && (
                  <p
                    className='font-size-12 text-primary'
                    style={{
                      position: 'absolute',
                      left: '3%',
                      bottom: '30%',
                    }}
                  >
                    OTP sent at: {otp?.time?.split(',')?.pop()}
                  </p>
                )}
              </Row>

              <button className='btn btn-success mr-2' type='submit'>
                {loading ? 'Submitting ...' : 'Submit'}
              </button>
              <Link to='#' onClick={() => setApproveModal(false)}>
                <button className='btn btn-secondary'>Close</button>
              </Link>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>

      {/* Cashout Preview Modal */}
      <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          Preview Cashout
        </ModalHeader>
        <ModalBody>
          <PreviewCashout previewId={previewId} />
        </ModalBody>
      </Modal>

      {/* CSV DOWNLOAD MODAL */}
      <Modal
        isOpen={showCSVModal}
        toggle={() => setShowCSVModal(!showCSVModal)}
      >
        <ModalHeader toggle={() => setShowCSVModal(false)}>
          Generate CSV
        </ModalHeader>
        <ModalBody>
          <AvForm className='form-horizontal' onValidSubmit={handleCSV}>
            <Col>
              <FormGroup>
                <Label htmlFor='startRow'>Start (Row)</Label>
                <AvField
                  name='startRow'
                  type='number'
                  max={accountEntries?.total - 1}
                  className='form-control'
                  id='startRow'
                  placeholder='Enter the row to start from'
                  errorMessage='value must be greater than 0 and less than total data'
                  min='1'
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor='endRow'>End (Row)</Label>
                <AvField
                  name='endRow'
                  type='number'
                  className='form-control'
                  min='1'
                  max={accountEntries?.total}
                  id='endRow'
                  placeholder='Enter the row to end'
                  helpMessage={
                    accountEntries?.total
                      ? `Total data is ${accountEntries?.total}`
                      : null
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Button
              size='sm'
              color='primary'
              className='ml-3 waves-effect waves-light '
              type='submit'
            >
              {csv_loading ? 'Generating...' : 'Generate'}
            </Button>
            {acc_csv && (
              <CSVLink
                data={csv}
                filename={`${user?.firstName}-${user?.lastName}_payment.csv`}
                className='btn-sm btn-success ml-2'
                target='_blank'
                onClick={() => setShowCSVModal(false)}
              >
                Download
              </CSVLink>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    accountEntries,
    acc_csv,
    loading,
    otp,
    loadingOTP,
    otpError,
    otpMessage,
  } = state.Users;
  const { csvData, csv_loading, cashout, tableError, cashoutError, message } =
    state.Cashout;
  const { paymentSummary } = state.Payment;
  const { eachUserBonus, userBonus, bonusError } =
    state.Bonus;
  return {
    accountEntries,
    csvData,
    acc_csv,
    csv_loading,
    otp,
    loadingOTP,
    otpError,
    otpMessage,
    cashout,
    loading,
    tableError,
    cashoutError,
    message,
    paymentSummary,
    eachUserBonus, userBonus, bonusError
  };
};

export default connect(mapStateToProps, {
  fetchAccountEntries,
  fetchCashout,
  updateCashoutStatus,
  updateCashoutFailed,
  generateOTP,
  generateOTPFailed,
  fetchCashoutCSV,
  fetchCashoutCSVError,
  fetchPaymentSummary,
  fetchAccountCSV,
  fetchAccountCSVFailed,
  fetctUserBonus
})(withRouter(PaymentHistory));
