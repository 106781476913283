import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { Row, Col, Container, FormGroup, Label, Alert } from 'reactstrap';
import MiniWidgets from '../Components/MiniWidget';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import LoyaltyTable from './driversLoyaltyTable';
import CheckAction, { ReadCheck } from '../../../../components/Common/Summary/actionCheck';
import GreySummaryCard from '../../../../components/Common/Summary/index';
import {
  fetchAllUser,
  updateUserPoint,
  updateUserPointFailed,
} from '../../../../store/actions';

const Loyalty = ({
  users,
  userError,
  tableError,
  fetchAllUser,
  history,
  updateUserPoint,
  updateUserPointFailed,
  loyaltyError,
  pointError,
  loading,
  message,
  isAuthorize,
  accessControl
}) => {
  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'Loyalty', link: '/drivers_loyalty' },
  ];

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        updateUserPointFailed('');
      }, 2000);
    }
  }, [message, updateUserPointFailed]);

  useEffect(() => {
    updateUserPointFailed('');
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Loyalty Users"
            breadcrumbItems={breadcrumbItems}
          />
          <GreySummaryCard field="Loyalty" subModule="Driver Loyalties">
            <>
              {message && (
                <Alert color="success" className="text-center">
                  {message}
                </Alert>
              )}
              {loyaltyError && (
                <Alert color="danger" className="text-center">
                  {loyaltyError}
                </Alert>
              )}
              {tableError && (
                <Alert color="danger" className="text-center">
                  {tableError}
                </Alert>
              )}
              <Row>
                <Col>
                  {' '}
                  <Row>
                    <MiniWidgets />
                  </Row>
                  <LoyaltyTable
                    loyaltyData={users}
                    tableError={tableError}
                    isAuthorize={isAuthorize}
                    loading
                    fetchAllUser={fetchAllUser}
                    history={history}
                    message={message}
                    ReadCheck={ReadCheck}
                    accessControl={accessControl}
                  />
                </Col>
              </Row>
            </>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { loyaltyError, pointError, message } = state.Loyalty;
  const { isAuthorize, users, loading, userError, tableError } = state.Users;
  return {
    isAuthorize,
    users,
    userError,
    loading,
    loyaltyError,
    pointError,
    message,
    tableError,
    accessControl
  };
};

export default connect(mapStateToProps, {
  fetchAllUser,
  updateUserPoint,
  updateUserPointFailed,
})(Loyalty);
