export const FETCH_PAYMENT = 'FETCH_PAYMENT';
export const FETCH_PAYMENT_SUCCESSFUL = 'FETCH_PAYMENT_SUCCESSFUL';
export const FETCH_PAYMENT_API_FAILED = 'FETCH_PAYMENT_API_FAILED';

// BANK Summary
export const FETCH_PAYMENT_SUMMARY = 'FETCH_PAYMENT_SUMMARY';
export const FETCH_PAYMENT_SUMMARY_FAILED = 'FETCH_PAYMENT_SUMMARY_FAILED';
export const FETCH_PAYMENT_SUMMARY_SUCCESSFULLY =
  'FETCH_PAYMENT_SUMMARY_SUCCESSFULLY';

export const FETCH_PAYMENT_LIST = 'FETCH_PAYMENT_LIST';
export const FETCH_PAYMENT_LIST_SUCCESSFUL = 'FETCH_PAYMENT_LIST_SUCCESSFUL';
export const FETCH_PAYMENT_LIST_API_FAILED = 'FETCH_PAYMENT_LIST_API_FAILED';

export const FETCH_EACH_PAYMENT = 'FETCH_EACH_PAYMENT';
export const FETCH_EACH_PAYMENT_SUCCESSFUL = 'FETCH_EACH_PAYMENT_SUCCESSFUL';
export const FETCH_EACH_PAYMENT_API_FAILED = 'FETCH_EACH_PAYMENT_API_FAILED';

export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const DELETE_PAYMENT_SUCCESSFUL = 'DELETE_PAYMENT_SUCCESSFULLY';
export const DELETE_PAYMENT_FAILED = 'DELETE_PAYMENT_FAILED';

export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const CREATE_PAYMENT_SUCCESSFUL = 'CREATE_PAYMENT_SUCCESSFULLY';
export const CREATE_PAYMENT_FAILED = 'CREATE_PAYMENT_FAILED';

export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const UPDATE_PAYMENT_SUCCESSFUL = 'UPDATE_PAYMENT_SUCCESSFULLY';
export const UPDATE_PAYMENT_FAILED = 'FAILED';

export const GET_PAYMENT_RECIPIENT = 'GET_PAYMENT_RECIPIENT';
export const GET_PAYMENT_RECIPIENT_SUCCESSFUL =
  'GET_PAYMENT_RECIPIENT_SUCCESSFULLY';
export const GET_PAYMENT_RECIPIENT_FAILED = 'GET_PAYMENT_RECIPIENT_FAILED';

export const FETCH_FOR_PAYMENT_CSV = 'FETCH_FOR_PAYMENT_CSV';
export const FETCH_FOR_PAYMENT_CSV_ERROR = 'FETCH_FOR_PAYMENT_CSV_ERROR';
export const FETCH_FOR_PAYMENT_CSV_SUCCESSFULLY =
  'FETCH_FOR_PAYMENT_CSV_SUCCESSFULLY';
