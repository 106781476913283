import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button } from 'reactstrap';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { fetchDashRevenue } from '../../../store/actions';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import './dashboard.scss';

const RevenueAnalytics = ({
  fetchDashRevenue,
  dashRevenue,
  revenue_loading,
  dashRevenueError,
  user,
  start,
  end,
}) => {
  const [dataLoading, setLoading] = useState(true);
  const [period, setPeriod] = useState('day');

  const [data, setData] = useState({
    series: [
      {
        name: 'Revnue',
        type: 'column',
        // data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
        data: [],
      },
      {
        name: 'Revnue',
        type: 'line',
        // data: [23, 32, 27, 38, 27, 32, 27, 38, 22, 31, 21, 16],
        data: [],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 3],
        curve: 'smooth',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
        },
      },
      dataLabels: {
        enabled: false,
      },

      legend: {
        show: false,
      },
      colors: ['#5664d2', '#1cbb8c'],
      labels: [
        // 'Jan',
        // 'Feb',
        // 'Mar',
        // 'Apr',
        // 'May',
        // 'Jun',
        // 'Jul',
        // 'Aug',
        // 'Sep',
        // 'Oct',
        // 'Nov',
        // 'Dec',
      ],
    },
  });

  const filterHelper = (field, data) => {
    let result = [];
    if (field === 'date') {
      data.forEach(function (arrayItem) {
        let newDate = new Date(arrayItem[field]);
        var current_time =
          newDate.getFullYear() +
          '/' +
          (newDate.getMonth() + 1) +
          '/' +
          newDate.getDate();
        result.unshift(current_time);
      });
    } else {
      data.forEach(function (arrayItem) {
        result.unshift(arrayItem[field]);
      });
    }

    return result;
  };

  useEffect(() => {
    setLoading(true);
    if (dashRevenue) {
      let cloneData = { ...data };
      filterHelper('total', dashRevenue?.period);
      cloneData.series[1].data = filterHelper('total', dashRevenue?.period);
      cloneData.options.labels = filterHelper('date', dashRevenue?.period);
      // cloneData.options.legend = 'monday'

      setData(cloneData);
      setLoading(false);
    }
  }, [dashRevenue]);

  useEffect(() => {
    if (user.userType === 'ADMIN') {
      fetchDashRevenue({ start, end, period });
    }
  }, [end, fetchDashRevenue, start, period]);
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className='d-flex justify-content-between align-items-center'>
            <h4 className='card-title mb-4'>Revenue Analytics</h4>
            <div className='float-right d-none d-md-inline-block'>
              <ButtonGroup className='mb-2'>
                <Button
                  size='sm'
                  color='light'
                  active={period === 'day' ? true : false}
                  onClick={() => setPeriod('day')}
                  type='button'
                >
                  Daily
                </Button>
                <Button
                  size='sm'
                  color='light'
                  active={period === 'week' ? true : false}
                  type='button'
                  onClick={() => setPeriod('week')}
                >
                  Weekly
                </Button>
                <Button
                  size='sm'
                  color='light'
                  active={period === 'month' ? true : false}
                  type='button'
                  onClick={() => setPeriod('month')}
                >
                  Monthly
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <div>
            <div id='line-column-chart' className='apex-charts' dir='ltr'>
              {!dataLoading && (
                <ReactApexChart
                  options={data.options}
                  series={data.series}
                  type='line'
                  height='300'
                />
              )}
            </div>
          </div>
        </CardBody>

        <CardBody className='border-top text-center'>
          <Row>
            {/* <Col sm={4}>
              <div className="d-inline-flex">
                <h5 className="mr-2">
                  {revenue_loading ? (
                    <Skeleton duration={2} width="20%" />
                  ) : (
                    `₦${dashRevenue?.thisMonth.total.toLocaleString('en-US')}`
                  )}
                </h5>
                <div className="text-success">
                  <i className="mdi mdi-menu-up font-size-14"> </i>0.0 %
                </div>
              </div>
              <p className="text-muted text-truncate mb-0">This month</p>
            </Col> */}
            <Col sm={4}>
              <div className='mt-4 mt-sm-0'>
                <p className='mb-2 text-muted text-truncate'>
                  <i className='mdi mdi-circle text-primary font-size-10 mr-1'></i>{' '}
                  This Month :
                </p>
                <div className='d-inline-flex'>
                  <h5 className='mb-0 mr-2'>
                    {' '}
                    {revenue_loading ? (
                      <Skeleton duration={2} width='20%' />
                    ) : (
                      `₦${
                        dashRevenue?.thisMonth.total
                          ? dashRevenue?.thisMonth.total.toLocaleString('en-US')
                          : 0
                      }`
                    )}
                  </h5>
                  <div className='text-success'>
                    <i className='mdi mdi-menu-up font-size-14'> </i>0.0 %
                  </div>
                </div>
              </div>
            </Col>

            <Col sm={4}>
              <div className='mt-4 mt-sm-0'>
                <p className='mb-2 text-muted text-truncate'>
                  <i className='mdi mdi-circle text-primary font-size-10 mr-1'></i>{' '}
                  This Year :
                </p>
                <div className='d-inline-flex'>
                  <h5 className='mb-0 mr-2'>
                    {' '}
                    {revenue_loading ? (
                      <Skeleton duration={2} width='20%' />
                    ) : (
                      `₦${
                        dashRevenue?.thisYear.total
                          ? dashRevenue?.thisYear.total.toLocaleString('en-US')
                          : 0
                      }`
                    )}
                  </h5>
                  <div className='text-success'>
                    <i className='mdi mdi-menu-up font-size-14'> </i>0.0 %
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className='mt-4 mt-sm-0'>
                <p className='mb-2 text-muted text-truncate'>
                  <i className='mdi mdi-circle text-success font-size-10 mr-1'></i>{' '}
                  Previous Year :
                </p>
                <div className='d-inline-flex'>
                  <h5 className='mb-0'>
                    {' '}
                    {revenue_loading ? (
                      <Skeleton duration={2} width='20%' />
                    ) : (
                      `₦${
                        dashRevenue?.lastYear.total
                          ? dashRevenue?.lastYear.total.toLocaleString('en-US')
                          : 0
                      }`
                    )}
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { dashRevenue, revenue_loading, dashRevenueError } = state.Dashboard;
  return { dashRevenue, revenue_loading, dashRevenueError };
};
export default connect(mapStateToProps, { fetchDashRevenue })(RevenueAnalytics);
