import React, { useEffect, useState } from 'react'
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from 'reactstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { AvForm, AvField } from 'availity-reactstrap-validation'

// actions
import { updateCashoutStatus } from '../../../store/actions'

const Form = ({
  data,
  cashoutError,
  updateCashoutStatus,
  message,
  loading,
}) => {
  const handleSubmit = (event, values) => {
    let updatedDat = { status: values.type }
    updateCashoutStatus(updatedDat, data.id)
  }

  return (
    <Card>
      <CardBody>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        {cashoutError && (
          <Alert color="danger" className="text-center">
            {cashoutError}
          </Alert>
        )}
        <div>
          <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
            <Row className="align-items-center">
              <Col md={12}>
                <FormGroup className="mb-4">
                  <AvField
                    name="type"
                    type="select"
                    className="form-control bg-light"
                    label="Type"
                    value={data.status}
                  >
                    <option>PENDING</option>
                    <option>PROCESSING</option>
                    <option>COMPLETED</option>
                    <option>CANCELLED</option>
                    <option>REJECTED</option>
                  </AvField>
                </FormGroup>
              </Col>
              <Col md={12} className="text-center">
                <button
                  className="btn btn-success mr-2"
                  type="submit"
                  disable={loading ? true : false}
                >
                  {loading ? 'Submitting ...' : 'Update'}
                </button>
              </Col>
            </Row>
          </AvForm>
        </div>
      </CardBody>
    </Card>
  )
}

const mapStateToProps = (state) => {
  const { message, loading, cashoutError } = state.Cashout
  return { message, loading, cashoutError }
}

export default connect(mapStateToProps, {
  updateCashoutStatus,
})(withRouter(Form))
