import React, { useState, useEffect } from 'react';
import { Card, Row, Col, FormGroup, Label } from 'reactstrap';

import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import Button from 'reactstrap/lib/Button';

const Installation = () => {
  return (
    <div>
      <div>
        <div>
          <AvForm className='form-horizontal' model={null}>
            <Row>
              <Col>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>Google Browser Key</Label>
                  <AvField
                    name='browserKey'
                    type='password'
                    className='form-control bg-light'
                    id='browserKey'
                    placeholder='Enter Browser Key ...'
                    required
                  />
                </FormGroup>
                <Label>Twilio Settings</Label>
                <Col>
                  <FormGroup className=' mb-4'>
                    <Label htmlFor='provider'>Twilio Account Sid</Label>
                    <AvField
                      name='twilioAcc'
                      type='text'
                      className='form-control bg-light'
                      placeholder='Twilio Account Sid ...'
                      id='twilioAcc'
                      required
                    />
                  </FormGroup>
                  <FormGroup className=' mb-4'>
                    <Label htmlFor='provider'>Twilio Auth Token</Label>
                    <AvField
                      name='twilioToken'
                      type='text'
                      className='form-control bg-light'
                      id='twilioToken'
                      placeholder='Twilio Auth Token ...'
                      required
                    />
                  </FormGroup>
                  <FormGroup className=' mb-4'>
                    <Label htmlFor='provider'>Twilio Number</Label>
                    <AvField
                      name='twilioNum'
                      type='text'
                      className='form-control bg-light'
                      id='twilioNum'
                      placeholder='Enter Twilio Number ...'
                      required
                    />
                  </FormGroup>
                </Col>
                <Label>Brain Tree Settings</Label>
                <Col>
                  <FormGroup className=' mb-4'>
                    <Label>Brain Tree Environment</Label>
                    <AvField
                      name='brainTree'
                      type='text'
                      className='form-control bg-light'
                      placeholder='Enter Brain Tree Environment ...'
                      id='brainTree'
                      required
                    />
                  </FormGroup>
                  <FormGroup className=' mb-4'>
                    <Label htmlFor='provider'>
                      Enable BrainTree Payment Gateway?
                    </Label>
                    <AvField
                      name='BrainTree Payment'
                      type='select'
                      className='form-control bg-light'
                      id='BrainTree Payment'
                      required
                    >
                      <option>Yes</option>
                      <option>No</option>
                    </AvField>
                  </FormGroup>
                  <FormGroup className=' mb-4'>
                    <Label htmlFor='provider'>Brain Tree Merchant Id</Label>
                    <AvField
                      name='brainTreeId'
                      type='text'
                      className='form-control bg-light'
                      placeholder='Enter Brain Tree Merchant Id ...'
                      id='brainTreeId'
                      required
                    />
                  </FormGroup>
                  <FormGroup className=' mb-4'>
                    <Label htmlFor='provider'>Brain Tree Public Key</Label>
                    <AvField
                      name='BrainTreeKey'
                      type='text'
                      className='form-control bg-light'
                      placeholder='Enter Brain Tree Public Key ...'
                      id='BrainTreeKey'
                      required
                    />
                  </FormGroup>
                  <FormGroup className=' mb-4'>
                    <Label htmlFor='provider'>Brain Tree Private Key</Label>
                    <AvField
                      name='BrainTreePrivateKey'
                      type='text'
                      className='form-control bg-light'
                      placeholder='Enter Brain Tree Private Key ...'
                      id='BrainTreePrivateKey'
                      required
                    />
                  </FormGroup>
                  <FormGroup className=' mb-4'>
                    <Label htmlFor='provider'>Brain Tree Master Merchant</Label>
                    <AvField
                      name='brainTreeMaster'
                      type='text'
                      className='form-control bg-light'
                      placeholder='Enter Brain Tree Master Merchant ...'
                      id='brainTreeMaster'
                      required
                    />
                  </FormGroup>
                  <FormGroup className=' mb-4'>
                    <Label htmlFor='provider'>
                      Brain Tree Default Merchant
                    </Label>
                    <AvField
                      name='yearlySub'
                      type='text'
                      className='form-control bg-light'
                      placeholder='Enter Brain Tree Default Merchant ...'
                      id='yearlySub'
                      required
                    />
                  </FormGroup>
                </Col>

                <div className='text-right'>
                  <Button className='btn btn-success' size='lg'>
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </div>
      </div>
    </div>
  );
};

export default Installation;
