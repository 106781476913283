
import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetRouteService = (query) => {
  const http = new HttpService();
  let url = 'route/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const GetEachRouteService = (id) => {
  const http = new HttpService();
  const url = `route/${id}`;
  return http.getData(url);
};

export const CreateRouteService = (payload) => {
  const http = new HttpService();
  const url = 'route';
  return http.postData(payload, url);
};
export const UpdateRouteService = (payload) => {
  const http = new HttpService();
  const url = `route/${payload.id}`;
  return http.putData(payload, url);
};

export const DeleteRouteService = (id) => {
  const http = new HttpService();
  const url = `route/${id}`;
  return http.deleteData(url);
};
