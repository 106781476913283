import React from 'react';
import { Card, Row, Col, FormGroup, Label } from 'reactstrap';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import Button from 'reactstrap/lib/Button';

const MapSettings = () => {
  return (
    <div>
      <div>
        <div>
          <AvForm
            className='form-horizontal'
            // onValidSubmit={handleSubmit}
            model={null}
          >
            <Row>
              <Col>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>Google Map Key </Label>
                  <AvField
                    name='googleKey'
                    type='text'
                    className='form-control bg-light'
                    id='googleKey'
                    placeholder='Enter Google Map Key  ...'
                    required
                  />
                </FormGroup>

                <div className='text-right'>
                  <Button className='btn btn-success' size='lg'>
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </div>
      </div>
    </div>
  );
};

export default MapSettings;
