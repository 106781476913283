import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Button,
  Row,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import PreviewProfileCard from './PreviewPackage';
import MiniWidgets from './Components/MiniWidget';
import PackageTable from './packageTable';
import UpdateForm from './UpdatePackage';
import PackageZone from './Components/PackageZone';
import GreySummaryCard from '../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../components/Common/Summary/actionCheck';
import { ScrollToTop } from '../../../utils/general';

import {
  fetchZone,
  updatePackage,
  updatePackageFailed,
  fetchPackage,
  deletePackage,
} from '../../../store/actions';

const ServiceType = ({
  packages,
  history,
  loading,
  tableError,
  fetchPackage,
  fetchZone,
  zone,
  updatePackage,
  updatePackageFailed,
  deletePackage,
  message,
  accessControl
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [zoneModal, setZoneModal] = useState(false);
  const [pkgDetails, setPkgDetails] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [updatedData, setUpdatedData] = useState(null);
  const [previewId, setPreviewId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [dateFilter, setDate] = useState({});
  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'Package Type', link: '#' },
  ];

  const previewPackageHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };

  const setIsUpdatedHandler = (value, data) => {
    setUpdatedData(data);
    setIsUpdated(value);
  };

  const deletePackageModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const deleteHandler = () => {
    setShowDeleteModal(false);
    deletePackage(deleteId);
    setDeleteId(null);
  };

  useEffect(() => {
    if (message) {
      ScrollToTop();
      setTimeout(() => {
        fetchPackage();
        updatePackageFailed('');
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    fetchZone();
  }, []);

  const addZoneHandler = (info) => {
    setPkgDetails(info);
    setZoneModal(true);
  };
  const date = (e) => {
    setDate(e);
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {isUpdated ? (
            <UpdateForm
              defaultData={updatedData}
              closeUpdate={setIsUpdated}
              setIsUpdated={() => setIsUpdated(false)}
            />
          ) : (
            <>
              <Breadcrumbs title='Packages' breadcrumbItems={breadcrumbItems} />

              <GreySummaryCard field='Ride Package'>
                <div className='mb-2 text-right'>
                  <CheckAction field='Ride Package' iconType={'Create'}>
                    <Link to='/create_service'>
                      <Button color='success'> Create Package </Button>
                    </Link>
                  </CheckAction>
                </div>
                {message && (
                  <Alert color='success' className='text-center'>
                    {message}
                  </Alert>
                )}
                <Row>
                  <MiniWidgets date={dateFilter} />
                </Row>
                <PackageTable
                  history={history}
                  tableError={tableError}
                  packageData={packages}
                  dateFilter={date}
                  loading={loading}
                  zone={zone}
                  addZoneHandler={addZoneHandler}
                  setIsUpdatedHandler={setIsUpdatedHandler}
                  message={message}
                  deletePackage={deletePackageModal}
                  fetchPackage={fetchPackage}
                  previewPackageAction={previewPackageHandler}
                  CheckAction={CheckAction}
                  ReadCheck={ReadCheck}
                  accessControl={accessControl}
                />
              </GreySummaryCard>
            </>
          )}
        </Container>
        <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
          <ModalHeader toggle={() => setShowModal(false)}>
            Preview Package
          </ModalHeader>
          <ModalBody>
            <PreviewProfileCard previewId={previewId} />
          </ModalBody>
        </Modal>
        {/* DELETE PACKAGE MODAL */}
        <Modal
          isOpen={showDeleteModal}
          toggle={() => setShowDeleteModal(!showDeleteModal)}
        >
          <ModalBody>
            <h4 className='card-title mb-4 text-center'>
              {' '}
              Are you sure you want to delete the package
            </h4>
            <button
              onClick={() => deleteHandler()}
              className='btn btn-outline-danger btn-sm w-100'
            >
              Click to Delete
            </button>
          </ModalBody>
        </Modal>

        <Modal isOpen={zoneModal} toggle={() => setZoneModal(!zoneModal)}>
          <ModalHeader toggle={() => setZoneModal(false)}>
            Add Package to a Zone
          </ModalHeader>
          <ModalBody>
            <PackageZone
              pkgDetails={pkgDetails}
              zone={zone}
              message={message}
              updatePackage={updatePackage}
              fetchZone={fetchZone}
              closeModal={setZoneModal}
              updatePackageFailed={updatePackageFailed}
            />
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { packages, loading, tableError, packageError, message } =
    state.Packages;
  const { zone } = state.Zone;
  return { accessControl, zone, packages, loading, tableError, packageError, message };
};

export default connect(mapStateToProps, {
  fetchZone,
  updatePackageFailed,
  updatePackage,
  fetchPackage,
  deletePackage,
})(withRouter(ServiceType));
