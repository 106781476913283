import React, { useState, useEffect } from 'react';

const Stopwatch = ({ rideCreateTime }) => {
  const countDownDate = new Date(rideCreateTime).getTime();
  const currentTime = new Date().getTime();
  const [countDown, setCountDown] = useState('');
  // console.log(
  //   new Date().getTime(),
  //   countDownDate,
  //   currentTime >= countDownDate
  // );
  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(new Date().getTime() - countDownDate);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  // return [minutes, seconds];
  return (
    <div className='stopwatch'>
      <div className='numbers'>
        <span>
          {minutes >= 0 ? minutes.toString().padStart(2, '0') : '00'}:
        </span>
        <span>{seconds >= 0 ? seconds.toString().padStart(2, '0') : '00'}</span>
      </div>
    </div>
  );
};
export default Stopwatch;
