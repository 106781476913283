import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Form from './form';

import { fetchEachContact } from '../../../../../store/actions';

const ContactForm = ({ fetchEachContact, contact, loading, message }) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');

  useEffect(() => {
    if (id) {
      fetchEachContact(id);
    }
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Form
          loading={loading}
          defaultValue={contact ? contact : null}
          message={message}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { contact, loading, contactError, message } = state.Contact;
  return { contact, loading, contactError, message };
};

export default connect(mapStateToProps, { fetchEachContact })(ContactForm);
