/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import {
  Alert,
  Row,
  Col,
  Table,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import Loader from '../../../../components/Common/Loading/index.js';
import { fetchEachSOS } from '../../../../store/actions';
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const SOSPreview = ({ sos, previewId, loading, sosError, fetchEachSOS }) => {
  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  useEffect(() => {
    fetchEachSOS(previewId);
  }, [fetchEachSOS, previewId]);

  var getfileExt = sos?.dataUrl?.split('.');
  var fileExt = getfileExt && getfileExt[getfileExt?.length - 1];

  console.log(fileExt);
  return (
    <React.Fragment>
      <div className='mt-3'>
        {sosError && !loading && (
          <Alert color='danger' className='text-center'>
            {sosError}
          </Alert>
        )}
        {sos && loading ? (
          <Loader />
        ) : (
          <Row>
            <Col>
              <Link to={`/ride/${sos?.rideId}`} style={{ float: 'right' }}>
                <button className='btn btn-primary btn-sm rounded'>
                  View Ride
                </button>
              </Link>
            </Col>

            <Col sm={12} className='text-center'>
              <div
                className=' my-0 mx-auto rounded w-auto p-2'
                style={{ maxWidth: 'fit-content', background: '#F1F8E9' }}
              >
                {fileExt === 'mp4' || fileExt === 'mov' ? (
                  <button
                    className='btn btn-success font-weight-bold d-flex align-items-center btn-sm rounded'
                    onClick={() => {
                      setShowVideo(true);
                    }}
                  >
                    <i className='font-size-15 ri-live-line mr-1'></i>
                    Watch video
                  </button>
                ) : fileExt === 'wav' ? (
                  <audio src={sos?.dataUrl} controls autoPlay />
                ) : (
                  <img
                    src={sos?.dataUrl}
                    alt='Event Media'
                    className='avatar-sm rounded-circle img-thumbnail p-2'
                    onClick={() => {
                      setShowImage(true);
                    }}
                  />
                )}
              </div>

              <h6
                className={`badge badge-soft-${
                  sos?.status === 'PENDING'
                    ? 'warning'
                    : sos?.status === 'CLOSED'
                    ? 'success'
                    : 'secondary'
                } font-size-12`}
              >
                {sos?.status}
              </h6>
              <p className='text-truncate' style={{ whiteSpace: 'normal' }}>
                {sos?.description}
              </p>
            </Col>
            <Col sm={12}>
              <div>
                <Table hover>
                  <tbody>
                    <tr>
                      <td className='font-weight-bold'>Event</td>
                      <td>{sos?.type}</td>
                    </tr>

                    <tr>
                      <td className='font-weight-bold'>Address </td>
                      <td>
                        {sos?.address ? (
                          <span className=' d-block mb-1'>
                            <span className='badge badge-soft-secondary'>
                              {sos?.address}
                            </span>
                          </span>
                        ) : (
                          '____'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Cordinate </td>
                      <td>
                        {sos?.location?.lat ? (
                          <span>
                            <span className=' d-block mb-1'>
                              <span className='badge badge-soft-secondary'>
                                Lat : {sos?.location?.lat}
                              </span>
                            </span>
                            <span className=' d-block'>
                              <span className='badge badge-soft-secondary'>
                                Lng : {sos?.location?.lng}
                              </span>
                            </span>
                          </span>
                        ) : (
                          '____'
                        )}
                      </td>
                    </tr>
                    {sos?.closedBy && (
                      <tr>
                        <td className='font-weight-bold'>Closed By </td>
                        <td>{sos?.closedBy}</td>
                      </tr>
                    )}
                    <tr>
                      <td className='font-weight-bold'>Date</td>
                      <td>
                        <div>{Moment(sos?.createdAt).format('l')}</div>
                        <div className='badge badge-soft-secondary font-size-10'>
                          {' '}
                          {Moment(sos?.createdAt).format('hh:mm A')}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Row className='p-4 justify-content-around'>
                  <Link to={`/user/${sos?.driver?.id}`}>
                    <Card>
                      <CardBody>
                        <div className='d-flex align-items-center text-dark'>
                          <div className='mr-2'>
                            <img
                              src={
                                sos?.driver?.picture
                                  ? sos?.driver?.picture
                                  : avatarImg
                              }
                              alt=''
                              height='20'
                              className='rounded-circle avatar-xs'
                            />
                          </div>
                          <div>
                            <div className='text-capitalize'>
                              {sos?.driver.firstName} {sos?.driver.lastName}
                            </div>
                            <span className='text-muted ml-2 badge badge-soft-secondary'>
                              <i className=' fas fa-star text-warning mr-1' />
                              {sos?.driver?.rating.toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                  <Link to={`/user/${sos?.rider?.id}`}>
                    <Card>
                      <CardBody>
                        <div className='d-flex align-items-center text-dark'>
                          <div className='mr-2'>
                            <img
                              src={
                                sos?.rider?.picture
                                  ? sos?.rider?.picture
                                  : avatarImg
                              }
                              alt=''
                              height='20'
                              className='rounded-circle avatar-xs'
                            />
                          </div>
                          <div>
                            <div className='text-capitalize'>
                              {sos?.rider.firstName} {sos?.rider.lastName}
                            </div>
                            <span className='text-muted ml-2 badge badge-soft-secondary'>
                              <i className=' fas fa-star text-warning mr-1' />
                              {sos?.rider?.rating.toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </div>
      <Modal
        isOpen={showImage}
        toggle={() => setShowImage(!showImage)}
        size='lg'
        style={{ left: '5%' }}
        centered={true}
      >
        <ModalBody className='p-0 m-2' style={{ height: '70vh' }}>
          <img
            src={sos?.dataUrl}
            alt='Profile'
            style={{ width: '-webkit-fill-available', height: '100%' }}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={showVideo}
        toggle={() => setShowVideo(!showVideo)}
        size='lg'
        style={{ left: '5%' }}
        centered={true}
      >
        <ModalBody className='p-0 m-2' style={{ height: '70vh' }}>
          <video
            style={{ width: '-webkit-fill-available', height: '100%' }}
            controls
          >
            <source src={sos?.dataUrl} type='video/mp4'></source>
          </video>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { sos, loading, sosError } = state.Contact;
  return { sos, loading, sosError };
};

export default connect(mapStateToProps, { fetchEachSOS })(SOSPreview);
