import React, { useState, useEffect } from 'react'
import {
  Card,
  Alert,
  CardBody,
  Row,
  FormGroup,
  Col,
  Input,
  UncontrolledTooltip,
} from 'reactstrap'

import BootstrapTable from 'react-bootstrap-table-next'
import { CSVLink } from 'react-csv'
import ReactPaginate from 'react-paginate'

import Loader from '../../../../components/Common/Loading/index.js'
import Moment from 'moment'
import moment from 'moment-timezone'
import FilterDropDown from '../../../../components/Common/FilterDropDown'
import SimpleBar from 'simplebar-react'
import { Link } from 'react-router-dom'
import { QueryReallignment, ScrollToTop } from '../../../../utils/general'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import queryString from 'query-string'

let skip = 0
let dataLimit = 10
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'

const DocumentTable = ({
  documentData,
  updateDocument,
  previewAction,
  tableError,
  fetchDocument,
  deleteDocument,
  updateDocUploadModalDisplay,
  updateId,
  history,
  message,
  CheckAction,
  ReadCheck,
  accessControl,
}) => {
  const [data, setData] = useState(null)
  const [csvData, setCsvData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [skipPage, setSkipPage] = useState(0)
  const [menu, setMenu] = useState(false)
  const [pageCount, setPageCount] = useState('')
  const [take, setLimit] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)
  const [status, setStatus] = useState('')
  const [isExpired, setExpired] = useState('')
  const [search, setSearch] = useState('')
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  })

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(documentData?.total / e.target.value))
    setLimit(parseInt(e.target.value))
    dataLimit = parseInt(e.target.value)
    let pageInfo = { skip, take: dataLimit, status, search }
    fetchDocument(pageInfo)
    SetUrlWithParams()
  }

  const handlePageClick = async (data) => {
    skip = data.selected
    setPageNumber(parseInt(data.selected))
    if (skip > 0) {
      skip += '0'
      setPageNumber(parseInt(data.selected + '0'))
    }
    let pageInfo = { skip, take, status, search, isExpired }
    setSkipPage(Math.ceil(pageNumber / take))
    SetUrlWithParams()
    fetchDocument(pageInfo)
    ScrollToTop()
  }

  const SearchHandler = (event) => {
    const search = event.target.value
    setSearch(search)
    let queryParams = {
      search,
      isExpired,
      take,
      // skip,
      status,
    }
    SetUrlWithParams()
    fetchDocument(queryParams)
  }
  useEffect(() => {
    if (documentData) {
      SetUrlWithParams()
      setData(tableDataHandler())
      setIsLoading(false)
      setSkipPage(Math.ceil(pageNumber / take))
      setPageCount(Math.ceil(documentData?.total / take))
      // SET CSV EXPORT DATA
      const csvData = documentData.result.map((data) => {
        let id = data?.id?.substring(0, 7)
        let formData = {
          id: id,
          Key: data?.key,
          Doc_Name: data?.name,
          Expiry: data?.isExpiry ? 'Yes' : 'No',
          Optional: data?.isOptional ? 'Yes' : 'No',
          Description: data?.description ? data.description : '----',
          Comment: data?.comment ? data.comment : '----',
          Data: data?.data ? data.data : '----',
          Document_No: data?.documentNumber ? data?.documentNumber : '----',
          Cordinates: data?.lat ? `${data.lat} - ${data.lng}` : '----',
          Status: data?.status,
          Expiry_Date: data?.expiry?.substring(0, 10),
          Created_Date: data?.createdAt?.substring(0, 10),
          Document_Url: data?.documentUrl ? data?.documentUrl : '----',
          DocumentBack_Url: data?.documentBackUrl
            ? data?.documentBackUrl
            : '----',
        }

        return formData
      })
      setCsvData(csvData)
    }
  }, [documentData])

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler()
    fetchDocument(parsedUrlParams)
  }, [fetchDocument])

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      isExpired,
      status,
      skip: pageNumber,
      take,
    }
    let result = QueryReallignment(queryParams)
    history.push({
      pathname: history.location.pathname,
      search: result,
    })
  }

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search)
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams()
      let params = {
        // start: date.start,
        // end: date.end,
        skip,
        take,
      }
      return params
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search)
    }
    if (parsedUrlParams?.status) {
      setStatus(parsedUrlParams?.status)
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip)
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take)
    }
    return parsedUrlParams
  }
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          isExpired,
          take,
          skip,
          status,
        }
        fetchDocument(queryParams)
      }, 2000)
    }
  }, [fetchDocument, message])
  const handleDocView = async (documentUrl, documentId) => {
    let docName, docExtension
    if (documentUrl) {
      var getDocNameFromUrl = documentUrl.split('/')
      docName = getDocNameFromUrl[getDocNameFromUrl.length - 1]

      var getDocExtensionFromUrl = documentUrl.split('.')
      docExtension = getDocExtensionFromUrl[getDocExtensionFromUrl.length - 1]

      if (docExtension === 'pdf') {
        previewAction(docName, documentId, false, true)
      } else {
        previewAction(docName, documentId, true, false)
      }
    } else {
      previewAction(null, documentId, false, false)
    }
  }

  const handleDocUpload = (id) => {
    updateDocUploadModalDisplay(true)
    updateId(id)
  }

  const handleStatusUpdate = (data, status) => {
    let formData = { ...data, status }
    updateDocument(formData)
  }

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate })
    setMenu(false)
    setStatus(values.vehicleStatus)
    console.log(values.isExpired)
    let isExpired =
      values.isExpired === 'true' ? true : values.expired === 'no' ? false : ''
    setExpired(isExpired)
    let queryParams = {
      isExpired,
      search,
      take,
      skip,
      status: values.vehicleStatus,
      start: values.startDate,
      end: values.endDate,
    }
    fetchDocument(queryParams)
  }

  const resetHandler = () => {
    setMenu(false)
    setDate({
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    })
    let queryParams = {
      search,
      take,
      skip,
      status,
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    }
    fetchDocument(queryParams)
  }

  let accessChecking = ReadCheck(accessControl, `Documents`)
  const tableDataHandler = () => {
    const rowData = documentData?.result?.map((data, i) => {
      return {
        keyId: data.id,
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,

        id: (
          <>
            {accessChecking ? (
              <Link
                to="#"
                onClick={() => handleDocView(data.documentUrl, data.id)}
                className="mr-3 text-dark font-weight-bold"
              >
                {data.id.substring(0, 7)}
              </Link>
            ) : (
              <div className="mr-3 text-dark font-weight-bold">
                {data?.id?.substring(0, 7)}
              </div>
            )}
          </>
        ),
        key: data.key,
        expire_date: (
          <div>
            {data.expiry ? (
              <>
                <div>{Moment(data.expiry).format('l')}</div>
                <div className="badge badge-soft-secondary font-size-10">
                  {' '}
                  {Moment(data.expiry).format('LT')}
                </div>
              </>
            ) : (
              'Nill'
            )}
          </div>
        ),

        date: (
          <div>
            <div>{Moment(data.createdAt).format('l')}</div>
            <div className="badge badge-soft-secondary font-size-10">
              {' '}
              {Moment(data.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
        description: data.description,
        name: data.name,
        owner: (
          <>
            {data.document?.user ? (
              <>
                {accessChecking ? (
                  <Link
                    to="#"
                    onClick={() => handleDocView(data.documentUrl, data.id)}
                    className="text-dark font-weight-bold"
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          data.user?.picture ? data.user?.picture : avatarImg
                        }
                        alt=""
                        height="20"
                        className="rounded-circle avatar-xs mr-2"
                      />

                      <div>
                        <div className="text-capitalize">
                          {data?.user?.firstName} {data.user?.lastName}
                        </div>

                        <span className="text-muted ml-2 badge badge-soft-secondary">
                          <i className=" fas fa-star text-warning mr-1" />
                          {data?.user?.rating.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div className="text-dark font-weight-bold">
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          data.user?.picture ? data.user?.picture : avatarImg
                        }
                        alt=""
                        height="20"
                        className="rounded-circle avatar-xs mr-2"
                      />

                      <div>
                        <div className="text-capitalize">
                          {data?.user?.firstName} {data.user?.lastName}
                        </div>

                        <span className="text-muted ml-2 badge badge-soft-secondary">
                          <i className=" fas fa-star text-warning mr-1" />
                          {data?.user?.rating.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              '---'
            )}
          </>
        ),

        status: (
          <h1
            className={`badge badge-soft-${
              data.status.toLowerCase() === 'accepted'
                ? 'success'
                : data.status.toLowerCase() === 'rejected'
                ? 'danger'
                : data.status.toLowerCase() === 'pending'
                ? 'warning'
                : data.status.toLowerCase() === 'flagged'
                ? 'dark'
                : data.status.toLowerCase() === 'review'
                ? 'secondary'
                : ''
            } font-size-12`}
          >
            {data.status ? data.status : '---'}
          </h1>
        ),

        action: (
          <div className="d-flex align-items-center">
            <CheckAction field="Documents" iconType="Edit">
              <Link
                to={`/document_form?id=${data.id}`}
                className="mr-3 text-primary"
                id="edit1"
              >
                <i className="mdi mdi-pencil font-size-18"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="edit1">
                Edit
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field="Documents" iconType="Delete">
              <Link
                to="#"
                onClick={() => deleteDocument(data.id)}
                className="text-danger mr-3"
                id="delete1"
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="delete1">
                Delete
              </UncontrolledTooltip>
            </CheckAction>
            {/* <Link
              to="#"
              className="text-dark mr-3"
              id="docUpload"
              onClick={() => handleDocUpload(data.id)}
            >
              <i className="fas fa-upload font-size-12"></i>
            </Link>
            <UncontrolledTooltip placement="top" target="docUpload">
              Upload
            </UncontrolledTooltip> */}
            <CheckAction field="Documents" iconType="Edit">
              {data.status.toLowerCase() === 'accepted' ? (
                <>
                  <Link
                    to="#"
                    className="text-danger"
                    id="reject"
                    style={
                      data.status === 'REJECTED'
                        ? { pointerEvents: 'none', opacity: '0.5' }
                        : null
                    }
                    onClick={() => handleStatusUpdate(data, 'REJECTED')}
                  >
                    <i className="fas fa-times font-size-12 text-danger"></i>
                  </Link>
                  <UncontrolledTooltip placement="top" target="reject">
                    Reject
                  </UncontrolledTooltip>
                </>
              ) : (
                <>
                  <Link
                    to="#"
                    className="mr-3 text-success"
                    id="approve"
                    onClick={() => handleStatusUpdate(data, 'ACCEPTED')}
                  >
                    <i className="fas fa-check font-size-12 text-success mb-2"></i>
                  </Link>
                  <UncontrolledTooltip placement="top" target="approve">
                    Approve
                  </UncontrolledTooltip>
                </>
              )}
            </CheckAction>
          </div>
        ),
      }
    })
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a
            }
            return a - b
          },
        },
        {
          text: 'Doc Id',
          dataField: 'id',
        },
        {
          text: 'Owner',
          dataField: 'owner',
        },

        {
          text: 'Key',
          dataField: 'key',
          sort: true,
        },
        {
          text: 'Expiring Date',
          dataField: 'expire_date',
        },
        {
          text: 'Name',
          dataField: 'name',
        },

        {
          text: 'Created Date',
          dataField: 'date',
        },
        {
          text: 'Status',
          dataField: 'status',
        },

        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-3">
              {tableError !== null ? (
                <Alert color="danger" className="text-center">
                  {tableError}
                </Alert>
              ) : (
                <>
                  <div className="d-flex justify-content-end  align-items-center mb-2">
                    <div className="">
                      <CSVLink
                        data={csvData}
                        filename={'All_documents_data.csv'}
                        className="btn btn-primary btn-sm  mt-1"
                        target="_blank"
                      >
                        <i className=" fas fa-cloud-download-alt mr-1" />
                        CSV
                      </CSVLink>
                    </div>
                    <div>
                      <FilterDropDown
                        menu={menu}
                        toggleMenu={() => setMenu(!menu)}
                      >
                        <div>
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={handleDateFilter}
                          >
                            <SimpleBar
                              style={{
                                maxHeight: '250px',
                              }}
                            >
                              <Row className="px-3  pt-3 pb-0">
                                <Col md="12">
                                  <FormGroup>
                                    <AvField
                                      name="startDate"
                                      type="date"
                                      className="form-control bg-light"
                                      id="startDate"
                                      value={date.start}
                                      helpMessage="Start Date"
                                      placeholder="Please choose the start date..."
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <FormGroup>
                                    <AvField
                                      name="endDate"
                                      type="date"
                                      className="form-control bg-light"
                                      id="endDate"
                                      value={date.end}
                                      helpMessage="End Date"
                                      placeholder="Please choose the end date..."
                                      required
                                    />
                                  </FormGroup>
                                </Col>{' '}
                                <Col md="12">
                                  <FormGroup>
                                    <AvField
                                      type="select"
                                      value={status}
                                      name="vehicleStatus"
                                      helpMessage="status"
                                    >
                                      <option value="">All</option>
                                      <option value="PENDING">PENDING</option>
                                      <option value="REVIEW">REVIEW</option>
                                      <option value="ACCEPTED">ACCEPTED</option>
                                    </AvField>
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <FormGroup>
                                    <AvField
                                      type="select"
                                      value={isExpired}
                                      name="isExpired"
                                      helpMessage="Expired Doc"
                                    >
                                      <option value="">All</option>
                                      <option value="true">Yes</option>
                                      <option value="no">No</option>
                                    </AvField>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </SimpleBar>

                            <div className="p-2 d-flex justify-content-between align-items-center bg-light">
                              <Link
                                to="#"
                                onClick={resetHandler}
                                className="btn btn-sm  bg-light font-size-14"
                              >
                                <i className=" mdi mdi-refresh mr-1"> Reset</i>
                              </Link>

                              <button
                                className="btn btn-success btn-sm mr-2"
                                type="submit"
                              >
                                <i className="mdi mdi-arrow-right-circle mr-1"></i>
                                Send
                              </button>
                            </div>
                          </AvForm>
                        </div>
                      </FilterDropDown>
                    </div>
                  </div>
                  {!isLoading && data !== null ? (
                    <>
                      <div className="d-flex justify-content-between mb-2">
                        <div>
                          <Input
                            bsSize="sm"
                            id="take"
                            name="take"
                            type="select"
                            onChange={handleLimitChange}
                            value={take}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div>
                          <Input
                            bsSize="sm"
                            id="search"
                            value={search}
                            name="search"
                            onChange={SearchHandler}
                            placeholder="Search"
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField="keyId"
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses="table-responsive"
                        noDataIndication={'No Data to Display'}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        // breakLabel={'...'}
                        // marginPagesDisplayed={2}
                        // pageRangeDisplayed={3}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        forcePage={skipPage}
                        //Bootstrap styling
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default DocumentTable
