import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
} from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import CustomSelect from "../../../../components/Common/MoreDropdown/CustomSelect";
import PackageSelect from "../Components/PackageSelect";
import MultiSelect from "../Components/MultiSelect";
import NextBusStopSelect from "../Components/NextBusStopSelect";
import CurrentBusStopSelect from "../Components/CurrentBusStopSelect";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getS3ImageUrlHandler } from "../../../../utils/general";
import moment from "moment";

// actions
import {
  createTour,
  createTourFailed,
  updateTour,
  updateTourFailed,
} from "../../../../store/actions";

const Form = ({
  createTour,
  createTourFailed,
  updateTour,
  updateTourFailed,
  message,
  history,
  tourError,
  defaultValue,
  loading,
  busStops,
  routes,
  packages,
  fetchBusStops,
  busStopError,
  fetchRoutes,
  fetchPackage,
}) => {
  const [errorMessage, setError] = useState("");
  const [prevBusStop, setprevBusStop] = useState([]);
  const [nextBusStop, setnextBusStop] = useState([]);
  const [currentBusStop, setcurrentBusStop] = useState([]);
  const [selectedPackage, setSelectedPkg] = useState([]);
  const [packageOption, SetPackageOption] = useState([]);
  const [Route, setRoute] = useState([]);
  const [routeOption, setRouteOption] = useState([]);
  const [busStopOption, SetBusStopOption] = useState([]);

  const handleSubmit = async (event, values) => {
    const tourData = {
      ...values,
    };
    tourData.route = Route[0].value;
    tourData.tourPackage = selectedPackage[0].value;
    tourData.previousBusStop = prevBusStop[0].value;
    tourData.nextBusStop = nextBusStop[0].value;
    tourData.currentBusStop = currentBusStop[0].value;
    tourData.status = values.Status;
    tourData.availableSeats = values.availableSeats;
    tourData.startTime = moment(values.startTime).format(
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    );

    if (defaultValue) {
      tourData.id = defaultValue.id;
      updateTour(tourData);
      return;
    }

    createTour(tourData);
  };

  useEffect(() => {
    if (defaultValue) {
      setprevBusStop([
        {
          value: defaultValue?.previousBusStop["id"],
          label: defaultValue?.previousBusStop["name"],
          address: defaultValue?.previousBusStop["address"],
          cord: {
            lat: defaultValue.previousBusStop["lat"],
            lng: defaultValue.previousBusStop["lng"],
          },
        },
      ]);
      setnextBusStop([
        {
          value: defaultValue?.nextBusStop?.id,
          label: defaultValue?.nextBusStop?.name,
          address: defaultValue?.nextBusStop?.address,
          cord: {
            lat: defaultValue.nextBusStop?.lat,
            lng: defaultValue.nextBusStop?.lng,
          },
        },
      ]);
      setcurrentBusStop([
        {
          value: defaultValue?.currentBusStop?.id,
          label: defaultValue?.currentBusStop?.name,
          address: defaultValue?.currentBusStop?.address,
          cord: {
            lat: defaultValue.currentBusStop?.lat,
            lng: defaultValue.currentBusStop?.lng,
          },
        },
      ]);

      setSelectedPkg([
        {
          value: defaultValue?.tourPackage?.id,
          label: defaultValue?.tourPackage?.name,
        },
      ]);
      setRoute([
        {
          value: defaultValue?.route?.id,
          label: defaultValue?.route?.name,
        },
      ]);
    }
    if (message) {
      setTimeout(() => {
        createTourFailed("");
        createTourFailed("");
        history.goBack();
      }, 2000);
    }
  }, [defaultValue, message]);

  useEffect(() => {
    createTourFailed("");
    setprevBusStop([]);
    setnextBusStop([]);
    setRoute([]);
    setSelectedPkg([]);
    setcurrentBusStop([]);
  }, []);

  useEffect(() => {
    if (routes) {
      const routeArr = [];
      routes.result.forEach((element) => {
        const routeObj = {};
        routeObj.value = element.id;
        routeObj.label = element.name;
        routeArr.push(routeObj);
      });
      setRouteOption(routeArr);
    }
    if (packages) {
      const packageArr = [];
      packages.result.forEach((element) => {
        const packageObj = {};
        packageObj.value = element.id;
        packageObj.label = element.name;
        packageArr.push(packageObj);
      });
      SetPackageOption(packageArr);
    }
    if (busStops) {
      const busStopArr = [];
      busStops.result.forEach((element) => {
        const busStopObj = {};
        busStopObj.value = element.id;
        busStopObj.label = element.name;
        busStopObj.address = element.address;
        busStopObj.cord = { lat: element.lat, lng: element.lng };
        busStopArr.push(busStopObj);
      });
      SetBusStopOption(busStopArr);
    }
  }, [busStops, packages, routes]);

  const handleFetchMore = () => {
    fetchBusStops({ take: busStopOption.length + 10, skip: 0 });
  };
  const handleFetchMoreRoute = () => {
    fetchRoutes({ take: routeOption.length + 10, skip: 0 });
  };
  const handleFetchMorePkg = () => {
    fetchPackage({ take: packageOption.length + 10, skip: 0 });
  };
  return (
    <Card className="col-md-12">
      <CardBody>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        {tourError && (
          <Alert color="danger" className="text-center">
            {tourError}
          </Alert>
        )}
        {busStopError && (
          <Alert color="danger" className="text-center">
            {busStopError}
          </Alert>
        )}
        {errorMessage && (
          <Alert color="danger" className="text-center">
            {errorMessage}
          </Alert>
        )}
        <div className="float-right">
          <Link to="#" onClick={() => history.goBack()}>
            <i className="fas fa-arrow-left mr-3" />
            Back
          </Link>
        </div>
        <div className="mb-3">
          <h6> {defaultValue ? "Update" : "Create"} Tour </h6>
        </div>
        <div className="col-md-12 pl-0">
          <Row>
            <Col md={12}>
              <AvForm
                className="form-horizontal"
                onValidSubmit={handleSubmit}
                model={defaultValue ? defaultValue : {}}
              >
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="packages">Packages</Label>

                      <div className="CustomSelect">
                        <PackageSelect
                          options={packageOption}
                          selected={selectedPackage}
                          onChange={setSelectedPkg}
                          fetchMore={
                            packageOption.length !== packages?.total
                              ? handleFetchMorePkg
                              : null
                          }
                          labelledBy="Select packages ..."
                          isMulti={false}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className=" mb-4">
                      <Label htmlFor="route">Preferred Route</Label>
                      <div className="CustomSelect">
                        <MultiSelect
                          options={routeOption}
                          selected={Route}
                          onChange={setRoute}
                          fetchMore={
                            routeOption.length !== routes?.total
                              ? handleFetchMoreRoute
                              : null
                          }
                          isMulti={false}
                          labelledBy="Select your preferred route ..."
                        ></MultiSelect>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="prevBusStop">Previuos Bus Stop</Label>
                          <div className="CustomSelect">
                            <CustomSelect
                              options={busStopOption}
                              selected={prevBusStop}
                              onChange={setprevBusStop}
                              fetchMore={
                                busStopOption.length !== busStops?.total
                                  ? handleFetchMore
                                  : null
                              }
                              isMulti={false}
                              labelledBy="Select previous bus stop ..."
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="nextBusStop">Next Bus Stop</Label>
                          <div className="CustomSelect">
                            <NextBusStopSelect
                              options={busStopOption}
                              selected={nextBusStop}
                              onChange={setnextBusStop}
                              fetchMore={
                                busStopOption.length !== busStops?.total
                                  ? handleFetchMore
                                  : null
                              }
                              isMulti={false}
                              labelledBy="Select last bus stop ..."
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="currentBusStop">
                            Current Bus Stop
                          </Label>
                          <div className="CustomSelect">
                            <CurrentBusStopSelect
                              options={busStopOption}
                              selected={currentBusStop}
                              onChange={setcurrentBusStop}
                              fetchMore={
                                busStopOption.length !== busStops?.total
                                  ? handleFetchMore
                                  : null
                              }
                              isMulti={false}
                              labelledBy="Select last bus stop ..."
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="startTime">Start Date</Label>
                          <AvField
                            className="form-control bg-light"
                            type="datetime-local"
                            name="startTime"
                            value={
                              defaultValue
                                ? `${defaultValue.startTime.split(":")[0]}:${
                                    defaultValue.startTime.split(":")[1]
                                  }`
                                : ""
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="availableSeats">
                            Available Seats
                          </Label>
                          <AvField
                            className="form-control bg-light"
                            type="number"
                            min="1"
                            name="availableSeats"
                            value={
                              defaultValue ? defaultValue?.availableSeats : ""
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="Status">Status</Label>
                          <AvField
                            className="form-control bg-light"
                            type="select"
                            name="Status"
                            value={
                              defaultValue ? defaultValue?.status : "PLANNED"
                            }
                          >
                            <option value={"PLANNED"}>Planned</option>
                            <option value={"PROGRESSING"}>Progressing</option>
                            <option value={"PAUSED"}>Paused</option>
                            <option value={"ENDED"}>Ended</option>
                            <option value={"CANCELLED"}>Cancelled</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <button className="btn btn-success mr-2" type="submit">
                  {loading
                    ? "Submitting ..."
                    : `${defaultValue ? "Update" : "Create"}`}
                </button>
                <Link to="#" onClick={() => history.goBack()}>
                  <button className="btn btn-secondary">Cancel</button>
                </Link>
              </AvForm>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, tourError } = state.Tour;
  return { message, loading, tourError };
};

export default connect(mapStateToProps, {
  createTour,
  createTourFailed,
  updateTour,
  updateTourFailed,
})(withRouter(Form));
