import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Input,
  FormGroup,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Button,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SimpleBar from 'simplebar-react';
import BootstrapTable from 'react-bootstrap-table-next';
import FilterDropDown from '../../../../components/Common/FilterDropDown';
import { withRouter } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Moment from 'moment';
import { CSVLink } from 'react-csv';
import moment from 'moment-timezone';
import Loader from '../../../../components/Common/Loading/index.js';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../../utils/general';
import queryString from 'query-string';
const headerSortingStyle = { backgroundColor: '#6c757d24' };
const defaultSorted = [
  {
    dataField: 'fullName',
    order: 'asc',
  },
];
let skip = 0;
let dataLimit = 10;
const LogsTable = ({
  logs,
  fetchDriverHoursLogs,
  loading,
  history,
  fetchForCSVError,
  fetchDataForCSV,
  csv,
  csv_loading,
}) => {
  const [data, setData] = useState(null);
  const [date, setDate] = useState({
    start: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });
  const [status, setStatus] = useState('');
  const [skipPage, setSkipPage] = useState(0);
  const [isLoading, setIsLoading] = useState(loading);
  // const [refreshTimer, setRefreshTimer] = useState(0);
  const [menu, setMenu] = useState(false);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [csvData, setCsvData] = useState([]);
  const [showCSVModal, setShowCSVModal] = useState(false);

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(logs?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      status,
      search,
      start: date.start,
      end: date.end,
    };
    fetchDriverHoursLogs(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }

    let pageInfo = {
      skip,
      take,
      status,
      search,
      start: date.start,
      end: date.end,
    };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchDriverHoursLogs(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      skip,
      status,
      start: date.start,
      end: date.end,
    };
    fetchDriverHoursLogs(queryParams);
  };
  const handleCSV = (e, values) => {
    fetchDataForCSV({
      take: values.endRow ? values.endRow - values.startRow + 1 : logs?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
    });
  };
  useEffect(() => {
    if (logs) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(logs?.total / take));
    }
  }, [logs]);

  useEffect(() => {
    if (csv) {
      // SET CSV EXPORT DATA
      const csvData = csv?.result.map((data) => {
        let id = data?.driverId?.substring(0, 7);
        let formData = {
          id: id,
          Full_Name: `${data?.firstName} - ${data?.lastName}`,
          Records: data?.records ? data?.records : '----',
          Minutes: data?.minutes ? data?.minutes : '----',
          Hours: data?.hours ? data?.hours : '----',
        };

        return formData;
      });

      setCsvData(csvData);
    }
  }, [csv]);

  useEffect(() => {
    if (!showCSVModal) {
      fetchForCSVError();
    }
  }, [showCSVModal]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchDriverHoursLogs(parsedUrlParams);
  }, [fetchDriverHoursLogs]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      status,
      take,
      skip: pageNumber,
      start: date.start,
      end: date.end,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        take,
        skip,
        start: date.start,
        end: date.end,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.status) {
      setStatus(parsedUrlParams?.status);
    }

    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    setStatus(values.Status);
    setMenu(false);
    let query = {
      start: values.startDate,
      end: values.endDate,
      status: values.Status,
    };
    fetchDriverHoursLogs(query);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    });
    setStatus('');
    let query = {
      start: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    };
    fetchDriverHoursLogs(query);
  };

  const tableDataHandler = () => {
    let rowData2 = [];
    if (logs.ok) {
      rowData2 = logs?.result?.map((data) => {
        return {
          keyId: data.driverId,
          id: (
            <Link
              to={`/user/${data.driverId}`}
              className='mr-3 text-dark font-weight-bold'
            >
              {data.driverId.substring(0, 7)}
            </Link>
          ),
          fullName: `${data.firstName} ${data.lastName}`,
          records: data?.records ? data?.records : '----',
          minutes: data?.minutes ? data?.minutes : '----',
          hours: data?.hours
            ? `${Math.floor(data?.minutes / 60)}.${data?.minutes % 60}`
            : '----',
          redemption: data?.redemption,
          rate: (
            <div>
              <div>completed-{data?.completionRate?.completed}</div>
              <div>uncompleted-{data?.completionRate?.uncompleted}</div>
            </div>
          ),
          acceptanceRate: (
            <div>
              <div>Receive-{data?.acceptanceRate?.requestReceived}</div>
              <div>Cancelled-{data?.acceptanceRate?.requestCancelled}</div>
              <div>Ignore-{data?.acceptanceRate?.requestIgnored}</div>
            </div>
          ),
        };
      });
    }
    return {
      columns: [
        {
          text: 'Name',
          dataField: 'fullName',
          sort: true,
          headerSortingStyle,
        },
        {
          text: 'Total Records',
          dataField: 'records',
          classes: 'text-center',
          headerStyle: { textAlign: 'center' },
          sort: true,
          headerSortingStyle,
        },
        {
          text: 'On Duration(h:m)',
          dataField: 'hours',
          sort: true,
          headerSortingStyle,
        },
        {
          text: 'Acceptance Rate',
          dataField: 'acceptanceRate',
        },
        {
          text: 'Completion Rate',
          dataField: 'rate',
        },
        {
          text: 'Redemption',
          dataField: 'redemption',
        },
      ],
      rows2: rowData2,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              <div className='d-flex justify-content-end align-items-top mb-2'>
                <div className='d-flex align-items-center'>
                  <div className=''>
                    <Button
                      size='sm'
                      color='primary'
                      className='mt-1'
                      onClick={() => setShowCSVModal(true)}
                      disabled={logs?.total > 0 ? false : true}
                    >
                      <i className=' fas fa-cloud-download-alt mr-1' />
                      CSV
                    </Button>
                  </div>
                  <FilterDropDown menu={menu} toggleMenu={() => setMenu(!menu)}>
                    <div>
                      <AvForm
                        className='form-horizontal'
                        onValidSubmit={handleDateFilter}
                      >
                        <SimpleBar
                          style={{
                            maxHeight: '250px',
                          }}
                        >
                          <Row className='px-3  pt-3 pb-0'>
                            <Col md='12'>
                              <FormGroup>
                                <AvField
                                  name='startDate'
                                  type='date'
                                  className='form-control bg-light'
                                  id='startDate'
                                  value={date.start}
                                  helpMessage='Start Date'
                                  placeholder='Please choose the start date...'
                                  // required
                                />
                              </FormGroup>
                            </Col>
                            <Col md='12'>
                              <FormGroup>
                                <AvField
                                  name='endDate'
                                  type='date'
                                  className='form-control bg-light'
                                  id='endDate'
                                  value={date.end}
                                  helpMessage='End Date'
                                  placeholder='Please choose the end date...'
                                  // required
                                />
                              </FormGroup>
                            </Col>{' '}
                            <Col md='12'>
                              <FormGroup>
                                <AvField
                                  type='select'
                                  value={status}
                                  name='Status'
                                  helpMessage='Status'
                                >
                                  <option value=''>All</option>
                                  <option value='AVAILABLE'>AVAILABLE</option>
                                  <option value='OFFLINE'>OFFLINE</option>
                                  <option value='BUSY'>BUSY</option>
                                </AvField>
                              </FormGroup>
                            </Col>
                          </Row>
                        </SimpleBar>

                        <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                          <Link
                            to='#'
                            onClick={resetHandler}
                            className='btn btn-sm  bg-light font-size-14'
                          >
                            <i className=' mdi mdi-refresh mr-1'> Reset</i>
                          </Link>

                          <button
                            className='btn btn-success btn-sm mr-2'
                            type='submit'
                          >
                            <i className='mdi mdi-arrow-right-circle mr-1'></i>
                            Send
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </FilterDropDown>
                </div>
              </div>
              {!isLoading && data !== null ? (
                <>
                  <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div>
                      <Input
                        bsSize='sm'
                        id='take'
                        name='take'
                        type='select'
                        onChange={handleLimitChange}
                        value={take}
                      >
                        <option>{take}</option>
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>

                    <div className='d-flex align-items-center'>
                      <Input
                        bsSize='sm'
                        id='search'
                        value={search}
                        name='search'
                        onChange={SearchHandler}
                        placeholder='Search'
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField='keyId'
                    data={data.rows2}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses='table-responsive'
                    noDataIndication={'No Data to Display'}
                    defaultSorted={defaultSorted}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    // breakLabel={'...'}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={3}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={skipPage}
                    //Bootstrap styling
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* CSV DOWNLOAD MODAL */}
      <Modal
        isOpen={showCSVModal}
        toggle={() => setShowCSVModal(!showCSVModal)}
      >
        <ModalHeader toggle={() => setShowCSVModal(false)}>
          Generate CSV
        </ModalHeader>
        <ModalBody>
          <AvForm className='form-horizontal' onValidSubmit={handleCSV}>
            <Col>
              <FormGroup>
                <Label htmlFor='startRow'>Start (Row)</Label>
                <AvField
                  name='startRow'
                  type='number'
                  max={logs?.total - 1}
                  className='form-control'
                  id='startRow'
                  placeholder='Enter the row to start from'
                  errorMessage='value must be greater than 0 and less than total data'
                  min='1'
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor='endRow'>End (Row)</Label>
                <AvField
                  name='endRow'
                  type='number'
                  className='form-control'
                  min='1'
                  max={logs?.total}
                  id='endRow'
                  placeholder='Enter the row to end'
                  helpMessage={
                    logs?.total ? `Total data is ${logs?.total}` : null
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Button
              size='sm'
              color='primary'
              className='ml-3 waves-effect waves-light '
              type='submit'
            >
              {csv_loading ? 'Generating...' : 'Generate'}
            </Button>
            {csv && (
              <CSVLink
                data={csvData}
                filename={'DriverHours_Data.csv'}
                className='btn-sm btn-success ml-2'
                target='_blank'
                onClick={() => setShowCSVModal(false)}
              >
                Download
              </CSVLink>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(LogsTable);
