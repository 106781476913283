import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_ACTIVITIES,
  FETCH_EACH_ACTIVITIES,
  CREATE_ACTIVITIES,
  DELETE_ACTIVITIES,
  UPDATE_ACTIVITIES,
} from './actionTypes';
import {
  fetchActivitiesSuccessful,
  fetchActivitiesError,
  fetchEachActivitiesSuccessful,
  fetchEachActivitiesError,
  deleteActivitiesSuccessful,
  deleteActivitiesFailed,
  createActivitiesSuccessful,
  createActivitiesFailed,
  updateActivitiesSuccessful,
  updateActivitiesFailed,
} from './actions';

import {
  GetActivitiesService,
  GetEachActivitiesService,
  DeleteActivitiesService,
  CreateActivitiesService,
  UpdateActivitiesService,
} from '../../services/activitiesService';

function* fetchActivitiesHandler({ payload }) {
  try {
    const response = yield call(GetActivitiesService, payload);

    yield put(fetchActivitiesSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchActivitiesError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchActivitiesError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchActivitiesError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEachActivitiesCodeHandler({ payload }) {
  try {
    const response = yield call(GetEachActivitiesService, payload);
    yield put(fetchEachActivitiesSuccessful(response.data));
  } catch (error) {
    yield put(fetchEachActivitiesError(error?.response?.data?.message));
  }
}
function* deleteActivitiesHandler({ payload }) {
  try {
    const response = yield call(DeleteActivitiesService, payload);
    yield put(deleteActivitiesSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteActivitiesFailed(error?.response?.data?.error));
  }
}

function* createActivitiesHandler({ payload }) {
  try {
    const response = yield call(CreateActivitiesService, payload);
    yield put(createActivitiesSuccessful(response.data.result));
  } catch (error) {
    yield put(
      createActivitiesFailed(error?.response?.data?.error.error.message)
    );
  }
}

function* updateActivitiesHandler({ payload }) {
  try {
    const response = yield call(UpdateActivitiesService, payload);
    yield put(updateActivitiesSuccessful(response.data.result));
  } catch (error) {
    yield put(updateActivitiesFailed(error?.response?.data?.error));
  }
}

export function* watchFetchActivities() {
  yield takeEvery(FETCH_ACTIVITIES, fetchActivitiesHandler);
}
export function* watchFetchEachActivities() {
  yield takeEvery(FETCH_EACH_ACTIVITIES, fetchEachActivitiesCodeHandler);
}
export function* watchDeleteActivities() {
  yield takeEvery(DELETE_ACTIVITIES, deleteActivitiesHandler);
}
export function* watchCreateActivities() {
  yield takeEvery(CREATE_ACTIVITIES, createActivitiesHandler);
}
export function* watchUpdateActivities() {
  yield takeEvery(UPDATE_ACTIVITIES, updateActivitiesHandler);
}

function* ActivitiesSaga() {
  yield all([
    fork(watchFetchActivities),
    fork(watchFetchEachActivities),
    fork(watchDeleteActivities),
    fork(watchCreateActivities),
    fork(watchUpdateActivities),
  ]);
}

export default ActivitiesSaga;
