import React, { useState, useEffect } from 'react'
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
  DirectionsRenderer,
} from 'react-google-maps'
// import * as parkData from './data.json'
// import socket, { Socket } from '../../../services/socketService'
// import startPoint from '../../../assets/images/markers_icon/location.png'
import startPoint from '../../../../../assets/images/markers_icon/location.png'
import OffLinePin from '../../../../../assets/images/markers_icon/pin.png'
function Map({
  // startLocation,
  // endLocation,
  driverlogData,
  locationPermission,
}) {
  const [selectedPark, setSelectedPark] = useState(null)
  const [directions, setDirection] = useState([])
  const [isOpen, setIsOpen] = useState(true)
  const [activeMarker, setActiveMarker] = useState(null)

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return
    }
    setActiveMarker(marker)
  }
  // const [markerPosition, setMarker] = useState({
  //   lat: startLocation.lat,
  //   lng: startLocation.lng,
  // })

  console.log(directions)
  useEffect(() => {
    console.log(driverlogData)
    let result = []
    if (driverlogData) {
      driverlogData.result.forEach((data) => {
        if (!data.lat) {
        } else {
          let value = {
            status: data.status,
            lat: Number(data.lat),
            lng: Number(data.lng),
            id: data.id,
            name: `${data.firstName}-${data.lastName}`,
          }
          result.push(value)
        }
      })
    }
    setDirection(result)
  }, [driverlogData])

  // const DirectionHandler = () => {
  //   const DirectionsService = new window.google.maps.DirectionsService()

  //   DirectionsService.route(
  //     {
  //       origin: new window.google.maps.LatLng(
  //         startLocation.lat,
  //         startLocation.lng,
  //       ),
  //       destination: new window.google.maps.LatLng(
  //         endLocation.lat,
  //         endLocation.lng,
  //       ),
  //       travelMode: window.google.maps.TravelMode.DRIVING,
  //     },
  //     (result, status) => {
  //       if (status === window.google.maps.DirectionsStatus.OK) {
  //         setDirection(result)
  //       } else {
  //         console.error(`error fetching directions ${result}`)
  //       }
  //     },
  //   )
  // }

  const handleLocation = (e) => {
    if (locationPermission) {
      // Socket('driver:log', {
      //   token: JSON.parse(localStorage.getItem('authTokens')).accessToken,
      //   body: {
      //     lat: e.latLng?.lat(),
      //     lng: e.latLng?.lng(),
      //   },
      // })
      // setMarker({ lat: e.latLng?.lat(), lng: e.latLng?.lng() })
    }
  }
  return (
    <>
      {directions.length > 0 && (
        <GoogleMap
          defaultZoom={9}
          defaultCenter={{ lat: 9.0666664, lng: 7.4833314 }}
          // defaultCenter={{ lat: 41.8507300, lng: -87.6512600 }}
          onClick={(e) => handleLocation(e)}
          // defaultOptions={{ styles: mapStyles }}
        >
          {directions.map((data, index) => (
            <Marker
              key={index}
              position={data}
              // defaultLabel="Hello"
              // tile="Helloofnojnoknkjds"
              icon={{
                url: data.status === 'AVAILABLE' ? startPoint : OffLinePin,
                scaledSize: new window.google.maps.Size(25, 25),
              }}
              onClick={() => handleActiveMarker(data.id)}
            >
              {activeMarker === data.id ? (
                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                  <div>{data.name}</div>
                </InfoWindow>
              ) : null}
            </Marker>
          ))}
          {/* <Marker
            position={driverLocalion}
            icon={{
              url: startPoint,
              scaledSize: new window.google.maps.Size(25, 25),
            }}
          /> */}
          {/* <DirectionsRenderer
            directions={directions}
            hideRouteList={true}
            draggable={false}
            suppressMarkers={true}
          /> */}
        </GoogleMap>
      )}
    </>
  )
}

const MapWrapped = withScriptjs(withGoogleMap(Map))

export default function App({ driverlogData, locationPermission }) {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        // startLocation={startLocation}
        driverlogData={driverlogData}
        // driverLocalion={driverLocalion}
        locationPermission={locationPermission}
      />
    </div>
  )
}
