import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
  FormGroup,
  Button,
  Alert,
  Col,
  Nav,
  NavItem,
  Input,
  NavLink,
  UncontrolledTooltip,
} from 'reactstrap';
import FilterDropDown from '../../../../../components/Common/FilterDropDown';
import imgCar from '../../../../../assets/images/sample_car.png';
import SimpleBar from 'simplebar-react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactPaginate from 'react-paginate';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { QueryReallignment } from '../../../../../utils/general';
import { freezeAccountFailed } from '../../../../../store/actions';

import Loader from '../../../../../components/Common/Loading/index.js';

import Moment from 'moment';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { ScrollToTop } from '../../../../../utils/general';

let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const DriverTable = ({
  history,
  match,
  loading,
  users,
  fetchAllUser,
  fetchForCSVError,
  fetchUsersForCSV,
  csvData,
  csv_loading,
  updateUserProfile,
  freezeAccount,
  isAdmin,
  isRider,
  updateUserDocInfo,
  userError,
  tableError,
  deleteUser,
  freezeMessage,
  message,
  approveMessage,
  approveError,
  dateFilter,
  fetchUserExport,
  fetchUserExportFailed,
  exportFile,
  CheckAction,
  accessControl,
  ReadCheck,
  isSuperAdmin
}) => {
  const [data, setData] = useState(null);
  const [csv, setCsvData] = useState([]);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [showFreezeModal, setShowFreezeModal] = useState(false);
  const [activeTab, SetActiveTab] = useState('1');
  const [skipPage, setSkipPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [search, setSearch] = useState('');
  const [rating, setRating] = useState('');
  const [admin, setStatus] = useState('');
  const [urlUpdate, setUrlUpdate] = useState(false);
  const [menu, setMenu] = useState(false);
  const [gender, setGender] = useState('');
  const [userType] = useState('DRIVER');
  const [isActive, setActive] = useState('');
  const [isDeleted, setDeleted] = useState('');
  const [take, setLimit] = useState(10);
  const [AccountId, setAccountId] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [date, setDate] = useState({
    start: '',
    end: '',
  });

  const toggleTab = (tab) => {
    let pageInfo = {
      userType,
      skip: pageNumber,
      take: dataLimit,
      isActive: tab === '2' ? 'true' : tab === '3' ? 'false' : '',
      isDeleted: tab === '4' ? 'true' : '',
      search,
      rating,
      gender,
      admin,
      vehicle: 'BYKE'
    };
    setActive(`${tab === '2' ? 'true' : tab === '3' ? 'false' : ''}`);
    setDeleted(`${tab === '4' ? 'true' : ''}`);
    if (activeTab !== tab) {
      SetActiveTab(tab);
    }
    fetchAllUser(pageInfo);
    setUrlUpdate(true);
  };

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(users?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      userType,
      isActive,
      admin,
      isDeleted,
      search,
      skip: pageNumber,
      take: dataLimit,
      start: date.start,
      end: date.end,
      rating,
      gender,
      vehicle: 'BYKE'
    };
    fetchAllUser(pageInfo);
    setUrlUpdate(true);
  };

  const handlePageClick = async (data) => {
    skip = data?.selected;
    setPageNumber(parseInt(data?.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data?.selected + '0'));
    }
    let pageInfo = {
      skip,
      take,
      userType,
      isActive,
      admin,
      isDeleted,
      search,
      start: date.start,
      end: date.end,
      rating,
      gender,
      vehicle: 'BYKE'
    };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchAllUser(pageInfo);
    setUrlUpdate(true);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      userType,
      gender,
      isActive,
      admin,
      isDeleted,
      start: date.start,
      end: date.end,
      rating,
      take,
      skip,
      vehicle: 'BYKE'
    };
    // queryParams.skip = skip
    queryParams.take = take;
    fetchAllUser(queryParams);
    setUrlUpdate(true);
  };

  const freezeAccountModal = (id) => {
    setShowFreezeModal(true);
    setAccountId(id);
  };

  const handleSubmit = (e, values) => {
    const formData = {
      ...values,
      overwrite: true,
    };
    formData.durationInHours = Number(values.durationInHours);
    // setShowFreezeModal(false);
    freezeAccount(AccountId, formData);
  };
  const handleCSV = (e, values) => {
    fetchUsersForCSV({
      userType,
      isActive,
      admin,
      isDeleted,
      search,
      rating,
      gender,
      take: values.endRow ? values.endRow - values.startRow + 1 : users?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
      vehicle: 'BYKE'
    });
  };
  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    dateFilter({ start: values.startDate, end: values.endDate });
    setMenu(false);
    setPageNumber(0);
    setStatus(values.admin);
    setGender(values.gender);
    setRating(values.rating);
    let queryParams = {
      userType,
      search,
      take,
      isActive,
      skip,
      gender: values.gender,
      admin: values.admin,
      start: values.startDate,
      end: values.endDate,
      rating: values.rating,
      vehicle: 'BYKE'
    };
    fetchAllUser(queryParams);
    setUrlUpdate(true);
  };

  const resetHandler = () => {
    setMenu(false);
    setSearch('');
    setStatus('');
    setGender('');
    setRating('');
    setDate({
      start: '',
      end: '',
    });
    dateFilter({});
    setGender('');
    let queryParams = {
      userType,
    };
    fetchAllUser(queryParams);
    setUrlUpdate(true);
  };

  useEffect(() => {
    if (users) {
      // update Page Url
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(users?.total / take));
    }
  }, [users]);

  useEffect(() => {
    if (csvData) {
      // SET CSV EXPORT DATA
      const csv = csvData?.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Full_Name: `${data?.firstName} - ${data?.lastName}`,
          Display_Name: data?.displayName ? data?.displayName : '----',
          Rating: data?.rating ? data?.rating?.toFixed(2) : 0,
          Raters: data?.raters,
          Gender: data?.gender ? data?.gender : '----',
          DOB: data?.dob?.substring(0, 10)
            ? data?.dob?.substring(0, 10)
            : '----',
          Registration_Date: data?.createdAt.substring(0, 10),
          Email: data?.email ? data?.email : '----',
          UserType: data?.userType,
          Phone_no: data?.phone ? data?.phone : '----',
          Referrer: data?.referrer ? data?.referrer : '----',
          Trips: data?.trips ? data?.trips : '----',
          Loyalty_Tier: data?.loyalty?.tier ? data?.loyalty?.tier : '----',
          Balance: `₦${data?.account.availableBalance}`,
          Registration_Status: data?.isActive ? 'ACTIVE' : 'INACTIVE',
          Account_Status: data?.account?.status,
        };

        return formData;
      });

      setCsvData(csv);
    }
  }, [csvData]);

  useEffect(() => {
    if (freezeMessage) {
      setShowFreezeModal(false);
      setAccountId(null);
    }
    if (message || approveMessage) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          userType,
          isActive,
          admin,
          isDeleted,
          start: date.start,
          end: date.end,
          gender,
          rating,
          vehicle: 'BYKE'
        };
        fetchAllUser(queryParams);
      }, 2000);
    }
  }, [freezeMessage, message, approveMessage]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    parsedUrlParams.userType = userType;
    fetchAllUser(parsedUrlParams);
  }, [fetchAllUser]);

  useEffect(() => {
    if (!showCSVModal) {
      fetchForCSVError();
    }
  }, [showCSVModal]);

  useEffect(() => {
    if (urlUpdate) {
      SetUrlWithParams();
    }
  }, [urlUpdate]);

  useEffect(() => {
    if (exportFile) {
      const link = document.createElement('a');
      link.href = `data:text/csv;charset=utf-8,${escape(exportFile)}`;
      link.download = 'all_drivers.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      fetchUserExportFailed('');
    }
  }, [exportFile]);

  const SetUrlWithParams = () => {
    let queryParams = {
      skip: pageNumber,
      take,
      search,
      gender,
      isActive,
      isDeleted,
      start: date.start,
      end: date.end,
      rating,
      vehicle: 'BYKE'
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        start: date.start,
        end: date.end,
        skip,
        take,
        gender,
        rating,
        vehicle: 'BYKE'
      };
      return params;
    }
    // update State with url param if available
    if (parsedUrlParams?.isActive === 'true') {
      SetActiveTab('2');
      setActive('true');
    } else if (parsedUrlParams?.isActive === 'false') {
      SetActiveTab('3');
      setActive('false');
    }
    if (parsedUrlParams?.isDeleted === 'true') {
      SetActiveTab('4');
      setDeleted('true');
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    if (parsedUrlParams?.rating) {
      setRating(parsedUrlParams?.rating);
    }
    return parsedUrlParams;
  };
  let accessChecking = ReadCheck(accessControl, `Captains`, 'Byke Captains');
  const tableDataHandler = () => {
    const rowData = users?.result?.map((data, i) => {
      let vehicle = data?.vehicles?.find(
        (vehicle) => vehicle.isDeleted === false
      );
      return {
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,
        keyId: data?.id,
        id: (

          <>
            {accessChecking ?
              <Link
                to={`/user/${data?.id}`}
                className='mr-3 text-dark font-weight-bold'
              >
                {data?.id?.substring(0, 7)}
                {data?.device && (
                  <div>
                    <span className={`badge badge-soft-primary font-size-10`}>
                      {data?.device?.slice(0, 12)}...
                    </span>{' '}
                  </div>
                )}
              </Link>
              :
              <div className='mr-3 text-dark font-weight-bold'>
                {data?.id?.substring(0, 7)}
                {data?.device && (
                  <div>
                    <span className={`badge badge-soft-primary font-size-10`}>
                      {data?.device?.slice(0, 12)}...
                    </span>{' '}
                  </div>
                )}
              </div>
            }
          </>
        ),
        vehicle: vehicle ? (
          <div
            className='text-truncate'
            style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}
          >

            <>
              {accessChecking ?
                <Link
                  to={`/user/${data?.id}?tab=4`}
                  // onClick={() => previewAction(vehicle?.id)}
                  className='text-dark font-weight-bold'
                >
                  <Row className='align-items-center'>
                    <Col className='mb-2 px-0 py-0 text-center' sm={3}>
                      <img
                        className='rounded avatar-sm border shadow-xs p-2'
                        alt='Profile'
                        src={vehicle?.imageFront ? vehicle?.imageFront : imgCar}
                      />
                    </Col>
                    <Col sm={9}>
                      <span className='mt-1 mb-1'>
                        <h4 className='font-weight-bold font-size-12 text-sm-left text-center mb-0'>
                          {vehicle?.make}{' '}
                          <span>
                            <span
                              className={`font-size-10 badge badge-${vehicle?.color?.toLowerCase() === 'red'
                                ? 'danger'
                                : vehicle?.color?.toLowerCase() === 'blue'
                                  ? 'primary'
                                  : vehicle?.color?.toLowerCase() === 'black'
                                    ? 'dark text-white'
                                    : vehicle?.color?.toLowerCase() === 'gold'
                                      ? 'warning'
                                      : vehicle?.color?.toLowerCase() === 'green'
                                        ? 'success'
                                        : vehicle?.color?.toLowerCase() === 'grey'
                                          ? 'secondary text-white'
                                          : 'soft-secondary text-dark'
                                } text-white rounded text-capitalize`}
                            >
                              {' '}
                              {vehicle?.color}{' '}
                            </span>
                          </span>
                        </h4>
                      </span>
                      <span className='text-muted mb-0 font-size-11 text-sm-left text-center'>
                        Model: {vehicle?.model}
                      </span>
                      <p className='text-muted text-sm-left text-center font-size-11 mb-0'>
                        {vehicle?.plateNumber} | {vehicle?.year}
                      </p>
                    </Col>
                  </Row>
                </Link>
                :
                <div className='text-dark font-weight-bold'>
                  <Row className='align-items-center'>
                    <Col className='mb-2 px-0 py-0 text-center' sm={3}>
                      <img
                        className='rounded avatar-sm border shadow-xs p-2'
                        alt='Profile'
                        src={vehicle?.imageFront ? vehicle?.imageFront : imgCar}
                      />
                    </Col>
                    <Col sm={9}>
                      <span className='mt-1 mb-1'>
                        <h4 className='font-weight-bold font-size-12 text-sm-left text-center mb-0'>
                          {vehicle?.make}{' '}
                          <span>
                            <span
                              className={`font-size-10 badge badge-${vehicle?.color?.toLowerCase() === 'red'
                                ? 'danger'
                                : vehicle?.color?.toLowerCase() === 'blue'
                                  ? 'primary'
                                  : vehicle?.color?.toLowerCase() === 'black'
                                    ? 'dark text-white'
                                    : vehicle?.color?.toLowerCase() === 'gold'
                                      ? 'warning'
                                      : vehicle?.color?.toLowerCase() === 'green'
                                        ? 'success'
                                        : vehicle?.color?.toLowerCase() === 'grey'
                                          ? 'secondary text-white'
                                          : 'soft-secondary text-dark'
                                } text-white rounded text-capitalize`}
                            >
                              {' '}
                              {vehicle?.color}{' '}
                            </span>
                          </span>
                        </h4>
                      </span>
                      <span className='text-muted mb-0 font-size-11 text-sm-left text-center'>
                        Model: {vehicle?.model}
                      </span>
                      <p className='text-muted text-sm-left text-center font-size-11 mb-0'>
                        {vehicle?.plateNumber} | {vehicle?.year}
                      </p>
                    </Col>
                  </Row>
                </div>
              }
            </>
          </div>
        ) : (
          <div className='text-center'>No vehicle</div>
        ),
        fullName: (
          <div
            className='text-truncate'
            style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}
          >

            <>
              {accessChecking ?
                <Link
                  to={`/user/${data?.id}`}
                  className='text-dark font-weight-bold'
                >
                  <div className='text-capitalize'>
                    {data?.firstName} {data?.lastName}
                  </div>
                  <div className='d-flex align-items-center'>
                    <img
                      src={data?.picture ? data?.picture : avatarImg}
                      alt=''
                      height='20'
                      className='rounded-circle avatar-xs mr-2'
                    />

                    <div>
                      <span className='text-muted mr-2 badge badge-soft-secondary'>
                        <i className=' fas fa-star text-warning mr-1' />
                        {data?.rating ? data?.rating?.toFixed(2) : 0}
                      </span>
                      {data?.gender && (
                        <span
                          className={`text-white mr-2 badge badge-${data?.gender === 'MALE' ? 'primary' : 'danger'
                            }`}
                        >
                          <i
                            className={`ri-${data?.gender === 'MALE' ? 'men' : 'women'
                              }-line mr-1`}
                          />
                          {data?.gender}
                        </span>
                      )}
                    </div>
                  </div>
                </Link>
                :
                <div className='text-dark font-weight-bold'>
                  <div className='text-capitalize'>
                    {data?.firstName} {data?.lastName}
                  </div>
                  <div className='d-flex align-items-center'>
                    <img
                      src={data?.picture ? data?.picture : avatarImg}
                      alt=''
                      height='20'
                      className='rounded-circle avatar-xs mr-2'
                    />

                    <div>
                      <span className='text-muted mr-2 badge badge-soft-secondary'>
                        <i className=' fas fa-star text-warning mr-1' />
                        {data?.rating ? data?.rating?.toFixed(2) : 0}
                      </span>
                      {data?.gender && (
                        <span
                          className={`text-white mr-2 badge badge-${data?.gender === 'MALE' ? 'primary' : 'danger'
                            }`}
                        >
                          <i
                            className={`ri-${data?.gender === 'MALE' ? 'men' : 'women'
                              }-line mr-1`}
                          />
                          {data?.gender}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              }
            </>
          </div>
        ),
        date: (
          <div>
            {' '}
            <div>{Moment(data?.createdAt).format('DD/MM/YYYY')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data?.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
        deleteDate: (
          <div>
            {' '}
            <div>{Moment(data?.updatedAt).format('DD/MM/YYYY')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data?.updatedAt).format('LT')}
            </div>
          </div>
        ),
        email: data?.email ? data?.email : '----',
        userType: (
          <div>
            <div>{data?.userType}</div>
            {data?.gender && (
              <span
                className={`text-white mr-2 badge badge-${data?.gender === 'MALE' ? 'primary' : 'danger'
                  }`}
              >
                <i className=' fas fa-star text-warning mr-1' />
                {data?.gender}
              </span>
            )}
          </div>
        ),
        gender: data?.gender ? data?.gender : '---',
        phone_no: data?.phone ? data?.phone : '----',
        status: (
          <>
            <span
              className={`badge badge-soft-${data?.isActive ? 'success' : 'danger'
                } font-size-12`}
            >
              {data?.isActive ? 'Active' : 'InActive'}
            </span>
            {data?.account?.status === 'SANCTION' && (
              <>
                { } / { }
                <span className={`badge badge-soft-warning font-size-12`}>
                  {data?.account?.status}
                </span>
              </>
            )}
          </>
        ),
        deleteStatus: (
          <>
            <span className={`badge badge-soft-secondary font-size-12`}>
              {data?.isActive ? 'Active' : 'InActive'}
            </span>
            {data?.account?.status === 'SANCTION' && (
              <>
                { } / { }
                <span className={`badge badge-soft-warning font-size-12`}>
                  {data?.account?.status}
                </span>
              </>
            )}
          </>
        ),
        Document: (
          <Link
            to={`/user/${data?.id}?tab=3`}
            // onClick={() =>
            //   updateUserDocInfo(data?.id, `${data?.firstName} ${data?.lastName}`)
            // }
            className='text-dark d-flex flex-column align-items-start'
          >
            <i className='mdi mdi-file-document-outline mdi-24px'></i>
            {data?.checks?.document && (
              <>
                <span className={`badge badge-soft-secondary font-size-12`}>
                  Uploaded
                </span>
              </>
            )}
          </Link>
        ),
        action: (
          <div className='d-flex align-items-center'>
            <CheckAction field='Captains' subModule='Byke Captains' iconType='Delete'>
              <Link
                to='#'
                onClick={() => deleteUser(data)}
                className='text-danger'
                style={
                  data?.isDeleted
                    ? { pointerEvents: 'none', opacity: '0.5' }
                    : null
                }
                id='delete1'
              >
                <i className='mdi mdi-trash-can font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='delete1'>
                Delete
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field='Captains' subModule='Byke Captains' iconType='Edit'>
              <Link
                to='#'
                onClick={() => freezeAccountModal(data?.id)}
                className='text-secondary'
                style={
                  data?.isDeleted
                    ? { pointerEvents: 'none', opacity: '0.5' }
                    : null
                }
                id='freeze_account'
              >
                <i className='ri-admin-line ml-3 mr-3 font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='freeze_account'>
                Freeze Account
              </UncontrolledTooltip>

              <Link
                to='#'
                className='mr-3 text-success'
                id='approve'
                style={
                  !data?.checks || data?.checks?.admin
                    ? { pointerEvents: 'none', opacity: '0.5' }
                    : null
                }
                onClick={() =>
                  updateUserProfile({ approve: 'approveDriver' }, data?.id)
                }
              >
                <i className='fas fa-check font-size-16 mb-2'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='approve'>
                Approve
              </UncontrolledTooltip>
            </CheckAction>
          </div>
        ),
      };
    });
    if (!isDeleted) {
      return {
        columns: [
          {
            text: 'S/N',
            dataField: 'index',
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              if (order === 'asc') {
                return b - a;
              }
              return a - b;
            },
          },
          {
            text: 'User Id',
            dataField: 'id',
          },
          {
            text: 'Full Name',
            dataField: 'fullName',
          },

          {
            text: 'Registeration Date',
            dataField: 'date',
          },
          {
            text: 'Phone No',
            dataField: 'phone_no',
          },
          {
            text: 'Status',
            dataField: 'status',
          },
          // {
          //   text: 'Document',
          //   dataField: 'Document',
          //   classes: 'text-center',
          // },
          {
            text: 'Action',
            dataField: 'action',
          },
        ],
        rows: rowData,
      };
    } else {
      return {
        columns: [
          {
            text: 'S/N',
            dataField: 'index',
            sort: true,
            sortFunc: (a, b, order, dataField) => {
              if (order === 'asc') {
                return b - a;
              }
              return a - b;
            },
          },
          {
            text: 'User Id',
            dataField: 'id',
          },
          {
            text: 'Full Name',
            dataField: 'fullName',
          },
          {
            text: 'Registeration Date',
            dataField: 'date',
          },
          {
            text: 'Deletion Date',
            dataField: 'deleteDate',
          },
          {
            text: 'Phone No',
            dataField: 'phone_no',
          },
          {
            text: 'Status',
            dataField: 'deleteStatus',
          },
          // {
          //   text: 'Document',
          //   dataField: 'Document',
          //   classes: 'text-center',
          // },
        ],
        rows: rowData,
      };
    }
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-0'>
              <div className='d-flex mb-2 justify-content-between align-items-center'>
                <Nav tabs className='nav-tabs-custom'>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab('1');
                      }}
                      className={classnames(
                        { active: activeTab === '1' },
                        'font-weight-bold p-3'
                      )}
                    >
                      All Users
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab('2');
                      }}
                      className={classnames(
                        { active: activeTab === '2' },
                        'p-3 font-weight-bold'
                      )}
                    >
                      Active
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab('3');
                      }}
                      className={classnames(
                        { active: activeTab === '3' },
                        ' p-3 font-weight-bold'
                      )}
                    >
                      inActive
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab('4');
                      }}
                      className={classnames(
                        { active: activeTab === '4' },
                        ' p-3 font-weight-bold'
                      )}
                    >
                      Deleted
                    </NavLink>
                  </NavItem>
                </Nav>
                <div className='d-flex'>
                  <Button
                    size='sm'
                    color='success'
                    onClick={() => fetchUserExport({ userType })}
                  >
                    Export All
                    <i className='ml-1 font-size-10 fas fa-share-alt'></i>
                  </Button>
                  <CheckAction field='Captains' subModule='Byke Captains' iconType={'Create'}>
                    {isSuperAdmin &&
                      <Link to={`/create_user?userType=DRIVER`}>
                        <Button color='info' className='ml-2 font-size-12' size='sm'>
                          Create Driver
                        </Button>
                      </Link>
                    }
                  </CheckAction>
                </div>
              </div>
              {tableError !== null ? (
                <Alert color='danger' className='text-center'>
                  {tableError}
                </Alert>
              ) : (
                <>
                  {!isLoading && data !== null ? (
                    <div>
                      {approveMessage !== null && (
                        <Alert color='success' className='text-center'>
                          {approveMessage}
                        </Alert>
                      )}
                      {approveError !== null && (
                        <Alert color='danger' className='text-center'>
                          {approveError ===
                            'an error occurred while processing your request'
                            ? 'Driver is not active'
                            : approveError}
                        </Alert>
                      )}
                      <div className='d-flex justify-content-end  align-items-center mb-2'>
                        <div className=''>
                          <Button
                            size='sm'
                            color='primary'
                            className='mt-1'
                            onClick={() => setShowCSVModal(true)}
                            disabled={users?.total > 0 ? false : true}
                          >
                            <i className=' fas fa-cloud-download-alt mr-1' />
                            CSV
                          </Button>
                        </div>
                        <div>
                          <FilterDropDown
                            menu={menu}
                            toggleMenu={() => setMenu(!menu)}
                          >
                            <div>
                              <AvForm
                                className='form-horizontal'
                                onValidSubmit={handleDateFilter}
                              >
                                <SimpleBar
                                  style={{
                                    maxHeight: '250px',
                                  }}
                                >
                                  <Row className='px-3  pt-3 pb-0'>
                                    <Col md='12'>
                                      <FormGroup>
                                        <AvField
                                          name='startDate'
                                          type='date'
                                          className='form-control bg-light'
                                          id='startDate'
                                          value={date.start}
                                          helpMessage='Start Date'
                                          placeholder='Please choose the start date...'
                                        // required
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md='12'>
                                      <FormGroup>
                                        <AvField
                                          name='endDate'
                                          type='date'
                                          className='form-control bg-light'
                                          id='endDate'
                                          value={date.end}
                                          helpMessage='End Date'
                                          placeholder='Please choose the end date...'
                                        // required
                                        />
                                      </FormGroup>
                                    </Col>{' '}
                                    <Col md='12'>
                                      <FormGroup>
                                        <AvField
                                          type='select'
                                          value={rating}
                                          name='rating'
                                          helpMessage='User Rating'
                                        >
                                          <option value=''>All</option>
                                          <option value='1'>1</option>
                                          <option value='2'>2</option>
                                          <option value='3'>3</option>
                                          <option value='4'>4</option>
                                          <option value='5'>5</option>
                                        </AvField>
                                      </FormGroup>
                                    </Col>
                                    <Col md='12'>
                                      <FormGroup>
                                        <AvField
                                          type='select'
                                          value={admin}
                                          name='admin'
                                          helpMessage='APPROVAL'
                                        >
                                          <option value=''>All</option>
                                          <option value='true'>Approved</option>
                                          <option value='false'>
                                            Not Approved
                                          </option>
                                        </AvField>
                                      </FormGroup>
                                    </Col>
                                    <Col md='12'>
                                      <FormGroup>
                                        <AvField
                                          type='select'
                                          value={gender}
                                          name='gender'
                                          helpMessage='gender'
                                        >
                                          <option value=''>All</option>
                                          <option value='MALE'>MALE</option>
                                          <option value='FEMALE'>FEMALE</option>
                                        </AvField>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </SimpleBar>

                                <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                                  <Link
                                    to='#'
                                    onClick={resetHandler}
                                    className='btn btn-sm  bg-light font-size-14'
                                  >
                                    <i className=' mdi mdi-refresh mr-1'>
                                      {' '}
                                      Reset
                                    </i>
                                  </Link>

                                  <button
                                    className='btn btn-success btn-sm mr-2'
                                    type='submit'
                                  >
                                    <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                    Send
                                  </button>
                                </div>
                              </AvForm>
                            </div>
                          </FilterDropDown>
                        </div>
                      </div>

                      <div>
                        <div className='d-flex justify-content-between mb-2'>
                          <div>
                            <Input
                              bsSize='sm'
                              id='take'
                              value={take}
                              name='take'
                              type='select'
                              onChange={handleLimitChange}
                            >
                              <option>10</option>
                              <option>25</option>
                              <option>50</option>
                            </Input>
                          </div>
                          <div>
                            <Input
                              bsSize='sm'
                              id='search'
                              name='search'
                              value={search}
                              onChange={SearchHandler}
                              placeholder='Search'
                            />
                          </div>
                        </div>
                        <BootstrapTable
                          keyField='keyId'
                          data={data?.rows}
                          columns={data?.columns}
                          striped
                          hover
                          wrapperClasses='table-responsive'
                          noDataIndication={'No Data to Display'}
                        />
                        <ReactPaginate
                          previousLabel={'Previous'}
                          nextLabel={'Next'}
                          pageCount={pageCount}
                          forcePage={skipPage}
                          onPageChange={handlePageClick}
                          containerClassName={
                            'pagination justify-content-center'
                          }
                          pageClassName={'page-item'}
                          pageLinkClassName={'page-link'}
                          previousClassName={'page-item'}
                          previousLinkClassName={'page-link'}
                          nextClassName={'page-item'}
                          nextLinkClassName={'page-link'}
                          breakClassName={'page-item'}
                          breakLinkClassName={'page-link'}
                          activeClassName={'active'}
                        />
                      </div>
                    </div>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* FREEZE ACCOUNT MODAL */}
      <Modal
        isOpen={showFreezeModal}
        toggle={() => setShowFreezeModal(!showFreezeModal)}
      >
        <ModalBody>
          <h4 className='card-title mb-4 text-center'>
            Enter the below information to freeze account
          </h4>
          <AvForm className='form-horizontal' onValidSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor='durationInHours'>Freeze Duration in Hr</Label>
              <AvField
                name='durationInHours'
                type='number'
                className='form-control'
                id='durationInHours'
                placeholder='Enter freeze duration In Hours'
                min='1'
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='reason'>Reason</Label>
              <AvField
                name='reason'
                type='text'
                className='form-control'
                id='reason'
                placeholder='Enter Reason'
                required
              />
            </FormGroup>
            <div className='mt-4 text-center'>
              <Button
                color='primary'
                className='w-100 waves-effect waves-light'
                type='submit'
              >
                {loading ? 'Loading...' : 'Login'}
              </Button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
      {/* CSV DOWNLOAD MODAL */}
      <Modal
        isOpen={showCSVModal}
        toggle={() => setShowCSVModal(!showCSVModal)}
      >
        <ModalHeader toggle={() => setShowCSVModal(false)}>
          Generate CSV
        </ModalHeader>
        <ModalBody>
          <AvForm className='form-horizontal' onValidSubmit={handleCSV}>
            <Col>
              <FormGroup>
                <Label htmlFor='startRow'>Start (Row)</Label>
                <AvField
                  name='startRow'
                  type='number'
                  max={users?.total - 1}
                  className='form-control'
                  id='startRow'
                  placeholder='Enter the row to start from'
                  errorMessage='value must be greater than 0 and less than total data'
                  min='1'
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor='endRow'>End (Row)</Label>
                <AvField
                  name='endRow'
                  type='number'
                  className='form-control'
                  min='1'
                  max={users?.total}
                  id='endRow'
                  placeholder='Enter the row to end'
                  helpMessage={
                    users?.total ? `Total data is ${users?.total}` : null
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Button
              size='sm'
              color='primary'
              className='ml-3 waves-effect waves-light '
              type='submit'
            >
              {csv_loading ? 'Generating...' : 'Generate'}
            </Button>
            {csvData && (
              <CSVLink
                data={csv}
                filename={'drivers_data.csv'}
                className='btn-sm btn-success ml-2'
                target='_blank'
                onClick={() => setShowCSVModal(false)}
              >
                Download
              </CSVLink>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { csvData, userError, freezeMessage, users, tableError } = state.Users;
  return { csvData, userError, freezeMessage, users, tableError };
};

export default connect(mapStateToProps, {
  freezeAccountFailed,
})(withRouter(DriverTable));
