import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Media,
} from 'reactstrap';
import SimpleBar from 'simplebar-react';
import Moment from 'moment';

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }
  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className='d-inline-block'
        >
          <DropdownToggle
            tag='button'
            className='btn header-item noti-icon waves-effect'
            id='page-header-notifications-dropdown'
          >
            <i className='ri-notification-3-line'></i>
            <span className='noti-dot'></span>
          </DropdownToggle>
          <DropdownMenu
            right
            className='dropdown-menu-lg p-0'
            aria-labelledby='page-header-notifications-dropdown'
          >
            <div className='px-3 pb-0 pt-3 border-bottom'>
              <Row className='align-items-center'>
                <Col>
                  <h6 className='m-0'> Notifications </h6>
                  <p className='text-muted font-sie-8 mb-0'>
                    5 Latest Notification
                  </p>
                </Col>
                <div className='col-auto'>
                  {/* <Link to="notification" className="small">
                    {' '}
                    View All
                  </Link> */}
                </div>
              </Row>
            </div>
            <SimpleBar style={{ maxHeight: '230px' }}>
              {this.props.myNotifications.result.length > 0 &&
                this.props.myNotifications.result.map((notification) => (
                  <Link
                    to='#'
                    className='text-reset notification-item'
                    key={notification.id}
                  >
                    <Media>
                      <Media body>
                        <h6 className='mt-0 mb-1 text-capitalize'>
                          {notification.title}
                        </h6>
                        <div className='font-size-12 text-muted'>
                          <p className='mb-1'>{notification.text}</p>
                          <p className='mb-0'>
                            <i className='mdi mdi-clock-outline'></i>{' '}
                            {Moment(notification.updatedAt).fromNow()}
                          </p>
                        </div>
                      </Media>
                    </Media>
                  </Link>
                ))}
            </SimpleBar>
            <div className='p-2 border-top'>
              <Link
                to='/notification'
                className='btn btn-sm btn-link font-size-14 btn-block text-center'
              >
                <i className='mdi mdi-arrow-right-circle mr-1'></i>
                View More
              </Link>
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}
export default NotificationDropdown;
