import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { Row, Container, Modal, ModalHeader, ModalBody } from 'reactstrap';
//Import Breadcrumb
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import MiniWidgets from '../Components/MiniWidget';
import GreySummaryCard from '../../../../components/Common/Summary/index';
import MyComplaintTable from '../table';
import { myTableHeader } from '../data';
import {
  fetchMyComplaint,
  assignComplaint,
  updateComplaint,
  assignComplaintSuccessful,
} from '../../../../store/actions';
import PreviewComplaint from '../Components/previewComplaint';

const breadcrumbItems = [
  { title: 'Dashboard', link: '#' },
  { title: 'Driver', link: '#' },
];

const MyComplaints = ({ loading, complaint, message, fetchMyComplaint }) => {
  const [showModal, setShowModal] = useState(false);
  const [complaintId, setComplaintId] = useState(null);

  useEffect(() => {
    fetchMyComplaint();
  }, []);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setComplaintId(null);
        fetchMyComplaint();
      }, 1000);
    }
  }, [message]);

  const previewHandler = (id) => {
    setShowModal(true);
    setComplaintId(id);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='My Complaint' breadcrumbItems={breadcrumbItems} />

          <GreySummaryCard field='Complaints' subModule='My Compliant'>
            <Row>
              <MiniWidgets />
            </Row>
            <MyComplaintTable
              complaint={complaint}
              field={'Complaints'}
              subModule={'My Compliant'}

              loading={loading}
              fetchComplaint={fetchMyComplaint}
              previewAction={previewHandler}

              columns={myTableHeader}
            />
          </GreySummaryCard>
        </Container>

        {/* Preview Modal */}
        <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
          <ModalHeader toggle={() => setShowModal(false)}>
            Preview Complaint
          </ModalHeader>
          <ModalBody>
            <PreviewComplaint previewId={complaintId} />
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  const { loading, complaint, message, complaintError } = state.Complaint;
  return { loading, complaint, message, complaintError };
};

export default connect(mapStateToProps, {
  fetchMyComplaint,
  assignComplaint,
  updateComplaint,
  assignComplaintSuccessful,
})(MyComplaints);
