import React, { useState } from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';

import { AvForm, AvField } from 'availity-reactstrap-validation';

const ConfigForm = ({ updateHandler, defaultData }) => {
  const [Status] = useState(defaultData.status);
  const [description, setDescription] = useState(defaultData.description);

  const handleSubmit = (event, values) => {
    const packageData = { ...defaultData };
    packageData.module = values.module;
    packageData.description = description;
    packageData.status = values.Status;
    updateHandler(packageData, defaultData.id);
  };

  return (
    <div>
      <div>
        <div>
          <AvForm
            className='form-horizontal'
            onValidSubmit={handleSubmit}
            model={defaultData}
          >
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <FormGroup className=' mb-4'>
                      <Label htmlFor='provider'>Configuration Name</Label>
                      <AvField
                        name='module'
                        type='text'
                        className='form-control bg-light'
                        id='name'
                        placeholder='Enter App Name ...'
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className=' mb-4'>
                      <AvField
                        className='form-control bg-light'
                        type='select'
                        name='Status'
                        label='Status'
                        value={Status}
                        required
                      >
                        <option>INACTIVE</option>
                        <option>ACTIVE</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label htmlFor='description'>
                        Configuration Description
                      </Label>
                      <textarea
                        className='form-control'
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        id='description'
                        rows='5'
                      ></textarea>
                    </FormGroup>
                  </Col>
                </Row>
                <div className='text-center'>
                  <button className='btn btn-success px-4' type='submit'>
                    Update
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </div>
      </div>
    </div>
  );
};

export default ConfigForm;
