import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Row, Col, Input, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SimpleBar from 'simplebar-react';
import BootstrapTable from 'react-bootstrap-table-next';
import FilterDropDown from '../FilterDropDown';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import ReactPaginate from 'react-paginate';
import Moment from 'moment';
import Loader from '../Loading/index.js';
import { Link } from 'react-router-dom';
import { ScrollToTop } from '../../../utils/general';
import { fetchRideDeclineLog } from '../../../store/actions';

let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const DeclineLogTable = ({
  rideDeclineLog,
  fetchRideDeclineLog,
  user,
  loading,
}) => {
  const [data, setData] = useState(null);
  // const [refreshTimer, setRefreshTimer] = useState(0);
  const [date, setDate] = useState({
    start: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });
  const [menu, setMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(loading);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    if (user && user.userType !== 'ADMIN') {
      let query = {
        skip: pageNumber,
        take: dataLimit,
        // start: date.start,
        // end: date.end,
      };
      fetchRideDeclineLog({ id: user.id, query });
    }
  }, [fetchRideDeclineLog, user]);

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(rideDeclineLog?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let query = {
      skip: pageNumber,
      take: dataLimit,
      // start: date.start,
      // end: date.end,
    };
    fetchRideDeclineLog({ id: user.id, query });
  };

  const handlePageClick = async (data) => {
    skip = data?.selected;
    setPageNumber(parseInt(data?.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data?.selected + '0'));
    }

    let query = {
      skip,
      take,
      // start: date.start,
      // end: date.end,
    };
    fetchRideDeclineLog({ id: user.id, query });
    ScrollToTop();
  };
  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    setMenu(false);
    let query = {
      start: values.startDate,
      end: values.endDate,
    };
    fetchRideDeclineLog({ id: user.id, query });
  };
  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    });
    let query = {
      start: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    };
    fetchRideDeclineLog({ id: user.id, query });
  };
  useEffect(() => {
    if (rideDeclineLog) {
      setData(tableDataHandler());
      setIsLoading(loading);
      setPageCount(Math.ceil(rideDeclineLog?.total / take));
    }
  }, [rideDeclineLog, take]);

  const tableDataHandler = () => {
    const rowData = rideDeclineLog?.result?.map((data) => {
      return {
        keyId: data?.id,
        id: (
          <Link
            to={`/ride/${data?.rideId}`}
            className='mr-3 text-dark font-weight-bold'
          >
            {data?.rideId.substring(0, 7)}
          </Link>
        ),
        userName: (
          <>
            {user.userType === 'DRIVER' ? (
              <div className='mr-3 text-dark'>
                <div className='text-capitalize'>
                  {data?.rider?.firstName} {data?.rider?.lastName}
                </div>
                <div className='d-flex align-items-center'>
                  <div className='mr-2'>
                    <img
                      src={
                        data?.rider?.picture ? data?.rider?.picture : avatarImg
                      }
                      alt=''
                      height='20'
                      className='rounded-circle avatar-xs'
                    />
                  </div>
                  <div>
                    <span className='text-muted ml-2 badge badge-soft-secondary'>
                      <i className=' fas fa-star text-warning mr-1' />
                      {data?.rider?.rating.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className='mr-3 text-dark'>
                <div className='text-capitalize'>
                  {data?.driver?.firstName} {data?.driver?.lastName}
                </div>
                <div className='d-flex align-items-center'>
                  <div className='mr-2'>
                    <img
                      src={
                        data?.driver?.picture
                          ? data?.driver?.picture
                          : avatarImg
                      }
                      alt=''
                      height='20'
                      className='rounded-circle avatar-xs'
                    />
                  </div>
                  <div>
                    <span className='text-muted ml-2 badge badge-soft-secondary'>
                      <i className=' fas fa-star text-warning mr-1' />
                      {data?.driver?.rating.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        ),
        type: <div className='text-capitalize'>{data?.type}</div>,

        date: (
          <div>
            {' '}
            <div>{Moment(data?.createdAt).format('l')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data?.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'ID',
          dataField: 'id',
        },
        {
          text: `${user.userType === 'DRIVER' ? 'RIDER' : 'DRIVER'}`,
          dataField: 'userName',
        },
        {
          text: 'Type',
          dataField: 'type',
          classes: 'text-center',
          headerStyle: { textAlign: 'center' },
        },
        {
          text: 'Date',
          dataField: 'date',
        },
      ],
      rows: rowData,
    };
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-0'>
              {!isLoading && data !== null ? (
                <>
                  <h4 className='text-capitalize card-title'> Timeout Log </h4>
                  <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div>
                      <Input
                        bsSize='sm'
                        id='take'
                        name='take'
                        type='select'
                        onChange={handleLimitChange}
                        value={take}
                      >
                        <option>{take}</option>
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>

                    <div className='d-flex align-items-center'>
                      <div>
                        {/* <FilterDropDown
                          menu={menu}
                          toggleMenu={() => setMenu(!menu)}
                        >
                          <div>
                            <AvForm
                              className='form-horizontal'
                              onValidSubmit={handleDateFilter}
                            >
                              <SimpleBar
                                style={{
                                  maxHeight: '250px',
                                }}
                              >
                                <Row className='px-3  pt-3 pb-0'>
                                  <Col md='12'>
                                    <FormGroup>
                                      <AvField
                                        name='startDate'
                                        type='date'
                                        className='form-control bg-light'
                                        id='startDate'
                                        value={date.start}
                                        helpMessage='Start Date'
                                        placeholder='Please choose the start date...'
                                        // required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md='12'>
                                    <FormGroup>
                                      <AvField
                                        name='endDate'
                                        type='date'
                                        className='form-control bg-light'
                                        id='endDate'
                                        value={date.end}
                                        helpMessage='End Date'
                                        placeholder='Please choose the end date...'
                                        // required
                                      />
                                    </FormGroup>
                                  </Col>{' '}
                                </Row>
                              </SimpleBar>

                              <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                                <Link
                                  to='#'
                                  onClick={resetHandler}
                                  className='btn btn-sm  bg-light font-size-14'
                                >
                                  <i className=' mdi mdi-refresh mr-1'>
                                    {' '}
                                    Reset
                                  </i>
                                </Link>

                                <button
                                  className='btn btn-success btn-sm mr-2'
                                  type='submit'
                                >
                                  <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                  Send
                                </button>
                              </div>
                            </AvForm>
                          </div>
                        </FilterDropDown> */}
                      </div>
                    </div>
                  </div>
                  <BootstrapTable
                    keyField='keyId'
                    data={data?.rows}
                    columns={data?.columns}
                    striped
                    hover
                    wrapperClasses='table-responsive rideDeclineLogTable'
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    // breakLabel={'...'}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={3}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    //Bootstrap styling
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { rideDeclineLog, loading } = state.Rides;
  return { rideDeclineLog, loading };
};

export default connect(mapStateToProps, {
  fetchRideDeclineLog,
})(DeclineLogTable);
