
import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetLicenseKeyService = (query) => {
  const http = new HttpService();
  let url = 'license/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const GetEachLicenseKeyService = (id) => {
  const http = new HttpService();
  const url = `license/${id}`;
  return http.getData(url);
};

export const CreateLicenseKeyService = (payload) => {
  const http = new HttpService();
  const url = 'license';
  return http.postData(payload, url);
};
export const UpdateLicenseKeyService = (payload) => {
  const http = new HttpService();
  const url = `license/${payload.id}`;
  return http.putData(payload, url);
};

export const DeleteLicenseKeyService = (id) => {
  const http = new HttpService();
  const url = `license/${id}`;
  return http.deleteData(url);
};
