import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_ZONE,
  FETCH_EACH_ZONE,
  CREATE_ZONE,
  DELETE_ZONE,
  UPDATE_ZONE,
} from './actionTypes';
import {
  fetchZoneSuccessful,
  fetchZoneError,
  fetchEachZoneSuccessful,
  fetchEachZoneError,
  deleteZoneSuccessful,
  deleteZoneFailed,
  createZoneSuccessful,
  createZoneFailed,
  updateZoneSuccessful,
  updateZoneFailed,
} from './actions';

import {
  GetZoneService,
  GetEachZoneService,
  DeleteZoneService,
  CreateZoneService,
  UpdateZoneService,
} from '../../services/zoneService';

function* fetchZoneHandler({ payload }) {
  try {
    const response = yield call(GetZoneService, payload);
    yield put(fetchZoneSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchZoneError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchZoneError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchZoneError(error?.response?.data?.message));
    }
  }
}
function* fetchEachZoneHandler({ payload }) {
  try {
    const response = yield call(GetEachZoneService, payload);
    yield put(fetchEachZoneSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachZoneError(error?.response?.data.error?.message));
  }
}

function* deleteZoneHandler({ payload }) {
  try {
    const response = yield call(DeleteZoneService, payload);
    yield put(deleteZoneSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteZoneFailed(error?.response?.data?.message));
  }
}

function* createZoneHandler({ payload }) {
  try {
    const response = yield call(CreateZoneService, payload);
    yield put(createZoneSuccessful(response.data.result));
  } catch (error) {
    yield put(createZoneFailed(error?.response?.data?.message));
  }
}

function* updateZoneHandler({ payload }) {
  try {
    const response = yield call(UpdateZoneService, payload);
    yield put(updateZoneSuccessful(response.data.result));
  } catch (error) {
    yield put(updateZoneFailed(error?.response?.data?.message));
  }
}

// ZONE SUMMARY

export function* watchFetchZone() {
  yield takeEvery(FETCH_ZONE, fetchZoneHandler);
}
export function* watchFetchEachZone() {
  yield takeEvery(FETCH_EACH_ZONE, fetchEachZoneHandler);
}
export function* watchDeleteZone() {
  yield takeEvery(DELETE_ZONE, deleteZoneHandler);
}
export function* watchCreateZone() {
  yield takeEvery(CREATE_ZONE, createZoneHandler);
}
export function* watchUpdateZone() {
  yield takeEvery(UPDATE_ZONE, updateZoneHandler);
}

function* zoneSaga() {
  yield all([
    fork(watchFetchZone),
    fork(watchFetchEachZone),
    fork(watchDeleteZone),
    fork(watchCreateZone),
    fork(watchUpdateZone),
  ]);
}

export default zoneSaga;
