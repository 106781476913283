import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Form from './form';

import { fetchEachBonus, fetchZone } from '../../../../store/actions';

const BonusForm = ({
  fetchEachBonus,
  fetchZone,
  bonusPreview,
  loading,
  message,
  zone,
}) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const userId = new URLSearchParams(search).get('user');

  useEffect(() => {
    if (id) {
      fetchEachBonus(id);
    }
  }, []);

  useEffect(() => {
    fetchZone();
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        {bonusPreview ? (
          <Form
            loading={loading}
            defaultValue={bonusPreview}
            message={message}
            zone={zone}
            fetchZone={fetchZone}
          />
        ) : (
          <Form
            loading={loading}
            message={message}
            zone={zone}
            defaultValue={null}
            userID={userId}
            fetchZone={fetchZone}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { bonusPreview, loading, bonusError, message } = state.Bonus;
  const { zone } = state.Zone;
  return { zone, bonusPreview, loading, bonusError, message };
};

export default connect(mapStateToProps, { fetchZone, fetchEachBonus })(
  BonusForm
);
