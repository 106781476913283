/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { Alert, Row, Col, Table } from 'reactstrap';
import Loader from '../../../../components/Common/Loading/index.js';
import { fetchEachDocType } from '../../../../store/actions';

const DocType = ({
  docTypePreview,
  previewId,
  loading,
  docTypeError,
  fetchEachDocType,
}) => {
  useEffect(() => {
    fetchEachDocType(previewId);
  }, [fetchEachDocType, previewId]);
  return (
    <React.Fragment>
      <div className='mt-3'>
        {docTypeError && !loading && (
          <Alert color='danger' className='text-center'>
            {docTypeError?.error}
          </Alert>
        )}
        {docTypePreview && loading ? (
          <Loader />
        ) : (
          <Row>
            <Col sm={12}>
              <div>
                <Table hover>
                  <tbody>
                    <tr>
                      <td className='font-weight-bold'>Key</td>
                      <td className='text-uppercase'>{docTypePreview?.key}</td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Name</td>
                      <td>{docTypePreview?.name}</td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>User Type</td>
                      <td>{docTypePreview?.userType}</td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Description</td>
                      <td>{docTypePreview?.description}</td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>
                        Is it a compulsory document?
                      </td>
                      <td>
                        {docTypePreview?.isOptional === true ? 'NO' : 'YES'}
                      </td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Does it expires?</td>
                      <td>
                        {docTypePreview?.isExpiry === true ? 'YES' : 'NO'}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { docTypePreview, loading, docTypeError } = state.DocType;
  return { docTypePreview, loading, docTypeError };
};

export default connect(mapStateToProps, { fetchEachDocType })(DocType);
