import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_APP_VERSIONS,
  FETCH_EACH_APP_VERSION,
  CREATE_APP_VERSION,
  DELETE_APP_VERSION,
  UPDATE_APP_VERSION,
} from './actionTypes';
import {
  fetchApp_versionsSuccessful,
  fetchApp_versionsError,
  fetchEachApp_versionSuccessful,
  fetchEachApp_versionError,
  deleteApp_versionSuccessful,
  deleteApp_versionFailed,
  createApp_versionSuccessful,
  createApp_versionFailed,
  updateApp_versionSuccessful,
  updateApp_versionFailed,
} from './actions';

import {
  GetApp_versionService,
  GetEachApp_versionService,
  DeleteApp_versionService,
  CreateApp_versionService,
  UpdateApp_versionService,
} from '../../services/appVersionService';

function* fetchApp_versionsHandler({ payload }) {
  try {
    const response = yield call(GetApp_versionService, payload);
    yield put(fetchApp_versionsSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchApp_versionsError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchApp_versionsError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchApp_versionsError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEachApp_versionHandler({ payload }) {
  try {
    const response = yield call(GetEachApp_versionService, payload);
    yield put(fetchEachApp_versionSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachApp_versionError(error?.response?.data.error?.message));
  }
}

function* deleteApp_versionHandler({ payload }) {
  try {
    const response = yield call(DeleteApp_versionService, payload);
    yield put(deleteApp_versionSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteApp_versionFailed(error?.response?.data?.message));
  }
}

function* createApp_versionHandler({ payload }) {
  try {
    const response = yield call(CreateApp_versionService, payload);
    yield put(createApp_versionSuccessful(response.data.result));
  } catch (error) {
    yield put(createApp_versionFailed(error?.response?.data?.message));
  }
}

function* updateApp_versionHandler({ payload }) {
  try {
    const response = yield call(UpdateApp_versionService, payload);
    yield put(updateApp_versionSuccessful(response.data.result));
  } catch (error) {
    yield put(updateApp_versionFailed(error?.response?.data?.message));
  }
}

// APP_VERSION SUMMARY

export function* watchFetchApp_version() {
  yield takeEvery(FETCH_APP_VERSIONS, fetchApp_versionsHandler);
}

export function* watchFetchEachApp_version() {
  yield takeEvery(FETCH_EACH_APP_VERSION, fetchEachApp_versionHandler);
}

export function* watchDeleteApp_version() {
  yield takeEvery(DELETE_APP_VERSION, deleteApp_versionHandler);
}
export function* watchCreateApp_version() {
  yield takeEvery(CREATE_APP_VERSION, createApp_versionHandler);
}
export function* watchUpdateApp_version() {
  yield takeEvery(UPDATE_APP_VERSION, updateApp_versionHandler);
}

function* app_versionSaga() {
  yield all([
    fork(watchFetchApp_version),
    fork(watchFetchEachApp_version),
    fork(watchDeleteApp_version),
    fork(watchCreateApp_version),
    fork(watchUpdateApp_version),
  ]);
}

export default app_versionSaga;
