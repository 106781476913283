import React from 'react';
import { Row, Col, Table } from 'reactstrap';
import Moment from 'moment';

const Overview = ({ vehicleInfo }) => {
  return (
    <div>
      <Row>
        <Col sm={6} className='border rounded p-2'>
          <h4 className='text-capitalize card-title mb-5 mt-2'>
            {' '}
            Vehicle Details{' '}
          </h4>

          <Table hover>
            <tbody>
              <tr>
                <td className='text-muted text-right col-6'>Vehicle Owner</td>
                <td className='font-weight-bold text-left col-6 text-capitalize'>
                  {`${vehicleInfo?.user.firstName} ${vehicleInfo?.user.lastName}`}
                </td>
              </tr>
              <tr>
                <td className='text-muted text-right col-6'>Brand Name</td>
                <td className='font-weight-bold text-left col-6'>
                  {vehicleInfo?.make}
                </td>
              </tr>
              <tr>
                <td className='text-muted text-right col-6'>Model</td>
                <td className='font-weight-bold text-left col-6'>
                  {vehicleInfo?.model}
                </td>
              </tr>
              <tr>
                <td className='text-muted text-right col-6'>Capacity</td>
                <td className='font-weight-bold text-lef col-6t'>
                  {vehicleInfo?.seats}
                </td>
              </tr>
              <tr>
                <td className='text-muted text-right col-6'>Color</td>
                <td className='font-weight-bold text-lef col-6t'>
                  {vehicleInfo?.color}
                </td>
              </tr>

              <tr>
                <td className='text-muted text-right col-6'> Year</td>
                <td className='font-weight-bold text-lef col-6t'>
                  {vehicleInfo?.year}
                </td>
              </tr>
              <tr>
                <td className='text-muted text-right col-6'>Plate Number</td>
                <td className='font-weight-bold text-left col-6'>
                  {vehicleInfo?.plateNumber}
                </td>
              </tr>

              <tr>
                <td className='text-muted text-right col-6'>Description</td>
                <td className='font-weight-bold text-left col-6'>
                  {vehicleInfo?.description}
                </td>
              </tr>
              <tr>
                <td className='text-muted text-right col-6'>Created At</td>
                <td className='font-weight-bold text-left col-6'>
                  {Moment(vehicleInfo?.createdAt).format('l')}
                </td>
              </tr>
              <tr>
                <td className='text-muted text-right col-6'>Packages</td>
                <td className='font-weight-bold text-lef col-6t'>
                  <div>
                    {vehicleInfo.packages.length > 0
                      ? vehicleInfo.packages.map((pkg) => (
                          <span key={pkg.id}>
                            <span
                              className={`badge text-white font-size-1 mr-2`}
                              style={{ background: pkg?.colorCode }}
                            >
                              {' '}
                              {pkg.name}
                            </span>
                          </span>
                        ))
                      : 'No package'}
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default Overview;
