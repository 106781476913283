/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { Alert, Row, Col, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Loader from '../../../components/Common/Loading/index.js';
import { fetchDocument } from '../../../store/actions';

const UserDocPreview = ({
  document,
  userId,
  loading,
  documentError,
  fetchDocument,
  updatePdfModalDisplay,
  updateImgModalDisplay,
  previewAction,
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (userId) {
      fetchDocument({ user: userId });
    }
  }, []);

  useEffect(() => {
    if (document) {
      setData(tableDataHandler());
      setIsLoading(false);
    }
  }, [document]);

  const handleDocView = async (documentUrl, documentId) => {
    let docName, docExtension;
    if (documentUrl) {
      var getDocNameFromUrl = documentUrl.split('/');
      docName = getDocNameFromUrl[getDocNameFromUrl.length - 1];

      var getDocExtensionFromUrl = documentUrl.split('.');
      docExtension = getDocExtensionFromUrl[getDocExtensionFromUrl.length - 1];

      if (docExtension === 'pdf') {
        previewAction(docName, documentId, false, true);
      }
      if (
        docExtension === 'png' ||
        docExtension === 'jpg' ||
        docExtension === 'jpeg'
      ) {
        previewAction(docName, documentId, true, false);
      }
    } else {
      previewAction(null, documentId, false, false);
    }
  };

  const tableDataHandler = () => {
    const rowData = document?.result?.map((data) => {
      return {
        id: data.id,
        key: data.key,
        name: data.name,
        documentView: (
          <Link
            to={'#'}
            onClick={() =>
              handleDocView(
                data.documentUrl ? data.documentUrl : data.documentBackUrl,
                data.id
              )
            }
            className='text-dark font-weight-bold'
            style={
              data.documentUrl === null && data.documentBackUrl === null
                ? { pointerEvents: 'none', opacity: '0.5' }
                : null
            }
          >
            <i className='fas fa-eye font-size-14'></i>
          </Link>
        ),
        status: (
          <h1
            className={`badge badge-soft-${
              data.status.toLowerCase() === 'accepted'
                ? 'success'
                : data.status.toLowerCase() === 'rejected'
                ? 'danger'
                : data.status.toLowerCase() === 'pending'
                ? 'warning'
                : data.status.toLowerCase() === 'flagged'
                ? 'dark'
                : data.status.toLowerCase() === 'review'
                ? 'secondary'
                : ''
            } font-size-12`}
          >
            {data.status ? data.status : '---'}
          </h1>
        ),
      };
    });
    return {
      columns: [
        // {
        //   dataField: 'sl.no',
        //   text: 'S.No',
        //   formatter: (cell, row, rowIndex) => {
        //     return rowIndex + 1;
        //   },
        // },
        {
          text: 'Key',
          dataField: 'key',
          sort: true,
        },
        {
          text: 'Name',
          dataField: 'name',
        },
        {
          text: 'View',
          dataField: 'documentView',
          // classes: 'text-center',
        },
        {
          text: 'Status',
          dataField: 'status',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <div className='mt-3'>
        {documentError && !loading && (
          <Alert color='danger' className='text-center'>
            {documentError}
          </Alert>
        )}
        {document && loading ? (
          <Loader />
        ) : (
          <Row>
            <Col sm={12}>
              {!isLoading && data !== null ? (
                <div>
                  <BootstrapTable
                    keyField='id'
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses='table-responsive'
                    noDataIndication={
                      'This user does not have a document to Display'
                    }
                  />
                </div>
              ) : (
                <Loader loading={isLoading} />
              )}
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { document, loading, documentError } = state.Document;
  return { document, loading, documentError };
};

export default connect(mapStateToProps, { fetchDocument })(UserDocPreview);
