export const FETCH_LICENSE_KEYS = 'FETCH_LICENSE_KEYS';
export const FETCH_LICENSE_KEYS_SUCCESSFUL = 'FETCH_LICENSE_KEYS_SUCCESSFUL';
export const FETCH_LICENSE_KEYS_API_FAILED = 'FETCH_LICENSE_KEYS_API_FAILED';

export const FETCH_EACH_LICENSE_KEY = 'FETCH_EACH_LICENSE_KEY';
export const FETCH_EACH_LICENSE_KEY_SUCCESSFUL =
  'FETCH_EACH_LICENSE_KEY_SUCCESSFUL';
export const FETCH_EACH_LICENSE_KEY_API_FAILED =
  'FETCH_EACH_LICENSE_KEY_API_FAILED';

export const DELETE_LICENSE_KEY = 'DELETE_LICENSE_KEY';
export const DELETE_LICENSE_KEY_SUCCESSFUL = 'DELETE_LICENSE_KEY_SUCCESSFULLY';
export const DELETE_LICENSE_KEY_FAILED = 'DELETE_LICENSE_KEY_FAILED';

export const CREATE_LICENSE_KEY = 'CREATE_LICENSE_KEY';
export const CREATE_LICENSE_KEY_SUCCESSFUL = 'CREATE_LICENSE_KEY_SUCCESSFULLY';
export const CREATE_LICENSE_KEY_FAILED = 'CREATE_LICENSE_KEY_FAILED';

export const UPDATE_LICENSE_KEY = 'UPDATE_LICENSE_KEY';
export const UPDATE_LICENSE_KEY_SUCCESSFUL = 'UPDATE_LICENSE_KEY_SUCCESSFULLY';
export const UPDATE_LICENSE_KEY_FAILED = 'FAILED';
