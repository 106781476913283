/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import SimpleImageSlider from 'react-simple-image-slider';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import imgCar from '../../../assets/images/sample_car.png';
import {
  Alert,
  Row,
  Col,
  Table,
  Container,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import VehicleInfo from './components/vehicleInfo';
import Loader from '../../../components/Common/Loading/index.js';
import { fetchEachVehicle } from '../../../store/actions';

const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const Vehicle = ({
  match,
  vehiclePreview,
  previewId,
  history,
  loading,
  vehicleError,
  fetchEachVehicle,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const images = [
    { url: vehiclePreview?.imageFront },
    vehiclePreview?.imageLeft ? { url: vehiclePreview?.imageLeft } : null,
    vehiclePreview?.imageBack ? { url: vehiclePreview?.imageBack } : null,
    vehiclePreview?.imageRight ? { url: vehiclePreview?.imageRight } : null,
  ];
  const breadcrumbItems = [
    { title: 'Vehicle', link: '/vehicles' },
    { title: 'Dashboard', link: '#' },
  ];
  useEffect(() => {
    if (match.params.id) {
      fetchEachVehicle(match.params.id);
    }
  }, [fetchEachVehicle, match.params.id]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Vehicles' breadcrumbItems={breadcrumbItems} />
          {vehicleError && !loading ? (
            <Alert color='danger' className='text-center'>
              {vehicleError}
            </Alert>
          ) : (
            <>
              {' '}
              {vehiclePreview === null ? (
                <Loader />
              ) : (
                <Row>
                  <Col>
                    <Link
                      to='#'
                      onClick={() => history.goBack()}
                      className='float-right mr-2'
                    >
                      <i className='fas fa-arrow-left mr-2' />
                      Back
                    </Link>
                  </Col>
                  <Col sm={12}>
                    <div>
                      <Card>
                        <CardBody>
                          <Row className='align-items-center px-4'>
                            <img
                              className='rounded avatar-lg border p-2'
                              alt='Profile'
                              src={
                                vehiclePreview?.imageFront
                                  ? vehiclePreview?.imageFront
                                  : imgCar
                              }
                              onClick={() => {
                                setShowModal(true);
                              }}
                              style={
                                vehiclePreview?.imageFront
                                  ? { cursor: 'pointer' }
                                  : { pointerEvents: 'none', cursor: 'pointer' }
                              }
                            />
                            <Col sm={9} className='d-flex'>
                              <div className='mr-2'>
                                <span className='mt-1 mb-1'>
                                  <h4 className='font-weight-bold font-size-14 text-sm-left text-center mb-0'>
                                    {vehiclePreview?.make}{' '}
                                    <span>
                                      <span
                                        className={`font-size-10 badge badge-${
                                          vehiclePreview.color.toLowerCase() ===
                                          'red'
                                            ? 'danger'
                                            : vehiclePreview.color.toLowerCase() ===
                                              'blue'
                                            ? 'primary'
                                            : vehiclePreview.color.toLowerCase() ===
                                              'black'
                                            ? 'dark text-white'
                                            : vehiclePreview.color.toLowerCase() ===
                                              'gold'
                                            ? 'warning'
                                            : vehiclePreview.color.toLowerCase() ===
                                              'green'
                                            ? 'success'
                                            : vehiclePreview.color.toLowerCase() ===
                                              'grey'
                                            ? 'secondary text-white'
                                            : 'soft-secondary text-dark'
                                        } text-white rounded text-capitalize`}
                                      >
                                        {' '}
                                        {vehiclePreview?.color}{' '}
                                      </span>
                                    </span>
                                  </h4>
                                </span>
                                <span className='text-muted mb-0 font-size-12 text-sm-left text-center'>
                                  Model: {vehiclePreview?.model}
                                </span>
                                <p className='text-muted text-sm-left text-center font-size-12 mb-0'>
                                  {vehiclePreview?.plateNumber} |{' '}
                                  {vehiclePreview?.year}
                                </p>
                                <h1
                                  className={`badge badge-soft-${
                                    vehiclePreview?.status.toLowerCase() ===
                                    'approved'
                                      ? 'success'
                                      : vehiclePreview?.status.toLowerCase() ===
                                        'cancelled'
                                      ? 'danger'
                                      : vehiclePreview?.status.toLowerCase() ===
                                        'rejected'
                                      ? 'danger'
                                      : vehiclePreview?.status.toLowerCase() ===
                                        'pending'
                                      ? 'warning'
                                      : vehiclePreview?.status.toLowerCase() ===
                                        'review'
                                      ? 'dark'
                                      : ''
                                  } font-size-11 p-1`}
                                >
                                  {vehiclePreview?.status}
                                </h1>
                              </div>
                              <div className='d-flex align-items-center ml-5'>
                                <Link to={`/user/${vehiclePreview?.user.id}`}>
                                  <div className='d-flex align-items-end'>
                                    <span className='p-1 ml-2 border rounded shadow-sm d-flex align-items-end'>
                                      {' '}
                                      <img
                                        className='rounded-circle avatar-xs border mr-1'
                                        width='100%'
                                        alt='Profile'
                                        src={
                                          vehiclePreview?.user.picture
                                            ? vehiclePreview?.user.picture
                                            : avatarImg
                                        }
                                      />
                                      <span className='text-capitalize text-black font-weight-bold'>
                                        {vehiclePreview?.user.firstName}{' '}
                                        {vehiclePreview?.user.lastName}
                                      </span>
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <VehicleInfo vehicleInfo={vehiclePreview} />
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                </Row>
              )}
            </>
          )}
          <Modal
            isOpen={showModal}
            toggle={() => setShowModal(!showModal)}
            size='lg'
            style={{ left: '5%' }}
            centered={true}
          >
            {/* <ModalHeader toggle={() => setShowModal(false)}>
        </ModalHeader> */}
            <ModalBody className='p-0 m-2'>
              <SimpleImageSlider
                width={'-webkit-fill-available'}
                height={'80vh'}
                images={images}
                showBullets={true}
                showNavs={true}
              />
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { vehiclePreview, loading, vehicleError } = state.Vehicle;
  return { vehiclePreview, loading, vehicleError };
};

export default connect(mapStateToProps, { fetchEachVehicle })(
  withRouter(Vehicle)
);
