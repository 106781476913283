import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import MiniWidgets from './components/MiniWidgets';
import VehiclePackage from './components/VehiclePackage';
import GreySummaryCard from '../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../components/Common/Summary/actionCheck';
import VehicleTable from './vehicleTable';
import PreviewVehicle from './previewVehicle';

import {
  fetchVehicle,
  fetchPackageFailed,
  deleteVehicle,
  updateVehicle,
  addPackageToVehicles,
  fetchPackage,
  updateVehicleFailed,
  updateVehiclePackages,
} from '../../../store/actions';

const Vehicle = ({
  vehicle,
  packages,
  fetchVehicle,
  fetchPackageFailed,
  deleteVehicle,
  updateVehicle,
  updateVehicleFailed,
  updateVehiclePackages,
  addPackageToVehicles,
  vehicleError,
  isAuthorize,
  fetchPackage,
  loading,
  packageMsg,
  message,
  history,
  accessControl
}) => {
  const [showModal, setShowModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [vehicleDetails, setVehicleDetails] = useState(null);
  const [statusModal, setStatusModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [dateFilter, setDate] = useState({});
  const breadcrumbItems = [
    { title: 'Configuration', link: '#' },
    { title: 'Vehicle', link: '/vehicles' },
  ];

  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        updateVehicleFailed('');
      }, 2000);
    }
  }, [message, updateVehicleFailed]);

  useEffect(() => {
    updateVehicleFailed('');
    fetchPackage();
  }, []);

  const handleStatusUpdate = (info, statusType) => {
    const vehicleData = {
      ...info,
    };
    vehicleData.id = info.id;
    vehicleData.status = statusType;

    updateVehicle(vehicleData);
  };
  const deleteVehicleModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const addPackageHandler = (info) => {
    setVehicleDetails(info);
    setStatusModal(true);
  };

  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteVehicle(deleteId);
    setDeleteId(null);
  };
  const date = (e) => {
    setDate(e);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Vehicles" breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field="Vehicles">
            {isAuthorize ? (
              <Alert color="danger" className="text-center">
                {isAuthorize}
              </Alert>
            ) : (
              <>
                <CheckAction field="Vehicles" iconType={'Create'}>
                  <div className="mb-2 text-right">
                    <Link to="/vehicle_form">
                      <Button color="success">
                        <i className="mdi mdi-plus-circle-outline font-size-18"></i>{' '}
                        Add Vehicle
                      </Button>
                    </Link>
                  </div>
                </CheckAction>
                {message && (
                  <Alert color="success" className="text-center">
                    {message}
                  </Alert>
                )}
                {vehicleError && (
                  <Alert color="danger" className="text-center">
                    {vehicleError}
                  </Alert>
                )}
                <Row>
                  <Col sm={12}>
                    <Row>
                      <MiniWidgets date={dateFilter} />
                    </Row>
                  </Col>
                  <Col>
                    <VehicleTable
                      vehicleData={vehicle}
                      packages={packages}
                      dateFilter={date}
                      loading
                      addPackageHandler={addPackageHandler}
                      deleteVehicle={deleteVehicleModal}
                      fetchVehicle={fetchVehicle}
                      fetchPackageFailed={fetchPackageFailed}
                      previewAction={previewHandler}
                      handleStatusUpdate={handleStatusUpdate}
                      message={message}
                      packageMsg={packageMsg}
                      history={history}
                      CheckAction={CheckAction}
                      ReadCheck={ReadCheck}
                      accessControl={accessControl}
                      addPackageToVehicles={addPackageToVehicles}
                    />
                  </Col>
                </Row>

                <Modal
                  isOpen={showModal}
                  toggle={() => setShowModal(!showModal)}
                >
                  <ModalHeader toggle={() => setShowModal(false)}>
                    Preview Vehicle
                  </ModalHeader>
                  <ModalBody>
                    <PreviewVehicle previewId={previewId} />
                  </ModalBody>
                </Modal>

                <Modal
                  isOpen={showDeleteModal}
                  toggle={() => setShowDeleteModal(!showDeleteModal)}
                >
                  <ModalBody>
                    <h4 className="card-title mb-4 text-center">
                      {' '}
                      Are you sure you want to delete this vehicle
                    </h4>
                    <button
                      onClick={() => deleteHandler()}
                      className="btn btn-outline-danger btn-sm w-100"
                    >
                      Click to Delete
                    </button>
                  </ModalBody>
                </Modal>

                {/* Status Update Modal */}
                <Modal
                  isOpen={statusModal}
                  toggle={() => setStatusModal(!statusModal)}
                >
                  <ModalHeader toggle={() => setStatusModal(false)}>
                    Update Vehicle Status
                  </ModalHeader>
                  <ModalBody>
                    <VehiclePackage
                      vehicleDetails={vehicleDetails}
                      packages={packages}
                      message={message}
                      updateVehiclePackages={updateVehiclePackages}
                      closeModal={setStatusModal}
                      updateVehicleFailed={updateVehicleFailed}
                    />
                  </ModalBody>
                </Modal>
              </>
            )}
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { vehicle, loading, vehicleError, message, isAuthorize } =
    state.Vehicle;
  const { packages } = state.Packages;
  const { accessControl } = state.Account;
  const packageMsg = state.Packages.message;
  return {
    vehicle,
    loading,
    vehicleError,
    message,
    packageMsg,
    isAuthorize,
    packages,
    accessControl,
  };
};

export default connect(mapStateToProps, {
  fetchVehicle,
  fetchPackageFailed,
  deleteVehicle,
  updateVehicle,
  fetchPackage,
  updateVehiclePackages,
  updateVehicleFailed,
  addPackageToVehicles,
})(Vehicle);
