export const FETCH_CONTACTS = 'FETCH_CONTACTS';
export const FETCH_CONTACTS_SUCCESSFUL = 'FETCH_CONTACTS_SUCCESSFUL';
export const FETCH_CONTACTS_API_FAILED = 'FETCH_CONTACTS_API_FAILED';

export const FETCH_SOS = 'FETCH_SOS';
export const FETCH_SOS_SUCCESSFUL = 'FETCH_SOS_SUCCESSFUL';
export const FETCH_SOS_API_FAILED = 'FETCH_SOS_API_FAILED';

export const FETCH_EACH_CONTACT = 'FETCH_EACH_CONTACT';
export const FETCH_EACH_CONTACT_SUCCESSFUL = 'FETCH_EACH_CONTACT_SUCCESSFUL';
export const FETCH_EACH_CONTACT_API_FAILED = 'FETCH_EACH_CONTACT_API_FAILED';

export const FETCH_EACH_SOS = 'FETCH_EACH_SOS';
export const FETCH_EACH_SOS_SUCCESSFUL = 'FETCH_EACH_SOS_SUCCESSFUL';
export const FETCH_EACH_SOS_API_FAILED = 'FETCH_EACH_SOS_API_FAILED';

export const DELETE_CONTACT = 'DELETE_CONTACT';
export const DELETE_CONTACT_SUCCESSFUL = 'DELETE_CONTACT_SUCCESSFULLY';
export const DELETE_CONTACT_FAILED = 'DELETE_CONTACT_FAILED';

export const CREATE_CONTACT = 'CREATE_CONTACT';
export const CREATE_CONTACT_SUCCESSFUL = 'CREATE_CONTACT_SUCCESSFULLY';
export const CREATE_CONTACT_FAILED = 'CREATE_CONTACT_FAILED';

export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_CONTACT_SUCCESSFUL = 'UPDATE_CONTACT_SUCCESSFULLY';
export const UPDATE_CONTACT_FAILED = 'FAILED';

export const UPDATE_SOS = 'UPDATE_SOS';
export const UPDATE_SOS_SUCCESSFUL = 'UPDATE_SOS_SUCCESSFULLY';
export const UPDATE_SOS_FAILED = 'FAILED';
