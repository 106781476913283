import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Col, Card, CardBody, Media } from 'reactstrap';
import { connect } from 'react-redux';
import { fetchBonusLogSummary } from '../../../../../store/actions';

const MiniWidgets = ({
  summaryError,
  bonusLogSummary,
  fetchBonusLogSummary,
  start,
  end,
  loading,
  defaultIcon,
  date,
}) => {
  useEffect(() => {
    if (date.end) {
      fetchBonusLogSummary(date);
    } else {
      fetchBonusLogSummary();
    }
  }, [date, fetchBonusLogSummary]);

  const reports = [
    {
      icon: defaultIcon ? defaultIcon : 'ri-bank-line',
      title: 'TOTAL',
      value:
        bonusLogSummary?.result?.total > 999 &&
        bonusLogSummary?.result?.total < 999999
          ? `₦${bonusLogSummary?.result?.total / 1000} K`
          : bonusLogSummary?.result?.total > 999999
          ? `₦${bonusLogSummary?.result?.total / 1000000} M`
          : `₦${bonusLogSummary?.result?.total}`,
      color: 'primary',
      driver:
        bonusLogSummary?.result?.bonusByUser?.driver > 999 &&
        bonusLogSummary?.result?.bonusByUser?.driver < 999999
          ? `₦${bonusLogSummary?.result?.bonusByUser?.driver / 1000} K`
          : bonusLogSummary?.result?.bonusByUser?.driver > 999999
          ? `₦${bonusLogSummary?.result?.bonusByUser?.driver / 1000000} M`
          : `₦${bonusLogSummary?.result?.bonusByUser?.driver}`,
      rider:
        bonusLogSummary?.result?.bonusByUser?.rider > 999 &&
        bonusLogSummary?.result?.bonusByUser?.rider < 999999
          ? `₦${bonusLogSummary?.result?.bonusByUser?.rider / 1000} K`
          : bonusLogSummary?.result?.bonusByUser?.rider > 999999
          ? `₦${bonusLogSummary?.result?.bonusByUser?.rider / 1000000} M`
          : `₦${bonusLogSummary?.result?.bonusByUser?.rider}`,
      admin:
        bonusLogSummary?.result?.bonusByUser?.admin > 999 &&
        bonusLogSummary?.result?.bonusByUser?.admin < 999999
          ? `₦${bonusLogSummary?.result?.bonusByUser?.admin / 1000} K`
          : bonusLogSummary?.result?.bonusByUser?.admin > 999999
          ? `₦${bonusLogSummary?.result?.bonusByUser?.admin / 1000000} M`
          : `₦${bonusLogSummary?.result?.bonusByUser?.admin}`,
      signup:
        bonusLogSummary?.result?.signup > 999 &&
        bonusLogSummary?.result?.signup < 999999
          ? `₦${bonusLogSummary?.result?.signup / 1000} K`
          : bonusLogSummary?.result?.signup > 999999
          ? `₦${bonusLogSummary?.result?.signup / 1000000} M`
          : `₦${bonusLogSummary?.result?.signup}`,
    },
    {
      icon: 'fas fa-donate',
      title: 'REFERRAL',
      value:
        bonusLogSummary?.result?.bonusByType?.referral > 999 &&
        bonusLogSummary?.result?.bonusByType?.referral < 999999
          ? `₦${bonusLogSummary?.result?.bonusByType?.referral / 1000} K`
          : bonusLogSummary?.result?.bonusByType?.referral > 999999
          ? `₦${bonusLogSummary?.result?.bonusByType?.referral / 1000000} M`
          : `₦${bonusLogSummary?.result?.bonusByType?.referral}`,
      color: 'secondary',
    },
    {
      icon: 'fas fa-car  ',
      title: 'ONLINE',
      value:
        bonusLogSummary?.result?.bonusByType?.online > 999 &&
        bonusLogSummary?.result?.bonusByType?.online < 999999
          ? `₦${bonusLogSummary?.result?.bonusByType?.online / 1000} K`
          : bonusLogSummary?.result?.bonusByType?.online > 999999
          ? `₦${bonusLogSummary?.result?.bonusByType?.online / 1000000} M`
          : `₦${bonusLogSummary?.result?.bonusByType?.online}`,

      color: 'warning',
    },
    {
      icon: 'fas fa-user-check',
      title: 'RATING',
      value:
        bonusLogSummary?.result?.bonusByType?.rating > 999 &&
        bonusLogSummary?.result?.bonusByType?.rating < 999999
          ? `₦${bonusLogSummary?.result?.bonusByType?.rating / 1000} K`
          : bonusLogSummary?.result?.bonusByType?.rating > 999999
          ? `₦${bonusLogSummary?.result?.bonusByType?.rating / 1000000} M`
          : `₦${bonusLogSummary?.result?.bonusByType?.rating}`,
      color: 'success',
      updatedTime: `${bonusLogSummary?.time}`,
    },
  ];

  return (
    <React.Fragment>
      {summaryError === null &&
        reports.map((report, key) => (
          <Col key={key} md={key !== 0 && 3}>
            <Card className='d-flex flex-row mb-3'>
              <CardBody>
                <Media>
                  <Media body className='overflow-hidden'>
                    <span
                      className={`text-${report.color} badge badge-soft-${report.color} rounded`}
                    >
                      <i className={report.icon + ' font-size-16'}></i>
                    </span>
                    <p className='text-truncate font-size-14 mt-4 mb-2 '>
                      {report.title}
                    </p>
                    <h2
                      className='mt-0 mb-0 font-size-'
                      style={{ width: 'max-content' }}
                    >
                      {bonusLogSummary === null ? (
                        <Skeleton duration={2} width='20%' />
                      ) : (
                        report.value
                      )}
                    </h2>

                    {bonusLogSummary?.isCache && report?.updatedTime && (
                      <p
                        className='font-size-12 text-primary'
                        style={{ position: 'absolute', right: '11px' }}
                      >
                        Updated at: {report?.updatedTime?.split(',')?.pop()}
                      </p>
                    )}
                  </Media>
                </Media>
              </CardBody>

              {report.driver && (
                <CardBody
                  className='p-1 pr-3 py-4 d-flex flex-column'
                  style={{ justifyContent: 'space-evenly' }}
                >
                  {bonusLogSummary === null ? (
                    <Skeleton duration={2} width='100%' />
                  ) : (
                    <div
                      className='d-flex justify-content-between align-items-center'
                      style={{ width: 'max-content' }}
                    >
                      <span className='text-muted font-size-12'>Driver</span>
                      <h6 className='m-0 ml-2'>{report.driver}</h6>
                    </div>
                  )}

                  {bonusLogSummary === null ? (
                    <Skeleton duration={2} width='100%' />
                  ) : (
                    <div
                      className='d-flex justify-content-between align-items-center'
                      style={{ width: 'max-content' }}
                    >
                      <span className='text-muted font-size-12'>RIDER</span>{' '}
                      <h6 className='m-0 ml-2'>{report.rider}</h6>
                    </div>
                  )}

                  {bonusLogSummary === null ? (
                    <Skeleton duration={2} width='100%' />
                  ) : (
                    <div
                      className='d-flex justify-content-between align-items-center'
                      style={{ width: 'max-content' }}
                    >
                      <span className='text-muted font-size-12'>ADMIN</span>
                      <h6 className='m-0 ml-2'>{report.admin}</h6>
                    </div>
                  )}
                </CardBody>
              )}
            </Card>
          </Col>
        ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { summaryError, bonusLogSummary } = state.Bonus;
  return { summaryError, bonusLogSummary };
};

export default connect(mapStateToProps, { fetchBonusLogSummary })(MiniWidgets);
