import {
  CHECK_LOGIN,
  LOGIN_USER_SUCCESSFUL,
  API_ERROR,
  UPDATE_ACCOUNT_WALLET,
  UPDATE_ACCOUNT_WALLET_SUCCESSFULLY,
  UPDATE_ACCOUNT_WALLET_FAILED,
  GENERATE_OTP,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAILED,
  API_ACTIVATION_ERROR,
  LOAD_USER,
  LOGOUT_USER,
  AUTH_ERROR,
  LOGOUT_USER_SUCCESS,
  LOAD_USER_SUCCESSFUL,
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  ACTIVATE_USER,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_FAILED,
  RE_ACTIVATE_USER,
  RE_ACTIVATE_USER_SUCCESS,
  RE_ACTIVATE_USER_FAILED,
  EMAIL_SUBSCRIPTION,
  EMAIL_SUBSCRIPTION_SUCCESS,
  EMAIL_SUBSCRIPTION_FAILED,
  FORGET_USER,
  FORGET_USER_SUCCESSFUL,
  FORGET_PASSWORD_API_FAILED,
  CREATE_NEW_PASSWORD,
  CREATE_NEW_PASSWORD_SUCCESSFUL,
  CREATE_NEW_PASSWORD_API_FAILED,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESSFUL,
  UPDATE_PROFILE_API_FAILED,
  GOOGLE_LOGIN,
  PASSWORD_SETTING,
  PASSWORD_SETTING_SUCCESSFUL,
  PASSWORD_SETTING_API_FAILED,
  USER_LOCATION,
  USER_LOCATION_SUCCESSFULLY,
  USER_LOCATION_ERROR,
  MY_ACCESS_CONTROL,
  MY_ACCESS_CONTROL_SUCCESSFUL,
  MY_ACCESS_CONTROL_FAILED,
} from './actionTypes';

const initialState = {
  user: null,
  accessControl: null,
  accessAuth: false,
  accessControlError: null,
  loginError: null,
  accountError: null,
  activationError: null,
  isAuthenticated: null,
  registrationError: null,
  isRegistered: false,
  forgetError: null,
  message: null,
  activate_message: null,
  loading: false,
  loadingOTP: false,
  otpMessage: null,
  otpError: null,
  otp: null,
  mainLoader: null,
  user_location: null,
  loadingLocation: false,
  user_locationError: null,
};

const Account = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_LOGIN:
      state = {
        ...state,
        loginError: null,
        loading: true,
        accessControl: null,

        activate_message: null,
        activationError: null,
      };
      break;

    case LOAD_USER:
      state = {
        ...state,
        loginError: null,
        loading: true,
        accessControl: null,
        mainLoader: true,

        activate_message: null,
        activationError: null,
      };
      break;
    case MY_ACCESS_CONTROL:
      state = {
        ...state,
        loading: true,
        accessAuth: false,
        accessControl: null,
      };
      break;

    case MY_ACCESS_CONTROL_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        accessAuth: true,
        accessControlError: null,
        accessControl: action?.payload,
      };
      break;

    // case MY_ACCESS_CONTROL_FAILED:
    //   state = {
    //     ...state,
    //     loading: true,
    //     accessControlError: action.payload,
    //     accessControl: null,
    //   };
    //   break;

    case LOGIN_USER_SUCCESSFUL:
      state = {
        ...state,
        token: action.payload,
        loading: false,
        activationError: null,
      };
      break;

    case UPDATE_ACCOUNT_WALLET:
      return {
        ...state,
        loading: true,
        message: null,
        accountError: null,
      };

    case UPDATE_ACCOUNT_WALLET_SUCCESSFULLY:
      return {
        ...state,
        loading: false,
        message: 'Account Updated Successfully',
        accountError: null,
      };

    case UPDATE_ACCOUNT_WALLET_FAILED:
      return {
        ...state,
        loading: false,
        message: null,
        accountError: action.payload,
      };
    case GOOGLE_LOGIN:
      // localStorage.setItem('authTokens', action.payload.token)
      state = {
        ...state,
        token: action.payload.token,
        isAuthenticated: true,
        loading: false,
      };
      break;
    case LOGOUT_USER:
      state = { ...state, loading: true, user: null };
      break;

    case LOGOUT_USER_SUCCESS:
      state = {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
      break;

    case LOAD_USER_SUCCESSFUL:
      state = {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        loading: false,
        mainLoader: false,
      };
      break;

    // Register
    case REGISTER_USER:
      state = {
        ...state,
        user: null,
        loading: true,
        isRegistered: false,
        registrationError: null,
      };
      break;

    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        user: action.payload.data,
        isRegistered: true,
        loading: false,
        registrationError: null,
      };
      break;

    case REGISTER_USER_FAILED:
      state = {
        ...state,
        loading: false,
        isRegistered: false,
        registrationError: action.payload,
      };
      break;

    case ACTIVATE_USER:
    case RE_ACTIVATE_USER:
    case EMAIL_SUBSCRIPTION:
      state = {
        ...state,
        loading: true,
        activate_message: null,
        activationError: null,
        registrationError: action.payload,
      };
      break;

    case ACTIVATE_USER_SUCCESS:
    case RE_ACTIVATE_USER_SUCCESS:
    case EMAIL_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        activate_message: action.payload,
      };
      break;

    case ACTIVATE_USER_FAILED:
    case RE_ACTIVATE_USER_FAILED:
    case EMAIL_SUBSCRIPTION_FAILED:
      state = {
        ...state,
        loading: false,
        activate_message: null,
        activationError: action.payload,
      };
      break;

    // Reset password
    case FORGET_USER:
      state = {
        ...state,
        user: null,
        loading: true,
        forgetError: null,
      };
      break;
    case FORGET_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        message: action.payload,
      };
      break;

    // Create New password
    case CREATE_NEW_PASSWORD:
    case PASSWORD_SETTING:
      state = {
        ...state,
        loading: true,
        forgetError: null,
        message: null,
      };
      break;

    // Generate OTP
    case GENERATE_OTP:
      state = {
        ...state,
        otp: null,
        loadingOTP: true,
        otpMessage: null,
        otpError: null,
      };
      break;
    case GENERATE_OTP_SUCCESS:
      state = {
        ...state,
        otp: action.payload,
        loadingOTP: false,
        otpMessage: action.payload.message,
        otpError: null,
      };
      break;
    case GENERATE_OTP_FAILED:
      state = {
        ...state,
        loadingOTP: false,
        otpMessage: null,
        otpError: action.paylaod,
        otp: null,
      };
      break;
    case CREATE_NEW_PASSWORD_SUCCESSFUL:
    case PASSWORD_SETTING_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        message: action.payload,
      };
      break;

    case FORGET_PASSWORD_API_FAILED:
    case CREATE_NEW_PASSWORD_API_FAILED:
    case PASSWORD_SETTING_API_FAILED:
      state = {
        ...state,
        loading: false,
        forgetError: action.payload,
        message: null,
      };
      break;

    // Create Update Profile
    case UPDATE_PROFILE:
      state = {
        ...state,
        loading: true,
        // forgetError: null,
        message: null,
      };
      break;

    case UPDATE_PROFILE_SUCCESSFUL:
      state = {
        ...state,
        user: action.payload,
        loading: false,
        message: 'Profile Update Successfully',
      };
      break;

    case UPDATE_PROFILE_API_FAILED:
      state = {
        ...state,
        loading: false,
        message: null,
        activationError: null,
        forgetError: action.payload,
      };
      break;

    // Error
    case API_ERROR:
    case MY_ACCESS_CONTROL_FAILED:
      state = {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        loading: false,
        accessAuth: false,
        message: null,
        loginError: action.payload,
        activationError: null,
      };
      break;

    case API_ACTIVATION_ERROR:
      state = {
        ...state,
        user: null,
        token: null,
        activationError: action.payload,
        isAuthenticated: false,
        loading: false,
        message: null,
        loginError: null,
      };
      break;

    case AUTH_ERROR:
      localStorage.removeItem('authTokens');
      state = {
        ...state,
        user: null,
        token: null,
        accessAuth: false,
        isAuthenticated: false,
        loading: false,
        mainLoader: false,
        message: null,
        loginError: action.payload,
      };
      break;

    case USER_LOCATION:
      state = {
        ...state,
        user_location: null,
        loadingLocation: true,
        user_locationError: null,
      };
      break;

    case USER_LOCATION_SUCCESSFULLY:
      state = {
        ...state,
        user_location: action.payload,
        loadingLocation: false,
        user_locationError: null,
      };
      break;

    case USER_LOCATION_ERROR:
      state = {
        ...state,
        user_location: null,
        loadingLocation: false,
        user_locationError: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Account;
