/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Row, Col, Table } from 'reactstrap';
import Loader from '../../../components/Common/Loading/index.js';
import { fetchEachPromoCode } from '../../../store/actions';
import Moment from 'moment';

const PromoCode = ({
  promoCodePreview,
  previewId,
  loading,
  promoCodeError,
  fetchEachPromoCode,
  historyViewer,
}) => {
  useEffect(() => {
    fetchEachPromoCode(previewId);
  }, [fetchEachPromoCode, previewId]);

  return (
    <React.Fragment>
      <div className='mt-1'>
        {promoCodeError && !loading && (
          <Alert color='danger' className='text-center'>
            {promoCodeError}
          </Alert>
        )}
        {!promoCodePreview && loading ? (
          <Loader />
        ) : (
          <Row>
            <Col sm={12} className='text-center'>
              <div className='d-flex justify-content-between pb-2'>
                <span
                  className={`badge badge-soft-${
                    new Date(promoCodePreview?.expiry) <= new Date()
                      ? 'danger'
                      : 'success'
                  }`}
                >
                  {Moment(promoCodePreview?.expiry).format('lll')}
                </span>
                <span
                  className={`badge badge-soft-${
                    promoCodePreview?.status.toLowerCase() === 'active'
                      ? 'success'
                      : promoCodePreview?.status.toLowerCase() === 'inactive'
                      ? 'danger'
                      : 'warning'
                  }`}
                >
                  {promoCodePreview?.status === 'COMINGSOON'
                    ? 'COMING SOON'
                    : promoCodePreview?.status}
                </span>
              </div>
              {promoCodePreview?.imageUrl && (
                <div
                  className=' my-0 mx-auto rounded w-auto p-2 shadow'
                  style={{ maxWidth: 'fit-content', background: '#F1F8E9' }}
                >
                  <img
                    src={promoCodePreview?.imageUrl}
                    alt='package'
                    className='avatar-sm'
                  />
                </div>
              )}
              <div>
                <h4 className='card-title'> {promoCodePreview?.code} </h4>
                <div className='text-muted'>
                  {promoCodePreview?.description}
                </div>
              </div>
            </Col>
            <Col sm={12}>
              <div>
                <Table hover>
                  <tbody>
                    <tr>
                      <td className='font-weight-bold'>Name</td>
                      <td className='text-uppercase'>
                        {promoCodePreview?.name}
                      </td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Category</td>
                      <td>{promoCodePreview?.category}</td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Value</td>
                      <td> {promoCodePreview?.value}</td>
                    </tr>
                    {promoCodePreview?.point && (
                      <tr>
                        <td className='font-weight-bold'>Point</td>
                        <td> {promoCodePreview?.point}</td>
                      </tr>
                    )}
                    <tr>
                      <td className='font-weight-bold'>Total / Balance</td>
                      <td>
                        {' '}
                        {promoCodePreview?.totalAtStart} /{' '}
                        {promoCodePreview?.total}
                      </td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Package Offers</td>
                      <td> {promoCodePreview?.packageOffer}</td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Start On</td>
                      <td>
                        {Moment(promoCodePreview?.startDate).format('lll')}
                      </td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold'>Expiry Date</td>
                      <td> {Moment(promoCodePreview?.expiry).format('lll')}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className='text-center'>
                <h4 className='card-title'> Packages: </h4>
                {promoCodePreview?.packages &&
                promoCodePreview?.packages.length !== 0
                  ? promoCodePreview?.packages.map((data) => (
                      <span
                        key={data?.id}
                        className='badge badge-secondary rounded mr-2 mb-1'
                      >
                        {' '}
                        #{data?.name}
                      </span>
                    ))
                  : 'No Packages'}

                <Link to={`/redeem_history/${previewId}`}>
                  <button className='btn btn-primary mt-4 w-100 btn-sm'>
                    Show Redeem History
                  </button>
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { promoCodePreview, loading, promoCodeError } = state.PromoCode;
  return { promoCodePreview, loading, promoCodeError };
};

export default connect(mapStateToProps, { fetchEachPromoCode })(PromoCode);
