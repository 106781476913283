import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  UncontrolledTooltip,
} from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import AutoComplete from "../../../../components/Common/Autocomplete";
import { AvForm, AvField } from "availity-reactstrap-validation";
import CustomSelect from "../../../../components/Common/MoreDropdown/CustomSelect";

// actions
import {
  unassignRole,
  assignRole,
  assignRoleFailed,
  fetchUserDetails,
  fetchUserDetailsError,
} from "../../../../store/actions";
const Form = ({
  unassignRole,
  assignRole,
  isUnassign,
  assignRoleFailed,
  message,
  history,
  roleError,
  assignedRole,
  loading,
  roles,
  fetchUserDetails,
  fetchUserDetailsError,
  user,
  fetchRoles,
}) => {
  const [errorMessage, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selected, setSelected] = useState([]);
  const [roleOption, SetRoleOption] = useState([]);
  const handleSubmit = async (event, values) => {
    setLoading(true);
    if (!selectedUser) {
      setError(
        `Please choose a user to ${
          isUnassign ? "unassign a role from" : "assign a role to"
        } `
      );
      return;
    }
    if (values.role === "loading") {
      setError(`Please select a role`);
      return;
    }
    let roleIds = [];
    selected.forEach((data) => {
      roleIds.push({ id: data.value });
    });

    let userId = selectedUser.value;
    if (isUnassign) {
      unassignRole(userId, roleIds);
      setLoading(false);
      return;
    }
    assignRole(userId, roleIds);
    // console.log(roleIds);
    setLoading(false);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        assignRoleFailed("");
        history.goBack();
      }, 2000);
    }

    if (roles) {
      const roleArr = [];
      roles.result.forEach((element) => {
        const roleObj = {};
        roleObj.value = element.id;
        roleObj.label = element.name;
        roleArr.push(roleObj);
      });
      SetRoleOption(roleArr);
    }

    // if (user && user?.roles?.length > 0) {
    //   const userRoleArr = [];
    //   if (user.roles) {
    //     user.roles.forEach((element) => {
    //       const userRoleObj = {};
    //       userRoleObj.value = element.id;
    //       userRoleObj.label = `${element.name}`;
    //       userRoleArr.push(userRoleObj);
    //     });
    //   }
    //   setSelected(userRoleArr);
    // }
  }, [assignRoleFailed, message, roles, user]);

  useEffect(() => {
    assignRoleFailed("");
    fetchUserDetailsError("");
    setSelected([]);
  }, []);

  useEffect(() => {
    if (selectedUser) {
      fetchUserDetails(selectedUser.value);
    }
  }, [selectedUser]);

  const handleFetchMore = () => {
    fetchRoles({ take: roleOption.length + 10, skip: 0 });
  };

  return (
    <Card className="col-md-6">
      <CardBody>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        {roleError && (
          <Alert color="danger" className="text-center">
            {roleError === "User with role already existing"
              ? "Role already assigned to this user"
              : roleError}
          </Alert>
        )}
        {assignedRole && (
          <Alert color="danger" className="text-center">
            {assignedRole}
          </Alert>
        )}
        {errorMessage && (
          <Alert color="danger" className="text-center">
            {errorMessage}
          </Alert>
        )}
        <div className="float-right">
          <Link to="#" onClick={() => history.goBack()}>
            <i className="fas fa-arrow-left mr-3" />
            Back
          </Link>
        </div>
        <div className="mb-3">
          <h6> {isUnassign ? "Unassign" : "Assign"} Role </h6>
        </div>
        <div className="col-md-12">
          <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <FormGroup className="mb-2">
                  <AutoComplete setUser={(e) => setSelectedUser(e)} />
                </FormGroup>

                <FormGroup>
                  <div className="d-flex align-items-center mb-1">
                    <Label htmlFor="role" className="m-0">
                      Role
                    </Label>
                    <i
                      className="mdi mdi-alert-circle-outline ml-2 font-size-16"
                      id="priorityTooltip"
                    ></i>
                    <UncontrolledTooltip
                      placement="top"
                      target="priorityTooltip"
                    >
                      Roles selected in the box are{" "}
                      {isUnassign ? "unassigned" : "assigned"}
                    </UncontrolledTooltip>
                  </div>

                  <div className="CustomSelect">
                    <CustomSelect
                      options={roleOption}
                      selected={selected}
                      onChange={setSelected}
                      fetchMore={
                        roleOption.length !== roles?.total
                          ? handleFetchMore
                          : null
                      }
                      labelledBy="Select roles ..."
                    ></CustomSelect>
                  </div>
                </FormGroup>
                {!loading ? (
                  <>
                    {user && (
                      <div
                        className="mb-4 d-flex text-truncate"
                        style={{
                          flexWrap: "wrap",
                          maxHeight: "200px",
                          overflowY: "visible",
                        }}
                      >
                        {user?.roles?.length > 0
                          ? user?.roles?.map((role) => (
                              <div key={role.id} className="ml-2">
                                <span
                                  className={`badge badge-soft-secondary font-size-12`}
                                >
                                  {role.name}
                                </span>
                              </div>
                            ))
                          : "No role is assigned to this user"}
                      </div>
                    )}
                  </>
                ) : (
                  <em>Loading Data...</em>
                )}
              </Col>
            </Row>

            <button className="btn btn-success mr-2" type="submit">
              {isLoading
                ? "Submitting ..."
                : `${isUnassign ? "Unassign" : "Assign"}`}
            </button>
            <Link to="#" onClick={() => history.goBack()}>
              <button className="btn btn-secondary">Cancel</button>
            </Link>
          </AvForm>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { assignedRole, message, roleError } = state.Role;
  const { user, loading } = state.Users;
  return { user, assignedRole, message, loading, roleError };
};

export default connect(mapStateToProps, {
  unassignRole,
  assignRole,
  assignRoleFailed,
  fetchUserDetails,
  fetchUserDetailsError,
})(withRouter(Form));
