import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import AutoComplete from '../../../../../components/Common/Autocomplete';
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import {
  updateApp_version,
  createApp_version,
  createApp_versionFailed,
} from '../../../../../store/actions';
const Form = ({
  createApp_version,
  updateApp_version,
  createApp_versionFailed,
  message,
  history,
  app_versionError,
  defaultValue,
  loading,
}) => {
  const [errorMessage, setError] = useState('');
  const [description, setDescription] = useState(' ');
  const handleSubmit = async (event, values) => {
    if (!description) {
      setError('Please key in the description');
      return;
    }
    const app_versionData = {
      ...values,
    };
    app_versionData.description = description;
    if (defaultValue) {
      app_versionData.id = defaultValue.id;
      updateApp_version(app_versionData);
      return;
    }
    createApp_version(app_versionData);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        createApp_versionFailed('');
        history.goBack();
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    createApp_versionFailed('');
  }, []);

  return (
    <Card className="col-md-6">
      <CardBody>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        {app_versionError && (
          <Alert color="danger" className="text-center">
            {app_versionError}
          </Alert>
        )}
        {errorMessage && (
          <Alert color="danger" className="text-center">
            {errorMessage}
          </Alert>
        )}
        <div className="float-right">
          <Link to="#" onClick={() => history.goBack()}>
            <i className="fas fa-arrow-left mr-3" />
            Back
          </Link>
        </div>
        <div className="mb-3">
          <h6> {defaultValue ? 'Update' : 'Create'} App Version </h6>
        </div>
        <div className="col-md-12">
          <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
            <Row>
              <Col>
                <FormGroup className="mb-4">
                  <Label htmlFor="name">Name</Label>
                  <AvField
                    name="name"
                    type="text"
                    className="form-control bg-light"
                    id="name"
                    value={defaultValue ? defaultValue.name : ''}
                    required
                    placeholder="Enter App_version Name ..."
                  />
                </FormGroup>

                <FormGroup className="mb-4">
                  <Label htmlFor="build"> Build {' '}<code>(versionName)</code></Label>
                  <AvField
                    className="form-control bg-light"
                    name="build"
                    type="text"
                    value={defaultValue ? defaultValue.build : ''}
                    required
                    placeholder="Enter the build number ..."
                  />
                </FormGroup>

                <FormGroup className=" mb-4">
                  <Label htmlFor="version"> Version {' '}<code>(versionCode)</code></Label>
                  <AvField
                    name="version"
                    type="text"
                    className="form-control bg-light"
                    id="version"
                    value={defaultValue ? defaultValue.version : ''}
                    required
                    placeholder="Enter the version number ..."
                  />
                </FormGroup>

                <FormGroup className=" mb-4">
                  <AvField
                    className="form-control bg-light"
                    type="select"
                    name="type"
                    label="Type"
                    value={defaultValue ? defaultValue.type : 'IOS_DRIVER'}
                  >
                    <option value={'IOS_DRIVER'}>IOS_DRIVER</option>
                    <option value={'IOS_RIDER'}>IOS_RIDER</option>
                    <option value={'ANDROID_DRIVER'}>ANDROID_DRIVER</option>
                    <option value={'ANDROID_RIDER'}>ANDROID_RIDER</option>
                  </AvField>
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="description"> Description</Label>
                  <textarea
                    className="form-control"
                    onChange={(e) => setDescription(e.target.value)}
                    id="description"
                    value={description}
                    rows="5"
                  // ref={descriptionRef}
                  ></textarea>
                </FormGroup>
              </Col>
            </Row>

            <button className="btn btn-success mr-2" type="submit">
              {loading
                ? 'Submitting ...'
                : `${defaultValue ? 'Update' : 'Create'}`}
            </button>
            <Link to="#" onClick={() => history.goBack()}>
              <button className="btn btn-secondary">Cancel</button>
            </Link>
          </AvForm>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, app_versionError } = state.App_version;
  return { message, loading, app_versionError };
};

export default connect(mapStateToProps, {
  createApp_version,
  updateApp_version,
  createApp_versionFailed,
})(withRouter(Form));
