import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Button,
  Row,
  Alert,
  FormGroup,
  Col,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import BootstrapTable from 'react-bootstrap-table-next';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import ReactPaginate from 'react-paginate';
import Loader from '../../../../components/Common/Loading/index.js';
import FilterDropDown from '../../../../components/Common/FilterDropDown/index.js';
import SimpleBar from 'simplebar-react';
import Moment from 'moment';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../../utils/general.js';
import queryString from 'query-string';

let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const UsersInvitationTable = ({
  invitation,
  match,
  userId,
  tableError,
  fetchEachInvitation,
  statusHandler,
  history,
  message,
  user,
  CheckAction,
}) => {
  const [data, setData] = useState(null);
  const [skipPage, setSkipPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [showModal, setShowModal] = useState('');
  const [date, setDate] = useState({
    start: null,
    end: null,
  });

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(invitation?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,

      // start: date.start,
      // end: date.end,
    };
    fetchEachInvitation(pageInfo);
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = {
      skip,
      take,

      // start: date.start,
      // end: date.end,
    };
    setSkipPage(Math.ceil(pageNumber / take));
    fetchEachInvitation(userId, pageInfo);

  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          take,
          skip,
          // start: date.start,
          // end: date.end,
        };
        fetchEachInvitation(userId, queryParams);
      }, 2000);
    }
  }, [fetchEachInvitation, match, message]);

  useEffect(() => {
    if (invitation) {
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(invitation?.total / take));
    }
  }, [invitation]);

  useEffect(() => {
    let parsedUrlParams = {
      skip,
      take: dataLimit,
    };
    if (userId) {
      fetchEachInvitation(userId, parsedUrlParams);
    }
  }, [fetchEachInvitation]);

  const tableDataHandler = () => {
    const rowData = invitation?.result?.map((data, i) => {
      return {
        id: data?.id,
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,
        referee: (
          <Link
            to='#'
            className='text-truncate text-dark font-weight-bold d-flex flex-column align-items-center'
            style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}
          >
            <img
              src={data?.picture ? data?.picture : avatarImg}
              alt=''
              height='20'
              className='rounded-circle avatar-xs mb-2'
            />
            <div className='text-capitalize mb-2'>
              {data?.firstName} {data?.lastName}
            </div>
            <div className='d-flex align-items-center'>
              {moment(data.createdAt).format('DD, MMM, YYYY')}.{' '}
              {Moment(data.createdAt).format('hh:mm a')}
            </div>
          </Link>
        ),

        userType: (
          <h6
            className=' mr-2 badge'
            style={{
              color: `${data?.userType ? 'white' : 'black'}`,
              background: `${data?.userType === 'RIDER'
                ? '#f18357'
                : data?.userType === 'DRIVER'
                  ? '#00a859'
                  : 'transparent'
                }`,
            }}
          >
            {data?.userType ? data?.userType : ' ---'}
          </h6>
        ),
        reminder: '----',
        earnedHistory: (
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <h6 className='mt-4 text-center text-dark font-weight-bold'>
              ₦{data?.total ? data?.total : 0}
            </h6>
            <div className='d-flex align-items-center'>
              {moment(data.updatedAt).format('DD, MMM, YYYY')}.{' '}
              {Moment(data.updatedAt).format('hh:mm a')}
            </div>
          </div>
        ),
        status: (
          <>
            <div className='d-flex align-items-center justify-content-between font-size-12'>
              <p className='badge badge-soft-info font-size-10 m-0'>ACCEPTED</p>

              {data?.status?.toLowerCase() === 'accepted' ||
                data?.status?.toLowerCase() === 'pending' ||
                data?.status?.toLowerCase() === 'joined' ||
                data?.status?.toLowerCase() === 'completed' ? (
                <div className='badge badge-info font-size-8 m-0 rounded-circle'>
                  <i className='fas fa-check font-size-8 text-white'></i>
                </div>
              ) : null}
            </div>
            <div className='d-flex align-items-center justify-content-between font-size-12'>
              <p className='badge badge-soft-warning font-size-10 m-0'>
                SIGNUP
              </p>
              {data?.status?.toLowerCase() === 'accepted' ||
                data?.status?.toLowerCase() === 'pending' ||
                data?.status?.toLowerCase() === 'joined' ||
                data?.status?.toLowerCase() === 'completed' ? (
                <div className='badge badge-warning font-size-8 m-0 rounded-circle'>
                  <i className='fas fa-check font-size-8 text-white'></i>
                </div>
              ) : null}
            </div>
            <div className='d-flex align-items-center justify-content-between font-size-12'>
              <p className='badge badge-soft-success font-size-10 m-0'>
                COMPLETED PROFILE
              </p>

              {data?.status?.toLowerCase() === 'joined' ||
                data?.status?.toLowerCase() === 'completed' ? (
                <div className='badge badge-success font-size-8 m-0 rounded-circle'>
                  <i className='fas fa-check font-size-8 text-white'></i>
                </div>
              ) : null}
            </div>
            <div className='d-flex align-items-center justify-content-between font-size-12'>
              <p className='badge badge-soft-primary font-size-10 m-0'>
                COMPLETED TRIPS
              </p>
              {data?.status?.toLowerCase() === 'completed' ? (
                <div className='badge badge-primary font-size-8 m-0 rounded-circle'>
                  <i className='fas fa-check font-size-8 text-white '></i>
                </div>
              ) : null}
            </div>
          </>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
          headerStyle: { width: '70px' },
        },
        {
          text: 'Referee',
          dataField: 'referee',
          headerStyle: { textAlign: 'center', width: '200px' },
        },
        {
          text: 'UserType',
          dataField: 'userType',
          classes: 'text-center',
          headerStyle: { textAlign: 'center' },
          sort: true,
        },
        {
          text: 'Status',
          dataField: 'status',
          headerStyle: { textAlign: 'center' },
        },
        {
          text: 'Reminded to Complete',
          dataField: 'reminder',
          classes: 'text-center',
          headerStyle: { textAlign: 'center' },
          sort: true,
        },
        {
          text: 'Money Earned',
          dataField: 'earnedHistory',
          headerStyle: { textAlign: 'center' },
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        {user && (
          <Col lg={12}>
            <Card>
              <CardBody>
                <div>
                  <Link
                    to='#'
                    onClick={() => history.goBack()}
                    className='text-dark float-right'
                  >
                    <h6>Back</h6>
                  </Link>
                  <Row className='mt-2 align-items-center responsive-profile-container'>
                    <Col
                      className='pl-3 mb-2 px-0 py-0 responsive-profile'
                      sm={7}
                    >
                      <div className='align-items-center d-flex responsive-profile-text'>
                        <div className='ml-3 mr-1 d-flex position-relative'>
                          <img
                            className='rounded-circle img-thumbnail p-2'
                            alt='Profile'
                            src={user?.picture ? user?.picture : avatarImg}
                            onClick={() => {
                              // setShowModal(true);
                            }}
                            style={
                              user?.picture
                                ? {
                                  width: '13rem',
                                  height: '13rem',
                                  cursor: 'pointer',
                                }
                                : {
                                  width: '13rem',
                                  height: '13rem',
                                  pointerEvents: 'none',
                                }
                            }
                          />
                          <div
                            className={`rounded-circle badge badge-soft-${user?.isActive === true ? 'success' : 'danger'
                              } font-size-22 align-self-end`}
                            style={{
                              position: 'absolute',
                              bottom: '10px',
                              right: '20px',
                            }}
                          >
                            {user?.isActive === true ? (
                              <i className='fas fa-dot-circle'></i>
                            ) : (
                              <i className=' fas fa-minus-circle'></i>
                            )}
                          </div>
                        </div>
                        <div className='mt-3'>
                          <h2 className='font-weight-bold mb-2 text-capitalize'>
                            {user?.firstName} {user?.lastName}
                          </h2>

                          <div className='mb-2'>
                            <h3
                              className='font-size-22 text-capitalize'
                              style={{ color: 'grey' }}
                            >
                              {user?.userType?.toLowerCase()}
                            </h3>
                          </div>
                          <div className='d-flex align-items-center'>
                            <div className='badge badge-soft-secondary'>
                              <span className='mr-1'>
                                <i className=' fas fa-star text-warning mr-1' />
                                {user?.rating}
                              </span>
                            </div>
                            <h6 className='ml-1 m-0 font-size-10'>
                              ({invitation?.total})
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col
                      sm={5}
                      className='pr-5 button-container d-flex flex-column align-items-end'
                    >
                      <>
                        <Row className='mr-5'>
                          <div className='d-flex flex-column mr-4'>
                            <h6 className='mb-2 text-upper font-size-12 font-weight-bold text-secondary'>
                              Balance{' '}
                            </h6>
                            <h6 className='text-success font-weight-bold'>
                              {user?.account?.currency}{' '}
                              {parseInt(
                                user?.account?.availableBalance
                              )?.toLocaleString()}
                            </h6>
                          </div>
                        </Row>
                        <CheckAction field='Referral' iconType={'Edit'}>
                          <Row className='mr-4 mb-3 buttons'>
                            <Button
                              className='btn btn-success px-3'
                              onClick={() => statusHandler()}
                            >
                              Off Set Reward
                            </Button>
                          </Row>
                        </CheckAction>
                      </>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
        <Col lg={12}>
          <h6>Invitation History</h6>
          <Card>
            <CardBody className='pt-3'>
              {tableError !== null ? (
                <Alert color='danger' className='text-center'>
                  {tableError}
                </Alert>
              ) : (
                <>
                  {!isLoading && data !== null ? (
                    <>
                      <BootstrapTable
                        keyField='id'
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses='table-responsive'
                        noDataIndication={'No Data to Display'}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        // breakLabel={'...'}
                        // marginPagesDisplayed={2}
                        // pageRangeDisplayed={3}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        forcePage={skipPage}
                        //Bootstrap styling
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UsersInvitationTable;
