import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import MiniWidgets from './Components/widget';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import UserTable from './userTable';
import UserDocPreview from './UserDocPreview';
import DocPreview from './Document/previewDoc';
import GreySummaryCard from '../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../components/Common/Summary/actionCheck';
// import ReadCheck from '../../../components/Common/Summary/ReadCheck';
//Import Action to copy breadcrumb items from local state to redux state
import {
  fetchAllUser,
  freezeAccount,
  deleteUserFailed,
  freezeAccountFailed,
  fetchUserSummary,
  fetchUsersForCSV,
  fetchForCSVError,
  deleteUser,
  fetchUserExport,
  fetchUserExportFailed,
} from '../../../store/actions';

const Dashboard = ({
  users,
  fetchAllUser,
  history,
  summaryError,
  accessControl,
  fetchUsersForCSV,
  fetchForCSVError,
  csvData,
  csv_loading,
  summaryCount,
  deleteUserFailed,
  freezeAccount,
  deleteUser,
  freezeAccountFailed,
  loading,
  freezeMessage,
  userError,
  isAuthorize,
  message,
  fetchUserExport,
  fetchUserExportFailed,
  exportMessage,
  exportError,
  exportFile,
}) => {
  const [showUserDocModal, setShowUserDocModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('');
  const [docIsPdf, setDocIsPdf] = useState(false);
  const [docIsImg, setDocIsImg] = useState(false);
  const [docName, setDocName] = useState(null);
  const [previewId, setPreviewId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [dateFilter, setDate] = useState({});
  const breadcrumbItems = [
    { title: 'nRyde', link: '#' },
    { title: 'Dashboard', link: '#' },
  ];

  useEffect(() => {
    if (freezeMessage) {
      setTimeout(() => {
        freezeAccountFailed('');
      }, 2000);
    }
  }, [freezeMessage]);
  useEffect(() => {
    if (exportMessage || exportError) {
      setTimeout(() => {
        fetchUserExportFailed('');
      }, 2000);
    }
  }, [exportMessage, exportError]);

  useEffect(() => {
    if (userError) {
      setTimeout(() => {
        deleteUserFailed('');
      }, 2000);
    }
  }, [userError]);

  const updateUserDocInfo = (id, name) => {
    setShowUserDocModal(true);
    setUserId(id);
    setUserName(name);
  };
  const previewHandler = (name, id, isImg, isDoc) => {
    setShowPreviewModal(true);
    setPreviewId(id);
    setDocName(name);
    setDocIsImg(isImg);
    setDocIsPdf(isDoc);
  };
  const deleteUserModal = (data) => {
    setShowDeleteModal(true);
    setDeleteData(data);
  };

  const deleteHandler = () => {
    setShowDeleteModal(false);
    const formData = {
      id: deleteData.id,
      email: deleteData.email,
    };
    deleteUser(formData);
    setDeleteData(null);
  };

  const date = (e) => {
    setDate(e);
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Users' breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field='Users' subModule='Users'>
            {freezeMessage && (
              <Alert color='success' className='text-center'>
                Account Freeze successfully
              </Alert>
            )}
            {userError && (
              <Alert color='danger' className='text-center'>
                {userError}
              </Alert>
            )}
            {message && (
              <Alert color='success' className='text-center'>
                {message}
              </Alert>
            )}
            {isAuthorize ? (
              <Alert color='danger' className='text-center'>
                {isAuthorize}
              </Alert>
            ) : (
              <Row>
                <Col>
                  <Col sm={12}>
                    <Row>
                      <MiniWidgets date={dateFilter} />
                    </Row>
                  </Col>
                  <UserTable
                    history={history}
                    dateFilter={date}
                    loading={loading}
                    freezeAccount={freezeAccount}
                    fetchAllUser={fetchAllUser}
                    fetchUsersForCSV={fetchUsersForCSV}
                    fetchForCSVError={fetchForCSVError}
                    csvData={csvData}
                    csv_loading={csv_loading}
                    updateUserDocInfo={updateUserDocInfo}
                    deleteUser={deleteUserModal}
                    message={message}
                    fetchUserExport={fetchUserExport}
                    fetchUserExportFailed={fetchUserExportFailed}
                    exportFile={exportFile}
                    CheckAction={CheckAction}
                    ReadCheck={ReadCheck}
                    accessControl={accessControl}

                  />
                </Col>
              </Row>
            )}
          </GreySummaryCard>
        </Container>
        <Modal
          isOpen={showUserDocModal}
          toggle={() => setShowUserDocModal(!showUserDocModal)}
        >
          <ModalHeader toggle={() => setShowUserDocModal(false)}>
            {userName}'s Documents
          </ModalHeader>
          <ModalBody>
            <UserDocPreview userId={userId} previewAction={previewHandler} />
          </ModalBody>
        </Modal>
        <Modal
          size={`${docIsPdf ? 'lg' : 'md'}`}
          isOpen={showPreviewModal}
          toggle={() => setShowPreviewModal(!showPreviewModal)}
        >
          <ModalHeader toggle={() => setShowPreviewModal(false)}>
            {docName ? docName : 'Preview Document'}
          </ModalHeader>
          <ModalBody>
            <DocPreview
              previewId={previewId}
              docIsPdf={docIsPdf}
              docIsImg={docIsImg}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={showDeleteModal}
          toggle={() => setShowDeleteModal(!showDeleteModal)}
        >
          <ModalBody>
            <h4 className='card-title mb-4 text-center'>
              {' '}
              Are you sure you want to delete this user
            </h4>
            <button
              onClick={() => deleteHandler()}
              className='btn btn-outline-danger btn-sm w-100'
            >
              Click to Delete
            </button>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const {
    csvData,
    csv_loading,
    users,
    loading,
    userError,
    isAuthorize,
    freezeMessage,
    summaryError,
    summaryCount,
    message,
    exportMessage,
    exportError,
    exportFile,
  } = state.Users;
  return {
    csvData,
    csv_loading,
    users,
    loading,
    userError,
    accessControl,
    isAuthorize,
    summaryError,
    summaryCount,
    freezeMessage,
    message,
    exportMessage,
    exportError,
    exportFile,
  };
};

export default connect(mapStateToProps, {
  fetchAllUser,
  freezeAccount,
  freezeAccountFailed,
  deleteUserFailed,
  deleteUser,
  fetchUsersForCSV,
  fetchForCSVError,
  fetchUserExport,
  fetchUserExportFailed,
})(withRouter(Dashboard));
