import {
  FETCH_CONTACTS,
  FETCH_CONTACTS_SUCCESSFUL,
  FETCH_CONTACTS_API_FAILED,
  FETCH_SOS,
  FETCH_SOS_SUCCESSFUL,
  FETCH_SOS_API_FAILED,
  FETCH_EACH_CONTACT,
  FETCH_EACH_CONTACT_SUCCESSFUL,
  FETCH_EACH_CONTACT_API_FAILED,
  FETCH_EACH_SOS,
  FETCH_EACH_SOS_SUCCESSFUL,
  FETCH_EACH_SOS_API_FAILED,
  DELETE_CONTACT,
  DELETE_CONTACT_SUCCESSFUL,
  DELETE_CONTACT_FAILED,
  CREATE_CONTACT,
  CREATE_CONTACT_SUCCESSFUL,
  CREATE_CONTACT_FAILED,
  UPDATE_CONTACT,
  UPDATE_CONTACT_SUCCESSFUL,
  UPDATE_CONTACT_FAILED,
  UPDATE_SOS,
  UPDATE_SOS_SUCCESSFUL,
  UPDATE_SOS_FAILED,
} from './actionTypes';

const initialState = {
  contacts: null,
  contactError: null,
  tableError: null,
  contact: null,
  isAuthorize: null,
  message: null,
  loading: false,
  all_sos: null,
  sosError: null,
  sos: null,
};

const Contact = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SOS:
    case FETCH_CONTACTS:
    case UPDATE_CONTACT:
    case UPDATE_SOS:
    case DELETE_CONTACT:
      state = {
        ...state,
        contacts: null,
        tableError: null,
        contactError: null,
        loading: true,
        message: null,
        isAuthorize: null,
        all_sos: null,
        sosError: null,
        sos: null,
      };
      break;
    case FETCH_CONTACTS_SUCCESSFUL:
      state = {
        ...state,
        contacts: action.payload,
        tableError: null,
        contactError: null,
        loading: false,
      };
      break;

    case FETCH_SOS_SUCCESSFUL:
      state = {
        ...state,
        all_sos: action.payload,
        tableError: null,
        contactError: null,
        loading: false,
        contacts: null,
        sosError: null,
      };
      break;

    case DELETE_CONTACT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        contactError: null,
        message: 'Emergency Contact deleted successfully',
      };
      break;

    case UPDATE_SOS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        sosError: null,
        message: 'SOS Updated Successfully',
      };
      break;

    case UPDATE_CONTACT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        contactError: null,
        message: 'Emergency Contact Updated Successfully',
      };
      break;

    case FETCH_EACH_CONTACT:
      state = {
        ...state,
        loading: true,
        contacts: null,
        contactError: null,
        message: null,
        isAuthorize: null,
      };
      break;

    case FETCH_EACH_CONTACT_SUCCESSFUL:
      state = {
        ...state,
        contact: action.payload,
        loading: false,
        contactError: null,
        message: null,
      };
      break;

    case FETCH_EACH_SOS:
      state = {
        ...state,
        loading: true,
        all_sos: null,
        sosError: null,
        message: null,
        isAuthorize: null,
      };
      break;

    case FETCH_EACH_SOS_SUCCESSFUL:
      state = {
        ...state,
        sos: action.payload,
        loading: false,
        sosError: null,
        message: null,
      };
      break;

    case CREATE_CONTACT:
      state = {
        ...state,
        loading: true,
        contactError: null,
        message: null,
      };
      break;

    case CREATE_CONTACT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        contactError: null,
        message: `Emergency Contact  created successfully`,
      };
      break;

    case FETCH_CONTACTS_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case FETCH_SOS_API_FAILED:
      return {
        ...state,
        sosError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case CREATE_CONTACT_FAILED:
    case DELETE_CONTACT_FAILED:
    case FETCH_EACH_CONTACT_API_FAILED:
    case FETCH_EACH_SOS_API_FAILED:
      state = {
        ...state,
        contacts: null,
        contact: null,
        sos: null,
        all_sos: null,
        loading: false,
        message: null,
        sosError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        contactError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_CONTACT_FAILED:
    case UPDATE_SOS_FAILED:
      state = {
        ...state,
        loading: false,
        sosError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        contactError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Contact;
