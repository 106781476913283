import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Alert,
  FormGroup,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ReactPaginate from 'react-paginate';
import queryString from 'query-string';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import FilterDropDown from '../../../../components/Common/FilterDropDown';
import SimpleBar from 'simplebar-react';
import Loader from '../../../../components/Common/Loading/index.js';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../../utils/general';
import Moment from 'moment';
import moment from 'moment-timezone';

let skip = 0;
let dataLimit = 10;

const LoyaltyTable = ({
  history,
  loyaltyData,
  tableError,
  previewAction,
  fetchAllUser,
  pointHandler,
  message,
  isAuthorize,
  CheckAction,
  ReadCheck,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [skipPage, setSkipPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [menu, setMenu] = useState(false);
  const [status, setStatus] = useState('');

  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const userType = 'RIDER';

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(loyaltyData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      search,
      userType,
      status,
    };
    fetchAllUser(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take, search, userType, status };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchAllUser(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      // skip,
      userType,
      status,
    };
    fetchAllUser(queryParams);
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    setMenu(false);
    setStatus(values.loyaltyStatus);
    let queryParams = {
      search,
      take,
      skip,
      userType,
      status: values.loyaltyStatus,
      start: values.startDate,
      end: values.endDate,
    };
    fetchAllUser(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    });
    let queryParams = {
      search,
      take,
      skip,
      status,
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    };
    fetchAllUser(queryParams);
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          userType,
          status,
        };
        fetchAllUser(queryParams);
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    if (loyaltyData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(loyaltyData?.total / take));
    }
  }, [loyaltyData]);

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history?.location?.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        take,
        skip,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.status) {
      setStatus(parsedUrlParams?.status);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchAllUser(parsedUrlParams);
  }, [fetchAllUser]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      userType,
      status,
      take,
      skip: pageNumber,
    };
    let result = QueryReallignment(queryParams);

    history.push({
      pathname: history?.location.pathname,
      search: result,
    });
  };

  let accessChecking = ReadCheck(accessControl, `Loyalty`, 'Driver Loyalties');
  const tableDataHandler = () => {
    const rowData = loyaltyData?.result?.map((data, i) => {
      return {
        id: data.id.substring(0, 7),
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,
        name: (
          <>
            {accessChecking ?

              <Link
                to={`/loyalty_preview?id=${data?.id}`}
                className='text-dark font-weight-bold text-capitalize'
              >
                {' '}
                {data?.firstName} {data?.lastName}
              </Link>

              :
              <div className='text-dark font-weight-bold text-capitalize'>
                {data?.firstName} {data?.lastName}
              </div>
            }
          </>
        ),

        point: (
          <>
            <span className='d-block'>
              Total :{' '}
              {data?.loyalty?.accumulatedPoint
                ? data?.loyalty?.accumulatedPoint
                : '___'}
            </span>
            <span className='d-flex align-items-center'>
              Balance : {data?.loyalty?.point ? data?.loyalty?.point : '___'}
            </span>
          </>
        ),
        tierStartDate: data?.loyalty?.tierStartDate ? (
          <div>
            {' '}
            <div>{Moment(data?.loyalty?.tierStartDate).format('l')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data?.loyalty?.tierStartDate).format('LT')}
            </div>
          </div>
        ) : (
          '___'
        ),
        tier: data?.loyalty?.tier,
        status: data?.loyalty?.status ? (
          <div
            className={`badge badge-soft-${data?.loyalty?.status === 'ACTIVE' ? 'success' : 'danger'
              } font-size-12`}
          >
            {data?.loyalty?.status}
          </div>
        ) : (
          '___'
        ),
        action: (
          <CheckAction field='Loyalty' subModule='Rider Loyalties' iconType='Edit'>
            <div className='d-flex'>
              <Link
                to={`#`}
                onClick={() => pointHandler(data.id, 'credit')}
                className='mr-3 text-success'
                id='increase'
              >
                <i className='mdi mdi-pencil-plus font-size-16'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='increase'>
                Increase points
              </UncontrolledTooltip>
              <Link
                to={`#`}
                onClick={() => pointHandler(data.id, 'debit')}
                className='mr-3 text-danger'
                id='decrease'
              >
                <i className='mdi mdi-pencil-minus font-size-16'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='decrease'>
                Decrease points
              </UncontrolledTooltip>
            </div>
          </CheckAction>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
        },
        {
          text: 'Loyalty Users',
          dataField: 'name',
          sort: true,
        },
        {
          text: 'Current Tier',
          dataField: 'tier',
        },
        {
          text: 'Points',
          dataField: 'point',
        },
        {
          text: 'Tier Start Date',
          dataField: 'tierStartDate',
        },
        {
          text: 'Status',
          dataField: 'status',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {isAuthorize !== null ? (
                <Alert color='danger' className='text-center'>
                  {isAuthorize}
                </Alert>
              ) : (
                <>
                  {!isLoading && data !== null ? (
                    <>
                      <div className='d-flex justify-content-end mb-2'>
                        <FilterDropDown
                          menu={menu}
                          toggleMenu={() => setMenu(!menu)}
                        >
                          <div>
                            <AvForm
                              className='form-horizontal'
                              onValidSubmit={handleDateFilter}
                            >
                              <SimpleBar
                                style={{
                                  maxHeight: '250px',
                                }}
                              >
                                <Row className='px-3  pt-3 pb-0'>
                                  <Col md='12'>
                                    <FormGroup>
                                      <AvField
                                        name='startDate'
                                        type='date'
                                        className='form-control bg-light'
                                        id='startDate'
                                        value={date.start}
                                        helpMessage='Start Date'
                                        placeholder='Please choose the start date...'
                                        required
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md='12'>
                                    <FormGroup>
                                      <AvField
                                        name='endDate'
                                        type='date'
                                        className='form-control bg-light'
                                        id='endDate'
                                        value={date.end}
                                        helpMessage='End Date'
                                        placeholder='Please choose the end date...'
                                        required
                                      />
                                    </FormGroup>
                                  </Col>{' '}
                                  <Col md='12'>
                                    <FormGroup>
                                      <AvField
                                        type='select'
                                        value={status}
                                        name='loyaltyStatus'
                                        helpMessage='status'
                                      >
                                        <option value=''>All</option>
                                        <option value='ACTIVE'>ACTIVE</option>
                                        <option value='INACTIVE'>
                                          INACTIVE
                                        </option>
                                      </AvField>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </SimpleBar>

                              <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                                <Link
                                  to='#'
                                  onClick={resetHandler}
                                  className='btn btn-sm  bg-light font-size-14'
                                >
                                  <i className=' mdi mdi-refresh mr-1'>
                                    {' '}
                                    Reset
                                  </i>
                                </Link>

                                <button
                                  className='btn btn-success btn-sm mr-2'
                                  type='submit'
                                >
                                  <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                  Send
                                </button>
                              </div>
                            </AvForm>
                          </div>
                        </FilterDropDown>
                      </div>

                      <div className='d-flex justify-content-between mb-2'>
                        <div>
                          <Input
                            bsSize='sm'
                            id='take'
                            name='take'
                            type='select'
                            value={take}
                            onChange={handleLimitChange}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div className='d-flex'>
                          <Input
                            bsSize='sm'
                            id='search'
                            value={search}
                            name='search'
                            onChange={SearchHandler}
                            placeholder='Search'
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField='id'
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses='table-responsive'
                        classes='react-bootstrap-table'
                        noDataIndication={'No Data to Display'}
                        defaultSorted={[{ dataField: 'index', order: 'desc' }]}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        forcePage={skipPage}
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default LoyaltyTable;
