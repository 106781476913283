export const IS_RIDE_AUTHORIZE = 'IS_RIDE_AUTHORIZE';

export const FETCH_RIDES = 'FETCH_RIDES';
export const FETCH_RIDES_SUCCESSFUL = 'FETCH_RIDES_SUCCESSFUL';
export const FETCH_RIDES_API_FAILED = 'FETCH_RIDES_API_FAILED';

export const FETCH_FOR_CSV = 'FETCH_FOR_CSV';
export const FETCH_FOR_CSV_ERROR = 'FETCH_FOR_CSV_ERROR';
export const FETCH_FOR_CSV_SUCCESSFULLY = 'FETCH_FOR_CSV_SUCCESSFULLY';

// ACCOUNT ENTITIES
export const FETCH_RIDE_SUMMARY = 'FETCH_RIDE_SUMMARY';
export const FETCH_RIDE_SUMMARY_FAILED = 'FETCH_RIDE_SUMMARY_FAILED';
export const FETCH_RIDE_SUMMARY_SUCCESSFULLY =
  'FETCH_RIDE_SUMMARY_SUCCESSFULLY';

// PRICE ENTITIES
export const FETCH_RIDE_PRICE = 'FETCH_RIDE_PRICE';
export const FETCH_RIDE_PRICE_FAILED = 'FETCH_RIDE_PRICE_FAILED';
export const FETCH_RIDE_PRICE_SUCCESSFULLY = 'FETCH_RIDE_PRICE_SUCCESSFULLY';

export const FETCH_EACH_RIDE = 'FETCH_EACH_RIDE';
export const FETCH_EACH_RIDE_SUCCESSFUL = 'FETCH_EACH_RIDE_SUCCESSFUL';
export const FETCH_EACH_RIDE_API_FAILED = 'FETCH_EACH_RIDE_API_FAILED';

export const FETCH_MY_RIDE = 'FETCH_MY_RIDE';
export const FETCH_MY_RIDE_SUCCESSFUL = 'FETCH_MY_RIDE_SUCCESSFUL';
export const FETCH_MY_RIDE_API_FAILED = 'FETCH_MY_RIDE_API_FAILED';

export const RIDE_STATUS_UPDATE = 'RIDE_STATUS_UPDATE';
export const RIDE_STATUS_UPDATE_SUCCESSFUL = 'RIDE_STATUS_UPDATE_SUCCESSFUL';
export const RIDE_STATUS_UPDATE_FAILED = 'RIDE_STATUS_UPDATE_FAILED';

export const DELETE_RIDE = 'DELETE_RIDE';
export const DELETE_RIDE_SUCCESSFUL = 'DELETE_RIDE_SUCCESSFUL';
export const DELETE_RIDE_FAILED = 'DELETE_RIDE_FAILED';

export const CREATE_RIDE = 'CREATE_RIDE';
export const CREATE_RIDE_SUCCESSFUL = 'CREATE_RIDE_SUCCESSFULLY';
export const CREATE_RIDE_FAILED = 'CREATE_RIDE_FAILED';

export const UPDATE_RIDE = 'UPDATE_RIDE';
export const UPDATE_RIDE_SUCCESSFUL = 'UPDATE_RIDE_SUCCESSFUL';
export const UPDATE_RIDE_FAILED = 'FAILED';

export const BROADCAST_RIDE = 'BROADCAST_RIDE';
export const BROADCAST_RIDE_SUCCESSFUL = 'BROADCAST_RIDE_SUCCESSFUL';
export const BROADCAST_RIDE_FAILED = 'BROADCAST_RIDE_FAILED';

export const RIDE_PRICE = 'RIDE_PRICE';
export const RIDE_PRICE_SUCCESSFUL = 'RIDE_PRICE_SUCCESSFUL';
export const RIDE_PRICE_FAILED = 'RIDE_PRICE_FAILED';

export const FETCH_PLACES = 'FETCH_PLACES';
export const FETCH_PLACES_SUCCESSFUL = 'FETCH_PLACES_SUCCESSFUL';
export const FETCH_PLACES_FAILED = 'FETCH_PLACES_FAILED';

export const FETCH_RIDE_LOG = 'FETCH_RIDE_LOG';
export const FETCH_RIDE_LOG_SUCCESSFUL = 'FETCH_RIDE_LOG_SUCCESSFUL';
export const FETCH_RIDE_LOG_API_FAILED = 'FETCH_RIDE_LOG_API_FAILED';

export const CONNECT = 'CONNECT';
export const CONNECT_SUCCESSFUL = 'CONNECT_SUCCESSFULLY';
export const CONNECT_FAILED = 'FAILED';

export const FETCH_RIDE_DECLINE_LOG = 'FETCH_RIDE_DECLINE_LOG';
export const FETCH_RIDE_DECLINE_LOG_SUCCESSFUL =
  'FETCH_RIDE_DECLINE_LOG_SUCCESSFUL';
export const FETCH_RIDE_DECLINE_LOG_API_FAILED =
  'FETCH_RIDE_DECLINE_LOG_API_FAILED';
