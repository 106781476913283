import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Table, Row, Col, Container, Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import Moment from 'moment';
import {
  fetchRides, fetchActivities, fetchDriverOnlineLogs,
  fetchDriverHoursLogs
} from '../../../store/actions';
import { Link } from 'react-router-dom';
import DateComponent, { TimeComponent } from '../DateComponent';
import MapDirection from '../Map/mapDirection2'
//Simple bar
import SimpleBar from 'simplebar-react';
import DeclineLogTable from './DeclineLog';
import { classname } from 'classnames';
import Activities from './previewActivity';

const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const RideHistory = ({
  fetchRides,
  rides,
  fetchActivities,
  activities,
  loading,
  ridesError,
  message,
  user,
  user_location,
  fetchDriverOnlineLogs,
  fetchDriverHoursLogs,
  driverHoursLogs,
  driverHoursLogsError,
  driverOnlineLogs,
  driverOnlineLogsError,
  driverLogs,
}) => {
  const [menu, SetMenu] = useState(false);
  const [userType, setUserType] = useState('driver');
  const [showModal, setShowModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);

  useEffect(() => {
    if (user) {
      fetchRides({ user: user.id });
      fetchActivities({ userId: user.id });
      if (user?.userType === "DRIVER") {
        fetchDriverOnlineLogs({
          driverId: user?.id,
        });
        fetchDriverHoursLogs({
          driverId: user?.id,
        });
      }
    } else {
      fetchRides();
    }
    if (user.userType === 'DRIVER') {
      setUserType('rider');
    }
  }, [fetchRides, user]);
  const ongoingRide =
    rides?.result &&
    rides?.result.find((ride) => ride.status !== "COMPLETED" && ride.status !== "CANCELLED");


  return (
    <React.Fragment>

      {/* // <SimpleBar style={{ maxHeight: '400px' }}> */}
      <div
        style={{
          maxHeight: '700px',
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <Row style={{ flexFlow: 'row' }}>
          <Col sm={4} md={4} >
            <div className="border rounded mb-2" >
              <div className='p-3 d-flex justify-content-between align-items-center' style={{ borderBottom: '2px dotted green', position: 'sticky' }}>
                <h6 className='font-weight-bold'> Recent Trips </h6>
                <Link to={`/rides?user=${user.id}`} className="mr-2 font-size-11 font-weight-bold text-success">
                  See all trips
                </Link>{' '}
              </div>
              {rides?.count > 0 ?
                <>
                  {!loading ? (
                    rides?.result.map((ride, key) => (
                      <div className='trips p-3' key={ride.id} style={{ borderBottom: '2px dotted green' }}>
                        <div className="d-flex justify-content-between align-items-center">
                          {/* <h4 className="card-title text-muted">
                          NR-{ride.id.substring(0, 7)}{' '}
                        </h4> */}
                          <h6 className="font-size-11">
                            {key + 1} / {rides?.total}
                          </h6>
                          <div>
                            <span className="font-weight-bold mr-2 font-size-10">
                              {ride?.cancellation?.requester}
                            </span>
                            <h6
                              className={`badge badge-soft-${ride.status === 'PROCESSING'
                                ? 'warning'
                                : ride.status === 'ACCEPTED'
                                  ? 'success'
                                  : ride.status === 'CANCELLED'
                                    ? 'danger'
                                    : ride.status === 'COMPLETED'
                                      ? 'success'
                                      : 'dark'
                                }`}
                            >
                              {ride.status}{' '}
                            </h6>
                          </div>
                        </div>

                        <div>
                          <div className="d-flex align-items-center mb-1">
                            <img
                              src={
                                ride[userType]?.picture
                                  ? ride[userType]?.picture
                                  : avatarImg
                              }
                              alt=""
                              height="20"
                              className="rounded-circle avatar-xs mr-2"
                            />
                            <div>
                              <h5 className="font-weight-bold font-size-13">
                                {ride[userType] ? (
                                  <>
                                    {ride.status === 'COMPLETED' &&
                                      (ride?.tripDetails?.distance / ride?.distance) *
                                      100 <
                                      30 ? (
                                      <div className="text-capitalize  text-danger">
                                        {ride[userType]?.firstName}{' '}
                                        {ride[userType]?.lastName}
                                        <i className="fa fa-ban font-weight-bold ml-2"></i>
                                        <span className="text-muted ml-2 badge badge-soft-secondary">
                                          <i className=" fas fa-star text-warning mr-1" />
                                          {ride[userType]?.rating.toFixed(2)}
                                        </span>
                                      </div>
                                    ) : (
                                      <div className="text-capitalize font-weight-bold">
                                        {ride[userType]?.firstName}{' '}
                                        {ride[userType]?.lastName}
                                        <span className="text-muted ml-2 badge badge-soft-secondary">
                                          <i className=" fas fa-star text-warning mr-1" />
                                          {ride[userType]?.rating.toFixed(2)}
                                        </span>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  'No Driver Found'
                                )}
                                <span>
                                  {/* <i className=" ri-checkbox-blank-circle-fill font-size-10 mr-1 ml-3 text-muted" /> */}
                                  <span className="text-success" style={{ textTransform: 'uppercase' }}>
                                    {' '}
                                    {ride.ridePackage.name}
                                  </span>
                                </span>
                              </h5>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="text-muted font-size-11">
                              {Moment(ride.createdAt).format(
                                'MMMM Do YYYY, h:mm:ss a'
                              )}
                            </p>
                            <span className='d-flex font-size-12'>
                              <span className="mb-1 rounded font-size-12 rounded">
                                {ride.paymentMethod === 'ACCOUNT' ? (
                                  <span className='d-flex align-items-center'>
                                    <i className="text-warning fas fa-wallet mr-1  font-size-10" />{' '}
                                    <h6 className="font-weight-bold bg-soft-warning px-2 rounded mb-0 font-size-12">
                                      NPAY
                                    </h6>
                                  </span>
                                ) : ride.paymentMethod === 'CASH' ? (
                                  <span className='d-flex align-items-center'>
                                    <i className="text-warning fas fa-money-bill-wave mr-1  font-size-10" />{' '}
                                    <h6 className="font-weight-bold bg-soft-warning px-2 rounded mb-0 font-size-12">
                                      Cash
                                    </h6>
                                  </span>
                                ) : ride.paymentMethod === 'CARD' ? (
                                  <span className='d-flex align-items-center'>
                                    <i
                                      className="text-warning fas fa-credit-card mr-2  font-size-10
"
                                    />
                                    <h6 className="font-weight-bold bg-soft-warning px-2 rounded mb-0 font-size-12">
                                      Card
                                    </h6>
                                  </span>
                                ) : (
                                  <span className='d-flex align-items-center'>
                                    <i
                                      className="text-warning fas fa-wallet mr-2  font-size-10
"
                                    />
                                    <h6 className="font-weight-bold bg-soft-warning px-2 rounded mb-0 font-size-12">
                                      {ride.paymentMethod}
                                    </h6>
                                  </span>
                                )}
                              </span>
                              /₦
                              {ride?.totalAmount?.toLocaleString('en-US')}
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div>
                          <ul className="list-unstyled activity-wid mb-0">
                            <li className="activity-list pb-2">
                              <div className="activity-icon d-flex align-items-center" style={{ marginTop: '2px' }}>
                                <div
                                  className={`rounded-circle badge badge-soft-success font-size-10 align-self-end`}
                                >
                                  <i className="fas fa-circle" style={{ marginTop: '1px', fontSize: '9px' }}></i>
                                </div>
                              </div>
                              <div>
                                <p className="mb-1 font-size-12 text-muted">
                                  {ride.pickUp?.address}
                                </p>
                              </div>
                            </li>
                            <li className="activity-list pb-2">
                              <div className="activity-icon">
                                <i className="ri-map-pin-line text-success" />
                              </div>
                              <div>
                                <p className="mb-1 font-size-12 text-muted">
                                  {ride.dropOff?.address}
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))
                  ) : (
                    <Table hover className=" mb-0 table-centered table-nowrap">
                      <tbody>
                        {[1, 2]?.map((data, key) => {
                          return (
                            <tr key={key}>
                              <td style={{ width: '10%' }}>
                                <div className="avatar-xs">
                                  <div className="avatar-title rounded-circle bg-light">
                                    <img
                                      src={avatarImg}
                                      alt=""
                                      height="20"
                                      className="rounded-circle avatar-xs"
                                    />
                                  </div>
                                </div>
                              </td>

                              <td style={{ width: '30%' }}>
                                <h5 className="font-size-14 mb-0">
                                  <Skeleton duration={2} width="75%" />
                                </h5>

                                <span className="text-muted">
                                  <Skeleton duration={2} width="60%" />
                                </span>
                              </td>
                              <td>
                                <div id="spak-chart1"></div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                </>
                :
                <p className='font-weight-bold p-3'>This user has no rides history</p>
              }
            </div>
          </Col>

          <Col sm={4} md={4}>
            <Col className="mb-4">
              <div className="border rounded mb-2" >
                <div className='p-3 d-flex justify-content-between align-items-center' style={{ borderBottom: '2px dotted lightgrey' }}>
                  <h6 className='font-weight-bold'> Current Trip </h6>

                </div>

                {ongoingRide ?
                  <div className='trips p-3'>
                    <div
                      className='text-dark font-weight-bold'
                    >

                      <div className='d-flex align-items-center'>
                        <img
                          src={ongoingRide[userType]?.picture ? ongoingRide[userType]?.picture : avatarImg}
                          alt=''
                          height='20'
                          className='rounded-circle avatar-xs mr-2'
                        />

                        <div className='d-flex flex-column'>
                          <div className='text-capitalize'>
                            {ongoingRide[userType]?.firstName} {ongoingRide[userType]?.lastName}
                          </div>
                          <div className='font-size-10 font-weight-bold text-secondary'>
                            {ongoingRide[userType]?.userType}
                          </div>
                          <div
                            className="font-size-10 font-weight-bold text-success"
                          >
                            {ongoingRide[userType]?.phone}
                          </div>

                        </div>


                      </div>
                    </div>
                    <div className='d-flex justify-content-between my-4' style={{ alignItems: 'flex-end' }}>
                      <ul className="list-unstyled activity-wid mb-0" style={{ width: '70%' }}>
                        <li className="activity-list pb-2 pl-1">
                          <div className="activity-icon d-flex align-items-center" style={{ marginTop: '2px' }}>
                            <div
                              className={`rounded-circle badge badge-soft-success font-size-10 align-self-end`}
                            >
                              <i className="fas fa-circle" style={{ marginTop: '1px', fontSize: '9px' }}></i>
                            </div>
                          </div>
                          <div>
                            <p className="mb-1 font-size-12 text-muted text-truncate">
                              {ongoingRide.pickUp?.address}
                            </p>
                          </div>
                        </li>
                        <li className="activity-list pb-0 pl-1">
                          <div className="activity-icon d-flex align-items-center" style={{ marginTop: '-1px', marginLeft: '1px' }}>
                            <i className="ri-map-pin-line text-secondary" />
                          </div>
                          <div>
                            <p className="mb-1 font-size-12 text-muted text-truncate">
                              {ongoingRide.dropOff?.address}
                            </p>
                          </div>
                        </li>
                      </ul>
                      <h6 className='font-size-10'>
                        {(ongoingRide?.distance / 1000).toLocaleString()}KM
                      </h6>
                    </div>
                    <div className=''>
                      <h6
                        className={`m-0 badge badge-${ongoingRide.status === 'PROCESSING'
                          ? 'warning'
                          : ongoingRide.status === 'ACCEPTED'
                            ? 'success'
                            : ongoingRide.status === 'CANCELLED'
                              ? 'danger'
                              : ongoingRide.status === 'COMPLETED'
                                ? 'success'
                                : 'dark'
                          }`} style={{ fontSize: '9px' }}
                      >
                        {ongoingRide.status}{' '}
                      </h6>
                    </div>
                  </div>
                  :
                  <p className="p-3 font-weight-bold">No on-going ride</p>}
              </div>

            </Col>
            <Col >
              <div className="border rounded mb-2" >
                <div className='p-3 d-flex justify-content-between align-items-center' style={{ borderBottom: '2px dotted lightgrey' }}>
                  <h6 className='font-weight-bold'> Recent Activities </h6>
                  <Link to={`/activities_log`} className="mr-2 font-size-11 font-weight-bold text-success">
                    See all activities
                  </Link>{' '}
                </div>
                <div>
                  {activities &&
                    activities?.result != '' &&
                    activities?.message !== 'No Activities' &&
                    user.isActive ? (
                    <SimpleBar >
                      {activities?.result?.map((activity, key) => (
                        <div className='d-flex flex-column p-3 cursor-pointer' key={key} style={{ borderBottom: '2px dotted lightgrey' }} onClick={() => (
                          setPreviewId(activity._id), setShowModal(true)
                        )}>
                          <div className='d-flex align-items-center justify-content-between mb-3'>
                            <span className='text-muted ml-2 badge badge-soft-secondary font-size-10 font-weight-bold' style={{ textTransform: 'capitalize' }}>
                              {activity?.nrydeSource}
                            </span>
                            <h6 className='text-right font-size-10 m-0'><DateComponent date={activity.createdAt} /></h6>
                          </div>
                          <div className='d-flex align-items-center'>
                            {activity.responseStatusCode === "200" || activity.responseStatusCode === "201" ?
                              <i className='fas fa-check-circle text-success'
                              ></i>
                              :
                              <i className='fa fa-ban text-danger'
                              ></i>
                            }


                            <div className='d-flex flex-column ml-2'>
                              <div className='text-capitalize'>
                                {activity?.message ? activity?.message : activity?.module}
                              </div>

                              <div
                                className="font-size-10 font-weight-bold text-success"
                              >
                                {/* {ongoingRide[userType]?.phone} */}
                              </div>

                            </div>

                          </div>
                          <div className='text-muted font-size-10' style={{ marginLeft: '21px' }}>
                            <TimeComponent date={activity.createdAt} />
                          </div>
                        </div>
                      ))}
                    </SimpleBar>
                  ) : (
                    <p className="p-3 font-weight-bold">There is no recent activities for this user</p>
                  )}
                </div>

              </div>
            </Col>
          </Col>
          <Col sm={4} md={4}>
            <Col className="mb-4">
              <div className="border rounded mb-2" >
                <div className='p-3 d-flex justify-content-between align-items-center' style={{ borderBottom: '2px dotted lightgrey' }}>
                  <h6 className='font-weight-bold'> Current Location </h6>

                </div>

                {user_location?.result?.address ?
                  <div className='trips p-3'>


                    <div className='d-flex align-items-center'>
                      <i className='ri-map-pin-line text-success mr-2'
                        style={{ fontSize: '30px' }}
                      />

                      <div className='d-flex flex-column font-weight-bold'>
                        <div className='text-capitalize'>
                          {user_location?.result?.address}
                        </div>


                      </div>


                    </div>
                    <div style={{
                      height: '150px',
                      background: 'grey',
                      marginTop: '10px',
                      borderRadius: '20px'
                    }}>
                      <MapDirection driverLocalion={{
                        lng: user_location?.result?.lng,
                        lat: user_location?.result?.lat
                      }} />
                    </div>
                  </div>


                  :
                  <p className="p-3 font-weight-bold">No record of location</p>}
              </div>

            </Col>
            <Col>
              <div className="border rounded mb-2" >
                <div className='p-3 d-flex justify-content-between align-items-center' style={{ borderBottom: '2px dotted lightgrey' }}>
                  <h6 className='font-weight-bold'> Hours </h6>
                  <Link to={`/drivers-hours`} className="mr-2 font-size-11 font-weight-bold text-success">
                    See more driver's hours
                  </Link>{' '}
                </div>
                {driverOnlineLogs?.count > 0 ?
                  <>
                    <div className='d-flex justify-content-between p-2' style={{ alignItems: 'baseline' }}>
                      <div className='d-flex'>
                        <div className='text-center'>
                          <h3 className='m-0'>{driverHoursLogs?.result?.hours}</h3>
                          <span className='text-muted font-size-10'>Hours</span>
                        </div>
                        <div className="d-flex flex-column justify-content-around mx-4" style={{ color: 'limegreen' }}>
                          <i className='fas fa-stop' style={{ fontSize: '8px', marginTop: '3px' }}></i>
                          <i className='fas fa-stop' style={{ fontSize: '8px', marginBottom: '10px' }}></i>
                        </div>
                        <div className='text-center'>
                          <h3 className='m-0'>{driverHoursLogs?.result?.minutes}</h3>
                          <span className='text-muted font-size-10'>Minutes</span>
                        </div>
                      </div>
                      <h6
                        className={`badge badge-soft-${driverLogs?.result[0]?.status === 'OFFLINE'
                          ? 'danger'
                          : driverLogs?.result[0]?.status === 'BUSY'
                            ? 'warning'
                            : driverLogs?.result[0]?.status === 'AVAILABLE'
                              ? 'success'
                              : 'secondary'
                          } font-size-10`}
                      >
                        {driverLogs?.result[0]?.status
                          ? driverLogs?.result[0]?.status
                          : 'No data'}
                      </h6>

                    </div>
                    <div className='font-size-12 p-2'>Total Records: <span className='font-weight-bold'>{driverHoursLogs?.result?.records}</span></div>
                    <div className='p-2'>
                      <Table
                        hover
                        className='mt-3 border rounded mb-0 table-centered table-nowrap p-2 font-size-12'
                      // style={{
                      //   height: '250px',
                      //   overflow: 'auto',
                      //   width: 'fit-content',
                      // }}
                      >
                        <thead
                          style={{
                            background: 'lightgrey',
                            position: 'sticky',
                            top: '0',
                            zIndex: '99',
                          }}
                        >
                          <tr>
                            <th className='py-1'>Time Spent</th>
                            <th className='py-1'>Date</th>
                            <th className='py-1'>Status</th>
                          </tr>
                        </thead>
                        <tbody>

                          {driverOnlineLogs?.result?.map((data, key) => {
                            return (
                              <tr key={key}>
                                <td>{data?.epoch ? (
                                  <div className='badge badge-soft-secondary font-size-12 text-center'>
                                    {new Date(data?.epoch * 1000).toISOString().substr(14, 5)}
                                  </div>
                                ) : (
                                  '----'
                                )}</td>
                                <td><div>{Moment(data.createdAt).format('l')}</div></td>
                                <td><div
                                  className={`badge badge-soft-${data?.status === 'NONE'
                                    ? 'danger'
                                    : data?.status === 'BUSY'
                                      ? 'warning'
                                      : data?.status === 'AVAILABLE'
                                        ? 'success'
                                        : 'secondary'
                                    }`} style={{ fontSize: '9px' }}
                                >
                                  {data?.status === 'OFFLINE'
                                    ? 'NOT AVAILABLE'
                                    : data?.status === 'NONE'
                                      ? 'OFFLINE'
                                      : data?.status}
                                </div></td>
                              </tr>
                            )
                          })}

                        </tbody>
                      </Table>
                    </div>
                  </>
                  :
                  <p className="text-center p-3 font-weight-bold">No online records</p>
                }
              </div>
            </Col>
          </Col>
        </Row>

      </div>
      <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          Preview Activity
        </ModalHeader>
        <ModalBody>
          <Activities previewId={previewId} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { rides, loading, ridesError, message } = state.Rides;
  const { activities, activitiesError } = state.Activities;
  const {
    driverLogs,
    driverHoursLogs,
    driverHoursLogsError,
    driverOnlineLogs,
    driverOnlineLogsError,
  } = state.Users;
  return {
    driverLogs,
    driverHoursLogs,
    driverHoursLogsError,
    driverOnlineLogs,
    driverOnlineLogsError, activities, activitiesError, rides, loading, ridesError, message
  };
};

export default connect(mapStateToProps, {
  fetchRides,
  fetchActivities,
  fetchDriverOnlineLogs,
  fetchDriverHoursLogs,
})(RideHistory);
