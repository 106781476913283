import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_INVITATION,
  FETCH_USERS_INVITATION,
  FETCH_EACH_INVITATION,
  FETCH_INVITATION_SUMMARY,
  FETCH_INVITATION_DETAILS,
  CREATE_INVITATION,
  DELETE_INVITATION,
  UPDATE_INVITATION,
  FETCH_FOR_INVITATION_CSV,
} from './actionTypes';
import {
  fetchInvitationSuccessful,
  fetchInvitationError,
  fetchUsersInvitationSuccessful,
  fetchUsersInvitationError,
  fetchEachInvitationSuccessful,
  fetchEachInvitationError,
  deleteInvitationSuccessful,
  deleteInvitationFailed,
  createInvitationSuccessful,
  createInvitationFailed,
  updateInvitationSuccessful,
  updateInvitationFailed,
  fetchInvitationDetailsFailed,
  fetchInvitationDetailsSuccessfully,
  fetchInvitationSummaryFailed,
  fetchInvitationSummarySuccessfully,
  fetchReferralCSVSuccessfully,
  fetchReferralCSVError,
} from './actions';

import {
  GetInvitationService,
  GetEachInvitationService,
  DeleteInvitationService,
  CreateInvitationService,
  UpdateInvitationService,
  fetchInvitationSummaryService,
  FetchUserInvitationService,
} from '../../services/invitationService';

function* fetchInvitationHandler({ payload }) {
  try {
    const response = yield call(GetInvitationService, payload);
    yield put(fetchInvitationSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchInvitationError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchInvitationError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchInvitationError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchUserInvitationHandler({ payload }) {
  try {
    const response = yield call(FetchUserInvitationService, payload);
    yield put(fetchUsersInvitationSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchUsersInvitationError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchUsersInvitationError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchUsersInvitationError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEachInvitationHandler({ payload }) {
  try {
    const response = yield call(GetEachInvitationService, payload);
    yield put(fetchEachInvitationSuccessful(response.data));
  } catch (error) {
    yield put(fetchEachInvitationError(error?.response?.data?.message));
  }
}
function* deleteInvitationHandler({ payload }) {
  try {
    const response = yield call(DeleteInvitationService, payload);
    yield put(deleteInvitationSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteInvitationFailed(error?.response?.data?.error));
  }
}

function* createInvitationHandler({ payload }) {
  try {
    const response = yield call(CreateInvitationService, payload);
    yield put(createInvitationSuccessful(response.data.result));
  } catch (error) {
    yield put(
      createInvitationFailed(error?.response?.data?.error.error.message)
    );
  }
}

function* updateInvitationHandler({ payload }) {
  try {
    const response = yield call(UpdateInvitationService, payload);
    yield put(updateInvitationSuccessful(response.data.result));
  } catch (error) {
    yield put(updateInvitationFailed(error?.response?.data?.error));
  }
}

function* fetchInvitationSummaryHandler({ payload }) {
  try {
    const response = yield call(fetchInvitationSummaryService, payload);
    yield put(fetchInvitationSummarySuccessfully(response.data));
  } catch (error) {
    yield put(fetchInvitationSummaryFailed(error.response.data));
  }
}

function* fetchInvitationDetailsHandler({ payload }) {
  try {
    const response = yield call(fetchInvitationSummaryService, payload);
    yield put(fetchInvitationDetailsSuccessfully(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchInvitationDetailsFailed(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchInvitationDetailsFailed(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchInvitationDetailsFailed(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchReferralCSVHandler({ payload }) {
  try {
    const response = yield call(GetInvitationService, payload);
    yield put(fetchReferralCSVSuccessfully(response.data));
  } catch (error) {
    yield put(fetchReferralCSVError(error?.response?.data?.message));
  }
}

export function* watchFetchInvitation() {
  yield takeEvery(FETCH_INVITATION, fetchInvitationHandler);
}

export function* watchFetchUserInvitation() {
  yield takeEvery(FETCH_USERS_INVITATION, fetchUserInvitationHandler);
}

export function* watchFetchEachInvitation() {
  yield takeEvery(FETCH_EACH_INVITATION, fetchEachInvitationHandler);
}
export function* watchDeleteInvitation() {
  yield takeEvery(DELETE_INVITATION, deleteInvitationHandler);
}
export function* watchCreateInvitation() {
  yield takeEvery(CREATE_INVITATION, createInvitationHandler);
}
export function* watchUpdateInvitation() {
  yield takeEvery(UPDATE_INVITATION, updateInvitationHandler);
}
export function* watchFetchInvitationSummary() {
  yield takeEvery(FETCH_INVITATION_SUMMARY, fetchInvitationSummaryHandler);
}
export function* watchFetchInvitationDetails() {
  yield takeEvery(FETCH_INVITATION_DETAILS, fetchInvitationDetailsHandler);
}
export function* watchReferralCSV() {
  yield takeEvery(FETCH_FOR_INVITATION_CSV, fetchReferralCSVHandler);
}

function* invitationSaga() {
  yield all([
    fork(watchFetchInvitation),
    fork(watchFetchUserInvitation),
    fork(watchFetchEachInvitation),
    fork(watchDeleteInvitation),
    fork(watchCreateInvitation),
    fork(watchUpdateInvitation),
    fork(watchFetchInvitationDetails),
    fork(watchFetchInvitationSummary),
    fork(watchReferralCSV),
  ]);
}

export default invitationSaga;
