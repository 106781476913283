import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import GreySummaryCard from '../../../../components/Common/Summary/index';
import { withRouter, Link } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import CheckAction, { ReadCheck } from '../../../../components/Common/Summary/actionCheck';
import ContactTable from './contactsTable';
import PreviewContact from './previewContact';
import { fetchContacts, deleteContact } from '../../../../store/actions';

const Contacts = ({
  contacts,
  tableError,
  fetchContacts,
  deleteContact,
  loading,
  message,
  history,
  accessControl
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const breadcrumbItems = [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Contacts', link: '#' },
  ];

  const deleteContactModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };
  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteContact(deleteId);
    setDeleteId(null);
  };
  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Contact' breadcrumbItems={breadcrumbItems} />

          <GreySummaryCard field='Emergency' subModule='Emergency Contacts'>
            {message && (
              <Alert color='success' className='text-center'>
                {message}
              </Alert>
            )}
            {tableError ? (
              <Alert color='danger' className='text-center'>
                {tableError}
              </Alert>
            ) : (
              <>
                <CheckAction
                  field='Emergency'
                  subModule='Emergency Contacts'
                  iconType={'Create'}
                >
                  <div className='mb-2 d-flex justify-content-end align-items-center '>
                    <Link to='/contact_form'>
                      <Button color='success' className='mr-1'>
                        <i className='mdi mdi-plus-circle-outline font-size-18'></i>{' '}
                        Create Contact
                      </Button>
                    </Link>
                  </div>
                </CheckAction>
                <Row>
                  <Col>
                    <ContactTable
                      contactData={contacts}
                      tableError={tableError}
                      loading={loading}
                      deleteContact={deleteContactModal}
                      fetchContacts={fetchContacts}
                      history={history}
                      message={message}
                      previewAction={previewHandler}
                      CheckAction={CheckAction}
                      ReadCheck={ReadCheck}
                      accessControl={accessControl}
                    />
                  </Col>
                </Row>

                {/* Delete Confirmation Modal */}
                <Modal
                  isOpen={showDeleteModal}
                  toggle={() => setShowDeleteModal(!showDeleteModal)}
                >
                  <ModalBody>
                    <h4 className='card-title mb-4 text-center'>
                      {' '}
                      Are you sure you want to delete this contact
                    </h4>
                    <button
                      onClick={() => deleteHandler()}
                      className='btn btn-outline-danger btn-sm w-100'
                    >
                      Click to Delete
                    </button>
                  </ModalBody>
                </Modal>

                {/* Contact Preview Modal */}
                <Modal
                  isOpen={showModal}
                  toggle={() => setShowModal(!showModal)}
                >
                  <ModalHeader toggle={() => setShowModal(false)}>
                    Preview Contact
                  </ModalHeader>
                  <ModalBody>
                    <PreviewContact previewId={previewId} />
                  </ModalBody>
                </Modal>
              </>
            )}
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { contacts, loading, contactError, message, tableError } =
    state.Contact;
  return { accessControl, contacts, tableError, loading, contactError, message };
};

export default connect(mapStateToProps, {
  fetchContacts,
  deleteContact,
})(withRouter(Contacts));
