import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { getS3DocUrlHandler } from '../../../../../utils/general';
import { Link, withRouter } from 'react-router-dom';

import Dropzone from 'react-dropzone';

import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import {
  updateDocument,
  updateDocumentFailed,
} from '../../../../../store/actions';

const Form = ({
  updateDocument,
  updateDocumentFailed,
  message,
  loading,
  history,
  documentError,
  defaultValue,
  updatePdfModalDisplay,
  updateImgModalDisplay,
  updateDocName,
  docIsImage,
  docIsPdf,
  docId,
}) => {
  const [docError, setDocError] = useState(false);
  const [selectedFiles, setFileUpload] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event, values) => {
    setIsLoading(true);
    setDocError(false);

    if (selectedFiles.length === 0) {
      const documentData = {
        ...values,
      };
      documentData.id = defaultValue.id;

      documentData.status = values.Status;

      updateDocument(documentData);
    } else {
      try {
        let result = await getS3DocUrlHandler(selectedFiles[0], 'document');
        const documentData = {
          ...values,
        };
        documentData.id = defaultValue.id;

        documentData.status = values.Status;
        documentData.documentUrl = result?.data.result?.mediaUrl;
        documentData.documentThumbUrl = result?.data?.result.mediaThumbUrl;

        updateDocument(documentData);
        setIsLoading(false);
      } catch (error) {
        alert('error in document upload');

        setIsLoading(false);
      }
    }
  };

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setFileUpload(files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        updateDocumentFailed('');
        // if (docId) {
        //   history.push(`user/${defaultValue.user.id}`)
        //   return
        // }
        history.goBack();
      }, 2000);
    }
  }, [history, message, updateDocumentFailed]);

  useEffect(() => {
    updateDocumentFailed('');
  }, [updateDocumentFailed]);

  if (defaultValue?.documentUrl) {
    var getDocNameFromUrl = defaultValue?.documentUrl.split('/');
    var docName = getDocNameFromUrl[getDocNameFromUrl.length - 1];

    var getDocExtensionFromUrl = defaultValue?.documentUrl.split('.');
    var docExtension =
      getDocExtensionFromUrl[getDocExtensionFromUrl.length - 1];
  }

  if (docExtension === 'pdf') {
    docIsPdf = true;
  }
  if (
    docExtension === 'png' ||
    docExtension === 'jpg' ||
    docExtension === 'jpeg'
  ) {
    docIsImage = true;
  }

  const handleDocView = () => {
    if (docIsPdf) {
      updatePdfModalDisplay(true);
      updateDocName(docName);
    }
    if (docIsImage) {
      updateImgModalDisplay(true);
      updateDocName(docName);
    }
  };
  return (
    <Card>
      <CardBody>
        {message && (
          <Alert color='success' className='text-center'>
            {message}
          </Alert>
        )}
        {documentError && (
          <Alert color='danger' className='text-center'>
            {documentError}
          </Alert>
        )}
        {docError && (
          <Alert color='danger' className='text-center'>
            {docError}
          </Alert>
        )}
        <div className='float-right'>
          {/* I used this logic because useHistory was not working on safari */}

          <Link
            to='#'
            onClick={() => history.goBack()}
            // onClick={
            //   docId
            //     ? () => history.push(`user/${defaultValue.user.id}`)
            //     : () => history.goBack()
            // }
          >
            <i className='fas fa-arrow-left mr-3' />
            Back
          </Link>
        </div>
        <div>
          <h5> Update Document </h5>
        </div>
        <div>
          <AvForm
            className='form-horizontal'
            onValidSubmit={handleSubmit}
            // model={defaultValue ? defaultValue : ''}
          >
            <Row>
              <Col md={6}>
                <FormGroup className=' mb-4'>
                  <AvField
                    className='form-control bg-light'
                    type='select'
                    name='Status'
                    label='Status'
                    value={defaultValue ? defaultValue.status : 'PENDING'}
                  >
                    <option value={'PENDING'}>Pending</option>
                    <option value={'REVIEW'}>Review</option>
                    <option value={'ACCEPTED'}>Accept</option>
                    <option value={'REJECTED'}>Reject</option>
                    <option value={'FLAGGED'}>Flag</option>
                  </AvField>
                </FormGroup>
              </Col>
            </Row>
            {defaultValue?.documentUrl ? (
              <Row>
                <Col md={6} className='mb-4'>
                  <div className='align-items-center'>
                    <Label>View Current Document</Label>
                    <Link
                      to={'#'}
                      onClick={handleDocView}
                      // onClick={() =>
                      //   window.open(
                      //     defaultValue.documentUrl,
                      //     '_blank',
                      //     'noopener,noreferrer'
                      //   )
                      // }
                      className='ml-2'
                      style={{ color: '#505d69' }}
                      // target={data.documentUrl ? '_blank' : ''}
                    >
                      <i className='mdi mdi-file-document-outline mdi-24px'></i>
                    </Link>
                  </div>
                  <AvField
                    name='docName'
                    type='text'
                    className='form-control bg-light'
                    id='docName'
                    value={docName}
                    disabled
                  />
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col md={6}>
                <Dropzone
                  onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
                  multiple={false}
                  accept='application/pdf, 
                  image/png, 
                  image/jpeg, 
                  image/jpg'
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className='dropzone'>
                      <div
                        className='dz-message needsclick mt-2'
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className='mb-3'>
                          <i className='display-4 text-muted ri-upload-cloud-2-line'></i>
                        </div>
                        <h4>Drop or click here to upload document.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className='dropzone-previews mt-3' id='file-previews'>
                  {selectedFiles.map((f, i) => {
                    if (
                      f.type === 'image/png' ||
                      f.type === 'image/jpeg' ||
                      f.type === 'image/jpg'
                    ) {
                      return (
                        <Card
                          className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                          key={i + '-file'}
                        >
                          <div className='p-2'>
                            <Row className='align-items-center'>
                              <Col className='col-auto'>
                                <img
                                  data-dz-thumbnail=''
                                  height='100'
                                  className='rounded bg-light'
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to='#'
                                  className='text-muted font-weight-bold'
                                >
                                  {f.name}
                                </Link>
                                <p className='mb-0'>
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    } else {
                      return (
                        <div className='p-2' key={i + '-file'}>
                          <Row className='align-items-center'>
                            <Col>
                              <AvField
                                className='text-muted font-weight-bold form-control bg-light'
                                value={f.name}
                                name='documentUrl'
                              />
                              <p className='mb-0'>
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  })}
                </div>
              </Col>
            </Row>
            <button className='btn btn-success mr-2' type='submit'>
              {isLoading ? 'Submitting ...' : 'Update'}
            </button>
            <Link
              to='#'
              onClick={
                docId
                  ? () => history.push(`user/${defaultValue.user.id}`)
                  : () => history.goBack()
              }
            >
              <button className='btn btn-secondary'>Cancel</button>
            </Link>
          </AvForm>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, documentError } = state.Document;
  return { message, loading, documentError };
};

export default connect(mapStateToProps, {
  updateDocument,
  updateDocumentFailed,
})(withRouter(Form));
