import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { getS3ImageUrlHandler } from '../../../../utils/general';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import { ScrollToTop } from '../../../../utils/general';
// actions
import { updateProfile, updateProfileError } from '../../../../store/actions';
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

//eslint-disable-next-line
const REGEXP = /[\+]\d{3}\d{2}\d{4}\d{4}/;

const EditProfile = ({
  user,
  updateProfile,
  updateProfileError,
  loading,
  message,
  history,
}) => {
  const [selectedFiles, setFileUpload] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [userId, setUserId] = useState(null);
  const [avatar, setAvatar] = useState(avatarImg);
  const [defaultData, setDefault] = useState(null);
  const handleSubmit = async (event, values) => {
    let formData = { ...values };
    setIsLoading(true);
    setImageError(false);
    try {
      if (selectedFiles.length > 0) {
        let result = await getS3ImageUrlHandler(selectedFiles[0], 'profile');
        formData.picture = result?.data.result?.mediaUrl;
      }
      formData.phone = values.phone;
      updateProfile(formData, userId);
    } catch (error) {
      setAvatar(avatarImg);
      setIsLoading(false);
      setImageError(true);
    }
  };

  const imageHandler = (e) => {
    // let {files} = e.target
    const files = e.target.files;
    if (files) {
      const reader = new FileReader();
      reader.onload = () => {
        setAvatar(reader.result);
      };
      reader.readAsDataURL(files[0]);
      setFileUpload(files);
    }
  };

  useEffect(() => {
    setImageError(false);
    let cloneData = { ...user };
    if (cloneData.dob) {
      cloneData.dob = cloneData.dob.split('T')[0];
    } else {
      cloneData.dob = '0000-00-00';
    }
    if (cloneData.gender === null) {
      cloneData.gender = 'MALE';
    }
    if (cloneData.address === null) {
      cloneData.address = '';
    }
    if (cloneData.address1 === null) {
      cloneData.address1 = '';
    }
    if (cloneData.country === null) {
      cloneData.country = '';
    }
    if (cloneData.state === null) {
      cloneData.state = '';
    }
    const img = user.picture ? user.picture : avatar;
    setAvatar(img);
    setDefault(cloneData);
    setUserId(user.id);
    updateProfileError('');
  }, []);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        // updateProfileError('');
        setIsLoading(false);
        ScrollToTop();
      }, [2000]);
    }
  }, [message]);

  return (
    <div>
      <Card>
        <CardBody>
          {message && (
            <Alert color='success' className='text-center'>
              {message}
            </Alert>
          )}
          {imageError && (
            <Alert color='danger' className='text-center'>
              Error in Image upload please check back
            </Alert>
          )}

          {defaultData && (
            <>
              <div>
                <div className='text-center mb-2  position-relative bgs'>
                  <img
                    className='rounded-circle avatar-md border shadow-lg p-2'
                    alt='Profile'
                    src={avatar}
                  />
                  <input
                    type='file'
                    accept='image/*'
                    name='image-upload'
                    className='d-none'
                    id='input'
                    onChange={(e) => imageHandler(e)}
                  />
                  <div className='label mt-1'>
                    <label
                      className='border shadow-md p-1 rounded bg-light'
                      htmlFor='input'
                      style={{ cursor: 'pointer' }}
                    >
                      <i className='dripicons-upload mr-2' />
                      Edit Image
                    </label>
                  </div>
                </div>
              </div>
              <AvForm
                className='form-horizontal'
                onValidSubmit={handleSubmit}
                model={defaultData}
              >
                <Row>
                  <Col md={6}>
                    <FormGroup className='mb-4'>
                      <Label htmlFor='firstName'>First Name</Label>
                      <AvField
                        name='firstName'
                        type='text'
                        className='form-control bg-light'
                        id='firstName'
                        placeholder='Enter first Name'
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className='mb-4'>
                      <Label htmlFor='lastName'>Last Name</Label>
                      <AvField
                        name='lastName'
                        type='text'
                        className='form-control bg-light'
                        id='lastName'
                        placeholder='Enter Last Name'
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className='mb-4'>
                      <Label htmlFor='useremail'>Email</Label>
                      <AvField
                        name='email'
                        validate={{
                          email: true,
                          required: true,
                        }}
                        disabled
                        type='email'
                        className='form-control bg-light'
                        id='useremail'
                        placeholder='Enter email'
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className='mb-4'>
                      <Label htmlFor='gender'>Gender</Label>
                      <AvField
                        name='gender'
                        type='select'
                        className='form-control bg-light'
                        id='gender'
                        placeholder='Enter Gender'
                        required
                      >
                        <option>MALE</option>
                        <option>FEMALE</option>
                      </AvField>
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup className='mb-4'>
                      <Label htmlFor='dob'>Date Of Birth</Label>
                      <AvField
                        name='dob'
                        type='date'
                        className='form-control bg-light'
                        id='dob'
                        placeholder='Enter lastName'
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className='mb-4'>
                      <Label htmlFor='phone'>Phone Number</Label>
                      <AvField
                        name='phone'
                        type='text'
                        // validate={{
                        //   tel: { pattern: REGEXP },
                        // }}
                        // errorMessage="please enter a valid phone number"
                        disabled
                        className='form-control bg-light'
                        id='phone'
                        placeholder='enter phone number'
                        // required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup className='mb-4'>
                      <Label htmlFor='address'>Address</Label>
                      <AvField
                        name='address'
                        type='text'
                        className='form-control bg-light'
                        id='address'
                        placeholder='Enter Address'
                        required
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col md={12}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="address1">Address</Label>
                      <AvField
                        name="address1"
                        type="text"
                        className="form-control bg-light"
                        id="address1"
                        placeholder="Enter address 2"
                      />
                    </FormGroup>
                  </Col> */}
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className='mb-4'>
                      <Label htmlFor='state'>State</Label>
                      <AvField
                        name='state'
                        type='text'
                        className='form-control bg-light'
                        id='state'
                        placeholder='Enter State'
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className='mb-4'>
                      <Label htmlFor='country'>Country</Label>
                      <AvField
                        name='country'
                        type='text'
                        className='form-control bg-light'
                        id='country'
                        placeholder='Enter Your Country'
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className='text-center'>
                  <button className='btn btn-success mr-2 px-5' type='submit'>
                    {isLoading ? 'Submitting ...' : 'Save'}
                  </button>
                </div>
              </AvForm>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loading, userError, message } = state.Account;
  return { loading, userError, message };
};

export default connect(mapStateToProps, { updateProfile, updateProfileError })(
  EditProfile
);
