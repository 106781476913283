import {
  IS_APP_CONFIG_AUTHORIZE,
  FETCH_APP_CONFIGS,
  FETCH_APP_CONFIGS_SUCCESSFUL,
  FETCH_APP_CONFIGS_API_FAILED,
  FETCH_EACH_APP_CONFIGS,
  FETCH_EACH_APP_CONFIGS_SUCCESSFUL,
  FETCH_EACH_APP_CONFIGS_API_FAILED,
  UPDATE_APP_CONFIGS,
  UPDATE_APP_CONFIGS_SUCCESSFUL,
  UPDATE_APP_CONFIGS_API_FAILED,
  UPDATE_APP_CONFIG_ITEM,
  UPDATE_APP_CONFIG_ITEM_SUCCESSFUL,
  UPDATE_APP_CONFIG_ITEM_API_FAILED,
  CREATE_APP_CONFIGS,
  CREATE_APP_CONFIGS_SUCCESSFUL,
  CREATE_APP_CONFIGS_API_FAILED,
  DELETE_APP_CONFIGS,
  DELETE_APP_CONFIGS_SUCCESSFUL,
  DELETE_APP_CONFIGS_API_FAILED,
  DELETE_APP_CONFIG_ITEM,
  DELETE_APP_CONFIG_ITEM_SUCCESSFUL,
  DELETE_APP_CONFIG_ITEM_API_FAILED,
  CREATE_ACCESS_CONTROL_FE,
  CREATE_ACCESS_CONTROL_FE_SUCCESSFUL,
  CREATE_ACCESS_CONTROL_FE_API_FAILED,
  UPDATE_ACCESS_CONTROL_FE,
  UPDATE_ACCESS_CONTROL_FE_SUCCESSFUL,
  UPDATE_ACCESS_CONTROL_FE_API_FAILED,
  LIST_ACCESS_CONTROL_FE,
  LIST_ACCESS_CONTROL_FE_SUCCESSFUL,
  LIST_ACCESS_CONTROL_FE_API_FAILED,
  LIST_EACH_ACCESS_CONTROL_FE,
  LIST_EACH_ACCESS_CONTROL_FE_SUCCESSFUL,
  LIST_EACH_ACCESS_CONTROL_FE_API_FAILED,
  DELETE_ACCESS_CONTROL_FE,
  DELETE_ACCESS_CONTROL_FE_SUCCESSFUL,
  DELETE_ACCESS_CONTROL_FE_API_FAILED,
} from './actionTypes';

const initialState = {
  appConfigs: null,
  appConfig: null,
  eachAppConfig: null,
  appConfigError: null,
  tableError: null,
  accessControlFE: null,
  eachAccessControlFE: null,
  accessControlFEError: null,
  message: null,
  deleteMessage: null,
  isAuthorize: null,
  loading: false,
};

const AppConfigs = (state = initialState, action) => {
  switch (action.type) {
    case IS_APP_CONFIG_AUTHORIZE:
      state = {
        ...state,
        isAuthorize: action.payload,
        loading: false,
      };
      break;

    case CREATE_APP_CONFIGS:
      state = {
        ...state,
        appConfigs: null,
        appConfigError: null,
        loading: true,
        message: null,
      };
      break;

    case UPDATE_ACCESS_CONTROL_FE:
      state = {
        ...state,
        accessControlFE: null,
        accessControlFEError: null,
        loading: true,
        tableError: null,
        message: null,
      };
      break;

    case LIST_ACCESS_CONTROL_FE:
    case DELETE_ACCESS_CONTROL_FE:
    case CREATE_ACCESS_CONTROL_FE:
      state = {
        ...state,
        accessControlFE: null,
        eachAccessControlFE: null,
        accessControlFEError: null,
        loading: true,
        tableError: null,
        message: null,
      };
      break;

    case LIST_EACH_ACCESS_CONTROL_FE:
      state = {
        ...state,
        eachAccessControlFE: null,
        accessControlFEError: null,
        loading: true,
        message: null,
      };
      break;

    case LIST_EACH_ACCESS_CONTROL_FE_SUCCESSFUL:
      state = {
        ...state,
        eachAccessControlFE: action.payload,
        accessControlFEError: null,
        loading: false,
        message: null,
      };
      break;

    case LIST_EACH_ACCESS_CONTROL_FE_API_FAILED:
      state = {
        ...state,
        eachAccessControlFE: null,
        accessControlFEError: action.payload,
        loading: true,
        message: null,
      };
      break;

    case UPDATE_ACCESS_CONTROL_FE_SUCCESSFUL:
      state = {
        ...state,
        message: 'Access Control Updated successfully',
        eachAccessControlFE: [action.payload],
        accessControlFEError: null,
        loading: false,
      };
      break;

    case DELETE_ACCESS_CONTROL_FE_SUCCESSFUL:
      state = {
        ...state,
        message: 'Access Control Deleted successfully',
        // accessControlFE: action.payload,
        accessControlFEError: null,
        loading: false,
      };
      break;

    case LIST_ACCESS_CONTROL_FE_SUCCESSFUL:
      state = {
        ...state,
        accessControlFE: action.payload,
        accessControlFEError: null,
        loading: false,
      };
      break;

    case CREATE_ACCESS_CONTROL_FE_SUCCESSFUL:
      state = {
        ...state,
        // accessControlFE: action.payload,
        accessControlFEError: null,
        loading: false,
        message: 'Access Control created successfully',
      };
      break;

    case CREATE_ACCESS_CONTROL_FE_API_FAILED:
    case UPDATE_ACCESS_CONTROL_FE_API_FAILED:
    case DELETE_ACCESS_CONTROL_FE_API_FAILED:
      state = {
        ...state,
        // appConfig: null,
        accessControlFE: null,
        tableError: null,
        loading: false,
        message: null,
        accessControlFEError: action.payload,
      };
      break;

    case LIST_ACCESS_CONTROL_FE_API_FAILED:
      state = {
        ...state,
        // appConfig: null,
        accessControlFE: null,
        tableError: action.payload,
        loading: false,
        message: null,
        accessControlFEError: null,
      };
      break;

    case CREATE_APP_CONFIGS_SUCCESSFUL:
      state = {
        ...state,
        appConfig: action.payload,
        appConfigError: null,
        loading: false,
        message: 'App created successfully',
      };
      break;

    case FETCH_APP_CONFIGS:
      state = {
        ...state,
        appConfigs: null,
        appConfig: null,
        isAuthorize: null,
        appConfigError: null,
        loading: true,
        message: null,
        deleteMessage: null,
      };
      break;

    case FETCH_APP_CONFIGS_SUCCESSFUL:
      state = {
        ...state,
        appConfigs: action.payload,
        appConfigError: null,
        loading: false,
      };
      break;

    case FETCH_EACH_APP_CONFIGS:
      state = {
        ...state,
        // appConfig: null,
        appConfigError: null,
        loading: true,
      };
      break;

    case FETCH_EACH_APP_CONFIGS_SUCCESSFUL:
      state = {
        ...state,
        appConfig: action.payload,
        appConfigError: null,
        loading: false,
      };
      break;

    case UPDATE_APP_CONFIGS:
    case UPDATE_APP_CONFIG_ITEM:
      state = {
        ...state,
        // appConfig: null,
        appConfigError: null,
        loading: true,
      };
      break;

    case UPDATE_APP_CONFIGS_SUCCESSFUL:
      state = {
        ...state,
        message: 'App Updated successfully',
        appConfig: action.payload,
        appConfigError: null,
        loading: false,
      };
      break;

    case UPDATE_APP_CONFIG_ITEM_SUCCESSFUL:
      let cloneData = { ...state.appConfig };
      let cloneDataItem = cloneData.appConfigItems.filter(
        (item) => item.id !== action.payload.id
      );
      cloneData.appConfigItems = [action.payload, ...cloneDataItem];
      state = {
        ...state,
        message: 'App Updated successfully',
        appConfig: cloneData,
        appConfigError: null,
        loading: false,
      };
      break;

    case DELETE_APP_CONFIGS:
    case DELETE_APP_CONFIG_ITEM:
      state = {
        ...state,
        // appConfig: null,
        appConfigError: null,
        loading: true,
      };
      break;

    case DELETE_APP_CONFIGS_SUCCESSFUL:
      state = {
        ...state,
        appConfig: action.payload,
        appConfigError: null,
        deleteMessage: 'App deleted Successfully',
        loading: false,
      };
      break;

    case DELETE_APP_CONFIG_ITEM_SUCCESSFUL:
      let appClone = { ...state.appConfig };
      let item = appClone.appConfigItems.filter(
        (item) => item.id !== action.payload
      );
      appClone.appConfigItems = item;
      state = {
        ...state,
        appConfig: appClone,
        appConfigError: null,
        deleteMessage: 'App deleted item Successfully',
        loading: false,
      };
      break;

    case CREATE_APP_CONFIGS_API_FAILED:
    case FETCH_APP_CONFIGS_API_FAILED:
    case FETCH_EACH_APP_CONFIGS_API_FAILED:
    case UPDATE_APP_CONFIGS_API_FAILED:
    case UPDATE_APP_CONFIG_ITEM_API_FAILED:
    case DELETE_APP_CONFIGS_API_FAILED:
    case DELETE_APP_CONFIG_ITEM_API_FAILED:
      state = {
        ...state,
        // appConfig: null,
        appConfigs: null,
        loading: false,
        message: null,
        appConfigError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AppConfigs;
