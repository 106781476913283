import {
  CREATE_PACKAGE,
  CREATE_PACKAGE_SUCCESSFUL,
  CREATE_PACKAGE_FAILED,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_SUCCESSFUL,
  UPDATE_PACKAGE_FAILED,
  FETCH_PACKAGE,
  FETCH_PACKAGE_SUCCESSFUL,
  FETCH_PACKAGE_FAILED,
  FETCH_PACKAGE_VEHICLES,
  FETCH_PACKAGE_VEHICLES_SUCCESSFUL,
  FETCH_PACKAGE_VEHICLES_FAILED,
  FETCH_EACH_PACKAGE,
  FETCH_EACH_PACKAGE_SUCCESSFUL,
  FETCH_EACH_PACKAGE_FAILED,
  DELETE_PACKAGE,
  DELETE_PACKAGE_SUCCESSFUL,
  DELETE_PACKAGE_FAILED,
  FETCH_PACKAGE_SUMMARY,
  FETCH_PACKAGE_SUMMARY_SUCCESSFULLY,
  FETCH_PACKAGE_SUMMARY_FAILED,
  ADD_PACKAGE_TO_VEHICLE,
  ADD_PACKAGE_TO_VEHICLE_SUCCESSFUL,
  ADD_PACKAGE_TO_VEHICLE_FAILED,
  REMOVE_VEHICLE_FROM_PACKAGE,
  REMOVE_VEHICLE_FROM_PACKAGE_SUCCESSFUL,
  REMOVE_VEHICLE_FROM_PACKAGE_FAILED,
  DELETE_PACKAGE_FROM_VEHICLE,
  DELETE_PACKAGE_FROM_VEHICLE_SUCCESSFUL,
  DELETE_PACKAGE_FROM_VEHICLE_FAILED,
} from './actionTypes';

const initialState = {
  packages: null,
  packagePreview: null,
  packageSummary: null,
  tableError: null,
  summaryError: null,
  generalError: null,
  loading: false,
  message: null,
  packageError: null,
  packageVehicle: null,
};

const Package = (state = initialState, action) => {
  switch (action.type) {
    // Register
    case FETCH_PACKAGE:
      state = {
        ...state,
        packages: null,
        loading: true,
        tableError: null,
        packageError: null,
        message: null,
        packagePreview: null,
        packageVehicle: null,
      };
      break;
    case FETCH_PACKAGE_VEHICLES:
      state = {
        ...state,
        // packageVehicle: null,
        // loading: true,
        tableError: null,
        packageError: null,
        message: null,
      };
      break;

    case ADD_PACKAGE_TO_VEHICLE:
      state = {
        ...state,
        loading: true,
        generalError: null,
        message: null,
      };
      break;

    case ADD_PACKAGE_TO_VEHICLE_SUCCESSFUL:
    case DELETE_PACKAGE_FROM_VEHICLE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        generalError: null,
        // message: action.payload,
        message: 'Action Performed Successfully',
      };
      break;
    case ADD_PACKAGE_TO_VEHICLE_FAILED:
    case DELETE_PACKAGE_FROM_VEHICLE_FAILED:
      state = {
        ...state,
        loading: false,
        generalError: action.payload,
        message: null,
      };
      break;

    case FETCH_PACKAGE_SUCCESSFUL:
      state = {
        ...state,
        packages: action.payload,
        loading: false,
        tableError: null,
        packageError: null,
        message: null,
      };
      break;

    case FETCH_PACKAGE_VEHICLES_SUCCESSFUL:
      state = {
        ...state,
        packageVehicle: action.payload,
        loading: false,
        tableError: null,
        packageError: null,
        message: null,
      };
      break;
    case DELETE_PACKAGE:
    case FETCH_EACH_PACKAGE:
      state = {
        ...state,
        loading: true,
        package: null,
        packageError: null,
        message: null,
      };
      break;

    case REMOVE_VEHICLE_FROM_PACKAGE:
    case DELETE_PACKAGE_FROM_VEHICLE:
      state = {
        ...state,
        loading: true,
        packageError: null,
        message: null,
      };
      break;
    case FETCH_PACKAGE_SUMMARY:
      return {
        ...state,
        packageSummary: null,
        summaryError: null,
        loading: true,
      };

    case FETCH_PACKAGE_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        packageSummary: action.payload,
        summaryError: null,
        tableError: null,
        loading: false,
      };

    case FETCH_PACKAGE_SUMMARY_FAILED:
      return {
        ...state,
        packageSummary: null,
        summaryError: action.payload,
        loading: false,
      };
    case FETCH_EACH_PACKAGE_SUCCESSFUL:
      state = {
        ...state,
        packagePreview: action.payload,
        loading: false,
        packageError: null,
        message: null,
      };
      break;

    case UPDATE_PACKAGE:
      state = {
        ...state,
        packages: null,
        loading: true,
        packageError: null,
        message: null,
      };
      break;

    case UPDATE_PACKAGE_SUCCESSFUL:
      state = {
        ...state,
        // packages: action.payload,
        loading: false,
        packageError: null,
        message: 'Package Updated Successfully',
      };
      break;

    case DELETE_PACKAGE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        packageError: null,
        message: 'package deleted successfully',
      };
      break;

    case REMOVE_VEHICLE_FROM_PACKAGE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        packageError: null,
        message: 'Vehicles removed successfully',
      };
      break;

    case CREATE_PACKAGE:
      state = {
        ...state,
        loading: true,
        packageError: null,
        message: null,
      };
      break;

    case CREATE_PACKAGE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        packageError: null,
        message: `${action.payload.name}  created successfully`,
      };
      break;
    case FETCH_PACKAGE_VEHICLES_FAILED:
    case FETCH_PACKAGE_FAILED:
      return {
        ...state,
        packageError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };

    case CREATE_PACKAGE_FAILED:
    case DELETE_PACKAGE_FAILED:
    case REMOVE_VEHICLE_FROM_PACKAGE_FAILED:
    case FETCH_EACH_PACKAGE_FAILED:
      state = {
        ...state,
        packages: null,
        packagePreview: null,
        loading: false,
        packageError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;

    case UPDATE_PACKAGE_FAILED:
      state = {
        ...state,
        loading: false,
        packageError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Package;
