import {
  FETCH_RIDES,
  FETCH_RIDES_SUCCESSFUL,
  FETCH_RIDES_API_FAILED,
  FETCH_FOR_CSV,
  FETCH_FOR_CSV_SUCCESSFULLY,
  FETCH_FOR_CSV_ERROR,
  FETCH_RIDE_SUMMARY,
  FETCH_RIDE_SUMMARY_SUCCESSFULLY,
  FETCH_RIDE_SUMMARY_FAILED,
  FETCH_RIDE_PRICE,
  FETCH_RIDE_PRICE_SUCCESSFULLY,
  FETCH_RIDE_PRICE_FAILED,
  FETCH_EACH_RIDE,
  FETCH_EACH_RIDE_SUCCESSFUL,
  FETCH_EACH_RIDE_API_FAILED,
  FETCH_MY_RIDE,
  FETCH_MY_RIDE_SUCCESSFUL,
  FETCH_MY_RIDE_API_FAILED,
  RIDE_STATUS_UPDATE,
  RIDE_STATUS_UPDATE_SUCCESSFUL,
  RIDE_STATUS_UPDATE_FAILED,
  DELETE_RIDE,
  DELETE_RIDE_SUCCESSFUL,
  DELETE_RIDE_FAILED,
  IS_RIDE_AUTHORIZE,
  CREATE_RIDE,
  CREATE_RIDE_SUCCESSFUL,
  CREATE_RIDE_FAILED,
  UPDATE_RIDE,
  UPDATE_RIDE_SUCCESSFUL,
  UPDATE_RIDE_FAILED,
  BROADCAST_RIDE,
  BROADCAST_RIDE_SUCCESSFUL,
  BROADCAST_RIDE_FAILED,
  RIDE_PRICE,
  RIDE_PRICE_SUCCESSFUL,
  RIDE_PRICE_FAILED,
  FETCH_PLACES,
  FETCH_PLACES_SUCCESSFUL,
  FETCH_PLACES_FAILED,
  FETCH_RIDE_LOG,
  FETCH_RIDE_LOG_SUCCESSFUL,
  FETCH_RIDE_LOG_API_FAILED,
  FETCH_RIDE_DECLINE_LOG,
  FETCH_RIDE_DECLINE_LOG_SUCCESSFUL,
  FETCH_RIDE_DECLINE_LOG_API_FAILED,
  CONNECT,
  CONNECT_SUCCESSFUL,
  CONNECT_FAILED,
} from './actionTypes';

const initialState = {
  rides: null,
  ride: null,
  ridePrice: null,
  ridesError: null,
  rideSummary: null,
  summaryError: null,
  message: null,
  isAuthorize: null,
  loading: false,
  tripId: null,
  places: null,
  placesError: null,
  placesLoading: null,
  csv: null,
  csv_loading: false,
  rideLog: null,
  rideDeclineLog: null,
  rideLogError: null,
  connectRes: null,
  connectErr: null,
};

const Rides = (state = initialState, action) => {
  switch (action.type) {
    case IS_RIDE_AUTHORIZE:
      state = {
        ...state,
        isAuthorize: action.payload,
      };
      break;

    case RIDE_PRICE:
      state = {
        ...state,
        loading: true,
        ridePrice: null,
        ridesError: null,
      };
      break;

    case RIDE_PRICE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        ridePrice: action.payload,
        ridesError: null,
      };
      break;

    case FETCH_RIDES:
    case FETCH_RIDE_PRICE:
    case RIDE_STATUS_UPDATE:
    case DELETE_RIDE:
      state = {
        ...state,
        rides: null,
        ride: null,
        rideprice: null,
        ridesError: null,
        loading: true,
        message: null,
        isAuthorize: null,
        rideLog: null,
        rideLogError: null,
      };
      break;

    case CONNECT:
      state = {
        ...state,
        connectRes: null,
        connectErr: null,
      };
      break;

    case CONNECT_SUCCESSFUL:
      state = {
        ...state,
        connectRes: action.payload,
        connectErr: null,
        message: 'Status updated successfully',
      };
      break;

    case CONNECT_FAILED:
      state = {
        ...state,
        connectErr:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;

    case FETCH_RIDE_LOG:
      state = {
        ...state,
        rideLog: null,
        rideLogError: null,
      };
      break;

    case FETCH_RIDE_DECLINE_LOG:
      state = {
        ...state,
        rideDeclineLog: null,
        rideLogError: null,
      };
      break;

    case FETCH_RIDE_PRICE_SUCCESSFULLY:
      state = {
        ...state,
        ridePrice: action.payload,
        ridesError: null,
        loading: false,
        message: null,
        isAuthorize: null,
      };
      break;

    case FETCH_FOR_CSV:
      state = {
        ...state,
        csv: null,
        csv_loading: false,
      };
      break;
    case FETCH_FOR_CSV_SUCCESSFULLY:
      state = {
        ...state,
        csv: action.payload,
        csv_loading: false,
      };
      break;
    case FETCH_FOR_CSV_ERROR:
      state = {
        ...state,
        csv: null,
        csv_loading: false,
      };
      break;
    case UPDATE_RIDE:
    case BROADCAST_RIDE:
      state = {
        ...state,
        rides: null,
        ridesError: null,
        loading: true,
        message: null,
        isAuthorize: null,
      };
      break;
    case FETCH_RIDES_SUCCESSFUL:
    case FETCH_MY_RIDE_SUCCESSFUL:
      state = {
        ...state,
        rides: action.payload,
        ridesError: null,
        loading: false,
      };
      break;
    case FETCH_RIDE_LOG_SUCCESSFUL:
      state = {
        ...state,
        rideLog: action.payload,
        rideLogError: null,
        loading: false,
      };
      break;

    case FETCH_RIDE_DECLINE_LOG_SUCCESSFUL:
      state = {
        ...state,
        rideDeclineLog: action.payload,
        rideLogError: null,
        loading: false,
      };
      break;

    case DELETE_RIDE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        ridesError: null,
        message: 'Ride deleted successfully',
      };
      break;

    case UPDATE_RIDE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        ridesError: null,
        message: 'Ride Updated Successfully',
      };
      break;

    case BROADCAST_RIDE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        ridesError: null,
        message: 'Ride Broadcast Successfully',
      };
      break;
    case FETCH_EACH_RIDE:
      state = {
        ...state,
        ride: null,
        ridesError: null,
        loading: true,
        isAuthorize: null,
      };
      break;
    case FETCH_MY_RIDE:
      state = {
        ...state,
        ridesError: null,
        loading: true,
        message: null,
        ride: null,
      };
      break;
    case FETCH_EACH_RIDE_SUCCESSFUL:
      state = {
        ...state,
        ride: action.payload,
        ridesError: null,
        loading: true,
      };
      break;

    case RIDE_STATUS_UPDATE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        ridesError: null,
        message: 'Ride status successfully changed',
        acceptMessage: 'Ride Accepted Successfully',
      };
      break;

    case FETCH_RIDE_SUMMARY:
      return {
        ...state,
        rideSummary: null,
        summaryError: null,
        loading: true,
      };

    case FETCH_RIDE_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        rideSummary: action.payload,
        summaryError: null,
        loading: false,
      };

    case FETCH_RIDE_SUMMARY_FAILED:
      return {
        ...state,
        rideSummary: null,
        summaryError: action.payload,
        loading: false,
      };
    case CREATE_RIDE:
      return {
        ...state,
        loading: true,
        ridesError: null,
        message: null,
      };

    case CREATE_RIDE_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        ridesError: null,
        message: `A trip to ${action.payload.dropOff.address} has been created successfully`,
        tripId: action.payload.id,
      };

    case FETCH_RIDE_PRICE_FAILED:
    case FETCH_MY_RIDE_API_FAILED:
      return {
        ...state,
        rides: null,
        loading: false,
        ridePrice: null,
        message: null,
        ridesError: action.payload,
      };

    case CREATE_RIDE_FAILED:
    case FETCH_RIDES_API_FAILED:
    case DELETE_RIDE_FAILED:
    case FETCH_EACH_RIDE_API_FAILED:
    case RIDE_PRICE_FAILED:
    case UPDATE_RIDE_FAILED:
    case BROADCAST_RIDE_FAILED:
    case RIDE_STATUS_UPDATE_FAILED:
      return {
        ...state,
        rides: null,
        loading: false,
        ridePrice: null,
        price: null,
        message: null,
        ridesError: action.payload,
      };

    case FETCH_RIDE_DECLINE_LOG_API_FAILED:
    case FETCH_RIDE_LOG_API_FAILED:
      return {
        ...state,
        rideLog: null,
        rideDeclineLog: null,
        loading: false,
        rideLogError: action.payload,
      };

    case FETCH_PLACES:
      state = {
        ...state,
        places: null,
        placesError: null,
        placesLoading: true,
      };
      break;

    case FETCH_PLACES_SUCCESSFUL:
      state = {
        ...state,
        places: action.payload,
        placesError: null,
        placesLoading: false,
      };
      break;

    case FETCH_PLACES_FAILED:
      return {
        ...state,
        placesError: action.payload,
        places: null,
        placesLoading: false,
      };

    default:
      return { ...state };
  }
  return state;
};

export default Rides;
