import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  FETCH_RIDES,
  FETCH_FOR_CSV,
  RIDE_PRICE,
  FETCH_RIDE_SUMMARY,
  FETCH_RIDE_PRICE,
  FETCH_EACH_RIDE,
  RIDE_STATUS_UPDATE,
  DELETE_RIDE,
  FETCH_MY_RIDE,
  CREATE_RIDE,
  UPDATE_RIDE,
  BROADCAST_RIDE,
  FETCH_PLACES,
  FETCH_RIDE_LOG,
  FETCH_RIDE_DECLINE_LOG,
  CONNECT,
} from './actionTypes';
import {
  fetchRideSummaryFailed,
  fetchRideSummarySuccessfully,
  fetchRidePriceFailed,
  fetchRidePriceSuccessfully,
  fetchRidesSuccessful,
  fetchRidesError,
  fetchForCSVSuccessfully,
  fetchForCSVError,
  fetchMyRideSuccessful,
  fetchMyRideError,
  fetchEachRideSuccessful,
  fetchEachRideError,
  rideStatusSuccessful,
  rideStatusFailed,
  fetchRideAuthorizeError,
  deleteRideSuccessful,
  deleteRideFailed,
  createRideSuccessful,
  createRideFailed,
  updateRideSuccessful,
  updateRideFailed,
  broadCastRideSuccessful,
  broadCastRideFailed,
  RidePriceSuccessful,
  RidePriceFailed,
  fetchPlacesSuccessful,
  fetchPlacesFailed,
  fetchRideLogSuccessful,
  fetchRideLogError,
  fetchRideDeclineLogSuccessful,
  fetchRideDeclineLogError,
  connectDriverSuccessful,
  connectDriverFailed,
} from './actions';

import {
  GetRidesService,
  GetEachRideService,
  GetMyRideService,
  RideStatusService,
  DeleteRideService,
  CreateRideService,
  BroadCastRideService,
  UpdateRideService,
  fetchRideSummaryService,
  fetchRidePriceService,
  RidePriceService,
  GetPlacesService,
  GetRideLogService,
  GetRideDeclineLogService,
  ConnectService,
} from '../../services/ridesService';

function* fetchRidesHandler({ payload }) {
  try {
    const response = yield call(GetRidesService, payload);
    yield put(fetchRidesSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchRideAuthorizeError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    }
    yield put(fetchRidesError(error?.response.data?.message));
  }
}
function* fetchForCSVHandler({ payload }) {
  try {
    const response = yield call(GetRidesService, payload);
    yield put(fetchForCSVSuccessfully(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchRideAuthorizeError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    }
    yield put(fetchForCSVError(error?.response.data?.message));
  }
}

function* fetchMyRideHandler({ payload }) {
  try {
    const response = yield call(GetMyRideService, payload);
    yield put(fetchMyRideSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchMyRideError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchMyRideError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchMyRideError(error?.response?.data?.message));
    }
  }
}

function* fetchEachRideHandler({ payload }) {
  try {
    const response = yield call(GetEachRideService, payload);
    yield put(fetchEachRideSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachRideError(error?.response.data?.message));
  }
}

function* fetchPlacesHandler({ payload }) {
  try {
    const response = yield call(GetPlacesService, payload);
    yield put(fetchPlacesSuccessful(response.data));
  } catch (error) {
    yield put(fetchPlacesFailed(error?.response.data?.message));
  }
}

function* rideStatusHandler({ payload }) {
  try {
    const response = yield call(RideStatusService, payload);
    yield put(rideStatusSuccessful(response.data.result));
  } catch (error) {
    yield put(rideStatusFailed(error?.response?.data?.message));
  }
}
function* deleteRideHandler({ payload }) {
  try {
    const response = yield call(DeleteRideService, payload);
    yield put(deleteRideSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteRideFailed(error?.response?.data?.message));
  }
}

function* createRideHandler({ payload }) {
  try {
    const response = yield call(CreateRideService, payload);
    yield put(createRideSuccessful(response.data.result));
  } catch (error) {
    console.log('error from saga');
    console.log(error);
    yield put(createRideFailed(error?.response?.data?.message));
  }
}

function* updateRideHandler({ payload }) {
  try {
    const response = yield call(UpdateRideService, payload);
    yield put(updateRideSuccessful(response.data.result));
  } catch (error) {
    yield put(updateRideFailed(error?.response?.data?.message));
  }
}

function* RidePriceHandler({ payload }) {
  try {
    const response = yield call(RidePriceService, payload);
    yield put(RidePriceSuccessful(response.data.result));
  } catch (error) {
    yield put(RidePriceFailed(error?.response?.data?.message));
  }
}

// RIDE SUMMARY
function* fetchRideSummaryHandler({ payload }) {
  try {
    const response = yield call(fetchRideSummaryService, payload);
    yield put(fetchRideSummarySuccessfully(response.data));
  } catch (error) {
    yield put(fetchRideSummaryFailed(error.response.data));
  }
}

// Price SUMMARY
function* fetchRidePriceHandler({ payload }) {
  try {
    const response = yield call(fetchRidePriceService, payload);
    yield put(fetchRidePriceSuccessfully(response.data));
  } catch (error) {
    yield put(fetchRidePriceFailed(error.response.data.message));
  }
}

function* broadCastRideHandler({ payload }) {
  try {
    const response = yield call(BroadCastRideService, payload);
    yield put(broadCastRideSuccessful(response.data.result));
  } catch (error) {
    yield put(broadCastRideFailed(error?.response?.data?.message));
  }
}
function* connectHandler({ payload }) {
  try {
    const response = yield call(ConnectService, payload);
    yield put(connectDriverSuccessful(response.data.result));
  } catch (error) {
    yield put(connectDriverFailed(error?.response?.data?.error));
  }
}

function* fetchRideLogHandler({ payload }) {
  try {
    const response = yield call(GetRideLogService, payload);
    yield put(fetchRideLogSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchRideLogError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchRideLogError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchRideLogError(error?.response?.data?.message));
    }
  }
}

function* fetchRideDeclineLogHandler({ payload }) {
  try {
    const response = yield call(GetRideDeclineLogService, payload);
    yield put(fetchRideDeclineLogSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchRideDeclineLogError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchRideDeclineLogError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchRideDeclineLogError(error?.response?.data?.message));
    }
  }
}

export function* watchBroadCastRide() {
  yield takeEvery(BROADCAST_RIDE, broadCastRideHandler);
}

export function* watchFetchRides() {
  yield takeEvery(FETCH_RIDES, fetchRidesHandler);
}
export function* watchFetchRideLog() {
  yield takeEvery(FETCH_RIDE_LOG, fetchRideLogHandler);
}

export function* watchFetchRideDeclineLog() {
  yield takeEvery(FETCH_RIDE_DECLINE_LOG, fetchRideDeclineLogHandler);
}

export function* watchForCSV() {
  yield takeEvery(FETCH_FOR_CSV, fetchForCSVHandler);
}

export function* watchFetchMyRide() {
  yield takeEvery(FETCH_MY_RIDE, fetchMyRideHandler);
}

export function* watchFetchEachRide() {
  yield takeEvery(FETCH_EACH_RIDE, fetchEachRideHandler);
}

export function* watchFetchPlaces() {
  yield takeEvery(FETCH_PLACES, fetchPlacesHandler);
}
export function* watchCancleRide() {
  yield takeEvery(RIDE_STATUS_UPDATE, rideStatusHandler);
}
export function* watchDeleteRide() {
  yield takeEvery(DELETE_RIDE, deleteRideHandler);
}
export function* watchCreateRide() {
  yield takeEvery(CREATE_RIDE, createRideHandler);
}
export function* watchUpdateRide() {
  yield takeEvery(UPDATE_RIDE, updateRideHandler);
}
export function* watchRidePrice() {
  yield takeEvery(RIDE_PRICE, RidePriceHandler);
}

export function* watchFetchRidePrice() {
  yield takeEvery(FETCH_RIDE_PRICE, fetchRidePriceHandler);
}

export function* watchConnect() {
  yield takeEvery(CONNECT, connectHandler);
}

export function* watchFetchRideSummary() {
  yield takeEvery(FETCH_RIDE_SUMMARY, fetchRideSummaryHandler);
}

function* ridesSaga() {
  yield all([
    fork(watchFetchRides),
    fork(watchForCSV),
    fork(watchFetchEachRide),
    fork(watchFetchRidePrice),
    fork(watchFetchMyRide),
    fork(watchCancleRide),
    fork(watchDeleteRide),
    fork(watchBroadCastRide),
    fork(watchFetchRideSummary),
    fork(watchCreateRide),
    fork(watchUpdateRide),
    fork(watchRidePrice),
    fork(watchFetchPlaces),
    fork(watchConnect),
    fork(watchFetchRideLog),
    fork(watchFetchRideDeclineLog),
  ]);
}

export default ridesSaga;
