import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_BONUS,
  FETCH_USER_BONUS,
  FETCH_EACH_USER_BONUS,
  FETCH_BONUS_LIST,
  FETCH_BONUS_LOG,
  FETCH_BONUS_LOG_SUMMARY,
  FETCH_EACH_BONUS_LOG,
  FETCH_EACH_BONUS,
  FETCH_BONUS_SUMMARY,
  CREATE_BONUS,
  DELETE_BONUS,
  UPDATE_BONUS,
  FETCH_BONUS_CSV,
  FETCH_BONUS_LOG_CSV,
} from './actionTypes';
import {
  fetchBonusListSuccessful,
  fetchBonusListError,
  fetchBonusLogSuccessful,
  fetchBonusLogSummarySuccessful,
  fetchEachBonusLogError,
  fetchEachBonusLogSuccessful,
  fetchBonusLogError,
  fetchBonusLogSummaryError,
  fetchBonusSuccessful,
  fetchBonusError,
  fetchEachBonusSuccessful,
  fetchEachBonusError,
  deleteBonusSuccessful,
  deleteBonusFailed,
  createBonusSuccessful,
  createBonusFailed,
  updateBonusSuccessful,
  updateBonusFailed,
  fetchEachUserBonusSuccessful,
  fetchEachUserBonusError,
  fetchBonusSummaryFailed,
  fetchBonusSummarySuccessfully,
  fetctUserBonusSuccessful,
  fetctUserBonusError,
  fetchBonusCSVSuccessfully,
  fetchBonusCSVError,
  fetchBonusLogCSVSuccessfully,
  fetchBonusLogCSVError,
} from './actions';

import {
  GetBonusService,
  GetBonusListService,
  GetEachBonusLogService,
  GetBonusLogService,
  GetBonusLogSummaryService,
  GetEachBonusService,
  DeleteBonusService,
  fetchUserBonusService,
  fetchEachUserBonusService,
  fetchBonusSummaryService,
  CreateBonusService,
  UpdateBonusService,
} from '../../services/bonusService';

function* fetchBonusListHandler({ payload }) {
  try {
    const response = yield call(GetBonusListService, payload);
    yield put(fetchBonusListSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchBonusListError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchBonusListError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchBonusListError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchBonusLogHandler({ payload }) {
  try {
    const response = yield call(GetBonusLogService, payload);
    yield put(fetchBonusLogSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchBonusLogError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchBonusLogError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchBonusLogError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchBonusLogSummaryHandler({ payload }) {
  try {
    const response = yield call(GetBonusLogSummaryService, payload);
    yield put(fetchBonusLogSummarySuccessful(response.data));
  } catch (error) {
    yield put(fetchBonusLogSummaryError(error?.response?.data?.message));
  }
}

function* fetchEachBonusLogHandler({ payload }) {
  try {
    const response = yield call(GetEachBonusLogService, payload);
    yield put(fetchEachBonusLogSuccessful(response.data));
  } catch (error) {
    yield put(fetchEachBonusLogError(error?.response?.data?.message));
  }
}

function* fetchBonusHandler({ payload }) {
  try {
    const response = yield call(GetBonusService, payload);
    yield put(fetchBonusSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchBonusError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchBonusError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchBonusError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEachBonusHandler({ payload }) {
  try {
    const response = yield call(GetEachBonusService, payload);
    yield put(fetchEachBonusSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachBonusError(error?.response?.data?.message));
  }
}

function* deleteBonusHandler({ payload }) {
  try {
    const response = yield call(DeleteBonusService, payload);
    yield put(deleteBonusSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteBonusFailed(error?.response?.data?.error));
  }
}

function* createBonusHandler({ payload }) {
  try {
    const response = yield call(CreateBonusService, payload);
    yield put(createBonusSuccessful(response.data.result));
  } catch (error) {
    yield put(createBonusFailed(error?.response?.data?.message));
  }
}

function* updateBonusHandler({ payload }) {
  try {
    const response = yield call(UpdateBonusService, payload);
    yield put(updateBonusSuccessful(response.data.result));
  } catch (error) {
    yield put(updateBonusFailed(error?.response?.data?.message));
  }
}

function* fetchBonusSummaryHandler({ payload }) {
  try {
    const response = yield call(fetchBonusSummaryService, payload);
    yield put(fetchBonusSummarySuccessfully(response.data));
  } catch (error) {
    yield put(fetchBonusSummaryFailed(error.response.data));
  }
}

function* fetchBonusCSVHandler({ payload }) {
  try {
    const response = yield call(GetBonusService, payload);
    yield put(fetchBonusCSVSuccessfully(response.data));
  } catch (error) {
    yield put(fetchBonusCSVError(error?.response?.data?.message));
  }
}

function* fetchBonusLogCSVHandler({ payload }) {
  try {
    const response = yield call(GetBonusLogService, payload);
    yield put(fetchBonusLogCSVSuccessfully(response.data));
  } catch (error) {
    yield put(fetchBonusLogCSVError(error?.response?.data?.message));
  }
}

function* fetchUserBonusHandler({ payload }) {
  try {
    const response = yield call(fetchUserBonusService, payload);
    yield put(fetctUserBonusSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetctUserBonusError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetctUserBonusError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetctUserBonusError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEachUserBonusHandler({ payload }) {
  try {
    const response = yield call(fetchEachUserBonusService, payload);
    yield put(fetchEachUserBonusSuccessful(response.data));
  } catch (error) {
    yield put(fetchEachUserBonusError(error.response.data));
  }
}

export function* watchFetchBonusSummary() {
  yield takeEvery(FETCH_BONUS_SUMMARY, fetchBonusSummaryHandler);
}

export function* watchFetchUserBonus() {
  yield takeEvery(FETCH_USER_BONUS, fetchUserBonusHandler);
}

export function* watchFetchEachUserBonus() {
  yield takeEvery(FETCH_EACH_USER_BONUS, fetchEachUserBonusHandler);
}

export function* watchFetchBonusList() {
  yield takeEvery(FETCH_BONUS_LIST, fetchBonusListHandler);
}

export function* watchFetchBonusLog() {
  yield takeEvery(FETCH_BONUS_LOG, fetchBonusLogHandler);
}

export function* watchFetchBonusLogSummary() {
  yield takeEvery(FETCH_BONUS_LOG_SUMMARY, fetchBonusLogSummaryHandler);
}

export function* watchFetchEachBonusLog() {
  yield takeEvery(FETCH_EACH_BONUS_LOG, fetchEachBonusLogHandler);
}

export function* watchFetchBonus() {
  yield takeEvery(FETCH_BONUS, fetchBonusHandler);
}

export function* watchFetchEachBonus() {
  yield takeEvery(FETCH_EACH_BONUS, fetchEachBonusHandler);
}
export function* watchDeleteBonus() {
  yield takeEvery(DELETE_BONUS, deleteBonusHandler);
}
export function* watchCreateBonus() {
  yield takeEvery(CREATE_BONUS, createBonusHandler);
}
export function* watchUpdateBonus() {
  yield takeEvery(UPDATE_BONUS, updateBonusHandler);
}

export function* watchBonusCSV() {
  yield takeEvery(FETCH_BONUS_CSV, fetchBonusCSVHandler);
}

export function* watchBonusLogCSV() {
  yield takeEvery(FETCH_BONUS_LOG_CSV, fetchBonusLogCSVHandler);
}

function* bonusSaga() {
  yield all([
    fork(watchFetchBonusList),
    fork(watchFetchBonusLog),
    fork(watchFetchBonusLogSummary),
    fork(watchFetchEachBonusLog),
    fork(watchFetchEachUserBonus),
    fork(watchFetchBonus),
    fork(watchFetchUserBonus),
    fork(watchFetchEachBonus),
    fork(watchFetchBonusSummary),
    fork(watchDeleteBonus),
    fork(watchCreateBonus),
    fork(watchUpdateBonus),
    fork(watchBonusCSV),
    fork(watchBonusLogCSV),
  ]);
}

export default bonusSaga;
