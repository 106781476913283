import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import Table from './table';
import PreviewCashout from './PreviewCashout';
import GreySummaryCard from '../../../../components/Common/Summary/index';

import {
  FetchEachCashoutAnalytics,
  FetchCashoutAnalytics,
  fetchCashoutCSV,
  fetchCashoutCSVError,
} from '../../../../store/actions';

const Cashout = ({
  cashoutAnalytics,
  FetchEachCashoutAnalytics,
  cashoutAnalyticsPreview,
  tableError,
  cashoutAnalyticsError,
  loading,
  message,
  history,
  FetchCashoutAnalytics,
}) => {
  const [showModal, setShowModal] = useState(false);

  const [previewId, setPreviewId] = useState(null);
  const [date, setDate] = useState({
    start: '',
    end: '',
  });
  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'Analytic', link: '#' },
    { title: 'Cashout', link: '/cashout' },
  ];

  const previewHandler = (id, date) => {
    setShowModal(true);
    setPreviewId({ id, date });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Analytic" breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field="Analytics" subModule="Cashout">
            <>
              {message && (
                <Alert color="success" className="text-center">
                  {message}
                </Alert>
              )}
              {tableError ? (
                <Alert color="danger" className="text-center">
                  {tableError}
                </Alert>
              ) : (
                <Row>
                  <Col>
                    <Table
                      cashoutAnalyticData={cashoutAnalytics}
                      FetchCashoutAnalytics={FetchCashoutAnalytics}
                      setGeneralDate={setDate}
                      message={message}
                      loading={loading}
                      history={history}
                      previewHandler={previewHandler}
                    />
                  </Col>
                </Row>
              )}
              {/* Cashout Preview Modal */}
              <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
                <ModalHeader toggle={() => setShowModal(false)}>
                  Cashout Analytic Preview
                </ModalHeader>
                <ModalBody>
                  <PreviewCashout
                    cashoutAnalyticsError={cashoutAnalyticsError}
                    FetchEachCashoutAnalytics={FetchEachCashoutAnalytics}
                    cashoutAnalyticsPreview={cashoutAnalyticsPreview}
                    previewId={previewId}
                    loading={loading}
                  />
                </ModalBody>
              </Modal>
            </>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    csvData,
    csv_loading,
    cashoutAnalytics,
    cashoutAnalyticsPreview,
    loading,
    tableError,
    cashoutAnalyticsError,
    message,
  } = state.AnalyticCashout;
  return {
    csvData,
    csv_loading,
    cashoutAnalytics,
    cashoutAnalyticsPreview,
    loading,
    tableError,
    cashoutAnalyticsError,
    message,
  };
};

export default connect(mapStateToProps, {
  FetchEachCashoutAnalytics,
  fetchCashoutCSV,
  FetchCashoutAnalytics,
  fetchCashoutCSVError,
})(Cashout);
