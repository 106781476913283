import {
  FETCH_CONTACTS,
  FETCH_CONTACTS_SUCCESSFUL,
  FETCH_CONTACTS_API_FAILED,
  FETCH_SOS,
  FETCH_SOS_SUCCESSFUL,
  FETCH_SOS_API_FAILED,
  FETCH_EACH_CONTACT,
  FETCH_EACH_CONTACT_SUCCESSFUL,
  FETCH_EACH_CONTACT_API_FAILED,
  FETCH_EACH_SOS,
  FETCH_EACH_SOS_SUCCESSFUL,
  FETCH_EACH_SOS_API_FAILED,
  DELETE_CONTACT,
  DELETE_CONTACT_SUCCESSFUL,
  DELETE_CONTACT_FAILED,
  CREATE_CONTACT,
  CREATE_CONTACT_SUCCESSFUL,
  CREATE_CONTACT_FAILED,
  UPDATE_CONTACT,
  UPDATE_CONTACT_SUCCESSFUL,
  UPDATE_CONTACT_FAILED,
  UPDATE_SOS,
  UPDATE_SOS_SUCCESSFUL,
  UPDATE_SOS_FAILED,
} from './actionTypes';

export const fetchContacts = (payload) => {
  return {
    type: FETCH_CONTACTS,
    payload,
  };
};

export const fetchContactsSuccessful = (payload) => {
  return {
    type: FETCH_CONTACTS_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchContactsError = (error) => {
  return {
    type: FETCH_CONTACTS_API_FAILED,
    payload: error,
  };
};

export const fetchSOS = (payload) => {
  return {
    type: FETCH_SOS,
    payload,
  };
};

export const fetchSOSSuccessful = (payload) => {
  return {
    type: FETCH_SOS_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchSOSError = (error) => {
  return {
    type: FETCH_SOS_API_FAILED,
    payload: error,
  };
};

export const fetchEachContact = (id) => {
  return {
    type: FETCH_EACH_CONTACT,
    payload: id,
  };
};

export const fetchEachContactSuccessful = (payload) => {
  return {
    type: FETCH_EACH_CONTACT_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachContactError = (error) => {
  return {
    type: FETCH_EACH_CONTACT_API_FAILED,
    payload: error,
  };
};

export const fetchEachSOS = (id) => {
  return {
    type: FETCH_EACH_SOS,
    payload: id,
  };
};

export const fetchEachSOSSuccessful = (payload) => {
  return {
    type: FETCH_EACH_SOS_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachSOSError = (error) => {
  return {
    type: FETCH_EACH_SOS_API_FAILED,
    payload: error,
  };
};

export const deleteContact = (id) => {
  return {
    type: DELETE_CONTACT,
    payload: id,
  };
};

export const deleteContactSuccessful = (payload) => {
  return {
    type: DELETE_CONTACT_SUCCESSFUL,
    payload,
  };
};

export const deleteContactFailed = (error) => {
  return {
    type: DELETE_CONTACT_FAILED,
    payload: error,
  };
};

export const createContact = (payload) => {
  return {
    type: CREATE_CONTACT,
    payload,
  };
};

export const createContactSuccessful = (payload) => {
  return {
    type: CREATE_CONTACT_SUCCESSFUL,
    payload,
  };
};

export const createContactFailed = (error) => {
  return {
    type: CREATE_CONTACT_FAILED,
    payload: error,
  };
};

export const updateContact = (payload) => {
  return {
    type: UPDATE_CONTACT,
    payload,
  };
};

export const updateContactFailed = (error) => {
  return {
    type: UPDATE_CONTACT_FAILED,
    payload: error,
  };
};

export const updateContactSuccessful = (payload) => {
  return {
    type: UPDATE_CONTACT_SUCCESSFUL,
    payload,
  };
};

export const updateSOS = (payload, id) => {
  return {
    type: UPDATE_SOS,
    payload: { payload, id },
  };
};

export const updateSOSFailed = (error) => {
  return {
    type: UPDATE_SOS_FAILED,
    payload: error,
  };
};

export const updateSOSSuccessful = (payload) => {
  return {
    type: UPDATE_SOS_SUCCESSFUL,
    payload,
  };
};
