import {
  FETCH_DOCUMENT,
  FETCH_DOCUMENT_SUCCESSFUL,
  FETCH_DOCUMENT_API_FAILED,
  FETCH_EACH_DOCUMENT,
  FETCH_EACH_DOCUMENT_SUCCESSFUL,
  FETCH_EACH_DOCUMENT_API_FAILED,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_SUCCESSFUL,
  DELETE_DOCUMENT_FAILED,
  CREATE_DOCUMENT,
  CREATE_DOCUMENT_SUCCESSFUL,
  CREATE_DOCUMENT_FAILED,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_SUCCESSFUL,
  UPDATE_DOCUMENT_FAILED,
  IS_DOCUMENT_AUTHORIZE,
  FETCH_DOCUMENT_SUMMARY,
  FETCH_DOCUMENT_SUMMARY_SUCCESSFULLY,
  FETCH_DOCUMENT_SUMMARY_FAILED,
} from './actionTypes';

const initialState = {
  document: null,
  documentError: null,
  documentSummary: null,
  summaryError: null,
  tableError: null,
  documentPreview: null,
  isAuthorize: null,
  message: null,
  loading: false,
};

const Document = (state = initialState, action) => {
  switch (action.type) {
    case IS_DOCUMENT_AUTHORIZE:
      state = {
        ...state,
        isAuthorize: action.payload,
      };
      break;

    case FETCH_DOCUMENT:
    case DELETE_DOCUMENT:
      state = {
        ...state,
        tableError: null,
        documentError: null,
        loading: true,
        message: null,
        isAuthorize: null,
      };
      break;

    case UPDATE_DOCUMENT:
      state = {
        ...state,
        tableError: null,
        documentError: null,
        message: null,
        isAuthorize: null,
      };
      break;

    case FETCH_DOCUMENT_SUCCESSFUL:
      state = {
        ...state,
        document: action.payload,
        tableError: null,
        documentError: null,
        loading: false,
      };
      break;

    case DELETE_DOCUMENT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        documentError: null,
        message: 'Document deleted successfully',
      };
      break;
    case UPDATE_DOCUMENT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        documentError: null,
        message: 'Document Updated Successfully',
      };
      break;

    case FETCH_DOCUMENT_SUMMARY:
      return {
        ...state,
        documentSummary: null,
        summaryError: null,
        loading: true,
      };

    case FETCH_DOCUMENT_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        documentSummary: action.payload,
        summaryError: null,
        loading: false,
      };

    case FETCH_DOCUMENT_SUMMARY_FAILED:
      return {
        ...state,
        documentSummary: null,
        summaryError: action.payload,
        loading: false,
      };
    case FETCH_EACH_DOCUMENT:
      state = {
        ...state,
        loading: true,
        documentError: null,
        message: null,
        isAuthorize: null,
      };
      break;

    case FETCH_EACH_DOCUMENT_SUCCESSFUL:
      state = {
        ...state,
        documentPreview: action.payload,
        loading: false,
        documentError: null,
        message: null,
      };
      break;

    case CREATE_DOCUMENT:
      state = {
        ...state,
        loading: true,
        documentError: null,
        message: null,
      };
      break;

    case CREATE_DOCUMENT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        documentError: null,
        message: `${action.payload.name}  created successfully`,
      };
      break;

    case FETCH_DOCUMENT_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case CREATE_DOCUMENT_FAILED:
    case DELETE_DOCUMENT_FAILED:
    case FETCH_EACH_DOCUMENT_API_FAILED:
      state = {
        ...state,
        document: null,
        documentPreview: null,
        loading: false,
        message: null,
        documentError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_DOCUMENT_FAILED:
      state = {
        ...state,
        loading: false,
        documentError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Document;
