import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetLoyaltyTierService = (query) => {
  const http = new HttpService();
  let url = 'loyaltyTier/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const GetEachLoyaltyTierService = (id) => {
  const http = new HttpService();
  const url = `loyaltyTier/${id}`;
  return http.getData(url);
};
export const GetMyLoyaltyTierService = () => {
  const http = new HttpService();
  const url = `loyaltyTier`;
  return http.getData(url);
};
export const CreateLoyaltyTierService = (payload) => {
  const http = new HttpService();
  const url = 'loyaltyTier';
  return http.postData(payload, url);
};
export const UpdateLoyaltyTierService = (payload) => {
  const http = new HttpService();
  const url = `loyaltyTier/${payload.id}`;
  console.log(url);
  return http.putData(payload, url);
};

export const DeleteLoyaltyTierService = (id) => {
  const http = new HttpService();
  const url = `loyaltyTier/${id}`;
  return http.deleteData(url);
};

export const fetchLoyaltyTierSummaryService = (query) => {
  // Payload with query
  const http = new HttpService();
  console.log(query);
  let url = 'summary/loyaltyTier';
  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};
