import {
  FETCH_ROLE,
  FETCH_ROLE_SUCCESSFUL,
  FETCH_ROLE_API_FAILED,
  FETCH_EACH_ROLE,
  FETCH_EACH_ROLE_SUCCESSFUL,
  FETCH_EACH_ROLE_API_FAILED,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESSFUL,
  DELETE_ROLE_FAILED,
  CREATE_ROLE,
  CREATE_ROLE_SUCCESSFUL,
  CREATE_ROLE_FAILED,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESSFUL,
  UPDATE_ROLE_FAILED,
  FETCH_PRIVILEGES,
  FETCH_PRIVILEGES_SUCCESSFUL,
  FETCH_PRIVILEGES_API_FAILED,
  ASSIGN_ROLE,
  ASSIGN_ROLE_SUCCESSFUL,
  ASSIGN_ROLE_FAILED,
  UNASSIGN_ROLE,
  UNASSIGN_ROLE_SUCCESSFUL,
  UNASSIGN_ROLE_FAILED,
} from './actionTypes';

export const fetchRoles = (payload) => {
  return {
    type: FETCH_ROLE,
    payload,
  };
};

export const fetchRoleSuccessful = (payload) => {
  return {
    type: FETCH_ROLE_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchRoleError = (error) => {
  return {
    type: FETCH_ROLE_API_FAILED,
    payload: error,
  };
};

export const fetchEachRole = (id) => {
  return {
    type: FETCH_EACH_ROLE,
    payload: id,
  };
};

export const fetchEachRoleSuccessful = (payload) => {
  return {
    type: FETCH_EACH_ROLE_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachRoleError = (error) => {
  return {
    type: FETCH_EACH_ROLE_API_FAILED,
    payload: error,
  };
};

export const deleteRole = (id) => {
  return {
    type: DELETE_ROLE,
    payload: id,
  };
};

export const deleteRoleSuccessful = (payload) => {
  return {
    type: DELETE_ROLE_SUCCESSFUL,
    payload,
  };
};

export const deleteRoleFailed = (error) => {
  return {
    type: DELETE_ROLE_FAILED,
    payload: error,
  };
};

export const createRole = (payload) => {
  return {
    type: CREATE_ROLE,
    payload,
  };
};

export const createRoleSuccessful = (payload) => {
  return {
    type: CREATE_ROLE_SUCCESSFUL,
    payload,
  };
};

export const createRoleFailed = (error) => {
  return {
    type: CREATE_ROLE_FAILED,
    payload: error,
  };
};

export const updateRole = (payload) => {
  return {
    type: UPDATE_ROLE,
    payload,
  };
};

export const updateRoleFailed = (error) => {
  return {
    type: UPDATE_ROLE_FAILED,
    payload: error,
  };
};

export const updateRoleSuccessful = (payload) => {
  return {
    type: UPDATE_ROLE_SUCCESSFUL,
    payload,
  };
};

export const fetchPrivileges = (payload) => {
  return {
    type: FETCH_PRIVILEGES,
    payload,
  };
};

export const fetchPrivilegesSuccessful = (payload) => {
  return {
    type: FETCH_PRIVILEGES_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchPrivilegesError = (error) => {
  return {
    type: FETCH_PRIVILEGES_API_FAILED,
    payload: error,
  };
};

export const assignRole = (id, data) => {
  return {
    type: ASSIGN_ROLE,
    payload: { id, data },
  };
};

export const assignRoleFailed = (error) => {
  return {
    type: ASSIGN_ROLE_FAILED,
    payload: error,
  };
};

export const assignRoleSuccessful = (payload) => {
  return {
    type: ASSIGN_ROLE_SUCCESSFUL,
    payload,
  };
};

export const unassignRole = (id, data) => {
  return {
    type: UNASSIGN_ROLE,
    payload: { id, data },
  };
};

export const unassignRoleFailed = (error) => {
  return {
    type: UNASSIGN_ROLE_FAILED,
    payload: error,
  };
};

export const unassignRoleSuccessful = (payload) => {
  return {
    type: UNASSIGN_ROLE_SUCCESSFUL,
    payload,
  };
};
