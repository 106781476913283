export const FETCH_REVIEWS = 'FETCH_REVIEWS';
export const FETCH_REVIEWS_SUCCESSFUL = 'FETCH_REVIEWS_SUCCESSFUL';
export const FETCH_REVIEWS_API_FAILED = 'FETCH_REVIEWS_API_FAILED';

export const FETCH_DRIVER_REVIEWS = 'FETCH_DRIVER_REVIEWS';
export const FETCH_DRIVER_REVIEWS_SUCCESSFUL =
  'FETCH_DRIVER_REVIEWS_SUCCESSFUL';
export const FETCH_DRIVER_REVIEWS_API_FAILED =
  'FETCH_DRIVER_REVIEWS_API_FAILED';

export const FETCH_EACH_REVIEW = 'FETCH_EACH_REVIEW';
export const FETCH_EACH_REVIEW_SUCCESSFUL = 'FETCH_EACH_REVIEW_SUCCESSFUL';
export const FETCH_EACH_REVIEW_API_FAILED = 'FETCH_EACH_REVIEW_API_FAILED';

// REVIEW Summary
export const FETCH_REVIEW_SUMMARY = 'FETCH_REVIEW_SUMMARY';
export const FETCH_REVIEW_SUMMARY_FAILED = 'FETCH_REVIEW_SUMMARY_FAILED';
export const FETCH_REVIEW_SUMMARY_SUCCESSFULLY =
  'FETCH_REVIEW_SUMMARY_SUCCESSFULLY';
