import React, { useEffect } from 'react'
import { Row, Col, Container, Alert } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import LogsTable from './LogsTable'
import Loader from '../../../../components/Common/Loading/index.js'
import GreySummaryCard from '../../../../components/Common/Summary/index'
import {
  fetchDriverHoursLogs,
  fetchDriverHoursLogsFailed,
  fetchHoursCSV,
  fetchHoursCSVError,
} from '../../../../store/actions'
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import CheckAction from '../../../../components/Common/Summary/actionCheck'

const Logs = ({
  fetchHoursCSV,
  fetchHoursCSVError,
  hour_csv,
  hour_csv_loading,
  fetchDriverHoursLogs,
  fetchDriverHoursLogsFailed,
  driverHoursLogs,
  driverHoursLogsError,
  loading,
  history,
}) => {
  const breadcrumbItems = [
    { title: 'Drivers', link: '' },
    { title: 'Drivers Hours', link: '#' },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Driver Hours" breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field="Users" subModule="Driver Logs">
            {driverHoursLogsError && (
              <Alert color="danger" className="text-center">
                {driverHoursLogsError}
              </Alert>
            )}
            {loading && !driverHoursLogs.ok ? (
              <Loader loading={loading} />
            ) : (
              <Row>
                <Col>
                  <LogsTable
                    logs={driverHoursLogs}
                    loading={loading}
                    fetchDriverHoursLogs={fetchDriverHoursLogs}
                    message={driverHoursLogsError}
                    history={history}
                    fetchDataForCSV={fetchHoursCSV}
                    fetchForCSVError={fetchHoursCSVError}
                    csv={hour_csv}
                    csv_loading={hour_csv_loading}
                    CheckAction={CheckAction}
                  />
                </Col>
              </Row>
            )}
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const {
    hour_csv,
    hour_csv_loading,
    loading,
    driverHoursLogs,
    driverHoursLogsError,
  } = state.Users
  return {
    hour_csv,
    hour_csv_loading,
    loading,
    driverHoursLogs,
    driverHoursLogsError,
  }
}

export default connect(mapStateToProps, {
  fetchDriverHoursLogs,
  fetchHoursCSV,
  fetchDriverHoursLogsFailed,
  fetchHoursCSVError,
})(withRouter(Logs))
