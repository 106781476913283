import HttpService from './HttpService';

export const GetDocTypeService = (query) => {
  const http = new HttpService();
  let url = 'documentType';
  if (query && query.limit) {
    url = `${url}?take=${query.limit}&skip=${query.currentPage}`;
  }
  if (query && query.searchValue) {
    url = `${url}?search=${query.searchValue}`;
  }
  return http.getData(url);
};

export const GetEachDocTypeService = (id) => {
  const http = new HttpService();
  const url = `documentType/${id}`;
  return http.getData(url);
};

export const CreateDocTypeService = (payload) => {
  const http = new HttpService();
  const url = 'documentType';
  return http.postData(payload, url);
};

export const UpdateDocTypeService = (payload) => {
  const http = new HttpService();
  const url = `documentType/${payload.id}`;
  return http.putData(payload, url);
};

export const initDocTypeService = (payload) => {
  const http = new HttpService();
  const url = `documentType/${payload.id}/init`;
  return http.putData(payload, url);
};

export const DeleteDocTypeService = (id) => {
  const http = new HttpService();
  const url = `documentType/${id}`;
  return http.deleteData(url);
};
