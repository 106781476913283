import React, { useState, useEffect } from 'react';
import { Card, CardBody, Input, UncontrolledTooltip, Alert } from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';

import {
  rideStatus,
  rideStatusFailed,
  deleteRide,
} from '../../../store/actions';

import Loader from '../../../components/Common/Loading/index.js';

import Moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';
let skip = 0;
let dataLimit = 10;

const LatestRides = ({
  rides,
  fetchRidesHandler,
  history,
  start,
  end,
  isAuthorize,
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [search, setSearch] = useState('');
  const [take, setLimit] = useState(10);
  const [status, setStatus] = useState('');

  const GetRideByStatus = (status) => {
    setStatus(status);
    let queryParams = {
      search,
      take,
      skip,
      status,
      // start,
      // end,
    };
    fetchRidesHandler(queryParams);
  };

  useEffect(() => {
    if (rides) {
      setData(tableDataHandler());
      setIsLoading(false);
      setPageCount(Math.ceil(rides?.total / take));
    }
  }, [rides]);

  useEffect(() => {
    let queryParams = {
      search,
      take,
      skip,
      status,
      // start,
      // end,
    };
    fetchRidesHandler(queryParams);
  }, [end, fetchRidesHandler, start, take]);

  useEffect(() => {
    rideStatusFailed('');
  }, []);

  const tableDataHandler = () => {
    const rowData = rides?.result?.map((data) => {
      return {
        keyId: data.id,
        id: (
          <Link
            to={`/ride/${data.id}`}
            className='mr-3 text-dark font-weight-bold'
          >
            {data.id.substring(0, 7)}
          </Link>
        ),
        date: (
          <div>
            {' '}
            <div>{Moment(data.createdAt).format('D/M/YYYY')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
        driverName:
          data.driver !== null ? (
            <div>
              <div className='text-capitalize'>
                {data.driver.firstName} {data.driver.lastName}
              </div>
              <span>
                <img
                  src={data.driver?.picture ? data.driver?.picture : avatarImg}
                  alt=''
                  height='20'
                  className='rounded-circle avatar-xs mr-1'
                />
                <span className='text-muted ml-2 badge badge-soft-secondary'>
                  <i className=' fas fa-star text-warning mr-1' />
                  {data?.driver?.rating.toFixed(2)}
                </span>
              </span>
            </div>
          ) : (
            'Not Assigned'
          ),
        userName: (
          <div>
            <div className='text-capitalize'>
              {data.rider.firstName} {data.rider.lastName}
            </div>
            <span>
              <img
                src={data.rider?.picture ? data.rider?.picture : avatarImg}
                alt=''
                height='20'
                className='rounded-circle avatar-xs mr-1'
              />
              <span className='text-muted ml-2 badge badge-soft-secondary'>
                <i className=' fas fa-star text-warning mr-1' />
                {data?.rider?.rating.toFixed(2)}
              </span>
            </span>
          </div>
        ),
        status: (
          <div className='d-flex flex-column align-items-start'>
            <h1
              className={`badge badge-soft-${
                data.status === 'PROCESSING'
                  ? 'warning'
                  : data.status === 'ACCEPTED'
                  ? 'success'
                  : data.status === 'CANCELLED'
                  ? 'danger'
                  : data.status === 'COMPLETED'
                  ? 'primary'
                  : 'dark'
              } font-size-12`}
            >
              {data.status}
            </h1>
            {data.status === 'CANCELLED' && (
              <p
                className={`badge badge-soft-secondary  font-size-10 m-0`}
                style={{
                  paddingTop: '4px',
                  background: `${
                    data?.cancellation?.reasonKey === 'SYSTEM_REQUEST'
                      ? '#6c757d2e'
                      : data?.cancellation?.requester === 'RIDER'
                      ? '#f18357'
                      : data?.cancellation?.requester === 'ADMIN'
                      ? '#007bff'
                      : '#00a859'
                  }`,
                  color: `${
                    data?.cancellation?.reasonKey !== 'SYSTEM_REQUEST'
                      ? 'white'
                      : 'black'
                  }`,
                }}
              >
                {data?.cancellation?.reasonKey === 'SYSTEM_REQUEST'
                  ? 'SYSTEM'
                  : data?.cancellation?.requester}
              </p>
            )}
          </div>
        ),
        paymentMethod:
          data.paymentMethod === 'ACCOUNT' ? (
            <span>
              <span className='d-block'>
                <i className='fas fa-wallet' /> NPAY{' '}
                {data.redemption && '/ PROMO'}
              </span>
              <div>
                <span className='badge badge-soft-success'>
                  ₦{data.totalAmount?.toLocaleString('en-US')}
                </span>
                {data.redemption && (
                  <>
                    -
                    <span className='badge badge-soft-danger'>
                      ₦{data.redemption?.value?.toLocaleString('en-US')}
                    </span>
                  </>
                )}
              </div>
            </span>
          ) : data.paymentMethod === 'CASH' ? (
            <span>
              <span className='d-block'>
                <i className='fas fa-money-bill-wave' /> {data.paymentMethod}
                {data.redemption && '/ PROMO'}
              </span>
              <div>
                <span className='badge badge-soft-success'>
                  ₦{data.totalAmount?.toLocaleString('en-US')}
                </span>
                {data.redemption && (
                  <>
                    -
                    <span className='badge badge-soft-danger'>
                      ₦{data.redemption?.value?.toLocaleString('en-US')}
                    </span>
                  </>
                )}
              </div>
            </span>
          ) : (
            <span>
              <span className='d-block'>
                <i className='fas fa-wallet' />
                {data.paymentMethod} {data.redemption && '/ PROMO'}
              </span>
              <div>
                <span className='badge badge-soft-success'>
                  ₦{data.totalAmount?.toLocaleString('en-US')}
                </span>
                {data.redemption && (
                  <>
                    -
                    <span className='badge badge-soft-danger'>
                      ₦{data.redemption?.value?.toLocaleString('en-US')}
                    </span>
                  </>
                )}
              </div>
            </span>
          ),
        packageType: <span> {data.ridePackage.name} </span>,
        action: (
          <>
            <Link to={`/ride/${data.id}`} className='mr-3 text-dark' id='edit'>
              <i className='mdi mdi-eye p-2 font-size-16 mb-2'></i>
            </Link>
            <UncontrolledTooltip
              placement='top'
              className='p-0 bg-light'
              target='edit'
            >
              Preview
            </UncontrolledTooltip>
          </>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'Date',
          dataField: 'date',
        },
        {
          text: 'Ride Id',
          dataField: 'id',
        },
        {
          text: 'Rider',
          dataField: 'userName',
        },
        {
          text: 'Captains',
          dataField: 'driverName',
        },
        {
          text: 'Payment',
          dataField: 'paymentMethod',
        },
        {
          text: 'Package Type',
          dataField: 'packageType',
        },
        {
          text: 'Trip Status',
          dataField: 'status',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Card>
        {isAuthorize ? (
          <Alert color='danger' className='text-center'>
            {isAuthorize}
          </Alert>
        ) : (
          <CardBody>
            <div>
              <div className='float-right'>
                <select
                  className='custom-select custom-select-sm'
                  onChange={(e) => {
                    GetRideByStatus(e.target.value);
                  }}
                >
                  <option value=''>All Trips</option>
                  <option value='PROCESSING'>Processing</option>
                  <option value='ACCEPTED'>Accepted</option>
                  <option value='APPROACHING'>Approaching</option>
                  <option value='ARRIVED'>Arrived</option>
                  <option value='INROUTE'>Inroute</option>
                  <option value='CANCELLED'>Cancelled</option>
                  <option value='COMPLETED'>Completed</option>
                </select>
              </div>
              <h4> Latest Rides </h4>
            </div>
            {!isLoading && data !== null ? (
              <>
                <BootstrapTable
                  keyField='keyId'
                  data={data.rows}
                  columns={data.columns}
                  striped
                  hover
                  wrapperClasses='table-responsive'
                  noDataIndication={'No Data to Display'}
                />
              </>
            ) : (
              <Loader loading={isLoading} />
            )}
          </CardBody>
        )}
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { rides, loading, ridesError, message, isAuthorize } = state.Rides;
  return { rides, loading, ridesError, message, isAuthorize };
};

export default connect(mapStateToProps, {
  rideStatus,
  rideStatusFailed,
  deleteRide,
})(LatestRides);
