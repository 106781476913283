import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
  FETCH_CASHOUT_ANALYTIC,
  FETCH_EACH_CASHOUT_ANALYTIC,
} from './actionTypes';
import {
  FetchCashoutAnalyticsSuccessful,
  FetchCashoutAnalyticsError,
  FetchEachCashoutAnalyticsSuccessful,
  FetchEachCashoutAnalyticsError,
} from './actions';

import {
  GetCashoutAnalyticService,
  GetEachCashoutAnalyticService,
} from '../../../services/analytic.js';

function* fetchAppConfigHandler({ payload }) {
  try {
    const response = yield call(GetCashoutAnalyticService, payload);
    yield put(FetchCashoutAnalyticsSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        FetchCashoutAnalyticsError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        FetchCashoutAnalyticsError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        FetchCashoutAnalyticsError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEachAppConfigHandler({ payload }) {
  try {
    const response = yield call(GetEachCashoutAnalyticService, payload);
    yield put(FetchEachCashoutAnalyticsSuccessful(response.data.result));
  } catch (error) {
    yield put(FetchEachCashoutAnalyticsError(error?.response?.data?.message));
  }
}

export function* watchFetchCashoutAnalytic() {
  yield takeEvery(FETCH_CASHOUT_ANALYTIC, fetchAppConfigHandler);
  yield takeEvery(FETCH_CASHOUT_ANALYTIC, fetchAppConfigHandler);
}

export function* watchFetchEachCashoutAnalytic() {
  yield takeEvery(FETCH_EACH_CASHOUT_ANALYTIC, fetchEachAppConfigHandler);
}

function* CashoutAnalyticSaga() {
  yield all([
    fork(watchFetchCashoutAnalytic),
    fork(watchFetchEachCashoutAnalytic),
  ]);
}

export default CashoutAnalyticSaga;
