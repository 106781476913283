/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Row, Col } from 'reactstrap';
import Loader from '../../../../components/Common/Loading/index.js';
import { fetchEachComplaint } from '../../../../store/actions';
import Moment from 'moment';

const PreviewComplaint = ({
  complaintPreview,
  previewId,
  loading,
  complaintError,
  fetchEachComplaint,
}) => {
  useEffect(() => {
    fetchEachComplaint(previewId);
  }, [fetchEachComplaint, previewId]);

  return (
    <React.Fragment>
      <div className='mt-1'>
        {complaintError && !loading && (
          <Alert color='danger' className='text-center'>
            {complaintError}
          </Alert>
        )}
        {!complaintPreview && loading ? (
          <Loader />
        ) : (
          <Row>
            <Col sm={12} className='text-center'>
              <div className='d-flex justify-content-between pb-2'>
                <span>
                  <span className='badge badge-soft-success'>
                    {complaintPreview?.type}
                  </span>
                </span>
                <span>
                  <span
                    className={`badge badge-soft-${
                      complaintPreview?.status === 'PENDING'
                        ? 'secondary'
                        : complaintPreview?.status === 'REVIEW'
                        ? 'warning'
                        : complaintPreview?.status === 'DISPUTED'
                        ? 'success'
                        : complaintPreview?.status === 'COMPLETED'
                        ? 'primary'
                        : 'dark'
                    }`}
                  >
                    {complaintPreview?.status}
                  </span>
                </span>
              </div>
              <div>
                <h4 className='card-title'> {complaintPreview?.title} </h4>
                {complaintPreview?.note && (
                  <span className='text-muted text-center'>
                    {complaintPreview?.note}
                  </span>
                )}

                <div className='text-center mt-2'>
                  <Link
                    to={`/ride/${complaintPreview?.ride?.id}`}
                    className='btn btn-primary btn-sm'
                    id='ride'
                  >
                    <i className='fas fa-car-alt'></i> Ride History
                  </Link>
                </div>
              </div>
            </Col>
            <Col sm={12}>
              <div>
                <hr />
                <h5 className='font-size-14 text-center  bg-light py-1 rounded'>
                  Reporter
                </h5>
                <hr />
                <div className='d-flex mb-2'>
                  <h5 className='col-6 font-size-14'> Name :</h5>
                  <p className='mb-1 text-capitalize'>
                    {complaintPreview?.reporter?.firstName}{' '}
                    {complaintPreview?.reporter?.lastName}
                  </p>
                </div>

                <div className='d-flex mb-2'>
                  <h5 className='col-6 font-size-14'> Phone No:</h5>
                  <p className='mb-1'>{complaintPreview?.reporter?.phone}</p>
                </div>

                <div className='d-flex mb-2'>
                  <h5 className='col-6 font-size-14'>User Type:</h5>
                  <p className='mb-1'>{complaintPreview?.reporter?.userType}</p>
                </div>
                {complaintPreview?.assignee && (
                  <>
                    <hr />
                    <h5 className='font-size-14 text-center  bg-light py-1 rounded'>
                      Assignee
                    </h5>
                    <hr />
                    <div className='d-flex mb-2'>
                      <h5 className='col-6 font-size-14'> Name :</h5>
                      <p className='mb-1 text-capitalize'>
                        {complaintPreview.assignee.firstName}{' '}
                        {complaintPreview.assignee.firstName}
                      </p>
                    </div>

                    <div className='d-flex mb-2'>
                      <h5 className='col-6 font-size-14'> User Type:</h5>
                      <p className='mb-1 rounded'>
                        {complaintPreview.assignee.userType}
                      </p>
                    </div>
                  </>
                )}
                <div className='badge badge-soft-secondary text-center d-block p-2'>
                  Created @ {Moment(complaintPreview?.createAt).format('lll')}
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { complaintPreview, loading, complaintError } = state.Complaint;
  return { complaintPreview, loading, complaintError };
};

export default connect(mapStateToProps, { fetchEachComplaint })(
  PreviewComplaint
);
