import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_KEY,
  authDomain: 'nyrde-342022.firebaseapp.com',
  projectId: 'nyrde-342022',
  storageBucket: 'nyrde-342022.appspot.com',
  messagingSenderId: '281635926570',
  appId: '1:281635926570:web:c18291e5f64af157cf0953',
  measurementId: 'G-61S1W8VF0M',
};
let messaging 
firebase.initializeApp(firebaseConfig);
if (firebase.messaging.isSupported()){
	 messaging = firebase.messaging();
}
 
const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound, setToken) => {
  let token = '';

  try {
    token = await messaging.getToken({ vapidKey: publicKey });

    if (token) {
      // console.log(token);
      setTokenFound(true);
      setToken(token);
    } else {
      console.log(
        'No registration token available. Request permission to generate one.'
      );
      setTokenFound(false);
    }
  } catch (error) {
    if (Notification.permission !== 'granted') {
      setTokenFound('Notification Not Granted');
      return;
    }
    setTokenFound('Error with Notification');

    console.log('An error occurred while retrieving token. ', error);
    return;
  }

  return token;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

// export const onBackgroundMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onBackgroundMessage((payload) => {
//       resolve(payload);
//     });
//   });
export default firebase;
