/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Row, Col, Table } from 'reactstrap';
import Loader from '../../../components/Common/Loading/index.js';
import { fetchEachActivities } from '../../../store/actions';
import Moment from 'moment';

const Activities = ({
  activitiesPreview,
  previewId,
  loading,
  activitiesError,
  fetchEachActivities,
}) => {
  useEffect(() => {
    fetchEachActivities(previewId);
  }, [fetchEachActivities, previewId]);

  return (
    <React.Fragment>
      <div className='mt-1'>
        {activitiesError && !loading && (
          <Alert color='danger' className='text-center'>
            {activitiesError}
          </Alert>
        )}
        {!activitiesPreview && loading ? (
          <Loader />
        ) : (
          <Row>
            <Col sm={12} className='text-center'>
              <div className='d-flex justify-content-between pb-2'>
                <h6 className={`badge badge-soft-dark`}>
                  {activitiesPreview?.result?.method}{' '}
                </h6>
                <h6
                  className={`badge badge-soft-${
                    activitiesPreview?.result?.responseStatusCode === '200' ||
                    activitiesPreview?.result?.responseStatusCode === '201'
                      ? 'success'
                      : 'danger'
                  } ml-2`}
                >
                  {activitiesPreview?.result?.responseStatusCode === '200' ||
                  activitiesPreview?.result?.responseStatusCode === '201'
                    ? 'success'
                    : 'failed'}{' '}
                </h6>
              </div>
              {activitiesPreview?.result?.response &&
                !activitiesPreview?.result?.response.success && (
                  <div>
                    <p className='mb-1 font-size-12 text-muted'>
                      {activitiesPreview?.result?.response?.error?.message
                        ? activitiesPreview?.result?.response?.error?.message
                        : activitiesPreview?.result?.response?.message}
                    </p>
                  </div>
                )}
            </Col>
            <Col sm={12}>
              <div>
                <Table hover>
                  <tbody>
                    <tr>
                      <td
                        className='font-weight-bold pl-4'
                        style={{ width: '20%' }}
                      >
                        Module
                      </td>
                      <td className='text-uppercase pl-5'>
                        {activitiesPreview?.result?.module}
                      </td>
                    </tr>
                    {activitiesPreview?.result?.nrydeSource && (
                      <tr>
                        <td className='font-weight-bold pl-4'>Source</td>
                        <td className='pl-5'>
                          {activitiesPreview?.result?.nrydeSource}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td className='font-weight-bold pl-4'> Date</td>
                      <td className='pl-5'>
                        <p className='text-muted fonnt-size-10 mb-1'>
                          {Moment(activitiesPreview?.result?.createdAt).format(
                            'MMMM Do YYYY, h:mm:ss a'
                          )}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className='font-weight-bold pl-4'>API Used</td>
                      <td
                        className='pl-5'
                        style={{
                          maxWidth: '300px',
                        }}
                      >
                        <div
                          className='text-truncate'
                          style={{ whiteSpace: 'normal' }}
                        >
                          {activitiesPreview?.result?.host}
                          {activitiesPreview?.url}
                        </div>
                      </td>
                    </tr>
                    {activitiesPreview?.result?.nrydeSource &&
                    activitiesPreview?.result?.userAgent ? (
                      <>
                        {activitiesPreview?.result?.nrydeSource ===
                        'admin-dashboard' ? (
                          <>
                            <tr>
                              <td className='font-weight-bold pl-4'>Browser</td>
                              <td className='pl-5'>
                                {activitiesPreview?.result?.userAgent?.includes(
                                  'Chrome'
                                ) && 'Google Chrome'}
                                {activitiesPreview?.result?.userAgent?.includes(
                                  'Firefox'
                                ) && 'Firefox'}
                                {activitiesPreview?.result?.userAgent?.includes(
                                  'Safari'
                                ) &&
                                !activitiesPreview?.result?.userAgent?.includes(
                                  'Chrome'
                                )
                                  ? 'Safari'
                                  : null}

                                {activitiesPreview?.result?.userAgent?.includes(
                                  'Edg'
                                ) && 'Microsoft Edge'}
                              </td>
                            </tr>

                            <tr>
                              <td className='font-weight-bold pl-4'>
                                Operating System
                              </td>
                              <td className='pl-5'>
                                {activitiesPreview?.result?.userAgent?.includes(
                                  'Windows'
                                ) && 'Windows'}
                                {activitiesPreview?.result?.userAgent?.includes(
                                  'Linux'
                                ) && 'Linux'}
                                {activitiesPreview?.result?.userAgent?.includes(
                                  'Macintosh'
                                ) && 'MacBook'}
                              </td>
                            </tr>
                          </>
                        ) : (
                          <tr>
                            <td className='font-weight-bold pl-4'>Device</td>
                            <td className='pl-5'>
                              {activitiesPreview?.result?.nrydeSource?.includes(
                                'android'
                              )
                                ? 'Android'
                                : 'IOS'}
                            </td>
                          </tr>
                        )}
                      </>
                    ) : null}
                  </tbody>
                </Table>
              </div>
              <div className='text-center'>
                {activitiesPreview?.result?.query && (
                  <>
                    <h4 className='card-title'> Queries </h4>
                    <Table hover>
                      <tbody>
                        {Object.keys(activitiesPreview?.result?.query).map(
                          function (value, idx) {
                            return (
                              <tr key={idx}>
                                <td>{value}</td>
                                <td>
                                  {activitiesPreview?.result?.query[value]}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </>
                )}
                {activitiesPreview?.result?.params && (
                  <>
                    <h4 className='card-title'> Parameters </h4>
                    <Table hover>
                      <tbody>
                        {Object.keys(activitiesPreview?.result?.params).map(
                          function (value, idx) {
                            return (
                              <tr key={idx}>
                                <td>{value}</td>
                                <td>
                                  {activitiesPreview?.result?.params[value]}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </>
                )}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { activitiesPreview, loading, activitiesError } = state.Activities;
  return { activitiesPreview, loading, activitiesError };
};

export default connect(mapStateToProps, { fetchEachActivities })(Activities);
