/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Moment from "moment";
import { connect } from "react-redux";
import {
  Alert,
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  Container,
  Card,
  CardBody,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Loader from "../../../components/Common/Loading/index.js";
import { useLocation, withRouter, Link } from "react-router-dom";
import { fetchEachTour } from "../../../store/actions.js";
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsRenderer,
  InfoWindow,
} from "@react-google-maps/api";
import busStopIcon from "../../../assets/images/markers_icon/busStop.png";
import busStopPin from "../../../assets/images/markers_icon/busStopPin.png";
import nextStopPin from "../../../assets/images/markers_icon/next.png";
import previousStopPin from "../../../assets/images/markers_icon/previous.png";
import carIcon from "../../../assets/images/car.png";

const avatarImg =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

const PreviewTour = ({ tour, loading, tourError, fetchEachTour, history }) => {
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [selectedStop, setSelectedStop] = useState(null);
  const [startBusStop, setStartBusStop] = useState([]);
  const [endBusStop, setEndBusStop] = useState([]);
  const [googleIsReady, setIsReady] = useState(false);
  const breadcrumbItems = [
    { title: "Tour", link: "#" },
    { title: "Preview", link: "#" },
  ];
  const search = useLocation().search;
  const previewId = new URLSearchParams(search).get("id");

  useEffect(() => {
    if (previewId) fetchEachTour(previewId);
  }, [fetchEachTour, previewId]);

  const direction = async (sortedStops) => {
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: sortedStops[0].busStop?.address,
      destination: sortedStops[sortedStops.length - 1].busStop?.address,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
  };

  useEffect(() => {
    if (window.google && window.google.maps) {
      setIsReady(true);
      if (tour) {
        let sortedStops = tour?.route?.stops.sort(
          (a, b) => a.position - b.position
        );
        direction(sortedStops);
        setStartBusStop([
          {
            value: sortedStops[0].busStop["id"],
            label: sortedStops[0].busStop["name"],
            address: sortedStops[0].busStop["address"],
            cord: {
              lat: sortedStops[0].busStop["lat"],
              lng: sortedStops[0].busStop["lng"],
            },
          },
        ]);
        setEndBusStop([
          {
            value: sortedStops[sortedStops.length - 1].busStop?.id,
            label: sortedStops[sortedStops.length - 1].busStop?.name,
            address: sortedStops[sortedStops.length - 1].busStop?.address,
            cord: {
              lat: sortedStops[sortedStops.length - 1].busStop?.lat,
              lng: sortedStops[sortedStops.length - 1].busStop?.lng,
            },
          },
        ]);
      }
    } else {
      setIsReady(false);
    }
  }, [tour, window.google]);

  console.log(tour?.route?.stops?.busStop);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Tour-Details" breadcrumbItems={breadcrumbItems} />
          <div className="mb-1 mb-2 text-right">
            <button
              onClick={() => history.goBack()}
              className="btn btn-sm btn-primary"
            >
              <i className="fas fa-arrow-left mr-3" />
              Back
            </button>
          </div>
          <Card className="">
            <CardBody>
              {tourError && !loading ? (
                <Alert color="danger" className="text-center">
                  {tourError}
                </Alert>
              ) : (
                <>
                  {tour === null ? (
                    <Loader />
                  ) : (
                    <div>
                      <Row>
                        <Col md={6}>
                          <div>
                            <Card className="border">
                              <CardBody>
                                <Link to={`/user/${tour?.driver.id}`}>
                                  <div className="d-flex align-items-center my-2 text-dark">
                                    <img
                                      src={
                                        tour?.driver?.picture
                                          ? tour?.driver?.picture
                                          : avatarImg
                                      }
                                      alt=""
                                      height="20"
                                      className="rounded-circle avatar-md mr-2"
                                    />

                                    <div className="col-10">
                                      <div className="text-capitalize">
                                        {tour?.driver.firstName}{" "}
                                        {tour?.driver.lastName}
                                      </div>
                                      <span className="d-flex justify-content-between">
                                        <span>
                                          <span className="text-muted badge badge-soft-secondary">
                                            <i className=" fas fa-star text-warning mr-1" />
                                            {tour?.driver?.rating.toFixed(2)}
                                          </span>
                                        </span>
                                      </span>

                                      <div className="d-flex align-items-end justify-content-between pr-2 mt-2 font-size-12">
                                        <div className="badge badge-dark text-white font-size-12 col-sm-4">
                                          {tour?.availableSeats} Seats
                                        </div>
                                        <h1
                                          className={`badge badge-soft-${
                                            tour?.status.toLowerCase() ===
                                            "progressing"
                                              ? "success"
                                              : tour?.status.toLowerCase() ===
                                                "paused"
                                              ? "primary"
                                              : tour?.status.toLowerCase() ===
                                                "cancelled"
                                              ? "danger"
                                              : tour?.status.toLowerCase() ===
                                                "planned"
                                              ? "warning"
                                              : tour?.status.toLowerCase() ===
                                                "ended"
                                              ? "dark"
                                              : ""
                                          } font-size-12 m-0`}
                                        >
                                          {tour?.status ? tour?.status : "---"}
                                        </h1>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </CardBody>
                            </Card>
                          </div>
                          <hr />

                          <Col sm={12}>
                            <div>
                              <Table hover>
                                <tbody>
                                  <tr>
                                    <td className="font-weight-bold">
                                      Package Name{" "}
                                    </td>
                                    <td className="text-uppercase font-size-16 font-weight-bold text-success">
                                      {tour?.tourPackage?.name}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="font-weight-bold">
                                      Current Bus Stop
                                    </td>
                                    <td className="text-uppercase">
                                      <div
                                        className="text-truncate d-flex align-items-center"
                                        style={{ whiteSpace: "normal" }}
                                      >
                                        {tour?.currentBusStop?.name}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-bold">
                                      Previous Bus Stop
                                    </td>
                                    <td className="text-uppercase">
                                      <div
                                        className="text-truncate d-flex align-items-center"
                                        style={{ whiteSpace: "normal" }}
                                      >
                                        {tour?.previousBusStop?.name}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-bold">
                                      Next Bus Stop
                                    </td>
                                    <td className="text-uppercase">
                                      <div
                                        className="text-truncate d-flex align-items-center"
                                        style={{ whiteSpace: "normal" }}
                                      >
                                        {tour?.nextBusStop?.name}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-bold">Start</td>
                                    <td className="text-uppercase">
                                      <div className="d-flex align-items-end">
                                        <div>
                                          {Moment(tour?.startTime)?.format("l")}
                                        </div>
                                        <div className="badge badge-soft-secondary font-size-10 ml-1">
                                          {" "}
                                          {Moment(tour?.startTime)?.format(
                                            "hh:mm A"
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  {tour?.endTime && (
                                    <tr>
                                      <td className="font-weight-bold">End</td>
                                      <td className="text-uppercase">
                                        <div className="d-flex align-items-end">
                                          <div>
                                            {Moment(tour?.endTime)?.format("l")}
                                          </div>
                                          <div className="badge badge-soft-secondary font-size-10 ml-1">
                                            {" "}
                                            {Moment(tour?.endTime)?.format(
                                              "hh:mm A"
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td className="font-weight-bold">
                                      Created At
                                    </td>
                                    <td className="text-uppercase">
                                      {Moment(tour?.createdAt).format("l")}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Col>
                        <Col md={6} className="mb-5 mt-2">
                          <LoadScript
                            googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY}
                          >
                            <GoogleMap
                              mapContainerStyle={{
                                height: "100%",
                              }}
                              // center={{
                              //   lat: tour?.route?.stops[0].busStop?.lat,
                              //   lng: tour?.route?.stops[0].busStop?.lng,
                              // }}
                              zoom={15}
                            >
                              {tour?.route?.stops?.length > 0 && googleIsReady
                                ? tour?.route?.stops?.map((stop) => (
                                    <Marker
                                      key={stop?.busStop.id}
                                      position={{
                                        lat: stop?.busStop?.lat,
                                        lng: stop?.busStop?.lng,
                                      }}
                                      icon={{
                                        url: busStopPin,
                                        scaledSize: new window.google.maps.Size(
                                          25,
                                          25
                                        ),
                                      }}
                                      title={stop?.busStop?.name}
                                      onClick={() => setSelectedStop(stop)}
                                    >
                                      {selectedStop === stop && (
                                        <InfoWindow
                                          onCloseClick={() =>
                                            setSelectedStop(null)
                                          }
                                          position={{
                                            lat: stop?.busStop?.lat,
                                            lng: stop?.busStop?.lng,
                                          }}
                                        >
                                          <div>
                                            <h6>{stop?.busStop?.name}</h6>
                                          </div>
                                        </InfoWindow>
                                      )}
                                    </Marker>
                                  ))
                                : null}
                              {tour?.previousBusStop && googleIsReady ? (
                                <Marker
                                  key={tour?.previousBusStop.id}
                                  position={{
                                    lat: tour?.previousBusStop?.lat,
                                    lng: tour?.previousBusStop?.lng,
                                  }}
                                  icon={{
                                    url: previousStopPin,
                                    scaledSize: new window.google.maps.Size(
                                      25,
                                      25
                                    ),
                                  }}
                                  title={tour?.previousBusStop?.name}
                                  onClick={() =>
                                    setSelectedStop(tour?.previousBusStop)
                                  }
                                >
                                  {selectedStop === tour?.previousBusStop && (
                                    <InfoWindow
                                      onCloseClick={() => setSelectedStop(null)}
                                      position={{
                                        lat: tour?.previousBusStop?.lat,
                                        lng: tour?.previousBusStop?.lng,
                                      }}
                                    >
                                      <div className="d-flex flex-column align-items-center justify-content-center">
                                        <h6>{tour?.previousBusStop?.name}</h6>
                                        <h6 className="badge badge-pill badge-success">
                                          Previous Bus Stop
                                        </h6>
                                      </div>
                                    </InfoWindow>
                                  )}
                                </Marker>
                              ) : null}
                              {tour?.currentBusStop && googleIsReady ? (
                                <Marker
                                  key={tour?.currentBusStop.id}
                                  position={{
                                    lat: tour?.currentBusStop?.lat,
                                    lng: tour?.currentBusStop?.lng,
                                  }}
                                  icon={{
                                    url: busStopIcon,
                                    scaledSize: new window.google.maps.Size(
                                      25,
                                      25
                                    ),
                                  }}
                                  title={tour?.currentBusStop?.name}
                                  onClick={() =>
                                    setSelectedStop(tour?.currentBusStop)
                                  }
                                >
                                  {selectedStop === tour?.currentBusStop && (
                                    <InfoWindow
                                      onCloseClick={() => setSelectedStop(null)}
                                      position={{
                                        lat: tour?.currentBusStop?.lat,
                                        lng: tour?.currentBusStop?.lng,
                                      }}
                                    >
                                      <div className="d-flex flex-column align-items-center justify-content-center">
                                        <h6>{tour?.currentBusStop?.name}</h6>
                                        <h6 className="badge badge-pill badge-success">
                                          Current Bus Stop
                                        </h6>
                                      </div>
                                    </InfoWindow>
                                  )}
                                </Marker>
                              ) : null}
                              {tour?.nextBusStop && googleIsReady ? (
                                <Marker
                                  key={tour?.nextBusStop.id}
                                  position={{
                                    lat: tour?.nextBusStop?.lat,
                                    lng: tour?.nextBusStop?.lng,
                                  }}
                                  icon={{
                                    url: nextStopPin,
                                    scaledSize: new window.google.maps.Size(
                                      25,
                                      25
                                    ),
                                  }}
                                  title={tour?.nextBusStop?.name}
                                  onClick={() =>
                                    setSelectedStop(tour?.nextBusStop)
                                  }
                                >
                                  {selectedStop === tour?.nextBusStop && (
                                    <InfoWindow
                                      onCloseClick={() => setSelectedStop(null)}
                                      position={{
                                        lat: tour?.nextBusStop?.lat,
                                        lng: tour?.nextBusStop?.lng,
                                      }}
                                    >
                                      <div className="d-flex flex-column align-items-center justify-content-center">
                                        <h6>{tour?.nextBusStop?.name}</h6>
                                        <h6 className="badge badge-pill badge-success">
                                          Next Bus Stop
                                        </h6>
                                      </div>
                                    </InfoWindow>
                                  )}
                                </Marker>
                              ) : null}
                              {tour && googleIsReady ? (
                                <Marker
                                  key={tour?.id}
                                  position={{
                                    lat: tour?.lat,
                                    lng: tour?.lng,
                                  }}
                                  icon={{
                                    url: carIcon,
                                    scaledSize: new window.google.maps.Size(
                                      25,
                                      25
                                    ),
                                  }}
                                  title={"Driver"}
                                  onClick={() => setSelectedStop("Driver")}
                                >
                                  {selectedStop === "Driver" && (
                                    <InfoWindow
                                      onCloseClick={() => setSelectedStop(null)}
                                      position={{
                                        lat: tour?.lat,
                                        lng: tour?.lng,
                                      }}
                                    >
                                      <div>
                                        <h6>Current Driver Location</h6>
                                      </div>
                                    </InfoWindow>
                                  )}
                                </Marker>
                              ) : null}
                              {directionsResponse && (
                                <DirectionsRenderer
                                  directions={directionsResponse}
                                  options={{
                                    suppressMarkers: true,
                                  }}
                                />
                              )}
                              {/* {directionsResponse && googleIsReady ? (
                                <>
                                  <Marker
                                    position={
                                      directionsResponse?.routes[0]?.legs[0]
                                        ?.start_location
                                    }
                                    icon={{
                                      url: busStopIcon,
                                      scaledSize: new window.google.maps.Size(
                                        35,
                                        35
                                      ),
                                    }}
                                    onClick={() =>
                                      setSelectedStop(startBusStop)
                                    }
                                  >
                                    {selectedStop === startBusStop && (
                                      <InfoWindow
                                        onCloseClick={() =>
                                          setSelectedStop(null)
                                        }
                                        position={{
                                          lat: startBusStop[0]?.cord?.lat,
                                          lng: startBusStop[0]?.cord?.lng,
                                        }}
                                      >
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                          <h6>{startBusStop[0]?.label}</h6>
                                          <h6 className="badge badge-pill badge-success">
                                            START
                                          </h6>
                                        </div>
                                      </InfoWindow>
                                    )}
                                  </Marker>
                                  <Marker
                                    position={
                                      directionsResponse?.routes[0]?.legs[0]
                                        ?.end_location
                                    }
                                    icon={{
                                      url: busStopIcon,
                                      scaledSize: new window.google.maps.Size(
                                        35,
                                        35
                                      ),
                                    }}
                                    onClick={() => setSelectedStop(endBusStop)}
                                  >
                                    {selectedStop === endBusStop && (
                                      <InfoWindow
                                        onCloseClick={() =>
                                          setSelectedStop(null)
                                        }
                                        position={{
                                          lat: endBusStop[0]?.cord?.lat,
                                          lng: endBusStop[0]?.cord?.lng,
                                        }}
                                      >
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                          <h6>{endBusStop[0]?.label}</h6>
                                          <h6 className="badge badge-pill badge-danger">
                                            END
                                          </h6>
                                        </div>
                                      </InfoWindow>
                                    )}
                                  </Marker>
                                </>
                              ) : null} */}
                            </GoogleMap>
                          </LoadScript>
                        </Col>
                      </Row>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { tour, loading, tourError } = state.Tour;
  return { tour, loading, tourError };
};

export default connect(mapStateToProps, {
  fetchEachTour,
})(PreviewTour);
