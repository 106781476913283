import React, { useEffect, useState } from 'react'
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from 'reactstrap'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import AutoComplete from '../../../../components/Common/Autocomplete'

import { AvForm, AvField } from 'availity-reactstrap-validation'

// actions
import { createCashoutFailed, createCashout } from '../../../../store/actions'

const Form = ({
  message,
  loading,
  history,
  cashoutError,
  createCashoutFailed,
  createCashout,
}) => {
  const [selectedUser, setSelectedUser] = useState(null)
  const [error, setError] = useState(null)

  const handleSubmit = (event, values) => {
    setError(null)
    if (!selectedUser) {
      setError('Please Select a User')
      return
    }
    const cashoutData = {
      ...values,
      user: selectedUser.value,
    }
    createCashout(cashoutData)
  }

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        createCashoutFailed('')
        history.goBack()
      }, 2000)
    }
  }, [message])

  useEffect(() => {
    createCashoutFailed('')
  }, [])

  // useEffect(() => {
  //   setMoreDetail('')
  //   if (defaultValue) {
  //     setMoreDetail(defaultValue.note)
  //     setSelectedUser(defaultValue.user.id)
  //     fetchUserDetails(defaultValue.user.id)
  //   }
  // }, [defaultValue, fetchUserDetails])

  return (
    <Row>
      <Col md="6">
        <Card>
          <CardBody>
            {message && (
              <Alert color="success" className="text-center">
                {message}
              </Alert>
            )}
            {cashoutError && (
              <Alert color="danger" className="text-center">
                {cashoutError}
              </Alert>
            )}
            {error && (
              <Alert color="danger" className="text-center">
                {error}
              </Alert>
            )}
            <div className="d-flex justify-content-end">
              <div>
                <Link to="#" onClick={() => history.goBack()}>
                  <i className="fas fa-arrow-left mr-3" />
                  Back
                </Link>
              </div>
            </div>
            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <FormGroup className="mb-4">
                    <AutoComplete
                      setUser={(e) => setSelectedUser(e)}
                      userType={'DRIVER'}
                      title="Account Owner"
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="type">Type</Label>
                    <AvField type="select" name="type" value="INSTANT">
                      <option value="INSTANT">INSTANT</option>
                      <option value="SCHEDULED">SCHEDULED</option>
                      <option value="BONUS">BONUS</option>
                    </AvField>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup className=" mb-4">
                    <Label htmlFor="amount">Amount</Label>
                    <AvField
                      name="amount"
                      type="number"
                      className="form-control bg-light"
                      id="amount"
                      placeholder="Enter amount ..."
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <button className="btn btn-success mr-2" type="submit">
                {loading ? 'Submitting ...' : 'Save'}
              </button>

              <Link to="#" onClick={() => history.push('/banks')}>
                <button className="btn btn-secondary">Cancel</button>
              </Link>
            </AvForm>
          </CardBody>
        </Card>{' '}
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  const { message, loading, cashoutError, bankList } = state.Cashout
  const { user, userError } = state.Users
  return { message, loading, cashoutError, user, userError, bankList }
}

export default connect(mapStateToProps, {
  createCashout,
  createCashoutFailed,
})(withRouter(Form))
