import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Col, Row, CardBody, Container, Media, Card } from 'reactstrap';
import Breadcrumbs from '../../Common/Breadcrumb';

//Import Components
import MiniWidgets from './summary';
import AccessTable from './table';

const Dashboard = () => {
  const breadcrumbItems = [
    { title: 'nRyde', link: '#' },
    { title: 'Dashboard', link: '#' },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
          <Row>
            {[1, 2, 3, 4].map((report) => (
              <Col key={report}>
                <Card>
                  <CardBody className="pb-0">
                    <Media>
                      <Media body className="overflow-hidden">
                        <div>
                          <Skeleton duration={10} />
                        </div>
                      </Media>
                    </Media>
                  </CardBody>
                  <CardBody className="border-top py-1">
                    <h2>
                      <Skeleton duration={10} height={50} />
                    </h2>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>{' '}
          <Row>
            {[1, 2, 3, 4].map((report) => (
              <Col key={report}>
                <Card>
                  <CardBody className="pb-0">
                    <Media>
                      <Media body className="overflow-hidden">
                        <div>
                          <Skeleton duration={10} />
                        </div>
                      </Media>
                    </Media>
                  </CardBody>
                  <CardBody className="border-top py-1">
                    <h2>
                      <Skeleton duration={10} height={200} />
                    </h2>
                  </CardBody>
                  <CardBody className=" py-1">
                    <h2>
                      <Skeleton duration={10} />
                    </h2>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            <Col>
              <AccessTable />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
