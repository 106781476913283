import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

// actions
import {
  createDocType,
  createDocTypeFailed,
  updateDocType,
  updateDocTypeFailed,
  initDocType,
  initDocTypeFailed,
} from "../../../../../store/actions";

const Form = ({
  createDocType,
  createDocTypeFailed,
  updateDocType,
  updateDocTypeFailed,
  initDocType,
  initDocTypeFailed,
  message,
  loading,
  history,
  docTypeError,
  defaultValue,
  docTypeId,
  initDoc,
  initDocError,
}) => {
  const [description, setDescription] = useState(" ");
  const [initilizeDoc, setInit] = useState(null);

  const handleSubmit = (event, values) => {
    const docTypeData = {
      ...values,
    };
    docTypeData.name = values.name;
    docTypeData.key = values.key;
    docTypeData.userType = values.userType;
    docTypeData.description = description;
    docTypeData.pages = Number(values.pages);
    docTypeData.isExpiry = values.isExpiry;
    docTypeData.isOptional = values.isOptional;
    if (defaultValue) {
      docTypeData.id = defaultValue.id;
      updateDocType(docTypeData);
      return;
    }
    createDocType(docTypeData);
  };

  useEffect(() => {
    if (message) {
      if (docTypeId && initilizeDoc) {
        initDocType({ id: docTypeId });
      }
      setTimeout(() => {
        createDocTypeFailed("");
        updateDocTypeFailed("");
        history.push("/document_type");
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    createDocTypeFailed("");
    updateDocTypeFailed("");
  }, []);
  useEffect(() => {
    if (defaultValue) {
      setDescription(defaultValue.description);
    }
  }, [defaultValue]);

  return (
    <Card>
      <CardBody>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        {docTypeError && (
          <Alert color="danger" className="text-center">
            {docTypeError}
          </Alert>
        )}
        <div className="float-right">
          <Link to="/document_type">
            <i className="fas fa-arrow-left mr-3" />
            Back
          </Link>
        </div>
        <div>
          <h5> {defaultValue ? "Update" : "Create"} Document Type </h5>
        </div>
        <div>
          <AvForm
            className="form-horizontal"
            onValidSubmit={handleSubmit}
          // model={defaultValue ? defaultValue : ''}
          >
            <Row>
              <Col md={6}>
                <FormGroup className="mb-4">
                  <Label htmlFor="name">Name</Label>
                  <AvField
                    name="name"
                    type="text"
                    className="form-control bg-light"
                    id="name"
                    value={defaultValue ? defaultValue.name : ""}
                    placeholder="Enter Document Name ..."
                    required
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup className="mb-4">
                  <Label htmlFor="key">Key</Label>

                  <AvField
                    name="key"
                    type="text"
                    className="form-control bg-light"
                    id="key"
                    validate={{
                      required: {
                        value: true,
                        errorMessage:
                          "Please enter the document key, as it is required",
                      },
                      minLength: {
                        value: 3,
                        errorMessage: "Key cannot be less than 3 characters",
                      },
                    }}
                    value={defaultValue ? defaultValue.key : ""}
                    placeholder="Enter Document Key ..."
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className=" mb-4">
                  <Label htmlFor="pages"> Pages</Label>
                  <AvField
                    name="pages"
                    type="number"
                    min="0"
                    className="form-control bg-light"
                    id="pages"
                    value={defaultValue ? defaultValue.pages : ""}
                    placeholder="Enter Document Pages ..."
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className=" mb-4">
                  <AvField
                    className="form-control bg-light"
                    type="select"
                    name="userType"
                    label="User Type"
                    value={defaultValue ? defaultValue.userType : "DRIVER"}
                  >
                    <option>DRIVER</option>
                    <option>RIDER</option>
                    <option>ADMIN</option>
                    <option>INSPECTOR</option>
                  </AvField>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="isExpiry">
                          Does this document expires ?
                        </Label>
                        <AvRadioGroup
                          inline
                          name="isExpiry"
                          value={defaultValue ? defaultValue.isExpiry : ""}
                        >
                          <AvRadio label="Yes" value={true} />
                          <AvRadio label="No" value={false} />
                        </AvRadioGroup>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="isOptional">
                          Is it a required document ?
                        </Label>
                        <AvRadioGroup
                          inline
                          name="isOptional"
                          value={defaultValue ? defaultValue.isOptional : ""}
                        >
                          <AvRadio label="Yes" value={false} />
                          <AvRadio label="No" value={true} />
                        </AvRadioGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                {!defaultValue && (
                  <Col md={12}>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="docInit">
                            Include this document for current users
                          </Label>
                          <AvRadioGroup
                            inline
                            name="docInit"
                            value={""}
                            onChange={(e) => (
                              e.persist(), setInit(e?.target?.value)
                            )}
                          >
                            <AvRadio label="Yes" value={true} />
                            <AvRadio label="No" value={false} />
                          </AvRadioGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Col>
              <Col md={6}>
                <div>
                  <FormGroup>
                    <Label htmlFor="description"> Description</Label>
                    <textarea
                      className="form-control"
                      onChange={(e) => setDescription(e.target.value)}
                      id="description"
                      value={description}
                      rows="5"
                    // ref={descriptionRef}
                    ></textarea>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            {defaultValue ? (
              <button className="btn btn-success mr-2" type="submit">
                {loading ? "Submitting ..." : "Update"}
              </button>
            ) : (
              <button className="btn btn-success mr-2" type="submit">
                {loading ? "Submitting ..." : "Save"}
              </button>
            )}

            <Link to="/document_type">
              <button className="btn btn-secondary">Cancel</button>
            </Link>
          </AvForm>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { initDoc, initDocError, docTypeId, message, loading, docTypeError } =
    state.DocType;
  return { initDoc, initDocError, docTypeId, message, loading, docTypeError };
};

export default connect(mapStateToProps, {
  createDocType,
  updateDocType,
  createDocTypeFailed,
  updateDocTypeFailed,
  initDocType,
  initDocTypeFailed,
})(withRouter(Form));
