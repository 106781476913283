import {
  CHECK_LOGIN,
  LOGIN_USER_SUCCESSFUL,
  API_ERROR,
  UPDATE_ACCOUNT_WALLET,
  UPDATE_ACCOUNT_WALLET_SUCCESSFULLY,
  UPDATE_ACCOUNT_WALLET_FAILED,
  GENERATE_OTP,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAILED,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOAD_USER,
  LOAD_USER_SUCCESSFUL,
  LOAD_USER_ERROR,
  AUTH_ERROR,
  API_ACTIVATION_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  ACTIVATE_USER,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_FAILED,
  RE_ACTIVATE_USER,
  RE_ACTIVATE_USER_SUCCESS,
  RE_ACTIVATE_USER_FAILED,
  EMAIL_SUBSCRIPTION,
  EMAIL_SUBSCRIPTION_SUCCESS,
  EMAIL_SUBSCRIPTION_FAILED,
  FORGET_USER,
  FORGET_USER_SUCCESSFUL,
  FORGET_PASSWORD_API_FAILED,
  CREATE_NEW_PASSWORD,
  CREATE_NEW_PASSWORD_SUCCESSFUL,
  CREATE_NEW_PASSWORD_API_FAILED,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESSFUL,
  UPDATE_PROFILE_API_FAILED,
  GOOGLE_LOGIN,
  PASSWORD_SETTING,
  PASSWORD_SETTING_SUCCESSFUL,
  PASSWORD_SETTING_API_FAILED,
  USER_LOCATION,
  USER_LOCATION_SUCCESSFULLY,
  USER_LOCATION_ERROR,
  MY_ACCESS_CONTROL,
  MY_ACCESS_CONTROL_SUCCESSFUL,
  MY_ACCESS_CONTROL_FAILED,
} from './actionTypes';

export const checkLogin = (user, history) => {
  return {
    type: CHECK_LOGIN,
    payload: { user, history },
  };
};

export const googleLogin = (token, history) => {
  return {
    type: GOOGLE_LOGIN,
    payload: { token, history },
  };
};

export const loginUserSuccessful = (token) => {
  return {
    type: LOGIN_USER_SUCCESSFUL,
    payload: token,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const apiActivationError = (error) => {
  return {
    type: API_ACTIVATION_ERROR,
    payload: error,
  };
};

export const authError = (error) => {
  return {
    type: AUTH_ERROR,
    // payload: error,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const loadUser = (payload) => {
  return {
    type: LOAD_USER,
    payload: { payload },
  };
};

export const loadUserSuccessful = (payload) => {
  return {
    type: LOAD_USER_SUCCESSFUL,
    payload: payload,
  };
};

export const loadUserError = (error) => {
  return {
    type: LOAD_USER_ERROR,
    payload: error,
  };
};

//Genearte OTP
export const generateOTP = (payload) => {
  return {
    type: GENERATE_OTP,
    payload,
  };
};

export const generateOTPSuccessful = (payload) => {
  return {
    type: GENERATE_OTP_SUCCESS,
    payload,
  };
};

export const generateOTPFailed = (error) => {
  return {
    type: GENERATE_OTP_FAILED,
    payload: error,
  };
};

// Register Module
export const registerUser = (userInfo) => {
  return {
    type: REGISTER_USER,
    payload: { userInfo },
  };
};

export const registerUserSuccessful = (user) => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  };
};

export const registerUserFailed = (error) => {
  return {
    type: REGISTER_USER_FAILED,
    payload: error,
  };
};

export const activateAccount = (id, history) => {
  return {
    type: ACTIVATE_USER,
    payload: { id, history },
  };
};

export const activateAccountSuccess = (payload) => {
  return {
    type: ACTIVATE_USER_SUCCESS,
    payload: payload,
  };
};

export const activateAccountFailed = (error) => {
  return {
    type: ACTIVATE_USER_FAILED,
    payload: error,
  };
};

export const resendActivateAccount = (email) => {
  return {
    type: RE_ACTIVATE_USER,
    payload: { email },
  };
};

export const resendActivateAccountSuccess = (payload) => {
  return {
    type: RE_ACTIVATE_USER_SUCCESS,
    payload: payload,
  };
};

export const resendActivateAccountFailed = (error) => {
  return {
    type: RE_ACTIVATE_USER_FAILED,
    payload: error,
  };
};

export const emailSubscription = (payload, query) => {
  return {
    type: EMAIL_SUBSCRIPTION,
    payload: { payload, query },
  };
};

export const emailSubscriptionSuccess = (payload) => {
  return {
    type: EMAIL_SUBSCRIPTION_SUCCESS,
    payload: payload,
  };
};

export const emailSubscriptionFailed = (error) => {
  return {
    type: EMAIL_SUBSCRIPTION_FAILED,
    payload: error,
  };
};

// forgetPassword
export const forgetUser = (user, history) => {
  return {
    type: FORGET_USER,
    payload: { user, history },
  };
};

export const forgetUserSuccessful = (message) => {
  return {
    type: FORGET_USER_SUCCESSFUL,
    payload: message,
  };
};

export const userForgetPasswordError = (error) => {
  return {
    type: FORGET_PASSWORD_API_FAILED,
    payload: error,
  };
};

// Create New Password
export const createNewPassword = (user, history) => {
  return {
    type: CREATE_NEW_PASSWORD,
    payload: { user, history },
  };
};

export const createNewPasswordSuccessful = (message) => {
  return {
    type: CREATE_NEW_PASSWORD_SUCCESSFUL,
    payload: message,
  };
};

export const createNewPasswordError = (error) => {
  return {
    type: CREATE_NEW_PASSWORD_API_FAILED,
    payload: error,
  };
};

// UPDATE PROFILE
export const updateProfile = (payload, id) => {
  return {
    type: UPDATE_PROFILE,
    payload: { payload, id },
  };
};

export const updateProfileSuccessful = (payload) => {
  return {
    type: UPDATE_PROFILE_SUCCESSFUL,
    payload: payload,
  };
};

export const updateProfileError = (error) => {
  return {
    type: UPDATE_PROFILE_API_FAILED,
    payload: error,
  };
};

// password setting
export const passwordSetting = (payload) => {
  return {
    type: PASSWORD_SETTING,
    payload: payload,
  };
};

export const passwordSettingSuccessful = (message) => {
  return {
    type: PASSWORD_SETTING_SUCCESSFUL,
    payload: message,
  };
};

export const passwordSettingError = (error) => {
  return {
    type: PASSWORD_SETTING_API_FAILED,
    payload: error,
  };
};

//WALLET
export const updateAccountWallet = (values, urlLink) => {
  return {
    type: UPDATE_ACCOUNT_WALLET,
    payload: { values, urlLink },
  };
};

export const updateAccountWalletSuccessfully = (payload) => {
  return {
    type: UPDATE_ACCOUNT_WALLET_SUCCESSFULLY,
    payload: payload,
  };
};

export const updateAccountWalletFailed = (error) => {
  return {
    type: UPDATE_ACCOUNT_WALLET_FAILED,
    payload: error,
  };
};

export const userLocation = (payload) => {
  return {
    type: USER_LOCATION,
    payload,
  };
};

export const userLocationSuccessfully = (payload) => {
  return {
    type: USER_LOCATION_SUCCESSFULLY,
    payload: payload,
  };
};

export const userLocationError = (error) => {
  return {
    type: USER_LOCATION_ERROR,
    payload: error,
  };
};

export const myAccessControl = (payload) => {
  return {
    type: MY_ACCESS_CONTROL,
    payload,
  };
};

export const myAccessControlSuccessfully = (payload) => {
  return {
    type: MY_ACCESS_CONTROL_SUCCESSFUL,
    payload: payload,
  };
};

export const myAccessControlFailed = (error) => {
  return {
    type: MY_ACCESS_CONTROL_FAILED,
    payload: error,
  };
};
