import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import AutoComplete from '../../../../../components/Common/Autocomplete';
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import {
  createContact,
  createContactFailed,
  updateContact,
  updateContactFailed,
  fetchUserDetails,
} from '../../../../../store/actions';
const Form = ({
  fetchUserDetails,
  user,
  createContact,
  createContactFailed,
  updateContact,
  updateContactFailed,
  message,
  history,
  contactError,
  defaultValue,
  loading,
}) => {
  const [errorMessage, setError] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const handleSubmit = async (event, values) => {
    if (!selectedUser) {
      setError('Please choose contact owner');
      return;
    }
    const contactData = {
      ...values,
    };
    contactData.userId = selectedUser.value;
    contactData.name = values.name;
    contactData.relationship = values.relationship;
    contactData.phone = values.phone;
    if (defaultValue) {
      contactData.id = defaultValue.id;
      updateContact(contactData);
      return;
    }
    createContact(contactData);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        createContactFailed('');
        updateContactFailed('');
        history.goBack();
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    createContactFailed('');
    updateContactFailed('');
  }, []);

  useEffect(() => {
    if (defaultValue) {
      fetchUserDetails(defaultValue.user);
      setSelectedUser(defaultValue.user);
    }
  }, [defaultValue]);
  //eslint-disable-next-line
  const REGEXP = /[\+]\d{3}\d{2}\d{4}\d{4}/;
  return (
    <Card className='col-md-6'>
      <CardBody>
        {message && (
          <Alert color='success' className='text-center'>
            {message}
          </Alert>
        )}
        {contactError && (
          <Alert color='danger' className='text-center'>
            {contactError}
          </Alert>
        )}
        {errorMessage && (
          <Alert color='danger' className='text-center'>
            {errorMessage}
          </Alert>
        )}
        <div className='float-right'>
          <Link to='#' onClick={() => history.goBack()}>
            <i className='fas fa-arrow-left mr-3' />
            Back
          </Link>
        </div>
        <div className='mb-3'>
          <h6> {defaultValue ? 'Update' : 'Create'} Contact </h6>
        </div>
        <div className='col-md-12'>
          <AvForm className='form-horizontal' onValidSubmit={handleSubmit}>
            <Row>
              <Col>
                <FormGroup className='mb-4'>
                  {(() => {
                    if (defaultValue)
                      return (
                        <>
                          <AvField
                            name='userId'
                            type='text'
                            className='form-control bg-light'
                            label='Contact Owner'
                            id='userId'
                            value={`${user?.firstName} ${user?.lastName}`}
                            required
                            disabled
                          />
                        </>
                      );
                    else
                      return (
                        <AutoComplete
                          setUser={(e) => setSelectedUser(e)}
                          // userType={'DRIVER'}
                          title='Contact Owner'
                        />
                      );
                  })()}
                </FormGroup>
                <FormGroup className='mb-4'>
                  <Label htmlFor='name'>Name</Label>
                  <AvField
                    name='name'
                    type='text'
                    className='form-control bg-light'
                    id='name'
                    value={defaultValue ? defaultValue.name : ''}
                    required
                    placeholder='Enter Contact Name ...'
                  />
                </FormGroup>

                <FormGroup className='mb-4'>
                  <AvField
                    className='form-control bg-light'
                    name='phone'
                    type='text'
                    // max={14}
                    validate={{
                      tel: { pattern: REGEXP },
                    }}
                    errorMessage='Please enter a valid phone number (country code + 10 digits)'
                    label='Phone Number'
                    value={defaultValue ? defaultValue.phone : ''}
                    required
                    placeholder='Enter Contact Phone Number ...'
                  />
                </FormGroup>

                <FormGroup className=' mb-4'>
                  <Label htmlFor='relationship'> Description</Label>
                  <AvField
                    name='relationship'
                    type='text'
                    className='form-control bg-light'
                    id='relationship'
                    value={defaultValue ? defaultValue.relationship : ''}
                    required
                    placeholder='Enter Contact Relationship ...'
                  />
                </FormGroup>
              </Col>
            </Row>

            <button className='btn btn-success mr-2' type='submit'>
              {loading
                ? 'Submitting ...'
                : `${defaultValue ? 'Update' : 'Create'}`}
            </button>
            <Link to='#' onClick={() => history.goBack()}>
              <button className='btn btn-secondary'>Cancel</button>
            </Link>
          </AvForm>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, contactError } = state.Contact;
  const { user, userError } = state.Users;
  return { user, message, loading, contactError, userError };
};

export default connect(mapStateToProps, {
  createContact,
  updateContact,
  createContactFailed,
  updateContactFailed,
  fetchUserDetails,
})(withRouter(Form));
