import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import FilterDropDown from '../../../components/Common/FilterDropDown';
import SimpleBar from 'simplebar-react';
import { CSVLink } from 'react-csv';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactPaginate from 'react-paginate';
import Loader from '../../../components/Common/Loading/index.js';
import AutoComplete from '../../../components/Common/Autocomplete/searchAutocomplete';
import CheckAction, { ReadCheck } from '../../../components/Common/Summary/actionCheck';
import Moment from 'moment';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../utils/general';
import queryString from 'query-string';

let skip = 0;
let dataLimit = 10;

const ActivitiesTable = ({
  activitiesData,
  previewAction,
  fetchActivities,
  deleteActivities,
  message,
  history,
  dateFilter,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [skipPage, setSkipPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [date, setDate] = useState({
    start: '',
    end: '',
  });
  const [menu, setMenu] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(activitiesData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      search,
      start: date.start,
      end: date.end,
      userId,
    };
    fetchActivities(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data?.selected;
    setPageNumber(parseInt(data?.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data?.selected + '0'));
    }
    let pageInfo = {
      skip,
      take,
      search,
      start: date.start,
      end: date.end,
      userId,
    };
    setSkipPage(Math.ceil(pageNumber / take));
    fetchActivities(pageInfo);
    ScrollToTop();
    SetUrlWithParams();
  };
  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      skip,
      start: date.start,
      end: date.end,
      userId,
    };
    fetchActivities(queryParams);
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    dateFilter({ start: values.startDate, end: values.endDate });
    setMenu(false);
    let queryParams = {
      search,
      take,
      skip,
      start: values.startDate,
      end: values.endDate,
      userId,
    };
    fetchActivities(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: '',
      end: '',
    });
    dateFilter({});
    let queryParams = {
      search,
      take,
      skip,
    };
    fetchActivities(queryParams);
  };
  useEffect(() => {
    if (selectedUser) {
      setUserId(selectedUser.id);
    }
  }, [selectedUser]);
  const handleUserFetch = (_event, value) => {
    let queryParams = {
      search,
      take,
      skip,
      start: date.start,
      end: date.end,
      userId,
    };
    fetchActivities(queryParams);
    setShowUserModal(false);
  };
  const handleFetchAll = () => {
    setUserId(null);
    setSelectedUser(null);
    let queryParams = {
      search,
      take,
      skip,
      start: date.start,
      end: date.end,
    };
    fetchActivities(queryParams);
    setShowUserModal(false);
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          start: date.start,
          end: date.end,
          userId,
        };
        fetchActivities(queryParams);
      }, 2000);
    }
  }, [message, fetchActivities]);

  useEffect(() => {
    if (activitiesData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(activitiesData?.total / take));
      // SET CSV EXPORT DATA
      const csvData = activitiesData.result.map((data) => {
        let id = data?._id?.substring(0, 7);
        let formData = {
          id: id,
          Driver_Name: `${data?.user?.firstName} - ${data?.user?.lastName} `,
          Make: data?.make ? data?.make : '----',
          Model: data?.model ? data?.model : '----',
          Description: data?.description ? data?.description : '----',
          Color: data?.color ? data?.color : '----',
          Year: data?.year ? data?.year : '----',
          Seats: data?.seats ? data?.seats : '----',
          Plate_Number: data?.plateNumber ? data?.plateNumber : '----',
          Status: data?.status,
          Image_Front: data?.imageFront ? data?.imageFront : '----',
          Image_Back: data?.imageBack ? data?.imageBack : '----',
          Image_Left: data?.imageLeft ? data?.imageLeft : '----',
          Image_Right: data?.imageRight ? data?.imageRight : '----',
          Craeted_Date: data?.createdAt?.substring(0, 10),
        };

        return formData;
      });
      setCsvData(csvData);
    }
  }, [activitiesData]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchActivities(parsedUrlParams);
  }, [fetchActivities]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      start: date.start,
      end: date.end,
      take,
      skip: pageNumber,
      // userId,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        take,
        skip,
        start: date.start,
        end: date.end,
        // userId,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }

    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    if (parsedUrlParams?.start) {
      setDate({
        start: parsedUrlParams?.start,
        end: parsedUrlParams?.end,
      });
    }
    // if (parsedUrlParams?.userId) {
    //   setUserId(parsedUrlParams?.userId);
    // }
    return parsedUrlParams;
  };
  let checking = ReadCheck(accessControl, `Reports`, 'Activities Log');

  const tableDataHandler = () => {
    const rowData = activitiesData?.result?.map((data, i) => {
      return {
        idKey: data?._id,
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,

        id: data?._id?.substring(0, 7),
        module: (
          <>
            {checking ?

              <Link
                to='#'
                onClick={() => previewAction(data._id)}
                className='text-dark font-weight-bold'
              >
                <div className='text-upper'>
                  {data?.module}
                  <div className='d-flex align-items-center'>
                    <div>
                      <span className='text-muted mr-2 badge badge-soft-primary'>
                        {data?.method}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>

              :
              <div className='text-dark font-weight-bold'>
                <div className='text-upper'>
                  {data?.module}
                  <div className='d-flex align-items-center'>
                    <div>
                      <span className='text-muted mr-2 badge badge-soft-primary'>
                        {data?.method}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            }</>

        ),
        message: data?.message ? data?.message : '----',
        user: (
          <div className='text-truncate'>
            <Link
              to={`/user/${data.userId}`}
              className='text-dark font-weight-bold'
            >
              <div className='text-capitalize'>
                {data.firstName} {data.lastName}
              </div>
              <div className='d-flex align-items-center'>
                <img
                  src={data?.picture ? data?.picture : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                  alt=''
                  height='20'
                  className='rounded-circle avatar-xs mr-2'
                />
                <div>
                  <span className='text-muted mr-2 badge badge-soft-secondary'>
                    {data?.userType}
                  </span>
                </div>
              </div>
            </Link>
          </div>
        ),
        response: (
          <div
            className='text-center font-weight-bold text-truncate'
            style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}
          >
            {data?.host}
            {data?.url.includes('?')
              ? data?.url.substr(0, data?.url.indexOf('?'))
              : data?.url}

            <div className='d-flex align-items-center justify-content-center text-upper'>
              <div>
                <h6
                  className={` badge badge-soft-${data?.responseStatusCode === '200' ||
                    data?.responseStatusCode === '201'
                    ? 'success'
                    : 'danger'
                    } ml-2`}
                >
                  {data?.responseStatusCode === '200' ||
                    data?.responseStatusCode === '201'
                    ? 'success'
                    : 'failed'}{' '}
                </h6>
              </div>
            </div>
          </div>
        ),
        device: (
          <div className='text-center text-upper font-weight-bold '>
            {data?.nrydeSource?.includes('admin')
              ? 'Admin Dashboard'
              : data?.nrydeSource?.includes('android')
                ? 'ANDROID'
                : data?.nrydeSource?.includes('ios')
                  ? 'IOS'
                  : data?.nrydeSource}
          </div>
        ),
        date: (
          <div>
            {' '}
            <div>{Moment(data?.createdAt).format('DD/MM/YYYY')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data?.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),

        action: (


          <div className='d-flex align-items-center'>
            <div>
              {/* <Link
                to={`/activities_form?id=${data?._id}`}
                className='mr-3 text-primary'
                id='edit1'
              >
                <i className='mdi mdi-pencil font-size-16'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='edit1'>
                Edit
              </UncontrolledTooltip> */}
              <CheckAction field='Reports' subModule='Activities Log' iconType='Delete'>
                <Link
                  to='#'
                  onClick={() => deleteActivities(data?._id)}
                  className='mr-3 text-danger'
                  id='delete1'
                >
                  <i className='mdi mdi-trash-can font-size-16'></i>
                </Link>
                <UncontrolledTooltip placement='top' target='delete1'>
                  Delete
                </UncontrolledTooltip>
              </CheckAction>
            </div>
          </div>


        ),
      };
    });
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
        },
        {
          text: 'Activity',
          dataField: 'module',
        },
        {
          text: 'Message',
          dataField: 'message',
        },
        {
          text: 'User',
          dataField: 'user',
          sort: true,
        },

        {
          text: 'Page - Module',
          dataField: 'response',
        },
        {
          text: 'Device',
          dataField: 'device',
          headerAlign: 'center',
        },
        {
          text: 'Time',
          dataField: 'date',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {!isLoading && data !== null ? (
                <>
                  <div className='d-flex justify-content-end  align-items-center mb-2'>
                    <div>
                      <Button
                        size='sm'
                        color='primary'
                        className='mt-1'
                        onClick={() => setShowUserModal(true)}
                      >
                        Filter User
                      </Button>
                    </div>
                    <div>
                      <FilterDropDown
                        menu={menu}
                        toggleMenu={() => setMenu(!menu)}
                        text={'Filter Date'}
                      >
                        <div>
                          <AvForm
                            className='form-horizontal'
                            onValidSubmit={handleDateFilter}
                          >
                            <SimpleBar
                              style={{
                                maxHeight: '250px',
                              }}
                            >
                              <Row className='px-3  pt-3 pb-0'>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='startDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='startDate'
                                      value={date.start}
                                      helpMessage='Start Date'
                                      placeholder='Please choose the start date...'
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='endDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='endDate'
                                      value={date.end}
                                      helpMessage='End Date'
                                      placeholder='Please choose the end date...'
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </SimpleBar>

                            <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                              <Link
                                to='#'
                                onClick={resetHandler}
                                className='btn btn-sm  bg-light font-size-14'
                              >
                                <i className=' mdi mdi-refresh mr-1'> Reset</i>
                              </Link>

                              <button
                                className='btn btn-success btn-sm mr-2'
                                type='submit'
                              >
                                <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                Send
                              </button>
                            </div>
                          </AvForm>
                        </div>
                      </FilterDropDown>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between mb-2'>
                    <div>
                      <Input
                        bsSize='sm'
                        id='take'
                        name='take'
                        type='select'
                        onChange={handleLimitChange}
                        value={take}
                      >
                        <option>5</option>
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>
                    <div className='d-flex align-items-center'>
                      {selectedUser && (
                        <>
                          <span
                            className={`badge badge-soft-secondary font-size-14`}
                          >
                            {selectedUser?.name}
                          </span>
                          <Link
                            className='ml-3 text-dark'
                            id='Refresh'
                            onClick={() => handleFetchAll()}
                            to={'#'}
                          >
                            <i className='mdi mdi-autorenew font-size-16 m-0 p-0'></i>
                          </Link>
                          <UncontrolledTooltip placement='top' target='Refresh'>
                            Fetch All
                          </UncontrolledTooltip>
                        </>
                      )}
                    </div>
                    <div>
                      <Input
                        bsSize='sm'
                        id='search'
                        value={search}
                        name='search'
                        onChange={SearchHandler}
                        placeholder='Search'
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField='idKey'
                    data={data?.rows}
                    columns={data?.columns}
                    striped
                    hover
                    wrapperClasses='table-responsive'
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={skipPage}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={showUserModal}
        toggle={() => setShowUserModal(!showUserModal)}
      >
        <ModalHeader toggle={() => setShowUserModal(false)}>
          Filter By User
        </ModalHeader>
        <ModalBody>
          <AvForm className='form-horizontal' onValidSubmit={handleUserFetch}>
            <FormGroup className='mb-4'>
              <AutoComplete setUser={(e) => setSelectedUser(e)} />
            </FormGroup>
            <button className='btn btn-success mr-2' type='submit'>
              Submit
            </button>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ActivitiesTable;
