import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Alert,
  FormGroup,
  Col,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import BootstrapTable from 'react-bootstrap-table-next';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import ReactPaginate from 'react-paginate';
import Loader from '../../../components/Common/Loading/index.js';
import FilterDropDown from '../../../components/Common/FilterDropDown';
import SimpleBar from 'simplebar-react';
import Moment from 'moment';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../utils/general';
import queryString from 'query-string';

let skip = 0;
let dataLimit = 10;

const PromoCodeTable = ({
  promoCodeData,
  tableError,
  previewAction,
  fetchPromoCode,
  deletePromoCode,
  history,
  message,
  dateFilter,
  CheckAction,
  ReadCheck,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [skipPage, setSkipPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState('');
  const [menu, setMenu] = useState(false);
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(promoCodeData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = { skip: pageNumber, take: dataLimit, status, search };
    fetchPromoCode(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take, status, search };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchPromoCode(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      // skip,
      status,
    };
    fetchPromoCode(queryParams);
  };
  const GetPromoCodeByStatus = (status) => {
    setStatus(status);
    let queryParams = {
      search,
      take,
      skip,
      status,
    };
    fetchPromoCode(queryParams);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          status,
        };
        fetchPromoCode(queryParams);
      }, 2000);
    }
  }, [fetchPromoCode, message]);

  useEffect(() => {
    if (promoCodeData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(promoCodeData?.total / take));
      // SET CSV EXPORT DATA
      const csvData = promoCodeData.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Name: data?.name ? data?.name : '----',
          Description: data?.description ? data?.description : '----',
          Category: data?.category ? data?.category : '----',
          Point: data?.point ? data?.point : '----',
          Percent: data?.percent ? data?.percent : '----',
          Value: data?.value ? data?.value : '----',
          Code: data?.code ? data?.code : '----',
          Package_Offer: data?.packageOffer ? data?.packageOffer : '----',
          Total: data?.total ? data?.total : '----',
          TotalAtStart: data?.totalAtStart ? data?.totalAtStart : '----',
          Redeems: data?.redeems ? data?.redeems : '----',
          Start_Date: data?.startDate?.substring(0, 10),
          Expiry_Date: data?.expiry?.substring(0, 10),
          Packages_ID: data?.packages ? `${data.packages}` : '----',
          Status: data?.status,
          Created_Date: data?.createdAt?.substring(0, 10),
          Image_Url: data?.imageUrl ? data?.imageUrl : '----',
        };

        return formData;
      });
      setCsvData(csvData);
    }
  }, [promoCodeData]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchPromoCode(parsedUrlParams);
  }, [fetchPromoCode]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      status,
      take,
      skip: pageNumber,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        take,
        skip,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.status) {
      setStatus(parsedUrlParams?.status);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    dateFilter({ start: values.startDate, end: values.endDate });
    setMenu(false);
    setStatus(values.vehicleStatus);
    let queryParams = {
      search,
      take,
      skip,
      status: values.vehicleStatus,
      start: values.startDate,
      end: values.endDate,
    };
    fetchPromoCode(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: '',
      end: '',
    });
    dateFilter({});
    let queryParams = {
      search,
      take,
      skip,
      status,
    };
    fetchPromoCode(queryParams);
  };
  let accessChecking = ReadCheck(accessControl, `Rides Promotions`, 'Promo Code');
  const tableDataHandler = () => {
    const rowData = promoCodeData?.result?.map((data) => {
      return {
        id: data.id,
        name: (

          <>
            {accessChecking ?

              <Link
                to='#'
                onClick={() => previewAction(data.id)}
                className='text-dark font-weight-bold'
              >
                <span className='text-capitalize'>
                  {data.name}
                  <span className='d-block'>
                    <span className='badge badge-soft-secondary rounded'>
                      {' '}
                      {data.category}{' '}
                    </span>
                  </span>
                </span>
              </Link>

              :
              <div className='text-dark font-weight-bold'>
                <span className='text-capitalize'>
                  {data.name}
                  <span className='d-block'>
                    <span className='badge badge-soft-secondary rounded'>
                      {' '}
                      {data.category}{' '}
                    </span>
                  </span>
                </span>
              </div>
            }
          </>
        ),
        value: (
          <span>
            <span className='d-block'>
              ₦{data.value.toLocaleString()}
              {data.point && (
                <>
                  {' '}
                  -{' '}
                  <span className='badge badge-warning rounded'>
                    {data.point}
                  </span>
                </>
              )}
            </span>

            {data.percent && (
              <>
                {' '}
                <span className='badge badge-soft-secondary rounded'>
                  {data.percent}%
                </span>
              </>
            )}
          </span>
        ),
        balance: (
          <>
            <span className='d-block'>Total : {data.totalAtStart}</span>
            <span className='d-flex align-items-center'>
              Balance : {data.total}
              <span className='badge badge-danger rounded ml-2'>
                {data.redeems}
              </span>
            </span>
          </>
        ),
        // dateFrom: Moment(data.startDate).format('lll'),
        date: (
          <>
            <span className='d-block font-size-13'>
              Start : {Moment.utc(data.startDate).format('lll')}
            </span>
            <span className='font-size-13'>
              End :
              <span
                className={`badge badge-soft-${new Date(data.expiry) <= new Date() ? 'danger' : 'success'
                  }`}
              >
                {Moment(data.expiry).format('lll')}
              </span>
            </span>
          </>
        ),
        // usage: <div className="text-center">{data.value}</div>,
        status: (
          <h1
            className={`badge badge-soft-${data.status.toLowerCase() === 'active'
              ? 'success'
              : data.status.toLowerCase() === 'inactive'
                ? 'danger'
                : 'warning'
              }`}
          >
            {data.status === 'COMINGSOON' ? 'COMING SOON' : data.status}
          </h1>
        ),
        action: (
          <div className='d-flex align-items-center'>
            <CheckAction field='Rides Promotions' subModule='Promo Code' iconType='Edit'>
              <Link
                to={`/promo_code_form?id=${data.id}`}
                className='mr-3 text-primary'
                id='edit1'
              >
                <i className='mdi mdi-pencil font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='edit1'>
                Edit
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field='Rides Promotions' subModule='Promo Code' iconType='Delete'>
              <Link
                to='#'
                onClick={() => deletePromoCode(data.id)}
                className='text-danger'
                id='delete1'
              >
                <i className='mdi mdi-trash-can font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='delete1'>
                Delete
              </UncontrolledTooltip>
            </CheckAction>
          </div>
        ),
      };
    });
    return {
      columns: [
        {
          dataField: 'sl.no',
          text: 'S.No',
          formatter: (cell, row, rowIndex) => {
            return rowIndex + 1;
          },
          sort: true,
        },
        {
          text: 'Name',
          dataField: 'name',
        },
        {
          text: 'Values - Point - %',
          dataField: 'value',
          sort: true,
        },
        {
          text: 'Total - Balance - Redeem',
          dataField: 'balance',
          sort: true,
        },
        {
          text: 'Start - End / Date',
          dataField: 'date',
        },
        {
          text: 'Status',
          dataField: 'status',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {tableError !== null ? (
                <Alert color='danger' className='text-center'>
                  {tableError}
                </Alert>
              ) : (
                <>
                  <div className='d-flex justify-content-end  align-items-center mb-2'>
                    <div className=''>
                      <CSVLink
                        data={csvData}
                        filename={'promoCodes_data.csv'}
                        className='btn btn-primary btn-sm  mt-1'
                        target='_blank'
                      >
                        <i className=' fas fa-cloud-download-alt mr-1' />
                        CSV
                      </CSVLink>
                    </div>
                    <div>
                      <FilterDropDown
                        menu={menu}
                        toggleMenu={() => setMenu(!menu)}
                      >
                        <div>
                          <AvForm
                            className='form-horizontal'
                            onValidSubmit={handleDateFilter}
                          >
                            <SimpleBar
                              style={{
                                maxHeight: '250px',
                              }}
                            >
                              <Row className='px-3  pt-3 pb-0'>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='startDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='startDate'
                                      value={date.start}
                                      helpMessage='Start Date'
                                      placeholder='Please choose the start date...'
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='endDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='endDate'
                                      value={date.end}
                                      helpMessage='End Date'
                                      placeholder='Please choose the end date...'
                                      required
                                    />
                                  </FormGroup>
                                </Col>{' '}
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      type='select'
                                      value={status}
                                      name='vehicleStatus'
                                      helpMessage='status'
                                    >
                                      <option value=''>All</option>
                                      <option value='ACTIVE'>ACTIVE</option>
                                      <option value='INACTIVE'>INACTIVE</option>
                                      <option value='COMINGSOON'>
                                        COMING SOON
                                      </option>
                                    </AvField>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </SimpleBar>

                            <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                              <Link
                                to='#'
                                onClick={resetHandler}
                                className='btn btn-sm  bg-light font-size-14'
                              >
                                <i className=' mdi mdi-refresh mr-1'> Reset</i>
                              </Link>

                              <button
                                className='btn btn-success btn-sm mr-2'
                                type='submit'
                              >
                                <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                Send
                              </button>
                            </div>
                          </AvForm>
                        </div>
                      </FilterDropDown>
                    </div>
                  </div>
                  {!isLoading && data !== null ? (
                    <>
                      <div className='d-flex justify-content-between mb-2'>
                        <div>
                          <Input
                            bsSize='sm'
                            id='take'
                            name='take'
                            type='select'
                            onChange={handleLimitChange}
                            value={take}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div>
                          <Input
                            bsSize='sm'
                            id='search'
                            value={search}
                            name='search'
                            onChange={SearchHandler}
                            placeholder='Search'
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField='id'
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses='table-responsive'
                        noDataIndication={'No Data to Display'}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        // breakLabel={'...'}
                        // marginPagesDisplayed={2}
                        // pageRangeDisplayed={3}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        forcePage={skipPage}
                        //Bootstrap styling
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PromoCodeTable;
