import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Table } from 'reactstrap';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { fetchDashRating } from '../../../store/actions';

const Ratings = ({ dashRating, fetchDashRating, loading, user }) => {
  const [userType, setUser] = useState('All');

  useEffect(() => {
    if (user.userType === 'ADMIN') {
      if (userType === 'All') {
        fetchDashRating({ rating: 'rating', take: 5, skip: 0 });
      } else {
        fetchDashRating({ rating: 'rating', take: 5, skip: 0, userType });
      }
    }
  }, [fetchDashRating, userType]);

  const avatarImg =
    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

  return (
    <React.Fragment>
      <Col lg={4}>
        <Card>
          <CardBody>
            <div className='float-right'>
              <select
                className='custom-select custom-select-sm'
                onChange={(e) => setUser(e.target.value)}
              >
                <option defaultValue>All</option>
                <option value='RIDER'>Rider</option>
                <option value='DRIVER'>Driver</option>
              </select>
            </div>

            <h4 className='card-title mb-3'>
              Highest Rating <span className='text-muted'>({userType}) </span>
            </h4>

            <div>
              <div className='table-responsive mt-4'>
                {loading && (
                  <Table hover className=' mb-0 table-centered table-nowrap'>
                    <tbody>
                      {[1, 2, 3, 4, 5]?.map((data, key) => {
                        return (
                          <tr key={key}>
                            <td style={{ width: '10%' }}>
                              <div className='avatar-xs'>
                                <div className='avatar-title rounded-circle bg-light'>
                                  <img
                                    src={avatarImg}
                                    alt=''
                                    height='20'
                                    className='rounded-circle avatar-xs'
                                  />
                                </div>
                              </div>
                            </td>

                            <td style={{ width: '90%' }}>
                              <h5 className='font-size-14 mb-0'>
                                <Skeleton duration={2} width='75%' />
                              </h5>

                              <span className='text-muted'>
                                <Skeleton duration={2} width='100%' />
                              </span>
                            </td>
                            <td>
                              <div id='spak-chart1'></div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                {dashRating && dashRating?.count && !loading ? (
                  <Table hover className=' mb-0 table-centered table-nowrap'>
                    <tbody>
                      {dashRating?.result?.map((data, key) => {
                        return (
                          <tr key={key}>
                            <td style={{ width: '60px' }}>
                              <div className='avatar-xs'>
                                <div className='avatar-title rounded-circle bg-light'>
                                  <img
                                    src={
                                      data.picture ? data.picture : avatarImg
                                    }
                                    alt=''
                                    height='20'
                                    className='rounded-circle avatar-xs'
                                  />
                                </div>
                              </div>
                            </td>

                            <td>
                              <h5 className='font-size-14 mb-0 text-capitalize'>
                                {data.firstName + ' ' + data.lastName}
                              </h5>
                              <span className='badge badge-soft-success font-size-11 mr-1'>
                                <i className=' fas fa-star text-warning mr-1' />
                                {data.rating.toFixed(2)}
                              </span>
                              <span className='text-muted'>
                                ({data.raters}
                                <i className='fas fa-user font-size-10 mr-1 text-muted' />
                                )
                              </span>
                              <span className='text-muted font-size-12'>
                                ({data.trips} / trips)
                              </span>
                            </td>
                            {/* <td>
                                <div id='spak-chart1'></div>
                              </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  'No data to display'
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { dashRating, loading, dashStatError } = state.Dashboard;
  return { dashRating, loading, dashStatError };
};

export default connect(mapStateToProps, { fetchDashRating })(Ratings);
