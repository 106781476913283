import {
  FETCH_APP_VERSIONS,
  FETCH_APP_VERSIONS_SUCCESSFUL,
  FETCH_APP_VERSIONS_API_FAILED,
  FETCH_EACH_APP_VERSION,
  FETCH_EACH_APP_VERSION_SUCCESSFUL,
  FETCH_EACH_APP_VERSION_API_FAILED,
  DELETE_APP_VERSION,
  DELETE_APP_VERSION_SUCCESSFUL,
  DELETE_APP_VERSION_FAILED,
  CREATE_APP_VERSION,
  CREATE_APP_VERSION_SUCCESSFUL,
  CREATE_APP_VERSION_FAILED,
  UPDATE_APP_VERSION,
  UPDATE_APP_VERSION_SUCCESSFUL,
  UPDATE_APP_VERSION_FAILED,
} from './actionTypes';

const initialState = {
  app_versions: null,
  app_versionError: null,
  tableError: null,
  app_version: null,
  isAuthorize: null,
  message: null,
  loading: false,
};

const App_version = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APP_VERSIONS:
    case UPDATE_APP_VERSION:
    case DELETE_APP_VERSION:
      state = {
        ...state,
        app_versions: null,
        tableError: null,
        app_versionError: null,
        loading: true,
        message: null,
        isAuthorize: null,
      };
      break;
    case FETCH_APP_VERSIONS_SUCCESSFUL:
      state = {
        ...state,
        app_versions: action.payload,
        tableError: null,
        app_versionError: null,
        loading: false,
      };
      break;

    case DELETE_APP_VERSION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        app_versionError: null,
        message: 'App_version deleted successfully',
      };
      break;

    case UPDATE_APP_VERSION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        app_versionError: null,
        message: 'App_version Updated Successfully',
      };
      break;

    case FETCH_EACH_APP_VERSION:
      state = {
        ...state,
        loading: true,
        app_versions: null,
        app_versionError: null,
        message: null,
        isAuthorize: null,
      };
      break;

    case FETCH_EACH_APP_VERSION_SUCCESSFUL:
      state = {
        ...state,
        app_version: action.payload,
        loading: false,
        app_versionError: null,
        message: null,
      };
      break;

    case CREATE_APP_VERSION:
      state = {
        ...state,
        loading: true,
        app_versionError: null,
        message: null,
      };
      break;

    case CREATE_APP_VERSION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        app_versionError: null,
        message: `App_version  created successfully`,
      };
      break;

    case FETCH_APP_VERSIONS_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case CREATE_APP_VERSION_FAILED:
    case DELETE_APP_VERSION_FAILED:
    case FETCH_EACH_APP_VERSION_API_FAILED:
      state = {
        ...state,
        app_versions: null,
        app_version: null,
        loading: false,
        message: null,
        app_versionError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_APP_VERSION_FAILED:
      state = {
        ...state,
        loading: false,
        app_versionError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default App_version;
