import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Label, FormGroup, Button, Alert } from "reactstrap";
import imgCar from "../../../../assets/images/sample_car.png";
import CustomSelect from "../../../../components/Common/MoreDropdown/CustomSelect";
import { fetchPackage } from "../../../../store/actions";
const VehiclePackage = ({
  vehicleDetails,
  packages,
  message,
  closeModal,
  vehicleError,
  fetchPackage,
  updateVehicleFailed,
  updateVehiclePackages,
}) => {
  const [selected, setSelected] = useState([]);
  const [packageOption, SetPackageOption] = useState([]);

  useEffect(() => {
    fetchPackage();
  }, []);

  const updateVehicle = () => {
    let vehicleIds = [];
    selected.forEach((data) => {
      vehicleIds.push(data.value);
    });
    const data = {
      packages: vehicleIds,
      id: vehicleDetails.id,
    };
    updateVehiclePackages(data);
  };

  useEffect(() => {
    if (packages) {
      const packageArr = [];
      packages.result.forEach((element) => {
        const packageObj = {};
        packageObj.value = element.id;
        packageObj.label = element.name;
        packageArr.push(packageObj);
      });
      SetPackageOption(packageArr);
    }

    if (vehicleDetails?.packages?.length > 0) {
      const pkgArr = [];
      if (vehicleDetails.packages) {
        vehicleDetails.packages.forEach((element) => {
          const pkgObj = {};
          pkgObj.value = element.id;
          pkgObj.label = element.name;
          pkgArr.push(pkgObj);
        });
      }
      setSelected(pkgArr);
    }

    if (message) {
      setTimeout(() => {
        updateVehicleFailed("");
        closeModal(false);
      }, 2000);
    }
  }, [packages, vehicleDetails, message]);
  const handleFetchMore = () => {
    fetchPackage({ take: packageOption.length + 10, skip: 0 });
  };
  return (
    <div>
      {message && (
        <Alert color="success" className="text-center">
          {message}
        </Alert>
      )}
      {vehicleError && (
        <Alert color="danger" className="text-center">
          {vehicleError}
        </Alert>
      )}
      <Row className="mb-2 align-items-center">
        <Col className="mb-2 px-0 py-0 text-center" sm={3}>
          <img
            className="rounded avatar-sm border shadow-xs p-2"
            alt="Profile"
            src={
              vehicleDetails.imageFront ? vehicleDetails?.imageFront : imgCar
            }
          />
        </Col>
        <Col sm={9}>
          <span className="mt-1 mb-1">
            <h4 className="font-weight-bold font-size-12 text-sm-left text-center mb-0">
              {vehicleDetails?.make}{" "}
              <span>
                <span
                  className={`font-size-10 badge badge-${
                    vehicleDetails.color.toLowerCase() === "red"
                      ? "danger"
                      : vehicleDetails.color.toLowerCase() === "blue"
                      ? "primary"
                      : vehicleDetails.color.toLowerCase() === "black"
                      ? "dark text-white"
                      : vehicleDetails.color.toLowerCase() === "gold"
                      ? "warning"
                      : vehicleDetails.color.toLowerCase() === "green"
                      ? "success"
                      : vehicleDetails.color.toLowerCase() === "grey"
                      ? "secondary text-white"
                      : "soft-secondary text-dark"
                  } text-white rounded text-capitalize`}
                >
                  {" "}
                  {vehicleDetails?.color}{" "}
                </span>
              </span>
            </h4>
          </span>
          <span className="text-muted mb-0 font-size-11 text-sm-left text-center">
            Model: {vehicleDetails?.model}
          </span>
          <p className="text-muted text-sm-left text-center font-size-11 mb-0">
            {vehicleDetails?.plateNumber} | {vehicleDetails?.year}
          </p>
        </Col>
      </Row>
      <div className="mb-4 d-flex">
        {vehicleDetails.packages.length > 0
          ? vehicleDetails.packages.map((pkg) => (
              <div key={pkg.id} className="ml-2">
                <span className={`badge badge-soft-secondary font-size-12`}>
                  {" "}
                  {pkg.name}
                </span>
              </div>
            ))
          : "No package"}
      </div>
      <div md={6}>
        <FormGroup>
          <Label htmlFor="packages"> Packages</Label>
          <CustomSelect
            options={packageOption}
            selected={selected}
            onChange={setSelected}
            fetchMore={
              packageOption.length !== packages?.total ? handleFetchMore : null
            }
            labelledBy="Select Packages ..."
          />
        </FormGroup>
        <div>
          <Button color="success" size="sm" onClick={updateVehicle}>
            {vehicleDetails.packages.length > 0 ? "Update" : "Add Packages"}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { packages, message, loading } = state.Packages;
  return { loading, message, packages };
};

export default connect(mapStateToProps, {
  fetchPackage,
})(withRouter(VehiclePackage));
