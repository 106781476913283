import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';

import GreySummaryCard from '../../../../components/Common/Summary/index';
import CheckAction from '../../../../components/Common/Summary/actionCheck';
import PackageVehicleTable from './packageVehicleTable';
// import PreviewVehicle from './previewVehicle';

import {
  fetchPackageVehiclesFailed,
  fetchPackageVehicles,
  fetchEachPackage,
} from '../../../../store/actions';

const Vehicle = ({
  packagePreview,
  packageVehicle,
  packageError,
  fetchPackageVehicles,
  fetchEachPackage,
  loading,
  packageMsg,
  message,
  history,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [vehicleDetails, setVehicleDetails] = useState(null);
  const [statusModal, setStatusModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [packageId, setPackageId] = useState(null);
  const [dateFilter, setDate] = useState({});
  const breadcrumbItems = [
    { title: 'Packages', link: '#' },
    { title: 'Package Vehicles', link: '/vehicles' },
  ];

  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');



  useEffect(() => {
    if (id) {
      setPackageId(id)
      fetchEachPackage(id);
    }
  }, [id])

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        // updateVehicleFailed('');
      }, 2000);
    }
  }, [message]);



  const date = (e) => {
    setDate(e);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Package Vehicles" breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field="Package Vehicles">

            <>

              {message && (
                <Alert color="success" className="text-center">
                  {message}
                </Alert>
              )}
              {packageError && (
                <Alert color="danger" className="text-center">
                  {packageError}
                </Alert>
              )}
              <Row>

                <Col>
                  <PackageVehicleTable
                    pkgVehicleData={packageVehicle}
                    dateFilter={date}
                    loading
                    packageId={packageId ? packageId : id}
                    fetchPackageVehicles={fetchPackageVehicles}
                    fetchPackageVehiclesFailed={fetchPackageVehiclesFailed}
                    packagePreview={packagePreview}
                    message={message}
                    packageMsg={packageMsg}
                    history={history}
                  />
                </Col>
              </Row>

              {/* <Modal
                  isOpen={showModal}
                  toggle={() => setShowModal(!showModal)}
                >
                  <ModalHeader toggle={() => setShowModal(false)}>
                    Preview Vehicle
                  </ModalHeader>
                  <ModalBody>
                    <PreviewVehicle previewId={previewId} />
                  </ModalBody>
                </Modal> */}

              <Modal
                isOpen={showDeleteModal}
                toggle={() => setShowDeleteModal(!showDeleteModal)}
              >
                <ModalBody>
                  <h4 className="card-title mb-4 text-center">
                    {' '}
                    Are you sure you want to delete this vehicle
                  </h4>
                  <button
                    // onClick={() => deleteHandler()}
                    className="btn btn-outline-danger btn-sm w-100"
                  >
                    Click to Delete
                  </button>
                </ModalBody>
              </Modal>


            </>

          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {

  const { packagePreview, message, packageError, packageVehicle, loading } = state.Packages;
  const packageMsg = state.Packages.message;
  return {
    loading,
    message,
    packageMsg,
    packagePreview,
    packageError,
    packageVehicle,
  };
};

export default connect(mapStateToProps, {
  fetchPackageVehiclesFailed,
  fetchPackageVehicles,
  fetchEachPackage,
})(Vehicle);
