import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';
export const CreatePushNotificationService = ({ payload, userId }) => {
  const http = new HttpService();
  let url = `fpn/push/topic`;
  if (userId) {
    url = `fpn/push/device/${userId}`;
  }
  return http.postData(payload, url);
};
export const FPNSubService = ({ payload }) => {
  const http = new HttpService();
  let url = `fpn/subscribe`;
  return http.postData(payload, url);
};
export const FetchPushNotificationService = (query) => {
  const http = new HttpService();
  const url = `fpn/push`;
  return http.getData(url);
};

export const DeletePushNotificationService = (id) => {
  const http = new HttpService();
  const url = `fpn/push/${id}`;
  return http.deleteData(url);
};

export const UpdatePushNotificationService = (payload) => {
  const http = new HttpService();
  const url = `fpn/push/${payload.id}`;
  return http.putData(payload, url);
};

export const CreateNotificationService = ({ payload }) => {
  const http = new HttpService();
  let url = `notification`;
  return http.postData(payload, url);
};
export const FetchNotificationService = (query) => {
  const http = new HttpService();
  let url = `notification/all`;
  if (query && query.isMyNotification) {
    url = 'notification';
    if (query.take) {
      url = `${url}?take=${query.take}&skip=${query.skip}`;
    }
    if (query.search) {
      url = `${url}?search=${query.search}`;
    }
  }

  if (query && !query.isMyNotification) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const FetchMyNotificationService = (query) => {
  const http = new HttpService();
  let url = `notification?take=5`;
  if (query && query.limit) {
    url = `${url}?take=${query.limit}&skip=${query.currentPage}`;
  }

  if (query && query.searchValue) {
    url = `${url}?search=${query.searchValue}`;
  }
  return http.getData(url);
};

export const FetchEachNotificationService = (id) => {
  const http = new HttpService();
  const url = `notification/${id}`;
  return http.getData(url);
};

export const DeleteNotificationService = (id) => {
  const http = new HttpService();
  const url = `notification/${id}`;
  return http.deleteData(url);
};

export const UpdateNotificationService = ({ payload, id }) => {
  const http = new HttpService();
  const url = `notification/${id}`;
  return http.putData(payload, url);
};

export const CreateTemplateService = (payload) => {
  const http = new HttpService();
  let url = `notification/template`;
  return http.postData(payload, url);
};
export const GetTemplateService = (query) => {
  const http = new HttpService();
  let url = `notification/template`;

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const GetEachTemplateService = (id) => {
  const http = new HttpService();
  const url = `notification/${id}/template`;
  return http.getData(url);
};

export const DeleteTemplateService = (id) => {
  const http = new HttpService();
  const url = `notification/${id}/template`;
  return http.deleteData(url);
};

export const UpdateTemplateService = ({ payload, id }) => {
  const http = new HttpService();
  const url = `notification/${id}/template`;
  return http.putData(payload, url);
};

export const GetBetaEmailsService = (query) => {
  const http = new HttpService();
  let url = `notification/beta`;

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const FetchNotificationLogService = (query) => {
  const http = new HttpService();
  let url = `notification-log/all`;

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const FetchEachNotificationLogService = (id) => {
  const http = new HttpService();
  const url = `notification-log/${id}`;
  return http.getData(url);
};
