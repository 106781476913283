import React, { useState, useEffect } from 'react';
import {
  Card,
  Alert,
  CardBody,
  Row,
  FormGroup,
  Col,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';

import ReactPaginate from 'react-paginate';

import Loader from '../../../../components/Common/Loading/index.js';
import Moment from 'moment';
import moment from 'moment-timezone';

import FilterDropDown from '../../../../components/Common/FilterDropDown';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../../utils/general';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import queryString from 'query-string';

let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const SOSTable = ({
  sosData,
  tableError,
  fetchSOS,
  history,
  message,
  previewAction,
  statusIdHandler,
  CheckAction,
  ReadCheck,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [skipPage, setSkipPage] = useState(0);
  const [menu, setMenu] = useState(false);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [type, setType] = useState('');
  const [date, setDate] = useState({});

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(sosData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = { skip, take: dataLimit, search, status, type };
    fetchSOS(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take, search, status, type };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchSOS(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      skip,
      status,
      type,
    };
    SetUrlWithParams();
    fetchSOS(queryParams);
  };
  useEffect(() => {
    if (sosData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(sosData?.total / take));
    }
  }, [sosData]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchSOS(parsedUrlParams);
  }, [fetchSOS]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      status,
      type,
      skip: pageNumber,
      take,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        // start: date.start,
        // end: date.end,
        status,
        type,
        skip,
        take,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }

    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    if (parsedUrlParams?.status) {
      setStatus(parsedUrlParams?.status);
    }
    if (parsedUrlParams?.type) {
      setStatus(parsedUrlParams?.type);
    }
    return parsedUrlParams;
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          status,
          type,
        };
        fetchSOS(queryParams);
      }, 2000);
    }
  }, [fetchSOS, message]);

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    if (values.type === 'ALL') {
      setType('');
    } else {
      setType(values.type);
    }
    if (values.Status !== 'ALL') {
      setStatus(values.Status);
    } else {
      setStatus('');
    }
    setMenu(false);
    let queryParams = {
      search,
      take,
      skip,
      status: values.Status === 'ALL' ? '' : values.Status,
      type: values.type === 'ALL' ? '' : values.type,
      start: values.startDate,
      end: values.endDate,
    };
    SetUrlWithParams();
    fetchSOS(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    });
    setStatus('');
    setType('');
    let queryParams = {
      search,
      take,
      skip,
      status: '',
      type: '',
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    };
    fetchSOS(queryParams);
  };
  let accessChecking = ReadCheck(accessControl, 'Emergency', 'SOS Info');
  const tableDataHandler = () => {
    let rowData = sosData?.result?.map((data, i) => {
      return {
        keyId: data?.id,
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,
        id: (

          <>
            {accessChecking ?

              <Link to={`#`} className='mr-3 text-dark font-weight-bold'>
                {data?.id.substring(0, 7)}
              </Link>

              :
              <div className='text-dark font-weight-bold'>
                {data?.id?.substring(0, 7)}
              </div>
            }
          </>
        ),
        event: (

          <>
            {accessChecking ?
              <Link
                to='#'
                onClick={() => previewAction(data?.id)}
                className='text-dark font-weight-bold text-Upper'
              >
                <div>{data?.type}</div>
              </Link>

              :
              <div className='text-dark font-weight-bold text-Upper'>
                <div>{data?.type}</div>
              </div>
            }
          </>
        ),
        driver:
          data?.driver !== null ? (
            <>
              {accessChecking ?
                <Link to={`/user/${data?.driver?.id}`}>
                  <div className='d-flex align-items-center text-dark'>
                    <div className='mr-2'>
                      <img
                        src={
                          data?.driver?.picture ? data?.driver?.picture : avatarImg
                        }
                        alt=''
                        height='20'
                        className='rounded-circle avatar-xs'
                      />
                    </div>
                    <div>
                      <div className='text-capitalize'>
                        {data?.driver.firstName} {data?.driver.lastName}
                      </div>
                      <span className='text-muted ml-2 badge badge-soft-secondary'>
                        <i className=' fas fa-star text-warning mr-1' />
                        {data?.driver?.rating.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </Link>

                :
                <div className='text-dark font-weight-bold'>
                  <div className='d-flex align-items-center text-dark'>
                    <div className='mr-2'>
                      <img
                        src={
                          data?.driver?.picture ? data?.driver?.picture : avatarImg
                        }
                        alt=''
                        height='20'
                        className='rounded-circle avatar-xs'
                      />
                    </div>
                    <div>
                      <div className='text-capitalize'>
                        {data?.driver.firstName} {data?.driver.lastName}
                      </div>
                      <span className='text-muted ml-2 badge badge-soft-secondary'>
                        <i className=' fas fa-star text-warning mr-1' />
                        {data?.driver?.rating.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              }
            </>
          ) : (
            '____'
          ),
        rider:
          data?.rider !== null ? (
            <>
              {accessChecking ?
                <Link to={`/user/${data?.rider?.id}`}>
                  <div className='d-flex align-items-center text-dark'>
                    <div className='mr-2'>
                      <img
                        src={
                          data?.rider?.picture ? data?.rider?.picture : avatarImg
                        }
                        alt=''
                        height='20'
                        className='rounded-circle avatar-xs'
                      />
                    </div>
                    <div>
                      <div className='text-capitalize'>
                        {data?.rider.firstName} {data?.rider.lastName}
                      </div>
                      <span className='text-muted ml-2 badge badge-soft-secondary'>
                        <i className=' fas fa-star text-warning mr-1' />
                        {data?.rider?.rating.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </Link>
                :
                <div className='text-dark font-weight-bold'>
                  <div className='d-flex align-items-center text-dark'>
                    <div className='mr-2'>
                      <img
                        src={
                          data?.rider?.picture ? data?.rider?.picture : avatarImg
                        }
                        alt=''
                        height='20'
                        className='rounded-circle avatar-xs'
                      />
                    </div>
                    <div>
                      <div className='text-capitalize'>
                        {data?.rider.firstName} {data?.rider.lastName}
                      </div>
                      <span className='text-muted ml-2 badge badge-soft-secondary'>
                        <i className=' fas fa-star text-warning mr-1' />
                        {data?.rider?.rating.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              }
            </>
          ) : (
            '____'
          ),
        address: data?.address ? (
          <span className=' d-block mb-1  text-truncate'>
            <span className='badge badge-soft-secondary'>{data?.address}</span>
          </span>
        ) : (
          '____'
        ),
        location: data?.location?.lat ? (
          <span style={{ maxWidth: '300px' }}>
            <span className=' d-block mb-1  text-truncate'>
              <span className='badge badge-soft-secondary'>
                Lat : {data?.location?.lat}
              </span>
            </span>
            <span className=' d-block  text-truncate'>
              <span className='badge badge-soft-secondary'>
                Lng : {data?.location?.lng}
              </span>
            </span>
          </span>
        ) : (
          '____'
        ),
        status: (
          <h6
            className={`badge badge-soft-${data?.status === 'PENDING'
              ? 'warning'
              : data?.status === 'CLOSED'
                ? 'success'
                : 'secondary'
              } font-size-12`}
          >
            {data?.status}
          </h6>
        ),
        date: (
          <div>
            <div>{Moment(data?.createdAt).format('l')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data?.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
        action: (
          <div className='d-flex align-items-center'>
            <CheckAction field='Emergency' subModule='SOS Info' iconType='Edit'>
              <Link
                to='#'
                onClick={() => statusIdHandler(data.id, data.status)}
                className='text-primary'
                id='edit'
              >
                <i className='mdi mdi-pencil font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='edit'>
                Change status
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field='Emergency' subModule='SOS Info' iconType='Read'>
              <Link
                to={`/ride/${data.rideId}`}
                className='ml-4 text-primary'
                id='ride'
              >
                <i className='mdi mdi-car-connected font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='ride'>
                View Ride
              </UncontrolledTooltip>
            </CheckAction>
          </div>
        ),
      };
    });

    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
        },
        // {
        //   text: 'User Id',
        //   dataField: 'id',
        // },
        {
          text: 'Event',
          dataField: 'event',
          sort: true,
        },
        {
          text: 'Driver',
          dataField: 'driver',
        },
        {
          text: 'Rider',
          dataField: 'rider',
        },
        {
          text: 'Address',
          dataField: 'address',
        },
        {
          text: 'Coordinate',
          dataField: 'location',
        },

        {
          text: 'Date',
          dataField: 'date',
        },
        {
          text: 'Status',
          dataField: 'status',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {tableError !== null ? (
                <Alert color='danger' className='text-center'>
                  {tableError}
                </Alert>
              ) : (
                <>
                  <div className='d-flex justify-content-end mb-2'>
                    <div>
                      <FilterDropDown
                        menu={menu}
                        toggleMenu={() => setMenu(!menu)}
                      >
                        <div>
                          <AvForm
                            className='form-horizontal'
                            onValidSubmit={handleDateFilter}
                          >
                            <SimpleBar
                              style={{
                                maxHeight: '250px',
                              }}
                            >
                              <Row className='px-3  pt-3 pb-0'>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='startDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='startDate'
                                      value={date.start}
                                      helpMessage='Start Date'
                                      placeholder='Please choose the start date...'
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='endDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='endDate'
                                      value={date.end}
                                      helpMessage='End Date'
                                      placeholder='Please choose the end date...'
                                    />
                                  </FormGroup>
                                </Col>{' '}
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      type='select'
                                      value={type}
                                      name='type'
                                      helpMessage='Event'
                                    >
                                      <option>ALL</option>
                                      <option>ACCIDENT</option>
                                      <option>ASSAULT</option>
                                      <option>ROBBERY</option>
                                      <option>OTHERS</option>
                                    </AvField>
                                  </FormGroup>
                                </Col>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      type='select'
                                      value={status}
                                      name='Status'
                                      helpMessage='status'
                                    >
                                      <option>ALL</option>
                                      <option value='PENDING'>PENDING</option>
                                      <option value='REVIEW'>REVIEW</option>
                                      <option value='CLOSED'>CLOSED</option>
                                    </AvField>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </SimpleBar>

                            <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                              <Link
                                to='#'
                                onClick={resetHandler}
                                className='btn btn-sm  bg-light font-size-14'
                              >
                                <i className=' mdi mdi-refresh mr-1'> Reset</i>
                              </Link>

                              <button
                                className='btn btn-success btn-sm mr-2'
                                type='submit'
                              >
                                <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                Send
                              </button>
                            </div>
                          </AvForm>
                        </div>
                      </FilterDropDown>
                    </div>
                  </div>
                  {!isLoading && data !== null ? (
                    <>
                      <div className='d-flex justify-content-between mb-2'>
                        <div>
                          <Input
                            bsSize='sm'
                            id='take'
                            name='take'
                            type='select'
                            onChange={handleLimitChange}
                            value={take}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div>
                          <Input
                            bsSize='sm'
                            id='search'
                            value={search}
                            name='search'
                            onChange={SearchHandler}
                            placeholder='Search'
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField='keyId'
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses='table-responsive'
                        noDataIndication={'No Data to Display'}
                        defaultSorted={[{ dataField: 'index', order: 'desc' }]}
                      // sort={{ dataField: 'name', order: 'asc' }}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        // breakLabel={'...'}
                        // marginPagesDisplayed={2}
                        // pageRangeDisplayed={3}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        forcePage={skipPage}
                        //Bootstrap styling
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SOSTable;
