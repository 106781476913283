import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Col, Card, CardBody, Media } from 'reactstrap';
import { connect } from 'react-redux';
import { driverLogsSummary } from '../../../../../store/actions';

const MiniWidgets = ({
  driverLogsSummaryError,
  driverLogsSummaryCounts,
  driverLogsSummary,
  defaultIcon,
}) => {
  useEffect(() => {
    driverLogsSummary({ latest: 'latest' });
  }, [driverLogsSummary]);

  const reports = [
    {
      icon: defaultIcon ? defaultIcon : 'fas fa-users',
      title: 'TOTAL',
      value: `${
        driverLogsSummaryCounts?.result?.total
          ? driverLogsSummaryCounts?.result?.total
          : '0'
      }`,
      color: 'primary',
    },
    {
      icon: 'ri-police-car-fill',
      title: 'AVAILABLE',
      value: `${
        driverLogsSummaryCounts?.result?.available
          ? driverLogsSummaryCounts?.result?.available
          : '0'
      }`,
      color: 'success',
    },
    {
      icon: 'fas fa-exclamation-triangle',
      title: 'UNAVAILABLE',
      value: `${
        driverLogsSummaryCounts?.result?.offline
          ? driverLogsSummaryCounts?.result?.offline
          : '0'
      }`,
      color: 'secondary',
    },
    {
      icon: 'fas fa-ban  ',
      title: 'BUSY',
      value: `${
        driverLogsSummaryCounts?.result?.busy
          ? driverLogsSummaryCounts?.result?.busy
          : '0'
      }`,
      color: 'warning',
      updatedTime: `${driverLogsSummaryCounts?.time}`,
    },
  ];

  return (
    <React.Fragment>
      {driverLogsSummaryError === null &&
        reports.map((report, key) => (
          <Col key={key} md={3}>
            <Card>
              <CardBody>
                <Media>
                  <Media body className='overflow-hidden'>
                    <span
                      className={`text-${report.color} badge badge-soft-${report.color} rounded`}
                    >
                      <i className={report.icon + ' font-size-16'}></i>
                    </span>
                    <p className='text-truncate font-size-14 mt-4 mb-2 '>
                      {report.title}
                    </p>
                    <h2 className='mt-0 mb-0 font-size-'>
                      {driverLogsSummaryCounts === null ? (
                        <Skeleton duration={2} width='20%' />
                      ) : (
                        report.value
                        // 'CSOON..'
                      )}
                    </h2>
                    {driverLogsSummaryCounts?.isCache &&
                      report?.updatedTime && (
                        <p
                          className='font-size-12 text-primary'
                          style={{ position: 'absolute', right: '11px' }}
                        >
                          Updated at: {report?.updatedTime?.split(',')?.pop()}
                        </p>
                      )}
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
        ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { driverLogsSummaryError, driverLogsSummaryCounts } = state.Users;
  return { driverLogsSummaryError, driverLogsSummaryCounts };
};

export default connect(mapStateToProps, { driverLogsSummary })(MiniWidgets);
