export const FETCH_PUSH_NOTIFICATION = 'FETCH_PUSH_NOTIFICATION';
export const FETCH_PUSH_NOTIFICATION_SUCCESSFUL =
  'FETCH_PUSH_NOTIFICATION_SUCCESSFULLY';
export const FETCH_PUSH_NOTIFICATION_FAILED = 'FAILED';

export const UPDATE_PUSH_NOTIFICATION = 'UPDATE_PUSH_NOTIFICATION';
export const UPDATE_PUSH_NOTIFICATION_SUCCESSFUL =
  'UPDATE_PUSH_NOTIFICATION_SUCCESSFULLY';
export const UPDATE_PUSH_NOTIFICATION_FAILED =
  'UPDATE_PUSH_NOTIFICATION_FAILED';

export const CREATE_PUSH_NOTIFICATION = 'CREATE_PUSH_NOTIFICATION';
export const CREATE_PUSH_NOTIFICATION_SUCCESSFUL =
  'CREATE_PUSH_NOTIFICATION_SUCCESSFULLY';
export const CREATE_PUSH_NOTIFICATION_FAILED =
  'CREATE_PUSH_NOTIFICATION_FAILED';

export const DELETE_PUSH_NOTIFICATION = 'DELETE_PUSH_NOTIFICATION';
export const DELETE_PUSH_NOTIFICATION_SUCCESSFUL =
  'DELETE_PUSH_NOTIFICATION_SUCCESSFULLY';
export const DELETE_PUSH_NOTIFICATION_FAILED =
  'DELETE_PUSH_NOTIFICATION_FAILED';

export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';
export const FETCH_NOTIFICATION_SUCCESSFUL = 'FETCH_NOTIFICATION_SUCCESSFULLY';
export const FETCH_NOTIFICATION_FAILED = 'FETCH_NOTIFICATION_FAILED';

export const FETCH_MY_NOTIFICATION = 'FETCH_MY_NOTIFICATION';
export const FETCH_MY_NOTIFICATION_SUCCESSFUL =
  'FETCH_MY_NOTIFICATION_SUCCESSFULLY';
export const FETCH_MY_NOTIFICATION_FAILED = 'FETCH_MY_NOTIFICATION_FAILED';

export const FETCH_EACH_NOTIFICATION = 'FETCH_EACH_NOTIFICATION';
export const FETCH_EACH_NOTIFICATION_SUCCESSFUL =
  'FETCH_EACH_NOTIFICATION_SUCCESSFULLY';
export const FETCH_EACH_NOTIFICATION_FAILED = 'FETCH_EACH_NOTIFICATION_FAILED';

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_SUCCESSFUL =
  'UPDATE_NOTIFICATION_SUCCESSFULLY';
export const UPDATE_NOTIFICATION_FAILED = 'UPDATE_NOTIFICATION_FAILED';

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const CREATE_NOTIFICATION_SUCCESSFUL =
  'CREATE_NOTIFICATION_SUCCESSFULLY';
export const CREATE_NOTIFICATION_FAILED = 'CREATE_NOTIFICATION_FAILED';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESSFUL =
  'DELETE_NOTIFICATION_SUCCESSFULLY';
export const DELETE_NOTIFICATION_FAILED = 'DELETE_NOTIFICATION_FAILED';

export const FPN_SUB = 'FPN_SUB';
export const FPN_SUB_SUCCESSFUL = 'FPN_SUB_SUCCESSFULLY';
export const FPN_SUB_FAILED = 'FPN_SUB_FAILED';

export const FETCH_TEMPLATE = 'FETCH_TEMPLATE';
export const FETCH_TEMPLATE_SUCCESSFUL = 'FETCH_TEMPLATE_SUCCESSFULLY';
export const FETCH_TEMPLATE_FAILED = 'FETCH_TEMPLATE_FAILED';

export const FETCH_EACH_TEMPLATE = 'FETCH_EACH_TEMPLATE';
export const FETCH_EACH_TEMPLATE_SUCCESSFUL =
  'FETCH_EACH_TEMPLATE_SUCCESSFULLY';
export const FETCH_EACH_TEMPLATE_FAILED = 'FETCH_EACH_TEMPLATE_FAILED';

export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const UPDATE_TEMPLATE_SUCCESSFUL = 'UPDATE_TEMPLATE_SUCCESSFULLY';
export const UPDATE_TEMPLATE_FAILED = 'UPDATE_TEMPLATE_FAILED';

export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const CREATE_TEMPLATE_SUCCESSFUL = 'CREATE_TEMPLATE_SUCCESSFULLY';
export const CREATE_TEMPLATE_FAILED = 'CREATE_TEMPLATE_FAILED';

export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const DELETE_TEMPLATE_SUCCESSFUL = 'DELETE_TEMPLATE_SUCCESSFULLY';
export const DELETE_TEMPLATE_FAILED = 'DELETE_TEMPLATE_FAILED';

export const FETCH_BETA_EMAILS = 'FETCH_BETA_EMAILS';
export const FETCH_BETA_EMAILS_SUCCESSFUL = 'FETCH_BETA_EMAILS_SUCCESSFULLY';
export const FETCH_BETA_EMAILS_FAILED = 'FETCH_BETA_EMAILS_FAILED';

export const FETCH_EMAILS_FOR_CSV = 'FETCH_EMAILS_FOR_CSV';
export const FETCH_EMAILS_FOR_CSV_ERROR = 'FETCH_EMAILS_FOR_CSV_ERROR';
export const FETCH_EMAILS_FOR_CSV_SUCCESSFULLY =
  'FETCH_EMAILS_FOR_CSV_SUCCESSFULLY';

export const FETCH_NOTIFICATION_LOG = 'FETCH_NOTIFICATION_LOG';
export const FETCH_NOTIFICATION_LOG_SUCCESSFUL =
  'FETCH_NOTIFICATION_LOG_SUCCESSFULLY';
export const FETCH_NOTIFICATION_LOG_FAILED = 'FETCH_NOTIFICATION_LOG_FAILED';

export const FETCH_EACH_NOTIFICATION_LOG = 'FETCH_EACH_NOTIFICATION_LOG';
export const FETCH_EACH_NOTIFICATION_LOG_SUCCESSFUL =
  'FETCH_EACH_NOTIFICATION_LOG_SUCCESSFULLY';
export const FETCH_EACH_NOTIFICATION_LOG_FAILED =
  'FETCH_EACH_NOTIFICATION_LOG_FAILED';
