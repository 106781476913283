/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { Alert, Row, Col, Button, FormGroup, Label } from 'reactstrap';
import Loader from '../../../../components/Common/Loading/index.js';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {
  fetchBank,
  createCashoutFailed,
  createCashout,
} from '../../../../store/actions.js';
import { Link } from 'react-router-dom';

const BankStatus = ({
  user,
  bank,
  loading,
  fetchBank,
  cashoutError,
  createCashoutFailed,
  createCashout,
  message,
  statusHandler,
}) => {
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    if (user) {
      let data = { user: user.id };
      fetchBank(data);
    }
  }, []);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        createCashoutFailed('');
        statusHandler();
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    createCashoutFailed('');
  }, []);

  const handleSubmit = (event, values) => {
    const cashoutData = {
      ...values,
      user: user.id,
      type: 'BONUS',
    };
    createCashout(cashoutData);
  };
  console.log(bank?.result[0]);
  return (
    <React.Fragment>
      {bank?.result[0] ? (
        <>
          <div>
            {message && (
              <Alert color='success' className='text-center'>
                {message}
              </Alert>
            )}
            {cashoutError && (
              <Alert color='danger' className='text-center'>
                {cashoutError}
              </Alert>
            )}

            <div className='d-flex justify-content-between'>
              <h4 className='card-title'>BANK DETAILS</h4>
              <h1
                className={`badge badge-soft-${
                  bank?.result[0]?.status.toLowerCase() === 'active'
                    ? 'success'
                    : bank?.result[0]?.status.toLowerCase() === 'inactive'
                    ? 'danger'
                    : bank?.result[0]?.status.toLowerCase() === 'sanction'
                    ? 'warning'
                    : 'dark'
                } font-size-12`}
              >
                {bank?.result[0]?.status ? bank?.result[0]?.status : '---'}
              </h1>
            </div>

            <div className='card shadow-lg pt-2 bg-soft-secondary'>
              <div className='d-flex mb-2'>
                <div className='col-6 font-size-14'> Account Name: </div>
                <div className='col-6 font-size-14'>
                  {' '}
                  {bank?.result[0]?.accountName}
                </div>
              </div>
              <div className='d-flex  mb-2'>
                <div className='col-6 font-size-14'> Account Number: </div>
                <div className='col-6 font-size-14'>
                  {' '}
                  {bank?.result[0]?.accountNumber}
                </div>
              </div>
            </div>
          </div>
          {bank?.result[0]?.status !== 'ACTIVE' ||
          bank?.result[0]?.status !== 'REVIEW' ? (
            <div>
              <AvForm className='form-horizontal' onValidSubmit={handleSubmit}>
                <Row>
                  <div className='d-flex align-items-center w-100 mb-2'>
                    <Col md={9} className='pr-0'>
                      <FormGroup className=' mb-4'>
                        <Label htmlFor='amount'>Amount</Label>

                        <AvField
                          name='amount'
                          type='number'
                          className='form-control bg-light'
                          required
                          errorMessage='please enter amount to cashout'
                          placeholder='Enter amount'
                        />
                      </FormGroup>
                    </Col>
                  </div>
                </Row>

                <button className='btn btn-success mr-2' type='submit'>
                  {loading ? 'Submitting ...' : 'Submit'}
                </button>
                <Link to='#' onClick={() => statusHandler()}>
                  <button className='btn btn-secondary'>Close</button>
                </Link>
              </AvForm>
            </div>
          ) : (
            <div className='text-center'>
              <h6>This user's bank account is inactive</h6>
            </div>
          )}
        </>
      ) : (
        <div className='text-center'>
          <h6>This user does not have a registered bank account </h6>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { bank, loading, bankRecipient } = state.Bank;
  const { message, cashoutError } = state.Cashout;
  return { message, cashoutError, bank, loading, bankRecipient };
};

export default connect(mapStateToProps, {
  createCashout,
  createCashoutFailed,
  fetchBank,
})(BankStatus);
