import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Moment from 'moment';
import moment from 'moment-timezone';
import DropdownLink from '../../../components/Common/FilterDropDown/DashboardDropdown';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import AccessDashboard from '../../../components/Common/AccessView/dashboard';
import { connect } from 'react-redux';
import { fetchMyRide, fetchRides } from '../../../store/actions';

//Import Components
import MiniWidgets from './MiniWidgets';
import RevenueAnalytics from './RevenueAnalytics';
import TripsAnalytics from './TripsAnalytics';
import EarningReports from './EarningReports';
import Ratings from './Ratings';
import RecentlyComplaints from './RecentComplaints';
import RevenueByLocations from './RevenueByLocations';
import LatestRides from './LatestRides';

const Dashboard = ({ fetchMyRide, fetchRides, user, accessControl }) => {
  const [date, setDate] = useState({
    start: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });
  const breadcrumbItems = [
    { title: 'nRyde', link: '#' },
    { title: 'Dashboard', link: '#' },
  ];


  if (
    accessControl?.result[0]?.data?.modules['Dashboard'].subscribe === 'GREY'
  ) {
    return <AccessDashboard />;
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
          <div className="d-flex justify-content-end">
            <div className="">
              <DropdownLink setDate={setDate} date={date} />
            </div>
          </div>
          <Row>
            <Col sm={12}>
              {' '}
              <Row>
                <MiniWidgets user={user} start={date.start} end={date.end} />{' '}
              </Row>
            </Col>
            <Col xl={8}>
              {/* revenue Analytics */}
              <RevenueAnalytics user={user} start={date.start} end={date.end} />
            </Col>

            <Col xl={4}>
              {/* sales Analytics */}
              <TripsAnalytics user={user} start={date.start} end={date.end} />

              {/* earning reports */}
              <EarningReports user={user} start={date.start} end={date.end} />
            </Col>
          </Row>

          <Row>
            {/* sources */}
            <Ratings user={user} />

            {/* recent activity */}
            <RecentlyComplaints />

            {/* revenue by locations */}
            <RevenueByLocations />
          </Row>

          <Row>
            <Col sm={12}>
              {user.userType === 'ADMIN' ? (
                <LatestRides
                  fetchRidesHandler={fetchRides}
                  start={date.start}
                  end={date.end}
                />
              ) : (
                <LatestRides
                  fetchRidesHandler={fetchMyRide}
                  start={date.start}
                  end={date.end}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { user, accessControl } = state.Account;
  return { user, accessControl };
};

export default connect(mapStateToProps, {
  fetchMyRide,
  fetchRides,
})(Dashboard);
