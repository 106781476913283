import {
  FETCH_ROLE,
  FETCH_ROLE_SUCCESSFUL,
  FETCH_ROLE_API_FAILED,
  FETCH_EACH_ROLE,
  FETCH_EACH_ROLE_SUCCESSFUL,
  FETCH_EACH_ROLE_API_FAILED,
  FETCH_PRIVILEGES,
  FETCH_PRIVILEGES_SUCCESSFUL,
  FETCH_PRIVILEGES_API_FAILED,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESSFUL,
  DELETE_ROLE_FAILED,
  CREATE_ROLE,
  CREATE_ROLE_SUCCESSFUL,
  CREATE_ROLE_FAILED,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESSFUL,
  UPDATE_ROLE_FAILED,
  ASSIGN_ROLE,
  ASSIGN_ROLE_SUCCESSFUL,
  ASSIGN_ROLE_FAILED,
  UNASSIGN_ROLE,
  UNASSIGN_ROLE_SUCCESSFUL,
  UNASSIGN_ROLE_FAILED,
} from './actionTypes';

const initialState = {
  roles: null,
  roleError: null,
  tableError: null,
  privileges: null,
  isAuthorize: null,
  message: null,
  loading: false,
  role: null,
  assignedRole: null,
};

const Role = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROLE:
    case UPDATE_ROLE:
    case DELETE_ROLE:
      state = {
        ...state,
        roles: null,
        tableError: null,
        roleError: null,
        loading: true,
        message: null,
        isAuthorize: null,
      };
      break;

    case ASSIGN_ROLE:
    case UNASSIGN_ROLE:
      state = {
        ...state,
        assignedRole: null,
        roleError: null,
      };
      break;

    case FETCH_ROLE_SUCCESSFUL:
      state = {
        ...state,
        roles: action.payload,
        role: null,
        tableError: null,
        roleError: null,
        loading: false,
      };
      break;

    case DELETE_ROLE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        roleError: null,
        message: 'Role deleted successfully',
      };
      break;
    case UPDATE_ROLE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        roleError: null,
        message: 'Role Updated Successfully',
      };
      break;

    case UNASSIGN_ROLE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        roleError: null,
        message: 'Role unassigned successfully',
      };
      break;
    case ASSIGN_ROLE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        roleError: null,
        message: 'Role Assigned Successfully',
      };
      break;

    case FETCH_PRIVILEGES:
      state = {
        ...state,
        loading: true,
        privileges: null,
      };
      break;

    case FETCH_PRIVILEGES_SUCCESSFUL:
      state = {
        ...state,
        privileges: action.payload,
        loading: false,
        roleError: null,
        message: null,
      };
      break;
    case FETCH_EACH_ROLE:
      state = {
        ...state,
        loading: true,
        role: null,
        roleError: null,
        message: null,
        isAuthorize: null,
      };
      break;

    case FETCH_EACH_ROLE_SUCCESSFUL:
      state = {
        ...state,
        role: action.payload,
        loading: false,
        roleError: null,
        message: null,
      };
      break;

    case CREATE_ROLE:
      state = {
        ...state,
        loading: true,
        roleError: null,
        message: null,
      };
      break;

    case CREATE_ROLE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        roleError: null,
        message: `${action.payload.name} role created successfully`,
      };
      break;

    case FETCH_ROLE_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case CREATE_ROLE_FAILED:
    case DELETE_ROLE_FAILED:
    case FETCH_PRIVILEGES_API_FAILED:
    case FETCH_EACH_ROLE_API_FAILED:
      state = {
        ...state,
        role: null,
        roles: null,
        privileges: null,
        loading: false,
        message: null,
        assignedRole: null,
        roleError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case ASSIGN_ROLE_FAILED:
    case UNASSIGN_ROLE_FAILED:
    case UPDATE_ROLE_FAILED:
      state = {
        ...state,
        loading: false,
        roleError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
        assignedRole: null,
        role: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Role;
