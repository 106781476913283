import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Label,
  Row,
  FormGroup,
  Col,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'moment';
import moment from 'moment-timezone';
import SimpleBar from 'simplebar-react';
import ReactPaginate from 'react-paginate';
import FilterDropDown from '../../../components/Common/FilterDropDown';
import Loader from '../../../components/Common/Loading/index.js';
// import BankLogo from './Components/bankLogo'
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../utils/general';
import queryString from 'query-string';

let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const PaymentTable = ({
  paymentData,
  previewAction,
  fetchPayment,
  message,
  history,
  dateFilter,
  fetchPaymentCSVError,
  fetchPaymentCSV,
  csvData,
  csv_loading,
  tableError,
  CheckAction,
  ReadCheck,
  accessControl
}) => {
  const [csv, setCsvData] = useState([]);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [data, setData] = useState(null);
  const [skipPage, setSkipPage] = useState(0);
  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [classification, setClassification] = useState('');
  const [type, setType] = useState('');
  const [source, setSource] = useState('');
  const [search, setSearch] = useState('');
  const [date, setDate] = useState({
    startDate: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    endDate: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(paymentData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      classification,
      search,
    };
    fetchPayment(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take, classification, search };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchPayment(pageInfo);
    ScrollToTop();
  };

  const handleDateFilter = (e, values) => {
    setDate({ startDate: values.startDate, endDate: values.endDate });
    dateFilter({ startDate: values.startDate, endDate: values.endDate });
    setClassification(values.Classification);
    setType(values.Type);
    setSource(values.Source);
    setMenu(false);
    let queryParams = {
      search,
      take,
      skip,
      classification: values.Classification,
      type: values.Type,
      source: values.Source,
      startDate: values.startDate,
      endDate: values.endDate,
    };
    fetchPayment(queryParams);
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      type,
      source,
      classification,
    };
    fetchPayment(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setClassification('');
    setSearch('');
    setDate({
      startDate: '',
      endDate: '',
    });
    dateFilter({});

    fetchPayment();
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          classification,
          type,
          source,
        };
        fetchPayment(queryParams);
      }, 2000);
    }
  }, [message]);

  const handleCSV = (e, values) => {
    fetchPaymentCSV({
      take: values.endRow
        ? values.endRow - values.startRow + 1
        : paymentData?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
    });
  };

  useEffect(() => {
    if (paymentData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(paymentData?.total / take));
    }
  }, [paymentData]);

  useEffect(() => {
    if (csvData) {
      // SET CSV EXPORT DATA
      const csv = csvData?.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Account_Owner: `${data.accountUser.firstName} - ${data.accountUser.lastName}`,
          Amount: data.amount ? `₦${data.amount}` : '----',
          Type: data?.type ? data?.type : '____',
          Classification: data?.classification ? data?.classification : '____',
          Source: data.source,
          Status: data?.status,
          Balance_Before: `₦${data.balanceBefore}`,
          Balance_After: `₦${data.balanceAfter}`,
          createdAt: data?.createdAt?.substring(0, 10),
        };

        return formData;
      });

      setCsvData(csv);
    }
  }, [csvData]);
  useEffect(() => {
    if (!showCSVModal) {
      fetchPaymentCSVError();
    }
  }, [showCSVModal]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchPayment(parsedUrlParams);
  }, [fetchPayment]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      classification,
      type,
      source,
      skip: pageNumber,
      take,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.status) {
      setClassification(parsedUrlParams?.status);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };
  let accessChecking = ReadCheck(accessControl, `Finance`, 'Payments');
  const tableDataHandler = () => {
    const rowData =
      paymentData &&
      paymentData?.result?.map((data, i) => {
        return {
          keyId: data.id,
          index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,

          id: (
            <>
              {accessChecking ?


                <Link
                  to='#'
                  onClick={() => previewAction(data.id)}
                  className='text-dark font-weight-bold'
                >
                  {data.id.substring(0, 7)}
                </Link>

                :
                <div className='text-dark font-weight-bold'>
                  {data?.id?.substring(0, 7)}
                </div>
              }
            </>
          ),

          userName: (

            <>
              {accessChecking ?

                <Link
                  to={`/user/${data.accountUser.id}`}
                  className='mr-3 text-dark'
                >
                  <div className='text-capitalize'>
                    {data.accountUser.firstName} {data.accountUser.lastName}
                  </div>
                  <div className='d-flex align-items-center'>
                    <div className='mr-2'>
                      <img
                        src={
                          data.accountUser?.picture
                            ? data.accountUser?.picture
                            : avatarImg
                        }
                        alt=''
                        height='20'
                        className='rounded-circle avatar-xs'
                      />
                    </div>
                    <div>
                      <span
                        className={`badge badge-${data?.accountUser?.userType === 'RIDER'
                          ? 'primary'
                          : data?.accountUser?.userType === 'DRIVER'
                            ? 'secondary'
                            : data?.accountUser?.userType === 'ADMIN'
                              ? 'success'
                              : 'warning'
                          }`}
                      >
                        {data?.accountUser?.userType}
                      </span>
                    </div>
                  </div>
                </Link>

                :
                <div className='mr-3 text-dark font-weight-bold'>
                  <div className='text-capitalize'>
                    {data.accountUser.firstName} {data.accountUser.lastName}
                  </div>
                  <div className='d-flex align-items-center'>
                    <div className='mr-2'>
                      <img
                        src={
                          data.accountUser?.picture
                            ? data.accountUser?.picture
                            : avatarImg
                        }
                        alt=''
                        height='20'
                        className='rounded-circle avatar-xs'
                      />
                    </div>
                    <div>
                      <span
                        className={`badge badge-${data?.accountUser?.userType === 'RIDER'
                          ? 'primary'
                          : data?.accountUser?.userType === 'DRIVER'
                            ? 'secondary'
                            : data?.accountUser?.userType === 'ADMIN'
                              ? 'success'
                              : 'warning'
                          }`}
                      >
                        {data?.accountUser?.userType}
                      </span>
                    </div>
                  </div>
                </div>
              }
            </>
          ),
          amount: (
            <div>
              <div className='d-flex align-items-center'>
                <div className='activity-icon avatar-xs mr-1'>
                  <span
                    className={`avatar-title bg-soft-success text-${data?.type === 'CREDIT' ? 'success' : 'danger'
                      } rounded-circle mr-2`}
                  >
                    <i
                      className={`fas fa-arrow-alt-circle-${data?.type === 'CREDIT' ? 'right' : 'left'
                        }`}
                    ></i>
                  </span>
                </div>
                ₦{data.amount?.toLocaleString('en-US')}
              </div>
              <div>
                <span
                  className={`badge badge-${data?.classification === 'CASH'
                    ? 'primary'
                    : data?.classification === 'BONUS'
                      ? 'warning'
                      : data?.classification === 'CASH'
                        ? 'success'
                        : 'secondary'
                    }`}
                >
                  {data?.classification}
                </span>
              </div>
            </div>
          ),
          source: (
            <div
              className={`badge badge-${data?.source === 'ADMIN'
                ? 'primary'
                : data?.source === 'RIDER'
                  ? 'warning'
                  : data?.source === 'DRIVER'
                    ? 'success'
                    : 'secondary'
                }`}
            >
              {data.source}
            </div>
          ),

          balance: (
            <div>
              <div className='d-flex align-items-center'>
                <span className={`text-warning rounded-circle mr-1`}>
                  <i className=' ri-creative-commons-sa-fill font-size-20' />
                </span>{' '}
                <span>₦{data.balanceBefore?.toLocaleString('en-US')}</span>
              </div>
              <div className='d-flex align-items-center'>
                <span className={`text-success rounded-circle mr-1`}>
                  <i className=' ri-record-circle-fill font-size-20' />
                </span>{' '}
                <span>₦{data.balanceAfter?.toLocaleString('en-US')}</span>
              </div>
            </div>
          ),
          createdAt: (
            <div>
              <div>{Moment(data.createdAt).format('l')}</div>
              <div className='badge badge-soft-secondary font-size-10'>
                {' '}
                {Moment(data.createdAt).format('hh:mm A')}
              </div>
            </div>
          ),
          action: (
            <div className='d-flex align-items-center'>
              <CheckAction field='Finance' subModule='Payments' iconType='Delete'>
                <Link
                  to='#'
                  // onClick={() => deleteBank(data.id)}
                  className='mr-3 text-danger'
                  id='delete1'
                >
                  <i className='mdi mdi-trash-can font-size-16'></i>
                </Link>
                <UncontrolledTooltip placement='top' target='delete1'>
                  Delete
                </UncontrolledTooltip>
              </CheckAction>
              <CheckAction field='Finance' subModule='Payments' iconType='Edit'>
                <Link
                  to='#'
                  className='mr-3 text-success'
                  id='activate'
                // onClick={() => statusHandler(data)}
                >
                  <i className='ri-edit-box-line font-size-16 text-success mb-2'></i>
                </Link>
                <UncontrolledTooltip placement='top' target='activate'>
                  Status
                </UncontrolledTooltip>
              </CheckAction>
            </div>
          ),
        };
      });
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
        },
        {
          text: 'Transaction Id',
          dataField: 'id',
        },
        {
          text: 'Owner',
          dataField: 'userName',
        },
        {
          text: 'Amount',
          dataField: 'amount',
        },
        {
          text: 'Balance Before/After',
          dataField: 'balance',
        },
        {
          text: 'CreatedAt',
          dataField: 'createdAt',
        },
        {
          text: 'Source',
          dataField: 'source',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {tableError === null ? (
                <>
                  <div className='d-flex justify-content-end align-items-center mb-2'>
                    <div>
                      <Button
                        size='sm'
                        color='primary'
                        className='mt-1'
                        onClick={() => setShowCSVModal(true)}
                        disabled={paymentData?.total > 0 ? false : true}
                      >
                        <i className=' fas fa-cloud-download-alt mr-1' />
                        CSV
                      </Button>
                    </div>
                    <div>
                      <FilterDropDown
                        menu={menu}
                        toggleMenu={() => setMenu(!menu)}
                      >
                        <div>
                          <AvForm
                            className='form-horizontal'
                            onValidSubmit={handleDateFilter}
                          >
                            <SimpleBar
                              style={{
                                maxHeight: '250px',
                              }}
                            >
                              <Row className='px-3  pt-3 pb-0'>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='startDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='startDate'
                                      value={date.start}
                                      helpMessage='Start Date'
                                      placeholder='Please choose the start date...'
                                    // required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='endDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='endDate'
                                      value={date.end}
                                      helpMessage='End Date'
                                      placeholder='Please choose the end date...'
                                    // required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      type='select'
                                      value={classification}
                                      helpMessage='Classification'
                                      name='Classification'
                                    >
                                      <option value=''>All</option>
                                      <option value='BONUS'>Bonus</option>
                                      <option value='CASH'>Cash</option>
                                      <option value='CASH_COLLECTED'>
                                        Cash Collected
                                      </option>
                                    </AvField>
                                  </FormGroup>
                                </Col>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      type='select'
                                      value={type}
                                      helpMessage='Type'
                                      name='Type'
                                    >
                                      <option value=''>All</option>
                                      <option value='CREDIT'>Credit</option>
                                      <option value='DEBIT'>Debit</option>
                                    </AvField>
                                  </FormGroup>
                                </Col>

                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      type='select'
                                      value={source}
                                      helpMessage='Source'
                                      name='Source'
                                    >
                                      <option value=''>ALL</option>
                                      <option value='RIDE'>RIDE</option>
                                      <option value='ADMIN'>ADMIN</option>
                                    </AvField>
                                  </FormGroup>
                                </Col>
                              </Row>

                              <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                                <Link
                                  to='#'
                                  onClick={resetHandler}
                                  className='btn btn-sm  bg-light font-size-14'
                                >
                                  <i className=' mdi mdi-refresh mr-1'>
                                    {' '}
                                    Reset
                                  </i>
                                </Link>

                                <button
                                  className='btn btn-success btn-sm mr-2'
                                  type='submit'
                                >
                                  <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                  Send
                                </button>
                              </div>
                            </SimpleBar>
                          </AvForm>
                        </div>
                      </FilterDropDown>
                    </div>
                  </div>
                  {!isLoading && data !== null ? (
                    <>
                      <div className='d-flex justify-content-between mb-2'>
                        <div>
                          <Input
                            bsSize='sm'
                            id='take'
                            name='take'
                            type='select'
                            onChange={handleLimitChange}
                            value={take}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div>
                          <Input
                            bsSize='sm'
                            id='search'
                            name='search'
                            onChange={SearchHandler}
                            placeholder='Search'
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField='keyId'
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses='table-responsive'
                        noDataIndication={'No Data to Display'}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        // breakLabel={'...'}
                        // marginPagesDisplayed={2}
                        // pageRangeDisplayed={3}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        forcePage={skipPage}
                        //Bootstrap styling
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              ) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* CSV DOWNLOAD MODAL */}
      <Modal
        isOpen={showCSVModal}
        toggle={() => setShowCSVModal(!showCSVModal)}
      >
        <ModalHeader toggle={() => setShowCSVModal(false)}>
          Generate CSV
        </ModalHeader>
        <ModalBody>
          <AvForm className='form-horizontal' onValidSubmit={handleCSV}>
            <Col>
              <FormGroup>
                <Label htmlFor='startRow'>Start (Row)</Label>
                <AvField
                  name='startRow'
                  type='number'
                  max={paymentData?.total - 1}
                  className='form-control'
                  id='startRow'
                  placeholder='Enter the row to start from'
                  errorMessage='value must be greater than 0 and less than total data'
                  min='1'
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor='endRow'>End (Row)</Label>
                <AvField
                  name='endRow'
                  type='number'
                  className='form-control'
                  min='1'
                  max={paymentData?.total}
                  id='endRow'
                  placeholder='Enter the row to end'
                  helpMessage={
                    paymentData?.total
                      ? `Total data is ${paymentData?.total}`
                      : null
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Button
              size='sm'
              color='primary'
              className='ml-3 waves-effect waves-light '
              type='submit'
            >
              {csv_loading ? 'Generating...' : 'Generate'}
            </Button>
            {csvData && (
              <CSVLink
                data={csv}
                filename={'driver_payment_detail.csv'}
                className='btn-sm btn-success ml-2'
                target='_blank'
                onClick={() => setShowCSVModal(false)}
              >
                Download
              </CSVLink>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default PaymentTable;
