/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Moment from "moment";
import { connect } from "react-redux";
import { Alert, Row, Col, Table, Modal, ModalBody } from "reactstrap";
import Loader from "../../../components/Common/Loading/index.js";
import { fetchEachRoute } from "../../../store/actions.js";
import { Link } from "react-router-dom";

const avatarImg =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

const PreviewRoute = ({
  route,
  previewId,
  loading,
  routeError,
  fetchEachRoute,
}) => {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    fetchEachRoute(previewId);
  }, [fetchEachRoute, previewId]);

  return (
    <React.Fragment>
      <div className="mt-3">
        {routeError && !loading ? (
          <Alert color="danger" className="text-center">
            {routeError}
          </Alert>
        ) : (
          <>
            {route === null ? (
              <Loader />
            ) : (
              <Row>
                <Col sm={12} className="text-center">
                  {route?.image && (
                    <div
                      className=" my-0 mx-auto rounded w-auto"
                      style={{ maxWidth: "fit-content", background: "#F1F8E9" }}
                    >
                      <img
                        src={route?.image}
                        alt="suv"
                        className="avatar-md cursor-pointer"
                        onClick={() => {
                          setShowImage(true);
                        }}
                      />
                    </div>
                  )}
                  {route?.tag && (
                    <span className="badge badge-success text-capitalize">
                      {route?.tag}
                    </span>
                  )}
                  <p className="font-size-12">{route?.description}</p>
                </Col>
                <Col sm={12}>
                  <div>
                    <Table hover>
                      <tbody>
                        <tr>
                          <td className="font-weight-bold">Name </td>
                          <td className="text-uppercase font-size-16 font-weight-bold text-success">
                            {route?.name}
                          </td>
                        </tr>

                        <tr>
                          <td
                            className="font-weight-bold"
                            style={{ width: "27%" }}
                          >
                            Bus Stops
                          </td>
                          <td className="text-uppercase">
                            <div
                              className="text-truncate d-flex align-items-center"
                              style={{ whiteSpace: "normal" }}
                            >
                              <div className="row">
                                {route?.stops
                                  ?.slice()
                                  .sort((a, b) => a.position - b.position)
                                  .map((stop) => (
                                    <div
                                      className="col mb-2"
                                      key={stop?.busStop?.id}
                                    >
                                      <div className="badge badge-secondary">
                                        {stop?.busStop?.name}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Start Date</td>
                          <td className="text-uppercase">
                            <div className="d-flex align-items-end">
                              <div>{Moment(route.startTime)?.format("l")}</div>
                              <div className="badge badge-soft-secondary font-size-10 ml-1">
                                {" "}
                                {Moment(route.startTime)?.format("hh:mm A")}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">End Date</td>
                          <td className="text-uppercase">
                            <div className="d-flex align-items-end">
                              <div>{Moment(route.endTime)?.format("l")}</div>
                              <div className="badge badge-soft-secondary font-size-10 ml-1">
                                {" "}
                                {Moment(route.endTime)?.format("hh:mm A")}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Created At</td>
                          <td className="text-uppercase">
                            {Moment(route.createdAt).format("l")}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Updated At</td>
                          <td className="text-uppercase">
                            {Moment(route.updatedAt).format("l")}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
      <Modal
        isOpen={showImage}
        toggle={() => setShowImage(!showImage)}
        size="lg"
        style={{ left: "5%" }}
        centered={true}
      >
        <ModalBody className="p-0 m-2" style={{ height: "70vh" }}>
          <img
            src={route?.image}
            alt="Route img"
            style={{ width: "-webkit-fill-available", height: "100%" }}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { route, loading, routeError } = state.Route;
  return { route, loading, routeError };
};

export default connect(mapStateToProps, {
  fetchEachRoute,
})(PreviewRoute);
