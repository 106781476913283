import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { Container, Card, CardBody, Col, Row, Alert } from 'reactstrap';
import GreySummaryCard from '../../../components/Common/Summary/index';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import CheckAction, { ReadCheck } from '../../../components/Common/Summary/actionCheck';
import MiniWidgets from './Components/MiniWidgets';
//Import Action to copy breadcrumb items from local state to redux state
import ReviewTable from './reviewTable';
import { fetchDriverReviews } from '../../../store/actions';

const DriverReview = ({
  fetchDriverReviews,
  loading,
  reviews,
  reviewsError,
  message,
  history,
  accessControl
}) => {
  const [dateFilter, setDate] = useState({});
  const breadcrumbItems = [
    { title: 'Driver Review', link: '#' },
    { title: 'Dashboard', link: '#' },
  ];
  const reviewer = true;
  let skip = 0;
  let dataLimit = 10;

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='Driver Review'
            breadcrumbItems={breadcrumbItems}
          />{' '}
          <GreySummaryCard field='Rating & Review' subModule='Driver Review'>
            <Row>
              <Col>
                <Row>
                  <Col sm={12}>
                    <Row>
                      <MiniWidgets date={dateFilter} userType={'DRIVER'} />
                    </Row>
                  </Col>
                </Row>
                {message && (
                  <Alert color='success' className='text-center'>
                    {message}
                  </Alert>
                )}
                {reviewsError ? (
                  <Alert className='text-center' color='danger'>
                    {reviewsError}
                  </Alert>
                ) : (
                  <ReviewTable
                    reviewsData={reviews}
                    loading={loading}
                    fetchDriverReviews={fetchDriverReviews}
                    userType={'DRIVER'}
                    reviewer={reviewer}
                    message={message}
                    history={history}
                    skip={skip}
                    dataLimit={dataLimit}
                    reviewsError={reviewsError}
                    CheckAction={CheckAction}
                    ReadCheck={ReadCheck}
                    accessControl={accessControl}
                    subModule={'Driver Review'}
                  />
                )}
              </Col>
            </Row>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { reviews, loading, reviewsError, message } = state.Review;
  return { accessControl, reviews, loading, reviewsError, message };
};

export default connect(mapStateToProps, { fetchDriverReviews })(DriverReview);
