import React from 'react'

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
} from 'reactstrap'

const FilterDropdown = ({text='Filer', children, menu, toggleMenu }) => {
  return (
    <React.Fragment>
      <UncontrolledDropdown
        isOpen={menu}
        toggle={toggleMenu}
        direction="left"
        className="d-inline-block"
      >
        <DropdownToggle
          tag="button"
          direction="left"
          className="btn btn-lg header-item noti-icon waves-effect"
          style={{ height: '40px' }}
          id="page-header-notifications-dropdown"
        >
          <div className="d-flex align-items-center bg-secondary text-white rounded px-2 py-0 ">
            <span className="font-size-13 mr-2">{text}</span>
            <i className="mdi mdi-filter-variant font-size-18 text-white"></i>
          </div>
        </DropdownToggle>
        <DropdownMenu
          direction="left"
          className="dropdown-menu-sm p-0"
          style={{ width: '200px' }}
          aria-labelledby="page-header-notifications-dropdown"
        >
          {children}
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  )
}
export default FilterDropdown
