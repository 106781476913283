export const FETCH_LOYALTY = 'FETCH_LOYALTY'
export const FETCH_LOYALTY_SUCCESSFUL = 'FETCH_LOYALTY_SUCCESSFUL'
export const FETCH_LOYALTY_API_FAILED = 'FETCH_LOYALTY_API_FAILED'

export const FETCH_MY_LOYALTY = 'FETCH_MY_LOYALTY'
export const FETCH_MY_LOYALTY_SUCCESSFUL = 'FETCH_MY_LOYALTY_SUCCESSFUL'
export const FETCH_MY_LOYALTY_API_FAILED = 'FETCH_MY_LOYALTY_API_FAILED'

export const UPDATE_USER_POINT = 'UPDATE_USER_POINT'
export const UPDATE_USER_POINT_SUCCESSFUL = 'UPDATE_USER_POINT_SUCCESSFULLY'
export const UPDATE_USER_POINT_FAILED = 'FAILED'

// LOYALTY Summary
export const FETCH_LOYALTY_SUMMARY = 'FETCH_LOYALTY_SUMMARY'
export const FETCH_LOYALTY_SUMMARY_FAILED = 'FETCH_LOYALTY_SUMMARY_FAILED'
export const FETCH_LOYALTY_SUMMARY_SUCCESSFULLY =
  'FETCH_BONUS_SUMMARY_SUCCESSFULLY'
