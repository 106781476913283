import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetInvitationService = (query) => {
  const http = new HttpService();
  let url = 'invitation/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }

  return http.getData(url);
};

export const FetchUserInvitationService = (query) => {
  const http = new HttpService();
  let url = 'invitation/users';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }

  return http.getData(url);
};

export const GetEachInvitationService = ({ user, query }) => {
  const http = new HttpService();
  let url = `invitation/${user}/history`;

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const CreateInvitationService = (payload) => {
  const http = new HttpService();
  const url = 'invitation';

  return http.postData(payload, url);
};
export const UpdateInvitationService = ({ payload, id }) => {
  const http = new HttpService();
  const url = `invitation/${id}`;

  return http.putData(payload, url);
};

export const DeleteInvitationService = (id) => {
  const http = new HttpService();
  const url = `invitation/${id}`;
  return http.deleteData(url);
};

export const fetchInvitationSummaryService = (query) => {
  // Payload with query
  const http = new HttpService();
  let url = 'summary/invitation';
  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};
