import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import MiniWidgets from './Components/MiniWidgets.js';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import GreySummaryCard from '../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../components/Common/Summary/actionCheck';
import PromoCodeTable from './promoCodeTable';
import PreviewPromoCode from './previewPromoCode';
// import PromoRedeemTable from './redeemHistory';

import { fetchPromoCode, deletePromoCode } from '../../../store/actions';

const PromoCode = ({
  promoCode,
  fetchPromoCode,
  deletePromoCode,
  tableError,
  loading,
  message,
  history,
  accessControl
}) => {
  const [showModal, setShowModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [dateFilter, setDate] = useState({});
  const breadcrumbItems = [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'Promo Code', link: '#' },
  ];

  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };
  const historyViewer = (e) => {
    setHistoryModal(e);
  };

  const deletePromoCodeModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const deleteHandler = () => {
    setShowDeleteModal(false);
    deletePromoCode(deleteId);
    setDeleteId(null);
  };
  const date = (e) => {
    setDate(e);
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Promo Code' breadcrumbItems={breadcrumbItems} />
          {tableError === null && (
            <div className='mb-2 text-right'>
              <CheckAction
                field='Rides Promotions'
                subModule='Promo Code'
                iconType={'Create'}
              >
                <Link to='/promo_code_form'>
                  <Button color='success'>
                    <i className='mdi mdi-plus-circle-outline font-size-18'></i>{' '}
                    Add Promo Code
                  </Button>
                </Link>
              </CheckAction>
            </div>
          )}
          {message && (
            <Alert color='success' className='text-center'>
              {message}
            </Alert>
          )}
          <Row>
            <Col>
              <GreySummaryCard field='Rides Promotions' subModule='Promo Code'>
                <Row>
                  <MiniWidgets date={dateFilter} />
                </Row>
                <PromoCodeTable
                  promoCodeData={promoCode}
                  tableError={tableError}
                  dateFilter={date}
                  loading
                  deletePromoCode={deletePromoCodeModal}
                  fetchPromoCode={fetchPromoCode}
                  previewAction={previewHandler}
                  history={history}
                  message={message}
                  CheckAction={CheckAction}
                  ReadCheck={ReadCheck}
                  accessControl={accessControl}
                />
              </GreySummaryCard>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
          <ModalHeader toggle={() => setShowModal(false)}>
            Preview Promo Code
          </ModalHeader>
          <ModalBody>
            <PreviewPromoCode
              previewId={previewId}
              historyViewer={historyViewer}
            />
          </ModalBody>
        </Modal>

        {/* <Modal
          isOpen={historyModal}
          toggle={() => setHistoryModal(!historyModal)}
        >
          <ModalHeader toggle={() => setHistoryModal(false)}>
            Redeem History
          </ModalHeader>
          <ModalBody>
            <PromoRedeemTable id={previewId} history={history} />
          </ModalBody>
        </Modal> */}

        <Modal
          isOpen={showDeleteModal}
          toggle={() => setShowDeleteModal(!showDeleteModal)}
        >
          <ModalBody>
            <h4 className='card-title mb-4 text-center'>
              {' '}
              Are you sure you want to delete this Promo Code
            </h4>
            <button
              onClick={() => deleteHandler()}
              className='btn btn-outline-danger btn-sm w-100'
            >
              Click to Delete
            </button>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { promoCode, loading, promoCodeError, message, tableError } =
    state.PromoCode;
  return { accessControl, promoCode, loading, promoCodeError, message, tableError };
};

export default connect(mapStateToProps, { fetchPromoCode, deletePromoCode })(
  PromoCode
);
