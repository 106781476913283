import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import {
  Row,
  Col,
  Container,
  FormGroup,
  Label,
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import GreySummaryCard from '../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../components/Common/Summary/actionCheck';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Location from '../../../components/Common/Location';
import TripsTable from './Tripstable';
import MiniWidgets from './Components/MiniWidgets';
import { Options } from './cancellationOptions';
//Import Action to copy breadcrumb items from local state to redux state
import {
  fetchRides,
  fetchDataForCSV,
  fetchForCSVError,
  rideStatus,
  rideStatusFailed,
  broadCastRide,
  deleteRide,
  fetchRidePriceFailed,
} from '../../../store/actions';

const Rides = ({
  user,
  rides,
  fetchRides,
  fetchDataForCSV,
  fetchForCSVError,
  csv,
  csv_loading,
  loading,
  rideStatus,
  rideStatusFailed,
  broadCastRide,
  fetchRidePriceFailed,
  ridesError,
  message,
  isAuthorize,
  deleteRide,
  accessControl
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [status, setStatus] = useState(null);
  const [Error, setError] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [dateFilter, setDate] = useState({});
  const [selected, setSelected] = useState([]);

  const location = Location();
  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'Rides', link: '#' },
  ];
  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };
  const date = (e) => {
    setDate(e);
  };
  const statusIdHandler = (id, e) => {
    setShowStatusModal(true);
    setPreviewId(id);
    setStatus(e);
  };

  useEffect(() => {
    if (message || ridesError) {
      setTimeout(() => {
        rideStatusFailed('');
        setShowModal(false);
        setShowStatusModal(false);
        fetchRides();
      }, 2000);
    }
    if (!showModal) {
      setSelected([]);
    }
  }, [message, ridesError, showModal, rideStatusFailed, fetchRides]);

  useEffect(() => {
    rideStatusFailed('');
    fetchRidePriceFailed('');
  }, []);

  const handleCancellation = (_event) => {
    setError(false);
    if (!selected.value) {
      setError(true);
      return;
    }
    const rideData = {
      id: previewId,
      reason: selected.value,
      status: 'cancel',
    };
    rideStatus(rideData);
  };
  const handleStatusUpdate = (event, values) => {
    const Status =
      values.Status === 'APPROACHING'
        ? 'approaching'
        : values.Status === 'ACCEPTED'
          ? 'accept'
          : values.Status === 'INROUTE'
            ? 'start'
            : values.Status === 'ARRIVED'
              ? 'arrived'
              : values.Status === 'COMPLETED'
                ? 'end'
                : values.Status;
    if (Status === 'undefined') {
      setStatusError('Please change the status');
      return;
    }
    if (location.loaded) {
      if (location.err) {
        setStatusError(
          location.error.message === 'User denied Geolocation'
            ? 'Please turn on location and relaod the page'
            : 'Location error'
        );
        return;
      } else {
        const rideData = {
          id: previewId,
          lat: location.coordinates.lat,
          lng: location.coordinates.lng,
          status: Status,
        };
        rideStatus(rideData);
      }
    } else {
      setStatusError('Location data not available yet.');
    }
  };

  const deleteRideModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteRide(deleteId);
    setDeleteId(null);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Rides" breadcrumbItems={breadcrumbItems} />

          <GreySummaryCard field="Rides" subModule="History">
            {isAuthorize ? (
              <Alert color="danger" className="text-center">
                {isAuthorize}
              </Alert>
            ) : (
              <>
                <CheckAction field="Rides" subModule="History" iconType={'Create'}>
                  <div className="mb-1 text-right">
                    <Link to="/trip_form">
                      <Button color="success">
                        <i className="mdi mdi-plus-circle-outline font-size-14 mr-2"></i>
                        Create Ride
                      </Button>
                    </Link>
                  </div>
                </CheckAction>
                {message && (
                  <Alert color="success" className="text-center">
                    {message}
                  </Alert>
                )}
                {ridesError && (
                  <Alert color="danger" className="text-center">
                    {ridesError}
                  </Alert>
                )}
                <Row>
                  <Col sm={12}>
                    <Row>
                      <MiniWidgets date={dateFilter} />
                    </Row>
                  </Col>
                  <Col>
                    <TripsTable
                      rides={rides}
                      loading={loading}
                      dateFilter={date}
                      broadCastRide={broadCastRide}
                      fetchRides={fetchRides}
                      fetchDataForCSV={fetchDataForCSV}
                      fetchForCSVError={fetchForCSVError}
                      csv={csv}
                      csv_loading={csv_loading}
                      previewHandler={previewHandler}
                      statusIdHandler={statusIdHandler}
                      deleteRide={deleteRideModal}
                      user={user}
                      ReadCheck={ReadCheck}
                      accessControl={accessControl}
                    />
                  </Col>
                </Row>
                <Modal
                  isOpen={showModal}
                  toggle={() => setShowModal(!showModal)}
                >
                  <ModalHeader toggle={() => setShowModal(false)}>
                    Cancel Ride
                  </ModalHeader>
                  <ModalBody>
                    {message && (
                      <Alert color="success" className="text-center">
                        {message}
                      </Alert>
                    )}
                    {Error && (
                      <Alert className="text-center" color="danger">
                        Please kindly state the reason for cancellation
                      </Alert>
                    )}
                    {ridesError && (
                      <Alert color="danger" className="text-center">
                        {ridesError}
                      </Alert>
                    )}
                    <div>
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={handleCancellation}
                      >
                        <Row>
                          <Col md="10">
                            <FormGroup className="mb-4">
                              <Label htmlFor="reasonForm">
                                Reason For Cancellation
                              </Label>

                              <CreatableSelect
                                options={selected ? Options : []}
                                isClearable
                                value={selected}
                                onChange={setSelected}
                                labelledBy="Selected"
                                isCreatable={true}
                                placeholder="Enter cancellation reason or choose an option..."
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <button className="btn btn-success mr-2" type="submit">
                          {loading ? 'Submitting ...' : 'Submit'}
                        </button>
                        <Link to="#" onClick={() => setShowModal(false)}>
                          <button className="btn btn-secondary">Close</button>
                        </Link>
                      </AvForm>
                    </div>
                  </ModalBody>
                </Modal>

                <Modal
                  isOpen={showStatusModal}
                  toggle={() => setShowModal(!showStatusModal)}
                >
                  <ModalHeader toggle={() => setShowStatusModal(false)}>
                    Change Status
                  </ModalHeader>
                  <ModalBody>
                    {message && (
                      <Alert color="success" className="text-center">
                        {message}
                      </Alert>
                    )}
                    {statusError && (
                      <Alert className="text-center" color="danger">
                        {statusError}
                      </Alert>
                    )}
                    {ridesError && (
                      <Alert color="danger" className="text-center">
                        {ridesError}
                      </Alert>
                    )}
                    <div>
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={handleStatusUpdate}
                      >
                        <Row>
                          <Col md="9">
                            <FormGroup className="mb-4">
                              <AvField
                                className="form-control bg-light"
                                type="select"
                                name="Status"
                                label="Status"
                                value={
                                  status === 'PROCESSING' ||
                                    status === 'CANCELLED'
                                    ? 'undefined'
                                    : status
                                }
                              >
                                <option value={'undefined'}>
                                  Change Status
                                </option>
                                <option value={'ACCEPTED'}>Accept</option>
                                <option value={'APPROACHING'}>
                                  Approaching
                                </option>
                                <option value={'ARRIVED'}>Arrived</option>
                                <option value={'INROUTE'}>Start Trip</option>
                                <option value={'COMPLETED'}>End Trip</option>
                              </AvField>
                            </FormGroup>
                          </Col>
                        </Row>

                        <button className="btn btn-success mr-2" type="submit">
                          {loading ? 'Submitting ...' : 'Submit'}
                        </button>
                        <Link to="#" onClick={() => setShowStatusModal(false)}>
                          <button className="btn btn-secondary">Close</button>
                        </Link>
                      </AvForm>
                    </div>
                  </ModalBody>
                </Modal>
              </>
            )}
          </GreySummaryCard>
        </Container>
        {/* DELETE RIDE MODAL */}
        <Modal
          isOpen={showDeleteModal}
          toggle={() => setShowDeleteModal(!showDeleteModal)}
        >
          <ModalBody>
            <h4 className="card-title mb-4 text-center">
              {' '}
              Are you sure you want to delete the package
            </h4>
            <button
              onClick={() => deleteHandler()}
              className="btn btn-outline-danger btn-sm w-100"
            >
              Click to Delete
            </button>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { csv, csv_loading, rides, loading, ridesError, message, isAuthorize } =
    state.Rides;
  const { user } = state.Account;
  return {
    user,
    csv,
    csv_loading,
    rides,
    loading,
    ridesError,
    message,
    isAuthorize,
    accessControl
  };
};

export default connect(mapStateToProps, {
  fetchRides,
  rideStatus,
  broadCastRide,
  rideStatusFailed,
  fetchRidePriceFailed,
  fetchDataForCSV,
  fetchForCSVError,
  deleteRide,
})(Rides);
