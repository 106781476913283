import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_ROUTES,
  FETCH_EACH_ROUTE,
  CREATE_ROUTE,
  DELETE_ROUTE,
  UPDATE_ROUTE,
} from './actionTypes';
import {
  fetchRoutesSuccessful,
  fetchRoutesError,
  fetchEachRouteSuccessful,
  fetchEachRouteError,
  deleteRouteSuccessful,
  deleteRouteFailed,
  createRouteSuccessful,
  createRouteFailed,
  updateRouteSuccessful,
  updateRouteFailed,
} from './actions';

import {
  GetRouteService,
  GetEachRouteService,
  DeleteRouteService,
  CreateRouteService,
  UpdateRouteService,
} from '../../services/routeService';

function* fetchRoutesHandler({ payload }) {
  try {
    const response = yield call(GetRouteService, payload);
    yield put(fetchRoutesSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchRoutesError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchRoutesError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchRoutesError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEachRouteHandler({ payload }) {
  try {
    const response = yield call(GetEachRouteService, payload);
    yield put(fetchEachRouteSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachRouteError(error?.response?.data.error?.message));
  }
}

function* deleteRouteHandler({ payload }) {
  try {
    const response = yield call(DeleteRouteService, payload);
    yield put(deleteRouteSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteRouteFailed(error?.response?.data?.message));
  }
}

function* createRouteHandler({ payload }) {
  try {
    const response = yield call(CreateRouteService, payload);
    yield put(createRouteSuccessful(response.data.result));
  } catch (error) {
    yield put(createRouteFailed(error?.response?.data?.message));
  }
}

function* updateRouteHandler({ payload }) {
  try {
    const response = yield call(UpdateRouteService, payload);
    yield put(updateRouteSuccessful(response.data.result));
  } catch (error) {
    yield put(updateRouteFailed(error?.response?.data?.message));
  }
}

// ROUTE SUMMARY

export function* watchFetchRoute() {
  yield takeEvery(FETCH_ROUTES, fetchRoutesHandler);
}

export function* watchFetchEachRoute() {
  yield takeEvery(FETCH_EACH_ROUTE, fetchEachRouteHandler);
}

export function* watchDeleteRoute() {
  yield takeEvery(DELETE_ROUTE, deleteRouteHandler);
}
export function* watchCreateRoute() {
  yield takeEvery(CREATE_ROUTE, createRouteHandler);
}
export function* watchUpdateRoute() {
  yield takeEvery(UPDATE_ROUTE, updateRouteHandler);
}

function* routeSaga() {
  yield all([
    fork(watchFetchRoute),
    fork(watchFetchEachRoute),
    fork(watchDeleteRoute),
    fork(watchCreateRoute),
    fork(watchUpdateRoute),
  ]);
}

export default routeSaga;
