import HttpService from './HttpService'
import { QueryReallignment } from '../utils/general'

export const GetBonusService = (query) => {
  const http = new HttpService()
  let url = 'bonus/all'

  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }

  return http.getData(url)
}

export const GetBonusListService = (query) => {
  const http = new HttpService()
  let url = 'bonus/list'
  return http.getData(url)
}

export const GetBonusLogService = (query) => {
  const http = new HttpService()
  let url = 'bonus/logs/all'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}

export const GetEachBonusLogService = (id) => {
  const http = new HttpService()
  let url = `bonus/logs/${id}`
  return http.getData(url)
}

export const GetEachBonusService = (id) => {
  const http = new HttpService()
  const url = `bonus/${id}`
  return http.getData(url)
}
export const GetMyBonusService = () => {
  const http = new HttpService()
  const url = `bonus`
  return http.getData(url)
}
export const CreateBonusService = (payload) => {
  const http = new HttpService()
  const url = 'bonus'
  return http.postData(payload, url)
}
export const UpdateBonusService = (payload) => {
  const http = new HttpService()
  const url = `bonus/${payload.id}`
  return http.putData(payload.data, url)
}

export const DeleteBonusService = (id) => {
  const http = new HttpService()
  const url = `bonus/${id}`
  return http.deleteData(url)
}

export const fetchBonusSummaryService = (query) => {
  // Payload with query
  const http = new HttpService()
  let url = 'summary/bonus'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}
export const GetBonusLogSummaryService = (query) => {
  // Payload with query
  const http = new HttpService()
  let url = 'summary/bonuslog'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}

export const fetchUserBonusService = (pageInfo) => {
  const http = new HttpService()
  let url = `bonus/admin/${pageInfo.userId}`
  if (pageInfo) {
    let queryParams = QueryReallignment(pageInfo)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}

export const fetchEachUserBonusService = (query) => {
  const http = new HttpService()
  let url = `bonus/${query.id}/status/${query.userId}/admin`
  return http.getData(url)
}
