import {
  SET_NETWORK_ERROR,
} from './actionTypes';

const initialState = {
  networkError: null,
};

const NetworkChecker = (state = initialState, action) => {
  switch (action.type) {
    case SET_NETWORK_ERROR:
      return {
        ...state,
        networkError: {
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    default:
      return state;
  }
};

export default NetworkChecker;
