import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import { connect } from 'react-redux';

import { Link, withRouter } from 'react-router-dom';

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from 'availity-reactstrap-validation';

// actions
import {
  createDocument,
  createDocumentFailed,
} from '../../../../store/actions';

const CreateDoc = ({
  createDocument,
  createDocumentFailed,
  docTypeData,
  updateModalDisplay,
  message,
  loading,
  history,
  documentError,
}) => {
  const handleSubmit = (event, values) => {
    const documentData = {
      ...values,
    };
    documentData.documentTypeId = values.documentTypeId;

    createDocument(documentData);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        updateModalDisplay(false);
        createDocumentFailed('');
        history.push('/documents');
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    createDocumentFailed('');
  }, []);

  return (
    <div>
      {message && (
        <Alert color='success' className='text-center'>
          {message}
        </Alert>
      )}
      {documentError && (
        <Alert color='danger' className='text-center'>
          {documentError}
        </Alert>
      )}

      <div>
        <AvForm className='form-horizontal' onValidSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <FormGroup className='mb-4'>
                <Label htmlFor='documentTypeId'>Choose Document Type</Label>
                <AvField
                  name='documentTypeId'
                  type='select'
                  className='form-control bg-light'
                  value={`${docTypeData?.result[0]?.id}`}
                >
                  {docTypeData?.result?.map((data) => (
                    <option key={data.id} value={`${data.id}`}>
                      {data.name}
                    </option>
                  ))}
                </AvField>
              </FormGroup>
            </Col>
          </Row>

          <button className='btn btn-success mr-2' type='submit'>
            {loading ? 'Submitting ...' : 'Create'}
          </button>
          <Link to='#' onClick={() => updateModalDisplay(false)}>
            <button className='btn btn-secondary'>Cancel</button>
          </Link>
        </AvForm>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, documentError } = state.Document;
  return { message, loading, documentError };
};

export default connect(mapStateToProps, {
  createDocument,
  createDocumentFailed,
})(withRouter(CreateDoc));
