import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Row, Col, Input, Alert } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactPaginate from 'react-paginate';
import queryString from 'query-string';
import Loader from '../../../../components/Common/Loading/index.js';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../../utils/general';
import Moment from 'moment';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import { fetchPromoHistory } from '../../../../store/actions';

let skip = 0;
let dataLimit = 10;

const PromoHistoryTable = ({
  history,
  promoHistory,
  fetchPromoHistory,
  message,
  promoHistoryError,
}) => {
  const [data, setData] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });
  const [status, setStatus] = useState('');

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(promoHistory?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      search,
      // start: date.start,
      // end: date.end,
    };
    fetchPromoHistory(pageInfo);
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = {
      skip,
      take,
      search,
      // start: date.start, end: date.end
    };
    fetchPromoHistory(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      // skip,
      start: date.start,
      end: date.end,
    };
    fetchPromoHistory(queryParams);
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          // start: date.start,
          // end: date.end,
        };
        fetchPromoHistory(queryParams);
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    if (promoHistory) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setPageCount(Math.ceil(promoHistory?.total / take));
      // SET CSV EXPORT DATA
      const csvData = promoHistory.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Name: data?.name ? data?.name : '----',
          Category: data?.category ? data?.category : '----',
          Value: data?.value ? data?.value : '----',
          Used_Date: data?.createdAt?.substring(0, 10),
          Expiry_Date: data?.promoCode?.expiry?.substring(0, 10),
          Status: data?.status,
        };

        return formData;
      });
      setCsvData(csvData);
    }
  }, [promoHistory]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchPromoHistory(parsedUrlParams);
  }, []);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      // start: date.start,
      // end: date.end,
    };
    let result = QueryReallignment(queryParams);

    history.push({
      pathname: history?.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history?.location?.search);

    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    return parsedUrlParams;
  };

  const GetPromoByStatus = (status) => {
    setStatus(status);
    let queryParams = {
      search,
      take,
      skip,
      status,
      // start: date.start,
      // end: date.end,
    };
    fetchPromoHistory(queryParams);
  };
  const tableDataHandler = () => {
    const rowData = promoHistory?.result?.map((data) => {
      return {
        id: data.id,
        name: (
          <div
            //  onClick={() => previewAction(data.id)}
            className='text-dark font-weight-bold'
          >
            <span className='text-capitalize'>
              {data?.promoCode?.name}
              <span className='d-block'>
                <span className='badge badge-soft-secondary rounded'>
                  {' '}
                  {data?.promoCode?.category}{' '}
                </span>
              </span>
            </span>
          </div>
        ),
        value: <span>₦{data.value.toLocaleString()}</span>,
        date: (
          <>
            <span className='d-block font-size-13'>
              Used on : {Moment.utc(data.createdAt).format('lll')}
            </span>
            <span className='font-size-13'>
              Expires :
              <span
                className={`badge badge-soft-${new Date(data?.promoCode?.expiry) <= new Date()
                    ? 'danger'
                    : 'success'
                  }`}
              >
                {Moment(data?.promoCode?.expiry).format('lll')}
              </span>
            </span>
          </>
        ),
        status: (
          <h1
            className={`badge badge-soft-${data.status.toLowerCase() === 'completed'
                ? 'primary'
                : data.status.toLowerCase() === 'cancelled'
                  ? 'danger'
                  : 'warning'
              }`}
          >
            {data.status}
          </h1>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'Name',
          dataField: 'name',
        },
        {
          text: 'Value Used',
          dataField: 'value',
          sort: true,
        },
        {
          text: 'Used - Expiry / Date',
          dataField: 'date',
        },
        {
          text: 'Status',
          dataField: 'status',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {message && (
                <Alert color='success' className='text-center'>
                  {message}
                </Alert>
              )}
              {promoHistoryError ? (
                <Alert color='danger' className='text-center'>
                  {promoHistoryError}
                </Alert>
              ) : !isLoading && data !== null ? (
                <>
                  <div className='d-flex justify-content-end  align-items-center mb-2'>
                    <div className=''>
                      <CSVLink
                        data={csvData}
                        filename={'promoCodeUsage_history.csv'}
                        className='btn btn-primary btn-sm  mt-1'
                        target='_blank'
                      >
                        <i className=' fas fa-cloud-download-alt mr-1' />
                        CSV
                      </CSVLink>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between mb-2'>
                    <div>
                      <Input
                        bsSize='sm'
                        id='take'
                        name='take'
                        type='select'
                        onChange={handleLimitChange}
                      >
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>
                    <div>
                      <select
                        className='custom-select custom-select-sm'
                        onChange={(e) => {
                          GetPromoByStatus(e.target.value);
                        }}
                        value={status}
                      >
                        <option value=''>All</option>
                        <option value='COMPLETED'>COMPLETED</option>
                        <option value='PENDING'>PENDING</option>
                        <option value='CANCELLED'>CANCELLED</option>
                      </select>
                    </div>
                  </div>
                  <BootstrapTable
                    keyField='id'
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses='table-responsive'
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { promoHistory, loading, promoHistoryError, message } = state.PromoCode;
  return { promoHistory, loading, promoHistoryError, message };
};

export default connect(mapStateToProps, {
  fetchPromoHistory,
})(PromoHistoryTable);
