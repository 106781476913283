import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetVehicleService = (query) => {
  const http = new HttpService();
  let url = 'vehicle/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const GetEachVehicleService = (id) => {
  const http = new HttpService();
  const url = `vehicle/${id}`;
  return http.getData(url);
};
export const GetMyVehicleService = () => {
  const http = new HttpService();
  const url = `vehicle`;
  return http.getData(url);
};
export const CreateVehicleService = (payload) => {
  const http = new HttpService();
  const url = 'vehicle';
  return http.postData(payload, url);
};
export const UpdateVehicleService = (payload) => {
  const http = new HttpService();
  const url = `vehicle/${payload.id}/review`;
  return http.putData(payload, url);
};

export const DeleteVehicleService = (id) => {
  const http = new HttpService();
  const url = `vehicle/${id}`;
  return http.deleteData(url);
};

export const fetchVehicleSummaryService = (query) => {
  // Payload with query

  const http = new HttpService();
  let url = 'summary/vehicle';
  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }

  return http.getData(url);
};

export const updateVehicleWithPackageService = (payload) => {
  let packages = {
    packages: payload.packages,
  };
  const http = new HttpService();
  const url = `vehicle/${payload.id}/package`;
  return http.putData(payload, url);
};
