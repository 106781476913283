import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Col, Card, CardBody, Media } from 'reactstrap';
import { connect } from 'react-redux';
import { fetchInvitationSummary } from '../../../../store/actions';

const MiniWidgets = ({
  summaryError,
  invitationSummary,
  fetchInvitationSummary,
  start,
  end,
  loading,
  defaultIcon,
  date,
}) => {
  useEffect(() => {
    if (date.end) {
      fetchInvitationSummary(date);
    } else {
      fetchInvitationSummary();
    }
  }, [date, fetchInvitationSummary]);

  const reports = [
    {
      icon: defaultIcon ? defaultIcon : 'ri-bank-line',
      title: 'Total',
      value: `${invitationSummary?.result?.totalInvitations}`,
      color: 'primary',
    },
    {
      icon: defaultIcon ? defaultIcon : 'ri-bank-line',
      title: 'Status',
      value: `${invitationSummary?.result?.totalInvitations}`,
      color: 'info',
      pending: `${invitationSummary?.result?.pending}`,
      joined: `${invitationSummary?.result?.joined}`,
    },
    {
      icon: 'fas fa-handshake ',
      title: 'ACCEPTED',
      value: `${invitationSummary?.result?.accepted}`,
      color: 'success',
    },
    {
      icon: 'fas fa-user-check',
      title: 'COMPLETED',
      value: `${invitationSummary?.result?.completed}`,
      color: 'primary',
    },
    {
      icon: 'fas fa-donate',
      title: 'Total Earnings',
      value:
        invitationSummary?.result?.earnings > 999 &&
        invitationSummary?.result?.earnings < 999999
          ? `₦${invitationSummary?.result?.earnings / 1000} K`
          : invitationSummary?.result?.earnings > 999999
          ? `₦${invitationSummary?.result?.earnings / 1000000} M`
          : `₦${invitationSummary?.result?.earnings}`,
      color: 'secondary',
      updatedTime: `${invitationSummary?.time}`,
    },
    {
      icon: 'fas fa-handshake ',
      title: 'Number Of Referee',
      value: `${invitationSummary?.result?.referrers}`,
      color: 'primary',
    },
    {
      icon: 'fas fa-user ',
      topTitle: 'Top Referrers',
      title: '-',
      value: `${
        invitationSummary?.result?.topReferrers.length > 0
          ? invitationSummary?.result?.topReferrers.length
          : 'None'
      }`,
      color: 'success',
    },
  ];

  return (
    <React.Fragment>
      {summaryError === null &&
        reports.map((report, key) => (
          <Col key={key} md={3}>
            <Card className='d-flex flex-row mb-3'>
              <CardBody>
                <Media>
                  <Media body className='overflow-hidden'>
                    {report.topTitle ? (
                      <>
                        <div
                          className={`d-flex align-items-center mb-2 pb-${
                            report.value === 'None' ? 2 : 0
                          }`}
                        >
                          <span
                            className={`text-${report.color} mr-2 badge badge-soft-${report.color} rounded`}
                          >
                            <i className={report.icon + ' font-size-16'}></i>
                          </span>
                          <p className='text-truncate font-size-14 mb-0'>
                            {report.topTitle}
                          </p>
                        </div>
                        <h2
                          className={`mt-${
                            report.value === 'None' ? 5 : 0
                          } mb-0 font-size-`}
                        >
                          {invitationSummary === null ? (
                            <Skeleton duration={2} width='20%' />
                          ) : (
                            report.value
                          )}
                        </h2>
                      </>
                    ) : (
                      <>
                        <span
                          className={`text-${report.color} badge badge-soft-${report.color} rounded`}
                        >
                          <i className={report.icon + ' font-size-16'}></i>
                        </span>
                        <p className='text-truncate font-size-14 mt-4 mb-2 '>
                          {report.title}
                        </p>
                        <h2 className='mt-0 mb-0 font-size-'>
                          {invitationSummary === null ? (
                            <Skeleton duration={2} width='20%' />
                          ) : (
                            report.value
                          )}
                        </h2>
                      </>
                    )}
                    {invitationSummary?.isCache && report?.updatedTime && (
                      <p
                        className='font-size-12 text-primary'
                        style={{ position: 'absolute', right: '11px' }}
                      >
                        Updated at: {report?.updatedTime?.split(',')?.pop()}
                      </p>
                    )}
                  </Media>
                </Media>
              </CardBody>
              {report.pending && (
                <CardBody
                  className='p-1 pr-3 py-4 d-flex flex-column'
                  style={{ justifyContent: 'space-evenly' }}
                >
                  {invitationSummary === null ? (
                    <Skeleton duration={2} width='100%' />
                  ) : (
                    <div className='d-flex justify-content-between align-items-center'>
                      <span className='text-muted font-size-12'>PENDING</span>
                      <h6 className='m-0'>{report.pending}</h6>
                    </div>
                  )}

                  {invitationSummary === null ? (
                    <Skeleton duration={2} width='100%' />
                  ) : (
                    <div className='d-flex justify-content-between align-items-center'>
                      <span className='text-muted font-size-12'>JOINED</span>
                      <h6 className='m-0'>{report.joined}</h6>
                    </div>
                  )}
                </CardBody>
              )}
            </Card>
          </Col>
        ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { summaryError, invitationSummary } = state.Referral;
  return { summaryError, invitationSummary };
};

export default connect(mapStateToProps, { fetchInvitationSummary })(
  MiniWidgets
);
