import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import MiniWidgets from './Components/MiniWidgets.js';
import DocumentTable from './documentTable';
import DocPreview from './previewDoc';
import UploadDoc from './UploadDocument';
import CreateDoc from './CreateDoc';
import GreySummaryCard from '../../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../../components/Common/Summary/actionCheck';

import {
  fetchDocType,
  fetchDocument,
  deleteDocument,
  updateDocument,
} from '../../../../store/actions';

const DocumentPage = ({
  document,
  updateDocument,
  tableError,
  accessControl,
  fetchDocument,
  deleteDocument,
  docType,
  fetchDocType,
  loading,
  message,
  isAuthorize,
  history,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDocUploadModal, setShowDocUploadModal] = useState(false);

  const [docIsPdf, setDocIsPdf] = useState(false);
  const [docIsImg, setDocIsImg] = useState(false);

  const [docName, setDocName] = useState(null);
  const [previewId, setPreviewId] = useState(null);
  const breadcrumbItems = [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'User', link: '#' },
    { title: 'Documents', link: '#' },
  ];

  const previewHandler = (name, id, isImg, isDoc) => {
    setShowPreviewModal(true);
    setPreviewId(id);
    setDocName(name);
    setDocIsImg(isImg);
    setDocIsPdf(isDoc);
  };

  useEffect(() => {
    fetchDocType();
  }, [fetchDocType]);

  const updateModalDisplay = (e) => {
    setShowModal(e);
  };
  const updateId = (id) => {
    setPreviewId(id);
  };
  const updateDocUploadModalDisplay = (e) => {
    setShowDocUploadModal(e);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Document' breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field='Documents'>
            {isAuthorize ? (
              <Alert color='danger' className='text-center'>
                {isAuthorize}
              </Alert>
            ) : (
              <>
                <CheckAction field='Documents' iconType={'Create'}>
                  <div className='mb-2 text-right'>
                    <Link to='#' onClick={() => setShowModal(true)}>
                      <Button color='success' size='sm'>
                        <i className='mdi mdi-plus-circle-outline font-size-18'></i>{' '}
                        Add Document
                      </Button>
                    </Link>
                  </div>
                </CheckAction>
                {message && (
                  <Alert color='success' className='text-center'>
                    {message}
                  </Alert>
                )}
                <Row>
                  <Col>
                    <Col sm={12}>
                      <Row>
                        <MiniWidgets />{' '}
                      </Row>
                    </Col>
                    <DocumentTable
                      documentData={document}
                      tableError={tableError}
                      updateDocument={updateDocument}
                      loading
                      deleteDocument={deleteDocument}
                      fetchDocument={fetchDocument}
                      previewAction={previewHandler}
                      updateDocUploadModalDisplay={updateDocUploadModalDisplay}
                      updateId={updateId}
                      history={history}
                      message={message}
                      CheckAction={CheckAction}
                      ReadCheck={ReadCheck}
                      accessControl={accessControl}
                    />
                  </Col>
                </Row>

                <Modal
                  size={`${docIsPdf ? 'lg' : 'md'}`}
                  isOpen={showPreviewModal}
                  toggle={() => setShowPreviewModal(!showPreviewModal)}
                >
                  <ModalHeader toggle={() => setShowPreviewModal(false)}>
                    {docName ? docName : 'Preview Document'}
                  </ModalHeader>
                  <ModalBody>
                    <DocPreview
                      previewId={previewId}
                      docIsPdf={docIsPdf}
                      docIsImg={docIsImg}
                    />
                  </ModalBody>
                </Modal>

                <Modal
                  isOpen={showModal}
                  toggle={() => setShowModal(!showModal)}
                >
                  <ModalHeader toggle={() => setShowModal(false)}>
                    Create Document
                  </ModalHeader>
                  <ModalBody>
                    <CreateDoc
                      docTypeData={docType}
                      updateModalDisplay={updateModalDisplay}
                      fetchDocType={fetchDocType}
                      loading={loading}
                      message={message}
                    />
                  </ModalBody>
                </Modal>
                <Modal
                  isOpen={showDocUploadModal}
                  toggle={() => setShowDocUploadModal(!showDocUploadModal)}
                >
                  <ModalHeader toggle={() => setShowDocUploadModal(false)}>
                    Upload Document
                  </ModalHeader>
                  <ModalBody>
                    <UploadDoc
                      previewId={previewId}
                      updateDocUploadModalDisplay={updateDocUploadModalDisplay}
                    />
                  </ModalBody>
                </Modal>
              </>
            )}
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { document, loading, documentError, message, tableError, isAuthorize } =
    state.Document;
  const { docType, docTypeError } = state.DocType;
  return {
    docType,
    docTypeError,
    document,
    tableError,
    loading,
    accessControl,
    documentError,
    message,
    isAuthorize,
  };
};

export default connect(mapStateToProps, {
  fetchDocType,
  fetchDocument,
  updateDocument,
  deleteDocument,
})(DocumentPage);
