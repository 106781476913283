import React, { useState, useEffect } from 'react'
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  UncontrolledTooltip,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import BootstrapTable from 'react-bootstrap-table-next'

import ReactPaginate from 'react-paginate'
import { Document, Page, pdfjs } from 'react-pdf'
import UploadDoc from '../../../../pages/IRides/Users/Document/UploadDocument'
import Loader from '../../../../components/Common/Loading/index.js'

import {
  fetchDocument,
  deleteDocument,
  fetchDocumentError,
} from '../../../../store/actions'

import { Link } from 'react-router-dom'
import { ScrollToTop } from '../../../../utils/general'

let currentPage = 0
let dataLimit = 10

const DocumentTable = ({
  user,
  document,
  previewAction,
  fetchDocument,
  deleteDocument,
  fetchDocumentError,
  message,
  match,
}) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [pageCount, setPageCount] = useState('')
  const [limit, setLimit] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)
  const [showDocUploadModal, setShowDocUploadModal] = useState(false)
  const [previewId, setPreviewId] = useState(null)
  const [isProfile] = useState(true)

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(document?.total / e.target.value))
    setLimit(parseInt(e.target.value))
    dataLimit = parseInt(e.target.value)
    let pageInfo = { currentPage: pageNumber, limit: dataLimit }
    fetchDocument(pageInfo)
  }

  const handlePageClick = async (data) => {
    currentPage = data.selected
    setPageNumber(parseInt(data.selected))
    if (currentPage > 0) {
      currentPage += '0'
      setPageNumber(parseInt(data.selected + '0'))
    }
    let pageInfo = { currentPage, limit }
    fetchDocument(pageInfo)
    ScrollToTop()
  }

  const SearchHandler = (event) => {
    const searchValue = event.target.value
    if (searchValue !== '') {
      const searchInfo = { searchValue }
      fetchDocument(searchInfo)
    } else {
      const payload = { user: user.id }
      fetchDocument(payload)
    }
  }

  useEffect(() => {
    if (document) {
      setData(tableDataHandler())
      setIsLoading(false)
      setPageCount(Math.ceil(document?.total / limit))
    }
  }, [document])

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        fetchDocumentError('')
      }, 2000)
    }
  }, [message])

  useEffect(() => {
    let payload = { user: user?.id }
    if (match.params.id) {
      payload.user = match.params.id
    }
    fetchDocument(payload)
  }, [])

  const updateId = (e) => {
    setPreviewId(e)
  }
  const updateDocUploadModalDisplay = (e) => {
    setShowDocUploadModal(e)
  }

  const handleDocView = async (documentUrl, documentId) => {
    let docName, docExtension
    if (documentUrl) {
      var getDocNameFromUrl = documentUrl.split('/')
      docName = getDocNameFromUrl[getDocNameFromUrl.length - 1]

      var getDocExtensionFromUrl = documentUrl.split('.')
      docExtension = getDocExtensionFromUrl[getDocExtensionFromUrl.length - 1]

      if (docExtension === 'pdf') {
        previewAction(docName, documentId, false, true)
      }
      if (
        docExtension === 'png' ||
        docExtension === 'jpg' ||
        docExtension === 'jpeg'
      ) {
        previewAction(docName, documentId, true, false)
      }
    } else {
      previewAction(null, documentId, false, false)
    }
  }

  const handleDocUpload = (e) => {
    updateDocUploadModalDisplay(true)
    updateId(e)
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const tableDataHandler = () => {
    const rowData = document?.result?.map((data) => {
      return {
        id: data.id,
        key: data.key,
        name: data.name,

        status: (
          <h1
            className={`badge badge-soft-${
              data.status.toLowerCase() === 'accepted'
                ? 'success'
                : data.status.toLowerCase() === 'rejected'
                ? 'danger'
                : data.status.toLowerCase() === 'pending'
                ? 'warning'
                : data.status.toLowerCase() === 'flagged'
                ? 'dark'
                : data.status.toLowerCase() === 'review'
                ? 'secondary'
                : ''
            } font-size-12`}
          >
            {data.status ? data.status : '---'}
          </h1>
        ),

        action: (
          <>
            <Link
              to={`/document_form?docId=${data.id}`}
              className="mr-3 text-primary"
              id="edit1"
            >
              <i className="mdi mdi-pencil font-size-18"></i>
            </Link>
            <UncontrolledTooltip placement="top" target="edit1">
              Edit
            </UncontrolledTooltip>
            <Link
              to="#"
              onClick={() => deleteDocument(data.id)}
              className="text-danger mr-3"
              id="delete1"
            >
              <i className="mdi mdi-trash-can font-size-18"></i>
            </Link>
            <UncontrolledTooltip placement="top" target="delete1">
              Delete
            </UncontrolledTooltip>

            <Link
              to="#"
              className="text-dark mr-3"
              id="docUpload"
              onClick={() => handleDocUpload(data.id)}
            >
              <i className="fas fa-upload font-size-12"></i>
            </Link>
            <UncontrolledTooltip placement="top" target="docUpload">
              Upload
            </UncontrolledTooltip>

            <Link
              to="#"
              onClick={() => handleDocView(data.documentUrl, data.id)}
              className="text-dark font-weight-bold"
              id="docPreview"
            >
              <i className="fas fa-eye font-size-12"></i>
            </Link>
            <UncontrolledTooltip placement="top" target="docPreview">
              Preview
            </UncontrolledTooltip>
          </>
        ),
      }
    })

    return {
      columns: [
        {
          text: 'Name',
          dataField: 'name',
        },
        {
          text: 'Key',
          dataField: 'key',
          sort: true,
        },

        {
          text: 'Status',
          dataField: 'status',
        },

        {
          text: 'Action',
          dataField: 'action',
          classes: 'text-center',
        },
      ],
      rows: rowData,
    }
  }
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-3">
              {message && (
                <Alert color="success" className="text-center">
                  {message}
                </Alert>
              )}
              {!isLoading && data !== null ? (
                <>
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <Input
                        bsSize="sm"
                        id="limit"
                        name="limit"
                        type="select"
                        onChange={handleLimitChange}
                      >
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>
                    <div>
                      <Input
                        bsSize="sm"
                        id="search"
                        name="search"
                        onChange={SearchHandler}
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField="id"
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses="table-responsive"
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    // breakLabel={'...'}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={3}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    //Bootstrap styling
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>

          <Modal
            isOpen={showDocUploadModal}
            toggle={() => setShowDocUploadModal(!showDocUploadModal)}
          >
            <ModalHeader toggle={() => setShowDocUploadModal(false)}>
              Upload Document
            </ModalHeader>
            <ModalBody>
              <UploadDoc
                previewId={previewId}
                uploadStatus="ACCEPTED"
                updateDocUploadModalDisplay={updateDocUploadModalDisplay}
                isProfile={isProfile}
              />
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { document, loading, documentError, message } = state.Document
  const { user } = state.Account
  return { user, document, loading, documentError, message }
}

export default connect(mapStateToProps, {
  fetchDocument,
  deleteDocument,
  fetchDocumentError,
})(withRouter(DocumentTable))
