import {
  FETCH_TOURS,
  FETCH_TOURS_SUCCESSFUL,
  FETCH_TOURS_API_FAILED,
  FETCH_EACH_TOUR,
  FETCH_EACH_TOUR_SUCCESSFUL,
  FETCH_EACH_TOUR_API_FAILED,
  DELETE_TOUR,
  DELETE_TOUR_SUCCESSFUL,
  DELETE_TOUR_FAILED,
  CREATE_TOUR,
  CREATE_TOUR_SUCCESSFUL,
  CREATE_TOUR_FAILED,
  UPDATE_TOUR,
  UPDATE_TOUR_SUCCESSFUL,
  UPDATE_TOUR_FAILED,
} from "./actionTypes";

const initialState = {
  tours: null,
  tourError: null,
  tableError: null,
  tour: null,
  isAuthorize: null,
  message: null,
  loading: false,
};

const Tour = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TOURS:
    case UPDATE_TOUR:
    case DELETE_TOUR:
      state = {
        ...state,
        tours: null,
        tableError: null,
        tourError: null,
        loading: true,
        message: null,
        isAuthorize: null,
      };
      break;
    case FETCH_TOURS_SUCCESSFUL:
      state = {
        ...state,
        tours: action.payload,
        tableError: null,
        tourError: null,
        loading: false,
      };
      break;

    case DELETE_TOUR_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        tourError: null,
        message: "Tour deleted successfully",
      };
      break;

    case UPDATE_TOUR_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        tourError: null,
        message: "Tour Updated Successfully",
      };
      break;

    case FETCH_EACH_TOUR:
      state = {
        ...state,
        loading: true,
        tours: null,
        tourError: null,
        message: null,
        isAuthorize: null,
      };
      break;

    case FETCH_EACH_TOUR_SUCCESSFUL:
      state = {
        ...state,
        tour: action.payload,
        loading: false,
        tourError: null,
        message: null,
      };
      break;

    case CREATE_TOUR:
      state = {
        ...state,
        loading: true,
        tourError: null,
        message: null,
      };
      break;

    case CREATE_TOUR_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        tourError: null,
        message: `Tour  created successfully`,
      };
      break;

    case FETCH_TOURS_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : "Error in processing data",
      };

    case CREATE_TOUR_FAILED:
    case DELETE_TOUR_FAILED:
    case FETCH_EACH_TOUR_API_FAILED:
      state = {
        ...state,
        tours: null,
        tour: null,
        loading: false,
        message: null,
        tourError:
          action.payload.constructor === String
            ? action.payload
            : "Error in processing data",
      };
      break;

    case UPDATE_TOUR_FAILED:
      state = {
        ...state,
        loading: false,
        tourError:
          action.payload.constructor === String
            ? action.payload
            : "Error in processing data",
        message: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Tour;
