import React, { useState } from "react";
import onClickOutside from "react-onclickoutside";

const CustomSelect = ({
  options,
  selected,
  onChange,
  labelledBy,
  fetchMore,
  isMulti = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option) => {
    if (isMulti) {
      const selectedOption = selected.find(
        (item) => item.value === option.value
      );
      if (selectedOption) {
        onChange(selected.filter((item) => item.value !== option.value));
      } else {
        onChange([...selected, option]);
      }
    } else {
      // When isMulti is false, only allow selecting the clicked option

      onChange([option]);

      setIsOpen(false); // Close the dropdown after selecting an option
    }
  };

  const handleSelectAll = () => {
    if (selected.length === options.length) {
      onChange([]);
    } else {
      onChange(options);
    }
  };

  CustomSelect.handleClickOutside = () => {
    setIsOpen(false);
  };

  return (
    <div className={`multi-select ${isOpen ? "open" : ""} w-100`}>
      <div
        className="selected-options"
        onClick={handleToggleDropdown}
        aria-expanded={isOpen}
        aria-haspopup="true"
        aria-labelledby={labelledBy}
      >
        {selected.length > 0 ? (
          <div className="selected-items text-truncate">
            {isMulti && (
              <span className="badge badge-secondary">
                {selected.length} selected
              </span>
            )}{" "}
            {selected.map((item) => item.label).join(", ")}
          </div>
        ) : (
          <div className="placeholder">{labelledBy}</div>
        )}
        <i className="fas fa-chevron-down" />
      </div>
      {isOpen && (
        <div className="dropdown-options z-index-999">
          {isMulti && (
            <div className="option select-all-option" onClick={handleSelectAll}>
              <input
                type="checkbox"
                className="select-all-checkbox"
                checked={selected.length === options.length}
                readOnly
              />
              Select All
            </div>
          )}
          {options.map((option) => (
            <div
              key={option.value}
              className={`option ${
                selected.some((item) => item.value === option.value)
                  ? "selected"
                  : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handleSelectOption(option);
              }}
            >
              <input
                type="checkbox"
                className="option-checkbox"
                checked={selected.some((item) => item.value === option.value)}
                readOnly
                disabled={!isMulti}
                style={{ visibility: `${!isMulti ? "hidden" : "visible"}` }}
              />
              {option.label}
            </div>
          ))}
          {fetchMore && (
            <div className="btn btn-info btn-sm w-100 mt-2" onClick={fetchMore}>
              Fetch More
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => CustomSelect.handleClickOutside,
};

export default onClickOutside(CustomSelect, clickOutsideConfig);
