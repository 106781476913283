import React, { useEffect, useState } from 'react'
// import MetaTags from "react-meta-tags"
import { ModalHeader, Container, ModalBody, Modal, Alert } from 'reactstrap'
import { connect } from 'react-redux'
import PreviewNotification from './PreviewNotification'

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import CheckAction, {
  ReadCheck,
} from '../../../components/Common/Summary/actionCheck'
import GreySummaryCard from '../../../components/Common/Summary/index'

import {
  fetchNotification,
  deleteNotification,
  updateNotification,
  updateNotificationFailed,
} from '../../../store/actions'

import NotificationTable from './table'

const breadcrumbItems = [
  { title: 'Dashboard', link: '#' },
  { title: 'Notification', link: '#' },
]

const Notification = ({
  fetchNotification,
  deleteNotification,
  updateNotification,
  updateNotificationFailed,
  notifications,
  notificationError,
  loading,
  history,
  message,
  user,
  accessControl,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [previewId, setPreviewId] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const previewHandler = (id) => {
    setShowModal(true)
    setPreviewId(id)
  }

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        updateNotificationFailed('')
      }, 2000)
    }
  }, [message])

  useEffect(() => {
    updateNotificationFailed('')
  }, [])
  const deleteNotificationModal = (id) => {
    setShowDeleteModal(true)
    setDeleteId(id)
  }

  const deleteHandler = () => {
    setShowDeleteModal(false)
    deleteNotification(deleteId)
    setDeleteId(null)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <GreySummaryCard
            field="Custom Notification"
            subModule="General Notifications"
          >
            <Breadcrumbs
              title="Notification"
              breadcrumbItems={breadcrumbItems}
            />
            <div>
              <>
                {message && (
                  <Alert color="success" className="text-center">
                    {message}
                  </Alert>
                )}
                {notificationError ? (
                  <Alert color="danger" className="text-center">
                    {notificationError}
                  </Alert>
                ) : (
                  <NotificationTable
                    notifications={notifications}
                    fetchNotification={fetchNotification}
                    message={message}
                    history={history}
                    loading={loading}
                    previewAction={previewHandler}
                    deleteNotification={deleteNotificationModal}
                    updateNotification={updateNotification}
                    user={user}
                    CheckAction={CheckAction}
                    ReadCheck={ReadCheck}
                    accessControl={accessControl}
                  />
                )}
                <Modal
                  isOpen={showModal}
                  toggle={() => setShowModal(!showModal)}
                >
                  <ModalHeader toggle={() => setShowModal(false)}>
                    Preview Notification
                  </ModalHeader>
                  <ModalBody>
                    <PreviewNotification previewId={previewId} />
                  </ModalBody>
                </Modal>
                <Modal
                  isOpen={showDeleteModal}
                  toggle={() => setShowDeleteModal(!showDeleteModal)}
                >
                  <ModalBody>
                    <h4 className="card-title mb-4 text-center">
                      {' '}
                      Are you sure you want to delete this notification
                    </h4>
                    <button
                      onClick={() => deleteHandler()}
                      className="btn btn-outline-danger btn-sm w-100"
                    >
                      Click to Delete
                    </button>
                  </ModalBody>
                </Modal>
              </>
            </div>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const {
    notifications,
    loading,
    notificationError,
    message,
    isAuthorize,
  } = state.Notifications
  const { user, accessControl } = state.Account
  return {
    notifications,
    loading,
    notificationError,
    message,
    isAuthorize,
    user,
    accessControl,
  }
}

export default connect(mapStateToProps, {
  fetchNotification,
  deleteNotification,
  updateNotification,
  updateNotificationFailed,
})(Notification)
