import {
  FETCH_PAYMENT_LIST,
  FETCH_PAYMENT_LIST_SUCCESSFUL,
  FETCH_PAYMENT_LIST_API_FAILED,
  FETCH_PAYMENT,
  FETCH_PAYMENT_SUCCESSFUL,
  FETCH_PAYMENT_API_FAILED,
  FETCH_EACH_PAYMENT,
  FETCH_EACH_PAYMENT_SUCCESSFUL,
  FETCH_EACH_PAYMENT_API_FAILED,
  DELETE_PAYMENT,
  DELETE_PAYMENT_SUCCESSFUL,
  DELETE_PAYMENT_FAILED,
  CREATE_PAYMENT,
  CREATE_PAYMENT_SUCCESSFUL,
  CREATE_PAYMENT_FAILED,
  GET_PAYMENT_RECIPIENT,
  GET_PAYMENT_RECIPIENT_SUCCESSFUL,
  GET_PAYMENT_RECIPIENT_FAILED,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_SUCCESSFUL,
  UPDATE_PAYMENT_FAILED,
  FETCH_PAYMENT_SUMMARY,
  FETCH_PAYMENT_SUMMARY_SUCCESSFULLY,
  FETCH_PAYMENT_SUMMARY_FAILED,
  FETCH_FOR_PAYMENT_CSV,
  FETCH_FOR_PAYMENT_CSV_SUCCESSFULLY,
  FETCH_FOR_PAYMENT_CSV_ERROR,
} from './actionTypes';

const initialState = {
  paymentList: null,
  paymentError: null,
  payment: null,
  paymentRecipient: null,
  paymentRecipientError: null,
  paymentError: null,
  tableError: null,
  paymentPreview: null,
  paymentSummary: null,
  summaryError: null,
  isAuthorize: null,
  message: null,
  loading: false,
  csvData: null,
  csv_loading: false,
};

const Payment = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAYMENT:
    case FETCH_PAYMENT_LIST:
    case UPDATE_PAYMENT:
    case DELETE_PAYMENT:
      state = {
        ...state,
        payment: null,
        paymentError: null,
        tableError: null,
        paymentRecipient: null,
        paymentError: null,
        loading: true,
        isAuthorize: null,
        message: null,
      };
      break;

    case GET_PAYMENT_RECIPIENT:
      state = {
        ...state,
        paymentRecipient: null,
        loading: true,
        paymentRecipientError: null,
        message: null,
      };
      break;
    case FETCH_PAYMENT_SUCCESSFUL:
      state = {
        ...state,
        payment: action.payload,
        paymentError: null,
        loading: false,
      };
      break;
    case GET_PAYMENT_RECIPIENT_SUCCESSFUL:
      state = {
        ...state,
        paymentRecipient: action.payload,
        paymentRecipientError: null,
        loading: false,
      };
      break;

    case GET_PAYMENT_RECIPIENT_FAILED:
      state = {
        ...state,
        paymentRecipient: null,
        paymentRecipientError: action.payload,
        loading: false,
      };
      break;
    case FETCH_PAYMENT_LIST_SUCCESSFUL:
      state = {
        ...state,
        paymentList: action.payload,
        paymentError: null,
        tableError: null,
        loading: false,
      };
      break;

    case DELETE_PAYMENT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        paymentError: null,
        message: 'payment details deleted successfully',
      };
      break;
    case UPDATE_PAYMENT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        paymentError: null,
        message: 'payment details updated Successfully',
      };
      break;

    case FETCH_EACH_PAYMENT:
      state = {
        ...state,
        loading: true,
        payment: null,
        paymentError: null,
        message: null,
      };
      break;
    case FETCH_EACH_PAYMENT_SUCCESSFUL:
      state = {
        ...state,
        paymentPreview: action.payload,
        loading: false,
        paymentError: null,
        message: null,
      };
      break;

    case FETCH_PAYMENT_SUMMARY:
      return {
        ...state,
        paymentSummary: null,
        summaryError: null,
        loading: true,
      };

    case FETCH_PAYMENT_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        paymentSummary: action.payload,
        summaryError: null,
        loading: false,
      };

    case FETCH_PAYMENT_SUMMARY_FAILED:
      return {
        ...state,
        paymentSummary: null,
        summaryError: action.payload,
        loading: false,
      };

    case CREATE_PAYMENT:
      state = {
        ...state,
        loading: true,
        paymentError: null,
        message: null,
      };
      break;

    case CREATE_PAYMENT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        paymentError: null,
        message: `${action.payload.paymentName} details added successfully for ${action.payload.accountName}`,
      };
      break;

    case FETCH_PAYMENT_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case CREATE_PAYMENT_FAILED:
    case FETCH_PAYMENT_LIST_API_FAILED:
    case DELETE_PAYMENT_FAILED:
    case FETCH_EACH_PAYMENT_API_FAILED:
      state = {
        ...state,
        payment: null,
        paymentPreview: null,
        loading: false,
        message: null,
        paymentError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_PAYMENT_FAILED:
      state = {
        ...state,
        loading: false,
        paymentError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;

    case FETCH_FOR_PAYMENT_CSV:
      state = {
        ...state,
        csvData: null,
        csv_loading: false,
      };
      break;

    case FETCH_FOR_PAYMENT_CSV_SUCCESSFULLY:
      state = {
        ...state,
        csvData: action.payload,
        csv_loading: false,
      };
      break;

    case FETCH_FOR_PAYMENT_CSV_ERROR:
      state = {
        ...state,
        csvData: null,
        csv_loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Payment;
