import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetRidesService = (query) => {
  const http = new HttpService();
  let url = 'ride/all';
  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const GetMyRideService = (query) => {
  const http = new HttpService();
  let url = `ride`;
  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const GetEachRideService = (id) => {
  const http = new HttpService();
  const url = `ride/${id}`;
  return http.getData(url);
};

export const RideStatusService = (payload) => {
  const http = new HttpService();
  const url = `ride/${payload.id}/${payload.status}`;
  return http.putData(payload, url);
};

export const DeleteRideService = (id) => {
  const http = new HttpService();
  const url = `ride/${id}`;
  return http.deleteData(url);
};

export const CreateRideService = (payload) => {
  const http = new HttpService();
  const url = 'ride';
  return http.postData(payload, url);
};

export const UpdateRideService = (payload) => {
  const http = new HttpService();
  const url = `ride/${payload.id}`;
  return http.putData(payload, url);
};

export const RidePriceService = (payload) => {
  const http = new HttpService();
  const url = `package/price`;
  return http.postData(payload, url);
};

export const fetchRidePriceService = (payload) => {
  const http = new HttpService();
  const url = `package/price`;
  return http.postData(payload, url);
};

export const fetchRideSummaryService = (query) => {
  // Payload with query
  const http = new HttpService();
  let url = 'summary/ride';
  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const BroadCastRideService = (payload) => {
  const http = new HttpService();
  const url = `ride/${payload}/broadcast`;
  return http.putData(payload, url);
};

export const GetPlacesService = (query) => {
  const http = new HttpService();
  let url = `place/autocomplete`;
  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const GetRideLogService = (query) => {
  const http = new HttpService();
  let url = 'ride-log/all';
  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const GetRideDeclineLogService = ({ id, query }) => {
  const http = new HttpService();
  let url = `ride-log/decline/${id}`;

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const ConnectService = ({ payload }) => {
  const http = new HttpService();
  const url = `connect`;

  return http.putData(payload, url);
};
