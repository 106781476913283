export const FETCH_CASHOUT_ANALYTIC = 'FETCH_CASHOUT_ANALYTIC';
export const FETCH_CASHOUT_ANALYTIC_SUCCESSFUL =
  'FETCH_CASHOUT_ANALYTIC_SUCCESSFUL';
export const FETCH_CASHOUT_ANALYTIC_API_FAILED =
  'FETCH_CASHOUT_ANALYTIC_API_FAILED';

export const FETCH_EACH_CASHOUT_ANALYTIC = 'FETCH_EACH_CASHOUT_ANALYTIC';
export const FETCH_EACH_CASHOUT_ANALYTIC_SUCCESSFUL =
  'FETCH_EACH_CASHOUT_ANALYTIC_SUCCESSFUL';
export const FETCH_EACH_CASHOUT_ANALYTIC_API_FAILED =
  'FETCH_EACH_CASHOUT_ANALYTIC_API_FAILED';
