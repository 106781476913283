import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Button,
  Row,
  Alert,
  FormGroup,
  Col,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import BootstrapTable from 'react-bootstrap-table-next';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import ReactPaginate from 'react-paginate';
import Loader from '../../../components/Common/Loading/index.js';
import FilterDropDown from '../../../components/Common/FilterDropDown';
import SimpleBar from 'simplebar-react';
import Moment from 'moment';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../utils/general';
import queryString from 'query-string';

let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const InvitationTable = ({
  invitationData,
  statusIdHandler,
  tableError,
  previewAction,
  fetchInvitation,
  deleteInvitation,
  history,
  message,
  dateFilter,
  fetchReferralCSVError,
  fetchReferralCSV,
  csvData,
  csv_loading,
  CheckAction,
  ReadCheck,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [csv, setCsvData] = useState([]);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [skipPage, setSkipPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState('');
  const [menu, setMenu] = useState(false);
  const [date, setDate] = useState({
    start: null,
    end: null,
  });

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(invitationData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      status,
      search,
      start: date.start,
      end: date.end,
    };
    fetchInvitation(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = {
      skip,
      take,
      status,
      search,
      start: date.start,
      end: date.end,
    };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchInvitation(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      skip,
      status,
      start: date.start,
      end: date.end,
    };
    fetchInvitation(queryParams);
  };

  const handleCSV = (e, values) => {
    fetchReferralCSV({
      take: values.endRow
        ? values.endRow - values.startRow + 1
        : invitationData?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
    });
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          status,
          start: date.start,
          end: date.end,
        };
        fetchInvitation(queryParams);
      }, 2000);
    }
  }, [fetchInvitation, message]);

  useEffect(() => {
    if (invitationData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(invitationData?.total / take));
    }
  }, [invitationData]);

  useEffect(() => {
    if (csvData) {
      // SET CSV EXPORT DATA
      const csv = csvData.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Referrer: `${data?.user?.firstName} - ${data?.user?.lastName} `,
          Referral_Code: data?.referralCode ? data?.referralCode : '----',
          Referree: `${data?.firstName} - ${data?.lastName} `,
          UserType: data?.userType ? data?.userType : '----',
          Phone: data?.phone,
          Status: data?.status,
          Date: data?.createdAt?.substring(0, 10),
        };

        return formData;
      });

      setCsvData(csv);
    }
  }, [csvData]);

  useEffect(() => {
    if (!showCSVModal) {
      fetchReferralCSVError();
    }
  }, [showCSVModal]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchInvitation({ ...parsedUrlParams, start: date.start, end: date.end });
  }, [fetchInvitation]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      status,
      take,
      skip: pageNumber,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        take,
        skip,
        start: date.start,
        end: date.end,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.status) {
      setStatus(parsedUrlParams?.status);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    dateFilter({ start: values.startDate, end: values.endDate });
    setMenu(false);
    setStatus(values.referralStatus);
    let queryParams = {
      search,
      take,
      skip,
      status: values.referralStatus,
      start: values.startDate,
      end: values.endDate,
    };
    fetchInvitation(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: '',
      end: '',
    });
    dateFilter({});
    let queryParams = {
      search,
      take,
      skip,
      status,
    };
    fetchInvitation(queryParams);
  };
  let accessChecking = ReadCheck(accessControl, `Referral`);
  const tableDataHandler = () => {
    const rowData = invitationData?.result?.map((data, i) => {
      return {
        id: data.id,
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,
        referrer: (

          <>
            {accessChecking ?

              <Link
                to={`/user_referral?id=${data?.user?.id}`}
                className='text-dark font-weight-bold'
              >
                <div className='text-capitalize'>
                  {data?.user?.firstName} {data?.user?.lastName}
                </div>
                <div className='d-flex align-items-center'>
                  <img
                    src={data?.user?.picture ? data?.user?.picture : avatarImg}
                    alt=''
                    height='20'
                    className='rounded-circle avatar-xs mr-2'
                  />

                  <div>
                    <span className='text-muted mr-2 badge badge-soft-secondary'>
                      <i className=' fas fa-star text-warning mr-1' />
                      {data?.user?.rating ? data?.user?.rating?.toFixed(2) : 0}
                    </span>
                    <span
                      className='text-white mr-2 badge badge-secondary'
                      style={{
                        background: `${data?.user?.userType === 'RIDER' ? '#f18357' : '#00a859'
                          }`,
                      }}
                    >
                      {data?.user?.userType}
                    </span>
                  </div>
                </div>
              </Link>

              :
              <div className='text-dark font-weight-bold'>
                <div className='text-capitalize'>
                  {data?.user?.firstName} {data?.user?.lastName}
                </div>
                <div className='d-flex align-items-center'>
                  <img
                    src={data?.user?.picture ? data?.user?.picture : avatarImg}
                    alt=''
                    height='20'
                    className='rounded-circle avatar-xs mr-2'
                  />

                  <div>
                    <span className='text-muted mr-2 badge badge-soft-secondary'>
                      <i className=' fas fa-star text-warning mr-1' />
                      {data?.user?.rating ? data?.user?.rating?.toFixed(2) : 0}
                    </span>
                    <span
                      className='text-white mr-2 badge badge-secondary'
                      style={{
                        background: `${data?.user?.userType === 'RIDER' ? '#f18357' : '#00a859'
                          }`,
                      }}
                    >
                      {data?.user?.userType}
                    </span>
                  </div>
                </div>
              </div>
            }
          </>
        ),
        code: (
          <span className='text-uppercase font-size-12'>
            {data?.referralCode}
          </span>
        ),
        referee: (
          <>
            <div className='text-capitalize text-dark font-weight-bold'>
              {data?.firstName ? data?.firstName : ' '}{' '}
              {data?.lastName && data?.lastName !== 'null'
                ? data?.lastName
                : ''}
            </div>
            <div className='d-flex align-items-center'>
              <div>
                {data?.userType && (
                  <span
                    className='text-white mr-2 badge badge-secondary'
                    style={{
                      background: `${data?.userType === 'RIDER' ? '#f18357' : '#00a859'
                        }`,
                    }}
                  >
                    {data?.userType}
                  </span>
                )}
              </div>
            </div>
          </>
        ),
        phone: data?.phone,
        date: (
          <div>
            {' '}
            <div>{Moment(data.createdAt).format('DD/MM/YYYY')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data.createdAt).add(1, 'hours').format('hh:mm A')}
            </div>
          </div>
        ),
        status: (
          <h1
            className={`badge badge-soft-${data?.status.toLowerCase() === 'accepted'
              ? 'success'
              : data?.status.toLowerCase() === 'completed'
                ? 'primary'
                : data?.status.toLowerCase() === 'pending'
                  ? 'warning'
                  : data?.status.toLowerCase() === 'joined'
                    ? 'dark'
                    : 'secondary'
              } font-size-12`}
          >
            {data.status ? data.status : '---'}
          </h1>
        ),
        action: (
          <div className='d-flex align-items-center'>

            {/* <Link
              to="#"
              onClick={() => statusIdHandler(data.id, data.status)}
              className="mr-3 text-primary"
              id="edit1"
            >
              <i className="fas fa-exchange-alt font-size-16"></i>
            </Link>
            <UncontrolledTooltip placement="top" target="edit1">
              Change Status
            </UncontrolledTooltip> */}
            <CheckAction field='Referral' iconType='Delete'>
              <Link
                to='#'
                onClick={() => deleteInvitation(data?.id)}
                className='text-danger'
                id='delete1'
              >
                <i className='mdi mdi-trash-can font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='delete1'>
                Delete
              </UncontrolledTooltip>
            </CheckAction>
          </div>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
        },
        {
          text: 'Referrer',
          dataField: 'referrer',
        },
        {
          text: 'Referral Code',
          dataField: 'code',
          sort: true,
        },
        {
          text: 'Referee',
          dataField: 'referee',
          sort: true,
        },
        {
          text: 'Contact',
          dataField: 'phone',
        },
        {
          text: 'Status',
          dataField: 'status',
        },
        {
          text: 'Date',
          dataField: 'date',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {tableError !== null ? (
                <Alert color='danger' className='text-center'>
                  {tableError}
                </Alert>
              ) : (
                <>
                  <div className='d-flex justify-content-end  align-items-center mb-2'>
                    <div className=''>
                      <Button
                        size='sm'
                        color='primary'
                        className='mt-1'
                        onClick={() => setShowCSVModal(true)}
                        disabled={invitationData?.total > 0 ? false : true}
                      >
                        <i className=' fas fa-cloud-download-alt mr-1' />
                        CSV
                      </Button>
                    </div>
                    <div>
                      <FilterDropDown
                        menu={menu}
                        toggleMenu={() => setMenu(!menu)}
                      >
                        <div>
                          <AvForm
                            className='form-horizontal'
                            onValidSubmit={handleDateFilter}
                          >
                            <SimpleBar
                              style={{
                                maxHeight: '250px',
                              }}
                            >
                              <Row className='px-3  pt-3 pb-0'>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='startDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='startDate'
                                      value={date.start}
                                      helpMessage='Start Date'
                                      placeholder='Please choose the start date...'
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      name='endDate'
                                      type='date'
                                      className='form-control bg-light'
                                      id='endDate'
                                      value={date.end}
                                      helpMessage='End Date'
                                      placeholder='Please choose the end date...'
                                      required
                                    />
                                  </FormGroup>
                                </Col>{' '}
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      type='select'
                                      value={status}
                                      name='referralStatus'
                                      helpMessage='status'
                                    >
                                      <option value=''>All</option>
                                      <option value='ACCEPTED'>ACCEPTED</option>
                                      <option value='COMPLETED'>
                                        COMPLETED
                                      </option>
                                      <option value='PENDING'>PENDING</option>
                                      <option value='WARNING'>WARNING</option>
                                      <option value='JOINED'>JOINED</option>
                                    </AvField>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </SimpleBar>

                            <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                              <Link
                                to='#'
                                onClick={resetHandler}
                                className='btn btn-sm  bg-light font-size-14'
                              >
                                <i className=' mdi mdi-refresh mr-1'> Reset</i>
                              </Link>

                              <button
                                className='btn btn-success btn-sm mr-2'
                                type='submit'
                              >
                                <i className='mdi mdi-arrow-right-circle mr-1'></i>
                                Send
                              </button>
                            </div>
                          </AvForm>
                        </div>
                      </FilterDropDown>
                    </div>
                  </div>
                  {!isLoading && data !== null ? (
                    <>
                      <div className='d-flex justify-content-between mb-2'>
                        <div>
                          <Input
                            bsSize='sm'
                            id='take'
                            name='take'
                            type='select'
                            onChange={handleLimitChange}
                            value={take}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div>
                          <Input
                            bsSize='sm'
                            id='search'
                            value={search}
                            name='search'
                            onChange={SearchHandler}
                            placeholder='Search'
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField='id'
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses='table-responsive'
                        noDataIndication={'No Data to Display'}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        // breakLabel={'...'}
                        // marginPagesDisplayed={2}
                        // pageRangeDisplayed={3}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        forcePage={skipPage}
                        //Bootstrap styling
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* CSV DOWNLOAD MODAL */}
      <Modal
        isOpen={showCSVModal}
        toggle={() => setShowCSVModal(!showCSVModal)}
      >
        <ModalHeader toggle={() => setShowCSVModal(false)}>
          Generate CSV
        </ModalHeader>
        <ModalBody>
          <AvForm className='form-horizontal' onValidSubmit={handleCSV}>
            <Col>
              <FormGroup>
                <Label htmlFor='startRow'>Start (Row)</Label>
                <AvField
                  name='startRow'
                  type='number'
                  max={invitationData?.total - 1}
                  className='form-control'
                  id='startRow'
                  placeholder='Enter the row to start from'
                  errorMessage='value must be greater than 0 and less than total data'
                  min='1'
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor='endRow'>End (Row)</Label>
                <AvField
                  name='endRow'
                  type='number'
                  className='form-control'
                  min='1'
                  max={invitationData?.total}
                  id='endRow'
                  placeholder='Enter the row to end'
                  helpMessage={
                    invitationData?.total
                      ? `Total data is ${invitationData?.total}`
                      : null
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Button
              size='sm'
              color='primary'
              className='ml-3 waves-effect waves-light '
              type='submit'
            >
              {csv_loading ? 'Generating...' : 'Generate'}
            </Button>
            {csvData && (
              <CSVLink
                data={csv}
                filename={'invitations_data.csv'}
                className='btn-sm btn-success ml-2'
                target='_blank'
                onClick={() => setShowCSVModal(false)}
              >
                Download
              </CSVLink>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default InvitationTable;
