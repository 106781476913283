import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetApp_versionService = (query) => {
  const http = new HttpService();
  let url = 'app-version/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const GetEachApp_versionService = (id) => {
  const http = new HttpService();
  const url = `app-version/${id}`;
  return http.getData(url);
};

export const CreateApp_versionService = (payload) => {
  const http = new HttpService();
  const url = 'app-version';
  return http.postData(payload, url);
};
export const UpdateApp_versionService = (payload) => {
  const http = new HttpService();
  const url = `app-version/${payload.id}`;
  return http.putData(payload, url);
};

export const DeleteApp_versionService = (id) => {
  const http = new HttpService();
  const url = `app-version/${id}`;
  return http.deleteData(url);
};
