import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const LoginService = (payload) => {
  const http = new HttpService();
  const url = 'auth/login';
  return http.postData(payload, url);
};

export const OTPService = (payload) => {
  const http = new HttpService();
  const url = 'auth/otpGenerate';
  return http.postData(payload, url);
};

export const googleLoginService = () => {
  const http = new HttpService();
  const url = 'auth/google';
  return http.getData(url);
};

export const loadUserServer = () => {
  const http = new HttpService();
  const url = 'user';
  return http.getData(url);
};

export const RegisterService = (payload) => {
  const http = new HttpService();
  const url = 'user';
  return http.postData(payload, url);
};

export const ActivateServices = (payload) => {
  const http = new HttpService();
  const url = 'user/activate';
  return http.putData(payload, url);
};

export const ReActivateServices = (email) => {
  const payload = { email };
  const http = new HttpService();
  const url = `user/resendActivation?email=${email}`;
  return http.putData(payload, url);
};

export const EmailSubscriptionServices = ({ payload, query }) => {
  const http = new HttpService();
  const url = `user/notificationType?email=${query}`;
  return http.putData(payload, url);
};

export const ForgetPasswordServices = (payload) => {
  const http = new HttpService();
  const url = 'user/forgotPassword';
  return http.postData(payload, url);
};

export const createNewPasswordServices = (payload) => {
  const http = new HttpService();
  const url = 'user/setNewPassword';
  return http.postData(payload, url);
};

export const updateProfileServices = ({ payload, id }) => {
  const http = new HttpService();
  const url = `user/${id}`;
  return http.putData(payload, url);
};

export const passwordSettingServices = (payload) => {
  const http = new HttpService();
  const url = 'user/changePassword';
  return http.putData(payload, url);
};

export const updateAccountWalletService = ({ values, urlLink }) => {
  const http = new HttpService();
  const url = `account/${urlLink}`;
  return http.putData(values, url);
};

export const getUserLocationService = (query) => {
  const http = new HttpService();
  let url = 'connect/location';
  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const myAccessControlService = () => {
  const http = new HttpService();
  let url = 'appConfig/acl';
  return http.getData(url);
};
