import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_CASHOUT,
  FETCH_EACH_CASHOUT,
  UPDATE_CASHOUT_STATUS,
  CREATE_CASHOUT,
  DELETE_CASHOUT,
  UPDATE_CASHOUT,
  FETCH_CASHOUT_SUMMARY,
  FETCH_FOR_CASHOUT_CSV,
} from './actionTypes';
import {
  fetchCashoutSuccessful,
  fetchCashoutError,
  fetchEachCashoutSuccessful,
  fetchEachCashoutError,
  createCashoutSuccessful,
  fetchCashoutSummarySuccessfully,
  fetchCashoutSummaryFailed,
  createCashoutFailed,
  deleteCashoutSuccessful,
  deleteCashoutFailed,
  updateCashoutSuccessful,
  updateCashoutFailed,
  updateCashoutStatusSuccessful,
  updateCashoutStatusFailed,
  fetchCashoutCSVSuccessfully,
  fetchCashoutCSVError,
} from './actions';

import {
  GetCashoutService,
  GetEachCashoutService,
  DeleteCashoutService,
  UpdateCashoutService,
  fetchCashoutSummaryService,
  CreateCashoutService,
  UpdateCashoutStatusService,
} from '../../services/cashoutService';

function* fetchCashoutHandler({ payload }) {
  try {
    const response = yield call(GetCashoutService, payload);
    yield put(fetchCashoutSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchCashoutError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchCashoutError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchCashoutError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEachCashoutHandler({ payload }) {
  try {
    const response = yield call(GetEachCashoutService, payload);
    yield put(fetchEachCashoutSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachCashoutError(error?.response?.data?.message));
  }
}

function* deleteCashoutHandler({ payload }) {
  try {
    const response = yield call(DeleteCashoutService, payload);
    yield put(deleteCashoutSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteCashoutFailed(error?.response?.data?.error));
  }
}

function* updateCashoutHandler({ payload }) {
  try {
    const response = yield call(UpdateCashoutService, payload);
    yield put(updateCashoutSuccessful(response.data.result));
  } catch (error) {
    yield put(updateCashoutFailed(error?.response?.data?.message));
  }
}

function* updateCashoutStatusHandler({ payload }) {
  try {
    const response = yield call(UpdateCashoutStatusService, payload);
    yield put(updateCashoutStatusSuccessful(response.data.result));
  } catch (error) {
    yield put(updateCashoutStatusFailed(error?.response?.data?.message));
  }
}

function* createCashoutHandler({ payload }) {
  try {
    const response = yield call(CreateCashoutService, payload);
    yield put(createCashoutSuccessful(response.data.result));
  } catch (error) {
    yield put(createCashoutFailed(error?.response?.data?.message));
  }
}
function* fetchCashoutCSVHandler({ payload }) {
  try {
    const response = yield call(GetCashoutService, payload);
    yield put(fetchCashoutCSVSuccessfully(response.data));
  } catch (error) {
    yield put(fetchCashoutCSVError(error?.response?.data?.message));
  }
}

function* fetchCashoutSummaryHandler({ payload }) {
  try {
    const response = yield call(fetchCashoutSummaryService, payload);
    yield put(fetchCashoutSummarySuccessfully(response.data));
  } catch (error) {
    yield put(fetchCashoutSummaryFailed(error.response.data));
  }
}

export function* watchFetchCashoutSummary() {
  yield takeEvery(FETCH_CASHOUT_SUMMARY, fetchCashoutSummaryHandler);
}

export function* watchCreateCashout() {
  yield takeEvery(CREATE_CASHOUT, createCashoutHandler);
}

export function* watchUpdateCashoutStatus() {
  yield takeEvery(UPDATE_CASHOUT_STATUS, updateCashoutStatusHandler);
}

export function* watchFetchCashout() {
  yield takeEvery(FETCH_CASHOUT, fetchCashoutHandler);
}

export function* watchFetchEachCashout() {
  yield takeEvery(FETCH_EACH_CASHOUT, fetchEachCashoutHandler);
}
export function* watchDeleteCashout() {
  yield takeEvery(DELETE_CASHOUT, deleteCashoutHandler);
}
export function* watchUpdateCashout() {
  yield takeEvery(UPDATE_CASHOUT, updateCashoutHandler);
}

export function* watchCashoutCSV() {
  yield takeEvery(FETCH_FOR_CASHOUT_CSV, fetchCashoutCSVHandler);
}

function* cashoutSaga() {
  yield all([
    fork(watchCreateCashout),
    fork(watchUpdateCashoutStatus),
    fork(watchFetchCashout),
    fork(watchFetchEachCashout),
    fork(watchFetchCashoutSummary),
    fork(watchDeleteCashout),
    fork(watchUpdateCashout),
    fork(watchCashoutCSV),
  ]);
}

export default cashoutSaga;
