import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';

import ReactPaginate from 'react-paginate';

import Loader from '../../../../components/Common/Loading/index.js';

import { Link } from 'react-router-dom';
import { ScrollToTop } from '../../../../utils/general';

let currentPage = 0;
let dataLimit = 10;

const DocTypeTable = ({
  docTypeData,
  previewAction,
  fetchDocType,
  deleteDocType,
  CheckAction,
  ReadCheck,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(docTypeData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = { currentPage: pageNumber, limit: dataLimit };

    fetchDocType(pageInfo);
  };

  const handlePageClick = async (data) => {
    currentPage = data.selected;
    setPageNumber(parseInt(data.selected));
    if (currentPage > 0) {
      currentPage += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { currentPage, limit };

    fetchDocType(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const searchValue = event.target.value;
    if (searchValue !== '') {
      const searchInfo = { searchValue };

      fetchDocType(searchInfo);
    } else {
      fetchDocType();
    }
  };

  useEffect(() => {
    if (docTypeData) {
      setData(tableDataHandler());
      setIsLoading(false);
      setPageCount(Math.ceil(docTypeData?.total / limit));
    }
  }, [docTypeData]);
  let accessChecking = ReadCheck(accessControl, `Configurations`, 'Document Type');

  const tableDataHandler = () => {
    const rowData = docTypeData?.result?.map((data) => {
      return {
        id: data.id,
        key: (

          <>
            {accessChecking ?

              <Link
                to='#'
                onClick={() => previewAction(data.id)}
                className='text-dark font-weight-bold'
              >
                {data.key}
              </Link>

              :
              <div className='text-dark font-weight-bold'>
                {data.key}
              </div>
            }</>
        ),
        description: data.description,
        name: data.name,
        userType: data.userType,
        pages: data.pages,

        action: (
          <div className='d-flex align-items-center'>
            <CheckAction
              field='Configurations'
              subModule='Document Type'
              iconType='Edit'
            >
              <Link
                to={`/document_type_form?id=${data.id}`}
                className='mr-3 text-primary'
                id='edit1'
              >
                <i className='mdi mdi-pencil font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='edit1'>
                Edit
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction
              field='Configurations'
              subModule='Document Type'
              iconType='Delete'
            >
              <Link
                to='#'
                onClick={() => deleteDocType(data.id)}
                className='text-danger'
                id='delete1'
              >
                <i className='mdi mdi-trash-can font-size-18'></i>
              </Link>
              <UncontrolledTooltip placement='top' target='delete1'>
                Delete
              </UncontrolledTooltip>
            </CheckAction>
          </div>
        ),
      };
    });
    return {
      columns: [
        {
          dataField: 'sl.no',
          text: 'S.No',
          formatter: (cell, row, rowIndex) => {
            return rowIndex + 1;
          },
          sort: true,
        },
        {
          text: 'Key',
          dataField: 'key',
          sort: true,
        },
        {
          text: 'Name',
          dataField: 'name',
        },
        {
          text: 'Description',
          dataField: 'description',
          sort: true,
        },
        {
          text: 'User',
          dataField: 'userType',
        },
        {
          text: 'Pages',
          dataField: 'pages',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {!isLoading && data !== null ? (
                <>
                  <div className='d-flex justify-content-between mb-2'>
                    <div>
                      <Input
                        bsSize='sm'
                        id='limit'
                        name='limit'
                        type='select'
                        onChange={handleLimitChange}
                      >
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>
                    <div>
                      <Input
                        bsSize='sm'
                        id='search'
                        name='search'
                        onChange={SearchHandler}
                        placeholder='Search'
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField='id'
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses='table-responsive'
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    // breakLabel={'...'}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={3}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    //Bootstrap styling
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DocTypeTable;
