import {
  FETCH_DASH_STAT,
  FETCH_DASH_STAT_SUCCESSFUL,
  FETCH_DASH_STAT_FAILED,
  FETCH_DASH_EARNING,
  FETCH_DASH_EARNING_SUCCESSFUL,
  FETCH_DASH_EARNING_FAILED,
  FETCH_DASH_REVENUE,
  FETCH_DASH_REVENUE_SUCCESSFUL,
  FETCH_DASH_REVENUE_FAILED,
  FETCH_DASH_TRIP,
  FETCH_DASH_TRIP_SUCCESSFUL,
  FETCH_DASH_TRIP_FAILED,
  FETCH_DASH_RATING,
  FETCH_DASH_RATING_SUCCESSFUL,
  FETCH_DASH_RATING_FAILED,
} from './actionTypes';

const INIT_STATE = {
  loading: false,
  dashStat: null,
  dashEarning: null,
  dashEarningError: null,
  dashRevenue: null,
  dashRevenueError: null,
  dashTrips: null,
  dashRating: null,
  dashStatError: null,
  message: null,
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_DASH_STAT:
    case FETCH_DASH_EARNING:
      return {
        ...state,
        dashStat: null,
        dashEarning: null,
        dashRevenue: null,
        dashRevenueError: null,
        loading: true,
        message: null,
        dashStatError: null,
        dashEarningError: null,
      };
    case FETCH_DASH_REVENUE:
      return {
        ...state,
        dashRevenue: null,
        revenue_loading: true,
        message: null,
        dashRevenueError: null,
      };
    case FETCH_DASH_TRIP:
      return {
        ...state,
        dashtrips: null,
        loading: true,
        message: null,
        dashStatError: null,
      };

    case FETCH_DASH_RATING:
      return {
        ...state,
        dashRating: null,
        loading: true,
        message: null,
        dashStatError: null,
      };

    case FETCH_DASH_STAT_SUCCESSFUL:
      return {
        ...state,
        dashStat: action.payload,
        loading: false,
        dashStatError: null,
      };

    case FETCH_DASH_EARNING_SUCCESSFUL:
      return {
        ...state,
        dashEarning: action.payload,
        loading: false,
        dashEarningError: null,
      };

    case FETCH_DASH_REVENUE_SUCCESSFUL:
      return {
        ...state,
        dashRevenue: action.payload,
        revenue_loading: false,
        dashRevenueError: null,
      };

    case FETCH_DASH_TRIP_SUCCESSFUL:
      return {
        ...state,
        dashTrips: action.payload,
        loading: false,
        dashStatError: null,
      };

    case FETCH_DASH_RATING_SUCCESSFUL:
      return {
        ...state,
        dashRating: action.payload,
        loading: false,
        dashStatError: null,
      };

    case FETCH_DASH_TRIP_FAILED:
      return {
        ...state,
        dashTrips: null,
        loading: false,
        message: null,
        dashStatError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case FETCH_DASH_STAT_FAILED:
      return {
        ...state,
        dashStat: null,
        loading: false,
        message: null,
        dashStatError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case FETCH_DASH_EARNING_FAILED:
      return {
        ...state,
        loading: false,
        message: null,
        dashEarningError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
    case FETCH_DASH_REVENUE_FAILED:
      return {
        ...state,
        revenue_loading: false,
        message: null,
        dashRevenueError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case FETCH_DASH_RATING_FAILED:
      return {
        ...state,
        dashRating: null,
        loading: false,
        message: null,
        dashStatError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    default:
      return state;
  }
};

export default Dashboard;
