import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  FETCH_TOURS,
  FETCH_EACH_TOUR,
  CREATE_TOUR,
  DELETE_TOUR,
  UPDATE_TOUR,
} from "./actionTypes";
import {
  fetchToursSuccessful,
  fetchToursError,
  fetchEachTourSuccessful,
  fetchEachTourError,
  deleteTourSuccessful,
  deleteTourFailed,
  createTourSuccessful,
  createTourFailed,
  updateTourSuccessful,
  updateTourFailed,
} from "./actions";

import {
  GetTourService,
  GetEachTourService,
  DeleteTourService,
  CreateTourService,
  UpdateTourService,
} from "../../services/tourService";

function* fetchToursHandler({ payload }) {
  try {
    const response = yield call(GetTourService, payload);
    yield put(fetchToursSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchToursError(
          "Access to this page is restricted to authorized users only"
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchToursError(
          "Too much request, please refresh and try again after few seconds"
        )
      );
    } else {
      yield put(
        fetchToursError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : "Error in processing data"
        )
      );
    }
  }
}

function* fetchEachTourHandler({ payload }) {
  try {
    const response = yield call(GetEachTourService, payload);
    yield put(fetchEachTourSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachTourError(error?.response?.data.error?.message));
  }
}

function* deleteTourHandler({ payload }) {
  try {
    const response = yield call(DeleteTourService, payload);
    yield put(deleteTourSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteTourFailed(error?.response?.data?.message));
  }
}

function* createTourHandler({ payload }) {
  try {
    const response = yield call(CreateTourService, payload);
    yield put(createTourSuccessful(response.data.result));
  } catch (error) {
    yield put(createTourFailed(error?.response?.data?.message));
  }
}

function* updateTourHandler({ payload }) {
  try {
    const response = yield call(UpdateTourService, payload);
    yield put(updateTourSuccessful(response.data.result));
  } catch (error) {
    yield put(updateTourFailed(error?.response?.data?.message));
  }
}

// TOUR SUMMARY

export function* watchFetchTour() {
  yield takeEvery(FETCH_TOURS, fetchToursHandler);
}

export function* watchFetchEachTour() {
  yield takeEvery(FETCH_EACH_TOUR, fetchEachTourHandler);
}

export function* watchDeleteTour() {
  yield takeEvery(DELETE_TOUR, deleteTourHandler);
}
export function* watchCreateTour() {
  yield takeEvery(CREATE_TOUR, createTourHandler);
}
export function* watchUpdateTour() {
  yield takeEvery(UPDATE_TOUR, updateTourHandler);
}

function* tourSaga() {
  yield all([
    fork(watchFetchTour),
    fork(watchFetchEachTour),
    fork(watchDeleteTour),
    fork(watchCreateTour),
    fork(watchUpdateTour),
  ]);
}

export default tourSaga;
