import React, { useEffect, useState } from 'react';
import Location from '../../../../components/Common/Location';
import Form from './form';

const RegistrationForm = () => {
  const [locationErr, setLocationErr] = useState(null);
  const [cordinate, setCordinate] = useState({ lat: '', lng: '' });
  const location = Location();
  useEffect(() => {
    if (location && location.loaded) {
      if (location.err) {
        setLocationErr(
          location.error.message === 'User denied Geolocation'
            ? 'Location Permission'
            : 'Error with location'
        );
        return;
      } else {
        setCordinate({
          lat: location.coordinates.lat,
          lng: location.coordinates.lng,
        });
      }
    }
    // else {
    //   setLocationErr('Error with location');
    // }
  }, [location]);

  return <Form geoLocation={cordinate} locationErr={locationErr} />;
};

export default RegistrationForm;
