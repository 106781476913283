import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import AutoComplete from '../../../../components/Common/Autocomplete';

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from 'availity-reactstrap-validation';

// actions
import {
  createBank,
  fetchBankList,
  createBankFailed,
  updateBank,
  updateBankFailed,
  fetchUserDetails,
} from '../../../../store/actions';

const Form = ({
  createBank,
  createBankFailed,
  updateBank,
  updateBankFailed,
  fetchBankList,
  message,
  loading,
  user,
  fetchUserDetails,
  bankError,
  bankList,
  defaultValue,
  history,
}) => {
  const [moreDetail, setMoreDetail] = useState('');
  const [errorMessage, setError] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);

  const handleSubmit = (event, values) => {
    setError('');
    if (!values.bankName || values.bankName === 'loading') return;
    let bankDetails = bankList.result.data.find(
      (data) => data.name === values.bankName
    );
    const bankData = {
      ...values,
    };

    bankData.bankName = bankDetails.name;
    bankData.code = bankDetails.code;
    bankData.currency = bankDetails.currency;
    bankData.type = bankDetails.type;
    bankData.longcode = bankDetails.longcode;
    bankData.gateway = bankDetails.gateway;
    bankData.accountNumber = values.accountNumber;
    bankData.note = moreDetail;
    bankData.accountName = values.accountName;
    bankData.userId = selectedUser?.value;
    if (defaultValue) {
      bankData.id = defaultValue.id;
      updateBank(bankData);
      return;
    }
    createBank(bankData);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        createBankFailed('');
        updateBankFailed('');
        history.goBack();
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    createBankFailed('');
    updateBankFailed('');
    fetchBankList();
  }, []);

  useEffect(() => {
    setMoreDetail('');
    if (defaultValue) {
      setMoreDetail(defaultValue.note);
      setSelectedUser(defaultValue.user.id);
      fetchUserDetails(defaultValue.user.id);
    }
  }, [defaultValue, fetchUserDetails]);

  return (
    <Card>
      <CardBody>
        {message && (
          <Alert color='success' className='text-center'>
            {message}
          </Alert>
        )}
        {bankError && (
          <Alert color='danger' className='text-center'>
            {bankError}
          </Alert>
        )}
        {errorMessage && (
          <Alert color='danger' className='text-center'>
            {errorMessage}
          </Alert>
        )}
        <div className='float-right'>
          <Link to='#' onClick={() => history.goBack()}>
            <i className='fas fa-arrow-left mr-3' />
            Back
          </Link>
        </div>
        <div>
          <h5> {defaultValue ? 'Update' : 'Add'} Bank Details</h5>
        </div>
        <div>
          <AvForm className='form-horizontal' onValidSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup className='mb-4 text-capitalize'>
                  {defaultValue ? (
                    <AvField
                      name='userId'
                      type='text'
                      className='form-control bg-light'
                      label='Account Owner'
                      id='userId'
                      value={
                        user
                          ? `${user?.firstName} ${user?.lastName}`
                          : 'User not found'
                      }
                      required
                      disabled
                    />
                  ) : (
                    <AutoComplete
                      setUser={(e) => setSelectedUser(e)}
                      userType={'DRIVER'}
                      title='Account Owner'
                    />
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className='mb-4'>
                  <Label htmlFor='bankName'>Bank</Label>
                  <AvField
                    type='select'
                    name='bankName'
                    value={defaultValue ? defaultValue.bankName : 'loading'}
                  >
                    {bankList === null ? (
                      <option value={'loading'} default>
                        {' '}
                        Loding ...
                      </option>
                    ) : (
                      <>
                        {defaultValue ? (
                          <option value={defaultValue.bankName}>
                            {defaultValue.bankName}
                          </option>
                        ) : (
                          <option value={'loading'}>Select Your Bank</option>
                        )}
                        {bankList.result.data.map((bank) => (
                          <option key={bank.name}>{bank.name}</option>
                        ))}
                      </>
                    )}
                  </AvField>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='accountName'> Account Name</Label>

                  <AvField
                    name='accountName'
                    type='text'
                    className='form-control bg-light'
                    id='accountName'
                    value={defaultValue ? defaultValue.accountName : ''}
                    placeholder='Enter the name on the account ...'
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='accountNumber'> Account Number</Label>

                  <AvField
                    name='accountNumber'
                    type='number'
                    minLength='10'
                    maxLength='10'
                    step='1'
                    className='form-control bg-light'
                    id='accountNumber'
                    value={defaultValue ? defaultValue.accountNumber : ''}
                    required
                    errorMessage='Account number must be 10 digit'
                    placeholder='Enter the account number...'
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='isDefault'>Is this a default account?</Label>
                  <AvRadioGroup
                    inline
                    name='isDefault'
                    value={defaultValue ? defaultValue.isDefault : ''}
                  >
                    <AvRadio label='Yes' value={true} />
                    <AvRadio label='No' value={false} />
                  </AvRadioGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='note'> Comment</Label>
                  <textarea
                    className='form-control'
                    onChange={(e) => setMoreDetail(e.target.value)}
                    id='note'
                    value={moreDetail ? moreDetail : ''}
                    rows='5'
                  ></textarea>
                </FormGroup>
              </Col>
            </Row>
            {defaultValue ? (
              <button className='btn btn-success mr-2' type='submit'>
                {loading ? 'Submitting ...' : 'Update'}
              </button>
            ) : (
              <button className='btn btn-success mr-2' type='submit'>
                {loading ? 'Submitting ...' : 'Save'}
              </button>
            )}

            <Link to='#' onClick={() => history.push('/banks')}>
              <button className='btn btn-secondary'>Cancel</button>
            </Link>
          </AvForm>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, bankError, bankList } = state.Bank;
  const { user, userError } = state.Users;
  return { message, loading, bankError, user, userError, bankList };
};

export default connect(mapStateToProps, {
  createBank,
  updateBank,
  createBankFailed,
  updateBankFailed,
  fetchBankList,
  fetchUserDetails,
})(withRouter(Form));
