import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import PreviewTable from './previewTable.js';
import { useLocation } from 'react-router-dom';
import BankStatus from './offSetReward.js';
import Moment from 'moment';
import moment from 'moment-timezone';
import CheckAction from '../../../../components/Common/Summary/actionCheck';
import {
  fetchEachInvitation,
  fetchInvitationDetails,
  fetchUserDetails,
} from '../../../../store/actions';

const Invitation = ({
  invitation,
  loading,
  invitationError,
  message,
  fetchEachInvitation,
  fetchUserDetails,
  user,
  history,
}) => {
  const [status, setStatus] = useState(null);
  const [statusModal, setStatusModal] = useState(false);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  useEffect(() => {
    if (id) {
      fetchUserDetails(id);
    }
  }, [fetchUserDetails, id]);

  const showStatusModal = () => {
    setStatusModal(!statusModal);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <div className='d-flex justify-content-between align-items-center mb-2 text-right'>
            <h6>{user ? 'Referral User Profile' : null}</h6>
            <CheckAction field='Referral' iconType={'Create'}>
              <Link to='/referral_form'>
                <Button color='success' size='sm' className='font-size-12'>
                  <i className='mdi mdi-plus-circle-outline font-size-10'></i>{' '}
                  Add Referral
                </Button>
              </Link>
            </CheckAction>
          </div>
          {message && (
            <Alert color='success' className='text-center'>
              {message}
            </Alert>
          )}
          <Row>
            <Col>
              <Row>{/* <MiniWidgets date={dateFilter} /> */}</Row>

              <PreviewTable
                invitation={invitation}
                tableError={invitationError}
                history={history}
                userId={id}
                user={user}
                loading
                fetchEachInvitation={fetchEachInvitation}
                statusHandler={showStatusModal}
                message={message}
                CheckAction={CheckAction}
              />
            </Col>
          </Row>
          {/* Status Update Modal */}
          <Modal
            isOpen={statusModal}
            toggle={() => setStatusModal(!statusModal)}
          >
            <ModalHeader toggle={() => setStatusModal(false)}>
              Off Set Reward
            </ModalHeader>
            <ModalBody>
              <BankStatus user={user} statusHandler={showStatusModal} />
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { invitation, loading, invitationError, message } = state.Referral;
  const { user, userError } = state.Users;
  return {
    invitation,
    loading,
    invitationError,
    message,
    user,
    userError,
  };
};

export default connect(mapStateToProps, {
  fetchEachInvitation,
  fetchInvitationDetails,
  fetchUserDetails,
})(Invitation);
