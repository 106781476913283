import React, { useState, useEffect } from 'react';
import { Label, Input, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import {
  fetchAllUser,
  fetchUserDetails,
  fetchUserError,
  fetchClearUser,
} from '../../../store/actions';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

const Debounced_Val = 200;

const Autocomplete = ({
  title = 'Select user',
  user,
  loading,
  users,
  fetchAllUser,
  userType,
  setUser,
  defaultUserId,
  fetchUserDetails,
  fetchUserError,
  fetchClearUser,
}) => {
  const [searchUser, setUserSearch] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [userData, setUserData] = useState([]);

  const onChangeHandler = (e) => {
    setSelectedUser(e);
    setUser(e);
  };

  useEffect(() => {
    if (searchUser.length !== 0) {
      let searchInfo = { search: searchUser };
      if (userType) {
        searchInfo = { userType: userType, search: searchUser };
      }
      fetchAllUser(searchInfo);
    }
  }, [searchUser]);

  useEffect(() => {
    if (!users) {
      fetchAllUser();
    }
  }, []);

  useEffect(() => {
    if (users) {
      const usersOption = users?.result?.map((data) => {
        return {
          id: data.id,
          name: `${data.firstName} ${data.lastName} - (${data.userType})`,
          email: data.email,
        };
      });

      setUserData(usersOption);
    }
    // if (defaultUserId) {
    //   fetchUserDetails(defaultUserId);
    // }
  }, [users, defaultUserId]);

  // useEffect(() => {
  //   fetchClearUser('');
  // }, []);

  return (
    <FormGroup>
      {/* Input here  */}
      <Label className='text-capitalize'> {title} </Label>
      <ReactSearchAutocomplete
        name='userId'
        // defaultValue={selectedUser}
        value={selectedUser}
        isLoading={loading}
        items={userData}
        onSearch={(e) => setUserSearch(e)}
        onSelect={onChangeHandler}
        showNoResultsText={loading ? 'Loading...' : 'No result found'}
        autoFocus
        inputDebounce={1000}
        required={true}
        placeholder={`Please type the owner's name`}
        fuseOptions={{ keys: ['name', 'email'] }}
        resultStringKeyName='name'
        styling={{
          alignItems: 'center',
          backgroundColor: 'hsl(0,0%,100%)',
          borderColor: ' hsl(0,0%,80%)',
          borderRadius: '4px',
          borderStyle: 'solid',
          borderWidth: '1px',
          cursor: 'default',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          minHeight: '38px',
          outline: '0 !important',
          position: 'relative',
          transition: 'all 100ms',
          boxSizing: 'border-box',
        }}
      />
    </FormGroup>
  );
};

const mapStateToProps = (state) => {
  const { user, users, loading, userError } = state.Users;
  return { user, users, loading, userError };
};

export default connect(mapStateToProps, {
  fetchAllUser,
  fetchUserDetails,
  fetchUserError,
  fetchClearUser,
})(Autocomplete);
