import HttpService from './HttpService'
import { QueryReallignment } from '../utils/general'

export const GetRewardService = (query) => {
  const http = new HttpService()
  let url = 'reward/all'

  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }

  return http.getData(url)
}

export const GetEachRewardService = (id) => {
  const http = new HttpService()
  const url = `reward/${id}`
  return http.getData(url)
}

export const CreateRewardService = (payload) => {
  const http = new HttpService()
  const url = 'reward'

  return http.postData(payload, url)
}
export const UpdateRewardService = ({ payload, id }) => {
  const http = new HttpService()
  const url = `reward/${id}`

  return http.putData(payload, url)
}

export const DeleteRewardService = (id) => {
  const http = new HttpService()
  const url = `reward/${id}`
  return http.deleteData(url)
}

export const GetRewardHistoryService = (query) => {
  const http = new HttpService()
  let url = `reward/logs/all`
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}

export const GetRewardRedeemHistoryService = (query) => {
  const http = new HttpService()
  let url
  if (query) {
    url = `reward/${query.promoId}/redeem`
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }

  return http.getData(url)
}

export const fetchRewardSummaryService = (query) => {
  // Payload with query
  const http = new HttpService()
  let url = 'summary/reward'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}
