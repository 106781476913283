import {
  FETCH_LICENSE_KEYS,
  FETCH_LICENSE_KEYS_SUCCESSFUL,
  FETCH_LICENSE_KEYS_API_FAILED,
  FETCH_EACH_LICENSE_KEY,
  FETCH_EACH_LICENSE_KEY_SUCCESSFUL,
  FETCH_EACH_LICENSE_KEY_API_FAILED,
  DELETE_LICENSE_KEY,
  DELETE_LICENSE_KEY_SUCCESSFUL,
  DELETE_LICENSE_KEY_FAILED,
  CREATE_LICENSE_KEY,
  CREATE_LICENSE_KEY_SUCCESSFUL,
  CREATE_LICENSE_KEY_FAILED,
  UPDATE_LICENSE_KEY,
  UPDATE_LICENSE_KEY_SUCCESSFUL,
  UPDATE_LICENSE_KEY_FAILED,
} from './actionTypes';

const initialState = {
  licenseKeys: null,
  licenseKeyError: null,
  tableError: null,
  licenseKey: null,
  isAuthorize: null,
  message: null,
  loading: false,
};

const LicenseKey = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LICENSE_KEYS:
    case UPDATE_LICENSE_KEY:
    case DELETE_LICENSE_KEY:
      state = {
        ...state,
        licenseKeys: null,
        tableError: null,
        licenseKeyError: null,
        loading: true,
        message: null,
        isAuthorize: null,
      };
      break;
    case FETCH_LICENSE_KEYS_SUCCESSFUL:
      state = {
        ...state,
        licenseKeys: action.payload,
        tableError: null,
        licenseKeyError: null,
        loading: false,
      };
      break;

    case DELETE_LICENSE_KEY_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        licenseKeyError: null,
        message: 'LicenseKey deleted successfully',
      };
      break;

    case UPDATE_LICENSE_KEY_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        licenseKeyError: null,
        message: 'LicenseKey Updated Successfully',
      };
      break;

    case FETCH_EACH_LICENSE_KEY:
      state = {
        ...state,
        loading: true,
        licenseKeys: null,
        licenseKeyError: null,
        message: null,
        isAuthorize: null,
      };
      break;

    case FETCH_EACH_LICENSE_KEY_SUCCESSFUL:
      state = {
        ...state,
        licenseKey: action.payload,
        loading: false,
        licenseKeyError: null,
        message: null,
      };
      break;

    case CREATE_LICENSE_KEY:
      state = {
        ...state,
        loading: true,
        licenseKeyError: null,
        message: null,
      };
      break;

    case CREATE_LICENSE_KEY_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        licenseKeyError: null,
        message: `LicenseKey  created successfully`,
      };
      break;

    case FETCH_LICENSE_KEYS_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case CREATE_LICENSE_KEY_FAILED:
    case DELETE_LICENSE_KEY_FAILED:
    case FETCH_EACH_LICENSE_KEY_API_FAILED:
      state = {
        ...state,
        licenseKeys: null,
        licenseKey: null,
        loading: false,
        message: null,
        licenseKeyError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_LICENSE_KEY_FAILED:
      state = {
        ...state,
        loading: false,
        licenseKeyError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LicenseKey;
