import React, { useState, useEffect } from 'react';
import {
  Card,
  Alert,
  CardBody,
  Row,
  FormGroup,
  Col,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';

import ReactPaginate from 'react-paginate';

import Loader from '../../../../components/Common/Loading/index.js';
import Moment from 'moment';
import moment from 'moment-timezone';

import FilterDropDown from '../../../../components/Common/FilterDropDown/index.js';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../../utils/general.js';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import queryString from 'query-string';

let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const APP_VERSIONTABLE = ({
  app_versionData,
  updateApp_version,
  previewAction,
  tableError,
  fetchApp_versions,
  deleteApp_version,
  history,
  message,
  CheckAction,
  ReadCheck,
  accessControl,
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [skipPage, setSkipPage] = useState(0);
  const [menu, setMenu] = useState(false);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState({});
  const [type, setType] = useState('');

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(app_versionData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = { skip, take: dataLimit, search, type };
    fetchApp_versions(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take, search, type };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchApp_versions(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      skip,
      type
    };
    SetUrlWithParams();
    fetchApp_versions(queryParams);
  };
  useEffect(() => {
    if (app_versionData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(app_versionData?.total / take));
    }
  }, [app_versionData]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchApp_versions(parsedUrlParams);
  }, [fetchApp_versions]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      type,
      skip: pageNumber,
      take,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        // start: date.start,
        // end: date.end,
        skip,
        take,
        type
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }

    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    if (parsedUrlParams?.type) {
      setType(parsedUrlParams?.type);
    }
    return parsedUrlParams;
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          type
        };
        fetchApp_versions(queryParams);
      }, 2000);
    }
  }, [fetchApp_versions, message]);

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    setMenu(false);
    setType(values.type);
    let queryParams = {
      search,
      take,
      skip,
      start: values.startDate,
      end: values.endDate,
      type: values.type
    };
    fetchApp_versions(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setDate({
      start: "",
      end: "",
    });
    setType('')
    let queryParams = {
      search,
      take,
      skip,

    };
    fetchApp_versions(queryParams);
  };
  let accessChecking = ReadCheck(accessControl, `Configurations`, 'App Version');
  const tableDataHandler = () => {
    let rowData = app_versionData?.result?.map((data, i) => {
      return {
        keyId: data?.id,
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,

        name: (

          <>
            {accessChecking ?

              <Link
                to={`#`}
                onClick={() => previewAction(data?.id)}
                className="mr-3 text-dark font-weight-bold d-flex flex-column align-items-start"
              >
                {data?.name}

                <span className="badge badge-success rounded font-size-10 mt-2">
                  {data?.type}
                </span>
              </Link>

              :
              <div className='mr-3 text-dark font-weight-bold d-flex flex-column align-items-start'>
                {data?.name}

                <span className="badge badge-success rounded font-size-10 mt-2">
                  {data?.type}
                </span>
              </div>
            }</>
        ),
        version: (
          <div className="text-center">
            <span className="">{data?.version}</span>
            {/* <span className="rounded badge badge-soft-secondary font-size-10">
              {data?.build}
            </span> */}
          </div>
        ),
        build: (
          <div className="text-center">
            <span className="">{data?.build}</span>

          </div>
        ),
        description: data?.description ? data?.description : '---',

        date: (
          <div>
            <div>{Moment(data?.createdAt)?.format('l')}</div>
            <div className="badge badge-soft-secondary font-size-10">
              {' '}
              {Moment(data?.createdAt)?.format('hh:mm A')}
            </div>
          </div>
        ),
        action: (
          <div className='d-flex align-items-center'>
            <CheckAction field="Configurations" subModule="App Version" iconType='Edit'>
              <Link
                to={`/app_version_form?id=${data?.id}`}
                className="mr-3 text-primary"
                id="edit1"
              >
                <i className="mdi mdi-pencil font-size-18"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="edit1">
                Edit
              </UncontrolledTooltip>
              {!data.isExpired &&
                <>
                  <Link
                    to={`#`}
                    onClick={() => updateApp_version({ id: data.id, isExpired: true })}
                    className="mr-3 text-danger"
                    id="expire"
                  >
                    <i className="fas fa-power-off font-size-16"></i>
                  </Link>
                  <UncontrolledTooltip placement="top" target="expire">
                    Void Version
                  </UncontrolledTooltip></>}
            </CheckAction>
            <CheckAction field="Configurations" subModule="App Version" iconType='Delete'>
              <Link
                to="#"
                onClick={() => deleteApp_version(data?.id)}
                className="text-danger mr-3"
                id="delete1"
              >
                <i className="mdi mdi-trash-can font-size-18"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="delete1">
                Delete
              </UncontrolledTooltip>
            </CheckAction>
          </div>
        ),
      };
    });

    return {
      columns: [
        {
          text: 'Name',
          dataField: 'name',
        },
        {
          text: `Version (versionCode)`,
          dataField: 'version',
          sort: true,
          headerAlign: 'center',
          headerStyle: { width: '120px' },
        },
        {
          text: `Build (versionName)`,
          dataField: 'build',
          sort: true,
          headerAlign: 'center',
          headerStyle: { width: '122px' },
        },
        {
          text: 'Description',
          dataField: 'description',
          headerAlign: 'center',
        },

        {
          text: 'Created Date',
          dataField: 'date',
        },

        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-3">
              {tableError !== null ? (
                <Alert color="danger" className="text-center">
                  {tableError}
                </Alert>
              ) : (
                <>
                  <div className="d-flex justify-content-end mb-2">
                    <div>
                      <FilterDropDown
                        menu={menu}
                        toggleMenu={() => setMenu(!menu)}
                      >
                        <div>
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={handleDateFilter}
                          >
                            <SimpleBar
                              style={{
                                maxHeight: '250px',
                              }}
                            >
                              <Row className="px-3  pt-3 pb-0">
                                <Col md="12">
                                  <FormGroup>
                                    <AvField
                                      name="startDate"
                                      type="date"
                                      className="form-control bg-light"
                                      id="startDate"
                                      value={date.start}
                                      helpMessage="Start Date"
                                      placeholder="Please choose the start date..."

                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <FormGroup>
                                    <AvField
                                      name="endDate"
                                      type="date"
                                      className="form-control bg-light"
                                      id="endDate"
                                      value={date.end}
                                      helpMessage="End Date"
                                      placeholder="Please choose the end date..."

                                    />
                                  </FormGroup>
                                </Col>{' '}
                                <Col md='12'>
                                  <FormGroup>
                                    <AvField
                                      type='select'
                                      value={type}
                                      name='type'
                                      helpMessage='Type'
                                    >
                                      <option value=''>All</option>
                                      <option value={'IOS_DRIVER'}>IOS-DRIVER</option>
                                      <option value={'IOS_RIDER'}>IOS-RIDER</option>
                                      <option value={'ANDROID_DRIVER'}>ANDROID-DRIVER</option>
                                      <option value={'ANDROID_RIDER'}>ANDROID-RIDER</option>
                                    </AvField>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </SimpleBar>

                            <div className="p-2 d-flex justify-content-between align-items-center bg-light">
                              <Link
                                to="#"
                                onClick={resetHandler}
                                className="btn btn-sm  bg-light font-size-14"
                              >
                                <i className=" mdi mdi-refresh mr-1"> Reset</i>
                              </Link>

                              <button
                                className="btn btn-success btn-sm mr-2"
                                type="submit"
                              >
                                <i className="mdi mdi-arrow-right-circle mr-1"></i>
                                Send
                              </button>
                            </div>
                          </AvForm>
                        </div>
                      </FilterDropDown>
                    </div>
                  </div>
                  {!isLoading && data !== null ? (
                    <>
                      <div className="d-flex justify-content-between mb-2">
                        <div>
                          <Input
                            bsSize="sm"
                            id="take"
                            name="take"
                            type="select"
                            onChange={handleLimitChange}
                            value={take}
                          >
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                          </Input>
                        </div>
                        <div>
                          <Input
                            bsSize="sm"
                            id="search"
                            value={search}
                            name="search"
                            onChange={SearchHandler}
                            placeholder="Search"
                          />
                        </div>
                      </div>
                      <BootstrapTable
                        keyField="keyId"
                        data={data.rows}
                        columns={data.columns}
                        striped
                        hover
                        wrapperClasses="table-responsive"
                        noDataIndication={'No Data to Display'}
                        defaultSorted={[{ dataField: 'index', order: 'desc' }]}
                      // sort={{ dataField: 'name', order: 'asc' }}
                      />
                      <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        // breakLabel={'...'}
                        // marginPagesDisplayed={2}
                        // pageRangeDisplayed={3}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        forcePage={skipPage}
                        //Bootstrap styling
                        containerClassName={'pagination justify-content-center'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        activeClassName={'active'}
                      />
                    </>
                  ) : (
                    <Loader loading={isLoading} />
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default APP_VERSIONTABLE;
