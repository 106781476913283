import HttpService from './HttpService'
import { QueryReallignment } from '../utils/general'

export const GetComplaintService = (query) => {
  const http = new HttpService()
  let url = 'Complaint/all'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}

export const GetEachComplaintService = (id) => {
  const http = new HttpService()
  const url = `complaint/${id}`
  return http.getData(url)
}
export const GetMyComplaintService = (query) => {
  const http = new HttpService()
  let url = `complaint`
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}
export const CreateComplaintService = (payload) => {
  const http = new HttpService()
  const url = 'complaint'
  return http.postData(payload, url)
}
export const UpdateComplaintService = (payload) => {
  const http = new HttpService()
  const url = `complaint/${payload.id}`
  return http.putData(payload, url)
}

export const DeleteComplaintService = (id) => {
  const http = new HttpService()
  const url = `complaint/${id}`
  return http.deleteData(url)
}

export const AssignComplaintService = (payload) => {
  const http = new HttpService()
  const body = { assignTo: payload.assignTo }
  const url = `complaint/${payload.id}/assign`
  return http.putData(body, url)
}

export const fetchComplaintSummaryService = (query) => {
  // Payload with query
  const http = new HttpService()
  let url = 'summary/complaint'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}
