/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { Alert, Row, Col, Table } from 'reactstrap';
import Loader from '../../../components/Common/Loading/index.js';
import { fetchEachBank } from '../../../store/actions';
import { Link } from 'react-router-dom';

const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const Bank = ({
  bankPreview,
  previewId,
  loading,
  bankError,
  fetchEachBank,
}) => {
  useEffect(() => {
    fetchEachBank(previewId);
  }, [fetchEachBank, previewId]);

  return (
    <React.Fragment>
      <div className='mt-3'>
        {bankError && !loading ? (
          <Alert color='danger' className='text-center'>
            {bankError}
          </Alert>
        ) : (
          <>
            {bankPreview === null ? (
              <Loader />
            ) : (
              <Row>
                <Col sm={12}>
                  <div>
                    {bankPreview?.note ? (
                      <center className='mb-4'>{bankPreview.note}</center>
                    ) : null}
                    <Table hover>
                      <tbody>
                        <tr>
                          <td className='font-weight-bold'>Account Name</td>
                          <td className='text-uppercase'>
                            {bankPreview?.accountName}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Bank</td>
                          <td className='text-uppercase'>
                            {bankPreview?.bankName}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Account Number</td>
                          <td>{bankPreview?.accountNumber}</td>
                        </tr>

                        <tr>
                          <td className='font-weight-bold'>Account Owner</td>
                          <td className='text-capitalize'>
                            {' '}
                            {bankPreview?.user
                              ? `${bankPreview?.user?.firstName} ${bankPreview?.user?.lastName}`
                              : 'User not found'}
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    <hr />
                    <Link
                      to={`/user/${bankPreview?.user?.id}`}
                      className='mr-3 mt-4 text-primary'
                    >
                      <Row className='mt-2 m-2 align-items-center border shadow rounded p-2'>
                        <img
                          className='rounded-circle avatar-md border shadow-lg p-2 ml-2'
                          width='100%'
                          alt='Profile'
                          src={
                            bankPreview?.user.picture
                              ? bankPreview?.user.picture
                              : avatarImg
                          }
                        />
                        <Col sm={8}>
                          {' '}
                          <h4 className='font-weight-bold font-size-12 mt-1 mb-1 text-capitalize'>
                            {bankPreview?.user.firstName}{' '}
                            {bankPreview?.user.lastName}
                            <span className='text-muted font-size-10'>
                              <span className='mx-2'> | </span>
                              <span className='mr-2'>
                                <i className=' fas fa-star text-warning mr-1' />
                                {bankPreview?.user.rating}
                              </span>
                              <span className='text-muted'>
                                ({bankPreview?.user.raters}{' '}
                                <i className='fas fa-user font-size-10 mr-1 text-muted' />
                                )
                              </span>
                            </span>
                          </h4>
                          <div className='mb-2'>
                            <span>
                              {bankPreview?.user.account.status ===
                                'SANCTION' && (
                                <span>
                                  {' '}
                                  /{' '}
                                  <span
                                    className={`badge badge-soft-warning font-size-11`}
                                  >
                                    SANCTION
                                  </span>
                                </span>
                              )}
                            </span>
                            <span className='text-muted font-size-10 font-weight-bold'>
                              {bankPreview?.user.userType}
                            </span>
                          </div>
                          <div className='d-flex justify-content-between'>
                            <h5 className='font-weight-bold badge badge-soft-secondary rounded'>
                              <i className='ri-vip-crown-2-line mr-1 text-warning' />
                              {bankPreview?.user.loyalty?.tier}
                            </h5>
                            <span>
                              <span
                                className={`badge badge-soft-${
                                  bankPreview.status.toLowerCase() === 'active'
                                    ? 'success'
                                    : bankPreview.status.toLowerCase() ===
                                      'inactive'
                                    ? 'danger'
                                    : bankPreview.status.toLowerCase() ===
                                      'sanction'
                                    ? 'warning'
                                    : 'dark'
                                } font-size-12`}
                              >
                                {bankPreview.status && bankPreview.status}
                              </span>
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { bankPreview, loading, bankError } = state.Bank;
  return { bankPreview, loading, bankError };
};

export default connect(mapStateToProps, { fetchEachBank })(Bank);
