import React, { Component } from 'react'
import AlartError from '../../../components/Common/AlertCard'
import {
  Row,
  Col,
  Button,
  Alert,
  Card,
  CardBody,
  Container,
  Label,
  FormGroup,
} from 'reactstrap'

// Redux
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import queryString from 'query-string'

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'

// actions
import { checkLogin, googleLogin, apiError } from '../../../store/actions'

// import images
import logosmdark from '../../../assets/images/nrydeLogo.png'
import googleLogo from '../../../assets/images/google.png'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = { username: '', password: '', headerSet: 'admin' }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setHeader = this.setHeader.bind(this)
    this.GoogleLoginHandler = this.GoogleLoginHandler.bind(this)
  }

  handleSubmit(event, values) {
    localStorage.setItem('headerType', this.state.headerSet)
    this.props.checkLogin(values, this.props.history)
  }

  setHeader(value) {
    console.log(value)
    this.setState((prev) => ({
      ...prev,
      headerSet: value,
    }))
  }

  componentDidMount() {
    let params = queryString.parse(this.props.location.search)

    if (params.accessToken) {
      let newToken = {
        accessToken: params.accessToken.trim(),
        refreshToken: params.refreshToken.trim(),
      }
      this.props.googleLogin(newToken, this.props.history)
    }
    this.props.apiError('')
    document.body.classList.add('auth-body-bg')
  }

  componentWillUnmount() {
    // this.props.apiError('');
    document.body.classList.remove('auth-body-bg')
  }

  GoogleLoginHandler() {
    const url = `${process.env.REACT_APP_BASE_URL}auth/google`
    window.location.replace(url)
    // this.props.googleLogin()
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div>

        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={12}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={4}>
                        <Card className="shadow-lg">
                          <CardBody>
                            <div>
                              <div className="text-center">
                                <div>
                                  <Link to="/" className="logo">
                                    <img
                                      src={logosmdark}
                                      height="50"
                                      alt="logo"
                                    />
                                  </Link>
                                </div>

                                <h4 className="font-size-18 mt-4">
                                  Welcome Back !
                                </h4>
                                <p className="text-muted">
                                  Sign in to continue to nRyde.
                                </p>
                              </div>
                              {this.props.loginError &&
                              this.props.loginError ? (
                                <AlartError error={this.props.loginError} />
                              ) : null}
                              {this.props.activationError && (
                                <>
                                  <AlartError
                                    error={this.props.activationError}
                                  />
                                  <div className="text-center">
                                    <Link to="/resend-activation">
                                      Click to resend activation
                                    </Link>
                                  </div>
                                </>
                              )}

                              <div className="p-2 mt-3">
                                <AvForm
                                  className="form-horizontal"
                                  onValidSubmit={this.handleSubmit}
                                >
                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-user-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="username">Email</Label>
                                    <AvField
                                      name="username"
                                      value={this.state.username}
                                      type="text"
                                      className="form-control"
                                      id="username"
                                      validate={{ email: true, required: true }}
                                      placeholder="Enter username"
                                    />
                                  </FormGroup>

                                  <FormGroup className="auth-form-group-custom mb-4">
                                    <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                    <Label htmlFor="userpassword">
                                      Password
                                    </Label>
                                    <AvField
                                      name="password"
                                      value={this.state.password}
                                      type="password"
                                      className="form-control"
                                      id="userpassword"
                                      placeholder="Enter password"
                                    />
                                  </FormGroup>

                                  <div className="mt-4 mb-3 text-center">
                                    <Button
                                      color="primary"
                                      className="w-100 waves-effect waves-light"
                                      type="submit"
                                      disabled={this.props.loading}
                                    >
                                      {this.props.loading
                                        ? 'Loading...'
                                        : 'Login'}
                                    </Button>
                                  </div>

                                  <div className="row">
                                    <div className="text-center col-12 col-sm-6 order-2 order-sm-1 mt-1">
                                      <Button
                                        color="white"
                                        className="w-100 waves-effect waves-light btn-outline-primary"
                                        onClick={this.GoogleLoginHandler}
                                      >
                                        <img
                                          src={googleLogo}
                                          alt="google"
                                          height="24"
                                          className="mr-2"
                                        />
                                        Google
                                      </Button>
                                    </div>
                                    <div className="col-12 col-sm-6 mt-1  order-1 order-sm-2">
                                      <select
                                        className="custom-select custom-select-md"
                                        onChange={(e) =>
                                          this.setHeader(e.target.value)
                                        }
                                      >
                                        <option value="admin">Admin</option>
                                        <option value="rider">Rider</option>
                                        <option value="driver">Driver</option>
                                        <option value="inspector">
                                          Inspector
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </AvForm>
                              </div>
                              <div className="mt-4 text-center">
                                <Link
                                  to="/forgot-password"
                                  className="text-muted"
                                >
                                  <i className="mdi mdi-lock mr-1"></i> Forgot
                                  your password?
                                </Link>
                              </div>
                              <div className="mt-5 text-center">
                                <p>
                                  Don't have an account ?{' '}
                                  <Link
                                    to="/register"
                                    className="font-weight-medium text-primary"
                                  >
                                    {' '}
                                    Register{' '}
                                  </Link>{' '}
                                </p>
                                <p>© 2022 nRyde.</p>
                                <p>
                                  Version : {process.env.REACT_APP_ENV}{' '}
                                  {process.env.REACT_APP_SITE_VERSION}
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { loginError, loading, activationError } = state.Account
  return { loginError, loading, activationError }
}

export default withRouter(
  connect(mapStateToProps, { checkLogin, apiError, googleLogin })(Login),
)
