import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_PAYMENT,
  FETCH_PAYMENT_LIST,
  FETCH_EACH_PAYMENT,
  FETCH_PAYMENT_SUMMARY,
  CREATE_PAYMENT,
  GET_PAYMENT_RECIPIENT,
  DELETE_PAYMENT,
  UPDATE_PAYMENT,
  FETCH_FOR_PAYMENT_CSV,
} from './actionTypes';
import {
  fetchPaymentListSuccessful,
  fetchPaymentListError,
  fetchPaymentSuccessful,
  fetchPaymentError,
  fetchPaymentSummaryFailed,
  fetchPaymentSummarySuccessfully,
  fetchEachPaymentSuccessful,
  fetchEachPaymentError,
  deletePaymentSuccessful,
  deletePaymentFailed,
  getRecipientFailed,
  getRecipientSuccessful,
  createPaymentSuccessful,
  createPaymentFailed,
  updatePaymentSuccessful,
  updatePaymentFailed,
  fetchPaymentCSVSuccessfully,
  fetchPaymentCSVError,
} from './actions';

import {
  GetPaymentService,
  GetEachPaymentService,
  DeletePaymentService,
  CreatePaymentService,
  fetchPaymentSummaryService,
  UpdatePaymentService,
  GetPaymentRecipientService,
} from '../../services/paymentService';

function* fetchPaymentHandler({ payload }) {
  try {
    const response = yield call(GetPaymentService, payload);
    yield put(fetchPaymentSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchPaymentError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchPaymentError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchPaymentError(error?.response?.data?.message));
    }
  }
}

function* fetchEachPaymentHandler({ payload }) {
  try {
    const response = yield call(GetEachPaymentService, payload);
    yield put(fetchEachPaymentSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachPaymentError(error?.response?.data?.message));
  }
}

function* deletePaymentHandler({ payload }) {
  try {
    const response = yield call(DeletePaymentService, payload);
    yield put(deletePaymentSuccessful(response.data.result));
  } catch (error) {
    yield put(deletePaymentFailed(error?.response?.data?.error));
  }
}

function* createPaymentHandler({ payload }) {
  try {
    const response = yield call(CreatePaymentService, payload);
    yield put(createPaymentSuccessful(response.data.result));
  } catch (error) {
    yield put(createPaymentFailed(error?.response?.data?.message));
  }
}

function* updatePaymentHandler({ payload }) {
  try {
    const response = yield call(UpdatePaymentService, payload);
    yield put(updatePaymentSuccessful(response.data.result));
  } catch (error) {
    yield put(updatePaymentFailed(error?.response?.data?.message));
  }
}

function* getRecipientHandler({ payload }) {
  try {
    const response = yield call(GetPaymentRecipientService, payload);
    yield put(getRecipientSuccessful(response.data.result));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        getRecipientFailed(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        getRecipientFailed(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(getRecipientFailed(error?.response?.data?.message));
    }
  }
}

// Payment SUMMARY
function* fetchPaymentSummaryHandler({ payload }) {
  try {
    const response = yield call(fetchPaymentSummaryService, payload);
    yield put(fetchPaymentSummarySuccessfully(response.data));
  } catch (error) {
    yield put(fetchPaymentSummaryFailed(error.response.data));
  }
}

function* fetchPaymentCSVHandler({ payload }) {
  try {
    const response = yield call(GetPaymentService, payload);
    yield put(fetchPaymentCSVSuccessfully(response.data));
  } catch (error) {
    yield put(fetchPaymentCSVError(error?.response?.data?.message));
  }
}

export function* watchFetchPayment() {
  yield takeEvery(FETCH_PAYMENT, fetchPaymentHandler);
}

export function* watchFetchEachPayment() {
  yield takeEvery(FETCH_EACH_PAYMENT, fetchEachPaymentHandler);
}
export function* watchDeletePayment() {
  yield takeEvery(DELETE_PAYMENT, deletePaymentHandler);
}
export function* watchCreatePayment() {
  yield takeEvery(CREATE_PAYMENT, createPaymentHandler);
}
export function* watchUpdatePayment() {
  yield takeEvery(UPDATE_PAYMENT, updatePaymentHandler);
}
export function* watchGetPaymentRecipient() {
  yield takeEvery(GET_PAYMENT_RECIPIENT, getRecipientHandler);
}
export function* watchFetchPaymentSummary() {
  yield takeEvery(FETCH_PAYMENT_SUMMARY, fetchPaymentSummaryHandler);
}

export function* watchPaymentCSV() {
  yield takeEvery(FETCH_FOR_PAYMENT_CSV, fetchPaymentCSVHandler);
}

function* PaymentSaga() {
  yield all([
    fork(watchFetchPayment),
    fork(watchFetchEachPayment),
    fork(watchDeletePayment),
    fork(watchFetchPaymentSummary),
    fork(watchCreatePayment),
    fork(watchUpdatePayment),
    fork(watchGetPaymentRecipient),
    fork(watchPaymentCSV),
  ]);
}

export default PaymentSaga;
