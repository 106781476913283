import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import GreySummaryCard from '../../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../../components/Common/Summary/actionCheck';
import DocTypeTable from './docTypeTable';
import DocType from './previewDocType';

import { fetchDocType, deleteDocType } from '../../../../store/actions';

const DocumentType = ({
  docType,
  fetchDocType,
  docTypeError,
  deleteDocType,
  loading,
  message,
  isAuthorize,
  accessControl
}) => {
  const [showModal, setShowModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const breadcrumbItems = [
    { title: 'Configuration', link: '#' },
    { title: 'Document Type', link: '#' },
  ];

  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };

  useEffect(() => {
    fetchDocType();
  }, [fetchDocType]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        fetchDocType();
      }, 2000);
    }
  }, [fetchDocType, message]);
  const deleteDocTypeModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteDocType(deleteId);
    setDeleteId(null);
  };
  return (
    <React.Fragment>
      <div className="page-content documentType">
        <Container fluid>
          <Breadcrumbs
            title="Document Type"
            breadcrumbItems={breadcrumbItems}
          />
          <GreySummaryCard field="Configurations" subModule="Document Type">
            {isAuthorize ? (
              <Alert color="danger" className="text-center">
                {isAuthorize}
              </Alert>
            ) : (
              <>
                <CheckAction
                  field="Configurations"
                  subModule="Document Type"
                  iconType={"Create"}
                >
                  <div className="mb-2 text-right">
                    <Link to="/document_type_form">
                      <Button color="success" size="sm">
                        <i className="mdi mdi-plus-circle-outline font-size-16"></i>{" "}
                        Create Document Type
                      </Button>
                    </Link>
                  </div>
                </CheckAction>
                {message && (
                  <Alert color="success" className="text-center">
                    {message}
                  </Alert>
                )}
                {docTypeError && (
                  <Alert color="danger" className="text-center">
                    {docTypeError}
                  </Alert>
                )}
                
                <Row>
                  <Col>
                    <DocTypeTable
                      docTypeData={docType}
                      loading={loading}
                      deleteDocType={deleteDocTypeModal}
                      fetchDocType={fetchDocType}
                      previewAction={previewHandler}
                      CheckAction={CheckAction}
                      ReadCheck={ReadCheck}
                      accessControl={accessControl}
                    />
                  </Col>
                </Row>
                <Modal
                  isOpen={showModal}
                  toggle={() => setShowModal(!showModal)}
                >
                  <ModalHeader toggle={() => setShowModal(false)}>
                    Preview Document Type
                  </ModalHeader>
                  <ModalBody>
                    <DocType previewId={previewId} />
                  </ModalBody>
                </Modal>
                <Modal
                  isOpen={showDeleteModal}
                  toggle={() => setShowDeleteModal(!showDeleteModal)}
                >
                  <ModalBody>
                    <h4 className="card-title mb-4 text-center">
                      {" "}
                      Are you sure you want to delete this Document Type
                    </h4>
                    <button
                      onClick={() => deleteHandler()}
                      className="btn btn-outline-danger btn-sm w-100"
                    >
                      Click to Delete
                    </button>
                  </ModalBody>
                </Modal>
              </>
            )}
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { docType, loading, docTypeError, message, isAuthorize } =
    state.DocType;
  return { accessControl, docType, loading, docTypeError, message, isAuthorize };
};

export default connect(mapStateToProps, { fetchDocType, deleteDocType })(
  DocumentType
);
