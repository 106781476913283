import {
  FETCH_ROUTES,
  FETCH_ROUTES_SUCCESSFUL,
  FETCH_ROUTES_API_FAILED,
  FETCH_EACH_ROUTE,
  FETCH_EACH_ROUTE_SUCCESSFUL,
  FETCH_EACH_ROUTE_API_FAILED,
  DELETE_ROUTE,
  DELETE_ROUTE_SUCCESSFUL,
  DELETE_ROUTE_FAILED,
  CREATE_ROUTE,
  CREATE_ROUTE_SUCCESSFUL,
  CREATE_ROUTE_FAILED,
  UPDATE_ROUTE,
  UPDATE_ROUTE_SUCCESSFUL,
  UPDATE_ROUTE_FAILED,
} from './actionTypes';

export const fetchRoutes = (payload) => {
  return {
    type: FETCH_ROUTES,
    payload,
  };
};

export const fetchRoutesSuccessful = (payload) => {
  return {
    type: FETCH_ROUTES_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchRoutesError = (error) => {
  return {
    type: FETCH_ROUTES_API_FAILED,
    payload: error,
  };
};

export const fetchEachRoute = (id) => {
  return {
    type: FETCH_EACH_ROUTE,
    payload: id,
  };
};

export const fetchEachRouteSuccessful = (payload) => {
  return {
    type: FETCH_EACH_ROUTE_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachRouteError = (error) => {
  return {
    type: FETCH_EACH_ROUTE_API_FAILED,
    payload: error,
  };
};

export const deleteRoute = (id) => {
  return {
    type: DELETE_ROUTE,
    payload: id,
  };
};

export const deleteRouteSuccessful = (payload) => {
  return {
    type: DELETE_ROUTE_SUCCESSFUL,
    payload,
  };
};

export const deleteRouteFailed = (error) => {
  return {
    type: DELETE_ROUTE_FAILED,
    payload: error,
  };
};

export const createRoute = (payload) => {
  return {
    type: CREATE_ROUTE,
    payload,
  };
};

export const createRouteSuccessful = (payload) => {
  return {
    type: CREATE_ROUTE_SUCCESSFUL,
    payload,
  };
};

export const createRouteFailed = (error) => {
  return {
    type: CREATE_ROUTE_FAILED,
    payload: error,
  };
};

export const updateRoute = (payload) => {
  return {
    type: UPDATE_ROUTE,
    payload,
  };
};

export const updateRouteFailed = (error) => {
  return {
    type: UPDATE_ROUTE_FAILED,
    payload: error,
  };
};

export const updateRouteSuccessful = (payload) => {
  return {
    type: UPDATE_ROUTE_SUCCESSFUL,
    payload,
  };
};
