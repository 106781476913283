import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col, Input } from 'reactstrap';
import Moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';

import ReactPaginate from 'react-paginate';

import Loader from '../../../../components/Common/Loading/index.js';

import { ScrollToTop } from '../../../../utils/general';

let skip = 0;
let dataLimit = 10;
const Table = ({
  cashoutAnalyticsPreview,
  previewId,
  FetchEachCashoutAnalytics,
  message,
  cashoutAnalyticsError,
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [skipPage, setSkipPage] = useState(0);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(cashoutAnalyticsPreview?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      ...previewId.date,
    };
    let id = previewId.id;
    FetchEachCashoutAnalytics({ id, date: pageInfo });
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take, ...previewId.date };
    let id = previewId.id;
    setSkipPage(Math.ceil(pageNumber / take));
    FetchEachCashoutAnalytics({ id, date: pageInfo });
    ScrollToTop();
  };

  useEffect(() => {
    if (cashoutAnalyticsError) {
      setIsLoading(false);
    }
  }, [cashoutAnalyticsError]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let pageInfo = {
          take,
          skip,
          ...previewId.date,
        };

        let id = previewId.id;
        FetchEachCashoutAnalytics({ id, date: pageInfo });
      }, 2000);
    }
  }, [FetchEachCashoutAnalytics, message]);

  useEffect(() => {
    if (cashoutAnalyticsPreview) {
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(cashoutAnalyticsPreview?.breakdown?.total / take));
    }
  }, [cashoutAnalyticsPreview]);

  const tableDataHandler = () => {
    const rowData = cashoutAnalyticsPreview?.breakdown?.result.map(
      (data, i) => {
        return {
          index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,

          date: Moment(data.dateTime).format('YYYY-MM-DD'),
          online: data?.total,
        };
      }
    );
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
        },
        {
          text: 'Date',
          dataField: 'date',
        },

        {
          text: 'Online hours(mins)',
          dataField: 'online',
        },
      ],
      rows: rowData,
    };
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          {!cashoutAnalyticsError && (
            <Card>
              <CardBody className="pt-3">
                {!isLoading ? (
                  <>
                    <h4>
                      {' '}
                      Total Hours Online :{
                        cashoutAnalyticsPreview?.onlineHours
                      }{' '}
                    </h4>
                    <div className="d-flex justify-content-between mb-2">
                      <div>
                        <Input
                          bsSize="sm"
                          id="take"
                          name="take"
                          type="select"
                          onChange={handleLimitChange}
                          value={take}
                        >
                          <option>10</option>
                          <option>25</option>
                          <option>50</option>
                        </Input>
                      </div>
                    </div>
                    <BootstrapTable
                      keyField="id"
                      data={data.rows}
                      columns={data.columns}
                      striped
                      hover
                      wrapperClasses="table-responsive"
                      noDataIndication={'No Data to Display'}
                    />
                    <ReactPaginate
                      previousLabel={'Previous'}
                      nextLabel={'Next'}
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      forcePage={skipPage}
                      containerClassName={'pagination justify-content-center'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      activeClassName={'active'}
                    />
                  </>
                ) : (
                  <Loader loading={isLoading} />
                )}
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Table;
