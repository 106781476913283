import React, { useEffect, useState } from 'react';
import Form from '../form';

const RoleForm = ({
  data,
  updateData,
  createHandler,
  createAccessControlFEError,
}) => {
  return (
    <React.Fragment>
      <div>
        <Form
          updateValue={updateData}
          // data={data}
          createHandler={createHandler}
          createAccessControlFEError={createAccessControlFEError}
        />
      </div>
    </React.Fragment>
  );
};

export default RoleForm;
