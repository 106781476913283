import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Col, Card, CardBody, Media } from 'reactstrap'
import { connect } from 'react-redux'
import { fetchPaymentSummary } from '../../../../store/actions'

const MiniWidgets = ({
  summaryError,
  paymentSummary,
  fetchPaymentSummary,
  start,
  end,
  loading,
  defaultIcon,
  date,
}) => {
  useEffect(() => {
    if (date.end) {
      fetchPaymentSummary(date)
    } else {
      fetchPaymentSummary()
    }
  }, [date, fetchPaymentSummary])

  const reports = [
    {
      icon: defaultIcon ? defaultIcon : 'ri-bank-line',
      title: 'Total Inflow',
      value: `${paymentSummary?.result?.totalInflow?.toLocaleString('en-US')}`,
      rate: `${
        paymentSummary?.previousRegisteredDrivers
          ? paymentSummary?.previousRegisteredDrivers
          : '0'
      }%`,
      color: 'primary',
      desc: 'From previous period',
    },
    {
      icon: 'fas fa-ban  ',
      title: 'Net Earning',
      value: `${paymentSummary?.result?.netEarnings?.toLocaleString('en-US')}`,
      rate: `${
        paymentSummary?.previousRegisteredUsers
          ? paymentSummary?.previousRegisteredUsers
          : '0'
      }%`,
      color: 'secondary',
      desc: 'From previous period',
    },
    {
      icon: 'ri-wallet-3-line',
      title: 'Total Outflow',
      value: paymentSummary?.result?.totalOutflow?.toLocaleString('en-US'),
      rate: `${
        paymentSummary?.previousActiveDrivers
          ? paymentSummary?.previousActiveDrivers
          : '0'
      }%`,
      color: 'success',
      desc: 'From previous period',
    },
    {
      icon: 'fas fa-exclamation-triangle',
      title: 'Owning nRyde',
      value: `${paymentSummary?.result?.owingnRyde?.toLocaleString('en-US')}`,
      rate: `${
        paymentSummary?.previousActiveUsers
          ? paymentSummary?.previousActiveUsers
          : '0'
      }%`,
      color: 'danger',
      desc: 'From previous period',
      updatedTime: `${paymentSummary?.time}`,
    },
  ];

  return (
    <React.Fragment>
      {summaryError === null &&
        reports.map((report, key) => (
          <Col key={key} md={3}>
            <Card>
              <CardBody>
                <Media>
                  <Media body className="overflow-hidden">
                    <span
                      className={`text-${report.color} badge badge-soft-${report.color} rounded`}
                    >
                      <i className={report.icon + ' font-size-16'}></i>
                    </span>
                    <p className="text-truncate font-size-14 mt-4 mb-2 ">
                      {report.title}
                    </p>
                    <h2 className="mt-0 mb-0 font-size-">
                      {paymentSummary === null ? (
                        <Skeleton duration={2} width="20%" />
                      ) : (
                        `₦${report.value}`
                      )}
                    </h2>
                    {paymentSummary?.isCache && report?.updatedTime && (
                      <p
                        className="font-size-12 text-primary"
                        style={{ position: 'absolute', right: '11px' }}
                      >
                        Updated at: {report?.updatedTime?.split(',')?.pop()}
                      </p>
                    )}
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
        ))}
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { summaryError, paymentSummary } = state.Payment
  return { summaryError, paymentSummary }
}

export default connect(mapStateToProps, { fetchPaymentSummary })(MiniWidgets)
