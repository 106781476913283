import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
  UncontrolledTooltip,
} from 'reactstrap'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from 'availity-reactstrap-validation'
import {
  approveUserAccount,
  approveUserAccountError,
} from '../../../store/actions'
const Form = ({
  message,
  errorMessage,
  loading,
  approveUserAccount,
  approveUserAccountError,
  setapproveUser,
  userId,
}) => {
  const handleSubmit = async (event, values) => {
    approveUserAccount(values, userId)
  }

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        approveUserAccountError('')
        setapproveUser(false)
      }, 2000)
    }
  }, [message])

  return (
    <div>
      {' '}
      <Card>
        <CardBody>
          {message && (
            <Alert color="success" className="text-center">
              {message}
            </Alert>
          )}
          {errorMessage && (
            <Alert color="danger" className="text-center">
              {errorMessage}
            </Alert>
          )}
          <div className="col-md-12">
            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col>
                  <FormGroup>
                    {/* Input here  */}
                    <div className="d-flex align-items-center mb-1">
                      <Label htmlFor="role" className="m-0">
                        Badge
                      </Label>
                      <i
                        className="mdi mdi-alert-circle-outline ml-2 font-size-16"
                        id="priorityTooltip"
                      ></i>
                      <UncontrolledTooltip
                        placement="top"
                        target="priorityTooltip"
                      >
                        Select badge to attached to Account
                      </UncontrolledTooltip>
                    </div>
                    <AvField type="select" name="badge" value="VERIFIED">
                      <option>VERIFIED</option>
                      <option>VERIFIED_GOVERNMENT</option>
                      <option>VERIFIED_AMBASSADOR</option>
                      <option>VERIFIED_BEREKETE</option>
                      <option>VERIFIED_STUDENT</option>
                      <option>VERIFIED_UNION</option>
                    </AvField>
                  </FormGroup>

                  <FormGroup>
                    {/* Input here  */}
                    <div className="d-flex align-items-center mb-1">
                      <Label htmlFor="role" className="m-0">
                        Status
                      </Label>
                    </div>
                    <AvField type="select" name="verification" value="PENDING">
                      <option>PENDING</option>
                      <option>SUBMITTED</option>
                      <option>REVIEW</option>
                      <option>APPROVED</option>
                      <option>REJECTED</option>
                      <option>COMMENT</option>
                    </AvField>
                  </FormGroup>
                </Col>
              </Row>

              <button
                disabled={loading}
                className="btn btn-success mr-2"
                type="submit"
              >
                {loading ? 'Submitting ...' : 'Submit'}
              </button>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { user, message, errorMessage, loading } = state.Users
  return { user, message, loading, errorMessage }
}

export default connect(mapStateToProps, {
  approveUserAccount,
  approveUserAccountError,
})(withRouter(Form))
