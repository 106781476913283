import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';

import ReactPaginate from 'react-paginate';

import Loader from '../../../components/Common/Loading/index.js';

import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../utils/general';
import queryString from 'query-string';

let skip = 0;
let dataLimit = 10;

const NotificationTable = ({
  notifications,
  previewAction,
  fetchNotification,
  deleteNotification,
  message,
  history,
  user,
  CheckAction,
  ReadCheck,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [skipPage, setSkipPage] = useState(0);
  const [isMyNotification, setIsMyNotification] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(notifications?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      isMyNotification: isMyNotification,
    };
    fetchNotification(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take, isMyNotification };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchNotification(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      skip,
      isMyNotification,
    };
    fetchNotification(queryParams);
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          isMyNotification,
        };
        fetchNotification(queryParams);
      }, 2000);
    }
  }, [fetchNotification, message]);
  useEffect(() => {
    if (notifications) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(notifications?.total / take));
    }
  }, [notifications]);

  const ChangeNoticeHandler = (e) => {
    let result = true;
    if (e.target.value === '1') {
      setIsMyNotification(true);
    } else {
      setIsMyNotification('');
      result = '';
    }
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      isMyNotification: result,
    };
    fetchNotification(pageInfo);
  };
  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchNotification(parsedUrlParams);
  }, [fetchNotification]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      take,
      skip: pageNumber,
      isMyNotification,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        take,
        skip,
        isMyNotification,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }

    if (parsedUrlParams?.isMyNotification) {
      setIsMyNotification(parsedUrlParams?.isMyNotification);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };
  let accessChecking = ReadCheck(accessControl, `Custom Notification`, 'General Notification');
  const tableDataHandler = () => {
    const rowData = notifications?.result?.map((data) => {
      return {
        title: (

          <>
            {accessChecking ?

              <Link
                to='#'
                className='mr-3 text-dark'
                onClick={() => previewAction(data.id)}
                id='preview'
              >
                <div className='text-capitalize'> {data.title} </div>
              </Link>

              :
              <div className='mr-3 text-dark font-weight-bold'>
                <div className='text-capitalize'> {data.title} </div>
              </div>
            }
          </>
        ),
        text: data.text,
        // text: (
        //   <div className="text-truncate" style={{ width: '200px' }}>
        //     {' '}
        //     {data.text}{' '}
        //   </div>
        // ),
        type: data.type,
        year: data.year,
        color: data.color,
        seats: data.seats,
        hasSeen: (
          <span
            className={`badge badge-soft-${data.hasSeen ? 'success' : 'danger'
              } font-size-12`}
          >
            {data.hasSeen ? 'Yes' : 'No'}
          </span>
        ),
        actionUrl: data.actionUrl ? (
          <button className='btn btn-outline-primary btn-sm rounded'>
            <a href={data.actionUrl} target='_blank'>
              Action
            </a>
          </button>
        ) : (
          'Null'
        ),
        action: (
          <>

            {/* <Link
              to='#'
              className='mr-3 text-primary'
              onClick={() => previewAction(data.id)}
              id='preview'
            >
              <i className='mdi mdi-eye p-2 font-size-18 text-secondary mb-2'></i>
            </Link>
            <UncontrolledTooltip
              placement='top'
              className='p-0 bg-light'
              target='preview'
            >
              Preview
            </UncontrolledTooltip> */}
            {user && user.userType === 'ADMIN' && (
              <div className='d-flex align-items-center'>
                <CheckAction
                  field='Custom Notification'
                  subModule='General Notification'
                  iconType={'Edit'}
                >
                  <Link
                    to={`/create_notification?id=${data.id}`}
                    className='mr-3 text-primary'
                    id='edit1'
                  >
                    <i className='mdi mdi-pencil font-size-18'></i>
                  </Link>
                  <UncontrolledTooltip placement='top' target='edit1'>
                    Edit
                  </UncontrolledTooltip>
                </CheckAction>
                <CheckAction
                  field='Custom Notification'
                  subModule='General Notification'
                  iconType={'Delete'}
                >
                  <Link
                    to='#'
                    onClick={() => deleteNotification(data.id)}
                    className='mr-3 text-danger'
                    id='delete1'
                  >
                    <i className='mdi mdi-trash-can font-size-18'></i>
                  </Link>
                  <UncontrolledTooltip placement='top' target='delete1'>
                    Delete
                  </UncontrolledTooltip>
                </CheckAction>
              </div>
            )
            }

          </>),
      };
    });
    return {
      columns: [
        // {
        //   dataField: 'sl.no',
        //   text: 'S.No',
        //   formatter: (cell, row, rowIndex) => {
        //     return rowIndex + 1;
        //   },
        //   sort: true,
        // },
        {
          text: 'Title',
          dataField: 'title',
          sort: true,
        },
        {
          text: 'Text',
          dataField: 'text',
        },
        {
          text: 'Type',
          dataField: 'type',
        },
        {
          text: 'Has seen',
          dataField: 'hasSeen',
          style: { Width: '4%' },
        },
        {
          text: 'Action Url',
          dataField: 'actionUrl',
          style: { Width: '4%' },
        },
        {
          text: 'Actions',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          {user && user.userType === 'ADMIN' && (
            <div className='row justify-content-between'>
              <div className='mx-3 mb-2 my-1'>
                <Link to='/create_notification'>
                  <Button color='success' size='sm'>
                    <i className=' fas git fa-plus mr-2' /> Create Notification
                  </Button>
                </Link>
              </div>
              <div className='col-sm-6 col-md-2 col-lg-2 my-1'>
                <Input
                  type='select'
                  bsSize='sm'
                  name='select'
                  onChange={ChangeNoticeHandler}
                  id='exampleSelect'
                >
                  <option value='1'>My Notification</option>
                  <option value='2'>All Notification</option>
                </Input>
              </div>
            </div>
          )}
          <Card>
            <CardBody className='pt-3'>
              {!isLoading && data !== null ? (
                <>
                  <div className='d-flex justify-content-between mb-2'>
                    <div>
                      <Input
                        bsSize='sm'
                        id='take'
                        name='take'
                        type='select'
                        onChange={handleLimitChange}
                        value={take}
                      >
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>
                    <div>
                      <Input
                        bsSize='sm'
                        id='search'
                        value={search}
                        name='search'
                        onChange={SearchHandler}
                        placeholder='Search'
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField='id'
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses='table-responsive'
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    // breakLabel={'...'}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={3}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={skipPage}
                    //Bootstrap styling
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default NotificationTable;
