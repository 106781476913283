import HttpService from './HttpService'

export const fetchDashStatService = (payload) => {
  const http = new HttpService()

  let url = `dashboard/${payload.data}`

  // For dashboard stats
  if (payload.start && !payload.user) {
    url = `dashboard/${payload.data}?start=${payload.start}&end=${payload.end}`
  }

  // For dashboard ratings
  if (payload.rating) {
    url = `dashboard/${payload.rating}?take=${payload.take}&skip=${payload.skip}`
    if (payload.userType) {
      url = `${url}&userType=${payload.userType}`
    }
  }

  // For user report in user details
  if (payload.user) {
    url = `dashboard/${payload.data}/${payload.user}`
    if (payload.start || payload.end) {
      url = `dashboard/${payload.data}/${payload.user}?start=${payload.start}&end=${payload.end}`
    }
  }
  return http.getData(url)
}

export const fetchDashEarningService = (payload) => {
  const http = new HttpService()
  let url = `dashboard/earnings`
  if (payload.start && payload.end) {
    url = `dashboard/earnings?start=${payload.start}&end=${payload.end}`
  }
  return http.getData(url)
}

export const fetchDashRevenueService = (payload) => {
  const http = new HttpService()
  let url = `dashboard/revenue`
  if (payload.start && payload.end) {
    url = `dashboard/revenue?start=${payload.start}&end=${payload.end}&period=${payload.period}`
  }
  return http.getData(url)
}
