export const FETCH_APP_VERSIONS = 'FETCH_APP_VERSIONS';
export const FETCH_APP_VERSIONS_SUCCESSFUL = 'FETCH_APP_VERSIONS_SUCCESSFUL';
export const FETCH_APP_VERSIONS_API_FAILED = 'FETCH_APP_VERSIONS_API_FAILED';

export const FETCH_EACH_APP_VERSION = 'FETCH_EACH_APP_VERSION';
export const FETCH_EACH_APP_VERSION_SUCCESSFUL =
  'FETCH_EACH_APP_VERSION_SUCCESSFUL';
export const FETCH_EACH_APP_VERSION_API_FAILED =
  'FETCH_EACH_APP_VERSION_API_FAILED';

export const DELETE_APP_VERSION = 'DELETE_APP_VERSION';
export const DELETE_APP_VERSION_SUCCESSFUL = 'DELETE_APP_VERSION_SUCCESSFULLY';
export const DELETE_APP_VERSION_FAILED = 'DELETE_APP_VERSION_FAILED';

export const CREATE_APP_VERSION = 'CREATE_APP_VERSION';
export const CREATE_APP_VERSION_SUCCESSFUL = 'CREATE_APP_VERSION_SUCCESSFULLY';
export const CREATE_APP_VERSION_FAILED = 'CREATE_APP_VERSION_FAILED';

export const UPDATE_APP_VERSION = 'UPDATE_APP_VERSION';
export const UPDATE_APP_VERSION_SUCCESSFUL = 'UPDATE_APP_VERSION_SUCCESSFULLY';
export const UPDATE_APP_VERSION_FAILED = 'FAILED';
