import {
  FETCH_LICENSE_KEYS,
  FETCH_LICENSE_KEYS_SUCCESSFUL,
  FETCH_LICENSE_KEYS_API_FAILED,
  FETCH_EACH_LICENSE_KEY,
  FETCH_EACH_LICENSE_KEY_SUCCESSFUL,
  FETCH_EACH_LICENSE_KEY_API_FAILED,
  DELETE_LICENSE_KEY,
  DELETE_LICENSE_KEY_SUCCESSFUL,
  DELETE_LICENSE_KEY_FAILED,
  CREATE_LICENSE_KEY,
  CREATE_LICENSE_KEY_SUCCESSFUL,
  CREATE_LICENSE_KEY_FAILED,
  UPDATE_LICENSE_KEY,
  UPDATE_LICENSE_KEY_SUCCESSFUL,
  UPDATE_LICENSE_KEY_FAILED,
} from './actionTypes';

export const fetchLicenseKeys = (payload) => {
  return {
    type: FETCH_LICENSE_KEYS,
    payload,
  };
};

export const fetchLicenseKeysSuccessful = (payload) => {
  return {
    type: FETCH_LICENSE_KEYS_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchLicenseKeysError = (error) => {
  return {
    type: FETCH_LICENSE_KEYS_API_FAILED,
    payload: error,
  };
};

export const fetchEachLicenseKey = (id) => {
  return {
    type: FETCH_EACH_LICENSE_KEY,
    payload: id,
  };
};

export const fetchEachLicenseKeySuccessful = (payload) => {
  return {
    type: FETCH_EACH_LICENSE_KEY_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachLicenseKeyError = (error) => {
  return {
    type: FETCH_EACH_LICENSE_KEY_API_FAILED,
    payload: error,
  };
};

export const deleteLicenseKey = (id) => {
  return {
    type: DELETE_LICENSE_KEY,
    payload: id,
  };
};

export const deleteLicenseKeySuccessful = (payload) => {
  return {
    type: DELETE_LICENSE_KEY_SUCCESSFUL,
    payload,
  };
};

export const deleteLicenseKeyFailed = (error) => {
  return {
    type: DELETE_LICENSE_KEY_FAILED,
    payload: error,
  };
};

export const createLicenseKey = (payload) => {
  return {
    type: CREATE_LICENSE_KEY,
    payload,
  };
};

export const createLicenseKeySuccessful = (payload) => {
  return {
    type: CREATE_LICENSE_KEY_SUCCESSFUL,
    payload,
  };
};

export const createLicenseKeyFailed = (error) => {
  return {
    type: CREATE_LICENSE_KEY_FAILED,
    payload: error,
  };
};

export const updateLicenseKey = (payload) => {
  return {
    type: UPDATE_LICENSE_KEY,
    payload,
  };
};

export const updateLicenseKeyFailed = (error) => {
  return {
    type: UPDATE_LICENSE_KEY_FAILED,
    payload: error,
  };
};

export const updateLicenseKeySuccessful = (payload) => {
  return {
    type: UPDATE_LICENSE_KEY_SUCCESSFUL,
    payload,
  };
};
