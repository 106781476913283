import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Label,
  FormGroup,
  Button,
  Alert,
  ButtonGroup,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import {
  generateOTP,
  updateAccountWalletFailed,
  updateAccountWallet,
  generateOTPFailed,
  fetchUser,
} from '../../../store/actions';

const WalletForm = ({
  userId,
  closeModal,
  fetchUser,
  updateAccountWallet,
  updateAccountWalletFailed,
  loading,
  accountError,
  message,
  generateOTP,
  otp,
  generateOTPFailed,
  loadingOTP,
  otpError,
  otpMessage,
  user,
}) => {
  const [increateWalletType, setIncreateWalletType] = useState(true);
  const [disableButton, setDisable] = useState(false);
  const handleSubmit = async (event, values) => {
    let formData = {
      ...values,
    };
    let urlLink = `${userId}/increaseBalance`;
    if (!increateWalletType) {
      urlLink = `${userId}/decreaseBalance`;
    }

    updateAccountWallet(formData, urlLink);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        updateAccountWalletFailed('');
        closeModal();
        if (userId) {
          fetchUser(userId);
        }
      }, 2000);
    }
    if (otp) {
      setTimeout(() => {
        generateOTPFailed('');
      }, 2000);
    }
  }, [message, otp]);

  const handleGenerateOTP = () => {
    setDisable(true);
    generateOTP({ email: user.email, type: 'ACCOUNT_BALANCE' });
    setTimeout(() => {
      setDisable(false);
    }, 15000);
  };
  return (
    <div>
      <div md={6}>
        <div>
          {message && (
            <Alert color='success' className='text-center'>
              {message}
            </Alert>
          )}
          {accountError && (
            <Alert color='danger' className='text-center'>
              {accountError}
            </Alert>
          )}
          {otpError && (
            <Alert color='danger' className='text-center'>
              {otpError}
            </Alert>
          )}
          {otpMessage && (
            <Alert color='secondary' className='text-center'>
              {otpMessage}
            </Alert>
          )}
          <div>
            <ButtonGroup size='sm' className='mb-2'>
              <Button
                className={`${increateWalletType ? 'btn btn-success' : 'btn btn-white'
                  }`}
                onClick={() => setIncreateWalletType(true)}
              >
                Increase
              </Button>
              <Button
                className={`${!increateWalletType ? 'bg-success' : 'btn btn-white'
                  }`}
                onClick={() => setIncreateWalletType(false)}
              >
                Decrease
              </Button>
            </ButtonGroup>
            <AvForm className='form-horizontal' onValidSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  {increateWalletType ? (
                    <FormGroup className=' mb-4'>
                      <Label htmlFor='seats'>Increase Wallet</Label>

                      <AvField
                        name='increaseByAmount'
                        step='10'
                        type='number'
                        min='10'
                        max='50000'
                        className='form-control bg-light'
                        id='seats'
                        required
                        errorMessage='This field required min = 10 annd max = 50000'
                        placeholder='Enter Number'
                      />
                    </FormGroup>
                  ) : (
                    <FormGroup className=' mb-4'>
                      <Label htmlFor='seats'>Decrease Wallet</Label>

                      <AvField
                        name='decreaseByAmount'
                        step='10'
                        type='number'
                        min='10'
                        max='50000'
                        className='form-control bg-light'
                        id='seats'
                        required
                        errorMessage='This field required min = 10 annd max = 50000'
                        placeholder='Enter Number'
                      />
                    </FormGroup>
                  )}
                </Col>
                <Col md={12}>
                  <FormGroup className=' mb-4'>
                    <AvField
                      className='form-control bg-light'
                      type='select'
                      name='source'
                      value='ADMIN'
                      label='Source'
                    >
                      <option value={'ADMIN'}>Admin</option>
                    </AvField>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup className=' mb-4'>
                    <AvField
                      className='form-control bg-light'
                      type='select'
                      name='classification'
                      label='Classification'
                      value='CASH'
                    >
                      <option value={'CASH'}>Cash</option>
                      <option value={'BONUS'}>Bonus</option>
                      <option value={'COMMISSION'}>Commission</option>
                      <option value={'PLATFORM_FEE'}>Platform Fee</option>
                      <option value={'FINE'}>Fine</option>
                      <option value={'ERROR'}>Error</option>
                      <option value={'OTHERS'}>Others</option>
                    </AvField>
                  </FormGroup>
                </Col>

                <div className='d-flex align-items-center w-100 mb-2'>
                  <Col md={9} className='pr-0'>
                    <FormGroup className=' mb-4'>
                      <Label htmlFor='code'>OTP</Label>

                      <AvField
                        name='code'
                        type='number'
                        className='form-control bg-light'
                        id='seats'
                        required
                        errorMessage='please enter OTP sent to you'
                        placeholder='Enter otp'
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} className='pl-1 mt-1'>
                    <Button
                      color='primary'
                      onClick={handleGenerateOTP}
                      disabled={disableButton}
                    >
                      {loadingOTP ? 'Sending' : 'Generate OTP'}
                    </Button>
                  </Col>
                </div>
                {otp && (
                  <p
                    className='font-size-12 text-primary'
                    style={{ position: 'absolute', left: '3%', bottom: '10%' }}
                  >
                    OTP sent at: {otp?.time?.split(',')?.pop()}
                  </p>
                )}
              </Row>
              <button className='btn btn-success mr-2' type='submit'>
                {loading ? 'Updating..' : 'Submit'}
              </button>
            </AvForm>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    user,
    otp,
    loadingOTP,
    otpError,
    otpMessage,
    loading,
    accountError,
    message,
  } = state.Account;
  return {
    user,
    otp,
    loadingOTP,
    otpError,
    otpMessage,
    loading,
    accountError,
    message,
  };
};

export default connect(mapStateToProps, {
  updateAccountWalletFailed,
  generateOTPFailed,
  updateAccountWallet,
  fetchUser,
  generateOTP,
})(WalletForm);
