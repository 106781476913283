import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AppRoute = ({
  component: Component,
  layout: Layout,
  accessFields,
  account,
  accessAuth,
  role,
  field,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        account.isAuthenticated && accessAuth ? (
          staticAccessHandler(role, account) &&
          accessHandler(accessFields, field) ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect
              to={{ pathname: '/404', state: { from: props.location } }}
            />
          )
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default React.memo(AppRoute);

const accessHandler = (accessData, field) => {
  if (accessData.length === 0) return true;
  if (accessData.length === 1 && field) {
    let result;
    if (field.subModule && field.module) {
      result =
        accessData[0].data?.modules[field.module][field.subModule]
          ?.subscribe === 'NOT_ALLOWED';
    } else {
      result =
        accessData[0].data?.modules[field.module]?.subscribe === 'NOT_ALLOWED';
    }
    return !result;
  }
  return true;
};

const staticAccessHandler = (role, account) => {
  if (role.includes(account?.user?.userType)) return true;
  return false;
};
