import React, { useState } from 'react';
import onClickOutside from 'react-onclickoutside';
import { Row, Button, Label, FormGroup } from 'reactstrap';
import Moment from 'moment';
import moment from 'moment-timezone';
import { AvForm, AvField } from 'availity-reactstrap-validation';

const DropdownLink = ({ date, setDate }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownValue, setValue] = useState('Today');

  DropdownLink.handleClickOutside = () => {
    setShowDropdown(false);
  };
  const handleDateFilter = (e, value) => {
    setDate({ start: value.startDate, end: value.endDate });
    setShowDropdown(false);
    setValue('Custom');
  };

  return (
    <div style={{ position: 'relative' }} className='mb-2'>
      <div
        className='dashboard-date-dropdown form-control-sm form-control d-flex align-items-center justify-content-between'
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {dropdownValue}
        <i
          className={`fas fa-angle-${
            showDropdown ? 'up' : 'down'
          } font-size-14`}
        ></i>
      </div>
      {showDropdown ? (
        <div className='dropdown-menu dashboard-date d-block pb-0'>
          <div
            className='dropdown-item'
            onClick={() => {
              setValue('Today');
              setShowDropdown(false);
              setDate({
                start: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
                end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
              });
            }}
          >
            Today
          </div>

          <div
            className='dropdown-item'
            onClick={() => {
              setValue('Last Week');
              setShowDropdown(false);
              setDate({
                start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
                end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
              });
            }}
          >
            Last Week
          </div>
          <div
            className='dropdown-item'
            onClick={() => {
              setValue('Last Month');
              setShowDropdown(false);
              setDate({
                start: Moment().subtract(1, 'M').format('YYYY-MM-DD'),
                end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
              });
            }}
          >
            Last Month
          </div>
          <div
            className='dropdown-item'
            onClick={() => {
              setValue('All Time');
              setShowDropdown(false);
              setDate({
                start: '',
                end: '',
              });
            }}
          >
            All Time
          </div>

          <AvForm className='form-horizontal' onValidSubmit={handleDateFilter}>
            <Row
              className='px-3  pt-3 pb-1 justify-content-around m-0'
              style={{ background: '#e6f6ee' }}
            >
              <FormGroup className='m-0'>
                <Label htmlFor='startDate' className='font-size-12'>
                  {' '}
                  From
                </Label>
                <AvField
                  name='startDate'
                  type='date'
                  className='form-control bg-light m-0'
                  id='startDate'
                  value={date?.start}
                  bsSize='sm'
                  bgsize='sm'
                />
              </FormGroup>

              <FormGroup className='m-0'>
                <Label htmlFor='endDate' className='font-size-12'>
                  {' '}
                  To
                </Label>
                <AvField
                  name='endDate'
                  type='date'
                  className='form-control bg-light'
                  id='endDate'
                  value={date?.end}
                  bsSize='sm'
                  bgsize='sm'
                />
              </FormGroup>
              <div className='w-100 px-2'>
                <Button
                  size='sm'
                  color='success'
                  className='w-100'
                  type='submit'
                >
                  Filter
                </Button>
              </div>
            </Row>
          </AvForm>
        </div>
      ) : null}
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => DropdownLink.handleClickOutside,
};
export default onClickOutside(DropdownLink, clickOutsideConfig);
