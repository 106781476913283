const myTableHeader = [
  {
    dataField: 'description',
    text: 'Description',
    style: { Width: '10%' },
  },
  {
    dataField: 'status',
    text: 'Status',
  },
];
const GeneralTableHeader = [
  {
    dataField: 'complaintType',
    text: 'Complaint Type',
  },
  {
    dataField: 'description',
    text: 'Description',
    style: { Width: '10%' },
  },
  {
    dataField: 'reporter',
    text: 'Reporter',
    // style: { Width: '10%' },
  },
  {
    dataField: 'reportee',
    text: 'Reportee',
    // style: { Width: '10%' },
  },
  {
    dataField: 'assign_by',
    text: 'Assigned By',
  },
  {
    dataField: 'assign_to',
    text: 'Assigned To',
    headerStyle: { width: '13%' },
  },
  {
    text: 'Created Date',
    dataField: 'date',
  },
  {
    dataField: 'status',
    text: 'Status',
  },
  {
    dataField: 'action',
    text: 'Action',
  },
];

const assignedTableHeader = [
  {
    dataField: 'complaintType',
    text: 'Complaint Type',
  },
  {
    dataField: 'description',
    text: 'Description',
    style: { Width: '10%' },
  },
  {
    dataField: 'reporter',
    text: 'Reporter',
  },
  {
    dataField: 'reportee',
    text: 'Reportee',
    // style: { Width: '10%' },
  },
  {
    text: 'Created Date',
    dataField: 'date',
  },
  {
    dataField: 'status',
    text: 'Status',
  },
  {
    dataField: 'action',
    text: 'Action',
  },
];



export { myTableHeader, GeneralTableHeader, assignedTableHeader };
