import {
  FETCH_VEHICLE_SUMMARY,
  FETCH_VEHICLE_SUMMARY_SUCCESSFULLY,
  FETCH_VEHICLE_SUMMARY_FAILED,
  UPDATE_VEHICLE_WITH_PACKAGE,
  UPDATE_VEHICLE_WITH_PACKAGE_SUCCESSFULLY,
  UPDATE_VEHICLE_WITH_PACKAGE_FAILED,
  FETCH_VEHICLE,
  FETCH_VEHICLE_SUCCESSFUL,
  FETCH_VEHICLE_API_FAILED,
  FETCH_EACH_VEHICLE,
  FETCH_EACH_VEHICLE_SUCCESSFUL,
  FETCH_EACH_VEHICLE_API_FAILED,
  FETCH_MY_VEHICLE,
  FETCH_MY_VEHICLE_SUCCESSFUL,
  FETCH_MY_VEHICLE_API_FAILED,
  DELETE_VEHICLE,
  DELETE_VEHICLE_SUCCESSFUL,
  DELETE_VEHICLE_FAILED,
  CREATE_VEHICLE,
  CREATE_VEHICLE_SUCCESSFUL,
  CREATE_VEHICLE_FAILED,
  UPDATE_VEHICLE,
  UPDATE_VEHICLE_SUCCESSFUL,
  UPDATE_VEHICLE_FAILED,
  IS_VEHICLE_AUTHORIZE,
} from './actionTypes'

export const fetchVehicleAuthorizeError = (error) => {
  return {
    type: IS_VEHICLE_AUTHORIZE,
    payload: error,
  }
}

export const fetchVehicle = (payload) => {
  return {
    type: FETCH_VEHICLE,
    payload,
  }
}

export const fetchVehicleSuccessful = (payload) => {
  return {
    type: FETCH_VEHICLE_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchVehicleError = (error) => {
  return {
    type: FETCH_VEHICLE_API_FAILED,
    payload: error,
  }
}

export const fetchEachVehicle = (id) => {
  return {
    type: FETCH_EACH_VEHICLE,
    payload: id,
  }
}

export const fetchEachVehicleSuccessful = (payload) => {
  return {
    type: FETCH_EACH_VEHICLE_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchEachVehicleError = (error) => {
  return {
    type: FETCH_EACH_VEHICLE_API_FAILED,
    payload: error,
  }
}

export const fetchMyVehicle = (payload) => {
  return {
    type: FETCH_MY_VEHICLE,
    payload,
  }
}

export const fetchMyVehicleSuccessful = (payload) => {
  return {
    type: FETCH_MY_VEHICLE_SUCCESSFUL,
    payload: payload,
  }
}

export const fetchMyVehicleError = (error) => {
  return {
    type: FETCH_MY_VEHICLE_API_FAILED,
    payload: error,
  }
}

export const deleteVehicle = (id) => {
  return {
    type: DELETE_VEHICLE,
    payload: id,
  }
}

export const deleteVehicleSuccessful = (payload) => {
  return {
    type: DELETE_VEHICLE_SUCCESSFUL,
    payload,
  }
}

export const deleteVehicleFailed = (error) => {
  return {
    type: DELETE_VEHICLE_FAILED,
    payload: error,
  }
}

export const createVehicle = (payload) => {
  return {
    type: CREATE_VEHICLE,
    payload,
  }
}

export const createVehicleSuccessful = (payload) => {
  return {
    type: CREATE_VEHICLE_SUCCESSFUL,
    payload,
  }
}

export const createVehicleFailed = (error) => {
  return {
    type: CREATE_VEHICLE_FAILED,
    payload: error,
  }
}

export const updateVehicle = (payload) => {
  return {
    type: UPDATE_VEHICLE,
    payload,
  }
}

export const updateVehicleFailed = (error) => {
  return {
    type: UPDATE_VEHICLE_FAILED,
    payload: error,
  }
}

export const updateVehicleSuccessful = (payload) => {
  return {
    type: UPDATE_VEHICLE_SUCCESSFUL,
    payload,
  }
}

//SUMMARY ENTRIES
export const fetchVehicleSummary = (query) => {
  return {
    type: FETCH_VEHICLE_SUMMARY,
    payload: query,
  }
}

export const fetchVehicleSummarySuccessfully = (payload) => {
  return {
    type: FETCH_VEHICLE_SUMMARY_SUCCESSFULLY,
    payload: payload,
  }
}

export const fetchVehicleSummaryFailed = (error) => {
  return {
    type: FETCH_VEHICLE_SUMMARY_FAILED,
    payload: error,
  }
}

//UPDATE VEHICLE WITH PACKAGE
export const updateVehiclePackages = (query) => {
  return {
    type: UPDATE_VEHICLE_WITH_PACKAGE,
    payload: query,
  }
}

export const updateVehiclePackagesSuccessfully = (payload) => {
  return {
    type: UPDATE_VEHICLE_WITH_PACKAGE_SUCCESSFULLY,
    payload: payload,
  }
}

export const updateVehiclePackagesFailed = (error) => {
  return {
    type: UPDATE_VEHICLE_WITH_PACKAGE_FAILED,
    payload: error,
  }
}
