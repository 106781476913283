import React, { Component } from 'react';
import { Row, Card, CardBody, Col } from 'reactstrap';
import ng_map from '../../../assets/images/ng-02.png';
import abuja_map from '../../../assets/images/abuja_map.jpg';
import lagos_map from '../../../assets/images/lagos_map.gif';

class RevenueByLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      location: 'All',
    };
  }

  render() {
    return (
      <React.Fragment>
        <Col lg={4}>
          <Card>
            <span className='badge badge-soft-primary font-size-11 font-weight-bold text-italics'>
              This section is in progress
            </span>
            <CardBody>
              <div className='float-right'>
                <select
                  className='custom-select custom-select-sm'
                  onChange={(e) => this.setState({ location: e.target.value })}
                >
                  <option defaultValue>All</option>
                  <option value='Lagos'>Lagos</option>
                  <option value='Abuja'>Abuja</option>
                </select>
              </div>

              <h4 className='card-title mb-4'>
                Revenue by Locations{' '}
                <span className='text-muted'>({this.state.location}) </span>
              </h4>

              <div
                id='usa-vectormap'
                style={{ height: '196px' }}
                className='text-center'
              >
                {
                  <>
                    {
                      this.state.location === "Abuja" ? <img src={abuja_map} alt='ng-map' height='200' width="300" /> : this.state.location === "Lagos" ? 
                      <img src={lagos_map} alt='ng-map' height='200' width="300" /> : 
                    <img src={ng_map} alt='ng-map' height='200' width="300" />
                    }
                  </>
                }
              </div>

              <Row className='justify-content-center'>
                <Col xl={5} md={6}>
                  <div className='mt-2'>
                    <div className='clearfix py-2'>
                      <h5 className='float-right font-size-16 m-0'>₦ 0.00</h5>
                      <p className='text-muted mb-0 text-truncate'>Abuja :</p>
                    </div>
                  </div>
                </Col>
                <Col xl={{ size: 5, offset: 1 }} md={6}>
                  <div className='mt-2'>
                    <div className='clearfix py-2'>
                      <h5 className='float-right font-size-16 m-0'>₦ 0.00</h5>
                      <p className='text-muted mb-0 text-truncate'>Lagos :</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className='text-center mt-4'>
                <button className='btn btn-primary btn-sm' disabled>
                  View more
                </button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default RevenueByLocations;
