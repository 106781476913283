import HttpService from './HttpService'
import { QueryReallignment } from '../utils/general'

export const GetLoyaltyService = (query) => {
  const http = new HttpService()
  let url = 'loyalty/history'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}

export const GetMyLoyaltyService = (query) => {
  const http = new HttpService()
  let url = `loyalty/history`
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}/${query.user}?${queryParams}`
  }
  return http.getData(url)
}

export const UpdateUserPointService = (payload) => {
  const http = new HttpService()
  const url = `loyalty/${payload.id}/${payload.action}`
  return http.putData(payload, url)
}

export const fetchLoyaltySummaryService = (query) => {
  // Payload with query
  const http = new HttpService()
  let url = 'summary/loyalty'
  if (query) {
    let queryParams = QueryReallignment(query)
    url = `${url}?${queryParams}`
  }
  return http.getData(url)
}
