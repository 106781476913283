import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_DOCUMENT,
  FETCH_EACH_DOCUMENT,
  CREATE_DOCUMENT,
  FETCH_DOCUMENT_SUMMARY,
  DELETE_DOCUMENT,
  UPDATE_DOCUMENT,
} from './actionTypes';
import {
  fetchDocumentSuccessful,
  fetchDocumentError,
  fetchEachDocumentSuccessful,
  fetchEachDocumentError,
  fetchDocumentSummaryFailed,
  fetchDocumentSummarySuccessfully,
  deleteDocumentSuccessful,
  deleteDocumentFailed,
  createDocumentSuccessful,
  createDocumentFailed,
  updateDocumentSuccessful,
  updateDocumentFailed,
  fetchDocumentAuthorizeError,
} from './actions';

import {
  GetDocumentService,
  GetEachDocumentService,
  DeleteDocumentService,
  fetchDocumentSummaryService,
  CreateDocumentService,
  UpdateDocumentService,
} from '../../services/documentService';

function* fetchDocumentHandler({ payload }) {
  try {
    const response = yield call(GetDocumentService, payload);
    yield put(fetchDocumentSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchDocumentAuthorizeError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    }
    yield put(fetchDocumentError(error?.response?.data.error?.message));
  }
}
function* fetchEachDocumentHandler({ payload }) {
  try {
    const response = yield call(GetEachDocumentService, payload);
    yield put(fetchEachDocumentSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachDocumentError(error?.response?.data.error?.message));
  }
}

function* deleteDocumentHandler({ payload }) {
  try {
    const response = yield call(DeleteDocumentService, payload);
    yield put(deleteDocumentSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteDocumentFailed(error?.response?.data?.message));
  }
}

function* createDocumentHandler({ payload }) {
  try {
    const response = yield call(CreateDocumentService, payload);
    yield put(createDocumentSuccessful(response.data.result));
  } catch (error) {
    yield put(createDocumentFailed(error?.response?.data?.message));
  }
}

function* updateDocumentHandler({ payload }) {
  try {
    const response = yield call(UpdateDocumentService, payload);
    yield put(updateDocumentSuccessful(response.data.result));
  } catch (error) {
    yield put(updateDocumentFailed(error?.response?.data?.message));
  }
}

// DOCUMENT SUMMARY
function* fetchDocumentSummaryHandler({ payload }) {
  try {
    const response = yield call(fetchDocumentSummaryService, payload);
    yield put(fetchDocumentSummarySuccessfully(response.data));
  } catch (error) {
    yield put(fetchDocumentSummaryFailed(error.response.data));
  }
}

export function* watchFetchDocument() {
  yield takeEvery(FETCH_DOCUMENT, fetchDocumentHandler);
}
export function* watchFetchEachDocument() {
  yield takeEvery(FETCH_EACH_DOCUMENT, fetchEachDocumentHandler);
}
export function* watchDeleteDocument() {
  yield takeEvery(DELETE_DOCUMENT, deleteDocumentHandler);
}
export function* watchCreateDocument() {
  yield takeEvery(CREATE_DOCUMENT, createDocumentHandler);
}
export function* watchUpdateDocument() {
  yield takeEvery(UPDATE_DOCUMENT, updateDocumentHandler);
}

export function* watchFetchDocumentSummary() {
  yield takeEvery(FETCH_DOCUMENT_SUMMARY, fetchDocumentSummaryHandler);
}

function* documentSaga() {
  yield all([
    fork(watchFetchDocument),
    fork(watchFetchEachDocument),
    fork(watchFetchDocumentSummary),
    fork(watchDeleteDocument),
    fork(watchCreateDocument),
    fork(watchUpdateDocument),
  ]);
}

export default documentSaga;
