import React, { useEffect, useState } from "react";
import { getS3ImageUrlHandler } from "../../../utils/general";
import { Card, CardBody, Row, Col, FormGroup, Label, Alert } from "reactstrap";
import AlartError from "../../../components/Common/AlertCard";
import { TwitterPicker } from "react-color";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";

import { Link, withRouter } from "react-router-dom";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import { ScrollToTop } from "../../../utils/general";

// actions
import { createPackage, createPackageFailed } from "../../../store/actions";

const CreateService = ({
  createPackage,
  createPackageFailed,
  message,
  loading,
  history,
  packageError,
}) => {
  const [selectedFiles, setFileUpload] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState(null);
  const [generalError, setGeneralError] = useState(null);
  const [color, setColor] = useState("");
  const [description, setDescription] = useState("");
  const [defaultData, setDefaultData] = useState({
    basePrice: "",
    capacity: "",
    distancePrice: "",
    isAcceptShareRide: true,
    name: "",
    pricingType: "Per Hour Pricing",
    provider: "",
    status: true,
    timePrice: "",
  });

  const handleSubmit = async (event, values) => {
    // ScrollToTop();
    setIsLoading(true);
    setGeneralError(null);
    setImageError(null);
    if (selectedFiles.length === 0) {
      setImageError("Image field is required ");
      setIsLoading(false);
      ScrollToTop();
      return;
    }
    if (!color) {
      setGeneralError("color code is required");
      setIsLoading(false);
      ScrollToTop();
      return;
    }
    try {
      let result = await getS3ImageUrlHandler(selectedFiles[0], "package");
      const packageData = {
        ...values,
      };
      packageData.capacity = Number(values.capacity);
      packageData.distancePrice = Number(values.distancePrice);
      packageData.vehicle = values.vehicle;
      packageData.pricingType =
        values.pricingType === "Base Price"
          ? "BASE"
          : values.pricingType === "Time Price"
          ? "TIME"
          : values.pricingType === "Distance Price"
          ? "DISTANCE"
          : values.pricingType === "Block Price"
          ? "BLOCK"
          : "TIMEDISTANCE";
      packageData.basePrice = Number(values.basePrice);
      packageData.timePrice = Number(values.timePrice);
      packageData.minimumPrice = Number(values.minimumPrice);
      packageData.blockPrice = Number(values.blockPrice);
      packageData.colorCode = color;
      packageData.minimumPriceDistance = Number(values.minimumPriceDistance);
      packageData.picture = result?.data.result?.mediaUrl;
      packageData.pictureThumb = result?.data?.result.mediaThumbUrl;
      packageData.description = description;
      packageData.isAcceptShareRide =
        values.isAcceptShareRide === "true" ? true : false;
      packageData.key = values.key.toUpperCase();
      createPackage(packageData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setImageError("Error in uploading image, please try again");
      setIsLoading(false);
      ScrollToTop();
    }
  };

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setFileUpload(files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    if (message) {
      ScrollToTop();
      setTimeout(() => {
        createPackageFailed("");
        history.push("/package_type");
      }, 2000);
    }
  }, [message]);

  const colorChangeHanndler = (c) => {
    setColor(c.hex);
  };

  useEffect(() => {
    createPackageFailed("");
  }, []);

  return (
    <div className="page-content">
      <Card>
        <CardBody>
          {message && (
            <Alert color="success" className="text-center">
              {message}
            </Alert>
          )}
          {imageError && (
            <Alert color="danger" className="text-center">
              {imageError}
            </Alert>
          )}
          {generalError && (
            <Alert color="danger" className="text-center">
              {generalError}
            </Alert>
          )}

          {packageError && <AlartError error={packageError} />}
          <div className="float-right">
            <Link to="/package_type">
              <i className="fas fa-arrow-left mr-3" />
              Back
            </Link>
          </div>
          <div>
            <h5> Create Package </h5>
          </div>
          <div>
            <AvForm
              className="form-horizontal"
              onValidSubmit={handleSubmit}
              model={defaultData}
            >
              <Row>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="name">Name</Label>
                    <AvField
                      name="name"
                      type="text"
                      className="form-control bg-light"
                      id="name"
                      placeholder="Enter Package name ..."
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className=" mb-4">
                    <Label htmlFor="provider">Provider</Label>
                    <AvField
                      name="provider"
                      type="text"
                      className="form-control bg-light"
                      id="provider"
                      placeholder="Enter Provider Name ..."
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      handleAcceptedFiles(acceptedFiles)
                    }
                    multiple={false}
                    accept="image/jpeg,image/jpd, image/png,"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick mt-2"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                          </div>
                          <h4>Drop/Upload Item Picture Here.</h4>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="basePrice">Base Price (₦)</Label>
                    <AvField
                      name="basePrice"
                      type="text"
                      className="form-control bg-light"
                      id="basePrice"
                      placeholder="Enter Base Price ..."
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className=" mb-4">
                    <Label htmlFor="distancePrice"> Distance Price (₦/m)</Label>
                    <AvField
                      name="distancePrice"
                      type="number"
                      className="form-control bg-light"
                      id="distancePrice"
                      placeholder="Enter Distance price ..."
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="timePrice">Time Price (₦/s)</Label>
                    <AvField
                      name="timePrice"
                      type="number"
                      min="0"
                      className="form-control bg-light"
                      id="timePrice"
                      placeholder="Enter Time Price ..."
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="capacity">Seat Capacity</Label>
                    <AvField
                      name="capacity"
                      type="number"
                      min="0"
                      max="10"
                      className="form-control bg-light"
                      id="capacity"
                      placeholder="Enter Seat Capacitor ..."
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="minimumPrice">Minimum Price (₦)</Label>
                    <AvField
                      name="minimumPrice"
                      type="number"
                      min="0"
                      className="form-control bg-light"
                      id="minimumPrice"
                      placeholder="Enter minimum price ..."
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="blockPrice">Block Price (₦)</Label>
                    <AvField
                      name="blockPrice"
                      type="number"
                      min="0"
                      className="form-control bg-light"
                      id="blockPrice"
                      placeholder="Enter block price ..."
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="minimumPriceDistance">
                      Minimum Distance (m)
                    </Label>
                    <AvField
                      name="minimumPriceDistance"
                      type="number"
                      min="0"
                      className="form-control bg-light"
                      id="minimumPriceDistance"
                      placeholder="Enter Minimum PriceDistance ..."
                      step="1"
                      helpMessage="Must be whole number"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className=" mb-4">
                    <AvField
                      className="form-control bg-light"
                      type="select"
                      name="pricingType"
                      label="Pricing Type"
                      value="Distance + Base + Time Price"
                    >
                      {" "}
                      <option>Distance + Base + Time Price</option>
                      <option>Base Price</option>
                      <option>Time Price</option>
                      <option>Distance Price</option>
                      <option>Block Price</option>
                    </AvField>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup className=" mb-4">
                    <AvField
                      className="form-control bg-light"
                      type="select"
                      name="vehicle"
                      label="Vehicle Type"
                      value="CAR"
                    >
                      <option>CAR</option>
                      <option>BYKE</option>
                      <option>TRYKE</option>
                      <option>BUS</option>
                    </AvField>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className=" mb-4">
                    <AvField
                      className="form-control bg-light"
                      type="select"
                      name="packageType"
                      label="Type"
                      value="ECONOMY"
                    >
                      <option>ECONOMY</option>
                      <option>PREMIUM</option>
                      <option>SPECIAL</option>
                    </AvField>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="key">Key</Label>
                    <AvField
                      name="key"
                      type="text"
                      maxLength="5"
                      className="form-control bg-light"
                      id="key"
                      placeholder="Enter Unique Key ..."
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="isAcceptShareRide">
                      Should this Type Accept Share Ride ?
                    </Label>
                    <AvRadioGroup
                      inline
                      name="isAcceptShareRide"
                      value="true"
                      required
                    >
                      <AvRadio label="Yes" value={"true"} />
                      <AvRadio label="No" value={"false"} />
                    </AvRadioGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup className="mt-4">
                    <div
                      className="p-2 rounded border-secondary text-white"
                      style={{ background: color }}
                    >
                      {" "}
                      Ride Color
                    </div>
                    <TwitterPicker
                      onChange={(c) => colorChangeHanndler(c)}
                      color={color}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor="description"> Description</Label>
                    <textarea
                      className="form-control"
                      onChange={(e) => setDescription(e.target.value)}
                      id="description"
                      rows="5"
                    ></textarea>
                  </FormGroup>
                </Col>
              </Row>
              <div className="text-center">
                <button className="btn btn-success mr-2 px-5" type="submit">
                  {isLoading ? "Submitting ..." : "Save"}
                </button>
              </div>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, packageError } = state.Packages;
  return { message, loading, packageError };
};

export default connect(mapStateToProps, { createPackage, createPackageFailed })(
  withRouter(CreateService)
);
