import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_LICENSE_KEYS,
  FETCH_EACH_LICENSE_KEY,
  CREATE_LICENSE_KEY,
  DELETE_LICENSE_KEY,
  UPDATE_LICENSE_KEY,
} from './actionTypes';
import {
  fetchLicenseKeysSuccessful,
  fetchLicenseKeysError,
  fetchEachLicenseKeySuccessful,
  fetchEachLicenseKeyError,
  deleteLicenseKeySuccessful,
  deleteLicenseKeyFailed,
  createLicenseKeySuccessful,
  createLicenseKeyFailed,
  updateLicenseKeySuccessful,
  updateLicenseKeyFailed,
} from './actions';

import {
  GetLicenseKeyService,
  GetEachLicenseKeyService,
  DeleteLicenseKeyService,
  CreateLicenseKeyService,
  UpdateLicenseKeyService,
} from '../../services/licenseKeyService';

function* fetchLicenseKeysHandler({ payload }) {
  try {
    const response = yield call(GetLicenseKeyService, payload);
    yield put(fetchLicenseKeysSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchLicenseKeysError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchLicenseKeysError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(
        fetchLicenseKeysError(
          error?.response?.data?.message.constructor === String
            ? error?.response?.data?.message
            : 'Error in processing data'
        )
      );
    }
  }
}

function* fetchEachLicenseKeyHandler({ payload }) {
  try {
    const response = yield call(GetEachLicenseKeyService, payload);
    yield put(fetchEachLicenseKeySuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachLicenseKeyError(error?.response?.data.error?.message));
  }
}

function* deleteLicenseKeyHandler({ payload }) {
  try {
    const response = yield call(DeleteLicenseKeyService, payload);
    yield put(deleteLicenseKeySuccessful(response.data.result));
  } catch (error) {
    yield put(deleteLicenseKeyFailed(error?.response?.data?.message));
  }
}

function* createLicenseKeyHandler({ payload }) {
  try {
    const response = yield call(CreateLicenseKeyService, payload);
    yield put(createLicenseKeySuccessful(response.data.result));
  } catch (error) {
    yield put(createLicenseKeyFailed(error?.response?.data?.message));
  }
}

function* updateLicenseKeyHandler({ payload }) {
  try {
    const response = yield call(UpdateLicenseKeyService, payload);
    yield put(updateLicenseKeySuccessful(response.data.result));
  } catch (error) {
    yield put(updateLicenseKeyFailed(error?.response?.data?.message));
  }
}

// LICENSE_KEY SUMMARY

export function* watchFetchLicenseKey() {
  yield takeEvery(FETCH_LICENSE_KEYS, fetchLicenseKeysHandler);
}

export function* watchFetchEachLicenseKey() {
  yield takeEvery(FETCH_EACH_LICENSE_KEY, fetchEachLicenseKeyHandler);
}

export function* watchDeleteLicenseKey() {
  yield takeEvery(DELETE_LICENSE_KEY, deleteLicenseKeyHandler);
}
export function* watchCreateLicenseKey() {
  yield takeEvery(CREATE_LICENSE_KEY, createLicenseKeyHandler);
}
export function* watchUpdateLicenseKey() {
  yield takeEvery(UPDATE_LICENSE_KEY, updateLicenseKeyHandler);
}

function* license_keySaga() {
  yield all([
    fork(watchFetchLicenseKey),
    fork(watchFetchEachLicenseKey),
    fork(watchDeleteLicenseKey),
    fork(watchCreateLicenseKey),
    fork(watchUpdateLicenseKey),
  ]);
}

export default license_keySaga;
