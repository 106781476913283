import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Button,
  FormGroup,
  Col,
} from 'reactstrap';
import queryString from 'query-string';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Link, withRouter } from 'react-router-dom';
import { CSVLink } from 'react-csv';
//Import Breadcrumb
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import GreySummaryCard from '../../../../components/Common/Summary/index';
import ComplainUpdate from '../Components/ComplainUpdate';
import MyComplaintTable from '../table';
import { assignedTableHeader } from '../data';
import {
  fetchMyComplaint,
  assignComplaint,
  updateComplaint,
  assignComplaintSuccessful,
  fetchDataForMyComplaintsCSV,
  fetchForCSVError,
} from '../../../../store/actions';
import PreviewComplaint from '../Components/previewComplaint';

const breadcrumbItems = [
  { title: 'Dashboard', link: '#' },
  { title: 'Driver', link: '#' },
];

const MyComplaints = ({
  loading,
  fetchDataForMyComplaintsCSV,
  fetchForCSVError,
  csv,
  csv_loading,
  user,
  complaint,
  message,
  complaintError,
  history,
  updateComplaint,
  assignComplaint,
  fetchMyComplaint,
  assignComplaintSuccessful,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [showCSVModal, setCSVModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [assignToModal, setAssignToModal] = useState(false);
  const [complaintId, setComplaintId] = useState(null);

  const fetchMyComplaintHandler = (params) => {
    let paramsUpdate = {
      ...params,
      assigneeId: user.id,
    };
    fetchMyComplaint(paramsUpdate);
  };

  useEffect(() => {
    let params = {
      assigneeId: user.id,
    };
    fetchMyComplaint(params);
  }, []);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setUpdateModal(false);
        setAssignToModal(false);
        setComplaintId(null);
        fetchMyComplaint();
        assignComplaintSuccessful('');
      }, 1000);
    }
  }, [message]);

  useEffect(() => {
    // Updating Url with previewId
    if (complaintId) {
      history.push({
        pathname: history.location.pathname,
        search: `previewId=${complaintId}`,
      });
    }
  }, [complaintId]);

  const handleCSV = (e, values) => {
    fetchDataForMyComplaintsCSV({
      assigneeId: user.id,
      take: values.endRow
        ? values.endRow - values.startRow + 1
        : complaint?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
    });
  };
  useEffect(() => {
    if (csv) {
      // SET CSV EXPORT DATA
      const csvData = csv?.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Description: data?.title ? data.title : '----',
          Note: data?.note ? data.note : '----',
          Type: data?.type ? data.type : '----',
          Priority: data?.priority ? data.priority : '----',
          Device: data?.device ? data.device : '----',
          Location: data?.location ? data.location : '----',
          ImageUrl: data?.imageUrl ? data.imageUrl : '----',
          Status: data?.status ? data.status : '----',
          Reporter: data?.reporter
            ? `${data?.reporter?.firstName} - ${data?.reporter?.lastName}`
            : '----',
          Reportee: data?.reportee
            ? `${data?.reportee?.firstName} - ${data?.reportee?.lastName}`
            : '---',
          Assignee: data?.assignee
            ? `${data?.assignee?.firstName} - ${data?.assignee?.lastName}`
            : '----',
          Assignor: data?.assignor
            ? `${data?.assignor?.firstName} - ${data?.assignor?.lastName}`
            : '---',
          Created_Date: data?.createdAt?.substring(0, 10),
        };

        return formData;
      });

      setCsvData(csvData);
    }
  }, [csv]);

  useEffect(() => {
    if (!showCSVModal) {
      fetchForCSVError();
    }
  }, [showCSVModal]);
  useEffect(() => {
    // Show modal if previewId present  on first Visit
    const parsedUrlParams = queryString.parse(history.location.search);
    let id = parsedUrlParams?.previewId;
    if (id) {
      previewHandler(id);
    }
  }, []);

  const previewHandler = (id) => {
    setShowModal(true);
    setComplaintId(id);
  };

  const updateHandler = (id) => {
    setUpdateModal(true);
    setComplaintId(id);
  };

  const closePreview = () => {
    setShowModal(false);
    setComplaintId('');
    // Clear Browser Url
    history.push({
      pathname: history.location.pathname,
    });
  };
  const toggleCSVModal = (e) => {
    setCSVModal(e);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Assigned Complaints"
            breadcrumbItems={breadcrumbItems}
          />
          <GreySummaryCard field="Complaints" subModule="General Complaint">
            <MyComplaintTable
              complaint={complaint}
              complaintError={complaintError}
              csvModal={toggleCSVModal}
              loading={loading}
              fetchComplaint={fetchMyComplaintHandler}
              previewAction={previewHandler}
              updateAction={updateHandler}
              assignedHandler={updateHandler}
              columns={assignedTableHeader}
              fetchDataForMyComplaintsCSV={fetchDataForMyComplaintsCSV}
              fetchForCSVError={fetchForCSVError}
              csv={csv}
              csv_loading={csv_loading}
            />
          </GreySummaryCard>
        </Container>

        {/* Preview Modal */}
        <Modal isOpen={showModal} toggle={() => closePreview()}>
          <ModalHeader toggle={() => closePreview()}>
            Preview Complaint
          </ModalHeader>
          <ModalBody>
            <PreviewComplaint previewId={complaintId} />
          </ModalBody>
        </Modal>

        {/* Update to Modal */}
        <Modal isOpen={updateModal} toggle={() => setUpdateModal(!updateModal)}>
          <ModalHeader toggle={() => setUpdateModal(false)}>
            Update Complaint
          </ModalHeader>
          <ModalBody>
            <ComplainUpdate
              updateId={complaintId}
              updateComplaint={updateComplaint}
              loading={loading}
              message={message}
              complaintError={complaintError}
            />
          </ModalBody>
        </Modal>
        {/* CSV DOWNLOAD MODAL */}
        <Modal isOpen={showCSVModal} toggle={() => setCSVModal(!showCSVModal)}>
          <ModalHeader toggle={() => setCSVModal(false)}>
            Generate CSV
          </ModalHeader>
          <ModalBody>
            <AvForm className="form-horizontal" onValidSubmit={handleCSV}>
              <Col>
                <FormGroup>
                  <Label htmlFor="startRow">Start (Row)</Label>
                  <AvField
                    name="startRow"
                    type="number"
                    max={complaint?.total - 1}
                    className="form-control"
                    id="startRow"
                    placeholder="Enter the row to start from"
                    errorMessage="value must be greater than 0 and less than total data"
                    min="1"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="endRow">End (Row)</Label>
                  <AvField
                    name="endRow"
                    type="number"
                    className="form-control"
                    min="1"
                    max={complaint?.total}
                    id="endRow"
                    placeholder="Enter the row to end"
                    helpMessage={
                      complaint?.total
                        ? `Total data is ${complaint?.total}`
                        : null
                    }
                    required
                  />
                </FormGroup>
              </Col>
              <Button
                size="sm"
                color="primary"
                className="ml-3 waves-effect waves-light "
                type="submit"
              >
                {csv_loading ? 'Generating...' : 'Generate'}
              </Button>
              {csv && (
                <CSVLink
                  data={csvData}
                  filename={'my_complaints.csv'}
                  className="btn-sm btn-success ml-2"
                  target="_blank"
                  onClick={() => setCSVModal(false)}
                >
                  Download
                </CSVLink>
              )}
            </AvForm>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  const { csv, csv_loading, loading, complaint, message, complaintError } =
    state.Complaint;
  const { user } = state.Account;
  return {
    csv,
    csv_loading,
    loading,
    complaint,
    message,
    complaintError,
    user,
  };
};

export default connect(mapStateToProps, {
  fetchMyComplaint,
  assignComplaint,
  updateComplaint,
  assignComplaintSuccessful,
  fetchDataForMyComplaintsCSV,
  fetchForCSVError,
})(withRouter(MyComplaints));
