import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import CheckAction, {ReadCheck} from '../../../../components/Common/Summary/actionCheck';
import LICENSEKEYTABLE from './licenseKeysTable';
import PreviewLicenseKey from './previewLicenseKey';
import {
  fetchLicenseKeys,
  deleteLicenseKey,

} from '../../../../store/actions';

const LicenseKeys = ({
  licenseKeys,
  tableError,
  fetchLicenseKeys,
  deleteLicenseKey,
  loading,
  message,
  history,
  accessControl
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const breadcrumbItems = [
    { title: 'Dashboard', link: '/dashboard' },
    { title: 'License', link: '#' },
  ];

  const deleteLicenseKeyModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };
  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteLicenseKey(deleteId);
    setDeleteId(null);
  };
  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='License' breadcrumbItems={breadcrumbItems} />
          {message && (
            <Alert color='success' className='text-center'>
              {message}
            </Alert>
          )}
          {tableError ? (
            <Alert color='danger' className='text-center'>
              {tableError}
            </Alert>
          ) : (
            <>
              <CheckAction
                field='Configurations'
                subModule='License'
                iconType={'Create'}
              >
                <div className='mb-2 d-flex justify-content-end align-items-center '>
                  <Link to='/licenseKey_form'>
                    <Button color='success' className='mr-1 font-size-11' size='sm'>
                      <i className='mdi mdi-plus-circle-outline font-size-12'></i>{' '}
                      Create License
                    </Button>
                  </Link>
                </div>
              </CheckAction>
              <Row>
                <Col>
                  <LICENSEKEYTABLE
                    licenseKeyData={licenseKeys}
                    tableError={tableError}
                    loading={loading}
                    deleteLicenseKey={deleteLicenseKeyModal}
                    fetchLicenseKeys={fetchLicenseKeys}
                    history={history}
                    message={message}
                    previewAction={previewHandler}
                      CheckAction={CheckAction}
                      ReadCheck={ReadCheck}
                      accessControl={accessControl}
                  />
                </Col>
              </Row>

              {/* Delete Confirmation Modal */}
              <Modal
                isOpen={showDeleteModal}
                toggle={() => setShowDeleteModal(!showDeleteModal)}
              >
                <ModalBody>
                  <h4 className='card-title mb-4 text-center'>
                    {' '}
                    Are you sure you want to delete this License Key
                  </h4>
                  <button
                    onClick={() => deleteHandler()}
                    className='btn btn-outline-danger btn-sm w-100'
                  >
                    Click to Delete
                  </button>
                </ModalBody>
              </Modal>

              {/* LicenseKey Preview Modal */}
              <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
                <ModalHeader toggle={() => setShowModal(false)}>
                  Preview License Key
                </ModalHeader>
                <ModalBody>
                  <PreviewLicenseKey previewId={previewId} />
                </ModalBody>
              </Modal>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { licenseKeys, loading, licenseKeyError, message, tableError } =
    state.LicenseKey;
  return {accessControl, licenseKeys, tableError, loading, licenseKeyError, message };
};

export default connect(mapStateToProps, {
  fetchLicenseKeys,
  deleteLicenseKey,
})(withRouter(LicenseKeys));
