import {
  FETCH_REWARD,
  FETCH_REWARD_SUCCESSFUL,
  FETCH_REWARD_API_FAILED,
  FETCH_REWARD_HISTORY,
  FETCH_REWARD_HISTORY_SUCCESSFUL,
  FETCH_REWARD_HISTORY_API_FAILED,
  FETCH_REWARD_REDEEM_HISTORY,
  FETCH_REWARD_REDEEM_HISTORY_SUCCESSFUL,
  FETCH_REWARD_REDEEM_HISTORY_API_FAILED,
  FETCH_EACH_REWARD,
  FETCH_EACH_REWARD_SUCCESSFUL,
  FETCH_EACH_REWARD_API_FAILED,
  DELETE_REWARD,
  DELETE_REWARD_SUCCESSFUL,
  DELETE_REWARD_FAILED,
  CREATE_REWARD,
  CREATE_REWARD_SUCCESSFUL,
  CREATE_REWARD_FAILED,
  UPDATE_REWARD,
  UPDATE_REWARD_SUCCESSFUL,
  UPDATE_REWARD_FAILED,
  FETCH_REWARD_SUMMARY,
  FETCH_REWARD_SUMMARY_SUCCESSFULLY,
  FETCH_REWARD_SUMMARY_FAILED,
} from './actionTypes'

const initialState = {
  reward: null,
  rewardHistory: null,
  rewardHistoryError: null,
  rewardError: null,
  rewardSummary: null,
  tableError: null,
  summaryError: null,
  rewardPreview: null,
  message: null,
  loading: false,
}

const reward = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REWARD:
    case FETCH_REWARD_HISTORY:
    case FETCH_REWARD_REDEEM_HISTORY:
    case UPDATE_REWARD:
    case DELETE_REWARD:
      state = {
        ...state,
        reward: null,
        rewardError: null,
        tableError: null,
        rewardHistory: null,
        rewardHistoryError: null,
        loading: true,
        message: null,
      }
      break
    case FETCH_REWARD_SUCCESSFUL:
      state = {
        ...state,
        reward: action.payload,
        tableError: null,
        rewardError: null,
        loading: false,
      }
      break

    case FETCH_REWARD_HISTORY_SUCCESSFUL:
    case FETCH_REWARD_REDEEM_HISTORY_SUCCESSFUL:
      state = {
        ...state,
        rewardHistory: action.payload,
        rewardError: null,
        loading: false,
        rewardHistoryError: null,
      }
      break

    case DELETE_REWARD_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        rewardError: null,
        message: 'reward Code deleted successfully',
      }
      break
    case UPDATE_REWARD_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        rewardError: null,
        message: 'reward Code Updated Successfully',
      }
      break

    case FETCH_REWARD_SUMMARY:
      return {
        ...state,
        rewardSummary: null,
        summaryError: null,
        loading: true,
      }

    case FETCH_REWARD_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        rewardSummary: action.payload,
        summaryError: null,
        loading: false,
      }

    case FETCH_REWARD_SUMMARY_FAILED:
      return {
        ...state,
        rewardSummary: null,
        summaryError: action.payload,
        loading: false,
      }

    case FETCH_EACH_REWARD:
      state = {
        ...state,
        loading: true,
        reward: null,
        rewardError: null,
        message: null,
      }
      break

    case FETCH_EACH_REWARD_SUCCESSFUL:
      state = {
        ...state,
        rewardPreview: action.payload,
        loading: false,
        rewardError: null,
        message: null,
      }
      break

    case CREATE_REWARD:
      state = {
        ...state,
        loading: true,
        rewardError: null,
        message: null,
      }
      break

    case CREATE_REWARD_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        rewardError: null,
        message: `${action.payload.name}  created successfully`,
      }
      break

    case FETCH_REWARD_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      }

    case CREATE_REWARD_FAILED:
    case DELETE_REWARD_FAILED:
    case FETCH_EACH_REWARD_API_FAILED:
      state = {
        ...state,
        reward: null,
        rewardHistory: null,
        rewardPreview: null,
        loading: false,
        message: null,
        rewardError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      }
      break

    case FETCH_REWARD_HISTORY_API_FAILED:
    case FETCH_REWARD_REDEEM_HISTORY_API_FAILED:
      state = {
        ...state,
        rewardHistory: null,
        loading: false,
        message: null,
        rewardHistoryError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      }
      break

    case UPDATE_REWARD_FAILED:
      state = {
        ...state,
        loading: false,
        rewardError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default reward
