import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { fetchDashEarning } from '../../../store/actions';
import Logo from '../../../assets/images/logo4.svg';
import Cash from '../../../assets/images/cash.svg';
import CardPay from '../../../assets/images/card.svg';
//Import Charts
import ReactApexChart from 'react-apexcharts';
import './dashboard.scss';

const EarningReports = ({
  fetchDashEarning,
  dashEarning,
  loading,
  dashEarningError,
  user,
  start,
  end,
}) => {
  const [data, setData] = useState({
    menu: false,
    series: [72],
    options: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ['#5664d2'],
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: '79%',
          },
          track: {
            margin: 0,
          },

          dataLabels: {
            show: false,
          },
        },
      },
    },
    series3: [60],
    options3: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ['#1cbb8c'],
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: '79%',
          },
          track: {
            margin: 0,
          },

          dataLabels: {
            show: false,
          },
        },
      },
    },
    series2: [65],
    options2: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ['#5664d2'],
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: '72%',
          },
          track: {
            margin: 0,
          },

          dataLabels: {
            show: false,
          },
        },
      },
    },
  });

  useEffect(() => {
    if (user.userType === 'ADMIN') {
      fetchDashEarning({ start, end });
    }
  }, [end, fetchDashEarning, start]);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className='float-right'>
            <select className='custom-select custom-select-sm'>
              <option defaultValue>All</option>
              <option value='1'>Ride</option>
              {/* <option value="2">Bike</option> */}
            </select>
          </div>

          <h4 className='card-title mb-4'>Earning Reports</h4>
          <div className='text-center'>
            <Row>
              <Col sm={4}>
                <div>
                  <div className='mb-3'>
                    <div id='radialchart-1' className='apex-charts'>
                      {/* <ReactApexChart
                        options={data.options}
                        series={data.series}
                        type="radialBar"
                        height="30"
                      /> */}
                      <img src={CardPay} width='40' alt='card' />
                    </div>
                  </div>

                  <p className='text-muted text-truncate mb-2'>Card</p>
                  <h5>
                    {loading ? (
                      <Skeleton duration={2} width='20%' />
                    ) : (
                      `₦${
                        dashEarning?.card
                          ? dashEarning?.card.toLocaleString('en-US')
                          : 0
                      }`
                    )}
                  </h5>
                </div>
              </Col>

              <Col sm={4}>
                <div className='mt-5 mt-sm-0'>
                  <div className='mb-3'>
                    <div id='radialchart-2' className='apex-charts'>
                      {/* <ReactApexChart
                        options={data.options3}
                        series={data.series3}
                        type="radialBar"
                        height="30"
                      /> */}
                      <img src={Logo} width='35' alt='Logo' />
                    </div>
                  </div>
                  <p className='text-muted text-truncate mb-2'>nPAY</p>
                  <h5>
                    {loading ? (
                      <Skeleton duration={2} width='20%' />
                    ) : (
                      `₦${
                        dashEarning?.npay
                          ? dashEarning?.npay.toLocaleString('en-US')
                          : 0
                      }`
                    )}
                  </h5>
                </div>
              </Col>
              <Col sm={4}>
                <div className='mt-5 mt-sm-0'>
                  <div className='mb-3'>
                    <div id='radialchart-2' className='apex-charts'>
                      {/* <ReactApexChart
                        options={data.options2}
                        series={data.series2}
                        type="radialBar"
                        height="30"
                      /> */}
                      <img src={Cash} width='40' alt='cash' />
                    </div>
                  </div>

                  <p className='text-muted text-truncate mb-2'>Cash</p>
                  <h5>
                    {loading ? (
                      <Skeleton duration={2} width='20%' />
                    ) : (
                      `₦${
                        dashEarning?.cash
                          ? dashEarning?.cash.toLocaleString('en-US')
                          : 0
                      }`
                    )}
                  </h5>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { dashEarning, loading, dashEarningError } = state.Dashboard;
  return { dashEarning, loading, dashEarningError };
};
export default connect(mapStateToProps, { fetchDashEarning })(EarningReports);
