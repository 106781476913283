import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetReviewsService = (query) => {
  const http = new HttpService();
  let url = 'ride/review/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }

  return http.getData(url);
};

export const GetDriverReviewsService = (query) => {
  const http = new HttpService();
  let url = `ride/review/all`;

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }

  return http.getData(url);
};

export const GetEachReviewService = (id) => {
  const http = new HttpService();
  const url = `ride/review/${id}`;
  return http.getData(url);
};

export const GetReviewSummaryService = (query) => {
  // Payload with query
  const http = new HttpService();
  let url = 'summary/review';
  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};
