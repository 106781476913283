import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import GreySummaryCard from '../../../../components/Common/Summary/index';
import HistoryTable from './historyTable';

const PromoCode = ({ history }) => {
  const breadcrumbItems = [
    { title: 'Promo Code', link: '#' },
    { title: 'History', link: '/promo_history' },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Promo Code History"
            breadcrumbItems={breadcrumbItems}
          />
          <GreySummaryCard field="Rides Promotions" subModule="History">
            <>
              <Row>
                <Col>
                  <HistoryTable history={history} />
                </Col>
              </Row>
            </>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PromoCode;
