import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';
import SimpleBar from 'simplebar-react';
import FilterDropDown from '../../../../components/Common/FilterDropDown';
import CheckAction from '../../../../components/Common/Summary/actionCheck';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';

import ReactPaginate from 'react-paginate';

import Loader from '../../../../components/Common/Loading/index.js';

import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../../utils/general';
import queryString from 'query-string';
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

let skip = 0;
let dataLimit = 10;
const Table = ({
  cashoutAnalyticData,
  previewHandler,
  setGeneralDate,
  FetchCashoutAnalytics,
  message,
  history,
}) => {
  const [data, setData] = useState(null);
  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [skipPage, setSkipPage] = useState(0);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState({
    start: '',
    end: '',
  });

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(cashoutAnalyticData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
    };
    FetchCashoutAnalytics(pageInfo);
    SetUrlWithParams();
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    setGeneralDate({ start: values.startDate, end: values.endDate });
    setMenu(false);
    let queryParams = {
      start: values.startDate,
      end: values.endDate,
    };
    FetchCashoutAnalytics(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setSearch('');
    setDate({
      start: '',
      end: '',
    });
    let queryParams = {};
    FetchCashoutAnalytics(queryParams);
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    FetchCashoutAnalytics(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      skip,
    };
    FetchCashoutAnalytics(queryParams);
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
        };
        FetchCashoutAnalytics(queryParams);
      }, 2000);
    }
  }, [FetchCashoutAnalytics, message]);

  useEffect(() => {
    if (cashoutAnalyticData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(cashoutAnalyticData?.total / take));
    }
  }, [cashoutAnalyticData]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    FetchCashoutAnalytics(parsedUrlParams);
  }, [FetchCashoutAnalytics]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      take,
      skip: pageNumber,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        take,
        skip,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };
  const tableDataHandler = () => {
    const rowData = cashoutAnalyticData?.result.map((data, i) => {
      return {
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,

        user: (
          <div
            className="text-truncate"
            style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}
          >
            <Link to="#" className="mr-3 text-dark">
              <div className="text-capitalize">
                {data?.user?.firstName} {data?.user?.lastName}
              </div>
              <div className="d-flex align-items-center">
                <div className="mr-2">
                  <img
                    src={data?.user?.picture ? data?.user?.picture : avatarImg}
                    alt=""
                    height="20"
                    className="rounded-circle avatar-xs"
                  />
                </div>
                <div>
                  <span
                    className={`badge badge-${data?.user?.userType === 'RIDER'
                        ? 'primary'
                        : data?.user?.userType === 'DRIVER'
                          ? 'secondary'
                          : data?.user?.userType === 'ADMIN'
                            ? 'success'
                            : 'warning'
                      }`}
                  >
                    {data?.user?.userType}
                  </span>
                </div>
              </div>
            </Link>
          </div>
        ),
        onlineHour: data?.onlineHours,
        acceptanceRate: (
          <div>
            <div>
              Receive-{data?.assigned !== 0
                ? (data?.assigned / data?.assigned).toFixed(2)
                : 0}
            </div>
            <div>
              Declined-
              {data?.decline !== 0
                ? (data?.decline / data?.assigned).toFixed(2)
                : 0}
            </div>
          </div>
        ),
        cancelled: (
          <div>
            <div>Driver-{data?.driverCancelled} </div>
            <div>Rider-{data?.riderCancelled}</div>
            <div>system-{data?.systemCancelled}</div>
          </div>
        ),
        completed: (
          <div>
            <div>completed-{data?.completed}</div>
            <div>
              Rate-
              {data?.assigned !== 0
                ? (data?.completed / data?.assigned).toFixed(2)
                : 0}
            </div>
          </div>
        ),
        action: (
          <CheckAction field="Analytics" subModule="Cashout" iconType='Read'>
            <Link
              to="#"
              className="mr-3 text-secondary"
              id="Analysis"
              onClick={() => previewHandler(data?.user?.id, date)}
            >
              <i className="mdi mdi-eye  pr-2 font-size-16 "></i>
            </Link>
            <UncontrolledTooltip placement="top" target="Analysis">
              Analysis
            </UncontrolledTooltip>
          </CheckAction>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
        },
        {
          text: 'User',
          dataField: 'user',
          sort: true,
        },
        {
          text: 'OnlineHour',
          dataField: 'onlineHour',
        },

        {
          text: 'Acceptance Rate %',
          dataField: 'acceptanceRate',
        },
        {
          text: 'Cancelled',
          dataField: 'cancelled',
        },
        {
          text: 'Completion Rate %',
          dataField: 'completed',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-3">
              <div className="d-flex justify-content-end align-items-center mb-2">
                <FilterDropDown menu={menu} toggleMenu={() => setMenu(!menu)}>
                  <div>
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleDateFilter}
                    >
                      <SimpleBar
                        style={{
                          maxHeight: '250px',
                        }}
                      >
                        <Row className="px-3  pt-3 pb-0">
                          <Col md="12">
                            <FormGroup>
                              <AvField
                                name="startDate"
                                type="date"
                                className="form-control bg-light"
                                id="startDate"
                                value={date.start}
                                helpMessage="Start Date"
                                placeholder="Please choose the start date..."
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <AvField
                                name="endDate"
                                type="date"
                                className="form-control bg-light"
                                id="endDate"
                                value={date.end}
                                helpMessage="End Date"
                                placeholder="Please choose the end date..."
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <div className="p-2 d-flex justify-content-between align-items-center bg-light">
                          <Link
                            to="#"
                            onClick={resetHandler}
                            className="btn btn-sm  bg-light font-size-14"
                          >
                            <i className=" mdi mdi-refresh mr-1"> Reset</i>
                          </Link>

                          <button
                            className="btn btn-success btn-sm mr-2"
                            type="submit"
                          >
                            <i className="mdi mdi-arrow-right-circle mr-1"></i>
                            Send
                          </button>
                        </div>
                      </SimpleBar>
                    </AvForm>
                  </div>
                </FilterDropDown>
              </div>
              {!isLoading && data !== null ? (
                <>
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <Input
                        bsSize="sm"
                        id="take"
                        name="take"
                        type="select"
                        onChange={handleLimitChange}
                        value={take}
                      >
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>
                    <div>
                      <Input
                        bsSize="sm"
                        id="search"
                        value={search}
                        name="search"
                        onChange={SearchHandler}
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField="id"
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses="table-responsive"
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={skipPage}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Table;
