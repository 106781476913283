import React, { Component } from 'react';
import AlartError from '../../../components/Common/AlertCard';
import {
  Row,
  Col,
  Button,
  Alert,
  Card,
  CardBody,
  FormGroup,
  Label,
  Container,
} from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import queryString from 'query-string';

// actions
import { emailSubscription } from '../../../store/actions';

// import images
import logosmdark from '../../../assets/images/nrydeLogo.png';
import { AvForm, AvField } from 'availity-reactstrap-validation';

class UnsubscribeEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event, values) {
    let query;
    const formData = {
      notificationType: values.notificationType,
    };
    if (this.state.userEmail) {
      query = this.state.userEmail;
    } else {
      query = values.email;
    }
    this.props.emailSubscription(formData, query);
  }

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    document.body.classList.add('auth-body-bg');
    this.setState({ ...this.state, userEmail: params?.email });
  }

  componentWillUnmount() {
    document.body.classList.remove('auth-body-bg');
  }

  render() {
    return (
      <React.Fragment>
        <div className='home-btn d-none d-sm-block'>
          <Link to='/'>
            <i className='mdi mdi-home-variant h2 text-white'></i>
          </Link>
        </div>

        <div>
          <Container fluid className='p-0'>
            <Row className='no-gutters'>
              <Col lg={12}>
                <div className='authentication-page-content p-4 d-flex align-items-center min-vh-100'>
                  <div className='w-100'>
                    <Row className='justify-content-center'>
                      <Col lg={4}>
                        <Card className='shadow-lg'>
                          <CardBody>
                            <AvForm
                              className='form-horizontal'
                              onValidSubmit={this.handleSubmit}
                            >
                              {this.props.activate_message ? (
                                <>
                                  <div className='text-center'>
                                    <Link to='/' className='logo'>
                                      <img
                                        src={logosmdark}
                                        height='50'
                                        alt='logo'
                                      />
                                    </Link>
                                  </div>
                                  <Alert
                                    color='success'
                                    className='text-center'
                                  >
                                    {this.props.activate_message}
                                  </Alert>
                                </>
                              ) : (
                                <>
                                  <div>
                                    <div className='text-center'>
                                      <div>
                                        <Link to='/' className='logo'>
                                          <img
                                            src={logosmdark}
                                            height='50'
                                            alt='logo'
                                          />
                                        </Link>
                                      </div>
                                      {this.props.activationError && (
                                        <AlartError
                                          error={this.props.activationError}
                                        />
                                      )}

                                      <h4 className='font-size-18 mt-4'>
                                        Welcome Back !
                                      </h4>
                                      <h4 className='text-center'>
                                        {this.state.userEmail &&
                                          this.state.userEmail}
                                      </h4>
                                      <p className='text-muted'>
                                        Unsubscribe to nRyde Email!
                                      </p>
                                    </div>

                                    <div>
                                      <Row>
                                        {!this.state.userEmail && (
                                          <Col md={12}>
                                            <FormGroup className=' mb-4'>
                                              <Label htmlFor='email'>
                                                Email
                                              </Label>
                                              <AvField
                                                name='email'
                                                type='email'
                                                className='form-control bg-light'
                                                id='email'
                                                placeholder='Enter Email ...'
                                                required
                                              />
                                            </FormGroup>
                                          </Col>
                                        )}

                                        <Col md={12}>
                                          <FormGroup className=' mb-4'>
                                            <AvField
                                              className='form-control bg-light'
                                              type='select'
                                              name='notificationType'
                                              value='ALL'
                                              label='Notification Type'
                                              required
                                            >
                                              <option value='ALL'>All</option>
                                              <option value='IMPORTANT'>
                                                IMPORTANT
                                              </option>
                                              <option value='NONE'>NONE</option>
                                            </AvField>
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                      <div className=' text-center'>
                                        <Button
                                          color='primary'
                                          className='w-md waves-effect waves-light'
                                        >
                                          {this.props.loading
                                            ? 'Activating...'
                                            : 'Activate Now!'}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className='mt-2 text-center'>
                                <p>© 2022 nRyde.</p>
                              </div>
                            </AvForm>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { activationError, activate_message, loading } = state.Account;
  return { activationError, activate_message, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    emailSubscription,
  })(UnsubscribeEmail)
);
