import {
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_SUCCESSFUL,
  FETCH_ACTIVITIES_API_FAILED,
  FETCH_EACH_ACTIVITIES,
  FETCH_EACH_ACTIVITIES_SUCCESSFUL,
  FETCH_EACH_ACTIVITIES_API_FAILED,
  DELETE_ACTIVITIES,
  DELETE_ACTIVITIES_SUCCESSFUL,
  DELETE_ACTIVITIES_FAILED,
  CREATE_ACTIVITIES,
  CREATE_ACTIVITIES_SUCCESSFUL,
  CREATE_ACTIVITIES_FAILED,
  UPDATE_ACTIVITIES,
  UPDATE_ACTIVITIES_SUCCESSFUL,
  UPDATE_ACTIVITIES_FAILED,
} from './actionTypes';

export const fetchActivities = (payload) => {
  return {
    type: FETCH_ACTIVITIES,
    payload,
  };
};

export const fetchActivitiesSuccessful = (payload) => {
  return {
    type: FETCH_ACTIVITIES_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchActivitiesError = (error) => {
  return {
    type: FETCH_ACTIVITIES_API_FAILED,
    payload: error,
  };
};

export const fetchEachActivities = (id) => {
  return {
    type: FETCH_EACH_ACTIVITIES,
    payload: id,
  };
};

export const fetchEachActivitiesSuccessful = (payload) => {
  return {
    type: FETCH_EACH_ACTIVITIES_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachActivitiesError = (error) => {
  return {
    type: FETCH_EACH_ACTIVITIES_API_FAILED,
    payload: error,
  };
};

export const deleteActivities = (id) => {
  return {
    type: DELETE_ACTIVITIES,
    payload: id,
  };
};

export const deleteActivitiesSuccessful = (payload) => {
  return {
    type: DELETE_ACTIVITIES_SUCCESSFUL,
    payload,
  };
};

export const deleteActivitiesFailed = (error) => {
  return {
    type: DELETE_ACTIVITIES_FAILED,
    payload: error,
  };
};

export const createActivities = (payload) => {
  return {
    type: CREATE_ACTIVITIES,
    payload,
  };
};

export const createActivitiesSuccessful = (payload) => {
  return {
    type: CREATE_ACTIVITIES_SUCCESSFUL,
    payload,
  };
};

export const createActivitiesFailed = (error) => {
  return {
    type: CREATE_ACTIVITIES_FAILED,
    payload: error,
  };
};

export const updateActivities = (payload, id) => {
  return {
    type: UPDATE_ACTIVITIES,
    payload: { payload, id },
  };
};

export const updateActivitiesFailed = (error) => {
  return {
    type: UPDATE_ACTIVITIES_FAILED,
    payload: error,
  };
};

export const updateActivitiesSuccessful = (payload) => {
  return {
    type: UPDATE_ACTIVITIES_SUCCESSFUL,
    payload,
  };
};
