import {
  IS_BANK_AUTHORIZE,
  FETCH_BANK_LIST,
  FETCH_BANK_LIST_SUCCESSFUL,
  FETCH_BANK_LIST_API_FAILED,
  FETCH_BANK,
  FETCH_BANK_SUCCESSFUL,
  FETCH_BANK_API_FAILED,
  FETCH_EACH_BANK,
  FETCH_EACH_BANK_SUCCESSFUL,
  FETCH_EACH_BANK_API_FAILED,
  DELETE_BANK,
  DELETE_BANK_SUCCESSFUL,
  DELETE_BANK_FAILED,
  CREATE_BANK,
  CREATE_BANK_SUCCESSFUL,
  CREATE_BANK_FAILED,
  GET_BANK_RECIPIENT,
  GET_BANK_RECIPIENT_SUCCESSFUL,
  GET_BANK_RECIPIENT_FAILED,
  UPDATE_BANK,
  UPDATE_BANK_SUCCESSFUL,
  UPDATE_BANK_FAILED,
  FETCH_BANK_SUMMARY,
  FETCH_BANK_SUMMARY_SUCCESSFULLY,
  FETCH_BANK_SUMMARY_FAILED,
  FETCH_FOR_BANK_CSV,
  FETCH_FOR_BANK_CSV_SUCCESSFULLY,
  FETCH_FOR_BANK_CSV_ERROR,
} from './actionTypes';

const initialState = {
  bankList: null,
  bankError: null,
  bank: null,
  bankRecipient: null,
  bankRecipientError: null,
  bankError: null,
  tableError: null,
  bankPreview: null,
  bankSummary: null,
  summaryError: null,
  isAuthorize: null,
  message: null,
  loading: false,
  csvData: null,
  csv_loading: false,
};

const Bank = (state = initialState, action) => {
  switch (action.type) {
    case IS_BANK_AUTHORIZE:
      state = {
        ...state,
        isAuthorize: action.payload,
      };
      break;

    case FETCH_BANK:
    case FETCH_BANK_LIST:
    case UPDATE_BANK:
    case DELETE_BANK:
      state = {
        ...state,
        bank: null,
        bankError: null,
        tableError: null,
        bankRecipient: null,
        bankError: null,
        loading: true,
        isAuthorize: null,
        message: null,
      };
      break;

    case GET_BANK_RECIPIENT:
      state = {
        ...state,
        bankRecipient: null,
        loading: true,
        bankRecipientError: null,
        message: null,
      };
      break;
    case FETCH_BANK_SUCCESSFUL:
      state = {
        ...state,
        bank: action.payload,
        bankError: null,
        loading: false,
      };
      break;
    case GET_BANK_RECIPIENT_SUCCESSFUL:
      state = {
        ...state,
        bankRecipient: action.payload,
        bankRecipientError: null,
        loading: false,
      };
      break;

    case GET_BANK_RECIPIENT_FAILED:
      state = {
        ...state,
        bankRecipient: null,
        bankRecipientError: action.payload,
        loading: false,
      };
      break;
    case FETCH_BANK_LIST_SUCCESSFUL:
      state = {
        ...state,
        bankList: action.payload,
        bankError: null,
        tableError: null,
        loading: false,
      };
      break;

    case DELETE_BANK_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        bankError: null,
        message: 'Bank details deleted successfully',
      };
      break;
    case UPDATE_BANK_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        bankError: null,
        message: 'Bank details updated Successfully',
      };
      break;

    case FETCH_EACH_BANK:
      state = {
        ...state,
        loading: true,
        bank: null,
        bankError: null,
        message: null,
      };
      break;
    case FETCH_EACH_BANK_SUCCESSFUL:
      state = {
        ...state,
        bankPreview: action.payload,
        loading: false,
        bankError: null,
        message: null,
      };
      break;

    case FETCH_BANK_SUMMARY:
      return {
        ...state,
        bankSummary: null,
        summaryError: null,
        loading: true,
      };

    case FETCH_BANK_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        bankSummary: action.payload,
        summaryError: null,
        loading: false,
      };

    case FETCH_BANK_SUMMARY_FAILED:
      return {
        ...state,
        bankSummary: null,
        summaryError: action.payload,
        loading: false,
      };

    case CREATE_BANK:
      state = {
        ...state,
        loading: true,
        bankError: null,
        message: null,
      };
      break;

    case CREATE_BANK_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        bankError: null,
        message: `${action.payload.bankName} details added successfully for ${action.payload.accountName}`,
      };
      break;

    case FETCH_BANK_API_FAILED:
      return {
        ...state,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };

    case CREATE_BANK_FAILED:
    case FETCH_BANK_LIST_API_FAILED:
    case DELETE_BANK_FAILED:
    case FETCH_EACH_BANK_API_FAILED:
      state = {
        ...state,
        bank: null,
        bankPreview: null,
        loading: false,
        message: null,
        bankError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case UPDATE_BANK_FAILED:
      state = {
        ...state,
        loading: false,
        bankError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      };
      break;

    case FETCH_FOR_BANK_CSV:
      state = {
        ...state,
        csvData: null,
        csv_loading: false,
      };
      break;

    case FETCH_FOR_BANK_CSV_SUCCESSFULLY:
      state = {
        ...state,
        csvData: action.payload,
        csv_loading: false,
      };
      break;

    case FETCH_FOR_BANK_CSV_ERROR:
      state = {
        ...state,
        csvData: null,
        csv_loading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Bank;
