import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Col, Card, CardBody, Media } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { fetchRideSummary } from '../../../../store/actions';

const MiniWidgets = ({
  summaryError,
  rideSummary,
  fetchRideSummary,
  history,
  start,
  end,
  loading,
  date,
  defaultIcon,
}) => {
  useEffect(() => {
    let query = {};
    if (date.end) {
      query = {...date};
    }
    const queryParam = queryString.parse(history.location.search);
    if (queryParam['user']) {
      query.driverId = queryParam['user'];
    }
    fetchRideSummary(query);
  }, [date, fetchRideSummary]);

  const reports = [
    {
      icon: defaultIcon ? defaultIcon : 'fas fa-users',
      title: 'TOTAL',
      value: `${rideSummary?.result.total}`,
      riders: `${rideSummary?.result.noOfRiders}`,
      rate: `${
        rideSummary?.previousRegisteredDrivers
          ? rideSummary?.previousRegisteredDrivers
          : '0'
      }%`,
      color: 'primary',
      desc: 'From previous period',
    },
    {
      icon: 'ri-police-car-fill',
      title: 'COMPLETED',
      value: rideSummary?.result.completed,
      rate: `${
        rideSummary?.previousActiveDrivers
          ? rideSummary?.previousActiveDrivers
          : '0'
      }%`,
      color: 'success',
      desc: 'From previous period',
    },
    {
      icon: 'fas fa-ban  ',
      title: 'PROCESSING',
      value: `${rideSummary?.result.processing}`,
      rate: `${
        rideSummary?.previousRegisteredUsers
          ? rideSummary?.previousRegisteredUsers
          : '0'
      }%`,
      color: 'warning',
      desc: 'From previous period',
    },
    {
      icon: 'fas fa-exclamation-triangle',
      title: 'CANCELLED',
      value: `${rideSummary?.result?.cancelled}`,
      rate: `${
        rideSummary?.previousActiveUsers
          ? rideSummary?.previousActiveUsers
          : '0'
      }%`,
      byRiders: `${rideSummary?.result?.cancellation?.rider}`,
      byDrivers: `${rideSummary?.result?.cancellation?.driver}`,
      byAdmins: `${rideSummary?.result?.cancellation?.admin}`,
      bySystem: `${rideSummary?.result?.cancellation?.system}`,
      color: 'danger',
      desc: 'From previous period',
      updatedTime: `${rideSummary?.time}`,
    },
  ];

  return (
    <React.Fragment>
      {summaryError === null &&
        reports.map((report, key) => (
          <Col key={key} md={3}>
            <Card className="d-flex flex-row">
              <CardBody>
                <Media>
                  <Media body className="overflow-hidden">
                    <span
                      className={`text-${report.color} badge badge-soft-${report.color} rounded`}
                    >
                      <i className={report.icon + ' font-size-16'}></i>
                    </span>
                    <p className="text-truncate font-size-14 mt-4 mb-2 ">
                      {report.title}
                    </p>
                    <h2 className="mt-0 mb-0 font-size-">
                      {rideSummary === null ? (
                        <Skeleton duration={2} width="20%" />
                      ) : (
                        report.value.toLocaleString()
                      )}
                    </h2>
                    {rideSummary?.isCache && report?.updatedTime && (
                      <p
                        className="font-size-12 text-primary"
                        style={{ position: 'absolute', right: '11px' }}
                      >
                        Updated at: {report?.updatedTime?.split(',')?.pop()}
                      </p>
                    )}
                  </Media>
                </Media>
              </CardBody>

              {report.riders && (
                <CardBody
                  className="p-1 pr-3 py-4 d-flex flex-column"
                  style={{ justifyContent: 'space-evenly' }}
                >
                  {rideSummary === null ? (
                    <Skeleton duration={2} width="100%" />
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-muted font-size-12">RIDERS</span>
                      <h6 className="m-0">{report.riders}</h6>
                    </div>
                  )}
                </CardBody>
              )}

              {report.byRiders && (
                <CardBody
                  className="p-1 pr-3 py-4 d-flex flex-column"
                  style={{ justifyContent: 'space-evenly' }}
                >
                  {rideSummary === null ? (
                    <Skeleton duration={2} width="100%" />
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-muted font-size-12">System</span>
                      <h6 className="m-0">{report.bySystem}</h6>
                    </div>
                  )}

                  {rideSummary === null ? (
                    <Skeleton duration={2} width="100%" />
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-muted font-size-12">Riders</span>
                      <h6 className="m-0">{report.byRiders}</h6>
                    </div>
                  )}
                  {rideSummary === null ? (
                    <Skeleton duration={2} width="100%" />
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-muted font-size-12">Drivers</span>
                      <h6 className="m-0">{report.byDrivers}</h6>
                    </div>
                  )}

                  {rideSummary === null ? (
                    <Skeleton duration={2} width="100%" />
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-muted font-size-12">Admin</span>
                      <h6 className="m-0">{report.byAdmins}</h6>
                    </div>
                  )}
                </CardBody>
              )}
            </Card>
          </Col>
        ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { summaryError, rideSummary } = state.Rides;
  return { summaryError, rideSummary };
};

export default withRouter(
  connect(mapStateToProps, { fetchRideSummary })(MiniWidgets)
);
