export const FETCH_BUS_STOPS = 'FETCH_BUS_STOPS';
export const FETCH_BUS_STOPS_SUCCESSFUL = 'FETCH_BUS_STOPS_SUCCESSFUL';
export const FETCH_BUS_STOPS_API_FAILED = 'FETCH_BUS_STOPS_API_FAILED';

export const FETCH_EACH_BUS_STOP = 'FETCH_EACH_BUS_STOP';
export const FETCH_EACH_BUS_STOP_SUCCESSFUL =
  'FETCH_EACH_BUS_STOP_SUCCESSFUL';
export const FETCH_EACH_BUS_STOP_API_FAILED =
  'FETCH_EACH_BUS_STOP_API_FAILED';

export const DELETE_BUS_STOP = 'DELETE_BUS_STOP';
export const DELETE_BUS_STOP_SUCCESSFUL = 'DELETE_BUS_STOP_SUCCESSFULLY';
export const DELETE_BUS_STOP_FAILED = 'DELETE_BUS_STOP_FAILED';

export const CREATE_BUS_STOP = 'CREATE_BUS_STOP';
export const CREATE_BUS_STOP_SUCCESSFUL = 'CREATE_BUS_STOP_SUCCESSFULLY';
export const CREATE_BUS_STOP_FAILED = 'CREATE_BUS_STOP_FAILED';

export const UPDATE_BUS_STOP = 'UPDATE_BUS_STOP';
export const UPDATE_BUS_STOP_SUCCESSFUL = 'UPDATE_BUS_STOP_SUCCESSFULLY';
export const UPDATE_BUS_STOP_FAILED = 'FAILED';
