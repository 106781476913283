import React, { useState, useEffect } from 'react'
import AlartError from '../../../../../components/Common/AlertCard'
import { connect } from 'react-redux'
import {
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
} from 'reactstrap'
//Import Breadcrumb
import Breadcrumbs from '../../../../../components/Common/Breadcrumb'
import MiniWidgets from './Components/widget'
import DriverTable from './bykeTable'
import UserDocPreview from '../../UserDocPreview'
import DocPreview from '../../Document/previewDoc'
import GreySummaryCard from '../../../../../components/Common/Summary/index'
import CheckAction, {
  ReadCheck,
} from '../../../../../components/Common/Summary/actionCheck'
//Import Action to copy breadcrumb items from local state to redux state
import {
  fetchAllUser,
  fetchUsersForCSV,
  fetchForCSVError,
  deleteUser,
  updateUserProfile,
  deleteUserFailed,
  fetchUserExport,
  fetchUserExportFailed,
} from '../../../../../store/actions'

const Dashboard = ({
  users,
  user,
  fetchAllUser,
  fetchUsersForCSV,
  fetchForCSVError,
  csvData,
  csv_loading,
  loading,
  userError,
  accessControl,
  isAuthorize,
  deleteUser,
  updateUserProfile,
  deleteUserFailed,
  message,
  approveMessage,
  approveError,
  fetchUserExport,
  fetchUserExportFailed,
  exportMessage,
  exportError,
  exportFile,
}) => {
  const [showUserDocModal, setShowUserDocModal] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [userId, setUserId] = useState(null)
  const [userName, setUserName] = useState('')
  const [docIsPdf, setDocIsPdf] = useState(false)
  const [docIsImg, setDocIsImg] = useState(false)
  const [docName, setDocName] = useState(null)
  const [previewId, setPreviewId] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteData, setDeleteData] = useState(null)
  const [dateFilter, setDate] = useState({})
  const breadcrumbItems = [
    { title: 'Byke Captains', link: '#' },
    { title: 'Dashboard', link: '#' },
  ]

  const updateUserDocInfo = (id, name) => {
    setShowUserDocModal(true)
    setUserId(id)
    setUserName(name)
  }

  const previewHandler = (name, id, isImg, isDoc) => {
    setShowPreviewModal(true)
    setPreviewId(id)
    setDocName(name)
    setDocIsImg(isImg)
    setDocIsPdf(isDoc)
  }
  const deleteUserModal = (data) => {
    setShowDeleteModal(true)
    setDeleteData(data)
  }

  const deleteHandler = () => {
    setShowDeleteModal(false)
    const formData = {
      id: deleteData.id,
      email: deleteData.email,
    }
    deleteUser(formData)
    setDeleteData(null)
  }

  useEffect(() => {
    if (userError) {
      setTimeout(() => {
        deleteUserFailed('')
      }, 2000)
    }
  }, [userError])
  useEffect(() => {
    if (exportMessage || exportError) {
      setTimeout(() => {
        fetchUserExportFailed('')
      }, 2000)
    }
  }, [exportMessage, exportError])
  const date = (e) => {
    setDate(e)
  }
  let isSuperAdmin = user?.userRoles?.find((usr) => usr.includes('SUPERADMIN'))
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Byke Captains"
            breadcrumbItems={breadcrumbItems}
          />
          <GreySummaryCard field="Captains" subModule="Byke Captains">
            {userError && (
              <Alert color="danger" className="text-center">
                {userError}
              </Alert>
            )}
            {message && (
              <Alert color="success" className="text-center">
                {message}
              </Alert>
            )}
            {isAuthorize ? (
              <AlartError error={isAuthorize} />
            ) : (
              <>
                <Row>
                  <Col sm={12}>
                    <Row>
                      <MiniWidgets
                        userType={'DRIVER'}
                        vehicle={'BYKE'}
                        date={dateFilter}
                        defaultIcon="fas fa-id-card"
                      />
                    </Row>
                  </Col>
                  <Col>
                    <DriverTable
                      usersData={users}
                      userType={'Driver'}
                      dateFilter={date}
                      loading={loading}
                      fetchAllUser={fetchAllUser}
                      fetchUsersForCSV={fetchUsersForCSV}
                      fetchForCSVError={fetchForCSVError}
                      csvData={csvData}
                      csv_loading={csv_loading}
                      updateUserDocInfo={updateUserDocInfo}
                      updateUserProfile={updateUserProfile}
                      deleteUser={deleteUserModal}
                      message={message}
                      approveMessage={approveMessage}
                      approveError={approveError}
                      CheckAction={CheckAction}
                      ReadCheck={ReadCheck}
                      accessControl={accessControl}
                      exportFile={exportFile}
                      fetchUserExport={fetchUserExport}
                      fetchUserExportFailed={fetchUserExportFailed}
                      isSuperAdmin={isSuperAdmin}
                    />
                  </Col>
                </Row>
                <Modal
                  isOpen={showUserDocModal}
                  toggle={() => setShowUserDocModal(!showUserDocModal)}
                >
                  <ModalHeader toggle={() => setShowUserDocModal(false)}>
                    {userName}'s Documents
                  </ModalHeader>
                  <ModalBody>
                    <UserDocPreview
                      userId={userId}
                      previewAction={previewHandler}
                    />
                  </ModalBody>
                </Modal>
                <Modal
                  size={`${docIsPdf ? 'lg' : 'md'}`}
                  isOpen={showPreviewModal}
                  toggle={() => setShowPreviewModal(!showPreviewModal)}
                >
                  <ModalHeader toggle={() => setShowPreviewModal(false)}>
                    {docName ? docName : 'Preview Document'}
                  </ModalHeader>
                  <ModalBody>
                    <DocPreview
                      previewId={previewId}
                      docIsPdf={docIsPdf}
                      docIsImg={docIsImg}
                    />
                  </ModalBody>
                </Modal>
                <Modal
                  isOpen={showDeleteModal}
                  toggle={() => setShowDeleteModal(!showDeleteModal)}
                >
                  <ModalBody>
                    <h4 className="card-title mb-4 text-center">
                      {' '}
                      Are you sure you want to delete this driver
                    </h4>
                    <button
                      onClick={() => deleteHandler()}
                      className="btn btn-outline-danger btn-sm w-100"
                    >
                      Click to Delete
                    </button>
                  </ModalBody>
                </Modal>
              </>
            )}
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = (state) => {
  const { user, accessControl } = state.Account
  const {
    csvData,
    csv_loading,
    users,
    loading,
    userError,
    isAuthorize,
    message,
    approveMessage,
    approveError,
    exportMessage,
    exportError,
    exportFile,
  } = state.Users
  return {
    csvData,
    csv_loading,
    users,
    accessControl,
    loading,
    userError,
    isAuthorize,
    message,
    approveMessage,
    approveError,
    exportMessage,
    exportError,
    exportFile,
    user,
  }
}

export default connect(mapStatetoProps, {
  fetchAllUser,
  deleteUser,
  updateUserProfile,
  deleteUserFailed,
  fetchUsersForCSV,
  fetchForCSVError,
  fetchUserExport,
  fetchUserExportFailed,
})(Dashboard)
