import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
  SET_NETWORK_ERROR,
} from './actionTypes';
import {
  setNetworkError
} from './actions';


function* handleRequest(action) {
  try {
    // Run any API called
  } catch (error) {
    if (error.code === 'ERR_NETWORK') {
      // Dispatch the action to update the state with the network error details
      yield put(setNetworkError(error.code, error.message));
    }
  }
}

export function* watchHandleRequest() {
  yield takeEvery(SET_NETWORK_ERROR, handleRequest);
}


function* NetworkCheckerSaga() {
  yield all([
    fork(watchHandleRequest),
  ]);
}

export default NetworkCheckerSaga;
