import {
  FETCH_LOYALTYTIER_SUMMARY,
  FETCH_LOYALTYTIER_SUMMARY_SUCCESSFULLY,
  FETCH_LOYALTYTIER_SUMMARY_FAILED,
  FETCH_LOYALTYTIER,
  FETCH_LOYALTYTIER_SUCCESSFUL,
  FETCH_LOYALTYTIER_API_FAILED,
  FETCH_EACH_LOYALTYTIER,
  FETCH_EACH_LOYALTYTIER_SUCCESSFUL,
  FETCH_EACH_LOYALTYTIER_API_FAILED,
  FETCH_MY_LOYALTYTIER,
  FETCH_MY_LOYALTYTIER_SUCCESSFUL,
  FETCH_MY_LOYALTYTIER_API_FAILED,
  DELETE_LOYALTYTIER,
  DELETE_LOYALTYTIER_SUCCESSFUL,
  DELETE_LOYALTYTIER_FAILED,
  CREATE_LOYALTYTIER,
  CREATE_LOYALTYTIER_SUCCESSFUL,
  CREATE_LOYALTYTIER_FAILED,
  UPDATE_LOYALTYTIER,
  UPDATE_LOYALTYTIER_SUCCESSFUL,
  UPDATE_LOYALTYTIER_FAILED,
  IS_LOYALTYTIER_AUTHORIZE,
} from './actionTypes';

export const fetchLoyaltyTierAuthorizeError = (error) => {
  return {
    type: IS_LOYALTYTIER_AUTHORIZE,
    payload: error,
  };
};

export const fetchLoyaltyTier = (payload) => {
  return {
    type: FETCH_LOYALTYTIER,
    payload,
  };
};

export const fetchLoyaltyTierSuccessful = (payload) => {
  return {
    type: FETCH_LOYALTYTIER_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchLoyaltyTierError = (error) => {
  return {
    type: FETCH_LOYALTYTIER_API_FAILED,
    payload: error,
  };
};

export const fetchEachLoyaltyTier = (id) => {
  return {
    type: FETCH_EACH_LOYALTYTIER,
    payload: id,
  };
};

export const fetchEachLoyaltyTierSuccessful = (payload) => {
  return {
    type: FETCH_EACH_LOYALTYTIER_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachLoyaltyTierError = (error) => {
  return {
    type: FETCH_EACH_LOYALTYTIER_API_FAILED,
    payload: error,
  };
};

export const fetchMyLoyaltyTier = (payload) => {
  return {
    type: FETCH_MY_LOYALTYTIER,
    payload,
  };
};

export const fetchMyLoyaltyTierSuccessful = (payload) => {
  return {
    type: FETCH_MY_LOYALTYTIER_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchMyLoyaltyTierError = (error) => {
  return {
    type: FETCH_MY_LOYALTYTIER_API_FAILED,
    payload: error,
  };
};

export const deleteLoyaltyTier = (id) => {
  return {
    type: DELETE_LOYALTYTIER,
    payload: id,
  };
};

export const deleteLoyaltyTierSuccessful = (payload) => {
  return {
    type: DELETE_LOYALTYTIER_SUCCESSFUL,
    payload,
  };
};

export const deleteLoyaltyTierFailed = (error) => {
  return {
    type: DELETE_LOYALTYTIER_FAILED,
    payload: error,
  };
};

export const createLoyaltyTier = (payload) => {
  return {
    type: CREATE_LOYALTYTIER,
    payload,
  };
};

export const createLoyaltyTierSuccessful = (payload) => {
  return {
    type: CREATE_LOYALTYTIER_SUCCESSFUL,
    payload,
  };
};

export const createLoyaltyTierFailed = (error) => {
  return {
    type: CREATE_LOYALTYTIER_FAILED,
    payload: error,
  };
};

export const updateLoyaltyTier = (payload) => {
  return {
    type: UPDATE_LOYALTYTIER,
    payload,
  };
};

export const updateLoyaltyTierFailed = (error) => {
  return {
    type: UPDATE_LOYALTYTIER_FAILED,
    payload: error,
  };
};

export const updateLoyaltyTierSuccessful = (payload) => {
  return {
    type: UPDATE_LOYALTYTIER_SUCCESSFUL,
    payload,
  };
};

//SUMMARY ENTRIES
export const fetchLoyaltyTierSummary = (query) => {
  return {
    type: FETCH_LOYALTYTIER_SUMMARY,
    payload: query,
  };
};

export const fetchLoyaltyTierSummarySuccessfully = (payload) => {
  return {
    type: FETCH_LOYALTYTIER_SUMMARY_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchLoyaltyTierSummaryFailed = (error) => {
  return {
    type: FETCH_LOYALTYTIER_SUMMARY_FAILED,
    payload: error,
  };
};
