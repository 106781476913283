import React, { useEffect } from 'react'
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Form from './form'

import { fetchEachReward } from '../../../../store/actions'

const RewardCodeForm = ({
  fetchEachReward,
  rewardPreview,
  loading,
  message,
}) => {
  const search = useLocation().search
  const id = new URLSearchParams(search).get('id')

  useEffect(() => {
    if (id) {
      fetchEachReward(id)
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        {rewardPreview ? (
          <Form
            loading={loading}
            defaultValue={rewardPreview}
            message={message}
          />
        ) : (
          <Form loading={loading} message={message} defaultValue={null} />
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { rewardPreview, loading, rewardError, message } = state.Reward
  return { rewardPreview, loading, rewardError, message }
}

export default connect(mapStateToProps, { fetchEachReward })(RewardCodeForm)
