import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Alert,
    Modal,
    ModalBody,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';

import imgCar from '../../../../assets/images/sample_car.png';
import { fetchVehicle, fetchPackageVehicles, removeVehicle } from '../../../../store/actions';

const App = ({
    vehicle,
    fetchVehicle,
    vehicleError,
    packageError,
    packageVehicle,
    fetchPackageVehicles,
    removeVehicle,
    message,
    history
}) => {
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [take, setTake] = useState(20)
    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');

    const handleSave = (e) => {
        removeVehicle(id, { vehicles: selectedVehicles })
        return
    }
    const handleSelectAll = () => {
        const allVehicleIds = packageVehicle?.result?.map((vehicleItem) => vehicleItem.id);
        setSelectedVehicles(allVehicleIds);
    };
    useEffect(() => {
        if (id) {
            fetchPackageVehicles(id, { take, skip: 0 });
        }
        // fetchVehicle({ take, skip: 0 });
    }, [fetchPackageVehicles, id, take]);

    const handleCheckboxChange = (vehicleId) => {
        if (selectedVehicles.includes(vehicleId)) {
            setSelectedVehicles(selectedVehicles.filter((id) => id !== vehicleId));
        } else {
            setSelectedVehicles([vehicleId, ...selectedVehicles]);
        }
    };


    useEffect(() => {
        if (message) {
            setTimeout(() => {
                history.goBack();
            }, 2000);
            setShowModal(false)
        }
    }, [message])

    // useEffect(() => {
    //     if (packageVehicle && packageVehicle?.result?.length > 0) {
    //         const pkgVehicleArr = [];
    //         if (packageVehicle?.result) {
    //             packageVehicle.result.forEach((element) => {
    //                 pkgVehicleArr.push(element.id);
    //             });
    //         }
    //         setSelectedVehicles(pkgVehicleArr);
    //     }
    // }, [packageVehicle]);


    return (
        <div className="page-content">
            {message && <Alert color="success" className="text-center">{message}</Alert>}
            {vehicleError && <Alert color="danger" className="text-center">{vehicleError}</Alert>}
            {packageError && <Alert color="danger" className="text-center">{packageError}</Alert>}


            <div className="pkgVehicles container mb-5 pb-5">
                <div className='mb-5 d-flex align-items-center justify-content-between'>
                    <h4 className="">All Vehicles</h4>
                    <div className='d-flex align-items-center justify-content-between'>
                        <Button color="success" size='sm' className="mr-2" onClick={() => setShowModal(true)}>
                            Save Changes
                        </Button>
                        <Button color="info" size='sm' className="mr-2" onClick={() => handleSelectAll()}>
                            Select All
                        </Button>
                        <Button color="primary" size='sm' className="mr-3" onClick={() => history.goBack()}>
                            Back
                        </Button>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mb-4">
                    {packageVehicle?.result?.map((vehicleItem) => (
                        <div className={`col-lg-3 col-md-6 mb-4 mt-4 
                            }`}
                            key={vehicleItem.id}
                            onClick={() =>
                                handleCheckboxChange(vehicleItem.id)
                            }>
                            <div
                                className={`card h-auto shadow mb-0 ${selectedVehicles.includes(vehicleItem.id) ? 'selected' : ''
                                    }`}
                                onClick={() => handleCheckboxChange(vehicleItem.id)}
                            >
                                <div className="form-check form-check-inline">
                                    <input
                                        className="check-input"
                                        type="checkbox"
                                        value={vehicleItem.id}
                                        checked={selectedVehicles.includes(vehicleItem.id)}
                                        onChange={() => handleCheckboxChange(vehicleItem.id)}
                                    />
                                </div>
                                <div className="image-wrapper">
                                    <img
                                        src={vehicleItem.imageFront ? vehicleItem.imageFront : imgCar}
                                        className="card-img-top vehicle-image"
                                        alt={vehicleItem.make}
                                    />
                                </div>

                                <div className="card-body py-1 pr-1">
                                    <p className="card-text text-truncate">
                                        <strong>Brand:{` `}</strong><span className='ml-2'> {` ${vehicleItem.make} - ${vehicleItem.model}`}</span>
                                    </p>
                                    <p className="card-text">
                                        <strong>Color:</strong>
                                        <strong
                                            className={`ml-2 font-size-10 badge badge-${vehicleItem.color.toLowerCase() === 'red'
                                                ? 'danger'
                                                : vehicleItem.color.toLowerCase() === 'blue'
                                                    ? 'primary'
                                                    : vehicleItem.color.toLowerCase() === 'white'
                                                        ? 'white text-dark'
                                                        : vehicleItem.color.toLowerCase() === 'yellow'
                                                            ? 'yellow text-dark'
                                                            : vehicleItem.color.toLowerCase() === 'black'
                                                                ? 'dark text-white'
                                                                : vehicleItem.color.toLowerCase() === 'grey'
                                                                    ? 'secondary text-white'
                                                                    : 'soft-secondary'
                                                } text-white rounded text-capitalize`}
                                            style={{ background: `${vehicleItem.color}`, color: 'darkgrey' }}
                                        >
                                            {' '}
                                            {vehicleItem?.color}{' '}
                                        </strong>
                                    </p>
                                    <p className="card-text d-flex">
                                        <strong>Driver:</strong>
                                        <span className="card-title driver-name ml-2 text-truncate text-capitalize">{`${vehicleItem.user?.firstName} ${vehicleItem.user?.lastName}`}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {packageVehicle?.result?.length !== packageVehicle?.total &&
                    <div className='text-center mb-5'>
                        <Button color='primary' size='sm' onClick={() => setTake(take + 10)}>
                            Fetch More Vehicles
                        </Button>
                    </div>
                }
                {/* <div className="mt-4">
                    <strong>Selected Vehicles:</strong> {selectedVehicles.join(', ')}
                </div> */}
            </div>
            <Modal
                isOpen={showModal}
                toggle={() => setShowModal(!showModal)}
            >
                <ModalBody>
                    <h4 className='card-title mb-4 text-center d-flex align-items-center'>
                        {' '}
                        You are about to remove <h6 className='badge badge-secondary mx-1 my-0'>{selectedVehicles.length}</h6> vehicles from this package
                    </h4>
                    <button
                        onClick={() => handleSave()}
                        className='btn btn-outline-danger btn-sm w-100'
                    >
                        Remove vehicles
                    </button>
                </ModalBody>
            </Modal>

        </div>
    );
};

const mapStateToProps = (state) => {
    const { vehicle, vehicleError } = state.Vehicle;
    const { packageError, message, packageVehicle, loading } = state.Packages;
    return {
        vehicle,
        vehicleError,
        packageVehicle,
        message,
        packageError
    };
};

export default connect(mapStateToProps, { removeVehicle, fetchVehicle, fetchPackageVehicles })(App);
