import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';
import Moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';

import ReactPaginate from 'react-paginate';

import Loader from '../../../../components/Common/Loading/index.js';

import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../../utils/general.js';
import queryString from 'query-string';
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

let skip = 0;
let dataLimit = 10;
const NotificationTable = ({
  notificationLogs,
  previewAction,
  fetchNotificationLog,
  message,
  history,
  CheckAction,
  ReadCheck,
  accessControl
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [skipPage, setSkipPage] = useState(0);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(notificationLogs?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
    };
    fetchNotificationLog(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchNotificationLog(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      skip,
    };
    fetchNotificationLog(queryParams);
  };
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
        };
        fetchNotificationLog(queryParams);
      }, 2000);
    }
  }, [fetchNotificationLog, message]);
  useEffect(() => {
    if (notificationLogs) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(notificationLogs?.total / take));
    }
  }, [notificationLogs]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchNotificationLog(parsedUrlParams);
  }, [fetchNotificationLog]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      take,
      skip: pageNumber,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        take,
        skip,
      };
      return params;
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };
  let accessChecking = ReadCheck(accessControl, `Custom Notification`, 'Notifications Log');
  const tableDataHandler = () => {
    const rowData = notificationLogs?.result?.map((data) => {
      return {
        id: data.id,
        title: (

          <>
            {accessChecking ?

              <Link
                to='#'
                className='mr-3 text-dark'
                // onClick={() => previewAction(data.id)}
                id='preview'
              >
                <div className='text-capitalize'> {data?.title} </div>

                <div className='badge badge-soft-success mt-2 font-size-10'>
                  {' '}
                  {data?.occurrence}
                </div>
              </Link>

              :
              <div className='mr-3 text-dark font-weight-bold'>
                <div className='text-capitalize'> {data?.title} </div>

                <div className='badge badge-soft-success mt-2 font-size-10'>
                  {' '}
                  {data?.occurrence}
                </div>
              </div>
            }
          </>
        ),
        message: data?.text ? (
          <div
            className='text-truncate'
            style={{ maxWidth: '600px', whiteSpace: 'pre-wrap' }}
          >
            {data?.text}
          </div>
        ) : (
          '----'
        ),
        topic: (
          <div className='badge badge-soft-secondary text-upper'>
            {data?.receiverType === 'UAT-DRIVER'
              ? 'DRIVERS'
              : data?.receiverType === 'UAT-RIDER'
                ? 'RIDERS'
                : data?.receiverType === 'UAT-ADMIN'
                  ? 'ADMINS'
                  : data?.receiverType}
          </div>
        ),
        type: data?.type,

        lastSent: data?.lastSent ? (
          <div>
            <div>{Moment(data?.stopAt).format('l')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data?.stopAt).format('hh:mm A')}
            </div>
          </div>
        ) : (
          <em>--Not Sent Yet--</em>
        ),
        date: (
          <div>
            <div>{Moment(data?.createdAt).format('l')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data?.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
        startDate: (
          <div>
            <div>{Moment(data?.sendAt).format('l')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data?.sendAt).format('hh:mm A')}
            </div>
          </div>
        ),
        endDate: data?.stopAt ? (
          <div>
            <div>{Moment(data?.stopAt).format('l')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data?.stopAt).format('hh:mm A')}
            </div>
          </div>
        ) : (
          '---'
        ),
        action: (
          <CheckAction
            field='Custom Notification'
            subModule='Notification Logs'
            iconType={'Edit'}
          >
            {new Date(data?.stopAt) >= new Date() ? (
              <div>
                <Button size='sm' className='' color='danger'>
                  Cancel
                </Button>
              </div>
            ) : (
              <em>--Executed--</em>
            )}
          </CheckAction>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'Title',
          dataField: 'title',
          sort: true,
        },
        {
          text: 'Message',
          dataField: 'message',
        },

        {
          text: 'Last Sent',
          dataField: 'lastSent',
        },
        {
          text: 'Sent To',
          dataField: 'topic',
        },
        {
          text: 'Start Date',
          dataField: 'startDate',
        },
        {
          text: 'End Date',
          dataField: 'endDate',
        },
        {
          text: 'Created Date',
          dataField: 'date',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {!isLoading && data !== null ? (
                <>
                  <div className='d-flex justify-content-between mb-2'>
                    <div>
                      <Input
                        bsSize='sm'
                        id='take'
                        name='take'
                        type='select'
                        onChange={handleLimitChange}
                        value={take}
                      >
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>
                    <div>
                      <Input
                        bsSize='sm'
                        id='search'
                        value={search}
                        name='search'
                        onChange={SearchHandler}
                        placeholder='Search'
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField='id'
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses='table-responsive'
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={skipPage}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default NotificationTable;
