export const FETCH_PROMOCODE = 'FETCH_PROMOCODE'
export const FETCH_PROMOCODE_SUCCESSFUL = 'FETCH_PROMOCODE_SUCCESSFUL'
export const FETCH_PROMOCODE_API_FAILED = 'FETCH_PROMOCODE_API_FAILED'

export const FETCH_PROMOHISTORY = 'FETCH_PROMOHISTORY'
export const FETCH_PROMOHISTORY_SUCCESSFUL = 'FETCH_PROMOHISTORY_SUCCESSFUL'
export const FETCH_PROMOHISTORY_API_FAILED = 'FETCH_PROMOHISTORY_API_FAILED'

export const FETCH_PROMOREDEEMHISTORY = 'FETCH_PROMOREDEEMHISTORY'
export const FETCH_PROMOREDEEMHISTORY_SUCCESSFUL =
  'FETCH_PROMOREDEEMHISTORY_SUCCESSFUL'
export const FETCH_PROMOREDEEMHISTORY_API_FAILED =
  'FETCH_PROMOREDEEMHISTORY_API_FAILED'

export const FETCH_EACH_PROMOCODE = 'FETCH_EACH_PROMOCODE'
export const FETCH_EACH_PROMOCODE_SUCCESSFUL = 'FETCH_EACH_PROMOCODE_SUCCESSFUL'
export const FETCH_EACH_PROMOCODE_API_FAILED = 'FETCH_EACH_PROMOCODE_API_FAILED'

export const DELETE_PROMOCODE = 'DELETE_PROMOCODE'
export const DELETE_PROMOCODE_SUCCESSFUL = 'DELETE_PROMOCODE_SUCCESSFULLY'
export const DELETE_PROMOCODE_FAILED = 'DELETE_PROMOCODE_FAILED'

export const CREATE_PROMOCODE = 'CREATE_PROMOCODE'
export const CREATE_PROMOCODE_SUCCESSFUL = 'CREATE_PROMOCODE_SUCCESSFULLY'
export const CREATE_PROMOCODE_FAILED = 'CREATE_PROMOCODE_FAILED'

export const UPDATE_PROMOCODE = 'UPDATE_PROMOCODE'
export const UPDATE_PROMOCODE_SUCCESSFUL = 'UPDATE_PROMOCODE_SUCCESSFULLY'
export const UPDATE_PROMOCODE_FAILED = 'FAILED'

// PROMOCODE Summary
export const FETCH_PROMOCODE_SUMMARY = 'FETCH_PROMOCODE_SUMMARY'
export const FETCH_PROMOCODE_SUMMARY_FAILED = 'FETCH_PROMOCODE_SUMMARY_FAILED'
export const FETCH_PROMOCODE_SUMMARY_SUCCESSFULLY =
  'FETCH_PROMOCODE_SUMMARY_SUCCESSFULLY'
