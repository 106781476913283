import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  NavLink,
  NavItem,
  Nav,
  Alert,
  TabContent,
  TabPane,
  Row,
  Col,
} from 'reactstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Loader from '../../../../components/Common/Loading/index.js';

import {
  deleteAppConfigs,
  deleteAppConfigItem,
  fetchEachAppConfigs,
  updateAppConfigs,
  updateAppConfigItem,
  updateAppConfigsError,
} from '../../../../store/actions';

import FormData from './FormData/index';
import ConfigurationForm from './FormData/configureForm';
import CardFooter from 'reactstrap/lib/CardFooter';

const PreviewAppSetting = ({
  history,
  fetchEachAppConfigs,
  updateAppConfigsError,
  appConfig,
  updateAppConfigs,
  deleteAppConfigs,
  deleteAppConfigItem,
  updateAppConfigItem,
  loading,
  match,
  message,
  deleteMessage,
}) => {
  const [activeTab, setActiveTab] = useState('');
  const [editItem, SetEditItem] = useState(false);
  const [appLoading, setAppLoading] = useState(true);
  const [isConfigUpdate, SetIsConfigUpdate] = useState(false);

  const updateHandler = (updateValue) => {
    const cloneConfigData = { ...appConfig };
    cloneConfigData.appConfigItems.push(updateValue.appConfigItems);
    SetEditItem(false);
    updateAppConfigs(cloneConfigData, cloneConfigData.id);
  };

  const updateItemHandler = (updateData, item) => {
    updateAppConfigItem(updateData, item.id);
  };

  const deleteHandlerItem = (id) => {
    deleteAppConfigItem(id);
  };

  const deleteAppHandler = () => {
    deleteAppConfigs(appConfig.id);
  };

  useEffect(() => {
    if (match.params.id) {
      fetchEachAppConfigs(match.params.id);
    }
  }, []);

  useEffect(() => {
    if (deleteMessage) {
      setTimeout(() => {
        history.push('/app_settings');
      }, [2000]);
    }
    if (message) {
      setTimeout(() => {
        SetEditItem(false)
        updateAppConfigsError('');
        fetchEachAppConfigs(match.params.id);
        SetIsConfigUpdate(false);
      }, [2000]);
    }
  }, [message, deleteMessage]);

  useEffect(() => {
    if (appConfig && appConfig.appConfigItems.length !== 0) {
      setActiveTab(appConfig.appConfigItems[0]?.id);
      setAppLoading(false);
    } else {
      setActiveTab('addField');
    }
  }, [appConfig]);
  return (
    <div className="page-content">
      <div className="d-flex justify-content-between">
        <Link to="/app_settings">
          <button className="btn btn-light btn-sm">
            <i className="fas fa-arrow-left mr-2" /> Back
          </button>
        </Link>
        {message && <Alert className="danger px-4">{message}</Alert>}
        {deleteMessage && (
          <Alert className="danger px-4">{deleteMessage}</Alert>
        )}
      </div>
      <Card className="mt-3">
        {appConfig === null && loading ? (
          <Loader />
        ) : (
          <CardBody>
            <div className="mb-3">
              <div>
                <div className="float-right">
                  {!isConfigUpdate ? (
                    <>
                      <span
                        className={`badge badge-soft-${
                          appConfig?.status === 'INACTIVE'
                            ? 'danger'
                            : 'success'
                        } font-size-11 mr-1 text-lowercase`}
                      >
                        {appConfig?.status}
                      </span>
                      <Link to="#" onClick={() => SetIsConfigUpdate(true)}>
                        <span>
                          <i className="fas fa-pencil-alt p-1 mx-2 border rounded" />
                        </span>
                      </Link>
                      <Link to="#" onClick={deleteAppHandler}>
                        <span>
                          <i className="far fa-trash-alt p-1 border rounded" />
                        </span>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link to="/app_settings">
                        <span>
                          <i className=" fas fa-home p-1 py-2 border rounded" />
                        </span>
                      </Link>
                      <Link
                        to="#"
                        onClick={() => SetIsConfigUpdate(false)}
                        className="ml-1"
                      >
                        <button className="btn btn-light btn-sm mr-2">
                          <i className="fas fa-chevron-left p-1" />
                          Back
                        </button>
                      </Link>
                    </>
                  )}
                </div>

                <h5>{appConfig?.module}</h5>
              </div>
              <p className="text-muted mb-0">{appConfig?.description}</p>
            </div>
            {isConfigUpdate ? (
              <ConfigurationForm
                defaultData={appConfig}
                updateHandler={updateAppConfigs}
              />
            ) : (
              <div>
                <Nav tabs>
                  {appConfig?.appConfigItems.map((item) => (
                    <NavItem key={item.id}>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab === item.id,
                        })}
                        onClick={() => {
                          setActiveTab(item.id);
                        }}
                      >
                        {item.name}
                      </NavLink>
                    </NavItem>
                  ))}
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === 'addField',
                      })}
                      onClick={() => {
                        setActiveTab('addField');
                      }}
                    >
                      <i className=" fas fa-plus mr-2 text-primary" />
                      Add Item
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                  {appConfig?.appConfigItems.map((item) => (
                    <TabPane tabId={item.id} className="p-3" key={item.id}>
                      {!editItem ? (
                        <Row>
                          <Col md={6}>
                            <Card>
                              <CardBody>
                                <div className="mb-2">
                                  <div className="d-flex justify-content-between">
                                    <span>
                                      Order :{' '}
                                      <span className="badge badge-warning">
                                        {item?.order}
                                      </span>
                                    </span>
                                    <span
                                      className={`badge badge-soft-${
                                        item?.status === 'INACTIVE'
                                          ? 'danger'
                                          : 'success'
                                      } font-size-11 mr-1 mb-2 text-lowercase`}
                                    >
                                      {item?.status}
                                    </span>
                                  </div>
                                  <div className="p-4 d-flex bg-light mr-2 align-items-center">
                                    {item?.picture && (
                                      <img
                                        src={item?.picture}
                                        alt="app_setting"
                                        className="avatar-md mr-2"
                                      />
                                    )}
                                    <h4 className="card-title">{item?.type}</h4>
                                  </div>
                                </div>
                              </CardBody>
                              <CardFooter className="d-flex justify-content-between">
                                <Link
                                  to="#"
                                  onClick={() => SetEditItem(!editItem)}
                                >
                                  Edit Item
                                </Link>
                                <Link
                                  to="#"
                                  onClick={() => deleteHandlerItem(item.id)}
                                >
                                  <span className="text-danger"> Delete </span>
                                </Link>
                              </CardFooter>
                            </Card>
                          </Col>
                        </Row>
                      ) : (
                        <FormData
                          closeEdit={() => SetEditItem(!editItem)}
                          loading={loading}
                          defaultValue={item}
                          updateHandler={updateItemHandler}
                        />
                      )}
                    </TabPane>
                  ))}
                  <TabPane tabId={'addField'} className="p-3">
                    <Row>
                      <Col sm="12">
                        <FormData updateHandler={updateHandler} />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            )}
          </CardBody>
        )}
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { appConfig, loading, message, deleteMessage } = state.AppConfigs;
  return { appConfig, loading, message, deleteMessage };
};

export default connect(mapStateToProps, {
  fetchEachAppConfigs,
  updateAppConfigs,
  updateAppConfigsError,
  deleteAppConfigs,
  deleteAppConfigItem,
  updateAppConfigItem,
})(withRouter(PreviewAppSetting));
