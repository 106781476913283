import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import ReactPaginate from 'react-paginate';
import Loader from '../../../components/Common/Loading/index.js';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../utils/general';
import queryString from 'query-string';
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';
const ReviewTable = ({
  loading,
  fetchReviews,
  fetchDriverReviews,
  reviewsData,
  reviewer,
  message,
  skip,
  dataLimit,
  history,
  userType,
  reviewsError,
  CheckAction,
  ReadCheck,
  accessControl,
  subModule,
}) => {
  const [data, setData] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [rating, setRate] = useState('');
  const [search, setSearch] = useState('');

  const handleLimitChange = async (e) => {
    let pageInfo;
    setPageCount(Math.ceil(reviewsData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    pageInfo = { skip: pageNumber, take: dataLimit, rating, userType };
    if (reviewer) {
      fetchDriverReviews(pageInfo);
      return;
    }
    fetchReviews(pageInfo);
  };
  const handlePageClick = async (data) => {
    skip = data.selected;
    let pageInfo;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    pageInfo = { skip, take, search, rating, userType };
    if (reviewer) {
      fetchDriverReviews(pageInfo);
    } else {
      fetchReviews(pageInfo);
    }
    ScrollToTop();
  };

  // const SearchHandler = (event) => {
  //   const searchValue = event.target.value;
  //   if (searchValue !== '') {
  //     let searchInfo = { searchValue };
  //     if (reviewer) {
  //       searchInfo = { reviewer, searchValue };
  //     }
  //     fetchReviews(searchInfo);
  //   } else {
  //     if (reviewer) {
  //       fetchReviews({ reviewer });
  //     } else {
  //       fetchReviews();
  //     }
  //   }
  // };

  const GetRatingByStar = (rate) => {
    setRate(rate);
    let queryParams = {
      search,
      take,
      skip,
      rating: rate,
      userType,
    };
    if (reviewer) {
      fetchDriverReviews(queryParams);
      return;
    }
    fetchReviews(queryParams);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          rating,
          userType,
        };
        if (reviewer) {
          fetchDriverReviews(queryParams);
        } else {
          fetchReviews(queryParams);
        }
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    let queryParams = {
      search,
      take,
      skip,
      rating,
      userType,
    };
    if (reviewer) {
      fetchDriverReviews(queryParams);
    } else {
      fetchReviews(queryParams);
    }
  }, [fetchDriverReviews, fetchReviews, rating, reviewer, search, skip, take]);

  // useEffect(() => {
  //   let parsedUrlParams = updateStateWithUrlHandler();
  //   fetchReviews(parsedUrlParams);
  // }, [fetchReviews]);

  // const SetUrlWithParams = () => {
  //   let queryParams = {
  //     search,
  //     rating,
  //   };
  //   let result = QueryReallignment(queryParams);
  //   history.push({
  //     pathname: history.location.pathname,
  //     search: result,
  //   });
  // };

  // const updateStateWithUrlHandler = () => {
  //   // Parse the url
  //   const parsedUrlParams = queryString.parse(history.location.search);
  //   if (parsedUrlParams?.search) {
  //     setSearch(parsedUrlParams?.search);
  //   }
  //   if (parsedUrlParams?.rating) {
  //     setRate(parsedUrlParams?.rating);
  //   }
  //   return parsedUrlParams;
  // };

  useEffect(() => {
    if (reviewsData) {
      // SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setPageCount(Math.ceil(reviewsData?.total / take));
      // SET CSV EXPORT DATA
      const csvData = reviewsData.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Title: data?.title,
          Rating: data?.rating ? data.rating : '----',
          Tip: data?.tip ? data.tip : '----',
          Reviewer: `${data?.reviewer?.firstName} - ${data?.reviewer?.lastName} - (${data?.reviewer?.userType})`,
          Reviewee: `${data?.reviewee?.firstName} - ${data?.reviewee?.lastName} - (${data?.reviewee?.userType})`,
          Created_Date: data?.createdAt?.substring(0, 10),
        };

        return formData;
      });
      setCsvData(csvData);
    }
  }, [reviewsData]);

  const tableDataHandler = () => {
    const rowData = reviewsData?.result?.map((data) => {
      return {
        id: data?.id,
        titleKey: data?.titleKey ? data?.titleKey : '---',
        title: data?.title ? data?.title : '---',
        tip: data?.tip,
        reviewee: (
          <div>
            <div className='text-capitalize'>
              {data?.reviewee?.firstName} {data?.reviewee?.lastName}
            </div>
            <div className='d-flex align-items-center'>
              <div className='mr-2'>
                <img
                  src={
                    data?.reviewee?.picture
                      ? data?.reviewee?.picture
                      : avatarImg
                  }
                  alt=''
                  height='20'
                  className='rounded-circle avatar-xs'
                />
              </div>
              <div>
                <span className='text-muted ml-2 badge badge-soft-secondary'>
                  <i className=' fas fa-star text-warning mr-1' />
                  {data?.reviewee?.rating.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        ),
        reviewer: (
          <div>
            <div className='text-capitalize'>
              {data?.reviewer?.firstName} {data?.reviewer?.lastName}
            </div>
            <div className='d-flex align-items-center'>
              <div className='mr-2'>
                <img
                  src={
                    data?.reviewer?.picture
                      ? data?.reviewer?.picture
                      : avatarImg
                  }
                  alt=''
                  height='20'
                  className='rounded-circle avatar-xs'
                />
              </div>
              <div>
                <span className='text-muted ml-2 badge badge-soft-secondary'>
                  <i className=' fas fa-star text-warning mr-1' />
                  {data?.reviewer?.rating.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        ),
        rating: data.rating ? (
          <span>
            {[
              ...Array(Math.ceil(data?.rating))
                .fill(0)
                .map((star, key) => (
                  <i key={key} className='mr-1  fas fa-star text-warning' />
                )),
            ]}
          </span>
        ) : (
          '---'
        ),
        role: data?.reviewer?.userType,
        action: (
          <CheckAction field='Rating & Review' subModule={subModule} iconType='Read'>
            <Link
              to={`/review_details?${reviewer ? 'reviewId' : 'id'}=${data.id}`}
              className='mr-3 text-secondary'
              id='edit1'
            >
              <i className='mdi mdi-eye font-size-18'></i>
            </Link>
            <UncontrolledTooltip placement='top' target='edit1'>
              Preview
            </UncontrolledTooltip>
          </CheckAction>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'Title',
          dataField: 'title',
          headerAlign: 'center',
          headerStyle: { width: '220px' },
        },
        {
          text: 'Reviewer',
          dataField: 'reviewer',
        },
        {
          text: 'Reviewee',
          dataField: 'reviewee',
        },
        {
          text: 'Tip (₦)',
          dataField: 'tip',
          headerStyle: { width: '100px' },
        },
        {
          text: 'Rating',
          dataField: 'rating',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className='pt-3'>
              {!isLoading && data !== null ? (
                <>
                  <div className='d-flex justify-content-end  align-items-center mb-2'>
                    <div className=''>
                      <CSVLink
                        data={csvData}
                        filename={
                          userType === 'RIDER'
                            ? 'rider_reviews.csv'
                            : 'driver_reviews.csv'
                        }
                        className='btn btn-primary btn-sm  mt-1'
                        target='_blank'
                      >
                        <i className=' fas fa-cloud-download-alt mr-1' />
                        CSV
                      </CSVLink>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between mb-2'>
                    <div>
                      <Input
                        bsSize='sm'
                        id='take'
                        name='take'
                        type='select'
                        onChange={handleLimitChange}
                      >
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>
                    <div>
                      <select
                        className='custom-select custom-select-sm'
                        onChange={(e) => {
                          GetRatingByStar(e.target.value);
                        }}
                        value={rating}
                      >
                        <option value=''>All Rating</option>
                        <option value='1'>1 star</option>
                        <option value='2'>2 stars</option>
                        <option value='3'>3 stars</option>
                        <option value='4'>4 stars</option>
                        <option value='5'>5 stars</option>
                      </select>
                    </div>
                  </div>
                  <BootstrapTable
                    keyField='id'
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses='table-responsive'
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    // breakLabel={'...'}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={3}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    //Bootstrap styling
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ReviewTable;
