import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import GreySummaryCard from '../../../../components/Common/Summary/index';
import HistoryTable from './table';

const PromoCode = ({ history }) => {
  const breadcrumbItems = [
    { title: 'Reward', link: '#' },
    { title: 'History', link: '/reward' },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Reward History"
            breadcrumbItems={breadcrumbItems}
          />
          <GreySummaryCard field="Rewards" subModule="Rewards Logs">
            <>
              <Row>
                <Col>
                  <HistoryTable history={history} />
                </Col>
              </Row>
            </>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PromoCode;
