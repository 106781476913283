import {
  FETCH_APP_VERSIONS,
  FETCH_APP_VERSIONS_SUCCESSFUL,
  FETCH_APP_VERSIONS_API_FAILED,
  FETCH_EACH_APP_VERSION,
  FETCH_EACH_APP_VERSION_SUCCESSFUL,
  FETCH_EACH_APP_VERSION_API_FAILED,
  DELETE_APP_VERSION,
  DELETE_APP_VERSION_SUCCESSFUL,
  DELETE_APP_VERSION_FAILED,
  CREATE_APP_VERSION,
  CREATE_APP_VERSION_SUCCESSFUL,
  CREATE_APP_VERSION_FAILED,
  UPDATE_APP_VERSION,
  UPDATE_APP_VERSION_SUCCESSFUL,
  UPDATE_APP_VERSION_FAILED,
} from './actionTypes';

export const fetchApp_versions = (payload) => {
  return {
    type: FETCH_APP_VERSIONS,
    payload,
  };
};

export const fetchApp_versionsSuccessful = (payload) => {
  return {
    type: FETCH_APP_VERSIONS_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchApp_versionsError = (error) => {
  return {
    type: FETCH_APP_VERSIONS_API_FAILED,
    payload: error,
  };
};

export const fetchEachApp_version = (id) => {
  return {
    type: FETCH_EACH_APP_VERSION,
    payload: id,
  };
};

export const fetchEachApp_versionSuccessful = (payload) => {
  return {
    type: FETCH_EACH_APP_VERSION_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachApp_versionError = (error) => {
  return {
    type: FETCH_EACH_APP_VERSION_API_FAILED,
    payload: error,
  };
};

export const deleteApp_version = (id) => {
  return {
    type: DELETE_APP_VERSION,
    payload: id,
  };
};

export const deleteApp_versionSuccessful = (payload) => {
  return {
    type: DELETE_APP_VERSION_SUCCESSFUL,
    payload,
  };
};

export const deleteApp_versionFailed = (error) => {
  return {
    type: DELETE_APP_VERSION_FAILED,
    payload: error,
  };
};

export const createApp_version = (payload) => {
  return {
    type: CREATE_APP_VERSION,
    payload,
  };
};

export const createApp_versionSuccessful = (payload) => {
  return {
    type: CREATE_APP_VERSION_SUCCESSFUL,
    payload,
  };
};

export const createApp_versionFailed = (error) => {
  return {
    type: CREATE_APP_VERSION_FAILED,
    payload: error,
  };
};

export const updateApp_version = (payload) => {
  return {
    type: UPDATE_APP_VERSION,
    payload,
  };
};

export const updateApp_versionFailed = (error) => {
  return {
    type: UPDATE_APP_VERSION_FAILED,
    payload: error,
  };
};

export const updateApp_versionSuccessful = (payload) => {
  return {
    type: UPDATE_APP_VERSION_SUCCESSFUL,
    payload,
  };
};
