import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  Input,
  NavLink,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { CSVLink } from 'react-csv';
import Moment from 'moment';
import moment from 'moment-timezone';
import TimerCountDown from './Components/TimerCountDown';
import Loader from '../../../../../components/Common/Loading/index.js';
import { QueryReallignment, ScrollToTop } from '../../../../../utils/general';
import FilterDropDown from '../../../../../components/Common/FilterDropDown';
import queryString from 'query-string';
import classnames from 'classnames';
import SimpleBar from 'simplebar-react';
import { fetchAllDriverLogs } from '../../../../../store/actions';
let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const DriverTable = ({
  driverLogs,
  usersData,
  fetchAllDriverLogs,
  updateUserDocInfo,
  message,
  history,
  dateFilter,
  fetchDriverLogsCSV,
  fetchDriverLogsCSVError,
  logs_csv,
  csv_loading,
  accessControl,
  ReadCheck
}) => {
  const [data, setData] = useState(null);
  const [refreshTimer, setRefreshTimer] = useState(0);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  // const [startTimer, setStartTimer] = useState(0)
  const [activeTab, SetActiveTab] = useState('1');
  const [Status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState('');
  const [menu, setMenu] = useState(false);
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });
  const toggleTab = (tab) => {
    let pageInfo = {
      start: date.start,
      end: date.end,
      skip: pageNumber,
      take: dataLimit,
      status:
        tab === '2'
          ? 'AVAILABLE'
          : tab === '3'
            ? 'BUSY'
            : tab === '4'
              ? 'OFFLINE'
              : tab === '5'
                ? 'NONE'
                : '',
      search,
    };
    setStatus(
      `${tab === '2'
        ? 'AVAILABLE'
        : tab === '3'
          ? 'BUSY'
          : tab === '4'
            ? 'OFFLINE'
            : tab === '5'
              ? 'NONE'
              : ''
      }`
    );

    if (activeTab !== tab) {
      SetActiveTab(tab);
    }
    fetchAllDriverLogs(pageInfo);
  };

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(usersData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = {
      skip: pageNumber,
      take: dataLimit,
      status: Status,
      search,
      start: date.start,
      end: date.end,
    };
    fetchAllDriverLogs(pageInfo);
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }

    let pageInfo = {
      skip,
      take,
      status: Status,
      search,
      start: date.start,
      end: date.end,
    };
    fetchAllDriverLogs(pageInfo);
    ScrollToTop();
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      status: Status,
    };
    fetchAllDriverLogs(queryParams);
  };
  const handleCSV = (e, values) => {
    fetchDriverLogsCSV({
      take: values.endRow
        ? values.endRow - values.startRow + 1
        : usersData?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
    });
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    dateFilter({ start: values.startDate, end: values.endDate });
    setMenu(false);
    setStatus(values.Status);
    let queryParams = {
      search,
      take,
      skip,
      status: values.Status,
      start: values.startDate,
      end: values.endDate,
    };
    fetchAllDriverLogs(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setSearch('');
    setDate({
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    });
    dateFilter({
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    });
    let queryParams = {
      start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
      end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
    };
    fetchAllDriverLogs(queryParams);
  };

  useEffect(() => {
    if (usersData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setPageCount(Math.ceil(usersData?.total / take));
    }
  }, [usersData]);
  useEffect(() => {
    if (logs_csv) {
      // SET CSV EXPORT DATA
      const csvData = logs_csv?.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Driver_Id: data?.driverId,
          Full_Name: `${data.firstName} - ${data.lastName}`,
          Display_Name: data.displayName ? data.displayName : '----',
          Source: data?.source,
          Email: data?.email ? data.email : '----',
          Address: data?.address ? data?.address : '----',
          Cordinates: data?.lat
            ? `lat: ${data.lat} - lng: ${data.lng}`
            : '----',
          Status: data?.status,
          Date: data?.createdAt?.substring(0, 10),
        };

        return formData;
      });

      setCsvData(csvData);
    }
  }, [logs_csv]);

  useEffect(() => {
    if (!showCSVModal) {
      fetchDriverLogsCSVError();
    }
  }, [showCSVModal]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          status: Status,
          start: date.start,
          end: date.end,
        };
        fetchAllDriverLogs(queryParams);
      }, 2000);
    }
  }, [message]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     let time = startTimer + 1
  //     setStartTimer((prevState) => ({
  //       ...prevState,
  //       startTimer: time,
  //     }))
  //     let queryParams = {
  //       search,
  //       take,
  //       skip,
  //       status: Status,
  //     }
  //     fetchAllDriverLogs(queryParams)
  //   }, 60000)
  //   return () => clearInterval(interval)
  // }, [refreshTimer])

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchAllDriverLogs(parsedUrlParams);
  }, [fetchAllDriverLogs]);
  // useEffect(() => {
  //   if (usersData) {
  //     fetchAllDriverLogs({ take: usersData.total });
  //   }
  // }, []);
  // console.log(driverLogs);
  // useEffect(() => {
  //   let queryParams = {
  //     take,
  //     start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
  //     end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  //   };
  //   fetchAllDriverLogs(queryParams);
  // }, []);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      status: Status,
      take,
      skip,
      start: date.start,
      end: date.end,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (Object.keys(parsedUrlParams).length === 0) {
      SetUrlWithParams();
      let params = {
        start: date.start,
        end: date.end,
        search,
        status: Status,
        take,
        skip,
      };
      return params;
    }
    // update State with url param if available
    if (parsedUrlParams?.Status === 'AVAILABLE') {
      SetActiveTab('2');
      setStatus(parsedUrlParams?.Status);
    } else if (parsedUrlParams?.Status === 'BUSY') {
      SetActiveTab('3');
      setStatus(parsedUrlParams?.Status);
    }
    if (parsedUrlParams?.Status === 'OFFLINE') {
      SetActiveTab('4');
      setStatus(parsedUrlParams?.Status);
    } else if (parsedUrlParams?.Status === 'NONE') {
      SetActiveTab('5');
      setStatus(parsedUrlParams?.Status);
    }
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };
  let accessChecking = ReadCheck(accessControl, `Users`, 'Driver Logs');
  const tableDataHandler = () => {
    const rowData = usersData?.result?.map((data) => {
      return {
        keyId: data.id,
        id: (
          <>
            {accessChecking ?

              <Link
                to={`/user/${data?.driverId}`}
                className='mr-3 text-dark font-weight-bold'
              >
                {data?.id?.substring(0, 7)}
              </Link >

              :
              <div className='mr-3 text-dark font-weight-bold'>
                {data?.id?.substring(0, 7)}
              </div>
            }
          </>
        ),
        location:
          data.country || data.state != null
            ? `${data?.country} , ${data?.state}`
            : '__',
        email: data.email ? data.email : '----',
        fullName: (
          <div className='text-capitalize'>
            {data.firstName} {data.lastName}
          </div>
        ),
        address: data.address && (
          <span>
            <span className=' d-block mb-1 text-wrap'>
              <span className='badge badge-soft-secondary text-wrap'>
                {data?.address.split(',')[0]},{data?.address.split(',')[1]}
              </span>
            </span>
          </span>
        ),
        driver: (
          <Link to={`/user/${data.id}`} className='text-dark font-weight-bold'>
            <div className='d-flex align-items-center'>
              <img
                src={data?.picture ? data?.picture : avatarImg}
                alt=''
                height='20'
                className='rounded-circle avatar-xs mr-2'
              />

              <div>
                <div className='text-capitalize'>
                  {data.firstName} {data.lastName}
                </div>

                <span className='text-muted ml-2 badge badge-soft-secondary'>
                  <i className=' fas fa-star text-warning mr-1' />
                  {data?.rating ? data?.rating?.toFixed(2) : 0}
                </span>
                {data.gender && (
                  <span
                    className={`text-white mr-2 badge badge-${data.gender === 'MALE' ? 'primary' : 'danger'
                      }`}
                  >
                    <i
                      className={`ri-${data.gender === 'MALE' ? 'men' : 'women'
                        }-line mr-1`}
                    />
                    {data.gender}
                  </span>
                )}
              </div>
            </div>
          </Link>
        ),
        date: (
          <div>
            {' '}
            <div>{Moment(data.createdAt).format('l')}</div>
            <div className='badge badge-soft-secondary font-size-10'>
              {' '}
              {Moment(data.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
        // phone_no: data.phone ? data.phone : '----',
        // rating: data?.rating.toFixed(1) ? data?.rating.toFixed(1) : '---',
        // trip: data.trips,
        status: (
          <div
            className={`badge badge-soft-${data?.status === 'NONE'
                ? 'danger'
                : data?.status === 'BUSY'
                  ? 'warning'
                  : data?.status === 'AVAILABLE'
                    ? 'success'
                    : 'secondary'
              } font-size-12`}
          >
            {data?.status === 'OFFLINE'
              ? 'NOT AVAILABLE'
              : data?.status === 'NONE'
                ? 'OFFLINE'
                : data?.status}
          </div>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'Log Id',
          dataField: 'id',
        },
        {
          text: 'Name',
          dataField: 'fullName',
        },

        {
          text: 'Email',
          dataField: 'email',
        },
        {
          text: 'Address',
          dataField: 'address',
        },
        {
          text: 'Registered Date',
          dataField: 'date',
        },
        {
          text: 'Status',
          dataField: 'status',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12} className='d-flex flex-column'>
          <div className='mb-3'>
            <div className='float-right'>
              <Link to={`/drivers-log`}>
                <button
                  size='sm'
                  className='btn btn-sm btn-primary'
                // onClick={() => history.goBack()}
                >
                  <i className='fas fa-arrow-left mr-3' />
                  Back
                </button>
              </Link>
            </div>
          </div>
          <Card>
            <CardBody>
              <div className='d-flex align-items-center justify-content-between mb-4'>
                <Nav tabs className='nav-tabs-custom'>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab('1');
                      }}
                      className={classnames(
                        { active: activeTab === '1' },
                        'font-weight-bold p-3'
                      )}
                    >
                      All Driver Logs
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab('2');
                      }}
                      className={classnames(
                        { active: activeTab === '2' },
                        'p-3 font-weight-bold'
                      )}
                    >
                      AVAILABLE
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab('3');
                      }}
                      className={classnames(
                        { active: activeTab === '3' },
                        ' p-3 font-weight-bold'
                      )}
                    >
                      ON TRIP
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab('4');
                      }}
                      className={classnames(
                        { active: activeTab === '4' },
                        ' p-3 font-weight-bold'
                      )}
                    >
                      NOT AVAILABLE
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        toggleTab('5');
                      }}
                      className={classnames(
                        { active: activeTab === '5' },
                        ' p-3 font-weight-bold'
                      )}
                    >
                      OFFLINE
                    </NavLink>
                  </NavItem>
                </Nav>
                <div className='d-flex align-items-center justify-content-end mb-2'>
                  {/* <TimerCountDown startTimer={startTimer} /> */}
                  <div className=''>
                    <Button
                      size='sm'
                      color='primary'
                      className='mt-1'
                      onClick={() => setShowCSVModal(true)}
                      disabled={usersData?.total > 0 ? false : true}
                    >
                      <i className=' fas fa-cloud-download-alt mr-1' />
                      CSV
                    </Button>
                  </div>
                  <FilterDropDown menu={menu} toggleMenu={() => setMenu(!menu)}>
                    <div>
                      <AvForm
                        className='form-horizontal'
                        onValidSubmit={handleDateFilter}
                      >
                        <SimpleBar
                          style={{
                            maxHeight: '250px',
                          }}
                        >
                          <Row className='px-3  pt-3 pb-0'>
                            <Col md='12'>
                              <FormGroup>
                                <AvField
                                  name='startDate'
                                  type='date'
                                  className='form-control bg-light'
                                  id='startDate'
                                  value={date.start}
                                  helpMessage='Start Date'
                                  placeholder='Please choose the start date...'
                                />
                              </FormGroup>
                            </Col>
                            <Col md='12'>
                              <FormGroup>
                                <AvField
                                  name='endDate'
                                  type='date'
                                  className='form-control bg-light'
                                  id='endDate'
                                  value={date.end}
                                  helpMessage='End Date'
                                  placeholder='Please choose the end date...'
                                />
                              </FormGroup>
                            </Col>
                            <Col md='12'>
                              <FormGroup>
                                <AvField
                                  type='select'
                                  value={Status}
                                  name='Status'
                                  helpMessage='Status'
                                >
                                  <option value=''>All</option>
                                  <option value='AVAILABLE'>AVAILABLE</option>
                                  <option value='BUSY'>BUSY</option>
                                  <option value='OFFLINE'>OFFLINE</option>
                                </AvField>
                              </FormGroup>
                            </Col>
                          </Row>

                          <div className='p-2 d-flex justify-content-between align-items-center bg-light'>
                            <Link
                              to='#'
                              onClick={resetHandler}
                              className='btn btn-sm  bg-light font-size-14'
                            >
                              <i className=' mdi mdi-refresh mr-1'>Reset</i>
                            </Link>

                            <button
                              className='btn btn-success btn-sm mr-2'
                              type='submit'
                            >
                              <i className='mdi mdi-arrow-right-circle mr-1'></i>
                              Send
                            </button>
                          </div>
                        </SimpleBar>
                      </AvForm>
                    </div>
                  </FilterDropDown>
                </div>
              </div>
              {!isLoading && data !== null ? (
                <>
                  <div className='d-flex justify-content-between mb-4'>
                    <div>
                      <Input
                        bsSize='sm'
                        id='take'
                        name='take'
                        type='select'
                        onChange={handleLimitChange}
                      >
                        <option>{take}</option>
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>

                    <div>
                      <Input
                        bsSize='sm'
                        id='search'
                        value={search}
                        name='search'
                        onChange={SearchHandler}
                        placeholder='Search'
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField='keyId'
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses='table-responsive'
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    // breakLabel={'...'}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={3}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    //Bootstrap styling
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* CSV DOWNLOAD MODAL */}
      <Modal
        isOpen={showCSVModal}
        toggle={() => setShowCSVModal(!showCSVModal)}
      >
        <ModalHeader toggle={() => setShowCSVModal(false)}>
          Generate CSV
        </ModalHeader>
        <ModalBody>
          <AvForm className='form-horizontal' onValidSubmit={handleCSV}>
            <Col>
              <FormGroup>
                <Label htmlFor='startRow'>Start (Row)</Label>
                <AvField
                  name='startRow'
                  type='number'
                  max={usersData?.total - 1}
                  className='form-control'
                  id='startRow'
                  placeholder='Enter the row to start from'
                  errorMessage='value must be greater than 0 and less than total data'
                  min='1'
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor='endRow'>End (Row)</Label>
                <AvField
                  name='endRow'
                  type='number'
                  className='form-control'
                  min='1'
                  max={usersData?.total}
                  id='endRow'
                  placeholder='Enter the row to end'
                  helpMessage={
                    usersData?.total
                      ? `Total data is ${usersData?.total}`
                      : null
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Button
              size='sm'
              color='primary'
              className='ml-3 waves-effect waves-light '
              type='submit'
            >
              {csv_loading ? 'Generating...' : 'Generate'}
            </Button>
            {logs_csv && (
              <CSVLink
                data={csvData}
                filename={'DriversLog_records_.csv'}
                className='btn-sm btn-success ml-2'
                target='_blank'
                onClick={() => setShowCSVModal(false)}
              >
                Download
              </CSVLink>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { driverLogs } = state.Users;
  return {
    driverLogs,
  };
};

export default connect(mapStatetoProps, {
  fetchAllDriverLogs,
})(DriverTable);
