import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Label,
  Alert,
} from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import CustomSelect from "../../../../components/Common/MoreDropdown/CustomSelect";
import CustomSelectNew from "../Components/CustomSelectNew";
import MultiSelect from "../Components/MultiSelect";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getS3ImageUrlHandler } from "../../../../utils/general";
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsRenderer,
  InfoWindow,
} from "@react-google-maps/api";
import busStopIcon from "../../../../assets/images/markers_icon/busStop.png";
import busStopPin from "../../../../assets/images/markers_icon/busStopPin.png";
import moment from "moment";

// actions
import {
  createRoute,
  createRouteFailed,
  updateRoute,
  updateRouteFailed,
} from "../../../../store/actions";

const Form = ({
  createRoute,
  createRouteFailed,
  updateRoute,
  updateRouteFailed,
  message,
  history,
  routeError,
  defaultValue,
  loading,
  busStops,
  fetchBusStops,
  busStopError,
}) => {
  const [errorMessage, setError] = useState("");
  const [description, setDescription] = useState(" ");
  const [selectedImage, setImageUpload] = useState([]);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [startBusStop, setStartBusStop] = useState([]);
  const [endBusStop, setEndBusStop] = useState([]);
  const [stops, setStops] = useState([]);
  const [center, setCenter] = useState({ lat: 9.072264, lng: 7.491302 });
  const [googleIsReady, setIsReady] = useState(false);
  const [selectedStop, setSelectedStop] = useState(null);
  const [busStopOption, SetBusStopOption] = useState([]);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const direction = async () => {
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: startBusStop[0].address,
      destination: endBusStop[0].address,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
  };

  useEffect(() => {
    if (window.google && window.google.maps) {
      setIsReady(true);
      if (startBusStop.length > 0 && endBusStop.length > 0) {
        direction();
      }
    } else {
      setIsReady(false);
    }
  }, [window.google, endBusStop.length, startBusStop.length]);

  const handleSubmit = async (event, values) => {
    let imageResult;
    let allStops = [];
    stops.forEach((data, i) => {
      // if (data.position) {
      //   allStops.push({ position: data.position, busStop: { id: data.value } });
      // } else {
      allStops.push({ position: i + 1, busStop: { id: data.value } });
      // }
    });
    const routeData = {
      ...values,
    };
    if (selectedImage.length !== 0) {
      imageResult = await getS3ImageUrlHandler(selectedImage[0], "route");
      routeData.image = imageResult?.data.result?.mediaUrl;
      routeData.imageThumb = imageResult?.data?.result.mediaThumbUrl;
    }

    routeData.start = { id: startBusStop[0].value };
    routeData.end = { id: endBusStop[0].value };
    routeData.stops = allStops;
    routeData.startTime = moment(values.startTime).format(
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    );
    routeData.endTime = moment(values.endTime).format(
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    );
    routeData.description = description;
    if (defaultValue) {
      routeData.id = defaultValue.id;
      updateRoute(routeData);
      return;
    }

    createRoute(routeData);
  };

  const imageHandler = (e) => {
    const files = e.target.files;
    if (files) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
      setImageUpload(files);
      setImageName(files[0].name);
    }
  };

  useEffect(() => {
    if (startBusStop?.cord?.lat) {
      setCenter({ lat: startBusStop?.cord?.lat, lng: startBusStop?.cord?.lng });
    }
  }, [startBusStop]);

  useEffect(() => {
    setDescription("");
    setImage("");
    if (defaultValue) {
      setDescription(defaultValue?.description);
      setImage(defaultValue?.image);
      if (defaultValue && defaultValue?.stops?.length > 0) {
        const stopArr = [];
        if (defaultValue.stops) {
          defaultValue.stops.sort((a, b) => a.position - b.position); // Sort by position
          defaultValue.stops.forEach((element) => {
            const stopObj = {};
            stopObj.value = element.busStop.id;
            stopObj.label = element.busStop.name;
            stopObj.address = element.busStop.address;
            stopObj.position = Number(element.position);
            stopObj.cord = {
              lat: element.busStop.lat,
              lng: element.busStop.lng,
            };
            stopArr.push(stopObj);
          });
        }
        setStops(stopArr);
      }
      setStartBusStop([
        {
          value: defaultValue?.start["id"],
          label: defaultValue?.start["name"],
          address: defaultValue?.start["address"],
          cord: {
            lat: defaultValue.start["lat"],
            lng: defaultValue.start["lng"],
          },
        },
      ]);
      setEndBusStop([
        {
          value: defaultValue?.end?.id,
          label: defaultValue?.end?.name,
          address: defaultValue?.end?.address,
          cord: {
            lat: defaultValue.end?.lat,
            lng: defaultValue.end?.lng,
          },
        },
      ]);
    }
    if (message) {
      setTimeout(() => {
        createRouteFailed("");
        createRouteFailed("");
        history.goBack();
      }, 2000);
    }
  }, [defaultValue, message]);

  useEffect(() => {
    createRouteFailed("");
    setStartBusStop([]);
    setEndBusStop([]);
    setStops([]);
  }, []);

  useEffect(() => {
    if (busStops) {
      const busStopArr = [];
      busStops.result.forEach((element) => {
        const busStopObj = {};
        busStopObj.value = element.id;
        busStopObj.label = element.name;
        busStopObj.address = element.address;
        busStopObj.cord = { lat: element.lat, lng: element.lng };
        busStopArr.push(busStopObj);
      });
      SetBusStopOption(busStopArr);
    }
  }, [busStops]);

  const handleFetchMore = () => {
    fetchBusStops({ take: busStopOption.length + 10, skip: 0 });
  };

  const handleDragStart = (e, index) => {
    setDraggedIndex(index); // Set the dragged index when drag starts
    e.dataTransfer.setData("text/plain", ""); // This is necessary for drag to work in some browsers
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    if (draggedIndex !== null && draggedIndex !== index) {
      // Reorder the stops while dragging
      const updatedStops = [...stops];
      const [draggedStop] = updatedStops.splice(draggedIndex, 1);
      updatedStops.splice(index, 0, draggedStop);
      setStops(updatedStops);
      setDraggedIndex(index); // Update the dragged index
    }
  };

  const handleDragEnd = () => {
    setDraggedIndex(null); // Reset the dragged index when drag ends
  };

  return (
    <Card className="col-md-12">
      <CardBody>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        {routeError && (
          <Alert color="danger" className="text-center">
            {routeError}
          </Alert>
        )}
        {busStopError && (
          <Alert color="danger" className="text-center">
            {busStopError}
          </Alert>
        )}
        {errorMessage && (
          <Alert color="danger" className="text-center">
            {errorMessage}
          </Alert>
        )}
        <div className="float-right">
          <Link to="#" onClick={() => history.goBack()}>
            <i className="fas fa-arrow-left mr-3" />
            Back
          </Link>
        </div>
        <div className="mb-3">
          <h6> {defaultValue ? "Update" : "Create"} Route </h6>
        </div>
        <div className="col-md-12 pl-0">
          <Row>
            <Col md={6}>
              <AvForm
                className="form-horizontal"
                onValidSubmit={handleSubmit}
                model={defaultValue ? defaultValue : {}}
              >
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="name">Name</Label>
                      <AvField
                        name="name"
                        type="text"
                        className="form-control bg-light"
                        id="name"
                        value={defaultValue ? defaultValue.name : ""}
                        required
                        placeholder="Enter Route Name ..."
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="tag">Tag</Label>
                      <AvField
                        name="tag"
                        type="text"
                        className="form-control bg-light"
                        id="tag"
                        value={defaultValue ? defaultValue.tag : ""}
                        required
                        placeholder="Enter Route Tag ..."
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="startBusStop">Start Bus Stop</Label>
                          <div className="CustomSelect">
                            <CustomSelect
                              options={busStopOption}
                              selected={startBusStop}
                              onChange={setStartBusStop}
                              fetchMore={
                                busStopOption.length !== busStops?.total
                                  ? handleFetchMore
                                  : null
                              }
                              isMulti={false}
                              labelledBy="Select start bus stop ..."
                            ></CustomSelect>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="endBusStop">Last Bus Stop</Label>
                          <div className="CustomSelect">
                            <CustomSelectNew
                              options={busStopOption}
                              selected={endBusStop}
                              onChange={setEndBusStop}
                              fetchMore={
                                busStopOption.length !== busStops?.total
                                  ? handleFetchMore
                                  : null
                              }
                              isMulti={false}
                              labelledBy="Select last bus stop ..."
                            ></CustomSelectNew>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="address">Stops</Label>
                      <div className="CustomSelect">
                        <MultiSelect
                          options={busStopOption}
                          selected={stops}
                          onChange={setStops}
                          fetchMore={
                            busStopOption.length !== busStops?.total
                              ? handleFetchMore
                              : null
                          }
                          labelledBy="Select all bus stops ..."
                        ></MultiSelect>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="startTime">Start Date</Label>
                          <AvField
                            className="form-control bg-light"
                            type="datetime-local"
                            name="startTime"
                            value={
                              defaultValue
                                ? `${defaultValue.startTime.split(":")[0]}:${
                                    defaultValue.startTime.split(":")[1]
                                  }`
                                : ""
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="endTime">End Date</Label>
                          <AvField
                            className="form-control bg-light"
                            type="datetime-local"
                            name="endTime"
                            value={
                              defaultValue
                                ? `${defaultValue.endTime.split(":")[0]}:${
                                    defaultValue.endTime.split(":")[1]
                                  }`
                                : ""
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup className=" mb-4">
                      <Label>Image</Label>
                      <div className={image ? "d-flex" : null}>
                        <div>
                          <div className="mb-3 input-group">
                            <div className="custom-file">
                              <input
                                type="file"
                                accept="image/*"
                                name="image-upload"
                                className="custom-file-input"
                                id="image"
                                aria-describedby="image"
                                onChange={(e) => imageHandler(e)}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="image"
                              >
                                Choose file ...
                              </label>
                            </div>
                          </div>
                          <span className="font-italic">{imageName}</span>
                        </div>
                        {image ? (
                          <img
                            data-dz-thumbnail=""
                            height="100"
                            className="rounded bg-light ml-3"
                            style={{ width: "40%" }}
                            alt={imageName}
                            src={image}
                          />
                        ) : null}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col md={12}>
                    <FormGroup>
                      <Label htmlFor="description">Description</Label>
                      <textarea
                        className="form-control"
                        onChange={(e) => setDescription(e.target.value)}
                        id="description"
                        value={description}
                        rows="5"
                        // ref={descriptionRef}
                      ></textarea>
                    </FormGroup>
                  </Col>
                </Row>

                <button className="btn btn-success mr-2" type="submit">
                  {loading
                    ? "Submitting ..."
                    : `${defaultValue ? "Update" : "Create"}`}
                </button>
                <Link to="#" onClick={() => history.goBack()}>
                  <button className="btn btn-secondary">Cancel</button>
                </Link>
              </AvForm>
            </Col>
            <Col md={6} className="mb-5 mt-2">
              {stops?.length > 0 && (
                <Row
                  className="ml-0 rounded my-3"
                  style={{
                    background: "#28a7452e",
                    maxHeight: "50%",
                    overflow: "hidden",
                  }}
                >
                  <div className="col p-0">
                    <div className="bus-stop-title">
                      <h6 className="text-white m-0"> Bus Stops </h6>
                      <em> --Drag to sort properly--</em>
                    </div>
                    {stops?.length > 0 ? (
                      <div className="bus-stop-list pt-3 pb-5 my-2">
                        {stops.map((stop, index) => (
                          <div
                            key={stop.value}
                            className="bus-stop-item"
                            draggable // Add draggable attribute
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={(e) => handleDragOver(e, index)}
                            onDragEnd={handleDragEnd}
                          >
                            <span className="bus-stop-number">{index + 1}</span>
                            <Card className="mb-2" style={{ width: "90%" }}>
                              <CardBody className="p-1">
                                <CardText>{stop.label}</CardText>
                              </CardBody>
                            </Card>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </Row>
              )}
              <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY}>
                <GoogleMap
                  mapContainerStyle={{
                    height: `${stops?.length > 0 ? "50%" : "100%"}`,
                  }}
                  center={center}
                  zoom={15}
                >
                  {stops?.length > 0 && googleIsReady
                    ? stops?.map((stop) => (
                        <Marker
                          key={stop.value}
                          position={{
                            lat: stop?.cord?.lat,
                            lng: stop?.cord?.lng,
                          }}
                          icon={{
                            url: busStopPin,
                            scaledSize: new window.google.maps.Size(25, 25),
                          }}
                          title={stop?.name}
                          onClick={() => setSelectedStop(stop)}
                        >
                          {selectedStop === stop && (
                            <InfoWindow
                              onCloseClick={() => setSelectedStop(null)}
                              position={{
                                lat: stop?.cord?.lat,
                                lng: stop?.cord?.lng,
                              }}
                            >
                              <div>
                                <h6>{stop?.label}</h6>
                              </div>
                            </InfoWindow>
                          )}
                        </Marker>
                      ))
                    : null}
                  {directionsResponse && (
                    <DirectionsRenderer
                      directions={directionsResponse}
                      options={{
                        suppressMarkers: true,
                      }}
                    />
                  )}
                  {directionsResponse && googleIsReady ? (
                    <>
                      <Marker
                        position={
                          directionsResponse?.routes[0]?.legs[0]?.start_location
                        }
                        icon={{
                          url: busStopIcon,
                          scaledSize: new window.google.maps.Size(35, 35),
                        }}
                        onClick={() => setSelectedStop(startBusStop)}
                      >
                        {selectedStop === startBusStop && (
                          <InfoWindow
                            onCloseClick={() => setSelectedStop(null)}
                            position={{
                              lat: startBusStop[0]?.cord?.lat,
                              lng: startBusStop[0]?.cord?.lng,
                            }}
                          >
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <h6>{startBusStop[0]?.label}</h6>
                              <h6 className="badge badge-pill badge-success">
                                START
                              </h6>
                            </div>
                          </InfoWindow>
                        )}
                      </Marker>
                      <Marker
                        position={
                          directionsResponse?.routes[0]?.legs[0]?.end_location
                        }
                        icon={{
                          url: busStopIcon,
                          scaledSize: new window.google.maps.Size(35, 35),
                        }}
                        onClick={() => setSelectedStop(endBusStop)}
                      >
                        {selectedStop === endBusStop && (
                          <InfoWindow
                            onCloseClick={() => setSelectedStop(null)}
                            position={{
                              lat: endBusStop[0]?.cord?.lat,
                              lng: endBusStop[0]?.cord?.lng,
                            }}
                          >
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <h6>{endBusStop[0]?.label}</h6>
                              <h6 className="badge badge-pill badge-danger">
                                END
                              </h6>
                            </div>
                          </InfoWindow>
                        )}
                      </Marker>
                    </>
                  ) : null}
                </GoogleMap>
              </LoadScript>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  const { message, loading, routeError } = state.Route;
  return { message, loading, routeError };
};

export default connect(mapStateToProps, {
  createRoute,
  createRouteFailed,
  updateRoute,
  updateRouteFailed,
})(withRouter(Form));
