import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Label,
  Row,
  FormGroup,
  Col,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'moment';
import moment from 'moment-timezone';
import SimpleBar from 'simplebar-react';
import ReactPaginate from 'react-paginate';
import FilterDropDown from '../../../components/Common/FilterDropDown';
import Loader from '../../../components/Common/Loading/index.js';
import BankLogo from './Components/bankLogo';
import { Link } from 'react-router-dom';
import { QueryReallignment, ScrollToTop } from '../../../utils/general';
import queryString from 'query-string';

let skip = 0;
let dataLimit = 10;
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const BankTable = ({
  bankData,
  previewAction,
  fetchBank,
  deleteBank,
  statusHandler,
  message,
  history,
  dateFilter,
  fetchBankCSVError,
  fetchBankCSV,
  csvData,
  csv_loading,
  CheckAction,
  accessControl,
  ReadCheck
}) => {
  const [csv, setCsvData] = useState([]);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [data, setData] = useState(null);
  const [skipPage, setSkipPage] = useState(0);
  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState('');
  const [take, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [status, setStatus] = useState('');
  const [search, setSearch] = useState('');
  const [date, setDate] = useState({
    start: Moment().subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment.utc().tz('Africa/Lagos').format('YYYY-MM-DD'),
  });

  const handleLimitChange = async (e) => {
    setPageCount(Math.ceil(bankData?.total / e.target.value));
    setLimit(parseInt(e.target.value));
    dataLimit = parseInt(e.target.value);
    let pageInfo = { skip: pageNumber, take: dataLimit, status, search };
    fetchBank(pageInfo);
    SetUrlWithParams();
  };

  const handlePageClick = async (data) => {
    skip = data.selected;
    setPageNumber(parseInt(data.selected));
    if (skip > 0) {
      skip += '0';
      setPageNumber(parseInt(data.selected + '0'));
    }
    let pageInfo = { skip, take, status, search };
    setSkipPage(Math.ceil(pageNumber / take));
    SetUrlWithParams();
    fetchBank(pageInfo);
    ScrollToTop();
  };

  const handleDateFilter = (e, values) => {
    setDate({ start: values.startDate, end: values.endDate });
    dateFilter({ start: values.startDate, end: values.endDate });
    setStatus(values.Status);
    setMenu(false);
    let queryParams = {
      search,
      take,
      skip,
      status: values.Status,
      start: values.startDate,
      end: values.endDate,
    };
    fetchBank(queryParams);
  };

  const SearchHandler = (event) => {
    const search = event.target.value;
    setSearch(search);
    let queryParams = {
      search,
      take,
      // skip,
      status,
    };
    fetchBank(queryParams);
  };

  const resetHandler = () => {
    setMenu(false);
    setStatus('');
    setSearch('');
    setDate({
      start: '',
      end: '',
    });
    dateFilter({});

    fetchBank();
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        let queryParams = {
          search,
          take,
          skip,
          status,
        };
        fetchBank(queryParams);
      }, 2000);
    }
  }, [message]);

  const handleCSV = (e, values) => {
    fetchBankCSV({
      take: values.endRow
        ? values.endRow - values.startRow + 1
        : bankData?.total,
      skip: values.startRow ? values.startRow - 1 : 0,
    });
  };

  useEffect(() => {
    if (bankData) {
      SetUrlWithParams();
      setData(tableDataHandler());
      setIsLoading(false);
      setSkipPage(Math.ceil(pageNumber / take));
      setPageCount(Math.ceil(bankData?.total / take));
    }
  }, [bankData]);

  useEffect(() => {
    if (csvData) {
      // SET CSV EXPORT DATA
      const csv = csvData?.result.map((data) => {
        let id = data?.id?.substring(0, 7);
        let formData = {
          id: id,
          Account_Owner: `${data.user.firstName} - ${data.user.lastName}`,
          Account_Name: data.accountName ? data.accountName : '----',
          Bank: data?.bankName ? data?.bankName : 0,
          Account_Number: data.accountNumber,
          Status: data?.status,
        };

        return formData;
      });

      setCsvData(csv);
    }
  }, [csvData]);
  useEffect(() => {
    if (!showCSVModal) {
      fetchBankCSVError();
    }
  }, [showCSVModal]);

  useEffect(() => {
    let parsedUrlParams = updateStateWithUrlHandler();
    fetchBank(parsedUrlParams);
  }, [fetchBank]);

  const SetUrlWithParams = () => {
    let queryParams = {
      search,
      status,
      skip: pageNumber,
      take,
    };
    let result = QueryReallignment(queryParams);
    history.push({
      pathname: history.location.pathname,
      search: result,
    });
  };

  const updateStateWithUrlHandler = () => {
    // Parse the url
    const parsedUrlParams = queryString.parse(history.location.search);
    if (parsedUrlParams?.search) {
      setSearch(parsedUrlParams?.search);
    }
    if (parsedUrlParams?.status) {
      setStatus(parsedUrlParams?.status);
    }
    if (parsedUrlParams?.skip) {
      setPageNumber(parsedUrlParams?.skip);
    }
    if (parsedUrlParams?.take) {
      setLimit(parsedUrlParams?.take);
    }
    return parsedUrlParams;
  };
  let checking = ReadCheck(accessControl, "Finance", "Bank Detail");
  const tableDataHandler = () => {
    const rowData = bankData?.result?.map((data, i) => {
      return {
        keyId: data.id,
        index: `${skip > 0 ? i + 1 + pageNumber : i + 1}`,
        id: (

          <>
            {checking ?

              <Link
                to="#"
                onClick={() => previewAction(data.id)}
                className="text-dark font-weight-bold"
              >
                {data.id.substring(0, 7)}
              </Link>

              :
              <div className='text-dark font-weight-bold'>
                {data?.id?.substring(0, 7)}
              </div>
            }</>
        ),
        accountName: (
          <>
            {checking ?

              <Link
                to="#"
                onClick={() => previewAction(data.id)}
                className="text-dark font-weight-bold"
              >
                {data.accountName}
              </Link>

              :
              <div className='text-dark font-weight-bold'>
                {data.accountName}
              </div>
            }</>
        ),
        userName: (


          <>
            {checking ?

              <Link to={`/user/${data.user.id}`} className="text-dark">
                <div className="text-capitalize">
                  {data.user.firstName} {data.user.lastName}
                </div>
                <div className="d-flex align-items-center">
                  <div className="mr-2">
                    <img
                      src={data.user?.picture ? data.user?.picture : avatarImg}
                      alt=""
                      height="20"
                      className="rounded-circle avatar-xs"
                    />
                  </div>
                  <div>
                    <span
                      className={`badge badge-${data?.user?.userType === 'RIDER'
                        ? 'primary'
                        : data?.user?.userType === 'DRIVER'
                          ? 'secondary'
                          : data?.user?.userType === 'ADMIN'
                            ? 'success'
                            : 'warning'
                        }`}
                    >
                      {data?.user?.userType}
                    </span>
                  </div>
                </div>
              </Link>

              :
              <div className='text-dark font-weight-bold'>
                <div className="text-capitalize">
                  {data.user.firstName} {data.user.lastName}
                </div>
                <div className="d-flex align-items-center">
                  <div className="mr-2">
                    <img
                      src={data.user?.picture ? data.user?.picture : avatarImg}
                      alt=""
                      height="20"
                      className="rounded-circle avatar-xs"
                    />
                  </div>
                  <div>
                    <span
                      className={`badge badge-${data?.user?.userType === 'RIDER'
                        ? 'primary'
                        : data?.user?.userType === 'DRIVER'
                          ? 'secondary'
                          : data?.user?.userType === 'ADMIN'
                            ? 'success'
                            : 'warning'
                        }`}
                    >
                      {data?.user?.userType}
                    </span>
                  </div>
                </div>
              </div>
            }</>
        ),
        bankLogo: (
          <div className="d-flex align-items-center">
            <div className="mr-2">
              <BankLogo bank={data.bankName} />
            </div>
            <div>
              <div>{data.bankName}</div>
            </div>
          </div>
        ),

        createdAt: (
          <div>
            <div>{Moment(data.createdAt).format('l')}</div>
            <div className="badge badge-soft-secondary font-size-10">
              {' '}
              {Moment(data.createdAt).format('hh:mm A')}
            </div>
          </div>
        ),
        bankName: data.bankName,
        accountNumber: (
          <div
            className='d-flex flex-column  align-items-center text-truncate'
            style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}
          >
            <div className='text-capitalize'>
              {data?.accountName}
            </div>
            <div className='badge badge-soft-secondary font-size-12 mt-2'>
              {' '}
              {data?.accountNumber}
            </div>
          </div>
        ),
        status: (
          <h1
            className={`badge badge-soft-${data.status.toLowerCase() === 'active'
              ? 'success'
              : data.status.toLowerCase() === 'inactive'
                ? 'danger'
                : data.status.toLowerCase() === 'sanction'
                  ? 'warning'
                  : 'dark'
              } font-size-12`}
          >
            {data.status ? data.status : '---'}
          </h1>
        ),
        action: (
          <div className='d-flex align-items-center'>
            <CheckAction field="Finance" subModule="Bank Detail" iconType='Edit'>
              <Link
                to={`/bank_form?id=${data.id}`}
                className="mr-3 text-primary"
                id="edit1"
              >
                <i className="mdi mdi-pencil font-size-16"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="edit1">
                Edit
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field="Finance" subModule="Bank Detail" iconType='Delete'>
              <Link
                to="#"
                onClick={() => deleteBank(data.id)}
                className="mr-3 text-danger"
                id="delete1"
              >
                <i className="mdi mdi-trash-can font-size-16"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="delete1">
                Delete
              </UncontrolledTooltip>
            </CheckAction>
            <CheckAction field="Finance" subModule="Bank Detail" iconType='Edit'>
              <Link
                to="#"
                className="mr-3 text-success"
                id="activate"
                onClick={() => statusHandler(data)}
              >
                <i className="ri-edit-box-line font-size-16 text-success mb-2"></i>
              </Link>
              <UncontrolledTooltip placement="top" target="activate">
                Status
              </UncontrolledTooltip>
            </CheckAction>
          </div>
        ),
      };
    });
    return {
      columns: [
        {
          text: 'S/N',
          dataField: 'index',
          sort: true,
          sortFunc: (a, b, order, dataField) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b;
          },
        },
        {
          text: 'Bank Id',
          dataField: 'id',
          headerStyle: { width: '100px' }
        },
        {
          text: 'Owner',
          dataField: 'userName',
        },

        {
          text: 'Bank',
          dataField: 'bankLogo',
          headerStyle: { width: '150px' }
        },
        // {
        //   text: 'Bank Name',
        //   dataField: 'bankName',
        // },
        {
          text: 'Account',
          dataField: 'accountNumber',
        },

        {
          text: 'CreatedAt',
          dataField: 'createdAt',
        },

        {
          text: 'Status',
          dataField: 'status',
        },
        {
          text: 'Action',
          dataField: 'action',
        },
      ],
      rows: rowData,
    };
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-3">
              <div className="d-flex justify-content-end align-items-center mb-2">
                <div>
                  <Button
                    size="sm"
                    color="primary"
                    className="mt-1"
                    onClick={() => setShowCSVModal(true)}
                    disabled={bankData?.total > 0 ? false : true}
                  >
                    <i className=" fas fa-cloud-download-alt mr-1" />
                    CSV
                  </Button>
                </div>
                <div>
                  <FilterDropDown menu={menu} toggleMenu={() => setMenu(!menu)}>
                    <div>
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={handleDateFilter}
                      >
                        <SimpleBar
                          style={{
                            maxHeight: '250px',
                          }}
                        >
                          <Row className="px-3  pt-3 pb-0">
                            <Col md="12">
                              <FormGroup>
                                <AvField
                                  name="startDate"
                                  type="date"
                                  className="form-control bg-light"
                                  id="startDate"
                                  value={date.start}
                                  helpMessage="Start Date"
                                  placeholder="Please choose the start date..."
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup>
                                <AvField
                                  name="endDate"
                                  type="date"
                                  className="form-control bg-light"
                                  id="endDate"
                                  value={date.end}
                                  helpMessage="End Date"
                                  placeholder="Please choose the end date..."
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup>
                                <AvField
                                  type="select"
                                  value={status}
                                  helpMessage="Status"
                                  name="Status"
                                >
                                  <option value="">All</option>
                                  <option value="ACTIVE">ACTIVE</option>
                                  <option value="INACTIVE">INACTIVE</option>
                                  <option value="REVIEW">REVIEW</option>
                                </AvField>
                              </FormGroup>
                            </Col>
                          </Row>

                          <div className="p-2 d-flex justify-content-between align-items-center bg-light">
                            <Link
                              to="#"
                              onClick={resetHandler}
                              className="btn btn-sm  bg-light font-size-14"
                            >
                              <i className=" mdi mdi-refresh mr-1"> Reset</i>
                            </Link>

                            <button
                              className="btn btn-success btn-sm mr-2"
                              type="submit"
                            >
                              <i className="mdi mdi-arrow-right-circle mr-1"></i>
                              Send
                            </button>
                          </div>
                        </SimpleBar>
                      </AvForm>
                    </div>
                  </FilterDropDown>
                </div>
              </div>
              {!isLoading && data !== null ? (
                <>
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <Input
                        bsSize="sm"
                        id="take"
                        name="take"
                        type="select"
                        onChange={handleLimitChange}
                        value={take}
                      >
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                      </Input>
                    </div>
                    <div>
                      <Input
                        bsSize="sm"
                        id="search"
                        name="search"
                        onChange={SearchHandler}
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <BootstrapTable
                    keyField="keyId"
                    data={data.rows}
                    columns={data.columns}
                    striped
                    hover
                    wrapperClasses="table-responsive"
                    noDataIndication={'No Data to Display'}
                  />
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    // breakLabel={'...'}
                    // marginPagesDisplayed={2}
                    // pageRangeDisplayed={3}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={skipPage}
                    //Bootstrap styling
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </>
              ) : (
                <Loader loading={isLoading} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* CSV DOWNLOAD MODAL */}
      <Modal
        isOpen={showCSVModal}
        toggle={() => setShowCSVModal(!showCSVModal)}
      >
        <ModalHeader toggle={() => setShowCSVModal(false)}>
          Generate CSV
        </ModalHeader>
        <ModalBody>
          <AvForm className="form-horizontal" onValidSubmit={handleCSV}>
            <Col>
              <FormGroup>
                <Label htmlFor="startRow">Start (Row)</Label>
                <AvField
                  name="startRow"
                  type="number"
                  max={bankData?.total - 1}
                  className="form-control"
                  id="startRow"
                  placeholder="Enter the row to start from"
                  errorMessage="value must be greater than 0 and less than total data"
                  min="1"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="endRow">End (Row)</Label>
                <AvField
                  name="endRow"
                  type="number"
                  className="form-control"
                  min="1"
                  max={bankData?.total}
                  id="endRow"
                  placeholder="Enter the row to end"
                  helpMessage={
                    bankData?.total ? `Total data is ${bankData?.total}` : null
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Button
              size="sm"
              color="primary"
              className="ml-3 waves-effect waves-light "
              type="submit"
            >
              {csv_loading ? 'Generating...' : 'Generate'}
            </Button>
            {csvData && (
              <CSVLink
                data={csv}
                filename={'driver_bank_detail.csv'}
                className="btn-sm btn-success ml-2"
                target="_blank"
                onClick={() => setShowCSVModal(false)}
              >
                Download
              </CSVLink>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default BankTable;
