import {
  ASSIGN_COMPLAINT,
  ASSIGN_COMPLAINT_SUCCESSFUL,
  ASSIGN_COMPLAINT_API_FAILED,
  FETCH_FOR_COMPLAINT_CSV,
  FETCH_FOR_MY_COMPLAINT_CSV,
  FETCH_FOR_COMPLAINT_CSV_SUCCESSFULLY,
  FETCH_FOR_COMPLAINT_CSV_ERROR,
  FETCH_COMPLAINT_SUMMARY,
  FETCH_COMPLAINT_SUMMARY_SUCCESSFULLY,
  FETCH_COMPLAINT_SUMMARY_FAILED,
  FETCH_COMPLAINT,
  FETCH_MY_COMPLAINT,
  FETCH_COMPLAINT_SUCCESSFUL,
  FETCH_COMPLAINT_API_FAILED,
  FETCH_EACH_COMPLAINT,
  FETCH_EACH_COMPLAINT_SUCCESSFUL,
  FETCH_EACH_COMPLAINT_API_FAILED,
  DELETE_COMPLAINT,
  DELETE_COMPLAINT_SUCCESSFUL,
  DELETE_COMPLAINT_FAILED,
  CREATE_COMPLAINT,
  CREATE_COMPLAINT_SUCCESSFUL,
  CREATE_COMPLAINT_FAILED,
  UPDATE_COMPLAINT,
  UPDATE_COMPLAINT_SUCCESSFUL,
  UPDATE_COMPLAINT_FAILED,
} from './actionTypes';

const initialState = {
  complaint: null,
  complaintError: null,
  complaintPreview: null,
  complaintSummary: null,
  summaryError: null,
  message: null,
  loading: false,
  csv: null,
  csv_loading: false,
};

const Complaint = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPLAINT:
    case FETCH_MY_COMPLAINT:
    case DELETE_COMPLAINT:
      state = {
        ...state,
        complaint: null,
        complaintError: null,
        loading: true,
        message: null,
      };
      break;

    case ASSIGN_COMPLAINT:
    case UPDATE_COMPLAINT:
      state = {
        ...state,
        complaintError: null,
        loading: true,
        message: null,
      };
      break;

    case FETCH_COMPLAINT_SUCCESSFUL:
      state = {
        ...state,
        complaint: action.payload,
        complaintError: null,
        loading: false,
      };
      break;

    case ASSIGN_COMPLAINT_SUCCESSFUL:
    case UPDATE_COMPLAINT_SUCCESSFUL:
      state = {
        ...state,
        message: action.payload,
        complaintError: null,
        loading: false,
      };
      break;

    case DELETE_COMPLAINT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        complaintError: null,
        message: 'complaint details deleted successfully',
      };
      break;

    case FETCH_COMPLAINT_SUMMARY:
      return {
        ...state,
        complaintSummary: null,
        summaryError: null,
        loading: true,
      };

    case FETCH_COMPLAINT_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        complaintSummary: action.payload,
        summaryError: null,
        loading: false,
      };

    case FETCH_COMPLAINT_SUMMARY_FAILED:
      return {
        ...state,
        complaintSummary: null,
        summaryError: action.payload,
        loading: false,
      };

    case FETCH_EACH_COMPLAINT:
      state = {
        ...state,
        loading: true,
        complaint: null,
        complaintPreview: null,
        complaintError: null,
        message: null,
      };
      break;
    case FETCH_EACH_COMPLAINT_SUCCESSFUL:
      state = {
        ...state,
        complaintPreview: action.payload,
        loading: false,
        complaintError: null,
        message: null,
      };
      break;

    case CREATE_COMPLAINT:
      state = {
        ...state,
        loading: true,
        complaintError: null,
        message: null,
      };
      break;

    case CREATE_COMPLAINT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        complaintError: null,
        message: `${action.payload.complaintName} details added successfully for ${action.payload.accountName}`,
      };
      break;

    case CREATE_COMPLAINT_FAILED:
    case FETCH_COMPLAINT_API_FAILED:
    case DELETE_COMPLAINT_FAILED:
    case FETCH_EACH_COMPLAINT_API_FAILED:
      state = {
        ...state,
        complaint: null,
        complaintPreview: null,
        loading: false,
        message: null,
        complaintError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;

    case ASSIGN_COMPLAINT_API_FAILED:
    case UPDATE_COMPLAINT_FAILED:
      state = {
        ...state,
        loading: false,
        message: null,
        complaintError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      };
      break;
    case FETCH_FOR_COMPLAINT_CSV:
    case FETCH_FOR_MY_COMPLAINT_CSV:
      state = {
        ...state,
        csv: null,
        csv_loading: false,
      };
      break;

    case FETCH_FOR_COMPLAINT_CSV_SUCCESSFULLY:
      state = {
        ...state,
        csv: action.payload,
        csv_loading: false,
      };
      break;
    case FETCH_FOR_COMPLAINT_CSV_ERROR:
      state = {
        ...state,
        csv: null,
        csv_loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Complaint;
