/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { Alert, Row, Col, Table } from 'reactstrap';
import Loader from '../../../../components/Common/Loading/index.js';
import { fetchEachContact, fetchUserDetails } from '../../../../store/actions';
import { Link } from 'react-router-dom';

const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const Contact = ({
  fetchUserDetails,
  user,
  contact,
  previewId,
  loading,
  contactError,
  fetchEachContact,
}) => {
  useEffect(() => {
    fetchEachContact(previewId);
  }, [fetchEachContact, previewId]);

  useEffect(() => {
    if (contact) {
      fetchUserDetails(contact.userId);
    }
  }, [contact]);

  return (
    <React.Fragment>
      <div className='mt-3'>
        {contactError && !loading ? (
          <Alert color='danger' className='text-center'>
            {contactError}
          </Alert>
        ) : (
          <>
            {contact === null ? (
              <Loader />
            ) : (
              <Row>
                <Col sm={12}>
                  <div>
                    <Link
                      to={`/user/${contact?.userId}`}
                      className='mr-3 text-primary'
                    >
                      <Row className='mt-2 m-2 align-items-center border shadow rounded p-2'>
                        <img
                          className='rounded-circle avatar-md border shadow-lg p-2 ml-2'
                          width='100%'
                          alt='Profile'
                          src={user?.picture ? user?.picture : avatarImg}
                        />
                        <Col sm={8}>
                          {' '}
                          <h4 className='font-weight-bold font-size-12 mt-1 mb-1 text-capitalize'>
                            {user?.firstName} {user?.lastName}
                            <span className='text-muted font-size-10'>
                              <span className='mx-2'> | </span>
                              <span className='mr-2'>
                                <i className=' fas fa-star text-warning mr-1' />
                                {user?.rating}
                              </span>
                              <span className='text-muted'>
                                ({user?.raters}{' '}
                                <i className='fas fa-user font-size-10 mr-1 text-muted' />
                                )
                              </span>
                            </span>
                          </h4>
                          <div className='mb-2'>
                            <span className='text-muted font-size-10 font-weight-bold'>
                              {user?.userType}
                            </span>
                          </div>
                          <div className='d-flex justify-content-between'>
                            {user?.loyalty?.tier && (
                              <h5 className='font-weight-bold badge badge-soft-secondary rounded'>
                                <i className='ri-vip-crown-2-line mr-1 text-warning' />
                                {user?.loyalty?.tier}
                              </h5>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Link>
                    <Table hover>
                      <tbody>
                        <tr>
                          <td className='font-weight-bold'>Contact Name </td>
                          <td className='text-uppercase font-size-16 font-weight-bold text-success'>
                            {contact?.name}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Relationship</td>
                          <td className='text-uppercase'>
                            {contact?.relationship}
                          </td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Phone Number</td>
                          <td className='text-uppercase'>{contact?.phone}</td>
                        </tr>
                        <tr>
                          <td className='font-weight-bold'>Created At</td>
                          <td className='text-uppercase'>
                            {Moment(contact.createdAt).format('l')}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { contact, loading, contactError } = state.Contact;
  const { user, userError } = state.Users;
  return { contact, loading, contactError, user, userError };
};

export default connect(mapStateToProps, { fetchEachContact, fetchUserDetails })(
  Contact
);
