import {
  FETCH_BUS_STOPS,
  FETCH_BUS_STOPS_SUCCESSFUL,
  FETCH_BUS_STOPS_API_FAILED,
  FETCH_EACH_BUS_STOP,
  FETCH_EACH_BUS_STOP_SUCCESSFUL,
  FETCH_EACH_BUS_STOP_API_FAILED,
  DELETE_BUS_STOP,
  DELETE_BUS_STOP_SUCCESSFUL,
  DELETE_BUS_STOP_FAILED,
  CREATE_BUS_STOP,
  CREATE_BUS_STOP_SUCCESSFUL,
  CREATE_BUS_STOP_FAILED,
  UPDATE_BUS_STOP,
  UPDATE_BUS_STOP_SUCCESSFUL,
  UPDATE_BUS_STOP_FAILED,
} from './actionTypes';

export const fetchBusStops = (payload) => {
  return {
    type: FETCH_BUS_STOPS,
    payload,
  };
};

export const fetchBusStopsSuccessful = (payload) => {
  return {
    type: FETCH_BUS_STOPS_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchBusStopsError = (error) => {
  return {
    type: FETCH_BUS_STOPS_API_FAILED,
    payload: error,
  };
};

export const fetchEachBusStop = (id) => {
  return {
    type: FETCH_EACH_BUS_STOP,
    payload: id,
  };
};

export const fetchEachBusStopSuccessful = (payload) => {
  return {
    type: FETCH_EACH_BUS_STOP_SUCCESSFUL,
    payload: payload,
  };
};

export const fetchEachBusStopError = (error) => {
  return {
    type: FETCH_EACH_BUS_STOP_API_FAILED,
    payload: error,
  };
};

export const deleteBusStop = (id) => {
  return {
    type: DELETE_BUS_STOP,
    payload: id,
  };
};

export const deleteBusStopSuccessful = (payload) => {
  return {
    type: DELETE_BUS_STOP_SUCCESSFUL,
    payload,
  };
};

export const deleteBusStopFailed = (error) => {
  return {
    type: DELETE_BUS_STOP_FAILED,
    payload: error,
  };
};

export const createBusStop = (payload) => {
  return {
    type: CREATE_BUS_STOP,
    payload,
  };
};

export const createBusStopSuccessful = (payload) => {
  return {
    type: CREATE_BUS_STOP_SUCCESSFUL,
    payload,
  };
};

export const createBusStopFailed = (error) => {
  return {
    type: CREATE_BUS_STOP_FAILED,
    payload: error,
  };
};

export const updateBusStop = (payload) => {
  return {
    type: UPDATE_BUS_STOP,
    payload,
  };
};

export const updateBusStopFailed = (error) => {
  return {
    type: UPDATE_BUS_STOP_FAILED,
    payload: error,
  };
};

export const updateBusStopSuccessful = (payload) => {
  return {
    type: UPDATE_BUS_STOP_SUCCESSFUL,
    payload,
  };
};
