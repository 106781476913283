import HttpService from './HttpService';
import { QueryReallignment } from '../utils/general';

export const GetZoneService = (query) => {
  const http = new HttpService();
  let url = 'zone/all';

  if (query) {
    let queryParams = QueryReallignment(query);
    url = `${url}?${queryParams}`;
  }
  return http.getData(url);
};

export const GetEachZoneService = (id) => {
  const http = new HttpService();
  const url = `zone/${id}`;
  return http.getData(url);
};

export const CreateZoneService = (payload) => {
  const http = new HttpService();
  const url = 'zone';
  return http.postData(payload, url);
};
export const UpdateZoneService = (payload) => {
  const http = new HttpService();
  const url = `zone/${payload.id}`;
  return http.putData(payload, url);
};

export const DeleteZoneService = (id) => {
  const http = new HttpService();
  const url = `zone/${id}`;
  return http.deleteData(url);
};
