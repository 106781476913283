import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_ROLE,
  FETCH_PRIVILEGES,
  CREATE_ROLE,
  DELETE_ROLE,
  UPDATE_ROLE,
  FETCH_EACH_ROLE,
  UNASSIGN_ROLE,
  ASSIGN_ROLE,
} from './actionTypes';
import {
  fetchRoleSuccessful,
  fetchRoleError,
  fetchPrivilegesSuccessful,
  fetchPrivilegesError,
  deleteRoleSuccessful,
  deleteRoleFailed,
  createRoleSuccessful,
  createRoleFailed,
  updateRoleSuccessful,
  updateRoleFailed,
  fetchEachRoleSuccessful,
  fetchEachRoleError,
  assignRoleSuccessful,
  assignRoleFailed,
  unassignRoleSuccessful,
  unassignRoleFailed,
} from './actions';

import {
  GetRoleService,
  GetPrivilegesService,
  DeleteRoleService,
  CreateRoleService,
  UpdateRoleService,
  GetEachRoleService,
  UnassignRoleService,
  AssignRoleService,
} from '../../services/rolesService';

function* fetchRoleHandler({ payload }) {
  try {
    const response = yield call(GetRoleService, payload);
    yield put(fetchRoleSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchRoleError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchRoleError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchRoleError(error?.response?.data?.message));
    }
  }
}
function* fetchPrivilegesHandler({ payload }) {
  try {
    const response = yield call(GetPrivilegesService, payload);
    yield put(fetchPrivilegesSuccessful(response.data));
  } catch (error) {
    yield put(fetchPrivilegesError(error?.response?.data.error?.message));
  }
}

function* fetchEachRoleHandler({ payload }) {
  try {
    const response = yield call(GetEachRoleService, payload);
    yield put(fetchEachRoleSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachRoleError(error?.response?.data.error?.message));
  }
}

function* deleteRoleHandler({ payload }) {
  try {
    const response = yield call(DeleteRoleService, payload);
    yield put(deleteRoleSuccessful(response.data.result));
  } catch (error) {
    yield put(deleteRoleFailed(error?.response?.data?.message));
  }
}

function* createRoleHandler({ payload }) {
  try {
    const response = yield call(CreateRoleService, payload);
    yield put(createRoleSuccessful(response.data.result));
  } catch (error) {
    yield put(createRoleFailed(error?.response?.data?.message));
  }
}

function* updateRoleHandler({ payload }) {
  try {
    const response = yield call(UpdateRoleService, payload);
    yield put(updateRoleSuccessful(response.data.result));
  } catch (error) {
    yield put(updateRoleFailed(error?.response?.data?.message));
  }
}

function* assignRoleHandler({ payload }) {
  try {
    const response = yield call(AssignRoleService, payload);
    yield put(assignRoleSuccessful(response.data.result));
  } catch (error) {
    yield put(assignRoleFailed(error?.response?.data?.message));
  }
}

function* unassignRoleHandler({ payload }) {
  try {
    const response = yield call(UnassignRoleService, payload);
    yield put(unassignRoleSuccessful(response.data.result));
  } catch (error) {
    yield put(unassignRoleFailed(error?.response?.data?.message));
  }
}

// ROLE SUMMARY

export function* watchFetchRole() {
  yield takeEvery(FETCH_ROLE, fetchRoleHandler);
}
export function* watchFetchPrivileges() {
  yield takeEvery(FETCH_PRIVILEGES, fetchPrivilegesHandler);
}
export function* watchFetchEachRole() {
  yield takeEvery(FETCH_EACH_ROLE, fetchEachRoleHandler);
}

export function* watchDeleteRole() {
  yield takeEvery(DELETE_ROLE, deleteRoleHandler);
}
export function* watchCreateRole() {
  yield takeEvery(CREATE_ROLE, createRoleHandler);
}
export function* watchUpdateRole() {
  yield takeEvery(UPDATE_ROLE, updateRoleHandler);
}
export function* watchAssignRole() {
  yield takeEvery(ASSIGN_ROLE, assignRoleHandler);
}
export function* watchUnassignRole() {
  yield takeEvery(UNASSIGN_ROLE, unassignRoleHandler);
}

function* roleSaga() {
  yield all([
    fork(watchFetchRole),
    fork(watchFetchPrivileges),
    fork(watchFetchEachRole),
    fork(watchDeleteRole),
    fork(watchCreateRole),
    fork(watchUpdateRole),

    fork(watchAssignRole),
    fork(watchUnassignRole),
  ]);
}

export default roleSaga;
