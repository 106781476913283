import {
  FETCH_LOYALTY,
  FETCH_LOYALTY_SUCCESSFUL,
  FETCH_LOYALTY_API_FAILED,
  FETCH_MY_LOYALTY,
  FETCH_MY_LOYALTY_SUCCESSFUL,
  FETCH_MY_LOYALTY_API_FAILED,
  UPDATE_USER_POINT,
  UPDATE_USER_POINT_SUCCESSFUL,
  UPDATE_USER_POINT_FAILED,
  FETCH_LOYALTY_SUMMARY,
  FETCH_LOYALTY_SUMMARY_SUCCESSFULLY,
  FETCH_LOYALTY_SUMMARY_FAILED,
} from './actionTypes'

const initialState = {
  loyalty: null,
  loyaltyError: null,
  loyaltyPreview: null,
  tableError: null,
  loyaltySummary: null,
  summaryError: null,
  message: null,
  loading: false,
}

const Loyalty = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOYALTY:
    case UPDATE_USER_POINT:
      state = {
        ...state,
        loyalty: null,
        loyaltyError: null,
        loading: true,
        isAuthorize: null,
        tableError: null,
        message: null,
        pointError: null,
      }
      break
    case FETCH_LOYALTY_SUCCESSFUL:
      state = {
        ...state,
        loyalty: action.payload,
        loyaltyError: null,
        tableError: null,
        loading: false,
      }
      break

    case UPDATE_USER_POINT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        loyaltyError: null,
        message: 'User Point Updated Successfully',
      }
      break

    case FETCH_LOYALTY_SUMMARY:
      return {
        ...state,
        loyaltySummary: null,
        summaryError: null,
        loading: true,
      }

    case FETCH_LOYALTY_SUMMARY_SUCCESSFULLY:
      return {
        ...state,
        loyaltySummary: action.payload,
        tableError: null,
        summaryError: null,
        loading: false,
      }

    case FETCH_LOYALTY_SUMMARY_FAILED:
      return {
        ...state,
        loyaltySummary: null,
        summaryError: action.payload,
        loading: false,
      }

    case FETCH_MY_LOYALTY:
      state = {
        ...state,
        loading: true,
        loyalty: null,
        loyaltyError: null,
        message: null,
      }
      break
    case FETCH_MY_LOYALTY_SUCCESSFUL:
      state = {
        ...state,
        loyaltyPreview: action.payload,
        loading: false,
        tableError: null,
        loyaltyError: null,
        message: null,
      }
      break

    case FETCH_LOYALTY_API_FAILED:
    case FETCH_MY_LOYALTY_API_FAILED:
      state = {
        ...state,
        loyalty: null,
        loyaltyPreview: null,
        loading: false,
        message: null,
        tableError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
      }
      break

    case UPDATE_USER_POINT_FAILED:
      state = {
        ...state,
        loading: false,
        pointError:
          action.payload.constructor === String
            ? action.payload
            : 'Error in processing data',
        message: null,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Loyalty
