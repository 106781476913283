import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Col, Card, CardBody, Media } from 'reactstrap';
import { connect } from 'react-redux';
import { fetchReviewSummary } from '../../../../store/actions';

const MiniWidgets = ({
  summaryError,
  reviewSummary,
  fetchReviewSummary,
  start,
  end,
  loading,
  defaultIcon,
  userType,
  date,
}) => {
  useEffect(() => {
    if (date.end) {
      if (userType) {
        fetchReviewSummary({ userType, start: date.start, end: date.end });
      } else {
        fetchReviewSummary(date);
      }
    } else {
      if (userType) {
        fetchReviewSummary({ userType });
      } else {
        fetchReviewSummary();
      }
    }
  }, [userType, date]);

  const reports = [
    {
      icon: defaultIcon ? defaultIcon : 'ri-bank-line',
      title: 'TOTAL',
      value: `${reviewSummary?.result.total}`,
      color: 'primary',
      desc: 'From previous period',
    },
    {
      icon: 'ri-star-s-line',
      title: 'RATINGS',
      value: reviewSummary?.result.ratings.toFixed(2),
      driver: `${
        reviewSummary?.result?.ratingsDriver
          ? reviewSummary?.result?.ratingsDriver.toFixed(2)
          : '0'
      }`,
      rider: `${
        reviewSummary?.result?.ratingsRider
          ? reviewSummary?.result?.ratingsRider.toFixed(2)
          : '0'
      }`,
      color: 'warning',
      desc: 'From previous period',
    },
    {
      icon: 'ri-wallet-3-line ',
      title: 'TOTAL TIPS',
      value: `${reviewSummary?.result.totalTip}`,
      color: 'success',
      desc: 'From previous period',
    },
    {
      icon: 'mdi mdi-cash-multiple',
      title: 'TIPS',
      value: `${reviewSummary?.result.tips}`,
      average: `${
        reviewSummary?.result?.averageTips
          ? reviewSummary?.result?.averageTips
          : '0'
      }`,
      color: 'secondary',
      desc: 'From previous period',
      updatedTime: `${reviewSummary?.time}`,
    },
  ];

  return (
    <React.Fragment>
      {summaryError === null &&
        reports.map((report, key) => (
          <Col key={key} md={3}>
            <Card className='d-flex flex-row'>
              <CardBody>
                <Media>
                  <Media body className='overflow-hidden'>
                    <span
                      className={`text-${report.color} badge badge-soft-${report.color} rounded`}
                    >
                      <i className={report.icon + ' font-size-16'}></i>
                    </span>
                    <p className='text-truncate font-size-14 mt-4 mb-2 '>
                      {report.title}
                    </p>
                    <h2 className={`${report.driver && 'd-flex '} mt-0 mb-0`}>
                      {reviewSummary === null ? (
                        <Skeleton duration={2} width='20%' />
                      ) : (
                        <>
                          {report.value}
                          {report.average && (
                            <span className='ml-1 font-size-12 text-secondary font-weight-light'>
                              ({report.average})
                            </span>
                          )}
                          {/* {report.driver && (
                            <div className='d-flex w-100 justify-content-around'>
                              <div className='d-flex align-items-center text-muted font-size-12 font-weight-light'>
                                (
                                <i className=' mdi mdi-account-tie text-secondary font-size-12' />{' '}
                                <span className='ml-1'>{report?.driver}</span>)
                              </div>

                              <div className='d-flex align-items-center text-muted font-size-12 font-weight-light'>
                                (
                                <i className=' mdi mdi-account text-secondary font-size-12' />{' '}
                                <span className='ml-1'>{report?.rider}</span>)
                              </div>
                            </div>
                          )} */}
                        </>
                      )}
                    </h2>
                    {reviewSummary?.isCache && report?.updatedTime && (
                      <p
                        className='font-size-12 text-primary'
                        style={{ position: 'absolute', right: '11px' }}
                      >
                        Updated at: {report?.updatedTime?.split(',')?.pop()}
                      </p>
                    )}
                  </Media>
                </Media>
              </CardBody>
              {/* {report.driver && (
                <CardBody className='d-flex flex-column justify-content-center'>
                  {reviewSummary === null ? (
                    <Skeleton duration={2} width='100%' />
                  ) : (
                    <div className='d-flex justify-content-between align-items-center text-muted'>
                      (
                      <i className=' mdi mdi-account-tie text-secondary font-size-16' />{' '}
                      - <h6 className='m-0'>{report?.driver}</h6>)
                    </div>
                  )}
                  {reviewSummary === null ? (
                    <Skeleton duration={2} width='100%' />
                  ) : (
                    <div className='d-flex justify-content-between align-items-center'>
                      <i className=' mdi mdi-account text-secondary font-size-18' />{' '}
                      - <h6 className='m-0'>{report?.rider}</h6>
                    </div>
                  )}
                </CardBody>
              )} */}
              {/* <CardBody className="border-top py-3">
              <div className="text-truncate">
                <span className="badge badge-soft-success font-size-11 mr-1">
                  <i className="mdi mdi-menu-up"> </i> {report.rate}
                </span>
                <span className="text-muted ml-2">{report.desc}</span>
              </div>
            </CardBody> */}
            </Card>
          </Col>
        ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { summaryError, reviewSummary } = state.Review;
  return { summaryError, reviewSummary };
};

export default connect(mapStateToProps, { fetchReviewSummary })(MiniWidgets);
