import React from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import Button from 'reactstrap/lib/Button';

const TripSettings = () => {
  return (
    <div>
      <div>
        <div>
          <AvForm
            className='form-horizontal'
            // onValidSubmit={handleSubmit}
            model={null}
          >
            <Row>
              <Col>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>Service Tax</Label>
                  <AvField
                    name='name'
                    type='text'
                    className='form-control bg-light'
                    id='name'
                    placeholder='Enter App Name ...'
                    required
                  />
                </FormGroup>

                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>Admin Commission Type</Label>
                  <AvField
                    name='adminCommssionType'
                    type='select'
                    className='form-control bg-light'
                    id='commssionType'
                    required
                  >
                    <option>Percentage</option>
                    <option>Fixed</option>
                  </AvField>
                </FormGroup>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>Admin Commission</Label>
                  <AvField
                    name='commission'
                    min='0'
                    type='number'
                    className='form-control bg-light'
                    id='commission'
                    placeholder='Enter Admin Commission ...'
                    required
                  />
                </FormGroup>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>Driver Search Radius</Label>
                  <AvField
                    name='radius'
                    min='0'
                    type='number'
                    className='form-control bg-light'
                    id='radius'
                    placeholder='Enter Driver Search Radius ...'
                    required
                  />
                </FormGroup>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>
                    Mininum time for search drivers for schedule rides in
                    miniutes
                  </Label>
                  <AvField
                    name='rideSchedule'
                    min='0'
                    type='number'
                    className='form-control bg-light'
                    id='rideSchedule'
                    required
                  />
                </FormGroup>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>Driver Commission Type </Label>
                  <AvField
                    name='driverCommissionType'
                    type='select'
                    className='form-control bg-light'
                    id='driverCommissionType'
                    required
                  >
                    <option>Commission 1</option>
                    <option>Commission 2</option>
                  </AvField>
                </FormGroup>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>
                    Driver Monthly Subscription Amount{' '}
                  </Label>
                  <AvField
                    name='monthlySub'
                    min='0'
                    type='number'
                    className='form-control bg-light'
                    id='monthlySub'
                    required
                  />
                </FormGroup>
                <FormGroup className=' mb-4'>
                  <Label htmlFor='provider'>
                    Driver Yearly Subscription Amount{' '}
                  </Label>
                  <AvField
                    name='yearlySub'
                    type='number'
                    min='100'
                    className='form-control bg-light'
                    id='yearlySub'
                    required
                  />
                </FormGroup>
                <div className='text-right'>
                  <Button className='btn btn-success' size='lg'>
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </div>
      </div>
    </div>
  );
};

export default TripSettings;
