import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Form from './form';

import { fetchRoles } from '../../../../store/actions';

const RoleForm = ({ fetchRoles, roles, loading, message }) => {
  const [isUnassign, setIsUnassign] = useState(false);
  const search = useLocation().search;
  const unassign = new URLSearchParams(search).get('function');

  useEffect(() => {
    fetchRoles();
    if (unassign) {
      setIsUnassign(true);
    }
  }, [unassign]);

  return (
    <React.Fragment>
      <div className='page-content' style={{ height: '640px' }}>
        <Form roles={roles} message={message} isUnassign={isUnassign} fetchRoles={fetchRoles} />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { roles, loading, roleError, message } = state.Role;
  return { roles, loading, roleError, message };
};

export default connect(mapStateToProps, { fetchRoles })(RoleForm);
