/* eslint-disable eqeqeq */
import React, { useEffect } from 'react'
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Alert, Row, Col, Progress } from 'reactstrap'
import Loader from '../../../components/Common/Loading/index.js'
import { fetchEachUserBonus } from '../../../store/actions'
import Moment from 'moment'

const PreviewComplaint = ({
  eachUserBonus,
  previewId,
  user,
  loading,
  bonusError,
  fetchEachUserBonus,
}) => {
  useEffect(() => {
    let payload = { id: previewId, userId: user.id }
    fetchEachUserBonus(payload)
  }, [fetchEachUserBonus, previewId, user])

  return (
    <React.Fragment>
      <div className="mt-1">
        {bonusError && !loading && (
          <Alert color="danger" className="text-center">
            {bonusError}
          </Alert>
        )}
        {!eachUserBonus && loading ? (
          <Loader />
        ) : (
          <Row>
            <Col sm={12} className="text-center">
              <div>
                <div className="text-center mt-2">
                  <button
                    disabled={eachUserBonus?.result?.isRedeemed}
                    className={`btn btn-${
                      eachUserBonus?.result?.isRedeemed ? 'primary' : 'light'
                    } btn-sm`}
                    id="ride"
                  >
                    isRedeemed
                  </button>
                  <h5 className="mt-2">
                    NGN {eachUserBonus?.result?.bonus?.value}
                  </h5>
                </div>
              </div>
            </Col>
            <Col sm={12}>
              <div>
                <hr />
                <h5 className="font-size-14 text-center  bg-light py-1 rounded">
                  {eachUserBonus?.result?.bonus?.name}
                  <span className="d-block font-size-10 text-secondary">
                    {eachUserBonus?.result?.bonus?.description}
                  </span>
                </h5>
                <hr />
                <div className="d-flex mb-2">
                  <h5 className="col-6 font-size-14"> Completed Rides :</h5>
                  <p className="mb-1 text-capitalize">
                    {eachUserBonus?.result?.bonus?.requirements?.ride}
                  </p>
                </div>

                <div className="d-flex mb-2">
                  <h5 className="col-6 font-size-14"> Acceptance Rate:</h5>
                  <p className="mb-1">
                    {eachUserBonus?.result?.bonus?.requirements?.acceptance *
                      100}
                    /100
                  </p>
                </div>

                <div className="d-flex mb-2">
                  <h5 className="col-6 font-size-14">Completion Rate:</h5>
                  <p className="mb-1">
                    {eachUserBonus?.result?.bonus?.requirements?.completions *
                      100}
                    /100
                  </p>
                </div>
                <div className="d-flex mb-2">
                  <h5 className="col-6 font-size-14">Hours Online:</h5>
                  <p className="mb-1">
                    {eachUserBonus?.result?.bonus?.requirements?.online}
                  </p>
                </div>
              </div>
              <hr />
              <h5 className="font-size-14 text-center  bg-light py-1 rounded">
                Progress
              </h5>
              <hr />
              <div>
                <span> Rides </span>
                <Progress
                  color="success"
                  className="my-2"
                  value={
                    (eachUserBonus?.result?.ride /
                      eachUserBonus?.result?.bonus?.requirements?.ride) *
                    100
                  }
                >
                  {eachUserBonus?.result?.ride}
                </Progress>
                <div className="d-flex justify-content-between">
                  <span>0</span>
                  <span>
                    {eachUserBonus?.result?.bonus?.requirements?.ride}
                  </span>
                </div>
              </div>

              <hr />
              <div>
                <span> Online </span>
                <Progress
                  color="success"
                  className="my-2"
                  value={
                    (eachUserBonus?.result?.online /
                      eachUserBonus?.result?.bonus?.requirements?.online) *
                    100
                  }
                >
                  {eachUserBonus?.result?.online}
                </Progress>
                <div className="d-flex justify-content-between">
                  <span>0</span>
                  <span>
                    {eachUserBonus?.result?.bonus?.requirements?.online}
                  </span>
                </div>
              </div>

              <hr />
              <div>
                <span> Completions </span>
                <Progress
                  color="success"
                  className="my-2"
                  value={
                    (eachUserBonus?.result?.completions /
                      eachUserBonus?.result?.bonus?.requirements?.completions) *
                    100
                  }
                >
                  {eachUserBonus?.result?.completions}
                </Progress>
                <div className="d-flex justify-content-between">
                  <span>0</span>
                  <span>
                    {eachUserBonus?.result?.bonus?.requirements?.completions *
                      100}
                    %
                  </span>
                </div>
              </div>

              <hr />
              <div>
                <span> Acceptance </span>
                <Progress
                  color="success"
                  className="my-2"
                  value={
                    (eachUserBonus?.result?.acceptance /
                      eachUserBonus?.result?.bonus?.requirements?.acceptance) *
                    100
                  }
                >
                  {eachUserBonus?.result?.acceptance}
                </Progress>
                <div className="d-flex justify-content-between">
                  <span>0</span>
                  <span>
                    {eachUserBonus?.result?.bonus?.requirements?.acceptance *
                      100}
                    %
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { eachUserBonus, loading, bonusError } = state.Bonus
  return { eachUserBonus, loading, bonusError }
}

export default connect(mapStateToProps, { fetchEachUserBonus })(
  PreviewComplaint,
)
