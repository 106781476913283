import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import {
  FETCH_PROMOCODE,
  FETCH_PROMOHISTORY,
  FETCH_PROMOREDEEMHISTORY,
  FETCH_EACH_PROMOCODE,
  FETCH_PROMOCODE_SUMMARY,
  CREATE_PROMOCODE,
  DELETE_PROMOCODE,
  UPDATE_PROMOCODE,
} from './actionTypes';
import {
  fetchPromoCodeSuccessful,
  fetchPromoCodeError,
  fetchPromoHistorySuccessful,
  fetchPromoHistoryError,
  fetchPromoRedeemHistorySuccessful,
  fetchPromoRedeemHistoryError,
  fetchEachPromoCodeSuccessful,
  fetchEachPromoCodeError,
  deletePromoCodeSuccessful,
  deletePromoCodeFailed,
  createPromoCodeSuccessful,
  createPromoCodeFailed,
  updatePromoCodeSuccessful,
  updatePromoCodeFailed,
  updateBonusFailed,
  fetchPromoCodeSummaryFailed,
  fetchPromoCodeSummarySuccessfully,
} from './actions';

import {
  GetPromoCodeService,
  GetEachPromoCodeService,
  DeletePromoCodeService,
  CreatePromoCodeService,
  UpdatePromoCodeService,
  fetchPromoCodeSummaryService,
  GetPromoHistoryService,
  GetPromoRedeemHistoryService,
} from '../../services/promoCodeService';

function* fetchPromoCodeHandler({ payload }) {
  try {
    const response = yield call(GetPromoCodeService, payload);
    yield put(fetchPromoCodeSuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchPromoCodeError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchPromoCodeError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchPromoCodeError(error?.response?.data?.message));
    }
  }
}

function* fetchPromoHistoryHandler({ payload }) {
  try {
    const response = yield call(GetPromoHistoryService, payload);
    yield put(fetchPromoHistorySuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchPromoHistoryError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchPromoHistoryError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchPromoHistoryError(error?.response?.data?.message));
    }
  }
}

function* fetchPromoRedeemHistoryHandler({ payload }) {
  try {
    const response = yield call(GetPromoRedeemHistoryService, payload);
    yield put(fetchPromoRedeemHistorySuccessful(response.data));
  } catch (error) {
    if (error.response.status === 403) {
      yield put(
        fetchPromoRedeemHistoryError(
          'Access to this page is restricted to authorized users only'
        )
      );
      return;
    } else if (error.response.status === 429) {
      yield put(
        fetchPromoRedeemHistoryError(
          'Too much request, please refresh and try again after few seconds'
        )
      );
    } else {
      yield put(fetchPromoRedeemHistoryError(error?.response?.data?.message));
    }
  }
}

function* fetchEachPromoCodeHandler({ payload }) {
  try {
    const response = yield call(GetEachPromoCodeService, payload);
    yield put(fetchEachPromoCodeSuccessful(response.data.result));
  } catch (error) {
    yield put(fetchEachPromoCodeError(error?.response?.data?.message));
  }
}
function* deletePromoCodeHandler({ payload }) {
  try {
    const response = yield call(DeletePromoCodeService, payload);
    yield put(deletePromoCodeSuccessful(response.data.result));
  } catch (error) {
    yield put(deletePromoCodeFailed(error?.response?.data?.error));
  }
}

function* createPromoCodeHandler({ payload }) {
  try {
    const response = yield call(CreatePromoCodeService, payload);
    yield put(createPromoCodeSuccessful(response.data.result));
  } catch (error) {
    yield put(
      createPromoCodeFailed(error?.response?.data?.error.error.message)
    );
  }
}

function* updatePromoCodeHandler({ payload }) {
  try {
    const response = yield call(UpdatePromoCodeService, payload);
    yield put(updatePromoCodeSuccessful(response.data.result));
  } catch (error) {
    yield put(updatePromoCodeFailed(error?.response?.data?.error));
  }
}

function* fetchPromoCodeSummaryHandler({ payload }) {
  try {
    const response = yield call(fetchPromoCodeSummaryService, payload);
    yield put(fetchPromoCodeSummarySuccessfully(response.data));
  } catch (error) {
    yield put(fetchPromoCodeSummaryFailed(error.response.data));
  }
}

export function* watchFetchPromoCode() {
  yield takeEvery(FETCH_PROMOCODE, fetchPromoCodeHandler);
}
export function* watchFetchPromoHistory() {
  yield takeEvery(FETCH_PROMOHISTORY, fetchPromoHistoryHandler);
}
export function* watchFetchPromoRedeemHistory() {
  yield takeEvery(FETCH_PROMOREDEEMHISTORY, fetchPromoRedeemHistoryHandler);
}

export function* watchFetchEachPromoCode() {
  yield takeEvery(FETCH_EACH_PROMOCODE, fetchEachPromoCodeHandler);
}
export function* watchDeletePromoCode() {
  yield takeEvery(DELETE_PROMOCODE, deletePromoCodeHandler);
}
export function* watchCreatePromoCode() {
  yield takeEvery(CREATE_PROMOCODE, createPromoCodeHandler);
}
export function* watchUpdatePromoCode() {
  yield takeEvery(UPDATE_PROMOCODE, updatePromoCodeHandler);
}
export function* watchFetchPromoCodeSummary() {
  yield takeEvery(FETCH_PROMOCODE_SUMMARY, fetchPromoCodeSummaryHandler);
}

function* promoCodeSaga() {
  yield all([
    fork(watchFetchPromoCode),
    fork(watchFetchPromoHistory),
    fork(watchFetchEachPromoCode),
    fork(watchDeletePromoCode),
    fork(watchCreatePromoCode),
    fork(watchUpdatePromoCode),
    fork(watchFetchPromoCodeSummary),
    fork(watchFetchPromoRedeemHistory),
  ]);
}

export default promoCodeSaga;
