import React, { useState, useEffect } from "react";
import { Row, Col, Label, FormGroup, Button, Alert } from "reactstrap";
import CustomSelect from "../../../../components/Common/MoreDropdown/CustomSelect";

const PackageZone = ({
  pkgDetails,
  zone,
  message,
  closeModal,
  packageError,
  updatePackageFailed,
  updatePackage,
  fetchZone,
}) => {
  const [selected, setSelected] = useState([]);
  const [zoneOption, SetZoneOption] = useState([]);

  const addZone = () => {
    let zoneIds = [];
    selected.forEach((data) => {
      zoneIds.push(data.value);
    });
    const data = {
      zones: zoneIds,
      id: pkgDetails.id,
      type: "zone",
    };
    updatePackage(data);
  };

  useEffect(() => {
    if (zone) {
      const zoneArr = [];
      zone.result.forEach((element) => {
        const zoneObj = {};
        zoneObj.value = element.id;
        zoneObj.label = element.name;
        zoneArr.push(zoneObj);
      });
      SetZoneOption(zoneArr);
    }

    if (pkgDetails?.zones?.length > 0) {
      const zonArr = [];
      if (pkgDetails.zones) {
        pkgDetails.zones.forEach((element) => {
          const zonObj = {};
          zonObj.value = element.id;
          zonObj.label = element.name;
          zonArr.push(zonObj);
        });
      }
      setSelected(zonArr);
    }

    if (message) {
      setTimeout(() => {
        updatePackageFailed("");
        closeModal(false);
      }, 2000);
    }
  }, [zone, pkgDetails, message]);
  const handleFetchMoreZones = () => {
    fetchZone({ take: zoneOption.length + 10, skip: 0 });
  };
  return (
    <div>
      {message && (
        <Alert color="success" className="text-center">
          {message}
        </Alert>
      )}
      {packageError && (
        <Alert color="danger" className="text-center">
          {packageError}
        </Alert>
      )}

      <div className="mb-4 d-flex">
        {pkgDetails?.zones?.length > 0
          ? pkgDetails?.zones?.map((zon) => (
              <div key={zon.id} className="ml-2">
                <span className={`badge badge-soft-secondary font-size-12`}>
                  {" "}
                  {zon.name}
                </span>
              </div>
            ))
          : "No zone"}
      </div>
      <div md={6}>
        <FormGroup>
          <Label htmlFor="zone"> Zones</Label>
          <div className="CustomSelect">
            <CustomSelect
              options={zoneOption}
              selected={selected}
              onChange={setSelected}
              fetchMore={
                zoneOption.length !== zone?.total ? handleFetchMoreZones : null
              }
              labelledBy="Select zones ..."
            ></CustomSelect>
          </div>
        </FormGroup>
        <div>
          <Button color="success" size="sm" onClick={addZone}>
            {pkgDetails?.zones?.length > 0 ? "Update" : "Add Zones"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PackageZone;
