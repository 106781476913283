import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import Moment from 'moment';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Common/Loading/index.js';
import { useLocation, withRouter, Link } from 'react-router-dom';

//Import Action to copy breadcrumb items from local state to redux state
import { fetchEachReview } from '../../../store/actions';
const avatarImg =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

const ReviewDetails = ({
  review,
  fetchEachReview,
  loading,
  reviewsError,
  message,
  history,
}) => {
  const [avatar] = useState(avatarImg);
  const breadcrumbItems = [
    { title: 'Review', link: '#' },
    { title: 'Dashboard', link: '#' },
  ];
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const reviewId = new URLSearchParams(search).get('reviewId');

  useEffect(() => {
    if (id) {
      fetchEachReview(id);
    } else {
      fetchEachReview(reviewId);
    }
  }, [fetchEachReview]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        if (id) {
          fetchEachReview(id);
        } else {
          fetchEachReview(reviewId);
        }
      }, 2000);
    }
  }, [fetchEachReview, id, message]);

  if (reviewsError && !loading) {
    return (
      <div className='page-content text-center'>
        <Alert color='danger'>{reviewsError}</Alert>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='Review Details'
            breadcrumbItems={breadcrumbItems}
          />
          <Row>
            <Col>
              <Link
                to='#'
                onClick={
                  reviewId
                    ? () => history.push(`/driver_review`)
                    : () => history.push(`/user_review`)
                }
                className='float-right mr-2'
              >
                <i className='fas fa-arrow-left mr-2' />
                Back
              </Link>
            </Col>
          </Row>
          {!loading && review !== null ? (
            <>
              <Row>
                <Col sm={8}>
                  <Row>
                    <Col md={6}>
                      <Card>
                        <CardBody>
                          <div className='position-relative'>
                            <div>
                              <h4 className='card-title'> Reviewer </h4>
                            </div>
                            <Row className='mt-2'>
                              <Col
                                className='mb-2 px-0 py-0 text-center'
                                sm={3}
                              >
                                <img
                                  className='rounded-circle avatar-md border shadow-lg p-2'
                                  width='100%'
                                  alt='Profile'
                                  src={
                                    review?.reviewer?.picture
                                      ? review?.reviewer?.picture
                                      : avatar
                                  }
                                />
                                <span
                                  className={`badge badge-soft-${
                                    review?.reviewer?.isActive
                                      ? 'success'
                                      : 'danger'
                                  } ml-2`}
                                >
                                  {review?.reviewer?.isActive
                                    ? 'Active'
                                    : 'InActive'}
                                </span>
                              </Col>
                              <Col sm={9}>
                                <span className='mt-1 mb-2'>
                                  <h4 className='text-capitalize font-weight-bold font-size-18 text-sm-left text-center'>
                                    {review?.reviewer?.firstName}{' '}
                                    {review?.reviewer?.lastName}{' '}
                                  </h4>
                                </span>
                                <p className='text-muted mb-1 pb-1 text-muted text-sm-left text-center'>
                                  {review?.reviewer?.userType}
                                </p>
                                <span
                                  className='position-absolute'
                                  // style={{ top: '-25px', right: '-15px' }}
                                >
                                  {review?.reviewer.rating ? (
                                    <span className='mr-2'>
                                      <i className=' fas fa-star text-warning mr-1' />
                                      {review?.reviewer?.rating?.toFixed(2)}
                                    </span>
                                  ) : (
                                    '---'
                                  )}
                                  <span className='text-muted'>
                                    ({review?.reviewer?.raters}{' '}
                                    <i className='fas fa-user font-size-10 mr-1 text-muted' />
                                    )
                                  </span>
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card>
                        <CardBody>
                          <div className='position-relative'>
                            <div>
                              <h4 className='card-title'> Reviewee </h4>
                            </div>
                            <Row className='mt-2'>
                              <Col
                                className='mb-2 px-0 py-0 text-center'
                                sm={3}
                              >
                                <img
                                  className='rounded-circle avatar-md border shadow-lg p-2'
                                  width='100%'
                                  alt='Profile'
                                  src={
                                    review?.reviewee?.picture
                                      ? review?.reviewee?.picture
                                      : avatar
                                  }
                                />
                                <span
                                  className={`badge badge-soft-${
                                    review?.reviewee?.isActive
                                      ? 'success'
                                      : 'danger'
                                  } ml-2`}
                                >
                                  {review?.reviewee?.isActive
                                    ? 'Active'
                                    : 'InActive'}
                                </span>
                              </Col>
                              <Col sm={9}>
                                <span className='mt-1 mb-2'>
                                  <h4 className='text-capitalize font-weight-bold font-size-18 text-sm-left text-center'>
                                    {review?.reviewee?.firstName}{' '}
                                    {review?.reviewee?.lastName}{' '}
                                  </h4>
                                </span>
                                <p className='text-muted mb-1 pb-1 text-muted text-sm-left text-center'>
                                  {review?.reviewee?.userType}
                                </p>
                                <span
                                  className='position-absolute'
                                  // style={{ top: '-25px', right: '-15px' }}
                                >
                                  {review?.reviewee?.rating ? (
                                    <span className='mr-2'>
                                      <i className=' fas fa-star text-warning mr-1' />
                                      {review?.reviewee?.rating?.toFixed(2)}
                                    </span>
                                  ) : (
                                    '---'
                                  )}
                                  <span className='text-muted'>
                                    ({review?.reviewee?.raters}{' '}
                                    <i className='fas fa-user font-size-10 mr-1 text-muted' />
                                    )
                                  </span>
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card>
                        <CardBody>
                          <div className='position-relative'>
                            <Row className='d-flex justify-content-between'>
                              <h4 className='card-title'> Ride Details </h4>
                              <div className='d-flex justify-content-between'>
                                <p
                                  className={`badge badge-soft-${
                                    review.ride.status === 'PROCESSING'
                                      ? 'warning'
                                      : review.ride.status === 'ACCEPTED'
                                      ? 'success'
                                      : review.ride.status === 'CANCELLED'
                                      ? 'danger'
                                      : review.ride.status === 'COMPLETED'
                                      ? 'primary'
                                      : 'dark'
                                  }`}
                                >
                                  {review.ride.status}
                                </p>

                                <p className='mb-1 ml-3'>
                                  {review.ride.paymentMethod === 'ACCOUNT' ? (
                                    <span>
                                      <i className='text-warning fas fa-wallet mr-2' />{' '}
                                      <span className='font-weight-bold bg-soft-warning px-2 rounded'>
                                        Account Payment
                                      </span>
                                    </span>
                                  ) : review.ride.paymentMethod === 'CASH' ? (
                                    <span>
                                      <i className='text-warning fas fa-money-bill-wave mr-2' />{' '}
                                      <span className='font-weight-bold bg-soft-warning px-2 rounded'>
                                        Cash Payment
                                      </span>
                                    </span>
                                  ) : (
                                    <span>
                                      <i
                                        className=' text-warning fas fa-credit-card mr-2
'
                                      />
                                      <span className='font-weight-bold bg-soft-warning px-2 rounded'>
                                        Card Payment
                                      </span>
                                    </span>
                                  )}
                                </p>
                              </div>
                            </Row>
                            <Row className='d-flex justify-content-between'>
                              <Col md={6}>
                                <hr />
                                <h5 className='font-size-14 text-center  bg-light py-1 rounded'>
                                  Distance-Time
                                </h5>
                                <hr />
                                <div className='d-flex mb-2'>
                                  <h5 className='col-6 font-size-14'>
                                    Estimation Distance:
                                  </h5>
                                  <p className='mb-1'>
                                    {' '}
                                    {review.ride.distance.toLocaleString()}Km
                                  </p>
                                </div>

                                <div className='d-flex mb-2'>
                                  <h5 className='col-6 font-size-14'>
                                    Estimation Duration:
                                  </h5>
                                  <p className='mb-1'>
                                    {' '}
                                    {Math.floor(review.ride.duration / 60)}m ,{' '}
                                    {review.ride.duration % 60}s
                                  </p>
                                </div>
                                <div className='d-flex mb-2'>
                                  <h5 className='col-6 font-size-14'>
                                    Created Date:
                                  </h5>
                                  <p className='mb-1'>
                                    {Moment(review.ride.createdAt).format('l')}
                                  </p>
                                </div>
                                <div className='d-flex mb-2'>
                                  <h5 className='col-6 font-size-14'>
                                    Ride Start Time:
                                  </h5>
                                  <p className='mb-1'>
                                    {review.ride.startTime
                                      ? Moment(review.ride.startTime).format(
                                          'LT'
                                        )
                                      : 'Pending'}
                                  </p>
                                </div>
                                <div className='d-flex mb-2'>
                                  <h5 className='col-6 font-size-14'>
                                    Ride End Time:
                                  </h5>
                                  <p className='mb-1'>
                                    {review.ride.startTime
                                      ? Moment(review.ride.endTime).format('LT')
                                      : 'Pending'}
                                  </p>
                                </div>
                              </Col>

                              <Col md={6}>
                                <hr />
                                <h5 className='font-size-14 text-center  bg-light py-1 rounded'>
                                  Address
                                </h5>
                                <hr />
                                <div className='d-flex mb-2'>
                                  <h5 className='col-6 font-size-14'>
                                    Pickup Address:
                                  </h5>
                                  <p className='mb-1'>
                                    {review.ride.pickUp?.address}
                                  </p>
                                </div>
                                <div className='d-flex mb-2'>
                                  <h5 className='col-6 font-size-14'>
                                    Drop Address:
                                  </h5>
                                  <p className='mb-1'>
                                    {review.ride.dropOff?.address}
                                  </p>
                                </div>
                                <hr />
                                <h5 className='font-size-14 text-center  bg-light py-1 rounded'>
                                  Price
                                </h5>
                                <hr />

                                <div className='d-flex mb-2'>
                                  <h5 className='col-6 font-size-14'>
                                    Total Amount:
                                  </h5>
                                  <p className='mb-1 bg-success text-white rounded px-2 rounded'>
                                    ₦{review.ride.totalAmount}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col sm={4}>
                  <Card>
                    <CardBody>
                      <div className='d-flex justify-content-between'>
                        <div>
                          <h4 className='card-title'>Review Info</h4>
                        </div>
                        <div>
                          {review?.rating ? (
                            <span className='mr-2'>
                              <i className=' fas fa-star text-warning mr-1' />
                              {review?.rating?.toFixed(2)}
                            </span>
                          ) : (
                            '---'
                          )}
                          <span className='px-2'>| </span>
                          <span className='font-weight-bold'>
                            Tip (₦ {review?.tip} )
                          </span>
                        </div>
                      </div>
                      <div>
                        <p>
                          Title:
                          <span className='ml-3'>{`${
                            review.title ? review.title : '---'
                          }`}</span>{' '}
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Card>
              <CardBody>
                <Loader loading={loading} />
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { review, loading, reviewsError, message } = state.Review;

  return { review, loading, reviewsError, message };
};

export default connect(mapStateToProps, {
  fetchEachReview,
})(withRouter(ReviewDetails));
