/* eslint-disable no-unused-expressions */
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Container,
  Alert,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { MapContainer, TileLayer, FeatureGroup, Polygon } from "react-leaflet";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { EditControl } from "react-leaflet-draw";
import "../../../../node_modules/leaflet/dist/leaflet.css";
import "../../../../node_modules/leaflet-draw/dist/leaflet.draw.css";
import ZoneTable from "./zoneTable";
import { fetchZone, deleteZone } from "../../../store/actions";
import GreySummaryCard from "../../../components/Common/Summary/index";
import CheckAction, {
  ReadCheck,
} from "../../../components/Common/Summary/actionCheck";
const Zones = ({
  zone,
  tableError,
  fetchZone,
  deleteZone,
  loading,
  message,
  history,
  accessControl,
}) => {
  const [center, setCenter] = useState({ lat: 9.072264, lng: 7.491302 });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [check, setCheck] = useState("");
  const [editWarning, setWarning] = useState(false);
  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Zones", link: "#" },
  ];
  const mapRef = useRef();
  const _onCreate = (e) => {
    const { layerType, layer } = e;
    if (layerType === "polygon") {
      let cord = JSON.stringify(layer.toGeoJSON().geometry.coordinates[0]);
      history.push(`/zone_form?cord=${cord}`);
    }
  };

  const _onEdited = (e) => {
    setWarning(false);
    const {
      layers: { _layers },
    } = e;
    if (!Object.values(_layers)[0]) {
      return;
    }
    let cord = JSON.stringify(
      Object.values(_layers)[0].toGeoJSON().geometry.coordinates[0]
    );
    Object.values(_layers).map(({ options }) => {
      history.push(`/zone_form?${options.id}??${cord}`);
    });
  };

  const polygons = zone?.result?.filter(
    (polygon) => polygon.polygon?.coordinates.length !== 0
  );

  // // eslint-disable-next-line no-extend-native
  // Array.prototype.swapItems = function (a, b) {
  //   this[a] = this.splice(b, 1, this[a])[0];
  //   return this;
  // };
  // let polygon;
  // polygons &&
  //   polygons.map((data) => {
  //     for (let j = 0; j < data?.polygon?.coordinates[0].length; j++) {
  //       polygon = data?.polygon?.coordinates[0][j].swapItems(0, 1);
  //     }
  //   });

  polygons?.forEach((data) => {
    data?.polygon?.coordinates[0]?.forEach((coordinate) => {
      if (coordinate.length >= 2) {
        [coordinate[0], coordinate[1]] = [coordinate[1], coordinate[0]];
      }
    });
  });

  const deleteZoneModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };
  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteZone(deleteId);
    setDeleteId(null);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Zone" breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field="Zones">
            <>
              {tableError === null ? (
                <div className="mb-1 d-flex justify-content-between align-items-center ">
                  <CheckAction field="Zones" iconType={"Read"}>
                    <BootstrapSwitchButton
                      checked={check}
                      onlabel="Table View"
                      offlabel="Map View"
                      onstyle="primary"
                      offstyle="primary"
                      onChange={(e) => setCheck(e)}
                      width={120}
                      // eslint-disable-next-line react/style-prop-object
                      style={"ml-3"}
                    />
                  </CheckAction>
                  <CheckAction field="Zones" iconType={"Create"}>
                    {!check && (
                      <Link to="/zone_form">
                        <Button color="success" className="mr-3">
                          <i className="mdi mdi-plus-circle-outline font-size-18"></i>{" "}
                          Create Zone
                        </Button>
                      </Link>
                    )}
                  </CheckAction>
                </div>
              ) : null}
              {message && (
                <Alert color="success" className="text-center">
                  {message}
                </Alert>
              )}
              {check ? (
                <div className="row py-3">
                  <div className="col text-center">
                    <div className="col">
                      {editWarning && (
                        <div
                          className="text-danger"
                          style={{
                            position: "relative",
                            top: "35px",
                            zIndex: "999",
                            fontWeight: "600",
                            fontSize: "17px",
                          }}
                        >
                          Only edit one zone at a time then save to proceed
                        </div>
                      )}

                      <MapContainer
                        center={center}
                        zoom={9}
                        refs={mapRef}
                        // scrollWheelZoom={false}
                        style={{ height: "600px", width: "100%" }}
                      >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <FeatureGroup>
                          {polygons?.map((poly, i) => (
                            <Polygon
                              pathOptions={{ color: "#3388ff" }}
                              id={poly?.id}
                              positions={poly?.polygon?.coordinates[0]}
                              draggable={true}
                              edit={true}
                              enable={true}
                              drawing={true}
                              key={i}
                            />
                          ))}

                          <EditControl
                            position="topright"
                            onCreated={_onCreate}
                            onEdited={_onEdited}
                            onEditStart={() => setWarning(true)}
                            onEditStop={() => setWarning(false)}
                          />
                        </FeatureGroup>
                      </MapContainer>
                    </div>
                  </div>
                </div>
              ) : (
                <Row>
                  <Col>
                    <Col sm={12}></Col>
                    <ZoneTable
                      zoneData={zone}
                      tableError={tableError}
                      loading
                      deleteZone={deleteZoneModal}
                      fetchZone={fetchZone}
                      history={history}
                      message={message}
                      CheckAction={CheckAction}
                      ReadCheck={ReadCheck}
                      accessControl={accessControl}
                    />
                  </Col>
                </Row>
              )}

              {/* Delete Confirmation Modal */}
              <Modal
                isOpen={showDeleteModal}
                toggle={() => setShowDeleteModal(!showDeleteModal)}
              >
                <ModalBody>
                  <h4 className="card-title mb-4 text-center">
                    {" "}
                    Are you sure you want to delete this zone
                  </h4>
                  <button
                    onClick={() => deleteHandler()}
                    className="btn btn-outline-danger btn-sm w-100"
                  >
                    Click to Delete
                  </button>
                </ModalBody>
              </Modal>
            </>
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const { zone, loading, zoneError, message, tableError } = state.Zone;
  return { accessControl, zone, tableError, loading, zoneError, message };
};

export default connect(mapStateToProps, {
  fetchZone,
  deleteZone,
})(withRouter(Zones));
