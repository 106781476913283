import {
  CREATE_PUSH_NOTIFICATION,
  CREATE_PUSH_NOTIFICATION_SUCCESSFUL,
  CREATE_PUSH_NOTIFICATION_FAILED,
  DELETE_PUSH_NOTIFICATION,
  DELETE_PUSH_NOTIFICATION_SUCCESSFUL,
  DELETE_PUSH_NOTIFICATION_FAILED,
  FETCH_PUSH_NOTIFICATION,
  FETCH_PUSH_NOTIFICATION_SUCCESSFUL,
  FETCH_PUSH_NOTIFICATION_FAILED,
  UPDATE_PUSH_NOTIFICATION,
  UPDATE_PUSH_NOTIFICATION_SUCCESSFUL,
  UPDATE_PUSH_NOTIFICATION_FAILED,
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESSFUL,
  CREATE_NOTIFICATION_FAILED,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESSFUL,
  DELETE_NOTIFICATION_FAILED,
  FETCH_NOTIFICATION,
  FETCH_NOTIFICATION_SUCCESSFUL,
  FETCH_NOTIFICATION_FAILED,
  FETCH_MY_NOTIFICATION,
  FETCH_MY_NOTIFICATION_SUCCESSFUL,
  FETCH_MY_NOTIFICATION_FAILED,
  FETCH_EACH_NOTIFICATION,
  FETCH_EACH_NOTIFICATION_SUCCESSFUL,
  FETCH_EACH_NOTIFICATION_FAILED,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESSFUL,
  UPDATE_NOTIFICATION_FAILED,
  FPN_SUB,
  FPN_SUB_SUCCESSFUL,
  FPN_SUB_FAILED,
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_SUCCESSFUL,
  CREATE_TEMPLATE_FAILED,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_SUCCESSFUL,
  DELETE_TEMPLATE_FAILED,
  FETCH_TEMPLATE,
  FETCH_TEMPLATE_SUCCESSFUL,
  FETCH_TEMPLATE_FAILED,
  FETCH_EACH_TEMPLATE,
  FETCH_EACH_TEMPLATE_SUCCESSFUL,
  FETCH_EACH_TEMPLATE_FAILED,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_SUCCESSFUL,
  UPDATE_TEMPLATE_FAILED,
  FETCH_BETA_EMAILS,
  FETCH_BETA_EMAILS_SUCCESSFUL,
  FETCH_BETA_EMAILS_FAILED,
  FETCH_EMAILS_FOR_CSV,
  FETCH_EMAILS_FOR_CSV_SUCCESSFULLY,
  FETCH_EMAILS_FOR_CSV_ERROR,
  FETCH_NOTIFICATION_LOG,
  FETCH_NOTIFICATION_LOG_SUCCESSFUL,
  FETCH_NOTIFICATION_LOG_FAILED,
  FETCH_EACH_NOTIFICATION_LOG,
  FETCH_EACH_NOTIFICATION_LOG_SUCCESSFUL,
  FETCH_EACH_NOTIFICATION_LOG_FAILED,
} from './actionTypes';

export const fetchPushNotification = (query) => {
  return {
    type: FETCH_PUSH_NOTIFICATION,
    payload: query,
  };
};

export const fetchPushNotificationSuccessful = (payload) => {
  return {
    type: FETCH_PUSH_NOTIFICATION_SUCCESSFUL,
    payload,
  };
};

export const fetchPushNotificationFailed = (error) => {
  return {
    type: FETCH_PUSH_NOTIFICATION_FAILED,
    payload: error,
  };
};

export const deletePushNotification = (id) => {
  return {
    type: DELETE_PUSH_NOTIFICATION,
    payload: id,
  };
};

export const deletePushNotificationSuccessful = (payload) => {
  return {
    type: DELETE_PUSH_NOTIFICATION_SUCCESSFUL,
    payload,
  };
};

export const deletePushNotificationFailed = (error) => {
  return {
    type: DELETE_PUSH_NOTIFICATION_FAILED,
    payload: error,
  };
};

export const updatePushNotification = (payload) => {
  return {
    type: UPDATE_PUSH_NOTIFICATION,
    payload,
  };
};

export const updatePushNotificationFailed = (error) => {
  return {
    type: UPDATE_PUSH_NOTIFICATION_FAILED,
    payload: error,
  };
};

export const updatePushNotificationSuccessful = (payload) => {
  return {
    type: UPDATE_PUSH_NOTIFICATION_SUCCESSFUL,
    payload,
  };
};

export const createPushNotification = (payload, userId) => {
  return {
    type: CREATE_PUSH_NOTIFICATION,
    payload: { payload, userId },
  };
};

export const createPushNotificationSuccessful = (payload) => {
  return {
    type: CREATE_PUSH_NOTIFICATION_SUCCESSFUL,
    payload,
  };
};

export const createPushNotificationFailed = (error) => {
  return {
    type: CREATE_PUSH_NOTIFICATION_FAILED,
    payload: error,
  };
};

export const fetchNotification = (query) => {
  return {
    type: FETCH_NOTIFICATION,
    payload: query,
  };
};

export const fetchNotificationSuccessful = (payload) => {
  return {
    type: FETCH_NOTIFICATION_SUCCESSFUL,
    payload,
  };
};

export const fetchNotificationFailed = (error) => {
  return {
    type: FETCH_NOTIFICATION_FAILED,
    payload: error,
  };
};

export const fetchMyNotification = (query) => {
  return {
    type: FETCH_MY_NOTIFICATION,
    payload: query,
  };
};

export const fetchMyNotificationSuccessful = (payload) => {
  return {
    type: FETCH_MY_NOTIFICATION_SUCCESSFUL,
    payload,
  };
};

export const fetchMyNotificationFailed = (error) => {
  return {
    type: FETCH_MY_NOTIFICATION_FAILED,
    payload: error,
  };
};

export const fetchEachNotification = (id) => {
  return {
    type: FETCH_EACH_NOTIFICATION,
    payload: id,
  };
};

export const fetchEachNotificationSuccessful = (payload) => {
  return {
    type: FETCH_EACH_NOTIFICATION_SUCCESSFUL,
    payload,
  };
};

export const fetchEachNotificationFailed = (error) => {
  return {
    type: FETCH_EACH_NOTIFICATION_FAILED,
    payload: error,
  };
};

export const deleteNotification = (id) => {
  return {
    type: DELETE_NOTIFICATION,
    payload: id,
  };
};

export const deleteNotificationSuccessful = (payload) => {
  return {
    type: DELETE_NOTIFICATION_SUCCESSFUL,
    payload,
  };
};

export const deleteNotificationFailed = (error) => {
  return {
    type: DELETE_NOTIFICATION_FAILED,
    payload: error,
  };
};

export const updateNotification = (payload, id) => {
  return {
    type: UPDATE_NOTIFICATION,
    payload: { payload, id },
  };
};

export const updateNotificationFailed = (error) => {
  return {
    type: UPDATE_NOTIFICATION_FAILED,
    payload: error,
  };
};

export const updateNotificationSuccessful = (payload) => {
  return {
    type: UPDATE_NOTIFICATION_SUCCESSFUL,
    payload,
  };
};

export const createNotification = (payload, userId) => {
  return {
    type: CREATE_NOTIFICATION,
    payload: { payload, userId },
  };
};

export const createNotificationSuccessful = (payload) => {
  return {
    type: CREATE_NOTIFICATION_SUCCESSFUL,
    payload,
  };
};

export const createNotificationFailed = (error) => {
  return {
    type: CREATE_NOTIFICATION_FAILED,
    payload: error,
  };
};

export const fpnSub = (payload) => {
  return {
    type: FPN_SUB,
    payload: { payload },
  };
};

export const fpnSubSuccessful = (payload) => {
  return {
    type: FPN_SUB_SUCCESSFUL,
    payload,
  };
};

export const fpnSubFailed = (error) => {
  return {
    type: FPN_SUB_FAILED,
    payload: error,
  };
};

export const fetchTemplate = (query) => {
  return {
    type: FETCH_TEMPLATE,
    payload: query,
  };
};

export const fetchTemplateSuccessful = (payload) => {
  return {
    type: FETCH_TEMPLATE_SUCCESSFUL,
    payload,
  };
};

export const fetchTemplateFailed = (error) => {
  return {
    type: FETCH_TEMPLATE_FAILED,
    payload: error,
  };
};

export const fetchEachTemplate = (id) => {
  return {
    type: FETCH_EACH_TEMPLATE,
    payload: id,
  };
};

export const fetchEachTemplateSuccessful = (payload) => {
  return {
    type: FETCH_EACH_TEMPLATE_SUCCESSFUL,
    payload,
  };
};

export const fetchEachTemplateFailed = (error) => {
  return {
    type: FETCH_EACH_TEMPLATE_FAILED,
    payload: error,
  };
};

export const deleteTemplate = (id) => {
  return {
    type: DELETE_TEMPLATE,
    payload: id,
  };
};

export const deleteTemplateSuccessful = (payload) => {
  return {
    type: DELETE_TEMPLATE_SUCCESSFUL,
    payload,
  };
};

export const deleteTemplateFailed = (error) => {
  return {
    type: DELETE_TEMPLATE_FAILED,
    payload: error,
  };
};

export const updateTemplate = (payload, id) => {
  return {
    type: UPDATE_TEMPLATE,
    payload: { payload, id },
  };
};

export const updateTemplateFailed = (error) => {
  return {
    type: UPDATE_TEMPLATE_FAILED,
    payload: error,
  };
};

export const updateTemplateSuccessful = (payload) => {
  return {
    type: UPDATE_TEMPLATE_SUCCESSFUL,
    payload,
  };
};

export const createTemplate = (payload) => {
  return {
    type: CREATE_TEMPLATE,
    payload,
  };
};

export const createTemplateSuccessful = (payload) => {
  return {
    type: CREATE_TEMPLATE_SUCCESSFUL,
    payload,
  };
};

export const createTemplateFailed = (error) => {
  return {
    type: CREATE_TEMPLATE_FAILED,
    payload: error,
  };
};

export const fetchBetaEmails = (query) => {
  return {
    type: FETCH_BETA_EMAILS,
    payload: query,
  };
};

export const fetchBetaEmailsSuccessful = (payload) => {
  return {
    type: FETCH_BETA_EMAILS_SUCCESSFUL,
    payload,
  };
};

export const fetchBetaEmailsFailed = (error) => {
  return {
    type: FETCH_BETA_EMAILS_FAILED,
    payload: error,
  };
};

export const fetchEmailsForCSV = (payload) => {
  return {
    type: FETCH_EMAILS_FOR_CSV,
    payload,
  };
};

export const fetchEmailsForCSVSuccessfully = (payload) => {
  return {
    type: FETCH_EMAILS_FOR_CSV_SUCCESSFULLY,
    payload: payload,
  };
};

export const fetchEmailsForCSVError = (error) => {
  return {
    type: FETCH_EMAILS_FOR_CSV_ERROR,
    payload: error,
  };
};

export const fetchNotificationLog = (query) => {
  return {
    type: FETCH_NOTIFICATION_LOG,
    payload: query,
  };
};

export const fetchNotificationLogSuccessful = (payload) => {
  return {
    type: FETCH_NOTIFICATION_LOG_SUCCESSFUL,
    payload,
  };
};

export const fetchNotificationLogFailed = (error) => {
  return {
    type: FETCH_NOTIFICATION_LOG_FAILED,
    payload: error,
  };
};

export const fetchEachNotificationLog = (id) => {
  return {
    type: FETCH_EACH_NOTIFICATION_LOG,
    payload: id,
  };
};

export const fetchEachNotificationLogSuccessful = (payload) => {
  return {
    type: FETCH_EACH_NOTIFICATION_LOG_SUCCESSFUL,
    payload,
  };
};

export const fetchEachNotificationLogFailed = (error) => {
  return {
    type: FETCH_EACH_NOTIFICATION_LOG_FAILED,
    payload: error,
  };
};
