import React, { useEffect, useState } from 'react';
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import MiniWidgets from './Components/MiniWidgets.js';
import GreySummaryCard from '../../../components/Common/Summary/index';
import CheckAction, { ReadCheck } from '../../../components/Common/Summary/actionCheck';
import BonusTable from './bonusTable';
import PreviewBonus from './previewBonus';

import {
  fetchBonus,
  deleteBonus,
  updateBonus,
  updateBonusFailed,
  fetchBonusCSV,
  fetchBonusCSVError,
} from '../../../store/actions';

const Bonus = ({
  bonus,
  fetchBonus,
  deleteBonus,
  updateBonus,
  updateBonusFailed,
  bonusError,
  isAuthorize,
  tableError,
  loading,
  message,
  history,
  fetchBonusCSV,
  fetchBonusCSVError,
  csvData,
  csv_loading,
  accessControl
}) => {
  const [showModal, setShowModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [dateFilter, setDate] = useState({});
  const breadcrumbItems = [
    { title: 'Dashboard', link: '#' },
    { title: 'Bonus', link: '/bonus' },
  ];

  const previewHandler = (id) => {
    setShowModal(true);
    setPreviewId(id);
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        updateBonusFailed('');
      }, 2000);
    }
  }, [message]);

  useEffect(() => {
    updateBonusFailed('');
  }, []);

  const handleStatusUpdate = (info, status) => {
    const statusReview = {
      ...info,
    };
    statusReview.id = info.id;
    statusReview.status = status;
    statusReview.review = 'review';

    updateBonus(statusReview);
  };

  const deleteBonusModal = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const deleteHandler = () => {
    setShowDeleteModal(false);
    deleteBonus(deleteId);
    setDeleteId(null);
  };
  const date = (e) => {
    setDate(e);
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='Bonus' breadcrumbItems={breadcrumbItems} />
          <GreySummaryCard field='Bonus' subModule='All'>
            {isAuthorize ? (
              <Alert color='danger' className='text-center'>
                {isAuthorize}
              </Alert>
            ) : (
              <>
                <CheckAction field='Bonus' subModule='All' iconType={'Create'}>
                  <div className='mb-2 text-right'>
                    <Link to='/bonus_form'>
                      <Button color='success'>
                        <i className='mdi mdi-plus-circle-outline font-size-18'></i>{' '}
                        Add Bonus
                      </Button>
                    </Link>
                  </div>
                </CheckAction>
                {message && (
                  <Alert color='success' className='text-center'>
                    {message}
                  </Alert>
                )}
                {bonusError ? (
                  <Alert color='danger' className='text-center'>
                    {bonusError}
                    <p> Please Visit later </p>
                  </Alert>
                ) : (
                  <Row>
                    <Col>
                      <Row>
                        <MiniWidgets date={dateFilter} />
                      </Row>
                      <BonusTable
                        bonusData={bonus}
                        loading
                        tableError={tableError}
                        dateFilter={date}
                        deleteBonus={deleteBonusModal}
                        fetchBonus={fetchBonus}
                        previewAction={previewHandler}
                        statusHandler={handleStatusUpdate}
                        message={message}
                        history={history}
                        fetchBonusCSV={fetchBonusCSV}
                        fetchBonusCSVError={fetchBonusCSVError}
                        csvData={csvData}
                        csv_loading={csv_loading}
                        CheckAction={CheckAction}
                        ReadCheck={ReadCheck}
                        accessControl={accessControl}
                      />
                    </Col>
                  </Row>
                )}

                <Modal
                  isOpen={showModal}
                  toggle={() => setShowModal(!showModal)}
                >
                  <ModalHeader toggle={() => setShowModal(false)}>
                    Preview Bonus
                  </ModalHeader>
                  <ModalBody>
                    <PreviewBonus previewId={previewId} />
                  </ModalBody>
                </Modal>
                <Modal
                  isOpen={showDeleteModal}
                  toggle={() => setShowDeleteModal(!showDeleteModal)}
                >
                  <ModalBody>
                    <h4 className='card-title mb-4 text-center'>
                      {' '}
                      Are you sure you want to delete this bonus details
                    </h4>
                    <button
                      onClick={() => deleteHandler()}
                      className='btn btn-outline-danger btn-sm w-100'
                    >
                      Click to Delete
                    </button>
                  </ModalBody>
                </Modal>
              </>
            )}
          </GreySummaryCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { accessControl } = state.Account;
  const {
    csvData,
    csv_loading,
    bonus,
    loading,
    bonusError,
    message,
    isAuthorize,
    tableError,
  } = state.Bonus;
  return {
    csvData,
    csv_loading,
    bonus,
    loading,
    bonusError,
    message,
    isAuthorize,
    tableError,
    accessControl
  };
};

export default connect(mapStateToProps, {
  fetchBonus,
  deleteBonus,
  updateBonus,
  updateBonusFailed,
  fetchBonusCSV,
  fetchBonusCSVError,
})(Bonus);
