import {
  CREATE_PUSH_NOTIFICATION,
  CREATE_PUSH_NOTIFICATION_SUCCESSFUL,
  CREATE_PUSH_NOTIFICATION_FAILED,
  UPDATE_PUSH_NOTIFICATION,
  UPDATE_PUSH_NOTIFICATION_SUCCESSFUL,
  UPDATE_PUSH_NOTIFICATION_FAILED,
  FETCH_PUSH_NOTIFICATION,
  FETCH_PUSH_NOTIFICATION_SUCCESSFUL,
  FETCH_PUSH_NOTIFICATION_FAILED,
  DELETE_PUSH_NOTIFICATION,
  DELETE_PUSH_NOTIFICATION_SUCCESSFUL,
  DELETE_PUSH_NOTIFICATION_FAILED,
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESSFUL,
  CREATE_NOTIFICATION_FAILED,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESSFUL,
  UPDATE_NOTIFICATION_FAILED,
  FETCH_NOTIFICATION,
  FETCH_NOTIFICATION_SUCCESSFUL,
  FETCH_NOTIFICATION_FAILED,
  FETCH_MY_NOTIFICATION,
  FETCH_MY_NOTIFICATION_SUCCESSFUL,
  FETCH_MY_NOTIFICATION_FAILED,
  FETCH_EACH_NOTIFICATION,
  FETCH_EACH_NOTIFICATION_SUCCESSFUL,
  FETCH_EACH_NOTIFICATION_FAILED,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESSFUL,
  DELETE_NOTIFICATION_FAILED,
  FPN_SUB,
  FPN_SUB_SUCCESSFUL,
  FPN_SUB_FAILED,
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_SUCCESSFUL,
  CREATE_TEMPLATE_FAILED,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_SUCCESSFUL,
  DELETE_TEMPLATE_FAILED,
  FETCH_TEMPLATE,
  FETCH_TEMPLATE_SUCCESSFUL,
  FETCH_TEMPLATE_FAILED,
  FETCH_EACH_TEMPLATE,
  FETCH_EACH_TEMPLATE_SUCCESSFUL,
  FETCH_EACH_TEMPLATE_FAILED,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_SUCCESSFUL,
  UPDATE_TEMPLATE_FAILED,
  FETCH_BETA_EMAILS,
  FETCH_BETA_EMAILS_SUCCESSFUL,
  FETCH_BETA_EMAILS_FAILED,
  FETCH_EMAILS_FOR_CSV,
  FETCH_EMAILS_FOR_CSV_SUCCESSFULLY,
  FETCH_EMAILS_FOR_CSV_ERROR,
  FETCH_NOTIFICATION_LOG,
  FETCH_NOTIFICATION_LOG_SUCCESSFUL,
  FETCH_NOTIFICATION_LOG_FAILED,
  FETCH_EACH_NOTIFICATION_LOG,
  FETCH_EACH_NOTIFICATION_LOG_SUCCESSFUL,
  FETCH_EACH_NOTIFICATION_LOG_FAILED,
} from './actionTypes';

const initialState = {
  pushNotifications: null,
  notifications: null,
  myNotifications: null,
  notification: null,
  notificationPreview: null,
  loading: false,
  message: null,
  notificationError: null,
  template: null,
  templates: null,
  templateError: null,
  templateLoading: false,
  templateMessage: null,
  emails: null,
  notificationLog: null,
  notificationLogs: null,
  notificationLogError: null,
  csv: null,
  csv_loading: false,
};

const Notification = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MY_NOTIFICATION:
      state = {
        ...state,
        loading: true,
        myNotifications: null,
        message: null,
      };
      break;
    case FETCH_PUSH_NOTIFICATION:
    case UPDATE_PUSH_NOTIFICATION:
    case FETCH_NOTIFICATION:
    case FETCH_EACH_NOTIFICATION:
    case FETCH_BETA_EMAILS:
    case FETCH_NOTIFICATION_LOG:
      state = {
        ...state,
        pushNotifications: null,
        notifications: null,
        notification: null,
        loading: true,
        notificationError: null,
        message: null,
        emails: null,
        notificationLogs: null,
        notificationLog: null,
        notificationLogError: null,
      };
      break;
    case FETCH_EACH_NOTIFICATION_LOG:
      state = {
        ...state,
        notificationLog: null,
        notificationLogError: null,
      };
      break;
    case FETCH_TEMPLATE:
      state = {
        ...state,
        template: null,
        // templates: null,
        templateError: null,
        templateLoading: true,
        templateMessage: null,
      };
      break;
    case UPDATE_TEMPLATE:
    case CREATE_TEMPLATE:
    case FETCH_EACH_TEMPLATE:
      state = {
        ...state,
        template: null,
        templateError: null,
        templateLoading: true,
        templateMessage: null,
      };
      break;
    case FETCH_TEMPLATE_SUCCESSFUL:
      state = {
        ...state,
        template: null,
        templates: action.payload,
        templateError: null,
        templateLoading: false,
        templateMessage: null,
      };
      break;
    case FETCH_EACH_TEMPLATE_SUCCESSFUL:
      state = {
        ...state,
        template: action.payload,
        // templates: null,
        templateError: null,
        templateLoading: false,
        templateMessage: null,
      };
      break;

    case FETCH_PUSH_NOTIFICATION_SUCCESSFUL:
      state = {
        ...state,
        pushNotifications: action.payload,
        loading: false,
        notificationError: null,
        message: null,
      };
      break;

    case FETCH_NOTIFICATION_SUCCESSFUL:
      state = {
        ...state,
        notifications: action.payload,
        loading: false,
        notificationError: null,
        message: null,
      };
      break;

    case FETCH_NOTIFICATION_LOG_SUCCESSFUL:
      state = {
        ...state,
        notificationLogs: action.payload,
        loading: false,
        notificationLogError: null,
        message: null,
      };
      break;
    case FETCH_EACH_NOTIFICATION_LOG_SUCCESSFUL:
      state = {
        ...state,
        notificationLog: action.payload,
        loading: false,
        notificationLogError: null,
        message: null,
      };
      break;
    case FETCH_BETA_EMAILS_SUCCESSFUL:
      state = {
        ...state,
        emails: action.payload,
        loading: false,
        notificationError: null,
        message: null,
      };
      break;
    case FETCH_MY_NOTIFICATION_SUCCESSFUL:
      state = {
        ...state,
        myNotifications: action.payload,
        loading: false,
        notificationError: null,
        message: null,
      };
      break;

    case FETCH_MY_NOTIFICATION_FAILED:
      state = {
        ...state,
        myNotifications: null,
        loading: false,
        notificationError: action.payload,
        message: null,
      };
      break;
    case CREATE_TEMPLATE_FAILED:
    case FETCH_TEMPLATE_FAILED:
    case FETCH_EACH_TEMPLATE_FAILED:
    case DELETE_TEMPLATE_FAILED:
    case UPDATE_TEMPLATE_FAILED:
      state = {
        ...state,
        template: null,
        templateError: action.payload,
        // templates: null,
        templateLoading: false,
        templateMessage: null,
      };
      break;

    case FETCH_NOTIFICATION_LOG_FAILED:
      state = {
        ...state,
        notificationLogs: null,
        notificationLogError: action.payload,
        loading: false,
      };
      break;
    case FETCH_EACH_NOTIFICATION_LOG_FAILED:
      state = {
        ...state,
        notificationLog: null,
        notificationLogError: action.payload,
        loading: false,
      };
      break;
    case FETCH_EACH_NOTIFICATION_SUCCESSFUL:
      state = {
        ...state,
        notification: action.payload,
        notificationPreview: action.payload,
        loading: false,
        notificationError: null,
        message: null,
      };
      break;

    case UPDATE_PUSH_NOTIFICATION_SUCCESSFUL:
    case UPDATE_NOTIFICATION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        notificationError: null,
        message: 'Notification Updated Successfully',
      };
      break;

    case UPDATE_TEMPLATE_SUCCESSFUL:
      state = {
        ...state,
        templateLoading: false,
        templateError: null,
        templateMessage: 'Template Updated Successfully',
      };
      break;
    case CREATE_TEMPLATE_SUCCESSFUL:
      state = {
        ...state,
        templateLoading: false,
        templateError: null,
        templateMessage: 'Template created Successfully',
      };
      break;

    case DELETE_PUSH_NOTIFICATION:
    case DELETE_NOTIFICATION:
      state = {
        ...state,
        loading: true,
        package: null,
        notificationError: null,
        message: null,
      };
      break;

    case DELETE_TEMPLATE:
      state = {
        ...state,
        templateLoading: true,
        templateError: null,
        templateMessage: null,
      };
      break;
    case DELETE_TEMPLATE_SUCCESSFUL:
      state = {
        ...state,
        templateLoading: false,
        templateError: null,
        templateMessage: 'Template deleted successfully',
      };
      break;
    case DELETE_PUSH_NOTIFICATION_SUCCESSFUL:
    case DELETE_NOTIFICATION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        notificationError: null,
        message: 'package deleted successfully',
      };
      break;

    case UPDATE_NOTIFICATION:
    case CREATE_PUSH_NOTIFICATION:
    case CREATE_NOTIFICATION:
    case FPN_SUB:
      state = {
        ...state,
        loading: true,
        notificationError: null,
        message: null,
      };
      break;

    case CREATE_PUSH_NOTIFICATION_SUCCESSFUL:
    case CREATE_NOTIFICATION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        notificationError: null,
        message: `Notification created successfully`,
      };
      break;

    case FPN_SUB_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        notificationError: null,
        message: null,
      };
      break;

    case CREATE_PUSH_NOTIFICATION_FAILED:
    case FETCH_PUSH_NOTIFICATION_FAILED:
    case DELETE_PUSH_NOTIFICATION_FAILED:
    case CREATE_NOTIFICATION_FAILED:
    case FETCH_NOTIFICATION_FAILED:
    case FETCH_EACH_NOTIFICATION_FAILED:
    case DELETE_NOTIFICATION_FAILED:
    case FETCH_BETA_EMAILS_FAILED:
    case FPN_SUB_FAILED:
      state = {
        ...state,
        pushNotifications: null,
        notificationPreview: null,
        notifications: null,
        notification: null,
        loading: false,
        notificationError: action.payload,
        message: null,
        emails: null,
      };
      break;

    case UPDATE_PUSH_NOTIFICATION_FAILED:
    case UPDATE_NOTIFICATION_FAILED:
      state = {
        ...state,
        loading: false,
        notificationError: action.payload,
        message: null,
      };
      break;
    case FETCH_EMAILS_FOR_CSV:
      state = {
        ...state,
        csv: null,
        csv_loading: false,
      };
      break;
    case FETCH_EMAILS_FOR_CSV_SUCCESSFULLY:
      state = {
        ...state,
        csv: action.payload,
        csv_loading: false,
      };
      break;
    case FETCH_EMAILS_FOR_CSV_ERROR:
      state = {
        ...state,
        csv: null,
        csv_loading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Notification;
