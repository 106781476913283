/* eslint-disable eqeqeq */
import React, { useEffect } from 'react'
// import MetaTags from "react-meta-tags"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Alert, Row, Col, Table } from 'reactstrap'
import Loader from '../../../../components/Common/Loading/index.js'
import { fetchEachLoyaltyTier } from '../../../../store/actions'
import Moment from 'moment'

const LoyaltyTier = ({
  loyaltyTierPreview,
  previewId,
  loading,
  loyaltyTierError,
  fetchEachLoyaltyTier,
}) => {
  useEffect(() => {
    fetchEachLoyaltyTier(previewId)
  }, [fetchEachLoyaltyTier, previewId])

  return (
    <React.Fragment>
      <div className="mt-1">
        {loyaltyTierError && !loading && (
          <Alert color="danger" className="text-center">
            {loyaltyTierError}
          </Alert>
        )}
        {!loyaltyTierPreview && loading ? (
          <Loader />
        ) : (
          <Row>
            <Col sm={12} className="text-center">
              <div className="d-flex justify-content-between pb-2">
                <h1
                  className={`badge text-white`}
                  style={{ background: loyaltyTierPreview?.colorCode }}
                >
                  {loyaltyTierPreview?.colorCode}
                </h1>
                <h1
                  className={`badge badge-soft-${
                    loyaltyTierPreview?.status.toLowerCase() === 'active'
                      ? 'success'
                      : loyaltyTierPreview?.status.toLowerCase() === 'inactive'
                      ? 'danger'
                      : 'warning'
                  }`}
                >
                  {loyaltyTierPreview?.status === 'COMINGSOON'
                    ? 'COMING SOON'
                    : loyaltyTierPreview?.status}
                </h1>
              </div>
              {loyaltyTierPreview?.image && (
                <div
                  className=" my-0 mx-auto rounded w-auto p-2 shadow"
                  style={{ maxWidth: 'fit-content', background: '#F1F8E9' }}
                >
                  <img
                    src={loyaltyTierPreview?.image}
                    alt="tier-img"
                    className="avatar-sm"
                  />
                </div>
              )}
              <div>
                <h4 className="card-title"> {loyaltyTierPreview?.code} </h4>
                <div className="text-muted">
                  {loyaltyTierPreview?.description}
                </div>
              </div>
            </Col>
            <Col sm={12}>
              <div>
                <Table hover>
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">Name</td>
                      <td className="text-uppercase">
                        {loyaltyTierPreview?.name}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">User Type</td>
                      <td>{loyaltyTierPreview?.userType}</td>
                    </tr>

                    <tr>
                      <td className="font-weight-bold">Benefits</td>
                      <td>
                        {loyaltyTierPreview?.benefits.length > 0
                          ? loyaltyTierPreview?.benefits.map((name, key) => (
                              <>
                                {' '}
                                <span
                                  key={key}
                                  className={`badge badge-soft-secondary font-size-12`}
                                >
                                  {' '}
                                  {name}
                                </span>{' '}
                              </>
                            ))
                          : 'None'}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-bold">Created Date</td>
                      <td>
                        {' '}
                        {Moment(loyaltyTierPreview?.createdAt).format('lll')}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="">
                <h4 className="card-title text-center"> Requirements </h4>
                <Table hover>
                  {loyaltyTierPreview?.userType === 'RIDER' ? (
                    <tbody>
                      <tr>
                        <td className="font-weight-bold">Minimum Points</td>
                        <td className="text-uppercase text-left">
                          {loyaltyTierPreview?.requirement?.minimumPoint >
                            999 &&
                          loyaltyTierPreview?.requirement?.minimumPoint < 999999
                            ? `${
                                loyaltyTierPreview?.requirement?.minimumPoint /
                                1000
                              } K`
                            : loyaltyTierPreview?.requirement?.minimumPoint >
                              999999
                            ? `${
                                loyaltyTierPreview?.requirement?.minimumPoint /
                                1000000
                              } M`
                            : loyaltyTierPreview?.requirement?.minimumPoint}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Maximum Points</td>
                        <td className="text-uppercase text-left">
                          {loyaltyTierPreview?.requirement?.maximumPoint >
                            999 &&
                          loyaltyTierPreview?.requirement?.maximumPoint < 999999
                            ? `${(
                                loyaltyTierPreview?.requirement?.maximumPoint /
                                1000
                              ).toFixed(1)} K`
                            : loyaltyTierPreview?.requirement?.maximumPoint >
                              999999
                            ? `${(
                                loyaltyTierPreview?.requirement?.maximumPoint /
                                1000000
                              ).toFixed(1)} M`
                            : loyaltyTierPreview?.requirement?.maximumPoint}
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="font-weight-bold">Trips</td>
                        <td>{loyaltyTierPreview?.requirement?.trip}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Rating</td>
                        <td>{loyaltyTierPreview?.requirement?.rating}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Vehicle Year</td>
                        <td>{loyaltyTierPreview?.requirement?.vehicleYear}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Cancellation</td>
                        <td>{loyaltyTierPreview?.requirement?.cancellation}</td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { loyaltyTierPreview, loading, loyaltyTierError } = state.LoyaltyTier
  return { loyaltyTierPreview, loading, loyaltyTierError }
}

export default connect(mapStateToProps, { fetchEachLoyaltyTier })(LoyaltyTier)
